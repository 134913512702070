import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Collapse from 'reactstrap/lib/Collapse';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { ProfileActions } from './profile-actions';
import Aux from '../../../../global/AuxComp';
import moment from 'moment';
import { DateRangePicker } from '../../../../global/Forms/DatePicker';
import { InputField } from '../../../../global/Forms';
import { Select, MonthSelect, YearSelect, MultiSelectList } from '../../../../global/Forms/Select';
import Button from '../../../../global/Button';
import { CONSTANTS } from '../../../../../constants';
import { Auth } from '../../../../../actions/Auth';
import AutosuggestComp from '../../../../global/AutosuggestComp';
import { CVPoint } from './cv-point';
import { filterCVPoints } from '../../../../../actions';
import { FileUpload } from '../../../../global/FileUpload';
import { AddSuggestion } from '../../../../global/Forms/AddSuggestion';
import { Errorbar } from '../../../../global/errorbar';
import { Switch } from '../../../../global/Forms/ToggleSwitch';
import isInclusivelyAfterDay from 'react-dates/esm/utils/isInclusivelyAfterDay';

export class Academics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: {
        getEducationBoards: [],
        getDepartments: [],
        getInstitutes: [],
        getSchools: [],
        getDegrees: [],
       
      },
      boardValue: "",
      departmentValue: "",
      instituteValue: "",
      schoolValue: "",
      degreeValue: "",
      modalShow: false,
      deleteId: null,
      errorbar: false,
      message: '',
      errorValidation: false,
      specializationList: [],
    }
    this.init();
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  init = async () => {
    this.props.toggleLoading(true);
    try {
      let resp = await Auth.Specialization.fetch()
      this.setState({ specializationList: resp.data })
      this.props.toggleLoading(false);
    } catch (e) {
      this.props.toggleLoading(false);
      console.log(e)
    }
    
  }
  checkProperties(obj) {
    for (let key in obj) {
      if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
        this.setState({ message: 'Please fill all mandatory input fields' });
        this.setState({ errorbar: true });
        return true
      }
    }
    this.setState({ errorbar: false });
    return false;
  }

  submitAcademicDetail = async (e, academic) => {
    if (e) e.preventDefault();
    if (!academic.academic_id) {
      return
    }

    if (academic.score_type === "%") {
      academic.total_score = 100;
      academic.score_ep = academic.score_value
    }
    // required properties for X academic_type
    const requiredPropertiesX = {
      academic_id: academic.academic_id,
      schoolValue: this.state.schoolValue,
      board_id: academic.board_id,
      score_type: academic.score_type,
      score_value: academic.score_value,
      score_ep: academic.score_ep,
      total_score: academic.total_score,
      year_start: moment(academic.year_start).year() ? academic.year_start : null,
      year_end: moment(academic.year_end).year() ? academic.year_end : null
    };

    let requiredPropertiesGRADUATION = {
      academic_id: academic.academic_id,
      // departmentValue: this.state.departmentValue,
      instituteValue: this.state.instituteValue,
      degreeValue: this.state.degreeValue,
      score_type: academic.score_type,
      score_value: academic.score_value,
      score_ep: academic.score_ep,
      total_score: academic.total_score,
      year_start: moment(academic.year_start).year() ? academic.year_start : null
    };
    if (!academic.currently_working)
      requiredPropertiesGRADUATION.year_end = moment(academic.year_end).year() ? academic.year_end : null
    else
      academic.year_end = academic.year_start

    const requiredPropertiesXII = {
      ...requiredPropertiesX
    };

    if (academic.academic_type == 'X') {
      if (this.checkProperties(requiredPropertiesX)) {
        return
      }
    }

    if (academic.academic_type == 'XII') {
      if (this.checkProperties(requiredPropertiesXII)) {
        return
      }
      if(!academic.stream) academic.stream= ""
    }

    try {
      if (academic.academic_type == 'XII' || academic.academic_type == 'X') {
        this.props.toggleLoading(true);
         
        if (!academic.school_id) {
          let schoolResp = await Auth.General.addSchool({ school_name: this.state.schoolValue.replace(/(^|\s)\S/g, l => l.toUpperCase()) })
          if (schoolResp.data.insertId) {
            academic.school_id = schoolResp.data.insertId
            academic.school_name = this.state.schoolValue
          }
        }
      }

      if (academic.academic_type == 'GRADUATION' || academic.academic_type == 'POST_GRADUATION') {
        if (this.checkProperties(requiredPropertiesGRADUATION)) {
          return
        }
        this.props.toggleLoading(true);
        if (!academic.department_id && this.state.departmentValue) {
          let departmentResp = await Auth.General.addDepartment({ department_name: this.state.departmentValue.replace(/(^|\s)\S/g, l => l.toUpperCase())  })
          if (departmentResp.data.insertId) {
            academic.department_id = departmentResp.data.insertId
            academic.department_name = this.state.departmentValue
          }
        }
        if(!academic.department_id) academic.department_id = 0;
        if (!academic.degree_id) {
          let degreeResp = await Auth.General.addDegree({ degree_name: this.state.degreeValue.replace(/(^|\s)\S/g, l => l.toUpperCase())  })
          if (degreeResp.data.insertId) {
            academic.degree_id = degreeResp.data.insertId
            academic.degree_name = this.state.degreeValue
          }
        }
        if (!academic.institute_id) {
          let instituteResp = await Auth.General.addInstitute({ college_name: this.state.instituteValue.replace(/(^|\s)\S/g, l => l.toUpperCase()) , university_name: academic.university_name.replace(/(^|\s)\S/g, l => l.toUpperCase())  })
          if (instituteResp.data.insertId) {
            academic.institute_id = instituteResp.data.insertId
            academic.college_name = this.state.instituteValue
            academic.university_name = academic.university_name
          }
        }
      }
      let method = Auth.Student.updateAcademicDetail,
        formData = Object.assign({}, academic);
      formData.year_start = moment(formData.year_start).format(CONSTANTS.DATE_FORMAT)
      formData.year_end = moment(formData.year_end).format(CONSTANTS.DATE_FORMAT)
      delete formData.board_short_form;
      if (typeof academic.academic_id == "string") {
        method = Auth.Student.createAcademicDetail;
        formData.student_id = this.props.user_info.student_id;
        delete formData.academic_id
      }
     var resp = await method(formData)
     
      this.props.getStudentProfile()
      if (e) this.props.toggleSection("");

    } catch (e) {

      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }

    }
    this.props.toggleLoading(false);
  }

  deleteDetail = async () => {
    let { deleteId } = this.state;
    if (!deleteId) return;
    let formData = {
      student_id: this.props.user_info.student_id,
      academic_id: deleteId,
      status: 0
    }
    this.props.toggleLoading(true)
    try {
      if (typeof deleteId == "number")
        await Auth.Student.updateAcademicDetail(formData)
      this.props.changeAcademics(formData, true)
      this.props.getStudentProfile()
      this.toggleModal()
    } catch (e) {

      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }

    }
    this.props.toggleLoading(false)
  }

  toggleModal = (id) => {
    this.setState({
      modalShow: !this.state.modalShow,
      deleteId: id
    })
  }

  filterAcademicDetails = academics => {
    let details = {
      "X": [],
      "XII": [],
      "GRADUATION": [],
      "POST_GRADUATION": []
    }
    academics.forEach(academic => {
      switch (academic.academic_type) {
        case "X": details["X"].push(academic)
          break;
        case "XII": details["XII"].push(academic)
          break;
        case "GRADUATION": details["GRADUATION"].push(academic)
          break;
        case "POST_GRADUATION": details["POST_GRADUATION"].push(academic)
          break;
        default: break;
      }
    })
    return details
  }

  changeYear = (e, field, method, academic) => {
    if (field === "year_start" && academic.year_end && e.target.value > moment(academic.year_end)[method]())
      academic.year_end = moment(academic.year_start)[method](e.target.value)
    let date = academic[field] ? moment(academic[field]) : moment();
    academic[field] = date[method](e.target.value).format(CONSTANTS.DATE_FORMAT);
    this.props.changeAcademics(academic);
  }

  handleAcademicChange = (e, academic) => {
    academic[e.target.name] = e.target.value;
    if (e.target.name == "university_name") {
      academic["institute_id"] = null
    } else if (e.target.name == "department_id") {
      academic["department_name"] = e.target.department_name
    } else if (e.target.name == "institute_id") {
      academic["college_name"] = e.target.college_name
      academic["university_name"] = e.target.university_name
    } else if (e.target.name == "school_id") {
      academic["school_name"] = e.target.school_name;
    } else if (e.target.name == "degree_id") {
      // academic["degree_name"] = e.target.degree_name;
    }
    this.props.changeAcademics(academic)
  }

  setAcademicBoard = (value, academic) => {
    let evtObj = {
      target: {
        value: value.board_id,
        name: "board_id",
        board_short_form: value.board_short_form,
        board_name: value.board_name
      }
    }
    this.setState({
      boardValue: value.board_name
    })
    this.handleAcademicChange(evtObj, academic)
  }

  
 addOtherSpecialization =(academic) => {
    let specializationList = this.state.specializationList;
    if(academic.otherSpecialization){
      specializationList.push({
        specialization_name : academic.otherSpecialization,
        academic_type : academic.academic_type == "GRADUATION" ? "UG" : "PG"
      })
      this.setState({specializationList : specializationList})
      let specialization = academic.specialization ? academic.specialization.split(", ") : [];
      let index = specialization.indexOf("Others");
      if(index >= 0){
        specialization.splice(index, 1);
      }
      academic.specialization = specialization.join(", ")
      this.props.changeAcademics(academic)
    }
 }
  setDepartmentValue = (value, academic) => {
    let evtObj = {
      target: {
        value: value.department_id,
        name: "department_id",
        department_name: value.department_name
      }
    }
    this.setState({
      departmentValue: value.department_name
    })
    this.handleAcademicChange(evtObj, academic)
  }

  setInstituteValue = (value, academic) => {
    let evtObj = {
      target: {
        value: value.id,
        name: "institute_id",
        college_name: value.college_name,
        university_name: value.university_name
      }
    }
    this.setState({
      instituteValue: value.college_name
    })
    this.handleAcademicChange(evtObj, academic)
  }

  setSchoolValue = (value, academic) => {
    let evtObj = {
      target: {
        value: value.school_id,
        name: "school_id",
        school_name: value.school_name
      }
    }
    this.setState({
      schoolValue: value.school_name
    })
    this.handleAcademicChange(evtObj, academic)
  }

  setDegreeValue = (value, academic) => {
    let evtObj = {
      target: {
        value: value.degree_id,
        name: "degree_id",
        degree_name: value.degree_name
      }
    }
    this.setState({
      degreeValue: value.degree_name
    })
    this.handleAcademicChange(evtObj, academic)
  }

  handleFieldChange = (e, academic) => {
    let { name, value } = e.target
    if (academic) {
      if (name == "schoolValue") this.setSchoolValue({ school_id: null, school_name: "" }, academic)
      if (name == "departmentValue") this.setDepartmentValue({ department_id: null, department_name: "" }, academic)
      if (name == "degreeValue") this.setDegreeValue({ degree_id: null, degree_name: "" }, academic)
      if (name == "instituteValue") this.setInstituteValue({ institute_id: null, college_name: "", university_name: "" }, academic)
    }
    this.setState({
      [name]: value
    })
  }

  setAutoSuggestionValues = (academic) => {
    this.setState({
      boardValue: academic.board_name || "",
      departmentValue: academic.department_name || "",
      instituteValue: academic.college_name || "",
      schoolValue: academic.school_name || "",
      degreeValue: academic.degree_name || ""
    })
  }

  renderDetails = (academic) => {
    if (!academic) return null
    let type = academic.academic_type,
      sectionName = "ACAD-" + academic.academic_id,
      onChange = (e) => {
        this.handleAcademicChange(e, academic)
      },
      setSpecialization = (e) => {
        let specialization = academic.specialization ? academic.specialization.split(", ") : [];
        let index = specialization.indexOf(e.target.value);
        if(e.target.value){
          if(index >= 0){
            specialization.splice(index, 1);
          }
          else{
            specialization.push(e.target.value)
          }
          academic.specialization = specialization.join(", ")
          this.props.changeAcademics(academic)
        }
      },
      addOtherSpecialization = () => {
       this.addOtherSpecialization(academic)
      },
      { boardValue, suggestions, departmentValue, instituteValue, schoolValue, degreeValue } = this.state,
      autoSuggestProps = {
        className: "w-100 r-inputfield",
        onChange: (e) => this.handleFieldChange(e, academic)
      },
      isUGPG = type.indexOf("GRADUATION") > -1,
      isPG = type == "POST_GRADUATION",
      isUG = type == "GRADUATION",
      isX = type == "X",
      isXII = type == "XII";
      var isPGSpecialization = type == "POST_GRADUATION";
    let specializationList = this.state.specializationList.map(obj => obj.specialization_name);
    let otherSpecializationList = academic.specialization ? academic.specialization.split(", ").filter(value => specializationList.indexOf(value) < 0) : []
    console.log(otherSpecializationList)
    

    const { files, editableSection, toggleSection, requiredValidation, commentThreads, boardList, degreeList, 
     } = this.props;
    if (!academic.academic_id)
      this.props.changeAcademics(academic, false, "NEW_" + type + Math.ceil(Math.random() * 10000))
    return (
      <Aux>
        <Collapse isOpen={this.props.editableSection !== sectionName}>
          <span className="r-subheading-2">
            {isUGPG ? <Aux>
              <p className="mb-0">{(academic.college_name)}</p>
            </Aux> : <Aux>
                <p>{academic.school_name}</p>
              </Aux>}
          </span>
          <span className="r-subheading-6 r-text--sm mb-2 r-text--grey-5">
            {isUGPG && (academic.university_name || "")}
          </span>

          {/* {isPG &&
            <p>Specialisation 01, Subject 01</p>
          } */}
          <Row className="justify-content-between mb-2">
            <Col xs={8}>
              {isUGPG ?
                (academic.degree_name || academic.department_name) && <p className="r-subheading-3 mt-2">{academic.degree_name}, {academic.department_name} </p>
                :
                <p className="r-subheading-3">{academic.subjects} </p>
              }

              <span className="r-text--grey-4">{`${academic.year_start ? moment(academic.year_start).format("YYYY") : ""} - ${academic.currently_working ? "Currently Pursuing" : (academic.year_end ? moment(academic.year_end).format("YYYY") : "")}`}</span>

            </Col>
            <Col xs={4}>
              <Row noGutters className="text-center justify-content-end">

                {academic.rank ? <Col xs="auto" className="px-2">
                  <span>{academic.rank}</span>
                  <p className="r-heading-7 r-text--blue-3">Highlights/Remarks</p>
                </Col> : null}
                <Col xs="auto" className="px-2">
                  <span>{academic.score_value || "X.XX"}</span>
                  <p className="r-heading-7 r-text--blue-3">{academic.score_type || "CGPA/%"}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          {(files[academic.file]) && <Row noGutters>
            {files[academic.file].map((doc, i) => <Col className="pr-3" key={i} xs="auto">
              <div className="r-tag r-tag--type-1 r-tag--no-delete">
                <a href={doc.url} target="_blank">{doc.file_name || doc.file_url || ""}</a>
              </div>
            </Col>)}
          </Row>}
        </Collapse>
        <Collapse isOpen={this.props.editableSection === sectionName} onEntering={() => this.setAutoSuggestionValues(academic)}>
          <form onSubmit={(e) => this.submitAcademicDetail(e, academic)}>

            {isUGPG ? <Aux>
              {isPG && this.details["POST_GRADUATION"].filter(data => typeof academic.academic_id !== "string").length === 0 ? <h6 className="r-subheading-3 mb-4" style={{fontStyle: "oblique"}}><span class="r-text--red-1">*</span>Enter pursuing PG details first</h6> : ""}
              {isUG && this.details["GRADUATION"].filter(data => typeof academic.academic_id !== "string").length === 0 ? <h6 className="r-subheading-3 mb-4" style={{fontStyle: "oblique"}}><span class="r-text--red-1">*</span>Enter pursuing Graduation details first</h6> : ""}
              <Row form className="mb-2">
                <Col xs={12}>
                  <div className="d-flex justify-content-between">
                    <label className="r-heading-7" htmlFor={`${type}__institute`}><span className="r-text--red-1">*</span>College Name</label>
                    {/* <AddSuggestion type="institute" /> */}
                  </div>
                  <AutosuggestComp
                    inputProps={{
                      ...autoSuggestProps,
                      placeholder: '',
                      value: instituteValue,
                      name: "instituteValue"
                    }}
                    suggestionID="getInstitutes"
                    renderSuggestion={(inst) => <div>{inst.college_name}, {inst.university_name}</div>}
                    setValue={(value) => this.setInstituteValue(value, academic)} />
                </Col>
              </Row>
              <Row form className="mb-2">
                <Col xs={12}>
                  <label className="r-heading-7" htmlFor={`${type}__university`}><span className="r-text--red-1">*</span> University Name</label>
                  <InputField name="university_name" id={`${type}__university`} className="w-100" value={academic.university_name || ""} onChange={onChange} />
                </Col>
              </Row>
              <Row form className="mb-2">
                <Col xs={12}>
                  <div className="d-flex justify-content-between">
                    <label className="r-heading-7" htmlFor={`${type}__degree`}><span className="r-text--red-1">*</span>Degree</label>
                    {/* <AddSuggestion type="degree" /> */}
                  </div>
                  {/* <Select name="degree_id" onChange={onChange} value={academic.degree_id}>
                    <option value="">Select</option>
                    {degreeList}
                  </Select> */}
                  <AutosuggestComp
                    inputProps={{
                      ...autoSuggestProps,
                      placeholder: '',
                      value: degreeValue,
                      name: "degreeValue"
                    }}
                    suggestionID="getDegrees"
                    renderSuggestion={(deg) => <div>{deg.degree_name}</div>}
                    setValue={(value) => this.setDegreeValue(value, academic)} />
                </Col>
              </Row>
              <Row form className="mb-2">
                <Col xs={12}>
                  <div className="d-flex justify-content-between">
                    <label className="r-heading-7" htmlFor={`${type}__department`}>Department Name</label>
                    {/* <AddSuggestion type="department" /> */}
                  </div>
                  <AutosuggestComp
                    inputProps={{
                      ...autoSuggestProps,
                      placeholder: '',
                      value: departmentValue,
                      name: "departmentValue"
                    }}
                    suggestionID="getDepartments"
                    renderSuggestion={(dept) => <div>{dept.department_name}</div>}
                    setValue={(value) => this.setDepartmentValue(value, academic)} />
                </Col>
              </Row>

              <Row form className="mb-2">
                <Col xs={12}>
                    <div 
                     className="d-flex justify-content-between">
                      <label className="r-heading-7">Specialization</label>
                      
                    </div>   
                    <MultiSelectList className="bg-light r-dropdown text-dark" title={academic.specialization || "Select"} >
                      <ul className="list-unstyled m-0">
                      {
                        this.state.specializationList.map((obj, i) =>{
                          return obj.academic_type == (isPGSpecialization ? "PG" : "UG") ?
                          <li key={i}>
                          <input checked={academic.specialization ? academic.specialization.split(", ").indexOf(obj.specialization_name) >= 0 : false} onChange={setSpecialization} type="checkbox"  value={obj.specialization_name}/>
                          <label>{obj.specialization_name}</label>
                          </li>
                            : ""                   
                        })
                       
                     }
                      {
                        otherSpecializationList.map((specialization_name, i) =>{
                         return specialization_name != "Others" ?  <li key={i}>
                          <input checked={academic.specialization ? academic.specialization.split(", ").indexOf(specialization_name) >= 0 : false} onChange={setSpecialization} type="checkbox"  value={specialization_name}/>
                          <label>{specialization_name}</label>
                          </li>   
                          :""
                        }                
                        )
                       
                     }
                        <li className="border-top">
                        <input checked={academic.specialization ? academic.specialization.split(", ").indexOf("Others") >= 0 : false} onChange={setSpecialization} type="checkbox"  value="Others"/>
                        <label>Add Others</label>
                        </li>
                      </ul>
                    </MultiSelectList>  
                   </Col>   
                    {academic.specialization && academic.specialization.split(", ").indexOf("Others") >= 0 ? 
                        <Col className="mt-2" xs="12" >
                            <InputField name="otherSpecialization" onChange={onChange} className="" type="text" />
                            <Button type="button" onClick={addOtherSpecialization}>Add</Button><Button  type="button" onClick={setSpecialization} value="Others">Cancel</Button>
                        </Col>   : ""
                    }                      
              </Row>


            </Aux> : <Aux>
                <Row form className="mb-2">
                  <Col xs={12}>
                    <div className="d-flex justify-content-between">
                      <label className="r-heading-7" htmlFor={`${type}__school`}><span className="r-text--red-1">*</span>School Name</label>
                      {/* <AddSuggestion type="school" /> */}
                    </div>
                    <AutosuggestComp
                      inputProps={{
                        ...autoSuggestProps,
                        placeholder: '',
                        value: schoolValue,
                        name: "schoolValue"
                      }}
                      suggestionID="getSchools"
                      renderSuggestion={(sch) => <div>{sch.school_name}</div>}
                      setValue={(value) => this.setSchoolValue(value, academic)} />
                  </Col>
                </Row>
                <Row form className="mb-2">
                  <Col xs={12}>
                    <div className="d-flex justify-content-between">
                      <label className="r-heading-7" htmlFor={`${type}__board`}><span className="r-text--red-1">*</span>Board Name</label>
                      {/* <AddSuggestion type="board" /> */}
                    </div>
                    <Select name="board_id" onChange={onChange} value={academic.board_id}>
                      <option value="">Select</option>
                      {boardList}
                    </Select>
                    {/* <AutosuggestComp
                      inputProps={{
                        ...autoSuggestProps,
                        placeholder: '',
                        value: boardValue,
                        name: `boardValue`
                      }}
                          suggestionID="getEducationBoards"
                          renderSuggestion={(board) => <div>{board.board_name}</div>}
                          setValue={(value) => this.setAcademicBoard(value, academic)}/> */}
                  </Col>
                </Row>
              </Aux>}
            {isXII && <Row form className="mb-2">
              <Col xs={12}>
                <label className="r-heading-7" htmlFor={`${type}__stream`}>Stream</label>
                <InputField name="stream" id={`${academic}__stream`} onChange={onChange} className="w-100" type="text" value={academic.stream || ""} />
              </Col>
            </Row>}
            <Row form className="mb-2">
              <Col xs={12}>
                <label className="r-heading-7" htmlFor={`${type}__subjects`}>Subjects</label>
                <InputField name="subjects" id={`${academic}__subjects`} onChange={onChange} className="w-100" type="text" value={academic.subjects || ""} />
              </Col>
            </Row>

            <Row form className="mb-2">
              <Col xs={6}>
                <label className="r-heading-7" htmlFor={`${type}__year_start`}><span className="r-text--red-1">*</span>Start Year</label>
                <Row noGutters>
                  {/*<Col xs={6}>
                    <MonthSelect onChange={(e) => this.changeYear(e, "year_start", "month", academic)} value={academic.year_start ? moment(academic.year_start).month() : ""} />
                  </Col>*/}
                  <Col xs={12}>
                    <YearSelect onChange={(e) => this.changeYear(e, "year_start", "year", academic)} value={academic.year_start ? moment(academic.year_start).year() : ""} />
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                <Row className="justify-content-between align-items-center">
                  <Col xs="auto">
                    <label className="r-heading-7" htmlFor={`${type}__year_end`}><span className="r-text--red-1">*</span>End Year</label>
                  </Col>
                  {isUGPG && <Col className="d-flex align-items-center" xs="auto">
                    <span className="r-text--sm mr-2">Currently Pursuing</span>
                    <Switch isOn={academic.currently_working == 1 ? true : false} handleToggle={(e) => this.handleAcademicChange({ target: { name: "currently_working", value: academic.currently_working ? 0 : 1 } }, academic)} />
                  </Col>}
                </Row>
                <Row noGutters>
                  {/*<Col xs={6}>
                    <MonthSelect onChange={(e) => this.changeYear(e, "year_end", "month", academic)} value={academic.year_end ? moment(academic.year_end).month() : ""} />
                  </Col>*/}
                  <Col xs={12}>
                    <YearSelect disabled={academic.currently_working} min={moment(academic.year_start).year()} onChange={(e) => this.changeYear(e, "year_end", "year", academic)} value={academic.year_end ? moment(academic.year_end).year() : ""} />
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Row form className="mb-2">
              <Col xs={12}>
                <label className="r-heading-7" htmlFor={`${type}__location`}>Location</label>
                <InputField name="location" id={`${type}__location`} className="w-100" value={academic.location || ""} onChange={onChange} />
              </Col>
            </Row> */}
            <Row form className="mb-2">
              <Col xs={academic.score_type === "CGPA" ? 3 : 6}>
                <Row noGutters>
                  <Col xs={6} className="pr-1">
                    <label className="r-heading-7" htmlFor={`${type}__score`}><span className="r-text--red-1">*</span>Score</label>
                    <Select name="score_type" value={academic.score_type || ""} onChange={onChange}>
                      <option value="">Select</option>
                      <option value="%">Percentage</option>
                      <option value="CGPA">CGPA</option>
                    </Select>
                  </Col>
                  <Col xs={6} className="pl-1">
                    <label className="r-heading-7"><span className="r-text--red-1">*</span>Value</label>
                    <InputField className="w-100" type="text" name="score_value" onChange={onChange} value={academic.score_value || ""} />
                  </Col>
                </Row>
              </Col>
              {
                academic.score_type === "CGPA" &&
                <Aux>
                  <Col xs={3}>
                    <label className="r-heading-7" htmlFor={`${type}__total-score`}><span className="r-text--red-1">*</span>Out of</label>
                    <InputField className="w-100" type="text" name="total_score" onChange={onChange} value={academic.total_score || ""} />
                  </Col>
                  <Col xs={3}>
                    <label style={{ whiteSpace: "nowrap" }} className="r-heading-7" htmlFor={`${type}__ep-score`}><span className="r-text--red-1">*</span>Equivalent percentage</label>
                    <InputField className="w-100" type="text" name="score_ep" onChange={onChange} value={academic.score_ep || ""} />
                  </Col>
                </Aux>
              }
              <Col xs={academic.score_type === "CGPA" ? 3 : 6}>
                <label className="r-heading-7" htmlFor={`${type}__rank`}>Highlights/Remarks</label>
                <InputField className="w-100" type="text" name="rank" onChange={onChange} value={academic.rank || ""} />
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">
              <Button type="button" onClick={() => this.props.onCancel()} className="r-text--sm">Cancel</Button>
              <Button type="submit" className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
            </div>
            {typeof academic.academic_id == "number" && < FileUpload toggleLoading={this.props.toggleLoading} updateData={this.props.changeAcademics} submitData={this.submitAcademicDetail} verification={{ user_id: this.props.user_info.user_id, data: academic, files }} />}
          </form>
        </Collapse >
        {typeof academic.academic_id == "number" && <CVPoint files={files} toggleCV={this.props.toggleCV} settings={this.props.settings} toggleLoading={this.props.toggleLoading} user_info={this.props.user_info} profile_id={academic.academic_id} type="ACADEMIC" getStudentProfile={this.props.getStudentProfile} changeCVPoint={this.props.changeCVPoint} cvPoints={this.cvPoints[academic.academic_id] || []} commentThreads={commentThreads} profileType="CV_POINT" />}
        {editableSection !== sectionName && <ProfileActions delete={() => this.toggleModal(academic.academic_id)} verification={{ ...academic }} toggleSection={() => toggleSection(sectionName)} isRequiredValidate={requiredValidation(this.details[type])} commentThreads={commentThreads} profileType="ACADEMIC" />}
      </Aux >
    )
  }

  addAcademic = (e, type) => {
    let newId = "NEW_" + type + Math.ceil(Math.random() * 10000)
    this.props.toggleSection("ACAD-" + newId)
    this.props.changeAcademics({ academic_type: type }, false, newId);
    this.el = e.target.closest(".student__profile__container")
    setTimeout(() => {
      if (this.el)
        this.el.scrollIntoView({ behavior: "smooth", block: "start" })
    }, 300)
  }
  render() {
    const { academic_detail, cv_point, editableSection } = this.props;
    this.details = this.filterAcademicDetails(academic_detail);
    this.cvPoints = filterCVPoints(cv_point, "ACADEMIC");
    return (
      <Aux>
        <div className="r-card">
          <div className={"student__profile__section" + (this.details["X"][0] && this.details["X"][0].freeze_status == 1 ? " student__profile--freeze" : "")}>
            <h2 className="r-heading-4 r-text--blue-3">Xth</h2>
            {!this.details["X"][0] ?
              <Aux>
                <div className="student__profile__actions">
                  <Button onClick={(e) => this.addAcademic(e, "X")} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
                </div>
                <div className="student__profile__section student__profile__section--placeholder">
                  <span className="r-subheading-2">
                    <p className="mb-0">School Name</p>
                  </span>
                  <Row className="justify-content-between mb-2">
                    <Col xs={7}>
                      <p className="r-subheading-3 mt-2">Subjects</p>
                      <span className="r-text--grey-4">Start Year - End Year</span>
                    </Col>
                    <Col xs={5} className="text-right">
                      <Row noGutters>

                        <Col xs={8} className="px-0 text-center">
                          <span>-</span>
                          <p className="r-heading-7 r-text--blue-3">Highlights/Remarks</p>
                        </Col>
                        <Col xs={4} className="px-3">
                          <span>X.XX</span>
                          <p className="r-heading-7 r-text--blue-3">CGPA/%</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Aux> :
              this.renderDetails(this.details["X"][0])}
          </div>
          <div className={"student__profile__section" + (this.details["XII"][0] && this.details["XII"][0].freeze_status == 1 ? " student__profile--freeze" : "")}>
            <h2 className="r-heading-4 r-text--blue-3">XIIth</h2>
            {!this.details["XII"][0] ?
              <Aux>
                <div className="student__profile__actions">
                  <Button onClick={(e) => this.addAcademic(e, "XII")} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
                </div>
                <div className="student__profile__section student__profile__section--placeholder">
                  <span className="r-subheading-2">
                    <p className="mb-0">School Name</p>
                  </span>
                  <Row className="justify-content-between mb-2">
                    <Col xs={7}>
                      <p className="r-subheading-3 mt-2">Subjects</p>
                      <span className="r-text--grey-4">Start Year - End Year</span>
                    </Col>
                    <Col xs={5} className="text-right">
                      <Row noGutters>

                        <Col xs={8}className="px-0 text-center">
                          <span>-</span>
                          <p className="r-heading-7 r-text--blue-3">Highlights/Remarks</p>
                        </Col>
                        <Col xs={4} className="px-3">
                          <span>X.XX</span>
                          <p className="r-heading-7 r-text--blue-3">CGPA/%</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Aux> :
              this.renderDetails(this.details["XII"][0])}
          </div>
          <div className="student__profile__container">
            <Row noGutters className="">
              <Col xs={12}>
                <h2 className="r-heading-4 r-text--blue-3">Graduation</h2>
                {editableSection === "" && <div className="student__profile__actions">
                  <Button onClick={(e) => this.addAcademic(e, "GRADUATION")} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
                </div>}
              </Col>
            </Row>
            {
              this.details["GRADUATION"].length > 0 ? this.details["GRADUATION"].map(acad => {
                return (
                  <div key={acad.academic_id} className={"student__profile__section" + (acad.freeze_status == 1 ? " student__profile--freeze" : "")}>
                    {this.renderDetails(acad)}
                  </div>
                )
              }) :

                <div className="student__profile__section student__profile__section--placeholder">
                  <span className="r-subheading-2">
                    <p className="mb-0">College Name</p>
                  </span>
                  <span className="r-subheading-6 r-text--sm mb-2 r-text--grey-5">
                    University Name
                </span>
                  <Row className="justify-content-between mb-2">
                    <Col xs={7}>
                      <p className="r-subheading-3 mt-2">Degree Name, Department name here </p>
                      <span className="r-text--grey-4">Start Year - End Year</span>

                    </Col>
                    <Col xs={5} className="text-right">
                      <Row noGutters>

                        <Col xs={8} className="px-0 text-center">
                          <span>-</span>
                          <p className="r-heading-7 r-text--blue-3">Highlights/Remarks</p>
                        </Col>
                        <Col xs={4} className="px-3">
                          <span>X.XX</span>
                          <p className="r-heading-7 r-text--blue-3">CGPA/%</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
            }
          </div>
          <div className="student__profile__container">
            <Row noGutters className="">
              <Col xs={12}>
                <h2 className="r-heading-4 r-text--blue-3">Post Graduation</h2>
                {editableSection === "" && <div className="student__profile__actions">
                  <Button onClick={(e) => this.addAcademic(e, "POST_GRADUATION")} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
                </div>}
              </Col>
            </Row>
            {
              this.details["POST_GRADUATION"].length > 0 ? this.details["POST_GRADUATION"].map(acad => {
                return (
                  <div key={acad.academic_id} className={"student__profile__section" + (acad.freeze_status == 1 ? " student__profile--freeze" : "")}>
                    {this.renderDetails(acad)}
                  </div>
                )
              }) :
                <div className="student__profile__section student__profile__section--placeholder">
                  <span className="r-subheading-2">
                    <p className="mb-0">College Name</p>
                  </span>
                  <span className="r-subheading-6 r-text--sm mb-2 r-text--grey-5">
                    University Name
              </span>
                  <Row className="justify-content-between mb-2">
                    <Col xs={7}>
                      <p className="r-subheading-3 mt-2">Degree Name, Department name here </p>
                      <span className="r-text--grey-4">Start Year - End Year</span>

                    </Col>
                    <Col xs={5} className="text-right">
                      <Row noGutters>

                        <Col xs={8} className="px-0 text-center">
                          <span>-</span>
                          <p className="r-heading-7 r-text--blue-3">Highlights/Remarks</p>
                        </Col>
                        <Col xs={4} className="px-3">
                          <span>X.XX</span>
                          <p className="r-heading-7 r-text--blue-3">CGPA/%</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
            }
          </div>
        </div>
        <Modal className="modal--delete" isOpen={this.state.modalShow} toggle={() => this.toggleModal()}>
          <ModalBody>
            <p>Are you sure?</p>
            <div className="text-right">
              <Button className="r-text--sm" onClick={() => this.toggleModal()}>Cancel</Button>
              <Button color="dark" onClick={this.deleteDetail} className="r-text--sm r-btn--no-radius">Delete</Button>
            </div>
          </ModalBody>
        </Modal>

        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
      </Aux>
    )
  }
}
