import React, { Component } from 'react';
import { MultiSelectList, Select } from '../../global/Forms/Select'
import { Row, Col, Container, Table, Modal, ModalBody, Collapse } from 'reactstrap';
import SpocNav from './SpocNav';
import companyAll from './data/companyALL.json'
import { Auth } from '../../../actions/Auth';
import Axios from 'axios';
import moment from 'moment';
import store from '../../../store';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import { Loader } from '../../global/Loader';
import { CONSTANTS } from '../../../constants';
import { getFullName } from '../../../actions';
import Button from '../../global/Button';
import { PermissionDenied } from '../../global/PermissionDenied';

class PTPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dept: "all",
      columns: [{ id: "sno", title: "S. No." }, { id: "name", title: "Name" }, { id: "tracking", title: "Tracking" }, { id: "contacted", title: "Contacted" }, { id: "interested", title: "Interested" }, { id: "confirmed", title: "Confirmed" }, { id: "application", title: "Total Companies - Applications open" }],
      nav: { process_name: "PT Performance", process_type: "pt", process_id: 1 },
      meetingColumns:[{ id: "sno", title: "S. No." }, { id: "name", title: "Name" },{id:'meetingCOunt', title:'Meeting Count'}],
      processList: [],
      ptList: [],
      isLoading: false,
      totalTracking:0,
      totalContacted:0,
      totalInterested:0,
      totalConfirmed:0,
      collegeList:[],
      start : new Date(),
      filterDate:false,
      end: new Date,
      campusList:[],
      selectedCollege:'',
      nameStyle:(store.getState().role.role_type === 'PT')?'tablestudent__studentstatus':'',
      selectedCampus:'',
      selectedProcess:[],
      totalOpenApplication:0,
      companyModal: [],
      isOpenDateFilter : {}
    };
  }


  async componentWillMount() {
    let permissions = store.getState().role.hasPermission;
    if (store.getState().role.role_type === "PT" && !(permissions.reportTeam && permissions.reportTeam.permission_access && permissions.reportTeam.permission_access.read)) {
      this.props.history.push("/pt/performance/student")
        return
    }
    this.init()
  }

  instiDetail = async () =>{
      let campusResTemp, degreeList;
      await Axios.all([Auth.Spoc.getInstitutes()])
      .then(Axios.spread((res1, res2) => {
        campusResTemp = res1;
      }));
      let detailsParams = new URLSearchParams();
      campusResTemp.data.forEach((item, i)=>{
        detailsParams.append("college_id", item.college_id);
        detailsParams.append("campus_id", item.campus_id);
      })
      let details = await Auth.Spoc.getInstituteDetails(detailsParams);
      this.setState({
        campusList:details.data.campusDetails,
        collegeList:details.data.collegeDetails,
        selectedCollege:details.data.campusDetails[0].college_id,
      })
  }

  init = async () => {
    this.setState({ isLoading: true })
    let redux = store.getState(),
      { instituteDetail } = redux.role;
    let params = {}
    if (instituteDetail.campus_id) {
      try {
        let processResp = await Auth.Processes.getProcesses(instituteDetail.campus_id)
        this.setState({
          selectedCampus:instituteDetail.campus_id,
          processList: processResp.data
        })
        if (processResp.data.length > 0) {
          params.process_id = processResp.data[0].process_id
          let ptResp = await Auth.Spoc.getUsers({ role_type: CONSTANTS.ROLE_TYPES_ID.PT, campus_id: instituteDetail.campus_id })
          this.setState({ ptList: ptResp.data })
          await this.getPTData(params.process_id || 0)
        }
      } catch (e) { console.log(e) }
    } else {
        this.instiDetail();
    }
    this.setState({ isLoading: false })
  }

  selectProcess =  (e) => {
    let {selectedProcess} = this.state;
    let value = parseInt(e.target.value);
    if(e.target.checked){
      selectedProcess.push(value);
    }
    else{
      if(selectedProcess.length === 1) return;
      selectedProcess.splice(selectedProcess.indexOf(value), 1);
    }
    this.getPTData(selectedProcess);
  }

  getPTData = async (process_id, filter = {}) => {
    this.setState({ 
      totalTracking:0,
      totalContacted:0,
      totalInterested:0,
      totalConfirmed:0,
      totalOpenApplication:0,
      selectedProcess: Array.isArray(process_id) ? process_id : [process_id],
       })
    if (!process_id) {
      this.setState({ptList: []})
      return;
    }
    this.setState({isLoading: true});
    let compResp = await Auth.Processes.getCompaniesInProcess(process_id, filter)
    let applicationResp = await Auth.Processes.getApplicationsInProcess(process_id, null, null, filter)
    let companies = compResp.data.map(comp => {
      let applications = applicationResp.data.filter(apl => apl.process_company_id === comp.process_company_id);
      comp.applications = applications
      return comp
    })
    let { ptList, totalTracking, totalContacted,totalInterested,totalConfirmed,totalOpenApplication } = this.state;
    ptList = ptList.map((pt, i) => {
      let status = { "tracking": [], "contacted": [], "interested": [], "confirmed": [], "opened": [] }
      companies.forEach(company => {
        if (company.user_id === pt.user_id) {
          if (company.company_status >= 0) status.tracking.push(company)
          if ((company.company_status >= 1) && (company.company_status !== 2) && (company.company_status !== 4)) status.contacted.push(company)
          if ((company.company_status >= 3) && (company.company_status !== 4)) status.interested.push(company)
          if (company.company_status >= 5) status.confirmed.push(company)
          if (company.applications.length > 0) status.opened.push(company)
        }
      })
      let temp = {
        sno: i + 1,
        name: getFullName(pt.userDetail),
        ...status
      }
      totalTracking += status.tracking.length;
      totalContacted += status.contacted.length;
      totalInterested += status.interested.length;
      totalConfirmed += status.confirmed.length;
      totalOpenApplication+= status.opened.length;
      return { ...pt, ...temp }
    })
    this.setState({ isLoading: false, ptList,totalTracking, totalContacted,totalInterested,totalConfirmed,totalOpenApplication  })

  }

  changeCollege = (target) => {
    this.setState({selectedCollege: target.value, selectedCampus:"", selectedProcess:[],ptList: [],processList: []});
  }

  changeCampus = async (target) =>{
    let {ptList} = this.state;
    this.setState({selectedCampus: target.value, selectedProcess:[]});
    if(target.value === ""){
      this.setState({ptList: [],processList: []})
      return }
    this.setState({isLoading: true});
      try {
        let processResp = await Auth.Processes.getProcesses(target.value)
        this.setState({
          processList: processResp.data
        })
        if (processResp.data.length > 0) {
          let ptResp = await Auth.Spoc.getUsers({ role_type: CONSTANTS.ROLE_TYPES_ID.PT, campus_id: target.value})
          ptList = ptResp.data ;
          this.setState({ptList});
          await this.getPTData(processResp.data[0].process_id)
        }
        else{
            this.setState({ptList: []})
        }
      } catch (e) { console.log(e) }
    this.setState({isLoading: false});
  }


  changeEnd = (end) => {
    this.setState({
      end: moment(end).format('YYYY-MM-DD')
    })
  }

  changeStart = (start) => {
    this.setState({
      start: moment(start).format('YYYY-MM-DD')
    })
  }

  redirecttoPlacment = (e, row) =>{
    if(store.getState().role.role_type === 'PT'){
      let url = `/pt/processes/${this.state.selectedProcess}/detail`
        this.props.history.push({
          pathname: url,
          state: { query:row.name }
        })
    }
  }

  downloadFile = async() => {
    this.setState({isLoading: true});
       let body = {
                      "download": true,
                      "size":10000,
                      "campus_id":this.state.selectedCampus,
                      "must": {
                          "process_id": this.state.selectedProcess,
                          "is_active": true
                      },
                      "aggregationParams": [
                          {
                              "aggregateOn": "user_id",
                              "aggregate": [
                                  {
                                      "aggregateOn": "campus_company_id",
                                      "aggregate": [
                                          {
                                              "aggregateOn": "company_status"
                                          }
                                      ]
                                  }
                              ]
                          }
                      ]
                  }
      try {
        let res = await Auth.Reports.teamreportDownload(body);
        const url = window.URL.createObjectURL(new Blob([res.data])); 
        const link = document.createElement('a');
        link.href = url;
        let fileName = 'Team Report'+'.csv';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({isLoading: false});
      } catch (e) { 
        this.setState({isLoading: false});
        console.log(e) 
      }
  }
  filterByDate = () => {
    let { selectedProcess, isOpenDateFilter } = this.state;
    isOpenDateFilter.is_applied = true;
    isOpenDateFilter.is_open = false;
    this.setState({isOpenDateFilter});
    this.getPTData(selectedProcess, {start_date : moment(isOpenDateFilter.start_date).toDate(), end_date : moment(isOpenDateFilter.end_date).toDate()});
  }
  clearDateFilter = () => {
    let { selectedProcess } = this.state;
    this.setState({isOpenDateFilter : {}});
    this.getPTData(selectedProcess);
  }
  render() {
    
    let { nav, isLoading,collegeList,filterDate, campusList, processList,selectedCampus,selectedCollege,selectedProcess, columns,meetingColumns, ptList, companyModal, totalTracking, totalContacted,totalInterested,totalConfirmed,totalOpenApplication, isOpenDateFilter } = this.state;
    let campusDataList = campusList.filter(campus =>{
      return campus.college_id == selectedCollege;
    });
    return (
      <Container className="apply">
        <Loader show={isLoading} />
        <SpocNav process={nav} downloadReport={this.downloadFile} type="TeamReport"/>
          <Row className="my-4">
            <Col xs={2}  className="mx-4">
              <div className="card bg-info text-white p-4">
                <h1>{totalTracking}</h1>
              </div>
              <p> Total Tracking</p>
            </Col>
            <Col xs={2}  className="mx-4" >
              <div className="card bg-success text-white p-4">
                <h1>{totalContacted}</h1>
              </div>
              <p>Total Contacted  </p>
            </Col>
            <Col xs={2}  className="mx-4">
              <div className="card bg-success text-white p-4">
                <h1>{totalInterested}</h1>
              </div>
              <p>Total Interested</p>
            </Col>
            <Col xs={2}  className="mx-4">
              <div className="card bg-secondary text-white p-4">
                <h1>{totalConfirmed}</h1>
              </div>
              <p>Total Confirmed</p>
            </Col>
            <Col xs={2}  className="mx-4" >
              <div className="card bg-secondary text-white p-4">
                <h1>{totalOpenApplication}</h1>
              </div>
              <p>Total Applications Open</p>
            </Col>
          </Row>

        <Row className="mx-0 my-3">
        {collegeList.length > 0 &&
            <Col xs="3">
              <Select onChange={({ target }) => this.changeCollege(target)}>
                {collegeList.map((college, i) =>
                  <option key={i} value={college.college_id}>{college.name}</option>
                )}
              </Select>
            </Col>
        }
        {campusDataList.length > 0 &&
            <Col xs="3">
              <Select value={selectedCampus} onChange={({ target }) => this.changeCampus(target)}>
                  <option value="">Select Campus</option>
                  {campusDataList.map((campus, i) =>
                    <option key={i} value={campus.campus_id}>{campus.name}</option>
                  )}
              </Select>
            </Col>
        }
        {processList.length > 0 &&
            <Col xs="3">
              {/* <Select value={selectedProcess} onChange={({ target }) => this.getPTData(target.value)}>
                {processList.map((process, i) =>
                  <option key={i} value={process.process_id}>{process.process_name}</option>
                )}
              </Select> */}
              <MultiSelectList title={selectedProcess.length ? `${selectedProcess.length} Placement Drive Selected`: "Select Placement Drive"}>
                <ul className="list-unstyled m-0">
                  {processList.map((process, i) => {
                    return (
                      <li key={i}>
                        <input name="value" onChange={(e) => this.selectProcess(e)} checked={selectedProcess.indexOf(process.process_id) >= 0}  type="checkbox" value={process.process_id} />
                        <label>{process.process_name}</label>
                      </li>
                    )
                  })}
                </ul>
              </MultiSelectList>
            </Col>
        }
        <Col>
         <em className="r-text--sm text-muted">Selected : {processList.filter(process => selectedProcess.indexOf(process.process_id) >= 0).map(process => process.process_name).join("  ,  ")}</em> 
        </Col>
        <Col xs={1} className="align-self-end text-right">
          <Button onClick={() => {isOpenDateFilter.is_open = !isOpenDateFilter.is_open; this.setState({isOpenDateFilter})}} className="font-weight-normal px-2 py-0" color="blue3"><img style={{ width: "21px" }} src="/images/filter-512.png" alt="" /></Button>
        </Col>
        </Row>
        { isOpenDateFilter && isOpenDateFilter.is_applied && <p className="text-right "><em className="text-muted r-text--sm">Data showing for {moment(isOpenDateFilter.start_date).format("Do MMM YYYY")} - {moment(isOpenDateFilter.end_date).format("Do MMM YYYY")}</em></p>}
        <Collapse isOpen={this.state.isOpenDateFilter.is_open}>
        <Row className="justify-content-end align-items-end">
          <Col xs={3}>
            <span className="r-subheading-4">From Date</span>
              <SingleDatePicker
                value={isOpenDateFilter.start_date}
                onDateChange={(date) => { isOpenDateFilter.start_date = date; this.setState({ isOpenDateFilter }) }}
              />
          </Col>
          <Col xs={3}>
              <span className="r-subheading-4">To Date</span>
              <SingleDatePicker
                value={isOpenDateFilter.end_date}
                onDateChange={(date) => { isOpenDateFilter.end_date = date; this.setState({ isOpenDateFilter }) }}
              />
          </Col>
          <Col xs={1}>
            {isOpenDateFilter.is_applied ? 
            <Button onClick={this.clearDateFilter} color="dark" square={true}>Clear</Button>
            :
            <Button onClick={this.filterByDate} color="dark" square={true}>Filter</Button>
            }
            </Col>
        </Row>
        </Collapse>
        <div className="align-items-center mb-5 mx-0">
          <span className="r-heading-3">Company Interaction</span>
          {!isLoading && <Row className="align-items-center my-5" style={{ overflowX: 'auto', overflowY: 'hidden', }}>
            <Table className="post">
              <thead>
                <tr className="tableheader">
                  {columns.map((col, i) =>
                    <th style={{ textAlign: "center" }} key={i}>
                      {col.title}
                    </th>

                  )}
                </tr>
              </thead>
              <tbody>
                { (ptList.length>0 )&&
                  ptList.map((row) => {
                    return (
                      <tr
                        className="borderBottomlightblack"
                        key={row.sno}>
                        <td style={{ textAlign: "center" }}>
                          {row.sno}
                        </td>
                        <td style={{ textAlign: "center" }} className={this.state.nameStyle} onClick={(e) => this.redirecttoPlacment(e,row)}>
                          {row.name || "-"}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button onClick={()=>this.setState({companyModal: row.tracking})}>{row.tracking.length}</Button>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button onClick={()=>this.setState({companyModal: row.contacted})}>{row.contacted.length}</Button>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button onClick={()=>this.setState({companyModal: row.interested})}>{row.interested.length}</Button>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button onClick={()=>this.setState({companyModal: row.confirmed})}>{row.confirmed.length}</Button>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button onClick={()=>this.setState({companyModal: row.opened})}>{row.opened.length}</Button>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
            <Modal zIndex="99999" isOpen={companyModal.length > 0} toggle={() => this.setState({ companyModal: [] })}>
            <ModalBody>
              <Table>
                <thead>
                  <tr className="tableheader">
                    <th>Company Name</th>
                  </tr>
                </thead>
                <tbody>
                  {companyModal.map(company => {
                    return <tr
                      className="borderBottomlightblack"
                      key={company.campus_company_id}>
                      <td>{company.company_name}</td>
                    </tr>
                  })}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
          </Row>}
        </div>
        {/* <div className="align-items-center my-5">
              <Row  className="justify-content-between align-items-center mt-5">
                <Col xs="10">
                     <span className="r-heading-3">Meetings Performance</span>
                </Col>
              </Row>
              <Row className=" my-5">
              {
                meetingCard.map((data,i) =>{
                  return(
                  <Col xs="3" className="text-center" key = {i}>
                    <Card className='r-bg--skylight text-center r-text--light'>
                      <span className="r-heading-2 px-2">{data.value}</span>
                    </Card>
                      <span style={{fontSize: "12px"}}>{data.title}</span>
                  </Col>
                    )
                })
              }
              </Row>
             <Row className="align-items-center my-5" style={{overflowX: 'auto', overflowY: 'hidden',}}>
                <TableComp tableData={meetingData}  columns={this.state.columnsptperform}/>
            </Row>
          </div> */}
      </Container>
    )
  }
}

export default PTPerformance;
