import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Progress from 'reactstrap/lib/Progress';
import Modal from 'reactstrap/lib/Modal';
import { Auth } from '../../../../../actions/Auth';

import { PersonalDetails } from './personal-details';
import Button from '../../../../global/Button';
import { CVPreview } from '../../../cv-builder/cv-preview';
import { InputField } from '../../../../global/Forms';
import { HorizontalLine } from '../../../../global/horizontal_line';
import { CONSTANTS } from '../../../../../constants';
import { Academics } from './academics';
import { Loader } from '../../../../global/Loader';
import { ProfessionalExperience } from './professional-exp';
import { Projects } from './projects';
import { Achievements } from './achievements';
import { OtherExperience } from './other-exp';
import { Skills } from './skills';
import { POR } from './por';
import { ExtraCurricular } from './extra-curricular';
import FreezeProfile from './FreezeProfile.js';
import { getQueryParams } from '../../../../../actions';
import store from '../../../../../store';

export class BuildProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideNavLeft: null,
      sideNavRight: null,
      cvPreview: false,
      editableSection: "",
      currentTab: 1,
      originalData: null,
      commentThreads: [],
      loading: false,
      backlogData: null
    }
  }

  toggleLoading = loading => {
    this.setState({
      loading
    })
  }

  componentDidMount() {
    this.getCommentThreads();
    this.backlogCount();
    var fixedSideNavStyle = {
      left: "",
      width: ""
    }
    var leftSideNavCol = document.querySelector(".left-sidenav").getBoundingClientRect();
    fixedSideNavStyle = {
      left: leftSideNavCol.left,
      width: leftSideNavCol.width,
      minWidth: leftSideNavCol.width <= 145 ? 250: 150,
      zIndex: 2,
      display : leftSideNavCol.width <= 145 ? "none" : "block",
      _isShowMenu : leftSideNavCol.width <= 145
    }
    this.setState({
      sideNavLeft: fixedSideNavStyle
    })
    var rightSideNavCol = document.querySelector(".right-sidenav").getBoundingClientRect();
    fixedSideNavStyle = {
      left: rightSideNavCol.left,
      width: rightSideNavCol.width
    }
    this.setState({
      sideNavRight: fixedSideNavStyle
    })
  }



  getCommentThreads = async () => {
    var studentId = this.props.studentDetails.user_info.student_id;
    try {
      let resp = await Auth.CommentBox.getCommentThread(studentId);
      this.setState({ commentThreads: resp.data })
    } catch (e) {
      console.log(e)
    }
  }

  backlogCount = async () => {
    var studentId = this.props.studentDetails.user_info.student_id;
    try {
      let resp = await Auth.Student.getBacklogCount(studentId);
      this.setState({backlogData: resp.data[0]})
    } catch (e) {
      console.log(e)
    }
  }

  onCancel = () => {
    this.toggleSection("");
    let { originalData } = this.state;
    if (originalData) {
      originalData.personal_info.hobbies = this.props.studentDetails.personal_info.hobbies;
      this.props.updateStudentDetails(originalData);
      this.setState({
        originalData: null
      })
    }
  }

  toggleSection = section => {
    let { editableSection, originalData } = this.state;
    if (section && editableSection !== section) {
      originalData = JSON.parse(JSON.stringify(this.props.studentDetails))
    }
    this.setState({
      editableSection: editableSection !== section ? section : "",
      originalData
    })
    return false;
  }

  toggleCV = () => {
    if (!this.state.cvPreview)
      this.props.generateCVConfig()
    this.setState({
      cvPreview: !this.state.cvPreview
    })
  }

  requiredValidation = fields => {
    let validFields = fields.filter(field => {
      if (Array.isArray(field)) {
        if (field.length > 0) {
          return true
        }
      } else {
        if (field) {
          return true
        }
      }
      return false
    })
    return validFields.length > 0 ? true : false
  }

  changeTab = (e) => {
    let tab = e.target.dataset.tabid;
    let baseUrl = this.props.isPT ? "/pt/students/recruitments/" + this.props.match.params.student_id + "/profile/" : "/student/recruitments/profile/"
    this.props.history.push(baseUrl + tab + window.location.search)
    this.setState({
      editableSection: ""
    })
    window.scrollTo(0, 0)
  }

  getStudentProfile = async () => {
    this.setState({ originalData: null })
    await this.props.getStudentProfile()
  }

  redirectToStudent = async(user_info) => {
    let {history} = this.props,
    {role, user} = store.getState(),
    campusId = role.instituteDetail.campus_id,
    {process_id} = getQueryParams(window.location.search),
    verifierRole = user.roles.filter(r=>(r.role_type==="VERIFIER" && r.instituteDetail.campus_id===campusId))
    if(verifierRole.length>0 && process_id) {
      let el = document.getElementById(`role-${verifierRole[0].assigned_role_id}`)
      if(el) {
        await el.click()
        history.push(`/verifier/verification/${user_info.student_id}/${process_id}/studentDetails`)
      }
    }
  }

  render() {
    let { studentDetails, studentCVDetails, settings, config, roleList, domainList, boardList, degreeList, industryList, sectorList, tab } = this.props,
      { cvPreview, editableSection, currentTab, loading, sideNavLeft, sideNavRight, commentThreads } = this.state,
      { files, cv_point, extra_curricular_activity, position_of_responsibility, personal_info, user_info, preferences, academic_detail, professional_detail, project, achievement, other_experience, skill } = studentDetails,
      sections = [],
      profileProps = {
        getStudentProfile: this.getStudentProfile,
        editableSection,
        files,
        commentThreads,
        toggleLoading: this.toggleLoading,
        toggleSection: this.toggleSection,
        requiredValidation: this.requiredValidation,
        changeCVPoint: this.props.changeCVPoint,
        onCancel: this.onCancel,
        cv_point,
        user_info
      },
      progress = studentDetails.profile_metric.profile_completion_percentage;
    if (config) {
      sections = Object.keys(config.section_order).sort().map(order => {
        return config.section_order[order]
      })
    }
    let hasPermission = this.props.role.hasPermission;
    let allowPTEdit = hasPermission.editStudent && hasPermission.editStudent && hasPermission.editStudent.permission_access && hasPermission.editStudent.permission_access.read === 1 ? true : false;
    // if(settings.STUDENT && settings.STUDENT.PROFILE) 
    //   if(settings.STUDENT.PROFILE.allow_pt_edit===1)
    var studentInfo = studentDetails.student_info[0] || {};
    return (
      <Container>
        <Row>
          <Col xs={3} className="left-sidenav">
            <div className="r-sidenav r-sidenav-fixed" style={{...sideNavLeft}} id="sidenav" >
              <div className="r-sidenav__header">
                <Row className="align-items-center">
                  <Col><h1 className="r-heading-4 d-inline-block mb-0">Build Profile</h1></Col>
                  {(settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id] && settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id].cv_builder===1)&&<Col className="pb-2 pt-1"><Button onClick={this.toggleCV} color="grey r-text--light r-text--sm float-right r-bg--grey-4"><i className="mr-3" className="fi flaticon-portfolio"></i>Resume Preview</Button></Col>}
                </Row>
                <div className="d-flex r-text--dark r-subheading-4 mt-3">
                  <span>{progress}% Complete</span>
                </div>
                <Progress value={progress} color={progress < 40 ? "danger" : (progress < 80 ? "warning" : "success")} className="r-progress" />
              </div>
              <ul className="r-sidenav__menu">
                {
                  CONSTANTS.SIDENAV.map(nav => {
                    return (
                      <li key={nav.id} onClick={this.changeTab} data-tabid={nav.id} className={"r-heading-6 " + (tab === nav.id ? "sidenav-active" : "")}>
                        {nav.text}
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </Col>
          <Col xs={12} md={7}>
            <Loader show={loading} />
            {!!sideNavLeft && sideNavLeft._isShowMenu && 
            <div onClick={() => {sideNavLeft.display = sideNavLeft.display === "none" ? "block" : "none"; this.setState({sideNavLeft})}} style={{width: 18, right: 30, top: 15, zIndex: 99}} className="position-absolute selectable">
              <hr className="mt-0" style={{marginBottom: 5, borderColor: "#333"}} />
              <hr className="mt-0" style={{marginBottom: 5, borderColor: "#333"}} />
              <hr className="mt-0" style={{marginBottom: 5, borderColor: "#333"}} />
            </div>
            }
            <div className={'student__profile' + ((user_info.freeze_status || (this.props.isPT && !allowPTEdit)) ? ' student__profile--noedit' : ' ')} >

              {tab === "personal" && <PersonalDetails
                {...profileProps}
                changeProfile={this.props.changeProfile}
                personal_info={personal_info}
                preferences={preferences}
                toggleCV={this.toggleCV}
                changePreferences={this.props.changePreferences}
                roleList={roleList}
                domainList={domainList}
                settings={settings} />}
              {
                tab === "academic" && <Academics
                  {...profileProps}
                  changeAcademics={this.props.changeAcademics}
                  toggleCV={this.toggleCV}
                  academic_detail={academic_detail}
                  boardList={boardList}
                  settings={settings}
                  degreeList={degreeList} />
              }
              {
                tab === "responsibility" && <POR
                  {...profileProps}
                  changePorDetail={this.props.changePorDetail}
                  toggleCV={this.toggleCV}
                  settings={settings}
                  position_of_responsibility={position_of_responsibility} />
              }
              {
                tab === "professional" && <ProfessionalExperience
                  {...profileProps}
                  changeProDetail={this.props.changeProDetail}
                  toggleCV={this.toggleCV}
                  professional_detail={professional_detail}
                  sectorList={sectorList}
                  settings={settings}
                  industryList={industryList} />
              }
              {
                tab === "project" && <Projects
                  {...profileProps}
                  changeProjectDetail={this.props.changeProjectDetail}
                  toggleCV={this.toggleCV}
                  project={project}
                  sectorList={sectorList}
                  settings={settings}
                  industryList={industryList} />
              }
              {
                tab === "extracurricular" && <ExtraCurricular
                  {...profileProps}
                  extra_curricular_activity={extra_curricular_activity}
                  toggleCV={this.toggleCV}
                  settings={settings}
                  changeExtraCurricular={this.props.changeExtraCurricular} />
              }
              {
                tab === "achievement" && <Achievements
                  {...profileProps}
                  toggleCV={this.toggleCV}
                  settings={settings}
                  changeAchievement={this.props.changeAchievement}
                  achievement={achievement} />
              }
              {
                tab === "other" && <OtherExperience
                  {...profileProps}
                  toggleCV={this.toggleCV}
                  settings={settings}
                  changeOtherExp={this.props.changeOtherExp}
                  other_experience={other_experience} />
              }
              {
                tab === "skill" && <Skills
                  {...profileProps}
                  toggleCV={this.toggleCV}
                  settings={settings}
                  changeSkill={this.props.changeSkill}
                  skill={skill} />
              }
            </div>
            <aside className="student__cv">
              <Modal modalClassName="student__cv__preview" isOpen={cvPreview} toggle={this.toggleCV}>
                <Button className="fi flaticon-plus-1 p-3 r-text--lg" style={{position:"absolute",zIndex:100}} onClick={this.toggleCV}></Button>
                <CVPreview showUnverified={true} preview={true} sections={sections} data={studentCVDetails} config={config} />
              </Modal>
            </aside>
          </Col>
          <Col xs={2} className="right-sidenav px-0">
            <div className="r-sidenav-fixed" style={{ ...sideNavRight }} >
              <Row className="justify-content-between align-items-center px-0 py-2">
                <Col xs="auto" >
                  <span className="r-heading-4 d-inline-block mb-0  r-text--blue-3 selectable" onClick={()=>this.redirectToStudent(user_info)}> Verification</span>
                </Col>
                <Col xs="12" className="mt-2">
                  <span>{studentDetails.profile_metric.unverified_point + studentDetails.profile_metric.verified_point + studentDetails.profile_metric.rejected_point} Points</span>
                </Col>
              </Row>
              <HorizontalLine color="#cfcfcf" />
              <Row noGutters className="text-center px-0 py-3">
                <Col xs="12" md="4" style={{ fontSize: "12px" }}>
                  <Row className="justify-content-center align-items-center">
                    <span className="mr-1">{studentDetails.profile_metric.unverified_point}</span>
                    <div style={{ width: "10px", height: "10px", borderRadius: "5px", textAlign: "center", backgroundColor: "#fc3" }} />
                  </Row>
                  <span>Pending</span>
                </Col>
                <Col xs="12" md="4" style={{ fontSize: "12px" }}>
                  <Row className="justify-content-center align-items-center">
                    <span className="mr-1">{studentDetails.profile_metric.verified_point}</span>
                    <div style={{ width: "10px", height: "10px", borderRadius: "5px", textAlign: "center", backgroundColor: "green" }} />
                  </Row>
                  <span>Approved</span>
                </Col>
                <Col xs="12" md="4" style={{ fontSize: "12px" }}>
                  <Row className="justify-content-center align-items-center">
                    <span className="mr-1">{studentDetails.profile_metric.rejected_point}</span>
                    <div style={{ width: "10px", height: "10px", borderRadius: "5px", textAlign: "center", backgroundColor: "red" }} />
                  </Row>
                  <span>Rejected</span>
                </Col>
              </Row>
              {(studentDetails.user_info.freeze_status===0)?<FreezeProfile studentDetails={studentDetails} />:
              <Col xs={12} className="r-bg--brandB rounded">
                <span className="mr-1">Your Profile is freezed</span>
              </Col>}


              {!!this.state.backlogData &&
              <>
              <Row>
                <Col xs="12" className="mt-3 mb-2">
                <span className="r-heading-4 d-inline-block mb-0  r-text--blue-3 " > Backlogs</span>
                </Col>
              </Row>
              <HorizontalLine color="#cfcfcf" />
              <Row noGutters className="text-center px-0 py-3">
                  <Col xs="12" md="4" style={{ fontSize: "12px" }}>
                    <Row className="justify-content-center align-items-center">
                      <span className="mr-1">{this.state.backlogData && this.state.backlogData.backlog_count ? this.state.backlogData.backlog_count : 0}</span>
                    </Row>
                    <span>Total</span>
                  </Col>
                  <Col xs="12" md="4" style={{ fontSize: "12px" }}>
                    <Row className="justify-content-center align-items-center">
                      <span className="mr-1">{this.state.backlogData && this.state.backlogData.current_backlog_count ? this.state.backlogData.current_backlog_count : 0}</span>
                    </Row>
                    <span>Current</span>
                  </Col>
              </Row>
              </>
              }
            </div>
            {/* <div className="student__documents card r-sidenav-fixed" style={{ ...sideNavRight }} >

              <div className="card-header pt-4 r-bg--light">
                <h1 className="r-subheading-2 r-text--blue-3 float-left mt-2">Documents</h1>
                <div className="student__profile__actions mt-4 mr-2">
                  <Button className="r-text--sm student__profile__actions--add float-right fi flaticon-plus" color="brandB"><span >Add</span></Button>
                </div>

              </div>
              <div className="text-middle p-3 pb-0">
                <InputField className="w-100 r-radius--3 pr-5" placeholder="Search" />
                <i style={{ position: 'absolute', marginLeft: '-20px' }} className="pt-2 mt-1  r-text--grey-4 fi flaticon-search "></i>
              </div>
              <div className="card-body p-2 pt-0">
                <ul className="student__documents__list">
                  <li draggable><span>My Document 001</span></li>
                  <li draggable><span>My Document 002</span></li>
                  <li draggable><span>My Document 003</span></li>
                  <li draggable><span>My Document 004</span></li>
                  <li draggable><span>My Document 005</span></li>
                  <li draggable><span>My Document 006</span></li>
                  <li draggable><span>My Document 007</span></li>
                  <li draggable><span>My Document 008</span></li>
                  <li draggable><span>My Document 009</span></li>
                  <li draggable><span>My Document 010</span></li>
                  <li draggable><span>My Document 011</span></li>

                </ul>
              </div>
              <div className="card-footer text-muted p-1 r-bg--light">
                <Button className="r-text--sm" color="link-blue">View in file manager</Button>
                <em className="r-text--sm">Documents are directly saved into your File Manager.</em>
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
    )
  }
}
