import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import DatePicker from 'react-dates/esm/components/SingleDatePicker';
import { Auth } from '../../../../actions/Auth';
import {SelectList} from '../../../global/Forms/Select'
import { Switch } from '../../../global/Forms/ToggleSwitch';
import { setUser, setLoading } from '../../../../actions/action-creators';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import {Row,Col,Container,Collapse,Table,Pagination,Popover, Card, PaginationItem, PaginationLink, InputGroup, InputGroupAddon,  Input} from 'reactstrap';
import FeedPost from '../../../global/post';

 class PTFeedPost extends Component{
  constructor(props){
    super(props);
    this.state = {
      getProcesList:  [],
      getStudentAssigned: [],
      getStudentProfileDetails: [],
      isOn: false, 
      modalstudent: false,
      modalshare:false,
      modal: false,
      modaledit: false,
      collapse: false,
      postcolapse: false,
      popoverOpen: false,
      popoverOpen2: false,
      process:{process_name: "Student Feed",process_type: "feed",process_id: 3},
    };
  }


   toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  }

    handleToggle(e) {
      this.setState({isOn: !this.state.isOn});
    }

    handlepostToggle = (e) => {
        this.setState(state => ({ postcolapse: !state.postcolapse }));
    }

    togglePopOver1 = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

    togglePopOver2 = () => {
    this.setState({
      popoverOpen2: !this.state.popoverOpen2
    });
  }


  // commentedPost () {
    
  //              {<div className="post__body" style={{border:"none"}}>
  //              <Row className="align-items-center">
  //                                 <Col xs="8">
  //                                   <Row className="justify-content-between align-items-center m-0">
  //                                      <Button className="r-btn--no-radius" color="dark">Know more!</Button>
  //                                      <Button className="font-weight-normal px-2 py-0" color="Light"><i className="mr-3 r-text--xl fi flaticon-like" /></Button>
  //                                      <Button className="font-weight-normal px-2 py-0" color="Light"><i className="mr-3 r-text--xl fi flaticon-comment" /></Button>
  //                                      <Button className="font-weight-normal px-2 py-0" color="Light">Share</Button>
  //                                   </Row>
  //                                 </Col>
  //                               </Row>
  //                               <span className="r-heading-4 my-5">Comments</span>
  //                               <Row className="align-items-center p-4">
  //                                   <Col>
  //                                     <div className="post__header">
  //                                       <img style={{height:"50px"}} src="/images/Male_Avatar.png" />
  //                                       <div className="ml-2">
  //                                         <span>Rahul Singh</span>
  //                                         <p className="mb-0 r-text--sm">Hi my comment is this . Please respond to this as soon as possible</p>
  //                                         <p>05:00pm, 12th October 2018</p>
  //                                       </div>
  //                                     </div>
  //                                   </Col>
  //                               </Row>
  //                               <InputField style={{width:"100%"}} />
  //                                <Button className="r-btn--no-radius" color="light">Send & notify</Button>
  //             </div>}
  // }


  render(){
    let {data, files} = this.props;
      return (
               <FeedPost files={files} data={data} style={{border:"none"}} postType=" " />
  )
 }
}

export default PTFeedPost;
