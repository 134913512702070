import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Collapse from 'reactstrap/lib/Collapse';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Aux from '../../../../global/AuxComp';
import Button from '../../../../global/Button';
import Fade from 'reactstrap/lib/Fade';
import moment from 'moment';
import { Auth } from '../../../../../actions/Auth';
import { CONSTANTS } from '../../../../../constants';
import { Errorbar } from '../../../../global/errorbar';
import { InputField } from '../../../../global/Forms';
import { RichTextViewer } from '../../../../global/Forms/RichTextViewer';
import Link from 'react-router-dom/Link';
import { ReactComponent as AttachmentLogo } from '../../../../../assets/icons/attachment.svg';
import { uploadFile, formattedMatrixError } from '../../../../../actions';
import { Select } from '../../../../global/Forms/Select';
import store from '../../../../../store';

export class ApplicationDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      applyModal: false,
      isDetailsOpen: this.props.isDetailsOpen,
      answer_data: [],
      resume_id: null,
      tabId: 1,
      errorbar: false,
      message: '',
      cvError:false,
      multirole: props.multirole,
      preference: null,
      applyError: [],
      shortlistQuestion: false,
      shortlistAnswer: {}
    }
  }

  closebar = () => {
    this.setState({ errorbar: false })
  }


  closeError = () => {
    this.setState({ cvError: false })
  }


  componentWillMount() {
    let { application } = this.props;
    let answer_data = application.application_question.map(ques => {
      return {
        application_question_id: ques.application_question_id,
        answer_content: ques.answer_type === "file" ? null : ""
      }
    });
    this.setState({ answer_data });
  }

  handleAnswerChange = (i, e, file) => {
    let { answer_data } = this.state;
    if (file && file !== true && e.target.files[0].size > (file * 1024)) {
      this.setState({ message: 'File size should be under ' + file + "kb" });
      this.setState({ errorbar: true });
      return false;
    }
    if(e.target.type === "checkbox"){
      var v = answer_data[i].answer_content ? answer_data[i].answer_content.split(",") : [];
      var index = v.indexOf(e.target.value);
      if(index === -1) v.push(e.target.value);
      else v.splice(index, 1);
      answer_data[i].answer_content = v.join(",")
    }
    else answer_data[i].answer_content = file ? e.target.files[0] : e.target.value;
    this.setState({
      answer_data
    })
  }

  handleShortlistAnswerChange = (id, e, file) => {
    let { shortlistAnswer } = this.state;
    if (file && file !== true && e.target.files[0].size > (file * 1024)) {
      this.setState({ message: 'File size should be under ' + file + "kb" });
      this.setState({ errorbar: true });
      return false;
    }
    shortlistAnswer[id] = file ? e.target.files[0] : e.target.value;
    this.setState({
      shortlistAnswer
    })
  }

  removeFile = (i) => {
    let { answer_data } = this.state;
    answer_data[i].answer_content = null;
    this.setState({
      answer_data
    })
  }
  removeShortlistFile = (i) => {
    let { shortlistAnswer } = this.state;
    shortlistAnswer[i] = null;
    this.setState({shortlistAnswer})
  }

  valid = () => {
    let { answer_data, resume_id } = this.state,
    {application} = this.props
    if(!resume_id) {
      this.setState({
        errorbar: true,
        message: "Please select a cv!"
      })
      return false
    }
    
    let mandatory = answer_data.filter((ans, i)=>application.application_question[i].is_mandatory===1 && !ans.answer_content)
    if(mandatory.length>0) {
      this.setState({
        errorbar: true,
        message: "Please answer all mandatory questions."
      })
      return false
    }
    return true
  }

  apply = async (e) => {
    
    e.preventDefault();
    let cvList = this.props.resumes.filter(cv => cv.cv_type !== 'MASTER')
    if (cvList.length < 1) {
        this.setState({ cvError: true });
    }
    let { answer_data, resume_id, preference } = this.state,
      { multirole } = this.props,
      { application_id, student_id, application_binder } = this.props.application;
    if (multirole && application_binder && application_binder[0].resume_restriction) {
      let temp = multirole.filter(apl => apl.resume_id && apl.student_application_status === 2)
      if (temp[0]) {
        resume_id = temp[0].resume_id
      }
    }
    if(!this.valid()) return
    this.props.setParentState({
      isLoading: true
    })
    answer_data = await Promise.all(answer_data.map(async (answer,i) => {
      let ans = {...answer};
      if (ans.answer_content && typeof ans.answer_content === "object") {
        try {
          let file = await uploadFile(ans.answer_content, `student/${student_id}/apply`)
          ans.answer_content = file.file_id.toString()
        } catch (e) { console.log(e) }
      }
      return ans
    }))
    let formData = {
      application_id,
      student_id,
      answer_data,
      resume_id,
      preference
    }
    try {
      await Auth.Student.apply(formData)
      await this.props.fetchApplications(this.props.process)
      this.setState({
        applyModal: false
      })
    } catch (e) {
      if (e.response) {
        if (e.response.data.error.error_type == 'MATRIX_RESTRICTION') {
          var matrixError = formattedMatrixError(e.response.data.error.error_data)
          var formattedError = <ol>
            <li className="mb-2">After your last offer, the following restrictions are applicable</li>
            {matrixError.map((error) => <li>{error}</li>)}
            <li className="mt-2">Hence, you are not able to proceed further</li>
          </ol>
          this.setState({ message: formattedError });
          this.setState({ errorbar: true });
        }
        else {
          this.setState({ message: e.response.data.error.toString() });
          this.setState({ errorbar: true });
        }
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
    this.props.setParentState({
      isLoading: false
    })

  }

  withdraw = async () => {
    let { application_id, student_id } = this.props.application;
    this.props.setParentState({isLoading: true})
    try {
      await Auth.Student.withdrawApplication({
        application_id,
        student_id
      })
      await this.props.fetchApplications(this.props.process)
    } catch (e) {
      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
    this.props.setParentState({isLoading: false})
  }

  toggleApplyModal = () => {
    this.setState({
      applyModal: !this.state.applyModal,
      applyError: []
    })
  }
  toggleDetails = () => {
    this.props.setParentState({
      isDetailsOpen: !this.state.isDetailsOpen
    })
  }

  fetchShortlistAnswers = async () => {
    let { shortlist_question } = this.props.application;
    let shortlistAnswer = {};
    await Promise.all(shortlist_question.filter(q => q.answer_data.length).map(async (ques) => {
      shortlistAnswer[ques.application_question_id] = ques.answer_data[0].answer_content;
    }));
    this.setState({shortlistAnswer});
  }



  submitShortlistAnser = async (e) => {
    e.preventDefault();
    let { application_id, student_id, shortlist_question } = this.props.application;
    let { shortlistAnswer } = this.state;
    this.props.setParentState({isLoading: true})
    try {
      var alreadyAnsweredData = shortlist_question.filter(q => q.answer_data.length).reduce((prev, ques) => {
        prev[ques.application_question_id] = ques.answer_data[0];
        return prev;
      }, {});
      await Promise.all(Object.keys(shortlistAnswer).map(async (questionId,i) => {
        if(!shortlistAnswer[questionId]) return;
        if (typeof shortlistAnswer[questionId] === "object") {
            let file = await uploadFile(shortlistAnswer[questionId], `student/${student_id}/apply`)
            shortlistAnswer[questionId] = file.file_id.toString();
        }
      }));
      let body = {
        application_id, student_id,
        answer_data : Object.keys(shortlistAnswer).filter(id => shortlistAnswer[id] && Object.keys(alreadyAnsweredData).indexOf(id) === -1).map(questionId => {
          return {application_question_id: questionId, answer_content: shortlistAnswer[questionId]}
        })
      }
      await Auth.Student.addAnswer(body);

      await Promise.all(Object.keys(shortlistAnswer).filter(id => Object.keys(alreadyAnsweredData).indexOf(id) !== -1).map(async questionId => {
        let editBody = {
          student_application_answer_id: alreadyAnsweredData[questionId].student_application_answer_id,
          answer_content: shortlistAnswer[questionId]
        };
        await Auth.Student.editAnswer(editBody);
      }))      

      await this.props.fetchApplications(this.props.process);
      this.setState({shortlistAnswer: {}, shortlistQuestion: false})
    } catch (e) {
        this.setState({ message: e.response ? e.response.data.error.toString() : 'Sorry! something went wrong. Please try again' });
    }
    this.props.setParentState({isLoading: false})
  }



  render() {
    let { application, resumes, multirole, process, settings } = this.props,
      { applyModal, answer_data, resume_id, tabId, preference, shortlistQuestion, shortlistAnswer } = this.state,
      isShortListingData = application.shortlisting_data.length > 0;
    let { isDetailsOpen } = this.props;
    var withdrawDeadlineDiff = moment.duration(moment(application.withdraw_deadline).diff(moment(), "milliseconds"))
    var appliedCv = resumes.filter(function (key) { return key.cv_id === application.resume_id });
    this.selectedResume = null;
    let deadLineCounter = moment.duration(moment(application.application_deadline).diff(moment(), "milliseconds"));
    let selectedPreference = multirole ? multirole.filter(apl => apl.student_application_status === 2).map(apl => apl.preference) : []
    if (multirole && application.application_binder && application.application_binder[0].resume_restriction) {
      multirole.some((apl) => {
        if (apl.resume_id && apl.student_application_status === 2) {
          this.selectedResume = apl.resume_id
          return true
        }
      })
    }
    let studentInfo = store.getState().user.studentInfo;
    studentInfo = studentInfo && studentInfo.length ? studentInfo[0] : {};
    return (
      <Aux>
        <Row className="justify-content-center">
          <Col xs="10" className={"r-bg--light"}>
            <Row>
              <Col className="pt-4">
              </Col>
            </Row>
          </Col>
        </Row>

        {application.shortlisting_data.filter(shortlist => shortlist.type === "S" && shortlist.locked_status > 0).slice(0).reverse().map((shortlist, i) => {
          let round = application.application_round.filter(r => shortlist.application_round_id === r.application_round_id)
          let isFirst = (i === 0),
            status = CONSTANTS.APPLICATION_STATUS[shortlist.locked_status] || CONSTANTS.APPLICATION_STATUS[2];
          return (
            <Collapse isOpen={isFirst || isDetailsOpen} key={i}>
              <Row className="justify-content-center">
                <Col xs="10" className={isFirst ? "r-bg--light" : "r-bg--grey-1"}>
                  <Row>
                    <Col className="pt-4">
                      <div className="apply__application__step pb-5">
                        <div className="r-heading-6 r-text--grey-8" style={{ marginTop: '-10px', fontWeight: 500 }}>Shortlist Announced</div>
                        <div className="r-text--sm r-text--grey-4">{moment(shortlist.locked_on).format("hh:mm a, DD MMM YYYY")}</div>
                        <div className="pt-4 pb-0">
                          <p className="r-heading-4 mb-0">{round[0] ? ("Shorlisting Announced for " + round[0].round_name) : ("Initial Shortlisting")}</p>
                          <p className={status.TEXTCLASS}>You're {status.NAME}</p>
                        </div>
                      </div>
                    </Col>

                  </Row>
                </Col>
              </Row>
            </Collapse>
          )
        })
        }



        <Collapse isOpen={isDetailsOpen || !isShortListingData}>
          <Row className="justify-content-center">
            <Col xs="10" className={isShortListingData > 0 ? "r-bg--grey-1" : "r-bg--light"}>
              <Row>
                <Col className="pt-4">
                  <div className="apply__application__step pb-5">
                    <div className="r-heading-6 r-text--grey-8" style={{ marginTop: '-10px', fontWeight: 500 }}>Applications Opened</div>
                    <div className="r-text--sm r-text--grey-4">{moment(application.published_on).format("hh:mm a, Do MMM YYYY")}</div>

                    <div className="pt-4 pb-0">
                      {deadLineCounter <= 0 ? <p className="r-heading-4 r-text--brandF mb-0">Applications are now closed</p> :
                        <p className="r-heading-4 mb-0">Applications are now open</p>}
                      <div>
                        {application.eligibility_status > 0 ?
                          <p>
                            <span className="r-subheading-3 r-text--brandD">You are eligible for this application.</span>
                          </p>
                          :
                          <p>
                            <span className="r-subheading-3 r-text--brandF">You are not eligible for this application.</span>
                          </p>
                        }
                        {!!application.is_blocked && 
                        <p className="r-subheading-3 r-text--brandF">{this.props.studentDetails.block_message || "You are blocked for this application"}</p>
                        }
                        <Row>
                          <Col xs="auto">
                            <Button className={`apply__application__tab ${tabId === 1 ? "active" : ""}`} onClick={() => this.setState({ tabId: 1 })}>CTC</Button>
                          </Col>
                          <Col xs="auto">
                            <Button className={`apply__application__tab ${tabId === 2 ? "active" : ""}`} onClick={() => this.setState({ tabId: 2 })}>Description</Button>
                          </Col>
                          <Col xs="auto">
                            <Button className={`apply__application__tab ${tabId === 3 ? "active" : ""}`} onClick={() => this.setState({ tabId: 3 })}>Instructions</Button>
                          </Col>
                          <Col xs="auto">
                            <Button className={`apply__application__tab ${tabId === 4 ? "active" : ""}`} onClick={() => this.setState({ tabId: 4 })}>Application Requirements</Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <div className="r-bg--grey-2 r-text--grey-4 p-3">
                              {
                                tabId === 1 && <div>
                                  {!!application.offer_amount_min &&
                                    <p>{application.offer_currency} {application.offer_amount_min ? application.offer_amount_min.toLocaleString("en-IN") : ""}
                                    {application.offer_input_type === "range" ? (" - " + (application.offer_amount_max ? application.offer_amount_max.toLocaleString("en-IN") : "")) : ""}
                                    {application.offer_amount_type === "M" ? " PM" : " PA"}</p>
                                  }
                                  <p>{application.offer_amount_comment || ""}</p>
                                  {!!application.stipend_value && <p>Stipend Amount: {application.stipend_value.toLocaleString("en-IN")}</p>}
                                </div>
                              }
                              {
                                tabId === 2 && <RichTextViewer>
                                  {application.description || ""}
                                </RichTextViewer>
                              }
                              {
                                tabId === 3 && <div>
                                        <RichTextViewer>
                                             {application.instruction || ""}
                                        </RichTextViewer>
                                </div>
                              }
                              {
                                tabId === 4 &&
                                application.application_question.map((ques, i) => {
                                  return (
                                    <div className="mb-3" key={ques.application_question_id}>
                                      <p className="m-0">Q{i + 1}: {ques.question_heading}</p>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </Col>
                        </Row>
                        <div className="mt-3 r-text--sm ">
                          {(application.student_application_status !== 2 && application.eligibility_status > 0 && deadLineCounter > 0 && !application.is_blocked && !application.is_recruitment_closed) ?
                            (
                              process.matrix_status.is_freezed ? 'Freezed due to Matrix restriction' : (application.application_binder && application.application_binder[0].apply_limit && selectedPreference.length >= application.application_binder[0].apply_limit) ? <div className="mt-3 r-subheading-3 r-text--brandF">You can only apply to {application.application_binder[0].apply_limit} applications.</div> : <Button color="dark" className="r-btn--no-radius" onClick={this.toggleApplyModal}>Apply</Button>)
                            :
                            (application.student_application_status === 2 ?
                              <Aux>
                                <Button className="r-btn--no-radius" color="grey" disabled>Applied</Button>
                                <span className="px-3 ml-3 border r-radius--3">
                                  {(appliedCv[0] && appliedCv[0].cv_name) || "Not Existed"}
                                </span>
                                {application.is_withdraw_allow === 1 && (!application.withdraw_deadline || withdrawDeadlineDiff > 0) &&
                                  <span className="px-3 ml-3 border r-radius--3">

                                    {process.matrix_status.is_freezed ? "Freezed due to Matrix Restriction" : <Button color="link" onClick={this.withdraw} className="ml-2 r-text--brandF r-heading-7">Withdraw</Button>}
                                    {/* <span className="r-text--sm r-text--brandF">{`${deadLineCounter.days()}d:${deadLineCounter.hours()}h:${deadLineCounter.minutes()}m:${deadLineCounter.seconds()}s`}</span> */}

                                  </span>}
                              </Aux>
                              :
                              ""
                            )
                          }
                          {!!application.shortlist_question.length && <Button onClick={() => this.setState({shortlistQuestion: true})} color="dark" className="r-btn--no-radius mx-3">Action Required</Button>}
                        </div>
                      </div>


                    </div>
                  </div>
                </Col>

              </Row>
            </Col>
          </Row>
        </Collapse>
        {isShortListingData && <Row className="justify-content-center">
          <Col xs="10" className="text-center r-bg--grey">
            <Button className="w-100 close_details" onClick={this.toggleDetails}>
              <i className="fi flaticon-arrow-q mx-1" />
              {isDetailsOpen ? "Collapse" : "See Older"}
              <i className="fi flaticon-arrow-q mx-1" />
            </Button>
          </Col>
        </Row>}
        <Modal zIndex={99999} isOpen={applyModal} toggle={this.toggleApplyModal}>
          <ModalBody>
            <p className="r-heading-4">Instructions</p>
            <RichTextViewer>
                 {application.instruction || ""}
            </RichTextViewer>
            <form onSubmit={this.apply}>
              <label className="r-subheading-4">Choose Resume</label>
              <Row form>
                <input type="hidden" value={resume_id || ""} name="resume_id" />
                {resumes.length > 0 ? resumes.map(cv => {
                  return cv.cv_type === 'MASTER' || (settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id] && settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id].cv_lock && cv.cv_type !== 'UPLOADED') ? '' : (<Col xs={3} key={cv.cv_id}>
                    <div onClick={() => this.setState({ resume_id: cv.cv_id })} className={"apply__application__resume" + ((this.selectedResume || resume_id) === cv.cv_id ? " active" : "")}>
                      {cv.cv_name}
                    </div>
                  </Col>)
                }) : <Col xs={12} className="r-text--brandF r-heading-7">Please add a resume to apply for this application</Col>
                }
              </Row>
              {answer_data.map((ques, i) => {
                let isRequired = {};
                if (application.application_question[i].is_mandatory)
                  isRequired.required = true
                return (
                  <Row form className="mt-4" key={i}>
                    <Col xs={12}>
                      <p className="r-subheading-4">{application.application_question[i].is_mandatory ? <span className="r-text--red-1">*</span>:""}{application.application_question[i].question_heading}</p>
                      {application.application_question[i].answer_type === "text" && <textarea {...isRequired} placeholder={application.application_question[i].question_content} onChange={(e) => this.handleAnswerChange(i, e)} className="w-100 r-inputfield" value={ques.answer_content}></textarea>}
                      {application.application_question[i].answer_type === "number" && <InputField type="number" {...isRequired} placeholder={application.application_question[i].question_content} onChange={(e) => this.handleAnswerChange(i, e)} className="w-100" value={ques.answer_content} />}
                      
                      {
                        application.application_question[i].answer_type === "multiple" && 

                        (application.application_question[i].answer_type_data||"").split(",").map(option => 
                          <InputField name={"multiple_choice_"+i} checked={(ques.answer_content||"").split(",").indexOf(option) !== -1} type="checkbox" className="mr-2 ml-3 pl-3" value={option} onChange={(e) => this.handleAnswerChange(i, e)} />
                        )

                      }
                      
                      {application.application_question[i].answer_type === "file" &&
                        (ques.answer_content ? <span className="text-truncate r-tag r-tag--type-1">
                          <AttachmentLogo width={10} />{ques.answer_content.name}<Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.removeFile(i)}></Button>
                        </span> : <Aux>
                            <label className="r-btn r-btn--blue text-center d-inline-block" htmlFor={`question_${ques.application_question_id}`}><i className="mr-1 r-text--sm fi flaticon-upload" />{application.application_question[i].question_content}</label>
                            <input id={`question_${ques.application_question_id}`} type="file" accept={JSON.parse(application.application_question[i].answer_type_data).file_type || '*'} onChange={(e) => this.handleAnswerChange(i, e, JSON.parse(application.application_question[i].answer_type_data).file_size || true)} className="d-none" />
                          </Aux>)}
                    </Col>
                  </Row>
                )
              })
              }
              {multirole && application.application_binder && application.application_binder.length && application.application_binder[0].is_preference &&
                <Row className="mt-3">
                  <Col xs={6}>
                    <Select required={true} value={preference || ""} onChange={({ target }) => this.setState({ preference: target.value })}>
                      <option value="">Select Preference</option>
                      {
                        multirole.map((apl, i) => {
                          return <option disabled={selectedPreference.indexOf(i + 1) > -1} key={i} value={i + 1}>{i + 1}</option>
                        })
                      }
                    </Select>
                  </Col>
                </Row>
              }
              <Button type="submit" color="dark" className="mt-3 r-text--sm r-btn--no-radius">Apply</Button>

              <div className="r-text--brandF">
                <ol>
                  {/*
                  this.state.applyError.map((error) => <li>{error}</li>)
                */}
                </ol>
              </div>
            </form>
          </ModalBody>
        </Modal>

        <Modal centered zIndex={99999} isOpen={shortlistQuestion} onOpened={() => this.fetchShortlistAnswers()}  toggle={() => this.setState({shortlistQuestion : false})}>
          <ModalHeader>You need to Answer additional questions</ModalHeader>
          <ModalBody>
            <form onSubmit={this.submitShortlistAnser}>
            {
              shortlistQuestion && 
              <>
              {
              application.shortlist_question.map((question, i) => {
                let isRequired = {};
                if (question.is_mandatory) isRequired.required = true
                return (
                  <Row form className="mt-4" key={i}>
                    <Col xs={12}>
                      <p className="r-subheading-4">{question.is_mandatory ? <span className="r-text--red-1">*</span>:""}{question.question_heading}</p>
                      {question.answer_type === "text" && <textarea {...isRequired} placeholder={question.question_content} onChange={(e) => this.handleShortlistAnswerChange(question.application_question_id, e)} className="w-100 r-inputfield" value={shortlistAnswer[question.application_question_id]}></textarea>}
                      {question.answer_type === "number" && <InputField type="number" {...isRequired} placeholder={question.question_content} onChange={(e) => this.handleShortlistAnswerChange(question.application_question_id, e)} className="w-100" value={shortlistAnswer[question.application_question_id]} />}
                      {question.answer_type === "file" &&
                        (shortlistAnswer[question.application_question_id] ? <span className="text-truncate r-tag r-tag--type-1">
                          <AttachmentLogo width={10} />{shortlistAnswer[question.application_question_id].name}<Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.removeFile(question.application_question_id)}></Button>
                        </span> : <Aux>
                            <label className="r-btn r-btn--blue text-center d-inline-block" htmlFor={`shortlist_question_${i}`}><i className="mr-1 r-text--sm fi flaticon-upload" />{question.question_content}</label>
                            <input id={`shortlist_question_${i}`} type="file" accept={JSON.parse(question.answer_type_data).file_type || '*'} onChange={(e) => this.handleShortlistAnswerChange(question.application_question_id, e, JSON.parse(question.answer_type_data).file_size || true)} className="d-none" />
                          </Aux>)}
                    </Col>
                  </Row>
                )
                })
              }
              <Row className="mx-0 mt-4 py-3 border-top">
                <Col className="text-right">
                  <Button color="dark" className="r-text--sm">Submit Answer</Button>
                </Col>
              </Row>
              </>
            }
          </form>

          </ModalBody>
        </Modal>




        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
        <Fade in={this.state.cvError} unmountOnExit={true} className="error-bar">
          <Row className="text-center py-4 r-subheading-3 mb-0 error-line d-block ">
                <i className="fi flaticon-alert"></i>
                Please{" "}
                <Link className=" py-4 r-subheading-3 mb-0 " to={`/student/recruitments/resumes`}>create a resume</Link>
                {" "}to apply for this application. You can't apply using your master resume.
          </Row>
          <Button onClick={this.closeError} className="close-bn"><i className="fi flaticon-close" ></i></Button>
        </Fade>
      </Aux>
    )
  }
}
