import { CONSTANTS } from ".";
import { filterCVPoints, groupCVHeaders } from "../actions";

export const TEMPLATE_SETTINGS = {
  "horizontal": {
    "initial": 20,
    "propNames": ["paddingLeft", "paddingRight"]
  },
  "vertical": {
    "initial": 20,
    "propNames": ["paddingTop", "paddingBottom"]
  },
  "typeface": {
    "default": "Times New Roman",
    "propNames": ["fontFamily"],
    "options": ["Times New Roman", "Segoe UI", "Arial", "Arial Black", "Comic Sans MS", "Impact", "Tahoma", "Trebuchet MS", "Verdana", "Courier New", "Lucida Console"]
  },
  "lineHeight": {
    "default": 1,
    "propNames": ["lineHeight"],
    "options": [1, 1.1, 1.15, 1.2, 1.25, 1.3, 1.35, 1.4, 1.45, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3]
  },
  "listStyle": {
    "default": "disc",
    "propNames": ["listStyleType"]
  }
}

export const TEMPLATE_CONFIGPATHS = {
  "collegeLogo": "personal_details.section_layout.components.rows.0.config"
}

export const TEMPLATE_CONFIG = {
  "section_order": {
    "a": "personal_details",
    "b": "personal_summary",
    "c": "academic_details",
    "i": "academic_achievements",
    "d": "internships",
    "e": "professional_experience",
    "f": "startup_exp",
    "g": "summer_internship",
    "h": "projects",
    "j": "positions_of_responsibility",
    "k": "extra_curricular_activity",
    "l": "achievements",
    "m": "other_experience",
    "n": "skills",
    "o": "hobbies"
  },
  "settings": {
    "horizontal": 0,
    "vertical": 0,
    "typeface": "Times New Roman",
    "lineHeight": 1.5,
    "listStyle": "disc"
  },
  "section_config": {
    "personal_details": {
      "visible": "1",
      "section_layout": {
        "config": {
          "font-size": "12px"
        },
        "components": {
          "rows": [
            {
              "columns": [],
              "config": {
                "font-size": "12px"
              }
            },
            {
              "columns": [
                {
                  "grid_size": 12,
                  "config": {
                    "font-size": "20px",
                    "font-weight": "bold",
                    "text-align": "left",
                    "letter-spacing": "2px",
                    "text-transform": "uppercase"
                  },
                  "items": [
                    {
                      "type": "NAME",
                      "data": [
                        "user_info"
                      ]
                    }
                  ]
                }
              ],
              "config": {
                "font-size": "12px"
              }
            },
            {
              "config": {
                "margin-bottom": "2px",
                "justify-content": "space-between"
              },
              "columns": [
                {
                  "grid_size": "auto",
                  "config": {
                    "font-size": "10px",
                    "letter-spacing": "2px",
                    "text-transform": "uppercase"
                  },
                  "items": []
                },
                {
                  "grid_size": "auto",
                  "config": {
                    "font-size": "10px",
                    "letter-spacing": "2px",
                    "text-transform": "uppercase"
                  },
                  "items": [
                    {
                      "data": [
                        "user_info.mobile",
                        " | ",
                        "user_info.email"
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      }
    },
    "personal_summary": { 
      "visible": "1",
      "section_layout": {
        "config": {
          "font-size": "12px"
        },
        "components": {
          "rows": [{
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "letter-spacing": "2px"
                  },
                  "items": [
                    {
                      "data": "SUMMARY"
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "config": {},
                  "items": [
                    {
                      "data": "personal_info.personal_summary"
                    }
                  ]
                }
              ]
            }]
        }
      }
    },
    "academic_details": {
      "visible": "1",
      "keys": [],
      "cv_keys": [],
      "section_layout": {
        "config": {
          "font-size": "12px",
          "margin-bottom": "2px"
        },
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "letter-spacing": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "EDUCATION"
                    }
                  ]
                },
                {
                  "grid_size": 12,
                  "items": [],
                  "config": {
                    "border": "1px solid #000000",
                    "border-width": "0 0 1px 1px"
                  },
                  "rows": [
                    {
                      "columns": [
                        {
                          "grid_size": 3,
                          "class": "px-1",
                          "config": {
                            "border-right": "1px solid #000000"
                          },
                          "items": [
                            {
                              "data": "Name of course"
                            }
                          ]
                        },
                        {
                          "grid_size": 2,
                          "class": "px-1",
                          "config": {
                            "border-right": "1px solid #000000"
                          },
                          "items": [
                            {
                              "data": "Passing Year"
                            }
                          ]
                        },
                        {
                          "grid_size": 5,
                          "class": "px-1",
                          "config": {
                            "border-right": "1px solid #000000"
                          },
                          "items": [
                            {
                              "data": "Name of institute"
                            }
                          ]
                        },
                        {
                          "grid_size": 2,
                          "class": "px-1",
                          "config": {
                            "border-right": "1px solid #000000"
                          },
                          "items": [
                            {
                              "data": "%/CGPA"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "columns": [
                {
                  "items": [],
                  "rows": []
                }
              ]
            }
          ]
        }
      }
    },
    "professional_details_internship": {
      "visible": "1",
      "keys": [],
      "section_layout": {
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "margin-top": "2px",
                    "letter-spacing": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "INTERNSHIPS"
                    }
                  ]
                },
                {
                  "config": {
                    "border-top": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "right",
                    "margin-top": "2px",
                    "margin-bottom": "2px",
                    "display": "none",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": ""
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "rows": [],
                  "items": []
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "items": [],
                  "rows": []
                }
              ]
            }
          ]
        },
        "config": {
          "font-size": "12px",
          "margin-top": "2px"
        }
      }
    },
    "internships": {
      "visible": "1",
      "keys": [],
      "section_layout": {
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "margin-top": "2px",
                    "letter-spacing": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "INTERNSHIPS"
                    }
                  ]
                },
                {
                  "config": {
                    "border-top": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "right",
                    "margin-top": "2px",
                    "margin-bottom": "2px",
                    "display": "none",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": ""
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "rows": [],
                  "items": []
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "items": [],
                  "rows": []
                }
              ]
            }
          ]
        },
        "config": {
          "font-size": "12px",
          "margin-top": "2px"
        }
      }
    },
    "startup_exp": {
      "visible": "1",
      "keys": [],
      "section_layout": {
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "margin-top": "2px",
                    "letter-spacing": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "STARTUP EXPERIENCE"
                    }
                  ]
                },
                {
                  "config": {
                    "border-top": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "right",
                    "margin-top": "2px",
                    "margin-bottom": "2px",
                    "display": "none",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": ""
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "rows": [],
                  "items": []
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "items": [],
                  "rows": []
                }
              ]
            }
          ]
        },
        "config": {
          "font-size": "12px",
          "margin-top": "2px"
        }
      }
    },
    "summer_internship": {
      "visible": "1",
      "keys": [],
      "section_layout": {
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "margin-top": "2px",
                    "letter-spacing": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "SUMMER INTERNSHIP"
                    }
                  ]
                },
                {
                  "config": {
                    "border-top": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "right",
                    "margin-top": "2px",
                    "margin-bottom": "2px",
                    "display": "none",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": ""
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "rows": [],
                  "items": []
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "items": [],
                  "rows": []
                }
              ]
            }
          ]
        },
        "config": {
          "font-size": "12px",
          "margin-top": "2px"
        }
      }
    },
    "professional_experience": {
      "visible": "1",
      "keys": "",
      "section_layout": {
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "config": {
                    "border": "1px solid #000000",
                    "border-width": "1px 0 1px 1px",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "letter-spacing": "2px",
                    "margin-top": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "PROFESSIONAL EXPERIENCE"
                    }
                  ]
                },
                {
                  "config": {
                    "border": "1px solid #000000",
                    "border-width": "1px 1px 1px 0",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "right",
                    "margin-top": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": ""
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "items": [],
                  "rows": []
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "items": [],
                  "rows": []
                }
              ]
            }
          ]
        },
        "config": {
          "font-size": "12px",
          "margin-top": "2px"
        }
      }
    },
    "projects": {
      "visible": "1",
      "section_layout": {
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "margin-top": "2px",
                    "letter-spacing": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "PROJECTS"
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "gridSize": 12,
                  "items": [],
                  "rows": []
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "items": [],
                  "rows": []
                }
              ]
            }
          ]
        },
        "config": {
          "font-size": "12px",
          "margin-top": "2px"
        }
      }
    },
    "positions_of_responsibility": {
      "visible": "1",
      "section_layout": {
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "margin-top": "2px",
                    "letter-spacing": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "POSITIONS OF RESPONSIBILITY"
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "gridSize": 12,
                  "items": [],
                  "rows": []
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "grid_size": 12,
                  "items": [],
                  "rows": []
                }
              ]
            }
          ]
        },
        "config": {
          "font-size": "12px",
          "margin-top": "2px"
        }
      }
    },
    "extra_curricular_activity": {
      "visible": "1",
      "section_layout": {
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "margin-top": "2px",
                    "letter-spacing": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "EXTRA-CURRICULAR ACTIVITIES AND ACHIEVEMENTS"
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "config": {},
                  "grid_size": 12,
                  "items": [],
                  "rows": []
                }
              ]
            },
            {
              "config": {},
              "columns": []
            }
          ]
        },
        "config": {
          "font-size": "12px",
          "margin-top": "2px"
        }
      }
    },
    "hobbies": {
      "visible": "1",
      "section_layout": {
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "margin-top": "2px",
                    "letter-spacing": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "HOBBIES"
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "items": [
                    {
                      "config": {
                        "display": "list-item",
                        "margin-left": "20px"
                      },
                      "type": "CSV",
                      "format": " | ",
                      "data": "personal_info.hobbies"
                    }
                  ]
                }
              ]
            }
          ]
        },
        "config": {
          "font-size": "12px",
          "margin-top": "2px"
        }
      }
    },
    "academic_achievements": {
      "visible": "1",
      "section_layout": {
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "margin-top": "2px",
                    "letter-spacing": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "ACADEMIC ACHIEVEMENTS AND AWARDS"
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "config": {},
                  "grid_size": 12,
                  "items": [],
                  "rows": []
                }
              ]
            },
            {
              "config": {},
              "columns": []
            }
          ]
        },
        "config": {
          "font-size": "12px",
          "margin-top": "2px"
        }
      }
    },
    "achievements": {
      "visible": "1",
      "section_layout": {
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "margin-top": "2px",
                    "letter-spacing": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "ACHIEVEMENTS"
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "config": {},
                  "grid_size": 12,
                  "items": [],
                  "rows": [
                    {
                      "config": {},
                      "key": "achievement",
                      "columns": [
                        {
                          "config": {},
                          "grid_size": 12,
                          "items": [
                            {
                              "config": {
                                "font-weight": "bold"
                              },
                              "data": "Test Scores"
                            }
                          ]
                        },
                        {
                          "grid_size": 12,
                          "rows": [],
                          "items":[],
                          "config":{
                            "margin-left": "10px"
                          }
                        }
                      ]
                    },
                    {
                      "config": {},
                      "key": "achievement",
                      "columns": [
                        {
                          "config": {},
                          "grid_size": 12,
                          "items": [
                            {
                              "config": {
                                "font-weight": "bold"
                              },
                              "data": "Patents"
                            }
                          ]
                        },
                        {
                          "grid_size": 12,
                          "rows": [],
                          "items":[],
                          "config":{
                            "margin-left": "10px"
                          }
                        }
                      ]
                    },
                    {
                      "config": {},
                      "key": "achievement",
                      "columns": [
                        {
                          "config": {},
                          "grid_size": 12,
                          "items": [
                            {
                              "config": {
                                "font-weight": "bold"
                              },
                              "data": "Publications"
                            }
                          ]
                        },
                        {
                          "grid_size": 12,
                          "rows": [],
                          "items":[],
                          "config":{
                            "margin-left": "10px"
                          }
                        }
                      ]
                    },
                    {
                      "config": {},
                      "key": "achievement",
                      "columns": [
                        {
                          "config": {},
                          "grid_size": 12,
                          "items": [
                            {
                              "config": {
                                "font-weight": "bold"
                              },
                              "data": "Scholarships"
                            }
                          ]
                        },
                        {
                          "grid_size": 12,
                          "rows": [],
                          "items":[],
                          "config":{
                            "margin-left": "10px"
                          }
                        }
                      ]
                    },
                    {
                      "config": {},
                      "key": "achievement",
                      "columns": [
                        {
                          "config": {},
                          "grid_size": 12,
                          "items": [
                            {
                              "config": {
                                "font-weight": "bold"
                              },
                              "data": "Competitions"
                            }
                          ]
                        },
                        {
                          "grid_size": 12,
                          "rows": [],
                          "items":[],
                          "config":{
                            "margin-left": "10px"
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": []
            }
          ]
        },
        "config": {
          "font-size": "12px",
          "margin-top": "2px"
        }
      }
    },
    "other_experience": {
      "visible": "1",
      "section_layout": {
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "margin-top": "2px",
                    "letter-spacing": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "OTHER EXPERIENCES"
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "config": {},
                  "grid_size": 12,
                  "items": [],
                  "rows": [
                    {
                      "config": {},
                      "key": "other_experience",
                      "columns": [
                        {
                          "config": {},
                          "grid_size": 12,
                          "items": [
                            {
                              "config": {
                                "font-weight": "bold"
                              },
                              "data": "Courses"
                            }
                          ]
                        },
                        {
                          "grid_size": 12,
                          "rows": [],
                          "items":[],
                          "config":{
                            "margin-left": "10px"
                          }
                        }
                      ]
                    },
                    {
                      "config": {},
                      "key": "other_experience",
                      "columns": [
                        {
                          "config": {},
                          "grid_size": 12,
                          "items": [
                            {
                              "config": {
                                "font-weight": "bold"
                              },
                              "data": "Workshops"
                            }
                          ]
                        },
                        {
                          "grid_size": 12,
                          "rows": [],
                          "items":[],
                          "config":{
                            "margin-left": "10px"
                          }
                        }
                      ]
                    },
                    {
                      "config": {},
                      "key": "other_experience",
                      "columns": [
                        {
                          "config": {},
                          "grid_size": 12,
                          "items": [
                            {
                              "config": {
                                "font-weight": "bold"
                              },
                              "data": "Conferences"
                            }
                          ]
                        },
                        {
                          "grid_size": 12,
                          "rows": [],
                          "items":[],
                          "config":{
                            "margin-left": "10px"
                          }
                        }
                      ]
                    },
                    {
                      "config": {},
                      "key": "other_experience",
                      "columns": [
                        {
                          "config": {},
                          "grid_size": 12,
                          "items": [
                            {
                              "config": {
                                "font-weight": "bold"
                              },
                              "data": "Volunteer Experience"
                            }
                          ]
                        },
                        {
                          "grid_size": 12,
                          "rows": [],
                          "items":[],
                          "config":{
                            "margin-left": "10px"
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": []
            }
          ]
        },
        "config": {
          "font-size": "12px",
          "margin-top": "2px"
        }
      }
    },
    "skills": {
      "visible": "1",
      "section_layout": {
        "components": {
          "rows": [
            {
              "config": {},
              "columns": [
                {
                  "config": {
                    "border": "1px solid #000000",
                    "background": "#d9d9d9",
                    "font-weight": "bold",
                    "text-align": "left",
                    "margin-top": "2px",
                    "letter-spacing": "2px",
                    "margin-bottom": "2px",
                    "font-size": "12px"
                  },
                  "items": [
                    {
                      "data": "SKILLS"
                    }
                  ]
                }
              ]
            },
            {
              "config": {},
              "columns": [
                {
                  "config": {},
                  "grid_size": 12,
                  "items": [],
                  "rows": []
                }
              ]
            },
            {
              "config": {},
              "columns": []
            }
          ]
        },
        "config": {
          "font-size": "12px",
          "margin-top": "2px"
        }
      }
    }
  },
  "methods": {
    "personalDetail": "return {'type': 'TEXT','data': [\"user_info.gender\",\", \",age + \" YRS\"]}",
    "proDetail": "let section = detail.project_id ? 'project' : 'professional_detail'; let cvDetail = [], cvPointKeys = [], id = detail.project_id || detail.professional_id; if (cvPoints && cvPoints[id]) { cvDetail = cvPointDetails(cvPoints[id]); Object.keys(cvPoints[id]).forEach(header => { cvPointKeys.push(...cvPoints[id][header].map(cv => cv.cv_point_id)) }) } return ({ 'config': {}, 'key': id, 'target': section, 'cv_keys': cvPointKeys, 'columns': [{ 'grid_size': 12, 'config': {}, 'items': [], 'rows': [{ 'config': {}, 'id': id, 'target': section, 'columns': [ { 'grid_size': 9, 'config': { 'font-weight': 'bold', 'text-align': 'left' }, 'items': [ { 'data': [`${section}.${i}.${detail.project_id ? 'project_title' : 'designation_name'}`, \", \" , `${section}.${i}.company_name`, section!=\"project\"?\", \":\"\" , section!=\"project\"?`${section}.${i}.location`:\"\"] } ] }, { 'grid_size': 3, 'config': { 'font-weight':'bold','text-align': 'right' }, 'items': [ { 'type': 'DATE', 'format': \"MMM YYYY\", 'data': [ `${section}.${i}.start_date`, ' to ', detail.currently_working?\"Present\":`${section}.${i}.end_date` ] } ] } ] }, ...cvDetail] }] });",
    "porDetail": "let section=\"position_of_responsibility\";let cvDetail=[],cvPointKeys=[],id=detail.id;if(cvPoints&&cvPoints[id]){Object.keys(cvPoints[id]).forEach(header=>{cvPointKeys.push(...cvPoints[id][header].map(cv=>{cvDetail.push({\"key\":cv.cv_point_id,\"id\":cv.cv_point_id,\"target\":\"cv_point\",\"config\":{},\"columns\":[{\"config\":{},\"items\":[{\"type\":\"LISTITEM\",\"data\":`cv_point.${cv.cv_point_id}.text`}]}]});return cv.cv_point_id}))})}return{\"config\":{},\"key\":id,\"target\":section,\"cv_keys\":cvPointKeys,\"columns\":[{\"grid_size\":12,\"config\":{},\"items\":[],\"rows\":[{\"config\":{},\"id\":id,\"target\":section,\"columns\":[{\"grid_size\": 9,\"config\":{\"font-weight\":\"bold\"},\"items\":[{\"data\":[`${section}.${i}.designation_name`,\", \",`${section}.${i}.society`,\", \",`${section}.${i}.college_name`]}]},{ 'grid_size': 3, 'config': { 'font-weight':'bold','text-align': 'right' }, 'items': [ { 'type': 'DATE', 'format': \"MMM YYYY\", 'data': [ `${section}.${i}.start_date`, ' to ', detail.currently_working?\"Present\":`${section}.${i}.end_date` ] } ] }]},...cvDetail]}]}",
    "academicDetail": "let instituteName = \"\"; let acadType = []; if (detail.academic_type === \"X\" || detail.academic_type === \"XII\") { instituteName = `academic_detail.${i}.school_name`; acadType = [ `academic_detail.${i}.board_short_form`, \" (Class \" , `academic_detail.${i}.academic_type` , \")\" ] } else { instituteName = [`academic_detail.${i}.college_name`, detail.university_name?\", \":\"\",`academic_detail.${i}.university_name`]; acadType = [`academic_detail.${i}.degree_name`] }; return ( { \"key\": i, \"id\": i, \"target\": \"academic_detail\", \"config\": { \"border-left\": \"1px solid #000000\", \"border-bottom\": \"1px solid #000000\", \"text-align\": \"left\" }, \"columns\": [ { \"grid_size\": 3, \"class\": \"px-0\", \"config\": { \"border-right\": \"1px solid #000000\" }, \"itemsClass\": \"px-1\", \"items\": [ { \"data\": acadType } ] }, { \"grid_size\": 2, \"class\": \"px-0\", \"config\": { \"border-right\": \"1px solid #000000\" }, \"itemsClass\": \"px-1\", \"items\": detail.currently_working?[{\"data\":\"Present\"}] : [ { \"type\": \"DATE\", \"format\": \"YYYY\", \"data\": [`academic_detail.${i}.year_end`] } ] }, { \"grid_size\": 5, \"class\": \"px-0\", \"config\": { \"border-right\": \"1px solid #000000\" }, \"itemsClass\": \"px-1\", \"items\": [ { \"data\": instituteName } ] }, { \"grid_size\": 2, \"class\": \"px-0\", \"config\": { \"border-right\": \"1px solid #000000\" }, \"itemsClass\": \"px-1\", \"items\": [ { \"type\": \"SCORE\", \"data\": [`academic_detail.${i}.score_value`, \" \", `academic_detail.${i}.score_type`,\" (\",`academic_detail.${i}.rank`,\")\"] } ] }] } )",
    "cvPointDetails": "let points=[]; return Object.keys(cvPoints).map(header => { return { \"config\": {}, \"columns\": [ { \"config\": {}, \"items\": [], \"rows\": [ { \"columns\": [{ \"grid_size\": 12, \"config\": {}, \"items\": [], \"class\": \"cvList\", \"rows\": cvPoints[header].map(cv => { return { \"key\": cv.cv_point_id, \"id\": cv.cv_point_id, \"target\": \"cv_point\", \"config\": {}, \"columns\": [ { \"config\": {}, \"items\": [ { \"type\": \"LISTITEM\", \"data\": `cv_point.${cv.cv_point_id}.text` } ] } ] } }) } ] } ] } ] } })",
    "academic_achievements": "var academicPoints={};Object.keys(studentDetails.cv_point).forEach(function(id){var cv=studentDetails.cv_point[id];if(cv.header&&cv.text&&cv.profile_type===\"ACADEMIC\"){if(academicPoints[\"Academic\"])academicPoints[\"Academic\"].push(cv);else academicPoints[\"Academic\"]=[cv]}});config.section_config.academic_achievements.section_layout.components.rows[1].columns[0].rows=Object.keys(academicPoints).map(function(header){return{\"columns\":[{\"grid_size\":12,\"config\":{},\"items\":[],\"class\":\"cvList\",\"rows\":academicPoints[header].map(function(cv){return{\"key\":cv.cv_point_id,\"id\":cv.cv_point_id,\"target\":\"cv_point\",\"config\":{},\"columns\":[{\"grid_size\":12,\"config\":{},\"items\":[{\"type\":\"LISTITEM\",\"data\":\"cv_point.\".concat(cv.cv_point_id,\".text\")}]}]}})}]}});return config",
    "ecDetail": "let section=\"extra_curricular_activity\";let cvDetail=[],cvPointKeys=[],id=detail.extra_curricular_id;if(cvPoints && cvPoints[id]){Object.keys(cvPoints[id]).forEach(header=>{cvPointKeys.push(...cvPoints[id][header].map(cv=>{cvDetail.push({\"key\":cv.cv_point_id,\"id\":cv.cv_point_id,\"target\":\"cv_point\",\"config\":{},\"columns\":[{\"config\":{},\"items\":[{\"type\":\"LISTITEM\",\"data\":`cv_point.${cv.cv_point_id}.text`}]}]});return cv.cv_point_id}))})};return{\"config\":{},\"key\":id,\"target\":section,\"cv_keys\":cvPointKeys,\"columns\":[{\"grid_size\":12,\"config\":{},\"items\":[],\"rows\":[{\"config\":{},\"id\":id,\"target\":section,\"columns\":[{\"grid_size\": 12,\"config\":{\"font-weight\":\"bold\"},\"items\":[{\"data\":[`${section}.${i}.activity_name`,\", \",`${section}.${i}.organised_by`]}]}]},...cvDetail]}]}",
    "achievements": "let section=\"achievement\";let achievement={\"TEST_SCORE\":0,\"PATENT\":1,\"PUBLICATION\":2,\"SCHOLARSHIP\":3,\"COMPETITION\":4},cvPoints={};Object.keys(achievement).forEach(type=>{config.section_config.achievements.section_layout.components.rows[1].columns[0].rows[achievement[type]].columns[1].rows=[]});Object.keys(studentDetails.cv_point).forEach(id=>{let cv=studentDetails.cv_point[id];if(cv.header&&cv.text){if(cv.profile_type===\"ACHIEVEMENTS\"){if(cvPoints[cv.profile_id])cvPoints[cv.profile_id].push(cv);else cvPoints[cv.profile_id]=[cv]}}});studentDetails.keys.achievement.forEach(id=>{let type=studentDetails.achievement[id].achievement_type;let cvDetail=[];if(cvPoints[id]){cvPoints[id].forEach(cv=>{cvDetail.push({\"target\":\"cv_point\",\"key\":cv.cv_point_id,\"id\":cv.cv_point_id,\"columns\":[{\"items\":[{\"type\":\"LISTITEM\",\"data\":`cv_point.${cv.cv_point_id}.text`}]}]})})};let temp={\"target\":\"achievement\",\"key\":id,\"id\":id,\"columns\":[{\"items\":[],\"rows\":[{\"columns\":[{\"grid_size\":10,\"config\":{},\"items\":[{\"data\":[`${section}.${id}.name`,\", \",`${section}.${id}.${type === \"PUBLICATION\" ? \"publisher_name\" : \"organisation\"}`]},{\"data\":type===\"TEST_SCORE\"?[\"Score - \",`${section}.${id}.score`,\"/\",`${section}.${id}.total_score`]:[\"\"]}]},{\"class\":\"text-right\",\"grid_size\":2,\"items\":[{\"type\":\"DATE\",\"format\":type===\"PATENT\"||type===\"PUBLICATION\"?\"DD MMM YYYY\":\"YYYY\",\"data\":`${section}.${id}.year`}]}]},...cvDetail]}]};config.section_config.achievements.section_layout.components.rows[1].columns[0].rows[achievement[type]].columns[1].rows.push(temp)});return config",
    "other_experience": "let section=\"other_experience\";let other_experience={\"COURSE\":0,\"WORKSHOP\":1,\"CONFERENCES\":2,\"VOLUNTEER\":3},cvPoints={};Object.keys(other_experience).forEach(type=>{config.section_config.other_experience.section_layout.components.rows[1].columns[0].rows[other_experience[type]].columns[1].rows=[]});Object.keys(studentDetails.cv_point).forEach(id=>{let cv=studentDetails.cv_point[id];if(cv.header&&cv.text){if(cv.profile_type===\"OTHER_EXPERIENCE\"){if(cvPoints[cv.profile_id])cvPoints[cv.profile_id].push(cv);else cvPoints[cv.profile_id]=[cv]}}});studentDetails.keys.other_experience.forEach(id=>{let type=studentDetails.other_experience[id].type;let cvDetail=[];if(cvPoints[id]){cvPoints[id].forEach(cv=>{cvDetail.push({\"target\":\"cv_point\",\"key\":cv.cv_point_id,\"id\":cv.cv_point_id,\"columns\":[{\"items\":[{\"type\":\"LISTITEM\",\"data\":`cv_point.${cv.cv_point_id}.text`}]}]})})};let temp={\"target\":\"other_experience\",\"key\":id,\"id\":id,\"columns\":[{\"items\":[],\"rows\":[{\"columns\":[{\"grid_size\":10,\"config\":{},\"items\":[{\"data\":[`${section}.${id}.name`,\", \",`${section}.${id}.institute`,\", \",`${section}.${id}.location`]}]},{\"class\":\"text-right\",\"grid_size\":2,\"items\":[{\"type\":\"DATE\",\"format\":\"MMM YYYY\",\"data\":[`${section}.${id}.year_end`]}]}]},...cvDetail]}]};config.section_config.other_experience.section_layout.components.rows[1].columns[0].rows[other_experience[type]].columns[1].rows.push(temp)});return config",
    "skills": "let section=\"skill\";let skill={\"LANGUAGE\":{\"name\":\"Language Skills\",id:[]},\"TECHNICAL\":{\"name\":\"Tools & Technologies \",id:[]},\"INTERPERSONAL\":{\"name\":\"Interpersonal Skills\",id:[]},\"OTHER\":{\"name\":\"Other Skills\",id:[]}},proficiency={0:\"\",1:\": Novice\",2:\": Intermediate\",3:\": Advanced\",4:\": Superior\",5:\": Distinguished\"},rowDetails=[];studentDetails.keys.skill.forEach(id=>{let type=studentDetails.skill[id].skill_type;skill[type].id.push(id)});Object.keys(skill).forEach(type=>{if(skill[type].id.length>0){let temp={\"target\":\"skill\",\"key\":skill[type].id[0],\"id\":skill[type].id[0],\"columns\":[{\"items\":[{\"type\":\"LISTITEM\",\"data\":[skill[type].name,\" - \"]}]}]};let data=[];skill[type].id.forEach((id,i)=>{data.push(`${section}.${id}.skill`,proficiency[studentDetails.skill[id].proficiency]);if(i<skill[type].id.length-1)data.push(\" | \")});temp.columns[0].items[0].data.push(...data);rowDetails.push(temp)}});config.section_config.skills.section_layout.components.rows[1].columns[0].rows=rowDetails;return config",
    "internships": "let rows=[],section=\"professional_detail\",cvPoints={};Object.keys(studentDetails.cv_point).forEach(id=>{let cv=studentDetails.cv_point[id];if(cv.profile_type===\"PROFESSIONAL\"){if(cvPoints[cv.profile_id])cvPoints[cv.profile_id].push(cv);else cvPoints[cv.profile_id]=[cv]}});studentDetails.keys.professional_detail.forEach(id=>{let detail=studentDetails.professional_detail[id];let cvDetail={columns:[{class:\"cvList\",items:[],rows:[]}]};if(detail.experience_type===\"INTERNSHIP\"){if(cvPoints[id]){cvDetail.columns[0].rows=cvPoints[id].map(cv=>{return{target:\"cv_point\",key:cv.cv_point_id,id:cv.cv_point_id,columns:[{items:[{type:\"LISTITEM\",data:`cv_point.${cv.cv_point_id}.text`}]}]}})};let temp={\"config\":{},\"key\":id,\"target\":section,\"cv_keys\":[],\"columns\":[{\"grid_size\":12,\"config\":{},\"items\":[],\"rows\":[{\"config\":{},\"id\":id,\"target\":section,\"columns\":[{\"grid_size\":9,\"config\":{\"font-weight\":\"bold\",\"text-align\":\"left\"},\"items\":[{\"data\":[`${section}.${id}.designation_name`,\", \",`${section}.${id}.company_name`,\", \",`${section}.${id}.location`]}]},{\"grid_size\":3,\"config\":{\"font-weight\":\"bold\",\"text-align\":\"right\"},\"items\":[{\"type\":\"DATE\",\"format\":\"MMM YYYY\",\"data\":[`${section}.${id}.start_date`,\" to \",detail.currently_working?\"Present\":`${section}.${id}.end_date`]}]}]},cvDetail]}]};rows.push(temp)}});config.section_config.internships.section_layout.components.rows[1].columns[0].rows=rows;return config",
    "startup_exp": "let rows=[],section=\"professional_detail\",cvPoints={};Object.keys(studentDetails.cv_point).forEach(id=>{let cv=studentDetails.cv_point[id];if(cv.profile_type===\"PROFESSIONAL\"){if(cvPoints[cv.profile_id])cvPoints[cv.profile_id].push(cv);else cvPoints[cv.profile_id]=[cv]}});studentDetails.keys.professional_detail.forEach(id=>{let detail=studentDetails.professional_detail[id];let cvDetail={columns:[{class:\"cvList\",items:[],rows:[]}]};if(detail.experience_type===\"STARTUP_EXPERIENCE\"){if(cvPoints[id]){cvDetail.columns[0].rows=cvPoints[id].map(cv=>{return{target:\"cv_point\",key:cv.cv_point_id,id:cv.cv_point_id,columns:[{items:[{type:\"LISTITEM\",data:`cv_point.${cv.cv_point_id}.text`}]}]}})};let temp={\"config\":{},\"key\":id,\"target\":section,\"cv_keys\":[],\"columns\":[{\"grid_size\":12,\"config\":{},\"items\":[],\"rows\":[{\"config\":{},\"id\":id,\"target\":section,\"columns\":[{\"grid_size\":9,\"config\":{\"font-weight\":\"bold\",\"text-align\":\"left\"},\"items\":[{\"data\":[`${section}.${id}.designation_name`,\", \",`${section}.${id}.company_name`,\", \",`${section}.${id}.location`]}]},{\"grid_size\":3,\"config\":{\"font-weight\":\"bold\",\"text-align\":\"right\"},\"items\":[{\"type\":\"DATE\",\"format\":\"MMM YYYY\",\"data\":[`${section}.${id}.end_date`]}]}]},cvDetail]}]};rows.push(temp)}});config.section_config.startup_exp.section_layout.components.rows[1].columns[0].rows=rows;return config",
    "summer_internship": "let rows=[],section=\"professional_detail\",cvPoints={};Object.keys(studentDetails.cv_point).forEach(id=>{let cv=studentDetails.cv_point[id];if(cv.profile_type===\"PROFESSIONAL\"){if(cvPoints[cv.profile_id])cvPoints[cv.profile_id].push(cv);else cvPoints[cv.profile_id]=[cv]}});studentDetails.keys.professional_detail.forEach(id=>{let detail=studentDetails.professional_detail[id];let cvDetail={columns:[{class:\"cvList\",items:[],rows:[]}]};if(detail.experience_type===\"SUMMER_INTERNSHIP\"){if(cvPoints[id]){cvDetail.columns[0].rows=cvPoints[id].map(cv=>{return{target:\"cv_point\",key:cv.cv_point_id,id:cv.cv_point_id,columns:[{items:[{type:\"LISTITEM\",data:`cv_point.${cv.cv_point_id}.text`}]}]}})};let temp={\"config\":{},\"key\":id,\"target\":section,\"cv_keys\":[],\"columns\":[{\"grid_size\":12,\"config\":{},\"items\":[],\"rows\":[{\"config\":{},\"id\":id,\"target\":section,\"columns\":[{\"grid_size\":9,\"config\":{\"font-weight\":\"bold\",\"text-align\":\"left\"},\"items\":[{\"data\":[`${section}.${id}.designation_name`,\", \",`${section}.${id}.company_name`,\", \",`${section}.${id}.location`]}]},{\"grid_size\":3,\"config\":{\"font-weight\":\"bold\",\"text-align\":\"right\"},\"items\":[{\"type\":\"DATE\",\"format\":\"MMM YYYY\",\"data\":[`${section}.${id}.end_date`]}]}]},cvDetail]}]};rows.push(temp)}});config.section_config.summer_internship.section_layout.components.rows[1].columns[0].rows=rows;return config"
  }
}

export const academicDetail = (detail, i) => {
  let instituteName = "";
  let acadType = []
  if (detail.academic_type === "X" || detail.academic_type === "XII") {
    instituteName = `academic_detail.${i}.school_name`
    acadType = [
      CONSTANTS.ACADEMIC_TYPES[detail.academic_type],
      " | ",
      `academic_detail.${i}.board_short_form`
    ]
  } else {
    instituteName = [`academic_detail.${i}.college_name`, ", ", `academic_detail.${i}.university_name`]
    acadType = [`academic_detail.${i}.degree_name`]
  }
  return (
    {
      "key": i,
      "id": i,
      "target": "academic_detail",
      "class": "borderLeft borderBottom",
      "columns": [
        {
          "grid_size": 3,
          "class": "borderRight px-0",
          "itemsClass": "px-1",
          "items": [
            {
              "data": acadType
            }
          ]
        },
        {
          "grid_size": 2,
          "class": "borderRight px-0",
          "itemsClass": "px-1",
          "items": [
            {
              "type": "DATE",
              "format": "YYYY",
              "data": `academic_detail.${i}.year_end`
            }
          ]
        },
        {
          "grid_size": 5,
          "class": "borderRight px-0",
          "itemsClass": "px-1",
          "items": [
            {
              "data": instituteName
            }
          ]
        },
        {
          "grid_size": 2,
          "class": "borderRight px-0",
          "itemsClass": "px-1",
          "items": [
            {
              "type": "SCORE",
              "data": [`academic_detail.${i}.score_value`, " ", `academic_detail.${i}.score_type`]
            }
          ]
        }
      ]
    }
  )
}

export const proDetail = (detail, i, cvPoints) => {
  let section = detail.project_id ? "project" : "professional_detail";
  let cvDetail = [],
    cvPointKeys = [],
    id = detail.project_id || detail.professional_id;
  if (cvPoints && cvPoints[id]) {
    cvDetail = cvPointDetails(cvPoints[id]);
    Object.keys(cvPoints[id]).forEach(header => {
      cvPointKeys.push(...cvPoints[id][header].map(cv => cv.cv_point_id))
    })
  }
  return ({
    "config": {},
    "key": id,
    "target": section,
    "cv_keys": cvPointKeys,
    "columns": [{
      "grid_size": 12,
      "config": {},
      "items": [],
      "rows": [{
        "config": {
          "background": "#D9D9D9"
        },
        "id": id,
        "target": section,
        "columns": [
          {
            "grid_size": 4,
            "config": {
              "border": "1px solid",
              "border-width": "0 0 1px 1px",
              // "background": "#D9D9D9",
              "font-weight": "bold",
              "text-align": "left"
            },
            "items": [
              {
                "data": `${section}.${i}.company_name`
              }
            ]
          },
          {
            "grid_size": 4,
            "config": {
              "border": "1px solid",
              "border-width": "0 0 1px 0",
              // "background": "#D9D9D9",
              "font-weight": "bold",
              "text-align": "center"
            },
            "items": [
              {
                "data": `${section}.${i}.${detail.project_id ? "project_title" : "designation_name"}`
              }
            ]
          },
          {
            "grid_size": 4,
            "config": {
              "border": "1px solid",
              "border-width": "0 1px 1px 0",
              // "background": "#D9D9D9",
              "text-align": "right"
            },
            "items": [
              {
                "type": "DATE",
                "format": "MMM'YY",
                "data": [
                  `${section}.${i}.start_date`,
                  " - ",
                  `${section}.${i}.end_date`
                ]
              }
            ]
          }
        ]
      }, ...cvDetail]
    }]

  })
}
export const projDetail = (detail, i, cvPoints) => {
  let section = "project";
  let cvDetail = [],
    cvPointKeys = [],
    id = detail.project_id
  if (cvPoints && cvPoints[id]) {
    Object.keys(cvPoints[id]).forEach(header => {
      cvPointKeys.push(...cvPoints[id][header].map(cv => {
        cvDetail.push({
          "key": cv.cv_point_id,
          "id": cv.cv_point_id,
          "target": "cv_point",
          "config": {},
          "columns": [
            {
              "config": {},
              "items": [
                {
                  "type": "LISTITEM",
                  "data": `cv_point.${cv.cv_point_id}.text`
                }
              ]
            }
          ]
        })
        return cv.cv_point_id
      }))
    })
  }
  return ({
    "config": {},
    "key": id,
    "target": section,
    "cv_keys": cvPointKeys,
    "columns": [{
      "grid_size": 12,
      "config": {
        "border-bottom": "1px solid #fff"
      },
      "items": [],
      "rows": [{
        "config": {
        },
        "id": id,
        "target": section,
        "columns": [
          {
            "grid_size": 2,
            "config": {
              "background": "#D9D9D9",
              "text-align": "center",
              "display": "flex",
              "align-items": "center",
              "justify-content": "center",
              "font-weight": "bold"
            },
            "items": [
              {
                "data": `${section}.${i}.company_name`
              }
            ]
          },
          {
            "grid_size": 8,
            "rows": [{
              "columns": [{
                "items": [
                  {
                    "data": `${section}.${i}.company_name`
                  }
                ],
                "config": {
                  "font-weight": "bold",
                  "text-align": "left"
                }
              }]
            }, ...cvDetail],
            "items": []
          },
          {
            "grid_size": 2,
            "config": {
              "background": "#D9D9D9",
              "text-align": "center",
              "display": "flex",
              "align-items": "center",
              "justify-content": "center"
            },
            "items": [
              {
                "type": "DATE",
                "format": "MMM'YY",
                "data": [
                  `${section}.${i}.start_date`,
                  " - ",
                  `${section}.${i}.end_date`
                ]
              }
            ]
          }
        ]
      }]
    }]

  })
}

export const personalDetail = (age, language) => {
  return {
    "type": "TEXT",
    "data": [
      "user_info.gender",
      ", ",
      `${age} `,
      "Years | ",
      `${language}`
    ]
  }
}

export const cvPointDetails = (cvPoints) => {
  return Object.keys(cvPoints).map(header => {
    return {
      "config": {},
      "columns": [
        {
          "config": {
            "border": "1px solid",
            "border-width": "0 1px 1px 1px"
          },
          "items": [],
          "rows": [
            {
              "columns": [
                {
                  "grid_size": 2,
                  "class": "cvHeader",
                  "config": {},
                  "items": [
                    {
                      "data": header
                    }
                  ]
                },
                {
                  "grid_size": 10,
                  "config": {},
                  "items": [],
                  "class": "cvList",
                  "rows": cvPoints[header].map(cv => {
                    return {
                      "key": cv.cv_point_id,
                      "id": cv.cv_point_id,
                      "target": "cv_point",
                      "config": {},
                      "columns": [
                        {
                          "config": {},
                          "items": [
                            {
                              "type": "LISTITEM",
                              "data": `cv_point.${cv.cv_point_id}.text`
                            }
                          ]
                        }
                      ]
                    }
                  })
                }
              ]
            }
          ]
        }
      ]
    }
  })

}
export const porDetail = (detail, i, cvPoints) => {
  let section = "position_of_responsibility";
  let cvDetail = [],
    cvPointKeys = [],
    id = detail.id
  if (cvPoints && cvPoints[id]) {
    Object.keys(cvPoints[id]).forEach(header => {
      cvPointKeys.push(...cvPoints[id][header].map(cv => {
        cvDetail.push({
          "key": cv.cv_point_id,
          "id": cv.cv_point_id,
          "target": "cv_point",
          "config": {},
          "columns": [
            {
              "config": {},
              "items": [
                {
                  "type": "LISTITEM",
                  "data": `cv_point.${cv.cv_point_id}.text`
                }
              ]
            }
          ]
        })
        return cv.cv_point_id
      }))
    })
  }
  return ({
    "config": {},
    "key": id,
    "target": section,
    "cv_keys": cvPointKeys,
    "columns": [{
      "grid_size": 12,
      "config": {
        "border-bottom": "1px solid #fff"
      },
      "items": [],
      "rows": [{
        "config": {
        },
        "id": id,
        "target": section,
        "columns": [
          {
            "grid_size": 2,
            "config": {
              "background": "#D9D9D9",
              "text-align": "center",
              "display": "flex",
              "align-items": "center",
              "justify-content": "center",
              "font-weight": "bold"
            },
            "items": [
              {
                "data": `${section}.${i}.designation_name`
              }
            ]
          },
          {
            "grid_size": 8,
            "rows": [{
              "columns": [{
                "items": [
                  {
                    "data": `${section}.${i}.college_name`
                  }
                ],
                "config": {
                  "font-weight": "bold",
                  "text-align": "left"
                }
              }]
            }, ...cvDetail],
            "items": []
          },
          {
            "class": "cvBlocks",
            "grid_size": 2,
            "config": {
            },
            "items": [
              {
                "type": "DATE",
                "format": "MMM'YY",
                "data": [
                  `${section}.${i}.start_date`,
                  " - ",
                  `${section}.${i}.end_date`
                ]
              }
            ]
          }
        ]
      }]
    }]

  })
}
export const ecDetail = (detail, i, cvPoints) => {
  let section = "extra_curricular_activity";
  let cvDetail = [],
    cvPointKeys = [],
    id = detail.extra_curricular_id
  if (cvPoints && cvPoints[id]) {
    Object.keys(cvPoints[id]).forEach(header => {
      cvPointKeys.push(...cvPoints[id][header].map(cv => {
        cvDetail.push({
          "key": cv.cv_point_id,
          "id": cv.cv_point_id,
          "target": "cv_point",
          "config": {},
          "columns": [
            {
              "grid_size": 11,
              "config": {},
              "items": [
                {
                  "type": "LISTITEM",
                  "data": `cv_point.${cv.cv_point_id}.text`
                }
              ]
            },
            {
              "grid_size": 1,
              "config": {},
              "items": [
                {
                  "type": "DATE",
                  "format": "YYYY",
                  "data": `${section}.${i}.activity_date`
                }
              ]
            }
          ]
        })
        return cv.cv_point_id
      }))
    })
  }
  return ({
    "config": {},
    "key": id,
    "target": section,
    "cv_keys": cvPointKeys,
    "columns": [{
      "grid_size": 12,
      "config": {
        "border-bottom": "1px solid #fff"
      },
      "items": [],
      "rows": [{
        "config": {
        },
        "id": id,
        "target": section,
        "columns": [
          {
            "grid_size": 2,
            "config": {
              "background": "#D9D9D9",
              "text-align": "center",
              "display": "flex",
              "align-items": "center",
              "justify-content": "center",
              "font-weight": "bold"
            },
            "items": [
              {
                "data": `${section}.${i}.activity_name`
              }
            ]
          },
          {
            "grid_size": 10,
            "rows": [...cvDetail],
            "items": []
          }
        ]
      }]
    }]

  })
}
export const reRenderCVPoints = (cvPoints, config) => {
  let academicCVPoints = filterCVPoints(cvPoints, "ACADEMIC"),
    proExpCVPoints = filterCVPoints(cvPoints, "PROFESSIONAL"),
    projectCVPoints = filterCVPoints(cvPoints, "PROJECT")
  let allAcademicPoints = Object.keys(academicCVPoints).reduce((prev, current) => {
    let temp = prev.concat(academicCVPoints[current])
    return temp
  }, [])
  let academicCVHeaders = groupCVHeaders(allAcademicPoints)
  config.section_config.academic_details.section_layout.components.rows[2].columns[0].rows = cvPointDetails(academicCVHeaders);
  let proExpRows = config.section_config.professional_experience.section_layout.components.rows[1].columns[0].rows;
  config.section_config.professional_experience.section_layout.components.rows[1].columns[0].rows = updateRows(proExpRows, proExpCVPoints)
  let projectRows = config.section_config.projects.section_layout.components.rows[1].columns[0].rows
  config.section_config.projects.section_layout.components.rows[1].columns[0].rows = updateRows(projectRows, projectCVPoints)
  let internshipRows = config.section_config.professional_details_internship.section_layout.components.rows[1].columns[0].rows
  config.section_config.professional_details_internship.section_layout.components.rows[1].columns[0].rows = updateRows(internshipRows, proExpCVPoints)
  return config;
}

const updateRows = (rows, cvPoints) => {
  if (Array.isArray(rows)) {
    rows.forEach(row => {
      let headers = groupCVHeaders(cvPoints[row.key] || [])
      row.columns[0].rows = [row.columns[0].rows[0]]
      row.columns[0].rows = row.columns[0].rows.concat(cvPointDetails(headers))
    })
  }
  return rows
}