import React, { Component } from 'react';
import { Select } from '../../global/Forms/Select'
import { Row, Col, Container, Table, Modal, ModalBody } from 'reactstrap';
import SpocNav from './SpocNav';
import companyAll from './data/companyALL.json'
import { Auth } from '../../../actions/Auth';
import Axios from 'axios';
import moment from 'moment';
import store from '../../../store';
import { connect } from 'react-redux';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import { Loader } from '../../global/Loader';
import { CONSTANTS } from '../../../constants';
import { getFullName } from '../../../actions';
import Button from '../../global/Button';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import {Errorbar} from '../../global/errorbar';
import { PermissionDenied } from '../../global/PermissionDenied';

class MeetingReport extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = ()=>{
    return(
      {
        dept: "all",
        columns: [{ id: "sno", title: "S. No." }, { id: "name", title: "Name" }, { id: "tracking", title: "Tracking" }, { id: "contacted", title: "Contacted" }, { id: "interested", title: "Interested" }, { id: "confirmed", title: "Confirmed" }, { id: "application", title: "Application Open" }],
        nav: {process_name: "Meeting Report", process_type: "meeting-report", process_id: 4  },
        meetingColumns:[{ id: "sno", title: "S. No." }, { id: "name", title: "Name" },{id:'meetingCOunt', title:'Meeting Count'}],
        processList: [],
        ptList: [],
        isLoading: false,
        totalTracking:0,
        totalContacted:0,
        totalInterested:0,
        totalConfirmed:0,
        collegeList:[],
        filterDate:false,
        startTime: null,
        endTime:  null,
        start :null,
        end: null,
        startDate : null,
        endDate : null,
        campusList:[],
        selectedCollege:'',
        selectedCampus:'',
        selectedProcess:'',
        totalOpenApplication:0,
        companyModal: [],
        errorbar : false,
        message : ''
      }
    )
  }

  closebar = ()=>{
    this.setState({errorbar : false});
  }


  async componentWillMount() {
    let permissions = store.getState().role.hasPermission;
    if (store.getState().role.role_type === "PT" && !(permissions.reportMeeting && permissions.reportMeeting.permission_access && permissions.reportMeeting.permission_access.read)) {
      this.props.history.push("/pt/performance/company-report")
        return
    }
    this.init()
  }

  instiDetail = async () =>{
      let campusResTemp, degreeList;
      await Axios.all([Auth.Spoc.getInstitutes()])
      .then(Axios.spread((res1, res2) => {
        campusResTemp = res1;
      }));
      let detailsParams = new URLSearchParams();
      campusResTemp.data.forEach((item, i)=>{
        detailsParams.append("college_id", item.college_id);
        detailsParams.append("campus_id", item.campus_id);
      })
      let details = await Auth.Spoc.getInstituteDetails(detailsParams);
      this.setState({
        campusList:details.data.campusDetails,
        collegeList:details.data.collegeDetails,
        selectedCollege:details.data.campusDetails[0].college_id,
      })
  }

  init = async () => {
    this.setState({ isLoading: true })
    let redux = store.getState(),
      { instituteDetail } = redux.role;
    if (instituteDetail.campus_id) {
      try {
        let processResp = await Auth.Processes.getProcesses(instituteDetail.campus_id)
        this.setState({
          processList: processResp.data,
          selectedCampus:instituteDetail.campus_id
        })
        let param = [{key:'campus_id', value:parseInt(instituteDetail.campus_id)}];
        this.getReports(param);
      } catch (e) {
        this.setState({errorbar : true})
        if(e.response && e.response.data){
          this.setState({message : e.response.data.error.toString()})
        }
        else{
          this.setState({message : 'Something went wrong! Please try again'});
        }
      }
    } else {
        this.instiDetail();
    }
    this.setState({ isLoading: false })
  }


  changeCollege = (target) => {
    this.setState({selectedCollege: target.value, selectedCampus:"",ptList: []})
  }

  changeCampus = async (target) =>{
    await this.setState({selectedCampus: target.value});
    if(target.value === ""){
      this.setState({ptList: []})
      return }
    let param = [{key:'campus_id', value:parseInt(target.value)}];
    this.getReports(param);
  }

  downloadMeetingReport = async()=>{
    let {start , end , selectedCampus} = this.state;
    this.setState({ isLoading: true })
    let startDate , endDate;
    let redux = store.getState(),
      { instituteDetail } = redux.role;
    let downloadParams = {};
    if(selectedCampus){
      downloadParams.campus_id = selectedCampus;
    }
    if(start && end){
      downloadParams.start_date = moment(start).format('YYYY:MM:DD')+" "+moment(moment().startOf('day')).format('HH:mm:ss');
      downloadParams.end_date = moment(end).format('YYYY:MM:DD')+" "+moment(moment().endOf('day')).format('HH:mm:ss');
    }
    try{
      let meetingReportDownloadResp = await Auth.Reports.meetingReportDownload(downloadParams);
      const url = window.URL.createObjectURL(new Blob([meetingReportDownloadResp.data]));
      const link = document.createElement('a');
      link.href = url;
      let fileName = 'Meeting Report'+'.csv';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.setState({ isLoading: false })
    }
    catch(e){
      this.setState({ isLoading: false })
      this.setState({errorbar : true});
      if(e.response && e.response.data ){
        this.setState({message : e.response.data.error})
      }
      else{
        this.setState({message : 'Something went Wrong! Please try again'})
      }
    }
  }


  changeEnd = (end) => {
    this.setState({
      end
    })
  }

  changeStart = (start) => {
    this.setState({
      start
    })
  }
  // OnChangeStartTime = (e)=>{
  //   this.setState({
  //     startTime : moment(e)
  //   })
  // }
  // onChangeEndTime = (e)=>{
  //   this.setState({
  //     endTime : moment(e)
  //   })
  // }


  getReports = async (param) =>{
    this.setState({isLoading: true});
      try {
        let meetingResponse = await Auth.Reports.getMeetingReport(param)
        this.setState({ptList: meetingResponse.data})
      } catch (e) {
        this.setState({errorbar : true})
        if(e.response && e.response.data && e.response.data.error){
          this.setState({message : e.response.data.error.toString(),ptList: []})
        }
        else{
          this.setState({message : 'Something went wrong! Please try again'});
        }
      }
    this.setState({isLoading: false});

  }
  // filterMeetings = ()=>{
  //   let {start,end,startTime,endTime} = this.state;
  //   if(start && end && startTime && endTime){
  //     let startDate = (moment(start).format('YYYY-MM-DD')+" "+moment(startTime).format('HH:mm:ss'));
  //     let endDate = (moment(end).format('YYYY-MM-DD')+" "+moment(endTime).format('HH:mm:ss'));
  //     this.init(startDate , endDate);
  //     this.setState({
  //       startDate,
  //       endDate
  //     })
  //   }
  //   else{
  //     this.setState({errorbar : true});
  //     this.setState({message : 'Enter both Start Date and End Date to filter results'})
  //   }
  // }


  filterMeetings = ()=>{
    let {start,end,startDate, endDate, selectedCampus} = this.state;
    if(selectedCampus===''){
      this.setState({
        errorbar : true,
        message : 'Please select a campus',
        ptList: []})
      return
    }
    if(moment(end).diff(moment(moment(start).format('YYYY-MM-DD ')), "days") < 1){
          this.setState({
            errorbar : true,
            message : 'End date should be greater than start date',
            ptList: []})
          return
    }
    if(start && end){
      let startDate = moment(start).format('YYYY:MM:DD')+" "+moment(moment().startOf('day')).format('HH:mm:ss');
      let endDate = moment(end).format('YYYY:MM:DD')+" "+moment(moment().endOf('day')).format('HH:mm:ss');
      this.setState({
        startDate,
        endDate
      })
      let param = [{key:'campus_id', value:parseInt(selectedCampus)},{key : 'start_date' , value : startDate},{key : 'end_date' , value : endDate}];
      this.getReports(param);
    }
    else{
      this.setState({errorbar : true});
      this.setState({message : 'Enter both Start Date and End Date to filter results'})
    }
  }

  clearFilterMeetings = async()=>{
    let param = [{key:'campus_id', value:parseInt(this.state.selectedCampus)}];
    this.getReports(param);
    await this.setState({
        start :null,
        end: null,
        startDate : null,
        endDate : null,
    });
  }


  toggleCompanyModel = async (companyDetails) => {
    if(!companyDetails.length){
      this.setState({companyModal : []})
      return false;
    }
    companyDetails = companyDetails.filter((value, index, self) => self.indexOf(value) === index);
    this.setState({companyModal: companyDetails});
  }
  render() {
    
    let { nav, isLoading,collegeList,filterDate, campusList, processList,selectedCampus,selectedCollege,selectedProcess, columns,meetingColumns, ptList, companyModal, totalTracking, totalContacted,totalInterested,totalConfirmed,totalOpenApplication , start , end , startDate , endDate } = this.state;
    let campusDataList = campusList.filter(campus =>{
      return campus.college_id == selectedCollege;
    });
    return (
      <Container className="apply">
        <Loader show={isLoading} />
        <SpocNav process={nav} downloadReport={this.downloadMeetingReport} type="MeetingReport" />
        <Row className="mx-0 my-3">
        {collegeList.length > 0 &&
            <Col xs="3">
              <Select onChange={({ target }) => this.changeCollege(target)}>
                {collegeList.map((college, i) =>
                  <option key={i} value={college.college_id}>{college.name}</option>
                )}
              </Select>
            </Col>
        }
        {campusDataList.length > 0 &&
            <Col xs="3">
              <Select value={selectedCampus} onChange={({ target }) => this.changeCampus(target)}>
                  <option value="">Select Campus</option>
                  {campusDataList.map((campus, i) =>
                    <option key={i} value={campus.campus_id}>{campus.name}</option>
                  )}
              </Select>
            </Col>
        }
        </Row>
        <div className="align-items-center mb-5 mx-0">
          <Row className="justify-content-between align-items-center mx-0">
            <span className="r-heading-3">Meeting Report</span>{/*
                        <Button className="font-weight-normal mx-3  px-5" color="dark" onClick={()=>this.setState({filterDate: !filterDate})}>Filter by time interval</Button>*/}
          </Row>
          <div className="m-3">
            <Row>
              <Col xs="auto">
                <Row>
                  <Col className="m-2"><span className="r-subheading-4">Start Date</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs="auto" className="pr-0 m-2">
                    <SingleDatePicker
                    value={start}
                    onDateChange={this.changeStart}
                    id="dob" />
                  </Col>
                </Row>
              </Col>
              <Col xs="auto">
                <Row>
                  <Col className="m-2"><span className="r-subheading-4">End Date</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs="auto" className="pr-0 m-2">
                    <SingleDatePicker
                    value={end}
                    onDateChange={this.changeEnd}
                    id="dob" />
                  </Col>
                </Row>
              </Col>
              <Col xs="auto" className="my-5">
                {startDate && endDate ? <Button  className="my-2 border" onClick={()=>this.clearFilterMeetings()}>Clear</Button> : <Button color="dark" className="my-2" onClick={()=>this.filterMeetings()}>Filter</Button> }
              </Col>
            </Row>
            {!!startDate && !!endDate && <Row>
              <Col>
              <em className="text-muted r-text--sm">Data showing for {moment(start).format("Do MMM YYYY")} - {moment(end).format("Do MMM YYYY")}</em>
              </Col>
            </Row>
            }
          </div>

            <Table className="post text-center">
              <thead>
                <tr className="tableheader">
                  {meetingColumns.map((col, i) =>
                    <th  key={i}>
                      {col.title}
                    </th>
                  )}
                  <th>Company</th>
                </tr>
              </thead>
              <tbody>
                { (ptList.length>0 )&&
                  ptList.map((row, i) => {
                    var meetingType = (row.meeting_types || []).reduce((obj, value) => (obj[value] = (obj[value] || 0) + 1, obj), {});
                    return (
                      <tr className="borderBottomlightblack " key={i}>
                        <td >
                          {i+1}
                        </td>
                        <td >
                          {getFullName(row) || "-"}
                        </td>
                        <td >
                          <Button onClick={() => this.toggleCompanyModel(row.company_details || [])}>{row.meeting_count || 0}</Button>
                          <em className="text-muted r-text--sm">{!!row.meeting_count && "("}
                          {!!row.meeting_count && Object.keys(meetingType).map(type => `${meetingType[type]} ${type}`).join(", ")}
                          {!!row.meeting_count && ")"}</em>
                        </td>
                      <td>
                        {row.company_details && row.company_details.length ? row.company_details.slice(0, 2).join(", ") : " - "}{ }
                        {row.company_details && row.company_details.length > 2 && <Button color="link-blue" onClick={() => this.toggleCompanyModel(row.company_details || [])}> ...more</Button> }
                      </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
            <Modal zIndex="99999" isOpen={filterDate} toggle={() => this.setState({filterDate: !filterDate})}>
            <ModalBody>
                <Row className='align-items-center'>
                      <Col xs="5" className="pr-0">
                          <span className="r-subheading-4">
                          Start Time
                          </span>
                          <SingleDatePicker
                          value={this.state.start}
                          onDateChange={this.changeStart}
                             />
                      </Col>
                      <Col xs="5" className="pr-0">
                          <span className="r-subheading-4">
                          End Time
                          </span>
                          <SingleDatePicker
                          value={this.state.end}
                          onDateChange={this.changeEnd}
                             />
                        </Col>
                        <Col xs='6' className='mt-5'>
                              <Button className="font-weight-normal mx-3 px-5" color="dark" onClick={()=>this.setState({filterDate: !filterDate})}>Submit</Button>
                        </Col>
                </Row>
            </ModalBody>
          </Modal>
          <Modal zIndex="99999" isOpen={companyModal.length > 0} toggle={() => this.setState({ companyModal: [] })}>
          <ModalBody>
            <Table>
              <thead>
                <tr className="tableheader">
                  <th>Company Name</th>
                </tr>
              </thead>
              <tbody>
                {companyModal.map((company, index) => {
                  return <tr
                    className="borderBottomlightblack"
                    key={index}>
                    <td>{company || ""}</td>
                  </tr>
                })}
              </tbody>
            </Table>
          </ModalBody>
        </Modal>
        </div>
        <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      </Container>
    )
  }
}
const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(MeetingReport);
