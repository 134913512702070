import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { SelectList, Select ,MultiSelectList} from './Forms/Select';
import { Auth } from '../../actions/Auth';
import { Loader } from './Loader';
import store from '../../store.js';

class FilterComponent extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      loader:false,
      batchList:{},
      depList: {},
      groupList:{},
      studentFilters: {
        batches: [],
        departments: [],
        groups:[],
      }
    }
  }


  addToFilters = async (e, filter) => {
    let { studentFilters, depList, batchList,groupList } = this.state;
    this.setState({loader:true})
    if (filter === "batches") {
      if (e.target.value) {
        let bt = batchList[e.target.value]
        studentFilters[filter].push(e.target.value)
        try {
          let res = await Auth.General.getDegreesCampusDepartment(store.getState().role.instituteDetail.campus_id, bt.campus_degree_id);
          var departments = res.data[0].departments;

          if(this.props.allStudents){
            var studentDepartmentIds = this.props.allStudents.map(o => o.degree_department_id);
            departments = departments.filter(dept => studentDepartmentIds.includes(dept.degree_department_id))
          }

          let depts = departments.reduce((prev, current) => {
            current.batch_name = bt.batch_name
            current.batch_id = bt.batch_id
            prev[current.batch_id + "-" + current.degree_department_id] = current;
            return prev;
          }, {});
          depList = Object.assign({}, depList, depts);

          

          this.setState({ depList });
        } catch (e) {
          console.log(e);
        }
      }
    }
    else if(filter ==="departments"){
        if (e.target.value) {
          let dept = depList[e.target.value]
          studentFilters[filter].push(e.target.value)
          try {
            let res = await Auth.PtStudents.getFilterGroup(e.target.value.split("-")[0], dept.degree_department_id);
            let groups = res.data;
            if(this.props.allStudents){
              var studentGroupIds = this.props.allStudents.map(o => o.group_id);
              groups = groups.filter(dept => studentGroupIds.includes(dept.group_id))
            }

            let group = groups.reduce((prev, current) => {
              prev[current.batch_id + "-" + current.degree_department_id +"-"+current.group_id] = current;
              return prev;
            }, {})
            groupList = Object.assign({}, groupList, group)
            this.setState({ groupList });
          } catch (e) {
            console.log(e);
          }
        }
    }
    else {
      if (e.target.value)
        studentFilters[filter].push(e.target.value)
    }
    this.props.filterStudents(studentFilters, batchList,depList,groupList);
    this.setState({ studentFilters })
    this.setState({loader:false})
  }


  removeFromFilters = (val, filter) => {
    let { studentFilters, depList, batchList,groupList } = this.state;
    if (filter === "batches") {
      let deps = Object.keys(depList).reduce((prev, current) => {
        if (current.split("-")[0] != val)
          prev[current] = depList[current]
        return prev
      }, {})
      let groups = Object.keys(groupList).reduce((prev, current) => {
        if (current.split("-")[0] != val)
          prev[current] = groupList[current]
        return prev
      }, {});
      studentFilters["departments"] = studentFilters["departments"].filter(item =>{ if(item.indexOf(val)<0){ return item;} });
      studentFilters["groups"] = studentFilters["groups"].filter(item =>{ if(item.indexOf(val)<0){ return item;} });
      this.setState({ depList: deps,groupList:groups });
    }
    else if (filter === "departments") {
      let groups = Object.keys(groupList).reduce((prev, current) => {
        if (current.split("-")[1] != val.split("-")[1])
          prev[current] = groupList[current]
        return prev
      }, {})
      studentFilters["groups"] = studentFilters["groups"].filter(item =>{ if(item.indexOf(val)<0){ return item;} });
      this.setState({ groupList:groups });
    }
    studentFilters[filter].splice(studentFilters[filter].indexOf(val), 1)
    this.props.filterStudents(studentFilters, batchList,depList,groupList);
    this.setState({ studentFilters })
  }

  handleCheck = (e, filter) =>{
    if(e.target.checked){
      this.addToFilters(e, filter)
    }
    else{this.removeFromFilters(e.target.value, filter)}
  }


 async componentDidMount () {
      let responseBatch = await Auth.Batch.getBatchDetail();
      var batchList = responseBatch.data;
      if(this.props.allStudents){
        var studentBatchIds = this.props.allStudents.map(o => o.batch_id);
        batchList = batchList.filter(batch => studentBatchIds.includes(batch.batch_id))
      }
      this.setState({
        batchList: batchList.reduce((prev, current) => { prev[current.batch_id] = current; return prev; }, {})
      })
  }


  render() {
    let { isLoading,  studentFilters,depList,batchList,groupList,loader } = this.state;
    return (
                    <Row className="mt-5 justify-content-center">
                      <Loader show={loader} />
                      <Col xs={4}>
                          <h4 className="r-subheading-3">Batch</h4>
                          <MultiSelectList title={"Select"}>
                            <ul className="list-unstyled m-0">
                              {Object.keys(batchList).map((bt, i) => {
                                return (
                                  <li key={i}>
                                    <input name="value" onChange={(e) => this.handleCheck(e, "batches")} checked={studentFilters.batches.indexOf(bt) > -1}  type="checkbox" value={bt} />
                                    <label>{batchList[bt].batch_name}</label>
                                  </li>
                                )
                              })}
                            </ul>
                          </MultiSelectList>
                          {/*<Select onChange={(e) => this.addToFilters(e, "batches")}>
                                                      <option value="">Select batch</option>
                                                      {Object.keys(batchList).map(bt => {
                                                        if (studentFilters.batches.indexOf(bt) < 0)
                                                          return <option key={bt} value={bt}>{batchList[bt].batch_name}</option>
                                                      })}
                                                    </Select>*/}
                          {studentFilters.batches.map((bt, i) => {
                            return (<div key={i}>
                              {(batchList[bt]) ?
                                <div key={bt} className="r-tag mt-2">
                                  {batchList[bt].batch_name}
                                  <button onClick={() => this.removeFromFilters(bt, "batches")} type="button" className="r-tag__delete fi flaticon-plus-1"></button>
                                </div> : null}</div>
                            )
                          })}
                      </Col>
                      <Col xs={4}>
                        <h4 className="r-subheading-3">Department</h4>
                          <MultiSelectList title={"Select"}>
                            <ul className="list-unstyled m-0">
                              {Object.keys(depList).map((dept, i) => {
                                return (
                                  <li key={i}>
                                    <input name="value" onChange={(e) => this.handleCheck(e, "departments")} checked={studentFilters.departments.indexOf(dept) > -1}  type="checkbox" value={dept} />
                                    <label>{depList[dept].department_name} - {depList[dept].batch_name}</label>
                                  </li>
                                )
                              })}
                            </ul>
                          </MultiSelectList>
                        {/*<Select onChange={(e) => this.addToFilters(e, "departments")}>
                                                  <option value="">Select department</option>
                                                  {Object.keys(depList).map(dept => {
                                                    if (studentFilters.departments.indexOf(dept) < 0)
                                                      return <option key={dept} value={dept}>{depList[dept].department_name} - {depList[dept].batch_name}</option>
                                                  })}
                                                </Select>*/}
                                                {studentFilters.departments.map((dept, i) => {
                                                   return (<div key={i}>
                                                    {(depList[dept])?<div key={dept} className="r-tag mt-2">
                                                                                  {depList[dept].department_name} - {depList[dept].batch_name}
                                                                                  <button onClick={() => this.removeFromFilters(dept, "departments")} type="button" className="r-tag__delete fi flaticon-plus-1"></button>
                                                                                </div>:null}</div>
                                                  )}) }
                      </Col>
                      <Col xs={4}>
                        <h4 className="r-subheading-3">Groups</h4>
                          <MultiSelectList title={"Select"}>
                            <ul className="list-unstyled m-0">
                              {Object.keys(groupList).map((group, i) => {
                                return (
                                  <li key={i}>
                                    <input name="value" onChange={(e) => this.handleCheck(e, "groups")} checked={studentFilters.groups.indexOf(group) > -1}  type="checkbox" value={group} />
                                    <label>{groupList[group].group_name}</label>
                                  </li>
                                )
                              })}
                            </ul>
                          </MultiSelectList>
                        {/*<Select onChange={(e) => this.addToFilters(e, "groups")}>
                                                  <option value="">Select group</option>
                                                  {Object.keys(groupList).map(group => {
                                                    if (studentFilters.groups.indexOf(group) < 0)
                                                      return <option key={group} value={group}>{groupList[group].group_name}</option>
                                                  })}
                                                </Select>*/}
                                                {studentFilters.groups.map((group, i) => {
                                                   return (<div key={i}>
                                                    {(groupList[group])?<div key={group} className="r-tag mt-2">
                                                                                  {groupList[group].group_name} 
                                                                                  <button onClick={() => this.removeFromFilters(group, "groups")} type="button" className="r-tag__delete fi flaticon-plus-1"></button>
                                                                                </div>:null}</div>
                                                  )}) }
                      </Col>
                    </Row>
             
    )
  }
}

export default FilterComponent;