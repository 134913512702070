import React, { Component } from 'react';
import { Col, Row, Container, Modal,Table, ModalBody,Spinner } from 'reactstrap';
import { Auth } from '../../../actions/Auth';
import store from '../../../store';
import moment from 'moment';
import Button from '../../global/Button';
import { Loader } from '../../global/Loader';
import Autosuggest from 'react-autosuggest';
import { InputField } from '../../global/Forms';
import { Errorbar } from '../../global/errorbar';
import { getFullName, uploadFile } from '../../../actions';
import PaginationComponent from '../../global/PaginationComponent.js';
import { Collapse, CardBody, Card , Input} from 'reactstrap';



class Attendance extends Component {
  state = {
    events: [],
    upcomingEvents: [],
    completedEvents: [],
    selectedTab:1,
    currentPage: 0,
    rowsPerPage: 10,
    pageCurrent: 0,
    rows: 10,
    markedList: [],
    modal: 0,
    qrCode: null,
    value: '',
    query:'',
    suggestions: [],
    attendeeList:[],
    stDetails:'',
    isLoading: false,
    errorbar:false,
    attLoader:false,
    sortOrderName:true,
    sortOrderRoll:true,
    columns:[{id: "sno", title: "S. No."},{id: "name", title: "Name"},{id:"email", title:"Email"},{id:"roll_no", title:"Roll No."},{id:"attendance_status", title:"Status"}],
    message:'', 
    attendExcelFile : null,
    isOpenUploadAttendance : false,
  }
  async componentWillMount() {
    if(this.props.location.state && this.props.location.state.event){
      this.openModal(this.props.location.state.event);
      this.props.history.push("/pt/attendance");
    }
    let redux = store.getState();
    let {events,upcomingEvents,completedEvents} = this.state;
    this.setState({ isLoading: true })
    try {
      let eventsResp = await Auth.Batch.getEvent({ attendee_id: redux.user.id });
      eventsResp.data[0].event_details.forEach(et =>{
        if(moment(et.end_date).isAfter(new Date())){
          upcomingEvents.push(et);
        }
        else{
          completedEvents.push(et);
        }
      })
      upcomingEvents = upcomingEvents.sort((a, b) => {
              if (moment(a.start_date).isAfter(b.start_date))
                  return -1 
              if (!moment(a.start_date).isAfter(b.start_date))
                  return 1
              return 0
            })
      completedEvents = completedEvents.sort((a, b) => {
              if (moment(a.start_date).isAfter(b.start_date))
                  return -1 
              if (!moment(a.start_date).isAfter(b.start_date))
                  return 1
              return 0
            })
      this.setState({ events,upcomingEvents,completedEvents })
    } catch (e) {
      console.log(e)
    }
    this.setState({ isLoading: false })
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  generateQR = async () => {
    let {attendeeList} = this.state;
    clearInterval(this.interval)
    try {
      let qrResp = await Auth.Attendance.generateQRCode({ event_id: this.state.modal })
      this.setState({ qrCode: qrResp.data.qrCode })
      this.interval = setInterval(() => {
        let { modal, qrCode } = this.state
        if (modal > 0 && qrCode !== null) this.generateQR()
      }, 8000)
    } catch (e) {
      console.log(e)
    }
    Auth.Attendance.getList({ event_id: this.state.modal }).then((resp) => {
      this.setState({ markedList: resp.data })
      let x = resp.data.reduce((obj, hash) => { obj[hash.attendee_id] = true; return obj },{})
      attendeeList.map(st=>{
                if (x[ st.attendee_id ] ){
                    st.attendee_info["attendance_status"] = 'Marked';
                }
                else st.attendee_info["attendance_status"]=null;
      })
      this.setState({attendeeList});
    })
  }
  openModal = async (evt) => {
    // try {
    //   await Auth.Batch.getAttendeesList({})
    // }catch (e) {
    //   console.log(e)
    // }
    await this.setState({ modal: evt.event_id, isLoading: true, markedList: [] });
    let resp = await Auth.PtStudents.getAttendeeSt(evt.event_id);
    this.setState({attendeeList:resp.data});
    clearInterval(this.interval)
    await this.generateQR();
    this.setState({ isLoading: false })
  }


  handleSelected = (selectedPage) => {
    this.setState({ currentPage: selectedPage-1 });
  }


  onChange = (event, { newValue }) => {
    if(event.type == 'click'){
            this.setState({
              value: newValue.attendee_info.roll_no,
              stDetails:newValue
            });
    }
    else{
          this.setState({
            value: newValue
          });
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.state.attendeeList.filter(y => {
      if(y.attendee_info.roll_no && y.attendee_info.roll_no.trim().toLowerCase().includes(inputValue)){
        return y;
      } 
    });
  };

  closebar = () => {
    this.setState({ errorbar: false, message:'' });
  }

  markAttendance = async (e,stDetails) =>{
    e.preventDefault()
    // let {stDetails} = this.state;
    if (!stDetails) {
      this.setState({ errorbar: true, message:'Student is not invited to the event' });
      return ; 
    }
    this.setState({isLoading: true})
    let body = {
               "student_id":stDetails.attendee_info.student_id,
               "event_id":stDetails.event_id,
               "attendee_id": stDetails.attendee_id,
               "attendance_category":'EVENT',
               "attendance_type":"ENTRY",
               "current_role_id":store.getState().role.role_id 
        }
    try{  
      await Auth.PtStudents.markManual(body);
      await this.generateQR();
      this.setState({isLoading: false})
      this.setState({stDetails:'',value: ''});
    }
     catch (e) {
      this.setState({isLoading: false})
      let errorbar = {
        show : true
      }
      if (e.response && e.response.data.error)
      errorbar.message = e.response.data.error.toString()
      else
      errorbar.message = "Something went wrong, Please try again later";
      this.setState({ errorbar })
    }
  }


  unMarkAttendance = async (e,stDetails) =>{
    e.preventDefault()
    this.setState({isLoading: true})
    let body = {
               "event_id":stDetails.event_id,
               "attendee_id": stDetails.attendee_id,
               "attendance_category":'EVENT',
               "attendance_type":"ENTRY"
        }
    try{  
      await Auth.PtStudents.unMarkAttendance(body);
      await this.generateQR();
      this.setState({isLoading: false})
    }
     catch (e) {
      this.setState({isLoading: false})
      this.setState({ errorbar: true, message:'Sorry! Something went wrong. Please try again!' });
    }
  }
  selectHandle = (selectedPage) =>{
    this.setState({ pageCurrent: selectedPage-1 });
  }


  sortByRoll = (type) =>{
    let {attendeeList , sortOrderRoll, sortOrderName} = this.state;
    attendeeList = this.state.attendeeList.sort((a, b) => {
      let nameA=a.attendee_info[type].toString().toLowerCase();
      let nameB=b.attendee_info[type].toString().toLowerCase();
      if(sortOrderRoll || sortOrderName) {
           this.setState({sortOrderRoll:false});
           this.setState({sortOrderName:false});
            if (nameA < nameB)
                return -1
            if (nameA > nameB)
                return 1
            return 0
      }
      else{
            this.setState({sortOrderName:true});
           this.setState({sortOrderRoll:true});
            if (nameA < nameB)
                return 1
            if (nameA > nameB)
                return -1
            return 0
      }
    })
    this.setState({attendeeList});
  }


  onSearch = (target) => {
    this.setState({ query: target.value });
    if (target.value !== "") {
      this.setState({ pageCurrent: 0 });
    }
  }

  markAttendanceByExcel = async (e) => {
    e.preventDefault();
    e.persist();
    let { attendeeList, attendExcelFile } = this.state;
    this.setState({isLoading: true})
    try {
      let fileResp = await uploadFile(attendExcelFile, "excel_upload")
      var body = {
          key : fileResp.file_url ,
          student_id : attendeeList.map(attendee => attendee.attendee_info.student_id) ,
          event_id  : this.state.modal,
      }
      let resp = await  Auth.Attendance.uploadAttendanceExcel(body);
      await this.generateQR();
      this.setState({isOpenUploadAttendance : false});
      e.target.reset()
    } catch (e) {
      console.log(e);
      let errorbar = {
        show : true
      }
      if (e.response && e.response.data.error)
      errorbar.message = e.response.data.error.toString()
      else
      errorbar.message = "Something went wrong, Please try again later";
      this.setState({ errorbar })
    }
    this.setState({isLoading: false})

  }


  render() {
    let { upcomingEvents,query,completedEvents,selectedTab,currentPage,rowsPerPage,pageCurrent,rows,attendeeList, modal, qrCode,columns, isLoading, markedList , isOpenUploadAttendance, attendExcelFile} = this.state;
    let { value, suggestions } = this.state;
    let length = pageCurrent*rows;
    let events = (selectedTab === 2)?completedEvents:upcomingEvents;
    let attendCount = 0;
    attendeeList.forEach(st =>{ 
      if(st.attendee_info["attendance_status"] === 'Marked'){
        attendCount += 1;
      }
    });
    attendeeList = attendeeList.filter(row => {
      let name = getFullName(row.attendee_info);
      return name.toLowerCase().includes(query.toLowerCase()) || row.attendee_info.roll_no.includes(query)
    });
    const inputProps = {
      placeholder: 'Type roll number to mark attendance',
      value,
      onChange: this.onChange
    };
    return (
      <Container>
        <Loader show={isLoading} />
        <Row className="py-3">
          <Col xs={12}>
            <h1 className="r-heading-3">Events List</h1>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col xs={2} >
            <Button onClick={() => this.setState({ selectedTab: 1 })} className={`px-2 text-left r-btn--no-radius ${selectedTab === 1 ? "apply__tab--selected" : ""}`}>
              <span className="d-block r-heading-4 r-text--brandB">{upcomingEvents.length}</span>
              <span className="d-inline-block r-subheading-4">Upcoming Events</span>
            </Button>
          </Col>
          <Col xs={2} >
            <Button onClick={() => this.setState({ selectedTab: 2 })} className={`px-2 text-left r-btn--no-radius ${selectedTab === 2 ? "apply__tab--selected" : ""}`}>
              <span className="d-block r-heading-4 r-text--brandB">{completedEvents.length}</span>
              <span className="d-inline-block r-subheading-4">Completed Events</span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {
              events.slice( currentPage * rowsPerPage, currentPage*rowsPerPage +rowsPerPage  ).map((evt, i) => {
                let days = moment(evt.end_date || moment.now()).diff(moment(evt.start_date || moment.now()), "days");
                let expiry = moment(evt.end_date || moment.now()).diff(moment(moment.now()), "days");
                return (
                  <Row key={i} className="border-bottom mb-3 pb-3">
                    <Col xs={4}>
                        <h3 className="r-subheading-3 mb-0 mt-1">{evt.title}</h3>
                        <span className="r-text--sm r-text--grey-4 mt-0">{moment(evt.start_date).format('MMMM Do YYYY, h:mm a')} - {moment(evt.end_date).format('h:mm a')}{(days>0)?<strong className="r-subheading-4 r-text--dark "> +{days}</strong>:null}</span>
                    </Col>
                    <Col xs={6}>
                      <div className="selectable r-text--blue-3 text-center col-4">
                        <h1 className="r-subheading-3 mb-0 mt-1">{evt.no_of_attendees}</h1>
                        <span className="r-text--sm r-text--grey-4 mt-0">Attendees</span>
                      </div>
                    </Col>
                    <Col xs={2}>
                      <Button color="blue" onClick={() => this.openModal(evt)}>Mark Attendance</Button>
                    </Col>
                  </Row>
                )
              })
            }
          </Col>
        </Row>
       <Row className="justify-content-between align-items-center" >
                   <Col xs="auto" className="">
                     <Row className="justify-content-between align-items-center">
                     <span className="px-1 d-inline-block r-subheading-4 " style={{fontSize:"15px"}}>Total events are {events.length}</span>
                     <span className="px-3 d-inline-block r-subheading-4 " style={{fontSize:"15px"}}>View</span>
                     <div className="r-dropdown border-0">
                         <select value={rowsPerPage} name="rowsPerPage" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={(e) => {this.setState({currentPage:0, [e.target.name]: e.target.value})}}>
                           <option   value="10" >10</option>
                           <option value="15">15</option>
                            <option   value="20" >20</option>
                           <option value="25">25</option>
                            <option   value="50" >50</option>
                           <option value="100">100</option>
                         </select>
                       <i className="fi flaticon-arrow"/>
                     </div>
                     </Row>
                   </Col>
                   <Col xs="auto">
                       <PaginationComponent
                           totalItems={events.length}
                           pageSize={rowsPerPage}
                           onSelect={this.handleSelected}
                           maxPaginationNumbers={3}
                           activePage={currentPage+1}
                         />
                   </Col>
               </Row>
        <Modal size="xl" zIndex="555555" isOpen={modal > 0} toggle={() => this.setState({ modal: 0, qrCode: null })}>
          <ModalBody>
            <Row>
              <Col xs={3}>
                {qrCode !== null && <img style={{width:'100%'}} src={qrCode} />}
                  <Row>
                    <Col xs={12} className='text-center col-12'>
                       <span className='r-subheading-1 '>Scan this QR Code for attendance</span>
                    </Col>
                  </Row>
              </Col>
              <Col xs={9}>
                <Row className='align-items-center justify-content-between mb-3'>
                  <Col xs={6}>
          <span className='r-subheading-1'>Attendance marked - {attendCount} / {attendeeList.length}</span>
                  </Col>
                  <Col xs={6}>
                    <Row className='align-items-center'>
                        {/* <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                          getSuggestionValue={(suggestion) => suggestion}
                          renderSuggestion={(suggestion) => <div>{getFullName(suggestion.attendee_info)}</div>}
                          inputProps={inputProps}
                        />
                        <Button onClick={(e) => this.markAttendance(e, this.state.stDetails)} className="float-right mr-3 my-2" color="dark r-btn--no-radius">Mark</Button> */}
                        <Col>
                           <Button color="blue" onClick={()=> {this.setState({isOpenUploadAttendance : !isOpenUploadAttendance})}}>Upload Attendance</Button>
                             <form onSubmit={this.markAttendanceByExcel}>
                                  <Collapse isOpen={isOpenUploadAttendance} >
                                      <Card className="mt-2">
                                        <CardBody>
                                        {/* <h3>Select excel file to upload</h3> */}

                                        <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                                          <div className="border text-center font-weight-normal mx-3 py-1  px-3  " color="outline-dark-v">
                                            {(attendExcelFile !== null) ? attendExcelFile.name : "Upload File"}
                                            <i className=" r-text--xl fi flaticon-upload r-text--grey-4 ml-3" style={{ fontSize: "16px" }} />
                                          </div>
                                          <input id="file-input" required type="file" name="file" onChange={(e) => this.setState({attendExcelFile : e.target.files[0]})} style={{ display: "none" }} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                                        </label>
                                          <a href="/attendance.xlsx" download>Download Sample</a>
                                              <div className="mt-3 float-right">
                                                <Button color="dark" className="mr-2">Upload</Button>
                                                {/* <Button type="button" color="dark" onClick={()=> {this.setState({isOpenUploadAttendance : false})}}>Cancel</Button> */}
                                              </div>
                                        </CardBody>
                                      </Card>
                                    </Collapse>
                              </form>
                        </Col>
                     </Row>
                  </Col>
                </Row>
                <Table >
                      <thead>
                        <tr  className="tableheader">
                                {columns.map((col,i) =>
                                {  if(col.id === "sno"){
                                          return(
                                            <th key={i} >
                                              S.No.
                                            </th>
                                          )
                                        }
                                        else if(col.id ==="name"){
                                         return ( <th key={i}>
                                                            <Row className="justify-content-start align-items-center mx-0">
                                                              <Col xs={8} className="px-2 borderBottomBlack">
                                                                <InputField className="tableinput r-nav__search" placeholder="Search Name / Roll No" type="text" style={{ background: "#c4dcf3" }} onChange={({ target }) => this.onSearch(target)} />
                                                                <span className="fi flaticon-search" ></span>
                                                              </Col>
                                                              <Col  xs={4} className="px-0">
                                                                  <Button onClick={() => this.sortByRoll('first_name')} className="rotate--90">
                                                                    <i className="d-inline-block r-text--sm fi flaticon-arrow-1 " />
                                                                    <i className="d-inline-block r-text--sm fi flaticon-arrow  " />
                                                                  </Button>
                                                              </Col>
                                                            </Row> 
                                                     </th>)
                                        }
                                        else if(col.id ==="roll_no"){
                                         return ( <th key={i} style={{minWidth:'100px'}}> 
                                                            <Row className="align-items-center mx-0">
                                                              <Col xs={8} className="px-0">
                                                               <span>Roll No.</span>
                                                              </Col>
                                                              <Col  xs={4} onClick={() => this.sortByRoll('roll_no')} className="px-0 rotate--90">
                                                                      <i className="d-inline-block r-text--sm fi flaticon-arrow-1 " />
                                                                      <i className="d-inline-block r-text--sm fi flaticon-arrow  " />
                                                              </Col>
                                                            </Row> 
                                                     </th>)
                                        }
                                        else{
                                            return(
                                              <th  key={i}>
                                                  { col.title }
                                                </th>
                                            )
                                        }}
                                )}
                        </tr>
                      </thead>
                      <tbody>
                            {
                              attendeeList.slice( parseInt(pageCurrent)*parseInt(rows), (parseInt(pageCurrent)*parseInt(rows)) +parseInt(rows)  ).map((st,i) =>{
                                      length = length+1;
                                    return(
                                      <tr
                                          className="borderBottomlightblack"
                                          style={{backgroundColor:(st.attendee_info["attendance_status"] === 'Marked')?'#9ef79c':''}}
                                          key={i}>
                                          {
                                            columns.map((col,j) =>{
                                               if(col.id ==="sno"){
                                                   return (
                                                        <td key={j}>
                                                          {length}
                                                        </td>)
                                                  }
                                                else if(col.id === "name"){
                                                    return(
                                                        <td  key={j} >
                                                                  {getFullName(st.attendee_info)}
                                                        </td>
                                                    )
                                                  }
                                                else if(col.id === "attendance_status"){
                                                    return(
                                                        <td  key={j} >
                                                             {(st.attendee_info["attendance_status"] !== 'Marked')?<Button color="blue" onClick={(e) => this.markAttendance(e, st)}>Mark</Button>: <Button color="blue" onClick={(e) => this.unMarkAttendance(e, st)}>Unmark</Button>}
                                                        </td>
                                                    )
                                                  }
                                                  else {
                                                      return(
                                                        <td key={j}>
                                                          {st.attendee_info[col.id]}
                                                        </td>
                                                      )
                                                  }
                                            })
                                          }
                                      </tr>
                                    )
                                  })
                            }
                      </tbody>
                  </Table>
                  <Row className="justify-content-between align-items-center" >
                       <Col xs="auto" className="">
                         <Row className="justify-content-between align-items-center">
                         <span className="px-1 d-inline-block r-subheading-4 " style={{fontSize:"15px"}}>Total attendees are {attendeeList.length}</span>
                         <span className="px-3 d-inline-block r-subheading-4 " style={{fontSize:"15px"}}>View</span>
                         <div className="r-dropdown border-0">
                             <select value={rows} name="rows" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={(e) => {this.setState({pageCurrent:0, [e.target.name]: e.target.value})}}>
                               <option   value="10" >10</option>
                               <option value="15">15</option>
                                <option   value="20" >20</option>
                               <option value="25">25</option>
                                <option   value="50" >50</option>
                               <option value="100">100</option>
                             </select>
                           <i className="fi flaticon-arrow"/>
                         </div>
                         </Row>
                       </Col>
                       <Col xs="auto">
                           <PaginationComponent
                               totalItems={attendeeList.length}
                               pageSize={rows}
                               onSelect={this.selectHandle}
                               maxPaginationNumbers={3}
                               activePage={pageCurrent+1}
                             />
                       </Col>
                  </Row>
              </Col>
            </Row>
          </ModalBody>
          <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
        </Modal>
      </Container>
    )
  }
}

export default Attendance