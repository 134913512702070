import React, { Component } from 'react';
import Aux from '../../../../global/AuxComp';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import moment from 'moment';
import Collapse from 'reactstrap/lib/Collapse';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { ProfileActions } from './profile-actions';
import Button from '../../../../global/Button';
import { Auth } from '../../../../../actions/Auth';
import { CONSTANTS } from '../../../../../constants';
import { InputField } from '../../../../global/Forms';
import { filterCVPoints } from '../../../../../actions';
import { CVPoint } from './cv-point';
import { FileUpload } from '../../../../global/FileUpload';
import { MonthSelect, YearSelect } from '../../../../global/Forms/Select';
import {Errorbar} from '../../../../global/errorbar';
import { SingleDatePicker } from '../../../../global/Forms/DatePicker';

export class OtherExperience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      deleteId: null,
      errorbar: false,
      message:''
    }
  }

  closebar = () =>{
    this.setState({errorbar: false});
  }

  addOtherExp = (e, type) => {
    this.props.toggleSection("OTH-0")
    this.props.changeOtherExp({ type })
    this.el = e.target.closest(".student__profile__container");
    setTimeout(() => {
      if (this.el)
        this.el.scrollIntoView({ behavior: "smooth", block: "start" })
    }, 300)
  }

  handleOtherExpChange = (e, exp) => {
    exp[e.target.name] = e.target.value;
    this.props.changeOtherExp(exp)
  }

  isValid = (exp) => {
    const { name, institute, location } = exp;
    if (!name || !institute){
      this.setState({message: 'Please fill all mandatory input fields'});
      this.setState({errorbar: true});
      return false
    }
    this.setState({errorbar: false });
    return true
  }

  handleOtherExpSubmit = async (e, exp) => {
    if (e) e.preventDefault();
    if (!this.isValid(exp)) {
      return
    }
    this.props.toggleLoading(true);
    let method = Auth.Student.updateOtherExp,
      formData = Object.assign({}, exp);
    formData.year_start = moment(formData.year_start).utc(false).format(CONSTANTS.DATE_FORMAT)
    formData.year_end = moment(formData.year_end).utc(false).format(CONSTANTS.DATE_FORMAT)
    if (typeof exp.id === "string") {
      method = Auth.Student.createOtherExp;
      formData.student_id = this.props.user_info.student_id;
      delete formData.id
    }
    try {
      await method(formData)
      this.props.getStudentProfile()
      if (e) this.props.toggleSection("");

    } catch (e) {
      console.log(e)
      if(e.response){
        this.setState({message: e.response.data.error.toString()});
        this.setState({errorbar: true});
      } else{
        this.setState({message: 'Sorry! something went wrong. Please try again'});
        this.setState({errorbar: true});
      }
    }
    this.props.toggleLoading(false);
  }

  deleteDetail = async () => {
    let { deleteId } = this.state;
    if (!deleteId) return;
    let formData = {
      student_id: this.props.user_info.student_id,
      id: deleteId,
      status: 0
    }
    this.props.toggleLoading(true)
    try {
      if (typeof deleteId === "number")
        await Auth.Student.updateOtherExp(formData)
      this.props.changeOtherExp(formData, true)
      this.toggleModal()
      this.props.getStudentProfile()
    } catch (e) {

      if(e.response){
        this.setState({message: e.response.data.error.toString()});
        this.setState({errorbar: true});
      } else{
        this.setState({message: 'Sorry! something went wrong. Please try again'});
        this.setState({errorbar: true});
      }

     }
    this.props.toggleLoading(false)
  }

  toggleModal = (id) => {
    this.setState({
      modalShow: !this.state.modalShow,
      deleteId: id
    })
  }

  changeYear = (e, field, method, exp) => {
    let date = exp[field] ? moment(exp[field]) : moment();
    exp[field] = date[method](e.target.value).format(CONSTANTS.DATE_FORMAT);
    this.props.changeOtherExp(exp)
  }

  handleDateChange = (mDate, field, exp) => {
    exp[field] = mDate
    this.props.changeOtherExp(exp)
  }

  render() {
    let { files, editableSection, toggleSection, other_experience, onCancel, cv_point, commentThreads } = this.props;
    this.cvPoints = filterCVPoints(cv_point, "OTHER_EXPERIENCE")
    return (
      <Aux>
        <div className="r-card">
          {
            Object.keys(CONSTANTS.OTHER_EXPERIENCE_TYPES).map(type => {
              return (
                <Row noGutters className=" student__profile__container" key={type}>
                  <Col xs={12}>
                    <h2 className="r-heading-4 r-text--blue-3 mb-4">{CONSTANTS.OTHER_EXPERIENCE_TYPES[type]}</h2>
                    {editableSection === "" && <div className="student__profile__actions">
                      <Button onClick={(e) => this.addOtherExp(e, type)} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
                    </div>}
                    {
                      other_experience.map((exp, i) => {
                        if (exp.type === type) {
                          return (
                            <div className={"student__profile__section" + (exp.freeze_status==1?" student__profile--freeze":"")} key={exp.id}>
                              <Collapse isOpen={editableSection !== `OTH-${i}`}>
                                <h1 className="r-subheading-2 mr-3" style={{fontWeight:600}}>
                                  {exp.name || "Name"}
                                </h1>
                                <h1 className="r-subheading-2 mt-3" style={{fontWeight:400}}>
                                  {exp.institute || "Institute"}
                                </h1>
                              <div className="r-text--grey-4 mb-2">{exp.year_start ? moment(exp.year_start).format("MMM YYYY") : "Year Start"} - {exp.year_end ? moment(exp.year_end).format("MMM YYYY") : "Year End"}</div>

                                {(files[exp.file]) && <Row noGutters>
                                  {files[exp.file].map((doc, i) => <Col className="pr-3" key={i} xs="auto">
                                    <div className="r-tag r-tag--type-1 r-tag--no-delete">
                                      <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.file_name || doc.file_url || "Filename"}</a>
                                    </div>
                                  </Col>)}
                                </Row>}
                              </Collapse>
                              <Collapse isOpen={editableSection === `OTH-${i}`}>
                                <form onSubmit={(e) => this.handleOtherExpSubmit(e, exp)}>
                                  <Row form className="mb-2">
                                    <Col xs={12}>
                                      <label className="r-heading-7"><span className="r-text--red-1">*</span>Name</label>
                                      <InputField className="w-100" type="text" name="name" value={exp.name} onChange={(e) => this.handleOtherExpChange(e, exp)} />
                                    </Col>
                                  </Row>
                                  <Row form className="mb-2">
                                    <Col xs={12}>
                                      <label className="r-heading-7"><span className="r-text--red-1">*</span>Organization</label>
                                      <InputField className="w-100" type="text" name="institute" value={exp.institute || ""} onChange={(e) => this.handleOtherExpChange(e, exp)} />
                                    </Col>
                                  </Row>
                                  <Row form className="mb-2">
                                    <Col xs={12}>
                                      <label className="r-heading-7">Location</label>
                                      <InputField className="w-100" type="text" name="location" value={exp.location || ""} onChange={(e) => this.handleOtherExpChange(e, exp)} />
                                    </Col>
                                  </Row>
                                  <Row form className="mb-2">
                                    <Col xs={6}>
                                      <label className="r-heading-7" htmlFor={`${type}__year_start`}><span className="r-text--red-1">*</span>Start {exp.type==="CONFERENCES"? "Date" :"Month/Year"}  </label>
                                      {exp.type==="CONFERENCES" ?<SingleDatePicker
                                        value={exp.year_start}
                                        onDateChange={(date) => this.handleDateChange(date, "year_start", exp)}
                                        id={`exp_start_${exp.id}`} /> :
                                      <Row noGutters>
                                        <Col xs={6}>
                                          <MonthSelect onChange={(e) => this.changeYear(e, "year_start", "month", exp)} value={exp.year_start ? moment(exp.year_start).month() : ""} />
                                        </Col>
                                        <Col xs={6}>
                                          <YearSelect onChange={(e) => this.changeYear(e, "year_start", "year", exp)} value={exp.year_start ? moment(exp.year_start).year() : ""} />
                                        </Col>
                                      </Row>}
                                    </Col>
                                    <Col xs={6}>
                                      <label className="r-heading-7" htmlFor={`${type}__year_start`}><span className="r-text--red-1">*</span>End {exp.type==="CONFERENCES"? "Date" :"Month/Year"} </label>
                                      {exp.type==="CONFERENCES" ?<SingleDatePicker
                                        value={exp.year_end}
                                        onDateChange={(date) => this.handleDateChange(date, "year_end", exp)}
                                        id={`exp_end_${exp.id}`} /> : 
                                      <Row noGutters>
                                        <Col xs={6}>
                                          <MonthSelect onChange={(e) => this.changeYear(e, "year_end", "month", exp)} value={exp.year_end ? moment(exp.year_end).month() : ""} />
                                        </Col>
                                        <Col xs={6}>
                                          <YearSelect onChange={(e) => this.changeYear(e, "year_end", "year", exp)} value={exp.year_end ? moment(exp.year_end).year() : ""} />
                                        </Col>
                                      </Row>}
                                    </Col>
                                  </Row>

                                  <div className="d-flex justify-content-end mt-3">
                                    <Button type="button" onClick={() => onCancel()} className="r-text--sm">Cancel</Button>
                                    <Button type="submit" className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
                                  </div>
                                  {typeof exp.id === "number" && < FileUpload toggleLoading={this.props.toggleLoading} updateData={this.props.changeOtherExp} submitData={this.handleOtherExpSubmit} verification={{ user_id: this.props.user_info.user_id, data: exp, files }} />}
                                </form>
                              </Collapse>
                              {typeof exp.id === "number" && <CVPoint getStudentProfile={this.props.getStudentProfile} settings={this.props.settings} toggleCV={this.props.toggleCV} files={files} toggleLoading={this.props.toggleLoading} user_info={this.props.user_info} profile_id={exp.id} type="OTHER_EXPERIENCE" changeCVPoint={this.props.changeCVPoint} cvPoints={this.cvPoints[exp.id] || []} commentThreads={commentThreads} />}
                              {editableSection !== `OTH-${i}` && <ProfileActions delete={() => this.toggleModal(exp.id)} verification={{ ...exp }} toggleSection={() => toggleSection(`OTH-${i}`)} isRequiredValidate={true} commentThreads={commentThreads} profileType="OTHER_EXPERIENCE" />}
                            </div>
                          )
                        }
                      })
                    }
                    {
                      other_experience.filter(exp => exp.type === type).length === 0 && <div className="student__profile__section student__profile__section--placeholder">
                      <h1 className="r-subheading-2 mr-3" style={{fontWeight:600}}>
                        Name
                      </h1>
                      <h1 className="r-subheading-2 mt-3" style={{fontWeight:400}}>
                        Institute
                      </h1>
                    <div className="r-text--grey-4 mb-2">Start Year - End Year</div>


                      </div>
                    }
                  </Col>
                </Row>
              )
            })
          }
        </div>
        <Modal className="modal--delete" isOpen={this.state.modalShow} toggle={() => this.toggleModal()}>
          <ModalBody>
            <p>Are you sure?</p>
            <div className="text-right">
              <Button className="r-text--sm" onClick={() => this.toggleModal()}>Cancel</Button>
              <Button color="dark" onClick={this.deleteDetail} className="r-text--sm r-btn--no-radius">Delete</Button>
            </div>
          </ModalBody>
        </Modal>
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      </Aux>
    )
  }
}
