import React from 'react';
import { Row,Col,Container,  Modal, ModalHeader,Nav, NavItem, NavLink,TabContent, TabPane, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import {Errorbar} from '../../../global/errorbar';
import Autosuggest from 'react-autosuggest';
import AutosuggestComp from '../../../global/AutosuggestComp';
import { AddSuggestion } from '../../../global/Forms/AddSuggestion';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../../actions/Auth';
import { setUser, setLoading } from '../../../../actions/action-creators';
import Button from '../../../global/Button';
import {SelectList} from '../../../global/Forms/Select'
import { InputField } from '../../../global/Forms';
import classnames from 'classnames';

class EditStudent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      suggestions: {
        getEducationBoards: [],
        getDepartments: [],
        getInstitutes: [],
        getSchools: [],
        getDegrees: []
      },
      batchid:  this.props.stDetails.batch_id,
      errorbar: false,
      message:'',
      campusList:[],
      campusID:"",
      campusName: "Select Campus ",
      batchName: this.props.stDetails.batch_name,
      activeTab: '1',
      course:'',
      dept: '',
      collegeName: "",
      reqField: false,
      group:'',
      firstName:this.props.stDetails.first_name,
      middleName: this.props.stDetails.middle_name,
      batchlist: [],
      degreeidList: [],
      departmentLists:[{degree_department_id: this.props.stDetails.degree_department_id, department_name:this.props.stDetails.department_name }],
      lastName:this.props.stDetails.last_name,
      email: this.props.stDetails.email,
      rollNo: this.props.stDetails.roll_no,
      departmentValue:this.props.stDetails.department_name,
      departmentid:this.props.stDetails.degree_department_id,
      dep: this.props.stDetails.department_name,
    };
  }


  componentDidMount = () => {
      this.getBatchs();
    // this._init();
  }

  getBatchs  = async () => {
      let {batchlist} = this.state;
     this.props.setLoading(true)
     try {
      let response = await Auth.PtStudents.getBatchList();
      let res = await Auth.General.getDegreesCampusDepartment(this.props.role.instituteDetail.campus_id, this.props.stDetails.campus_degree_id);
      this.setState({departmentLists: res.data[0].departments})
      this.setState({batchlist: response.data});
      this.props.setLoading(false);
    } catch (e) {
        console.log(e);
        this.props.setLoading(false);
       }
  }



  closebar = () =>{
      this.setState({errorbar: false});
  }
  

  // _init = async() =>{
  //     let {campusList} = this.state;
  //    this.props.setLoading(true)
  //    try {
  //     let respClg = await Auth.PtStudents.getCollege(this.props.user.current_role_info.college_id);
  //     this.setState({collegeName: respClg.data.collegeDetails[0].name});
  //     let response = await Auth.PtStudents.getCampus(this.props.user.current_role_info.campus_id);
  //     this.setState({campusList: response.data});
  //     this.props.setLoading(false);
  //   } catch (e) {
  //         this.props.setLoading(false);
  //         this.setState({errorbar: true});
  //         this.setState({message: e.response.data.error.toString()});
  //      }
  // }

  onChange = e => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }

  handleSubmit = async(e) =>{
      e.preventDefault();
      if (this.state.departmentid !== null) {
             this.props.setLoading(true)
            var stBody = {
                       // "department_id": 1,
                       // "campus_degree_id": 1,
                        "degree_department_id": this.state.departmentid,
                       // "campus_id": 1,
                        "student_id":this.props.stDetails.student_id,
                       // "university_id": this.props.user.current_role_info.university_id,
                       // "college_id": this.props.user.current_role_info.college_id,
                       "first_name": this.state.firstName,
                       "middle_name": this.state.middleName,
                       "last_name":this.state.lastName,
                       "roll_no": this.state.rollNo,
                }
            try {
                  let resp = await Auth.PtStudents.editStudent(stBody);
                  let res = await Auth.PtStudents.studentEdit(stBody);
                  this.props.toggle();
                  this.props.setLoading(false);
                  this.props.refresh(stBody, "edit");
                } catch (e) {
                  this.props.setLoading(false);
                  this.setState({errorbar: true});
                  this.setState({message: e.response.data.error.toString()});
                }
      }
      else{
        this.setState({reqField: true});
      }
}

  render() {
    const { group, dep,batchName,middleName,batchid,campusID,suggestions,collegeName,departmentid, departmentValue, lastName, firstName,email,rollNo,departmentLists } =this.state;
   const autoSuggestProps = {
      onChange: this.onChange,
        className: "w-100 r-inputfield",
    };
    return (
        <Modal  zIndex="99999"  isOpen={this.props.modal} toggle={this.props.toggle} size="xl"   style={{marginTop: "50px"}}>
                       <form onSubmit={this.handleSubmit}>
                      {/*    <Row  className="align-items-center mx-0">
                                                     <Col xs="6" className="py-2">
                                                      College
                                                          <InputField className="r-nav__search" type="text" name="collegeName" value={collegeName} disabled/>
                                                     </Col>
                                                     <Col xs="6" className="py-2">
                                                      Campus 
                                                      <div className="r-dropdown border-0">
                                                          <select value={campusID} name="campusID" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={this.onChange}>
                                                            {this.state.campusList.map(data =>{
                                                              return <option key={data.campus_id}  value={data.campus_id}>{data.name}</option>
                                                            })}
                                                          </select>
                                                        <i className="fi flaticon-arrow"/>
                                                        </div> 
                                                     </Col>
                                                </Row>*/}
                          <Row  className="align-items-center mx-0">
                               <Col xs="6" className="py-2">
                                Batch
                                <InputField className="r-nav__search"  value={this.props.stDetails.batch_name} disabled/>
                               </Col>
                               <Col xs="6" className="py-2">
                                Department
                                   <select value={departmentid} name="departmentid" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={this.onChange} required>
                                      <option value="" >Select Department</option>
                                       {departmentLists.map(data =>{
                                         return <option key={data.degree_department_id}  value={data.degree_department_id}>{data.department_name}</option>
                                       })}
                                   </select>
                               </Col>
                          </Row>
                         <Row  className="align-items-center mx-0">
                             <Col xs="3" className="py-2">
                                First Name
                                <InputField className="r-nav__search" placeholder="First Name..." type="text" name="firstName" value={firstName} onChange={this.onChange} required/>
                              </Col>
                             <Col xs="3" className="py-2">
                                Middle Name
                                <InputField className="r-nav__search" placeholder="Middle Name..." type="text" name="middleName" value={middleName} onChange={this.onChange} />
                              </Col>
                             <Col xs="3" className="py-2">
                                  Last Name
                                <InputField className="r-nav__search" placeholder="Last Name..." type="text" name="lastName" value={lastName} onChange={this.onChange} />
                              </Col>
                             <Col xs="3" className="py-2">
                                Email
                                <InputField className="r-nav__search" type="text" name="email" value={email}  disabled/>
                              </Col>
                             <Col xs="3" className="py-2">
                                Roll No
                                <InputField className="r-nav__search" placeholder="Roll No..." type="text" name="rollNo" value={rollNo} onChange={this.onChange} required/>
                              </Col>
                          </Row>
                         <Row  className="justify-content-end align-items-center  mx-0  my-2 " style={{textAlign:"center", padding:"50px 0"}}>
                              <Button type="submit" className="font-weight-normal mx-3  px-5" color="dark"> Save</Button>
                              <Button onClick={this.props.toggle} className="font-weight-normal mx-3  px-5" color="outline-dark-v">Cancel</Button>
                         </Row>
                        </form>
                    <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
        </Modal>
    );
  }
}



const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoading: (show) => dispatch(setLoading(show)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditStudent));