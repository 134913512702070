import React from 'react';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';
import VerifierTest from '../views/verifier/verifier';
import VerifierDetailPage from '../views/verifier/verifier_detail_page';
import Switch from 'react-router-dom/Switch';

export const VerifierRoutes = () => {
  return (
    <Switch>
      <Route exact path="/verifier/verification" component={VerifierTest} />
      <Route exact path="/verifier/verification/:student_id/:process_id/studentDetails" component={VerifierDetailPage} />
      <Route render={() => <Redirect to="/verifier/verification" />} />
    </Switch>
  )
}
