import React, { Component } from 'react';
import { Auth } from '../../../actions/Auth';
import { Row, Col, Container, CardHeader, Card, CardBody, CardFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Select } from "../../global/Forms/Select";
import { InputField } from "../../global/Forms";
import { Loader } from '../../global/Loader';
import { Errorbar } from '../../global/errorbar';
import ReCAPTCHA from 'react-google-recaptcha';
import Aux from "../../global/AuxComp";
import {CONSTANTS} from '../../../constants';
class LcmModule extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }


  getInitialState = () => {
    return ({
      elective: null,
      emailVerifyStatus: null,
      email: null,
      generateOtp: 1,
      verifymessage: null,
      emailOtpVerifyMessage: null,
      electiveList: null,
      selectedElective: null,
      emailToken: null,
      loading: false,
      errorbar: false,
      message: '',
      finalSuccessMessage: null,
      electiveName: null,
      currentCourseId : null,
      isVerified : false,
      selectedcourseConstant : null,
      selectedsemConstant : null,
      isVerifiedGenerate : false,
      sem3SecPaperList : null,
      sem5SecPaperList : null,
      sem5GePaperList : null,
      sem3GePaperList : null,
      obj : null,
      current_course_name : null
    })
  }

  onChange = (value)=> {
    if(value){
      this.setState({isVerified : true});
    }
    else{
      this.setState({isVerified : false});
    }
  }

  onchangeGenerate = (value)=>{
    if(value){
      this.setState({isVerifiedGenerate : true});
    }
    else{
      this.setState({isVerifiedGenerate : false});
    }
  }

  handleFormSubmit = async (e) => {
    e.preventDefault();
    let { fullName, mobile, rollNo } = e.target;
    let { current_course_name  , selectedcourseConstant  , isVerified} = this.state;
    let msg= '';
    if(mobile.value.toString().length!=10){
      msg = 'Please enter the correct mobile number';
    }
    if(rollNo.value.toString().length>4){
      msg = 'Roll No. should be of 4 digits and should not include 0 as first digit';
    }
    if(msg){
      this.setState({ message: msg});
      this.setState({ errorbar: true });
    }
    else{
      this.createStudentElective(fullName.value, mobile.value, rollNo.value);
    }
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  handleVerificationFormSubmit = async (e) => {
    e.preventDefault();
    let { email } = e.target;
    let params = {
      email: email.value
    }
    let {isVerifiedGenerate} = this.state;
    if(isVerifiedGenerate==true){
      this.setState({ loading: true });
      try {
        await Auth.User.emailTokenGenerate(params);
        this.setState({ email: email.value, emailVerifyStatus: 2, generateOtp: 2, verifymessage: "Verification Code has been send to your mail. Kindly check your mail" });
      }
      catch (e) {
        console.log(e);
        this.setState({ errorbar: true });
        this.setState({ message: e.response.data.error.toString() });
      }
      this.setState({ loading: false });
    }
    else{
      this.setState({ errorbar: true });
      this.setState({ message: 'Kindly verify to submit' });
    }
  }
  handleVerification = async (e) => {
    e.preventDefault();
    let { emailOtp } = e.target;
    let params = {
      email: this.state.email,
      token: emailOtp.value
    }
    this.setState({ loading: true });
    try {
      await Auth.User.emailTokenVerify(params);
      this.setState({ emailOtpVerifyMessage: "Email verified. Kindly Fill all the details", emailVerifyStatus: 1, generateOtp: null, emailToken: emailOtp.value })
    }
    catch (e) {
      console.log(e);
      this.setState({ errorbar: true });
      this.setState({ message: e.response.data.error.toString() });
    }
    this.setState({ loading: false });
  }

  getElectiveList = async () => {
    try {
      let electiveList = await Auth.Electives.getList(48);
      this.setState({ obj : electiveList.data });
    }
    catch (e) {
      console.log(e);
      this.setState({ errorbar: true });
      this.setState({ message: e.response.data.error.toString() });
    }
  }

  createStudentElective = async (fullName, mobile, rollNo) => {
    let params = {
      "campus_id": 48,
      "full_name": fullName,
      "mobile": mobile,
      "roll_no": rollNo,
      "email": this.state.email,
      "token": this.state.emailToken,
      "current_course_name": this.state.selectedcourseConstant,
      "current_semester": this.state.selectedsemConstant
    }
    let gePaperList = this.state.sem5GePaperList,
      secPaperList = this.state.sem5SecPaperList
    if(this.state.selectedsemConstant==="III") {
      gePaperList = this.state.sem3GePaperList
      secPaperList = this.state.sem3SecPaperList
    }
    
      params.selected_ge_preference =  gePaperList?gePaperList.split("-")[1]: null;
      params.selected_ge_course_name =  gePaperList?gePaperList.split("-")[0]: null;
      params.selected_sec_preference =  secPaperList?secPaperList.split("-")[1]: null;
      params.selected_sec_course_name =  secPaperList?secPaperList.split("-")[0]: null;
    this.setState({ loading: true });
    try {
      let electiveName ;
      let electiveCount = params.selected_ge_preference && params.selected_sec_preference? 2 : 1;
      if(electiveCount == 2){
        electiveName = params.selected_ge_preference +" and "+ params.selected_sec_preference;
      }
      else{
        electiveName = params.selected_ge_preference ? params.selected_ge_preference : params.selected_sec_preference;
      }
      let msg = "You have selected " + electiveName + " as your elective"
      await Auth.Electives.createElective(params);
      this.setState({ finalSuccessMessage: msg, generateOtp: null, emailVerifyStatus: null })
    }
    catch (e) {
      console.log(e);
      this.setState({ errorbar: true });
      this.setState({ message: e.response.data.error.toString() });
    }
    this.setState({ loading: false });
  }


  componentDidMount() {
    this.getElectiveList();
  }

  render() {
    let {obj} = this.state;
    return (
      <Container>
        <Loader show={this.state.loading} />
        <Row>
          <Col>
            {this.state.finalSuccessMessage ?
              <Row>
                <Col align="center" className="m-5">
                  <span className="r-text--green-1 r-subheading-2 mb-2 m-5">{"*"}{this.state.finalSuccessMessage}</span>
                </Col>
              </Row> : null}
          </Col>
        </Row>
        <Row>
          <Col align="start">
            {this.state.generateOtp != null || this.state.emailVerifyStatus != null ?
              <Card className="m-5 w-50">
                <CardHeader className="r-text--blue-3 r-heading-6" align="start">Elective Form</CardHeader>
                <CardBody>
                  {this.state.generateOtp == 1 ? <form onSubmit={this.handleVerificationFormSubmit}>
                    <Row>
                      <Col>
                        <label>Email</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={8}>
                        <InputField placeholder="Enter email to generate verification code" name="email" className="w-100" type="email" required={true} />
                      </Col>
                      <Col xs="auto" align="end">
                        <Button color="dark" className="m-2">Generate</Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="my-3">
                        <ReCAPTCHA sitekey={CONSTANTS.RECAPTCHA_KEY.googleRecaptchaKey} onChange={this.onchangeGenerate} />
                      </Col>
                    </Row></form> : null}
                  {this.state.generateOtp == 2 ? <form onSubmit={this.handleVerification}>
                    <Row>
                      <Col>
                        {this.state.verifymessage ? <span className="r-text--green-1 r-subheading-4 mb-2">{"*"}{this.state.verifymessage}</span> : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Enter Verification Code</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <InputField placeholder="Enter the verification code" name="emailOtp" className="w-100" required={true} />
                      </Col>
                      <Col xs="auto" align="end">
                        <Button color="dark" className="m-2">Verify</Button>
                      </Col>
                    </Row></form> : null
                  }
                  {
                    this.state.emailVerifyStatus == 1 ? <form onSubmit={this.handleFormSubmit}  >
                      <Row>
                        <Col>
                          {this.state.emailOtpVerifyMessage ? <span className="r-text--green-1 r-subheading-4 mb-2">{"*"}{this.state.emailOtpVerifyMessage}</span> : null}
                        </Col>
                      </Row>
                      <Row>
                        <Col align="start">
                          <label><span className="r-text--red-1">*</span>Full Name</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3">
                          <InputField placeholder="Full Name" name="fullName" className="w-100" required={true} />
                        </Col>
                      </Row>
                      <Row>
                        <Col align="start">
                          <label><span className="r-text--red-1">*</span>Mobile No.</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3">
                          <InputField placeholder="Mobile No." name="mobile" className="w-100" required={true} />
                        </Col>
                      </Row>
                      <Row>
                        <Col align="start">
                          <label><span className="r-text--red-1">*</span>Roll No.</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3">
                          <InputField placeholder="Roll No." name="rollNo" className="w-100" required={true} />
                        </Col>
                      </Row>
                      <Row>
                        <Col align="start">
                          <label><span className="r-text--red-1">*</span>Choose Course</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3">
                          <Select onChange={async (e) => await this.setState({ selectedcourseConstant: e.target.value , selectedsemConstant : "" })} required={true} >
                            <option value="">Select Course </option>
                            {
                              (obj && obj.hinduUniversity.courseName) && Object.keys(obj.hinduUniversity.courseName).map(elective =>
                                <option key={elective}
                                  id={elective} value={elective}>{elective}</option>
                              )
                            }
                          </Select>
                        </Col>
                      </Row>

                      {(this.state.selectedcourseConstant)&&  <Aux><Row>
                        <Col align="start">
                          <label><span className="r-text--red-1">*</span>Choose Semester</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3">
                          {(this.state.selectedcourseConstant && obj.hinduUniversity.courseName[this.state.selectedcourseConstant].semester) &&
                            <Select value={this.state.selectedsemConstant} onChange={(e) => this.setState({ selectedsemConstant: e.target.value, sem3GePaperList: null, sem3SecPaperList: null, sem5GePaperList: null, sem5SecPaperList: null })} required={true} >
                              <option value="">Select Semester </option>
                              {
                                ((obj &&  obj.hinduUniversity.courseName[this.state.selectedcourseConstant]) && obj.hinduUniversity.courseName[this.state.selectedcourseConstant].semester) && Object.keys(obj.hinduUniversity.courseName[this.state.selectedcourseConstant].semester).map(elective =>
                                        <option key={elective}
                                        id={elective} value={elective}>{elective}</option>
                                    )
                                }
                            </Select>}
                        </Col>
                      </Row></Aux>}
                      {(this.state.selectedsemConstant && obj) && obj.hinduUniversity.courseName[this.state.selectedcourseConstant].semester[this.state.selectedsemConstant].map(data=>
                            <Aux> <Row>
                              <Col align="start">
                                <label><span className="r-text--red-1">*</span>{obj.hinduUniversity[data].title}</label>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-3">
                                <Select onChange={(e) => this.setState({ [data] : e.target.value})} required={true} >
                                  <option value="">Select {obj.hinduUniversity[data].title} </option>
                                  {
                                    (obj && obj.hinduUniversity[data].list) && Object.keys(obj.hinduUniversity[data].list).map(item =>{
                                      if(this.state.selectedcourseConstant.includes("Music") || !this.state.selectedcourseConstant.includes(obj.hinduUniversity[obj.hinduUniversity.courseName[this.state.selectedcourseConstant].courseList][item])){
                                        return(
                                          <option key={obj.hinduUniversity[data].list[item]}
                                          id={obj.hinduUniversity[data].list[item]} value={obj.hinduUniversity[obj.hinduUniversity.courseName[this.state.selectedcourseConstant].courseList][item] + "-" + obj.hinduUniversity[data].list[item]}> {obj.hinduUniversity[obj.hinduUniversity.courseName[this.state.selectedcourseConstant].courseList][item]}{' - '}{obj.hinduUniversity[data].list[item]}</option>
                                        )
                                      }
                                      })
                                    }


                                </Select>
                              </Col>
                            </Row></Aux>


                         ) }
                      <Row>

                        <Col align="end">
                          <Button color="dark" className="m-3">Submit</Button>
                        </Col>
                      </Row>
                    </form> : null
                  }
                </CardBody>
              </Card> : null}
          </Col>
        </Row>
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(LcmModule);
