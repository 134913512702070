import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Spinner from 'reactstrap/lib/Spinner';
import { getFullName } from '../../../../actions';
import { CONSTANTS } from '../../../../constants';
import Button from '../../../global/Button';
import { Select } from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import AddMinutes from './add-minutes'

import {UncontrolledCollapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse } from 'reactstrap';
export class MeetingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: "",
      isLoading: false,
      history: '',
      filterRole: 'All',
      isOpenAddInteraction: false
    }
  }

  toggle  = (history) =>{
     this.setState({
      history
    }) }


    handleRoleChanged = (event) =>{

      this.setState({filterRole: event.target.value})

    }


  updateProfileStatus = async (e) => {
    let { editable } = this.state,
      { company_id, profile, handleDataChange } = this.props,
      val = e.target.value;
    this.setState({ isLoading: true })
    try {
      await Auth.Processes.updateProfileStatus({ process_company_profile_id: editable, profile_status: val })
      let updatedProfile = profile.map(pro => {
        if (pro.process_company_profile_id === editable)
          pro.profile_status = val
        return pro
      })
      handleDataChange({ company_id, profile: updatedProfile })
      this.setState({ editable: "" })
    } catch (e) {
      console.log(e)
    }
    this.setState({ isLoading: false })
  }
  render() {
    let { meeting, profile } = this.props,
      { editable, isLoading } = this.state;

    return (
      <div className="processes__meeting">
        <Row>
          {(meeting && meeting.contacts && meeting.contacts.length > 0) ? <Col xs={3}>
            <h3 className="r-heading-4">Contacts</h3>
            {
              meeting.contacts.map(contact => {
                return (
                  <div
                    key={contact.contact_id}
                    onClick={()=>this.toggle(contact.contact_id)}
                    className="processes__meeting__contact"
                  >
                    <h5 className="r-subheading-3"> {getFullName(contact)} </h5>
                  <span>{CONSTANTS.CONTACT_TYPES[contact.contact_type] || contact.contact_type}</span>
                  </div>
                )
              })
            }
          </Col> :
            <Col xs={3}><h3 className="r-heading-4">No Contacts added</h3></Col>}
          <Col xs={9}>
            <div>
              <h3 className="r-heading-4">Update Status&nbsp;{isLoading && <Spinner color="dark" />}</h3>
              <Row>
                {profile.length > 0 ? profile.map(p => {
                  return (
                    <Col className="my-2" xs={3} key={p.process_company_profile_id}>
                      <div className="processes__meeting__profile" >
                        <div>{p.role_name}</div>
                        {editable === p.process_company_profile_id ? <Select defaultValue={p.profile_status} onChange={this.updateProfileStatus}>
                          {Object.keys(CONSTANTS.PROFILE_STATUS).map(st => {
                            return (
                              <option key={st} value={st}>{CONSTANTS.PROFILE_STATUS[st]}</option>
                            )
                          })}
                        </Select> : <span>{CONSTANTS.PROFILE_STATUS[p.profile_status]}<Button onClick={() => this.setState({ editable: p.process_company_profile_id })} className="fi flaticon-edit"></Button></span>}
                      </div>
                    </Col>
                  )
                }) : <Col><p className="r-subheading-3">No roles added</p></Col>
                }
              </Row>

                <div className="minutes">
                  <Row>
                  <Col xs={12}>
                    <h3
                    onClick={() => this.setState({isOpenAddInteraction: true})}
                    className="r-heading-4 my-4"
                    id="togglerNotesForm"> Interaction History <button className="btn btn-link"><div align="end" className="p-0 m-0 col-2"><i className="fi flaticon-plus-2"></i></div></button>
                    </h3>
                  </Col>

                    <Col xs={12}>
                      <AddMinutes
                        isOpenAdd={this.state.isOpenAddInteraction}
                        onCloseAdd={() => this.setState({isOpenAddInteraction: false})}
                        meetingData={meeting}
                        getCompanies={this.props.getCompanies}
                        profileData={profile}
                        company_id={this.props.company_id}
                        filterRole = { this.state.filterRole}
                        handleDataChange={this.props.handleDataChange} 
                      />
                    </Col>
                  </Row>
                </div>
            </div>
          </Col>
        </Row>

      </div>
    )
  }
}
