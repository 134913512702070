import React, { Component } from 'react';
import Button from './Button';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import Fade from 'reactstrap/lib/Fade';
import { setError } from '../../actions/action-creators';
export class Errorbar extends Component {

	componentWillReceiveProps(nextProps) {
		if (nextProps.showBar === true) {
			if (this.timeout)
				clearTimeout(this.timeout)
			this.timeout = setTimeout(() => this.props.onClose(), 4000)
		}
	}

	render() {
		// if (this.props.showBar) {
			return (
				<Fade in={this.props.showBar} unmountOnExit={true} className={this.props.isSuccess ? "success-bar" : "error-bar"}>
					<p className="text-center py-4 r-subheading-3 mb-0 error-line d-block "><i className="fi flaticon-alert"></i>{this.props.message}</p>
					<Button onClick={this.props.onClose} className="close-bn"><i className="fi flaticon-close" ></i></Button>
				</Fade>
			)
		// }
		// else {
		// 	return null;
		// }
	}
}

class ErrorComponent extends Component {

	componentWillReceiveProps(nextProps) {
		if (nextProps.error.showBar === true) {
			if (this.timeout)
				clearTimeout(this.timeout)
			this.timeout = setTimeout(() => this.onClose(), 2500)
		}
	}

	onClose = () => {
		this.props.setError({ showBar: false })
	}

	render() {
		let { error } = this.props
		return (
			<Fade in={!!error.showBar} unmountOnExit={true} className="error-bar">
				<p className="text-center py-4 r-subheading-3 mb-0 error-line d-block "><i className="fi flaticon-alert"></i>{error.message || "Something went wrong"}</p>
				<Button onClick={this.onClose} className="close-bn"><i className="fi flaticon-close" ></i></Button>
			</Fade>
		)
	}
}
const mapStateToProps = state => ({
	...state
})
const mapDispatchToProps = dispatch => ({
	setError: (error) => dispatch(setError(error))
})
export const GlobalErrorbar = withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorComponent));

