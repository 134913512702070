import React,{Component} from 'react';
import { Collapse } from 'reactstrap';
import {  CardBody, CardTitle, CardText} from 'reactstrap';
import {Col,Row} from 'reactstrap';
import Aux from '../../global/AuxComp';
import {StatusButton} from './verifier_status_button';
import moment from 'moment';
import { connect } from 'react-redux';

class ExtraCurricularActivityCollapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
      };
    this.section = Math.ceil(Math.random() * 10000)
    this.popoverName = "POP-" + this.section
  }

  toggle = () => {
    this.props.toggleSection(this.section)
    this.props.getfileId(this.props.extraCurricularActivity.file);
  }

  render() {
    let extraCurricularActivity = this.props.extraCurricularActivity;
    let allCommentThread= this.props.getAllCommentThread!=null?(this.props.getAllCommentThread.filter(commentThread=>
       commentThread.profile_type=="EXTRA_CURRICULAR" && commentThread.profile_id==extraCurricularActivity.extra_curricular_id && commentThread.student_id==extraCurricularActivity.student_id &&commentThread.verifier_id==this.props.role.assigned_role_id)):null;
    return (
      <Aux>
      <CardBody onClick={this.toggle}   className={"verifier__card-body  "+(this.section==this.props.collapse?"active":"")}>
      <CardTitle>
      <Row>
      <Col xs={10}>
      <span className="r-heading-6 mr-3">
      {extraCurricularActivity.activity_name ||  "Activity Name"}</span>
      <span>{("("+moment(extraCurricularActivity.activity_date).year()+")") ||  "" } </span>
      </Col>
      {extraCurricularActivity.file!=0? <Col xs={2} align="end">
          <span title="Proof Attached"><img src="/images/paper-clip@2x.png" alt="Proof" className="w-25" /></span>
      </Col> : null}
      </Row>
      </CardTitle>
      <CardText>
      <Row>
      <Col xs="auto">
      <span>{extraCurricularActivity.activity_level}
      </span>
      </Col>
      </Row>
      <Row>
      <Col xs="auto" className="r-subheading-3">
      <p>{extraCurricularActivity.activity_type!=null? extraCurricularActivity.activity_type : "Activity Type"} {" | "}{extraCurricularActivity.organised_by || "Organization"}
      </p>
      </Col>
      </Row>
      </CardText>
      </CardBody>
      <Collapse  className="r-bg--blue-1" isOpen={this.props.collapse==this.section}>
      <StatusButton allCommentThread={allCommentThread} student_id={extraCurricularActivity.student_id} id={extraCurricularActivity.extra_curricular_id} profileType="EXTRA_CURRICULAR" profile_type="extra_curricular" getStudentDetails={this.props.getStudentDetails}  popoverName={this.popoverName} data={extraCurricularActivity} />
      </Collapse>
      </Aux>
    );
  }
}
const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(ExtraCurricularActivityCollapse);
// export default ExtraCurricularActivityCollapse;
