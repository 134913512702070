import React, { Component } from 'react';
import { connect } from 'react-redux';
import Redirect from 'react-router-dom/Redirect';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../actions/Auth';
import { SelectList } from '../../global/Forms/Select'
import { Switch } from '../../global/Forms/ToggleSwitch';
import { setUser, setLoading } from '../../../actions/action-creators';
import Button from '../../global/Button';
import { InputField } from '../../global/Forms';
import { Nav, NavItem, NavLink } from 'reactstrap';

class PTTeamNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getProcesList: [],
      getStudentAssigned: [],
      getStudentProfileDetails: [],
      processList: [{ process_name: "Profiles", process_type: "student", process_id: 1 }, { process_name: "Snapshot", process_type: "batch", process_id: 2 }],
      selectedProcess: this.props.process,
      isOn: false,
      modalstudent: false,
      modalshare: false,
      modal: false,
      modaledit: false,
    };
  }

  fetchApplications = (process) => {
    this.props.history.push("/pt/students/" + process.process_type);
  }


  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  togglestudent = () => {
    this.setState(prevState => ({
      modalstudent: !prevState.modalstudent
    }));
  }

  toggleshare = () => {
    this.setState(prevState => ({
      modalshare: !prevState.modalshare
    }));
  }

  toggledit = () => {
    this.setState(prevState => ({
      modaledit: !prevState.modaledit
    }));
  }


  handleToggle(e) {
    this.setState({ isOn: !this.state.isOn });
  }

  render() {
    let { getStudentProfileDetails, selectedProcess, processList } = this.state;
    return (
      <div className="my-4">
        {/* {selectedProcess && <SelectList selected={selectedProcess.process_name}>
          {
            processList.map(process => {
              return (
                <li key={process.process_id} onClick={() => this.fetchApplications(process)} >{process.process_name}</li>
              )
            })
          }
        </SelectList>} */}

          {/* {selectedProcess &&
            processList.map(process => {
              return (
                <Button className="mr-3 r-subheading-2" key={process.process_id} onClick={() => this.fetchApplications(process)} color={selectedProcess.process_name === process.process_name ? "blue-3" : "blue"}>
                  {process.process_name}
                </Button>
              )
            })
          } */}

      </div>
    )
  }
}

export default withRouter(PTTeamNav);
