import React, { Component } from 'react';
import Button from '../../../global/Button';
import Aux from '../../../global/AuxComp';
import Progress from 'reactstrap/lib/Progress';
import store from '../../../../store';
import { setError } from '../../../../actions/action-creators';
import { Auth } from '../../../../actions/Auth';

export class ApplicationStatus extends Component {
  state = {
    jobData: null,
    init: true
  }

  checkJob = async () => {
    let { job } = this.props
    try {
      let jobResp = await Auth.Kue.search({ q: `campus_job_id_${job.campus_job_id}` });
      if (jobResp.data[0]) {
        let detailsResp = await Auth.Kue.getJobDetails({ id: jobResp.data[0] })
        if (detailsResp.data.state !== "failed") {
          this.setState({ jobData: detailsResp.data })
          setTimeout(() => {
            let { jobData } = this.state
            if (jobData && jobData.state !== "complete")
              this.checkJob()
          }, 1000)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  async componentDidMount() {
    await this.checkJob();
    this.setState({ init: false })
  }

  render() {
    let { jobData, init } = this.state;
    if (init)
      return (<div />)
    if (jobData)
      return (
        jobData.state !== "complete" ? <Aux>
          <span>{jobData.progress}%</span>
          <Progress value={jobData.progress} />
          <span className="r-text--brandF r-text--sm">{jobData.error}</span>
        </Aux> : <Button color="blue">&#10004;Added to Placement Drive</Button>
      )
    return (
      <Aux>
        <Button color="dark" onClick={this.props.getJobDetails} className="r-btn--no-radius r-text--sm mr-3">View</Button>
        <Button type="submit" onClick={this.props.handleApplicationAccept} className="r-text--sm r-btn--no-radius  r-text--blue-3" color="outline-dark-v">Accept</Button>
      </Aux>
    )
  }
}