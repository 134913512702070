import React from 'react';
import { Row,Col,Container,UncontrolledCollapse,CardBody, Card,Collapse,Spinner,Modal } from 'reactstrap';
import { connect } from 'react-redux';
import { HorizontalLine } from '../../../global/horizontal_line';
import { CONSTANTS } from '../../../../constants';
import Button from '../../../global/Button';
import  Profile  from './profile';
import Link from 'react-router-dom/Link';
import  Connections  from './connection';
import  Students  from './students';
import Placement  from './placement';
import store from '../../../../store';
import { Auth } from '../../../../actions/Auth';
import { uploadFile, getFiles, sortObjectArray, getErrorMessage } from '../../../../actions';
import About from './about';
import Aux from '../../../global/AuxComp';
import { Loader } from '../../../global/Loader';
import Axios from 'axios';
import { setError } from '../../../../actions/action-creators';

class CollegeProfile extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading : false,
      sideNavLeft: null,
      sideNavRight: null,
      fileurl: null,
      file: '',
      logoUrl : "",
      bannerUrl : "",
      campusProfile: {},
      campusDetail: {campus_id : "NAN"},
      count:20,
      isLoggedIn : false,
      activeProcessList : [],
      isOpenReport : false,
      isOpenTemplate:false,
      isLoadingReporOverview : false,
      reportOverview : {},
      visitorCounts : {},
      processList:[{process_id:1,process_name:"placement 2017"},{process_id:2,process_name:"placement 2018"},{process_id:3,process_name:"placement 2019"}],
      initConnection : false,
      connections : [],
      applications: []
    };
     
  }

 async componentDidMount(){
   if (this.props.user) {
     this.setState({isLoggedIn : true})
      let hasPermission = this.props.role.hasPermission;
      if(!hasPermission.campusProfile || (hasPermission.campusProfile && !hasPermission.campusProfile.permission_access.read)) {
        this.props.history.push("/pt/students/student")
        return
      }
    }
    await this.getCampusDetail();
    await this.getActivePlacement();
    await this.fetchConnections()

    this.setState({ tab: this.props.user ? (this.props.match.params.tab || 'students') : 'about'  })
  }
  fetchConnections = async () => {
    var  campus_id = this.state.campusDetail.campus_id
    this.setState({initConnection: true})
    try {
      let connectionResp = await Axios.all([Auth.Recruiter.Connection.getRequests({ campus_id }), Auth.Recruiter.Connection.getApplications({ campus_id }), Auth.Processes.getProcesses(campus_id)]);
      connectionResp[0].data.data.sort(sortObjectArray("connection_status"))
      let connections = connectionResp[0].data.data.filter(conn => (conn.connection_status === 0 || conn.connection_status === 1))
      connectionResp[1].data.data.sort(sortObjectArray("job_status"))
      this.setState({ connections, applications: connectionResp[1].data.data, processes: connectionResp[2].data })
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    this.setState({initConnection: false})
  }
  getActivePlacement = async() =>{
    this.setState({isLoadingActiveProcess : true})
    try{
        let body = {
            campus_id : this.state.campusDetail.campus_id
        }
        let processResp = await Auth.PublicCampusProfile.fetchActiveProcess(body);
        this.setState({activeProcessList : processResp.data, isOpenReport : processResp.data[0] ? processResp.data[0].process_id : false})
        this.fetchReportOverview();
    }
    catch(e){
        console.log(e);
    }
    this.setState({isLoadingActiveProcess : false})
  }
  fetchReportOverview = async() =>{
    this.setState({isLoadingReporOverview : true})
    try{
        var body = {
            process_id : this.state.isOpenReport
        }
        let reportOverviewResp = await Auth.CampusProfile.fetchReportOverview(body);
        let data = reportOverviewResp.data;
        this.setState({reportOverview: data})
    }
    catch(e){
        console.log(e);
    }
    this.setState({isLoadingReporOverview : false})
  }

  getCampusDetail = async () =>{
    this.setState({isLoading : true})

         try{
           var recruiter_id;
          if (this.props.user) {
            var body ={campus_id : store.getState().role.instituteDetail.campus_id};
            let resp = await Auth.Spoc.getInstituteDetails(body); 
            this.setState({campusDetail: resp.data.campusDetails[0] || {} });    
          }
          else{
            var body ={slug_name : this.props.match.params.campus_slugname}
            let resp = await Auth.PublicCampusProfile.fetch(body);
            this.setState({campusDetail: resp.data[0] || {} }); 
            var recruiterUserResp = await Auth.Recruiter.User.get();  
            recruiter_id = recruiterUserResp.data.data.recruiter_id
          }
          await this.getCampusProfileData(recruiter_id);
         }
         catch (e) {
          this.setState({campusDetails : {}})
          console.log(e)
         }    
         this.setState({isLoading : false})

  }
  getCampusProfileData = async (recruiter_id) =>{
    this.setState({isLoading : true})
    try{
      let resp = await Auth.CampusProfile.fetch({campus_id : this.state.campusDetail.campus_id, recruiter_id}); 
      var campusProfile = resp.data[0] || {};
     
      this.setState({ campusProfile:campusProfile })
    } catch (e) {
      console.log(e)
    }
    this.setState({isLoading : false})
  }

  changeTab = (e) => {
    this.setState({tab: e.target.dataset.tabid})
    let tab = e.target.dataset.tabid;
    let baseUrl = "/pt/college/"
    this.props.history.push(baseUrl + tab)
    window.scrollTo(0, 0)
  }

  changeView = () => {
    this.setState({isLoggedIn : !this.state.isLoggedIn})
    this.setState({tab: 'about'})
    let tab = 'about';
    let baseUrl = "/pt/college/"
    this.props.history.push(baseUrl + tab)
    window.scrollTo(0, 0)
  }

  changeLogoImage = async (e) => {
    try{
      let fileResp = await uploadFile(e.target.files[0], "campus_profile")
      this.campusProfileChange("logo_file_id",fileResp.file_id)
      this.handleProfileSubmit();
    }
    catch(err){
      console.log(err)
    }
  }

  changeBannerImage = async (e) => {
    try{
      let fileResp = await uploadFile(e.target.files[0], "campus_profile")
      this.campusProfileChange("banner_file_id",fileResp.file_id)
      this.handleProfileSubmit();
    }
    catch(err){
      console.log(err)
    }
  }
  campusProfileChange = (key, value) => {
    let { campusProfile } = this.state;
    campusProfile[key] = value;
    this.setState({campusProfile});
  }
  handleProfileSubmit = async() => {
    try{
      let { campusProfile } = this.state;
      if(campusProfile.campus_profile_id){
        await Auth.CampusProfile.updateCampusProfile(campusProfile)
      }
      else{
        await Auth.CampusProfile.createCampusProfile(campusProfile)
      }
      await this.getCampusProfileData();
    }
    catch(err){
      console.log(err)
    }
  }


  redircttoConnection = () =>{
    this.setState({tab: 'connection'})
    let tab = 'connection';
    let baseUrl = "/pt/college/"
    this.props.history.push(baseUrl + tab)
    window.scrollTo(0, 0)
  }


  render() {
    const {activeProcessList, isLoadingReporOverview, isLoading ,sideNavRight,campusDetail, campusProfile, tab, isLoggedIn,isOpenTemplate} = this.state;
   var pendingRequest = 0;
   pendingRequest = pendingRequest+this.state.connections.filter(connection => connection.connection_status == 0).length;
   let pendingApplicationRequest = this.state.applications.filter(app => app.job_status == 1).length;
   pendingRequest = pendingRequest+pendingApplicationRequest;

  var connectedCounts = this.state.connections.filter(connection => connection.connection_status == 1).length;
    let showBooklet = (this.props.match.params.campus_slugname==="be9c")?true:false;
    return (
      !campusDetail.campus_id ? 
      <h1 className="text-center mt-5 pt-5">No Profile Found</h1>
      :
        <Container>
          <Loader show={isLoading}/>
         
            <div className=" p-0" style={{backgroundColor:"#fff", position: "sticky", top : "-175px", zIndex : 99}}>
              <div>
                {
                   campusProfile.bannnerFileObject && campusProfile.bannnerFileObject.signed_url ?
                  <div className="student__profile__image__container">
                     <img style={{height:"250px", width:'100%'}} src={campusProfile.bannnerFileObject && campusProfile.bannnerFileObject.signed_url} alt="Cover Photo" />
                     { isLoggedIn &&<span className="fi flaticon-edit-2 student__profile__image--edit">
                        <input title="Update College Logo" type="file" accept="image/png, image/jpeg" className="student__profile__image--upload" onChange={this.changeBannerImage} />
                      </span>}
                  </div>
                  :
                  <div className="mt-0" style={{height:"250px", width:'100%', background:'#aec7d8'}}>
                    <Row className="text-center w-100 h-100 align-items-center">
                      <Col>
                      { isLoggedIn && <input title="Update College Banner" type="file" accept="image/png, image/jpeg"  onChange={this.changeBannerImage} />}
                      </Col>
                    </Row>
                  
                  </div>

                }
              </div>
              <Row>
                <Col xs={8}>
                  <Row className="ml-3 py-3">
                    <Col style={{maxWidth: '125px'}} className="mx-0 px-0 student__profile__image__container">
                      <img  style={ {marginTop:'-45px', objectFit : "fill"}} src={(campusProfile.logoFileObject && campusProfile.logoFileObject.signed_url) || "/images/dummy_college.png"} className="img-fluid student__profile__image w-100" alt="Profile Image" />
                     { isLoggedIn && <span  style={ {marginTop:'-45px'}} className="fi flaticon-edit-2 student__profile__image--edit" >
                        <input title="Update College Logo" type="file" accept="image/png, image/jpeg" className="student__profile__image--upload" onChange={this.changeLogoImage} />
                      </span>
                      }
                    </Col>
                    <Col xs={9}  className="mx-0 px-0 pl-4">
                        <span className="mb-0 r-subheading-1 text-capitalize"  >{this.state.campusDetail.name}</span> <br></br>
                        <span className="">Connections</span>
                        <span className="pl-3" style={{fontWeight: 700}}>{connectedCounts}</span>
                    </Col>
                  </Row>
                </Col>
               {/* <Col xs={4} className="mt-4 college_text-end">
                  {this.props.user ? <Button style={{fontSize: '15px'}} color="link" onClick={this.changeView}><i className="fi flaticon-eye mr-2"></i>{this.state.isLoggedIn ? "View as Public":"View as Member"}</Button> : "" } <br></br>
                      {isLoggedIn ?  <span className="">No. of views<span className="pl-3" style={{fontWeight: 700}}>{campusProfile.visitor_count}</span></span>
                    : " "}
                </Col> */}
              </Row>
              <HorizontalLine color="#cfcfcf" />

            </div>
          <Row className="mt-5 ">
            <Col xs={2}  className="left-sidenav pr-0">
              
                <div style={{position : "sticky", top : 165}}>
                <ul className="r-sidenav__menu">
                  { isLoggedIn ?
                    <Aux>{
                      CONSTANTS.COLLEGENAV.map(nav => {
                        return (
                          <li key={nav.id} onClick={this.changeTab} data-tabid={nav.id} className={"r-heading-6 " + (tab === nav.id ? "sidenav-active" : "")}>
                            {nav.text}{nav.id === "connection" && pendingRequest > 0 ? <div style={{float:"right", borderRadius:"50%", background:"red", height:20, width:20, textAlign:"center"}}>{pendingRequest} </div> : null }
                          </li>
                        )
                      }) }

                         
                    </Aux>
                     :
                     <Aux>
                        <li onClick={()=> {this.setState({tab: "about"})}} data-tabid={"about"} className={"r-heading-6 " + (tab === "about" ? "sidenav-active" : "")}>
                        About
                        </li>
                        {/*<li onClick={()=> {this.setState({tab: "feed"})}} data-tabid={"feed"} className={"r-heading-6 " + (tab === "feed" ? "sidenav-active" : "")}>
                            Feed
                        </li>*/}
                    </Aux>
                  }
                   {this.props.user ? 
                   <li onClick={this.changeView} className={"r-heading-6"}>
                       {this.state.isLoggedIn ? "View Public Profile":"Back"}
                    </li> : null
                    }
                </ul>
                {isLoggedIn ?  <div className="mt-4 ml-4 pl-2" style={{fontSize: "17px"}}>
                                  <hr />
                                  No. of views
                                  <div className="border border-primary p-4 text-center mt-3" style={{fontWeight: 600, fontSize : 20, borderRadius : 15, width : 70}}>{campusProfile.visitor_count || 0}</div>
                                </div>
                      : " "}
                </div>
               
            
              {/* { isLoggedIn &&

              <Row className="mt-5">
                <Col>
                  <div className="right-sidenav " style={{ ...sideNavRight }} >
                      <h2 className="r-heading-5 r-text--dark text-center"> Quick Links</h2>
                      <HorizontalLine color="#cfcfcf" />
                      { this.props.role.hasPermission.studentTab && this.props.role.hasPermission.studentTab.permission_access.read ? 
                        <Aux><Row id="toggler" className="justify-content-between align-item-center px-4 py-2" >
                            <span className="r-heading-5 d-inline-block r-text--blue-3">Students</span>
                            <i className="r-pointer r-text--sm fi flaticon-plus" />
                        </Row>
                        <UncontrolledCollapse toggler="#toggler">
                            <Col xs={12}><Link to={"/pt/students/batches"} ><span className="r-subheading-3 r-text--dark">Add batch</span></Link></Col>
                            <Col xs={12}><Link to={"/pt/students/student"} ><span className="r-subheading-3 r-text--dark">Add students</span></Link></Col>
                      </UncontrolledCollapse> 
                      </Aux> : ""
                      }
                       { this.props.role.hasPermission.companiesTab && this.props.role.hasPermission.companiesTab.permission_access.read ? 
                        <Aux>
                          <Row id="toggler1" className="justify-content-between align-item-center px-4 py-2" >
                              <span className="r-heading-5 d-inline-block r-text--blue-3">Companies</span>
                              <i className="r-pointer r-text--sm fi flaticon-plus" />
                          </Row>
                          <UncontrolledCollapse toggler="#toggler1">
                              <Col xs={12}><Link to={"/pt/companies"} ><span className="r-subheading-3 r-text--dark">Add company</span></Link></Col>
                          </UncontrolledCollapse>
                        </Aux> : ""
                      }
                       { this.props.role.hasPermission.placementsTab && this.props.role.hasPermission.placementsTab.permission_access.read ?
                        <Aux>
                          <Row id="toggler2" className="justify-content-between align-item-center px-4 py-2" >
                              <span className="r-heading-5 d-inline-block r-text--blue-3">Placements</span>
                              <i className="r-pointer r-text--sm fi flaticon-plus" />
                          </Row>
                          <UncontrolledCollapse toggler="#toggler2">
                              <Col xs={12}><Link to={"/pt/processes"} ><span className="r-subheading-3 r-text--dark">Add placement drive</span></Link></Col>
                          </UncontrolledCollapse>
                        </Aux> : ""
                        }
                        { this.props.role.hasPermission.reportPlacementTeamPerformance && this.props.role.hasPermission.reportPlacementTeamPerformance.permission_access.read ?  
                        <Aux>
                          <Row id="toggler3" className="justify-content-between align-item-center px-4 py-2" >
                              <span className="r-heading-5 d-inline-block r-text--blue-3">Reports</span>
                              <i className="r-pointer r-text--sm fi flaticon-plus" />
                          </Row>
                       
                          <UncontrolledCollapse toggler="#toggler3">
                              <Col xs={12}><Link to={"/pt/performance/process"} ><span className="r-subheading-3 r-text--dark">Placement report</span></Link></Col>
                              <Col xs={12}><Link to={"/pt/performance/pt"} ><span className="r-subheading-3 r-text--dark">Team report</span></Link></Col>
                              <Col xs={12}><Link to={"/pt/performance/student"} ><span className="r-subheading-3 r-text--dark">Student report</span></Link></Col>
                              <Col xs={12}><Link to={"/pt/performance/meeting-report"} ><span className="r-subheading-3 r-text--dark">Meeting report</span></Link></Col>
                              <Col xs={12}><Link to={"/pt/performance/company-report"} ><span className="r-subheading-3 r-text--dark">Company report</span></Link></Col>
                          </UncontrolledCollapse>
                        </Aux> : ""
                        }
                        { this.props.role.hasPermission.settingsTab && this.props.role.hasPermission.settingsTab.permission_access.read ? 
                          <Aux>
                            <Row id="toggler4" className="justify-content-between align-item-center px-4 py-2" >
                                <span className="r-heading-5 d-inline-block r-text--blue-3">Settings</span>
                                <i className="r-pointer r-text--sm fi flaticon-plus" />
                            </Row>
                            <UncontrolledCollapse toggler="#toggler4">
                                <Col xs={12}><Link to={"/pt/settings"} ><span className="r-subheading-3 r-text--dark">PT configurations</span></Link></Col>
                            </UncontrolledCollapse>
                          </Aux>
                          : ""
                          }
                        { this.props.role.hasPermission.calendarTab && this.props.role.hasPermission.calendarTab.permission_access.read ?  
                        <Aux>
                          <Row id="toggler5" className="justify-content-between align-item-center px-4 py-2" >
                              <span className="r-heading-5 d-inline-block r-text--blue-3">Calendar</span>
                              <i className="r-pointer r-text--sm fi flaticon-plus" />
                          </Row>
                          <UncontrolledCollapse toggler="#toggler5">
                              <Col xs={12}><Link to={"/calendar/events"} ><span className="r-subheading-3 r-text--dark">Add event</span></Link></Col>
                              <Col xs={12}><Link to={"/calendar/meetings"} ><span className="r-subheading-3 r-text--dark">Add meeting</span></Link></Col>
                          </UncontrolledCollapse>
                        </Aux>:""
                        }
                    </div>
                  </Col>
                </Row>
  } */}
            </Col>
            <Col  xs={7}>
              {tab === "profile" && <Profile />}
              {tab === "connection" && <Connections pendingApplicationCount={pendingApplicationRequest} connections={this.state.connections} applications={this.state.applications} init = {this.state.initConnection} onChangeApplication={(applications) => this.setState({applications})} fetchConnections={this.fetchConnections}/>}
              {tab === "students" && <Students />}
              {tab === "placments" && <Placement />}
              {tab === "about" && <About getActivePlacement={this.getActivePlacement} activeProcessList={activeProcessList} isLoggedIn={isLoggedIn} campus_id={this.state.campusDetail.campus_id} campusProfile={campusProfile} campusProfileChange={this.campusProfileChange} handleProfileSubmit={this.handleProfileSubmit} />}
              {tab === "feed" && 
                <Row className="mt-5">
                  <Col>
                  <h1 className="r-heading-3 text-center">Coming soon....</h1>
                  </Col>
                </Row>}

            </Col>

            
          <Col xs={3}>
            <div style={{position : "sticky", top : 160}}>
              {isLoggedIn && <h2 className="r-heading-4 r-text--dark text-center">Placement  Statistics</h2>}
              {isLoggedIn && <HorizontalLine  className="mt-2" color="#cfcfcf" />}
               {isLoggedIn && activeProcessList.map( (data, i) => 
               <Row key={i}>
                 <Col className="">
                  <Button className="mt-3" style={{width: "80%", marginLeft: "10%"}} onClick={()=>{this.setState({isOpenReport: this.state.isOpenReport == data.process_id ? false : data.process_id})}} color="blue-3" >
                    {data.process_name}
                  </Button>
                  <Collapse onEntering={this.fetchReportOverview} className="mt-3"  isOpen={data.process_id == this.state.isOpenReport ? true : false}>
                    <Card className="p-1">
                      <CardBody className="pb-0">
                        {isLoadingReporOverview ? <div className="center-xy"><Spinner animation="border" variant="dark" /></div> :
                        <div>
                            <Row  className="mb-2 border-bottom">
                                <Col><span className="r-heading-6 text-center">OFFERS</span></Col>
                            </Row>
                            <Row className="border-bottom mt-3">
                                <Col xs={8}><span className="r-subheading-5">Total Students</span></Col>
                                <Col className="text-right" xs={4}>{this.state.reportOverview.placed + this.state.reportOverview.unplaced}</Col>
                            </Row>
                            <Row className="mt-3 border-bottom">
                                <Col xs={8}><span className="r-subheading-5">Placed</span></Col>
                                <Col className="text-right" xs={4}>{this.state.reportOverview.placed}</Col>
                            </Row>
                            <Row  className="mt-3 border-bottom">
                                <Col xs={8}><span className="r-subheading-5">Unplaced</span></Col>
                                <Col className="text-right" xs={4}>{this.state.reportOverview.unplaced}</Col>
                            </Row>
                            <Row  className="mt-3 border-bottom">
                                <Col xs={8}><span className="r-subheading-5">Total Offers </span></Col>
                                <Col className="text-right" xs={4}>{this.state.reportOverview.total_offer}</Col>
                            </Row>
                            <Row  className="mt-3 border-bottom">
                                <Col><span className="r-heading-6 text-center">SALARY</span></Col>
                            </Row>
                            <Row  className="mt-3 border-bottom">
                                <Col><span className="r-subheading-5">Highest</span></Col>
                                <Col className="text-right">{Math.round(this.state.reportOverview.highest).toLocaleString('en-IN')}</Col>
                                </Row>
                            <Row  className="mt-3 border-bottom">
                                <Col><span className="r-subheading-5">Average</span></Col>
                                <Col className="text-right">{Math.round(this.state.reportOverview.average).toLocaleString('en-IN')}</Col>
                            </Row>
                            <Row  className="mt-3 border-bottom">
                                <Col><span className="r-subheading-5">Median</span></Col>
                                <Col className="text-right">{Math.round(this.state.reportOverview.median).toLocaleString('en-IN')}</Col>
                            </Row>
                            <Row  className="mt-3">
                                <Col><span className="r-subheading-5">Lowest</span></Col>
                                <Col className="text-right">{Math.round(this.state.reportOverview.lowest).toLocaleString('en-IN')}</Col>
                            </Row>
                           
                           
                        </div>}
                      </CardBody>
                    </Card>
                  </Collapse>
                  </Col>
                </Row>
              )
              }
              {showBooklet && <Button className="mt-3" style={{width: "80%", marginLeft: "10%"}} onClick={()=>{this.setState({isOpenTemplate:!isOpenTemplate })}} color="blue-3" >
                              Open Booklet
                            </Button>}
            </div>
        </Col> 
          </Row>  
          {isOpenTemplate && <Modal zIndex="99999" isOpen={isOpenTemplate} toggle={() =>{this.setState({isOpenTemplate:!isOpenTemplate})}} size="xl" style={{ marginTop: "50px" }}>
                              <div>
                                   <iframe src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=zhpl11dqwd" width="100%" height={600} seamless="seamless" scrolling="no" frameBorder={0} allowFullScreen />
                              </div>
                            </Modal>}
        </Container>
    );
  }
}

const mapStateToProps = state => ({
  user : state.user,
  role : state.role
})

export default connect(mapStateToProps, null)(CollegeProfile)
