import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createBrowserHistory} from 'history';
import {Row,Col,Container} from 'reactstrap';
import { Card, Button, CardHeader, CardFooter, CardBody, CardTitle, CardText} from 'reactstrap';
import SectionPointCollapse from './verifier_section_card_collapse';
import PositionOfResponsibilityCollapse from './verifier_position_of_responsibility_collapse';
import ProfessionalDetailCollapse from './verifier_professional_detail_collapse'
import ProjectCollapse from './verifier_project_collapse';
import ExtraCurricularActivityCollapse from './verifier_extra_curricular_activity_collapse';
import AchievementCollapse from './verifier_achievement_collapse'
import OtherExperienceCollapse from './verifier_other_experience_collapse';
import CvPointCollapse from './cv_point_collapse';
import PopoverExampleMulti from './verifier_comment';
import {CONSTANTS} from '../../../constants';
import Aux from '../../global/AuxComp';
import { Auth } from '../../../actions/Auth';
import PersonalDetailCollapse from './personal_detail';

export class VerifySection extends Component{
  constructor(props){
    super(props);
    this.state={
      collapse: "",
      value:"",
      studentDetails: this.props,
      allCommentThread:null
    };
  }
    onClickHandler = (value)=>{
      if(value==0){
         this.setState({
           value
       })
     }
     else{this.setState({value:-1})}
    }

    getAllCommentThread=async ()=>{
      if(this.props.studentId && this.props.role.assigned_role_id){
      try{
        let commentThread = await Auth.CommentBox.getCommentThread(this.props.studentId,this.props.role.assigned_role_id);
        await this.setState({
          allCommentThread:commentThread.data
        })
      }
      catch(e){
        console.log(e);
      }
    }
  }
    async componentWillMount(){
      await this.getAllCommentThread();
    }


  toggleSection = (section) => {
    this.setState({
      collapse: section
    })
  }
  render(){
    let {academicDetail,achievement ,personalDetail,extraCurricularActivity, otherExperience, positionOfResponsibility, preferences, professionalDetail , project, skill, cvPoint,student_info, cvPointProject,cvPointProfessional, cvPointExtraCurricular, cvPointOtherExperience, cvPointPositionOfResponsibility, value , cvPointAchievments } = this.props;
    return(
      <Aux>
      {
        personalDetail[0]!=undefined && personalDetail[0].student_id!=undefined && (personalDetail[0].is_verified==value || value==-1) ?

            <div>
           <Card className="verifier__section-card">
            {
               <CardHeader className="r-heading-4 r-text--blue-4 r-bg--grey-1">Personal Details</CardHeader>
            }
              {
               this.state.allCommentThread &&
              <PersonalDetailCollapse student_info={this.props.student_info} getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails}  personalDetail={personalDetail} collapse={this.state.collapse} toggleSection={this.toggleSection} />
            }
               </Card>
               </div>:null
           }


      {
       this.state.allCommentThread && academicDetail.map(academicDetail => {
      let points = cvPoint[academicDetail.academic_id] || []
      let showSection = points.filter(pt=>pt.is_verified==value);
      if(academicDetail.is_verified==value || showSection.length || value==-1){
        return(
          <div>
          <Card className="verifier__section-card">
          {
            <CardHeader className="r-heading-4 r-text--blue-4 r-bg--grey-1">{CONSTANTS.ACADEMIC_TYPES[academicDetail.academic_type]}</CardHeader>
          }
            {
              academicDetail.is_verified==value || value==-1?
            <SectionPointCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails}  academicDetail={academicDetail} collapse={this.state.collapse} toggleSection={this.toggleSection}
             />:null}


            {points.map(cvPoint => {
              if(cvPoint.is_verified==value || value==-1){
              return(
                  cvPoint.text!=null?(<CvPointCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails} cvPoint={cvPoint} collapse={this.state.collapse} toggleSection={this.toggleSection} />):null

                )
              }
            })
          }
          </Card>
          </div>
        )
      }


    })
  }

{  this.state.allCommentThread && positionOfResponsibility.map(positionOfResponsibility => {
    let points=cvPointPositionOfResponsibility[positionOfResponsibility.id] || []
    let showSection = points.filter(pt=>pt.is_verified==value);

    if(positionOfResponsibility.is_verified==value || showSection.length || value==-1){

      return(
        <div>
        <Card className="verifier__section-card">
          <CardHeader className="r-heading-4 r-text--blue-4 r-bg--grey-1">Positions Of Responsibility</CardHeader>
          {(positionOfResponsibility.is_verified==value || value==-1)&&
          <PositionOfResponsibilityCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId}  getStudentDetails={this.props.getStudentDetails} positionOfResponsibility={positionOfResponsibility} collapse={this.state.collapse} toggleSection={this.toggleSection}  />}
          {points.map(cvPoint => {
            if(cvPoint.is_verified==value || value==-1)
            {
            return(
                <CvPointCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails} cvPoint={cvPoint} collapse={this.state.collapse} toggleSection={this.toggleSection} />
            )
          }
          })
        }
        </Card>
        </div>
      )
    }


  })
}

{  this.state.allCommentThread && professionalDetail.map(professionalDetail => {
    let points=cvPointProfessional[professionalDetail.professional_id] || []
    let showSection = points.filter(pt=>pt.is_verified==value);
    if(professionalDetail.is_verified==value || showSection.length || value==-1){
      return(
        <div>
        <Card className="verifier__section-card">
          <CardHeader className="r-heading-4 r-text--blue-4 r-bg--grey-1">Professional Experience</CardHeader>
          {professionalDetail.is_verified==value || value==-1?
          <ProfessionalDetailCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails} professionalDetail={professionalDetail} collapse={this.state.collapse} toggleSection={this.toggleSection}  />:null}
          {points.map(cvPoint => {
              if(cvPoint.is_verified==value || value==-1){
            return(
                cvPoint.text!=null?(<CvPointCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails} cvPoint={cvPoint} collapse={this.state.collapse} toggleSection={this.toggleSection} />):null
            )
          }
          })
        }
        </Card>
        </div>
      )
    }


  })
}

{
  this.state.allCommentThread && project.map(project => {
    let points=cvPointProject[project.project_id] || []
    let showSection = points.filter(pt=>pt.is_verified==value);
    if(project.is_verified==value || showSection.length || value==-1){
    return(
      <div>
      <Card className="verifier__section-card">
        <CardHeader className="r-heading-4 r-text--blue-4 r-bg--grey-1"> Projects </CardHeader>
        {(project.is_verified==value || value==-1)&&
        <ProjectCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails} project={project} collapse={this.state.collapse} toggleSection={this.toggleSection}  />}
        {points.map(cvPoint => {
            if(cvPoint.is_verified==value || value==-1){
          return(
              cvPoint.text!=null?(<CvPointCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails} cvPoint={cvPoint} collapse={this.state.collapse} toggleSection={this.toggleSection} />):null
          )
        }
        })
      }
      </Card>
      </div>
    )
  }

  })
}

{  this.state.allCommentThread && extraCurricularActivity.map(extraCurricular => {
    let points=cvPointExtraCurricular[extraCurricular.extra_curricular_id] || []
    let showSection = points.filter(pt=>pt.is_verified==value);
    if(extraCurricular.is_verified==value || showSection.length!=0 || value==-1){

    return(
      <div>
      <Card className="verifier__section-card">
        <CardHeader className="r-heading-4 r-text--blue-4 r-bg--grey-1"> Extracurricular Activity </CardHeader>
        {(extraCurricular.is_verified==value || value==-1)&&
        <ExtraCurricularActivityCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails} extraCurricularActivity={extraCurricular} collapse={this.state.collapse} toggleSection={this.toggleSection} />}
        {points.map(cvPoint => {
          if(cvPoint.is_verified==value || value==-1){
          return(
              cvPoint.text!=null?(<CvPointCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails} cvPoint={cvPoint} collapse={this.state.collapse} toggleSection={this.toggleSection} />):null
          )
        }
        })
      }
      </Card>
      </div>
    )
  }

  })
}

{  this.state.allCommentThread && achievement.map(achievement => {
    let points=cvPointAchievments[achievement.achievement_id] || []
    let showSection = points.filter(pt=>pt.is_verified==value);
    if(achievement.is_verified==value || showSection.length || value==-1){
    return(
      <div>
      <Card className="verifier__section-card">
        <CardHeader className="r-heading-4 r-text--blue-4 r-bg--grey-1"> {CONSTANTS.ACHIEVEMENT_TYPES[achievement.achievement_type]} </CardHeader>
        {(achievement.is_verified==value || value==-1)&&
        <AchievementCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails} achievement={achievement} collapse={this.state.collapse} toggleSection={this.toggleSection} />}
        {points.map(cvPoint => {
            if(cvPoint.is_verified==value || value==-1){
          return(
              cvPoint.text!=null?(<CvPointCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails} cvPoint={cvPoint} collapse={this.state.collapse} toggleSection={this.toggleSection} />):null
          )
        }
        })
      }
      </Card>
      </div>
    )
  }

  })
}

{  this.state.allCommentThread && otherExperience.map(otherExperience => {
    let points=cvPointOtherExperience[otherExperience.id] || []
    let showSection = points.filter(pt=>pt.is_verified==value);
    if(otherExperience.is_verified==value || showSection.length || value==-1){
    return(
      <div>
      <Card className="verifier__section-card">
        <CardHeader className="r-heading-4 r-text--blue-4 r-bg--grey-1"> {CONSTANTS.OTHER_EXPERIENCE_TYPES[otherExperience.type]} </CardHeader>
        {(otherExperience.is_verified==value || value==-1)&&
        <OtherExperienceCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails} otherExperience={otherExperience} collapse={this.state.collapse} toggleSection={this.toggleSection}  />}
        {points.map(cvPoint => {
            if(cvPoint.is_verified==value || value==-1)
          return(
              cvPoint.text!=null?(<CvPointCollapse getAllCommentThread={this.state.allCommentThread} getfileId={this.props.getfileId} getStudentDetails={this.props.getStudentDetails} cvPoint={cvPoint} collapse={this.state.collapse} toggleSection={this.toggleSection} />):null
          )
        })
      }
      </Card>
      </div>
    )
  }
  })
}
</Aux>

  )
  }
}


 const mapStateToProps = state => ({
   ...state
 })
export const  VerifySectionCard =  connect(mapStateToProps, null)(VerifySection);
