import React, { Component } from "react";
import { Button } from "reactstrap";
import Aux from "../../global/AuxComp";
import { Auth } from "../../../actions/Auth";
import PopoverExampleMulti from "./verifier_comment";
import { Loader } from "../../global/Loader";
import withRouter from "react-router-dom/withRouter";
import { Errorbar } from "../../global/errorbar";

async function updateVerifyStatus(formData) {
  try {
    let updateVerify = Auth.Verfier.updateStudentVerificationStatus;
    await updateVerify(formData);
  } catch (e) {
    if (e.response && e.response.data) console.log(e.response, this);
  }
}

async function freezeStudentSectionStatus(params) {
  try {
    let freezeStatus = await Auth.Verfier.freezeStudentSection(params);
  } catch (e) {
    // if (e.response && e.response.data) {
    //   this.setState({ errorbar: true });
    //   this.setState({ message: "THIS is errorbar" });
    // }
    if (e.response && e.response.data) console.log(e);
  }
}

const StatusButtonComponent = props => {
  // console.log(this.props.allCommentThread);
  let onClickHandler = async (value, freezeStatus) => {
    let formData = {};
    formData.is_verified = value;
    // formData.student_id= his.props.student_id || "";
    formData.profile_type = props.profile_type || "";
    formData.id = props.id || "";
    formData.student_id = props.student_id || 0;
    formData.process_id = props.match.params.process_id;
    // formData.cv_point_id = this.props.cv_point_id || "";
    await updateVerifyStatus(formData);

    let params = {
      profile_type: props.profile_type,
      id: props.id,
      student_id: props.student_id,
      process_id: props.match.params.process_id
    };

    if (value == 1 && freezeStatus == 1) {
      params.freeze_status = 1;
      await freezeStudentSectionStatus(params);
      await props.getStudentDetails(props.match.params.student_id);
    }
    else {
      params.freeze_status = 0;
      await freezeStudentSectionStatus(params);
      await props.getStudentDetails(props.match.params.student_id);
    }
  };

  let popoverName = props.popoverName;
  let data = props.data;

  let commentThreadId;
  let messages = [];

  let messageList = () => {
    if (props.allCommentThread != null) {
      let getMessageList = async () => {
        try {
          let getComment = await Auth.CommentBox.getComment(
            props.allCommentThread.verifier_comment_thread_id
          );
          getComment.data.map(comment => {
            messages.push(comment);
          });
          commentThreadId = props.allCommentThread.verifier_comment_thread_id;
        } catch (e) {
          console.log(e);
        }
      };
    } else {
      commentThreadId = null;
    }
  };
  return (
    <Aux>
      {" "}
      {/* {props.profile_type!="personal_detail" ?  */}
      {data.is_verified == 1 && data.freeze_status == 1  ? (
        <Button className="verifier__section-card-button verifier__section-card-button-selected">
          <span className="r-subheading-4 r-text--brandG">
            {" "}
            Approve & Freeze{" "}
          </span>{" "}
        </Button>
      ) : (
        <Button
          className="verifier__section-card-button"
          onClick={() => onClickHandler(1, 1)}
        >
          {" "}
          <span className="r-subheading-4"> Approve & Freeze </span>{" "}
        </Button>
      )}
      {/* ):null } {" "} */}
      {data.is_verified == 1 && data.freeze_status != 1 ? (
        <Button className="verifier__section-card-button verifier__section-card-button-selected">
          <span className="r-subheading-4 r-text--brandG"> Approve </span>{" "}
        </Button>
      ) : (
        <Button
          className="verifier__section-card-button"
          onClick={() => onClickHandler(1, 0)}
        >
          {" "}
          <span className="r-subheading-4"> Approve </span>{" "}
        </Button>
      )}{" "}
      {data.is_verified == 0 ? (
        <Button className="verifier__section-card-button verifier__section-card-button-selected">
          <span className="r-subheading-4 r-text--brandG"> Pending </span>{" "}
        </Button>
      ) : (
        <Button
          className="verifier__section-card-button"
          onClick={() => onClickHandler(0, 0)}
        >
          {" "}
          <span className="r-subheading-4"> Pending </span>{" "}
        </Button>
      )}{" "}
      {data.is_verified == 2 ? (
        <Button className="verifier__section-card-button verifier__section-card-button-selected">
          <span className="r-subheading-4 r-text--brandG"> Reject </span>{" "}
        </Button>
      ) : (
        <Button
          className="verifier__section-card-button"
          onClick={() => onClickHandler(2, 0)}
        >
          {" "}
          <span className="r-subheading-4"> Reject </span>{" "}
        </Button>
      )}{" "}
      <PopoverExampleMulti
        allCommentThread={props.allCommentThread}
        messages={messages}
        popoverName={popoverName}
        profile_type={props.profileType}
        student_id={props.student_id}
        profile_id={props.id}
        popoverOpen = {false}
      />{" "}
    </Aux>
  );
};

export const StatusButton = withRouter(StatusButtonComponent);
