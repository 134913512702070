import React, { Component } from 'react';
import Button from '../Button';

export class ToggleButton extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    let { values, onToggle, selected, buttonType } = this.props,
      { value1, value2 } = values;
    let classNames = ["r-toggle__btn", (selected) ? "toogel_red" : "toogel_green"].join(" ");
    let classNamesValue = ["r-toggle__value", (selected) ? "toogel_value_red" : "toogel_value_green"].join(" ");
    return (
      <div className={`r-toggle r-toggle--selected-${selected == value1 ? 1 : 2}`}>
        {(buttonType==="pt")?null:<Button className="r-toggle__value" onClick={() => onToggle(value1)}>{value1}</Button>}
        <button className={(buttonType==="pt")?classNames:"r-toggle__btn"} onClick={() => onToggle((selected == value1) ? value2 : value1)}></button>
        <Button className={(buttonType==="pt")?classNamesValue:"r-toggle__value"} onClick={() => onToggle(value2)}>{value2}</Button>
      </div>
    )
  }
}