import React,{Component} from 'react';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container} from 'reactstrap';
import {Button, ButtonGroup} from 'reactstrap';
import moment from 'moment';
import  {InputField} from '../../global/Forms';
import {Errorbar} from '../../global/errorbar';
import  {Switch} from '../../global/Forms/ToggleSwitch';
import {HorizontalLine} from '../../global/horizontal_line';
import {Collapse} from 'reactstrap';
import Aux from '../../global/AuxComp';
import { ListGroup, ListGroupItem } from 'reactstrap';
import {Card, CardTitle, CardBody} from 'reactstrap';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import DatePicker from 'react-dates/esm/components/SingleDatePicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import {YearPicker, MonthPicker , DayPicker} from 'react-dropdown-date';
import { Form, FormGroup, Label, Input, FormText, DropdownItem ,DropdownToggle, DropdownMenu , Dropdown, Modal , ModalHeader, ModalFooter, ModalBody} from 'reactstrap';
import Profile from '../ptTeam/Profile/Profile';
import withRouter from 'react-router-dom/withRouter';
import { Loader } from '../../global/Loader';
import StudentSelect from '../ptTeam/Profile/StudentSelect';
import {UnblockStudents} from '../ptTeam/process/unblock-student';


class StudentSection extends Component{
  constructor(){
    super();
    this.state={
      value:1,
      reminderValue:null,
      isOn:false,
      batchDetails: null,
      chooseBatch: "",
      dropdownOpen : false,
      requiredDegrees: null,
      batch : [],
      degreeList: [],
      requiredDegreesList : [],
      studentObject : {},
      batchObject: {},
      obj:{},
      department:{},
      students : [],
      deptList: [],
      studentList: [],
      year : null,
      month : null,
      day: null,
      studentMails : [],
      subject: null,
      body : null,
      reminderTriggerValue : 1,
      remindDropdownValue : null,
      remindTime: null,
      alreadySelectedModal : false,
      remindDate : null,
      deleteSelectedStudent : null,
      selectedStudentList : [],
      errorbar: false,
      message:'',
      filterModal : false,
      studentFilters: {
        batches: [],
        departments: [],
        groups: [],
      },
      batchList : [],
      deptList : [],
      groupList : [],
      allStudents : []
    }
  }

  filterStudents = async (studentFilters, batchList, depList, groupList) => {
    await this.setState({batchList: batchList, depList: depList, groupList: groupList });
    await this.setState(prevState => ({
      studentFilters: {
        ...prevState.studentFilters,
        batches: studentFilters.batches,
        departments: studentFilters.departments,
        groups: studentFilters.groups,
      }
    }))
    this.setState({ loader: true });
    let students = this.state.allStudents;

    if (studentFilters.batches.length > 0)
      students = students.filter(st => studentFilters.batches.indexOf(st.batch_id.toString()) > -1)
    if (studentFilters.departments.length > 0)
      students = students.filter(st => studentFilters.departments.indexOf(st.batch_id + "-" + st.degree_department_id) > -1)
    if (studentFilters.groups.length > 0)
      students = students.filter(st => studentFilters.groups.indexOf(st.batch_id + "-" + st.degree_department_id + "-" + st.group_id) > -1)
    await this.setState({ allStudents: students, filterModal: false });
    this.setState({ loader: false });
  }

  closebar = () =>{
      this.setState({errorbar: false});
  }



  OnChangeStartTime = (e)=>{
    if(this.state.day !=null && this.state.month!=null && this.state.year!=null){
      this.formatTime();
      let time = moment(e).format("HH:mm")+":"+"00";
      this.props.setRemindTime(time);
      this.setState({
        remindTime : moment(e).utc(false).format("HH:mm:ss")
      })
    }
  }

  formatTime = ()=>{
    let {day,month,year,remindTime} = this.state;
    let m = month;
    if(m/10<1){
      m = "0"+m;
      // m=m+1;
    }
    let d= day;
    if(d/10<1){
      d="0"+d
    }
    let remindDate = (year+"-"+m+"-"+d);
    this.props.setRemindDate(remindDate);
  }

  handleChange = (e)=>{

    this.setState({
      chooseBatch : e.target.value
    })
  }
  toggle = ()=>{
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  addClicker = async (batchDetails)=>{
    if(this.state.batch.length>0)
    {
      let batchList = this.state.batch;
      let batches = this.state.batch.filter(batch=>
        batch.batch_id == batchDetails.batch_id);
        if(batches.length == 0){
          batchList.push(batchDetails);
        }
        await this.setState({
          batch : batchList
        })
        await this.getBatchStudentObject();
      }
      else{
        let batches = this.state.batch;
        if(this.state.batch.length==0){
          batches.push(batchDetails);

          await this.setState({
            batch : batches
          })
        }
        await this.getBatchStudentObject();
    }
  }


deleteBatch = async (batches)=>{
  let batchList = [];
  this.state.batch.map( batchInfo => {
    if(batches.batch_id  != batchInfo.batch_id )
    {
      batchList.push(batchInfo);
    }
  });
        await this.setState({
        batch : batchList
      })
      await this.deleteDepartmenList(batches);
       this.getDepartmentsList();
    }
     handleReminderDropdown = (e)=>{
       switch(e.target.value){
         case '1' : this.props.setRemindTime(moment(this.props.startDate).subtract(10,'minutes'));
         this.props.setRemindDate(moment(this.props.startDate).format('YYYY-MM-DD'));
         break;
         case '2' : this.props.setRemindTime( moment(this.props.startDate).subtract(15,'minutes'));
         this.props.setRemindDate(moment(this.props.startDate).format('YYYY-MM-DD'));
         break;
         case '3' : this.props.setRemindTime( moment(this.props.startDate).subtract(30,'minutes'));
         this.props.setRemindDate(moment(this.props.startDate).format('YYYY-MM-DD'));
         break ;
         case '4' : this.props.setRemindTime( moment(this.props.startDate).subtract(45,'minutes'));
         this.props.setRemindDate(moment(this.props.startDate).format('YYYY-MM-DD'));
         break;
         case '5' : this.props.setRemindTime( moment(this.props.startDate).subtract(1,'hour'));
         this.props.setRemindDate(moment(this.props.startDate).format('YYYY-MM-DD'))
         break;
         default :
       }
     }

    onClickHandler = async (value)=>{
      await this.setState({
        value
      })
      this.getbatchDetails();
  }

   async componentDidMount(){
     await this.getbatchDetails();
     if(this.props.match.params.event_id) await this.getEventAttendees();
     await this.getTotalStudents();
  }

  getTotalStudents = async()=>{
    this.setState({loading: true})
    let tempbatch = {};
    let tempsts = [];
    let {selectedStudentList , batchDetails} = this.state;
    await batchDetails.forEach(batch => {
      tempbatch[batch.batch_id] = { batch_name: batch.batch_name, departments: {} };
      batch.student_info && batch.student_info.forEach(student => {
        tempsts.push(student)
        tempbatch[batch.batch_id].departments[student.degree_department_id] = { department_name: student.department_name }
      })
    })
    var finalStudentArray = tempsts;
    if(selectedStudentList.length){
      finalStudentArray= [];
      tempsts.forEach(st=>{
        var student = selectedStudentList.filter(selected=> selected.student_id == st.student_id) || []
        if(student.length == 0) finalStudentArray.push(st);
      });
      if(finalStudentArray.length)tempsts = finalStudentArray;
    }
    await this.setState({ allStudents: tempsts });
    this.setState({loading: false})

  }

  handleToggle = async (isOn)=>{
    await this.setState({
      isOn: !this.state.isOn
    })
    this.props.setSwitch(this.state.isOn);
  }

    deleteDepartmenList = async (batch)=>{
      let {batchObject} = this.state;
      Object.keys(batchObject).map(batches=>{
        if(batches == batch.batch_id){
          delete batchObject[batch.batch_id]
        }
      })
      this.setState({
        batchObject
      })
      await this.getDepartmentsList();
    }
    deleteDepartment = async (department, batch_id)=>{
      let {batchObject} = this.state;
      delete batchObject[batch_id].departments[department];
      await this.setState({
        batchObject
      })
      // this.getDepartmentsList();
       this.getDepartmentsList();
      // await this.filterStudentEmail();
    }

    getBatchStudentObject = async()=>{
      let studentDepartmentObject = {}
      let studentObject = {};
      let batchObject = {};
      let obj = {};
      let {batch} = this.state;
        batch.map(batches=>{
          batchObject[batches.batch_id] = {batch_name:batches.batch_name,departments:{}};
            batches.student_info.map(student=>{
                batchObject[batches.batch_id].departments[student.degree_department_id] = student.department_name
                        })
        })
        await this.setState({
          batchObject
        })
        await this.getDepartmentsList()



    }

  getbatchDetails =async  () => {
    this.setState({loading: true})
    try{
      let getBatchList = await Auth.Batch.getBatch();
      // this.getTotalStudents(getBatchList.data);
      await this.setState({
        batchDetails: getBatchList.data
      })
    }
    catch(e){
      this.setState({errorbar: true});
      if(e.response && e.response.data){
        this.setState({message: e.response.data.error.toString()});
      }
      else{
        this.setState({message: 'Something went wrong! Please try again'});
      }
    }
    this.setState({loading: false});
  }

  getDepartmentsList = async ()=>{
    let {batchObject}  = this.state;
    let deptList = [] ;
    Object.keys(batchObject).map(department=>{
      Object.keys(batchObject[department].departments).map(dept=>{
        deptList.push(dept);
      })
    })
    let finalList = deptList.sort();
    for(let i=0;i<finalList.length;i++){
      if(finalList[i]==finalList[i+1]){
        finalList.splice(i,1);
      }
    }
    await this.setState({
      deptList
    })
     await this.getStudentList();

  }

  filterStudentEmail = async()=>{
    let {studentList} = this.state;
    let studentMails = [];
    if(studentList!=undefined){
      studentList.map(email=>{
        studentMails.push(email.email);
      })
    }
    await this.setState({
      studentMails
    })
    this.props.setMailCollection(this.state.studentMails)

  }

  getStudentList = async (studentList)=>{
    // let studentList = ;
    // let {deptList} = this.state
    // let {batchObject} = this.state;
    // let finalBatchList = [];
    // let {batchDetails} = this.state;
    // for(let i=0;i<batchDetails.length; i++){
    //   Object.keys(batchObject).map(batch=>{
    //     if(batch==batchDetails[i].batch_id){
    //       finalBatchList.push(batchDetails[i]);
    //     }
    //   })
    // }
    //
    // finalBatchList.map(batch=>{
    //   batch.student_info.map(student=>{
    //     for(let i=0 ; i<deptList.length;i++){
    //       if(student.degree_department_id == deptList[i])
    //       {
    //         studentList.push(student);
    //       }
    //     }
    //
    //   })
    // })


    await this.setState({
      studentList
    })
    await this.props.setStudentCollection(studentList,this.props.eventList );
    if(studentList){
      await this.filterStudentEmail();
      await this.props.setTotalStudent(this.state.studentList.length);
       this.props.setStudenList(this.state.studentList)
       // this.props.setMailCollection(this.state.studentMails)
    }
    this.setState({filterModal : false});
  }

  // studentMailFilter = (studentMails)=>{
  //   // let {studentMails} = this.state;
  //   let list = [];
  //   let flag = 0;
  //   for(let i =0;i<studentMails.length;i++){
  //     for(let j=0;j<studentMails.length;j++){
  //       if(studentMails[i]==studentMails[j]){
  //         flag=1;
  //       }
  //     }
  //     if(flag==0){
  //       list.push(studentMails[i])
  //     }else{
  //       delete studentMails[i];
  //     }
  //   }
  //   this.setState({
  //     studentMails:list
  //   })
  // }
  onChangeSubject = async(e)=>{
    await this.setState({
      subject: e.target.value
    })
    // await this.props.setMailCollection(this.state.studentMails,this.state.body, this.state.subject )
  }
  onChangeBody = async (e)=>{
    await this.setState({
      body: e.target.value
    })
    // await this.props.setMailCollection(this.state.studentMails,this.state.body, this.state.subject)
  }
  handleReminderTrigger = async (e)=>{
    await this.setState({
      reminderTriggerValue : e.target.value
    })
    this.props.setRepeatReminderValue(this.state.reminderTriggerValue);
  }
  setReminderValue = async (value)=>{
    // let {reminderTriggerValue} = this.state;
      this.setState({
        reminderValue : value
      })
      if(value==1){
        this.props.setRepeatReminderValue("once")
      }
  }

  getEventAttendees = async ()=>{
    this.setState({loading: true})
    try{
      let eventParams = {
        event_id:this.props.match.params.event_id
      }
      let {batchDetails} = this.state;
      let eventAttendees = [];
      try{
        let events = await Auth.Batch.getEvent(eventParams);
        console.log(events)
        if(events.data[0].attendee_details.length>0){
          for(let i =0;i<batchDetails.length ; i++){
            batchDetails[i].student_info && batchDetails[i].student_info.map(student=>{
              let selectedStudent = events.data[0].attendee_details.filter(attendee=>attendee.attendee_id == student.user_id);
              if(selectedStudent.length>0){
                eventAttendees.push(student);
              }
            })
          }
        }
      }
      catch(e){
        console.log(e);
      }
      await this.setState({selectedStudentList : eventAttendees});
    }
    catch(e){
      this.setState({errorbar: true});
      if(e.response && e.response.data){
        this.setState({message: e.response.data.error.toString()});
      }
      else{
        this.setState({message: 'Something went wrong! Please try again'});
      }
    }
    this.setState({loading: false})

  }

  deleteStudentAttendee = async (student)=>{
    this.setState({loading: true})
    try{
      let params = {
        event_id: this.props.match.params.event_id,
        attendee_id : student.user_id
      }
        await Auth.Batch.deleteEventAttendee(params);
        this.getEventAttendees();
    }
    catch(e){
      this.setState({errorbar: true});
      if(e.response && e.response.data){
        this.setState({message: e.response.data.error.toString()});
      }
      else{
        this.setState({message: 'Something went wrong! Please try again'});
      }
    }
    this.setState({deleteStudentModal : !this.state.deleteStudentModal});
    this.setState({loading : false});
  }

  render(){
    let {filterModal , allStudents} = this.state;
    return(
      <Aux>
      <Loader show={this.state.loading} />
      <Row className="m-1 mt-4 pt-1">

      <Col xs="12" onClick={()=>this.onClickHandler(1)}>
      <span className="r-heading-4">1. Select Students</span>
      </Col>
      </Row>
      <Collapse  isOpen={this.state.value==1} className="mb-3 mt-3">
      <Row className="ml-2">
        <Col xs="6" className="mb-3">
            <Button onClick={()=>this.setState({filterModal : true})} className="mr-3 my-3 r-btn r-btn--blue mr-2 my-2 p-2 r-subheading-3" color="blue" >Select Students</Button>
        </Col>
      </Row>
      {filterModal &&
        <UnblockStudents calendar={true} processStudent={this.state.allStudents} addStudentModal={filterModal} toggle={()=>this.setState({filterModal : false})} getStudentList={this.getStudentList} />}
      {/*<Row className="ml-2">
      <Col xs="6" className="mb-3">
      <Row className="m-0 p-0">
      <Col className="p-0">
      <span className="r-subheading-3">Batch</span>
      </Col>
      </Row>
      <hr color="black" className="m-0 mb-2"/>
      {this.state.batch!=null?this.state.batch.map(batch=> <Row >
        <Col className="mt-2">
        <span className="r-tag r-bg--grey d-block mb-1"><span className="r-subheading-3 r-text--grey-4 ">{batch.batch_name}</span>
        <span className="r-tag__delete fi flaticon-plus-1 r-text--grey-4" onClick={ ()=> this.deleteBatch(batch)}></span></span>
        </Col>
        </Row>) :null}

      <Row>
      </Row>
      <Row>
      <Col  className="mt-2  rounded">
      <Row>
      <Col>

      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="w-100 r-bg--grey-2 r-text--grey-4 border-0">

        <DropdownToggle caret className="r-bg--grey-2 r-text--grey-4 border-0 w-100">
        <span className="r-subheading-4"> Add Batch Here </span>
        </DropdownToggle>
        <DropdownMenu className="w-100">
        {this.state.batchDetails!=null?this.state.batchDetails.map(batch=>


       <DropdownItem  className="w-100" onClick={()=>this.addClicker(batch)}>{batch.batch_name}</DropdownItem>
       ):null}
       </DropdownMenu>
       </Dropdown></Col>
      </Row>
      </Col>

      </Row>
      </Col>
      <Col xs="6">
      <Row className="m-0 p-0">
      <span className="r-subheading-3">Departments</span>
      </Row>
      <hr color="black" className="m-0 mb-2"/>

      {
        Object.keys(this.state.batchObject).map(batch=>{
         return Object.keys(this.state.batchObject[batch].departments).map(dept=>{
            return (
              <Row>
              <Col className="mt-2">
              <span className="r-tag r-bg--grey d-block mb-1"><span className="r-subheading-3 r-text--grey-4">{this.state.batchObject[batch].departments[dept]!=null?this.state.batchObject[batch].departments[dept].slice(0,25):null} - {this.state.batchObject[batch].batch_name!=null?this.state.batchObject[batch].batch_name:null}</span>
              <span className="r-tag__delete fi flaticon-plus-1 r-text--grey-4" onClick={()=>this.deleteDepartment(dept, batch )}></span></span>
              </Col>
              </Row>
            )
          })
        })
      }
      </Col>

      </Row>*/}
      <Row>
        <Col xs="6" className=" mt-4 border-right" align="center">
      <span class="r-text--green-1 r-subheading-3 ml-4 ">{this.state.studentList.length!=0?this.state.studentList.length:0} Participants Selected </span>
      </Col>
      {this.state.selectedStudentList.length!=0?<Col xs="6" className={this.props.eventId!=undefined?" mt-4 ":"d-none"} align="center" >
      <span class="r-text--green-1 r-subheading-3 ml-4 settings__button-cursor" onClick={()=>this.setState({alreadySelectedModal : !this.state.alreadySelectedModal})} >{this.state.selectedStudentList.length!==0?this.state.selectedStudentList.length:0} Participants Already Selected </span>
      </Col>: null}
      <Modal isOpen={this.state.alreadySelectedModal} zIndex="99999" size="xl">
      <ModalHeader className="r-subheading-3" toggle={()=>this.setState({alreadySelectedModal : !this.state.alreadySelectedModal})}>Selected Student List </ModalHeader>
      <ModalBody>
      <table className="processes__table__1 table border-0">
      <tbody>
      <tr>
      <th>Roll No.</th>
      <th>Name</th>
      <th>Email</th>
      <th>Batch Name</th>
      <th>Department Name</th>
      <th></th>
      </tr>
      {this.state.selectedStudentList && this.state.selectedStudentList.length!=0?this.state.selectedStudentList.map(student=>
        <tr>
        <td>{student.roll_no}</td>
        <td>{student.first_name}{" "}{student.last_name}</td>
        <td>{student.email}</td>
        <td>{student.batch_name}</td>
        <td>{student.department_name}</td>
        <td>{this.props.match.params.event_id
        ?<i className="fi flaticon-bin" onClick={()=>this.setState({deleteStudentModal : !this.state.deleteStudentModal, deleteSelectedStudent : student})}/>:null}</td>
        </tr>
      ):null}
      </tbody>
      </table>
      </ModalBody>
      </Modal>
      <Modal isOpen={this.state.deleteStudentModal} zIndex="99999">
      <ModalHeader toggle={()=>this.setState({deleteStudentModal : !this.state.deleteStudentModal})}>Are you sure you want to delete</ModalHeader>
      <ModalFooter>
      <Button  className="pr-4 pl-4  r-text--light border-0 r-subheading-4" color="dark" onClick={()=>this.deleteStudentAttendee(this.state.deleteSelectedStudent)}> Delete </Button>
      <Button  className="pr-4 pl-4 r-bg--grey-2 r-text--grey-4 border-0 r-subheading-4"   onClick={() => this.setState({ deleteStudentModal : !this.state.deleteStudentModal})}>Cancel</Button>
      </ModalFooter>
      </Modal>
      </Row>


      <Row>
      <Col xs="5">

      <Card className={/*this.props.eventId=null?"border-0 r-bg--grey-2 m-1 ml-3 p-2 mb-4 calendar__primary-card mt-4":*/"d-none"}>
      <CardTitle>
      <Row>
      <Col xs="8" className="mb-2"><span className="m-0 pr-0 r-text--dark r-subheading-3"> Selected </span></Col>
      </Row>
      </CardTitle>
      <CardBody className="pt-0">
      {this.state.studentList.length!=0?this.state.studentList.map(student=>{
        return(
          <Row className="m-1 mb-2 p-1">
          <Col>
          <Button className="calendar__pt-member-button r-text--grey-4 w-100 r-subheading-4"><Row><Col xs="10" className="m-1 pr-0" align="start"><span>{student.first_name}{" "}{student.last_name}{" "} ({student.user_id / student.email})</span></Col><Col xs="1" className="p-0 m-0 border-0 mt-1"  align="end" onClick={()=>alert("student")}><i className="fi flaticon-plus-3"/></Col></Row></Button>
          </Col>
          </Row>
        )
      }):null
    }
      </CardBody>
      </Card>

      </Col>
      </Row>

      </Collapse>
      <HorizontalLine color="#f5f5f5" />
      {this.state.studentList  && this.state.studentList.length!=0  && moment(this.props.startDate).diff(moment() , 'minutes')>10?
      <Row className="m-1 mt-4 mb-4">
      <Col xs="12">
      <Row>
      <span className="r-heading-4 ml-4">2. </span>
      <Col xs="1"  className="px-4"><b>
      <img className="img-fluid" src="/images/icons/nav_notifications.png" alt="Reminder" /></b>
      </Col>
        <div className="r-dropdown border-0">
          <select onChange={this.handleReminderDropdown}  className="r-dropdown__select r-subheading-4 calendar__description-box r-text--grey-4" ref="remind_type">
          <option value="">Select</option>
          <option value="1">Remind me 10 minutes before</option>
          {moment(this.props.startDate).diff(moment() , 'minutes')>15?<option value="2">Remind me 15 minutes before</option>:null}
          {moment(this.props.startDate).diff(moment() , 'minutes')>30?<option value="3">Remind me 30 minutes before</option>:null}
          {moment(this.props.startDate).diff(moment() , 'minutes')>45?<option value="4">Remind me 45 minutes before</option>:null}
          {moment(this.props.startDate).diff(moment() , 'minutes')>60?<option value="5">Remind me 1 hour before</option>:null}
          </select>
          <i className="fi flaticon-arrow"/>
          </div>
      </Row>
      </Col>

      </Row>: null}
      <Collapse className="mt-3"  isOpen={this.state.remindDropdownValue==2}>
      <Card className="border-0 m-1 ml-3">
      <Row ><Col className="mt-2 ml-3"><span className="mt-2 r-subheading-3"> Set Reminder Start Date And Time</span></Col></Row>
      <div>
      <Row>

      <Col>
      <div className="mt-2">
      <span>
      <YearPicker
                   defaultValue={'Year'}
                   // default is 1900
                   start={2000}
                   // default is current year
                   end={2050}
                   // default is ASCENDING
                   reverse
                   // default is false
                   required={true}
                   // default is false
                   disabled={false}
                   // mandatory
                   value={this.state.year}
                   // mandatory
                   onChange={(year) => {
                       this.setState({ year });
                   }}
                   id={'year'}
                   name={'year'}
                   classes={'classes border-0 calendar__description-box r-text--grey-4 r-subheading-4 p-2'}
                   optionClasses={'option classes'}
               />
               </span>
                  <span>
                  <MonthPicker
                    defaultValue={'select month'}
                    // to get months as numbers
                    numeric
                    // default is full name
                    short
                    // default is Titlecase
                    caps
                    // mandatory if end={} is given in YearPicker
                    endYearGiven
                    // mandatory
                    year={this.state.year}
                    // default is false
                    required={true}
                    // default is false
                    disabled={false}
                    // mandatory
                    value={this.state.month}
                    // mandatory
                    onChange={(month) => {
                        this.setState({ month });
                    }}
                    id={'month'}
                    name={'month'}
                    classes={'classes border-0 calendar__description-box r-text--grey-4 r-subheading-4 p-2'}
                    optionClasses={'option classes'}
                />
                </span>
                <span className="r-bg--grey border-0  r-text--grey-4 r-subheading-4">
                <DayPicker
                    defaultValue={'select day'}
                    // mandatory
                    year={this.state.year}
                    // mandatory
                    month={this.state.month}
                    // mandatory if end={} is given in YearPicker
                    endYearGiven
                    // default is false
                    required={true}
                    // default is false
                    disabled={false}
                    // mandatory
                    value={this.state.day}
                    // mandatory
                    onChange={(day) => {
                        this.setState({ day });
                    }}
                    id={'day'}
                    name={'day'}
                    classes={'classes border-0 calendar__description-box r-text--grey-4 r-subheading-4 p-2'}
                    optionClasses={'option classes'}
                />
                </span>
                <span className="ml-2">
                <TimePicker placeholder="hh:mm:ss" onChange={this.OnChangeStartTime} allowEmpty={false}
                inputReadOnly
                clearIcon={true}
                format= "hh:mm a" use12Hours />
                </span>
                </div>
               </Col>
      </Row>
      <div className="mt-4 ml-1">
      <Row>
      <Col xs="auto">
      <label class="radio-inline"><input type="radio"  name="optradio" onChange={()=>this.setReminderValue(1)}   /><span className="ml-2 r-subheading-4 r-text--grey-4">Only Once</span></label>
      </Col>
      <Col xs="auto">
      <label class="radio-inline"><input type="radio" name="optradio" onChange={()=>this.setReminderValue(2)}   /><span className="ml-2 r-subheading-4 r-text--grey-4">Repeat</span></label>
      </Col>
      </Row>
      </div>
      <Row>
      <Col xs="3">
        <Collapse isOpen={this.state.reminderValue==2}>
        <div className="r-dropdown border-0 mt-4 mb-4">
        <select  className="r-dropdown__select r-subheading-4 calendar__description-box r-text--grey-4" ref="remind_type"  onChange={this.handleReminderTrigger} >
        <option value="">Select</option>
        <option value="monthly">Monthly</option>
        <option value="weekly">Weekly</option>
        <option value="daily">Daily</option>
        </select>
        <i className="fi flaticon-arrow"/>
        </div>
        </Collapse>

      </Col>
      </Row>
      </div>
      </Card>
      </Collapse>
      <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      </Aux>
    )
  }
}

export default StudentSection = withRouter(StudentSection);
