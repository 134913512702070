import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Errorbar} from '../../../global/errorbar';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../../actions/Auth';
import Aux from '../../../global/AuxComp';
import { getFiles } from '../../../../actions';
import { setUser, setLoading } from '../../../../actions/action-creators';
import {Row,Col,Container } from 'reactstrap';
import {HorizontalLine} from '../../../global/horizontal_line';
import PTTeamNav from '../PTTeamNav';
import PostCard from './PostCard';
import CreatePost from './CreatePost';
import HelpSection from '../../helpSection/student_section';

 class Feed extends Component{
  constructor(props){
    super(props);
    this.state = {
      getProcesList:  [],
      getStudentAssigned: [],
      modalstudent: false,
      modalshare:false,
      modal: false,
      modaledit: false,
      collapse: false,
      fileId: null,
      bannerId: null,
      videoId: null,
      popoverOpen: false,
      postsdata: [],
      files: {},
      file: null,
      post_tag: null,
      errorbar: false,
      message:'',
      title:'',
      content:'',
      popoverOpen2: false,
      process:{process_name: "Feed",process_type: "feed",process_id: 3},
    };
  }



  closebar = () =>{
      this.setState({errorbar: false});
  }



  componentWillMount() {
    this.init();
  }

  init = async () => {
    this.props.setLoading(true);
    var fileIds = []
    try {
      let posts = await Auth.Feed.postView();
      let postsdata = posts.data;
      postsdata.forEach(p => {
        // fileIds.push(p.post_banner_id);
        fileIds.push(p.file_id)
      });
      let files = await getFiles(fileIds)
      postsdata = await this.appendCompanyRecruiterFeed(postsdata);
      this.setState({
        postsdata,
        files
      })
    } catch (e) {
                  this.setState({errorbar: true});
                  this.setState({message: e.response.data.error.toString()});}
    this.props.setLoading(false);
  }

    appendCompanyRecruiterFeed = async (posts) => {
      var recruiterId = posts.filter(post => post.origin_type === "RECRUITER").map(post => post.origin_id);
      if(recruiterId.length === 0) return posts;
      recruiterId = [...new Set(recruiterId)];
      let recruiterResp = await Auth.Recruiter.Company.getCompany({recruiter_id : recruiterId});
      var recruiterDetail = recruiterResp.data.data.reduce((prev, curr) => {prev[curr.recruiter_id] = curr; return prev;}, {});
      console.log(recruiterDetail)
      posts = posts.map(post => {
        if(post.origin_type === "RECRUITER"){
          post.company_name = recruiterDetail[post.origin_id] ? recruiterDetail[post.origin_id].company_name : "Recruiter Not Found";
        }
        return post;
      })
      return posts;
    }

   toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  }


  likePost = async(data) =>{
        const {postsdata} = this.state;
          var stBody = {
                        "post_id":data.post_id,
                        "liked": (data.is_liked === 1)?"0":1
                        }
            try {
                  await Auth.Feed.createActivity(stBody);
                    let objIndex = postsdata.findIndex((obj => obj.post_id == data.post_id));
                    postsdata[objIndex].no_of_like = (data.is_liked === 1)?(data.no_of_like - 1):(data.no_of_like + 1) ;
                    postsdata[objIndex].is_liked = (data.is_liked === 1)?0:1
                    this.setState({postsdata});
                } catch (e) {
                  console.log(e.response)
                }
  }




  render(){
      return (
        <Aux>
          {/*<Row className="justify-content-between align-items-center">
            <Col xs="12">
              <PTTeamNav process={this.state.process}/>
            </Col>
          </Row>*/}
        {/* <HorizontalLine color ="#cfcfcf"/> */}
          <CreatePost refresh={this.init} handlepostToggle={this.handlepostToggle}/>
      { /* <Row  className="justify-content-between align-items-center my-5">
                     <Col xs="auto" >
                       <InputField className="r-nav__search" placeholder="Search" type="text" />
                       <span className="fi flaticon-search"></span>
                      </Col>
                      <Col xs="auto">
                          <Row>
                              <Col xs="auto">
                                  <select className="selectfield">
                                    <option value="volvo">Type</option>
                                    <option value="saab">Saab</option>
                                    <option value="opel">Opel</option>
                                    <option value="audi">Audi</option>
                                  </select>
                              </Col>
                              <Col xs="auto">
                                  <select className="selectfield">
                                    <option value="volvo">Time</option>
                                    <option value="saab">Saab</option>
                                    <option value="opel">Opel</option>
                                    <option value="audi">Audi</option>
                                  </select>
                              </Col>
                              <Col xs="auto">
                                  <select className="selectfield">
                                    <option value="volvo">Sector</option>
                                    <option value="saab">Saab</option>
                                    <option value="opel">Opel</option>
                                    <option value="audi">Audi</option>
                                  </select>
                              </Col>
                              <Col xs="auto">
                                  <select className="selectfield">
                                    <option value="volvo">Last Contacted</option>
                                    <option value="saab">Saab</option>
                                    <option value="opel">Opel</option>
                                    <option value="audi">Audi</option>
                                  </select>
                              </Col>
                          </Row>
                      </Col>
              </Row>*/}

        {this.state.postsdata.map((data,i) =>{
                  return(
                    <PostCard 
                        key={i} 
                        data={data}  
                        files={this.state.files} 
                        refreseh={this.init} 
                        role_type={this.props.role.role_type}
                        redirect={this.props.history.push} 
                        likePost={this.likePost}
                        init={this.init}
                    />                  
                  )
        })}
                    <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      <HelpSection tab={"newsfeedTab"} />
      </Aux>
  )
 }
}

const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoading: (show) => dispatch(setLoading(show)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Feed));
