import React,{Component} from 'react';
import { Collapse } from 'reactstrap';
import {  CardBody, CardTitle, CardText} from 'reactstrap';
import {Col,Row} from 'reactstrap';
import Aux from '../../global/AuxComp';
import {StatusButton} from './verifier_status_button';
import moment from 'moment';
import { connect } from 'react-redux';

class OtherExperienceCollapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
      };
    this.section = Math.ceil(Math.random() * 10000)
    this.popoverName = "POP-" + this.section
  }

  toggle = () => {
    this.props.toggleSection(this.section)
    this.props.getfileId(this.props.otherExperience.file);
  }

  render() {
    let otherExperience = this.props.otherExperience;
    let allCommentThread= this.props.getAllCommentThread!=null?(this.props.getAllCommentThread.filter(commentThread=>
       commentThread.profile_type=="OTHER_EXPERIENCE" && commentThread.profile_id==otherExperience.id && commentThread.student_id==otherExperience.student_id &&commentThread.verifier_id==this.props.role.assigned_role_id)):null;
    return (
      <Aux>
      <CardBody onClick={this.toggle}  className={"verifier__card-body  "+(this.section==this.props.collapse?"active":"")}>
      <CardTitle>
      <Row>
      <Col xs={10}>
      <span className="r-heading-6 mr-3">
      {otherExperience.name ||  "Name"}</span>
      <span>{("("+moment(otherExperience.year_start).format('MMM')+"  "+moment(otherExperience.year_start).year()+" - "+ moment(otherExperience.year_end).format('MMM')+"  "+moment(otherExperience.year_end).year()+")") ||  "" } </span>
      </Col>
      {otherExperience.file!=0? <Col xs={2} align="end">
          <span title="Proof Attached"><img src="/images/paper-clip@2x.png" alt="Proof" className="w-25" /></span>
      </Col> : null}
      </Row>
      </CardTitle>
      <CardText>
      <Row>
      <Col xs="auto">
      <span>{otherExperience.institute || "Institute"}
      </span> | <span>{otherExperience.location || "Location"}</span>
      </Col>
      </Row>

      </CardText>
      </CardBody>
      <Collapse   className="r-bg--blue-1" isOpen={this.props.collapse==this.section}>
      <StatusButton  allCommentThread={allCommentThread} student_id={otherExperience.student_id} id={otherExperience.id} profileType="OTHER_EXPERIENCE" profile_type="other_experience" getStudentDetails={this.props.getStudentDetails}  popoverName={this.popoverName} data={otherExperience} />
      </Collapse>
      </Aux>
    );
  }
}
const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(OtherExperienceCollapse);
// export default OtherExperienceCollapse;
