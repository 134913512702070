import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from '../../../actions/Auth';
import { setUser, setLoading } from '../../../actions/action-creators';
import {Row,Col,Container,Collapse, Modal, ModalBody, Table, Input, ModalHeader, Spinner } from 'reactstrap';
import Button from '../../global/Button';
import { SelectList } from '../../global/Forms/Select';
import { InputField } from '../../global/Forms';
import { Switch } from '../../global/Forms/ToggleSwitch';
import AddCollegeCollapse from './add_college_collapse';
import ShowCollege from './show_college';
import AddMember from './add_member';
import MemberListChild from './member_list_child';
import {Errorbar} from '../../global/errorbar';
import {Confirmation} from '../../global/confirmation';

 class MembersModal extends Component{
  constructor(props){
    super(props);
    this.state = { addMember:[], userRoles: [], memberList : [], showError:false, errorMsg : '', deleteConfirmationId : null, isConfirmation : false };
    this.getUserRoles();
  }
  setError =(isShow, msg) => {
    this.setState({showError : isShow, errorMsg : msg});
  }
  createMember = async (index) => {
    this.setState({isSpinnerLoading : true})
    let { addMember } = this.state;
    var data = addMember[index];
    data = {...data, ...this.props.modalInfo}
    try{
      var resp = await Auth.Spoc.createUserWithRole(data);
      addMember.splice(index, 1);
      this.setState({ addMember });
      this.props.handleFetchInstitute();
      this.getUsers();
    }
    catch(e){
      if(e.response.data.error.toString() !== null){
        this.setError(true, e.response.data.error.toString())
      }
      else{
        this.setError(true, 'Sorry! something went wrong. Please try again')
      }
    }

    this.setState({isSpinnerLoading : false})
  }


  confirmDeleteUser = (id) => {
    this.setState({deleteConfirmationId : id, isConfirmation : true})
  }
  deleteUser = async (id) => {
    this.toggleConfirmation()
    this.setState({isSpinnerLoading : true})

    var data = {
      id : id,
      status : '0'
    }
    try{
      var resp = await Auth.Spoc.inactiveUser(data);
    }
    catch(e){
      console.log(e)
    }
    this.getUsers();
    this.props.handleFetchInstitute();
    this.setState({isSpinnerLoading : false})
  }
  addMember = () =>
  {
    var addMember = this.state.addMember;
    addMember.push({});
    this.setState({ addMember });
  }
  cancelAddMember = (index) =>
  {
    var addMember = this.state.addMember;
    addMember.splice(index, 1);
    this.setState({ addMember });
  }
  componentDidMount() {
    this.getUsers();
  }
  getUsers = async() => {
    this.setState({isSpinnerLoading : true})
    var resp = {};
    try{
      if(this.props.modalInfo)
        resp = await Auth.Spoc.getUsers(this.props.modalInfo)
      this.setState({memberList : resp.data});
    }
    catch(e){
      console.log(e)
    }
    this.setState({isSpinnerLoading : false})
  }
  getUserRoles = async() => {
    try{
        var roles = await Auth.Spoc.getUserRoles(this.props.modalInfo.university_id);
        roles = Array.isArray(roles.data) ? roles.data : [];
        this.setState({userRoles : roles});
    }
    catch(e){
      console.log(e);
    }
  }
  handleChangeAddMember =(index, key, value) => {
    let { addMember }  = this.state;
    addMember[index][key] = value;
    this.setState({addMember});
  }
  toggleConfirmation = () => {
    this.setState({isConfirmation : false})
  }
  render(){
    const closeBtn = <button className="close" onClick={this.props.closeMembersModel}>&times;</button>;
    let {memberList, isSpinnerLoading, isConfirmation } = this.state;
      return (
        <Modal zIndex="99999" isOpen={this.props.isMembersModel} toggle={this.props.closeMembersModel} className="spocModel1 modal-lg modal-dialog-centered">
          <Container >
            <ModalHeader close={closeBtn}>
              Role level and Permissions
              <Button onClick={this.addMember} className="r-text--sm mt-3 ml-3" color="brandB"><i className="fi flaticon-plus mr-2"></i> New</Button>

            </ModalHeader>
            <ModalBody onMouseOut={this.closeMembersModel}>
              <Row className="align-items-center r-bg--blue-2 mt-1">
                  <Col xs={7}>
                    <Row>
                      {/*<Col xs={1}><Input className="ml-0" type="checkbox" /></Col>*/}
                      <Col xs={5}>Name</Col>
                      <Col xs={6}>Email</Col>
                    </Row>
                  </Col>
                  <Col xs={5}>
                    <Row className="align-items-center">
                      <Col xs={5}>Role Name</Col>
                      <Col xs={2}>Status</Col>
                      <Col xs={5}></Col>
                    </Row>
                  </Col>
              </Row>
                  {this.state.addMember.map((child, index) =>   <AddMember key={index} addMemberData={child} index={index} handleChangeAddMember={this.handleChangeAddMember} userRoles={this.state.userRoles} createMember={this.createMember} modalInfo={this.props.modalInfo} cancelAddMember={this.cancelAddMember} />)}
                  { memberList.length > 0 || this.state.addMember.length > 0 ? memberList.map((child, index) => <MemberListChild key={index} index={index} memberData={child} userRoles={this.state.userRoles} deleteUser={this.confirmDeleteUser}  modalInfo={this.props.modalInfo} />) : <div className="text-center"><span>No Data Found</span></div>}

            </ModalBody>
            {isSpinnerLoading && <div className="center-xy"><Spinner color="dark"/></div>}
            <Errorbar showBar={this.state.showError} onClose={() => this.setError(false, "")} message={this.state.errorMsg}/>
            <Confirmation isOpen={isConfirmation} toggleConfirmation={this.toggleConfirmation} callback={() => this.deleteUser(this.state.deleteConfirmationId)} />

          </Container>
        </Modal>
  )
 }
}

export default MembersModal;
