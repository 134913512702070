import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from '../../../actions/Auth';
import { Row, Col, Container, ModalBody } from 'reactstrap';
import Aux from "../../global/AuxComp";
import { Button, ButtonGroup } from 'reactstrap';
import { Loader } from '../../global/Loader';
import moment from 'moment';
import { generateMasterCV, generateStudentCVDetails, getFullName, getErrorMessage } from '../../../actions';
import { VerifySectionCard } from './verifier_detail_page_card';
import VerifierStudentProof from './verifier_student_proofs';
import { filterCVPoints } from '../../../actions';
//import Timer from './timer';
import { CONSTANTS } from '../../../constants';
import StudentDetailModel from '../../../constants/StudentDetailModel';
import { TEMPLATE_CONFIG } from '../../../constants/template-1';
import Modal from 'reactstrap/lib/Modal';
import { CVPreview } from '../cv-builder/cv-preview';
import Link from 'react-router-dom/Link';
import store from '../../../store';
import { setError } from '../../../actions/action-creators';

class VerifierDetailPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      studentDetails: null,
      studentId: "",
      cvPreview: false,
      // date:  moment.duration(moment('2020-01-01 00:00:01').diff(moment(), "milliseconds")),
      approve: null,
      reject: null,
      pending: null,
      value: -1,
      index: "",
      loading: false,
      fileId: null,
      signedUrl: [],
      config: TEMPLATE_CONFIG,
      studentProfile: JSON.parse(JSON.stringify(StudentDetailModel)),
      studentCVDetails: {
        "user_info": {},
        "personal_info": {},
        "academic_detail": {},
        "professional_detail": {},
        "project": {},
        "achievement": {},
        "other_experience": {},
        "skill": {},
        "cv_point": {},
        "position_of_responsibility": {},
        "extra_curricular_activity": {}
      },
      studentIdArray: [],
      isConfirm : false,

    }
  }


  getStudentAssigned = async (student_id) => {
    this.toggleLoading(true);
    this.backBtn = null;
    this.nextBtn = null;
    try {
      let params = new URLSearchParams();
      params.append("role_id", this.props.role.assigned_role_id);
      params.append("process_id", this.props.match.params.process_id);
      let getStudentAssigned = await Auth.Verfier.getStudentAssigned(params);
      let studentIdArray = [];
      if (getStudentAssigned.data) {
        getStudentAssigned.data.map(student_id => {
          studentIdArray.push(student_id.student_id);
        })
      }
      let currentIndex = studentIdArray.indexOf(parseInt(student_id));
      if (currentIndex > 0)
        this.backBtn = `/verifier/verification/${studentIdArray[currentIndex - 1]}/${this.props.match.params.process_id}/studentDetails`
      if (currentIndex < studentIdArray.length - 1) {
        this.nextBtn = `/verifier/verification/${studentIdArray[currentIndex + 1]}/${this.props.match.params.process_id}/studentDetails`
      }
      this.setState({
        studentIdArray
      })
    }
    catch (e) {
      console.log(e);
    }

    this.toggleLoading(false);
  }



  toggleLoading = (loading) => {
    this.setState({
      loading
    })
  }

  updateProofIndex = async (fileId) => {
    await this.setState({
      fileId
    })
    await this.getfilesId(fileId)
  }
  getfilesId = async (fileId) => {
    this.setState({ signedUrl: [] })
    this.toggleLoading(true);
    const signedUrl = []
    const signed = []
    if (fileId != null) {
      try {
        let getFilesbyId = await Auth.User.getFilesbyId(fileId);
        getFilesbyId.data.map(file_url => {
          signedUrl.push(file_url.file_url)
        })

        await Promise.all(signedUrl.map(async signed_url => {
          let getFileUrl = await Auth.User.getFileUrl(signed_url);
          if ((signed_url.split('.').pop().split(/\#|\?/)[0] || "").toLowerCase() === 'pdf') {
            let resp = await Auth.User.getFileFromS3(getFileUrl.data[signed_url])
            const url = window.URL.createObjectURL(new Blob([resp.data],{type: "application/pdf"}));
            signed.push(url);
          } else
            signed.push(getFileUrl.data[signed_url]);
        }));
        await this.setState({
          signedUrl: signed
        })
      }
      catch (e) {
        console.log(e);
      }

    }
    this.toggleLoading(false);
  }

  async componentWillReceiveProps(nextProps) {
    if(this.props.match.params.student_id!==nextProps.match.params.student_id){
    await this.setState(
      {
        studentCVDetails: {
          "user_info": {},
          "personal_info": {},
          "academic_detail": {},
          "professional_detail": {},
          "project": {},
          "achievement": {},
          "other_experience": {},
          "skill": {},
          "cv_point": {},
          "position_of_responsibility": {},
          "extra_curricular_activity": {}
        }
      })
    await this.getStudentProfileDetails(nextProps.match.params.student_id)
    await this.getStudentAssigned(nextProps.match.params.student_id)
  }

  }

  async componentWillMount() {
    await this.getStudentProfileDetails(this.props.match.params.student_id)
    await this.getStudentAssigned(this.props.match.params.student_id)
    // setInterval(this.countDown,1000)
  }

  // countDown = () => {
  // let date =  moment.duration(moment('2020-01-01 00:00:01').diff(moment(), "milliseconds"))
  //   this.setState({date})
  // }

  onClickHandler = (value) => {
    if (value == 1) {
      this.setState({
        value
      })
    }
    else if (value == 2) {
      this.setState({
        value
      })
    }
    else if (value == 0) {
      this.setState({
        value
      })
    }
    else { this.setState({ value: -1 }) }
  }



  getStudentProfileDetails = async (student_id) => {
    let { studentProfile, studentCVDetails } = this.state;
    let redux = store.getState()
    this.toggleLoading(true);
    try {
      let getStudentDetails = await Auth.Student.getProfile(student_id);
      // try{
      let cvTemplates = await Auth.Student.getCVTemplates();
      let masterCV = await Auth.Student.getResumes(null, {student_id, cv_type: "MASTER"})
      this.setState({
        studentDetails: getStudentDetails.data[0],
        // config: cv cvTemplates.data[0].template
      })

      // template

      studentProfile.user_info = Object.assign({}, redux.role, getStudentDetails.data[0].student_info[0]);
      studentProfile.personal_info = Object.assign({}, studentProfile.personal_info, getStudentDetails.data[0].personal_detail[0]);
      studentProfile = Object.assign({}, studentProfile, getStudentDetails.data[0])
      if (cvTemplates.data[0]){
        let configByGroup = cvTemplates.data[0]
        if(studentProfile.user_info.group_id) {
          let groupTemplate = cvTemplates.data.filter(tmp=>tmp.group_id===studentProfile.user_info.group_id)[0]
          if(groupTemplate) configByGroup = groupTemplate
        }
        if(masterCV.data[0] && masterCV.data[0].template_id) {
          let masterTemplate = cvTemplates.data.filter(tmp=>tmp.template_id===masterCV.data[0].template_id)[0]
          if(masterTemplate) configByGroup = masterTemplate
        }
          this.setState({ config: configByGroup.template })
      }
      studentProfile.academic_detail.sort(this.sortAcademic);
      studentCVDetails = generateStudentCVDetails(studentCVDetails, studentProfile);
    }
    catch (e) {
      console.log(e);
      this.props.setError({showBar: true, message: getErrorMessage(e)})
    }
    this.toggleLoading(false);
    this.setState({
      studentProfile,
      studentCVDetails
    })
  }

  generateCVConfig = () => {
    let { config, studentCVDetails } = this.state;
    config = generateMasterCV(studentCVDetails, config)
    // this.updateCVConfig(config)
  }

  toggleCV = () => {
    if (!this.state.cvPreview)
      this.generateCVConfig()
    this.setState({
      cvPreview: !this.state.cvPreview
    })
  }

  sortAcademic = (a, b) => {
    let types = Object.keys(CONSTANTS.ACADEMIC_TYPES);
    let indexA = types.indexOf(a.academic_type),
      indexB = types.indexOf(b.academic_type);
    if (indexA > indexB)
      return -1;
    if (indexA < indexB)
      return 1;
    return 0;
  }

  redirectToStudent = async(user_info) => {
    let {role, user, history, match} = this.props,
    campusId = role.instituteDetail.campus_id,
    ptRole = user.roles.filter(r=>(r.role_type==="PT" && r.instituteDetail.campus_id===campusId))
    if(ptRole.length>0) {
      let el = document.getElementById(`role-${ptRole[0].assigned_role_id}`)
      await el.click()
      history.push(`/pt/students/recruitments/${user_info.student_id}/profile?process_id=${match.params.process_id}`)
    }
  }

  studentVerificationAll = async (is_verified, freeze_status) => {
    this.toggleLoading(true);
    try {
      var body = {
        student_id : this.props.match.params.student_id ,
        is_verified, freeze_status,
        process_id : this.props.match.params.process_id
      }
      await Auth.Verfier.updateStudentVerificationStatusAll(body)
      this.setState({ isConfirm: false})
    } catch(e) {
      console.log(e)
    }
    this.toggleLoading(false);
    await this.getStudentProfileDetails(this.props.match.params.student_id)
  }

  render() {
    let {isConfirm} = this.state
    let sections = [];
    if (this.state.config) {
      sections = Object.keys(this.state.config.section_order).sort().map(order => {
        return this.state.config.section_order[order]
      })
    }
    let studentDetails = this.state.studentDetails;
    if (!studentDetails)
      return <div></div>
    let { academic_detail, achievement, personal_detail, extra_curricular_activity, other_experience, position_of_responsibility, preferences, professional_detail, project, skill, cv_point } = studentDetails;
    let user_info, personal_info;
    if (studentDetails.student_info != undefined) {

      personal_info = studentDetails.student_info[0];
      user_info = studentDetails.student_info[0]
    }
    let cvPoints = filterCVPoints(cv_point, "ACADEMIC");
    let cvPointProject = filterCVPoints(cv_point, "PROJECT");
    let cvPointProfessional = filterCVPoints(cv_point, "PROFESSIONAL");
    let cvPointExtraCurricular = filterCVPoints(cv_point, "EXTRA_CURRICULAR");
    let cvPointOtherExperience = filterCVPoints(cv_point, "OTHER_EXPERIENCE");
    let cvPointPositionOfResponsibility = filterCVPoints(cv_point, "POR");
    let cvPointAchievments = filterCVPoints(cv_point, "ACHIEVEMENTS");
    // let deadLineCounter = this.state.date;

    let all = 0, pending = 0, approved = 0, rejected = 0;
    let academicPoints = academic_detail.filter(pt => {
      if (pt.is_verified == 0) {
        pending++;
        all++;
      }
      else if (pt.is_verified == 1) {
        approved++;
        all++;
      }
      else if (pt.is_verified == 2) {
        rejected++;
        all++;
      }
    })
    let achievementPoints = achievement.filter(pt => {
      if (pt.is_verified == 0) {
        pending++;
        all++;
      }
      else if (pt.is_verified == 1) {
        approved++;
        all++;
      }
      else if (pt.is_verified == 2) {
        rejected++;
        all++;
      }
    })

    let personalDetail = personal_detail.filter(pt => {
      if (pt.is_verified == 0) {
        pending++;
        all++;
      }
      else if (pt.is_verified == 1) {
        approved++;
        all++;
      }
      else if (pt.is_verified == 2) {
        rejected++;
        all++;
      }
    })

    let extraCurricularActivityPoints = extra_curricular_activity.filter(pt => {
      if (pt.is_verified == 0) {
        pending++;
        all++;
      }
      else if (pt.is_verified == 1) {
        approved++;
        all++;
      }
      else if (pt.is_verified == 2) {
        rejected++;
        all++;
      }
    })
    let otherExperiencePoints = other_experience.filter(pt => {
      if (pt.is_verified == 0) {
        pending++;
        all++;
      }
      else if (pt.is_verified == 1) {
        approved++;
        all++;
      }
      else if (pt.is_verified == 2) {
        rejected++;
        all++;
      }
    })
    let positionOfResponsibilityPoints = position_of_responsibility.filter(pt => {
      if (pt.is_verified == 0) {
        pending++;
        all++;
      }
      else if (pt.is_verified == 1) {
        approved++;
        all++;
      }
      else if (pt.is_verified == 2) {
        rejected++;
        all++;
      }
    })
    let preferencesPoints = preferences.filter(pt => {
      if (pt.is_verified == 0) {
        pending++;
        all++;
      }
      else if (pt.is_verified == 1) {
        approved++;
        all++;
      }
      else if (pt.is_verified == 2) {
        rejected++;
        all++;
      }
    })
    let professionalDetailPoints = professional_detail.filter(pt => {
      if (pt.is_verified == 0) {
        pending++;
        all++;
      }
      else if (pt.is_verified == 1) {
        approved++;
        all++;
      }
      else if (pt.is_verified == 2) {
        rejected++;
        all++;
      }
    })
    let projectPoints = project.filter(pt => {
      if (pt.is_verified == 0) {
        pending++;
        all++;
      }
      else if (pt.is_verified == 1) {
        approved++;
        all++;
      }
      else if (pt.is_verified == 2) {
        rejected++;
        all++;
      }
    })
    let skillPoints = skill.filter(pt => {
      if (pt.is_verified == 0) {
        pending++;
        all++;
      }
      else if (pt.is_verified == 1) {
        approved++;
        all++;
      }
      else if (pt.is_verified == 2) {
        rejected++;
        all++;
      }
    })
    let cvPoint = cv_point.filter(pt => {
      if (pt.is_verified == 0) {
        pending++;
        all++;
      }
      else if (pt.is_verified == 1) {
        approved++;
        all++;
      }
      else if (pt.is_verified == 2) {
        rejected++;
        all++;
      }
    })
    return (
      <Container>
        <Loader show={this.state.loading} />
        <Row>
          <Col xs="6"  >
            <div className="verifier__detail-scroll">
              <Row>
                <Col xs="1">
                  <Link to={"/verification"}>
                    <img src="/images/left-arrow.png" alt="Back-Arrow" width="30" className="verifier__details-page-back-button" />
                  </Link>
                </Col>
                <Col xs="6" align="center">
                  {user_info != undefined  ?
                    <div >
                      <ButtonGroup className="verifier__detail-page">
                        <Link to={this.backBtn || "/verifier"} className={this.backBtn ? "" : "invisible"}><Button className="verifier__swipe-button" ><b><i className="fi flaticon-arrow-side"></i></b></Button></Link>
                        <span onClick={()=>this.redirectToStudent(user_info)} className="verifier__student-name r-text--grey-4 r-subheading-4 selectable"><b>{getFullName(user_info)} </b></span>
                        <Link className={this.nextBtn ? "" : "invisible"} to={this.nextBtn || "/verifier"}><Button className="verifier__swipe-button"><b><i className="fi flaticon-arrow-side-2"></i></b></Button></Link>
                      </ButtonGroup>

                    </div> : null}
                </Col>

                <Col xs="5" className="mt-1" align="end">
                  <div>
                    <Button className="r-btn--brandA verifier__resume-button"><span className="r-heading-7" onClick={this.toggleCV}> Resume Preview </span></Button>
                    <Modal modalClassName="student__cv__preview cv__master" isOpen={this.state.cvPreview} toggle={this.toggleCV}>
                      <CVPreview verification={true} preview={true} sections={sections} data={this.state.studentCVDetails} config={this.state.config} />
                    </Modal>
                  </div>
                </Col>
              </Row>

              <div className="mt-3">
                  <Button className="verifier__section-card-button " onClick={() => this.setState({isConfirm: () => this.studentVerificationAll(1, 1) })}>
                    <span className="r-subheading-4 ">
                      {" "}
                       Approve & Freeze All{" "}
                    </span>{" "}
                  </Button>

                  <Button className="verifier__section-card-button " onClick={() => this.setState({isConfirm: () => this.studentVerificationAll(1, "0") })}>
                    <span className="r-subheading-4">
                      {" "}
                       Approve All{" "}
                    </span>{" "}
                  </Button>

                  <Button className="verifier__section-card-button " onClick={() => this.setState({isConfirm: () => this.studentVerificationAll("0","0") })}>
                    <span className="r-subheading-4">
                      {" "}
                       Pending All{" "}
                    </span>{" "}
                  </Button>

                  <Button className="verifier__section-card-button " onClick={() => this.setState({isConfirm: () => this.studentVerificationAll(2 ,"0") })}>
                    <span className="r-subheading-4">
                      {" "}
                       Reject All{" "}
                    </span>{" "}
                  </Button>
              </div>
              <Modal centered size="sm" isOpen={isConfirm} toggle={() => this.setState({ isConfirm: false })}>
                <ModalBody>
                  <h3>Are you Sure ?</h3>
                  <Row className="mt-3">
                    <Col className="text-right">
                      <Button onClick={() => this.setState({ isConfirm: false })} className="mr-2 r-btn r-btn--outline-dark-v r-text--sm" color="outline-dark-v r-text--sm">Cancel</Button>
                      <Button className="r-btn r-btn--dark r-text--sm" color="dark r-text--sm" onClick={() => this.state.isConfirm()}>Sure</Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>

              <Row>
                <Col xs="auto">
                  <button className={"verifier__status-button-brief mt-3 " + (this.state.value == -1 ? "active" : "")} onClick={() => this.onClickHandler(-1)}><span className="r-subheading-3">All <span className="badge badge-primary">{all / 10 >= 1 ? all : ("0" + all)}</span></span></button>
                </Col>
                <Col xs="auto">
                  <button className={"verifier__status-button-brief mt-3" + (this.state.value == 1 ? "active" : "")} onClick={() => this.onClickHandler(1)}><span className="r-subheading-3">Approved <span className="badge badge-success">{approved / 10 >= 1 ? approved : ("0" + approved)}</span></span></button>
                </Col>
                <Col xs="auto">
                  <button className={"verifier__status-button-brief mt-3" + (this.state.value == 0 ? "active" : "")} onClick={() => this.onClickHandler(0)}><span className="r-subheading-3">Pending <span className="badge badge-warning">{pending / 10 >= 1 ? pending : ("0" + pending)}</span></span></button>
                </Col>
                <Col xs="auto">
                  <button className={"verifier__status-button-brief mt-3" + (this.state.value == 2 ? "active" : "")} onClick={() => this.onClickHandler(2)} ><span className="r-subheading-3"> Rejected </span><span className=" mb-3 badge badge-danger">{rejected / 10 >= 1 ? rejected : ("0" + rejected)}</span></button>
                </Col>
              </Row>

              <Row>
                <Col>
                  <VerifySectionCard studentId={this.props.match.params.student_id} student_info={studentDetails.student_info[0]} getfileId={this.updateProofIndex} value={this.state.value} getStudentDetails={this.getStudentProfileDetails} academicDetail={academic_detail} achievement={achievement} personalDetail={personal_detail} extraCurricularActivity={extra_curricular_activity} otherExperience={other_experience} positionOfResponsibility={position_of_responsibility} preferences={preferences} professionalDetail={professional_detail} project={project} skill={skill} cvPoint={cvPoints} cvPointProject={cvPointProject} cvPointProfessional={cvPointProfessional} cvPointExtraCurricular={cvPointExtraCurricular} cvPointOtherExperience={cvPointOtherExperience} cvPointPositionOfResponsibility={cvPointPositionOfResponsibility} cvPointAchievments={cvPointAchievments} />

                </Col>
              </Row>
            </div>
          </Col>

          <Col xs="6">
            {this.state.signedUrl.length > 0 && <VerifierStudentProof signedUrl={this.state.signedUrl} />}

          </Col>
        </Row>

      </Container>

    )
  }
}
const mapDispatchToProps = dispatch => ({
  setError: (err) => dispatch(setError(err))
})
const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, mapDispatchToProps)(VerifierDetailPage)
