import React,{Component} from 'react';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container, Button} from 'reactstrap';
import {Modal,ModalBody , ModalHeader, ModalFooter} from 'reactstrap';
import AddMember from '../spoc/add_member';


class MembersModal extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

  getInitialState = ()=>{
    return(
      {

      }
    )
  }

  render(){
    return(
      <Modal zIndex="99999" isOpen={this.props.isMembersModel && (this.props.selectedUniversity && this.props.selectedUniversity.university_id==this.props.university.university_id)} toggle={()=>this.props.closeMembersModel(false)} className="spocModel1 modal-lg modal-dialog-centered">
      <Container>
          <ModalHeader toggle={()=>this.props.closeMembersModel(false)}>
            Role level and Permissions
            <Button  className="r-text--sm r-btn  ml-3 r-bg--brandB r-text--light" ><i className="fi flaticon-plus mr-2"></i> New</Button>

          </ModalHeader>
          <ModalBody>
            <Row className="align-items-center r-bg--blue-2 mt-1">
              <Col xs={4}>Name</Col>
              <Col xs={3}>Email</Col>
              <Col xs={3}>Role Name</Col>
              <Col xs={2}>Action</Col>
            </Row>
            <AddMember modalInfo={{}} />
          </ModalBody>
          <ModalFooter>

          </ModalFooter>
      </Container>
      </Modal>
    )
  }
}

export default MembersModal;
