export default {
  "user_info": {
    "id": null,
    "title": null,
    "first_name": null,
    "middle_name": null,
    "last_name": null,
    "email": null,
    "mobile": null,
    "country_code": null,
    "user_key": null,
    "country": null,
    "state": null,
    "city": null,
    "nationality": null,
    "gender": null,
    "dob": null,
    "profile_links": null,
    "address": null,
    "created_by": null,
    "status": null,
    "created_on": null,
    "modified_on": null,
    "updated_by": null
  },
  "personal_info": {
    "student_id": 0,
    "file": 0,
    "image_url": 0,
    "personal_summary": "",
    "video_resume_url": 0,
    "is_verified": 0,
    "hobbies": ""
  },
  "academic_detail": [],
  "preferences": [],
  "professional_detail": [],
  "project": [],
  "achievement": [],
  "other_experience": [],
  "skill": [],
  "cv_point": [],
  "position_of_responsibility": [],
  "extra_curricular_activity": [],
  "files": {}
}