import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Row,Col,Container,Collapse, Input} from 'reactstrap';
import Aux from '../../global/AuxComp';
import Button from '../../global/Button';
import { SelectList } from '../../global/Forms/Select';
import { InputField } from '../../global/Forms';
import { Switch } from '../../global/Forms/ToggleSwitch';
import RolePermissionTab from './role_permission_tab';
import RoleAccessLevelTab from './role_accesslevel_tab';

  export default class MemberListChild extends Component{
    constructor(props){
       super(props);
         this.state = { isOn: false, isEdit:false, isAccessCollapse : false, activeTab : 1 };
     }

	  handleToggle(e) {
  		this.setState({isOn: !this.state.isOn});
  	}
	  toggleAccessCollapse = () => {
  		this.setState({isAccessCollapse: !this.state.isAccessCollapse});
  	}
	  toggleEditMember = () => {
  		this.setState({isEdit: !this.state.isEdit});
  	}
	  setActiveTab = (value) => {
  		this.setState({activeTab: value});
  	}

    render(){
      let { data, userRoles, memberData, deleteUser, modalInfo } = this.props;
      var role = userRoles.filter(data => data.role_id === memberData.role_id);
      return (
      <Aux>
        { !this.state.isEdit ?
        <Row className="align-items-center mt-3">
            <Col xs={7}>
              <Row>
              {/*<Col xs={1}><Input className="ml-0" type="checkbox" /></Col>*/}
              <Col xs={5}>{memberData.userDetail && memberData.userDetail.first_name}{" "}{memberData.userDetail && memberData.userDetail.last_name}</Col>
              <Col xs={6}>{memberData.userDetail.email}</Col>

              </Row>
            </Col>
            <Col xs={5}>
              <Row className="align-items-center">
              <Col>{role[0] && role[0].role_name}</Col>
              {/* <Col xs={2}><Switch  isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col> */}
              {/*<Col xs={2}>Society</Col>*/}
              {modalInfo.role_type === 3 && <Col xs={3}><Button onClick={this.toggleAccessCollapse}  className="r-text--sm" color="blue" >Access</Button></Col>}
              <Col>
                {/*<Button className="m-2"  onClick={this.toggleEditMember} color="link-blue" >Edit</Button>*/}
                <Button className="m-2" onClick={() => deleteUser(memberData.id)} color="link-blue" >Delete</Button>
              </Col>
              </Row>
            </Col>
        </Row>

        :
        <Row className="align-items-center mt-3">
            <Col xs={7}>
              <Row>
              {/* <Col xs={1}><Input className="ml-0 mt-3" type="checkbox" /></Col> */}
              <Col xs={5}>
                <Row>
                  <Col xs={6} className="px-1"><InputField type="text" className="w-100" value={memberData.userDetail && memberData.userDetail.first_name} /></Col>
                  <Col xs={6} className="px-1"><InputField type="text" className="w-100" value={memberData.userDetail && memberData.userDetail.last_name} /></Col>
                </Row>
              </Col>
              <Col xs={6}><InputField type="text" className="w-100" value={memberData.userDetail.email} /></Col>

              </Row>
            </Col>
            <Col xs={5}>
              <Row className="align-items-center">
              <Col xs={5}><SelectList className="r-text--sm" selected={role.length > 0 ? role[0].role_name : "Select Role"}>
              <li>Select Role</li>
              {
                userRoles && userRoles.filter(data => data.role_type === modalInfo.role_type).map((child, index) =>  <li key={index} data-role_id={child.role_id} >{child.role_name}</li>)
              }
              </SelectList></Col>
              <Col xs={2}><Switch  isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>

              <Col xs={5}>
                <Button className="m-2"  color="link-blue" >Save</Button>
                <Button className="m-2"  onClick={this.toggleEditMember} color="link-blue" >Cancel</Button>
              </Col>
              </Row>
            </Col>
        </Row>
      }
       <Collapse className="mt-3" isOpen={this.state.isAccessCollapse}>
        <Container className="">
          {/* <Row className="pt-4 pb-2 border-bottom  border-secondary">
            <Col>
              <h3 className={"m-2 p-2 d-inline-block r-subheading-3 " +(this.state.activeTab === 1 ? "active-tab active-tab-brandB":"")} onClick={ ()=>this.setActiveTab(1)} >Placement Drive</h3>
            </Col>
          </Row> */}
          {this.state.isAccessCollapse && <RoleAccessLevelTab onSuccess={this.toggleAccessCollapse} memberData={this.props.memberData} modalInfo={this.props.modalInfo} />}
        </Container>
      </Collapse>
      {/* <RolePermissionTab /> */}
      </Aux>

    )
  }
}
