import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Switch } from '../../../global/Forms/ToggleSwitch';
import { Auth } from '../../../../actions/Auth';
import { CONSTANTS } from '../../../../constants';
import { UnblockStudents } from './unblock-student';
import { ApplicationTimeline } from './application-timeline';
import moment from 'moment';

export class ProfileApplicationStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addStudentModal: false,
      error:{
        show:'',
        message:'',
      },
      applicationData: {
        eligible: 0,
        applied: 0,
        // shortlisted: 0,
        // rejected: 0
      }
    }
  }


  componentWillMount() {
    // Auth.Processes.getApplicants(this.props.application.application_id).then(resp => {
    //   this.props.handleDataChange({ application_id: this.props.application.application_id, ...resp.data })
    // })
  }

  getStatus = () => {
    let { application } = this.props;
    let status = CONSTANTS.PROCESS_APPLICATION_STATUS[application.publish_status || "0"];
    if (application.shortlist_release_status)
      status = "Initial Shortlist"
    if (application.active_round && application.active_round.round_name)
      status = application.active_round.round_name
    return status
  }

  toggleState = (state) => {
    this.setState({
      [state]: !this.state[state]
    })
  }


  checkPublish = (e) => {
    e.stopPropagation()
    let status = this.props.application.publish_status
    if ((this.props.shouldPublish) && (status === 0)) {
      this.toggleState("addStudentModal");
    }
    else if (!this.props.shouldPublish) {
      let { error } = this.state;
      error.show = true;
      error.message = "Please add student to the process";
      this.setState({ error })
    }
  }

  publishApplication = (stList, generatePost) => {
    let { application_id, process_id } = this.props.application
    let body = {
      application_id,
      "student_id": stList,
      generatePost
    }
    Auth.Processes.publishApplication(body).then(resp => {
      this.props.handleDataChange({ application_id, publish_status: 1 })
      Auth.Processes.getApplicationsInProcess(process_id, null, application_id).then(resp => {
        this.props.handleDataChange({ application_id, ...resp.data[0] })
      })
      this.toggleState("addStudentModal");
    })
  }

  render() {
    let { profile, application } = this.props,
      { addStudentModal } = this.state,
      status = this.getStatus(),
      applicationData = {
        eligible: application.applicant_counts ? (application.applicant_counts.eligible || 0) : 0,
        applied: application.applicant_counts ? (application.applicant_counts.applied || 0) : 0,
      }
    return (
      <div className="processes__application">
        {/* <span className="r-subheading-4 r-text--light">Application Status</span> */}
        <Row noGutters className="r-bg--light r-radius--1 r-text--sm">
          <Col xs={7} >
            <Row noGutters>
              <Col xs={8}>
                <div title={profile.role_name} className="processes__application__role">
                  {profile.role_name}
                </div>
              </Col>
              <Col xs={4} className="text-right">
                {application.publish_status === 1 && <div>
                  <span title="Eligible" className="processes__application__status processes__application__status--1">{applicationData.eligible}</span>
                  <span title="Applied" className="processes__application__status processes__application__status--2">{applicationData.applied}</span>
                  {/* <span className="processes__application__status processes__application__status--3">{applicationData.shortlisted}</span>
                  <span className="processes__application__status processes__application__status--4">{applicationData.rejected}</span> */}
                </div>}
              </Col>
            </Row>
          </Col>

          <Col xs={5} className="r-bg--blue-2 rounded-right">
            {application.is_recruitment_closed === 1 ? <span className="px-2">Recruitment Completed</span>
            :
            <Row noGutters>
              <Col xs={6} title={status} className="text-truncate px-2">
                <Row noGutters className="align-items-center justify-content-between">
                  {application.publish_status==1 && (moment(application.application_deadline).diff(moment(),"milliseconds")<=0) ?"Closed" :status}
                  {
                    ((this.props.role.hasPermission.processApplicationPublish) && (this.props.role.hasPermission.processApplicationPublish.permission_access.create === 1) && (application.publish_status === 0)) ? <Switch isOn={application.publish_status} handleToggle={this.checkPublish} /> : null
                  }
                  {(addStudentModal) ? <UnblockStudents publishApplication={this.publishApplication} addStudentModal={addStudentModal} application_id={this.props.application.application_id} process_id={this.props.processId} toggle={() => this.toggleState("addStudentModal")} processStudent={this.props.processStudent} /> : null}
                </Row>
              </Col>
              <Col xs={6} className="px-2">
                <ApplicationTimeline handleDataChange={this.props.handleDataChange} profile={profile} application={application} />
              </Col>
            </Row>
            }
          </Col>

        </Row>
      </div>
    )
  }
}
