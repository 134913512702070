import React,{Component} from 'react';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container} from 'reactstrap';
import {Button, ButtonGroup} from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import  {InputField} from '../../global/Forms';
import  {Switch} from '../../global/Forms/ToggleSwitch';
import {HorizontalLine} from '../../global/horizontal_line';
import {Errorbar} from '../../global/errorbar';
import {Collapse} from 'reactstrap';
import Aux from '../../global/AuxComp';
import { ListGroup, ListGroupItem } from 'reactstrap';
import {Card, CardTitle, CardBody} from 'reactstrap';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import DatePicker from 'react-dates/esm/components/SingleDatePicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import {YearPicker, MonthPicker , DayPicker} from 'react-dropdown-date';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {CONSTANTS} from '../../../constants';


class CompanySection extends Component{
  constructor(){
    super();
    this.state={
      value:1,
      isOn:false,
      contacts : null,
      contactList : [],
      year : null,
      month : null,
      day: null,
      reminderValue:null,
      reminderTriggerValue : 1,
      remindDropdownValue : 1,
      remindTime: null,
      remindDate : null,
      errorbar: false,
      deleteContactList : [],
      contactSelected : [],
      deleteContactList : [],
      message:''
    }
  }

  closebar = () =>{
      this.setState({errorbar: false});
  }

  handleReminderDropdown = (e)=>{
    this.setState({
      remindDropdownValue : e.target.value
    })
  }

  handleReminderTrigger = async (e)=>{
    await this.setState({
      reminderTriggerValue : e.target.value
    })
    this.props.setRepeatReminderValue(this.state.reminderTriggerValue);
  }

  setReminderValue = async (value)=>{
    // let {reminderTriggerValue} = this.state;
      this.setState({
        reminderValue : value
      })
      if(value==1){
        this.props.setRepeatReminderValue("once")
      }
  }

  formatTime = ()=>{
    let {day,month,year,remindTime} = this.state;
    let m = month;
    if(m/10<1){
      m= parseInt(m)+1;
      m = "0"+m;
      // m=m+1;
    }
    let d= day;
    if(d/10<1){
      d="0"+d
    }

    let remindDate = (year+"-"+m+"-"+d);
    // let endDate = (endYear+"-"+em+"-"+ed+" "+endTime);
    // this.setState({
    //   remindDate
    // })
    this.props.setRemindDate(remindDate);
  }

  onClickHandler = (value)=>{
    this.setState({
      value
    })
    // alert("value is "+value);
  }
  handleToggle = (isOn)=>{
    this.setState({
      isOn: !this.state.isOn
    })
  }

  OnChangeStartTime = (e)=>{
    if(this.state.day !=null && this.state.month!=null && this.state.year!=null){
      this.formatTime();
      let time = moment(e).format("HH:mm")+":"+"00";
      this.props.setRemindTime(time);
      this.setState({
        remindTime : moment(e).format("HH:mm:ss")
      })
    }
  }

  getCompanyContacts = async ()=>{
    try{
      let params = new URLSearchParams();
      params.append("access_key",this.props.role.access_key);
      params.append("campus_company_id",this.props.selectedCompany);
      // here send this.state.campus_company_id;
      let contact = await Auth.Batch.getContacts(params);
      await this.setState({
        contacts: contact.data
      })
    }
    catch(e){
      this.setState({errorbar: true});
      if(e.response && e.response.data){
        this.setState({message: e.response.data.error.toString()});
      }
      else{
        this.setState({message: 'Something went wrong! Please try again'});
      }
    }
  }

  addContact = async (contact)=>{
    let {contactList, contactSelected, deleteContactList} = this.state;
    let flag=0;
    for(let i=0;i<contactList.length;i++){
      if(contactList[i]==contact){
        flag=1
      }
    }
    if(flag==0){
      contactList.push(contact);
    }
    if(deleteContactList.length!=0){
      for(let i =0; i<deleteContactList.length;i++){
        if(deleteContactList[i].contact_id == contact.contact_id){
          deleteContactList.splice(i,1);
        }
      }
    }
    await this.setState({
      contactList
    })
    await this.props.setContactList(contactList, contactSelected, deleteContactList);
    this.props.setTotalCompanyMembers(this.state.contactList.length);
  }

  deleteContact = async(contact)=>{
    let list = [];
    let {contactList , contactSelected , deleteContactList} = this.state;
    for(let i =0;i<contactList.length;i++){
      if(contactList[i]!=contact){
        list.push(contactList[i])
      }
    }

    if(contactSelected.length!=0){
      for(let i=0;i<contactSelected.length; i++){
        if(contact.contact_id == contactSelected[i].contact_id){
          deleteContactList.push(contact);
        }
      }
    }

    await this.props.setContactList(contactList , contactSelected , deleteContactList);
    this.props.setTotalCompanyMembers(contactList.length);
    await this.setState({
      contactList : list,
      deleteContactList
    })
  }

  getMeetingAttendees = async ()=>{
    if(this.props.meetingId!=null){

      let cList = [];
      let contacts = this.state.contacts;

      let params = {
        "meeting_id" : this.props.meetingId,
        "attendee_type": "contact"
      }
      let attendeeList = []
      try{
        let meetingAttendees =await  Auth.Batch.getAttendeesList(params);
        meetingAttendees.data.map(attendee=>{
          attendeeList.push(attendee.user_id);
        })
        if(attendeeList.length!=0){

            for(let i=0;i<attendeeList.length;i++){
              contacts.map(contact=>{
                if(contact.contact_id==attendeeList[i]){
                  cList.push(contact);
                }
              })
            }
            this.setState({
              contactList : [...cList],
              contactSelected : [...cList]

            })
        }
      }
      catch(e){
        this.setState({errorbar: true});
        if(e.response && e.response.data){
          this.setState({message: e.response.data.error.toString()});
        }
        else{
          this.setState({message: 'Something went wrong! Please try again'});
        }
      }
    }
  }

  async componentDidMount(){
    await this.getCompanyContacts();
     this.getMeetingAttendees();
  }

  render(){
    return(
      <Aux>
      <Row className="m-1 mt-4 pt-1">
      <Col xs="10" onClick={()=>this.onClickHandler(1)}>
      <span className="r-heading-4">1. Primary</span>
      </Col>
      <Col xs="2" align="end">
      {this.state.value==1?<i className="fi flaticon-arrow-q"/> : <i className="fi flaticon-arrow"/>}
      </Col>
      </Row>
      <Collapse  isOpen={this.state.value==1} className="mb-2">
      <Row>
      <Col xs="6" className="mr-0 pr-0 mt-3">
      <Card className="border-0 r-bg--grey-2 m-1 ml-3 mr-0 pr-0 p-2 calendar__primary-card">
      <CardTitle>
      <span className="r-text--dark r-subheading-3">All members</span>
      </CardTitle>
      <CardBody className="p-2 mt-1">

      {this.state.contacts!=null?this.state.contacts.map(contact=>{
        return(
          <Row className="m-1 mb-2 p-1">
          <Button className="calendar__pt-member-button r-text--grey-4 w-100 r-subheading-4" onClick={()=>this.addContact(contact)}><Row><Col xs="10" className="m-1" align="start">{contact.first_name}{" "}{contact.last_name}{" "} ({contact.contact_type}) </Col><Button color="link"><Col xs="2" className="p-0 m-0" align="end"><i className="fi flaticon-plus-2"/></Col></Button></Row></Button>
          </Row>


      )}):null

      }
      </CardBody>
      </Card>
      </Col>
      <Col xs="6" className="pl-0 ml-0 mt-3">
      <Card className="border-0 r-bg--grey-2 m-1 ml-3 p-2 calendar__primary-card">
      <CardTitle>

      <Col  className="mb-2"><span className=" r-text--dark r-subheading-3"> Selected </span> <span className="r-text--green-1 r-subheading-4 ml-2">{this.state.contactList.length} Participants selected </span></Col>

      </CardTitle>
      <CardBody className="p-0 pt-0">

      {this.state.contactList.length!=0?this.state.contactList.map(contact=>{
        return(
          <Row className="m-1 mb-2 p-1">
          <Button className="calendar__pt-member-button r-text--grey-4 w-100 r-subheading-4"><Row><Col xs="10" className="m-1 pr-0" align="start"><span>{contact.first_name}{" "}{contact.last_name}{" "} ({contact.contact_type}) </span></Col><Col xs="1" className="p-0 m-0 border-0 mt-1"  align="end" onClick={()=>this.deleteContact(contact)}><i className="fi flaticon-plus-3"/></Col></Row></Button>
          </Row>
        )
      }):null
      }

      </CardBody>
      </Card>
      </Col>
      </Row>
      </Collapse>
      <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      </Aux>
    )
  }
}
const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(CompanySection);
