import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import { Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { Loader } from '../../../global/Loader';
import { SelectList } from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import { CONSTANTS } from '../../../../constants';
import { CompanyContact } from './contact-card';


export class ContactCrudModal extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      contactList : [],
      isDetailsOpen : false,
      modalAddContact : false,
      addContact : this.props.addContact || {mobile_detail:[{mobile_number : ''}], email_detail:[{email_id : ''}]},
    }
  }

  componentDidMount() {


  }
  handleAddContact = (key, value, index) => {
    var addContact = this.state.addContact;
    if(index !== undefined){
      var obj_key = key === 'email_detail' ? 'email_id' : 'mobile_number';
      addContact[key] = Array.isArray(addContact[key]) ? addContact[key] : [];
      addContact[key][index][obj_key] = value;
      if(addContact.contact_id){
          addContact[key][index].append_new = true;
      }
    }
    else
      addContact[key] = value;

    this.setState({ addContact })
  }
  appendAddContactFeild = (key) => {
    var addContact = this.state.addContact;
    if(key === "mobile_detail"){
      addContact.mobile_detail.push({mobile_number : ''});
    }
    else if(key === "email_detail"){
      addContact.email_detail.push({email_id : ''});
    }
    this.setState({ addContact })
  }



  removeContactDetail = (type, index) => {
      var addContact = this.state.addContact;
      if(type === "mobile_detail"){
        addContact.deletedMobile = addContact.deletedMobile  || [];
        addContact.deletedMobile.push(addContact.mobile_detail[index].id);
        addContact.mobile_detail.splice(index, 1);
      }
      else if(type === "email_detail"){
        addContact.deletedEmail = addContact.deletedEmail || [];
        addContact.deletedEmail.push(addContact.email_detail[index].id);
        addContact.email_detail.splice(index, 1);
      }
      this.setState({addContact});
  }
  submitContactForm = (e) =>{
    e.preventDefault();
    var addContact = this.state.addContact;
    if(addContact.contact_id){
      this.editContactSubmit(e);
    }
    else{
      this.createContact(e);
    }
  }
  editContactSubmit = async () => {
    this.props.toggleLoading(true)
    var addContact = this.state.addContact;
    addContact.comments = undefined
    var contact_id = addContact.contact_id;
    addContact.access_key = 3;
    try {
      let resp = await Auth.PtCompanies.editContact(addContact);
      if(addContact.deletedMobile){
        var deleteMobiledata = {
          id : addContact.deletedMobile,
          status : 0
        }
        try {
            await Auth.PtCompanies.inactiveContactMobile(deleteMobiledata)
        }catch (e){
          console.log(e);
        }
      }
      var appendMobileDetail = addContact.mobile_detail.filter(data => data.append_new === true);
      await Promise.all(appendMobileDetail.map(async(item, i)=>{
        var data = {
          contact_id : contact_id,
          mobile_number : item.mobile_number
        }
        try {
          var campusResTemp = await Auth.PtCompanies.addContactMobile(data)
        }catch (e){
          this.props.setError(true, e.response.data.error.toString() || 'Sorry! something went wrong with contact details. Please try again')
        }
      }));
      var appendEmailDetail = addContact.email_detail.filter(data => data.append_new === true);
      await Promise.all(appendEmailDetail.map(async(item, i)=>{
        var data = {
          contact_id : contact_id,
          email_id : item.email_id
        }
        try {
          var campusResTemp = await Auth.PtCompanies.addContactEmail(data)
        }catch (e){
          this.props.setError(true, e.response.data.error.toString() || 'Sorry! something went wrong with Email details. Please try again')
        }
      }));

      if(addContact.deletedEmail){
        var deleteEmaildata = {
          id : addContact.deletedEmail,
          status : 0
        }
        try {
          await Auth.PtCompanies.inactiveContactEmail(deleteEmaildata)
        }catch (e){
          console.log(e)
        }
      }
      this.props.toggleAddContact();
      this.props.init();
      if(this.props.refreshParent)
         this.props.refreshParent()
    }
    catch (e) {
      if(e.response.data.error.toString() !== null)
        this.props.setError(true, e.response.data.error.toString())
      else
        this.props.setError(true, 'Sorry! something went wrong. Please try again')
    }
    this.props.toggleLoading(false)
  }
  createContact = async (e) => {
    e.preventDefault();
    this.props.toggleLoading(true)
    var addContact = this.state.addContact;
    addContact.comments = undefined
    var campus_company_id = this.props.campus_company_id;
    if(campus_company_id){
      addContact.campus_company_id = campus_company_id;
      addContact.access_key = 3;
      try {
        let resp = await Auth.PtCompanies.addContact(addContact);
        var contact_id = resp.data.insertId;
          await Promise.all(addContact.mobile_detail.map(async(item, i)=>{
            if(item.mobile_number){
              var data = {
                contact_id : contact_id,
                mobile_number : item.mobile_number
              }
              try {
                var campusResTemp = await Auth.PtCompanies.addContactMobile(data)
              }catch (e){
                this.props.setError(true, e.response.data.error.toString() || 'Sorry! something went wrong with contact details. Please try again')
              }
            }
          }));

          await Promise.all(addContact.email_detail.map(async(item, i)=>{
            if(item.email_id){
              var data = {
                contact_id : contact_id,
                email_id : item.email_id
              }
              try {
                var campusResTemp = await Auth.PtCompanies.addContactEmail(data)
              }catch (e){
                this.props.setError(true, e.response.data.error.toString() || 'Sorry! something went wrong with Email details. Please try again')

              }
            }
          }));

        this.props.toggleAddContact();
        this.props.init();
        if(this.props.refreshParent)
           this.props.refreshParent()
      }
      catch (e) {
          this.props.setError(true, e.response.data.error.toString() || 'Sorry! something went wrong. Please try again')
      }


    }
    else{
      this.props.setError(true, 'campus_company_id not found')
    }
    this.props.toggleLoading(false)
  }



  render() {
    let { contactList, isDetailsOpen, editableCompany, addContact } = this.state;
    let { campus_company_id, toggleAddContact } = this.props;
    return (

      <Modal isOpen={this.props.modalAddContact} toggle={toggleAddContact} className="modal-dialog-centered modal-lg">
      <form onSubmit={this.submitContactForm}>
        <ModalBody className="p-5">

          <h1 className="r-subheading-3 mb-4">Add Contacts to the Company</h1>
          <Row className=" align-items-center my-3">
            <Col>
              <h1 className="r-subheading-3"><span className="r-text--red-1">*</span>First Name</h1>
              <InputField onChange={(e) => this.handleAddContact('first_name', e.target.value)} className="w-100" value={addContact.first_name} />
            </Col>
            <Col>
              <h1 className="r-subheading-3">Last Name</h1>
              <InputField  onChange={(e) => this.handleAddContact('last_name', e.target.value)} className="w-100" value={addContact.last_name} />
            </Col>

          </Row>
          <Row className=" align-items-center my-3">
            <Col>
              <h1 className="r-subheading-3"><span className="r-text--red-1">*</span>Contact Type</h1>
              <SelectList  className="w-100" selected={this.state.addContact && CONSTANTS.CONTACT_TYPES[addContact.contact_type] || "Select Contact Role"} >
                <li>Select Contact Role</li>
                { Object.keys(CONSTANTS.CONTACT_TYPES).map((data, index) =>
                  <li key={index} onClick={(e) => this.handleAddContact('contact_type', data)}>{CONSTANTS.CONTACT_TYPES[data]}</li>
                )}
              </SelectList>
            </Col>
          </Row>
          <h1 className="r-subheading-3">Contact Number</h1>

          <Row className=" align-items-center my-3">
            <Col>
              { addContact.mobile_detail.map((data, index) =>
              <div className="input-icon"  key={index} >
                <InputField onChange={(e) => this.handleAddContact('mobile_detail', e.target.value, index)} readOnly={data.id ? true : false} value={data.mobile_number} className="w-100 my-2" />
                <i onClick={() => this.removeContactDetail('mobile_detail', index)} className="fi flaticon-bin selectable"></i>
              </div>
              )}
            </Col>
            <Col>
              <Button type="button"  onClick={() => this.appendAddContactFeild('mobile_detail')} color="grey r-btn--no-radius r-text--sm"><i className="fi flaticon-plus"></i></Button>
            </Col>
          </Row>
          <h1 className="r-subheading-3">Email</h1>
          <Row  className=" align-items-center my-3">
          <Col>
            { this.state.addContact.email_detail.map((data, index) =>
            <div className="input-icon" key={index} >
              <InputField onChange={(e) => this.handleAddContact('email_detail', e.target.value, index)} value={data.email_id} className="w-100 my-2" />
              <i onClick={() => this.removeContactDetail('email_detail', index)} className="fi flaticon-bin selectable"></i>
            </div>
            )}
          </Col>
            <Col>
              <Button type="button" onClick={() => this.appendAddContactFeild('email_detail')} color="grey r-btn--no-radius r-text--sm"><i className="fi flaticon-plus"></i></Button>
            </Col>
          </Row>
          <Row className=" align-items-center my-3">
            <Col>
              <h1 className="r-subheading-3">Linked In</h1>
              <InputField   onChange={(e) => this.handleAddContact('linkedin', e.target.value)} value={addContact.linkedin} className="w-100" />

            </Col>

          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="dark-v r-btn--no-radius r-text--sm" type="button" onClick={toggleAddContact}>Cancel</Button>{' '}
          <Button color="dark r-btn--no-radius r-text--sm" type="submit" >Save</Button>
        </ModalFooter>
        </form>
      </Modal>
    )
  }
}
