import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import moment from 'moment';

export default class FreezeProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deadline: props.studentDetails.user_info.deadline,
      deadLineCounter: moment.duration(moment(props.studentDetails.user_info.deadline).diff(moment(), "milliseconds")),
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.studentDetails.user_info.deadline != this.state.deadline) {
      this.setState({
        deadline: nextProps.studentDetails.user_info.deadline,
        deadLineCounter: moment.duration(moment(nextProps.studentDetails.user_info.deadline).diff(moment(), "milliseconds")),
      })
    }
  }

  componentWillMount() {
    this.interval = setInterval(this.getDeadline, 1000)
  }



  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getDeadline = () => {
    let deadLineCounter = moment.duration(moment(this.state.deadline).diff(moment(), "milliseconds"))
    this.setState({ deadLineCounter })
  }



  render() {
     let { deadLineCounter,deadline } = this.state ;
    return (
            <div >
            {(deadline)?
              <Col xs={12} className="r-bg--blue-2 rounded">
              <span className="mr-1">Profile will be freezed</span>
              <div className="text-truncate text-center r-subheading-4 r-text--brandF">{`${deadLineCounter.asDays() < 0 ? 0 : Math.floor(deadLineCounter.asDays())}d:${deadLineCounter.hours() < 0 ? 0 : deadLineCounter.hours()}h:${deadLineCounter.minutes() < 0 ? 0 : deadLineCounter.minutes()}m:${deadLineCounter.seconds() < 0 ? 0 : deadLineCounter.seconds()}s`}</div>
            </Col>:null}
            </div> 
    )
  }
}
