import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../../actions/Auth';
import { setUser, setLoading } from '../../../../actions/action-creators';
import Button from '../../../global/Button';
import { Row, Col, Container, Popover, Modal, ModalBody , ModalHeader , ModalFooter } from 'reactstrap';
import { HorizontalLine } from '../../../global/horizontal_line';
import { Errorbar } from '../../../global/errorbar';
import StudentSelect from './StudentSelect';
import AddStudent from './AddStudent';
import { uploadFile, getFullName } from '../../../../actions';
import ShareProfile from './ShareProfile';
import PTTeamNav from '../PTTeamNav';
import StudentTable from './StudentTable';
import { Loader } from '../../../global/Loader';
import { Select } from '../../../global/Forms/Select';
import Link from "react-router-dom/Link";
import HelpSection from "../../helpSection/student_section";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.batches = {}
    this.state = {
      init: true,
      loader: false,
      getProcesList: [],
      getStudentAssigned: [],
      isMultipleSelect: false,
      getStudentProfileDetails: [],
      studentProcess: [],
      errorbar: false,
      message: '',
      isOn: false,
      modalstudent: false,
      modalshare: false,
      modal: false,
      popoverOpen: false,
      popoverFilter: false,
      modalSelect: false,
      batchList: {},
      allStudents: [],
      mainData: [],
      depList: {},
      groupList: {},
      studentFilters: {
        batches: [],
        departments: [],
        groups: [],
      },
      processList: [],
      batchs: [],
      batchdata: [],
      filterIcon: false,
      process: { process_name: "Profiles", process_type: "student", process_id: 1 },
      studentsIDs: [],
      cvCategoryModal: false,
      cvCategories: [],
      downloadCSVModal : false,
      sortOrderName:true,
      sortOrderRoll:true,
      settingsPermission: false
    };
    this.child = React.createRef();
  }

  async componentDidMount () {
    let {hasPermission} = this.props.role
    if(hasPermission.settingsTab && hasPermission.settingsTab.permission_access.read)
      this.setState({settingsPermission: true})
    if(!hasPermission.studentTab || (hasPermission.studentTab && !hasPermission.studentTab.permission_access.read)) {
      this.props.history.push("/pt/companies")
      return
    }
    await this.init();
    if(sessionStorage.getItem("studentFilters") !== null){
         let studentFilters  = JSON.parse(sessionStorage.getItem('studentFilters'));
        await this.filterStudents(studentFilters, this.state.batchList, this.state.depList, this.state.groupList)
        sessionStorage.removeItem("studentFilters")
    }
  }

  getAllStudents() {
    let array = [];
    document.getElementsByName("selectedStudent").forEach(data => {
      if (data.checked) {
        array.push(data.value);
      }
    })
    return array;
  }

  init = async () => {
    this.setState({ loader: true });
    try {
      let response = await Auth.PtStudents.getBatch();
      let responseBatch = await Auth.Batch.getBatchDetail();
      let cvCategoryResp = await Auth.Student.getCVCategories();
      this.setState({ batchs: response.data, cvCategories: cvCategoryResp.data });
      // this.setState({ batchList: responseBatch.data.reduce((prev, current) => { prev[current.batch_id] = current; return prev; }, {}) })
      let tempbatch = {};
      let tempsts = [];
      await response.data.forEach(batch => {
        tempbatch[batch.batch_id] = { batch_name: batch.batch_name, departments: {} };
        batch.student_info && batch.student_info.forEach(student => {
          tempsts.push(student)
          tempbatch[batch.batch_id].departments[student.degree_department_id] = { department_name: student.department_name }
        })
      })
      this.setState({ allStudents: tempsts });
      this.setState({ mainData: tempsts });
      // let processResp = await Auth.Processes.getProcesses(this.props.role.instituteDetail.campus_id);
      // this.setState({
      //   processList: processResp.data,

      // })
    } catch (e) {
      console.log(e)
      // this.setState({errorbar: true});
      // this.setState({message: e.response.data.error});
    }
    this.setState({ init: false });
    this.setState({ loader: false });
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  toggleSelect = () => {
    this.setState(prevState => ({
      modalSelect: !prevState.modalSelect
    }));
  }

  togglestudent = () => {
    this.setState(prevState => ({
      modalstudent: !prevState.modalstudent
    }));
  }


  togglePopOver1 = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  togglePopOverFilter = () => {
    this.setState({
      popoverFilter: !this.state.popoverFilter
    });
  }

  toggleshare = () => {
    this.setState(prevState => ({
      modalshare: !prevState.modalshare
    }));
  }


  toggleState = (state) => {
    this.setState({ [state]: !this.state[state] })
    if (state === 'isMultipleSelect') {
      this.setState({ selectedCards: [] })
    }
  }


  toggleProcess = () => {
    this.setState(prevState => ({
      isOpenAddProcess: !prevState.isOpenAddProcess
    }));
  }


  filterStudents = async (studentFilters, batchList, depList, groupList) => {
    this.setState({ filterIcon: true, batchList: batchList, depList: depList, groupList: groupList });
    this.setState(prevState => ({
      studentFilters: {
        ...prevState.studentFilters,
        batches: studentFilters.batches,
        departments: studentFilters.departments,
        groups: studentFilters.groups,
      }
    }))
    this.setState({ loader: true });
    let students = this.state.allStudents;

    if (studentFilters.batches.length > 0)
      students = students.filter(st => studentFilters.batches.indexOf(st.batch_id.toString()) > -1)
    if (studentFilters.departments.length > 0)
      students = students.filter(st => studentFilters.departments.indexOf(st.batch_id + "-" + st.degree_department_id) > -1)
    if (studentFilters.groups.length > 0)
      students = students.filter(st => studentFilters.groups.indexOf(st.batch_id + "-" + st.degree_department_id + "-" + st.group_id) > -1)

    this.setState({ loader: false });
    this.setState({ allStudents: students, modal: false });
  }


  getAlert = async () => {
    this.setState(prevState => ({
      studentFilters: {
        ...prevState.studentFilters,
        batches: [],
        departments: [],
        groups: []
      }
    }));
    this.setState({ filterIcon: false });
    this.init();
  }

  downloadCSV = async () => {
    let { allStudents } = this.state,
      params = {student_id : []}
    allStudents.forEach(st => {
      params.student_id.push(st.student_id);
    })
    this.setState({ loader: true });
    try {
      let response = await Auth.Download.csvStudentData(params)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'StudentProfiles.csv');
      document.body.appendChild(link);
      link.click();
      link.remove()
    } catch (e) {
      console.log(e)
      let message = "Something went wrong. Please try again later."
      if (e.response && e.response.data)
        message = e.response.data.error
      this.setState({ errorbar: true, message })
    }
    this.setState({ loader: false , downloadCSVModal : !this.state.downloadCSVModal });
  }

  downloadCV = async(e) => {
    e.preventDefault()
    let { allStudents } = this.state,
      {cv_category_id} = e.target;
    let formData = {
      scope: "batch",
      cv_category_id: cv_category_id.value,
      student_id: allStudents.map(st=>st.student_id)
    }
    this.setState({ loader: true });
    try {
      let resp = await Auth.Download.cvDownload(formData);
      this.setState({cvMessage: resp.data.message})
    } catch(e) {
      let message = "Something went wrong. Please try again later."
      if (e.response && e.response.data)
        message = e.response.data.error
      this.setState({ errorbar: true, message })
    }
    this.setState({ loader: false });
  }

  refresh = async () => {
    await this.init();
    this.filterStudents(this.state.studentFilters, this.state.batchList, this.state.depList, this.state.groupList)
  }

  stProfile = (row, type) =>{
    let url = (type === 'profile')?`/pt/students/recruitments/${row.student_id}/profile`:`/pt/students/recruitments/${row.student_id}/resumes`
    if (this.state.filterIcon) {
        sessionStorage.setItem('studentFilters',JSON.stringify(this.state.studentFilters))
    }
    this.props.history.push(url)
  }


  sortByRoll = (type) =>{
    let {allStudents , sortOrderRoll, sortOrderName} = this.state;
    allStudents = this.state.allStudents.sort((a, b) => {
      let nameA=a[type].toString().toLowerCase();
      let nameB=b[type].toString().toLowerCase();
      if(sortOrderRoll || sortOrderName) {
           this.setState({sortOrderRoll:false});
           this.setState({sortOrderName:false});
            if (nameA < nameB)
                return -1
            if (nameA > nameB)
                return 1
            return 0
      }
      else{
            this.setState({sortOrderName:true});
           this.setState({sortOrderRoll:true});
            if (nameA < nameB)
                return 1
            if (nameA > nameB)
                return -1
            return 0
      }
    })
    this.setState({allStudents});
  }

  render() {
    let { loader, settingsPermission, batchs, studentFilters, allStudents, batches, cvCategoryModal, cvCategories, cvMessage } = this.state;
    
    return (
      <Container className="apply" id="pt-students">
        <Loader show={loader} />
        <Row className="justify-content-between align-items-center">
          <Col xs="10">
            <PTTeamNav process={this.state.process} />
          </Col>
          <Col xs="auto my-4">
            <Button onClick={this.togglestudent} className="font-weight-normal px-2 py-0" color="brandB"><i className="m-2 r-text--sm fi flaticon-plus-1" />  Add Student</Button>
          </Col>
        </Row>
        <HorizontalLine color="#cfcfcf" />
        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Row className="justify-content-start align-items-center my-2">
              <Col xs='auto'>
                {
                  <div className="d-flex align-items-center my-3">You are viewing {allStudents.length} students from {(studentFilters.departments.length === 0) ? <div className="mx-2"> all</div> : <div className="mx-2">{studentFilters.departments.length}</div>}departments of {(studentFilters.batches.length === 0) ? <div className="mx-2"> all</div> : <div className="mx-2">{studentFilters.batches.length}</div>} batches</div>
                }
              </Col>
              <Col xs='auto' style={{ display: this.state.filterIcon ? 'none' : '' }}>
                <Button onClick={this.toggle} id="PopoverFilter" className="font-weight-normal ml-3 px-2 py-0" color="blue3"><img style={{ width: "21px" }} src="/images/filter-512.png" alt="" /></Button>
              </Col>
              <Col xs='auto' style={{ display: this.state.filterIcon ? ' ' : 'none' }}>
                <Button onClick={this.getAlert} className="font-weight-normal ml-3 px-2 py-0" color="blue3"><img style={{ width: "21px" }} src="/images/clear_filters.png" alt="" /></Button>
              </Col>
            </Row>
          </Col>
      { allStudents.length !== 0 &&
          <Col xs="auto">
            <Button color="dark" className="mr-2 r-text--sm" onClick={() => this.setState({ cvCategoryModal: true,cvMessage:"" })}>Download CV<i className="ml-2 fi flaticon-file" /></Button>
            <Modal zIndex="99999" className="modal--centered" isOpen={cvCategoryModal} toggle={() => this.setState({ cvCategoryModal: false })}>
              <ModalBody>
                <form onSubmit={this.downloadCV}>
                  <label>Select CV Category</label>
                  <Select name="cv_category_id" required={true}>
                    <option value="">Select</option>
                    {
                      cvCategories.map(ct=><option key={ct.cv_category_id} value={ct.cv_category_id}>{ct.cv_category}</option>)
                    }
                  </Select>
                  <div className="r-text--sm">{cvMessage}</div>
                  <Button color="dark" className="r-btn--no-radius mt-4">Send CV's to email</Button>
                </form>
              </ModalBody>
            </Modal>
            <Button color="dark" className="r-text--sm" onClick={()=>this.setState({downloadCSVModal : !this.state.downloadCSVModal})}>Download Excel<i className="ml-2 fi flaticon-file" /></Button>
            <div className="excel_setting">
            <Modal zIndex="99999" className="modal--centered excel_setting" isOpen={this.state.downloadCSVModal} toggle={() => this.setState({ downloadCSVModal: false })}>
            <ModalBody>
              <Button color="dark" onClick={()=>this.downloadCSV()}>Download</Button>
              {settingsPermission && <Link to={"/pt/Configurations/excel"}><Button className="r-text--dark r-bg--light border ml-3">Change Excel Configurations</Button></Link>}
            </ModalBody>
            </Modal>
            </div>
          </Col>
      }
        </Row>

        {(!this.state.init) ? <Col xs='auto'>
          {(batchs.length !== 0) ?
                <StudentTable
                    sortByRoll={this.sortByRoll}
                    tableData={allStudents}
                    refreshtable={this.state.filterIcon ? this.refresh : this.init}
                    toggleAddProcess={this.toggleProcess}
                    stProfile={this.stProfile} />
            : <div>No batches added. Please add a <Button onClick={(e) => this.props.history.push("/pt/students/batches")} className="font-weight-normal px-0 py-2" style={{ fontSize: "14px", color: "blue" }}>batch</Button> to proceed</div>}
        </Col> : null}
        <StudentSelect onRef={ref => (this.child = ref)} filterStudents={this.filterStudents} toggle={this.toggle} batches={this.state.batchs} allStudents={allStudents} modal={this.state.modal} />{/*
                        <StudentSelectProcess onRef={ref => (this.child = ref)} filterStudents={this.filterStudents} process={this.state.processList} toggle={this.toggleSelect} batches={this.state.batchList}  allStudents={allStudents}  modal={this.state.modalSelect}/>*/}
        <AddStudent settingsPermission={settingsPermission} batchdata={this.state.batchs} getBatchs={this.init} toggle={this.togglestudent} batches={batches} allStudents={allStudents} togglbatch={this.togglbatch} modal={this.state.modalstudent} />
        <ShareProfile toggle={this.toggleshare} modal={this.state.modalshare} />

        {/* <Popover placement="bottom-end" trigger="legacy" isOpen={this.state.popoverFilter} target="PopoverFilter" toggle={this.togglePopOverFilter} >
                                                <Col xs="auto">
                                                    <Row className="align-items-center mx-0" >
                                                         <Button  onClick={this.toggleSelect}className="font-weight-normal py-2" color="BrandA" style={{fontSize:"14px"}}>Filter by Process</Button>
                                                    </Row>
                                                    <Row className="align-items-center mx-0" >
                                                          <Button onClick={this.toggle} className="font-weight-normal py-2" color="BrandA" style={{fontSize:"14px"}}>Filter by batch/department</Button>
                                                    </Row>
                                                </Col>
                                            </Popover>*/}
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
        <HelpSection  tab={"studentTab"} />
      </Container>
    )
  }
}


const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoading: (show) => dispatch(setLoading(show)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
