import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Row,Col,Container,Collapse} from 'reactstrap';
import Button from '../../global/Button';
import { InputField } from '../../global/Forms';
import { Switch } from '../../global/Forms/ToggleSwitch';
import AddCourseCollapse from './add_course_collapse';

  export default class AddCampusCollapse extends Component{
    constructor(props){
       super(props);
         this.state = { isOn: false, addressCollapse : false, appendCollapse : [] };
     }

	  handleToggle(e) {
  		this.setState({isOn: !this.state.isOn});
  	}
	  toggleAddressCollapse = (e) => {
  		this.setState({addressCollapse: !this.state.addressCollapse});
  	}

    render(){
      let { campusKey,removeCampus,removeCourse, campusData,addCourse, state , appendCreateCampusData, appendCreateCourseData, degreeList} = this.props;
      return (
        <div>
          <Row className="align-items-center">
            <Col xs={7} className="offset-1" >
              <h1 className="r-subheading-4">Campus Name</h1>
              <InputField  onChange={(e) => appendCreateCampusData(campusKey, 'name', e.target.value)} className="w-100" type="text" />
              <Button onClick={this.toggleAddressCollapse} className="r-text--sm ml-3 location_icon" color="link-blue"><i className="d-inline-block fi flaticon-pin"></i></Button>
                <Collapse className="my-3" isOpen={this.state.addressCollapse}>
                <Row className="align-items-center">
                  <Col> <h1 className="r-subheading-4">City</h1> <InputField onChange={(e) => appendCreateCampusData(campusKey, 'city', e.target.value)} className="w-100" type="text" /></Col>
                  <Col> <h1 className="r-subheading-4">State</h1><InputField onChange={(e) => appendCreateCampusData(campusKey, 'state', e.target.value)} className="w-100" type="text" /></Col>
                  <Col> <h1 className="r-subheading-4">Country</h1><InputField onChange={(e) => appendCreateCampusData(campusKey, 'country', e.target.value)} className="w-100" type="text" /></Col>
                  <Col> <h1 className="r-subheading-4">Pin</h1><InputField onChange={(e) => appendCreateCampusData(campusKey, 'pin', e.target.value)} className="w-100" type="text" /></Col>
                </Row>
                </Collapse>
            </Col>
            <Col xs={1}>
              <h1 className="r-subheading-4">Status</h1>
              <div className="">
              <Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)} />
              </div>
            </Col>
            <Col xs={2} className="align-middle">
              <Button onClick={() => addCourse(campusKey)} className="r-text--sm ml-3" color="link-blue"><i className="d-inline-block fi flaticon-arrow rotate--90 mr-2"></i>Add New Degree</Button>
            </Col>
            <Col xs={1}>
              <Button onClick={()=>removeCampus(campusKey)} className="r-text--sm ml-3"><i className="d-inline-block fi flaticon-plus-1 rotate--45 mr-2"></i></Button>
            </Col>
          </Row>
          {
            campusData && Array.isArray(campusData.course_obj) && campusData.course_obj.map((child, index) => <AddCourseCollapse key={index} campusKey={campusKey} courseKey={index} appendCreateCourseData={appendCreateCourseData} removeCourse={removeCourse} degreeList={degreeList} /> )
          }
          </div>

    )
  }
}
