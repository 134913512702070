import React, { Component } from 'react';
import {Errorbar} from '../../../global/errorbar';
import {
  LineChart, Line,BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,LabelList,
} from 'recharts';
import { Auth } from '../../../../actions/Auth';
import {Confirmation} from '../../../global/confirmation';
import { Switch } from '../../../global/Forms/ToggleSwitch';
import { setUser, setLoading } from '../../../../actions/action-creators';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import {Row,Col, Modal, ModalBody} from 'reactstrap';
import PTFeedPost from './PTFeedPost';
import FeedPost  from '../../../global/post';
import PTTeamNav from '../PTTeamNav';
import PTFeedDetails from './PTFeedDetails';
import Aux from '../../../global/AuxComp';
import ReportIcon from "../../../../assets/report.png"
import FilterWithProcess from '../../../global/filter-with-process';

 class PostCard extends Component{
  constructor(props){
    super(props);
    this.state = {
      getProcesList:  [],
      getStudentAssigned: [],
      getStudentProfileDetails: [],
      errorbar: false,
      message:'',
      isOn: false,
      post: props.data,
      modalstudent: false,
      isConfirmation : false,
      deleteConfirmationId : null,
      modalshare:false,
      modal: false,
      modaledit: false,
      collapse: true,
      postcolapse: false,
      popoverOpen: false,
      popoverOpen2: false,
      process:{process_name: "Student Feed",process_type: "feed",process_id: 3},
      isOpenPostDetail : false,
      addStudentModal: false,
      selectedCount : 0
    };
  }


    componentWillReceiveProps(props){
      this.setState({post :props.data});
    }


    toggle = () => {
      this.setState(state => ({ collapse: !state.collapse }));
    }
    togglePostDetail = () => {
      this.setState({isOpenPostDetail: !this.state.isOpenPostDetail})
    }

    handleToggle(e) {
      this.setState({isOn: !this.state.isOn});
    }

    handlepostToggle = (e) => {
        this.setState(state => ({ postcolapse: !state.postcolapse }));
    }

    togglePopOver1 = () => {
      this.setState({
        popoverOpen: !this.state.popoverOpen
      });
    }

    togglePopOver2 = () => {
      this.setState({
        popoverOpen2: !this.state.popoverOpen2
      });
    }

  deletepost = async(id) => {
    //this.props.setLoading(true);
    this.toggleConfirmation()
    let postBody = {"post_id": id, status : "0"};
    try {
      await Auth.Feed.editPost(postBody);
      this.props.refreseh();
      // this.props.setLoading(false);
    } catch (e) {
      //this.props.setLoading(false)
      this.setState({errorbar: true});
      this.setState({message: e.response.data.error.toString()});
    }
  }


  confirmDeleteUser = (id) => {
    this.setState({deleteConfirmationId : id, isConfirmation : true})
  }

  toggleConfirmation = () => {
    this.setState({isConfirmation : false})
  }

  addStModal = async (post_id) => {
    this.setState({
      isLoading: true
    })
    this.setState({ addStudentModal: post_id })
    this.allStudents = []
    await Auth.PtStudents.getBatch().then(resp => {
      resp.data.forEach(batch => {
        batch.student_info.forEach(student => {
          this.allStudents.push(student)
        })
      })
    })
    this.setState({ isLoading: false, selectedCount: this.allStudents.length })
  }
  filterStudents = (studentFilters, processStudent) => {
    let students = this.allStudents;
    if (studentFilters.batches.length > 0)
      students = students.filter(st => studentFilters.batches.indexOf(st.batch_id.toString()) > -1)
    if (studentFilters.departments.length > 0)
      students = students.filter(st => studentFilters.departments.indexOf(st.batch_id + "-" + st.degree_department_id) > -1)
    if (studentFilters.groups.length > 0)
      students = students.filter(st => studentFilters.groups.indexOf(st.batch_id + "-" + st.degree_department_id + "-" + st.group_id) > -1)
    if (studentFilters.selectedProcess && studentFilters.selectedProcess.length && processStudent)
    students = students.filter(st => processStudent.indexOf(st.student_id) > -1)
    this.setState({
       selectedCount: students.length });
  }
  postPublish = async () =>{
    try {
      var userBody = {
        post_id : this.state.addStudentModal,
        user_id : this.allStudents.map(s => s.user_id)
      }
      await Auth.Feed.postMemberAdd(userBody)
      await Auth.Feed.approvePost({post_id : this.state.addStudentModal});
      this.props.init();
      this.setState({ addStudentModal: false })
    }
    catch(e){
      this.setState({errorbar: true});
      this.setState({message: e.response.data.error.toString()});
    }
  }
  render(){
    let {data, files} = this.props;
    const {post,isConfirmation, selectedCount, addStudentModal} = this.state;
      return (
        <Aux>

         {/* <Card className="post" >
                               <Row className="justify-content-between align-items-center py-2 ">
                                       <Col xs="1" className="text-center px-2">
                                           <img src="/images/dummy_company.PNG" style={{width:"40px"}}/>
                                       </Col>
                                       <Col xs="3" className="px-2">
                                         <h4>{data.post_title}</h4>
                                       </Col>
                                       <Col xs="2" className="px-2">
                                         <div>{moment(data.post_date).format("hh:mma, Do MMMM YYYY")}</div>
                                       </Col>
                                       <Col xs="1" className="px-2">
                                       {data.post_type}
                                       </Col>
                                       <Col xs="1" className="px-2">
                                           <Button className="font-weight-normal px-2 py-0" color="Light"><i className="mr-3 r-text--sm fi flaticon-eye" /></Button>
                                           {data.views}
                                       </Col>
                                       <Col xs="1" className="px-2">
                                           <Button className="font-weight-normal px-2 py-0" color="Light"><i className="mr-3 r-text--sm fi flaticon-like" /></Button>
                                           {data.likes}
                                       </Col>
                                       <Col xs="1" className="px-2">
                                           <Button className="font-weight-normal px-2 py-0" color="Light">Share</Button>
                                           {data.share}
                                       </Col>
                                       <Col xs="1" className="px-2">
                                           <Button onClick={this.toggle} className="font-weight-normal px-2 py-0" color="Light">Open</Button>
                                       </Col>
                                       <Col xs="1" className="px-2">
                                           <Button onClick={(e) => this.deletepost(data)} className="font-weight-normal px-2 py-0" color="Light"><i className="mr-3 r-text--sm fi flaticon-bin" /></Button>
                                       </Col>
                              </Row>
                           </Card>
                  */}
                        <Row className="">
                          <Col>
                             <FeedPost files={files} data={post} style={{border:"none"}} postType=" "  role_type={this.props.role_type} redirect={this.props.redirect} likePost={this.props.likePost} addStModal={this.addStModal} filterStudents={this.filterStudents} />
                          </Col>
                          {/* {
                            /*(data.post_id)?( 
                            <Col xs="4" className="align-items-center" style={{backgroundColor:"#0066CC"}}>
                                 <Col xs={{ size: '5', offset: 4 }} className="py-3" style={{top:'40%', backgroundColor:"#1A4169", textAlign:"center",color:"#fff"}}>
                                    Slide to accept post
                                    <div className="my-3" style={{marginLeft:"40%",}}><Switch  isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)} /></div>
                                    5 days ago
                                  </Col>
                                </Col>
                            
                          } */}
                          {this.state.isOpenPostDetail ? 
                          <PTFeedDetails toggle={this.toggle}  data={post} deletepost={this.confirmDeleteUser}/>
                            : "" 
                          }
                        <Button style={{position:"absolute", right:"-15px"}} onClick={this.togglePostDetail} className="mt-4"><img width="15" src={ReportIcon}></img></Button>

                        </Row>
                  <Confirmation isOpen={isConfirmation} toggleConfirmation={this.toggleConfirmation} callback={() => this.deletepost(this.state.deleteConfirmationId)} />
                  <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
                  <Modal size="xl" zIndex="99999" isOpen={addStudentModal} toggle={() => this.setState({ addStudentModal: false })}>
                    <ModalBody>
                      <h2 className="r-subheading-1 text-center">Filter Students to add</h2>
                      <p className="r-heading-4"><span className="r-text--green-1">{selectedCount} students </span> selected</p>
                      <FilterWithProcess filterStudents={this.filterStudents} />
                      <div className="text-center mt-4">
                        <Button type="button" color="dark" onClick={this.postPublish} className="r-btn--no-radius">Post</Button>
                      </div>
                    </ModalBody>
                  </Modal>
          </Aux>
  )
 }
}


const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoading: (show) => dispatch(setLoading(show)),
})
export default PostCard;
