import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
// import { ImageDrop } from 'quill-image-drop-module';

let AlignStyle = Quill.import('attributors/style/align')
let BackgroundStyle = Quill.import('attributors/style/background')
let ColorStyle = Quill.import('attributors/style/color')
let DirectionStyle = Quill.import('attributors/style/direction')
let FontStyle = Quill.import('attributors/style/font')
let SizeStyle = Quill.import('attributors/style/size')    

Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(DirectionStyle, true);
Quill.register(FontStyle, true);
Quill.register(SizeStyle, true);

// Quill.register('modules/imageDrop', ImageDrop);



// const BaseImage = Quill.import('formats/image');

// const ATTRIBUTES = [
//   'alt',
//   'height',
//   'width',
//   'style'
// ];

// const WHITE_STYLE = ['margin', 'display', 'float'];

// class Image extends BaseImage {
//   static formats(domNode) {
//     return ATTRIBUTES.reduce(function(formats, attribute) {
//       if (domNode.hasAttribute(attribute)) {
//         formats[attribute] = domNode.getAttribute(attribute);
//       }
//       return formats;
//     }, {});
//   }
  
//   format(name, value) {
//     if (ATTRIBUTES.indexOf(name) > -1) {
//       if (value) {
//         if (name === 'style') {
//           value = this.sanitize_style(value);
//         }
//         this.domNode.setAttribute(name, value);
//       } else {
//         this.domNode.removeAttribute(name);
//       }
//     } else {
//       super.format(name, value);
//     }
//   }

//   sanitize_style(style) {
//     let style_arr = style.split(";")
//     let allow_style = "";
//     style_arr.forEach((v, i) => {
//       if (WHITE_STYLE.indexOf(v.trim().split(":")[0]) !== -1) {
//         allow_style += v + ";"
//       }
//     })
//     return allow_style;
//   }
// }

// export default Image;


// Quill.register('formats/image', Image);




let Parchment = Quill.import("parchment");
let lineHtStyle = new Parchment.Attributor.Style("letterspacing", "letter-spacing", {
  scope: Parchment.Scope.BLOCK,
  whitelist: ["-0.5px", "0", "0.5px"],
});
Parchment.register(lineHtStyle);
export class RTE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: {
        toolbar: [["bold", "italic", "underline"], [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }], ["link"], [{ 'list': 'bullet' }]],
        // imageDrop: true,
        clipboard: {
          matchers: [
            []
          ]
        },
      },
      formats: [
        "font",
        "size",
        "header",
        "bold",
        "italic",
        "underline",
        "align",
        "link",
        "background",
        "group",
        "image",
        "indent",
        "color",
        "spacing",
        "list",
        "code",
        "width",
        "height",
        "style",
        "code-block",
      ]
    };
  }
  render() {
    return (
      <ReactQuill
        preserveWhitespace
        className={"r-rte" + (this.props.hideToolbar ? " r-rte-toolbar-hide" : "")}
        {...this.state}
        {...this.props}
        ref={this.props.inputRef}
      />
    );
  }
}
