import React, { Component } from 'react';

export class Stars extends Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 5
    }
  }


  render() {
    let { count } = this.state,
      { proficiency, edit } = this.props,
      stars = []
    for (let i = 0; i < count; i++) {
      let editProps = {}
      if (edit) {
        editProps.onClick = () => this.props.selectStars(i + 1)
      }
      stars.push(<span key={i} {...editProps} className={"fi flaticon-star mx-1" + (i < proficiency ? " active" : "")}></span>)
    }
    return (
      <div className={"r-stars" + (edit?" r-stars--edit":"")+" "+(this.props.className || "")}>
        {
          stars.map(star => star)
        }
      </div>
    )
  }
}