import React from 'react';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import { StudentRoutes } from './student';
import {VerifierRoutes} from "./verifier";
import {SpocRoutes} from "./spoc";
import {Ptteam} from "./ptteam";
import {OfferRoutes} from './offer';
import Offer from '../views/ptTeam/offer';

import Calendar from '../views/calendar/calendar';
import Event from '../views/calendar/event';
import Meeting from '../views/calendar/meeting';
import { CONSTANTS } from '../../constants';
import {RecultaAdminRoutes} from './reculta_admin';
import Aux from '../global/AuxComp';
import LcmModule from '../views/lcm';

export const Routes = (props) => {
    return (
        <Switch>
            {/* Components added for dev only */}

            <Route exact path={"/student/lcm"} component={LcmModule} />
            {renderRoleRoutes(props.role)}

        </Switch>
    )
}
const renderRoleRoutes = (role) => {
    let RoleRoutes = null
    switch(role.role_type) {
        case "STUDENT": RoleRoutes = <StudentRoutes/>
        break;
        case "VERIFIER": RoleRoutes = <VerifierRoutes/>
        break;
        case "SPOC" : RoleRoutes = <SpocRoutes />
        break;
        case "PT" : RoleRoutes = <Ptteam permission={role.hasPermission}/>
        break;
        case  "ADMIN" : RoleRoutes = <RecultaAdminRoutes />
        break;
        default : RoleRoutes = null
    }
    return RoleRoutes
}
