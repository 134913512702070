import React, { Component } from 'react';
import { Select } from '../../global/Forms/Select'
import { Row, Col, Container, Table, Modal, ModalBody } from 'reactstrap';
import SpocNav from './SpocNav';
import companyAll from './data/companyALL.json'
import { Auth } from '../../../actions/Auth';
import Axios from 'axios';
import moment from 'moment';
import store from '../../../store';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import { Loader } from '../../global/Loader';
import { CONSTANTS } from '../../../constants';
import { getFullName } from '../../../actions';
import PaginationComponent from '../../global/PaginationComponent.js';
import { InputField } from '../../global/Forms';
import Button from '../../global/Button';
import { PermissionDenied } from '../../global/PermissionDenied';


class AttendanceReport extends Component {
    constructor(props) {
      super(props);
      this.state = {
        nav: {process_name: "Attendance Report", process_type: "attendance-report", process_id: 6 },
        processList: [],
        collegeList:[],
        currentPage: 0,
        rowsPerPage: 10,
        campusList:[],
        query: "",
        selectedCollege:'',
        selectedCampus:'',
        selectedProcess:'',
        attendanceList:[],
        attendanceColumns:[{ id: "sno", title: "Roll No" }, { id: "company_name", title: "Student name" }, { id: "eligible", title: "No. of events invited" }, { id: "applied", title: "No. of events attended" },  { id: "initial_shortlist", title: "Attendance %" }],

      };
    }


    componentDidMount () {
         this.init()
    }


    init = async () => {
      let redux = store.getState(),
        { instituteDetail } = redux.role;
      let params = {}
      if (instituteDetail.campus_id) {
        try {
          let processResp = await Auth.Processes.getProcesses(instituteDetail.campus_id)
          this.setState({
            selectedCampus:instituteDetail.campus_id,
            processList: processResp.data
          })
          if (processResp.data.length > 0)
            params.process_id = processResp.data[0].process_id

          await this.getcompanyReports(params)
        } catch (e) { console.log(e) }
      }
      else {
          this.instiDetail();
      }
    }


    instiDetail = async () =>{
        let campusResTemp, degreeList;
        await Axios.all([Auth.Spoc.getInstitutes()])
        .then(Axios.spread((res1, res2) => {
          campusResTemp = res1;
        }));
        let detailsParams = new URLSearchParams();
        campusResTemp.data.forEach((item, i)=>{
          detailsParams.append("college_id", item.college_id);
          detailsParams.append("campus_id", item.campus_id);
        })
        let details = await Auth.Spoc.getInstituteDetails(detailsParams);
        this.setState({
          campusList:details.data.campusDetails,
          collegeList:details.data.collegeDetails,
          selectedCollege:details.data.campusDetails[0].college_id,
        })
    }


  changeCollege = (target) => {
    this.setState({
      selectedCollege: target.value,
      selectedCampus:"",
      selectedProcess:'',})
  }

  changeCampus = async (target) =>{
    this.setState({selectedCampus: target.value, selectedProcess:""});
    if(target.value === ""){
      this.setState({processList: []})
      return
    }
    this.setState({isLoading: true});
      try {
        let params = {}
        let processResp = await Auth.Processes.getProcesses(target.value)
        this.setState({
          processList: processResp.data
        })
        if (processResp.data.length > 0)
          params.process_id = processResp.data[0].process_id
        await this.getcompanyReports(params)
      } catch (e) { console.log(e) }
    this.setState({isLoading: false});
  }

  getcompanyReports = async (params) => {
    this.setState({ isLoading: true, selectedProcess:params.process_id });
         let body = {
           process_id: params.process_id
         }
        try {
          let res = await Auth.Reports.attendanceReportView(params.process_id);
          this.setState({attendanceList:res.data})
          this.setState({isLoading: false});
        } catch (e) {
            this.setState({isLoading: false});
            console.log(e)
        }
  }

  onSearch = (target) => {
    this.setState({ query: target.value });
    if (target.value !== "") {
      this.setState({ currentPage: 0 });
    }
  }

  downloadFile = async() => {
       this.setState({isLoading: true});
      try {
        let res = await Auth.Reports.attendancereportDownload(this.state.selectedProcess);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = 'Attendance Report'+'.csv';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({isLoading: false});
      } catch (e) {
          this.setState({isLoading: false});
          console.log(e)
      }
  }



  handleSelected = (selectedPage) => {
    this.setState({ currentPage: selectedPage - 1 });
  }
  
    render(){
      let permissions = store.getState().role.hasPermission;
    if (store.getState().role.role_type === "PT" && !(permissions.reportAttendance && permissions.reportAttendance.permission_access && permissions.reportAttendance.permission_access.read)) {
      return (
        <>
        <SpocNav process={this.state.nav} downloadReport={this.downloadFile} type="atendanceReport"/>
        <PermissionDenied/>
        </>
      )
    }
      let { nav, collegeList, campusList,isLoading, selectedCampus,selectedCollege,selectedProcess,currentPage,query, rowsPerPage, processList,attendanceColumns, attendanceList} = this.state;
        const redux = store.getState();
        let campusDataList = campusList.filter(campus =>{
          return campus.college_id == selectedCollege;
      });
      attendanceList = attendanceList.filter(row => {
        let name =   getFullName(row) || "";
        return name.toLowerCase().includes(query.toLowerCase())
      })
          return (
              <Container className="apply">
                    <Loader show={isLoading} />
                        <SpocNav process={nav} downloadReport={this.downloadFile} type="atendanceReport" />
                        {(redux.role.instituteDetail.campus_id > 0 && processList.length === 0) ? <p>No processes added</p> : ""}
                    <Row className="mx-0 my-3 justify-content-between">
                    {collegeList.length > 0 &&
                        <Col xs="3">
                          <Select onChange={({ target }) => this.changeCollege(target)}>
                            {collegeList.map((college, i) =>
                              <option key={i} value={college.college_id}>{college.name}</option>
                            )}
                          </Select>
                        </Col>
                    }
                    {campusDataList.length > 0 &&
                        <Col xs="3">
                          <Select value={selectedCampus} onChange={({ target }) => this.changeCampus(target)}>
                              <option value="">Select Campus</option>
                              {campusDataList.map((campus, i) =>
                                <option key={i} value={campus.campus_id}>{campus.name}</option>
                              )}
                          </Select>
                        </Col>
                    }
                    {processList.length > 0 &&
                        <Col xs="3">
                          <Select value={selectedProcess} onChange={({ target }) => this.getcompanyReports({ process_id: target.value })}>
                            {processList.map((process, i) =>
                              <option key={i} value={process.process_id}>{process.process_name}</option>
                            )}
                          </Select>
                        </Col>
                    }
                 <Col xs={3} className="pl-2">
                  <div>
                  <InputField onChange={(e) => {this.onSearch(e.target)}} className=" r-radius--3 pr-5 ml-4" style={{width: '246px'}} placeholder="Search for Student" />
                  <i style={{ marginLeft: '-30px' }} className="pt-2 r-text--grey-4 fi flaticon-search"></i>
                  </div>
                </Col>
                    </Row>
                    <Row className="justify-content-between align-items-center mx-0">
                        <span className="r-heading-3">Attendance Report</span>
                    </Row>
                      <Table className="post">
                        <thead>
                          <tr className="tableheader">
                            {attendanceColumns.map((col, i) =>
                              <th key={i}>
                                {col.title}
                              </th>

                            )}
                          </tr>
                        </thead>
                        <tbody>
                          { (attendanceList.length>0 )&&
                            attendanceList.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map((row, i) => {
                              return (
                                <tr
                                  className="borderBottomlightblack"
                                  key={i}>
                                  <td >
                                    {row.roll_no}
                                  </td>
                                  <td>
                                    { getFullName(row) || "name not available"}
                                  </td>
                                  <td >
                                    {row.countOfInvited}
                                  </td>
                                <td >
                                    {row.countOfAttended}
                                </td>
                                <td >
                                    {((row.countOfInvited===0)?0:(row.countOfAttended*100)/row.countOfInvited).toFixed(2)}
                                </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                      <Row className="justify-content-between align-items-center mx-0" >
                        <Col xs="auto" className="">
                          <Row className="justify-content-between align-items-center">
                            <span className="px-1 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>Total students are {attendanceList.length}</span>
                            <span className="px-3 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>View</span>
                            <div className="r-dropdown border-0">
                              <select value={rowsPerPage} name="rowsPerPage" className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} onChange={(e) => { this.setState({ [e.target.name]: e.target.value, currentPage: 0 }) }}>
                                <option value="10" >10</option>
                                <option value="15">15</option>
                                <option value="20" >20</option>
                                <option value="25">25</option>
                                <option value="50" >50</option>
                                <option value="100">100</option>
                              </select>
                              <i className="fi flaticon-arrow" />
                            </div>
                          </Row>
                        </Col>
                        <Col xs="auto">
                          <PaginationComponent
                            totalItems={attendanceList.length}
                            pageSize={rowsPerPage}
                            onSelect={this.handleSelected}
                            maxPaginationNumbers={3}
                            activePage={currentPage + 1}
                          />
                        </Col>
                      </Row>
            </Container>
          )
    }
}
export default AttendanceReport;
