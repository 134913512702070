
import React,{Component} from 'react';
import { Collapse } from 'reactstrap';
import {  CardBody, CardTitle, CardText} from 'reactstrap';
import {Col,Row} from 'reactstrap';
import { connect } from 'react-redux';
import Aux from '../../global/AuxComp';
import {StatusButton} from './verifier_status_button';
import moment from 'moment';

class PositionOfResponsibilityCollapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
      };
    this.section = Math.ceil(Math.random() * 10000)
    this.popoverName = "POP-" + this.section
  }

  toggle = () => {
    this.props.toggleSection(this.section)
    this.props.getfileId(this.props.positionOfResponsibility.file);
  }

  render() {
    let positionOfResponsibility = this.props.positionOfResponsibility;
    let allCommentThread= this.props.getAllCommentThread!=null?(this.props.getAllCommentThread.filter(commentThread=>
       commentThread.profile_type=="POR" && commentThread.profile_id==positionOfResponsibility.id && commentThread.student_id==positionOfResponsibility.student_id &&commentThread.verifier_id==this.props.role.assigned_role_id)):null;
    return (
      <Aux>
      <CardBody onClick={this.toggle}   className={"verifier__card-body  "+(this.section==this.props.collapse?"active":"")}>
      <CardTitle>
      <Row>
      <Col xs={10}>
      <span className="r-heading-6 mr-3">
      {positionOfResponsibility.designation_name ||  "Designation Name"}</span>
      <span>{"("+moment(positionOfResponsibility.start_date).format("MMM")+" "+moment(positionOfResponsibility.start_date).year()+" - "}{positionOfResponsibility.end_date?moment(positionOfResponsibility.end_date).format("MMM")+" "+moment(positionOfResponsibility.end_date).year(): "Currently Working"}{")"} </span>
      </Col>
      {positionOfResponsibility.file!=0? <Col xs={2} align="end">
          <span title="Proof Attached"><img src="/images/paper-clip@2x.png" alt="Proof" className="w-25" /></span>
      </Col> : null}
      </Row>
      </CardTitle>
      <CardText>
      <Row>
      <Col xs="auto">
      <span>{positionOfResponsibility.society}
      </span>
      </Col>
      </Row>
      <Row>
      <Col xs="auto" className="r-subheading-3">
      <p>{positionOfResponsibility.college_name!=null? positionOfResponsibility.college_name : ""}
      </p>
      </Col>
      </Row>
      </CardText>
      </CardBody>
      <Collapse  className="r-bg--blue-1" isOpen={this.props.collapse==this.section}>

      <StatusButton allCommentThread={allCommentThread} getStudentDetails={this.props.getStudentDetails}  popoverName={this.popoverName} data={positionOfResponsibility} student_id={positionOfResponsibility.student_id} profile_type="position_of_responsibility" id={positionOfResponsibility.id} profileType="POR" />
      </Collapse>
      </Aux>
    );
  }
}

 const mapStateToProps = state => ({
   ...state
 })
export default connect(mapStateToProps, null)(PositionOfResponsibilityCollapse)
// export default PositionOfResponsibilityCollapse;
