import React, { Component } from 'react'
import { connect } from 'react-redux';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import Spinner from 'reactstrap/lib/Spinner';
import { SelectList, Select } from '../../../global/Forms/Select';
import Table from 'reactstrap/lib/Table';
import { Errorbar } from '../../../global/errorbar';
import { Auth } from '../../../../actions/Auth';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import FilterComponent from '../../../global/filter-component';
import PaginationComponent from '../../../global/PaginationComponent.js';
import { getErrorMessage, getFullName, uploadFile } from '../../../../actions';
import store from '../../../../store';
import { setError, setLoading } from '../../../../actions/action-creators';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/information.svg';
import UncontrolledPopover from 'reactstrap/lib/UncontrolledPopover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

class UnblockStudentsModal extends Component {
  state = {
    init: true,
    currentPage: 0,
    rowsPerPage: 10,
    selectAll:1,
    isLoading: false,
    checkall: true,
    students: [],
    processStudent: [],
    inlineLoader: false,
    blocklist: [],
    batchList: {},
    errorshow:false,
    message:'',
    depList: {},
    groupList:{},
    studentFilters: {
      batches: [],
      departments: [],
      groups:[],
      query: ""
    }
  }
  async componentWillMount() {
    let { processStudent } = this.props;
    this.setState({ isLoading: true })
    try {
      let responseBatch = await Auth.Batch.getBatchDetail();
      this.setState({
        processStudent,
        batchList: responseBatch.data.reduce((prev, current) => { prev[current.batch_id] = current; return prev; }, {})
      })
    } catch (e) { console.log(e) }
    if(this.props.selectedStudentId){
      this.setState({blocklist : processStudent.filter(pStudent => this.props.selectedStudentId.indexOf(pStudent.student_id) === -1).map(pStudent => pStudent.student_id)});
    }
    this.setState({ isLoading: false, init: false })
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleAddStudent = async (e) => {
    let {processStudent,blocklist,studentFilters}= this.state;
    e.preventDefault();

    
    let students = processStudent;
    if (studentFilters.batches.length > 0)
      students = students.filter(st => studentFilters.batches.indexOf(st.batch_id.toString()) > -1)
    if (studentFilters.departments.length > 0)
      students = students.filter(st => studentFilters.departments.indexOf(st.batch_id + "-" + st.degree_department_id) > -1)
    if (studentFilters.groups.length > 0)
      students = students.filter(st => studentFilters.groups.indexOf(st.batch_id + "-" + st.degree_department_id + "-" + st.group_id) > -1)
    let totalSt = students.filter(st => {
        if ([...blocklist].indexOf(st.student_id) < 0)
          return true;
      }).map(st=>st.student_id);
      
    if(this.props.onEditApplicants){
      this.props.onEditApplicants(totalSt);
      return;
    }
    
    if (totalSt.length<1 && !this.props.calendar) {
      this.setState({errorshow:true, message:"Please select students to publish application"})
    }
    if(totalSt.length<1 && this.props.calendar){

    }
    let eventStudentList =[];
    if(this.props.getStudentList){
      eventStudentList = students.filter(st => {
          if ([...blocklist].indexOf(st.student_id) < 0)
            return true;
        }).map(st=>st);
      this.props.getStudentList(eventStudentList);
    }
    if(!this.props.calendar){
      this.props.publishApplication(totalSt, e.target.generatePost.checked);
    }
  }

  onStudentChange = (e) => {
    let { blocklist } = this.state,
      checked = e.target.checked,
      value = parseInt(e.target.value);
    if (checked)
      blocklist.splice(blocklist.indexOf(value), 1)
    else
      blocklist.push(value)
    this.setState({ blocklist })
  }



  filterStudents = (studentFilters, batchList,depList,groupList) =>{
        this.setState({batchList:batchList,blocklist: [], depList:depList, groupList:groupList ,currentPage:0});
        this.setState(prevState => ({
            studentFilters: {
              ...prevState.studentFilters,
              batches: studentFilters.batches,
              departments: studentFilters.departments,
              groups: studentFilters.groups,
            }
        }))
  }


  onSearch = (e) => {
    let { studentFilters } = this.state
    if (e.target.value !== "") {
      this.setState({ currentPage: 0 });
    }
    studentFilters.query = e.target.value
    this.setState({ studentFilters })
  }

  handleSelected = (selectedPage) => {
    this.setState({ currentPage: selectedPage - 1 });
  }


  closeError = () => {
    let { errorshow } = this.state;
    errorshow = false;
    this.setState({ errorshow })
  }


  toggleSelectAll(type) {
    let { currentPage, rowsPerPage,roundId, selectAll,processStudent,studentFilters ,blocklist} = this.state;

    let students = processStudent;
    if (studentFilters.batches.length > 0)
      students = students.filter(st => studentFilters.batches.indexOf(st.batch_id.toString()) > -1)
    if (studentFilters.departments.length > 0)
      students = students.filter(st => studentFilters.departments.indexOf(st.batch_id + "-" + st.degree_department_id) > -1)
    if (studentFilters.groups.length > 0)
      students = students.filter(st => studentFilters.groups.indexOf(st.batch_id + "-" + st.degree_department_id + "-" + st.group_id) > -1)
    
    if(type === "ALL"){
      if (blocklist.length !== 0) blocklist=[];
      else{
        students.forEach(x => {
          blocklist.push(x.student_id);
        });
      }
    }
    if(type === "PLACED"){
      blocklist = students.filter(s => s.status !== "Placed").map(s => s.student_id);
    }
    if(type === "UNPLACED"){
      blocklist = students.filter(s => s.status !== "Unplaced").map(s => s.student_id);
    }
    this.setState({blocklist});
    // this.setState({blocklist,
    //   selectAll: selectAll === 0 ? 1 : 0
    // });

  }

  selectStudentByExcel = async (event) => {
    event.preventDefault();
    store.dispatch(setLoading(true));
    let students = this.state.processStudent;
    try {
      let file = await uploadFile(event.target.files[0], `application/select_student/`)
      if (file.file_url) {
        
        let resp = await Auth.General.getStudentIdExcel({file_key: file.file_url});
        var blockedIds = students.filter(o => !resp.data.includes(o.student_id)).map(o => o.student_id);
        console.log(students, blockedIds, resp.data)
        this.setState({blocklist: blockedIds})
        
      } else {
        throw new Error("Error uploading file. Please try again.")
      }

    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  } 

  render() {
    let { init,selectAll,uploadedFile, batchList, depList,groupList, studentFilters, blocklist, processStudent, currentPage, rowsPerPage, inlineLoader,errorshow, message } = this.state;
    let { addStudentModal, toggle } = this.props;
    let students = processStudent;
    if (studentFilters.batches.length > 0)
      students = students.filter(st => studentFilters.batches.indexOf(st.batch_id.toString()) > -1)
    if (studentFilters.departments.length > 0)
      students = students.filter(st => studentFilters.departments.indexOf(st.batch_id + "-" + st.degree_department_id) > -1)
    if (studentFilters.groups.length > 0)
      students = students.filter(st => studentFilters.groups.indexOf(st.batch_id + "-" + st.degree_department_id + "-" + st.group_id) > -1)
    students = students.filter(st => {
      let name = getFullName(st)
      if (name.toLowerCase().indexOf(studentFilters.query.toLowerCase()) > -1)
        return st;
    })
    var placedStudentId = students.filter(s => s.status === "Placed").map(s => s.student_id);
    var unPlacedStudentId = students.filter(s => s.status === "Unplaced").map(s => s.student_id);
    var selectedStudentId = students.filter(s => blocklist.indexOf(s.student_id) === -1).map(s => s.student_id);
    var selectedState = null;
    if(selectedStudentId.length){
      if(students.length === selectedStudentId.length)
        selectedState = "ALL";
      else if(selectedStudentId.every(r=> placedStudentId.includes(parseInt(r))) && selectedStudentId.length === placedStudentId.length)
        selectedState = "PLACED";
      else if(selectedStudentId.every(r=> unPlacedStudentId.includes(parseInt(r))) && selectedStudentId.length === unPlacedStudentId.length)
        selectedState = "UNPLACED";
      }
      students = students.sort((a, b) => {
        return blocklist.indexOf(b.student_id) < 0 ? 1 : -1
      })
    return (
      <Modal size="xl" zIndex="99999" isOpen={addStudentModal} toggle={toggle}>
        {!init && <ModalBody>
          {!this.props.calendar && <p className="r-heading-2">You are going to publish application for <span className="r-text--green-1">{students.length - [...blocklist].length} students </span></p>}
          <div className="my-3">
            <FilterComponent allStudents={processStudent} filterStudents={this.filterStudents}/>
          </div>
          <Row noGutters className="justify-content-end">
            <Col xs="4">
              <InputField className="w-100" placeholder="Search Name" name="search" value={studentFilters.query} onChange={this.onSearch} />
            </Col>
          </Row>
          <form onSubmit={this.handleAddStudent}>
            <Table className="processes__table__2 mt-4">
              <thead>
                <tr>
                  <th>
                      {/* <input type="checkbox"
                        checked={selectAll === 1}
                        name="selectAll"
                        ref={input => {
                          if (input) {
                            input.indeterminate = selectAll === 2;
                          }
                        }}
                        onChange={() => this.toggleSelectAll()} /> */}

                      <h3 className="r-heading-6">Select
                        <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                            <i className=" r-text--xl fi flaticon-upload r-text--grey-4 ml-3" style={{ fontSize: "16px" }} />
                          <input id="file-input" onChange={(e) =>this.selectStudentByExcel(e)} type="file" style={{ display: "none" }} />
                        </label> 
                        <Button id="PopoverLegacy-upload" type="button">
                          <InfoIcon style={{height: 10}} />
                        </Button>
                        <UncontrolledPopover className="zIndex--max" trigger="focus" placement="bottom" target="PopoverLegacy-upload">
                            <PopoverBody>We can select students by Excel Upload, Excel should have roll_no/email columns</PopoverBody>
                        </UncontrolledPopover>                     
                      </h3>

                      <div style={{fontSize: 9}}>
                        <Button type="button" color={selectedState ==="PLACED" ? "blue-3" : "blue"} className="px-2 py-1" style={{borderRadius : 10}} onClick={() => this.toggleSelectAll("PLACED")}>Placed</Button>
                        <Button type="button" color={selectedState ==="ALL" ? "blue-3" : "blue"} className="px-2 py-1 mx-2" style={{borderRadius : 10}} onClick={() => this.toggleSelectAll("ALL")}>All</Button>
                        <Button type="button" color={selectedState ==="UNPLACED" ? "blue-3" : "blue"} className="px-2 py-1" style={{borderRadius : 10}} onClick={() => this.toggleSelectAll("UNPLACED")}>Unplaced</Button>
                      </div>
                  </th>
                  <th>Student</th>
                  <th>Roll</th>
                  <th>Batch</th>
                  <th>Department</th>
                  <th>Group</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  students ? students.slice(parseInt(currentPage) * parseInt(rowsPerPage), (parseInt(currentPage) * parseInt(rowsPerPage)) + parseInt(rowsPerPage)).map((st, i) => {
                    return (
                      <tr key={i}>
                        <td><input onChange={this.onStudentChange} name="student_id" value={st.student_id} type="checkbox" checked={blocklist.indexOf(st.student_id) < 0} /></td>
                        <td>{getFullName(st)}</td>
                        <td>{st.roll_no || "-"}</td>
                        <td>{st.batch_name}</td>
                        <td>{st.department_name || "-"}</td>
                        <td>{st.group_name || "-"}</td>
                        <td>{st.status || "-"}</td>
                      </tr>
                    )
                  }) : <tr><td colSpan={5}>No Students in this batch</td></tr>
                }
              </tbody>
            </Table>
            <Row className="justify-content-between align-items-center  mx-0 px-0">
              <Col xs="auto" className="">
                <Row className="justify-content-between align-items-center">
                  <span className="px-3 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>View</span>
                  <div className="r-dropdown border-0">
                    <select value={rowsPerPage} name="rowsPerPage" className="r-dropdown__select " style={{ backgroundColor: "#dee2e6" }} onChange={(e) => { this.setState({ [e.target.name]: e.target.value, currentPage: 0 }) }}>
                      <option value="10" >10</option>
                      <option value="15">15</option>
                      <option value="20" >20</option>
                      <option value="25">25</option>
                      <option value="50" >50</option>
                      <option value="100">100</option>
                    </select>
                    <i className="fi flaticon-arrow" />
                  </div>
                </Row>
              </Col>
              <Col xs="auto">
                <PaginationComponent
                  totalItems={students.length}
                  pageSize={rowsPerPage}
                  onSelect={this.handleSelected}
                  maxPaginationNumbers={3}
                  activePage={currentPage + 1}
                />
              </Col>
            </Row>
            <div className="text-right">
              {inlineLoader && <Spinner color="dark" />}
              {
                this.props.publishApplication && 
                <span className="mr-3"><input name="generatePost" defaultChecked={true} type="checkbox" className="mr-2" />Auto generate Feed</span>
              }
              <Button className="r-btn--no-radius" color="dark">Save</Button>
            </div>
          </form>
        </ModalBody>}
        <Errorbar showBar={errorshow} message={message} onClose={this.closeError} />
      </Modal>
    )
  }
}
const mapStateToProps = state => ({
  ...state
})

export const UnblockStudents = connect(mapStateToProps, null)(UnblockStudentsModal)
