import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Tooltip from 'reactstrap/lib/Tooltip';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import UncontrolledPopover from 'reactstrap/lib/UncontrolledPopover';
import Button from '../../../../global/Button';
import { ApplicationStatus } from './application-status';
import Aux from '../../../../global/AuxComp';
import moment from 'moment';
import { Errorbar } from '../../../../global/errorbar';
import { CONSTANTS } from '../../../../../constants';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/information.svg'
import { ENDPOINTS } from '../../../../../constants/endpoints';
import { Auth } from '../../../../../actions/Auth';
export class Application extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tooltip: "",
      eligibilityModal: false,
      errorbar: false,
      message: ''
    }
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  toggleTooltip = (target) => {
    this.setState({
      tooltip: target !== this.state.tooltip ? target : ""
    })
  }

  toggleEligibility = () => {
    this.setState({
      eligibilityModal: !this.state.eligibilityModal
    })
  }

  generateTooltip = (target, date) => {
    return (
      <Tooltip placement="bottom" isOpen={this.state.tooltip === target} target={target} toggle={() => this.toggleTooltip(target)}>
        {moment(date).format("hh:mm a, Do MMM YYYY")}
      </Tooltip>
    )
  }

  downloadDocs = async(file) => {
    let fileResp = await Auth.User.getFileUrl(file.file_url)
    const link = document.createElement('a');
    link.href = fileResp.data[file.file_url];
    link.setAttribute("target", "_blank");
    link.setAttribute('download', `${file.file_name}`);
    document.body.appendChild(link);
    link.click();
    link.remove()
  }

  render() {
    let { application, view, files, isFirstrole, isMultirole, multipleLocation } = this.props,
      { company, company_profile } = application,
      jdDoc = [], pptDoc = [], otherDoc = [],
      initialShortlisting = application.shortlisting_data.length ? application.shortlisting_data[0].locked_status : null

    application.application_docs.forEach(doc => {
      if (doc.doc_type === "JD" && files[doc.file_path]) jdDoc.push(files[doc.file_path][0])
      if (doc.doc_type === "PPT" && files[doc.file_path]) pptDoc.push(files[doc.file_path][0])
      if (doc.doc_type === "Others" && files[doc.file_path]) otherDoc.push(files[doc.file_path][0])
    })
    let classificationList = application.classifications.map((data) => data.name);
    return (
      <Aux>
        <Col xs={3}>
          {!isMultirole && <Row>
            <Col xs={12}>
              <span className="r-heading-4 r-text--brandA">{company.company_name || "Company Name"}</span>
              {application.application_binder && application.application_binder[0] &&
                <Aux>
                  <span title="Multirole Instructions" id={`binder-${application.application_binder_id}`} className="apply__multirole-info"><InfoIcon /></span>
                  <UncontrolledPopover fade={false} trigger="legacy" target={`binder-${application.application_binder_id}`}>
                    <Container className="py-3 r-text--sm">
                      <Row>
                        <Col xs={12}>
                          <h4 className="r-heading-5"> Multirole Instructions</h4>
                          <div>
                            <p>{application.application_binder[0].resume_restriction ? "You are restricted to use same resume for applying to all roles." : "You can use different resumes to apply for different roles."}</p>
                            {application.application_binder[0].is_preference && <p>You have to select a preference while appying for each role.</p>}
                            {application.application_binder[0].apply_limit && <p>You can only apply to {application.application_binder[0].apply_limit} roles.</p>}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </UncontrolledPopover>
                </Aux>
              }
            </Col>
            <Col xs={12}>
              <span className="r-subheading-4 r-text--grey-4">{classificationList.join(' | ')}</span>
            </Col>
            <Col xs={12}>
              <span className="r-subheading-3">{multipleLocation ? "Multiple Locations" : application.location}</span>
            </Col>

          </Row>}
        </Col>
        <Col xs={3}>
          <span className="d-block r-subheading-3">{company_profile.role_name || "Product Manager"}</span>
          <span className="d-block r-subheading-4">{application.designation}</span>
          {!!application.offer_amount_min && <span className="r-text--sm">
            {application.offer_currency} {application.offer_amount_min ? application.offer_amount_min.toLocaleString("en-IN") : ""}
            {application.offer_input_type === "range" ? (" - " + (application.offer_amount_max ? application.offer_amount_max.toLocaleString("en-IN") : "")) : ""}
            {application.offer_amount_type === "M" ? " PM" : " PA"}
          </span>}
          {
            !!application.stipend_value && 
            <span className="r-text--sm">
            {application.offer_currency} {application.stipend_value.toLocaleString("en-IN") + " PM"}
            </span>
          }
          {multipleLocation && <span className="d-block r-text--sm">{application.location}</span>}
        </Col>
        <Col xs={6}>
          <Row className="apply__jobs__profile align-items-center">

            {view === "Status View" ? <Aux>
              <Col xs={3}>
                <Modal zIndex={99999} isOpen={this.state.eligibilityModal} toggle={this.toggleEligibility} className="modal-lg">
                  <ModalBody>
                    <h2 className="r-heading-7">Eligibility Criteria</h2>
                    {application.eligibility_status === 0 && <p className="r-text--brandF">You're not Eligible due to the below highlighted reasons</p>}
                    <Row className="mx-0 r-bg--blue-2 border-bottom">
                      <Col xs={4}>Eligibility Rule</Col>
                      <Col xs={4}>Range</Col>
                      <Col xs={4}>Criteria</Col>
                    </Row>
                    {
                      application.application_eligibility.map(criteria => {
                        criteria.value_type = (criteria.value_type || "").trim();
                        return (
                          <Row key={criteria.application_eligibility_id} className={`mx-0 border-bottom ${application.uneligible_data.indexOf(criteria.type) > -1 ? "r-bg--hightlight-error" : ""}`}>
                            <Col xs={4}>{CONSTANTS.ELIGIBILITY_CRITERIA_TYPES[criteria.type] ? CONSTANTS.ELIGIBILITY_CRITERIA_TYPES[criteria.type].label : criteria.type}</Col>
                            <Col xs={4}>{CONSTANTS.ELIGIBILITY_OPERATOR[criteria.operator]}</Col>
                            <Col xs={4}>{criteria.operator == 'range' ? criteria.value.split(",")[0] + " - " + criteria.value.split(",")[1] : ( ["GRAD_SCORE", "PG_SCORE"].indexOf(criteria.type) === -1 ? (criteria.value_type || criteria.value) : criteria.value )}</Col>
                          </Row>
                        )
                      })
                    }
                  </ModalBody>
                </Modal>
                <div className="d-flex flex-column text-center">
                  {application.application_eligibility.length > 0 && <Button color="grey" className="r-text--sm px-2 mb-2" onClick={this.toggleEligibility}> Eligibility Criteria </Button>}
                  {jdDoc.map( (doc) => <Button onClick={()=>this.downloadDocs(doc)} color="grey" className="r-text--dark r-text--sm px-2 mb-2">Job Description</Button> )}
                  {pptDoc.map( (doc) => <Button onClick={()=>this.downloadDocs(doc)} color="grey" className="r-text--dark r-text--sm px-2 mb-2">PPT</Button> )}
                  {otherDoc.map((doc) => <Button onClick={()=>this.downloadDocs(doc)} color="grey" className="r-text--dark r-text--sm px-2 mb-2">Other Document</Button> )}
                </div>
              </Col>
              <Col xs={9}>
                <ApplicationStatus studentDetails={this.props.studentDetails} application={application} />
              </Col>
            </Aux> :
              <Col xs={12}>
                <div className="apply__performance">
                  <div className={"apply__performance__step " + (application.student_application_status === 2 ? "apply__performance__step--done" : "")}>
                    <span>Applied</span>
                    <span className="apply__performance__track">
                      <Button id={`applied-${application.application_id}`}></Button>
                      {/* {application.student_application_status === 2 && this.generateTooltip(`applied-${application.application_id}`, moment())} */}
                    </span>
                  </div>
                  <div className={"apply__performance__step " + (initialShortlisting === 1 ? "apply__performance__step--done" : "")}>
                    <span>Shortlisted</span>
                    <span className="apply__performance__track">
                      <Button id={`shortlist-${application.application_id}`}></Button>
                      {application.shortlisting_data.length > 0 && this.generateTooltip(`shortlist-${application.application_id}`, moment(application.shortlisting_data[0].locked_on))}
                    </span>

                  </div>
                  {application.application_round.map((round, i) => {
                    return (
                      <div key={i} className={"text-truncate apply__performance__step " + ((application.shortlisting_data[i + 1] && application.shortlisting_data[i + 1].locked_status === 1) ? "apply__performance__step--done" : "")
                      }>
                        <span title={round.round_name}>{round.round_name}</span>
                        <span className="apply__performance__track">
                          <Button id={`round-${application.application_id}-${i}`}></Button>
                          {(application.shortlisting_data[i + 1] && application.shortlisting_data[i + 1].locked_on) && this.generateTooltip("round-" + application.application_id + "-" + i, application.shortlisting_data[i + 1].locked_on)}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </Col>}
          </Row>
        </Col>
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
      </Aux>
    )
  }
}
