import React,{Component} from 'react';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container} from 'reactstrap';
import {Button, ButtonGroup, Collapse, ModalHeader} from 'reactstrap';
import { Loader } from '../../global/Loader';
import  {InputField} from '../../global/Forms';
import {Errorbar} from '../../global/errorbar';
import AdminOverview from './admin_overview';
import {CONSTANTS} from '../../../constants';

class RecultaAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

  getInitialState = ()=>{
    return(
      {
        loading : false,
        openUniverstyCollapse : false,
        universityName : null,
        spocMail : null,
        spocName : null,
        universityState : null,
        universityCity : null,
        universityZipcode : null,
        universityAddress : null,
        memberList : [],
        universityList  : []
      }
    )
  }

  cancelForm = ()=>{
    document.getElementById("createUniversityForm").reset();
    this.setState({
      openUniverstyCollapse : false,
      universityName : null,
      spocMail : null,
      spocName : null,
      universityState : null,
      universityCity : null,
      universityZipcode : null,
      universityAddress : null
    })
  }

  createUniversitySpoc = async()=>{
    this.setState({loading : true});
    let params = {
      "name" : this.state.universityName,
      "city" : this.state.universityCity,
      "state" : this.state.universityState,
      "address" : this.state.universityAddress,
      "zip_code" : this.state.universityZipcode
    }
    try{
      let universityResp = await Auth.University.createUniversity(params);
      let accessKeyParams = {
        "university_id" : universityResp.data.insertId
      }
      try{
        let accessKeyResp = await Auth.Spoc.fetchAccessKey(accessKeyParams);
        await this.createRole(accessKeyResp.data[0].access_key);
        await this.getUniversityList();
      }
  catch(e){
    console.log(e);
  }
  }
  catch(e){
    console.log(e);
  }
  this.setState({loading : false});
}

createRole = async(accessKey)=>{
  let roleParams = {
    "role_name": "Spoc",
    "access_key": accessKey,
    "role_type": 2,
    "description": "Spoc"
  }
  try{
    let roleResp = await Auth.Spoc.addRole(roleParams);
    this.createUser(roleResp.data[0].role_id , accessKey);
  }
  catch(e){
    console.log(e);
  }
}

createUser = async(roleId , accessKey)=>{
  let userParams ={
    "first_name" :this.state.spocName,
    "email": this.state.spocMail
  }
  try{
    let userResp = await Auth.User.signUp(userParams);
    this.userRoleMapping(userResp.data[0].id, roleId ,accessKey)
  }
  catch(e){
    console.log(e);
  }
}

userRoleMapping  = async(userId , roleId, accessKey)=>{
  let userRoleMappingParams = {
    "user_id": userId,
    "role_id": roleId,
    "role_level": 1,
    "access_key": accessKey,
    "access_level": 1
  }
  try{
    await Auth.Spoc.createUserRoleMapping(userRoleMappingParams);
  }
  catch(e){
    console.log(e);
  }
}

  submitForm = async(e)=>{
    e.preventDefault();
    let {spocMail , spocName, universityName , universityCity , universityState , universityZipcode, universityAddress} = e.target
    await this.setState({
      spocName : spocName.value,
      spocMail : spocMail.value,
      universityName : universityName.value,
      universityCity : universityCity.value,
      universityState : universityState.value,
      universityZipcode : universityZipcode.value,
      universityAddress : universityAddress.value
    })
    await this.createUniversitySpoc();
    document.getElementById("createUniversityForm").reset();
    this.cancelForm();
  }

  getUniversityList = async()=>{
    try{
      let universityList = await Auth.University.getUniversity();
      this.setState({universityList : universityList.data})

      // if(universityList.data.length!=0){
      //   for(let i =0 ; i<universityList.data.length ; i++){
      //     try{
      //       let accessKeyParams = {
      //         "university_id" : universityList.data[i].university_id,
      //         "campus_id" : 0,
      //         "college_id" : 0
      //       }
      //       try{
      //         let accessKeyResp = await Auth.Spoc.fetchAccessKey(accessKeyParams);
      //         // console.log("accessKeyResp", accessKeyResp.data);
      //         try{
      //           console.log("accessKeyResp", accessKeyResp);
      //           let roleParams = {
      //             "access_key" : accessKeyResp.data[0].access_key
      //           }
      //           let getRoleResp = await Auth.User.getRoleList(roleParams);
      //           console.log("getRoleResp",getRoleResp.data);
      //         }
      //         catch(e){
      //           console.log(e);
      //         }
      //       }
      //       catch(e){
      //         console.log(e);
      //       }
      //     }
      //     catch(e){
      //       console.log(e);
      //     }
      //   }
      // }
      // console.log(universityList.data);
    }
    catch(e){
      console.log(e);
    }
  }

  async componentDidMount(){
    await this.getUniversityList();
  }

  render(){
    return(
      <Container>
      <Loader show={this.state.loading}/>
      <div className="m-3">
        <Row>
          <Col xs="2" className="mt-3">
            <span className="r-subheading-1">Overview</span>
          </Col>
          <Col xs="auto">
            <button className="r-text--sm mt-4 r-btn r-btn--brandB" color="brandB" onClick={()=>this.setState({openUniverstyCollapse : !this.state.openUniverstyCollapse})}><i className="fi flaticon-plus px-2"/> Add New University</button>
          </Col>
        </Row>
      </div>
      <Collapse isOpen={this.state.openUniverstyCollapse} className="border p-3 m-3" >
      <form onSubmit={this.submitForm} id="createUniversityForm" >
        <Row>
          <Col xs="6" className="border-right">
            <Row>
              <Col className="m-3">
                <span className="modal-title r-heading-6">Step 1 - Enter University Details</span>
              </Col>
            </Row>
              <Row>
                <Col>
                  <InputField placeholder="Name" name="universityName" type="text"  className="w-100 border-0 m-2" required={true} />
                </Col>
                <Col xs="6">
                  <InputField placeholder="State" name="universityState" type="text" className="w-100 border-0 m-2"    />
                </Col>
                <Col xs="6">
                  <InputField placeholder="City" name="universityCity" type="text"   className="w-100 border-0 m-2"   />
                </Col>
                <Col xs="6">
                  <InputField placeholder="Address" name="universityAddress" type="text" className="w-100 border-0 m-2"  />
                </Col>
                <Col xs="6">
                  <InputField placeholder="ZipCode" name="universityZipcode" type="text" pattern="[0-9]{6}" className="w-100 border-0 m-2" />
                </Col>
              </Row>
          </Col>
          <Col xs="6">
            <Row>
              <Col className="m-3">
                <span className="modal-title r-heading-6">Step 2 - Enter Spoc Details</span>
              </Col>
            </Row>
            <Row className="m-3">
              <Col xs="6">
                <InputField placeholder="Name" name="spocName" type="text" className="w-100 border-0 m-2"   required />
              </Col>
              <Col xs="6">
                <InputField placeholder="Email" name="spocMail" type="email"   className="w-100 border-0 m-2"  required />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col align="end">
            <Button color="dark" className="mt-2"> Save </Button>
            <Button className="r-subheading-4" type="button" className="r-bg--light r-text--dark mt-2 ml-2" onClick={()=>this.cancelForm()}>Cancel</Button>
          </Col>
        </Row>
      </form>
      </Collapse>
      {this.state.universityList &&  <AdminOverview  universityList={this.state.universityList} getUniversityList={this.getUniversityList}/> }
      </Container>
    )
  }
}
export default RecultaAdmin;
