import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import { connect } from 'react-redux';
import { Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { Loader } from '../../../global/Loader';
//import { SelectList } from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import { Select } from '../../../global/Forms/Select';
import Aux from '../../../global/AuxComp';
import Button from '../../../global/Button';
import store from '../../../../store';
import { InputField } from '../../../global/Forms';
import { CompanyCard } from './company-card';
import { CompanyInfo } from './company-info';
import AutosuggestComp from '../../../global/AutosuggestComp';
import PaginationComponent from '../../../global/PaginationComponent.js';
import Axios from 'axios';
import {Errorbar} from '../../../global/errorbar';
import {CONSTANTS} from '../../../../constants';
import Link from 'react-router-dom/Link';
import HelpSection from "../../helpSection/student_section";
import { ENDPOINTS } from '../../../../constants/endpoints';
import {getFullName, uploadFile} from "../../../../actions"
import Badge from 'reactstrap/lib/Badge';
import ModalHeader from 'reactstrap/lib/ModalHeader';
class Companies extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

   createCompany = async (e) => {
     e.preventDefault()
     let { createCompany , companyAddress } = this.state;
     var body = {
       access_key : 3,
       company_id : createCompany.company_id
     }
     if(companyAddress){
       body.company_address = companyAddress;
     }
     this.setState({
       isLoading: true
     })
     try {
       if(!createCompany.company_id) {
          var companyBody = {
            company_name : createCompany.company,
            sector_id : createCompany.sector_id,
            company_website: createCompany.website
          }
          if(companyAddress){
            companyBody.company_address = companyAddress;
          }
          let resp = await Auth.PtCompanies.createBaseCompany(companyBody);
          body.company_id = resp.data.insertId;
       }
       let resp = await Auth.PtCompanies.mapCampusCompany(body);
       await this.createCompanyClassificationMapping(resp.data.insertId);
       await this.init();
       this.setState({
         suggestionValue: { company : '' },
         isOpenAddModal : false,
         createCompany : null,
         isLoading: false,
         classificationList : []
       })
     } catch (e) {
       if(e.response.data.error.toString() !== null)
         this.setError(true, e.response.data.error.toString())
       else
         this.setError(true, 'Sorry! something went wrong. Please try again')
     }
     await this.init();
     this.setState({
       isLoading: false,
     })
   }
   createCompanyByCsv = async() => {
     let { companyCsv } = this.state;
     if(!companyCsv) this.setError(true, 'You have to select CSV file to proceed');
     this.setState({
      isLoading: true
    })
     try{
      let fileResp = await uploadFile(companyCsv, "company_upload");
      var body = {
        key : fileResp.file_url
      }
      await Auth.PtCompanies.uploadExcel(body)
      await this.init();
      this.toggleState('isOpenAddModal');
     }
     catch (e) {
      if(e.response.data.error.toString() !== null)
        this.setError(true, e.response.data.error.toString())
      else
        this.setError(true, 'Sorry! something went wrong. Please try again')
    }
    this.setState({
      isLoading: false
    })
     
   }
  handleFieldChange = (e, setValue) => {
    let { suggestionValue, createCompany } = this.state;
      if(setValue){
        suggestionValue[e.target.name] = setValue.newValue;
        createCompany[e.target.name] = setValue.newValue;
        if(e.target.name === 'company' && setValue.method === 'type')
          createCompany.company_id = null
      }
      else{
        createCompany[e.target.name] = e.target.value;
      }
      this.setState({suggestionValue, createCompany})
  };

  setValuefromSuggestion = (value, name) => {
    let { suggestionValue, createCompany } = this.state;
    suggestionValue[name] = value[name + "_name"]
    createCompany[name + "_id"] = value[name + "_id"];
    createCompany[name] = value[name + "_name"];
    createCompany.website = value.company_website;
    this.setState({suggestionValue, createCompany})
    return value[name + "_name"];
  }

  getInitialState = () => {
    return {
      isLoading : false,
      currentPage: 0,
      rowsPerPage: 10,
      searchText : '',
      isMultipleSelect : false,
      selectedCards : [],
      isOpenAddModal : false,
      isExcelImport : false,
      companiesList : [],
      sectorList : [],
      processList : [],
      isOpenMulDeleteModal : false,
      companyInfo : null,
      createCompany : {},
      isOpenAddProcess : false,
      priority : 0,
      process: '',
      classificationDetails : null,
      addClassificationInstruction : false,
      classificationList : {},
      suggestions: {
        company: [],
      },
      suggestionValue: {
        company : '',
      },
      showError:false,
      errorMsg : '',
      companyAddress : '',
      companyCsv : null,
      ptList : [],
      isOpenUploadContact : false,
      reminderList : [],
      isOpenReminders: false,
      filterCompany : {}
    }
  }
  setError =(isShow, msg) => {
    this.setState({showError : isShow, errorMsg : msg});
  }
  componentDidMount() {
    let {hasPermission} = this.props.role
    if(!hasPermission.companiesTab || (hasPermission.companiesTab && !hasPermission.companiesTab.permission_access.read)) {
      this.props.history.push("/pt/processes")
      return
    }
    this.init(this.props.match.params.campus_company_id);


  }
  componentWillReceiveProps(nextProps) {
    if(this.props.match.params.campus_company_id!==nextProps.match.params.campus_company_id)
    this.init(nextProps.match.params.campus_company_id)
  }

  getClassfication =  async ()=>{
    let classificationDetails  = await Auth.classification.listClassificationDetails();
    this.setState({
      classificationDetails : classificationDetails.data
    })
  }

  createCompanyClassificationMapping =async  (insertId)=>{
    let {createCompany,classificationList} = this.state;
    let list =[];
    if(Object.keys(classificationList).length!=0){
      Object.keys(classificationList).map(classification=>
        list.push(classificationList[classification]))
        try{
          let params = {
            campus_company_id : insertId,
            classification_id : list
          }
          await  Auth.classification.companyClassificationMapping(params);
        }
        catch(e){
          console.log(e);
        }
      }
    }

   handleClassificaionChange = async(e)=>{
     let {classificationList} = this.state;
     if(e.target.value!==undefined && e.target.value !='add'){
       classificationList[e.target.name] = e.target.value;
       this.setState({
         classificationList
       })
     }
     else if(e.target.value =='add'){
       this.setState({
         isLoading: true
       })
       try{
         var resp = await  Auth.Spoc.getUsers({...this.props.role.instituteDetail,campus_id : 0,  role_type :  CONSTANTS.ROLE_TYPES_ID.SPOC});
         this.spocList = resp.data.map((member) => member.userDetail);
       }
       catch(e){
         this.spocList = [];
        }
        this.setState({addClassificationInstruction : true});
        this.setState({
          isLoading: false
        })
     }
   }

  init = async (campus_company_id) => {
    this.setState({
      isLoading: true
    })
    await this.getClassfication();
    try {
      var value = campus_company_id || null;
      var resp, industriesResp, processResp, ptList;
      await Axios.all([
        Auth.PtCompanies.getCompanies(value), Auth.General.getIndustries(),
        Auth.Processes.getProcesses(this.props.role.instituteDetail.campus_id), 
        Auth.Spoc.getUsers({ role_type: CONSTANTS.ROLE_TYPES_ID.PT, campus_id: this.props.role.instituteDetail.campus_id }) ,
      ])
        .then(Axios.spread(async function (companyResp,industries,process, ptListResp, reminderResp  ) {
          // companyResp.data = companyResp.data.reduce((prev, current) => {
          //   current.company_details.sector_details = {sector_name:""}
          //   current.pt_details = {};
          //   ptListResp.data.some((pt)=> {
          //     if(pt.user_id === current.created_by){
          //       current.pt_details = pt.userDetail
          //       return true;
          //     }
          //   })
          //   prev.push(current)
          //   return prev;
          // }, [])
          resp = companyResp;
          industriesResp = industries;
          processResp = process;
          ptList = ptListResp.data;
        }));
        await this.setState({
          ptList,
          companiesList: resp.data,
          sectorList : [],
          processList : processResp.data,
        })
      this.fetchTodaysReminders();
      if(campus_company_id){
        var companyInfo = resp.data.filter(data => data.campus_company_id == campus_company_id);
        if(companyInfo[0])
        {
          this.setState({companyInfo : companyInfo[0]});
        }
      }
      else{
        this.setState({companyInfo : null});
      }
    } catch (e) {
      if(e.response.data && e.response.data.error)
        this.setError(true, e.response.data.error.toString())
      else
        this.setError(true, 'Sorry! something went wrong. Please try again')
    }
    
    this.setState({isLoading: false});
    // this.appendRecruiterInfo();
  }

  fetchTodaysReminders = async() => {
    let {companiesList} = this.state;
    try{
      var resp = await Auth.Batch.fetchTodayMeetingReminders();
      var reminderList = resp.data;
      if(reminderList.length){
        var meetingResp = await Auth.Batch.getMeetings({meeting_id: reminderList.map(o => o.meeting_id)});
        companiesList = companiesList.reduce((prev, curr) => { prev[curr.campus_company_id] = curr; return prev;}, {});
        meetingResp = meetingResp.data.reduce((prev, curr) => { prev[curr.meeting_id] = curr; return prev;}, {});
        reminderList = reminderList.map(reminder => {
          reminder.meetingData = meetingResp[reminder.meeting_id] || {};
          reminder.companyData = companiesList[reminder.campus_company_id] || {};
          return reminder;
        })
      }
      this.setState({reminderList});
    }
    catch(e){

    }
  }
  markSeenReminder = async(reminder) => {
    this.setState({isLoading: true});
    try{
      await Auth.Batch.updateMeetingReminder({meeting_remainder_id: reminder.meeting_remainder_id, is_visited: 1});
      await this.fetchTodaysReminders();
    }
    catch(e){

    }
    this.setState({isLoading: false});
  }

  // appendRecruiterInfo = async () => {
    // let { companiesList } = this.state;
    // let recruiterResp = await Auth.Recruiter.fetchInviteRecruiter({company_id : companiesList.map(o => o.company_id).join(",")})
    // let recruiterList = recruiterResp.data.reduce((prev, current) => {prev[current.company_id] = current; return prev;}, {})
    // companiesList = companiesList.map(company => {
    //   company.recruiter = recruiterList[company.company_id] || null;
    //   return company;
    // })
    // this.setState({companiesList})
  // }

  deleteCompany = async (campus_company_id) => {
    this.setState({
      isLoading: true,
      isOpenMulDeleteModal : false,
      isMultipleSelect : false,
      selectedCards : []
    })
    try {
      var data={
        access_key : 3,
        status :0,
        campus_company_id : campus_company_id
      }
      let resp = await Auth.PtCompanies.inactiveCompanies(data);
    } catch (e) { console.log(e) }
    await this.init();
    this.setState({
      isLoading: false
    })
  }
  hitMapToProcess = async (e) => {
    this.setState({
      isOpenAddProcess : false,
      isMultipleSelect : false,
      selectedCards : []
    })
    var process_id = e.target.closest('.modal').querySelector("[name='process']").value;
    if(this.state.companyInfo !== null){
      var campus_company_id = this.state.companyInfo.campus_company_id;
    }
    else if(this.state.isMultipleSelect){
      var campus_company_id = this.state.selectedCards;
    }
    await this.mapToProcess(process_id, campus_company_id);
  }
  mapToProcess = async (process_id, campus_company_id) => {
    if(!process_id){
      this.setError(true,'Placement Drive should not be empty');
      return false;
    }
    this.setState({
      isLoading: true,
    })
    try {
      var data={
        process_id :process_id,
        campus_company_id : campus_company_id
      }
      let resp = await Auth.Processes.mapCompaniesToProcess(data);
      this.props.history.push("/pt/processes/"+process_id+"/detail");
    } catch (e) {
      if(e.response.data.error.toString() !== null)
        this.setError(true, e.response.data.error.toString())
      else
        this.setError(true, 'Sorry! something went wrong. Please try again')
     }
     this.setState({isLoading : false});
  }
  setCompanyInfo = (value) => {
    this.setState({companyInfo : value});

  }
  selectCard = (index) => {
    var selectedCards = this.state.selectedCards;
    if(selectedCards.indexOf(index) > -1)
      selectedCards.splice(selectedCards.indexOf(index), 1);
    else
        selectedCards.push(index);
    this.setState({selectedCards});
  }

  toggleState = async(state) =>{
    if(state == 'isMultipleSelect'){
      this.setState({ selectedCards : []})
    }
    if(state == 'isOpenAddModal'){
      this.setState({ createCompany : {} , classificationList : []})
    }
    if(state == 'isOpenMulDeleteModal' && !this.state.isOpenMulDeleteModal){
      try {
         this.mappedProcess = await Auth.PtCompanies.getCompaniesInProcess(this.state.selectedCards)
         this.mappedProcess = this.mappedProcess.data.reduce((prev, current) => {
           current.count = prev[current.campus_company_id] ? prev[current.campus_company_id].count+1 : 1;
           prev[current.campus_company_id] = current;
           return prev;
         }, [])
      }catch (e){
        console.log(e);
      }
    }
    this.setState({ [state] : !this.state[state] })

  }
  toggleParentState =(state, value) =>{
    this.setState({[state] : value});
  }
  openAddProcess =() =>{
    this.setState({isOpenAddProcess : true});
  }
  updatePriority = (index) => {
    this.setState({priority : index});
  }
  toggleLoading =(value) => {
      this.setState({isLoading : value});
  }


  onChange = e => {
    if(e.target.value==="sttoDrive"){
     this.setState({addProcessModal: true})
    }
    else {this.setState({
            [e.target.name]: e.target.value,
        })}
  }

    addProcess = async (e) => {
      e.preventDefault();
      let { process_name, process_type } = e.target;
      let formData = {
        process_name: process_name.value,
        process_type: process_type.value,
        campus_id: store.getState().role.instituteDetail.campus_id
      }
      Auth.Processes.addProcess(formData).then(resp => {
        if (resp.data[0]) {
          let { processList } = this.state;
          processList.push(resp.data[0])
          this.setState({
            processList,
            addProcessModal: false,
            applications: [],
            companies: [],
            processStudent : []
          })
          this.setState({addProcessModal: false})
        }
      }).catch(e => {
        console.log(e)
      })
    }


  handleSelected = (selectedPage) => {
    this.setState({ currentPage: selectedPage - 1 });
  }

  handleDataChange = (campus_company_id, updateData) => {
    let { companiesList } = this.state;
    companiesList = companiesList.map(company => {return company.campus_company_id == campus_company_id ? {...company, ...updateData} : company});
    this.setState({companiesList});
  }

  uploadContacts = async (e) => {
    e.preventDefault();
    var file = e.target.csv_file.files[0];
    this.setState({ isLoading: true })
    try{
     let fileResp = await uploadFile(file, "company_upload");
     var body = {
       key : fileResp.file_url
     }
     var resp = await Auth.PtCompanies.uploadContacts(body);
     await this.init();
     if(typeof resp.data !== "object"){
      this.setState({message : "Data uploaded successfully. Few records failed, Please check downloaded CSV"})
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      let fileName = `Upload contacts error.csv`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
     this.toggleState('isOpenUploadContact');
    }
    catch (e) {
     if(e.response.data.error.toString() !== null) this.setError(true, e.response.data.error.toString())
     else  this.setError(true, 'Sorry! something went wrong. Please try again')
   }
   this.setState({ isLoading: false })
  }

  render() {
    let {currentPage, rowsPerPage, isLoading, isMultipleSelect, selectedCards, isOpenAddModal, isExcelImport, companiesList, sectorList, processList, companyInfo, classificationDetails} = this.state;
    let { suggestions, suggestionValue, searchText, addProcessModal , companyAddress, ptList, reminderList, filterCompany } = this.state;
    
    let hasPermission = this.props.role.hasPermission;
    let isShowOnlyAssignedCompany = hasPermission.showOnlyAssignedCompany && hasPermission.showOnlyAssignedCompany && hasPermission.showOnlyAssignedCompany.permission_access.read;
    if (isShowOnlyAssignedCompany) {
      companiesList = companiesList.filter(company => company.user_id === this.props.user.id);
    }
    companiesList = companiesList.filter(company => {
      if(filterCompany.pt_member_id && filterCompany.pt_member_id != company.user_id) return false;
      if(filterCompany.classification_id && company.classifications.findIndex(x => x.classification_id == filterCompany.classification_id) === -1) return false;
      return true;
    })

    if(searchText !== '' || true){
      companiesList = companiesList.filter(data => {
        var companyName = data.company_details.company_name || '';
        var sectorName = data.company_details.sector_details ? data.company_details.sector_details.sector_name || '' : '';
        return companyName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 || sectorName.toLowerCase().indexOf(searchText.toLowerCase())  !== -1
      })
    }
    let cardProps = {
      isMultipleSelect, selectedCards, sectorList, processList,
      deleteCompany : this.deleteCompany,
      selectCard : this.selectCard,
      mapToProcess : this.mapToProcess,
      setCompanyInfo : this.setCompanyInfo,
      history : this.props.history,
      toggleParentState : this.toggleParentState,
      toggleLoading : this.toggleLoading,
      refreshParent : this.init,
      classificationDetails,
      ptList,
      handleDataChange : this.handleDataChange,
      appendRecruiterInfo : this.appendRecruiterInfo
    }
    let infoProps = {
      classificationDetails, processList, companyInfo,
      deleteCompany : this.deleteCompany,
      mapToProcess : this.mapToProcess,
      setCompanyInfo : this.setCompanyInfo,
      openAddProcess : this.openAddProcess,
      history : this.props.history,
      toggleLoading : this.toggleLoading,
      role : this.props.role,
      ptList
    }
    return (
      <Aux>
      <Loader show={isLoading} />
      <Modal className="modal-dialog-centered" isOpen={this.state.isOpenAddProcess} toggle={() => this.toggleState('isOpenAddProcess')} >
        <ModalBody>
            <h1 className="r-subheading-3 border-bottom pb-3">Add to Placement Drive</h1>
            <Row className="mt-4">
              <Col xs={6}>
                <h1 className="r-subheading-4">Select  Placement Drive Name</h1>
                <Select name="process"  onChange={this.onChange}>
                  <option value="">Select Placement Drive</option>
                    <option  value="sttoDrive" style={{color:"blue"}}> + Add Placement Drive</option>
                  {processList.map((child, index) => (<option key={index} value={child.process_id} >{child.process_name}</option>))}
                </Select>
              </Col>
            </Row>
            {/*<Row className="mt-4">
              <Col xs={6}>
                <h1 className="r-subheading-4">Priority</h1>
                { ['','','','',''].map( (child, index) => <span key={index} style={{color : this.state.priority > index ? "#c59708f0":"black"}} onClick={() => this.updatePriority(index+1)} >&#9830;{' '}</span> )}
              </Col>
            </Row>
          */}
            <Button onClick={this.hitMapToProcess} className="float-right mr-3" color="dark r-btn--no-radius">Add to Placement Drive</Button>
        </ModalBody>
        <Modal zIndex="99999" isOpen={addProcessModal} toggle={() => this.setState({ addProcessModal: false})}>
          <ModalBody>
            <span className="modal__title r-heading-6">Add Placement Drive</span>
            <form className="mt-3" onSubmit={this.addProcess}>
              <label className="r-subheading-4">Placement Drive Name</label>
              <InputField defaultValue={""} required name="process_name" className="w-100" type="text" placeholder="Enter Placement Drive Name" />
              <label className="mt-2 r-subheading-4">Placement Drive Type</label>
              <Select name="process_type" defaultValue={""}>
                {
                  Object.keys(CONSTANTS.PROCESS_TYPES).map((processType) =>
                    <option key={processType} value={processType}>{CONSTANTS.PROCESS_TYPES[processType]}</option>
                  )
                }
              </Select>
              <div className="text-right mt-3">
                <Button type="submit" color="dark" className="r-text--sm r-btn--no-radius">Save</Button>
              </div>
            </form>
          </ModalBody>
        </Modal>

      </Modal>
      {
      companyInfo !== null ? <CompanyInfo {...infoProps}  classificationDetails={this.state.classificationDetails && this.state.classificationDetails} getClassfication = {this.init}  />
      :

      <Container>
        <Row className="mt-0 pb-3 border-bottom align-items-center justify-content-between ">
          <Col >
            <div className="align-items-center row">
              <Col xs="auto">
                <div className="heading-dropdown r-heading-1">
                  <div className="r-dropdown d-inline-block align-middle">All Companies</div>
                </div>
              </Col>
              <Col xs="auto">
                  <Button onClick={() => this.toggleState('isOpenAddModal')} color="brandB" className="ml-4 r-text--sm"><i className="fi flaticon-plus r-text--sm mr-2"></i>Company</Button>
              </Col>

              <Col xs="auto">
                <Link to={"/pt/emailCampaign"}><Button  color="brandB" className="ml-4 r-text--sm ml-2">Email Campaign</Button></Link>
              </Col>
              {/* <Col xs="auto">
                <Button onClick={() => this.setState({isOpenUploadContact : true})} color="brandB" className="ml-4 r-text--sm ml-2">Upload Contacts</Button>
              </Col> */}
            </div>
		      </Col>
          <Col xs="auto">
          { !isMultipleSelect ?
            (companiesList.length != 0 ?
               <Button onClick={() => this.toggleState('isMultipleSelect')} color="blue" className=" r-text--sm"><i className="fi flaticon-plus r-text--sm mr-2"></i>Select Multiple</Button> : "")
            :
            <div>
              { isMultipleSelect && <span class="green-selected">{selectedCards.length} Selected</span>}
              <Button onClick={() => this.toggleState('isOpenMulDeleteModal')}>
                <i className="fi flaticon-bin" />
                <span className="ml-2">Delete</span>
              </Button>
              |
              <Button  onClick={this.openAddProcess} >
                <i className="fi flaticon-plus-1 r-text--sm" />
                <span className="ml-2">Add to Placement Drive</span>
              </Button>
              <Button  onClick={() => this.toggleState('isMultipleSelect')} className="mx-1 rotate--45">
                <i className="fi flaticon-plus" />
              </Button>
            </div>
          }
          </Col>
        </Row>
        <Row className="py-3 justify-content-between mb-5">
          <Col xs={4}>
            <div className="text-middle">
              <InputField onChange={(e) => {this.setState({searchText : e.target.value, currentPage: 0})}} className="w-100 r-radius--3 pr-5" placeholder="Search for Companies/Sector" />
              <i style={{ position: 'absolute', marginLeft: '-30px' }} className="pt-2 r-text--grey-4 fi flaticon-search"></i>
            </div>
          </Col>

          <Col xs={6} className="pl-2 text-right">
          <Button color="dark" onClick={() => this.setState({isOpenReminders: true})} className="r-text--sm mr-3">Today's Reminders <Badge color="light ml-2">{reminderList.filter(o => !o.is_visited).length}</Badge></Button>
         <a target="_blank" href={ENDPOINTS.PT_COMPANIES.DOWNLOAD_COMPANY_DATA}> <Button color="dark" className="r-text--sm">Company info<i className="ml-2 fi flaticon-file" /></Button></a>
          
          </Col>

        </Row>
        <Row className="justify-content-end my-2" noGutters>
          {
            classificationDetails && classificationDetails.map(category => 
              <Col xs={"auto"} className="pr-3">
                <div className="r-dropdown  border-0">
                <select className="r-dropdown__select r-subheading-4 r-text--grey-4" value={filterCompany.classification_id || ""} onChange={(e) => {filterCompany.classification_id = e.target.value; this.setState({filterCompany, currentPage: 0});}}>
                  <option value="">All {category.name}</option>
                  {
                    category.classification_list.map(classification => {
                      return <option key={classification.classification_id} value={classification.classification_id}>{classification.name}</option>
                    })
                  }
                </select>
                <i className="fi flaticon-arrow"/>
                </div>
              </Col>
            )
          }
          { !isShowOnlyAssignedCompany &&
          <Col xs={"auto"} className="pr-3">
              <div className="r-dropdown border-0 d-inline-block">
                <select className="r-dropdown__select r-subheading-4 r-text--grey-4" value={filterCompany.pt_member_id || ""} onChange={(e) => {filterCompany.pt_member_id = e.target.value; this.setState({filterCompany, currentPage: 0});}}>
                  <option value="">All Members</option>
                  {
                    ptList.map(pt => {
                      return <option key={pt.id} value={pt.user_id}>{getFullName(pt.userDetail)}{this.props.user.id === pt.user_id && " (me)"}</option>
                    })
                  }
                </select>
                <i className="fi flaticon-arrow"/>
              </div>
             
          </Col>
          }
          <div> <img style={{width: 15}} src="/images/filter-512.png" alt="" /></div>
        </Row>

        { companiesList.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map((child, index) => <CompanyCard companyData={child} index={index} key={index} {...cardProps} campus_id={this.props.role.instituteDetail.campus_id} />)}

        {companiesList.length == 0 && <div>No Company added. Please click on 'Add Company' button</div>}


       <Row className="justify-content-between align-items-center" >
                 <Col xs="auto" className="">
                   <Row className="justify-content-between align-items-center">
                     <span className="px-1 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>Total companies are {companiesList.length}</span>
                     <span className="px-3 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>View</span>
                     <div className="r-dropdown border-0">
                       <select value={rowsPerPage} name="rowsPerPage" className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} onChange={(e) => { this.setState({ [e.target.name]: e.target.value, currentPage: 0 }) }}>
                         <option value="10" >10</option>
                         <option value="15">15</option>
                         <option value="20" >20</option>
                         <option value="25">25</option>
                         <option value="50" >50</option>
                         <option value="100">100</option>
                       </select>
                       <i className="fi flaticon-arrow" />
                     </div>
                   </Row>
                 </Col>
                 <Col xs="auto">
                   <PaginationComponent
                     totalItems={companiesList.length}
                     pageSize={rowsPerPage}
                     onSelect={this.handleSelected}
                     maxPaginationNumbers={3}
                     activePage={currentPage + 1}
                   />
                 </Col>
               </Row>

        <Modal className="modal-dialog-centered modal-lg" isOpen={isOpenAddModal} toggle={() => this.toggleState('isOpenAddModal')} style={{width:'80%'}} >
          <ModalBody>
              <Row>
                <Col xs="auto">
                  <Button type="button"><h1  onClick={() => this.toggleState('isExcelImport')} className={"pb-2 r-subheading-3"+ (!isExcelImport ? " border-bottom":"")}>Add Company</h1></Button>
                </Col>
                <Col xs="auto">
                  <Button type="button"><h1 onClick={() => this.setState({isExcelImport: isExcelImport === "COMPANY" ? false: "COMPANY"})} className={"pb-2  r-subheading-3"+ (isExcelImport === "COMPANY" ? " border-bottom":"")}>Import from Excel</h1></Button>
                </Col>
                <Col xs="auto">
                  <Button type="button"><h1 onClick={() => this.setState({isExcelImport: isExcelImport === "CONTACT" ? false: "CONTACT"})} className={"pb-2  r-subheading-3"+ (isExcelImport === "CONTACT" ? " border-bottom":"")}>Upload Contacts</h1></Button>
                </Col>
              </Row>
              { !isExcelImport ?
                  <Row className="mt-4">
                    <Col xs="4">
                      <h1 className="r-subheading-4">Company Name</h1>
                                        <AutosuggestComp
                                              inputProps={{
                                                className: "w-100 r-inputfield",
                                                onChange: this.handleFieldChange,
                                                value: suggestionValue.company,
                                                name : 'company',
                                                required : true
                                              }}
                                            suggestionID="getCompanies"
                                            renderSuggestion={(comp) => <div>{comp.company_name}</div>}
                                            setValue={(value) => this.setValuefromSuggestion(value, 'company')} />
                    </Col>
                    <Col xs="4">
                      <h1 className="r-subheading-4">Company Website</h1>
                      <InputField  onChange={this.handleFieldChange} name="website" className="w-100" value={this.state.createCompany && this.state.createCompany.website ? this.state.createCompany.website : ''} />
                    </Col>
                    <Col xs="4">
                      <h1 className="r-subheading-4">Company Address</h1>
                      <InputField  onChange={(e)=>this.setState({companyAddress : e.target.value})} name="companyAddress" className="w-100" value={this.state.createCompany &&  Object.keys(this.state.createCompany) && Object.keys(this.state.createCompany).length ? companyAddress : ''} />
                    </Col>
                    {this.state.classificationDetails!=null?
                      this.state.classificationDetails.map(classification=>
                        <Col xs="4" key={classification.name}>
                          <h1 className="r-subheading-4">{classification.name}</h1>
                          <Select  onChange={this.handleClassificaionChange} name={classification.name} >
                            <option value="">{"select "+classification.name}</option>
                            {classification.classification_list && classification.classification_list.map(list =>
                                <option key={list.classification_id} value={list.classification_id} >{list.name}</option>
                              )}
                            <option value="add" >+ Add new</option>
                          </Select>
                        </Col>)
                      :null
                    }
                  </Row>
              :
              <>
                {isExcelImport === "COMPANY" &&
                <Row className="mt-4">
                  <Col>
                    <h1 className="r-subheading-4">Upload CSV File</h1>
                    <input type="file" onChange={(e) => this.setState({companyCsv : e.target.files[0]})} color="grey r-btn--no-radius my-3" />
                    <a href="/Company Upload Sample.xlsx">Format for Reference</a>
                  </Col>
                </Row>
                }
                {isExcelImport === "CONTACT" &&
                  <form onSubmit={this.uploadContacts}>
                      <Row className="mt-5">
                        <Col>
                          <h1 className="r-subheading-4">Upload CSV File</h1>
                          <input type="file" name="csv_file" color="grey r-btn--no-radius my-3" />
                          <a href="https://campus.frontend.s3.ap-south-1.amazonaws.com/Company+Details_Template.xlsx">Format for Reference</a>
                        </Col>
                      </Row>
                  <div className="text-right py-4 mt-3 border-top">
                    <Button type="button" color="outline-dark-v r-text--sm" onClick={() => this.toggleState('isOpenAddModal')}>Cancel</Button>{' '}
                    <Button type="submit" color="dark-v r-text--sm" >Upload</Button>
                  </div>
                </form>}
              </>
            }
          </ModalBody>
          {isExcelImport !== "CONTACT" && 
          <ModalFooter>
            <Button type="button" color="outline-dark-v r-text--sm" onClick={() => this.toggleState('isOpenAddModal')}>Cancel</Button>{' '}
           { !isExcelImport ?
             <Button onClick={this.createCompany} type="submit" color="dark-v r-text--sm" >Add</Button>
            :
             <Button onClick={this.createCompanyByCsv} type="submit" color="dark-v r-text--sm" >Add</Button>
           }
          </ModalFooter>
          }
        </Modal>

        <Modal className="modal-dialog-centered" isOpen={this.state.isOpenUploadContact} toggle={() => this.toggleState('isOpenUploadContact')} >
          <form onSubmit={this.uploadContacts}>
          <ModalBody>
              <h1 className="r-subheading-3 pb-3 border-bottom mb-3">Upload Company Contacts</h1>
              <Row className="mt-5">
                <Col>
                  <h1 className="r-subheading-4">Upload CSV File</h1>
                  <input type="file" name="csv_file" color="grey r-btn--no-radius my-3" />
                  <a href="https://campus.frontend.s3.ap-south-1.amazonaws.com/Company+Details_Template.xlsx">Format for Reference</a>
                </Col>
              </Row>
          </ModalBody>
          <ModalFooter className="mt-5">
            <Button type="button" color="outline-dark-v r-text--sm" onClick={() => this.toggleState('isOpenUploadContact')}>Cancel</Button>{' '}
            <Button type="submit" color="dark-v r-text--sm" >Upload</Button>
          </ModalFooter>
          </form>
        </Modal>
        <Modal className="modal-dialog-centered" isOpen={this.state.isOpenMulDeleteModal} toggle={() => this.toggleState('isOpenMulDeleteModal')} >
          <ModalBody>
              <h1 className="r-subheading-3">Delete</h1>
              {this.mappedProcess ?
                <div className="mt-5 pl-3">
                {
                  this.mappedProcess && this.mappedProcess.map(data => <h1 className="r-subheading-4">{data.company_name} mapped with {data.count} placement drive</h1>
                  )
                }
                </div>
                 : "" }
              <h1 className="r-heading-6 my-5 ml-3">Do you want to delete {selectedCards.length} selected Company(s) ?</h1>
              <Button onClick={() => this.deleteCompany(selectedCards)} className="float-right mr-3" color="dark r-btn--no-radius">Delete</Button>
          </ModalBody>

        </Modal>
        <Modal className="modal-dialog-centered" isOpen={this.state.addClassificationInstruction} toggle={() => this.toggleState('addClassificationInstruction')} >
          <ModalBody>

              <h1 className="r-subheading-3 py-2">Please reach out to your Admin to add new Company Classification. You may contact any of the following Admins.</h1>
              <ul>
              {this.spocList && this.spocList.map((spoc) => <li>{spoc.first_name+' '+spoc.last_name+' ('+spoc.email+')'}</li>
              )}
              </ul>
          </ModalBody>

        </Modal>

        <Modal size="md" centered isOpen={this.state.isOpenReminders} toggle={() => this.toggleState('isOpenReminders')} >
          <ModalHeader>Today's Reminders</ModalHeader>
          <ModalBody className="px-5">
                  {reminderList.length ?
                    reminderList.map(reminder => 
                      <Row className="shadow-sm border border-light py-3 my-3">
                        <Col xs={4}><span className="r-subheading-4">{reminder.companyData.company_details && reminder.companyData.company_details.company_name}</span></Col>
                        <Col xs={5}><p dangerouslySetInnerHTML={{ __html: reminder.meetingData.meeting_description }}></p></Col>
                        <Col xs={3}>
                          {reminder.is_visited ? 
                            <span className="r-text--sm">Seen</span>
                            :
                            <Button color={"blue"} onClick={() => this.markSeenReminder(reminder)} className="r-text--sm">Mark Seen</Button>
                            }
                        </Col>
                      </Row>
                    )
                    :
                    <em className="r-text--sm text-secondary text-center d-block my-3">Use engagement feature and set reminders under interaction notes for timely follow up with recruiters.</em>
                  }
          </ModalBody>
        </Modal>


        <Errorbar showBar={this.state.showError} onClose={() => this.setError(false, "")} message={this.state.errorMsg}/>


      </Container>
    }
    <HelpSection tab={"companiesTab"} />
    </Aux>
    )
  }
}
const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(Companies)
