import React, { Component } from 'react';
import Aux from '../../../../global/AuxComp';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Collapse from 'reactstrap/lib/Collapse';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { ProfileActions } from './profile-actions';
import Button from '../../../../global/Button';
import { Auth } from '../../../../../actions/Auth';
import { CONSTANTS } from '../../../../../constants';
import { InputField } from '../../../../global/Forms';
import { Stars } from '../../../../global/Forms/stars';
import {Errorbar} from '../../../../global/errorbar';

export class Skills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      deleteId: null,
      errorbar: false,
      message:''
    }
  }

  closebar = () =>{
    this.setState({errorbar: false});
  }

  handleSkillChange = (i, sk) => {
    if (i.target)
      sk["skill"] = i.target.value
    else
      sk["proficiency"] = i
    this.props.changeSkill(sk);
  }

  addSkill = (e, type) => {
    this.props.toggleSection("SK-0")
    this.props.changeSkill({ skill_type: type, skill:"", proficiency: 0 });
    this.el = e.target.closest(".student__profile__container");
    setTimeout(() => {
      if (this.el)
        this.el.scrollIntoView({ behavior: "smooth", block: "start" })
    }, 300)
  }

  isValid = (sk) => {
    let { skill, proficiency} = sk;
    if (!skill){
      this.setState({message: 'Please fill all mandatory input fields'});
      this.setState({errorbar: true});
      return false
    }
    this.setState({errorbar: false});
    return true
  }

  handleSkillSubmit = async (e, sk) => {
    e.preventDefault();
    if (!this.isValid(sk)) {
      return
    }
    this.props.toggleLoading(true);
    let method = Auth.Student.updateSkill,
      formData = Object.assign({}, sk);
    if (typeof sk.student_skill_id === "string") {
      method = Auth.Student.createSkill;
      formData.student_id = this.props.user_info.student_id;
      delete formData.student_skill_id
    }
    try {
      await method(formData)
      this.props.getStudentProfile();
      this.props.toggleSection("");

    } catch (e) {

      if(e.response){
        this.setState({message: e.response.data.error.toString()});
        this.setState({errorbar: true});
      } else{
        this.setState({message: 'Sorry! something went wrong. Please try again'});
        this.setState({errorbar: true});
      }
    }
    this.props.toggleLoading(false);
  }

  deleteDetail = async () => {
    let { deleteId } = this.state;
    if (!deleteId) return;
    let formData = {
      student_id: this.props.user_info.student_id,
      student_skill_id: deleteId,
      status: 0
    }
    this.props.toggleLoading(true)
    try {
      if (typeof deleteId === "number")
        await Auth.Student.updateSkill(formData)
      this.props.changeSkill(formData, true)
      this.toggleModal()
      this.props.getStudentProfile()
    } catch (e) {
      if(e.response.data.error.toString() !== null){
        this.setState({message: e.response.data.error.toString()});
        this.setState({errorbar: true});
      } else{
        this.setState({message: 'Sorry! something went wrong. Please try again'});
        this.setState({errorbar: true});
      }
    }
    this.props.toggleLoading(false)
  }

  toggleModal = (id) => {
    this.setState({
      modalShow: !this.state.modalShow,
      deleteId: id
    })
  }

  render() {
    let { editableSection, toggleSection, skill, onCancel } = this.props;
    return (
      <Aux>
        <div className="r-card">
          {
            Object.keys(CONSTANTS.SKILL_TYPES).map(type => {
              return (
                <Row noGutters className="student__profile__container student__profile__container--skill pt-0" key={type}>
                  <Col xs={12}>
                    <h2 className="r-heading-4 r-text--blue-3 mb-4">{CONSTANTS.SKILL_TYPES[type]}</h2>
                    {editableSection === "" && <div className="student__profile__actions">
                      <Button onClick={(e) => this.addSkill(e, type)} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
                    </div>}
                    {
                      skill.map((sk, i) => {
                        if (sk.skill_type === type) {
                          return (
                            <Row noGutters key={sk.student_skill_id}>
                              <Col xs={12}>
                                <div className="student__profile__section">
                                  <Collapse isOpen={editableSection !== `SK-${i}`}>
                                    <Row>
                                      <Col xs={4}>
                                        <p>{sk.skill || "Skill Name"}</p>
                                      </Col>
                                      <Col xs={8}>
                                        <Stars proficiency={sk.proficiency} />
                                      </Col>
                                    </Row>
                                  </Collapse>
                                  <Collapse isOpen={editableSection === `SK-${i}`}>
                                    <form onSubmit={(e) => this.handleSkillSubmit(e, sk)}>
                                      <Row form className="mb-2">
                                        <Col xs={6}>
                                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Skill Name</label>
                                          <InputField className="w-100" type="text" name="skill" value={sk.skill} onChange={(e) => this.handleSkillChange(e, sk)} />
                                        </Col>
                                        <Col xs={6}>
                                          <label className="r-heading-7">Proficiency</label>
                                          <Row noGutters className="align-items-center">
                                            <Col xs="auto">
                                              <Stars proficiency={sk.proficiency} selectStars={(i) => this.handleSkillChange(i, sk)} edit={true} />
                                            </Col>
                                            <Col xs="auto">
                                              <Button onClick={()=>this.handleSkillChange(0, sk)} className="r-text--sm" type="button">Clear</Button>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                      <div className="d-flex justify-content-end mt-3">
                                        <Button type="button" onClick={() => onCancel()} className="r-text--sm">Cancel</Button>
                                        <Button type="submit" className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
                                      </div>
                                    </form>
                                  </Collapse>
                                  {editableSection !== `SK-${i}` && <ProfileActions delete={() => this.toggleModal(sk.student_skill_id)} toggleSection={() => toggleSection(`SK-${i}`)} isRequiredValidate={true} />}
                                </div>
                              </Col>
                            </Row>
                          )
                        }
                      })
                    }
                    {
                      skill.filter(s => s.skill_type === type).length === 0 && <div className="student__profile__section student__profile__section--placeholder">
                        <Row>
                          <Col xs={4}>
                            <p>Skill Name</p>
                          </Col>
                          <Col xs={8}>
                            <Stars proficiency="3" />
                          </Col>
                        </Row>
                      </div>
                    }
                  </Col>
                </Row>
              )
            })
          }
        </div>
        <Modal className="modal--delete" isOpen={this.state.modalShow} toggle={() => this.toggleModal()}>
          <ModalBody>
            <p>Are you sure?</p>
            <div className="text-right">
              <Button className="r-text--sm" onClick={() => this.toggleModal()}>Cancel</Button>
              <Button color="dark" onClick={this.deleteDetail} className="r-text--sm r-btn--no-radius">Delete</Button>
            </div>
          </ModalBody>
        </Modal>
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      </Aux>
    )
  }
}
