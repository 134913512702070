import React,{Component} from 'react';
import { Collapse } from 'reactstrap';
import {  CardBody, CardTitle, CardText} from 'reactstrap';
import {Col,Row} from 'reactstrap';
import Aux from '../../global/AuxComp';
import {StatusButton} from './verifier_status_button';
import moment from 'moment';
import { connect } from 'react-redux';

class AchievementCollapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
      };
    this.section = Math.ceil(Math.random() * 10000)
    this.popoverName = "POP-" + this.section
  }

  toggle = () => {
    this.props.toggleSection(this.section)
    this.props.getfileId(this.props.achievement.file);
  }

  render() {
    let achievement = this.props.achievement;
    let allCommentThread= this.props.getAllCommentThread!=null?(this.props.getAllCommentThread.filter(commentThread=>
       commentThread.profile_type=="ACHIEVEMENTS" && commentThread.profile_id==achievement.achievement_id && commentThread.student_id==achievement.student_id &&commentThread.verifier_id==this.props.role.assigned_role_id)):null;
    return (
      <Aux>
      <CardBody onClick={this.toggle}  className= {"verifier__card-body  "+(this.section==this.props.collapse?"active":"")}>
      <CardTitle>
      <Row>
      <Col xs={10}>
      <span className="r-heading-6 mr-3">
      {achievement.name ||  "Name"}</span>
      <span>{achievement.achievement_type=="TEST_SCORE" || achievement.achievement_type=="SCHOLARSHIP" || achievement.achievement_type=="COMPETITION" ?("("+ moment(achievement.year).year()+")")  : ("("+moment(achievement.year).format("MMM")+" "+moment(achievement.year).year()+")") }  </span>
      </Col>
      {achievement.file!=0? <Col xs={2} align="end">
          <span title="Proof Attached"><img src="/images/paper-clip@2x.png" alt="Proof" className="w-25" /></span>
      </Col> : null}
      </Row>
      </CardTitle>
      <CardText>
      <Row>
      <Col xs="auto">
      <span>{achievement.organisation || "Organization"}
      </span> {/*| <span>{achievement.score || "Score"}</span>*/}
      </Col>
      </Row>

      </CardText>
      </CardBody>
      <Collapse  className="r-bg--blue-1" isOpen={this.props.collapse==this.section}>
      <StatusButton allCommentThread={allCommentThread} student_id={achievement.student_id} id={achievement.achievement_id} profileType="ACHIEVEMENTS" profile_type="achievement" getStudentDetails={this.props.getStudentDetails}  popoverName={this.popoverName} data={achievement}/>
      </Collapse>
      </Aux>
    );
  }
}

 const mapStateToProps = state => ({
   ...state
 })
export default connect(mapStateToProps, null)(AchievementCollapse)
// export default AchievementCollapse;
