import React,{Component} from 'react';
import { Collapse } from 'reactstrap';
import {  CardBody, CardTitle, CardText} from 'reactstrap';
import {Col,Row} from 'reactstrap';
import Aux from '../../global/AuxComp';
import {StatusButton} from './verifier_status_button';
import moment from 'moment';
import { connect } from 'react-redux';

class ProjectCollapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
      };
    this.section = Math.ceil(Math.random() * 10000)
    this.popoverName = "POP-" + this.section
  }

  toggle = () => {
    this.props.toggleSection(this.section)
    this.props.getfileId(this.props.project.file);
  }

  render() {
    let project = this.props.project;
    let allCommentThread= this.props.getAllCommentThread!=null?(this.props.getAllCommentThread.filter(commentThread=>
       commentThread.profile_type=="PROJECT" && commentThread.profile_id==project.project_id && commentThread.student_id==project.student_id &&commentThread.verifier_id== this.props.role.assigned_role_id)):null;
    return (
      <Aux>
      <CardBody onClick={this.toggle}   className={"verifier__card-body  "+(this.section==this.props.collapse?"active":"")}>
      <CardTitle>
      <Row>
      <Col xs={10}>
      <span className="r-heading-6 mr-3">
      {project.project_title ||  "Project Title" }</span>
      <span>{("("+moment(project.start_date).format("MMM")+" "+moment(project.start_date).year()+" - "+moment(project.end_date).format("MMM")+" "+moment(project.end_date).year()+")") ||  "" } </span>
      </Col>
      {project.file!=0? <Col xs={2} align="end">
          <span title="Proof Attached"><img src="/images/paper-clip@2x.png" alt="Proof" className="w-25" /></span>
      </Col> : null}
      </Row>
      </CardTitle>
      <CardText>
      <Row>
      <Col xs="auto">
      <span>{project.sector_name}
      </span>
      </Col>
      </Row>
      <Row>
      <Col xs="auto" className="r-subheading-3">
      <p>{project.company_name!=null? project.company_name : ""}{" ("+project.industry_name+")"}
      </p>
      </Col>
      </Row>
      </CardText>
      </CardBody>
      <Collapse  className="r-bg--blue-1" isOpen={this.props.collapse==this.section}>
      <StatusButton allCommentThread={allCommentThread} student_id={project.student_id} id={project.project_id} profile_type="project" profileType="PROJECT" getStudentDetails={this.props.getStudentDetails}  popoverName={this.popoverName} data={project} />
      </Collapse>
      </Aux>
    );
  }
}

 const mapStateToProps = state => ({
   ...state
 })
export default connect(mapStateToProps, null)(ProjectCollapse);
// export default ProjectCollapse;
