import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import { getFullName, getErrorMessage , sortObjectArray } from '../../../actions';
import { Auth } from '../../../actions/Auth';
import store from '../../../store';
import { setError, setLoading } from '../../../actions/action-creators';
import Button from '../Button';
import Collapse from 'reactstrap/lib/Collapse';
import ChatBox from './chatbox';
export default class Footer extends Component {
  state = {
    connectionList : [],
    isOpenMessageBar : false,
    chatBoxData : null,
    unreadMsgCount : []
  }

  componentDidMount() {
    this.getUnreadMsg();
    this.getConnectionRequest();
    this.countinterval = setInterval(this.getUnreadMsg, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.countinterval);
  }


  setChatBox =(data) => {
    this.setState({chatBoxData : data})
  }

  getConnectionRequest = async () =>{
    let state = store.getState(),
    campus_id = state.role.instituteDetail.campus_id;
    store.dispatch(setLoading(true))
    try {
        let connectionResp = await Auth.Recruiter.Connection.getRequests({ campus_id });
        this.setState ({connectionList : connectionResp.data.data})
      } catch (e) {
        store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
        console.log(e)
      }
      store.dispatch(setLoading(false))
  }

  getUnreadMsg = async () =>{
    try{
      let countResp = await Auth.recruiterConversation.msgCount();
      this.setState ({unreadMsgCount : countResp.data.data})
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    let  { isOpenMessageBar ,  chatBoxData ,connectionList, unreadMsgCount } = this.state;
    connectionList = connectionList.filter(connection => connection.connection_status === 1);
    var tCount = 0;
    connectionList = connectionList.map(connection => {
        var filteredUnread = unreadMsgCount.filter(msg => msg.recruiter_id == connection.recruiter_id);
        var count = filteredUnread.length ? filteredUnread[0].campus_id_count : 0;
        tCount = tCount+count;
        connection.unread_count = count;
        return connection;
    })
    return (
        <div  style={{ position: "fixed", right: 0, bottom: 0, zIndex: 999999}}>
            <Row className="justify-content-end">
            {chatBoxData ? <Col  style={{right: "1.3%", width : 300}}>
               <ChatBox setChatBox={this.setChatBox} connectionData={chatBoxData} />
                </Col>
                 : ""}
                <Col className="align-self-end"  style={{right: "1.3%", width : 300}}>
                    <Button onClick={() => {this.setState({isOpenMessageBar : !isOpenMessageBar})}} square={true} color="blue-3 w-100 r-subheading-3  py-3 text-left">Messaging {tCount ? <div style={{height : 16, width:18 , borderRadius : 10, fontSize: "smaller", fontWeight: 400}} className="bg-danger text-center text-white float-right mr-4 mt-1">{tCount}</div> : " "}</Button>
                    <Collapse isOpen={isOpenMessageBar}>
                        <Row noGutters className="border bg-light">
                            <Col className="">
                            {connectionList.length ? connectionList.map(connection => 
                                    <Row noGutters className="border pl-3 text-left">
                                        <Col className="py-3">
                                            <span style={{fontWeight : 400, cursor:"pointer"}} className="r-subheading-3" onClick={() => this.setChatBox(connection)} color="link">{connection.company_name}</span>
                                           { connection.unread_count > 0 ?
                                           <div style={{height : 16, width:18 , borderRadius : 10, fontSize: "smaller", fontWeight: 400}} className="bg-danger text-center text-white float-right mr-5 mt-1">{connection.unread_count}</div> 
                                          : "" }
                                        </Col>
                                    </Row>
                            ) : 
                            <p className="m-4 text-primary" style={{fontSize: '13px', fontStyle: "italic"}}>You should be connected with Recruiters to start messaging</p>
                            }
                            </Col>
                        </Row>
                    </Collapse>
                </Col>
            </Row>

        </div>
    )
  }
}
