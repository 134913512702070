export const API_CONFIG = {
  BASE_URL: process.env.REACT_APP_BASE_URL || "https://staging.reculta.com",
  RECRUITER_URL:  process.env.REACT_APP_RECRUITER_URL || "https://beta-recruiter.reculta.com",
  BASE_PATH: "/api/v1",
  KUE_PATH: "/kue/job"
}
export const ENDPOINTS = {
  USER: {
    SIGNUP : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/signup`,
    LOGIN: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/login`,
    GET_USER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user`,
    LOGOUT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/logout`,
    RESET_PASSWORD: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/change/password`,
    UPDATE_USER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/profile/update`,
    UPLOAD_FILE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/file/upload`,
    ADD_TO_FILE_MANAGER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/file/create`,
    UPDATE_FILE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/file/update`,
    GET_FILE_URL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/file/signed/url`,
    GET_FILES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/file`,
    GET_NOTIFICATIONS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/notification`,
    UPDATE_NOTIFICATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/notification/history/status/update`,
    GET_ROLE_LIST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/role/list`,
    ADD_VERIFIER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/role/mapping/email/create`,
    FORGOTPASSWORD: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/forgot/password`,
    RETRIEVEPASSWORD: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/retrieve/password`,
    GET_CONFIGURATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/institute/config/list`,
    CREATE_CONFIGURATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/institute/config/create`,
    UPDATE_CONFIGURATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/institute/config/update`,
    DELETE_CONFIGURATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/institute/config/activeInactive`,
    EMAIL_TOKEN_GENERATE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/email/verification/create`,
    EMAIL_TOKEN_VERIFY : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/email/token/verify`,
    DOWNLOAD_FILE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/file/download`
  },
  STUDENT: {
    GET_ID: id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student?access_key=3&user_id=${id}`,
    GET_PROFILE: id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/profile?student_id=${id}`,
    GET_PREFERENCES: id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/preferences?student_id=${id}`,
    CREATE_PERSONAL_DETAIL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/personal/detail/create`,
    UPDATE_PERSONAL_DETAIL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/personal/detail/update`,
    CREATE_ACADEMIC_DETAIL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/academic/detail/create`,
    UPDATE_ACADEMIC_DETAIL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/academic/detail/update`,
    CREATE_PROEXP_DETAIL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/professional/detail/create`,
    UPDATE_PROEXP_DETAIL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/professional/detail/update`,
    CREATE_PROJECT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/project/create`,
    UPDATE_PROJECT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/project/update`,
    CREATE_ACHIEVEMENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/achievements/create`,
    UPDATE_ACHIEVEMENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/achievements/update`,
    CREATE_OTHER_EXP: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/other/experiences/create`,
    UPDATE_OTHER_EXP: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/other/experiences/update`,
    CREATE_SKILL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/skill/create`,
    UPDATE_SKILL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/skill/update`,
    CREATE_PREFERENCE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/preferences/create`,
    UPDATE_PREFERENCE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/preferences/update`,
    CREATE_CV_POINT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/cv/point/create`,
    UPDATE_CV_POINT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/cv/point/update`,
    CREATE_POR: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/position/of/responsibility/create`,
    UPDATE_POR: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/position/of/responsibility/update`,
    CREATE_EXTRA_CURRICULAR: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/extra/curricular/activity/create`,
    UPDATE_EXTRA_CURRICULAR: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/extra/curricular/activity/update`,
    GET_RESUMES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/cv/student/list`,
    GET_RESUME_APPLICATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/cv/application/list`,
    CREATE_RESUME: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/cv/student/create`,
    UPDATE_RESUME: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/cv/student/update`,
    DELETE_RESUME: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/cv/student/activeInactive`,
    PROCESS_LIST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/application/process/list`,
    GET_APPLICATIONS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/application/list`,
    GET_ALL_APPLICATIONS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/application/all`,
    APPLY: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/application/apply`,
    WITHDRAW_APPLICATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/application/withdraw`,
    FREEZE_UNFREEZE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/freezeUnfreeze`,
    DOWNLOAD_RESUME: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/cv/download`,
    CV_TEMPLATES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/cv/template/list`,
    CV_CATEGORY_LIST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/cv/category/list`,
    CV_CATEGORY_CREATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/cv/category/create`,
    CV_CATEGORY_DELETE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/cv/category/inactive`,
    UPLOAD_STUDENT_ACADEMIC: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/academic/detail/upload`,
    UPDATE_PROCESS_INVITATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/application/process/participation/capture`,
    GET_PROCESS_APPROVAL_ORDER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/application/process/participation/payment/order`,
    DOWNLOAD_BACKLOGS_DATA: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/backlog/download`,
    UPLOAD_BACKLOGS_DATA: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/backlog/upload/excel`,
    DOWNLOAD_SEMESTER_SCORE_DATA: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/semester/score/download`,
    UPLOAD_SEMESTER_SCORE_DATA: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/semester/score/upload/excel`,
    DOWNLOAD_PREFERENCES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/preferences/download/excel`,
    ADD_APPLICATION_BLOCK_RULE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/blocked/applications/count/create`,
    FETCH_APPLICATION_BLOCK_RULE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/blocked/applications/count`,
    EDIT_APPLICATION_BLOCK_RULE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/blocked/applications/count/update`,
    DELETE_APPLICATION_BLOCK_RULE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/blocked/applications/delete`,
    FETCH_APPLICATION_BLOCKED: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/blocked/applications`,
    ADD_ANSWER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/application/answer/add`,
    EDIT_ANSWER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/application/answer/edit`,
    DELETE_ANSWER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/application/answer/inactive`,
    GET_BACKLOG_COUNT: id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/backlog/fetch?student_id=${id}`,
  },
  GENERAL: {
    GET_EDUCATION_BOARD: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/education/board/list`,
    ADD_EDUCATION_BOARD: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/education/board/create`,
    GET_DESIGNATIONS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/designation/list`,
    ADD_DESIGNATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/designation/create`,
    GET_SECTORS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/sector/list`,
    ADD_SECTOR: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/sector/create`,
    GET_INDUSTRIES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/industry/list`,
    ADD_INDUSTRY: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/industry/create`,
    GET_DOMAINS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/domain/fetch`,
    ADD_DOMAIN: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/domain/insert`,
    GET_COMPANIES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/company/list`,
    ADD_COMPANY: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/company/create`,
    GET_DEPARTMENTS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/static/department/list`,
    ADD_DEPARTMENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/static/department/create`,
    GET_INSTITUTES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/static/university/college/list`,
    ADD_INSTITUTE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/static/university/college/create`,
    GET_DEGREES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/degree`,
    GET_DEGREES_CAMPUS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/degree/list`,
    GET_DEGREES_CAMPUS_DEPARTMENT: (campusid, degreeId) => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/degree/list?campus_id=${campusid}&${degreeId ? "campus_degree_id=" + degreeId : ""}`,
    ADD_DEGREE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/degree/create`,
    GET_SCHOOLS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/static/school/list`,
    ADD_SCHOOL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/static/school/create`,
    GET_ROLES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/role/list`,
    GET_SPECIALIZATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/specialization/list`,
    GET_STUDENT_ID_EXCEL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/read/excel`

  },
  VERIFIER: {
    GET_PROCESS_LIST: id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/verifier/process?role_id=${id}`,
    GET_STUDENT_ASSIGNED: params => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/verifier/student?${params}`,
    UPDATE_STUDENT_STATUS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/profile/verify`,
    UPDATE_STUDENT_STATUS_ALL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/profile/verify/all`,
    GET_PROFILE: params => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/profile?access_key=3&${params}`,
    FREEZE_STUDENT_SECTION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/profile/freeze/unfreeze`,

  },
  FEED: {
    GET_POSTS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/post`,
    LIKE_POST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/post/action`,
    SEND_POST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/post/send`,
    POST_MEMBER_ADD: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/post/member/create`,
    POST_VIEW: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/post/view`,
    POST_EDIT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/post/edit`,
    POST_MEMBER_VIEW: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/post/member/detail`,
    POST_ACTIVITY_CREATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/post/activity/create`,
    APPROVE_POST : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/post/recruiter/approve`

  },
  SPOC: {
    CREATE_COLLEGE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/college/create`,
    CREATE_CAMPUS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/create`,
    CREATE_COURSE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/degree/create`,
    INACTIVE_UNIVERSITY: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/university/activeInactive`,
    INACTIVE_COLLEGE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/college/activeInactive`,
    INACTIVE_CAMPUS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/activeInactive`,
    INACTIVE_COURSE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/degree/activeInactive`,
    EDIT_COLLEGE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/college/editDetails`,
    EDIT_CAMPUS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/editDetails`,
    GET_INSTITUTES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/institute/list`,
    GET_INSTITUTES_DETAILS_BY_ACCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/institute/list/detail`,
    GET_INSTITUTE_DETAILS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/institute/detail`,
    GET_COURSES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/degree/list`,
    GET_USER_ROLES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/role/list`,
    CREATE_USER_WITH_ROLE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/role/mapping/email/create`,
    GET_USERS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/role/member/details`,
    INACTIVE_USER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/role/mapping/activeInactive`,
    DEGREE_DEPARTMENT_LIST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/degree/list`,
    INACTIVE_DEGREE_DEPARTMENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/degree/department/activeInactive`,
    ADD_DEGREE_DEPARTMENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/degree/department/create`,
    ADD_STATIC_DEPARTMENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/static/department/create`,
    ADD_ROLE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/role/create`,
    EDIT_ROLE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/role/update`,
    DEL_ROLE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/role/activeInactive`,
    GET_PERMISSION_DEFAULT: id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/permission/default?role_type=${id}`,
    GET_PERMISSION_ROLE: id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/role/permission/mapping/list?role_id=${id}`,
    ADD_PERMISSION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/role/permission/mapping/create`,
    EDIT_PERMISSION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/role/permission/mapping/update`,
    CREATE_USER_ROLE_MAPPING : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/role/mapping/create`,
    FETCH_ACCESS_KEY : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/detail_map/list`,
    FETCH_USER_ACCESS :  `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/access/get`,
    GRANT_USER_ACCESS :  `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/access/grant`,
    REMOVE_USER_ACCESS :  `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/access/remove`,
  },

  COMMENT_BOX: {
    CREATE_COMMENT_THREAD: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/verifier/comment/thread/create`,
    COMMENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/verifier/comment`,
    GET_COMMENT: verifier_comment_thread_id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/verifier/comment?verifier_comment_thread_id=${verifier_comment_thread_id}`,
    GET_COMMENT_THREAD: (student_id, verifier_id) => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/verifier/comment/thread?student_id=${student_id}&${verifier_id ? "verifier_id=" + verifier_id : ""}`
  },
  PROCESSES: {
    GET_PROCESSES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/list`,
    BULK_APPLY: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/application/bulk/apply`,
    SHOW_ALL_APPLICATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/list/applicants`,
    GET_COMPANIES_IN_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/list`,
    MAP_COMPANIES_TO_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/add`,
    GET_APPLICATIONS_IN_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/list`,
    UPDATE_COMPANY_STATUS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/status/update`,
    GET_APPLICANTS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/list/applicants`,
    UPDATE_SHORTLIST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/shortlist/update`,
    UPDATE_SHORTLIST_EXCEL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/shortlist/update/excel`,
    UPLOAD_SHORTLIST_EMAIL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/round/excel/email`,
    PUBLISH_APPLICATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/publish`,
    ADD_MILESTONE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/round/add`,
    DELETE_MILESTONE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/round/inactive`,
    ADD_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/create`,
    UPDATE_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/update`,
    DELETE_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/inactive`,
    EDIT_APPICATION: (id) => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/edit/${id}`,
    ADD_ELIGIBITY_CRITERIA: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/eligibility/add`,
    DELETE_ELIGIBILITY_CRITERIA: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/eligibility/inactive`,
    ADD_APPLICATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/add`,
    MAP_COMPANY_PROFILES_TO_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/add`,
    GET_VERIFIERS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/verifier/process`,
    GET_STUDENTS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/verifier/student`,
    ASSIGN_VERIFIER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/verifier/process/create`,
    ASSIGN_STUDENTS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/verifier/student/create`,
    REMOVE_STUDENTS_VERIFIER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/verifier/student/update`,
    REMOVE_VERIFIER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/verifier/process/update`,
    MAP_STUDENTS_TO_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/studentmapping/insert`,
    REMOVE_STUDENTS_FROM_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/studentmapping/inactive`,
    UPDATE_PROFILE_STATUS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/status/update`,
    UPDATE_COMPANY_USER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/user/update`,
    UPDATE_COMPANY_CONTACT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/contact/update`,
    UPDATE_CAMPUS_COMPANY_USER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/company/assign`,
    DELETE_DOC: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/docs/inactive`,
    ADD_DOC: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/docs/add`,
    RELEASE_SHORTLIST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/shortlist/release`,
    FETCH_QUESTION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/question/list`,
    ADD_QUESTION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/question/add`,
    DELETE_QUESTION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/question/inactive`,
    APPEND_MULTIPLE_ROLE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/multirole/append`,
    ADD_MULTIPLE_ROLE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/multirole/bind`,
    DELETE_PROCESS_COMPANY: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/inactive`,
    GET_STUDENT: (id) => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/studentmapping/list?process_id=${id}`,
    ARCHIVEUNARCHIVEPROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/updatearchive`,
    CHANGE_APPLICATION_STATUS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/application/status`,
    DOWNLOAD_APPLICATION_DATA: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/download/answer`,
    DOWNLOAD_APPLICATION_PREFERENCE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/download/preference`,
    DUPLICATE_APPLICATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/copy/to/process`,
    ADD_APPLICANT_TO_APPLICATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/add/students`,
    REMOVE_APPLICANT_TO_APPLICATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/remove/students`,
    CLOSE_APPLICATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/close`,

  },

  PT_COMPANIES: {
    GET_COMPANIES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/company/invite/list`,
    CREATE_BASE_COMPANY: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/company/create`,
    MAP_CAMPUS_COMPANY: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/company/invite/create`,
    INACTIVE_COMPANIES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/company/invite/activeInactive`,
    COMPANY_CONTACTS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/contact/list`,
    COMPANY_CONTACT_DETAILS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/contact/list/all`,
    CREATE_CONTACT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/contact/create`,
    ADD_CONTACT_EMAIL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/contact/email/create`,
    ADD_CONTACT_MOBILE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/contact/mobile/create`,
    INACTIVE_CONTACT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/contact/activeInactive`,
    EDIT_CONTACT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/contact/update`,
    INACTIVE_CONTACT_EMAIL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/contact/email/activeInactive`,
    INACTIVE_CONTACT_MOBILE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/contact/mobile/activeInactive`,
    UPLOAD_CONTACTS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/contact/upload/from/excel`,
    ADD_COMPANY_PROFILE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/company/profile/create`,
    INACTIVE_COMPANY_PROFILE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/company/profile/activeInactive`,
    COMPANY_PROFILES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/company/profile/list`,
    MAPPED_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/mappedprocess/list`,
    DOWNLOAD_COMPANY_DATA: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/company/data/download`,
    UPLOAD_EXCEL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/company/upload/from/excel`
  },

  PT_STUDENTS: {
    GET_BATCHS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/batch/detail`,
    GET_BATCHS_SPEC: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/batch/detail`,
    CREATE_BATCHS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/batch/create`,
    DELETE_BATCHS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/batch/active/inactive`,
    GET_BATCHS_LIST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/batch`,
    GET_GROUPS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/groups`,
    CREATE_GROUPS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/group/create`,
    EDIT_GROUP: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/group/edit`,
    DELETE_GROUPS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/group/active/inactive`,
    DELETE_STUDENTS_GROUP: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/remove/from/group`,
    STUDENT_CREATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/create`,
    STUDENT_CREATE_CSV: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/upload/from/excel`,
    DELETE_STUDENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/active/inactive`,
    STUDENT_EDIT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/edit/personal/info`,
    EDIT_STUDENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/edit`,
    CREATE_POST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/post/create`,
    DELETE_POST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/post/remove`,
    VIEW_CAMPUS: id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/viewCampus?college_id=${id}`,
    VIEW_COLLEGE: id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/institute/detail?college_id=${id}`,
    GET_PREFERENCES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/preferences`,
    GET_STUDENTS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/list`,
    ADD_STUDENTS_GROUP: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/add/to/group`,
    FILTER_GROUP: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/groups/detail`,
    MARK_MANUAL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/attendance/mark/manual`,
    UNMARK_ATTENDANCE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/attendance/activeInactive`,
    VIEW_ATTEND_STUDENT: id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/event/attendee/detail?event_id=${id}`,
  },
  BATCH: {
    GET_BATCH_DETAIL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/batch/detail`,
    GET_BATCH: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/batch`,
    GET_DEGREES: id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/degree?degree_id=${id}`,
    EDIT_BATCH: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/batch/edit`,
    SEND_MAIL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/email/send`,
    GET_INVITE_DETAILS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/invite/viewInvite`,
    SEND_RESPONSE_TO_INVITE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/invite/editDetails`,
    GET_EVENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/event/viewEvent`,
    CREATE_EVENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/event/create`,
    CREATE_ATTENDEE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/event/attendee/create`,
    GET_MEETING_LIST_OF_ATTENDEES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/meeting/attendees/list`,
    CREATE_INVITE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/invite/create`,
    CREATE_INVITE_ATTENDEE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/invite/attendee/create`,
    CREATE_REMINDER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/reminder/create`,
    DELETE_EVENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/event/activeInactive`,
    DELETE_MEETING: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/meeting/activeInactive`,
    UPDATE_MEETING_DETAILS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/meeting/update`,
    UPDATE_EVENT_DETAILS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/event/editDetails`,
    EVENT_ATTENDEE_INACTIVE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/event/attendee/activeInactive`,
    MEETING_ATTENDEES_INACTIVE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/meeting/attendees/activeInactive`

  },
  MEETINGS: {
    GET_MEETINGS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/meeting/list`,
    CREATE_MEETING: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/meeting/create`,
    FETCH_TODAY_MEETING_REMINDER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/meeting/remainder/fetch/today`,
    CREATE_MEETING_REMINDER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/meeting/remainder/create`,
    UPDATE_MEETING_REMINDER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/meeting/remainder/update`,
    CREATE_MEETING_ATTENDEE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/meeting/attendees/create`,
    GET_COMPANIES: params => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/company/invite/list?${params}`,
    GET_CONTACTS: campus_company_id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/contact/list?${campus_company_id}`
  },

  OFFER: {
    ADD_OFFER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/offer/create`,
    ADD_OFFER_EXCEL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/offer/create/excel`,
    VIEW_OFFER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/offer/viewOffer`,
    EDIT_OFFER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/offer/editDetails`,
    DELETE_OFFER : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/offer/activeInactive`,
    ACCEPT_OFFER : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/offer/accept`,
    COMPANY_OFFERS : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/offer/student`
  },

  DOWNLOAD: {
    CV_DOWNLOAD: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/cv/download/all`,
    CV_CSVDOWNLOAD: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/data/download`,
    ELIGIBLE_STUDENT:  `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/eligible/list/download`,

  },
  REPORTS: {
    CTC_BY_SECTOR: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/ctcbysector`,
    CTC_BY_GENDER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/ctcbygender`,
    CTCLIST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/offer`,
    CTC_BY_ROLE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/ctcbyrole`,
    GENDER_REPORT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/batch/student`,
    STUDENT_PROFESSIONAL_REPORT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/batch/student/professional`,
    MEETING_REPORT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/meeting`,
    CLASSIFICATION_LIST : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/offer/classification`,
    STUDENT_REPORT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/batch/process/student`,
    PLACEMENT_REPORT_DOWNLOAD: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/placement/download`,
    PLACEMENT_REPORT_VIEW : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/placement/view`,
    PLACEMENT_REPORT_CATEGORY_VIEW : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/placement/category/view`,
    TEAM_REPORT_DOWNLOAD: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/team`,
    STUDENT_REPORT_DOWNLOAD:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/batch/process/student/download`,
    COMPANY_REPORT_DOWNLOAD:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/process/company/download`,
    COMPANY_REPORT_DOWNLOAD_BY_DEPARTMENT:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/student/company/download`,
    COMPANY_REPORT_DOWNLOAD_COMPANY_GROUPED:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/process/company/download/group/by/company`,
    ATTENDANCE_REPORT_DOWNLOAD: id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/attendance/download?process_id=${id}`,
    ATTENDANCE_REPORT_VIEW : id => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/attendance/view?process_id=${id}`,
    MEETING_REPORT_DOWNLOAD : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/meeting/download`,
    STUDENT_REPORT_VIEW : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/application/report/details/fetch`,

  },

  CLASSIFICATION : {
    ADD_CLASSIFICATION_CATEGORY : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/classification/category/add`,
    CREATE_CLASSIFICATION : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/classification/add`,
    LIST_CLASSIFICATION_DETAILS : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/classification/category/list`,
    EDIT_CLASS_CATEGORY: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/classification/category/edit`,

    INACTIVE_CLASS_CATEGORY: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/classification/category/inactive`,
    INACTIVE_CATEGORY: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/classification/inactive`,
    COMPANY_CLASSIFICATION_MAPPING : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/classification/company/map`,
    ADD_MATRIX_RULE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/process/rule/add`,
    PROCESS_COMPANY_MAPPING : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/classification/process/company/map`,
    EDIT_PROCESS_COMPANY_MAPPING : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/classification/process/company/map/edit`,
    GET_MATRIX_RULE : id=> `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/process/rule/list?process_id=${id}`,
    EDIT_MATRIX : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/process/rule/edit`,
    EDIT_MATRIX_RULE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/process/rule/detail/edit`,
    DELETE_MATRIX :  `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/process/rule/inactive`,
    EDIT_COMPANY_CLASSIFICATION_MAPPING : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/classification/company/map/edit`,
    GET_COMPANY_MAPPED_CLASSIFICATION : id=> `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/classification/company/map/list?company_classification_id=${id}`
  },

  MATRIX : {
    CHECK_WITHDRAW_STATUS : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/matrix/process/matrix/status/check`,
  },

  CAMPUS_ROLES:{
    GET_ROLES : id=> `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/roles?campus_id=${id}`,
    CREATE_ROLE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/role/create`,
    EDIT_ROLE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/role/edit`,
    ACTIVE_INACTIVE_ROLE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/role/active/inactive`,
  },
  SEND_LINK:{
    TO_COMPANY: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/send_to_company`,
  },

  ATTENDANCE: {
    GENERATE_QR: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/event/qr`,
    GET_LIST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/attendance/list`,
    ATTENDANCE_VIEW: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/attendance/view`,
    UPLOAD_ATTENDANCE_EXCEL: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/attendance/upload/by/excel`,

  },

  INST_CONFIGURATION : {
     GET_CONFIG_LIST:  `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/institute/config/list`,
     CREATE_CONFIG : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/institute/config/create`
   },
   UNIVERSITY : {
     CREATE_UNIVERSITY : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/university/create`,
     GET_UNIVERSITY : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/university/viewUniversity`,
     EDIT_UNIVERSITY : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/university/editDetails`
   },

   ELECTIVES : {
     GET_LIST : id =>`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/elective/list?campus_id=${id}`,
     CREATE_ELECTIVE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/elective/create`
   },
   CSV_DOWNLOAD : {
     GET_LIST : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/csv/config/list?config_type=STUDENT_PROFILE_DOWNLOAD`,
     CREATE_EXCEL_LIST : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/csv/config/create`
   },
   EMAIL_TEMPLATE : {
     GET_EMAIL_TEMPLATE_LIST : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/email/template/list`,
     GET_EMAIL_COUNT : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/email/template/details`,
     CREATE_TEMPLATE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/email/template/create`,
     UPDATE_EMAIL_TEMPLATE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/email/template/update`,
     DELETE_EMAIL_TEMPLATE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/email/template/remove`,
     SEND_CAMPAIGN_MAILS :  `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/email/campaign/send`,
     GET_CAMPAIGN_MAIL_DETAILS : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/email/campaign/info`,
     GET_CAMPAIGN_MAIL_TRACKING : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/email/tracking/info`

   },
   EVENT_ROUND_MAPPING : {
     CREATE_EVENT_ROUND_MAPPING : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/event/eventRoundMapping/create`,
     GET_EVENT_ROUND_MAPPING : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/event/eventRoundMapping`,
     DELETE_EVENT_ROUND_MAPPING : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/calendar/event/eventRoundMapping/activeInactive`
   },
   RECRUITER: {
     USER : {
       GET : `${API_CONFIG.RECRUITER_URL}${API_CONFIG.BASE_PATH}/recruiter/get`,
       INVITERECRUITER : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/login/create`,
     },
     COMPANY : {
       GET_COMPANY : `${API_CONFIG.RECRUITER_URL}${API_CONFIG.BASE_PATH}/recruiter/company/fetch`
     },
     CONNECTION: {
       GET_CONNECTION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/connection/fetch`,
       INACTIVE_CONNECTION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/connection/inactive`,
       GET_REQUESTS: `${API_CONFIG.RECRUITER_URL}${API_CONFIG.BASE_PATH}/connection/fetch`,
       UPDATE_REQUEST: `${API_CONFIG.RECRUITER_URL}${API_CONFIG.BASE_PATH}/connection/approve`,
       GET_APPLICATIONS: `${API_CONFIG.RECRUITER_URL}${API_CONFIG.BASE_PATH}/connection/application/fetch`,
       UPDATE_APPLICATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/application/approve`,
       GET_APPLICATION_DETAILS: `${API_CONFIG.RECRUITER_URL}${API_CONFIG.BASE_PATH}/connection/application/details/fetch`,
       GET_APPLICATION_REFERENCES: `${API_CONFIG.RECRUITER_URL}${API_CONFIG.BASE_PATH}/process/campus/job/reference/fetch`
     },
     INVITERECRUITER : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/login/create`,
     FETCH_INVITE_RECRUITER : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/fetch`,

   },
   OTP_VERIFICATION : {
     SEND_OTP : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/otp/send`,
     RETRY_OTP : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/otp/retry`,
     VERIFY_OTP : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/otp/verify`,
     REGISTER_USER : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/temp/register`,
     DOWNLOAD_OTP_STUDENTS : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/temp`
   },
   KUE: {
     SEARCH: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.KUE_PATH}/search`,
     JOB_DETAILS: (jobId) => `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.KUE_PATH}/${jobId}`
   },
   FEEDBACK: {
     REQUEST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/feedback/generate`,
     FETCH: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/feedback/fetch`,
     ACCEPT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/feedback/accept`
   },
   SPECIALIZATION: {
    FETCH: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/specialization/list`,
    CREATE_SPECIALIZATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/specialization/create`,
    DELETE_SPECIALIZATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/specialization/activeInactive`,
   },
   CAMPUS_PROFILE: {
    FETCH:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/profile` ,
    CREATE_CAMPUS_PROFILE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/profile/create` ,
    UPDATE_CAMPUS_PROFILE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/profile/edit`,
    FETCH_PAST_PLACEMENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/past_process/list`,
    CREATE_PAST_PLACEMENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/past_process/create`,
    UPDATE_PAST_PLACEMENT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/past_process/update`,
    FETCH_REPORT_OVERVIEW: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/report/placement/view`,
   },
   PUBLIC_CAMPUS_PROFILE: {
    FETCH:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/slugname/view` ,
    FETCH_ACTIVE_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/campus/view`,
   },
   RECRUITER_CONVERSATION: {
    FETCH: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/conversation/read/campus` ,
    SEND: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/conversation/send/campus` ,
    MSG_COUNT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/conversation/count/unread` ,

  }

}
