import React, { Component } from 'react';
import moment from 'moment';
import { Auth } from '../../../../actions/Auth';
import { CONSTANTS } from '../../../../constants';

export class ApplicationTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deadline: props.application.application_deadline,
      deadLineCounter: moment.duration(moment(props.application.application_deadline).diff(moment(), "milliseconds"))
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.application.application_deadline != this.state.deadline) {
      this.setState({
        deadline: nextProps.application.application_deadline,
        deadLineCounter: moment.duration(moment(nextProps.application.application_deadline).diff(moment(), "milliseconds"))
      })
    }
  }

  componentWillMount() {
    this.interval = setInterval(this.getDeadline, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getDeadline = () => {
    let deadLineCounter = moment.duration(moment(this.state.deadline).diff(moment(), "milliseconds"))
    this.setState({ deadLineCounter })
  }

  render() {
    let { deadLineCounter } = this.state;
    return (
      <div>
        <div className="text-truncate text-center r-subheading-4 r-text--brandF">{`${deadLineCounter.asDays() < 0 ? 0 : Math.floor(deadLineCounter.asDays())}d:${deadLineCounter.hours() < 0 ? 0 : deadLineCounter.hours()}h:${deadLineCounter.minutes() < 0 ? 0 : deadLineCounter.minutes()}m:${deadLineCounter.seconds() < 0 ? 0 : deadLineCounter.seconds()}s`}</div>
      </div>
    )
  }
}
