import React, { Component } from 'react';
import Aux from '../../../../global/AuxComp';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import moment from 'moment';
import Collapse from 'reactstrap/lib/Collapse';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { ProfileActions } from './profile-actions';
import Button from '../../../../global/Button';
import AutosuggestComp from '../../../../global/AutosuggestComp';
import { Auth } from '../../../../../actions/Auth';
import { CONSTANTS } from '../../../../../constants';
import { InputField } from '../../../../global/Forms';
import { filterCVPoints } from '../../../../../actions';
import { CVPoint } from './cv-point';
import { FileUpload } from '../../../../global/FileUpload';
import { MonthSelect, YearSelect } from '../../../../global/Forms/Select';
import { Errorbar } from '../../../../global/errorbar';
import { Switch } from '../../../../global/Forms/ToggleSwitch';

export class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      deleteId: null,
      suggestions: {
        getSectors: [],
        getIndustries: [],
        getCompanies: []
      },
      autoSuggestValues: {
        sector: "",
        industry: "",
        company: ""
      },
      errorbar: false,
      message: ''
    };

    this.autoSuggestProps = {
      className: "w-100 r-inputfield",
      onChange: this.handleFieldChange
    }
  }

  closebar = () => {
    this.setState({ errorbar: false })
  }

  handleFieldChange = (e, pro) => {
    let { autoSuggestValues } = this.state;
    if (e.target.name === "company") this.setValuefromSuggestion({ company_id: null, company_name: "" }, "company", pro)
    if (e.target.name === "sector") this.setValuefromSuggestion({ sector_id: null, sector_name: "" }, "sector", pro)
    if (e.target.name === "industry") this.setValuefromSuggestion({ industry_id: null, industry_name: "" }, "industry", pro)
    autoSuggestValues[e.target.name] = e.target.value;
    this.setState({
      autoSuggestValues
    })
  }

  isValid = (pro) => {
    let { sector, industry, company } = this.state.autoSuggestValues
    let { project_id, company_id, industry_id, sector_id } = pro;
    if (!project_id || !company || !industry || !sector) {
      this.setState({ message: 'Please fill all mandatory input fields' });
      this.setState({ errorbar: true });
      return false
    }
    this.setState({ errorbar: false });
    return true
  }

  handleProDetailSubmit = async (e, pro) => {
    let { sector, industry, company } = this.state.autoSuggestValues
    if (e) e.preventDefault();
    if (!this.isValid(pro)) {
      return
    }
    try {
      this.props.toggleLoading(true);
      if (!pro.sector_id) {
        let sectorResp = await Auth.General.addSector({ sector_name: sector })
        if (sectorResp.data.insertId) {
          pro.sector_id = sectorResp.data.insertId
          pro.sector_name = sector
        }
      }
      if (!pro.industry_id) {
        let industryResp = await Auth.General.addIndustry({ industry_name: industry, sector_id: pro.sector_id })
        if (industryResp.data.insertId) {
          pro.industry_id = industryResp.data.insertId
          pro.industry_name = industry
        }
      }
      if (!pro.company_id) {
        let companyResp = await Auth.General.addCompany({ company_name: this.state.autoSuggestValues.company, sector_id: pro.sector_id, industry_id: pro.industry_id })
        if (companyResp.data.insertId) {
          pro.company_id = companyResp.data.insertId
          pro.company_name = this.state.autoSuggestValues.company
        }
      }
      let method = Auth.Student.updateProject,
        formData = Object.assign({}, pro);
      formData.start_date = moment(formData.start_date).format(CONSTANTS.DATE_FORMAT)
      // if(formData.currently_working)
      //   formData.end_date= moment();
      formData.end_date = formData.currently_working ? null : moment(formData.end_date).format(CONSTANTS.DATE_FORMAT)
      if (typeof pro.project_id == "string") {
        method = Auth.Student.createProject;
        formData.student_id = this.props.user_info.student_id;
        delete formData.project_id
      }
      await method(formData)
      this.props.getStudentProfile()
      if (e) this.props.toggleSection("");
    } catch (e) {
      //  console.log(e)
      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
    this.props.toggleLoading(false);
  }

  deleteDetail = async () => {
    let { deleteId } = this.state;
    if (!deleteId) return;
    let formData = {
      student_id: this.props.user_info.student_id,
      project_id: deleteId,
      status: 0
    }
    this.props.toggleLoading(true)
    try {
      if (typeof deleteId == "number")
        await Auth.Student.updateProject(formData)
      this.props.changeProjectDetail(formData, true)
      this.toggleModal()
      this.props.getStudentProfile()

    } catch (e) {
      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
    this.props.toggleLoading(false)
  }

  toggleModal = (id) => {
    this.setState({
      modalShow: !this.state.modalShow,
      deleteId: id
    })
  }

  setValuefromSuggestion = (value, name, pro) => {
    let { autoSuggestValues } = this.state,
      proName = name + "_name",
      evtObj = {
        target: {
          name: name + "_id",
          value: value[name + "_id"]
        }
      }
    autoSuggestValues[name] = value[proName]
    pro[proName] = value[proName]
    this.setState({
      autoSuggestValues
    })
    this.handleProChange(evtObj, pro)
  }

  changeYear = (e, field, method, pro) => {
    let date = pro[field] ? moment(pro[field]) : moment();
    pro[field] = date[method](e.target.value).format(CONSTANTS.DATE_FORMAT);
    this.props.changeProjectDetail(pro);
  }

  handleProChange = (e, pro) => {
    pro[e.target.name] = e.target.value;
    this.props.changeProjectDetail(pro)
  }

  addProject = (e) => {
    let { autoSuggestValues } = this.state;
    e.preventDefault();
    this.props.toggleSection(`PROJ-0`);
    autoSuggestValues.sector = ""
    autoSuggestValues.industry = ""
    autoSuggestValues.company = ""
    this.props.changeProjectDetail({});
    this.el = e.target.closest(".student__profile__container")
    setTimeout(() => {
      if (this.el)
        this.el.scrollIntoView({ behavior: "smooth", block: "start" })
    }, 300)
  }

  setAutoSuggestionValues = (pro) => {
    let { autoSuggestValues } = this.state;
    autoSuggestValues.sector = pro.sector_name || ""
    autoSuggestValues.industry = pro.industry_name || ""
    autoSuggestValues.company = pro.company_name || ""
    this.setState({
      autoSuggestValues
    })
  }

  render() {
    let { files, editableSection, toggleSection, project, onCancel, cv_point, commentThreads, sectorList, industryList } = this.props;
    let { autoSuggestValues, suggestions } = this.state;
    this.cvPoints = filterCVPoints(cv_point, "PROJECT")
    return (
      <Aux>
        <div className="r-card student__profile__container">
          <Row noGutters className="mb-0">
            <Col xs={12}>
              <h2 className="r-heading-4 r-text--blue-3">Projects</h2>
              {editableSection === "" && <div className="student__profile__actions">
                <Button onClick={this.addProject} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
              </div>}
            </Col>
          </Row>
          {
            project.length > 0 ? project.map((pro, i) => {
              return (
                <div className={"student__profile__section pt-4" + (pro.freeze_status == 1 ? " student__profile--freeze" : "")} key={pro.project_id}>
                  <Collapse isOpen={editableSection !== `PROJ-${i}`}>
                    <h1 className="r-subheading-2 mr-3" style={{ fontWeight: 600 }}>
                      {pro.project_title || <em>Project Title</em>}
                    </h1>
                    <h1 className="r-subheading-2 mt-3" style={{ fontWeight: 400 }}>
                      {pro.company_name || <em>Company</em>}
                    </h1>
                    <div className="r-text--grey-4 mb-2">{pro.start_date ? moment(pro.start_date).format("MMM YYYY") : <em>Start Month/Year</em>} - {pro.currently_working ? "Currently Working" : (pro.end_date ? moment(pro.end_date).format("MMM YYYY") : <em>End Month/Year</em>)}</div>
                    {(files[pro.file]) && <Row noGutters>
                      {files[pro.file].map((doc, i) => <Col className="pr-3" key={i} xs="auto">
                        <div className="r-tag r-tag--type-1 r-tag--no-delete">
                          <a href={doc.url} target="_blank">{doc.file_name || doc.file_url || "Filename"}</a>
                        </div>
                      </Col>)}
                    </Row>}
                  </Collapse>
                  <Collapse isOpen={editableSection === `PROJ-${i}`} onEntering={() => this.setAutoSuggestionValues(pro)}>
                    <form onSubmit={(e) => this.handleProDetailSubmit(e, pro)}>
                      <Row form className="mb-2">
                        <Col xs={12}>
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Title</label>
                          <InputField className="w-100" type="text" name="project_title" value={pro.project_title} onChange={(e) => this.handleProChange(e, pro)} />
                        </Col>
                      </Row>
                      <Row form className="mb-2">
                        <Col xs={12}>
                          <div className="d-flex justify-content-between">
                            <label className="r-heading-7"><span className="r-text--red-1">*</span>Organization</label>
                            {/* <AddSuggestion type="organization" /> */}
                          </div>
                          <AutosuggestComp
                            inputProps={{
                              className: "w-100 r-inputfield",
                              onChange: (e) => this.handleFieldChange(e, pro),
                              placeholder: '',
                              value: autoSuggestValues.company,
                              name: "company"
                            }}
                            suggestionID="getCompanies"
                            renderSuggestion={(comp) => <div>{comp.company_name}</div>}
                            setValue={(value) => this.setValuefromSuggestion(value, "company", pro)} />
                        </Col>
                      </Row>
                      <Row form className="mb-2">
                        <Col xs={6}>
                          <div className="d-flex justify-content-between">
                            <label className="r-heading-7"><span className="r-text--red-1">*</span>Sector</label>
                            {/* <AddSuggestion type="sector" /> */}
                          </div>
                          {/* <Select name="sector_id" value={pro.sector_id} onChange={(e) => this.handleProChange(e, pro)}>
                            <option value="">Select</option>
                            {sectorList}
                          </Select> */}
                          <AutosuggestComp
                            inputProps={{
                              className: "w-100 r-inputfield",
                              onChange: (e) => this.handleFieldChange(e, pro),
                              placeholder: '',
                              value: autoSuggestValues.sector,
                              name: "sector"
                            }}
                            suggestionID="getSectors"
                            renderSuggestion={(sec) => <div>{sec.sector_name}</div>}
                            setValue={(value) => this.setValuefromSuggestion(value, "sector", pro)} />
                        </Col>
                        <Col xs={6}>
                          <div className="d-flex justify-content-between">
                            <label className="r-heading-7"><span className="r-text--red-1">*</span>Industry</label>
                            {/* <AddSuggestion type="industry" /> */}
                          </div>
                          {/* <Select name="industry_id" value={pro.industry_id} onChange={(e) => this.handleProChange(e, pro)}>
                            <option value="">Select</option>
                            {industryList}
                          </Select> */}
                          <AutosuggestComp
                            inputProps={{
                              className: "w-100 r-inputfield",
                              onChange: (e) => this.handleFieldChange(e, pro),
                              placeholder: '',
                              value: autoSuggestValues.industry,
                              name: "industry"
                            }}
                            suggestionID="getIndustries"
                            renderSuggestion={(ind) => <div>{ind.industry_name}</div>}
                            setValue={(value) => this.setValuefromSuggestion(value, "industry", pro)} />
                        </Col>
                      </Row>
                      <Row form className="mb-2">
                        <Col xs={6}>
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Start Month/Year</label>
                          <Row noGutters>
                            <Col xs={6}>
                              <MonthSelect onChange={(e) => this.changeYear(e, "start_date", "month", pro)} value={pro.start_date ? moment(pro.start_date).month() : ""} />
                            </Col>
                            <Col xs={6}>
                              <YearSelect onChange={(e) => this.changeYear(e, "start_date", "year", pro)} value={pro.start_date ? moment(pro.start_date).year() : ""} />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={6}>
                          <Row className="justify-content-between align-items-center">
                            <Col xs="auto">
                              <label className="r-heading-7"><span className="r-text--red-1">*</span>End Month/Year</label>
                            </Col>
                            <Col className="d-flex align-items-center" xs="auto">
                              <span className="r-text--sm mr-2">Currently working</span>
                              <Switch isOn={pro.currently_working == 1 ? true : false} handleToggle={(e) => this.handleProChange({ target: { name: "currently_working", value: pro.currently_working ? 0 : 1 } }, pro)} />
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col xs={6}>
                              <MonthSelect disabled={pro.currently_working} onChange={(e) => this.changeYear(e, "end_date", "month", pro)} value={pro.end_date ? moment(pro.end_date).month() : ""} />
                            </Col>
                            <Col xs={6}>
                              <YearSelect disabled={pro.currently_working} onChange={(e) => this.changeYear(e, "end_date", "year", pro)} value={pro.end_date ? moment(pro.end_date).year() : ""} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>


                      <div className="d-flex justify-content-end mt-3">
                        <Button type="button" onClick={() => onCancel()} className="r-text--sm">Cancel</Button>
                        <Button type="submit" className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
                      </div>
                      {typeof pro.project_id == "number" && <FileUpload toggleLoading={this.props.toggleLoading} updateData={this.props.changeProjectDetail} submitData={this.handleProDetailSubmit} verification={{ user_id: this.props.user_info.user_id, data: pro, files }} />}
                    </form>
                  </Collapse>
                  {typeof pro.project_id == "number" && <CVPoint getStudentProfile={this.props.getStudentProfile} settings={this.props.settings} toggleCV={this.props.toggleCV} files={files} toggleLoading={this.props.toggleLoading} user_info={this.props.user_info} profile_id={pro.project_id} type="PROJECT" changeCVPoint={this.props.changeCVPoint} cvPoints={this.cvPoints[pro.project_id] || []} commentThreads={commentThreads} />}
                  {editableSection !== `PROJ-${i}` && <ProfileActions delete={() => this.toggleModal(pro.project_id)} verification={{ ...pro }} toggleSection={() => toggleSection(`PROJ-${i}`)} isRequiredValidate={true} commentThreads={commentThreads} profileType="PROJECT" />}
                </div>
              )
            }) : <div className="student__profile__section student__profile__section--placeholder">

                <h1 className="r-subheading-2 mr-3" style={{ fontWeight: 600 }}>
                  Project Title
                </h1>
                <h1 className="r-subheading-2 mt-3" style={{ fontWeight: 400 }}>
                  Company
                </h1>
                <div className="r-text--grey-4 mb-2">Start Date - End Date</div>

              </div>
          }
          <Modal className="modal--delete" isOpen={this.state.modalShow} toggle={() => this.toggleModal()}>
            <ModalBody>
              <p>Are you sure?</p>
              <div className="text-right">
                <Button className="r-text--sm" onClick={() => this.toggleModal()}>Cancel</Button>
                <Button color="dark" onClick={this.deleteDetail} className="r-text--sm r-btn--no-radius">Delete</Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
      </Aux>
    )
  }
}
