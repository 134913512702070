import React,{Component} from 'react';
import { Auth } from '../../../actions/Auth';

class DownloadOtpStudents extends Component{
  constructor(props) {
    super(props);
  }

  downloadOtpStudents= async()=>{
    let params={
      purpose : this.props.match.params.purpose || "netambit",
      columns : ["city","college_name","email" , "first_name" , "mobile_no" , "created_on","work_experience", "dob"].toString()
    }
    try{
      if(params.purpose=="google_pay"){
        params.columns = ["city","college_name","email" , "first_name" , "mobile_no" , "created_on","course","gender", "dob" , "answer"].toString();
      }
      let resp = await Auth.OtpVerification.downloadOtpStudents(params);
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      let fileName = `${params.purpose}_candidates.csv`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    catch(e){
      console.log(e);
    }
  }

  componentDidMount(){
    this.downloadOtpStudents();
  }
  render(){
    return(
      <span></span>
    )
  }
}
export default DownloadOtpStudents;
