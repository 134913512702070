import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import Button from '../../global/Button';
import Modal from "reactstrap/lib/Modal";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { InputField } from "../../global/Forms";
import Link from "react-router-dom/Link";
import { CardBody, CardTitle, CardText, Card, Collapse, PopoverHeader, Popover } from "reactstrap";
import { connect } from "react-redux";
import { Auth } from "../../../actions/Auth";
import { Switch } from "../../global/Forms/ToggleSwitch";
import { CONSTANTS } from "../../../constants";
import { SelectList, Select } from "../../global/Forms/Select";
import { Loader } from "../../global/Loader";
import { ReactComponent as InfoIcon } from '../../../assets/icons/information.svg';
import UncontrolledPopover from 'reactstrap/lib/UncontrolledPopover';
import { CVCategory } from "./cv-category";
import { ManageSpecialization } from "./manage_specialization";
import Aux from "../../global/AuxComp";
import { Errorbar } from '../../global/errorbar';
import MultipleOfferSystem from './multiple_offer_system';
import { uploadFile } from "../../../actions";
import store from "../../../store";
import { HELP_SECTION_CONFIG } from "../../../constants/help_section_config";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = ()=>{
    return(
      {
        value: false,
        openCollapseValue : null,
        isOn: false,
        processId: null,
        modal: false,
        modal_1: false,
        selectedProcessID: null,
        selectedProcessName: null,
        selectedProcessType: null,
        updateProcess: null,
        updateProcessType: null,
        loading: false,
        processList: null,
        batchList: [],
        cvConfig : null,
        selectedBatch : null,
        archiveProcessList: null,
        selectDeleteProcess: null,
        applicationModal: false,
        offerSection: null,
        displayMaxCounter: null,
        classificationcategory: null,
        classificationDetails: null,
        selectedClassification: null,
        categoryValue: null,
        categoryList: null,
        selectedCategory: null,
        categoryMaxCount: {},
        categoryOverallCount: {},
        overallCount: null,
        selectedOfferedCategory: null,
        selectedOfferedClassification: null,
        outsideOfferCount: "",
        inProcessCheckbox: null,
        selectedMatrixProcess: null,
        editApplicationModal: false,
        cvSettingModal:false,
        ptUpload: false,
        stUpload: false,
        temUpload: false,
        cvToolbar: true,
        showUnverified: true,
        enableSectionDrag: true,
        isSinglePageCV: false,
        cvLock : false,
        martixRuleList: null,
        ruleList: null,
        addRuleModal: false,
        classificationCheckbox: false,
        selectedOfferedCategoryName: null,
        ruleSelectedClassification: null,
        displayRuleCollapse: null,
        deleteMatrix: null,
        deleteMatrixModal: false,
        ptAllowEditProfile: false,
        popoverOpen : false,
        popoverName : '',
        errorbar : false,
        isSuccess : false,
        message : '',
        defaultProcess : null,
        isOpenUploadStudentAcademicModal : false,
        isOpenUploadBacklogsModal : false,
        isOpenUploadSemesterScoreModal : false,
        isOpenHelpModal: false
      }
    )
  }


  closebar = () => {
    this.setState({ errorbar: false, isSuccess : false });
  }

  closeApplicationModal = ()=>{
    this.setState({editApplicationModal : !this.state.editApplicationModal});
  }

  SetNewProcess = e => {
    this.setState({
      updateProcess: e.target.value
    });
  };

  setNewProcessType = e => {
    this.setState({
      selectedProcessType: e.target.value
    });
  };


  toggleLoading = value => {
    this.setState({
      loading: value
    });
  };

  updateProcess = async () => {
    let formData = {
      process_id: this.state.selectedProcessID,
      process_type: this.state.selectedProcessType
    };
    if (this.state.updateProcess) {
      formData.process_name = this.state.updateProcess;
    } else {
      formData.process_name = this.state.selectedProcessName;
    }

    this.toggleLoading(true);
    try {
      let resp = await Auth.Processes.updateProcess(formData);
      this.getProcessList();
    } catch (e) {
      this.setState({errorbar : true});
      if(e.response && e.response.data){
        this.setState({message : e.response.data.error.toString()})
      }else{
        this.setState({message : "Something went wrong! Please try again"})
      }
    }
    this.setState({ modal: !this.state.modal });
    this.toggleLoading(false);
  };

  deleteProcess = async processId => {
    this.toggleLoading(true);
    let { processList } = this.state;
    let finalList = processList.filter(
      process => process.process_id != processId
    );
    await this.setState({
      processList: finalList,
      selectDeleteProcess: null
    });
    await Auth.Processes.deleteProcess({ process_id: processId });
    this.setState({ modal_1: !this.state.modal_1 });
    this.toggleLoading(false);
  };

  getProcessList = async () => {
    let getCampusProcess = await Auth.Batch.getProcesses(
      this.props.role.instituteDetail.campus_id
    );
    let processList = getCampusProcess.data.filter(
      process => process.archive_status == 0
    );
    let archiveProcessList = getCampusProcess.data.filter(
      process => process.archive_status == 1
    );
    await this.setState({
      processList: processList
    });
    this.setState({
      archiveProcessList
    });
  };

  archiveProcess = async process => {
    this.toggleLoading(true);
    let params = {
      process_id: process.process_id,
      archive_status: 1
    };
    try {
      await Auth.Processes.archiveUnarchiveProcess(params);
    } catch (e) {
      this.setState({errorbar : true});
      if(e.response && e.response.data){
        this.setState({message : e.response.data.error.toString()})
      }else{
        this.setState({message : "Something went wrong! Please try again"})
      }
    }
    let { archiveProcessList, processList } = this.state;
    let finalProcessList = processList.filter(
      selectProcess => selectProcess.process_id != process.process_id
    );
    await this.setState({
      processList: finalProcessList
    });

    archiveProcessList.push(process);
    this.setState({
      archiveProcessList
    });
    this.toggleLoading(false);
  };
  unarchiveProcess = async process => {
    this.toggleLoading(true);
    let params = {
      process_id: process.process_id,
      archive_status: 0
    };
    try {
      await Auth.Processes.archiveUnarchiveProcess(params);
    } catch (e) {
      this.setState({errorbar : true});
      if(e.response && e.response.data){
        this.setState({message : e.response.data.error.toString()})
      }else{
        this.setState({message : "Something went wrong! Please try again"})
      }
    }
    let { archiveProcessList, processList } = this.state;
    let finalArchiveList = archiveProcessList.filter(
      selectProcess => selectProcess.process_id != process.process_id
    );
    await this.setState({
      archiveProcessList: finalArchiveList
    });
    processList.push(process);
    this.setState({
      processList
    });
    this.toggleLoading(false);
  };

   componentDidMount() {
     this.getProcessList();
     this.getcvConfig();
  }

  getcvConfig = async ()=>{
    let batchResp = await Auth.Batch.getBatchDetail();
    let batchList = batchResp.data;
    this.setState({batchList, selectedBatch : batchList.length ? batchList[0].batch_id : null});
    let res =await  Auth.User.getConfiguration();
    if(res.data.STUDENT && res.data.STUDENT.STUDENT_CV_SETTING){
      this.setState({cvConfig : res.data.STUDENT.STUDENT_CV_SETTING})
    }
    if(batchList.length) this.selectCVConfig(batchList[0].batch_id);
  }

  selectCVConfig = (batchId) => {
    let { cvConfig } = this.state;
    if(cvConfig && cvConfig[batchId]){
      this.setState({
        stUpload:cvConfig[batchId].student_upload?true:false,
        ptUpload:cvConfig.pt_upload?true:false,
        temUpload:cvConfig[batchId].cv_builder?true:false,
        cvToolbar: cvConfig[batchId].cv_toolbar? true: false,
        showUnverified: cvConfig[batchId].show_unverified? true: false,
        enableSectionDrag: cvConfig[batchId].enable_section_drag===0? false: true,
        cvLock: cvConfig[batchId].cv_lock ? true: false,
        isSinglePageCV: cvConfig[batchId].is_single_page_cv ? true: false
      })
    }
    else {
      this.setState({
        stUpload: false,
        ptUpload: cvConfig.pt_upload ? true : false,
        temUpload: false,
        cvToolbar: true,
        showUnverified: true,
        enableSectionDrag: true,
        cvLock: false,
        isSinglePageCV: false
      })
    }
  }
  createcvConfig = async() => {
    const {ptUpload, stUpload, temUpload, cvToolbar, showUnverified, enableSectionDrag, isSinglePageCV, cvLock } = this.state;
    try {
      await Auth.User.createConfiguration({
        "module": "STUDENT",
        "feature": "STUDENT_CV_SETTING",
        "config": { 
          ...(this.state.cvConfig || {}),
          [this.state.selectedBatch] : {
            student_upload:stUpload?1:0,
            cv_builder:  temUpload?1:0,
            cv_toolbar: cvToolbar? 1: 0,
            show_unverified: showUnverified? 1: 0,
            enable_section_drag: enableSectionDrag? 1: 0,
            is_single_page_cv: isSinglePageCV ? 1: 0,
            cv_lock: cvLock? 1: 0
          },
          pt_upload: ptUpload?1:0,
        }
      })
      let res =await Auth.User.getConfiguration();
      this.setState({cvConfig : res.data.STUDENT.STUDENT_CV_SETTING})

      this.setState({ cvSettingModal : !this.state.cvSettingModal})
    } catch (e) {
      this.setState({errorbar : true});
      if(e.response && e.response.data){
        this.setState({message : e.response.data.error.toString()})
      }else{
        this.setState({message : "Something went wrong! Please try again"})
      }
    }
  }

  selectBatch = (batch) => {
    this.setState({ selectedBatch: batch.batch_id });
    this.selectCVConfig(batch.batch_id);
  }

  uploadStudentAcademic = async (e) => {
    e.preventDefault();
    e.persist();
    let {csvFile} = e.target;
    var file = csvFile.files[0];
    this.setState({ loading: true });
    try{
      let newFile = await uploadFile(file, "student_create_excell/"+ store.getState().user.id);

        var body = {
            key : newFile.file_url,
        }
        var resp = await Auth.Student.uploadStudentAcademic(body)
        e.target.reset();
        if(typeof resp.data !== "object"){
          this.setState({message : "Data uploaded successfully. Few records failed, Please check downloaded CSV"})
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          let fileName = `upload academics error.csv`;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
        this.setState({isOpenUploadStudentAcademicModal : false});
    }
    catch(e){
      console.log(e.response.data)
      this.setState({errorbar : true});
      if(e.response && e.response.data){
        this.setState({message : e.response.data.error ? e.response.data.error.toString() : "Something went wrong! Please try again"})
      }else{
        this.setState({message : "Something went wrong! Please try again"})
      }  
    }
    this.setState({ loading: false });
  }
  
  downloadBacklogAndScoreData = async (type) => {
    this.setState({ loading: true });
    try{
        var resp = type === "BACKLOG" ? 
          await Auth.Student.downloadBacklogsData()
          :
          await Auth.Student.downloadSemesterScoreData();
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = `Student ${type === "BACKLOG" ? "Backlogs" : "Semester Score"} Data.csv`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    catch(e){
      this.setState({errorbar : true});
      if(e.response && e.response.data){
        this.setState({message : e.response.data.error ? e.response.data.error.toString() : "Something went wrong! Please try again"})
      }else{
        this.setState({message : "Something went wrong! Please try again"})
      }  
    }
    this.setState({ loading: false });
  }

  uploadBacklogAndScoreData = async (e, type) => {
    e.preventDefault();
    e.persist();
    let {csvFile} = e.target;
    var file = csvFile.files[0];
    this.setState({ loading: true });
    try{
        let newFile = await uploadFile(file, "student_data_excel/"+ store.getState().user.id);
        var body = {
            key : newFile.file_url,
        }
        var resp = type === "BACKLOG" ? 
          await Auth.Student.uploadBacklogsData(body)
          :
          await Auth.Student.uploadSemesterScoreData(body);
          this.setState({errorbar: true, isSuccess: true, message : resp.data.message || "Data uploaded successfully!"})
          e.target.reset();
          this.setState({isOpenUploadBacklogsModal : false, isOpenUploadSemesterScoreModal: false});
    }
    catch(e){
      this.setState({errorbar : true});
      if(e.response && e.response.data){
        this.setState({message : e.response.data.error ? e.response.data.error.toString() : "Something went wrong! Please try again"})
      }else{
        this.setState({message : "Something went wrong! Please try again"})
      }  
    }
    this.setState({ loading: false });
  }


  render() {
    let flag = 0 , classificationFlag=0;
    let {
      ptUpload,
      stUpload,
      temUpload,
      cvToolbar,
      showUnverified,
      enableSectionDrag,
      isOpenUploadStudentAcademicModal,
      isOpenUploadBacklogsModal,
      isOpenUploadSemesterScoreModal,
      isSinglePageCV,
      cvLock,
      batchList,
      selectedBatch
    } = this.state;
    return (
      <Container>
        <Loader show={this.state.loading} />
        <div>
          <Row className="m-4">
            <Col>
              <span className="r-subheading-1">
                <Row>
                  <Col xs="1">
                    <Link to={"/pt/"}>
                      <img
                        src="/images/left-arrow.png"
                        alt="back-button"
                        height="30"
                      />
                    </Link>
                  </Col>
                  <Col xs="auto" align="start">
                    <span>PT Configurations </span>
                  </Col>
                </Row>
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs="4">
              <Card className="ml-5 mt-3 pt_settings">
                <CardTitle className="m-2 p-3 border-bottom">
                  <span className="r-heading-4 r-text--brandA">{"  "} Batch Tools </span>
                </CardTitle>
                <CardBody className="settings__placement-activities">
                  <Row className="">
                    <Col>
                      <Button
                        onClick={e =>
                          this.props.history.push({
                            pathname: "/pt/students/preferences",
                            state: { some: "state" }
                          })
                        }
                        className="r-subheading-3 r-text--grey-4 pr-0"
                        color="BrandA"

                      >
                        Manage Preferences
                      </Button>
                      <span className="settings__info-icon mt-1" id="Popover-1" onClick={()=>this.setState({popoverOpen : !this.state.popoverOpen , popoverName : "Popover-1"})}><InfoIcon /></span>
                      <Popover  className="w-100 h-auto  rounded" placement="right" isOpen={this.state.popoverOpen && this.state.popoverName=="Popover-1"} target="Popover-1"  toggle= {()=>this.setState({popoverOpen : !this.state.popoverOpen})}>

                        <PopoverHeader className=" p-3 r-bg--light  w-100 ">
                        <Row>
                        <Col xs="10">
                        <span className="r-subheading-4" align="center">
                        View and download the preferences given by the students for job location, domain & roles
                        </span></Col>
                        <Col xs="2">
                        <button className="border-0 r-bg--light" onClick={()=>this.setState({
                          popoverOpen: !this.state.popoverOpen
                        })}><i className="fi flaticon-plus-3 r-subheading-3"></i></button></Col></Row></PopoverHeader>
                      </Popover>

                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        onClick={e =>
                          this.props.history.push({
                            pathname: "/pt/students/groups",
                            state: { some: "state" }
                          })
                        }
                        className="r-subheading-3 r-text--grey-4 position-relative pr-0"
                        color="BrandA"
                      >
                        Manage Groups
                      </Button>
                      <span className="settings__info-icon mt-1" id="Popover-2" onClick={()=>this.setState({popoverOpen : !this.state.popoverOpen , popoverName : "Popover-2"})}><InfoIcon /></span>
                      <Popover  className="w-100 h-auto  rounded" placement="right" isOpen={this.state.popoverOpen && this.state.popoverName=="Popover-2"} target="Popover-2"  toggle= {()=>this.setState({popoverOpen : !this.state.popoverOpen})}>

                        <PopoverHeader className=" p-3 r-bg--light  w-100 ">
                        <Row>
                        <Col xs="10">
                        <span className="r-subheading-4" align="center">
                        View and Manage student groups. Groups can be created within a batch and only specific groups can be added to a placement drive, event etc.
                        </span></Col>
                        <Col xs="2">
                        <button className="border-0 r-bg--light" onClick={()=>this.setState({
                          popoverOpen: !this.state.popoverOpen
                        })}><i className="fi flaticon-plus-3 r-subheading-3"></i></button></Col></Row></PopoverHeader>
                      </Popover>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs="auto" className="items-align-center">
                      <Button
                        onClick={e =>
                          this.props.history.push({
                            pathname: "/pt/students/batches",
                            state: { some: "state" }
                          })
                        }
                        className="r-subheading-3 r-text--grey-4 position-relative pr-0"
                        color="BrandA"
                      >
                        Manage Batches
                      </Button>
                      <span className="settings__info-icon mt-1" id="Popover-3" onClick={()=>this.setState({popoverOpen : !this.state.popoverOpen, popoverName : "Popover-3"})}><InfoIcon /></span>
                      <Popover  className="w-100 h-auto  rounded" placement="right" isOpen={this.state.popoverOpen && this.state.popoverName=="Popover-3"} target="Popover-3"  toggle= {()=>this.setState({popoverOpen : !this.state.popoverOpen})}>
                        <PopoverHeader className=" p-3 r-bg--light  w-100 ">
                        <Row>
                        <Col xs="10">
                        <span className="r-subheading-4" align="center">
                        View and manage batches within the College. 2014-2018 B.Tech for example can be created as a batch.
                        </span></Col>
                        <Col xs="2">
                        <button className="border-0 r-bg--light" onClick={()=>this.setState({
                          popoverOpen: !this.state.popoverOpen
                        })}><i className="fi flaticon-plus-3 r-subheading-3"></i></button></Col></Row></PopoverHeader>
                      </Popover>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Link to={"/pt/configurations/email"}>
                        <Button
                          className="r-subheading-3 r-text--grey-4 position-relative pr-0"
                          color="BrandA"
                          style={{ fontSize: "14px" }}>
                          Email Settings
                        </Button>
                      </Link>
                        <span className="settings__info-icon mt-1" id="Popover-4" onClick={()=>this.setState({popoverOpen : !this.state.popoverOpen, popoverName: "Popover-4"})}><InfoIcon /></span>
                        <Popover  className="w-100 h-auto  rounded" placement="right" isOpen={this.state.popoverOpen && this.state.popoverName=="Popover-4"} target="Popover-4"  toggle= {()=>this.setState({popoverOpen : !this.state.popoverOpen})}>
                          <PopoverHeader className=" p-3 r-bg--light  w-100 ">
                          <Row>
                          <Col xs="10">
                          <span className="r-subheading-4" align="center">
                          Manage all emails that are triggered through the system. Add Sender's email, BCC, CC lists
                          </span></Col>
                          <Col xs="2">
                          <button className="border-0 r-bg--light" onClick={()=>this.setState({
                            popoverOpen: !this.state.popoverOpen
                          })}><i className="fi flaticon-plus-3 r-subheading-3"></i></button></Col></Row></PopoverHeader>
                        </Popover>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Link to={"/pt/Configurations/excel"}>
                        <Button
                          className="r-subheading-3 r-text--grey-4 position-relative pr-0"
                          color="BrandA"
                          style={{ fontSize: "14px" }}>
                          Excel Download
                        </Button>
                      </Link>
                        <span className="settings__info-icon mt-1" id="Popover-12" onClick={()=>this.setState({popoverOpen : !this.state.popoverOpen, popoverName: "Popover-12"})}><InfoIcon /></span>
                        <Popover  className="w-100 h-auto  rounded" placement="right" isOpen={this.state.popoverOpen && this.state.popoverName=="Popover-12"} target="Popover-12"  toggle= {()=>this.setState({popoverOpen : !this.state.popoverOpen})}>
                          <PopoverHeader className=" p-3 r-bg--light  w-100 ">
                          <Row>
                          <Col xs="10">
                          <span className="r-subheading-4" align="center">
                          Configure excel download settings for student data
                          </span></Col>
                          <Col xs="2">
                          <button className="border-0 r-bg--light" onClick={()=>this.setState({
                            popoverOpen: !this.state.popoverOpen
                          })}><i className="fi flaticon-plus-3 r-subheading-3"></i></button></Col></Row></PopoverHeader>
                        </Popover>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                        <Button onClick={() => this.setState({isOpenUploadStudentAcademicModal: {}})}
                          className="r-subheading-3 r-text--grey-4 position-relative pr-0"
                          color="BrandA"
                          style={{ fontSize: "14px" }}>
                          Upload Student Academic
                        </Button>
                    </Col>
                      <Modal className="modal-dialog-centered" isOpen={!!isOpenUploadStudentAcademicModal}>
                      <form onSubmit={this.uploadStudentAcademic}>

                          <ModalHeader>
                          <h3>Upload Student Academic Detail by CSV</h3>
                          </ModalHeader>
                            <ModalBody>
                                <input required type="file" name="csvFile" className="mt-4 mb-2" />
                                <span className="my-1 d-block" style={{fontSize: 12}}><em>* Download Sample File, Fill the details and upload here</em></span>
                                <a href="https://campus.frontend.s3.ap-south-1.amazonaws.com/Student+Acedemic+Upload+Sample.xlsx" target="_blank" >Download Sample File</a>
                            </ModalBody>
                          <ModalFooter>
                            <Row className="mt-3">
                              <Col className="text-right">
                                  <Button type="submit">Upload Data</Button>{' '}
                                  <Button type="button" onClick={()=> {this.setState({isOpenUploadStudentAcademicModal : false})}}>Cancel</Button>
                              </Col>
                            </Row>
                          </ModalFooter>
                          </form>

                      </Modal>
                  </Row>
                  <Row>
                    <Col>
                        <Button onClick={() => this.setState({isOpenUploadBacklogsModal: {}})}
                          className="r-subheading-3 r-text--grey-4 position-relative pr-0"
                          color="BrandA"
                          style={{ fontSize: "14px" }}>
                          Manage Student Backlogs
                        </Button>
                    </Col>
                      <Modal className="modal-dialog-centered" isOpen={!!isOpenUploadBacklogsModal}>
                      <form onSubmit={(e) => this.uploadBacklogAndScoreData(e, "BACKLOG")}>

                          <ModalHeader>
                          <h3>Upload Student Backlogs Data</h3>
                          </ModalHeader>
                            <ModalBody>
                                <input required type="file" name="csvFile" className="mt-4 mb-2" />
                                <span className="my-1 d-block" style={{fontSize: 12}}><em>* Download Sample File, Fill the details and upload here</em></span>
                                <a href="https://campus.frontend.s3.ap-south-1.amazonaws.com/Backlog+Upload+Sample.xlsx" target="_blank" >Download Sample File</a>
                                <div className="w-100 m-3 pt-2 text-center">
                                  <Button type="button" onClick={() => this.downloadBacklogAndScoreData("BACKLOG")} color="blue-3">Download Backlog Data</Button>
                                </div>
                            </ModalBody>
                          <ModalFooter>
                            <Row className="mt-3">
                              <Col className="text-right">
                                  <Button type="submit">Upload Data</Button>{' '}
                                  <Button type="button" onClick={()=> {this.setState({isOpenUploadBacklogsModal : false})}}>Cancel</Button>
                              </Col>
                            </Row>
                          </ModalFooter>
                          </form>

                      </Modal>
                  </Row>
                  <Row>
                    <Col>
                        <Button onClick={() => this.setState({isOpenUploadSemesterScoreModal: {}})}
                          className="r-subheading-3 r-text--grey-4 position-relative pr-0"
                          color="BrandA"
                          style={{ fontSize: "14px" }}>
                          Manage Semester Wise Score
                        </Button>
                    </Col>
                      <Modal className="modal-dialog-centered" isOpen={!!isOpenUploadSemesterScoreModal}>
                      <form onSubmit={(e) => this.uploadBacklogAndScoreData(e, "SEMESTER_SCORE")}>

                          <ModalHeader>
                          <h3>Upload Score Data</h3>
                          </ModalHeader>
                            <ModalBody>
                                <input required type="file" name="csvFile" className="mt-4 mb-2" />
                                <span className="my-1 d-block" style={{fontSize: 12}}><em>* Download Sample File, Fill the details and upload here</em></span>
                                <a href="https://campus.frontend.s3.ap-south-1.amazonaws.com/semester+score+upload+sample.xlsx" target="_blank" >Download Sample File</a>
                                <div className="w-100 m-3 pt-2 text-center">
                                  <Button type="button" onClick={() => this.downloadBacklogAndScoreData("SEMESTER_SCORE")} color="blue-3">Download Score Data</Button>
                                </div>
                            </ModalBody>
                          <ModalFooter>
                            <Row className="mt-3">
                              <Col className="text-right">
                                  <Button type="submit">Upload Data</Button>{' '}
                                  <Button type="button" onClick={()=> {this.setState({isOpenUploadSemesterScoreModal : false})}}>Cancel</Button>
                              </Col>
                            </Row>
                          </ModalFooter>
                          </form>

                      </Modal>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs="4">
              <Card className="ml-5 mt-3 pt_settings">
                <CardTitle className="m-2 p-3 border-bottom">
                  <span className="r-heading-4 r-text--brandA">
                    Pre-Placement Activities
                  </span>
                </CardTitle>
                <CardBody className="settings__placement-activities">
                  <Row>
                    <Col xs="8">
                      <Button
                        className="r-subheading-3 r-text--grey-4 position-relative pr-0"
                        color="BrandA"
                        style={{ fontSize: "14px" }}
                        onClick={() =>this.setState({value : !this.state.value, openCollapseValue : 1})}
                      >
                        Multiple Offer Rules
                        </Button>
                        <span className="settings__info-icon mt-1" id="Popover-5" onClick={()=>this.setState({popoverOpen : !this.state.popoverOpen, popoverName: "Popover-5"})}><InfoIcon /></span>
                        <Popover  className="w-100 h-auto  rounded" placement="right" isOpen={this.state.popoverOpen && this.state.popoverName=="Popover-5"} target="Popover-5"  toggle= {()=>this.setState({popoverOpen : !this.state.popoverOpen})}>
                          <PopoverHeader className=" p-3 r-bg--light  w-100 ">
                          <Row>
                          <Col xs="10">
                          <span className="r-subheading-4" align="center">
                          Define your placement policy and place restrictions on each student's applications. Ex - with 1 offer a candidate can be restricted to apply to only two more "dream" companies. Company Categories (dream, superdream, etc.) can be defined  through Admin login.
                          </span></Col>
                          <Col xs="2">
                          <button className="border-0 r-bg--light" onClick={()=>this.setState({
                            popoverOpen: !this.state.popoverOpen
                          })}><i className="fi flaticon-plus-3 r-subheading-3"></i></button></Col></Row></PopoverHeader>
                        </Popover>
                    </Col>
                    <Col xs="4" className="mt-2" align="end">
                      <span className="r-subheading-4 mt-1 r-text--green-1">
                        {this.state.processList != null
                          ? this.state.processList.length
                          : 0}{" "}
                        Drives
                      </span>
                    </Col>
                    <Col>
                      <Collapse
                        isOpen={this.state.value  && this.state.openCollapseValue==1}
                        className="m-1 ml-3"
                      >
                        {this.state.processList &&
                          this.state.processList.map(process => {
                            return (
                              <div key={process.process_id}>
                                <Row>
                                  <Col className="pr-0 text-truncate">
                                    <Button
                                      className="r-subheading-3 r-text--grey-4"
                                      color="BrandA"
                                      onClick={() =>
                                        this.setState({editApplicationModal: !this.state.editApplicationModal, defaultProcess : process})
                                      }
                                    >
                                      {" "}
                                      {process.process_name}
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                      </Collapse>
                    </Col>
                  </Row>

                  {this.state.defaultProcess && <Modal
                    zIndex="99999"
                    size="xl"
                    isOpen={this.state.editApplicationModal}
                    toggle={()=>this.closeApplicationModal()}
                  >
                  <ModalHeader
                    toggle={
                      ()=>this.setState({editApplicationModal : !this.state.editApplicationModal})
                    }
                  >
                    <span className="r-heading-4">Edit Offer Rules </span>
                  </ModalHeader>
                  <MultipleOfferSystem process={this.state.defaultProcess}  />
                  </Modal>}
                  <Row>
                    <Col>
                      <Button
                        className="r-subheading-3 r-text--grey-4"
                        color="BrandA"
                        style={{ fontSize: "14px" }}
                      >
                        Roles And Permissions
                      </Button>
                        <span className="settings__comming-soon">
                          (Coming soon)
                        </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        onClick={e => this.props.history.push("/pt/attendance")}
                        className="r-subheading-3 r-text--grey-4 position-relative pr-0"
                        color="BrandA"
                        style={{ fontSize: "14px" }}
                      >
                        Attendance
                      </Button>
                      <span className="settings__info-icon mt-1" id="Popover-6" onClick={()=>this.setState({popoverOpen : !this.state.popoverOpen, popoverName: "Popover-6"})}><InfoIcon /></span>
                      <Popover  className="w-100 h-auto  rounded" placement="right" isOpen={this.state.popoverOpen && this.state.popoverName=="Popover-6"} target="Popover-6"  toggle= {()=>this.setState({popoverOpen : !this.state.popoverOpen})}>
                        <PopoverHeader className=" p-3 r-bg--light  w-100 ">
                        <Row>
                        <Col xs="10">
                        <span className="r-subheading-4" align="center">
                        Capture and manage attendance for all the events created through the calendar feature
                        </span></Col>
                        <Col xs="2">
                        <button className="border-0 r-bg--light" onClick={()=>this.setState({
                          popoverOpen: !this.state.popoverOpen
                        })}><i className="fi flaticon-plus-3 r-subheading-3"></i></button></Col></Row></PopoverHeader>
                      </Popover>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        onClick={() => this.setState({ cvSettingModal : !this.state.cvSettingModal})}
                        className="r-subheading-3 r-text--grey-4 position-relative pr-0"
                        color="BrandA"
                        style={{fontSize:"14px"}}>
                        CV Setting
                        </Button>
                        <span className="settings__info-icon mt-1" id="Popover-7" onClick={()=>this.setState({popoverOpen : !this.state.popoverOpen, popoverName: "Popover-7"})}><InfoIcon /></span>
                        <Popover  className="w-100 h-auto  rounded" placement="right" isOpen={this.state.popoverOpen && this.state.popoverName=="Popover-7"} target="Popover-7"  toggle= {()=>this.setState({popoverOpen : !this.state.popoverOpen})}>
                          <PopoverHeader className=" p-3 r-bg--light  w-100 ">
                          <Row>
                          <Col xs="10">
                          <span className="r-subheading-4" align="center">
                          Multiple options for CV creation, verification etc. can be managed here
                          </span></Col>
                          <Col xs="2">
                          <button className="border-0 r-bg--light" onClick={()=>this.setState({
                            popoverOpen: !this.state.popoverOpen
                          })}><i className="fi flaticon-plus-3 r-subheading-3"></i></button></Col></Row></PopoverHeader>
                        </Popover>
                        <Modal zIndex="99999" size="md" isOpen={this.state.cvSettingModal} toggle={() => this.setState({ cvSettingModal : !this.state.cvSettingModal})}>
                            <ModalHeader toggle={async () => await this.setState({ cvSettingModal : !this.state.cvSettingModal})} ><span className="r-subheading-3">Assign permission to manage cv </span></ModalHeader>
                            <ModalBody>
                             
                              {selectedBatch ? <>
                              <span className="d-block r-subheading-3 mt-4 border-bottom">Generic Configuration</span>
                              <ul className="pl-5">
                                <li>
                                  <Row className="justify-content-between align-items-center mx-0 mt-2">
                                    <span>Pt-team can upload cv</span>
                                    <input name="ptUpload"  type="checkbox" value={ptUpload} checked={ptUpload} onChange={() => this.setState({ ptUpload : !ptUpload})}/>
                                  </Row>
                                </li>
                              </ul>
                              <span className="d-block r-subheading-3 mt-4 border-bottom">Batch Configuration</span>
                              <Row className="mt-3 mb-3">
                                <Col xs={5} className="ml-5">
                                  <SelectList selected={selectedBatch ? batchList.filter(batch => batch.batch_id === selectedBatch)[0].batch_name : "Select Batch"}>
                                    {batchList.map(batch => 
                                      <li onClick={() => this.selectBatch(batch)}>{batch.batch_name}</li>
                                    )}
                                  </SelectList>
                                </Col>
                              </Row>
                              <ul className="pl-5">
                                <li>
                                  <Row className="justify-content-between align-items-center mx-0">
                                    <span>Student can upload cv</span>
                                    <input name="stUpload"  type="checkbox" value={stUpload} checked={stUpload} onChange={() => this.setState({ stUpload : !stUpload})} />
                                  </Row>
                                </li>
                                <li>
                                  <Row className="justify-content-between align-items-center mx-0">
                                    <span>Use cv template</span>
                                    <input name="temUpload"  type="checkbox" value={temUpload} checked={temUpload} onChange={() => this.setState({ temUpload : !temUpload})}  />
                                  </Row>
                                </li>
                                <li>
                                  <Row className="justify-content-between align-items-center mx-0">
                                    <span>Use cv toolbar</span>
                                    <input name="cvToolbar"  type="checkbox" value={cvToolbar} checked={cvToolbar} onChange={() => this.setState({ cvToolbar : !cvToolbar})}  />
                                  </Row>
                                </li>
                                <li>
                                  <Row className="justify-content-between align-items-center mx-0">
                                    <span>Show unverified points on variants</span>
                                    <input name="showUnverified"  type="checkbox" value={showUnverified} checked={showUnverified} onChange={() => this.setState({ showUnverified : !showUnverified})}  />
                                  </Row>
                                </li>
                                <li>
                                  <Row className="justify-content-between align-items-center mx-0">
                                    <span>Enable section reorder in the CV</span>
                                    <input name="enableSectionDrag"  type="checkbox" value={enableSectionDrag} checked={enableSectionDrag} onChange={() => this.setState({ enableSectionDrag : !enableSectionDrag})}  />
                                  </Row>
                                </li>
                                <li>
                                  <Row className="justify-content-between align-items-center mx-0">
                                    <span>Use CV Lock as PDF</span>
                                    <input name="enableSectionDrag"  type="checkbox" value={cvLock} checked={cvLock} onChange={() => this.setState({ cvLock : !cvLock})}  />
                                  </Row>
                                </li>
                                <li>
                                  <Row className="justify-content-between align-items-center mx-0">
                                    <span>Restrict to One page CV</span>
                                    <input name="isSinglePageCV"  type="checkbox" value={isSinglePageCV} checked={isSinglePageCV} onChange={() => this.setState({ isSinglePageCV : !isSinglePageCV})}  />
                                  </Row>
                                </li>
                              </ul>
                                  <Row  className="justify-content-end align-items-center py-5  mx-0 ">
                                        <Button type="button" onClick={this.createcvConfig} className="font-weight-normal mx-3" color="dark r-btn--no-radius">Save</Button>
                                  </Row>
                                
                               </> 
                               :
                               <Row className="text-center text-danger r-text--sm mt-2"><Col><span>No Batch Added</span></Col></Row>
                              }
                            </ModalBody>
                        </Modal>
                    </Col>
                  </Row>
                  <CVCategory/>
                  <ManageSpecialization/>
                </CardBody>
              </Card>
            </Col>
            <Col xs="4">
              <Card className="ml-5 mt-3 pt_settings">
                <CardTitle className="m-2 p-3 border-bottom">
                  <span className="r-heading-4 r-text--brandA">
                    {"  "} Placement Activities{" "}
                  </span>
                </CardTitle>
                <Row>
                  <Col>
                    <CardBody className="settings__placement-activities">
                      <Row>
                        <Col>
                          <Row>
                            <Col xs="7">
                              <Button
                                onClick={() => this.setState({value : !this.state.value, openCollapseValue : 2})}
                                className="r-subheading-3 r-text--grey-4 position-relative pr-0"
                                color="BrandA"
                                style={{ fontSize: "14px" }}
                              >
                                Placement Drives
                              </Button>
                              <span className="settings__info-icon mt-1" id="Popover-8" onClick={()=>this.setState({popoverOpen : !this.state.popoverOpen, popoverName: "Popover-8"})}><InfoIcon /></span>
                              <Popover  className="w-100 h-auto  rounded" placement="right" isOpen={this.state.popoverOpen && this.state.popoverName=="Popover-8"} target="Popover-8"  toggle= {()=>this.setState({popoverOpen : !this.state.popoverOpen})}>
                                <PopoverHeader className=" p-3 r-bg--light  w-100 ">
                                <Row>
                                <Col xs="10">
                                <span className="r-subheading-4" align="center">
                                All open Placement Drives are listed here. They can be edited, deleted, archived from here
                                </span></Col>
                                <Col xs="2">
                                <button className="border-0 r-bg--light" onClick={()=>this.setState({
                                  popoverOpen: !this.state.popoverOpen
                                })}><i className="fi flaticon-plus-3 r-subheading-3"></i></button></Col></Row></PopoverHeader>
                              </Popover>
                            </Col>
                            <Col xs="5" className="mt-2" align="end">
                              <span className="r-subheading-4 mt-1 r-text--green-1">
                                {this.state.processList != null
                                  ? this.state.processList.length
                                  : 0}{" "}
                                Drives
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Collapse
                                isOpen={this.state.value && this.state.openCollapseValue == 2}
                                className="m-1 ml-3"
                              >
                                {this.state.processList &&
                                  this.state.processList.map(process => {
                                    return (
                                      <div key={process.process_id}>
                                        <Row>
                                          <Col
                                            xs="6"
                                            className="pr-0 text-truncate"
                                          >
                                            <Button
                                              className="r-subheading-3 r-text--grey-4"
                                              color="BrandA"
                                              style={{ fontSize: "14px" }}
                                            >
                                              {" "}
                                              {process.process_name}
                                            </Button>
                                          </Col>
                                          <Col xs="1" className="mt-1">
                                            <i
                                              className="fi flaticon-edit"
                                              onClick={async () =>
                                                await this.setState({
                                                  selectedProcessID:
                                                    process.process_id,
                                                  selectedProcessName:
                                                    process.process_name,
                                                  selectedProcessType:
                                                    process.process_type,
                                                  modal: true,
                                                  updateProcess : process.process_name
                                                })
                                              }
                                            />
                                          </Col>
                                          <Col xs="3">
                                            <Button
                                              onClick={() =>
                                                this.archiveProcess(process)
                                              }
                                              type="submit"
                                              className="r-bg--grey-1 r-text--grey-4 border-0 r-subheading-4 r-btn p-1 px-2"
                                            >
                                              Archive
                                            </Button>
                                          </Col>
                                          <Col xs="1" className="mt-1">
                                            <i
                                              className="fi flaticon-bin"
                                              onClick={async () =>
                                                await this.setState({
                                                  selectDeleteProcess: process,
                                                  modal_1: !this.state.modal_1
                                                })
                                              }
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  })}
                              </Collapse>
                              <Modal
                                isOpen={this.state.modal_1}
                                toggle={() =>
                                  this.setState({
                                    modal_1: !this.state.modal_1,

                                  })
                                }
                                className="calendar__invitation-dialog"
                              >
                                <ModalBody>
                                  <span className="r-subheading-4">
                                    This action can't be undone, do you want to
                                    proceed?
                                  </span>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    onClick={() =>
                                      this.deleteProcess(
                                        this.state.selectDeleteProcess
                                          .process_id
                                      )
                                    }
                                    className="pr-4 pl-4  r-text--light border-0 r-subheading-4"
                                    color="dark"
                                  >
                                    Delete
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      this.setState({
                                        modal_1: !this.state.modal_1
                                      })
                                    }
                                    className="ml-3 pr-4 pl-4 r-bg--grey-2 r-text--grey-4 border-0 r-subheading-4"
                                  >
                                    Cancel
                                  </Button>
                                </ModalFooter>
                              </Modal>
                              <Modal
                                zIndex="99999"
                                isOpen={this.state.modal}
                                toggle={() =>
                                  this.setState({ modal: !this.state.modal, selectedProcessID: null,
                                  selectedProcessName:null,
                                  selectedProcessType : null })
                                }
                              >
                                <ModalBody>
                                  <span className="modal__title r-heading-6">
                                    Edit Process
                                  </span>
                                  <div className="mt-3">
                                    <label className="r-subheading-4">
                                      Process Name
                                    </label>
                                    <InputField
                                      defaultValue={
                                        this.state.selectedProcessName
                                      }
                                      name="process_name"
                                      className="w-100 border-0"
                                      type="text"
                                      placeholder="Enter Process Name"
                                      onChange={this.SetNewProcess}
                                      value  ={this.state.updateProcess}
                                    />
                                    <label className="mt-2 r-subheading-4">
                                      Process Type
                                    </label>
                                    <div>
                                      {this.state.selectedProcessType && (
                                        <select
                                          className="w-100 r-bg--grey-1 border-0 p-2"
                                          onChange={this.setNewProcessType}
                                          value={this.state.selectedProcessType}
                                        >
                                          {Object.keys(
                                            CONSTANTS.PROCESS_TYPES
                                          ).map(processType => (
                                            <option value={processType}>
                                              {
                                                CONSTANTS.PROCESS_TYPES[
                                                  processType
                                                ]
                                              }
                                            </option>
                                          ))}
                                        </select>
                                      )}
                                    </div>
                                    <div className="text-right mt-3">
                                      <Button
                                        type="submit"
                                        color="dark"
                                        onClick={this.updateProcess}
                                        className="r-text--sm r-btn--no-radius"
                                      >
                                        Save
                                      </Button>
                                    </div>
                                  </div>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="7">
                          <Button
                            onClick={() => this.setState({value : !this.state.value , openCollapseValue : 3})}
                            className="r-subheading-3 r-text--grey-4 position-relative pr-0"
                            color="BrandA"
                            style={{ fontSize: "14px" }}
                          >
                            {" "}
                            Archives
                          </Button>
                          <span className="settings__info-icon mt-1" id="Popover-9" onClick={()=>this.setState({popoverOpen : !this.state.popoverOpen, popoverName: "Popover-9"})}><InfoIcon /></span>
                          <Popover  className="w-100 h-auto  rounded" placement="right" isOpen={this.state.popoverOpen && this.state.popoverName=="Popover-9"} target="Popover-9"  toggle= {()=>this.setState({popoverOpen : !this.state.popoverOpen})}>
                            <PopoverHeader className=" p-3 r-bg--light  w-100 ">
                            <Row>
                            <Col xs="10">
                            <span className="r-subheading-4" align="center">
                            Placement drives that are completed appear here
                            </span></Col>
                            <Col xs="2">
                            <button className="border-0 r-bg--light" onClick={()=>this.setState({
                              popoverOpen: !this.state.popoverOpen
                            })}><i className="fi flaticon-plus-3 r-subheading-3"></i></button></Col></Row></PopoverHeader>
                          </Popover>
                        </Col>
                        <Col xs="5" className="mt-2" align="end">
                          <span className="r-subheading-4 mt-1 r-text--green-1">
                            {this.state.archiveProcessList != null
                              ? this.state.archiveProcessList.length
                              : 0}{" "}
                            Drives
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Collapse
                            isOpen={this.state.value && this.state.openCollapseValue==3}
                            className="m-1 ml-3"
                          >
                            {this.state.archiveProcessList &&
                              this.state.archiveProcessList.map(process => {
                                return (
                                  <div key={process.process_id}>
                                    <Row>
                                      <Col
                                        xs="8"
                                        className="pr-0 text-truncate"
                                      >
                                        <Button
                                          className="r-subheading-3 r-text--grey-4"
                                          color="BrandA"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {process.process_name}
                                        </Button>
                                      </Col>

                                      <Col xs="4" align="end">
                                        <Button
                                          type="submit"
                                          onClick={() =>
                                            this.unarchiveProcess(process)
                                          }
                                          className="r-bg--grey-1 r-text--grey-4 border-0 r-subheading-4 r-btn p-1 px-2"
                                        >
                                          Unarchive
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              })}
                          </Collapse>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="8" align="start">
                          <Button
                            onClick={() => this.setState({value : !this.state.value , openCollapseValue : 4})}
                            className="r-subheading-3 r-text--grey-4 position-relative pr-0"
                            color="BrandA"
                            style={{ fontSize: "14px" }}
                          >
                            Verification
                          </Button>
                          <span className="settings__info-icon mt-1" id="Popover-10" onClick={()=>this.setState({popoverOpen : !this.state.popoverOpen, popoverName: "Popover-10"})}><InfoIcon /></span>
                          <Popover  className="w-100 h-auto  rounded" placement="right" isOpen={this.state.popoverOpen && this.state.popoverName=="Popover-10"} target="Popover-10"  toggle= {()=>this.setState({popoverOpen : !this.state.popoverOpen})}>
                            <PopoverHeader className=" p-3 r-bg--light  w-100 ">
                            <Row>
                            <Col xs="10">
                            <span className="r-subheading-4" align="center">
                            Add verifiers who are responsible for verifying student data. Students can be mapped to different verifiers
                            </span></Col>
                            <Col xs="2">
                            <button className="border-0 r-bg--light" onClick={()=>this.setState({
                              popoverOpen: !this.state.popoverOpen
                            })}><i className="fi flaticon-plus-3 r-subheading-3"></i></button></Col></Row></PopoverHeader>
                          </Popover>
                        </Col>
                        <Col xs="4" className="mt-2" align="end">
                          <span className="r-subheading-4 mt-1 r-text--green-1">
                            {this.state.processList != null
                              ? this.state.processList.length
                              : 0}{" "}
                            Drives
                          </span>
                        </Col>
                        <Col>
                          <Collapse
                            isOpen={this.state.value && this.state.openCollapseValue==4}
                            className="m-1 ml-3"
                          >
                            {this.state.processList &&
                              this.state.processList.map(process => {
                                return (
                                  <div key={process.process_id}>
                                    <Row>
                                      <Col className="pr-0 text-truncate">
                                        <Link
                                          to={
                                            "/pt/processes/" +
                                            process.process_id +
                                            "/verifiers"
                                          }
                                        >
                                          <Button
                                            className="r-subheading-3 r-text--grey-4"
                                            color="BrandA"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            {process.process_name}
                                          </Button>
                                        </Link>
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              })}
                          </Collapse>
                        </Col>
                      </Row>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <br/>
          <Row className="m-4">
            <Col>
              <span className="r-subheading-1">
                <Row>
                  <Col xs="1">
                    {/* <Link to={"/pt/"}>
                      <img
                        src="/images/left-arrow.png"
                        alt="back-button"
                        height="30"
                      />
                    </Link> */}
                  </Col>
                  <Col xs="auto" align="start">
                    <span>Help Section </span>
                  </Col>
                </Row>
              </span>
            </Col>
          </Row> 
          <Row>
            <Col xs="4">
              <Card className="ml-5 mt-3 pt_settings">
                <CardTitle className="m-2 p-3 border-bottom">
                  <span className="r-heading-4 r-text--brandA">{"  "} Students </span>
                </CardTitle>
                <CardBody className="settings__placement-activities">
                  {Object.keys(HELP_SECTION_CONFIG.studentTab).map(section => 
                  <Row className="">
                    <Col>
                      <Button
                        onClick={e =>
                         this.setState({isOpenHelpModal: HELP_SECTION_CONFIG.studentTab[section]})
                        }
                        className="r-subheading-3 r-text--grey-4 pr-0"
                        color="BrandA"

                      >
                        {section}
                      </Button>                      
                    </Col>
                  </Row> )}
                </CardBody>
              </Card>  
            </Col>
            <Col xs="4">
              <Card className="ml-5 mt-3 pt_settings">
                <CardTitle className="m-2 p-3 border-bottom">
                  <span className="r-heading-4 r-text--brandA">{"  "} Companies </span>
                </CardTitle>
                <CardBody className="settings__placement-activities">
                  {Object.keys(HELP_SECTION_CONFIG.companiesTab).map(section => 
                  <Row className="">
                    <Col>
                      <Button
                        onClick={e =>
                         this.setState({isOpenHelpModal: HELP_SECTION_CONFIG.companiesTab[section]})
                        }
                        className="r-subheading-3 r-text--grey-4 pr-0"
                        color="BrandA"

                      >
                        {section}
                      </Button>                      
                    </Col>
                  </Row> )}
                </CardBody>
              </Card>  
            </Col>
            <Col xs="4">
              <Card className="ml-5 mt-3 pt_settings">
                <CardTitle className="m-2 p-3 border-bottom">
                  <span className="r-heading-4 r-text--brandA">{"  "} Placements </span>
                </CardTitle>
                <CardBody className="settings__placement-activities">
                  {Object.keys(HELP_SECTION_CONFIG.placementsTab).map(section => 
                  <Row className="">
                    <Col>
                      <Button
                        onClick={e =>
                         this.setState({isOpenHelpModal: HELP_SECTION_CONFIG.placementsTab[section]})
                        }
                        className="r-subheading-3 r-text--grey-4 pr-0"
                        color="BrandA"

                      >
                        {section}
                      </Button>                      
                    </Col>
                  </Row> )}   
                </CardBody>
              </Card>  
            </Col> 
          </Row>    
        </div>
        <Modal
          isOpen={!!this.state.isOpenHelpModal}
          size="lg"
          zIndex="99999"
          toggle={() => this.setState({ isOpenHelpModal: false })}
        >
          
          <ModalBody>
            {(this.state.isOpenHelpModal || []).map((item) => {
                return (
                  Object.keys(item).length > 0 &&
                  Object.keys(item).map((subItem) => {
                    if (subItem == "video" && item[subItem]) {
                      return (
                        <Row key={subItem}>
                          <Col className="m-4 p-3 border">
                            <center>
                              <video id="video1" width="500" controls>
                                <source src={item[subItem]} type="video/mp4" />
                                Your browser does not support HTML5 video.
                              </video>
                            </center>
                          </Col>
                        </Row>
                      );
                    }
                    if (subItem == "text") {
                      return (
                        <Row key={subItem}>
                          <Col className="m-1">
                            <ul>
                              <li key={subItem}>{item[subItem]}</li>
                            </ul>
                          </Col>
                        </Row>
                      );
                    }
                    if (subItem == "image" && item[subItem]) {
                      return (
                        <Row key={subItem}>
                          <Col className="m-4 p-3 border">
                            <img
                              src={"/images/help_section/" + item[subItem]}
                              className="img-fluid"
                              alt="Help Section"
                            />
                          </Col>
                        </Row>
                      );
                    }
                  })
                );
              })}
          </ModalBody>
        </Modal>
      <Errorbar showBar={this.state.errorbar} isSuccess={this.state.isSuccess} onClose={this.closebar} message={this.state.message} />
      </Container>

    );
  }
}

const mapStateToProps = state => ({
  ...state
});
export default connect(
  mapStateToProps,
  null
)(Settings);

