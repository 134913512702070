import React,{Component} from 'react';
import {Row, Col, Container, Button} from 'reactstrap';
import Link from 'react-router-dom/Link';
import {  CardBody, CardTitle, CardText, Card, Collapse} from 'reactstrap';
import { connect } from 'react-redux';
import { Auth } from '../../../actions/Auth';
import  {Switch} from '../../global/Forms/ToggleSwitch';
import Modal from 'reactstrap/lib/Modal';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { InputField } from '../../global/Forms';
import { CONSTANTS } from '../../../constants';
import { SelectList, Select } from '../../global/Forms/Select';
import { Loader } from '../../global/Loader';
import Aux from '../../global/AuxComp';
import { ToggleButton } from '../../global/Forms/ToggleButton';
import {Multifield} from '../../global/Forms/Multifield';
import { Errorbar } from '../../global/errorbar';

class ExcelDownload extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState= ()=>{
    return({
      profileSectionCollapse : false,
      defaultProfileSectionsList : null,
      defaultProfileSubSections : null,
      profileSectionsList : null,
      profileSubSections : null,
      dragOverIndex : null,
      draggedIndex : null,
      sections : null,
      sectionCheckboxSelected : false,
      list: [],
      subSections : null,
      filterList : [],
      filterListSubsection : {},
      downlodOnlyVerified : false,
      singleDegreeOnly: false,
      noDegreeDepartmentForPersuing: false,
      loading : false,
      errorbar: false,
      message: ''
    })
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  reOrderSections = (sections, value, profileSection) => {
    let {profileSectionsList , profileSubSections} =this.state;
    let sectionOrder = [];
    sections.forEach((section,i)=>{
      sectionOrder[i.toString()] = section
    })
    if(value==1){
      profileSectionsList = sectionOrder;
      this.setState({
        profileSectionsList
      })
    }
    if(value==2){
      profileSubSections[profileSection] = sectionOrder;
      this.setState({
        profileSubSections
      })
    }
  }


  onDragStartSubsection = (e, index , profileSection) => {
    let {profileSubSections} = this.state;
    this.draggedItem = profileSubSections[profileSection][index];
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  }

  onDragOverSubsection = (index , profileSection) => {
    let {subSections} = this.state;
    const draggedOverItem = {};
    if (this.draggedItem === draggedOverItem) {
      return;
    }
    let section = subSections[profileSection].filter(item => item.id !== this.draggedItem.id);
    section.splice(index, 0, this.draggedItem);
    this.reOrderSections(section , 2, profileSection);
  }

  onDragEndSubsection = () => {
    this.draggedItem = null;
  }

  sections= ()=>{
    let {profileSectionsList , profileSubSections} = this.state;
    let subSections = profileSubSections
    let sections = profileSectionsList;
      this.setState({sections , subSections});
  }

  onDragStart = (e, index) => {
    let {profileSectionsList} = this.state;
    this.draggedItem = profileSectionsList[index];
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  }


  onDragOver = (index) => {
    let {sections} = this.state;
    const draggedOverItem = sections[index];
    if (this.draggedItem === draggedOverItem) {
      return;
    }
    let section = sections.filter(item => item.header !== this.draggedItem.header)
    section.splice(index, 0, this.draggedItem);
    this.reOrderSections(section , 1);
  }

  onDragEnd = () => {
    this.draggedItem = null;
  }

  formatSectionHeaderList =  (section)=>{
    let {profileSectionsList , profileSubSections} = this.state ;
    let obj = [...profileSectionsList];
    obj[section].status = !profileSectionsList[section].status;
    if(obj[section].status==false){
      profileSubSections[obj[section].header].forEach(item=>{
        item.status=false
      })
    }
    if(obj[section].status==true){
      profileSubSections[obj[section].header].forEach(item=>{
        item.status=true
      })
    }
    this.setState({profileSectionsList : obj , profileSubSections});
}

  formatSubSectionList = (header , section)=>{
    let {profileSubSections} = this.state;
    let obj = {...profileSubSections};
    obj[header][section].status = !profileSubSections[header][section].status;
    this.setState({
      profileSubSections : obj
    })
  }

  formatSectionHeaderFilterList = (section)=>{
    let {filterList , filterListSubsection} = this.state;
    let obj = [...filterList];
    obj[section].status = !filterList[section].status;
    if(obj[section].status==false){
      filterListSubsection[obj[section].header].forEach(item=>{
        item.status=false
      })
    }
    if(obj[section].status==true){
      filterListSubsection[obj[section].header].forEach(item=>{
        item.status=true
      })
    }
    this.setState({filterList : obj });
  }

  formatSubSectionFilterList = (header , section)=>{
    let {filterListSubsection} = this.state;
    let obj = {...filterListSubsection};
    obj[header][section].status = !filterListSubsection[header][section].status;
    this.setState({
      filterListSubsection : obj
    })
  }

  filterSectionNewList = ()=>{
    let {profileSectionsList , defaultProfileSectionsList , defaultProfileSubSections , profileSubSections} = this.state;
    let filterList = [];
    let filterListSubsection = {}
    if(profileSubSections && profileSectionsList){
      for(let i = 0; i<defaultProfileSectionsList.length; i++){
        let flag=0;
        for(let j=0 ; j< profileSectionsList.length ; j++){
          if(profileSectionsList[j].header == defaultProfileSectionsList[i].header){
            flag=1;
          }
        }

        if(flag==0){
          let changeStatus = {...defaultProfileSectionsList[i]};
          changeStatus.status = false;
          filterList.push(changeStatus);
        }
        if(filterList.length>0){
          let finalFilter = {...filterListSubsection};
          for(let i=0 ;i<filterList.length ; i++){
            filterListSubsection[filterList[i].header] = defaultProfileSubSections[filterList[i].header];
            for(let j=0 ; j<filterListSubsection[filterList[i].header].length ; j++ ){
              filterListSubsection[filterList[i].header][j].status = false;
            }
          }
        }
        this.setState({filterList , filterListSubsection})
      }
    }
    else{
      this.setState({filterList : [...defaultProfileSectionsList] , filterListSubsection : {...defaultProfileSubSections}})
    }
  }

  getCsvList = async()=>{
    this.setState({loading : true});
    try{
      let csvResp = await Auth.CsvDownload.getList();
      await this.setState({
        defaultProfileSectionsList : csvResp.data.defaultConfig.profileRecordMasterHeader,
        defaultProfileSubSections : csvResp.data.defaultConfig.profileDownloadSetting.headerDefaultList,
        profileSectionsList : csvResp.data.config && csvResp.data.config.profileRecordMasterHeader? csvResp.data.config.profileRecordMasterHeader : csvResp.data.defaultConfig.profileRecordMasterHeader,
        profileSubSections : csvResp.data.config && csvResp.data.config.profileDownloadSetting? csvResp.data.config.profileDownloadSetting.headerDefaultList : csvResp.data.defaultConfig.profileDownloadSetting.headerDefaultList,
        downlodOnlyVerified : (csvResp.data.config && csvResp.data.config.downlodOnlyVerified) || false,
        singleDegreeOnly: csvResp.data.config && csvResp.data.config.singleDegreeOnly === false ? false : true,
        noDegreeDepartmentForPersuing: csvResp.data.config && csvResp.data.config.noDegreeDepartmentForPersuing === false ? false : true,
      })
      this.filterSectionNewList();
    }
    catch(e){
      console.log(e);
      if(e.response && e.response.data){
        this.setState({ errorbar: true });
        this.setState({ message: e.response.data.error.toString()});
      }
    }
    this.setState({loading : false});
  }

  saveExcelDownloadSettings = async()=>{
    this.setState({loading : true});
    let {profileSubSections , profileSectionsList , filterList , filterListSubsection} = this.state;
    let finalProfileSectionList  =  profileSectionsList!=null? [...profileSectionsList] : [];
    let finalProfileSubSections  = profileSubSections!=null? {...profileSubSections} : {};
    let finalFilterList = [...filterList];
    let finalFilterListSubsection = {...filterListSubsection};
    if(finalFilterList.length > 0){
      for(let i =0 ; i<finalFilterList.length ; i++){
        if(finalFilterList[i].status==true){
          finalProfileSectionList.push(finalFilterList[i]);
        }
      }
    }
    if(Object.keys(finalFilterListSubsection).length){
      Object.keys(finalFilterListSubsection).forEach((item)=>{
        finalProfileSubSections[item] = finalFilterListSubsection[item];
        // for(let j = 0 ;j<finalProfileSubSections[item].length ; j++){
        //   console.log("items" , finalFilterListSubsection[item][j]);
        //   if(finalFilterListSubsection[item][j].status==false){
        //     finalProfileSubSections[item].splice(j,1);
        //     j = j-1;
        //   }
        // }
        if(!finalProfileSubSections[item].length>0){
          delete finalProfileSubSections[item];
        }
      })
    }
    try{
      let csvFormData = {
        "config_type": "STUDENT_PROFILE_DOWNLOAD",
        "config": {
          "profileRecordMasterHeader": finalProfileSectionList,
          "profileDownloadSetting": {
            "path": "student_profile.csv",
            "headerDefaultList": finalProfileSubSections
          },
          "downlodOnlyVerified" : this.state.downlodOnlyVerified ,
          "singleDegreeOnly" : this.state.singleDegreeOnly,
          "noDegreeDepartmentForPersuing" : this.state.noDegreeDepartmentForPersuing
        }
      }
    await Auth.CsvDownload.createExcelList(csvFormData);
    this.getCsvList();
    }
    catch(e){
      console.log(e);
      if(e.response && e.response.data){
        this.setState({ errorbar: true });
        this.setState({ message: e.response.data.error.toString()});
      }
    }
    this.setState({loading : false});
  }

  async componentDidMount(){
    await this.getCsvList();
     this.sections();
  }

  render(){
    let {profileSectionsList , profileSubSections , sectionsCheckbox , defaultProfileSubSections, filterListSubsection , defaultProfileSectionsList , filterList} = this.state;
    let profileSections = {...CONSTANTS.profileSections};
    return(
      <Container >
      <Loader show={this.state.loading} />
        <div>
          <Row className="m-4">
            <Col>
              <span className="r-subheading-1">
                <Row>
                  <Col xs="auto">
                    <Link to={"/pt/settings"}>
                      <img src="/images/left-arrow.png" alt="back-button" height="30"  />
                    </Link>
                    <span  className="ml-3">Excel Download Configurations </span>
                  </Col>
                </Row>
              </span>
            </Col>
          </Row>
        </div>
        <div className="mt-5 ml-5 mb-3 w-50">
          <Row className="m-1">
            <Col xs={8} >
              <span className="r-heading-6">Select</span>
            </Col>
            <Col xs={4} align="center">
              <Button color="dark" onClick={()=>this.saveExcelDownloadSettings()}>Save</Button>
            </Col>
          </Row>
        </div>
        {/* <ToggleButton buttonType="pt" values={{}}  selected={null} onToggle={console.log} /> */}
        <Row className="w-50 ml-5 mt-5">
            <Col xs={8}>
                <span>Download only verified details ?</span>
            </Col>
            <Col xs={4} className="pl-5">
              <Switch className="ml-5" isOn={this.state.downlodOnlyVerified} handleToggle={() => this.setState({downlodOnlyVerified : !this.state.downlodOnlyVerified})} />
            </Col>
        </Row>
        <div className="mt-3 ml-5 mb-3">
        {
          profileSectionsList &&  profileSectionsList.map((section , i)=>{
            let wrapperProps = {};
            let overlayProps = {};
            if(this.state.profileSectionCollapse==false){
              wrapperProps = {
                onDragOver: () => this.onDragOver(i)
              }
              overlayProps = {
                draggable: this.state.profileSectionCollapse? false : true,
                onDragEnd: this.onDragEnd,
                onDragStart: (e) => this.onDragStart(e, i)
              }
            }
            return(

            <div className="mt-3 border w-50"  {...wrapperProps}>
            <Row className="m-1" {...overlayProps}>
                <Col xs={8} className="mb-3 mt-3">
                  <input type="checkbox" className="settings__excel_checkbox" checked={section.status} onChange={()=>this.formatSectionHeaderList(i)} id={profileSections[section.header]}  /> <span className="r-subheading-4 r-text--grey-4 pr-0 ml-1"> {profileSections[section.header]}</span>
                </Col>
                {profileSubSections[section.header].length > 1 && <Col xs={4} align="center" className="mb-3 mt-3">
                  {this.state.profileSectionCollapse && this.state.section===profileSections[section.header] ?<Button
                    className="r-btn r-btn--blue"
                    color="blue" onClick={()=>this.setState({profileSectionCollapse : false , section : null})} >Hide
                  </Button> : <Button
                    className="r-btn r-btn--blue"
                    color="blue" onClick={()=>this.setState({profileSectionCollapse : true , section : profileSections[section.header]})} >More
                  </Button> }
                </Col>}
              </Row>
              <Collapse isOpen={this.state.section===profileSections[section.header] && this.state.profileSectionCollapse}  >
                <div className="mt-3 w-100">
                  {section.header === "academic_detail" && 
                  <Row className="ml-2 mt-3">
                      <Col xs={10}>
                          <span className="r-subheading-4 r-text--grey-4 pr-0 ml-1">Remove degree, department column from currently pursuing course</span>
                      </Col>
                      <Col xs={2} className="">
                        <Switch className="" isOn={this.state.noDegreeDepartmentForPersuing} handleToggle={() => this.setState({noDegreeDepartmentForPersuing : !this.state.noDegreeDepartmentForPersuing})} />
                      </Col>
                      <Col xs={10} className="mt-2">
                          <span className="r-subheading-4 r-text--grey-4 pr-0 ml-1">Show only 1 UG, 1 PG details</span>
                      </Col>
                      <Col xs={2} className="mt-2">
                        <Switch className="" isOn={this.state.singleDegreeOnly} handleToggle={() => this.setState({singleDegreeOnly : !this.state.singleDegreeOnly})} />
                      </Col>
                  </Row>}
                <Row>
                  <Col className="m-2">
                    <span className="r-subheading-3">Sub Sections</span>
                  </Col>
                </Row>
                {
                  profileSubSections[section.header].map((data,j)=>{
                    let wrapperPropsSubsection = {};
                    let overlayPropsSubsection = {};
                    if(this.state.profileSectionCollapse===true){
                        wrapperPropsSubsection = {
                        onDragOver: () => this.onDragOverSubsection(j , section.header)
                      }
                      overlayPropsSubsection = {
                        draggable:  true ,
                        onDragEnd: this.onDragEndSubsection,
                        onDragStart: (e) => this.onDragStartSubsection(e, j , section.header)
                      }
                    }
                    return(
                      <div  {...wrapperPropsSubsection}>
                      <Row className="m-1"  {...overlayPropsSubsection}>
                        <Col  className="mb-3 mt-3" >
                          <input type="checkbox" className="settings__excel_checkbox" checked={data.status} onChange={()=>this.formatSubSectionList(section.header, j)} disabled={profileSectionsList[i].status==false} /> <span className="r-subheading-4 r-text--grey-4 pr-0 ml-1">{data.title} </span>
                          </Col>
                      </Row>
                      </div>
                    )
                  })
                }
                </div>
                </Collapse>
              </div>
            ) }
          )
        }
        </div>
          <div className="mt-3 ml-5 mb-3">
          {
            filterList &&  filterList.map((section , i)=>{
              return(

              <div className="mt-3 border w-50" >
              <Row className="m-1">

                  <Col xs={8} className="mb-3 mt-3">
                    <input type="checkbox" className="settings__excel_checkbox" checked={section.status}  onChange={()=>this.formatSectionHeaderFilterList(i)} id={profileSections[section.header]}  /> <span className="r-subheading-4 r-text--grey-4 pr-0 ml-1"> {profileSections[section.header]}</span>
                  </Col>
                  <Col xs={4} align="center" className="mb-3 mt-3">
                    <Button
                      className="r-btn r-btn--blue"
                      color="blue" onClick={()=>this.setState({profileSectionCollapse : !this.state.profileSectionCollapse , section : profileSections[section.header]})} >
                      {this.state.profileSectionCollapse && this.state.section==profileSections[section.header] ? "Hide" : "More"}
                    </Button>
                  </Col>
                </Row>
                  <Collapse isOpen={this.state.profileSectionCollapse && this.state.section==profileSections[section.header]} >
                  <div className="mt-3 w-50">
                  <Row>
                    <Col className="m-2">
                      <span className="r-subheading-3">Sub Sections</span>
                    </Col>
                  </Row>
                  {
                    filterListSubsection[section.header].map((data, j)=>{
                      return(
                        <div >
                        <Row className="m-1"  >
                          <Col  className="mb-3 mt-3">
                            <input type="checkbox" className="settings__excel_checkbox" checked={data.status} disabled={filterList[i].status==false}  onChange={()=>this.formatSubSectionFilterList(section.header, j)} /> <span className="r-subheading-4 r-text--grey-4 pr-0 ml-1">{data.title} </span>
                            </Col>
                        </Row>
                        </div>
                      )
                    })
                  }
                  </div>
                  </Collapse>
                </div>
              )}
            )
          }
          </div>
      <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
      </Container>
    )
  }
}
export default ExcelDownload;
