import { ACTIONS } from "../constants/actions";
import { Auth } from "./Auth";

export const setUser = (user) => dispatch => {
  dispatch({
    type: ACTIONS.SET_USER,
    payload: user
  })
}

export const setLoading = (show) => dispatch => {
  dispatch({
    type: ACTIONS.SET_LOADER,
    payload: show
  })
}

export const setRole = role => async(dispatch) => {
  try {
    await Auth.User.updateProfile({ current_role_id: role.assigned_role_id })
  } catch (e) { console.log(e) }
  dispatch({
    type: ACTIONS.SET_ROLE,
    payload: role
  })
}

export const setError = (error) => dispatch => {
  dispatch({
    type: ACTIONS.SET_ERROR,
    payload: error
  })
}