import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import { Row, Col,Card, CardBody, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import {UncontrolledCollapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Loader } from '../../../global/Loader';

import { Select} from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import { CONSTANTS } from '../../../../constants';
import FaqCard from './faq-card' 



export default  class FAQ extends Component {


  render() {    
    return(

      <div className="container mt-5">  
        <div className="faq">
          <div className="title">
            <Col xs={12}>
              <h1 className="r-heading-1 text-center mb-5">Student User FAQ(s)</h1>
            </Col>
          </div>
          <FaqCard 
            heading={'Student Feed '} 
            
            points ={
              <div className="point">              
                <ul className="font-weight-normal">
                  <li>This is the central news feed where all information related to placements is available to you. You can manage their day’s events, see information on recruiters, and keep a check on your applications via your own dashboard.</li>
                  <li>To access all events for the day/ week/ month added by your placement team, please click the small calendar icon next to the <b><i>Applys</i></b> tab on the top navigation bar. You can also add tasks/ events for your own self using the calendar.</li>
                                
                </ul> 
               
                <div className="text-center">
                <img src={'/images/faq/s-faq-1.png'} className="img-fluid pb-2 pt-2"/>
                </div>
            
                
              </div>
            }
          /> 

        <FaqCard 
            heading={'Profile'} 
            
            points ={
              <div className="point">        
                <p>
                  The <b><i>Profile</i></b> tab helps you create your comprehensive profile. The aim is to capture data consistently for you and all your batchmates. Most of the options are hence available in the form of dropdowns. While building your profile, you should keep adding relevant CV points under each section to enable the system to build your resume.
                </p>      
                <ul className="font-weight-normal">
                  <li>
                    Once you have added the information in a section of the profile. E.g. Class X details under the <b><i>Academic Details</i></b> section, the system gives you the option to add a CV point. 
                    </li>      
                    <li>
                  	To add a CV point, you need to add a header and then the relevant point. The header helps club your CV points in related sections together. For example, any CV points added under the header <b><i>Academic Achievements</i></b> for Class X, XII and graduation will be clubbed and shown together on your CV
                  </li>                          
                </ul> 
                <div className="text-center">
                <img src={'/images/faq/s-faq-2.png'} className="img-fluid pb-2 pt-2"/>
                </div>
              </div>
            }
          /> 

 

          <FaqCard 
            heading={'Resume'} 
            
            points ={
              <div className="point">        
                  
                <ul>
                  <li>
                  This section uses your profile information to <b><i>automatically build your CV (in your institute’s specific format).</i></b>  All the CV points that have been added are available in a Master Resume. Students can pick and choose points from the Master Resume to create multiple versions of their CV(s) if required by them.
                  </li>
                  <li>
                  You will need to create at least one variant from your <b><i>master resume</i></b> . This variant is then available to you for applying to all applications opened by your placement team. Use the <b><i>Manage Resumes</i></b> option to create new variants and save them. You can also remove/ re-add specific CV points from a variant.
                  </li>
                  <li>
                    The resume builder also gives you the option to edit and format your resumes as desired.
                  </li>
                </ul>

                <br/>
             
                
                <div className="text-center">
                <img src={'/images/faq/s-faq-3.png'} className="img-fluid pb-2 pt-2"/>
                </div>
          
               
              </div>
            }
          />

  

        <FaqCard 
            heading={'Apply'} 
            
            points ={
              <div className="point">        
                <p>
                This section is for you to apply to all those roles, the applications for which have been published by the placement team. All the information on the company, role and any other details are available here. Applying is as simple as
                </p>      
                <ul>
                  <li>
                    Selecting the role
                  </li>
                  <li>
                    Clicking on apply
                  </li>
                  <li>
                    Selecting the resume 
                  </li>
                  <li>
                    Answering additional questions (if any), and 
                  </li>
                  <li>
                    Clicking on <b><i>Apply</i></b>. 
                  </li>                 
                </ul>
                <p>
                  Over time, the status of the application also starts reflecting here (e.g. – Shortlisted, Rejected, etc.)
                </p>
                <ul>
                    <li>
                      To get a simplified view which shows just your progress through various rounds for a particular role/ application, you can switch from <b><i>Status View</i></b> to <b><i>Performance View</i></b> using the toggle available on the top right of the applications list.
                    </li>                   
                </ul>
                <div className="text-center">
                  <img src={'/images/faq/s-faq-4.png'} className="img-fluid pb-2 pt-2"/>
                </div>              
              </div>
            }
          />     

        </div>
      </div>

    )
  }

}
