import React,{Component} from 'react';
import { Collapse } from 'reactstrap';
import {  CardBody, CardTitle, CardText} from 'reactstrap';
import {Col,Row} from 'reactstrap';
import { connect } from 'react-redux';
import Aux from '../../global/AuxComp';
import {StatusButton} from './verifier_status_button';
import moment from 'moment';

class SectionPointCollapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
      };
    this.section = Math.ceil(Math.random() * 10000)
    this.popoverName = "POP-" + this.section
  }

  toggle = () => {
    this.props.toggleSection(this.section)
    this.props.getfileId(this.props.academicDetail.file);
  }


  render() {
    let academicDetail = this.props.academicDetail;
    let allCommentThread= this.props.getAllCommentThread!=null?(this.props.getAllCommentThread.filter(commentThread=>
       commentThread.profile_type=="ACADEMIC" && commentThread.profile_id==academicDetail.academic_id && commentThread.student_id==academicDetail.student_id &&commentThread.verifier_id==this.props.role.assigned_role_id)):null;
    return (
      <Aux>
      <CardBody onClick={this.toggle}  className={"verifier__card-body  "+(this.section==this.props.collapse?"active":"")} >
      {academicDetail.degree_name!=null?(<CardTitle >
      <Row>
      <Col xs={10}>
      <span className="r-heading-6 mr-3">{academicDetail.degree_name}</span>
      <span>{"("+moment(academicDetail.year_start).year()+" - "}{academicDetail.year_end?moment(academicDetail.year_end).year(): "Currently Pursuing"}{")"} </span>
      </Col>
      {academicDetail.file!=0? <Col xs={2} align="end">
          <span title="Proof Attached"><img src="/images/paper-clip@2x.png" alt="Proof" className="w-25" /></span>
      </Col> : null}
      </Row>
      </CardTitle>):null}
      <CardTitle>
      <Row>
      <Col xs={10}>
      <span className="r-heading-6 mr-3">
      {academicDetail.subjects ||  "Subjects"}</span>
      <span>{academicDetail.degree_name==null? "("+(moment(academicDetail.year_start).year()+" - "+moment(academicDetail.year_end).year()+")") : "" } </span>
      </Col>
      {academicDetail.file!=0 && academicDetail.board_short_form!=null? <Col xs={2} align="end">
          <span title="Proof Attached"><img src="/images/paper-clip@2x.png" alt="Proof" className="w-25" /></span>
      </Col> : null}

      </Row>
      </CardTitle>
      <CardText className="r-subheading-3">
      <Row>
      <Col xs="auto">
      <span>{academicDetail.board_short_form || academicDetail.college_name} {" | "} {academicDetail.department_name!=null? academicDetail.department_name : ""}{academicDetail.school_name!=null?academicDetail.school_name+" | " :null}
      {academicDetail.score_value!=null?academicDetail.score_value:null}{academicDetail.score_type!=null?academicDetail.score_type:null}{academicDetail.score_type && academicDetail.score_type=='CGPA'? (' - '+academicDetail.score_ep+'%') : null}
      </span>
      </Col>
      </Row>
      </CardText>
      </CardBody>
      <Collapse  className="r-bg--blue-1" isOpen={this.props.collapse==this.section}>
      <StatusButton allCommentThread={allCommentThread} student_id={academicDetail.student_id} id={academicDetail.academic_id} profile_type="academic_detail" profileType="ACADEMIC"   getStudentDetails={this.props.getStudentDetails}  popoverName={this.popoverName} data={academicDetail} />
      </Collapse>
      </Aux>
    );
  }
}
const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(SectionPointCollapse)
// export default SectionPointCollapse;
