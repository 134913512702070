import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row,Col,Container} from 'reactstrap';

// function filterSections(section){
//
// }

export const CardButton = (props) =>
{
  let  all=0, pending=0, approved=0,rejected=0;
     let{academic_detail, achievement, personal_detail, extra_curricular_activity, other_experience, position_of_responsibility, preferences,professional_detail, project, skill , cv_point} = props.studentDetails;

     let academicPoints = academic_detail!=null?(academic_detail.filter(pt=>{
       if(pt.is_verified==0){
         pending++;
         all++;
       }
       else if(pt.is_verified==1){
         approved++;
         all++;
       }
       else if (pt.is_verified==2) {
         rejected++;
         all++;
       }
     })):null

     let achievementPoints = achievement!=null?(achievement.filter(pt=>{
       if(pt.is_verified==0){
         pending++;
         all++;
       }
       else if(pt.is_verified==1){
         approved++;
         all++;
       }
       else if (pt.is_verified==2) {
         rejected++;
         all++;
       }
     })):null
     let personalDetail = personal_detail.filter(pt=>{
       if(pt.is_verified==0){
         pending++;
         all++;
       }
       else if(pt.is_verified==1){
         approved++;
         all++;
       }
       else if (pt.is_verified==2) {
         rejected++;
         all++;
       }
     })

     let extraCurricularActivityPoints =extra_curricular_activity!=null?( extra_curricular_activity.filter(pt=>{
       if(pt.is_verified==0){
         pending++;
         all++;
       }
       else if(pt.is_verified==1){
         approved++;
         all++;
       }
       else if (pt.is_verified==2) {
         rejected++;
         all++;
       }
     })):null

     let otherExperiencePoints = other_experience!=null?( other_experience.filter(pt=>{
       if(pt.is_verified==0){
         pending++;
         all++;
       }
       else if(pt.is_verified==1){
         approved++;
         all++;
       }
       else if (pt.is_verified==2) {
         rejected++;
         all++;
       }
     })):null

     let positionOfResponsibilityPoints =position_of_responsibility!=null?( position_of_responsibility.filter(pt=>{
       if(pt.is_verified==0){
         pending++;
         all++;
       }
       else if(pt.is_verified==1){
         approved++;
         all++;
       }
       else if (pt.is_verified==2) {
         rejected++;
         all++;
       }
     })):null

     let preferencesPoints =preferences!=null?(preferences.filter(pt=>{
       if(pt.is_verified==0){
         pending++;
         all++;
       }
       else if(pt.is_verified==1){
         approved++;
         all++;
       }
       else if (pt.is_verified==2) {
         rejected++;
         all++;
       }
     })):null

     let professionalDetailPoints =professional_detail!=null?( professional_detail.filter(pt=>{
       if(pt.is_verified==0){
         pending++;
         all++;
       }
       else if(pt.is_verified==1){
         approved++;
         all++;
       }
       else if (pt.is_verified==2) {
         rejected++;
         all++;
       }
     })):null

     let projectPoints =project!=null?( project.filter(pt=>{
       if(pt.is_verified==0){
         pending++;
         all++;
       }
       else if(pt.is_verified==1){
         approved++;
         all++;
       }
       else if (pt.is_verified==2) {
         rejected++;
         all++;
       }
     })):null

     let skillPoints =skill!=null? (skill.filter(pt=>{
       if(pt.is_verified==0){
         pending++;
         all++;
       }
       else if(pt.is_verified==1){
         approved++;
         all++;
       }
       else if (pt.is_verified==2) {
         rejected++;
         all++;
       }
     })):null

     let cvPoint = cv_point!=null?(cv_point.filter(pt=>{
       if(pt.is_verified==0){
         pending++;
         all++;
       }
       else if(pt.is_verified==1){
         approved++;
         all++;
       }
       else if (pt.is_verified==2) {
         rejected++;
         all++;
       }
     })):null
     // totalUnverified = 1;

     // if( pending >0 || approved>0 || rejected>0 ){
     //   props.calcUnverifiedStudents();
     // }

  return(
    <Row>
    <ul className="verifier__list">
    <li>
    <Col className="my-2">
    <Row>
    <Col xs="auto"><span className=" r-text--grey-8 r-subheading-1" align="start" >{all/10>=1?all:("0"+all)}</span>
    </Col>
    </Row>
    <Row>
    <Col xs="auto" ><span className="r-text--grey-8 r-subheading-3" >All</span>
    </Col>
    </Row>
    </Col>
    </li>
    <li>
    <Col className="my-2">
    <Row>
    <Col xs="auto"><span className=" r-text--grey-8 r-subheading-1" align="start" >{approved/10>=1?approved:("0"+approved)}</span>
    </Col>
    </Row>
    <Row>
    <Col xs="auto"><span className="r-text--grey-8 r-subheading-3" >Approved</span>
    </Col>
    </Row>
    </Col>
    </li>

    <li>
    <Col className="my-2">
    <Row>
    <Col xs="auto"><span className=" r-text--grey-8 r-subheading-1" align="start" >{rejected/10>=1?rejected:("0"+rejected)}</span>
    </Col>
    </Row>
    <Row>
    <Col xs="auto"><span className="r-text--grey-8 r-subheading-3" >Rejected</span>
    </Col>
    </Row>
    </Col>
    </li>

    <li>
    <Col className="my-2">
    <Row>
    <Col align="start"><span className=" r-text--blue-3 r-subheading-1" align="center" >{pending/10>=1?pending:("0"+pending)}</span>
    </Col>
    </Row>
    <Row>
    <Col align="start"><span className="r-text--grey-8 r-subheading-3" >Pending</span>
    </Col>
    </Row>
    </Col>
    </li>
    </ul>

    </Row>

  );
}
