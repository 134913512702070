import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { SelectList, Select ,MultiSelectList} from './Forms/Select';
import { Auth } from '../../actions/Auth';
import { Loader } from './Loader';
import store from '../../store.js';
import Aux from './AuxComp';

class FilterWithProcess extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      loader:false,
      processList : [],
      batchList:{},
      depList: {},
      groupList:{},
      studentFilters: {
        batches: [],
        departments: [],
        groups:[],
      },
      selectedProcess : [],
      processStudent : [],
    }
  }

  getProcess = async () => {
      try {
        let processResp = await Auth.Processes.getProcesses(store.getState().role.instituteDetail.campus_id);
        this.setState({ processList : processResp.data})
      }
      catch (e) {
        console.log(e);
      }
  }

  addToFilters = async (e, filter) => {
    let { studentFilters, depList, batchList,groupList } = this.state;
    this.setState({loader:true})
    if (filter === "batches") {
      if (e.target.value) {
        let bt = batchList[e.target.value]
        studentFilters[filter].push(e.target.value)
        try {
          let res = await Auth.General.getDegreesCampusDepartment(store.getState().role.instituteDetail.campus_id, bt.campus_degree_id);
          let depts = res.data[0].departments.reduce((prev, current) => {
            current.batch_name = bt.batch_name
            current.batch_id = bt.batch_id
            prev[current.batch_id + "-" + current.degree_department_id] = current;
            return prev;
          }, {})
          depList = Object.assign({}, depList, depts)
          this.setState({ depList });
        } catch (e) {
          console.log(e);
        }
      }
    }
    else if(filter ==="departments"){
        if (e.target.value) {
          let dept = depList[e.target.value]
          studentFilters[filter].push(e.target.value)
          try {
            let res = await Auth.PtStudents.getFilterGroup(e.target.value.split("-")[0], dept.degree_department_id);
            let group = res.data.reduce((prev, current) => {
              prev[current.batch_id + "-" + current.degree_department_id +"-"+current.group_id] = current;
              return prev;
            }, {})
            groupList = Object.assign({}, groupList, group)
            this.setState({ groupList });
          } catch (e) {
            console.log(e);
          }
        }
    }
    else {
      if (e.target.value)
        studentFilters[filter].push(e.target.value)
    }
    this.props.filterStudents(studentFilters);
    this.setState({ studentFilters })
    this.setState({loader:false})
  }


  removeFromFilters = (val, filter) => {
    let { studentFilters, depList, batchList,groupList } = this.state;
    if (filter === "batches") {
      let deps = Object.keys(depList).reduce((prev, current) => {
        if (current.split("-")[0] != val)
          prev[current] = depList[current]
        return prev
      }, {})
      let groups = Object.keys(groupList).reduce((prev, current) => {
        if (current.split("-")[0] != val)
          prev[current] = groupList[current]
        return prev
      }, {});
      studentFilters["departments"] = studentFilters["departments"].filter(item =>{ if(item.indexOf(val)<0){ return item;} });
      studentFilters["groups"] = studentFilters["groups"].filter(item =>{ if(item.indexOf(val)<0){ return item;} });
      this.setState({ depList: deps,groupList:groups });
    }
    else if (filter === "departments") {
      let groups = Object.keys(groupList).reduce((prev, current) => {
        if (current.split("-")[1] != val.split("-")[1])
          prev[current] = groupList[current]
        return prev
      }, {})
      studentFilters["groups"] = studentFilters["groups"].filter(item =>{ if(item.indexOf(val)<0){ return item;} });
      this.setState({ groupList:groups });
    }
    studentFilters[filter].splice(studentFilters[filter].indexOf(val), 1)
    this.props.filterStudents(studentFilters);
    this.setState({ studentFilters })
  }

  handleCheck = (e, filter) =>{
    if(e.target.checked){
      this.addToFilters(e, filter)
    }
    else {this.removeFromFilters(e.target.value, filter)};

    this.setState({selectedProcess: []});
  }

  handleProcess = async (e) =>{
    let { selectedProcess, processStudent } = this.state;
    this.setState({loader:true})
    let process_id = e.target.value
    if(e.target.checked){
      selectedProcess.push(process_id)
      let res = await Auth.Processes.getProcessStudents(process_id);
      res.data.forEach(ps => {
          ps.process_id = process_id
          processStudent.push(ps)
      })
    }
    else{
        var i = selectedProcess.indexOf(process_id)
        selectedProcess.splice(i , 1)
       processStudent = processStudent.filter(ps => {return ps.process_id !== process_id})
    }
    this.setState({loader:false})
    this.props.filterStudents({batches: [],departments: [],groups:[], selectedProcess }, processStudent.map(obj => obj.student_id));
    this.setState({selectedProcess , processStudent})

  }

 async componentDidMount () {
     this.getProcess() ;
      let responseBatch = await Auth.Batch.getBatchDetail();
      this.setState({
        batchList: responseBatch.data.reduce((prev, current) => { prev[current.batch_id] = current; return prev; }, {})
      })
  }


  render() {
    let { isLoading,  studentFilters,depList,batchList,groupList,loader , processList, selectedProcess} = this.state;
    return (

        <Aux>
                    <Row className="mt-5 ">
                        <Col xs={4}>
                            <h4 className="r-subheading-3">Placement drive</h4>
                            <MultiSelectList title={"Select"}>
                            <ul className="list-unstyled m-0">
                              {processList.map((pr ,i) => {
                                return (
                                  <li key={i}>
                                    <input name="value" checked={(selectedProcess.indexOf(pr.process_id.toString()) > -1)} onChange = {this.handleProcess} type="checkbox" value={pr.process_id} />
                                    <label>{pr.process_name}</label>
                                  </li>
                                )
                              })}
                            </ul>
                            </MultiSelectList>
            
                            
                        </Col>
                    </Row>

                    <Row className="mt-4 text-center">
                        <Col xs={4}>
                            <h3>OR</h3>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                      <Loader show={loader} />
                      <Col xs={4}>
                          <h4 className="r-subheading-3">Batch</h4>
                          <MultiSelectList title={"Select"}>
                            <ul className="list-unstyled m-0">
                              {Object.keys(batchList).map((bt, i) => {
                                return (
                                  <li key={i}>
                                    <input name="value" onChange={(e) => this.handleCheck(e, "batches")} checked={studentFilters.batches.indexOf(bt) > -1}  type="checkbox" value={bt} />
                                    <label>{batchList[bt].batch_name}</label>
                                  </li>
                                )
                              })}
                            </ul>
                          </MultiSelectList>
                        
                          {studentFilters.batches.map((bt, i) => {
                            return (<div key={i}>
                              {(batchList[bt]) ?
                                <div key={bt} className="r-tag mt-2">
                                  {batchList[bt].batch_name}
                                  <button onClick={() => this.removeFromFilters(bt, "batches")} type="button" className="r-tag__delete fi flaticon-plus-1"></button>
                                </div> : null}</div>
                            )
                          })}
                      </Col>
                      <Col xs={4}>
                        <h4 className="r-subheading-3">Department</h4>
                          <MultiSelectList title={"Select"}>
                            <ul className="list-unstyled m-0">
                              {Object.keys(depList).map((dept, i) => {
                                return (
                                  <li key={i}>
                                    <input name="value" onChange={(e) => this.handleCheck(e, "departments")} checked={studentFilters.departments.indexOf(dept) > -1}  type="checkbox" value={dept} />
                                    <label>{depList[dept].department_name} - {depList[dept].batch_name}</label>
                                  </li>
                                )
                              })}
                            </ul>
                          </MultiSelectList>
                        
                                                {studentFilters.departments.map((dept, i) => {
                                                   return (<div key={i}>
                                                    {(depList[dept])?<div key={dept} className="r-tag mt-2">
                                                            {depList[dept].department_name} - {depList[dept].batch_name}
                                                            <button onClick={() => this.removeFromFilters(dept, "departments")} type="button" className="r-tag__delete fi flaticon-plus-1"></button>
                                                        </div>:null}</div>
                                                  )}) }
                      </Col>
                      <Col xs={4}>
                        <h4 className="r-subheading-3">Groups</h4>
                          <MultiSelectList title={"Select"}>
                            <ul className="list-unstyled m-0">
                              {Object.keys(groupList).map((group, i) => {
                                return (
                                  <li key={i}>
                                    <input name="value" onChange={(e) => this.handleCheck(e, "groups")} checked={studentFilters.groups.indexOf(group) > -1}  type="checkbox" value={group} />
                                    <label>{groupList[group].group_name}</label>
                                  </li>
                                )
                              })}
                            </ul>
                          </MultiSelectList>
                        
                                                {studentFilters.groups.map((group, i) => {
                                                   return (<div key={i}>
                                                    {(groupList[group])?<div key={group} className="r-tag mt-2">
                                                    {groupList[group].group_name} 
                                                    <button onClick={() => this.removeFromFilters(group, "groups")} type="button" className="r-tag__delete fi flaticon-plus-1"></button>
                                                    </div>:null}</div>
                                                  )}) }
                      </Col>
                    </Row>
        </Aux>
             
    )
  }
}

export default FilterWithProcess;