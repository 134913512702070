import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import { Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { Loader } from '../../../global/Loader';
import { SelectList } from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import { CONSTANTS } from '../../../../constants';
import { CompanyContact } from './contact-card';
import { ContactSection } from './contact_section';
import { RolesSection } from './roles_section';
import {Collapse} from 'reactstrap';
import { Select } from '../../../global/Forms/Select';
import Aux from '../../../global/AuxComp';


export class CompanyInfo extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      isLoading : false,
      editableCompany : false,
      toggleEditClassificationCollapse : false,
      classificationDetails : this.props.classificationDetails,
      // companyClassification : null,
      classification : null,
      selectedClassification : null

    }
  }

  deleteCompany = (campus_company_id) => {
    this.props.setCompanyInfo(null);
    this.props.deleteCompany([campus_company_id])
  }


  toggleEditableCompany = () => {
    this.setState({editableCompany : !this.state.editableCompany});
  }

  handleEditForm = async(e)=>{
    this.setState({isLoading : true});
    e.preventDefault();
    let {classification_type} = e.target;
    if(classification_type.value){
      let params = {
        "company_classification_id": this.state.classification.company_classification_id,
        "classification_id" : classification_type.value
      }
      try{
        await Auth.classification.editCompanyClassificationMapping(params);
        await this.props.getClassfication(this.state.classification.campus_company_id);
        this.setState(this.getInitialState());
      }
      catch(e){
        console.log(e);
      }
    }
    this.setState({isLoading : false});
  }

  handleEditClassificationForm = async(e)=>{
    e.preventDefault();
    this.setState({isLoading : true});
    let {edit_classification_type} = e.target;
    let {companyInfo} = this.props;
    if(edit_classification_type.value){
      try{
        let params = {
          campus_company_id : companyInfo.campus_company_id,
          classification_id : edit_classification_type.value
        }
        let classificationMapping = await  Auth.classification.companyClassificationMapping(params);
        await this.props.getClassfication(companyInfo.campus_company_id);
        this.setState(this.getInitialState());
      }
      catch(e){
        console.log(e);
      }
    }
    this.setState({isLoading : false});
  }
  render() {
    let { isLoading, editableCompany, companyProfiles, designationList } = this.state;
    let { companyInfo, setCompanyInfo, deleteCompany, openAddProcess, history, classificationDetails, role } = this.props;
    let categoryList = [] ;
      classificationDetails.forEach(item=>{
        let flag = 0;
        companyInfo.classifications.forEach((classification)=>{
          if(item.classification_category_id == classification.classification_category_id){
          flag = 1;
        }
      })
      if(flag==0){
        categoryList.push(item);
      }
    })
    return (

      <Container>
        <Loader show={isLoading} />
        <Row className="mt-3 pb-3 border-bottom align-items-center">
          <Col>
          { !editableCompany ?
            <h1 className="r-subheading-1"><i onClick={() => {history.push('/pt/companies')}} className="fi flaticon-arrow-1 mr-3 selectable"></i> {companyInfo.company_details.company_name}</h1>
            :
            <h1 className="r-subheading-1"><i  className="fi flaticon-arrow-1 mr-3 selectable"></i> <InputField value={companyInfo.company_details.company_name} /></h1>
          }
          </Col>
          <Col xs="auto">
            {/*<Button onClick={this.toggleEditableCompany}>
              <i className="fi flaticon-edit" />
              <span className="ml-2">Edit</span>
            </Button>
            |
          */}
            <Button onClick={() => this.deleteCompany(companyInfo.campus_company_id)}>
              <i className="fi flaticon-bin" />
              <span className="ml-2">Delete</span>
            </Button>
            |
            <Button onClick={openAddProcess}>
              <i className="fi flaticon-plus-1 r-text--sm" />
              <span className="ml-2">Add to Process</span>
            </Button>
          </Col>
        </Row>
        <Row className="mt-5 p-3 align-items-center">
          <Col xs={12}>
            <Row>
              <Col xs={3}>
                  <h1 className="r-subheading-2">Basic Information</h1>
              </Col>
            </Row>
            <div className="p-4">
              {companyInfo.company_address ?
                <Aux>
                  <Row>
                    <Col xs={3} className="mb-2">
                        <h1 className="r-subheading-3">Address</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className="mb-4">
                      <div className="card shadow-sm py-3 px-3 w-100">
                        {companyInfo.company_address}
                      </div>
                    </Col>
                  </Row>
                </Aux> : null}
              <Row>
                <Col xs={3}  className="mb-2">
                    <h1 className="r-subheading-3">Company Classification</h1>
                </Col>
              </Row>
            <Row>
            {companyInfo.classifications.map((classification)=>{
              let categoryName = classificationDetails.filter((category) => category.classification_category_id == classification.classification_category_id)[0] || {};
              return (<Col xs={3}>
                <div className="card shadow-sm py-3 px-3 w-100">
                <Row>
                  <Col xs={10}>
                    <h1 className="r-subheading-3 r-text--blue-3">{classification.name || "-"}</h1>
                  </Col>
                  <Col xs={2}>
                    <i className="fi flaticon-edit r-text--blue-3 settings__button-cursor" onClick={async()=>await this.setState({toggleEditClassificationCollapse  : !this.state.toggleEditClassificationCollapse , classification :classification })} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1 className="r-subheading-4">{categoryName.name}</h1>
                  </Col>
                </Row>
                {this.state.classification && <Collapse isOpen={this.state.toggleEditClassificationCollapse && this.state.classification.classification_id == classification.classification_id} >
                <Row>
                <Col>
                {this.state.classification &&
                  <form onSubmit={this.handleEditForm}>
                  <Select name="classification_type" defaultValue={this.state.classification.classification_id} >
                  <option key="" value="">Select </option>
                  {this.state.classificationDetails && this.state.classificationDetails.map(classificationType=>{
                    if(classification.classification_category_id == classificationType.classification_category_id){
                      return(
                        classificationType.classification_list && classificationType.classification_list.map(category=>
                          <option  value={category.classification_id}
                           key={category.classification_id}>{category.name} </option>
                        )
                      )
                    }
                  }
                  )

                  }
                  </Select>
                  <Button type="submit" color="dark" className="r-text--sm r-btn--no-radius mt-2">Save</Button>
                  <span className="r-subheading-4 ml-3 settings__button-cursor" onClick={()=>this.setState(this.getInitialState())}>Cancel</span>
                  </form>
                }

                </Col>
                </Row>
                </Collapse>}
                </div>
              </Col>)
            })
            }
            {categoryList.map(category=>{
              return(
              <Col xs={3}>
                <div className="card shadow-sm py-3 px-3 w-100">
                <Row>
                  <Col xs={10}>
                    <h1 className="r-subheading-3 r-text--blue-3">{category.name || "-"}</h1>
                  </Col>
                  <Col xs={2}>
                    <i className="fi flaticon-edit r-text--blue-3 settings__button-cursor" onClick={async()=>await this.setState({toggleEditClassificationCollapse  : !this.state.toggleEditClassificationCollapse , selectedClassification : category })} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1 className="r-subheading-4">{category.name}</h1>
                  </Col>
                </Row>
                {this.state.selectedClassification && <Collapse isOpen={this.state.toggleEditClassificationCollapse && this.state.selectedClassification.classification_category_id == category.classification_category_id} >
                <Row>
                <Col>
                {this.state.selectedClassification &&
                  <form onSubmit={this.handleEditClassificationForm}>
                  <Select name="edit_classification_type"  >
                  <option key="" value="">Select </option>
                  {this.state.selectedClassification && this.state.selectedClassification.classification_list.map(classificationType=>{
                      return(
                          <option  value={classificationType.classification_id}
                           key={classificationType.classification_id}>{classificationType.name} </option>
                        )
                    })
                  }
                  </Select>
                  <Button type="submit" color="dark" className="r-text--sm r-btn--no-radius mt-2">Save</Button>
                  <span className="r-subheading-4 ml-3 settings__button-cursor" onClick={()=>this.setState(this.getInitialState())}>Cancel</span>
                  </form>
                }

                </Col>
                </Row>
                </Collapse>}
                </div>
              </Col>
            )})
          }
            </Row>
            </div>
          </Col>


        </Row>
        <Row className="mt-3 p-3 align-items-center">
          <Col xs={12}>
            <Row>
              <Col xs={4}>
                  <h1 className="r-subheading-2">Contacts</h1>
              </Col>
            </Row>
            <ContactSection  toggleLoading={this.props.toggleLoading} campus_company_id={companyInfo.campus_company_id} />
          </Col>


        </Row>
        <Row className="mt-4 p-3 align-items-center">
          <Col xs={12}>
            <RolesSection  toggleLoading={this.props.toggleLoading} campus_company_id={companyInfo.campus_company_id} campus_id={role.instituteDetail.campus_id}/>
          </Col>


        </Row>

      </Container>
    )
  }
}
