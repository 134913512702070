import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import { Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { Loader } from '../../../global/Loader';
import { SelectList } from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import { CONSTANTS } from '../../../../constants';
import { CompanyContact } from './contact-card';
import { ContactCrudModal } from './contact_crud_modal';
import {Errorbar} from '../../../global/errorbar';
import {Confirmation} from '../../../global/confirmation';


export class ContactSection extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      contactList : [],
      isDetailsOpen : false,
      modalAddContact : false,
      addContact : null,
      showError : false,
      errorMsg : "",
      init : false,
      isConfirmation : false
    }
  }
  toggleAddContact = () => {
    this.setState({
      modalAddContact: !this.state.modalAddContact,
      addContact : null,
    })
  }
  componentDidMount() {
    this.fetchContact();
  }



  fetchContact = async() => {
    this.props.toggleLoading(true)
    try {
      let resp = await Auth.PtCompanies.getCompanyContacts(this.props.campus_company_id);
      if(resp.data.length > 0)
        resp = await Auth.PtCompanies.getCompanyContactDetails(resp.data.map(data => data.contact_id))
      this.setState({
        contactList: resp.data,
      })
    } catch (e) { console.log(e) }
      this.setState({init : true})
      this.props.toggleLoading(false)
  }

  removeContactDetail = (type, index) => {
      var addContact = this.state.addContact;
      if(type === "mobile_detail"){
        addContact.deletedMobile = addContact.deletedMobile  || [];
        addContact.deletedMobile.push(addContact.mobile_detail[index].id);
        addContact.mobile_detail.splice(index, 1);
      }
      else if(type === "email_detail"){
        addContact.deletedEmail = addContact.deletedEmail || [];
        addContact.deletedEmail.push(addContact.email_detail[index].id);
        addContact.email_detail.splice(index, 1);
      }
      this.setState({addContact});
  }

  editContact = async(contactData, e) => {
    e.stopPropagation();
    this.props.toggleLoading(true)
    try {
      let resp = await Auth.PtCompanies.getCompanyContactDetails(contactData.contact_id);
      contactData = resp.data[0];
      this.setState({addContact: contactData, modalAddContact : true})
    }
    catch (e) {  console.log(e);}
    this.props.toggleLoading(false)
  }
  toggleConfirmation = () => {
    this.setState({isConfirmation : false})
  }
  confirmDeleteContact = (contact_id,e) => {
    e.stopPropagation();
    this.setState({isConfirmation : true, confirmContactId : contact_id})
  }
  deleteContact = async() => {

    this.props.toggleLoading(true)
    var contact_id = this.state.confirmContactId
    var data = {
      contact_id : contact_id,
      status : 0
    }
    try {
       await Auth.PtCompanies.inactiveContact(data)
       this.fetchContact();
       this.setState({isConfirmation : false, confirmContactId : null})
       if(this.props.refreshParent)
          await this.props.refreshParent()
    }catch (e){
      if(e.response.data.error.toString() !== null){
        this.setError(true, e.response.data.error.toString())
      }
      else{
        this.setError(true, 'Sorry! something went wrong. Please try again')
      }
    }

    this.props.toggleLoading(false)
  }

  setError =(isShow, msg) => {
    this.setState({showError : isShow, errorMsg : msg});
  }

  render() {
    let { contactList, isDetailsOpen, addContact, init, isConfirmation } = this.state;
    let { campus_company_id } = this.props;
    return (
      <div className="pt-2 pb-5" >
        <Row className="">
        { contactList.map( (child, index) =>
            <CompanyContact key={index} index={index} setError={this.setError} contactData={child}  deleteContact={this.confirmDeleteContact} editContact={this.editContact} />
        )}
        <Col xs={3} className=" d-flex align-items-center">
          {init && <Button color="blue" onClick={this.toggleAddContact} className="p-4 my-3"><i className="fi flaticon-plus mr-3"></i>Add Contact</Button>}
        </Col>
        </Row>
        {this.state.modalAddContact && <ContactCrudModal setError={this.setError} refreshParent={this.props.refreshParent} modalAddContact={this.state.modalAddContact} toggleLoading={this.props.toggleLoading} toggleAddContact={this.toggleAddContact} campus_company_id={campus_company_id} addContact={addContact} init={this.fetchContact} />}
        <Errorbar showBar={this.state.showError} onClose={() => this.setError(false, "")} message={this.state.errorMsg}/>
        <Confirmation isOpen={isConfirmation} toggleConfirmation={this.toggleConfirmation} callback={this.deleteContact} />

      </div>
    )
  }
}
