import React from 'react';
import { Row,Col,Container,  Modal, ModalHeader,Nav, NavItem,Collapse, NavLink,TabContent, TabPane, ModalBody, ModalFooter,Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import {Confirmation} from '../../../global/confirmation';
import {Errorbar} from '../../../global/errorbar';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../../actions/Auth';
import { setUser, setLoading } from '../../../../actions/action-creators';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import store from '../../../../store';
import AutosuggestComp from '../../../global/AutosuggestComp';
import GroupTable from './GroupTable';
import classnames from 'classnames';


class EditGroup extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      batchs:  [],
      courses: [],
      departments:  [],
      batchdata:this.props.batchdata,
      departmentLists:[],
      degreeidList: [],
      batch:'',
      activeTab: '1',
      course:'',
      dept: '',
      groupName:this.props.data.group_name,
      errorbar: false,
      setloading:false,
      isConfirmation : false, 
      deleteConfirmationId : null,
      message:'',
      isOpen: false,
      modal: false,
      type:"",
      batchid: props.data.batch_id,
      departmentid: props.data.degree_department_id
    };
  }


    componentWillReceiveProps(props){
      this.setState({
      groupName:props.data.group_name,
      batchid: props.data.batch_id,
      batchdata:this.props.batchdata,
      departmentid: props.data.degree_department_id});
  }


  toggleEdit = async (e) =>{
    this.setState({editGroup: true})
    this.getGropus();
  }

  getGropus  = async () => {
     try {
      let res = await Auth.General.getDegreesCampusDepartment(store.getState().role.instituteDetail.campus_id, this.props.data.campus_degree_id);
      this.setState({departmentLists: res.data[0].departments});
    } catch (e) {
      console.log(e);
       }
  }

  onChange = async (e) => {
      let {batchdata} = this.state;
    if (e.target.name==="batchid"){
              this.setState({[e.target.name]: e.target.value})
              if(e.target.value){
                            let obj = batchdata.find(o => o.batch_id === parseInt(e.target.value));
                            let res = await Auth.General.getDegreesCampusDepartment(store.getState().role.instituteDetail.campus_id, obj.campus_degree_id);
                            this.setState({departmentLists: res.data[0].departments});
                }
              else{
                this.setState({departmentLists: []});
              }
    }
    else{
        this.setState({
            [e.target.name]: e.target.value
        })
    }
  }


  closebar = () =>{
      this.setState({errorbar: false});
  }


  editgroup = async(event) =>{
    event.preventDefault();
      this.setState({setloading: true});
    let groupBody = {
              "group_id":this.props.data.group_id,
             "batch_id": this.state.batchid,
             "degree_department_id": this.state.departmentid,
             "group_name":this.state.groupName
        }
    try {
          let resp = await Auth.PtStudents.editGroup(groupBody);
          this.props.updateGroup();
          this.setState({editGroup: false})
          this.setState({setloading: false});
        } catch (e) {
      this.setState({setloading: false});
          this.setState({errorbar: true});
          this.setState({message: e.response.data.error.toString()});
        }
  }

  async deletegroup(id){
      this.setState({setloading: true});
    this.toggleConfirmation()
    let groupBody = {
          "group_id":id
        }
    try {
          let resp = await Auth.PtStudents.deleteGroup(groupBody);
          this.props.updateGroup();
          this.setState({setloading: false});
        } catch (e) {
          this.setState({setloading: false});
          this.setState({errorbar: true});
          this.setState({message: e.response.data.error.toString()});
        }

}
   toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  confirmDeleteGroup = (id) => {
    this.setState({deleteConfirmationId : id, isConfirmation : true})
  }

  toggleConfirmation = () => {
    this.setState({isConfirmation : false})
  }


  render() {
    const { batch,type, groupName,batchdata,departmentid,departmentLists, dept, departments,editGroup, batchid,isConfirmation,} =this.state;
    const{data}= this.props;
    
    return (
      <div >
                    {(editGroup)?<form  onSubmit={this.editgroup}>
           <Row className="background-blue2 justify-content-around  mx-0 px-5 my-4">
                  <Col xs="3"  className="text-left px-0">
                        <InputField className="r-nav__search" placeholder="Type your Group name..." type="text" name="groupName" value={groupName} onChange={this.onChange} required/>
                  </Col>
                   <Col xs="2" className="text-center">
                    <div className="r-dropdown border-0 ">
                        <select value={batchid} name="batchid" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={this.onChange} required>
                         <option  value="">Select Batch</option>
                          {batchdata.map(data =>{
                            return <option key={data.batch_id}  value={data.batch_id}>{data.batch_name}</option>
                          })}
                        </select>
                      <i className="fi flaticon-arrow"/>
                      </div>
                   </Col>
                       <Col xs="3" className="text-center">
                                   {(this.state.batchid !== null)?<select value={departmentid} name="departmentid" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={this.onChange} required>
                                   <option  value="">Select Department</option>
                                   {departmentLists.map(data =>{
                                     return <option key={data.degree_department_id}  value={data.degree_department_id}>{data.department_name}</option>
                                   })}
                                 </select>: <select className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}} required></select>}
                       </Col>
                        <Col xs="2"  className="text-center">
                        </Col>
                        {this.state.setloading? <div className="center-xy"><Spinner color="dark"/></div>:null}
                        <Col xs="2" className="text-right px-2">
                             <Button  type="submit"><i className="r-text--blue-3 d-inline-block fi flaticon-save " style={{fontSize:"15px"}}/></Button>
                             <Button type="button"  onClick={(e) => this.setState({editGroup: false}) }><i  className="r-text--blue-3 d-inline-block fi flaticon-plus-1 rotate--45 " style={{fontSize:"15px"}}/> </Button>
                            
                        </Col>
              </Row>
              </form>:
           <Row className="background-blue2 justify-content-around align-items-center mx-0 px-5 my-4">
                        <Col xs="2" className="text-left px-0">
                            <span >{data.group_name}</span>
                        </Col>
                        <Col xs="2"  className="text-center">
                             <span >{data.batch_name}</span>
                        </Col>
                        <Col xs="3" className="text-center">
                            <span >{data.department_name}</span>
                        </Col>
                        <Col xs="3"  className="text-center">
                                <Button onClick={() =>{this.setState({modal: true, type:"show"});}} className="r-btn--no-radius p-0 pl-2 r-btn r-btn--blue" color="brandA">
                                  <i className="fi flaticon-user" />
                                  <span className="mx-2">{data.student_count} {data.student_count && data.student_count === 1 ? "Student" : "Students"}</span>
                                </Button>
                                <span className="border-left-white "/>
                                <Button onClick={() =>{this.setState({modal: true, type:"add"});}} className="r-btn--no-radius p-0 pr-2 r-btn r-btn--blue" color="brandA">
                                  <i className="fi flaticon-plus-1  pl-2" />
                                  <span className="mx-2">Add</span>
                                </Button>
                        </Col>
                        {this.state.setloading? <div className="center-xy"><Spinner color="dark"/></div>:null}
                       {(data.student_count<1)?<Col xs="2" className="text-right px-2">
                                                    <Button onClick={(e) => this.toggleEdit(e) }><i className="r-text--blue-3 d-inline-block fi flaticon-edit" style={{fontSize:"15px"}}/></Button>
                                                    <Button onClick={(e) => this.confirmDeleteGroup(data.group_id)}><i className="r-text--blue-3 d-inline-block fi flaticon-bin " style={{fontSize:"15px"}}/></Button>
                                               </Col>:<Col xs="2" className="text-right px-2" />}
                    </Row>}
                    {(this.state.modal)?<GroupTable refresh={this.props.updateGroup} modal={this.state.modal} toggle={this.toggle} groupid={this.props.data.group_id} batchid={batchid} departmentid={this.state.departmentid} type={type}/>:null}
                    <Confirmation isOpen={isConfirmation} toggleConfirmation={this.toggleConfirmation} callback={() => this.deletegroup(this.state.deleteConfirmationId)} />
                  </div>
    );
  }
}

export default EditGroup;