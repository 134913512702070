import React from 'react';
import { Row,Col,Container,  Card, Collapse, NavLink,TabContent, TabPane,Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';
import { connect } from 'react-redux';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import { HorizontalLine } from '../../../global/horizontal_line';
import { Select } from '../../../global/Forms/Select';
import { MonthSelect, YearSelect } from '../../../global/Forms/Select';
import Aux from '../../../global/AuxComp';


class Placement extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      sideNavLeft: null,
      sideNavRight: null,
      addSection:false,
      activeIndex: 0,
      processList:[{process_id:1,process_name:"placement 2017"},{process_id:2,process_name:"placement 2018"},{process_id:3,process_name:"placement 2019"}],
      company:[{name:'Reculta'},{name:'Apple'},{name:'Google'},{name:'Microsoft'},{name:'ITC'},{name:'Amazon'},{name:'Facebook'}],
      tab:1

    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }


  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.company.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.company.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }



  render() {
    const { activeIndex, processList } = this.state;

    return (
         <div className="pt-0 r-card">
          <div className="student__profile__section" >
            <h2 className="r-heading-4 r-text--blue-3 mb-0">Placement Report 2018</h2>
            <div className="student__profile__actions mt-4">
              <Button onClick={(e) => this.setState({addSection:true})} className="r-text--sm student__profile__actions--add fi flaticon-plus-1  " color="brandB"><span>Add</span></Button>
            </div>
            {!this.state.addSection?
              <Aux>
                <div className="student__profile__section" >
                    <Row >
                      <Col xs="3">
                        <Card className='r-bg--brandA r-text--light'>
                          <span className="r-subheading-1 px-2 py-2">100</span>
                        </Card>
                        <span style={{ fontSize: "12px" }}>Total Students</span>
                      </Col>
                      <Col xs="3">
                        <Card className='r-bg--brandA r-text--light'>
                          <span className="r-subheading-1 px-2 py-2">80</span>
                        </Card>
                        <span style={{ fontSize: "12px" }}>Registered Students</span>
                      </Col>
                      <Col xs="3">
                        <Card className='r-bg--brandA r-text--light'>
                          <span className="r-subheading-1 px-2 py-2">50</span>
                        </Card>
                        <span style={{ fontSize: "12px" }}>Domestic Offer</span>
                      </Col>
                      <Col xs="3">
                        <Card className='r-bg--brandA r-text--light'>
                          <span className="r-subheading-1 px-2 py-2">30</span>
                        </Card>
                        <span style={{ fontSize: "12px" }}>International Offer</span>
                      </Col>
                      <Col xs="3">
                        <Card className='r-bg--brandA r-text--light'>
                          <span className="r-subheading-1 px-2 py-2">12 lpa</span>
                        </Card>
                        <span style={{ fontSize: "12px" }}>Maximum Domestic CTC</span>
                      </Col>
                      <Col xs="3">
                        <Card className='r-bg--brandA r-text--light'>
                          <span className="r-subheading-1 px-2 py-2">5 lpa</span>
                        </Card>
                        <span style={{ fontSize: "12px" }}>Minimum Domestic CTC</span>
                      </Col>
                      <Col xs="3">
                        <Card className='r-bg--brandA r-text--light'>
                          <span className="r-subheading-1 px-2 py-2">62 lpa</span>
                        </Card>
                        <span style={{ fontSize: "12px" }}>Maximum International CTC</span>
                      </Col>
                      <Col xs="3">
                        <Card className='r-bg--brandA r-text--light'>
                          <span className="r-subheading-1 px-2 py-2">50 lpa</span>
                        </Card>
                        <span style={{ fontSize: "12px" }}>Minimum International CTC</span>
                      </Col>
                    </Row>
                      <Carousel
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}
                      >
                        {this.state.company.map((item,i) => {
                                              return (
                                                <CarouselItem
                                                  onExiting={this.onExiting}
                                                  onExited={this.onExited}
                                                  key={i}
                                                >
                                                <Card className='r-bg--brandA r-text--light '>
                                                  <span className="r-subheading-1 px-2 py-2 text-center">{item.name}</span>
                                                </Card>
                                                </CarouselItem>
                                              );
                                            })}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                      </Carousel>
                </div>
              </Aux>:<Aux>
                  <Row form className="mb-2 mt-4">
                    <Col xs={12}>
                      <label className="r-heading-7">Select Placment Year</label>
                      <YearSelect onChange={(e) => this.changeYear(e, "start_date", "year",)} value={""} />
                    </Col>
                  </Row>
                   <Row form className="mb-2">
                    <Col xs={6}>
                      <label className="r-heading-7" >Total Students</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                    <Col xs={6}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>Registered Students</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                  </Row>
                   <Row form className="mb-2">
                    <Col xs={6}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>Domestic Offer</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                    <Col xs={6}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>International Offer</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                  </Row>
                   <Row form className="mb-2">
                    <Col xs={6}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>Maximum Domestic CTC</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                    <Col xs={6}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>Minimum Domestic CTC</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                  </Row>
                   <Row form className="mb-2">
                    <Col xs={6}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>Maximum International CTC</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                    <Col xs={6}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>Minimum International CTC</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end mt-3">
                    <Button type="button" onClick={(e) => this.setState({addSection:false})} className="r-text--sm">Cancel</Button>
                    <Button type="submit" className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
                  </div>
              </Aux> }
          </div>
         </div>
    );
  }
}


export default Placement;