import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container,Collapse} from 'reactstrap';
import Button from '../../global/Button';
import { InputField } from '../../global/Forms';
import { Select } from '../../global/Forms/Select';
import { Switch } from '../../global/Forms/ToggleSwitch';


  export default class AppendCourse extends Component{
    constructor(props){
       super(props);
         this.state = { isOn: false, degreeList:this.props.degreeList || [] };
     }

	  handleToggle(e) {
  		this.setState({isOn: !this.state.isOn});
  	}


    render(){
      let { universityIndex, campusData, collegeIndex, campusIndex, courseIndex, removeAppend, createAppend, modifyObjDetails} = this.props;
      let { degreeList } = this.state;
      return (
        <Collapse isOpen={true}>
          <Row className="align-items-center">
            <Col xs={7} className="offset-2 pt-2" >
              <h1 className="r-subheading-4">Degree Name</h1>
              <Select onChange={(e) => modifyObjDetails(universityIndex, collegeIndex,campusIndex, courseIndex, 'degree_id', e.target.value)}   >
                <option >Select Degree</option>
                {
                  degreeList.map(degree => {
                    return (
                      <option  key={degree.degree_id} value={degree.degree_id}>{degree.degree_name}</option>
                        )
                    })
                }
              </Select>
            </Col>
            <Col xs={1}>
              <h1 className="r-subheading-4">Status</h1>
              <div className="mt-3">
              <Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)} />
              </div>
            </Col>
            { campusData.rip ? '':
            <Col xs={2} className="text-right">
              <Button onClick={() => removeAppend(universityIndex, collegeIndex, campusIndex, courseIndex )}   className="r-text--sm mt-4 py-2 px-1" color="outline-dark-v" square="true">Cancel</Button>
              <Button onClick={() => createAppend(universityIndex, collegeIndex, campusIndex, courseIndex )}  className="r-text--sm mt-4 ml-1 px-1 py-2" color="dark-v" square="true">Save</Button>
            </Col>
          }
          </Row>

          <Row>

          </Row>

        </Collapse>
    )
  }
}
