import React, { Component } from 'react';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../../actions/Auth';
import { Row,Col,Container,  Modal, ModalHeader,Nav,Table, NavItem,Collapse, NavLink,TabContent, TabPane, ModalBody, ModalFooter,Pagination, PaginationItem, PaginationLink ,Spinner} from 'reactstrap';
import moment from 'moment';
import Button from '../../../global/Button';
import { uploadFile, getFullName } from '../../../../actions';
import PaginationComponent from '../../../global/PaginationComponent.js';
import { InputField } from '../../../global/Forms';

 class StudentTable extends Component{

    constructor(props){
    super(props);
    this.state = {
      currentPage: 0,
      rowsPerPage: 10,
      selected: {},
      selectAll: 0,
      query:'',
      allStudents:props.processStudent,
      columns:[
        {id: "sno", title: "S. No."},
        {id: "name", title: "Name"},
        {id:"email", title:"Email"},
        {id:"roll_no", title:"Roll No."}, 
        {id: "approval_status", title: "Registration Status"},
        {id: "processDelete", title: "Action"}
      ],

    };
  }


    componentWillReceiveProps(props){
      this.setState({allStudents:props.processStudent,});
  }



  onChange = e => {
    this.setState({
        [e.target.name]: e.target.value,
    })
  }

  onSubmit = async(e) =>{
      e.preventDefault();
    this.setState({setloading: true});
      let data = [];
      await Object.keys(this.state.selected).forEach(id => {
          data.push(parseInt(id));
      });
      var groupBody = {
                "group_id":this.props.groupid,
                "student_id":data
          }
      try {
            let resp = await Auth.PtStudents.addStudentoGroup(groupBody);
            this.props.toggle();
            this.props.refresh();
          this.setState({setloading: false});
          } catch (e) {
            console.log(e);
          this.setState({setloading: false});
          }
  }

  selectRow = async(stID) => {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[stID] = !this.state.selected[stID];
    await this.setState({
      selected: newSelected,
      selectAll: 2
    });
  }

  toggleSelectAll = async(e)=> {
    let {currentPage,rowsPerPage} = this.state;
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.state.allStudents.slice( currentPage * rowsPerPage, currentPage*rowsPerPage +rowsPerPage  ).forEach(x => {
        newSelected[x.student_id] = true;
      });
    }

    await this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0
    });
  }


  handleSelected = (selectedPage) => {
    this.setState({ currentPage: selectedPage - 1 });
  }

  downloadStudents = () => {
    let { allStudents } = this.state;
    let arraystgroup = [
      ["Student Name", "Roll No.", "Email", "Batch Name", "Department", "Degree", "Registration Status", "Rejected reason"]
    ];
    allStudents.map(row => {
      var insertRow = [];
      insertRow.splice(0, 0, getFullName(row));
      insertRow.splice(2, 0, row["roll_no"]);
      insertRow.splice(3, 0, row["email"]);
      insertRow.splice(4, 0, row["batch_name"]);
      insertRow.splice(5, 0, row["department_name"]);
      insertRow.splice(6, 0, row["campus_degree_name"]);
      insertRow.splice(7, 0, row["approval_status"] ? ( row["approval_status"] === 1 ?  "Accepted" : "Rejected") : "Pending");
      insertRow.splice(8, 0, row["comment"] || "");
      arraystgroup.push(insertRow);
    });
    var CsvString = "";
    arraystgroup.forEach((RowItem, RowIndex) => {
      RowItem.forEach((ColItem, ColIndex) => {
        CsvString += ColItem + ',';
      });
      CsvString += "\r\n";
    });
    CsvString = "data:application/csv," + encodeURIComponent(CsvString);
    var x = document.createElement("A");
    x.setAttribute("href", CsvString);
    x.setAttribute("download", "Drive - students.csv");
    document.body.appendChild(x);
    x.click();

  }

  render(){
    let {columns,allStudents,currentPage,selectedProcess, rowsPerPage,query,selectAll} = this.state;
    let pagesCount =  Math.ceil(allStudents.length /rowsPerPage);
    let students = allStudents;
    let length = rowsPerPage*currentPage;
    students= allStudents.filter(row => {
      let name = getFullName(row);
      return name.toLowerCase().includes(query.toLowerCase()) || row.roll_no.toLowerCase().includes(query.toLowerCase())
    })

    return (
        <Modal  zIndex="99999"  isOpen={this.props.modal} toggle={this.props.toggle} size="xl"  style={{marginTop: "50px"}}>
              <Row className="justify-content-between align-items-center mx-0  mt-3">
              <Col xs="auto">
                <Row  className=" align-items-center mx-0 my-4">
                  <span className="r-heading-3">Students in current placement drive</span>
                </Row>
              </Col>
              <Col xs="auto" >
                  <Button onClick={this.props.toggle}><i className="d-inline-block fi flaticon-plus-1 rotate--45 mr-2" style={{fontSize:"20px"}}/></Button>
              </Col>
           </Row>
          <Row noGutters className="justify-content-end align-items-center">
            <Col xs={2} className="text-center">
               <Button className="r-text--sm" color="blue" onClick={this.downloadStudents}>Download</Button>
            </Col>
            <Col xs="3" className="px-2">
              <InputField className="w-100 my-3" placeholder="Search Name or Roll Number" name="query" value={query} onChange={(e) =>{ this.setState({ currentPage: 0, query: e.target.value})}} />
            </Col>
          </Row>
           {this.state.setloading? <div className="center-xy"><Spinner color="dark"/></div>:null}
           <Row className="align-items-center mx-0 px-0 mb-3" style={{overflowX: 'auto', overflowY: 'hidden', border: '2px solid black', borderColor:"#c4dcf3"}}>
               <Table >
                      <thead>
                        <tr  className="tableheader">
                            {columns.map((col,i) => {  
                              if(col.id === "sno")return(<th key={i} >S.No.</th>)
                              else if(col.id ==="name") return ( <th key={i}> Name</th>)
                              else return(<th  key={i}>{ col.title }</th> )
                            })}
                        </tr>
                      </thead>
                      <tbody>
                            {
                                  students.slice( parseInt(currentPage)*parseInt(rowsPerPage), (parseInt(currentPage)*parseInt(rowsPerPage)) +parseInt(rowsPerPage)  ).map((row,i) =>{
                                      length = length+1;
                                    return(
                                      <tr
                                          className="borderBottomlightblack"
                                          key={i}>
                                          {
                                            columns.map(col =>{
                                              if(col.id ==="sno") return (<td key={col.id}>{length}</td>)
                                              else if(col.id === "name")return(<td  key={col.id} >{row["first_name"]} {row["last_name"]}</td>)
                                              else if(col.id ==="year_of_passout") return (<td key={col.id}>{moment(row[col.id]).format(" YYYY")} </td>)
                                              else if(col.id ==="approval_status") return (
                                                  <td key={col.id}>
                                                    {!row[col.id] && "Pending"}
                                                    {row[col.id] === 1 && "Accepted"}
                                                    {row[col.id] === 2 && <span title={row.comment}>Rejected</span>}
                                                  </td>)
                                              else if(col.id === "processDelete") return (<td key={col.id}><Button onClick={() => this.props.confirmDeleteStudent(row.student_id)}><i className="fi flaticon-bin"></i></Button></td>)
                                              else return(<td key={col.id}>{row[col.id]}</td>)
                                            })
                                          }
                                      </tr>
                                    )
                                  })
                            }
                      </tbody>
                  </Table>
              </Row>
          <Row className="justify-content-between align-items-center  mx-0 px-0">
              <Col xs="auto" className="">
                <Row className="justify-content-between align-items-center">
                <span className="px-1 d-inline-block r-subheading-4 " style={{fontSize:"15px"}}>Total students are {allStudents.length}</span>
                <span className="px-3 d-inline-block r-subheading-4 " style={{fontSize:"15px"}}>View</span>
                <div className="r-dropdown border-0">
                    <select value={rowsPerPage} name="rowsPerPage" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={this.onChange}>
                      <option   value="10" >10</option>
                      <option value="15">15</option>
                       <option   value="20" >20</option>
                      <option value="25">25</option>
                       <option   value="50" >50</option>
                      <option value="100">100</option>
                    </select>
                  <i className="fi flaticon-arrow"/>
                </div>
                </Row>
              </Col>
              <Col xs="auto">
                  <PaginationComponent
                    totalItems={students.length}
                    pageSize={rowsPerPage}
                    onSelect={this.handleSelected}
                    maxPaginationNumbers={3}
                    activePage={currentPage + 1}
                  />
              </Col>
          </Row>
          </Modal>
  )
 }
}

export default StudentTable;
