import React,{Component} from 'react';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container} from 'reactstrap';
import {Button} from 'reactstrap';
import moment from 'moment';
import  {InputField} from '../../global/Forms';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import EditParticipants from './edit_participants';
import Aux from '../../global/AuxComp';
import Link from 'react-router-dom/Link';
import {Errorbar} from '../../global/errorbar';
import {  Modal,  ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Loader } from '../../global/Loader';
import { connect } from 'react-redux';
import { RTE } from '../../global/Forms/RTE';
import store from '../../../store';
import {CONSTANTS} from '../../../constants';
import { RTE2 } from '../../global/Forms/RTE-div';
import { getFiles, uploadFile } from '../../../actions';
import { ReactComponent as AttachmentLogo } from '../../../assets/icons/attachment.svg';

class Event extends Component{
  constructor(props){
    super(props);
    this.state={
      loading : false,
      modal:false,
      value: 1,
      totalPtMemebers : 0,
      totalStudent : 0,
      title: "",
      description : "",
      location: "",
      year: null,
      month:  null,
      day:   null,
      endYear:  null,
      endMonth:  null,
      endDay:  null,
      eventType : null,
      startDate: null,
      startTime: moment(),
      endTime:  moment(),
      start : new Date(),
      end: new Date,
      endDate : null,
      params : null,
      studentCollection : [],
      event_id : null,
      inviteEventId : null,
      studentMails : [],
      body: null,
      subject : null,
      isOn : null,
      inviteId : null,
      studentlist  : null,
      reminderValue : null,
      remindDate : moment(),
      remindTime : moment(),
      currentEvent:{},
      ptMemberList : [],
      eventId :  null,
      attendee_id : this.props.user.id,
      university_id : this.props.role.instituteDetail.university_id,
      college_id : this.props.role.instituteDetail.college_id,
      campus_id : this.props.role.instituteDetail.campus_id,
      errorbar: false,
      message:'',
      deleteEventModal : false,
      ptMemberSelected : [],
      deletePtList : [],
      publish : 0,
      generatePost: true,
      attachedFile : null,
      attachmentFile : null,
      file_id : null
    }
  }

  toggleLoading = (loading) =>{
    this.setState({
      loading
    })
  }

  handleToggle=()=>{
    this.setState({
      modal: !this.state.modal
    });
  }

  setPtMemberList = (list,ptMemberSelected, deletePtList )=>{
    this.setState({
      ptMemberList :list,
      ptMemberSelected,
      deletePtList
    })
  }

  setTotalPtMembers = (totalPtMemebers)=>{
    this.setState({
      totalPtMemebers
    })
  }

  setTotalStudent = (totalStudent)=>{
    this.setState({
      totalStudent
    })
  }

  closebar = ()=>{
      this.setState({errorbar: false});
  }

  setRemindDate = async(date)=>{
    await this.setState({
      remindDate: date
    })
    this.formatRemindTime();
  }

  setRemindTime = async(time)=>{
    await this.setState({
      remindTime :  time
    })
    this.formatRemindTime();
  }

  formatRemindTime = ()=>{
    let {remindTime,remindDate} = this.state;
    remindDate = (moment(remindDate).format('YYYY-MM-DD')+" "+moment(remindTime).format('HH:mm')+":00");
    this.setState({
      remindDate : moment(remindDate).utc(false)
    })
  }

  setRepeatReminderValue = (reminderValue)=>{
    this.setState({
      reminderValue
    })
  }

  setReminder = ()=>{
    let {studentlist, ptMemberList} = this.state;
    this.formatRemindTime();
    if(this.state.title!=null && this.state.description!=null){
      let remindTo = [];
      studentlist && studentlist.map(student=>{
        remindTo.push(student.student_id);
      })
      ptMemberList && ptMemberList.map(ptMember=>{
        remindTo.push(ptMember.user_id);
      })
      let params = {
        remind_through :"notification",
        subject : this.state.title ,
        remind_time : moment(this.state.remindDate).format('HH:mm')+":00" ,
        recurring_type :"once",
        notes :this.state.description ,
        remind_to : remindTo,
        remind_date : moment(this.state.remindDate).format('YYYY-MM-DD')
      }
      let create = Auth.Batch.createReminder(params);
    }
}

setSwitch = (isOn)=>{
  this.setState({
    isOn
  })
}

  setMailCollection = async (studentMails)=>{
    await this.setState({
      studentMails
    })
  }

  setStudenList = (studentlist)=>{
    this.setState({
      studentlist
    })
  }

  setStudentCollection = async(studentCollection,event_id)=>{
    await this.setState({
      studentCollection,
      event_id
    })
  }

  titleOnChange = (e)=>{
    this.setState({
      title: e.target.value
    })
  }
  descriptionOnChange = (value)=>{
    this.setState({
      description: value
    })
  }
  locationOnChange=(e)=>{
    this.setState({
      location: e.target.value
    })
  }
  eventTypeOnchange = (e)=>{
    this.setState({
      eventType: e.target.value
    })
  }
  OnChangeStartTime = (e)=>{
    this.setState({
      startTime : moment(e)
    })
  }
  onChangeEndTime = (e)=>{
    this.setState({
      endTime : moment(e)
    })
  }

  formatTime = ()=>{
    let {start,end,startTime,endTime} = this.state;
    let startDate = (moment(start).format('YYYY-MM-DD')+" "+moment(startTime).format('HH:mm:ss'));
    let endDate = (moment(end).format('YYYY-MM-DD')+" "+moment(endTime).format('HH:mm:ss'));
    this.setState({
      startDate,
      endDate
    })
  }

  onClickHandler =(value)=>{
    this.setState({
      value
    })
  }

  saveAndPublish = async ()=>{
   this.toggleLoading(true);
    let {studentMails, isOn, studentlist, ptMemberList} = this.state;
    if(this.state.eventId!=null){
      await this.updateEvent(this.state.eventId,0)
    }
    else{
      await this.saveEvent(0 , 1);
    }

    if(this.state.params){
      await this.setState({publish : 1});
      if(this.state.studentlist!=undefined  || this.state.ptMemberList.length!=0){
        if(this.state.title!=null  && this.state.description!=null){
          let inviteParams = {
            event_id : this.state.inviteEventId,
            university_id : this.props.role.instituteDetail.university_id,
            college_id : this.props.role.instituteDetail.college_id,
            campus_id : this.props.role.instituteDetail.campus_id,
            title : this.state.title,
            description : this.state.description,
            file_id : this.state.file_id
          }
          try{
            let createInvite = await Auth.Batch.createInvite(inviteParams);

            if(this.state.studentlist!=undefined && this.state.studentlist.length!=null){
              let studentEmailArray =[] , attendeeArray =[];
              this.state.studentlist.forEach(student=>{
                studentEmailArray.push(student.email);
                attendeeArray.push(student.user_id);
              })
                let sendInviteParams = {
                  invite_id : createInvite.data[0].invite_details[createInvite.data[0].invite_details.length-1].invite_id,
                  recipient_type :1,
                  recipient_id : attendeeArray,
                  start : moment(this.state.startDate).utc(false).format("YYYY-MM-DD HH:mm:ss"),
                  end : moment(this.state.endDate).utc(false).format("YYYY-MM-DD HH:mm:ss"),
                  title : this.state.title,
                  description : this.state.description,
                  location : this.state.location,
                  attendeeEmail : studentEmailArray,
                  file_id : this.state.file_id
                }
                try{
                  let sendInvites = await Auth.Batch.createInviteAttendee(sendInviteParams);

                }
                catch(e){
                  this.setState({errorbar: true});
                  if(e.response && e.response.data){
                    this.setState({message: e.response.data.error.toString()});
                  }
                  else{
                    this.setState({message: 'Something went wrong! Please try again'});
                  }
                }
            }
            if(this.state.ptMemberList!=undefined && this.state.ptMemberList.length!=0){
                let ptEmailArray = [], attendeeArray =[];
                ptMemberList.forEach(ptMember=>{
                  ptEmailArray.push(ptMember.userDetail.email);
                  attendeeArray.push(ptMember.user_id);
                })
                let sendInviteParams = {
                  invite_id : createInvite.data[0].invite_details[createInvite.data[0].invite_details.length-1].invite_id,
                  recipient_type :1,
                  start : moment(this.state.startDate).utc(false).format("YYYY-MM-DD HH:mm:ss"),
                  end : moment(this.state.endDate).utc(false).format("YYYY-MM-DD HH:mm:ss"),
                  title : this.state.title,
                  description : this.state.description,
                  location : this.state.location,
                  attendeeEmail :ptEmailArray,
                  recipient_id : attendeeArray
                }
                try{

                  let sendInvites = await Auth.Batch.createInviteAttendee(sendInviteParams);
                  // this.props.history.push("/calendar");
                }
                catch(e){
                  this.setState({errorbar: true});
                  if(e.response && e.response.data){
                    this.setState({message: e.response.data.error.toString()});
                  }
                  else{
                    this.setState({message: 'Something went wrong! Please try again'});
                  }
                }

              // })
            }

            this.props.history.push("/calendar");
          }
          catch(e){
            this.setState({errorbar: true});
            this.setState({message: e.response.data.error.toString()});
          }
        }
          else{
            this.setState({errorbar: true});
            this.setState({message: "Create event to create invites"});
          }

      }
      else{
        this.setState({errorbar: true});
        this.setState({message: "Select Students to create invites"});
      }

      if(this.state.remindTime  && this.state.remindDate){
        await this.setReminder();
      }
      await this.setState({
        title: null,
        description : null,
        location: null,
        year: null,
        month:  null,
        day:   null,
        endYear:  null,
        endMonth:  null,
        endDay:  null,
        startTime: null,
        endTime:  null,
        eventType : null,
        startDate: null,
        endDate : null,
        params : null,
        studentCollection : [],
        event_id : null,
        studentMails : [],
        body: null,
        subject : null,
        isOn : null,
        inviteId : null,
        studentlist  : null,
        reminderValue : null,
        remindDate : null,
        remindTime : null,
        ptMemberList : [],
        eventId :  null,
      })
      await this.setState({publish : 0});
      this.handleToggle();

    }
    else{

      this.setState({errorbar: true});
      this.setState({message: "Fill all the details to create event "});
    }


      this.toggleLoading(false);
  }


  saveEvent = async  (flag , publish)=>{
    if(publish==0){
      this.toggleLoading(true);
    }
    let description = document.createElement("div")
    description.innerHTML = this.state.description || ""
    if (description.textContent.trim() === "") description = ""
    if((this.state.title  && description  && this.state.location  && this.state.end !=null && this.state.start !=null) ){
      if (CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type] == 3 && !this.state.eventType){
        this.setState({errorbar: true});
        this.setState({message: "Fill all the details to create event "});
        this.toggleLoading(false)
        return
      }
      if(this.state.startDate <= this.state.endDate ){
        await this.formatTime();

        let params= {
          title : this.state.title,
          description: this.state.description,
          location: this.state.location,
          start_date: moment(this.state.startDate).utc(false).format("YYYY-MM-DD HH:mm:ss"),
          end_date: moment(this.state.endDate).utc(false).format("YYYY-MM-DD HH:mm:ss"),
          generatePost: this.state.generatePost
        }
          params.event_type = CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==5? 6 : this.state.eventType;
        try{
          let fileResp = {};
          if(this.state.attachmentFile){
            fileResp = await uploadFile(this.state.attachmentFile, `event/attachment`);
            params.file_id = fileResp.file_id;
            this.setState({file_id : fileResp.file_id})
          }
          let events = await Auth.Batch.createEvent(params);
          this.setState({inviteEventId : events.data.event_details[0].event_id})
          let attendeeParams = {
            event_id: events.data.event_details[0].event_id,
            attendee_id: [this.props.user.id],
            attendee_type:1,
            entity_id : events.data.event_details[0].event_id,
            entity_type : "EVENT",
            subject : this.state.title,
            notification_body : this.state.description,
            reciever_id : [ this.props.user.id ]
          }

          if(this.state.attachmentFile){
            attendeeParams.file_id = fileResp.file_id;
          }
          try{

            let createAttendee = await Auth.Batch.createAttendee(attendeeParams);

            this.setState({
              params
            })
          }
          catch(e){
            this.setState({errorbar: true});
            if(e.response && e.response.data){
              this.setState({message: e.response.data.error.toString()});
            }
            else{
              this.setState({message: 'Something went wrong! Please try again'});
            }
          }
            this.addAttendeeToEvent(events.data.event_details[0].event_id, fileResp.file_id);
            if(flag==1){
              this.setState({
              location: null,
                year: null,
                month:  null,
                day:   null,
                start : null,
                end  : null,
                endYear:  null,
                endMonth:  null,
                endDay:  null,
                startTime: null,
                endTime:  null,
                eventType : null,
                startDate: null,
                endDate : null,
                params : null,
                event_id : null,
                eventId :  null,
              })
            }
          }
          catch(e){
            this.setState({errorbar: true});
            if(e.response && e.response.data){
              this.setState({message: e.response.data.error.toString()});
            }
            else{
              this.setState({message: 'Something went wrong! Please try again'});
            }
          }
            if(flag==1){
              this.handleToggle()
              this.props.history.push("/calendar");

            }
      }
      else{
        this.setState({errorbar: true});
         // this.toggleLoading(false)
        this.setState({message: "Start and End time must have entered incorrect "});
      }
    }
    else{
      this.setState({errorbar: true});
      this.setState({message: "Fill all the details to create event "});
    }
    if(publish==0){
      this.toggleLoading(false)
    };
  }

changeTab = ()=>{
  this.setState({
    value : 2
  })
}

  addAttendeeToEvent = async (event_id, file_id)=>{
    let { studentCollection, ptMemberList, ptMemberSelected} = this.state;
    if(event_id!=undefined && studentCollection.length!=0){
        let attendeeArray = [];
        studentCollection.forEach(student=>{
          attendeeArray.push(student.user_id);
        })
          let params = {
            attendee_id : attendeeArray,
            event_id,
            attendee_type: 1,
            entity_id : event_id,
            entity_type : "EVENT",
            subject : this.state.title,
            notification_body : this.state.description,
            reciever_id : attendeeArray,
            file_id
          }
          let eventResponse = await Auth.Batch.createAttendee(params)
    }
    if(event_id!=undefined && ptMemberList.length!=0 && ptMemberSelected.length==0){
      let attendeeArray = [];
      ptMemberList.forEach(ptMember=>{
        attendeeArray.push(ptMember.user_id);
      })

      ptMemberList.map(async ptMember=>{
        let params = {
          event_id : event_id,
          attendee_type: 1,
          entity_id : event_id,
          entity_type : "EVENT",
          subject : this.state.title,
          notification_body : this.state.description,
          attendee_id : attendeeArray,
          reciever_id : attendeeArray
      }
        let eventResponse = await Auth.Batch.createAttendee(params);
      })
    }

    if(event_id!=undefined && ptMemberList.length!=0 && ptMemberSelected.length!=0){
      let params = [];
      for(let i =0;i<ptMemberList.length ; i++){
        let ptMember = ptMemberSelected.filter(ptMember=>ptMember.user_id == ptMemberList[i].user_id);
        if(ptMember.length==0){
          params.push({event_id : event_id,
          attendee_type : 1 ,
          entity_id : event_id,
          entity_type : "EVENT",
          subject : this.state.title,
          notification_body : this.state.description,
          attendee_id : [ptMemberList[i].user_id],
          reciever_id : [ptMemberList[i].user_id]
        })
      }
    }
    if(params.length!=0){
      for(let i=0;i<params.length ; i++){
        try{
          let eventResponse = await Auth.Batch.createAttendee(params[i]);
        }
        catch(e){
          this.setState({errorbar: true});
          if(e.response && e.response.data){
            this.setState({message: e.response.data.error.toString()});
          }
          else{
            this.setState({message: 'Something went wrong! Please try again'});
          }
        }
      }
    }
  }
}

  deleteEvent = async (eventId)=>{
    this.toggleLoading(true);
    let params = {
      event_id : eventId
    }
    try{
      let deleteSingleEvent = await Auth.Batch.deleteEvent(params);
      this.props.history.push("/calendar");
    }
    catch(e){
      this.setState({errorbar: true});
      if(e.response && e.response.data){
        this.setState({message: e.response.data.error.toString()});
      }
      else{
        this.setState({message: 'Something went wrong! Please try again'});
      }
    }
        this.setState({deleteEventModal : !this.state.deleteEventModal})
    this.toggleLoading(false);
  }

  getEvents = async ()=>{
    this.toggleLoading(true);
    if(this.props.match.params.event_id){
      let eventParams = {
        event_id:this.props.match.params.event_id
      }
      try{
        let events = await Auth.Batch.getEvent(eventParams);
        this.setState({currentEvent:events.data[0].event_details[0]})
        if(events.data[0].event_details.length!=0){
          var attachedFile = null;
          if(events.data[0].event_details[0].file_id){
            var fileResp = await getFiles([events.data[0].event_details[0].file_id]);
            attachedFile = fileResp[events.data[0].event_details[0].file_id] ? fileResp[events.data[0].event_details[0].file_id][0] : null;
          }
          await this.setState({
            eventId : events.data[0].event_details[0].event_id,
            title:events.data[0].event_details[0].title,
            description : events.data[0].event_details[0].description,
            location: events.data[0].event_details[0].location,
            eventType: events.data[0].event_details[0].event_type,
            start : moment(events.data[0].event_details[0].start_date).format('YYYY-MM-DD'),
            end: moment(events.data[0].event_details[0].end_date).format('YYYY-MM-DD'),
            startTime :  moment(events.data[0].event_details[0].start_date),
            endTime : moment(events.data[0].event_details[0].end_date),
            attachedFile
          })
          await this.formatTime();
        }
        else{
          this.props.history.push("/calendar");
        }
      }
      catch(e){
        console.log(e)
        this.setState({errorbar: true});
        if(e.response && e.response.data){
          this.setState({message: e.response.data.error.toString()});
        }
        else{
          this.setState({message: 'Something went wrong! Please try again'});
        }
      }
    }
    this.toggleLoading(false);
  }

  updateEvent = async (eventId , flag)=>{
    this.toggleLoading(true);
    await this.formatTime();
    await this.setState({inviteEventId : eventId});
    let description = document.createElement("div")
    description.innerHTML = this.state.description || ""
    if (description.textContent.trim() === "") description = ""
  if(this.state.startDate <= this.state.endDate && this.state.title && this.state.location && description && this.state.eventType){
    let params = {
      event_id: eventId,
      title : this.state.title,
      description: this.state.description,
      location: this.state.location,
      event_type : this.state.eventType
    }
    await this.formatTime();
    params.start_date =  moment(this.state.startDate).utc(false).format('YYYY-MM-DD HH:mm:ss');
    params.end_date = moment(this.state.endDate).utc(false).format('YYYY-MM-DD HH:mm:ss');
    if(!this.state.attachedFile) params.file_id = "0";
    let fileResp = {};
    if(this.state.attachmentFile){
      fileResp = await uploadFile(this.state.attachmentFile, `event/attachment`);
      params.file_id = fileResp.file_id;
    }
    try{
      let updateEvent = await Auth.Batch.updateEventDetails(params);
      try{
        if(this.state.studentlist!=null || this.state.ptMemberList.length!=0){
          await this.addAttendeeToEvent(eventId, fileResp.file_id);
        }
        await this.innactiveEventAttendees();
        if(!flag==0){
          this.props.history.push("/calendar");
        }
      }
      catch(e){
        console.log(e)
        this.setState({errorbar: true});
        if(e.response && e.response.data){
          this.setState({message: e.response.data.error.toString()});
        }
        else{
          this.setState({message: 'Something went wrong! Please try again'});
        }
      }
    }
    catch(e){
      console.log(e)
      this.setState({errorbar: true});
      if(e.response && e.response.data){
        this.setState({message: e.response.data.error.toString()});
      }
      else{
        this.setState({message: 'Something went wrong! Please try again'});
      }
    }
}
else{
  this.setState({errorbar: true});
  this.setState({message: "Fill all the mandatory fields correctly"})
}
    this.handleToggle();
    if(this.state.publish==0){
      this.toggleLoading(false)
    };

  }

  innactiveEventAttendees = async ()=>{
    if(this.props.match.params.event_id){
      let {ptMemberSelected , deletePtList} = this.state;
      if(ptMemberSelected != null){
        for(let i=0;i<deletePtList.length; i++){
            try{
              let params = {
                event_id: this.props.match.params.event_id,
                attendee_id : deletePtList[i].user_id
              }
                let deleteAttendee = await Auth.Batch.deleteEventAttendee(params);
            }
            catch(e){
              this.setState({errorbar: true});
              if(e.response && e.response.data){
                this.setState({message: e.response.data.error.toString()});
              }
              else{
                this.setState({message: 'Something went wrong! Please try again'});
              }
            }
          }
        }
      }
    }

  changeEnd = (end) => {
    this.setState({
      end: moment(end).format('YYYY-MM-DD')
    })
  }

  changeStart = (start) => {
    this.setState({
      start: moment(start).format('YYYY-MM-DD')
    })
  }

  componentWillMount(){
    this.getEvents();
  }

  componentWillReceiveProps(nextProps){
    this.props.match.params.event_id = nextProps.match.params.event_id;
    this.getEvents();
  }

  redirecttoAttandence = () =>{
    if(store.getState().role.role_type === 'PT'){
      let url = `/pt/attendance`
        this.props.history.push({
          pathname: url,
          state: { event: this.state.currentEvent}
        })
    }
  }


  render(){
    // console.log(this.state.studentCollection);
    return(
      <Container >
      <Loader show={this.state.loading} />
      <Row>
      <Col xs="1.5" className="p-0 m-4 mr-0" align="start"><span className="r-subheading-1 p-0 m-3" >Calendar</span></Col>
      <Col xs="6" className="p-0 m-0 mt-4 ml-4">
      <InputField className="m-1" placeholder="*Event Name" className="w-100 border-0 rounded m-1" onChange={this.titleOnChange} value={this.state.title} /></Col>
      <Col xs="1" className="m-2 mt-4  ">
      {this.props.match.params.event_id && (this.props.location.state &&  this.props.location.state.created_by == this.props.user.id)?
          <Button type="submit" className="calendar__save-button m-2 ml-5" color="dark" onClick={this.handleToggle}><span className="r-subheading-4 pl-3 pr-3">Save</span></Button>:null
      } 
      {!this.props.match.params.event_id?
          <Button type="submit" className="calendar__save-button m-2 ml-5" color="dark" onClick={this.handleToggle}><span className="r-subheading-4 pl-3 pr-3">Save</span></Button>:null
      }


      <Modal isOpen={this.state.modal} toggle={this.handleToggle} className="calendar__invitation-dialog">
        <ModalBody>

          {this.state.studentlist!=null || this.state.ptMemberList.length!=0?<span className="r-subheading-4">Would you like to send invitations mails to Reculta Calendar Guests?</span>:<span className="r-subheading-4">Would you like to save this Event?</span>}

        </ModalBody>
        <ModalFooter>
        {!this.props.match.params.event_id && <span className="mr-3"><input type="checkbox" className="mr-2" checked={this.state.generatePost} onChange={(e) => this.setState({generatePost : !this.state.generatePost})} />Auto Generate Feed</span>}
          <Button   onClick={this.props.match.params.event_id && CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==3 || (this.props.match.params.event_id!=null && (this.props.location.state && this.props.location.state.created_by == this.props.user.id)) ?()=>this.updateEvent(this.props.match.params.event_id, 1):()=>this.saveEvent(1 , 0)} className="pr-4 pl-4">Save</Button>{' '}
          {this.state.studentMails.length!=0 || this.state.ptMemberList.length!=0?<Button  onClick={()=>this.saveAndPublish()} className="ml-3 pr-4 pl-4">Send</Button>:null}
          <Button  onClick={this.handleToggle} className="ml-3 pr-4 pl-4">Cancel</Button>
        </ModalFooter>
      </Modal>



      </Col>
      {this.props.match.params.event_id!=null && (this.props.location.state && this.props.location.state.created_by == this.props.user.id)?
         <Col xs="1" className="m-2 mt-4 p-0">
         <Button type="submit" className="calendar__save-button m-2 ml-5" color="dark"><span className="r-subheading-4 pl-3 pr-3" onClick={()=> this.setState({deleteEventModal : !this.state.deleteEventModal})}>Delete</span></Button>
    </Col>:null}

      <Col xs="1" align="start" className="m-2 mt-4 pr-0">
      <Link to={"/calendar"} >
      <Button type="submit" className="calendar__discard-button m-2 " color="dark"><span className="r-subheading-4 pd-1">{this.props.match.params.event_id!=null &&  (this.props.location.state && this.props.location.state.created_by == this.props.user.id)?"Discard": this.props.match.params.event_id!=null?"Close":"Discard"}</span></Button>
      </Link>
      </Col>

      </Row>

      <Modal isOpen={this.state.deleteEventModal} zIndex="99999" size="md">
      <ModalHeader toggle={()=>this.setState({deleteEventModal : !this.state.deleteEventModal})}>Are you sure you want to delete?
      </ModalHeader>
      <ModalFooter>
      <Button  className="pr-4 pl-4  r-text--light border-0 r-subheading-4" color="dark"  onClick={()=>this.deleteEvent(this.props.match.params.event_id)}>Delete</Button>
        <Button  className="pr-4 pl-4 r-bg--grey-2 r-text--grey-4 border-0 r-subheading-4"   onClick={() => this.setState({ deleteEventModal : !this.state.deleteEventModal})}>Cancel</Button>
      </ModalFooter>
      </Modal>
      <Row>
      <Col xs="2">
      </Col>
        <Aux>
        <Col xs="6" className="p-0" >
         {/*<div>
          <Row>
          <Col className="m-1 p-0 pl-1">
          <span className="r-subheading-4">
          Title
          </span>
          </Col>
          </Row>
          <Row>
          <Col className="m-1 pl-0 pr-4 mr-3">
          <span className="r-subheading-4">
          <InputField  className=" m-1 w-100 border-0 rounded m-1" onChange={this.titleOnChange} value={this.state.title} />
          </span>
          </Col>
          </Row>
          </div>*/}
        <div>

        {/*<Row>
        <Col className="m-1 pl-0 pr-3 mr-4">
        <span className="r-subheading-4">
        Description
        </span>
        </Col>
        </Row>
        */}
        {/*<Row>
        <Col className="m-1 pl-0 pr-3 mr-4">
        <span className="r-subheading-4">
        <textarea  cols="5" rows="5" className="m-1 w-100 border-0 rounded m-1 calendar__description-box" value={this.state.description} onChange={this.descriptionOnChange}></textarea>
        </span>
        </Col>
        </Row>*/}
        <Row>
        <Col className="m-1 p-0 pl-1 mr-4 pr-3 calendar__event-description-box">
        <RTE2 onChange={(value)=>this.descriptionOnChange(value)}  hideToolbar={true} placeholder="*Type Description here" value={this.state.description} />
        </Col>
        </Row>
        <Row>
        <Col  className="p-0 pr-5 mt-5">
        {CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]!=5?
        <EditParticipants startDate={this.state.start && this.state.startTime && (moment(this.state.start).format('YYYY-MM-DD')+" "+moment(this.state.startTime).format('HH:mm:ss'))}  endDate={this.state.end && this.state.endTime && (moment(this.state.end).format('YYYY-MM-DD')+" "+moment(this.state.endTime).format('HH:mm:ss'))} user_id={this.props.user.id} role_type={this.props.role.role_type} university_id={this.props.role.instituteDetail.university_id} college_id={this.props.role.instituteDetail.campus_id} campus_id={this.props.role.instituteDetail.campus_id} setTotalPtMembers={this.setTotalPtMembers} setTotalStudent={this.setTotalStudent} eventId={this.props.match.params.event_id} title={this.state.title} description={this.state.description} setPtMemberList={this.setPtMemberList} setRemindTime={this.setRemindTime} setRemindDate={this.setRemindDate}  params={this.state.params} setStudentCollection={this.setStudentCollection} setRepeatReminderValue={this.setRepeatReminderValue} setMailCollection={this.setMailCollection} setStudenList={this.setStudenList} setSwitch={this.setSwitch} />:null}

        </Col>
        </Row>
        </div>
        </Col>
        {this.state.value==1?
        <Col xs="4">
        <div>
        {this.state.value==1?
        <Row>
        <Col className="m-1"><span className="r-text--red-1">*</span>
        <span className="r-subheading-4">
        Location
        </span>
        </Col>
        </Row>:null}
        {this.state.value==1?
        <Row>
        <Col className="m-1 pl-3 pr-4 mr-3">
        <span className="r-subheading-4">
        <InputField  className=" m-1 w-100 border-0 rounded m-1"  placeholder="Type in the location..." value={this.state.location} onChange = {this.locationOnChange}/>
        </span>
        </Col>
        </Row>:null}
        </div>
        <div>
        {this.state.value==1 && CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==3?

        <Row>
        <Col className="m-1">
        <span className="r-subheading-4">
        <span className="r-text--red-1">*</span>Category
        </span>
        </Col>
        </Row>:null}
        {this.state.value==1 && CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==3?
        <Row>
        <Col className="m-1">
        <div className="r-dropdown border-0">
        <select onChange = {this.eventTypeOnchange}  value={this.state.eventType} className="r-dropdown__select r-subheading-4 calendar__description-box r-text--grey-4">
        <option value="">Select</option>
        {CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==3?<option value="1">PPT  </option>:null}
        {CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==3?<option value="2">Conference </option>:null}
        {CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==3?<option value="3">College Fest </option>:null}
        {CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==3?<option value="4">Seminar </option>:null}
        {CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==3?<option value="5">Other</option>:null}
        {CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==5?<option value="6">Normal</option>:null}
        </select>
        <i className="fi flaticon-arrow"/>
        </div>
        </Col>
        </Row>: null}
        </div>
        <Row className="mt-4">
          <Col xs="12">
            <span className="r-subheading-4">Attachment</span>
          </Col>
          <Col className=" mt-1" xs="12" >
            {this.state.attachedFile ? 
              <div className="text-truncate r-tag r-tag--type-1">
                <AttachmentLogo width={10} />
                <a href={this.state.attachedFile.url} target="_blank" rel="noopener noreferrer">{this.state.attachedFile.file_name}</a> 
                <Button type="button" className="r-tag__delete fi flaticon-plus-1 text-dark" onClick={(e) => this.setState({attachedFile : null})}></Button>
              </div>
              :
            <input onChange={(e) => this.setState({attachmentFile : e.target.files[0]})} type="file" />
            }
          </Col>
        </Row>
        {this.state.value==1?<div>
        <Row>
        <Col className="m-1 mt-4">
        <span className="r-subheading-4">
        <span className="r-text--red-1">*</span>Start Time
        </span>
        </Col>
        </Row>
        <Row>

        <Col className="pl-4 pr-0">
                 </Col>
        </Row>
        </div>:null}
        <Row>
        <Col xs="5" className="pr-0">
        <SingleDatePicker
        value={this.state.start}
        onDateChange={this.changeStart}
          id="dob" />
        </Col>
        <Col xs="7">
        <TimePicker placeholder="hh:mm"  className="w-50" showSecond={false}  onChange={this.OnChangeStartTime} minuteStep={5} value={this.state.startTime}
        allowEmpty={false}
        inputReadOnly
        format= "hh:mm a" use12Hours />
        </Col>

        </Row>

        {this.state.value==1?
        <div>
        <Row>
        <Col className="m-1">
        <span className="r-subheading-4">
        <span className="r-text--red-1">*</span>End Time
        </span>
        </Col>
        </Row>
        <Row>

        <Col className="pl-4 pr-0">
                 </Col>
        </Row>
        </div>:null}
        <Row>
        <Col xs="5" className="pr-0">
        <SingleDatePicker
        value={this.state.end}
        onDateChange={this.changeEnd}

        id="dob"/>
        </Col>
        <Col xs="7">
        <TimePicker placeholder="hh:mm"   className="w-50" showSecond={false}  onChange={this.onChangeEndTime} minuteStep={5} value={this.state.endTime}
        allowEmpty={false}
        inputReadOnly
        format= "hh:mm a" use12Hours />
        </Col>

        </Row>
        <Row>
          {store.getState().role.role_type === 'PT' && this.props.match.params.event_id!=null && (this.props.location.state && this.props.location.state.created_by == this.props.user.id)?
          <Col xs="12" align="start" className="m-2 mt-4 pr-0">
            <Button type="button" onClick={() => this.redirecttoAttandence()} className="calendar__save-button m-2 " color="dark"><span className="r-subheading-4 pd-1">Mark Attendance</span></Button>
          </Col>:null}
          </Row>
        </Col>:null}
        <Col xs="2">
        </Col>
         </Aux>
      </Row>
        <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      </Container>
    )
  }
}
const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(Event);
