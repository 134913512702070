import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../../actions/Auth';
import { SelectList } from '../../../global/Forms/Select'
import { Switch } from '../../../global/Forms/ToggleSwitch';
import { setUser, setLoading } from '../../../../actions/action-creators';
import Button from '../../../global/Button';
import { Row, Col, Container } from 'reactstrap';
import PieChartCOMP from '../../../global/PieChartCOMP';
import { HorizontalLine } from '../../../global/horizontal_line';
import FilterComponent from '../../../global/filter-component';
import StudentSelect from './StudentSelect';
import { Loader } from '../../../global/Loader';
import { CONFIGURATION } from '../../../../constants/configuration';
import Aux from '../../../global/AuxComp';
import { Errorbar } from '../../../global/errorbar';

class Preferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stDetails: [],
      filterstDetails: [],
      roleArray: [],
      domainArray: [],
      locationArray: [],
      companyArray: [],
      batches: {},
      allStudents: [""],
      stPreferance: [],
      loader:false,
      modal: false,
      filterIcon: false,
      settings: {},
      selectedPriority: "Priority 1",
      process: { process_name: "Student Profiles", process_type: "student", process_id: 1 },
      config: CONFIGURATION,
      batchList:{},
      depList: {},
      groupList:{},
      studentFilters: {
        batches: [],
        departments: [],
        groups:[],
      },
      errorbar: false,
      message: ""
    };
  }



  componentWillMount() {
    this.init();
  }
  closebar = () => {
    this.setState({ errorbar: false });
  }
  init = async () => {
    this.setState({loader:true});
    try {
      let configResp = await Auth.User.getConfiguration();
      if(!configResp.data.STUDENT || !configResp.data.STUDENT.PROFILE) {
        configResp.data = {
          "STUDENT": {
            "PROFILE":{
              "preferences":1
            }
          }
        }
      }
      let response = await Auth.PtStudents.getBatch();
      let tempbatch = {};
      let tempsts = [];
      let tempstids = [];
      this.setState(prevState => ({
        studentFilters: {
          ...prevState.studentFilters,
          batches: response.data.length
        },
        settings: configResp.data
      }))
      response.data.forEach(batch => {
        tempbatch[batch.batch_id] = { batch_name: batch.batch_name, departments: {} };
        batch.student_info.forEach(student => {
          tempstids.push(student.student_id)
          tempsts.push(student)
          tempbatch[batch.batch_id].departments[student.degree_department_id] = { department_name: student.department_name }
        })
      })
      this.setState({ allStudents: tempsts });
      this.setState({ batches: tempbatch });
      let resp = await Auth.PtStudents.getPreferences(tempstids);
      let stPreferance = [];
      resp.data.forEach(data => {
        stPreferance.push(data);
      })
      this.setState({ stPreferance: stPreferance });
      let respsts = await Auth.PtStudents.getStudentsList(tempstids);
      let stDetails = [];
      respsts.data.forEach(data => {
        stDetails.push(data);
      })

      respsts.data.forEach((data, i) => {
        let array = [];
        resp.data.find(item => {
          if (item.student_id === data.student_id) {
            array.push(item);
          }
        })
        stDetails[i]["preferences"] = array;
      })
      let temprolearr = this.prefrenceType(stDetails, 1, "ROLE");
      let tempdomarr = this.prefrenceType(stDetails, 1, "DOMAIN");
      let templocationarr = this.prefrenceType(stDetails, 1, "LOCATION");
      this.setState({loader:false});
      this.setState({
        roleArray: temprolearr,
        domainArray: tempdomarr,
        locationArray: templocationarr,
        stDetails: stDetails,
        filterstDetails: stDetails
      })
      this.setState({ filterIcon: false });
    } catch (e) {
      console.log(e)
      this.setState({loader:false});
    }
  }



  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }


  prefrenceType = (stDetails, priority, type) => {
    let tempdom = {};
    let tempdomarr = [];

    stDetails.forEach(data => {
      data.preferences.forEach(res => {
        if (res.preferences_type === type && res.priority === priority) {
          tempdom[res.preferences] = (tempdom[res.preferences] || 0) + 1;
        }
      })
    })
    Object.keys(tempdom).forEach(data => {
      let dom = {
        name: data,
        value: tempdom[data],
      }
      tempdomarr.push(dom);
    })
    return tempdomarr;
  }



  exportToCsv = async () => {
    const { stDetails } = this.state;
    this.props.setLoading(true)
    try{
      var params = {
        student_id: stDetails.map(function(student) {
          return student.student_id;
        })
      }
        var resp = await Auth.Student.downloadPreferences(params)
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = `Student Preferences Data.csv`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    catch(e){
      this.setState({errorbar : true});
      if(e.response && e.response.data){
        this.setState({message : e.response.data.error ? e.response.data.error.toString() : "Something went wrong! Please try again"})
      }else{
        this.setState({message : "Something went wrong! Please try again"})
      }  
    }
    this.props.setLoading(false)
    // let arraystgroup = [];
    // let colums = ["First Name", "Last Name", "Roll No.", "Email", "Mobile", "Batch Name", "Department", "Degree", "Gender", "Preferences Type", "Preferences", "Priority", "Preferences Type", "Preferences", "Priority", "Preferences Type", "Preferences", "Priority", "Preferences Type", "Preferences", "Priority", "Preferences Type", "Preferences", "Priority", "Preferences Type", "Preferences", "Priority", "Preferences Type", "Preferences", "Priority", "Preferences Type", "Preferences", "Priority", "Preferences Type", "Preferences", "Priority"];
    // arraystgroup.push(colums);
    // stDetails.forEach(res => {
    //   let st = [];
    //   st.splice(0, 0, res["first_name"]);
    //   st.splice(1, 0, res["last_name"]);
    //   st.splice(2, 0, res["roll_no"]);
    //   st.splice(3, 0, res["email"]);
    //   st.splice(4, 0, res["mobile"]);
    //   st.splice(5, 0, res["batch_name"]);
    //   st.splice(6, 0, res["department_name"]);
    //   st.splice(7, 0, res["campus_degree_name"]);
    //   st.splice(8, 0, res["gender"]);
    //   if (res.preferences.length !== 0) {
    //     res.preferences.forEach(data => {
    //       st.push(data["preferences_type"]);
    //       st.push(data["preferences"]);
    //       st.push(data["priority"]);
    //     })
    //   }
    //   arraystgroup.push(st);
    // })

    // var CsvString = "";
    // arraystgroup.forEach((RowItem, RowIndex) => {
    //   RowItem.forEach((ColItem, ColIndex) => {
    //     CsvString += ColItem + ',';
    //   });
    //   CsvString += "\r\n";
    // });
    // CsvString = "data:application/csv," + encodeURIComponent(CsvString);
    // var x = document.createElement("A");
    // x.setAttribute("href", CsvString);
    // x.setAttribute("download", "studentspreferences.csv");
    // document.body.appendChild(x);
    // x.click();

  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }




  filterStudents = async (studentFilters, batchList,depList,groupList) => {
    this.setState({ filterIcon: true,batchList:batchList, depList:depList, groupList:groupList });
    this.toggle();
    this.setState(prevState => ({
      studentFilters: {
        ...prevState.studentFilters,
        batches: studentFilters.batches,
        departments: studentFilters.departments,
        groups: studentFilters.groups,
      }
    }))
    this.setState({loader:true});
    let students = this.state.allStudents;
    // let batchId = [];
    // let depId = [];
    // if (studentFilters.batches.length > 0){
    //   studentFilters.batches.forEach(bt => {
    //       batchId.push(batchList[bt].batch_id)
    //   }) 
    // }
    // if (studentFilters.departments.length > 0){
    //       studentFilters.departments.forEach(dept => {
    //           if(depList[dept]){
    //           depId.push(depList[dept].degree_department_id)
    //         }
    //       }) 
    // }
    // if((batchId.length > 0) || (depId.length>0)){
    //   try {
    //       let res = await Auth.PtStudents.getBatchSpec(batchId,depId);
    //         let tempsts = [];
    //         res.data.forEach(batch => {
    //           batch.student_info && batch.student_info.forEach(student => {
    //             tempsts.push(student)
    //           })
    //         })
    //       students = tempsts;
    //     } catch (e) {
    //       console.log(e);
    //     }
    // }

    // if (studentFilters.groups.length > 0){
    //       let sts = []
    //       Object.keys(groupList).forEach(group =>{
    //           if(studentFilters.groups.indexOf(group)>-1){
    //             groupList[group].student_info.forEach(st=>{
    //                           sts.push(st)
    //               })
    //           }
    //       });
    //       students = sts;
    // }

    if (studentFilters.batches.length > 0)
      students = students.filter(st => studentFilters.batches.indexOf(st.batch_id.toString()) > -1)
    if (studentFilters.departments.length > 0)
      students = students.filter(st => studentFilters.departments.indexOf(st.batch_id + "-" + st.degree_department_id) > -1)
    if (studentFilters.groups.length > 0)
      students = students.filter(st => studentFilters.groups.indexOf(st.batch_id + "-" + st.degree_department_id + "-" + st.group_id) > -1)
    let stDetails = [];
    students.forEach((data, i) => {
      stDetails.push(data);
      let array = [];
      this.state.stPreferance.find(item => {
        if (item.student_id === data.student_id) {
          array.push(item);
        }
      })
      stDetails[i]["preferences"] = array;
    })
    this.setState({ filterstDetails: stDetails });
    let temprolearr = this.prefrenceType(stDetails, 1, "ROLE");
    let tempdomarr = this.prefrenceType(stDetails, 1, "DOMAIN");
    let templocationarr = this.prefrenceType(stDetails, 1, "LOCATION");
    this.setState({
      selectedPriority: "Priority 1",
      roleArray: temprolearr,
      domainArray: tempdomarr,
      locationArray: templocationarr,
    })
    this.setState({loader:false});
  }

  handlePriority = (priorityName, priority) => {
    const { filterstDetails } = this.state;
    let temprolearr = this.prefrenceType(filterstDetails, priority, "ROLE");
    let tempdomarr = this.prefrenceType(filterstDetails, priority, "DOMAIN");
    let templocationarr = this.prefrenceType(filterstDetails, priority, "LOCATION");
    this.setState({
      selectedPriority: priorityName,
      roleArray: temprolearr,
      domainArray: tempdomarr,
      locationArray: templocationarr,
    })
  }

  togglePreferences = async() => {
    let { settings } = this.state;
    if(settings.STUDENT.PROFILE.preferences===1) {
      settings.STUDENT.PROFILE.preferences = 0
    } else {
      settings.STUDENT.PROFILE.preferences = 1
    }
    try {
      await Auth.User.createConfiguration({
        "module": "STUDENT",
        "feature": "PROFILE",
        "config": {
            ...settings.STUDENT.PROFILE
        }
    })
    this.setState({
      settings
    })
    } catch (e) {
      console.log(e)
    }
  }


  render() {
    let { roleArray, domainArray,loader, locationArray, selectedPriority, batches, studentFilters, allStudents, settings } = this.state,
    showPreferences = true;
    if(settings.STUDENT && settings.STUDENT.PROFILE && settings.STUDENT.PROFILE.preferences===0)
      showPreferences = false
    return (
      <Container className="apply">
        <Loader show={loader} />
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
        <Row className="justify-content-between align-items-center my-3">
          <Col xs="auto">
            <div className="heading-dropdown r-heading-3">
              <Button onClick={this.props.history.goBack} className="font-weight-normal py-0" color="blue3"><img style={{ height: "30px" }} src="/images/left-arrow.png" alt="" /></Button>
              {this.props.location.state && this.props.location.state["some"] == "state" ? "Settings/ Student Profiles/ Manage Preferences" : "Student Profiles/ Manage Preferences"}
            </div>
          </Col>
          {showPreferences && <Col xs="auto" className="px-2">
            <Button onClick={this.exportToCsv} className="font-weight-normal px-2 py-0" color="brandB"><i className="m-2 r-text--sm fi flaticon-file" />EXPORT TO CSV</Button>
          </Col>}
          {/* <Col xs="auto" className="px-2">
                         <Button onClick={this.togglestudent}  className="font-weight-normal px-2 py-0"><i className="m-2 r-text--sm fi flaticon-plus-1" /> Generate Statistics</Button>
                       </Col>*/}
        </Row>
        <HorizontalLine color="#cfcfcf" />
        <Row className="mt-3 align-items-center">
          <Col xs="auto">
            Show/Hide Preferences
          </Col>
          <Col xs="auto">
            <Switch isOn={showPreferences} handleToggle={this.togglePreferences}></Switch>
          </Col>
        </Row>
        {(showPreferences && !loader) && <Aux>
          <Row className="justify-content-between align-items-center mt-3 mb-3">
            <Col xs="auto">
              <Row className="align-items-center mt-3 mb-3">
                <Col xs='auto'>
                  You have selected {this.state.filterstDetails.length} students from {(studentFilters.departments.length === 0) ? "all" : studentFilters.departments.length} departments of {studentFilters.batches.length} batches
              </Col>
                <Col xs='auto' style={{ display: this.state.filterIcon ? 'none' : '' }}>
                  <Button onClick={this.toggle} className="font-weight-normal px-2 py-0" color="blue3"><img style={{ width: "21px" }} src="/images/filter-512.png" alt="" /></Button>
                </Col>
                <Col xs='auto' style={{ display: this.state.filterIcon ? ' ' : 'none' }}>
                  <Button onClick={this.init} className="font-weight-normal px-2 py-0" color="blue3"><img style={{ width: "21px" }} src="/images/clear_filters.png" alt="" /></Button>
                </Col>
              </Row>
            </Col>
            <Col xs="auto">
              <Row className=" align-items-center mt-3 mb-3">
                <div>View Statistics</div>
                <SelectList selected={selectedPriority} className="d-inline-block ml-3">
                  <li className="d-inline-block r-text--dark" onClick={(e) => this.handlePriority("Priority 1", 1)}>Priority 1</li>
                  <li className="d-inline-block r-text--dark" onClick={(e) => this.handlePriority("Priority 2", 2)}>Priority 2</li>
                  <li className="d-inline-block r-text--dark" onClick={(e) => this.handlePriority("Priority 3", 3)}>Priority 3</li>
                </SelectList>
              </Row>
            </Col>
          </Row>
          <Row className="my-2">
            <Col xs="12">
              <h3 className="r-heading-3">Domain</h3>
              {(domainArray.length !== 0) ? <PieChartCOMP data={domainArray} /> : <Row className="justify-content-center my-5"><img style={{ height: "250px" }} src="/images/nopie.png" alt="" /></Row>}
            </Col>
            <Col xs="12">
              <h3 className="r-heading-3">Role</h3>
              {(roleArray.length !== 0) ? <PieChartCOMP data={roleArray} /> : <Row className="justify-content-center my-5"><img style={{ height: "250px" }} src="/images/nopie.png" alt="" /></Row>}
            </Col>
            <Col xs="12">
              <h3 className="r-heading-3">Location</h3>
              {(locationArray.length !== 0) ? <PieChartCOMP data={locationArray} /> : <Row className="justify-content-center my-5"><img style={{ height: "250px" }} src="/images/nopie.png" alt="" /></Row>}
            </Col>
          </Row>
          {this.state.modal && <StudentSelect onRef={ref => (this.child = ref)} filterStudents={this.filterStudents} toggle={this.toggle} batches={batches} allStudents={allStudents} modal={this.state.modal} />}
        </Aux>}
      </Container>
    )
  }
}


const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoading: (show) => dispatch(setLoading(show)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Preferences));
