import React, { Component } from 'react';
import Aux from '../AuxComp';
import Button from '../Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import Spinner from 'reactstrap/lib/Spinner';
import { InputField } from '.';
import { Auth } from '../../../actions/Auth';

export class AddSuggestion extends Component {
  state = {
    isOpen: false,
    isLoading: false,
    serverError: false
  }

  handleSuggestionAdd = async () => {
    this.setState({ serverError: false })
    let formData = new FormData(this.refs.form)
    let serializedData = {}
    let invalidEntries = []
    formData.forEach((value, key) => {
      serializedData[key] = value;
      if (value === "")
        invalidEntries.push(key)
    })
    if (invalidEntries.length)
      return
    let method = null;
    switch (this.props.type) {
      case "institute":
        method = Auth.General.addInstitute
        break;
      case "degree":
        method = Auth.General.addDegree
        break;
      case "department":
        method = Auth.General.addDepartment
        break;
      case "school":
        method = Auth.General.addSchool
        break;
      case "board":
        method = Auth.General.addEducationBoard
        break;
      case "designation":
        method = Auth.General.addDesignation
        break;
      case "organization":
        method = Auth.General.addCompany
        break;
      case "sector":
        method = Auth.General.addSector
        break;
      case "industry":
        method = Auth.General.addIndustry
        break;
      default:
        break;
    }
    this.setState({ isLoading: true })
    try {
      await method(serializedData);
      this.setState({ isOpen: false })
    } catch (e) {
      console.log(e)
      this.setState({ serverError: true })
    }
    this.setState({ isLoading: false })
  }

  renderSuggestionFields = (type) => {
    switch (type) {
      case "institute": return (<div>
        <Row className="mb-3">
          <Col>
            <label className="r-heading-7">College Name<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="college_name" />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label className="r-heading-7">University Name<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="university_name" />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label className="r-heading-7">State<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="state" />
          </Col>
          <Col>
            <label className="r-heading-7">District<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="district" />
          </Col>
        </Row>
      </div>);
      case "degree": return (<div>
        <Row className="mb-3">
          <Col>
            <label className="r-heading-7">Degree Name<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="degree_name" />
          </Col>
        </Row>
      </div>);
      case "department": return (<div>
        <Row className="mb-3">
          <Col>
            <label className="r-heading-7">Department Name<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="department_name" />
          </Col>
        </Row>
      </div>);
      case "school": return (<div>
        <Row className="mb-3">
          <Col>
            <label className="r-heading-7">School Name<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="school_name" />
          </Col>
        </Row>
      </div>);
      case "board": return (<div>
        <Row className="mb-3">
          <Col>
            <label className="r-heading-7">Board Name<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="board_name" />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label className="r-heading-7">Board Short Form<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="board_short_form" />
          </Col>
        </Row>
      </div>);
      case "designation": return (<div>
        <Row className="mb-3">
          <Col>
            <label className="r-heading-7">Designation Name<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="designation_name" />
          </Col>
        </Row>
      </div>);
      case "organization": return (<div>
        <Row className="mb-3">
          <Col>
            <label className="r-heading-7">Organization Name<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="company_name" />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label className="r-heading-7">Organization Website<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="company_website" />
            <InputField type="hidden" name="industry_id" value="1" />
            <InputField type="hidden" name="sector_id" value="1" />
          </Col>
        </Row>
      </div>);
      case "sector": return (<div>
        <Row className="mb-3">
          <Col>
            <label className="r-heading-7">Sector Name<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="sector_name" />
          </Col>
        </Row>
      </div>);
      case "industry": return (<div>
        <Row className="mb-3">
          <Col>
            <label className="r-heading-7">Industry Name<span className="r-text--brandF">*</span></label>
            <InputField className="w-100" type="text" name="industry_name" />
            <InputField type="hidden" name="sector_id" value="1" />
          </Col>
        </Row>
      </div>);
      default: break

    }
  }
  render() {
    let { isOpen, isLoading, serverError } = this.state,
      { type } = this.props;
    return (
      <Aux>
        <Button type="button" onClick={() => this.setState({ isOpen: true })} className="r-text--sm r-text--blue-3"><i className="mr-2 fi flaticon-plus-1" />Add</Button>
        <Modal className="modal--centered" isOpen={isOpen} toggle={() => this.setState({ isOpen: false })}>
          <ModalBody>
            <form ref="form">
              {this.renderSuggestionFields(type)}
              <p className="r-subheading-4 r-text--brandF">{serverError ? "Something went wrong, please try again later" : " "}</p>
              <div className="text-right r-text--sm">
                {isLoading && <Spinner color="dark" />}
                <Button onClick={this.handleSuggestionAdd} type="button" className="r-btn--no-radius" color="dark">Add</Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </Aux>
    )
  }
}