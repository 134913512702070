import React,{Component} from 'react';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container} from 'reactstrap';
import {Button, ButtonGroup} from 'reactstrap';
import moment from 'moment';
import  {InputField} from '../../global/Forms';
import {HorizontalLine} from '../../global/horizontal_line';
import MeetingPrimarySection from './meeting_primary_section';
import CompanySection from './company_section';
import Aux from '../../global/AuxComp';

class MeetingEditParticipants extends Component {
  constructor() {
    super();
    this.state={
      value : 1
    }

  }
  onClickHandler = (value)=>{
    this.setState({
      value
    })
  }

  render(){
    return(
      <Aux>
      <Row>
      <div className="m-0 ml-4">
      <button type="button" className={"pl-3 pr-3 calendar__edit-partipants__top-buttons r-subheading-4 r-text--grey-4  "+(this.state.value==1?"active":null)} onClick={()=>this.onClickHandler(1)}>Placement Team</button>
      <button type="button" className={" pl-3 pr-3 calendar__edit-partipants__top-buttons r-subheading-4 r-text--grey-4  "+(this.state.value==2?"active":null)} onClick={()=>this.onClickHandler(2)}>Company</button>
      </div>
      </Row>
      <hr  color="black" class="m-0"/>
      <div className={this.state.value==1?"":"d-none"}>
      <MeetingPrimarySection user_id={this.props.user_id} role_type={this.props.role_type} university_id={this.props.university_id} college_id={this.props.college_id} campus_id={this.props.campus_id} setPtMemberMails={this.props.setPtMemberMails} setTotalPtMembers={this.props.setTotalPtMembers} setPtMemberList={this.props.setPtMemberList} meetingId={this.props.meetingId} />
      </div>
      <div className={this.state.value==2?"":"d-none"}>
      <CompanySection user_id={this.props.user_id} role_type={this.props.role_type} university_id={this.props.university_id} college_id={this.props.college_id} campus_id={this.props.campus_id} setTotalCompanyMembers={this.props.setTotalCompanyMembers} setContactList={this.props.setContactList} meetingId={this.props.meetingId} selectedCompany={this.props.selectedCompany} params={this.props.params} />
      </div>
      </Aux>
    )
  }
}

export default MeetingEditParticipants;
