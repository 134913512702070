import React from 'react';
import Autosuggest from 'react-autosuggest';
import { Auth } from '../../actions/Auth';

class AutosuggestComp extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      suggestions:[],
      min3: false,
      wrongSugges: false,
    };
  }


  autoSearch = async ({ value }, method) => {
    this.setState({min3:false})
    this.setState({wrongSugges:false})
    try {
      let searchResp = await Auth.General[method](value);
      this.setState({
        suggestions:searchResp.data
      })
    } catch (e) {
      if(e.response.data.error.toString() ==="Invalid department_name.length should be min 3."){
              this.setState({min3:true})
      }
    }
  }

  clearSuggestionList = () => {
    this.setState({ suggestions: [] })
              this.setState({min3:false})
  }

  render() {
    const{ suggestionID,inputProps,min3} = this.props;
    
    return (
      <div>
               <Autosuggest
                        inputProps={inputProps}
                      suggestions={this.state.suggestions}
                      onSuggestionsFetchRequested={(data) => this.autoSearch(data, suggestionID)}
                      getSuggestionValue={(value) => this.props.setValue(value)}
                      renderSuggestion={this.props.renderSuggestion}
                      onSuggestionsClearRequested={() => this.clearSuggestionList()}
                    />
                    {min3?<span style={{color: "red"}}>Minimum character length should be 3</span>:null}
      </div>
    );
  }
}
export default AutosuggestComp;