import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from './global/Button';

class TermsCondition extends Component {
  constructor(props) {
    super(props)
  }


  render() {
    return (
      <Modal isOpen={true} className="modal-lg">
          <div className="border-bottom py-3 text-center" >Terms & Conditions</div>
          <ModalBody>
            <iframe src="/tnc_first_login.pdf" className="w-100" height="500"></iframe>
          </ModalBody>
          <ModalFooter>
          <Button color="outline-dark-v" square={true} onClick={() => this.props.updateTerms(false)}>Exit</Button>
          {' '}
          <Button color="dark" square={true} onClick={() => this.props.updateTerms(true)}>Agree</Button>
          </ModalFooter>
        </Modal>
    )
  }
}

export default TermsCondition
