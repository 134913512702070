import React,{Component} from 'react';
import ReactQuill,{Quill} from 'react-quill';
let alignStyle = Quill.import('attributors/style/align');
Quill.register(alignStyle, true);
let Parchment = Quill.import('parchment');
const config = {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['-0.5px','0','0.5px']
}
var lineHtClass = new Parchment.Attributor.Class('letterspacing','ql-letter-spacing', config);
let lineHtStyle = new Parchment.Attributor.Style('letterspacing','letter-spacing', config);
Parchment.register(lineHtStyle);
// var Block = Quill.import('blots/block');
// Block.tagName = 'DIV';
// Quill.register(Block, true);
export class RTE2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modules : {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          ["link"], [{ 'list': 'bullet' }]
        ],
      },
      formats: [
        "font",
        "size",
        "header",
        "bold",
        "italic",
        "underline",
        "align",
        "link",
        "background",
        "group",
        "image",
        "indent",
        "color",
        "spacing",
        "list",
        "code",
        "width",
        "height",
        "style",
        "code-block",
      ]
    }
  }
  render() {
    return (
      <ReactQuill preserveWhitespace className={"r-rte" + (this.props.hideToolbar?" r-rte-toolbar-hide":"")} {...this.state} {...this.props} ref={this.props.inputRef} />
    )
  }
}
