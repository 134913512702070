import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Collapse from 'reactstrap/lib/Collapse';
import Button from '../../../../global/Button';
import moment from 'moment';
import { CONSTANTS } from '../../../../../constants';
import { ProfileActions } from './profile-actions';
import { InputField } from '../../../../global/Forms';
import { SingleDatePicker } from '../../../../global/Forms/DatePicker';
import { FileUpload } from '../../../../global/FileUpload';
import { Select } from '../../../../global/Forms/Select';
import { Auth } from '../../../../../actions/Auth';
import { Multifield } from '../../../../global/Forms/Multifield';
import { replaceFile, getFullName } from '../../../../../actions';
import { RTE } from '../../../../global/Forms/RTE';
import { RichTextViewer } from '../../../../global/Forms/RichTextViewer';
import { Errorbar } from '../../../../global/errorbar';
import store from '../../../../../store';
import { ReactComponent as SkypeLogo } from "../../../../../assets/icons/skype.svg"
export class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorbar: false,
      message: ''
    }
    this.RTEProps = {
      modules : {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{'list': 'bullet'}],
          [{ align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [{'letterspacing': ["-0.5px","0","0.5px"]}],
          [{ 'script': 'sub'}, { 'script': 'super' }],
          ['link']
        ],
      },

      formats : [
        'bold', 'italic', 'underline','list', 'bullet', 'align','letterspacing','script'
      ]
    }
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  handlePrefsChange = (e, pref, priority) => {
    if (!pref) {
      pref = {};
      pref.preferences_type = e.target.name
    }
    pref.priority = priority;
    pref.preferences = e.target.value;
    pref.student_id = this.props.user_info.student_id;
    this.props.changePreferences(pref)
  }

  handlePrefSubmit = async (e) => {
    e.preventDefault();
    let { preferences } = this.props;
    this.props.toggleLoading(true)
    let response = await Promise.all(preferences.filter(pref => !(typeof pref.id === "string" && pref.preferences.trim() === "")).map(async (pref) => {
      let method = Auth.Student.updatePreference,
        resp = 0;
      if (typeof pref.id === "string") {
        method = Auth.Student.createPreference;
        delete pref.id;
      }
      try {
        resp = await method(pref)
      } catch (e) {
        if (e.response) {
          this.setState({ message: e.response.data.error.toString() });
          this.setState({ errorbar: true });
        } else {
          this.setState({ message: 'Sorry! something went wrong. Please try again' });
          this.setState({ errorbar: true });
        }
      }
      return resp
    }))
    this.props.toggleLoading(false)
    if (response.indexOf(0) < 0) {
      await this.props.getStudentProfile()
      this.props.toggleSection("")
    }
  }
  changeDob = (mDate) => {
    let targetObj = {
      target: {
        name: "user_info",
        id: "dob",
        value: mDate.format(CONSTANTS.DATE_FORMAT)
      }
    }
    this.props.changeProfile(targetObj)
  }
  getContactAddress = ({ city, state, country }) => {
    let contact = [];
    if (city) contact.push(city)
    if (state) contact.push(state)
    if (country) contact.push(country)
    return contact;
  }

  getFilteredPreferences = (preferences) => {
    let roles = [], domains = [], locations = []
    preferences.forEach(pref => {
      switch (pref.preferences_type) {
        case "ROLE": if (roles.length < 3) roles.push(pref);
          break;
        case "DOMAIN": if (domains.length < 3) domains.push(pref)
          break;
        case "LOCATION": if (locations.length < 3) locations.push(pref)
          break;
        default: break;
      }
    })
    return {
      roles: roles.sort(this.prioritySort),
      domains: domains.sort(this.prioritySort),
      locations: locations.sort(this.prioritySort)
    }
  }

  prioritySort = (a, b) => {
    if (a.priority < b.priority)
      return -1;
    if (a.priority > b.priority)
      return 1;
    return 0;
  }

  updateProfileLinks = e => {
    let { profile_links } = this.props.user_info
    try {
      profile_links = JSON.parse(profile_links)
    } catch (e) {
      profile_links = {}
    }
    profile_links[e.target.name] = e.target.value;
    this.props.changeProfile({ target: { name: 'user_info', id: 'profile_links', value: JSON.stringify(profile_links) } })
  }

  changeProfileImage = async (e) => {
    if (e.target.files[0]) {
      this.props.toggleLoading(true)
      let file = await replaceFile(e.target.files[0], `profile_images/${this.props.user_info.user_id}/profile`)
      this.props.toggleLoading(false)
      if (file) {
        this.props.changeProfile({
          target: {
            name: 'personal_info',
            id: 'image_url',
            value: file.file_id
          }
        })
        await this.updatePersonalInfo()
      }
    }
  }



  updateUserInfo = async (e) => {
    if (e) e.preventDefault(e);
    let { user_info } = this.props;
    let formData = {
      id: user_info.user_id,
      gender: user_info.gender,
      city: user_info.city,
      state: user_info.state,
      country: user_info.country,
    }
    let msg = '';
    Object.keys(formData).map(async data=>{
      if(!formData[data]){
        msg = 'Fill the mandatory fields';
        // this.props.toggleLoading(false);
      }})
      if(msg){
        this.setState({ message: 'Fill the mandatory fields' });
        this.setState({ errorbar: true });
      }
      else{
        this.props.toggleLoading(true)
        if (user_info.dob)
        formData.dob = moment(user_info.dob).startOf("day").add(12, "hours").format(CONSTANTS.DATE_FORMAT)
        try {

          await Auth.User.updateUserInfo(formData)
          this.props.toggleLoading(false)
          if (e) {
            await this.props.getStudentProfile()
            this.props.toggleSection("")
          }
        } catch (e) {
          this.props.toggleLoading(false)
          if (e.response.data.error.toString() !== null) {
            this.setState({ message: e.response.data.error.toString() });
            this.setState({ errorbar: true });
          } else {
            this.setState({ message: 'Sorry! something went wrong. Please try again' });
            this.setState({ errorbar: true });
          }

        }
      }
  }

  updatePersonalInfo = async (e) => {
    let { personal_info, user_info } = this.props;
    if (e) e.preventDefault(e);
    let summary = document.createElement("div")
    summary.innerHTML = personal_info.personal_summary || ""
    if (summary.textContent.trim() === "") personal_info.personal_summary = ""
    this.props.toggleLoading(true)
    let formData = {
      ...personal_info
    }
    formData.student_id = user_info.student_id
    try {
      if (personal_info.student_id) {
        await Auth.Student.updatePersonalDetail(formData)
      } else {
        await Auth.Student.createPersonalDetail(formData)
      }
      this.props.toggleLoading(false)
      this.props.getStudentProfile();
      if (e) {
        this.props.toggleSection("")
      }
    } catch (e) {
      this.props.toggleLoading(false)
      if (e.response.data.error.toString() !== null) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
  }

  updateContactInfo = async (e) => {
    e.preventDefault();
    let { user_info } = this.props;
    if(user_info.aadhaar_number && !/^\d{12}$/.test(user_info.aadhaar_number)) {
      this.setState({ errorbar: true, message: 'Invalid Aadhaar Number' });
      this.props.toggleLoading(false)
      return;
    }
    if(user_info.pan_number && !/^[A-Za-z0-9]{10}$/.test(user_info.pan_number)) {
      this.setState({ errorbar: true, message: 'Invalid PAN Number' });
      this.props.toggleLoading(false)
      return;
    }
    this.props.toggleLoading(true)
    let formData = {
      id: user_info.user_id,
      mobile: user_info.mobile,
      email: user_info.email,
      aadhaar_number: user_info.aadhaar_number || 0,
      pan_number: user_info.pan_number || "",
      profile_links: JSON.parse(user_info.profile_links)
    }
    if(formData.email && formData.mobile ){
      try {
        await Auth.User.updateUserInfo(formData)
        this.props.toggleLoading(false)
        await this.props.getStudentProfile()
        this.props.toggleSection("")

      } catch (e) {
        this.props.toggleLoading(false)
        if (e.response.data.error.toString() !== null) {
          this.setState({ message: e.response.data.error.toString() });
          this.setState({ errorbar: true });
        } else {
          this.setState({ message: 'Sorry! something went wrong. Please try again' });
          this.setState({ errorbar: true });
        }

      }
    }
    else{
      this.props.toggleLoading(false);
      this.setState({ errorbar: true });
      this.setState({ message: 'Email and Telephone fields are mandatory' });
    }
  }

  updateHobbies = async (hobbies) => {
    this.props.changeProfile({
      target: {
        name: "personal_info",
        id: "hobbies",
        value: hobbies.join(CONSTANTS.DELIMITER) ? hobbies.join(CONSTANTS.DELIMITER) : CONSTANTS.DELIMITER
      }
    })
    await this.updatePersonalInfo()
  }

  submitHobbies = (e) => {
    let el = e.target.form.querySelector('input[type="submit"]');
    if (el) {
      el.click()
      this.props.toggleSection();
    }
  }

  saveSection = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button type="button" onClick={() => this.props.onCancel()} className="r-text--sm">Cancel</Button>
        <Button type="submit" className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
      </div>
    )
  }



  render() {
    let { files, roleList, domainList, personal_info, user_info, preferences, toggleSection, editableSection, commentThreads, settings } = this.props,
      address = this.getContactAddress(user_info),
      filteredPreferences = this.getFilteredPreferences(preferences),
      profileLinks = {
        keys: [],
        values: {}
      },
      hobbies = personal_info.hobbies && personal_info.hobbies !== CONSTANTS.DELIMITER ? personal_info.hobbies.split(CONSTANTS.DELIMITER) : [],
      showPreferences = true;
    if (user_info.profile_links) {
      try {
        profileLinks.values = JSON.parse(user_info.profile_links);
        profileLinks.keys = Object.keys(profileLinks.values);
      } catch (e) {
      }
    }
    if (settings && settings.STUDENT && settings.STUDENT.PROFILE && settings.STUDENT.PROFILE.preferences == 0)
      showPreferences = false
    return (
      <div className="r-card">
        <div className="student__profile__section pt-0 d-flex flex-row">
          <div className="student__profile__image__container">
            <img src={files[personal_info.image_url] ? files[personal_info.image_url][0].url : "/images/dummy_profile.jpg"} className="img-fluid student__profile__image" alt="Profile Image" />
            <span className="fi flaticon-edit-2 student__profile__image--edit">
              <input title="Update Profile Picture" type="file" accept="image/png, image/jpeg" className="student__profile__image--upload" onChange={this.changeProfileImage} />
            </span>
          </div>
          <div className="d-flex flex-column justify-content-between px-4">
            <div>
              <h1 className="mb-0 r-heading-6 text-capitalize">{getFullName(user_info) || ""}</h1>
              <p className="mb-0 r-subheading-3">{user_info.department_name || "" }</p>
              <p className="mb-0 r-subheading-3">{user_info.roll_no || ""}</p>
              <p>{this.props.profileStatus}</p>
            </div>
            <div className="video-resume__container">
              {/* <Button disabled={!personal_info.video_resume_url} className="video-resume mt-4 justify-content-center" color="blue-3">Video Resume <i className="fi flaticon-multimedia ml-2 align-middle"></i></Button>
              <Button className="fi flaticon-edit-2"></Button> */}
            </div>
          </div>
        </div>
        <div className="student__profile__section pt-0">
          <Collapse isOpen={editableSection !== "address"}>
            <p className="fi-abs fi flaticon-birth"><span>{user_info.dob ? moment(user_info.dob).format("DD-MM-YYYY") : <em>Date of Birth</em>}</span> . <span className="text-capitalize">{(user_info.gender === "NOT_SELECTED" ? null : user_info.gender) || <em>Gender</em>}</span></p>
            <p className="fi fi-abs flaticon-pin">{address.length > 0 ? address.join(", ") : <em>Location</em>}</p>
            {(files[personal_info.file]) && <Row noGutters>
              {files[personal_info.file].map((doc, i) => <Col className="pr-3" key={i} xs="auto">
                <div className="r-tag r-tag--type-1 r-tag--no-delete">
                  <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.file_name || doc.file_url || "Filename"}</a>
                </div>
              </Col>)}
            </Row>}
          </Collapse>
          {editableSection !== "address" && <ProfileActions toggleSection={() => toggleSection("address")} verification={{ ...personal_info }} isRequiredValidate={this.props.requiredValidation([user_info.dob, address])} commentThreads={commentThreads} profileType="PERSONAL" />}
          <Collapse isOpen={editableSection === "address"}>
            <form onSubmit={(e) => {this.updateUserInfo(e); this.updatePersonalInfo(e)}}>
              <Row form className="mb-3 align-items-center">
                <Col xs={6}>
                  <label className="r-heading-7" htmlFor="dob"><span className="r-text--red-1">*</span>Date of Birth</label>
                  <SingleDatePicker
                    value={user_info.dob}
                    onDateChange={this.changeDob}
                    focused={this.state.dobFocused}
                    id="dob" />
                </Col>
                <Col xs={6}>
                  <label className="r-heading-7" htmlFor="gender"><span className="r-text--red-1">*</span>Gender</label>
                  <Select name="user_info" id="gender" value={user_info.gender || ""} onChange={this.props.changeProfile}>
                    <option value="NOT_SELECTED">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Select>
                </Col>
              </Row>
              <Row form className="mb-3 align-items-end">
                <Col xs={6}>
                  <FileUpload toggleLoading={this.props.toggleLoading} updateData={this.props.changeProfile} submitData={async () => { await this.updateUserInfo(); await this.updatePersonalInfo(); }} verification={{ user_id: user_info.user_id, data: personal_info, files }} />
                </Col>
                <Col xs={6}>
                  <label className="r-heading-7" htmlFor="city"><span className="r-text--red-1">*</span>City/Town</label>
                  <InputField name="user_info" id="city" onChange={this.props.changeProfile} className="w-100" type="text" value={user_info.city || ""}  />
                </Col>
              </Row>
              <Row form className="mb-3">
                <Col xs={6}>
                  <label className="r-heading-7" htmlFor="state"><span className="r-text--red-1">*</span>State</label>
                  <InputField name="user_info" id="state" onChange={this.props.changeProfile} className="w-100" type="text" value={user_info.state || ""} />
                </Col>
                <Col xs={6}>
                  <label className="r-heading-7" htmlFor="country"><span className="r-text--red-1">*</span>Country</label>
                  <InputField name="user_info" id="country" onChange={this.props.changeProfile} className="w-100" type="text" value={user_info.country || ""}  />
                </Col>
              </Row>
              <Row form className="mb-3">
                <Col xs={6}>
                  <label className="r-heading-7" htmlFor="category">Category</label>
                  <Select name="personal_info" id="category" value={personal_info.category || ""} onChange={this.props.changeProfile}>
                    <option value="">Select</option>
                    <option value="SC">SC</option>
                    <option value="ST">ST</option>
                    <option value="OBC">OBC</option>
                    <option value="General">General</option>
                  </Select>
                </Col>
                <Col xs={6}>
                  <label className="r-heading-7" htmlFor="parents_annual_income">Parents Anual Income</label>
                  <InputField name="personal_info" id="parents_annual_income" onChange={this.props.changeProfile} className="w-100" type="number" value={personal_info.parents_annual_income || ""}  />
                </Col>
              </Row>
              <Row form className="mb-3 mt-5">
                <Col xs={6}>
                  <InputField id="vision_issues" checked={personal_info.vision_issues == "1" ? true : false} type="checkbox" className="mr-2" value="Do you have any vision related issues" onChange={(e) => this.props.changeProfile({
                    target: {
                      name: "personal_info",
                      id: "vision_issues",
                      value : e.target.checked ? "1":"0"
                    }
                  })} />
                </Col>
              </Row>
              {this.saveSection()}

            </form>


          </Collapse>
        </div>
        <div className="student__profile__section pt-0">
          <h2 className="r-heading-4 r-text--blue-3">Contact Info & Identity Proofs </h2>
          <Collapse isOpen={editableSection !== "contact"}>
            <Row>
              <Col xs="auto">
                <p className="fi fi-abs flaticon-mail">{user_info.email || <em>Email</em>}</p>
              </Col>
              <Col xs="auto">
                <p className="fi fi-abs flaticon-phone">{user_info.mobile || <em>Mobile No.</em>}</p>
              </Col>
              <Col xs="auto">
                <p className="fi fi-abs flaticon-document">{user_info.aadhaar_number || <em>Aadhaar Number</em>}</p>
              </Col>
              <Col xs="auto">
                <p className="fi fi-abs flaticon-portfolio">{user_info.pan_number || <em>PAN Number</em>}</p>
              </Col>
              {
                profileLinks.keys.map(key => {
                  let name = CONSTANTS.PROFILE_ICONS[key] || {},
                    value = profileLinks.values[key];
                  return (
                    <Col xs="auto" key={key}>
                      {key === "skype" ? 
                      <p><SkypeLogo style={{height: 15, width: 15}} className="mr-2" />{profileLinks.values["skype"]}</p>
                      :
                      <p className={`fi fi-abs flaticon-${name.icon || "custom"}`}>{(name.domain && value.indexOf(name.domain) > -1) ? value.split(name.domain)[1] : value}</p>
                      }
                    </Col>
                  )
                })
              }
            </Row>
          </Collapse>
          <Collapse isOpen={editableSection === "contact"}>
            <form onSubmit={this.updateContactInfo}>
              <Row form className="mb-2">
                <Col xs={12}>
                  <label className="r-heading-7"><span className="r-text--red-1">*</span>Email</label>
                  <InputField disabled={true} className="w-100" type="text" name="user_info" id="email" value={user_info.email || ""} onChange={this.props.changeProfile} />
                </Col>
              </Row>
              <Row form className="mb-2">
                <Col xs={12}>
                  <label className="r-heading-7"><span className="r-text--red-1">*</span>Telephone</label>
                  <InputField className="w-100" type="text" name="user_info" id="mobile" value={user_info.mobile || ""} onChange={this.props.changeProfile} />
                </Col>
              </Row>
              <Row form className="mb-2">
                <Col xs={12}>
                  <label className="r-heading-7">Aadhar Number</label>
                  <InputField className="w-100" type="number" name="user_info" id="aadhaar_number" value={user_info.aadhaar_number || ""} onChange={this.props.changeProfile} />
                </Col>
              </Row>
              <Row form className="mb-2">
                <Col xs={12}>
                  <label className="r-heading-7">PAN Number</label>
                  <InputField className="w-100" type="text" name="user_info" id="pan_number" value={user_info.pan_number || ""} onChange={this.props.changeProfile} />
                </Col>
              </Row>
              <Row form className="mb-2">
                <Col xs={12}>
                  <label className="r-heading-7">Github</label>
                  <InputField className="w-100" type="text" name="github" value={profileLinks.values["github"] || ""} onChange={this.updateProfileLinks} />
                </Col>
              </Row>
              <Row form className="mb-2">
                <Col xs={12}>
                  <label className="r-heading-7">Linkedin</label>
                  <InputField className="w-100" type="text" name="linkedin" value={profileLinks.values["linkedin"] || ""} onChange={this.updateProfileLinks} />
                </Col>
              </Row>
              <Row form className="mb-2">
                <Col xs={12}>
                  <label className="r-heading-7">Skype Id</label>
                  <InputField className="w-100" type="text" name="skype" value={profileLinks.values["skype"] || ""} onChange={this.updateProfileLinks} />
                </Col>
              </Row>
              {this.saveSection()}
            </form>

          </Collapse>
          {editableSection !== "contact" && <ProfileActions toggleSection={() => toggleSection("contact")} isRequiredValidate={this.props.requiredValidation([user_info.mobile, user_info.email, profileLinks.keys])} />}
        </div>
        <div className="student__profile__section pt-0">
          <h2 className="r-heading-4 r-text--blue-3">Hobbies</h2>
          <Collapse isOpen={editableSection !== "hobbies"}>
            <Row>
              {hobbies.length > 0 ? hobbies.map((hobby, index) => {
                return (
                  <Col xs={4} key={index}>
                    <div className="r-tag">
                      <span dangerouslySetInnerHTML={{ __html: hobby }}></span>
                    </div>
                  </Col>
                )
              }) : <Col><em>Type and press enter to save it</em></Col>}
            </Row>
          </Collapse>
          <Collapse isOpen={editableSection === "hobbies"}>
            <Multifield updateValues={this.updateHobbies} values={hobbies}>
              <div className="d-flex justify-content-end mt-3">
                <Button type="button" onClick={() => this.props.onCancel()} className="r-text--sm">Cancel</Button>
                <Button type="button" onClick={this.submitHobbies} className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
                <input className="d-none" type="submit" />
              </div>
            </Multifield>
          </Collapse>
          {editableSection !== "hobbies" && <ProfileActions toggleSection={() => toggleSection("hobbies")} isRequiredValidate={this.props.requiredValidation([hobbies])} />}
        </div>
        <div className="student__profile__section pt-0">
          <h2 className="r-heading-4 r-text--blue-3">Summary</h2>
          <Collapse isOpen={editableSection !== "summary"}>
            <RichTextViewer>
              {personal_info.personal_summary || "<em>Enter Profile Summary</em>"}
            </RichTextViewer>
          </Collapse>
          {editableSection !== "summary" && <ProfileActions toggleSection={() => toggleSection("summary")} isRequiredValidate={this.props.requiredValidation([personal_info.personal_summary])} />}
          <Collapse isOpen={editableSection === "summary"}>
            <form onSubmit={this.updatePersonalInfo}>
              <Row form className="mb-2">
                <Col xs={12}>
                  <RTE value={personal_info.personal_summary || ""} onChange={(value) => this.props.changeProfile({
                    target: {
                      name: "personal_info",
                      id: "personal_summary",
                      value
                    }
                  })} {...this.RTEProps} />
                  {/* <textarea name="personal_info" onChange={this.props.changeProfile} rows="5" className="w-100" id="personal_summary" value={personal_info.personal_summary || ""} /> */}
                </Col>
              </Row>
              {this.saveSection()}
            </form>


          </Collapse>
        </div>
        {showPreferences && <div className="student__profile__section pt-0">
          <h2 className="r-heading-4 r-text--blue-3">Preferences</h2>
          <Collapse isOpen={editableSection !== "preferences"}>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={12} sm={4}>
                    <h2 className="r-heading-6 r-text--blue-3">Role</h2>
                    <ul className="list-unstyled">
                      {
                        filteredPreferences.roles.length > 0 ? filteredPreferences.roles.map((role, i) => {
                          return (
                            <li key={i}>{role.preferences}</li>
                          )
                        }) : <li><em>No Preferences</em></li>
                      }
                    </ul>
                  </Col>
                  <Col xs={12} sm={4}>
                    <h2 className="r-heading-6 r-text--blue-3">Domain</h2>
                    <ul className="list-unstyled">
                      {
                        filteredPreferences.domains.length > 0 ? filteredPreferences.domains.map((domain, i) => {
                          return (
                            <li key={i}>{domain.preferences}</li>
                          )
                        }) : <li><em>No Preferences</em></li>
                      }
                    </ul>
                  </Col>
                  <Col xs={12} sm={4}>
                    <h2 className="r-heading-6 r-text--blue-3">Location</h2>
                    <ul className="list-unstyled">
                      {
                        filteredPreferences.locations.length > 0 ? filteredPreferences.locations.map((loc, i) => {
                          return (
                            <li key={i}>{loc.preferences}</li>
                          )
                        }) : <li><em>No Preferences</em></li>
                      }
                    </ul>
                  </Col>
                </Row>
              </Col>
              <ProfileActions isRequiredValidate={this.props.requiredValidation([filteredPreferences.roles, filteredPreferences.domains, filteredPreferences.locations])} />
            </Row>
          </Collapse>
          {editableSection !== "preferences" && <ProfileActions toggleSection={() => toggleSection("preferences")} isRequiredValidate={this.props.requiredValidation([filteredPreferences.roles, filteredPreferences.domains, filteredPreferences.locations])} />}
          <Collapse isOpen={editableSection === "preferences"}>
            <form onSubmit={this.handlePrefSubmit}>
              <Row>
                <Col xs={4}>
                  <div className="mb-2">
                    <label className="r-heading-7">Preferred Role 01</label>
                    <InputField className="w-100" name="ROLE" value={filteredPreferences.roles[0] ? filteredPreferences.roles[0].preferences : ""} onChange={(e) => this.handlePrefsChange(e, filteredPreferences.roles[0], 1)} type="text"/>
                  </div>
                  <div className="mb-2">
                    <label className="r-heading-7">Preferred Role 02</label>
                    <InputField className="w-100" name="ROLE" value={filteredPreferences.roles[1] ? filteredPreferences.roles[1].preferences : ""} onChange={(e) => this.handlePrefsChange(e, filteredPreferences.roles[1], 2)} type="text"/>
                  </div>
                  <div className="mb-2">
                    <label className="r-heading-7">Preferred Role 03</label>
                    <InputField className="w-100" name="ROLE" value={filteredPreferences.roles[2] ? filteredPreferences.roles[2].preferences : ""} onChange={(e) => this.handlePrefsChange(e, filteredPreferences.roles[2], 3)} type="text"/>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="mb-2">
                    <label className="r-heading-7">Preferred Domain 01</label>
                    <InputField className="w-100" name="DOMAIN" value={filteredPreferences.domains[0] ? filteredPreferences.domains[0].preferences : ""} onChange={(e) => this.handlePrefsChange(e, filteredPreferences.domains[0], 1)} type="text"/>
                  </div>
                  <div className="mb-2">
                    <label className="r-heading-7">Preferred Domain 02</label>
                    <InputField className="w-100" name="DOMAIN" value={filteredPreferences.domains[1] ? filteredPreferences.domains[1].preferences : ""} onChange={(e) => this.handlePrefsChange(e, filteredPreferences.domains[1], 2)}  type="text"/>
                  </div>
                  <div className="mb-2">
                    <label className="r-heading-7">Preferred Domain 03</label>
                    <InputField className="w-100" name="DOMAIN" value={filteredPreferences.domains[2] ? filteredPreferences.domains[2].preferences : ""} onChange={(e) => this.handlePrefsChange(e, filteredPreferences.domains[2], 3)}  type="text"/>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="mb-2">
                    <label className="r-heading-7">Preferred Location 01</label>
                    <InputField className="w-100" name="LOCATION" value={filteredPreferences.locations[0] ? filteredPreferences.locations[0].preferences : ""} onChange={(e) => this.handlePrefsChange(e, filteredPreferences.locations[0], 1)} type="text" />
                  </div>
                  <div className="mb-2">
                    <label className="r-heading-7">Preferred Location 02</label>
                    <InputField className="w-100" name="LOCATION" value={filteredPreferences.locations[1] ? filteredPreferences.locations[1].preferences : ""} onChange={(e) => this.handlePrefsChange(e, filteredPreferences.locations[1], 2)} type="text" />
                  </div>
                  <div className="mb-2">
                    <label className="r-heading-7">Preferred Location 03</label>
                    <InputField className="w-100" name="LOCATION" value={filteredPreferences.locations[2] ? filteredPreferences.locations[2].preferences : ""} onChange={(e) => this.handlePrefsChange(e, filteredPreferences.locations[2], 3)} type="text" />
                  </div>
                </Col>
              </Row>
              {this.saveSection()}
            </form>

          </Collapse>
        </div>}
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
      </div >
    )
  }
}
