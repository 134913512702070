import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container} from 'reactstrap';
import PendingInvites from './pending_invites';
import Link from 'react-router-dom/Link';
import moment from 'moment';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EventPopover from './event_popover';
import {Errorbar} from '../../global/errorbar';
import { Loader } from '../../global/Loader';
import {CONSTANTS} from '../../../constants';

const localizer = BigCalendar.momentLocalizer(moment)

class Calendar extends Component{
  constructor(props){
    super(props);
    this.state={
      loading : false,
      view: 'month',
      selectedDay : null,
      events : [],
      eventList : [],
      eventObject: [],
      dummyEvents: [],
      applications: [],
      params : {
        attendee_id : this.props.user.id,
        attendee_type : 1,
        secondAttendeeType : 1,
        user_id : this.props.user.id,
        meetingList : [],
        meetingDetails : [],
        meetings : [],
      },
      errorbar: false,
      message:''
    }
  }

  toggleLoading = (loading) =>{
    this.setState({
      loading
    })
  }

  closebar = ()=>{
    this.setState({errorbar : false})
  }

  getEvent = async ()=>{
    this.toggleLoading(true);
    let {params} = this.state;
    // params.attendee_id = this.state.user_id;
    try{
      let events = await Auth.Batch.getEvent(params);
      this.setState({events : events.data[0]})
      await this.filterAttendee();
    }
    catch(e){
      this.setState({errorbar: true, message: e.response && e.response.data ? e.response.data.error.toString() : 'Something went wrong! Please try again'});
    }
    this.toggleLoading(false);
  }
  
  getApplications = async ()=>{
    this.toggleLoading(true);
    if(CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==5){
      try{
        let studentResp = await Auth.Student.getId(this.props.user.id);
        let studentInfo = studentResp.data[studentResp.data.length - 1] || {};
        let resp = await Auth.Student.getAllApplication({student_id: studentInfo.student_id});
        let applications = resp.data.filter(app => app.application_deadline && app.company_details).map(app => {
          var obj = {
            event_id : "app_"+app.application_id,
            title : app.company_details.company_name,
            start : new Date(app.application_deadline),
            end : new Date(app.application_deadline),
            desc : 'Pre-meeting meeting, to prepare for the meeting',
            eventType : "APPLICATION",
            process_id: app.process_id,
            application_id: app.application_id
          }
          return obj;
        })
      
        this.setState({applications})
      }
      catch(e){
        this.setState({errorbar: true, message: e.response && e.response.data ? e.response.data.error.toString() : 'Something went wrong! Please try again'});
      }
    }
    this.toggleLoading(false);
  }

//   eventStyleGetter = (event, start, end, isSelected)=> {
//     if(event)
//     var backgroundColor = '#d8ff0070';
//     var style = {
//         backgroundColor: backgroundColor,
//         borderRadius: '0px',
//         border: '1px solid  #0066cc',
//         color: '#b6cc00f2',
//         display: 'block'
//     };
//     return {
//         style: style
//     };
// }

  filterAttendee = async ()=>{
    let eventList = []
    let{events} =this.state;
    if(events.length!=0){

      if(events.attendee_details.length!=0){
        events.attendee_details.map(singleEvent=>{
          if((this.state.params.attendee_id == singleEvent.attendee_id) && (this.state.params.attendee_type == singleEvent.attendee_type ||  this.state.params.secondAttendeeType == singleEvent.attendee_type))
          {
            eventList.push(singleEvent.event_id);
          }
        })}
        let list = eventList.sort();
        for(let i=0;i<eventList.length-1;i++){
        if(list[i]==list[i+1]){
            list.splice(i,1);
            i=i-1;
          }
        }
        this.setState({
          eventList:list
        })
        await this.filterEvents();
      }
    }

    filterEvents = ()=>{
      let {events} = this.state;
      let {eventList} = this.state;
      let eventObject = [];
      for(let i =0; i<events.event_details.length; i++){
        eventList.map(details=>{
          if(details == events.event_details[i].event_id){
            eventObject.push({event_id: events.event_details[i].event_id,"title":events.event_details[i].title, desc:events.event_details[i].description,"start": new Date(events.event_details[i].start_date), "end": new Date(events.event_details[i].end_date),location: events.event_details[i].location, eventType: events.event_details[i].event_type,created_by: events.event_details[i].created_by} )
          }
        })
      }
      this.setState({
        eventObject
      })

      let {dummyEvents} = this.state;
      if(this.state.eventObject.length!=0){
        this.state.eventObject.map(object=>{
          dummyEvents.push(object)
        })
      }
      this.setState({
        dummyEvents
      })


    }
    getMeetingAttendees = async ()=>{
        if(CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==3){
      this.toggleLoading(true);
      let params = {
        user_id : this.props.user.id,
        meeting_category : "Meeting"

      }

      let meetingList = []
      try{
        params.attendee_type =  ["primary"];
        let meetingAttendees =await  Auth.Batch.getAttendeesList(params);
        if(meetingAttendees.data[0]!=undefined && meetingAttendees.data[0].length!=0){
          meetingAttendees.data.map(meeting=>{
            meetingList.push(meeting.meeting_id);
          })
        }
        await this.setState({
          meetingList
        })
      }
      catch(e){
        this.setState({errorbar: true});
        if(e.response && e.response.data){
          this.setState({message: e.response.data.error.toString()});
        }
        else{
          this.setState({message: 'Something went wrong! Please try again'});
        }
      }
      try{
        params.attendee_type =  ["secondary"];
        let meetingAttendees =await  Auth.Batch.getAttendeesList(params);
        if(meetingAttendees.data[0]!=undefined && meetingAttendees.data[0].length!=0){
          meetingAttendees.data.map(meeting=>{
            meetingList.push(meeting.meeting_id);
          })
        }
        await this.setState({
          meetingList
        })
      }
      catch(e){
        this.setState({errorbar: true});
        if(e.response && e.response.data){
          this.setState({message: e.response.data.error.toString()});
        }
        else{
          this.setState({message: 'Something went wrong! Please try again'});
        }
      }

      await this.getMeetings();
      this.toggleLoading(false);


    }
  }
    getMeetings = async ()=>{
      let {meetingList} = this.state;
      // console.log(meetingList);
      if(meetingList && meetingList.length!=null){
        // var params = new URLSearchParams();
        // params.append("access_key",this.props.role.access_key);
        // if(meetingList.length>0){
        //   for(let i=0;i<meetingList.length;i++){
        //     // console.log(meetingList[i]);
        //     params.append("meeting_id",meetingList[i]);
        //     // console.log("params",params);
        //   }
        // }
        // console.log(params);
        // console.log("meetingList",meetingList);
        // console.log("params:",params);
        // params.append("meeting_id",meetingList);

        // params.append("access_key",this.props.user.current_role_info.access_key)
        var body = {
          access_key : this.props.role.access_key
        }
        if(meetingList.length) body.meeting_id = meetingList;
        try{
          let meetings = await Auth.Batch.getMeetings(body);
          await this.setState({
            meetings : meetings.data
          })
        }
        catch(e){
          this.setState({errorbar: true});
          if(e.response && e.response.data){
            this.setState({message: e.response.data.error.toString()});
          }
          else{
            this.setState({message: 'Something went wrong! Please try again'});
          }
        }


        let meetingDetails = [];
        let {meetings} = this.state
        // meetingDetails.push(meetings);
        if(meetings && meetings.length!=0){
          meetings.map(meetingObject=>{
            meetingDetails.push({meeting_id : meetingObject.meeting_id, title:meetingObject.meeting_title, desc:meetingObject.meeting_description, "start":new Date(meetingObject.start_date), "end":new Date(meetingObject.end_date) , eventType: "Meetings", created_by:meetingObject.created_by})
          })
        }
        // await this.setState({
        //   meetingDetails
        // })
        let {dummyEvents} = this.state;
        if(meetingDetails.length!=0){
          meetingDetails.map(meetings=>{
            dummyEvents.push(meetings);
          })
          this.setState({
            dummyEvents
          })
        }
      }


    }

  async componentDidMount(){
    // await this.getUserData();
      await this.getMeetingAttendees();
    await  this.getEvent();
    await  this.getApplications();
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    // console.log(event);
    var backgroundColor =  '#3174ad';
    if(event.eventType === "APPLICATION") backgroundColor = '#ef0065';
    if(event.eventType === "Meetings") backgroundColor = '#35a2a2';
    var style = {
        backgroundColor: backgroundColor,
        borderRadius: '5px',
        opacity: 0.8,
        color: 'white',
        // border: 'px',
        display: 'block'
    };
    return {
        style: style
    };
  }
  render(){
    return(
      <Container>
      <Loader show={this.state.loading} />
      <Row>
      <Col xs="3">
      <div>
      <Row>
      <Col>
      <div className="mt-5">
      <span className="r-subheading-1">
      Calendar</span>
      </div>
      </Col>
      </Row>
      {/* <Row>
      <Col className="mt-5">
      <span className="r-subheading-3  r-text--blue-3 ">Add New</span>
      </Col>
      </Row> */}

      <div className="r-bg--blue-3  mt-3">
      {CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==3?
        <ul className="nav calendar__event-topic">

        <li className="nav-item  border-right calendar__event-topic-li  r-text-light">
        <Link to={"/calendar/events"} className="calendar__event-link r-text--light">
        <span className="nav-link r-subheading-4  mr-4 ml-2 r-text--light">Add New Event</span>
        </Link>
        </li>
        {/* <li className="nav-item  r-text-light calendar__event-topic-li">
        <Link to={"/calendar/meetings"} className="calendar__event-link  r-text--light">
        <span className="nav-link r-subheading-4 mr-4 ml-2 r-text--light">Meeting</span>
        </Link>
        </li> */}
        </ul>:  <ul className="nav ">
          <li className="   r-text-light w-100">
          <Link to={"/calendar/events"} className="r-text--light">
          <span className="nav-link r-subheading-4  mr-4 ml-2 r-text--light">Add New Event</span>
          </Link>
          </li></ul>}

      {/*<Link to={"calendar/reminders"} className="calendar__event-link">
      <li className="nav-item m-1 mr-0 ml-2 text-light">
      <span className="nav-link r-subheading-4 mr-4  ml-2">Reminder</span>
      </li>
      </Link>*/}
      </div>

      {/*      <Row>
      <Col className="mt-5">
      <span className="r-subheading-3  r-text--blue-3 ">Categories</span>
      </Col>
      </Row>
      <Row>
    {/*<Categories/>
      </Row>
      */}
      {/* <Row>
      <Col className="mt-5">
      <span className="r-subheading-3  r-text--blue-3 ">Pending Invites</span>
      </Col>
      </Row>
      <Row className="calendar__pending-invites">
      <Col xs="12">
      <PendingInvites recipient_id={this.props.user.id}  role_type={this.props.role.role_type} university_id={this.props.role.instituteDetail.university_id} college_id={this.props.role.instituteDetail.college_id} campus_id={this.props.role.instituteDetail.campus_id}  />
      </Col>
      </Row> */}
      </div>
      </Col>
      <Col xs="9">
        <div className="text-right position-absolute" style={{right: 0, top: 5}}>
          {CONSTANTS.ROLE_TYPES_ID[this.props.role.role_type]==5 ?
          <>
            <span style={{height: 10, width: 10, background: "#ef0065", display: "inline-block"}}></span>{' '}<span>Applications</span>{' '}
            <span style={{height: 10, width: 10, background: "#3174ad", display: "inline-block"}}></span>{' '}<span>Events</span>
          </>
          :
          <>
            <span style={{height: 10, width: 10, background: "#3174ad", display: "inline-block"}}></span>{' '}<span>Events</span>{' '}
            <span style={{height: 10, width: 10, background: "#35a2a2", display: "inline-block"}}></span>{' '}<span>Meetings</span>
          </>
          }
          
        </div>
        <div className="calendar__bc-calendar">
          <BigCalendar
          localizer={localizer}
          // events={this.state.applications}
          events={[...this.state.dummyEvents, ...this.state.applications]}
          startAccessor="start"
          endAccessor="end"
          defaultView = 'month'
          view = {this.state.view}
          onView = {(view)=>this.setState({
            view
          })}
          date={this.state.selectedDay}
          onNavigate = {(date)=>this.setState({
            selectedDay:date
          })}
          popup={true}
          views={['month','week', 'day']}
          onSelectEvent =  {(event)=>{
            if(event.eventType==="APPLICATION"){
              this.props.history.push(`/student/recruitments/apply?process_id=${event.process_id}&application_id=${event.application_id}`);
            }
            else if(event.eventType==="Meetings"){
              this.props.history.push({pathname:"/calendar/meetings/"+event.meeting_id+"/edit",state:event})
            }
            else{
                this.props.history.push({pathname:"/calendar/events/"+event.event_id+"/edit",state:event})
            }
          }}
          eventPropGetter={this.eventStyleGetter}
          />
       </div>
       </Col>
       </Row>
       <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
       </Container>
     )
   }
 }

 const mapStateToProps = state => ({
   ...state
 })
export default connect(mapStateToProps, null)(Calendar);
