import React, { Component } from 'react';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import { TEMPLATE_SETTINGS, TEMPLATE_CONFIGPATHS } from '../../../constants/template-1';
import Aux from '../../global/AuxComp';
import Collapse from 'reactstrap/lib/Collapse';
import { getSelectionContainerElement, resolveValuesFromObject } from '../../../actions';
import { Select } from '../../global/Forms/Select';
import { InputField } from '../../global/Forms';
import {  ChromePicker } from 'react-color';
import { ReactComponent as ColorFillerLogo } from '../../../assets/icons/color-filler.svg';
import { ReactComponent as TextColorLogo } from '../../../assets/icons/color-text.svg';

export class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  handleColorButtonClick = (e) => {
    var setColor = e.currentTarget.dataset.color;
    if(setColor){
      this.setState({ colorPickerColor: setColor })
    }
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
    this.setState({ whichColorPicker: e.currentTarget.attributes['id'].value })
  };

  handleColorClose = () => {
    this.setState({ displayColorPicker: false })
  };
  handleColorChange = (color) => {
    document.querySelector('#'+this.state.whichColorPicker).dataset.color = color.hex;
    this.setState({ colorPickerColor: color.rgb })
  };

  getInitialState = () => {
    return {
      displayColorPicker : false,
      colorPickerColor : null,
      whichColorPicker : null,
      sections: this.props.sections,
      selectedElementConfig: {},
      options: {
        layout: true,
        typeface: true,
        other: true
      }
    }
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.onTextSelected)
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onTextSelected)
  }

  onTextSelected = () => {
    let el = getSelectionContainerElement(),
      styles = {},
      selectedEl = document.querySelector(".highlight")
    if (selectedEl) {
      let classList = selectedEl.className.split(" ")
      classList.splice(classList.indexOf("highlight"), 1)
      selectedEl.className = classList.join(" ")
    }
    if (el) {
      let highlight = el;
      if (!el.dataset.configpath) {
        highlight = el.closest(".configurable")
      }
      if (highlight && highlight.dataset.configpath) {
        highlight.className += " highlight"
        styles = resolveValuesFromObject(highlight.dataset.configpath, JSON.parse(JSON.stringify(this.props.config.section_config))) || {}
        this.setState({
          selectedElementConfig: styles
        })
      } else {
        this.setState({
          selectedElementConfig: {}
        })
      }
    } else {
      this.setState({
        selectedElementConfig: {}
      })
    }
  }

  changeStyles = (propname, op, value, path) => {
    op = op ? parseInt(op) : null;
    this.props.updateStyling(propname, op, value, path);
    this.onTextSelected()
  }

  toggleStyles = (propname, value, toggleValue) => {
    let newVal = value;
    if (this.state.selectedElementConfig[propname] === value) {
      newVal = toggleValue
    }
    this.props.updateStyling(propname, null, newVal);
    this.onTextSelected();
  }

  toggleAccordian = opt => {
    let { options } = this.state;
    options[opt] = !options[opt]
    this.setState({
      options
    })
  }

  handleSettings = (path, value) => {
    if (isNaN(value) || value >= 0)
      this.props.updateSettings(path, value)
  }

  isLogoVisible = () => {
    let logoStyle = resolveValuesFromObject(TEMPLATE_CONFIGPATHS["collegeLogo"], this.props.config.section_config)
    if (logoStyle["display"] && logoStyle["display"] === "none") {
      return false
    }
    return true
  }

  render = () => {
    const popover = {
      position: 'absolute',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    let { config } = this.props,
      { options, selectedElementConfig } = this.state;
    if (!config) {
      return <div></div>
    }
    return (
      <Aux>

          <Row className="mt-3">
            <Col xs={12}>
              <h3 className={"cv-builder__toolbar__accordian" + (!options.layout ? " cv-builder__toolbar__accordian--collapsed" : "")} onClick={() => this.toggleAccordian('layout')}>
                Layout<i className="fi flaticon-arrow"></i>
              </h3>
              <Collapse isOpen={options.layout}>
                <Row className="text-center">
                  <Col xs={6}>
                    <p id="h_margin"><em>Horizontal</em>&nbsp;<span className="fi flaticon-width-1"></span></p>
                    <UncontrolledTooltip placement="bottom" target="h_margin">
                     Horizontal Margin
                    </UncontrolledTooltip>
                    <Button color="primary" id="h_increase_margin" onClick={() => this.handleSettings("horizontal", config.settings.horizontal + 1)}>+</Button>

                    <span>&nbsp;&nbsp;{config.settings.horizontal}&nbsp;&nbsp;</span>
                    <Button color="primary" id="h_decrease_margin" onClick={() => this.handleSettings("horizontal", config.settings.horizontal - 1)}>-</Button>

                  </Col>
                  <Col xs={6}>
                    <p id="v_margin"><em>Vertical</em>&nbsp;<span className="fi flaticon-height"></span></p>
                    <Button color="primary" id="v_increase_margin" onClick={() => this.handleSettings("vertical", config.settings.vertical + 1)}>+</Button>
                    <UncontrolledTooltip placement="bottom" target="v_margin">
                      Vertical Margin
                    </UncontrolledTooltip>
                    <span>&nbsp;&nbsp;{config.settings.vertical}&nbsp;&nbsp;</span>
                    <Button color="primary" id="v_decrease_margin" onClick={() => this.handleSettings("vertical", config.settings.vertical - 1)}>-</Button>

                  </Col>
                </Row>
              </Collapse>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              <h3 className={"cv-builder__toolbar__accordian" + (!options.typeface ? " cv-builder__toolbar__accordian--collapsed" : "")} onClick={() => this.toggleAccordian('typeface')}>Typeface<i className="fi flaticon-arrow"></i></h3>
              <Collapse isOpen={options.typeface}>
                <Row className="r-text--sm">
                  <Col xs={7}>
                    <Select value={config.settings.typeface} type="select" onChange={(e) => this.handleSettings("typeface", e.target.value)}>
                      {
                        TEMPLATE_SETTINGS["typeface"].options.map((opt, i) => {
                          return (
                            <option key={i} value={opt}>{opt}</option>
                          )
                        })
                      }
                    </Select>
                  </Col>
                  <Col  col={5} className="d-flex px-0">
                    <div className="fi flaticon-typography py-2 mt-1  px-3"></div>
                    <Select id="line_height" value={config.settings.lineHeight || 1.5} type="select" onChange={(e) => this.handleSettings("lineHeight", e.target.value)}>
                      {
                        TEMPLATE_SETTINGS["lineHeight"].options.map((opt, i) => {
                          return (
                            <option key={i} value={opt}>{opt}</option>
                          )
                        })
                      }
                    </Select>
                    <UncontrolledTooltip placement="top" target="line_height">
                      Line Spacing
                    </UncontrolledTooltip>
                  </Col>
                </Row>
                <Row className="pt-3 justify-content-center align-self-center">
                  <Col xs={12}>
                    <Row className="mx-0">
                      <Col className="px-0" xs="auto">
                        <Button id="text_align_left" active={selectedElementConfig["text-align"] === "left"} color="link px-2" onClick={(e) => this.changeStyles("text-align", null, "left")}>
                          <i className="fi flaticon-text"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="text_align_left">
                          Align Left
                        </UncontrolledTooltip>
                        <Button id="text_align_center" active={selectedElementConfig["text-align"] === "center"} color="link px-2" onClick={(e) => this.changeStyles("text-align", null, "center")}>
                          <i className="fi flaticon-text-1"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="text_align_center">
                          Center Text
                        </UncontrolledTooltip>
                        <Button id="text_align_right" active={selectedElementConfig["text-align"] === "right"} color="link px-2" onClick={(e) => this.changeStyles("text-align", null, "right")}>
                          <i className="fi flaticon-right-align"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="text_align_right">
                          Align Right
                        </UncontrolledTooltip>
                        <Button id="text_align_justify" active={selectedElementConfig["text-align"] === "justify"} color="link px-2" onClick={(e) => this.changeStyles("text-align", null, "justify")}>
                          <i className="fi flaticon-ui"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="text_align_justify">
                          Justify
                        </UncontrolledTooltip>
                      </Col>
                        <span className="r-text--grey-3" style={{lineHeight: '24pt', fontSize: 18}}>|</span>
                      <Col className="px-0" xs="auto">
                        <Button id="vertical_align"  color="link" onClick={(e) => this.changeStyles("align-self", null, (selectedElementConfig["align-self"] === 'flex-start' ? "center":(selectedElementConfig["align-self"] === 'center' ? "flex-end":"flex-start"))) }>
                          <i className={"fi "+(selectedElementConfig["align-self"] === 'flex-end' ? "flaticon-align":(selectedElementConfig["align-self"] === 'flex-start' ? "flaticon-align-1":"flaticon-vertical-align")) }></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="vertical_align">
                          Vertical Alignment
                        </UncontrolledTooltip>

                      </Col>
                        <span className="r-text--grey-3" style={{lineHeight: '24pt', fontSize: 18}}>|</span>
                      <Col className="px-0" xs="auto">
                        <Button id="text_bold" active={selectedElementConfig["font-weight"] === "bold"} color="link" onClick={(e) => this.toggleStyles("font-weight", "bold", "normal")}>
                          <i className="fi flaticon-bold"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="text_bold">
                          Bold
                        </UncontrolledTooltip>
                        <Button id="text_italic" active={selectedElementConfig["font-style"] === "italic"} color="link" onClick={(e) => this.toggleStyles("font-style", "italic", "normal")}>
                          <i className="fi flaticon-italic"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="text_italic">
                          Italic
                        </UncontrolledTooltip>
                        <Button id="text_underline" active={selectedElementConfig["text-decoration"] === "underline"} color="link" onClick={(e) => this.toggleStyles("text-decoration", "underline", "none")}>
                          <i className="fi flaticon-letter-u"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="text_underline">
                          Underline
                        </UncontrolledTooltip>
                      </Col>

                    </Row>
                    <Row className="justify-content-center align-self-center">
                      <Col className="pr-0">
                        <Button id="text_transform_uppercase" active={selectedElementConfig["text-transform"] === "uppercase"} color="link" onClick={(e) => this.changeStyles("text-transform", null, "uppercase")}>
                          <i className="fi flaticon-icons8-uppercase-filled-50-1"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="text_transform_uppercase">
                          UPPERCASE
                        </UncontrolledTooltip>
                        <Button id="text_transform_lowercase" active={selectedElementConfig["text-transform"] === "lowercase"} color="link" onClick={(e) => this.changeStyles("text-transform", null, "lowercase")}>
                          <i className="fi flaticon-icons8-lowercase-2-filled-50"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="text_transform_lowercase">
                          lowercase
                        </UncontrolledTooltip>
                        <Button id="text_transform_sencase" active={selectedElementConfig["text-transform"] === "capitalize"} color="link" onClick={(e) => this.changeStyles("text-transform", null, "capitalize")}>
                          <i className="fi flaticon-sencase"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="text_transform_sencase">
                          Sentence case.
                        </UncontrolledTooltip>
                          <span className="r-text--grey-3" style={{lineHeight: '24pt', fontSize: 18}}>|</span>
                      <Button id="bg-color" active={selectedElementConfig["font-weight"] === "bold"} data-color={"green"} color="link pt-0 pb-2"  onClick={ this.handleColorButtonClick }>
                        <ColorFillerLogo style={{width:18, height:18}} />
                      </Button>

                        { this.state.displayColorPicker ? <div style={ popover }>
                          <div style={ cover } onClick={ this.handleColorClose }/>
                          <ChromePicker  color={ this.state.colorPickerColor }  onChange={this.handleColorChange } data-name={this.state.whichColorPicker}  />
                        </div> : null }

                      <UncontrolledTooltip placement="bottom" target="bg-color">
                        Background Color
                      </UncontrolledTooltip>
                      <Button id="text-color" active={selectedElementConfig["font-weight"] === "bold"} data-color={"red"}  color="link  pt-0 pb-2"  onClick={ this.handleColorButtonClick }>
                        <TextColorLogo style={{width:18, height:18}} />
                      </Button>
                      <UncontrolledTooltip placement="bottom" target="text-color">
                        Text Color
                      </UncontrolledTooltip>
                      </Col>
                        <span className="r-text--grey-3" style={{lineHeight: '24pt', fontSize: 18}}>|</span>
                      <Col xs={4} className="px-1">
                      <Button id="olist"  color="link" onClick={(e) => this.handleSettings("listStyle", "decimal")}>
                        <i className="fi flaticon-order"></i>
                      </Button>
                      <UncontrolledTooltip placement="bottom" target="olist">
                        Numbering
                      </UncontrolledTooltip>
                      <Button id="ulist" color="link" onClick={(e) => this.handleSettings("listStyle", "disc")}>
                        <i className="fi flaticon-menu"></i>
                      </Button>
                      <UncontrolledTooltip placement="bottom" target="ulist">
                        Bullets
                      </UncontrolledTooltip>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Collapse>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              <h3 className={"cv-builder__toolbar__accordian" + (!options.other ? " cv-builder__toolbar__accordian--collapsed" : "")} onClick={() => this.toggleAccordian('other')}>Other<i className="fi flaticon-arrow"></i></h3>
              <Collapse isOpen={options.other}>
                <Row>
                  <Col>
                    <InputField checked={this.isLogoVisible()} type="checkbox" value="College Logo" onChange={({ target }) => this.changeStyles("display", null, target.checked ? "flex" : "none", "collegeLogo")} />
                  </Col>
                </Row>
                <Row>
                  {/* <Col>
                    <InputField type="checkbox" value="Profile Picture" />
                  </Col> */}
                </Row>
              </Collapse>
            </Col>
          </Row>

      </Aux>
    )
  }
}
