import React, { Component } from 'react';
import { Select } from '../../global/Forms/Select'
import { Row, Col, Container, Table , Spinner, Collapse} from 'reactstrap';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import SpocNav from './SpocNav';
import store from '../../../store';
import { Auth } from '../../../actions/Auth';
import { Loader } from '../../global/Loader';
import { getFullName } from '../../../actions';
import { InputField } from '../../global/Forms';
import { ENDPOINTS } from '../../../constants/endpoints';
import PaginationComponent from '../../global/PaginationComponent.js';
import Axios from 'axios';
import Button from '../../global/Button';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import moment from 'moment';
import { Switch } from '../../global/Forms/ToggleSwitch';
import { PermissionDenied } from '../../global/PermissionDenied';

class StudentReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [{ id: "sno", title: "#" }, { id: "name", title: "Name" }, { id: "eligible", title: "Eligible" }, { id: "applied", title: "Applied" }, { id: "shortlisted1", title: "Round 1" }, { id: "shortlisted2", title: "Round 2" }, { id: "shortlisted3", title: "Round 3" }, { id: "shortlisted4", title: "Round 4" }, { id: "offer", title: "Offer" }],
      nav: { process_name: "Student Report", process_type: "student", process_id: 2 },
      processList: [],
      currentPage: 0,
      rowsPerPage: 10,
      studentList: [],
      isLoading: false,
      query: "",
      totalEligible:0,
      showMessage:'Please Select a campus',
      totalApplied:0,
      totalShortlisted:0,
      totalSelected:0,
      collegeList:[],
      campusList:[],
      selectedCollege:'',
      selectedCampus:'',
      selectedProcess:'',
      companyModal: [],
      studentIdArray : [],
      studentFormatedData : {"ELIGIBLE" : {}, "APPLIED" : {} , "SHORTLISTED" : {} , "OFFERED" : {}},
      selectedStudent  : null,
      companyObject : {},
      formatDataType : '',
      nameStyle:(store.getState().role.role_type === 'PT')?'tablestudent__studentstatus':'',
      modalLoading : false,
      isOpenDateFilter : {},
      isOn: false,
      companyDataView : [],
      isFilterRecruitmentClosed: ""

    };
  }

  async componentWillMount() {
    let permissions = store.getState().role.hasPermission;
    if (store.getState().role.role_type === "PT" && !(permissions.reportStudent && permissions.reportStudent.permission_access && permissions.reportStudent.permission_access.read)) {
      this.props.history.push("/pt/performance/meeting-report")
        return
    }
    this.init();
  }

  getstReport = async (process_id, filter={}) =>{
    let {totalEligible, totalApplied, totalShortlisted,studentList, totalSelected} =  this.state;
      totalEligible = 0;
      totalApplied = 0;
      totalShortlisted = 0;
      totalSelected = 0;
      this.setState({ isLoading: true })
      var stReportBody = {
        process_id, ...filter
      }
      if(this.state.isOn) stReportBody.group_by = "company";
      if(this.state.isFilterRecruitmentClosed !== "") stReportBody.is_recruitment_closed = this.state.isFilterRecruitmentClosed;
        let stReportResp = await Auth.Reports.studentreportDownload(stReportBody);
         totalEligible =  0;
          totalApplied =  0;
          totalShortlisted =  0;
          totalSelected =  0;
        this.setState({isLoading: false, studentList:stReportResp.data,selectedProcess:process_id,totalEligible, totalApplied, totalShortlisted,totalSelected });
        // let processResp = await Auth.Reports.studentReport(body);
        // let studentResp = await Auth.Processes.getProcessStudents(process_id)
        // if (studentResp.data.length<1) {
        //   this.setState({isLoading: false,studentList:[],showMessage:'No students in this process'});
        //   return;
        // }
        // let resp = await processResp.data.aggregations.student_id_aggregation.buckets.map(this.formatData);
        // resp.map(value => {
        //       totalEligible += value.eligible || 0;
        //       totalApplied += value.applied || 0;
        //       totalShortlisted += value.shortlisted || 0;
        //       totalSelected += value.offered || 0;
        // })
        // studentList = studentResp.data.map(x => Object.assign(x, resp.find(y => y.student_id == x.student_id)));
        // let studentIdArray = studentList.map(student=>{return student.student_id});
        // this.setState({isLoading: false, selectedProcess:process_id,totalEligible, totalApplied, totalShortlisted,studentList, totalSelected , studentIdArray})
  }


  formatData = (bucket) => {
    let temp = {}
    temp.student_id = bucket.key;
    bucket.entity_type_aggregation.buckets.map(data =>{
      temp[data.key] = data.doc_count;
    })
    return temp
  }

  init = async () => {
    this.setState({ isLoading: true })
    let redux = store.getState(),
      { instituteDetail } = redux.role;
    let params = {}
    if (instituteDetail.campus_id) {
      try {
        let processResp = await Auth.Processes.getProcesses(instituteDetail.campus_id)
        this.setState({
            selectedCampus:instituteDetail.campus_id,
          processList: processResp.data
        })
        if (processResp.data.length > 0)
          params.process_id = processResp.data[0].process_id
        let data = await  this.getstReport(params.process_id);
        await this.getstReport(params.process_id || 0)
      } catch (e) { console.log(e) }
    } else {
        this.instiDetail();
    }
    this.setState({ isLoading: false })
  }


  instiDetail = async () =>{
      let campusResTemp, degreeList;
      await Axios.all([Auth.Spoc.getInstitutes()])
      .then(Axios.spread((res1, res2) => {
        campusResTemp = res1;
      }));
      let detailsParams = new URLSearchParams();
      campusResTemp.data.forEach((item, i)=>{
        detailsParams.append("college_id", item.college_id);
        detailsParams.append("campus_id", item.campus_id);
      })
      let details = await Auth.Spoc.getInstituteDetails(detailsParams);
      this.setState({
        campusList:details.data.campusDetails,
        collegeList:details.data.collegeDetails,
        selectedCollege:details.data.campusDetails[0].college_id,
      })
  }


  onSearch = (target) => {
    this.setState({ query: target.value });
    if (target.value !== "") {
      this.setState({ currentPage: 0 });
    }
  }


  handleSelected = (selectedPage) => {
    this.setState({ currentPage: selectedPage - 1 });
  }

  changeCollege = (target) => {
    this.setState({selectedCollege: target.value, selectedCampus:"", selectedProcess:'',studentList: [],processList: [],showMessage:'Please Select a campus'})
  }


  stProfile = (row, type) =>{
    if(store.getState().role.role_type === 'PT'){
      let url = `/pt/students/recruitments/${row.student_id}/apply`
        this.props.history.push(url)
    }
  }

  changeCampus = async (target) =>{
    this.setState({selectedCampus: target.value, selectedProcess:""});
    if(target.value === ""){
      this.setState({studentList: [],processList: [],showMessage:'Please Select a campus'})
      return }
    this.setState({isLoading: true});
      try {
        let processResp = await Auth.Processes.getProcesses(target.value);
        if (processResp.data.length > 0) {
          await this.getstReport(processResp.data[0].process_id)
        }
        else{
            this.setState({
                studentList: [],
                totalEligible:0,
                selectedProcess:'',
                totalApplied:0,
                totalShortlisted:0,
                totalSelected:0,});
        }
        this.setState({
          processList: processResp.data
        })
      } catch (e) { console.log(e) }
    this.setState({isLoading: false});
  }

  processChangeHandler = async (process_id , student_data , formatDataType )=>{
    this.setState({companyDataView : []});
    try{
      var body = {
        "student_id" : student_data.student_id ,
        "process_id" : process_id,
        "filter" : formatDataType,
        type: "student_report"
      }
      let applicationResp = await Auth.Reports.studentReportView(body);
      var data = {};
      applicationResp.data.map(obj => {
        data[obj.company_name] = data[obj.company_name] ? data[obj.company_name] +1 : 1;
      });
      data =Object.keys(data).map(name => {
        return {
          company_name: name,
          counts: data[name]
        }
      })
      this.setState({companyDataView : data});

    }
    catch(e){
      console.log(e);
    }
    this.setState({modalLoading : false});
  }

  getOfferDetails = async (student_id , process_id)=>{
    this.setState({isLoading : true});
    let {studentFormatedData, formatDataType} = this.state;

    let offerParams = {student_id : student_id , process_id : process_id};
    try{
      let offerResp = await Auth.Offer.getOffer(offerParams);
      let companyArray = offerResp.data.map(offer=>{
        return offer.campus_company_id;
      })
      let {companyObject} = this.state;
      studentFormatedData[formatDataType][student_id] = [];
      if(companyArray.length>0){
        let companyResp = await Auth.PtCompanies.getCompanies(companyArray);
        if(companyResp.data.length>0){
          studentFormatedData[formatDataType][student_id] = companyResp.data;
          await this.countCompany(companyArray , companyResp.data);
        }
        this.setState({studentFormatedData});
      }
    }
    catch(e){
      console.log(e);
    }
    this.setState({isLoading : false});
  }

  countCompany = (companyArray , companyData)=>{
    let companyObject = {};
    let companies = companyArray.sort();
    for(let i =0; i<companies.length; i++){
      let count = 0;
      for(let j=0 ; j<companies.length;  j++){
        if(companies[i]==companies[j]){
          count = count+1;
        }
      }
      if(count>0){
        companyObject[companies[i]] = count;
      }
    }
    let finalCompanyObject = {};
    companyData.forEach(company=>{
      if(companyObject[company.campus_company_id]){
        finalCompanyObject[company.campus_company_id] = companyObject[company.campus_company_id];
      }
    })
    this.setState({companyObject});
  }

  handleOfferButton = async (data , selectedStudent)=>{
    let {selectedProcess} = this.state;
    await this.setState({ companyModal: data , formatDataType : 'OFFERED' , selectedStudent , companyObject : {}});
    this.getOfferDetails(selectedStudent.student_id , selectedProcess);

  }


  downloadFile = async() => {
    this.setState({ isLoading: true })
        let stReportBody = new URLSearchParams();
        stReportBody.append('download',true);
        stReportBody.append('process_id', this.state.selectedProcess);
        if(this.state.isOn) stReportBody.append('group_by', "company");
        if(this.state.isFilterRecruitmentClosed !== "") stReportBody.append('is_recruitment_closed',  this.state.isFilterRecruitmentClosed);
      try {
        let res = await Auth.Reports.studentreportDownload(stReportBody);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = 'Student Report'+'.csv';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({ isLoading: false })
      } catch (e) {
        this.setState({ isLoading: false })
        console.log(e)
      }
  }
  filterByDate = () => {
    let { selectedProcess, isOpenDateFilter } = this.state;
    isOpenDateFilter.is_applied = true;
    isOpenDateFilter.is_open = false;
    this.setState({isOpenDateFilter});
    this.getstReport(selectedProcess, {start_date : moment(isOpenDateFilter.start_date).toDate(), end_date : moment(isOpenDateFilter.end_date).toDate()});
  }
  clearDateFilter = () => {
    let { selectedProcess } = this.state;
    this.setState({isOpenDateFilter : {}});
    this.getstReport(selectedProcess);
  }

  handleToggle = async (e) => {
    await this.setState({isOn: !this.state.isOn});
    this.getstReport(this.state.selectedProcess);
    }

  render() {
    
    let { studentList, nav,tabs,isFilterRecruitmentClosed, isLoading,collegeList, campusList, selectedCampus,selectedCollege,selectedProcess,currentPage,query, rowsPerPage, processList, columns, companyModal, totalEligible, totalApplied, totalShortlisted, totalSelected , formatDataType , studentFormatedData , selectedStudent , companyObject , modalLoading, isOpenDateFilter,companyDataView} = this.state;
    let campusDataList = campusList.filter(campus =>{
      return campus.college_id == selectedCollege;
    });
    let companyMapping = {}
    if(Array.isArray(companyModal)) {
      companyMapping = companyModal.reduce((prev, current) => {
        if (!prev[current.campus_company_id]) prev[current.campus_company_id] = []
        prev[current.campus_company_id].push(current.company)
        return prev
      }, {});
    }
    let data = studentList.filter(row => {
      let name = row.first_name || "";
      return name.toLowerCase().includes(query.toLowerCase())
    })
    return (
      <Container className="apply">
        <Loader show={isLoading} />
        <SpocNav process={nav}  downloadReport={this.downloadFile} type="StudentReport"/>
          {/*<Row className="my-4">
            <Col xs={2} className="mx-4">
              <div className="card bg-info text-white p-4">
                <h1>{studentList.length}</h1>
              </div>
              <p>{studentList.length > 1 ? "Total Students" : "Total student"}</p>
            </Col>
            <Col xs={2} className="mx-4">
              <div className="card text-white  bg-success p-4">
                <h1>{totalEligible}</h1>
              </div>
              <p> Total Eligible</p>
            </Col>
            <Col xs={2} className="mx-4">
              <div className="card bg-success text-white p-4">
                <h1>{totalApplied}</h1>
              </div>
              <p>Total Applied  </p>
            </Col>
            <Col xs={2} className="mx-4">
              <div className="card bg-secondary text-white p-4">
                <h1>{totalShortlisted}</h1>
              </div>
              <p>Total Shortlisted</p>
            </Col>
            <Col xs={2} className="mx-4">
              <div className="card bg-secondary text-white p-4">
                <h1>{totalSelected}</h1>
              </div>
              <p>Total Offers</p>
            </Col>
          </Row>*/}
        <Row className="mx-0 my-3 justify-content-between">
        {collegeList.length > 0 &&
            <Col xs="3">
              <Select onChange={({ target }) => this.changeCollege(target)}>
                {collegeList.map((college, i) =>
                  <option key={i} value={college.college_id}>{college.name}</option>
                )}
              </Select>
            </Col>
        }
        {campusDataList.length > 0 &&
            <Col xs="3">
              <Select value={selectedCampus} onChange={({ target }) => this.changeCampus(target)}>
                  <option value="">Select Campus</option>
                  {campusDataList.map((campus, i) =>
                    <option key={i} value={campus.campus_id}>{campus.name}</option>
                  )}
              </Select>
            </Col>
        }
        {processList.length > 0 &&
            <Col xs="3">
              <Select value={selectedProcess} onChange={({ target }) => this.getstReport(target.value)}>
                {processList.map((process, i) =>
                  <option key={i} value={process.process_id}>{process.process_name}</option>
                )}
              </Select>
            </Col>
        }
      
          <Col xs={3} className="pl-2">
            <div>
              <InputField onChange={(e) => {this.onSearch(e.target)}} className=" r-radius--3 pr-5 ml-4" style={{width: '190px'}} placeholder="Search for Student" />
              <i style={{ marginLeft: '-30px' }} className="pt-2 r-text--grey-4 fi flaticon-search"></i>
              <Button onClick={() => {isOpenDateFilter.is_open = !isOpenDateFilter.is_open; this.setState({isOpenDateFilter})}} className="font-weight-normal px-2 py-0 ml-4" color="blue3"><img style={{ width: "21px" }} src="/images/filter-512.png" alt="" /></Button>
            </div>
          </Col>
          
        </Row>

        <Row className="justify-content-end align-items-center">
          <Col xs="3" className="text-right">
              <div className="d-inline-block"><Switch  isOn={this.state.isOn} handleToggle={this.handleToggle}/></div>
              <div className="d-inline-block ml-2 mr-4">{this.state.isOn ? "Company Wise" : "Application Wise"}</div>
          </Col>
          <Col xs={2}>
            <Select value={this.state.isFilterRecruitmentClosed} onChange={async ({ target }) => { await this.setState({isFilterRecruitmentClosed: target.value}); this.getstReport(this.state.selectedProcess); }}>
                <option value={""}>All</option>
                <option value={0}>Recruitment in progress</option>
                <option value={1}>Recruitment Completed</option>
            </Select>
          </Col>
        </Row>

        { isOpenDateFilter && isOpenDateFilter.is_applied && <p className="text-right "><em className="text-muted r-text--sm">Data showing for {moment(isOpenDateFilter.start_date).format("Do MMM YYYY")} - {moment(isOpenDateFilter.end_date).format("Do MMM YYYY")}</em></p>}
        <Collapse isOpen={this.state.isOpenDateFilter.is_open}>
        <Row className="justify-content-end align-items-end">
          <Col xs={3}>
            <span className="r-subheading-4">From Date</span>
              <SingleDatePicker
                value={isOpenDateFilter.start_date}
                onDateChange={(date) => { isOpenDateFilter.start_date = date; this.setState({ isOpenDateFilter }) }}
              />
          </Col>
          <Col xs={3}>
              <span className="r-subheading-4">To Date</span>
              <SingleDatePicker
                value={isOpenDateFilter.end_date}
                onDateChange={(date) => { isOpenDateFilter.end_date = date; this.setState({ isOpenDateFilter }) }}
              />
          </Col>
          <Col xs={1}>
            {isOpenDateFilter.is_applied ? 
            <Button onClick={this.clearDateFilter} color="dark" square={true}>Clear</Button>
            :
            <Button onClick={this.filterByDate} color="dark" square={true}>Filter</Button>
            }
            </Col>
        </Row>
        </Collapse>
        {!isLoading && <Row className="align-items-center mt-3 mx-0" style={{ overflowX: 'auto', overflowY: 'hidden', }}>
          <Table className="post">
            <thead>
              <tr className="tableheader">
                {columns.map((col, i) =>
                  <th style={{ textAlign: "center" }} key={i}>
                    {col.title}
                  </th>

                )}
              </tr>
            </thead>
            <tbody>
              {
                data.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map((row, i) => {
                  return (
                    <tr
                      className="borderBottomlightblack"
                      key={i}>
                      <td style={{ textAlign: "center" }}>
                        {(currentPage * rowsPerPage) + i+1}
                      </td>
                      <td style={{ textAlign: "center" }} className={this.state.nameStyle}  onClick={(e) => this.stProfile(row,'apply')}>
                        { getFullName(row)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Button onClick={() =>this.processChangeHandler(selectedProcess , row , 'ELIGIBLE' )}>{row.eligible || 0}</Button>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Button onClick={() =>this.processChangeHandler(selectedProcess , row ,'APPLIED' )}>{row.applied || 0}</Button>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Button onClick={()=> this.processChangeHandler(selectedProcess , row ,'ROUND_1')}>{row.shortlist_round_1 || 0}</Button>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Button onClick={()=> this.processChangeHandler(selectedProcess , row ,'ROUND_2' )}>{row.shortlist_round_2 || 0}</Button>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Button onClick={()=> this.processChangeHandler(selectedProcess , row ,'ROUND_3' )}>{row.shortlist_round_3 || 0}</Button>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Button onClick={()=> this.processChangeHandler(selectedProcess , row ,'ROUND_4' )}>{row.shortlist_round_4 || 0}</Button>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Button onClick={() =>this.handleOfferButton(row.offered , row)}>{row.offer_count || 0}</Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
          {data.length === 0 && <p>{this.state.showMessage}</p>}
          <Modal zIndex="99999" isOpen={formatDataType && selectedStudent &&  studentFormatedData[formatDataType]} toggle={() => this.setState({ formatDataType : '' , companyObject : {} })}>
            <ModalBody>
              {(selectedStudent && formatDataType && studentFormatedData[formatDataType]) &&
                <Table>
                  <thead>
                    <tr className="tableheader">
                      <th>Company Name</th>
                      {Object.keys(companyObject).length >0 && <th>Count </th>}
                    </tr>
                  </thead>
                  <tbody>
                    {modalLoading ? <Col align="center" className="m-3"><Spinner color="dark" /></Col> : null}
                    {(selectedStudent && formatDataType &&   Object.keys(studentFormatedData[formatDataType]).length > 0 &&  studentFormatedData[formatDataType][selectedStudent.student_id]) &&  studentFormatedData[formatDataType][selectedStudent.student_id].map(student=>
                      {
                        return(
                          <tr
                            className="borderBottomlightblack"
                            key={student.campus_company_id}>
                            <td className="r-subheading-3">{student.company_details? student.company_details.company_name :  student.company_name}</td>
                            {Object.keys(companyObject).length >0 && <td>{companyObject[student.campus_company_id]}</td>}
                            </tr>
                        )
                      })

                      }
                      {((!studentFormatedData[formatDataType][selectedStudent.student_id] || studentFormatedData[formatDataType][selectedStudent.student_id].length==0)  && !modalLoading) &&
                        <tr className="borderBottomlightblack" key="0"><td><span className="r-text--red-1">*No Companies Found</span></td></tr>}
                  </tbody>
                </Table>}
            </ModalBody>
          </Modal>
          
          <Modal zIndex="99999" isOpen={companyDataView.length > 0} toggle={() => this.setState({ companyDataView: [] })}>
            <ModalBody>
              <Table>
                <thead>
                  <tr className="tableheader">
                    <th>Company Name</th>
                    <th>Counts</th>
                  </tr>
                </thead>
                <tbody>
                  {companyDataView.map((company, i ) => {
                    return <tr
                      className="borderBottomlightblack"
                      key={i}>
                      <td>{company.company_name}</td>
                      <td>{company.counts}</td>
                    </tr>
                  })}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
        </Row>}

          <Row className="justify-content-between align-items-center mx-0" >
            <Col xs="auto" className="">
              <Row className="justify-content-between align-items-center">
                <span className="px-1 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>Total students are {data.length}</span>
                <span className="px-3 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>View</span>
                <div className="r-dropdown border-0">
                  <select value={rowsPerPage} name="rowsPerPage" className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} onChange={(e) => { this.setState({ [e.target.name]: e.target.value, currentPage: 0 }) }}>
                    <option value="10" >10</option>
                    <option value="15">15</option>
                    <option value="20" >20</option>
                    <option value="25">25</option>
                    <option value="50" >50</option>
                    <option value="100">100</option>
                  </select>
                  <i className="fi flaticon-arrow" />
                </div>
              </Row>
            </Col>
            <Col xs="auto">
              <PaginationComponent
                totalItems={data.length}
                pageSize={rowsPerPage}
                onSelect={this.handleSelected}
                maxPaginationNumbers={3}
                activePage={currentPage + 1}
              />
            </Col>
          </Row>

      </Container>
    )
  }
}

export default StudentReport;
