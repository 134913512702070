import React from 'react';
import { Row,Col,Container,  Modal, ModalHeader,Nav, NavItem,Collapse, NavLink,TabContent, TabPane, ModalBody, ModalFooter,Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { Auth } from '../../../../actions/Auth';
import { uploadFile } from '../../../../actions';
import { HorizontalLine } from '../../../global/horizontal_line';
import Aux from '../../../global/AuxComp';
import classnames from 'classnames';
import { InputField } from '../../../global/Forms';
import Button from '../../../global/Button';
import { RTE } from '../../../global/Forms/RTE';
import  Feed from '../Feed/Feed';

class Students extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      sideNavLeft: null,
      sideNavRight: null,
      fileurl: null,
      file: '',
      post_tag: null,
      errorbar: false,
      message:'',
      title:'',
      content:'',
      attachfile:'',
      tab:1

    };
  }


   handleChangefile = (event) =>{
    this.setState({
      fileurl: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    })
  }


  closebar = () =>{
      this.setState({errorbar: false});
  }


  postCreate = async(e)=>{
      // e.preventDefault();
      //  this.props.setLoading(true);
      //    let newFile , attachedfile;
      //    if(this.state.attachfile){
      //             attachedfile = await uploadFile(this.state.attachfile,this.props.user.id + "/post" );
      //             this.setState({fileId: attachedfile.file_id})}
      //    if(this.state.file){
      //             newFile = await uploadFile(this.state.file,this.props.user.id + "/post" );
      //             this.setState({bannerId: newFile.file_id})}
      //     var postBody = {
      //              "post_type": "PUBLIC",
      //              "post_title": this.state.title,
      //              "post_banner_id": this.state.bannerId,
      //              "file_id": this.state.fileId,
      //              "video_id": this.state.videoId,
      //              "post_tag": this.state.post_tag,
      //              "post_creator_type": "PT",
      //              "post_content": this.state.content,
      //              "university_id":this.props.user.current_role_info.university_id,
      //              "college_id": this.props.user.current_role_info.college_id,
      //              "campus_id": this.props.user.current_role_info.campus_id,
      //              "campus_degree_id":this.props.user.current_role_info.campus_degree_id
      //         }
      //     try {
      //            await Auth.PtStudents.createPost(postBody);
      //           this.props.refresh();
      //           await this.setState({
      //             fileurl: null,
      //             file:'',
      //             attachfile:'',
      //             title:'',
      //             content:'',
      //             bannerId:'',
      //             fileId:'',
      //           });
      //           this.props.setLoading(false);
      //         } catch (e) {
      //           this.props.setLoading(false);
      //           console.log(e);
      //             // this.setState({errorbar: true});
      //             // this.setState({message: e.response.data.error.toString()});
      //         }
  }


  onChange = e => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }



  render() {
    let {title, content} = this.state;
    return (
       <Container>
          <Feed />
          {/*<div className="post my-5">
                          <Col xs="auto">
                                    <Row className="align-items-center py-3">
                                        <Col xs={7}>
                                          <div className="post__header align-items-center" >
                                            <span className="r-heading-4">Create a post</span>
                                          </div>
                                        </Col>
                                      </Row>
                              </Col>
                              <form onSubmit={this.postCreate}>
                                 {(this.state.fileurl !== null)?<Row  className="justify-content-center align-items-center "><img style={{width: "250px"}} src={this.state.fileurl} alt=""/></Row>:null}
                                <Col xs="12" className=" my-3">
                                   <InputField className="r-nav__search titleinput" onChange={this.onChange} placeholder="Title here..." name="title" value={title} type="text" required />
                                  </Col>
                                <Col xs="12" className=" my-3">
                                   <RTE className="r-nav__search titleinput" onChange={(value) => this.setState({content: value})} name="content" value={content} rows="3" placeholder="Description here..." required />
                                </Col>
                              <Col xs={{ size: '12', offset: 0 }}>
                                    <Row className="justify-content-end align-items-center my-4">
                                                <label htmlFor="file-input"  >
                                                 <Row className="border align-items-center font-weight-normal mx-3 py-1  px-3  " color="outline-dark-v">
                                                      <span style={{fontSize:"14px"}} className="r-text--grey-4">{this.state.attachfile.name || "Add an attachment"}</span>
                                                      <div className=" mx-2"/>
                                                      <img style={{width:'20px'}}  src={require('../../../../assets/icons/attachment.svg')}/>
                                                </Row>
                                                <input id="file-input"  onChange={(e) =>{this.setState({attachfile:e.target.files[0]})}} type="file" style={{display:"none"}}/>
                                                </label>
                                                <label htmlFor="file-input-image"  >
                                                 <Row className="border align-items-center font-weight-normal mx-3 py-1  px-3  " color="outline-dark-v">
                                                      <span style={{fontSize:"14px"}} className="r-text--grey-4">Add an</span>
                                                      <div className=" mx-2"/>
                                                      <i className=" r-text--xl fi flaticon-art r-text--grey-4" style={{fontSize:"16px"}}/>
                                                      <span className="mx-2"> /</span>
                                                      <i className=" r-text--xl fi flaticon-multimedia r-text--grey-4" style={{fontSize:"16px"}}/>
                                                </Row>
                                                <input id="file-input-image"  onChange={this.handleChangefile} type="file" style={{display:"none"}}/>
                                                </label>
                                       { /* <Button id="Popover2" className="font-weight-normal border-left  py-2 px-4 r-btn--no-radius r-btn r-btn--grey" color="grey">+ Button/Link</Button>
                                   <Button id="Popover1" className="font-weight-normal border-left  px-2 py-0" ><img src="/images/barchart.png" style={{height:"20px"}}  alt=""/></Button>

                                                                          <Button className="font-weight-normal border-left  px-2 py-0" ><img src="/images/paper-clip@2x.png" style={{height:"20px"}}  alt=""/></Button>
                                                                          <Col xs="auto">
                                                                              <Row><span className="r-heading-4">Category</span></Row>
                                                                              <Row><select className="selectfield">
                                                                                <option value="volvo">Marketing Society</option>
                                                                                <option value="saab">Likes</option>
                                                                                <option value="opel">Comments</option>
                                                                              </select></Row>
                                                                          </Col>*/}
                                      {/*  <Button type="submit" className="font-weight-normal  px-5 py-2 mx-4 r-btn--no-radius r-btn r-btn--dark" color="dark">Post</Button>
                                    </Row>
                              </Col>
                          </form>
                      </div>*/}
       </Container>
    );
  }
}


export default Students;
