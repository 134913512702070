import React, { Component } from "react";
import { Row, Col, Container , Collapse , Card , CardTitle, CardBody } from "reactstrap";
import Button from '../../global/Button';
import Modal from "reactstrap/lib/Modal";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { InputField } from "../../global/Forms";
import Aux from "../../global/AuxComp";
import { Errorbar } from '../../global/errorbar';
import { Auth } from "../../../actions/Auth";
import { SelectList, Select } from "../../global/Forms/Select";
import { connect } from "react-redux";
import { Loader } from "../../global/Loader";

class MultipleOfferSystem extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = ()=>{
    return(
      {
        value: false,
        openCollapseValue : null,
        isOn: false,
        processId: null,
        modal: false,
        modal_1: false,
        selectedProcessID: null,
        selectedProcessName: null,
        selectedProcessType: null,
        updateProcess: null,
        updateProcessType: null,
        loading: false,
        processList: null,
        archiveProcessList: null,
        selectDeleteProcess: null,
        applicationModal: false,
        offerSection: null,
        displayMaxCounter: null,
        isOnlyAcceptedOffers: false,
        classificationcategory: null,
        classificationDetails: null,
        selectedClassification: null,
        categoryValue: null,
        categoryList: null,
        selectedCategory: null,
        categoryMaxCount: {},
        categoryOverallCount: {},
        overallCount: null,
        selectedOfferedCategory: null,
        selectedOfferedClassification: null,
        outsideOfferCount: "",
        inProcessCheckbox: null,
        selectedMatrixProcess: this.props.process,
        editApplicationModal: false,
        cvSettingModal:false,
        ptUpload: false,
        stUpload: false,
        temUpload: false,
        cvToolbar: true,
        showUnverified: true,
        martixRuleList: null,
        ruleList: null,
        addRuleModal: false,
        classificationCheckbox: false,
        selectedOfferedCategoryName: null,
        ruleSelectedClassification: null,
        displayRuleCollapse: null,
        deleteMatrix: null,
        deleteMatrixModal: false,
        ptAllowEditProfile: false,
        popoverOpen : false,
        popoverName : '',
        errorbar : false,
        message : ''
      }
    )
  }

  stateOnCloseModal = ()=>{
    return({
      inProcessCheckbox : null,
      selectedOfferedClassification : null,
      outsideOfferCount : "",
      ruleList: null,
      classificationCheckbox: false,
      selectedOfferedCategoryName: null,
      displayMaxCounter: null,
      classificationcategory: null,
      categoryValue: null,
      categoryList: null,
      categoryMaxCount: {},
      overallCount: "",
      categoryOverallCount: {},
      addRuleModal: false,
      selectedOfferedCategory : null,
      offerSection : null,
    })
  }
  closebar = () => {
    this.setState({ errorbar: false });
  }

  getClassfication = async () => {
    let classificationDetails = await Auth.classification.listClassificationDetails();
    let categoryList = [];
    if (classificationDetails.data.length != 0) {
      classificationDetails.data.map(classification => {
        classification.classification_list.map(category => {
          categoryList.push(category);
        });
      });
    }
    this.setState({classificationDetails: classificationDetails.data,categoryList});
    if(classificationDetails.data[0]){
      this.setState({
        offerSection : classificationDetails.data[0].classification_category_id,
        selectedClassification :  classificationDetails.data[0].classification_category_id,
        ruleSelectedClassification : classificationDetails.data[0].name
      });
    }
  };

  addMatrixRuleToProcess = async () => {
    this.setState({ loading: true });
    let {
      selectedOfferedCategory,
      selectedMatrixProcess,
      inProcessCheckbox,
      selectedOfferedClassification,
      categoryOverallCount,
      overallCount,
      categoryMaxCount,
      selectedClassification,
      classificationDetails,
      categoryValue,
      outsideOfferCount,
      isOnlyAcceptedOffers
    } = this.state;

    if(outsideOfferCount && (inProcessCheckbox!=null)){
      let params = {
        process_id: selectedMatrixProcess.process_id,
        offer_count: parseInt(outsideOfferCount),
        // offer_classification_id : selectedOfferedClassification,
        matrix_rules: []
      };


      if (inProcessCheckbox) {
        params.offer_type = 1;
      }
      if (isOnlyAcceptedOffers) {
        params.include_only_accepted_offer = 1;
      }

      if (selectedOfferedClassification != null) {
        params.offer_classification_id = selectedOfferedClassification;
      }

      if (overallCount) {
        params.matrix_rules.push({
          rule_type: "max_limit",
          apply_limit: parseInt(overallCount)
        });
      }

      if (Object.keys(categoryOverallCount).length != 0) {
        Object.keys(categoryOverallCount).map(category => {
          if (categoryOverallCount[category] != "") {
            params.matrix_rules.push({
              rule_type: "classification_itself",
              apply_limit: categoryOverallCount[category],
              classification_category_id: category
            });
          }
        });
      }

      if (Object.keys(categoryMaxCount).length != 0) {
        Object.keys(categoryMaxCount).map(classification => {
          if (categoryMaxCount[classification] != "") {
            params.matrix_rules.push({
              rule_type: "classification_each",
              apply_limit: categoryMaxCount[classification],
              classification_id: classification
            });
          }
        });
      }

        try {
          let addMatrixRuleProcess = await Auth.classification.addMatrixRule(
            params
          );
          await this.getMatrixRule(this.state.selectedMatrixProcess.process_id);
          await this.setState(this.stateOnCloseModal());
          await this.setState({overallCount : null})
        } catch (e) {
          this.setState({ errorbar: true , loading:false });
          if(e.response && e.response.data){
            this.setState({ message: e.response.data.error.toString()});
          }
          else{
            this.setState({ message: 'Something went wrong! Please try again'});
          }
        }
        this.setState({loading : false});
    }
    else{
        this.setState({ errorbar: true , loading:false });
        this.setState({ message: 'Fill the mandatory fields'});
    }
    this.setState({loading : false});
  };

  handleFieldChange = e => {
    let { categoryMaxCount } = this.state;
    if (e.target.value !== undefined) {
      //   let {categoryMaxCount} = this.state;
      categoryMaxCount[e.target.name] = e.target.value;
      this.setState({
        categoryMaxCount
      });
    }
  };

  handleClassificaionChange = e => {
    if (e.target.value !== undefined) {
      this.setState({
        selectedOfferedCategory: e.target.value,
        selectedOfferedCategoryName: e.target.classificationName
      });
    }
  };

  handleItselfCount = e => {
    let { categoryOverallCount } = this.state;
    if (e.target.value !== undefined) {
      categoryOverallCount[e.target.name] = e.target.value;
    }
    this.setState({
      categoryOverallCount
    });
  };

  getMatrixRule = async processId => {
    this.setState({loading : true});
    try {
      let martixRuleList = await Auth.classification.getMatrixRuleList(
        processId
      );
      await this.setState({
        martixRuleList: martixRuleList.data
      });
    } catch (e) {
      this.setState({errorbar : true});
      if(e.response && e.response.data){
        this.setState({message : e.response.data.error.toString()})
      }else{
        this.setState({message : "Something went wrong! Please try again"})
      }
    }
    this.setState({loading : false});
  };

  processMatrixHandler = async () => {
    let process = this.props.process;
    await this.setState({
      selectedMatrixProcess: process,
      selectedProcessID: process.process_id,
      editApplicationModal: !this.state.editApplicationModal,
      selectedProcessName: process.process_name
    });
    this.getMatrixRule(process.process_id);
  };

  handleEditRule = async rule => {
    let { categoryOverallCount, categoryMaxCount , classificationDetails } = this.state;
    await this.setState({
      ruleList: rule,
      displayRuleCollapse: null,
      outsideOfferCount: rule.offer_count,
      isOnlyAcceptedOffers: !!rule.include_only_accepted_offer,
      inProcessCheckbox: rule.offer_type == 1 ? true : false,
      classificationCheckbox:
        rule.offer_classification["classification_category_id"] ||
        rule.offer_classification["classification_id"]
          ? true
          : false,
      selectedOfferedCategory:
        Object.keys(rule.offer_classification).length != 0
          ? rule.offer_classification["classification_category_id"]
          : null,
      selectedOfferedClassification:
        Object.keys(rule.offer_classification).length != 0
          ? rule.offer_classification["classification_id"]
          : null,
      selectedClassification :  Object.keys(rule.offer_classification).length> 0
        ? rule.offer_classification["classification_category_id"]
        : null,
      offerSection:
        Object.keys(rule.offer_classification).length != 0
          ? rule.offer_classification["classification_category_id"]
          : null,
      categoryValue: 2,
      ruleSelectedClassification : Object.keys(rule.offer_classification).length != 0
        ?classificationDetails.forEach(classification=>{if(classification.classification_category_id == rule.offer_classification["classification_category_id"]){return classification.name }})
        : null,
    });
    if (Object.keys(rule.offer_classification).length != 0) {
      let editCategory = this.state.classificationDetails.filter(
        classification =>
          classification.classification_category_id ==
          rule.offer_classification.classification_category_id
      );
      await this.setState({
        selectedOfferedCategoryName: editCategory[0].name,
        ruleSelectedClassification: editCategory[0].name
      });
    }

    if (rule.matrix_rule.length > 0) {
      rule.matrix_rule.map(async list => {
        if (list.rule_type == "max_limit") {
          await this.setState({
            overallCount: list.apply_limit!=null ? list.apply_limit : null
          });
        } else if (list.rule_type == "classification_itself") {
          categoryOverallCount[list.classification_category_id] =
            list.apply_limit;
          await this.setState({
            categoryOverallCount,
            selectedClassification: list.classification_category_id!=null ? list.classification_category_id : this.state.selectedClassification
          });
        } else {
          categoryMaxCount[list.classification_id] = list.apply_limit;
          await this.setState({
            categoryMaxCount,
            selectedClassification: list.classification_category_id!=null ? list.classification_category_id : this.state.selectedClassification
          });
        }
      });
    }
  };

  editMatrixProcessRule = async matrix => {
    this.setState({ loading: true });
    let {
      selectedOfferedCategory,
      selectedMatrixProcess,
      inProcessCheckbox,
      selectedOfferedClassification,
      categoryOverallCount,
      overallCount,
      categoryMaxCount,
      selectedClassification,
      classificationDetails,
      categoryValue,
      outsideOfferCount,
      isOnlyAcceptedOffers
    } = this.state;

    if(outsideOfferCount){
    let addNewRuleParams = {
      process_id: this.state.selectedMatrixProcess.process_id,
      offer_count: parseInt(outsideOfferCount),
      matrix_rules: []
    };

    let params = {
      process_matrix_id: matrix.process_matrix_id,
      offer_count: parseInt(outsideOfferCount)
      // offer_classification_id : selectedOfferedClassification,
      // matrix_rules : []
    };
    params.offer_type = inProcessCheckbox == true ? 1 : 0;
    params.include_only_accepted_offer = isOnlyAcceptedOffers == true ? 1 : 0;
    // if(inProcessCheckbox==true){
    //   params.offer_type = 1
    // }
    // else{
    //   params.offer_type = 0
    // }

    if (selectedOfferedClassification != null) {
      params.offer_classification_id = selectedOfferedClassification;
    }
    let editMatrixRuleParams = [];
    if (matrix.matrix_rule.length != 0) {
      matrix.matrix_rule.map(rule => {
        if (rule.rule_type == "max_limit") {
          if (overallCount) {
            editMatrixRuleParams.push({
              process_matrix_rule_id: rule.process_matrix_rule_id,
              apply_limit: parseInt(overallCount)
            });
          }
        } else if (rule.rule_type == "classification_itself") {
          editMatrixRuleParams.push({
            process_matrix_rule_id: rule.process_matrix_rule_id,
            apply_limit: categoryOverallCount[rule.classification_category_id]
          });
        } else {
          editMatrixRuleParams.push({
            process_matrix_rule_id: rule.process_matrix_rule_id,
            apply_limit: categoryMaxCount[rule.classification_id]
          });
        }
      });
    }

    // if(matrix.matrix_rule.lenth!=0){
    //   matrix.matrix_rule.map(rule=>{
    //     if(rule.rule_type !="max_limit" && overallCount!=null){
    //       addNewRuleParams.matrix_rules.push({"rule_type" : "max_limit", "apply_limit" : overallCount})
    //     }
    //     if(rule.rule_type!="classification_itself" && Object.keys(categoryOverallCount).length!=0){
    //
    //       if(Object.keys(categoryOverallCount).length!=0){
    //         Object.keys(categoryOverallCount).map(category=>{
    //           if(categoryOverallCount[category]!=""){
    //             addNewRuleParams.matrix_rules.push({"rule_type" : "classification_itself", "apply_limit":categoryOverallCount[category], "classification_category_id":category})
    //           }
    //         })
    //       }
    //     }
    //     if(rule.rule_type !="classification_each" && Object.keys(categoryMaxCount).length!=0 ){
    //
    //       if(Object.keys(categoryMaxCount).length!=0){
    //         Object.keys(categoryMaxCount).map(classification=>{
    //           if(categoryMaxCount[classification]!=""){
    //             addNewRuleParams.matrix_rules.push({"rule_type" : "classification_each", "apply_limit": categoryMaxCount[classification], "classification_id": classification})
    //           }
    //         })
    //       }
    //     }
    //
    //   })
    //     }

    // if(addNewRuleParams.matrix_rules.length !=0){
    //   try{
    //     await Auth.classification.addMatrixRule(addNewRuleParams);
    //     // this.getMatrixRule(this.state.selectedMatrixProcess.process_id);
    //   }
    //   catch(e){
    //     console.log(e)
    //   }
    //
    // }

    try {
      await Auth.classification.editMatrix(params);
      for (let i = 0; i < editMatrixRuleParams.length; i++) {
        try {
          let tempParam = {
            process_matrix_rule_id:
              editMatrixRuleParams[i].process_matrix_rule_id,
            apply_limit: editMatrixRuleParams[i].apply_limit
          };
          await Auth.classification.editMatrixRule(tempParam);
        } catch (e) {
          this.setState({errorbar : true});
          if(e.response && e.response.data){
            this.setState({message : e.response.data.error.toString()})
          }else{
            this.setState({message : "Something went wrong! Please try again"})
          }
        }
      }

      await this.getMatrixRule(this.state.selectedMatrixProcess.process_id);
      this.setState({
        ruleList: null,
        displayMaxCounter: null,
        classificationcategory: 1,
        selectedClassification: null,
        categoryValue: null,
        categoryList: null,
        selectedCategory: null,
        categoryMaxCount: {},
        overallCount: null,
        categoryOverallCount: {},
        selectedOfferedCategory: null,
        selectedOfferedClassification: null,
        outsideOfferCount: null,
        inProcessCheckbox: null,
        addRuleModal: false
      });
    } catch (e) {
      this.setState({errorbar : true});
      if(e.response && e.response.data){
        this.setState({message : e.response.data.error.toString()})
      }else{
        this.setState({message : "Something went wrong! Please try again"})
      }
    }
  }else{
    this.setState({errorbar : true, loading : false ,message : 'Fill all the mandatory fields'});
  }
  this.setState({ loading: false });
  };

  deleteMatrixRule = async matrix => {
    this.setState({ loading: true });
    try {
      await Auth.classification.deleteMatrix({
        process_matrix_id: matrix.process_matrix_id
      });
    } catch (e) {
      this.setState({errorbar : true});
      if(e.response && e.response.data){
        this.setState({message : e.response.data.error.toString()})
      }else{
        this.setState({message : "Something went wrong! Please try again"})
      }
    }
    await this.getMatrixRule(this.state.selectedMatrixProcess.process_id);
    this.setState({ loading: false });
  }

  handleAddRuleForm = ()=>{
    this.setState(this.stateOnCloseModal());
    this.setState({addRuleModal : !this.state.addRuleModal});
  }
  closeMultipleOffer = async()=>{
    await this.setState(this.getInitialState());
    this.getClassfication();
    this.getProcessList();
  }

  getProcessList = async () => {
    let getCampusProcess = await Auth.Batch.getProcesses(
      this.props.role.instituteDetail.campus_id
    );
    let processList = getCampusProcess.data.filter(
      process => process.archive_status == 0
    );
    let archiveProcessList = getCampusProcess.data.filter(
      process => process.archive_status == 1
    );
    await this.setState({
      processList: processList
    });
    this.setState({
      archiveProcessList
    });
  }

  closeMultipleOfferSystemModal = ()=>{
    this.closeMultipleOffer();
  }

  cancelMultipleOfferEdit = async()=>{
    await this.setState(this.stateOnCloseModal())
    this.setState({overallCount:null})
  }

  async componentDidMount() {
    await this.getClassfication();
    this.processMatrixHandler();
    // this.getProcessList();
    // this.getcvConfig();
  }

  render() {
   let flag = 0 , classificationFlag=0;
   let {closeApplicationModal}= this.props;
   let {loading} = this.state;
   return(
     <Container>
     <Loader show={loading} />
     <ModalBody>
       <Button
         className="r-text--sm r-btn r-btn--brandB"
         onClick={() =>
           this.handleAddRuleForm()
         } >
         + Add Rule{" "}
       </Button>

       <Collapse
         isOpen={this.state.addRuleModal}
         className="mt-3"
       >
         <Row>
           <Col xs="6">
             <ModalHeader>
               <span className="r-heading-4">
                 Step 1 - Define Condition
               </span>
             </ModalHeader>
           </Col>
           <Col xs="6">
             <ModalHeader>
               <span className="r-heading-4">
                 Step 2 - Define Rules
               </span>
             </ModalHeader>
           </Col>
         </Row>
         <ModalBody>
           <Row>
             <Col xs="6" className="border-right">
               <Row>
                 <Col xs="">
                   <label className="r-subheading-3">
                     <span className="r-text--red-1">*</span>On what offer count should this condition
                     apply?
                   </label>
                   <InputField
                     onChange={e =>
                       this.setState({
                         outsideOfferCount: e.target.value
                       })
                     }
                     value={this.state.outsideOfferCount}
                     name="offerCount"
                     className="w-100"
                     placeholder="Offer Count"
                   />
                 </Col>
               </Row>
               <Row>
                 <Col className="mt-4 d-flex align-items-center">
                   <input
                     type="checkbox"
                     name="checkbox"
                     onChange={() =>
                       this.setState({
                        isOnlyAcceptedOffers: !this.state
                           .isOnlyAcceptedOffers
                       })
                     }
                     checked={this.state.isOnlyAcceptedOffers}
                   />
                   <span className="r-subheading-3 ml-2">
                     {" "}
                     Apply only on Accepted Offers
                   </span>
                 </Col>
               </Row>
               <Row>
                 <Col className="mt-4 d-flex align-items-center">
                   <input
                     type="checkbox"
                     name="checkbox"
                     onChange={() =>
                       this.setState({
                         classificationCheckbox: !this.state
                           .classificationCheckbox
                       })
                     }
                     checked={this.state.classificationCheckbox}
                   />
                   <span className="r-subheading-3 ml-2">
                     {" "}
                     Apply more conditions
                   </span>
                 </Col>
               </Row>

               <Row className={this.state.classificationCheckbox?"" : "d-none"}
               >
                 <Col xs="6" className="mt-4">
                   {this.state.classificationDetails &&
                     this.state.classificationDetails.map(
                       classification => (
                         <div key={classification.classification_category_id}>
                           <input
                             type="radio"
                             name="classification"
                             onClick={
                               (e)=>  this.setState({
                                   selectedOfferedCategory: e.target.value,
                                   selectedOfferedCategoryName: classification.name,
                                   selectedClassification : e.target.value,
                                   offerSection : e.target.value,
                                   ruleSelectedClassification : classification.name
                                 })
                             }
                             value={
                               classification.classification_category_id
                             }
                             checked={
                               classification.classification_category_id ==
                               this.state
                                 .selectedOfferedCategory
                             }
                           />
                           <label class="form-check-label ml-1">
                             {" "}
                             {classification.name}{" "}
                           </label>
                         </div>
                       )
                     )}
                 </Col>
                 {this.state.selectedOfferedCategoryName !=
                 null ? (
                   <Col xs="6" className="mt-4">
                     <label className="r-subheading-3">
                       {this.state.selectedOfferedCategoryName}{" "}
                       Type
                     </label>
                     {this.state.classificationDetails && (
                       <Select
                         onChange={e =>
                           this.setState({
                             selectedOfferedClassification:
                               e.target.value
                           })
                         }
                         name="category"
                         required
                       >
                         <option value="" >Select </option>
                         {this.state.classificationDetails.map(
                           classification =>
                             classification.classification_list &&
                             classification.classification_list.map(
                               list => {
                                 if (
                                   list.classification_category_id ==
                                   this.state
                                     .selectedOfferedCategory
                                 ) {
                                   return (
                                     <option
                                       value={
                                         list.classification_id
                                       }
                                     >
                                       {list.name}
                                     </option>
                                   );
                                 }
                               }
                             )
                         )}
                       </Select>
                     )}
                   </Col>
                 ) : null}
               </Row>
               <Row>
                 <Col className="mt-4">
                   <span className="r-subheading-3 ml-1">
                     {" "}
                     <span className="r-text--red-1">*</span>Condition applicable on?
                   </span>
                 </Col>
               </Row>
               <Row>
                 <Col className="d-inline-block m-3">
                   <input
                     type="radio"
                     name="inProcessCheckbox"
                     onChange={() =>
                       this.setState({
                         inProcessCheckbox: true
                       })
                     }
                     checked={this.state.inProcessCheckbox==true}
                   />{" "}
                   <span className="r-subheading-4 mr-3">
                     {" "}
                     Offers in{" "}
                     {this.state.selectedProcessName &&
                       this.state.selectedProcessName}
                   </span>
                   <input
                     type="radio"
                     name="inProcessCheckbox"
                     className="ml-4"
                     onChange={() =>
                       this.setState({
                         inProcessCheckbox: false
                       })
                     }
                     checked={this.state.inProcessCheckbox==false}
                   />{" "}
                   <span className="r-subheading-4">
                     All Offers{" "}
                   </span>
                 </Col>
               </Row>
             </Col>
             <Col xs="6">
               <Row>
                 <Col
                   className={
                     this.state.displayMaxCounter != 1
                       ? ""
                       : "d-none"
                   }
                 >
                   <label className="r-subheading-3">
                     Overall Maximum Application Limit?
                   </label>
                   <InputField
                     onChange={e =>
                       this.setState({
                         overallCount: e.target.value
                       })
                     }
                     value = {this.state.overallCount}
                     name="offerCount"
                     className="w-100"
                     placeholder="Max Count"
                   />
                 </Col>
               </Row>
               <Row>
                 <Col className="mt-4">
                   <span className="r-subheading-3">
                     {" "}
                     Apply Limits On
                   </span>
                 </Col>
               </Row>
               <Row>
                 <Col className="mt-1">
                   {this.state.classificationDetails &&
                     this.state.classificationDetails.map(
                       classification => {
                         if(this.state.selectedOfferedCategory == classification.classification_category_id ){
                           return(
                             <button
                               type="button"
                               className={
                                 " btn pl-3 pr-3 calendar__edit-partipants__top-buttons r-subheading-4 r-text--grey-4  " +
                                 (
                                 this.state.selectedOfferedCategory == classification.classification_category_id
                                   ? "active"
                                   : null)
                               }
                               onClick={() =>
                                  this.setState({
                                   offerSection:
                                     classification.classification_category_id,
                                   selectedClassification:
                                     classification.classification_category_id,
                                   ruleSelectedClassification:
                                     classification.name
                                 })
                               }
                               name={classification.name}
                             >
                               {classification.name}
                             </button>
                           )
                         }
                       }
                     )}
                 </Col>
               </Row>
               <hr color="black" class="m-0" />
               {this.state.ruleSelectedClassification  && this.state.selectedOfferedCategory ? (
                 <Row>
                   <Col className="d-flex mt-3">
                     <div class="form-check-inline">
                       <input
                         type="radio"
                         name="classificationcategory"
                         onClick={() =>
                           this.setState({ categoryValue: 2 })
                         }
                         value={
                           this.state.classificationcategory
                         }
                         checked={this.state.categoryValue==2}
                       />{" "}
                       <label class="form-check-label ml-1">
                         {" "}
                         The same{" "}
                         {this.state
                           .ruleSelectedClassification &&
                           this.state
                             .ruleSelectedClassification}{" "}
                         as the offer{" "}
                       </label>
                     </div>
                     <div class="form-check-inline ml-3">
                       <input
                         type="radio"
                         name="classificationcategory"
                         onClick={() =>
                           this.setState({ categoryValue: 1 })
                         }
                         value={
                           this.state.classificationcategory
                         }
                         checked={this.state.categoryValue==1}
                       />{" "}
                       <label class="form-check-label ml-1">
                         {" "}
                         Each{" "}
                         {this.state
                           .ruleSelectedClassification &&
                           this.state
                             .ruleSelectedClassification}{" "}
                       </label>
                     </div>
                   </Col>
                 </Row>
               ) : null}
               <Collapse
                 isOpen={this.state.categoryValue == 1}
                 className="m-3"
               >
                 {this.state.classificationDetails &&
                   this.state.classificationDetails.map(
                     classification => {
                       if (
                         classification.classification_category_id ==
                         this.state.selectedClassification
                       ) {
                         return (
                           classification.classification_list &&
                           classification.classification_list.map(
                             category => (
                               <Row>
                                 <Col xs="6">
                                   <span className="r-subheading-4">
                                     {" "}
                                     {category.name}{" "}
                                   </span>
                                 </Col>
                                 <Col xs="6" className="mb-2">
                                   <InputField
                                     onChange={
                                       this.handleFieldChange
                                     }
                                     name={
                                       category.classification_id
                                     }
                                     value ={Object.keys(this.state.categoryMaxCount).length>0? this.state.categoryMaxCount[category.classification_id] : ""}
                                     className="w-100"
                                     placeholder="Count"
                                   />
                                 </Col>
                               </Row>
                             )
                           )
                         );
                       }
                     }
                   )}
               </Collapse>
               <Collapse
                 isOpen={this.state.categoryValue == 2}
                 className="m-3"
               >
                 {this.state.selectedClassification && (
                   <Row>
                     <Col>
                       <InputField
                         onChange={this.handleItselfCount}
                         name={this.state.offerSection}
                         value = {Object.keys(this.state.categoryOverallCount).length>0? this.state.categoryOverallCount[this.state.offerSection] : ""}
                         className="w-100"
                         placeholder="Count"
                       />
                     </Col>
                   </Row>
                 )}
               </Collapse>
             </Col>
           </Row>
         </ModalBody>
         <ModalFooter>
           <Button
             className="pr-4 pl-4  r-text--light border-0 r-subheading-4"
             color="dark"
             onClick={() => this.addMatrixRuleToProcess()}
           >
             Save
           </Button>
           <Button
             onClick={() =>
               this.setState(this.stateOnCloseModal())
             }
             className="ml-3 pr-4 pl-4 r-bg--grey-2 r-text--grey-4 border-0 r-subheading-4"
           >
             Cancel
           </Button>
         </ModalFooter>
       </Collapse>
       <table className="processes__table__1 table border-0 mt-3">
         <tbody>
           <tr>
             <th className="r-subheading-3">Offer Count</th>
             <th className="r-subheading-3">Classification</th>
             <th className="r-subheading-3">
               Classification-Type
             </th>
             <th className="r-subheading-3">Applicable On</th>
             <th className="r-subheading-3">Rule</th>
             <th />
             <th />
           </tr>

           {this.state.martixRuleList &&
             this.state.martixRuleList.map(list => (
               <Aux>
                 <tr>
                   <td>
                     {list.offer_count != null
                       ? list.offer_count
                       : "All"}
                   </td>
                   <td>
                     {list.offer_classification
                       .classification_category_id != null
                       ? this.state.classificationDetails &&
                         this.state.classificationDetails.map(
                           classification => {
                             if (
                               classification.classification_category_id ==
                               list.offer_classification
                                 .classification_category_id
                             ) {
                               return classification.name;
                             }
                           }
                         )
                       : "All"}
                   </td>
                   <td>
                     {list.offer_classification.name
                       ? list.offer_classification.name
                       : "All"}
                   </td>
                   <td>
                     {list.offer_type == 1 ? (
                       <span>
                         Offers in{" "}
                         {this.state.selectedProcessName &&
                           this.state.selectedProcessName}
                       </span>
                     ) : (
                       <span> All Offers</span>
                     )}
                   </td>
                   <td>
                     <button className="btn"><i
                       className="fi flaticon-eye  r-subheading-4"
                       onClick={async () =>
                         await this.setState({
                           ruleList: null,
                           displayRuleCollapse: list,
                           addRuleModal: false
                         })
                       }
                     /></button>
                   </td>
                   <td>
                   <button className="btn" >
                     <i
                       className="fi flaticon-edit r-subheading-4"
                       onClick={() => this.handleEditRule(list)}
                     /></button>
                   </td>
                   <td>
                     <button className="btn" ><i
                       className="fi flaticon-bin r-subheading-4"
                       onClick={() =>
                         this.setState({
                           deleteMatrixModal: !this.state
                             .deleteMatrixModal,
                           deleteMatrix: list
                         })
                       }
                     /></button>
                   </td>
                 </tr>

                 <Modal
                   zIndex="99999"
                   size="md"
                   isOpen={
                     this.state.deleteMatrixModal &&
                     this.state.deleteMatrix.process_matrix_id ==
                       list.process_matrix_id
                   }
                   toggle={() =>
                     this.setState({
                       deleteMatrixModal: !this.state
                         .deleteMatrixModal
                     })
                   }
                 >
                   <ModalHeader
                     toggle={async () =>
                       await this.setState({
                         deleteMatrixModal: !this.state
                           .deleteMatrixModal
                       })
                     }
                   >
                     <span className="r-subheading-3">
                       Are you sure you want to delete ?{" "}
                     </span>
                   </ModalHeader>
                   <ModalFooter>
                     <Button
                       className="pr-4 pl-4  r-text--light border-0 r-subheading-4"
                       color="dark"
                       onClick={() =>
                         this.deleteMatrixRule(
                           this.state.deleteMatrix
                         )
                       }
                     >
                       Delete
                     </Button>
                     <Button
                       className="pr-4 pl-4 r-bg--grey-2 r-text--grey-4 border-0 r-subheading-4"
                       onClick={() =>
                         this.setState({
                           deleteMatrixModal: !this.state
                             .deleteMatrixModal
                         })
                       }
                     >
                       Cancel
                     </Button>
                   </ModalFooter>
                 </Modal>
                 {this.state.displayRuleCollapse &&
                 this.state.displayRuleCollapse
                   .process_matrix_id ==
                   list.process_matrix_id ? (
                   <tr>
                     <td colSpan="7" className="r-bg--light">
                       <Collapse
                         isOpen={
                           this.state.displayRuleCollapse &&
                           this.state.displayRuleCollapse
                             .process_matrix_id ==
                             list.process_matrix_id
                         }
                       >
                         {this.state.displayRuleCollapse
                           .matrix_rule.length != 0 ? (
                           <Card className="w-75 m-2">
                             <CardTitle className="r-subheading-3 m-3 border-bottom">
                               <Row>
                                 <Col xs="11">Applied Rules</Col>
                                 <Col xs="1" align="end">
                                   <i
                                     className="fi flaticon-plus-3 settings__button-cursor"
                                     onClick={() =>
                                       this.setState({
                                         displayRuleCollapse: null
                                       })
                                     }
                                   />
                                 </Col>
                               </Row>
                             </CardTitle>
                             <CardBody>
                               {list.matrix_rule &&
                                 list.matrix_rule.map(rule => {
                                   if (
                                     rule.rule_type ==
                                     "max_limit"
                                   ) {
                                     return (
                                       <div>
                                         <span className="r-subheading-4">
                                           Maximum Application
                                           Limit -{" "}
                                           {rule.apply_limit}
                                         </span>
                                       </div>
                                     );
                                   } else if (
                                     rule.rule_type ==
                                     "classification_itself"
                                   ) {
                                     return (
                                       <div>
                                         <span className="r-subheading-4">
                                           Maximum limit on
                                           applying to same{" "}
                                           {this.state
                                             .classificationDetails
                                             ? this.state.classificationDetails.map(
                                                 classification => {
                                                   classification.classification_list &&
                                                     classification.classification_list.map(
                                                       category => {
                                                         if (
                                                           category.classification_category_id ==
                                                           rule.classification_category_id
                                                         ) {
                                                           return rule.classification_category_id;
                                                         }
                                                       }
                                                     );
                                                 }
                                               )
                                             : null}{" "}
                                           as the offer -{" "}
                                           {rule.apply_limit}
                                         </span>
                                       </div>
                                     );
                                   } else {
                                     return (
                                       <div>
                                         <span className="r-subheading-4">
                                           Maximum limits
                                           applicable on{" "}
                                         </span>
                                         {Object.keys(
                                           rule.classification
                                         ).length != 0 ? (
                                           <span className="r-subheading-4">
                                             {
                                               rule
                                                 .classification[
                                                 "name"
                                               ]
                                             }{" "}
                                             - {rule.apply_limit}
                                           </span>
                                         ) : null}
                                       </div>
                                     );
                                   }
                                 })}
                             </CardBody>
                           </Card>
                         ) : (
                           <Card className="w-75 m-2">
                             <CardTitle className="r-subheading-3 m-2">
                               <Row>
                                 <Col xs="11">
                                   No Rules Defined
                                 </Col>
                                 <Col xs="1" align="end">
                                   <i
                                     className="fi flaticon-plus-3 settings__button-cursor"
                                     onClick={() =>
                                       this.setState({
                                         displayRuleCollapse: null
                                       })
                                     }
                                   />
                                 </Col>
                               </Row>
                             </CardTitle>
                           </Card>
                         )}
                       </Collapse>
                     </td>
                   </tr>
                 ) : null}
                 {this.state.ruleList &&
                 this.state.ruleList.process_matrix_id ==
                   list.process_matrix_id ? (
                   <tr>
                     <td colSpan="7" className="r-bg--light">
                       <Collapse
                         isOpen={
                           this.state.ruleList &&
                           this.state.ruleList
                             .process_matrix_id ==
                             list.process_matrix_id
                         }
                         className="mt-3"
                       >
                         <Row>
                           <Col xs="6">
                             <ModalHeader>
                               <span className="r-heading-4">
                                 Step 1 - Define Condition
                               </span>
                             </ModalHeader>
                           </Col>
                           <Col xs="6">
                             <ModalHeader>
                               <span className="r-heading-4">
                                 Step 2 - Define Rules
                               </span>
                             </ModalHeader>
                           </Col>
                         </Row>
                         <ModalBody>
                           <Row>
                             <Col
                               xs="6"
                               className="border-right"
                             >
                               <Row  >
                                 <Col>
                                   <label className="r-subheading-3">
                                     <span className="r-text--red-1">*</span>On what offer count should
                                     this condition apply?
                                   </label>
                                   <InputField
                                     onChange={e =>
                                       this.setState({
                                         outsideOfferCount:
                                           e.target.value
                                       })
                                     }
                                     name="offerCount"
                                     value={
                                       this.state
                                         .outsideOfferCount !=
                                       null
                                         ? this.state
                                             .outsideOfferCount
                                         : null
                                     }
                                     className="w-100"
                                     placeholder="Offer Count"
                                   />
                                 </Col>
                               </Row>
                               <Row >
                                 <Col className="mt-4 d-flex align-items-center">
                                   <input
                                     type="checkbox"
                                     name="checkbox"
                                     onChange={() => this.setState({isOnlyAcceptedOffers: !this.state.isOnlyAcceptedOffers})}
                                     value={
                                       this.state
                                         .isOnlyAcceptedOffers
                                     }
                                     checked={
                                       this.state
                                         .isOnlyAcceptedOffers
                                     }
                                   />
                                   <span className="r-subheading-3 ml-2">
                                     {" "}
                                     Apply only on Accepted Offers
                                   </span>
                                 </Col>
                               </Row>
                               <Row >
                                 <Col className="mt-4 d-flex align-items-center">
                                   <input
                                     type="checkbox"
                                     name="checkbox"

                                     value={
                                       this.state
                                         .classificationCheckbox
                                     }
                                     checked={
                                       this.state
                                         .classificationCheckbox
                                     }
                                   />
                                   <span className="r-subheading-3 ml-2">
                                     {" "}
                                     Apply more conditions
                                   </span>
                                 </Col>
                               </Row>

                               <Row
                               >
                                 <Col xs="6" className="mt-4">
                                   {this.state
                                     .classificationDetails &&
                                     this.state.classificationDetails.map(
                                       classification =>{
                                         if(this.state.selectedOfferedCategory!=null && (classification.classification_category_id == this.state.selectedOfferedCategory))
                                         return(
                                           <div>
                                             <input
                                               type="radio"
                                               name={
                                                 classification.name
                                               }

                                               value={
                                                 classification.classification_category_id
                                               }
                                               checked={
                                                 classification.classification_category_id ==
                                                 this.state
                                                   .selectedOfferedCategory
                                               }
                                             />
                                             <label class="form-check-label ml-1">
                                               {" "}
                                               {
                                                 classification.name
                                               }{" "}
                                             </label>
                                           </div>
                                         )
                                       }
                                     )}
                                 </Col>
                                 {this.state
                                   .selectedOfferedCategoryName !=
                                 null ? (
                                   <Col xs="6" className="mt-4">
                                     <label className="r-subheading-3">
                                       {
                                         this.state
                                           .selectedOfferedCategoryName
                                       }{" "}
                                       Type
                                     </label>
                                     {this.state
                                       .classificationDetails && (
                                       <Select
                                         onChange={e =>
                                           this.setState({
                                             selectedOfferedClassification:
                                               e.target.value
                                           })
                                         }
                                         value={
                                           this.state
                                             .selectedOfferedClassification
                                         }
                                         name="category"
                                       >
                                         <option value="" >Select</option>
                                         {this.state.classificationDetails.map(
                                           classification =>
                                             classification.classification_list &&
                                             classification.classification_list.map(
                                               list => {
                                                 if (
                                                   list.classification_category_id ==
                                                   this.state
                                                     .selectedOfferedCategory
                                                 ) {
                                                   return (
                                                     <option
                                                       value={
                                                         list.classification_id
                                                       }
                                                     >
                                                       {
                                                         list.name
                                                       }
                                                     </option>
                                                   );
                                                 }
                                               }
                                             )
                                         )}
                                       </Select>
                                     )}
                                   </Col>
                                 ) : null}
                               </Row>
                               <Row>
                                 <Col className="mt-4">
                                   <span className="r-subheading-3 ml-1">
                                     {" "}
                                     Condition applicable on?
                                   </span>
                                 </Col>
                               </Row>
                               <Row>
                                 <Col className="d-inline-block m-3">
                                   <input
                                     type="radio"
                                     name="checkbox"
                                     value="inProcessCheckbox"
                                     onClick={() =>
                                        this.setState({
                                         inProcessCheckbox: true
                                       })
                                     }
                                     checked={this.state.inProcessCheckbox==true}
                                   />{" "}
                                   <span className="r-subheading-4 mr-3">
                                     {" "}
                                     Offers in{" "}
                                     {this.state
                                       .selectedProcessName &&
                                       this.state
                                         .selectedProcessName}
                                   </span>
                                   <input
                                     type="radio"
                                     name="checkbox"
                                     className="ml-4"
                                     value="allCheckbox"
                                     onClick={()=>
                                       this.setState({
                                         inProcessCheckbox: false
                                       })
                                     }
                                     checked={this.state.inProcessCheckbox==false}
                                   />{" "}
                                   <span className="r-subheading-4">
                                     All Offers{" "}
                                   </span>
                                 </Col>
                               </Row>
                             </Col>
                             <Col xs="6">
                               <Row  className={this.state.overallCount !=null && this.state.overallCount!=""?"":"d-none"}>
                                 <Col
                                   className={
                                     this.state
                                       .displayMaxCounter != 1
                                       ? ""
                                       : "d-none"
                                   }
                                 >
                                   <label className="r-subheading-3">
                                     Overall Maximum Application
                                     Limit?
                                   </label>
                                   <InputField
                                     onChange={e =>
                                       this.setState({
                                         overallCount:
                                           e.target.value
                                       })
                                     }
                                     value={
                                       this.state.overallCount
                                     }
                                     name="offerCount"
                                     className="w-100"
                                     placeholder="Max Count"
                                   />
                                 </Col>
                               </Row>
                               <Row>
                                 <Col className="mt-4">
                                   <span className="r-subheading-3">
                                     {" "}
                                     Apply Limits On
                                   </span>
                                 </Col>
                               </Row>
                               <Row >
                                 <Col className="mt-1">
                                   {this.state
                                     .classificationDetails &&
                                     this.state.classificationDetails.map(
                                       classification =>{
                                         if(this.state.selectedOfferedCategory!=null && (classification.classification_category_id==this.state.selectedOfferedCategory)){
                                           flag=1;
                                           return(
                                             <button
                                               type="button"
                                               className={
                                                 "btn pl-3 pr-3 calendar__edit-partipants__top-buttons r-subheading-4 r-text--grey-4  " +
                                                 (this.state.offerSection ==
                                                 classification.classification_category_id
                                                   ? "active"
                                                   : null)
                                               }
                                               onClick={ () =>
                                                  this.setState(
                                                   {
                                                     offerSection:
                                                       classification.classification_category_id,
                                                     selectedClassification:
                                                       this.state
                                                         .selectedClassification ==
                                                       classification.classification_category_id
                                                         ? this.state
                                                             .selectedClassification
                                                         : classification.classification_category_id,
                                                     ruleSelectedClassification:
                                                       classification.name
                                                   }
                                                 )
                                               }
                                               name={
                                                 classification.name
                                               }
                                             >
                                               {classification.name}
                                             </button>
                                           )
                                         }

                                       }
                                     )}
                                 </Col>
                               </Row>
                               <Row className={flag==0?"":"d-none"}>
                                 <Col>
                                   <span className="r-text--red-1">*No Rules Defined</span>
                                 </Col>
                               </Row>
                               <hr color="black" class="m-0" />
                               {this.state.selectedClassification &&  this.state
                                 .ruleSelectedClassification !=
                               null ? (
                                 <Row>
                                   <Col className="d-flex mt-3">
                                     <div className={"form-check-inline "+this.state.classificationcategory && this.state.offerSection ?"" : "d-none"} >
                                       <input
                                         type="radio"
                                         name="classificationcategoryNew"
                                         onClick={() =>
                                           this.setState({
                                             categoryValue: 2
                                           })
                                         }
                                         value={
                                           this.state
                                             .classificationcategory
                                         }
                                         checked={
                                           this.state
                                             .categoryValue == 2
                                         }
                                       />{" "}
                                       <label class="form-check-label ml-1">
                                         {" "}
                                         The same{" "}
                                         {this.state
                                           .ruleSelectedClassification &&
                                           this.state
                                             .ruleSelectedClassification}{" "}
                                         as the offer{" "}
                                       </label>
                                     </div>
                                     <div class="form-check-inline ml-3">
                                       <input
                                         type="radio"
                                         name="classificationcategoryNew"
                                         onClick={() =>
                                           this.setState({
                                             categoryValue: 1
                                           })
                                         }
                                         value={
                                           this.state
                                             .classificationcategory
                                         }
                                         checked={
                                           this.state
                                             .categoryValue == 1
                                         }
                                       />{" "}
                                       <label class="form-check-label ml-1">
                                         {" "}
                                         Each{" "}
                                         {this.state
                                           .ruleSelectedClassification &&
                                           this.state
                                             .ruleSelectedClassification}{" "}
                                       </label>
                                     </div>
                                   </Col>
                                 </Row>
                               ) : null}
                               {this.state.selectedClassification &&
                                 <Collapse
                                   isOpen={
                                     this.state.categoryValue == 1
                                   }
                                   className="m-3"
                                 >
                                   {this.state
                                     .classificationDetails &&
                                     this.state.classificationDetails.map(
                                       classification => {
                                         if (
                                           classification.classification_category_id ==
                                           this.state
                                             .selectedClassification
                                         ) {
                                           return (
                                             classification.classification_list &&
                                             classification.classification_list.map(
                                               category => {if(  this.state
                                                   .categoryMaxCount[
                                                   category
                                                     .classification_id
                                                 ]!=null ){
                                                   classificationFlag=1;
                                                 return(
                                                   <Row>
                                                     <Col xs="6">
                                                       <span className="r-subheading-4">
                                                         {" "}
                                                         {
                                                           category.name
                                                         }{" "}
                                                       </span>
                                                     </Col>
                                                     <Col
                                                       xs="6"
                                                       className="mb-2"
                                                     >
                                                       <InputField
                                                         onChange={
                                                           this
                                                             .handleFieldChange
                                                         }
                                                         name={
                                                           category.classification_id
                                                         }
                                                         value={
                                                           this.state
                                                             .categoryMaxCount[
                                                             category
                                                               .classification_id
                                                           ]
                                                         }
                                                         className="w-100"
                                                         placeholder="Count"
                                                       />
                                                     </Col>
                                                   </Row>
                                                 )
                                               }}
                                             )
                                           );
                                         }

                                       }
                                     )

                                   }
                                   {classificationFlag==0?
                                       <span className="r-text--red-1">*No Rules Defined</span>:null

                                   }
                                 </Collapse>}
                               <Collapse
                                 isOpen={
                                   this.state.categoryValue == 2
                                 }
                                 className="m-3"
                               >
                                 {(this.state.offerSection && this.state
                                   .categoryOverallCount[
                                   this.state
                                     .offerSection
                                 ]!=null) &&
                                   <Row>
                                     <Col>
                                       <InputField
                                         onChange={
                                           this.handleItselfCount
                                         }
                                         name={
                                           this.state
                                             .offerSection
                                         }
                                         value={
                                           this.state
                                             .categoryOverallCount[
                                             this.state
                                               .offerSection
                                           ]
                                         }
                                         className="w-100"
                                         placeholder="Count"
                                       />
                                     </Col>
                                   </Row>
                                 }
                                 {Object.keys(this.state
                                   .categoryOverallCount).length==0 && flag!=0?<span className="r-text--red-1">*No Rules Defined</span> : null}
                               </Collapse>
                             </Col>
                           </Row>
                         </ModalBody>
                         <ModalFooter>
                           <Button
                             className="pr-4 pl-4  r-text--light border-0 r-subheading-4"
                             color="dark"
                             onClick={() =>
                               this.editMatrixProcessRule(
                                 this.state.ruleList
                               )
                             }
                           >
                             Save
                           </Button>
                           <Button
                             onClick={() =>
                               this.cancelMultipleOfferEdit()
                             }
                             className="ml-3 pr-4 pl-4 r-bg--grey-2 r-text--grey-4 border-0 r-subheading-4"
                           >
                             Cancel
                           </Button>
                         </ModalFooter>
                       </Collapse>
                     </td>
                   </tr>
                 ) : null}
               </Aux>
             ))}
         </tbody>
       </table>
     </ModalBody>
     <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
     </Container>
   )
 }
}
const mapStateToProps = state => ({
  ...state
});
export default connect(mapStateToProps,null)(MultipleOfferSystem)
