import React,{Component} from 'react';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container} from 'reactstrap';
import {Button, ButtonGroup} from 'reactstrap';
import moment from 'moment';
import  {InputField} from '../../global/Forms';
import  {Switch} from '../../global/Forms/ToggleSwitch';
import {HorizontalLine} from '../../global/horizontal_line';
import {Collapse} from 'reactstrap';
import Aux from '../../global/AuxComp';
import { ListGroup, ListGroupItem } from 'reactstrap';
import {Card, CardTitle, CardBody} from 'reactstrap';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import DatePicker from 'react-dates/esm/components/SingleDatePicker';
import TimePicker from 'rc-time-picker';
import {Errorbar} from '../../global/errorbar';
import 'rc-time-picker/assets/index.css';
import {YearPicker, MonthPicker , DayPicker} from 'react-dropdown-date';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {CONSTANTS} from '../../../constants';


class PrimarySection extends Component{
  constructor(){
    super();
    this.state={
      value:1,
      isOn:false,
      subject: null,
      body : null,
      contacts : null,
      contactList : [],
      year : null,
      month : null,
      day: null,
      reminderValue:null,
      reminderTriggerValue : 1,
      remindDropdownValue : 1,
      remindTime: null,
      remindDate : null,
      ptMember : null,
      ptMemberList : [],
      finalList : null,
      ptMemberSelected : [],
      deletePtList : [],
      errorbar: false,
      message:'',
    }
  }

  closebar = () =>{
      this.setState({errorbar: false});
  }

  onChangeSubject = async(e)=>{
    await this.setState({
      subject: e.target.value
    })
  }
  onChangeBody = async (e)=>{
    await this.setState({
      body: e.target.value
    })
  }
  handleReminderTrigger = async (e)=>{
    await this.setState({
      reminderTriggerValue : e.target.value
    })
  }
  setReminderValue = async (value)=>{
      this.setState({
        reminderValue : value
      })
  }

  OnChangeStartTime = (e)=>{
    if(this.state.day !=null && this.state.month!=null && this.state.year!=null){
      this.formatTime();
      let time = moment(e).format("HH:mm")+":"+"00";
      this.props.setRemindTime(time);
      this.setState({
        remindTime : moment(e).format("HH:mm:ss")
      })
    }
  }

  formatTime = ()=>{
    let {day,month,year,remindTime} = this.state;
    let m = month;
    if(m/10<1){
      m = "0"+m;
    }
    let d= day;
    if(d/10<1){
      d="0"+d
    }

    let remindDate = (year+"-"+m+"-"+d);
    this.props.setRemindDate(remindDate);
  }
  onClickHandler = (value)=>{
    this.setState({
      value
    })
  }
  handleToggle = (isOn)=>{
    this.setState({
      isOn: !this.state.isOn
    })
  }

  getEventAttendees = async ()=>{
    if(this.props.eventId){
      let ptList = [];
      let params = {
        "event_id" : this.props.eventId
      }
      let attendeeList = [];
      try{
        let getEventAttendees = await Auth.Batch.getEvent(params);
        let attendeeList = getEventAttendees.data[0].attendee_details.filter(attendee=>attendee.attendee_id!=this.props.user_id)
        if(attendeeList.length!=0){
          let filterAttendeeList = attendeeList.sort((a,b) => a.attendee_id - b.attendee_id);

            for(let i = 0 ; i<filterAttendeeList.length-1 ; i++){
            if(filterAttendeeList[i].attendee_id == filterAttendeeList[i+1].attendee_id){
              filterAttendeeList.splice(i,1);
              i=i-1;
            }
          }
          try{
            let params = {
              campus_id:  this.props.college_id,
              role_type: CONSTANTS.ROLE_TYPES_ID["PT"]
            }

            let ptMembers = await Auth.Spoc.getUsers(params);
            if(ptMembers.data.length != null){

              for(let i=0;i<attendeeList.length;i++){
                ptMembers.data && ptMembers.data.map(member=>{
                  if(member.user_id==attendeeList[i].attendee_id ){
                    ptList.push(member);
                  }
                })
              }
              await this.setState({
                ptMemberList : [...ptList],
                ptMemberSelected : [...ptList]
              })
            }


          }
          catch(e){
            this.setState({errorbar: true});
            if(e.response && e.response.data){
              this.setState({message: e.response.data.error.toString()});
            }
            else{
              this.setState({message: 'Something went wrong! Please try again'});
            }
          }
        }
      }
      catch(e){
        this.setState({errorbar: true});
        if(e.response && e.response.data){
          this.setState({message: e.response.data.error.toString()});
        }
        else{
          this.setState({message: 'Something went wrong! Please try again'});
        }
      }
    }
  }

  getCampusPtMembers = async ()=>{
    try{
      let params = {
        campus_id:  this.props.college_id,
        role_type: CONSTANTS.ROLE_TYPES_ID["PT"]
      }
      let ptMembers = await Auth.Spoc.getUsers(params);
      let initialList =[];
      if(ptMembers.data.length!=0){
        for(let i=0;i<ptMembers.data.length;i++){
          if(ptMembers.data[i].user_id !=this.props.user_id ){
            initialList.push(ptMembers.data[i]);
          }
        }
      }
      this.setState({
        ptMember: initialList
      })
    }
    catch(e){
      this.setState({errorbar: true});
      if(e.response && e.response.data){
        this.setState({message: e.response.data.error.toString()});
      }
      else{
        this.setState({message: 'Something went wrong! Please try again'});
      }
    }
  }

  addPtMember = async(ptMember)=>{
    let {ptMemberList , ptMemberSelected , deletePtList} = this.state;
    let flag=0;
    for(let i=0;i<ptMemberList.length;i++){
      if(ptMember.user_id == ptMemberList[i].user_id)
      {
        flag=1;
      }
    }
    if(flag==0){
      ptMemberList.push(ptMember);
    }
    await this.props.setPtMemberList(ptMemberList,  ptMemberSelected, deletePtList);
    await   this.props.setTotalPtMembers(ptMemberList.length);
    await this.setState({
      ptMemberList
    })
  }

  deleteptMember = async (ptMember)=>{

    let list = [];
    let {ptMemberList, ptMemberSelected, deletePtList} = this.state;
    for(let i =0;i<ptMemberList.length;i++){
      if(ptMemberList[i].user_id!=ptMember.user_id){
        list.push(ptMemberList[i])
      }
    }

    if(ptMemberSelected.length!=0){
      for(let i=0;i<ptMemberSelected.length ; i++){
        if(ptMember.user_id==ptMemberSelected[i].user_id){
          deletePtList.push(ptMember);
        }
      }
    }
    this.props.setPtMemberList(list, ptMemberSelected, deletePtList);
      this.props.setTotalPtMembers(list.length);
    await this.setState({
      ptMemberList : list,
      deletePtList
    })
  }

  async componentDidMount(){
    await this.getEventAttendees()
    this.getCampusPtMembers();

  }

  render(){
    return(
      <Aux>
      <Row className="m-1 mt-4 pt-1">
      <Col xs="12" onClick={()=>this.onClickHandler(1)}>
      <span className="r-heading-4">1. Add PT members</span>
      </Col>

      </Row>
      <Collapse  isOpen={this.state.value==1} className="mb-2">
      <Row>
      <Col xs="6" className="mr-0 pr-0">
      <Card className="border-0 r-bg--grey-2 m-1 ml-3 mr-0 pr-0 p-2 mb-4 calendar__primary-card">
      <CardTitle>
      <span className="r-text--dark r-subheading-3">All members</span>
      </CardTitle>
      <CardBody className="p-2 mt-1">
      {this.state.ptMember!=null?this.state.ptMember.map(ptMember=>{
        return(
          <Row className="m-1 mb-2 p-1">
          <Button className="calendar__pt-member-button r-text--grey-4 w-100 r-subheading-4" onClick={()=>this.addPtMember(ptMember)}><Row><Col xs="10" className="m-1" align="start"><span>{ptMember.userDetail.first_name}{" "}{ptMember.userDetail.last_name}{" "} (Placement Team) </span></Col><Button color="link"><Col xs="2" className="p-0 m-0" align="end"><i className="fi flaticon-plus-2"/></Col></Button></Row></Button>
          </Row>
        )
      }):null}

      </CardBody>
      </Card>
      </Col>
      <Col xs="6" className="pl-0 ml-0">
      <Card className="border-0 r-bg--grey-2 m-1 ml-3 p-2 mb-4 calendar__primary-card">
      <CardTitle>

      <Col  className="mb-2"><span className=" r-text--dark r-subheading-3"> Selected </span> <span className="r-text--green-1 r-subheading-4 ml-2">{this.state.ptMemberList.length} Participants selected </span></Col>

      </CardTitle>
      <CardBody className="pt-0">
      {this.state.ptMemberList.length!=0?this.state.ptMemberList.map(ptMember=>{
        return(
          <Row className="m-1 mb-2 p-1">
          <Button className="calendar__pt-member-button r-text--grey-4 w-100 r-subheading-4"><Row><Col xs="10" className="m-1 pr-0" align="start"><span>{ptMember.userDetail.first_name}{" "}{ptMember.userDetail.last_name}{" "} (Placement Team)</span></Col><Col xs="1" className="p-0 m-0 border-0 mt-1"  align="end" onClick={()=>this.deleteptMember(ptMember)}><i className="fi flaticon-plus-3"/></Col></Row></Button>
          </Row>
        )
      }):null
    }
      </CardBody>
      </Card>
      </Col>
      </Row>
      </Collapse>
      <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      </Aux>
    )
  }
}

export default PrimarySection;
