import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Select, SelectList } from '../Forms/Select';
import { Nav } from './Nav';
import Aux from '../AuxComp';
import { Auth } from '../../../actions/Auth';

/**
 * @author Akshay Arora
 * @description Global header component for the app
 * @class Header
 * @extends {Component}
 */
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentWillMount = () => {

  }

  /**
   * @description gets the initial state of the component
   * @returns {any}
   * @memberOf Header
   */
  getInitialState = () => {
    return {}
  }

  logout = async() => {
    this.props.setLoading(true);
    try {
      await Auth.User.logout()
      this.props.setUser(null);
    } catch(e) {
      console.log(e);
    }
    this.props.setLoading(false);
  }

  render = () => {
    const { userDetail } = this.props;
    return (
      <Aux>
        <Nav setUser={this.props.setUser} setLoading={this.props.setLoading} userDetail={userDetail} logout={this.logout} setRole={this.props.setRole} />
      </Aux>
    )
  }
}

export default Header;
