import React, { Component } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Aux from './global/AuxComp';
import Button from './global/Button';

class Policies extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenPolicies : false
    }
  }


  render() {
    return (
      <Aux>
        <ul className="r-login__links text-secondary r-text--sm pt-5 mt-5 ml-3" style={{position: "absolute", bottom: 5,}}>
          <li><Button color="link"><a href="https://www.reculta.com/company" target="_blank" rel="noopener noreferrer">About</a></Button></li>
          <li><Button color="link"><a href="https://www.reculta.com/contact" target="_blank" rel="noopener noreferrer">Contact Us</a></Button></li>
          <li><Button color="link" onClick={() => this.setState({isOpenPolicies: "https://campus.frontend.s3.ap-south-1.amazonaws.com/policies/Terms+and+Conditions.pdf"})}>Terms &amp; Conditions</Button></li>
          <li><Button color="link" onClick={() => this.setState({isOpenPolicies: "https://campus.frontend.s3.ap-south-1.amazonaws.com/policies/Privacy+Policy.pdf"})}>Privacy Policy</Button></li>
          <li><Button color="link" onClick={() => this.setState({isOpenPolicies: "https://campus.frontend.s3.ap-south-1.amazonaws.com/policies/Pricing+Policy.pdf"})}>Pricing</Button></li>
          <li><Button color="link" onClick={() => this.setState({isOpenPolicies: "https://campus.frontend.s3.ap-south-1.amazonaws.com/policies/Refund+Policy.pdf"})}>Cancellation/Refund Policy</Button></li>
        </ul>
        <Modal centered={true} isOpen={!!this.state.isOpenPolicies} className="modal-lg">
          <ModalBody>
            <iframe title= "Policies" src={this.state.isOpenPolicies + "#toolbar=0&navpanes=0"} className="w-100" height="500"></iframe>
          </ModalBody>
          <ModalFooter>
          <Button color="outline-dark-v" square={true} onClick={() => this.setState({isOpenPolicies: false})}>Close</Button>
          </ModalFooter>
        </Modal>
      </Aux>
    )
  }
}

export default Policies
