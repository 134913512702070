import React, { Component } from 'react';
import {Row, Col, Input , Modal, ModalBody, Spinner} from 'reactstrap';
import { Auth } from '../../../../actions/Auth';
import { InputField } from '../../../global/Forms';
import { Select } from '../../../global/Forms/Select';
import {Errorbar} from '../../../global/errorbar';
import Button from '../../../global/Button';
import store from '../../../../store';
import moment from 'moment';
import { CONSTANTS } from '../../../../constants';

 class AddtoProcess extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      studentinfo:[],
      process: null,
      errorbar: false,
      message:'',
      successmsg: '',
      processList: [],
      addProcessModal: false,
      setloading:false,
    };
  }

  componentDidMount (){
    this.getProceesList();
  }


getProceesList = async () =>{
    try {
      let processResp = await Auth.Processes.getProcesses( store.getState().role.instituteDetail.campus_id);
      this.setState({
        processList: processResp.data,
      })
    } catch (e) {
      console.log(e)
    }
}


  addProcess = async (e) => {
    e.preventDefault();
    let { process_name, process_type } = e.target;
    let formData = {
      process_name: process_name.value,
      process_type: process_type.value,
      campus_id: store.getState().role.instituteDetail.campus_id
    }
    Auth.Processes.addProcess(formData).then(resp => {
      if (resp.data[0]) {
        let { processList } = this.state;
        processList.push(resp.data[0])
        this.setState({
          processList,
          addProcessModal: false,
          applications: [],
          companies: [],
          processStudent : []
        })
        this.setState({addProcessModal: false})
      }
    }).catch(e => {
      console.log(e)
    })
  }


  closebar = () =>{
      this.setState({errorbar: false});
  }


  onChange = e => {
    if(e.target.value==="sttoDrive"){
     this.setState({addProcessModal: true})
    }
    else {this.setState({
            [e.target.name]: e.target.value,
        })}
  }

  addtoprocess = async(e) =>{
    const {studentinfo, process,errorbar,message} = this.state;
    if(process === null){
          this.setState({errorbar:true, message:"Please select a placement drive"})
          return;}
    this.setState({setloading: true});
    let formData = {
      "process_id": process,
      "student_id":this.props.studentsIDs
    }
    try {
      let resp = await Auth.Processes.mapStudentsToProcess(formData);
      this.setState({setloading: false});
      this.props.toggleAddProcess();
    } catch (e) { 
      this.setState({setloading: false});
      this.setState({errorbar: true});
      this.setState({message: e.response.data.error.toString()});
    }
  }


  render() {
    let { isOpenAddProcess,} = this.props;
    let {   processList, addProcessModal} = this.state;
    return (
      <Modal  zIndex="99999" className="modal-dialog-centered" isOpen={isOpenAddProcess} toggle={this.props.toggleAddProcess} >
        <ModalBody>
            <h1 className="r-subheading-3 border-bottom pb-3">Add to  Placement Drive</h1>
            <span>You have selected {this.props.studentsIDs.length} students to add them in  Placement Drive</span>
            <Row className="mt-4">
              <Col xs={6}>
                <h1 className="r-subheading-4">Select Placement Drive Name</h1>
                <Select name="process" onChange={this.onChange}>
                  <option value="null" >Select  Placement Drive</option>
                  <option  value="sttoDrive" style={{color:"blue"}}> + Add Placement Drive</option>
                  {processList.map((child, index) => (<option key={index} value={child.process_id} >{child.process_name}</option>))}
                </Select>
              </Col>
            </Row>
              <span className="green-selected">{this.state.successmsg}</span>
             <Row  className="justify-content-end align-items-center py-5  mx-0 " style={{textAlign:"center",}}>
                    {this.state.setloading? <div className="center-xy"><Spinner color="dark"/></div>:null}
                  <Button onClick={this.addtoprocess} className="font-weight-normal mx-3" color="dark r-btn--no-radius"> Add to Placement Drive</Button>
                  <Button onClick={this.props.toggleAddProcess} className="font-weight-normal mx-3 px-5" color="outline-dark-v  r-btn--no-radius">Cancel</Button>
             </Row>
        </ModalBody>
        <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
        <Modal zIndex="99999" isOpen={addProcessModal} toggle={() => this.setState({ addProcessModal: false})}>
          <ModalBody>
            <span className="modal__title r-heading-6">Add Placement Drive</span>
            <form className="mt-3" onSubmit={this.addProcess}>
              <label className="r-subheading-4">Placement Drive Name</label>
              <InputField defaultValue={""} required name="process_name" className="w-100" type="text" placeholder="Enter Placement Drive Name" />
              <label className="mt-2 r-subheading-4">Placement Drive Type</label>
              <Select name="process_type" defaultValue={""}>
                {
                  Object.keys(CONSTANTS.PROCESS_TYPES).map((processType) =>
                    <option key={processType} value={processType}>{CONSTANTS.PROCESS_TYPES[processType]}</option>
                  )
                }
              </Select>
              <div className="text-right mt-3">
                <Button type="submit" color="dark" className="r-text--sm r-btn--no-radius">Save</Button>
              </div>
            </form>
          </ModalBody>
        </Modal>

      </Modal>
    )
  }
}

export default AddtoProcess;
