import React from 'react';
import { Row, Col, Container, Modal, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane, ModalBody, ModalFooter, Fade, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../../actions/Auth';
import { setUser, setLoading } from '../../../../actions/action-creators';
import { uploadFile } from '../../../../actions';
import Button from '../../../global/Button';
import { Errorbar } from '../../../global/errorbar';
import { Select, SelectList } from '../../../global/Forms/Select'
import { InputField } from '../../../global/Forms';
import { AddSuggestion } from '../../../global/Forms/AddSuggestion';
import store from '../../../../store';
import classnames from 'classnames';
import AutosuggestComp from '../../../global/AutosuggestComp';

class AddStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: {
        getEducationBoards: [],
        getDepartments: [],
        getInstitutes: [],
        getSchools: [],
        getDegrees: []
      },
      batchid: "",
      errorbar: false,
      message: '',
      batchName: 'Select batch',
      activeTab: '1',
      course: '',
      dept: '',
      degree: '',
      group: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      rollNo: '',
      departmentValue: '',
      degreeValue: "",
      departmentid: " ",
      campusDegreeID: "",
      degreeid: '',
      campusList: [],
      campusID: "",
      campusName: "Select Campus ",
      collegeName: "",
      dep: '',
      reqField: false,
      successBar: false,
      csvFile: '',
      degreeidList: [],
      batchList: this.props.batchdata,
      departmentLists: [],
      studentFilters: {
        batches: [],
        departments: []
      }
    };
  }


  componentWillReceiveProps(props) {
    this.setState({
      batchList: props.batchdata
    });
  }

  onChange = async(e) => {
    let { batchList } = this.state;
    if (e.target.value === "sttoBatch") {
      this.props.history.push("/pt/students/batches")
    }
    else if (e.target.name === "batchid") {
      this.setState({ [e.target.name]: e.target.value })
      let obj = batchList.find(o => o.batch_id === parseInt(e.target.value));
      let res = await Auth.General.getDegreesCampusDepartment(this.props.role.instituteDetail.campus_id, obj.campus_degree_id);
      this.setState({ departmentLists: res.data[0].departments });
    }
    else {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }


  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  onClosesuccessBar = () => {
    this.setState({ successBar: !this.state.successBar });
    this.props.toggle();
  }


  componentDidMount() {
    this._init();
  }


  _init = async () => {
    try {
      let res = await Auth.General.getDegreesCampus();
      this.setState({ degreeidList: res.data })
    } catch (e) {
      console.log(e)
    }
  }




  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.setLoading(true)
    var stBody = {
      "batch_id": this.state.batchid,
      "email": this.state.email,
      "degree_department_id": this.state.departmentid,
      "first_name": this.state.firstName,
      "middle_name": this.state.middleName,
      "last_name": this.state.lastName,
      "roll_no": this.state.rollNo,
    }

    try {
      let resp = await Auth.PtStudents.createStudent(stBody);
      this.props.getBatchs();
      this.props.toggle();
      this.props.setLoading(false);
      this.setState({
        batchName: 'select',
        batchid: '',
        degree: '',
        group: '',
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        rollNo: '',
        departmentValue: '',
        degreeValue: "",
        departmentid: '',
        degreeid: '',

      })
    } catch (e) {
      this.props.setLoading(false);
      this.setState({ message: e.response.data.error.toString() });
      this.setState({ errorbar: true });
    }
  }

  handleUploadCsv = (e) =>{
        this.setState({
             csvFile: e.target.files[0]
         });
    
  }

  handleSubmitCSV = async (e) => {
    e.preventDefault();
    if(this.state.csvFile === ''){
      this.setState({ errorbar: true });
      this.setState({ message: "Please choose a csv file" });
      return;
    }
    this.setState({ setloading: true });
    try {

      let newFile = await uploadFile(this.state.csvFile, "student_create_excell/"+ store.getState().user.id);
      console.log(newFile);
      let resp = await Auth.PtStudents.createStudentCsv(this.state.batchid, this.state.departmentid, newFile.file_url);
      this.setState({ setloading: false });
      this.setState({ successBar: true });
      this.setState({
        batchName: 'select',
        batchid: '',
        degree: '',
        csvFile: '',
        departmentValue: '',
        degreeValue: "",
        departmentid: '',
        degreeid: '',

      })
    } catch (e) {
      this.setState({ setloading: false });
      this.setState({ message: e.response.data.error.toString() });
      this.setState({ errorbar: true });
    }
  }

  render() {
    const { departmentLists, batchList, group, csvFile, dep, batchName, batchid, campusID, suggestions, departmentid, degreeid, collegeName, departmentValue, degreeValue, lastName, firstName,middleName, degree, email, rollNo } = this.state,
      {settingsPermission} = this.props;
    const autoSuggestProps = {
      onChange: this.onChange,
      className: "w-100 r-inputfield",
      required: "true"
    };
    return (
      <Modal zIndex="99999" isOpen={this.props.modal} toggle={this.props.toggle} size="xl" style={{ marginTop: "50px" }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
              style={{ cursor: "pointer" }}
            >
              Add Student
                      </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
              style={{ cursor: "pointer" }}
            >
              Import from CSV
                      </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <form onSubmit={this.handleSubmit}>
              <Row className="align-items-center mx-0">
                <Col xs="6" className="py-2">
                  Batch
                    <select value={batchid} name="batchid" className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} onChange={this.onChange} required>
                    <option value="" >Select Batch</option>
                    {settingsPermission && <option value="sttoBatch" style={{ color: "blue" }}> + Add Batch</option>}
                    {batchList.map(data => {
                      return <option key={data.batch_id} value={data.batch_id}>{data.batch_name}</option>
                    })}
                  </select>
                </Col>
                <Col xs="6" className="py-2">
                  Department
                                   {(this.state.batchid !== null) ? <select value={departmentid} name="departmentid" className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} onChange={this.onChange} required>
                    <option value="" >Select Department</option>
                    {departmentLists.map(data => {
                      return <option key={data.degree_department_id} value={data.degree_department_id}>{data.department_name}</option>
                    })}
                  </select> : <select className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} required></select>}
                </Col>
              </Row>
              <Row className="align-items-center mx-0">
                <Col xs="3" className="py-2">
                  First Name
                                <InputField className="r-nav__search" placeholder="First Name..." type="text" name="firstName" value={firstName} onChange={this.onChange} required />
                </Col>
                <Col xs="3" className="py-2">
                  Middle Name
                                <InputField className="r-nav__search" placeholder="Middle Name..." type="text" name="middleName" value={middleName} onChange={this.onChange} />
                </Col>
                <Col xs="3" className="py-2">
                  Last Name
                                <InputField className="r-nav__search" placeholder="Last Name..." type="text" name="lastName" value={lastName} onChange={this.onChange} />
                </Col>
                <Col xs="3" className="py-2">
                  Email
                                <InputField className="r-nav__search" placeholder="Email..." type="text" name="email" value={email} onChange={this.onChange} required />
                </Col>
                <Col xs="3" className="py-2">
                  Roll No
                                <InputField className="r-nav__search" placeholder="Roll No..." type="text" name="rollNo" value={rollNo} onChange={this.onChange} required />
                </Col>
              </Row>
              <Row className="justify-content-end align-items-center  mx-0  my-2 " style={{ textAlign: "center", padding: "50px 0" }}>
                <Button type="submit" className="font-weight-normal mx-3  px-5" color="dark"> Add</Button>
                <Button type="button" onClick={this.props.toggle} className="font-weight-normal mx-3  px-5" color="outline-dark-v">Cancel</Button>
              </Row>
            </form>
          </TabPane>
          <TabPane tabId="2">
            <form onSubmit={this.handleSubmitCSV}>
              <Row className="align-items-center mx-0">
                <Col xs="6" className="py-2">
                  Batch
                <select value={batchid} name="batchid" className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} onChange={this.onChange} required>
                    <option value="" >Select Batch</option>
                    {settingsPermission && <option value="sttoBatch" style={{ color: "blue" }}>Add Batch</option>}
                    {batchList.map(data => {
                      return <option key={data.batch_id} value={data.batch_id}>{data.batch_name}</option>
                    })}
                  </select>
                </Col>
                <Col xs="6" className="py-2">
                  Department
                                   {(this.state.batchid !== null) ? <select value={departmentid} name="departmentid" className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} onChange={this.onChange} required>
                    <option value="" >Select Department</option>
                    {departmentLists.map(data => {
                      return <option key={data.degree_department_id} value={data.degree_department_id}>{data.department_name}</option>
                    })}
                  </select> : <select className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} required></select>}
                </Col>
              </Row>
              <Row className="align-items-center  mx-0">
                <Col xs="auto" className="py-2">
                  <h2>Upload CSV File</h2>
                  <Col xs="auto" className="pl-0">
                      <InputField className="r-nav__search" type="file" placeholder="Search" name="csvFile" onChange={this.handleUploadCsv} />
                      
                  </Col>
                  <Col>
                    <a href="/AddStudent.csv">Download csv template </a>
                  </Col>
                </Col>
              </Row>
              <Row className="justify-content-end align-items-center  mx-0  my-2 " style={{ textAlign: "center", padding: "50px 0" }}>
                <Button type="submit" className="font-weight-normal mx-3  px-5" color="dark"> Add</Button>
                <Button type="button" onClick={this.props.toggle} className="font-weight-normal mx-3  px-5" color="outline-dark-v">Cancel</Button>
              </Row>
            </form>
          </TabPane>
        </TabContent>
        {this.state.setloading ? <div className="center-xy"><Spinner color="dark" /></div> : null}
        <Fade in={this.state.successBar} unmountOnExit={true} className="success-bar">
          <p className="text-center py-4 r-subheading-3 mb-0 error-line d-block "><i className="fi flaticon-alert"></i>Students upload in progress.You will receive an email with details when it will done </p>
          <Button onClick={this.onClosesuccessBar} className="close-bn"><i className="fi flaticon-close" ></i></Button>
        </Fade>
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
      </Modal>
    );
  }
}



const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoading: (show) => dispatch(setLoading(show)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddStudent));