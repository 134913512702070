import React from 'react';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';
import Switch from 'react-router-dom/Switch';
import Profile from '../views/ptTeam/Profile/Profile';
import Batch from '../views/ptTeam/Profile/Batch';
import Process from '../views/ptTeam/process';
import Companies from '../views/ptTeam/companies';
import Preferences from '../views/ptTeam/Profile/Preferences';
import Feed from '../views/ptTeam/Feed/Feed';
import Groups from '../views/ptTeam/Profile/Groups';
import Snapshot from '../views/ptTeam/BatchSnapshot/Snapshot';
import StudentRecruitment from '../views/student/recruitment';
import { ManageVerifiers } from '../views/ptTeam/process/manage-verifiers';
import PTPerformance from '../views/spoc/PTPerformance';
import StudentReport from '../views/spoc/StudentReport';
import ProcessPerformance from '../views/spoc/ProcessPerformance';
import AttendanceReport from '../views/spoc/attendance-report.js';
import MeetingReport from '../views/spoc/meeting-report';
import CompanyReport from '../views/spoc/company-report';
import Offer from '../views/ptTeam/offer';
import Calendar from '../views/calendar/calendar';
import Event from '../views/calendar/event';
import Meeting from '../views/calendar/meeting';
import Dashboard from '../views/ptTeam/dashboard';
import FAQ from '../views/ptTeam/faq/';
import Settings from '../views/ptTeam/settings';
import EmailConfiguration from '../views/ptTeam/email_configuration';
import Attendance from '../views/ptTeam/attendance';
import ExcelDownload from '../views/ptTeam/excel_download';
import EmailCampaignOverview from '../views/ptTeam/companies/email_campaign_overview';
import EmailCampaign from '../views/ptTeam/companies/email_campaign';
import CollegeProfile from '../views/ptTeam/college/college-profile';
import Footer from '../global/Footer';
import Aux from '../global/AuxComp';
export const Ptteam = ({ permission, location }) => {
  let enableSettings = permission.settingsTab && permission.settingsTab.permission_access.read,
    enableCalendar = permission.calendarTab && permission.calendarTab.permission_access.read;
  return (
    <div style={{paddingBottom:50}}>

    <Switch>
      <Route exact path="/pt/college" component={CollegeProfile} />
      <Route exact path="/pt/college/:tab" component={CollegeProfile} />
      {enableCalendar && <Route exact path="/calendar" component={Calendar} />}
      {enableCalendar && <Route exact path="/calendar/events" component={Event} />}
      {enableCalendar && <Route exact path="/calendar/meetings" component={Meeting} />}
      {enableCalendar && <Route exact path="/calendar/meetings/:meeting_id/edit" component={Meeting} />}
      {enableCalendar && <Route exact path="/calendar/events/:event_id/edit" component={Event} />}
      <Route exact path="/pt/dashboard" component={Dashboard} />
      {enableSettings && <Route exact path="/pt/settings" component={Settings} />}
      {enableSettings && <Route exact path="/pt/configurations/email" component={EmailConfiguration} />}
      {enableSettings && <Route exact path="/pt/Configurations/excel" component={ExcelDownload} />}
      {enableSettings && <Route exact path="/pt/attendance" component={Attendance} />}
      <Route exact path={"/pt/emailCampaign"} component={EmailCampaignOverview} />
      <Route exact path="/pt/students/student" component={Profile} />
      <Route exact path="/pt/students/feed" component={Feed} />
      <Route exact path="/pt/students/batch" component={Snapshot} />
      <Route exact path="/pt/students/batches" component={Batch} />
      <Route exact path="/pt/students/groups" component={Groups} />
      <Route exact path="/faq" component={FAQ} />
      <Route exact path="/pt/companies" component={Companies} />
      <Route exact path="/pt/companies/:campus_company_id" component={Companies} />
      <Route exact path="/pt/processes" component={Process} />
      <Route exact path="/pt/processes/:process_id/detail" component={Process} />
      <Route exact path="/pt/processes/:process_id/offers" component={Offer} />
      <Route exact path="/pt/processes/:process_id/verifiers" component={ManageVerifiers} />
      <Route exact path="/pt/students/preferences" component={Preferences} />
      <Route exact path="/pt/students/recruitments/:student_id/:tab" component={StudentRecruitment} />
      <Route exact path="/pt/students/recruitments/:student_id/:tab/:profileTab" component={StudentRecruitment} />
      {(permission.reportPlacementTeamPerformance && permission.reportPlacementTeamPerformance.permission_access.read) &&
        <Switch>
          <Route exact path="/pt/performance/pt" component={PTPerformance} />
          <Route exact path="/pt/performance/student" component={StudentReport} />
          <Route exact path="/pt/performance/process" component={ProcessPerformance} />
          <Route exact path="/pt/performance/meeting-report" component={MeetingReport} />
          <Route exact path="/pt/performance/attendance-report" component={AttendanceReport} />
          <Route exact path="/pt/performance/company-report" component={CompanyReport} />
          <Route exact path="/pt/performance" render={() => <Redirect to="/pt/performance/process" />} />
          <Route render={() => <Redirect to="/pt/college" />} />
        </Switch>}
      <Route render={() => <Redirect to="/pt/college" />} />
    </Switch>
    {location.pathname.includes("pt/college") && <Footer />}
    </div>
  )
}
