import axios from "axios";
import { ENDPOINTS } from "../constants/endpoints";
import store from '../store';
import { setUser } from "./action-creators";

const UNAUTHORIZED = 401;
let Axios = axios.create();


Axios.interceptors.response.use(response => response, error => {
  if (error.response) {
    let { status } = error.response
    if (status === UNAUTHORIZED)
      store.dispatch(setUser(null))
  }
  return Promise.reject(error)
})
const config = {
  // withCredentials: true
  withCredentials: true
}
export const Auth = {
  User: {
    signUp: params => Axios.post(ENDPOINTS.USER.SIGNUP, params, config),
    login: (username, password) => Axios.post(ENDPOINTS.USER.LOGIN, {
      username,
      password
    }, config),
    getUser: () => Axios.get(ENDPOINTS.USER.GET_USER, config),
    logout: () => Axios.post(ENDPOINTS.USER.LOGOUT, {}, config),
    resetPassword: (password, newPassword, confirmPassword) => Axios.post(ENDPOINTS.USER.RESET_PASSWORD, {
      old_password: password,
      new_password: newPassword,
      confirm_password: confirmPassword
    }, config),
    forgotPassword: (params) => Axios.post(ENDPOINTS.USER.FORGOTPASSWORD, params, config),
    retrievePassword: (params) => Axios.post(ENDPOINTS.USER.RETRIEVEPASSWORD, params, config),
    updateProfile: (userInfo) => Axios.post(ENDPOINTS.USER.UPDATE_USER, { ...userInfo }, config),
    updateUserInfo: (userInfo) => Axios.post(ENDPOINTS.USER.UPDATE_USER, { ...userInfo }, config),
    uploadFile: (file, container) => Axios.post(ENDPOINTS.USER.UPLOAD_FILE, file, Object.assign({}, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        container
      }
    }, config)),
    getS3UploadURL: (params) => Axios.put(ENDPOINTS.USER.GET_FILE_URL, {}, { ...config, params }, config),
    uploadFileToS3: (signed_url, file, config = {}) => Axios.put(signed_url, file, config),
    getFileFromS3: (signed_url) => Axios.get(signed_url, { responseType: "blob" }),
    addFileToManager: (fileData) => Axios.post(ENDPOINTS.USER.ADD_TO_FILE_MANAGER, fileData, config),
    deleteFile: (id) => Axios.post(ENDPOINTS.USER.UPDATE_FILE, { id, status: 0 }, config),
    getFilesbyId: (file_id) => Axios.get(ENDPOINTS.USER.GET_FILES, Object.assign({ params: { file_id } }, config)),
    getFilesList: (fileIds) => {
      let params = new URLSearchParams();
      fileIds.forEach(file_id => {
        if (file_id)
          params.append("file_id", file_id)
      })
      return Axios.get(ENDPOINTS.USER.GET_FILES, Object.assign({ params }, config))
    },
    getFileUrl: (file_url) => Axios.get(ENDPOINTS.USER.GET_FILE_URL+"?file_url="+encodeURIComponent(file_url), Object.assign({ params: {} }, config)),
    getFileUrlList: (files) => {
      let fileUrls = files.map(({ file_url }) => file_url)
      return Axios.post(ENDPOINTS.USER.GET_FILE_URL, { file_url: fileUrls }, config)
    },
    getNotifications: () => Axios.get(ENDPOINTS.USER.GET_NOTIFICATIONS, config),
    updateNotification: (notification) => Axios.post(ENDPOINTS.USER.UPDATE_NOTIFICATION, {
      notification_id: notification.notification_id,
      reciever_id: [notification.reciever_id],
      notification_status: 1
    }, config),
    getRoleList: (params) => Axios.get(ENDPOINTS.USER.GET_ROLE_LIST, Object.assign({}, { params }, config)),
    addVerifier: (data) => Axios.post(ENDPOINTS.USER.ADD_VERIFIER, data, config),
    getConfiguration: () => Axios.get(ENDPOINTS.USER.GET_CONFIGURATION, config),
    createConfiguration: (data) => Axios.post(ENDPOINTS.USER.CREATE_CONFIGURATION, data, config),
    updateConfiguration: (data) => Axios.post(ENDPOINTS.USER.UPDATE_CONFIGURATION, data, config),
    emailTokenGenerate: (params) => Axios.post(ENDPOINTS.USER.EMAIL_TOKEN_GENERATE, params, config),
    emailTokenVerify: (params) => Axios.post(ENDPOINTS.USER.EMAIL_TOKEN_VERIFY, params, config)
  },
  Student: {
    getId: id => Axios.get(ENDPOINTS.STUDENT.GET_ID(id), config),
    getProfile: id => Axios.get(ENDPOINTS.STUDENT.GET_PROFILE(id), config),
    getPreferences: id => Axios.get(ENDPOINTS.STUDENT.GET_PREFERENCES(id), config),
    createPersonalDetail: personalDetails => Axios.post(ENDPOINTS.STUDENT.CREATE_PERSONAL_DETAIL, personalDetails, config),
    updatePersonalDetail: personalDetails => Axios.post(ENDPOINTS.STUDENT.UPDATE_PERSONAL_DETAIL, personalDetails, config),
    createAcademicDetail: academic => Axios.post(ENDPOINTS.STUDENT.CREATE_ACADEMIC_DETAIL, academic, config),
    updateAcademicDetail: academic => Axios.post(ENDPOINTS.STUDENT.UPDATE_ACADEMIC_DETAIL, academic, config),
    createProExpDetail: pro => Axios.post(ENDPOINTS.STUDENT.CREATE_PROEXP_DETAIL, pro, config),
    updateProExpDetail: pro => Axios.post(ENDPOINTS.STUDENT.UPDATE_PROEXP_DETAIL, pro, config),
    createProject: proj => Axios.post(ENDPOINTS.STUDENT.CREATE_PROJECT, proj, config),
    updateProject: proj => Axios.post(ENDPOINTS.STUDENT.UPDATE_PROJECT, proj, config),
    createAchievement: ach => Axios.post(ENDPOINTS.STUDENT.CREATE_ACHIEVEMENT, ach, config),
    updateAchievement: ach => Axios.post(ENDPOINTS.STUDENT.UPDATE_ACHIEVEMENT, ach, config),
    createOtherExp: exp => Axios.post(ENDPOINTS.STUDENT.CREATE_OTHER_EXP, exp, config),
    updateOtherExp: exp => Axios.post(ENDPOINTS.STUDENT.UPDATE_OTHER_EXP, exp, config),
    createSkill: skill => Axios.post(ENDPOINTS.STUDENT.CREATE_SKILL, skill, config),
    updateSkill: skill => Axios.post(ENDPOINTS.STUDENT.UPDATE_SKILL, skill, config),
    createPreference: pref => Axios.post(ENDPOINTS.STUDENT.CREATE_PREFERENCE, pref, config),
    updatePreference: pref => Axios.post(ENDPOINTS.STUDENT.UPDATE_PREFERENCE, pref, config),
    createCVPoint: cv => Axios.post(ENDPOINTS.STUDENT.CREATE_CV_POINT, cv, config),
    updateCVPoint: cv => Axios.post(ENDPOINTS.STUDENT.UPDATE_CV_POINT, cv, config),
    createPOR: por => Axios.post(ENDPOINTS.STUDENT.CREATE_POR, por, config),
    updatePOR: por => Axios.post(ENDPOINTS.STUDENT.UPDATE_POR, por, config),
    createExtraCurricular: ec => Axios.post(ENDPOINTS.STUDENT.CREATE_EXTRA_CURRICULAR, ec, config),
    updateExtraCurricular: ec => Axios.post(ENDPOINTS.STUDENT.UPDATE_EXTRA_CURRICULAR, ec, config),
    getResumes: (student_id, params) => Axios.get(ENDPOINTS.STUDENT.GET_RESUMES, Object.assign({ params: { student_id, ...params } }, config)),
    getResumeApplication: (params) => Axios.get(ENDPOINTS.STUDENT.GET_RESUME_APPLICATION, { params , ...config}),
    createResume: res => Axios.post(ENDPOINTS.STUDENT.CREATE_RESUME, res, config),
    updateResume: res => Axios.post(ENDPOINTS.STUDENT.UPDATE_RESUME, res, config),
    studentFreeze: res => Axios.post(ENDPOINTS.STUDENT.FREEZE_UNFREEZE, res, config),
    deleteResume: cv_id => Axios.post(ENDPOINTS.STUDENT.DELETE_RESUME, { cv_id, status: 0 }, config),
    getProcessList: (student_id) => Axios.get(ENDPOINTS.STUDENT.PROCESS_LIST, Object.assign({
      params: {
        student_id
      }
    }, config)),
    getApplications: (process_id, student_id) => {
      let params = { process_id }
      if (student_id) params.student_id = student_id
      return Axios.get(ENDPOINTS.STUDENT.GET_APPLICATIONS, Object.assign({
        params
      }, config))
    },
    getAllApplication: (params) => Axios.get(ENDPOINTS.STUDENT.GET_ALL_APPLICATIONS, {params, ...config}),
    apply: (data) => Axios.post(ENDPOINTS.STUDENT.APPLY, data, config),
    withdrawApplication: (data) => Axios.post(ENDPOINTS.STUDENT.WITHDRAW_APPLICATION, data, config),
    downloadResume: (params) => Axios.get(ENDPOINTS.STUDENT.DOWNLOAD_RESUME, Object.assign({}, { params, responseType: "blob" }, config)),
    lockResume: (params) => Axios.get(ENDPOINTS.STUDENT.DOWNLOAD_RESUME, Object.assign({}, { params }, config)),
    getCVTemplates: () => Axios.get(ENDPOINTS.STUDENT.CV_TEMPLATES, config),
    getCVCategories: () => Axios.get(ENDPOINTS.STUDENT.CV_CATEGORY_LIST, config),
    createCVCategory: (data) => Axios.post(ENDPOINTS.STUDENT.CV_CATEGORY_CREATE, data, config),
    deleteCVCategory: (data) => Axios.post(ENDPOINTS.STUDENT.CV_CATEGORY_DELETE, data, config),
    uploadStudentAcademic: (data) => Axios.post(ENDPOINTS.STUDENT.UPLOAD_STUDENT_ACADEMIC, data, config),
    updateProcessInvitation: (data) => Axios.post(ENDPOINTS.STUDENT.UPDATE_PROCESS_INVITATION, data, config),
    getProcessApprovalOrder: (params) => Axios.get(ENDPOINTS.STUDENT.GET_PROCESS_APPROVAL_ORDER,  Object.assign({}, { params }, config) ),
    downloadBacklogsData: (params) => Axios.get(ENDPOINTS.STUDENT.DOWNLOAD_BACKLOGS_DATA,  Object.assign({}, { params }, config) ),
    downloadSemesterScoreData: (params) => Axios.get(ENDPOINTS.STUDENT.DOWNLOAD_SEMESTER_SCORE_DATA,  Object.assign({}, { params }, config) ),
    uploadBacklogsData: (data) => Axios.post(ENDPOINTS.STUDENT.UPLOAD_BACKLOGS_DATA, data, config),
    uploadSemesterScoreData: (data) => Axios.post(ENDPOINTS.STUDENT.UPLOAD_SEMESTER_SCORE_DATA, data, config),
    downloadPreferences : (params) => Axios.get(ENDPOINTS.STUDENT.DOWNLOAD_PREFERENCES,  Object.assign({}, { params }, config) ),
    addApplicationBlockRule : (data) => Axios.post(ENDPOINTS.STUDENT.ADD_APPLICATION_BLOCK_RULE, data, config),
    editApplicationBlockRule : (data) => Axios.post(ENDPOINTS.STUDENT.EDIT_APPLICATION_BLOCK_RULE, data, config),
    deleteApplicationBlockRule : (data) => Axios.post(ENDPOINTS.STUDENT.DELETE_APPLICATION_BLOCK_RULE, data, config),
    fetchApplicationBlockRule : (params) => Axios.get(ENDPOINTS.STUDENT.FETCH_APPLICATION_BLOCK_RULE, {params, ...config}),
    getBlockedApplications : (params) => Axios.get(ENDPOINTS.STUDENT.FETCH_APPLICATION_BLOCKED, {params, ...config}),
    addAnswer : (body) => Axios.post(ENDPOINTS.STUDENT.ADD_ANSWER, body, config),
    editAnswer : (body) => Axios.post(ENDPOINTS.STUDENT.EDIT_ANSWER, body, config),
    deleteAnswer : (body) => Axios.post(ENDPOINTS.STUDENT.DELETE_ANSWER, body, config),
    getBacklogCount: id => Axios.get(ENDPOINTS.STUDENT.GET_BACKLOG_COUNT(id), config),
  },
  General: {
    getEducationBoards: (query) => {
      let params = query ? `?board_name=${query}` : "";
      return Axios.get(ENDPOINTS.GENERAL.GET_EDUCATION_BOARD + params, config)
    },
    addEducationBoard: (data) => Axios.post(ENDPOINTS.GENERAL.ADD_EDUCATION_BOARD, data, config),
    // getDesignations: (query) => {
    //   let params = query ? `?designation_name=${query}` : ""
    //   return Axios.get(ENDPOINTS.GENERAL.GET_DESIGNATIONS + params, config)
    // },

    getDesignations: (query, designation_id = null) => {
      let params = {}
      if (query) params.designation_name = query;
      if (designation_id) params.designation_id = designation_id
      return Axios.get(ENDPOINTS.GENERAL.GET_DESIGNATIONS, Object.assign({ params }, config))
    },

    addDesignation: (data) => Axios.post(ENDPOINTS.GENERAL.ADD_DESIGNATION, data, config),
    getSectors: (query) => {
      let params = query ? `?sector_name=${query}` : ""
      return Axios.get(ENDPOINTS.GENERAL.GET_SECTORS + params, config)
    },
    addSector: (data) => Axios.post(ENDPOINTS.GENERAL.ADD_SECTOR, data, config),
    getDomains: (query) => {
      let params = query ? `?domain_name=${query}` : ""
      return Axios.get(ENDPOINTS.GENERAL.GET_DOMAINS + params, config)
    },
    addDomain: (data) => Axios.post(ENDPOINTS.GENERAL.ADD_DOMAIN, data, config),
    getSectorsById: (list) => {
      let params = new URLSearchParams();
      list.forEach(s => params.append("sector_id", s))
      return Axios.get(ENDPOINTS.GENERAL.GET_SECTORS, { params, ...config })
    },
    getIndustries: (query) => {
      let params = query ? `?industry_name=${query}` : ""
      return Axios.get(ENDPOINTS.GENERAL.GET_INDUSTRIES + params, config)
    },
    addIndustry: (data) => Axios.post(ENDPOINTS.GENERAL.ADD_INDUSTRY, data, config),
    getCompanies: (query) => {
      let params = query ? `?company_name=${query}` : ""
      return Axios.get(ENDPOINTS.GENERAL.GET_COMPANIES + params, config)
    },
    addCompany: (data) => Axios.post(ENDPOINTS.GENERAL.ADD_COMPANY, data, config),
    getDepartments: (query) => {
      let params = query ? `?department_name=${query}` : ""
      return Axios.get(ENDPOINTS.GENERAL.GET_DEPARTMENTS + params, config)
    },
    addDepartment: (data) => Axios.post(ENDPOINTS.GENERAL.ADD_DEPARTMENT, data, config),
    getInstitutes: (query) => {
      let params = query ? `?college_name=${query}` : ""
      return Axios.get(ENDPOINTS.GENERAL.GET_INSTITUTES + params, config)
    },
    addInstitute: (data) => Axios.post(ENDPOINTS.GENERAL.ADD_INSTITUTE, data, config),
    getDegrees: (query) => {
      let params = query ? `?degree_name=${query}` : ""
      return Axios.get(ENDPOINTS.GENERAL.GET_DEGREES + params, config)
    },
    getDegreesCampus: res => Axios.get(ENDPOINTS.GENERAL.GET_DEGREES_CAMPUS, config),
    getDegreesCampusDepartment: (campusid, degreeId) => Axios.get(ENDPOINTS.GENERAL.GET_DEGREES_CAMPUS_DEPARTMENT(campusid, degreeId), config),
    addDegree: (data) => Axios.post(ENDPOINTS.GENERAL.ADD_DEGREE, data, config),
    getSchools: (query) => {
      let params = query ? `?school_name=${query}` : ""
      return Axios.get(ENDPOINTS.GENERAL.GET_SCHOOLS + params, config)
    },
    addSchool: (data) => Axios.post(ENDPOINTS.GENERAL.ADD_SCHOOL, data, config),
    getRoles: (query) => {
      let params = query ? `?role_name=${query}` : ""
      return Axios.get(ENDPOINTS.GENERAL.GET_ROLES + params, config)
    },
    getSpecialization: (query) => {
      let params = query ? `?specialization_name=${query}` : ""
      return Axios.get(ENDPOINTS.GENERAL.GET_SPECIALIZATION + params, config)
    },
    getStudentIdExcel: params => Axios.get(ENDPOINTS.GENERAL.GET_STUDENT_ID_EXCEL, {params, ...config}),

  },
  Verfier: {
    getProcesList: id => Axios.get(ENDPOINTS.VERIFIER.GET_PROCESS_LIST(id), config),
    getStudentAssigned: params => Axios.get(ENDPOINTS.VERIFIER.GET_STUDENT_ASSIGNED(params), config),
    updateStudentVerificationStatus: verification => Axios.post(ENDPOINTS.VERIFIER.UPDATE_STUDENT_STATUS, verification, config),
    updateStudentVerificationStatusAll: verification => Axios.post(ENDPOINTS.VERIFIER.UPDATE_STUDENT_STATUS_ALL, verification, config),
    getStudentDetails: params => Axios.get(ENDPOINTS.VERIFIER.GET_PROFILE(params), config),
    freezeStudentSection: params => Axios.post(ENDPOINTS.VERIFIER.FREEZE_STUDENT_SECTION, params, config),

  },
  Feed: {
    getFeed: () => Axios.get(ENDPOINTS.FEED.GET_POSTS, config),
    likePost: res => Axios.post(ENDPOINTS.FEED.LIKE_POST, res, config),
    sendPost: params => Axios.post(ENDPOINTS.FEED.SEND_POST, params, config),
    postMemberAdd: params => Axios.post(ENDPOINTS.FEED.POST_MEMBER_ADD, params, config),
    postView: params => Axios.get(ENDPOINTS.FEED.POST_VIEW,{params, ...config}),
    postMemberView: params => Axios.get(ENDPOINTS.FEED.POST_MEMBER_VIEW,{params, ...config}),
    createActivity: params => Axios.post(ENDPOINTS.FEED.POST_ACTIVITY_CREATE, params, config),
    editPost : params => Axios.post(ENDPOINTS.FEED.POST_EDIT, params, config),
    approvePost : params => Axios.get(ENDPOINTS.FEED.APPROVE_POST, {params, ...config})
  },
  Spoc: {
    createCollege: res => Axios.post(ENDPOINTS.SPOC.CREATE_COLLEGE, res, config),
    createCampus: res => Axios.post(ENDPOINTS.SPOC.CREATE_CAMPUS, res, config),
    createCourse: res => Axios.post(ENDPOINTS.SPOC.CREATE_COURSE, res, config),
    editCollege: res => Axios.post(ENDPOINTS.SPOC.EDIT_COLLEGE, res, config),
    inactiveUniversity: res => Axios.post(ENDPOINTS.SPOC.INACTIVE_UNIVERSITY, res, config),
    inactiveCollege: res => Axios.post(ENDPOINTS.SPOC.INACTIVE_COLLEGE, res, config),
    inactiveCampus: res => Axios.post(ENDPOINTS.SPOC.INACTIVE_CAMPUS, res, config),
    inactiveCourse: res => Axios.post(ENDPOINTS.SPOC.INACTIVE_COURSE, res, config),
    editCampus: res => Axios.post(ENDPOINTS.SPOC.EDIT_CAMPUS, res, config),
    getInstitutes: res => Axios.get(ENDPOINTS.SPOC.GET_INSTITUTES, config),
    getInstitutesDetailsByAccess: res => Axios.get(ENDPOINTS.SPOC.GET_INSTITUTES_DETAILS_BY_ACCESS, config),
    getInstituteDetails: params => Axios.get(ENDPOINTS.SPOC.GET_INSTITUTE_DETAILS, Object.assign({}, { params }, config)),
    getCourses: (access_key) => Axios.get(ENDPOINTS.SPOC.GET_COURSES + `?access_key=${access_key}`, config),
    getUserRoles: (university_id) => Axios.get(ENDPOINTS.SPOC.GET_USER_ROLES + `?university_id=${university_id}`, config),
    getUsers: params => Axios.get(ENDPOINTS.SPOC.GET_USERS, Object.assign({}, { params }, config)),
    createUserWithRole: res => Axios.post(ENDPOINTS.SPOC.CREATE_USER_WITH_ROLE, res, config),
    inactiveUser: res => Axios.post(ENDPOINTS.SPOC.INACTIVE_USER, res, config),
    getDegreeDepartment: params => Axios.get(ENDPOINTS.SPOC.DEGREE_DEPARTMENT_LIST, Object.assign({}, { params }, config)),
    inactiveDegreeDepartment: res => Axios.post(ENDPOINTS.SPOC.INACTIVE_DEGREE_DEPARTMENT, res, config),
    addDegreeDepartment: res => Axios.post(ENDPOINTS.SPOC.ADD_DEGREE_DEPARTMENT, res, config),
    addStaticDepartment: res => Axios.post(ENDPOINTS.SPOC.ADD_STATIC_DEPARTMENT, res, config),
    addRole: res => Axios.post(ENDPOINTS.SPOC.ADD_ROLE, res, config),
    editRole: res => Axios.post(ENDPOINTS.SPOC.EDIT_ROLE, res, config),
    deleteRole: res => Axios.post(ENDPOINTS.SPOC.DEL_ROLE, res, config),
    getPermissionListDefault: id => Axios.get(ENDPOINTS.SPOC.GET_PERMISSION_DEFAULT(id), config),
    getPermissionListRole: id => Axios.get(ENDPOINTS.SPOC.GET_PERMISSION_ROLE(id), config),
    addPermission: res => Axios.post(ENDPOINTS.SPOC.ADD_PERMISSION, res, config),
    editPermission: res => Axios.post(ENDPOINTS.SPOC.EDIT_PERMISSION, res, config),
    getRole: () => Axios.get(ENDPOINTS.USER.GET_ROLE_LIST, config),
    createUserRoleMapping: params => Axios.post(ENDPOINTS.SPOC.CREATE_USER_ROLE_MAPPING, params, config),
    fetchAccessKey: params => Axios.post(ENDPOINTS.SPOC.FETCH_ACCESS_KEY, params, config),
    fetchUserAccess : params => Axios.get(ENDPOINTS.SPOC.FETCH_USER_ACCESS, {params, ...config}),
    grantUserAccess : params => Axios.post(ENDPOINTS.SPOC.GRANT_USER_ACCESS, params, config),
    removeUserAccess : params => Axios.post(ENDPOINTS.SPOC.REMOVE_USER_ACCESS, params, config),
  },

  CommentBox: {
    createCommentThread: res => Axios.post(ENDPOINTS.COMMENT_BOX.CREATE_COMMENT_THREAD, res, config),
    comment: res => Axios.post(ENDPOINTS.COMMENT_BOX.COMMENT, res, config),
    getComment: id => Axios.get(ENDPOINTS.COMMENT_BOX.GET_COMMENT(id), config),
    getCommentThread: (student_id, verifier_id) => Axios.get(ENDPOINTS.COMMENT_BOX.GET_COMMENT_THREAD(student_id, verifier_id), config)
  },
  Processes: {
    getProcesses: campus_id => Axios.get(ENDPOINTS.PROCESSES.GET_PROCESSES, Object.assign({}, { params: { campus_id: campus_id } }, config)),
    bulkApply: (data) => Axios.post(ENDPOINTS.PROCESSES.BULK_APPLY, data, config),
    showAllApplication: params => Axios.get(ENDPOINTS.PROCESSES.SHOW_ALL_APPLICATION, {params, ...config}),
    getProcessById: process_id => Axios.get(ENDPOINTS.PROCESSES.GET_PROCESSES, Object.assign({}, { params: { process_id } }, config)),
    getCompaniesInProcess: (process_id, param = {}) => Axios.get(ENDPOINTS.PROCESSES.GET_COMPANIES_IN_PROCESS, Object.assign({}, { params: { process_id, ...param } }, config)),
    mapCompaniesToProcess: res => Axios.post(ENDPOINTS.PROCESSES.MAP_COMPANIES_TO_PROCESS, res, config),
    getApplicationsInProcess: (process_id, shortlist_release_status, application_id, param = {}) => {
      let params = {
        process_id
      }
      if (shortlist_release_status)
        params = { ...params, shortlist_release_status }
      if (application_id)
        params = { ...params, application_id }
      return Axios.get(ENDPOINTS.PROCESSES.GET_APPLICATIONS_IN_PROCESS, Object.assign({}, { params : {...params, ...param} }, config))
    },
    updateCompanyStatus: company => Axios.post(ENDPOINTS.PROCESSES.UPDATE_COMPANY_STATUS, { ...company }, config),
    getCampusCompany: (company_id) => Axios.get(ENDPOINTS.PT_COMPANIES.GET_COMPANIES, Object.assign({}, {
      params: {
        access_key: 3,
        company_id
      }
    }, config)),
    getApplicants: (application_id) => Axios.get(ENDPOINTS.PROCESSES.GET_APPLICANTS, Object.assign({}, { params: { application_id } }, config)),
    updateShortlist: (data) => Axios.post(ENDPOINTS.PROCESSES.UPDATE_SHORTLIST, data, config),
    updateShortlistExcel: (data) => Axios.post(ENDPOINTS.PROCESSES.UPDATE_SHORTLIST_EXCEL, data, config),
    uploadShortlistEmail: (data) => Axios.post(ENDPOINTS.PROCESSES.UPLOAD_SHORTLIST_EMAIL, data, config),
    publishApplication: (application_id) => Axios.post(ENDPOINTS.PROCESSES.PUBLISH_APPLICATION, application_id, config),
    addMilestone: (data) => Axios.post(ENDPOINTS.PROCESSES.ADD_MILESTONE, data, config),
    deleteMilestone: (data) => Axios.post(ENDPOINTS.PROCESSES.DELETE_MILESTONE, data, config),
    addProcess: (data) => Axios.post(ENDPOINTS.PROCESSES.ADD_PROCESS, data, config),
    updateProcess: (data) => Axios.post(ENDPOINTS.PROCESSES.UPDATE_PROCESS, data, config),
    deleteProcess: (data) => Axios.post(ENDPOINTS.PROCESSES.DELETE_PROCESS, data, config),
    editApplication: (id, data) => Axios.post(ENDPOINTS.PROCESSES.EDIT_APPICATION(id), data, config),
    addEligibilityCriteria: (data) => Axios.post(ENDPOINTS.PROCESSES.ADD_ELIGIBITY_CRITERIA, data, config),
    deleteEligibilityCriteria: (data) => Axios.post(ENDPOINTS.PROCESSES.DELETE_ELIGIBILITY_CRITERIA, data, config),
    addApplication: (data) => Axios.post(ENDPOINTS.PROCESSES.ADD_APPLICATION, data, config),
    mapCompanyProfilesToProcess: res => Axios.post(ENDPOINTS.PROCESSES.MAP_COMPANY_PROFILES_TO_PROCESS, res, config),
    getVerifiers: (process_id) => Axios.get(ENDPOINTS.PROCESSES.GET_VERIFIERS, Object.assign({}, { params: { process_id } }, config)),
    getStudents: (role_id, process_id) => Axios.get(ENDPOINTS.PROCESSES.GET_STUDENTS, Object.assign({}, { params: { role_id, process_id } }, config)),
    assignVerifier: (data) => Axios.post(ENDPOINTS.PROCESSES.ASSIGN_VERIFIER, data, config),
    assignStudents: (data) => Axios.post(ENDPOINTS.PROCESSES.ASSIGN_STUDENTS, data, config),
    removeStudentfromVerifier: (data) => Axios.post(ENDPOINTS.PROCESSES.REMOVE_STUDENTS_VERIFIER, { ...data, status: 0 }, config),
    mapStudentsToProcess: (data) => Axios.post(ENDPOINTS.PROCESSES.MAP_STUDENTS_TO_PROCESS, data, config),
    removeStudentsFromProcess: (data) => Axios.post(ENDPOINTS.PROCESSES.REMOVE_STUDENTS_FROM_PROCESS, data, config),
    updateProfileStatus: (data) => Axios.post(ENDPOINTS.PROCESSES.UPDATE_PROFILE_STATUS, data, config),
    updateCompanyUser: (data) => Axios.post(ENDPOINTS.PROCESSES.UPDATE_COMPANY_USER, data, config),
    updateCompanyContact: (data) => Axios.post(ENDPOINTS.PROCESSES.UPDATE_COMPANY_CONTACT, data, config),
    updateCampusCompanyUser: (data) => Axios.post(ENDPOINTS.PROCESSES.UPDATE_CAMPUS_COMPANY_USER, data, config),
    deleteDocs: (ids) => Axios.post(ENDPOINTS.PROCESSES.DELETE_DOC, { "application_docs_id": ids }, config),
    addDoc: (data) => Axios.post(ENDPOINTS.PROCESSES.ADD_DOC, data, config),
    releaseShortlist: (data) => Axios.post(ENDPOINTS.PROCESSES.RELEASE_SHORTLIST, data, config),
    fetchQuestion: (params) => Axios.get(ENDPOINTS.PROCESSES.FETCH_QUESTION, {params, ...config}),
    addQuestion: (data) => Axios.post(ENDPOINTS.PROCESSES.ADD_QUESTION, data, config),
    deleteQuestion: (data) => Axios.post(ENDPOINTS.PROCESSES.DELETE_QUESTION, data, config),
    appendMultipleRole: (data) => Axios.post(ENDPOINTS.PROCESSES.APPEND_MULTIPLE_ROLE, data, config),
    addMultipleRole: (data) => Axios.post(ENDPOINTS.PROCESSES.ADD_MULTIPLE_ROLE, data, config),
    removeVerifier: (data) => Axios.post(ENDPOINTS.PROCESSES.REMOVE_VERIFIER, data, config),
    getProcessStudents: id => Axios.get(ENDPOINTS.PROCESSES.GET_STUDENT(id), config),
    deleteProcessCompany: (data) => Axios.post(ENDPOINTS.PROCESSES.DELETE_PROCESS_COMPANY, data, config),
    archiveUnarchiveProcess: (params) => Axios.post(ENDPOINTS.PROCESSES.ARCHIVEUNARCHIVEPROCESS, params, config),
    changeApplicationStatus: params => Axios.post(ENDPOINTS.PROCESSES.CHANGE_APPLICATION_STATUS, params, config),
    duplicateApplication: params => Axios.post(ENDPOINTS.PROCESSES.DUPLICATE_APPLICATION, params, config),
    addApplicantsToApplication: params => Axios.post(ENDPOINTS.PROCESSES.ADD_APPLICANT_TO_APPLICATION, params, config),
    removeApplicantsToApplication: params => Axios.post(ENDPOINTS.PROCESSES.REMOVE_APPLICANT_TO_APPLICATION, params, config),
    closeApplication: params => Axios.post(ENDPOINTS.PROCESSES.CLOSE_APPLICATION, params, config)
  },

  PtCompanies: {
    getCompanies: (campus_company_id) => {
      let params = new URLSearchParams();
      if (campus_company_id) {
        campus_company_id = Array.isArray(campus_company_id) ? campus_company_id : [campus_company_id];
        campus_company_id.forEach(s => params.append("campus_company_id", s))
      }
      return Axios.get(ENDPOINTS.PT_COMPANIES.GET_COMPANIES, { params, ...config })
    },
    getCompanyContacts: (campus_company_id) => Axios.get(ENDPOINTS.PT_COMPANIES.COMPANY_CONTACTS + `?access_key=3${campus_company_id ? '&campus_company_id='+campus_company_id : ''}`, config),
    getCompanyContactDetails: (contact_id) => Axios.get(ENDPOINTS.PT_COMPANIES.COMPANY_CONTACT_DETAILS, Object.assign({}, { params: { contact_id, access_key: 3 } }, config)),
    createBaseCompany: res => Axios.post(ENDPOINTS.PT_COMPANIES.CREATE_BASE_COMPANY, res, config),
    mapCampusCompany: res => Axios.post(ENDPOINTS.PT_COMPANIES.MAP_CAMPUS_COMPANY, res, config),
    inactiveCompanies: res => Axios.post(ENDPOINTS.PT_COMPANIES.INACTIVE_COMPANIES, res, config),
    addContact: res => Axios.post(ENDPOINTS.PT_COMPANIES.CREATE_CONTACT, res, config),
    addContactEmail: res => Axios.post(ENDPOINTS.PT_COMPANIES.ADD_CONTACT_EMAIL, res, config),
    addContactMobile: res => Axios.post(ENDPOINTS.PT_COMPANIES.ADD_CONTACT_MOBILE, res, config),
    inactiveContact: res => Axios.post(ENDPOINTS.PT_COMPANIES.INACTIVE_CONTACT, res, config),
    editContact: res => Axios.post(ENDPOINTS.PT_COMPANIES.EDIT_CONTACT, res, config),
    inactiveContactEmail: res => Axios.post(ENDPOINTS.PT_COMPANIES.INACTIVE_CONTACT_EMAIL, res, config),
    inactiveContactMobile: res => Axios.post(ENDPOINTS.PT_COMPANIES.INACTIVE_CONTACT_MOBILE, res, config),
    uploadContacts: res => Axios.post(ENDPOINTS.PT_COMPANIES.UPLOAD_CONTACTS, res, config),
    addCompanyProfile: res => Axios.post(ENDPOINTS.PT_COMPANIES.ADD_COMPANY_PROFILE, res, config),
    inactiveCompanyProfile: res => Axios.post(ENDPOINTS.PT_COMPANIES.INACTIVE_COMPANY_PROFILE, res, config),
    getCompanyProfiles: (campus_company_id) => Axios.get(ENDPOINTS.PT_COMPANIES.COMPANY_PROFILES + `?access_key=3&campus_company_id=${campus_company_id}`, config),
    getCompaniesInProcess: campus_company_id => Axios.get(ENDPOINTS.PT_COMPANIES.MAPPED_PROCESS, Object.assign({}, { params: { campus_company_id } }, config)),
    downloadCompanyData: (params) => Axios.get(ENDPOINTS.PT_COMPANIES.DOWNLOAD_COMPANY_DATA, { ...config, params }),
    uploadExcel: res => Axios.post(ENDPOINTS.PT_COMPANIES.UPLOAD_EXCEL, res, config),
  },
  Meetings: {
    getMeetings: (params) => Axios.post(ENDPOINTS.MEETINGS.GET_MEETINGS, params, config)

  },

  PtStudents: {
    getBatch: () => Axios.get(ENDPOINTS.PT_STUDENTS.GET_BATCHS, config),
    getBatchSpec: (batchID, depID) => {
      let params = new URLSearchParams();
      batchID.forEach(batch_id => {
        if (batch_id)
          params.append("batch_id", batch_id)
      })
      depID.forEach(degree_department_id => {
        if (degree_department_id)
          params.append("degree_department_id", degree_department_id)
      })
      return Axios.get(ENDPOINTS.PT_STUDENTS.GET_BATCHS_SPEC, Object.assign({ params }, config))
    },
    getBatchList: () => Axios.get(ENDPOINTS.PT_STUDENTS.GET_BATCHS_LIST, config),
    createBatch: res => Axios.post(ENDPOINTS.PT_STUDENTS.CREATE_BATCHS, res, config),
    deleteBatch: res => Axios.post(ENDPOINTS.PT_STUDENTS.DELETE_BATCHS, res, config),
    createStudent: res => Axios.post(ENDPOINTS.PT_STUDENTS.STUDENT_CREATE, res, config),
    createStudentCsv: (batchID, depID, fileUrl) => {
      let params = new URLSearchParams();
      params.append("batch_id", batchID);
      params.append("degree_department_id", depID);
      params.append("file_url", fileUrl);
      return Axios.post(ENDPOINTS.PT_STUDENTS.STUDENT_CREATE_CSV, {}, Object.assign({ params }, config))
    },
    editBatch: res => Axios.post(ENDPOINTS.BATCH.EDIT_BATCH, res, config),
    editStudent: res => Axios.post(ENDPOINTS.PT_STUDENTS.STUDENT_EDIT, res, config),
    studentEdit: res => Axios.post(ENDPOINTS.PT_STUDENTS.EDIT_STUDENT, res, config),
    deleteStudent: res => Axios.post(ENDPOINTS.PT_STUDENTS.DELETE_STUDENT, res, config),
    getGroup: () => Axios.get(ENDPOINTS.PT_STUDENTS.GET_GROUPS, config),
    createGroup: res => Axios.post(ENDPOINTS.PT_STUDENTS.CREATE_GROUPS, res, config),
    editGroup: res => Axios.post(ENDPOINTS.PT_STUDENTS.EDIT_GROUP, res, config),
    deleteStudentGroup: res => Axios.post(ENDPOINTS.PT_STUDENTS.DELETE_STUDENTS_GROUP, res, config),
    deleteGroup: res => Axios.post(ENDPOINTS.PT_STUDENTS.DELETE_GROUPS, res, config),
    createPost: res => Axios.post(ENDPOINTS.PT_STUDENTS.CREATE_POST, res, config),
    deletePost: res => Axios.post(ENDPOINTS.PT_STUDENTS.DELETE_POST, res, config),
    getCampus: id => Axios.get(ENDPOINTS.PT_STUDENTS.VIEW_CAMPUS(id), config),
    getCollege: id => Axios.get(ENDPOINTS.PT_STUDENTS.VIEW_COLLEGE(id), config),
    getAttendeeSt: id => Axios.get(ENDPOINTS.PT_STUDENTS.VIEW_ATTEND_STUDENT(id), config),
    getPreferences: (sts) => {
      let params = new URLSearchParams();
      sts.forEach(student_id => {
        if (student_id)
          params.append("student_id", student_id)
      })
      return Axios.get(ENDPOINTS.PT_STUDENTS.GET_PREFERENCES, Object.assign({ params }, config))
    },
    getFilterGroup: (batch_id, degree_department_id) => {
      let params = new URLSearchParams();
      params.append("batch_id", batch_id);
      params.append("degree_department_id", degree_department_id)
      return Axios.get(ENDPOINTS.PT_STUDENTS.GET_GROUPS, Object.assign({ params }, config))
    },
    getStudentsList: (sts) => {
      let params = new URLSearchParams();
      sts.forEach(student_id => {
        if (student_id)
          params.append("student_id", student_id)
      })
      return Axios.get(ENDPOINTS.PT_STUDENTS.GET_STUDENTS, Object.assign({ params }, config))
    },
    addStudentoGroup: res => Axios.post(ENDPOINTS.PT_STUDENTS.ADD_STUDENTS_GROUP, res, config),
    markManual: res => Axios.post(ENDPOINTS.PT_STUDENTS.MARK_MANUAL, res, config),
    unMarkAttendance: res => Axios.post(ENDPOINTS.PT_STUDENTS.UNMARK_ATTENDANCE, res, config),
    //getPreferences: id => Axios.get(ENDPOINTS.STUDENT.GET_PREFERENCES(id), config),
  },

  Batch: {
    getBatchDetail: (params) => Axios.get(ENDPOINTS.BATCH.GET_BATCH, {params, ...config}),
    getBatch: () => Axios.get(ENDPOINTS.BATCH.GET_BATCH_DETAIL, config),
    getDegrees: id => Axios.get(ENDPOINTS.BATCH.GET_DEGREES(id), config),
    getInviteDetails: params => Axios.post(ENDPOINTS.BATCH.GET_INVITE_DETAILS, params, config),
    sendResponseToInvite: params => Axios.post(ENDPOINTS.BATCH.SEND_RESPONSE_TO_INVITE, params, config),
    getEvent: params => Axios.post(ENDPOINTS.BATCH.GET_EVENT, params, config),
    createEvent: params => Axios.post(ENDPOINTS.BATCH.CREATE_EVENT, params, config),
    createAttendee: params => Axios.post(ENDPOINTS.BATCH.CREATE_ATTENDEE, params, config),
    sendMail: params => Axios.post(ENDPOINTS.BATCH.SEND_MAIL, params, config),
    getAttendeesList: params => Axios.post(ENDPOINTS.BATCH.GET_MEETING_LIST_OF_ATTENDEES, params, config),
    getMeetings: params => Axios.post(ENDPOINTS.MEETINGS.GET_MEETINGS, params, config),
    createMeetings: params => Axios.post(ENDPOINTS.MEETINGS.CREATE_MEETING, params, config),
    fetchTodayMeetingReminders: () => Axios.get(ENDPOINTS.MEETINGS.FETCH_TODAY_MEETING_REMINDER,config),
    createMeetingReminder: params => Axios.post(ENDPOINTS.MEETINGS.CREATE_MEETING_REMINDER, params, config),
    updateMeetingReminder: params => Axios.post(ENDPOINTS.MEETINGS.UPDATE_MEETING_REMINDER, params, config),
    createMeetingAttendee: params => Axios.post(ENDPOINTS.MEETINGS.CREATE_MEETING_ATTENDEE, params, config),
    deleteEventAttendee: params => Axios.post(ENDPOINTS.BATCH.EVENT_ATTENDEE_INACTIVE, params, config),
    deleteMeetingAttendee: params => Axios.post(ENDPOINTS.BATCH.MEETING_ATTENDEES_INACTIVE, params, config),
    createInvite: params => Axios.post(ENDPOINTS.BATCH.CREATE_INVITE, params, config),
    createInviteAttendee: params => Axios.post(ENDPOINTS.BATCH.CREATE_INVITE_ATTENDEE, params, config),
    createReminder: params => Axios.post(ENDPOINTS.BATCH.CREATE_REMINDER, params, config),
    getCompanies: campus_id => Axios.get(ENDPOINTS.MEETINGS.GET_COMPANIES(campus_id), config),
    getContacts: campus_company_id => Axios.get(ENDPOINTS.MEETINGS.GET_CONTACTS(campus_company_id), config),
    deleteEvent: params => Axios.post(ENDPOINTS.BATCH.DELETE_EVENT, params, config),
    deleteMeeting: params => Axios.post(ENDPOINTS.BATCH.DELETE_MEETING, params, config),
    updateMeetingDetails: params => Axios.post(ENDPOINTS.BATCH.UPDATE_MEETING_DETAILS, params, config),
    updateEventDetails: params => Axios.post(ENDPOINTS.BATCH.UPDATE_EVENT_DETAILS, params, config),
    getProcesses: campus_id => Axios.get(ENDPOINTS.PROCESSES.GET_PROCESSES, Object.assign({}, { params: { campus_id: campus_id, archive_status: [0, 1] } }, config))
  },
  Offer: {
    getOffer: params => Axios.post(ENDPOINTS.OFFER.VIEW_OFFER, params, config),
    addOffer: (data) => Axios.post(ENDPOINTS.OFFER.ADD_OFFER, data, config),
    addOfferExcel: (data) => Axios.post(ENDPOINTS.OFFER.ADD_OFFER_EXCEL, data, config),
    editOffer: (data) => Axios.post(ENDPOINTS.OFFER.EDIT_OFFER, data, config),
    deleteOffer: params => Axios.post(ENDPOINTS.OFFER.DELETE_OFFER, params, config),
    acceptOffer: params => Axios.post(ENDPOINTS.OFFER.ACCEPT_OFFER, params, config),
    getCompanyOffers: params => Axios.get(ENDPOINTS.OFFER.COMPANY_OFFERS, {params, ...config})
  },
  Reports: {
    getCTCbySector: (params) => Axios.post(ENDPOINTS.REPORTS.CTC_BY_SECTOR, params, config),
    getCTCbyGender: (params) => Axios.post(ENDPOINTS.REPORTS.CTC_BY_GENDER, params, config),
    getCTC: (params) => Axios.post(ENDPOINTS.REPORTS.CTCLIST, params, config),
    getCTCbyRole: (params) => Axios.post(ENDPOINTS.REPORTS.CTC_BY_ROLE, params, config),
    genderReport: (params) => Axios.post(ENDPOINTS.REPORTS.GENDER_REPORT, params, config),
    studentReport: (params) => Axios.post(ENDPOINTS.REPORTS.STUDENT_REPORT, params, config),
    studentProReport: (params) => Axios.post(ENDPOINTS.REPORTS.STUDENT_PROFESSIONAL_REPORT, params, config),
    getMeetingReport: (paramList) => {
      let params = new URLSearchParams();
      paramList.forEach(data => {
        if (data.key == 'campus_id')
          params.append('campus_id', data.value)
        if (data.key == 'start_date')
          params.append('start_date', data.value)
        if (data.key == 'end_date')
          params.append('end_date', data.value)
      })
      return Axios.get(ENDPOINTS.REPORTS.MEETING_REPORT, Object.assign({ params }, config))
    },

    getCTCbyClassification: params => Axios.post(ENDPOINTS.REPORTS.CLASSIFICATION_LIST, params, config),
    placementreportDownload: params => Axios.get(ENDPOINTS.REPORTS.PLACEMENT_REPORT_DOWNLOAD, {params , ...config}),
    placementReportView : params =>Axios.get(ENDPOINTS.REPORTS.PLACEMENT_REPORT_VIEW , {params , ...config}),
    placementReportCategoryView : params=> Axios.get(ENDPOINTS.REPORTS.PLACEMENT_REPORT_CATEGORY_VIEW , {params , ...config}),
    teamreportDownload: (data) => Axios.post(ENDPOINTS.REPORTS.TEAM_REPORT_DOWNLOAD, data, config),
    studentreportDownload: params => Axios.get(ENDPOINTS.REPORTS.STUDENT_REPORT_DOWNLOAD, {params , ...config}),
    companyreportDownload: (data) => Axios.post(ENDPOINTS.REPORTS.COMPANY_REPORT_DOWNLOAD, data, config),
    companyreportByDepartmentDownload: (params) => Axios.get(ENDPOINTS.REPORTS.COMPANY_REPORT_DOWNLOAD_BY_DEPARTMENT, {params , ...config}),
    companyreportDownloadCompanyGrouped: (data) => Axios.post(ENDPOINTS.REPORTS.COMPANY_REPORT_DOWNLOAD_COMPANY_GROUPED, data, config),
    attendancereportDownload: id => Axios.get(ENDPOINTS.REPORTS.ATTENDANCE_REPORT_DOWNLOAD(id), config),
    attendanceReportView : id =>Axios.get(ENDPOINTS.REPORTS.ATTENDANCE_REPORT_VIEW(id) , config),
    meetingReportDownload : params=>Axios.post(ENDPOINTS.REPORTS.MEETING_REPORT_DOWNLOAD, params , config),
    studentReportView : params =>Axios.get(ENDPOINTS.REPORTS.STUDENT_REPORT_VIEW , {params , ...config}),

  }
  ,
  Download: {
    cvDownload: data => Axios.post(ENDPOINTS.DOWNLOAD.CV_DOWNLOAD, data, config),
    cvCSVDownload: params => Axios.post(ENDPOINTS.DOWNLOAD.CV_CSVDOWNLOAD, params, config),
    csvStudentData: params => Axios.post(ENDPOINTS.DOWNLOAD.CV_CSVDOWNLOAD,  params, config ),
    // eligibleStudent: params => Axios.get(ENDPOINTS.DOWNLOAD.ELIGIBLE_STUDENT, params, config),
  },

  Classification: {
    addClassificationCategory: params => Axios.post(ENDPOINTS.CLASSIFICATION.ADD_CLASSIFICATION_CATEGORY, params, config),
    createClassfication: params => Axios.post(ENDPOINTS.CLASSIFICATION.CREATE_CLASSIFICATION, params, config),
    listClassificationDetails: params => Axios.get(ENDPOINTS.CLASSIFICATION.LIST_CLASSIFICATION_DETAILS, Object.assign({ params }, config)),
    editClassCategory: params => Axios.post(ENDPOINTS.CLASSIFICATION.EDIT_CLASS_CATEGORY, params, config),
    inactive_class_category: params => Axios.post(ENDPOINTS.CLASSIFICATION.INACTIVE_CLASS_CATEGORY, params, config),
    inactive_category: params => Axios.post(ENDPOINTS.CLASSIFICATION.INACTIVE_CATEGORY, params, config),
    companyClassificationMapping: params => Axios.post(ENDPOINTS.CLASSIFICATION.COMPANY_CLASSIFICATION_MAPPING, params, config)
  },
  classification: {
    addClassificationCategory: params => Axios.post(ENDPOINTS.CLASSIFICATION.ADD_CLASSIFICATION_CATEGORY, params, config),
    createClassfication: params => Axios.post(ENDPOINTS.CLASSIFICATION.CREATE_CLASSIFICATION, params, config),
    listClassificationDetails: () => Axios.get(ENDPOINTS.CLASSIFICATION.LIST_CLASSIFICATION_DETAILS, config),
    companyClassificationMapping: params => Axios.post(ENDPOINTS.CLASSIFICATION.COMPANY_CLASSIFICATION_MAPPING, params, config),
    addMatrixRule: params => Axios.post(ENDPOINTS.CLASSIFICATION.ADD_MATRIX_RULE, params, config),
    processCompanyMapping: params => Axios.post(ENDPOINTS.CLASSIFICATION.PROCESS_COMPANY_MAPPING, params, config),
    editProcessCompanyMapping: params => Axios.post(ENDPOINTS.CLASSIFICATION.EDIT_PROCESS_COMPANY_MAPPING, params, config),
    editMatrix: params => Axios.post(ENDPOINTS.CLASSIFICATION.EDIT_MATRIX, params, config),
    editMatrixRule: params => Axios.post(ENDPOINTS.CLASSIFICATION.EDIT_MATRIX_RULE, params, config),
    deleteMatrix: params => Axios.post(ENDPOINTS.CLASSIFICATION.DELETE_MATRIX, params, config),
    getMatrixRuleList: params => Axios.get(ENDPOINTS.CLASSIFICATION.GET_MATRIX_RULE(params), config),
    editCompanyClassificationMapping: params => Axios.post(ENDPOINTS.CLASSIFICATION.EDIT_COMPANY_CLASSIFICATION_MAPPING, params, config),
    getCompanyMappedclassification: id => Axios.get(ENDPOINTS.GET_COMPANY_MAPPED_CLASSIFICATION(id), config)
  },
  Matrix: {
    checkWithdrawComplete: params => Axios.get(ENDPOINTS.MATRIX.CHECK_WITHDRAW_STATUS, Object.assign({ params }, config)),

  },
  CampusRoles: {
    getRoles: params => Axios.get(ENDPOINTS.CAMPUS_ROLES.GET_ROLES(params), config),
    createRole: params => Axios.post(ENDPOINTS.CAMPUS_ROLES.CREATE_ROLE, params, config),
    editRole: params => Axios.post(ENDPOINTS.CAMPUS_ROLES.EDIT_ROLE, params, config),
    inactiveRole: params => Axios.post(ENDPOINTS.CAMPUS_ROLES.ACTIVE_INACTIVE_ROLE, params, config),
  },
  SendLink: {
    toCompany: params => Axios.post(ENDPOINTS.SEND_LINK.TO_COMPANY, params, config),
  },
  Attendance: {
    generateQRCode: params => Axios.get(ENDPOINTS.ATTENDANCE.GENERATE_QR, Object.assign({}, { params }, config)),
    getList: params => Axios.get(ENDPOINTS.ATTENDANCE.GET_LIST, Object.assign({}, { params }, config)),
    attendanceView: params => Axios.get(ENDPOINTS.ATTENDANCE.ATTENDANCE_VIEW, { params, ...config}),
    uploadAttendanceExcel: params => Axios.post(ENDPOINTS.ATTENDANCE.UPLOAD_ATTENDANCE_EXCEL, params, config),

  },
  Inst_Configuration: {
    getConfig: params => Axios.get(ENDPOINTS.INST_CONFIGURATION.GET_CONFIG_LIST, config),
    createConfig: params => Axios.post(ENDPOINTS.INST_CONFIGURATION.CREATE_CONFIG, params, config)
  },
  University: {
    createUniversity: params => Axios.post(ENDPOINTS.UNIVERSITY.CREATE_UNIVERSITY, params, config),
    getUniversity: params => Axios.get(ENDPOINTS.UNIVERSITY.GET_UNIVERSITY, config),
    editUniversity: params => Axios.post(ENDPOINTS.UNIVERSITY.EDIT_UNIVERSITY, params, config)
  },
  Electives: {
    getList: (id) => Axios.get(ENDPOINTS.ELECTIVES.GET_LIST(id), config),
    createElective: params => Axios.post(ENDPOINTS.ELECTIVES.CREATE_ELECTIVE, params, config)
  },
  CsvDownload: {
    getList: () => Axios.get(ENDPOINTS.CSV_DOWNLOAD.GET_LIST, config),
    createExcelList: params => Axios.post(ENDPOINTS.CSV_DOWNLOAD.CREATE_EXCEL_LIST, params, config)
  },
  EmailTemplate: {
    getTemplateList: () => Axios.get(ENDPOINTS.EMAIL_TEMPLATE.GET_EMAIL_TEMPLATE_LIST, config),
    getMailcount: () => Axios.get(ENDPOINTS.EMAIL_TEMPLATE.GET_EMAIL_COUNT, config),
    createTemplate: params => Axios.post(ENDPOINTS.EMAIL_TEMPLATE.CREATE_TEMPLATE, params, config),
    updateTemplate: params => Axios.post(ENDPOINTS.EMAIL_TEMPLATE.UPDATE_EMAIL_TEMPLATE, params, config),
    deleteTemplate: params => Axios.post(ENDPOINTS.EMAIL_TEMPLATE.DELETE_EMAIL_TEMPLATE, params, config),
    sendMails: params => Axios.post(ENDPOINTS.EMAIL_TEMPLATE.SEND_CAMPAIGN_MAILS, params, config),
    getTemplateDetails: params => Axios.get(ENDPOINTS.EMAIL_TEMPLATE.GET_CAMPAIGN_MAIL_DETAILS, { params, ...config }),
    getEmailTracking: params => Axios.get(ENDPOINTS.EMAIL_TEMPLATE.GET_CAMPAIGN_MAIL_TRACKING, { params, ...config })
  },
  EventRoundMapping: {
    createEventRoundMapping: params => Axios.post(ENDPOINTS.EVENT_ROUND_MAPPING.CREATE_EVENT_ROUND_MAPPING, params, config),
    getEventRoundMapping: params => Axios.get(ENDPOINTS.EVENT_ROUND_MAPPING.GET_EVENT_ROUND_MAPPING, { params, ...config }),
    deleteEventRoundMapping: params => Axios.post(ENDPOINTS.EVENT_ROUND_MAPPING.DELETE_EVENT_ROUND_MAPPING, params, config)
  },
  Recruiter: {
    User : {
      get : (params) => Axios.get(ENDPOINTS.RECRUITER.USER.GET, { ...config, params })
    },
    Company : {
      getCompany : (params) => Axios.get(ENDPOINTS.RECRUITER.COMPANY.GET_COMPANY, { ...config, params })
    },
    Connection: {
      getConnection: (params) => Axios.get(ENDPOINTS.RECRUITER.CONNECTION.GET_CONNECTION, { ...config, params }),
      inactiveConnection: (params) => Axios.post(ENDPOINTS.RECRUITER.CONNECTION.INACTIVE_CONNECTION, params, config),
      getRequests: (params) => Axios.get(ENDPOINTS.RECRUITER.CONNECTION.GET_REQUESTS, { ...config, params }),
      updateRequest: (data) => Axios.post(ENDPOINTS.RECRUITER.CONNECTION.UPDATE_REQUEST, data, config),
      getApplications: (params) => Axios.get(ENDPOINTS.RECRUITER.CONNECTION.GET_APPLICATIONS, { ...config, params }),
      updateApplication: (params) => Axios.get(ENDPOINTS.RECRUITER.CONNECTION.UPDATE_APPLICATION, { ...config, params }),
      getApplicationDetails: (params) => Axios.get(ENDPOINTS.RECRUITER.CONNECTION.GET_APPLICATION_DETAILS, { ...config, params }),
      getApplicationReferences: (params) => Axios.get(ENDPOINTS.RECRUITER.CONNECTION.GET_APPLICATION_REFERENCES, { ...config, params }),

    },
    inviteRecruiter : (data) => Axios.post(ENDPOINTS.RECRUITER.INVITERECRUITER , data, config),
    fetchInviteRecruiter : (params) => Axios.get(ENDPOINTS.RECRUITER.FETCH_INVITE_RECRUITER, { ...config, params })

  },
  OtpVerification : {
    sendOtp : params=> Axios.post(ENDPOINTS.OTP_VERIFICATION.SEND_OTP , params , config),
    retryOtp : params=> Axios.post(ENDPOINTS.OTP_VERIFICATION.RETRY_OTP , params , config),
    verifyOTP : params => Axios.post(ENDPOINTS.OTP_VERIFICATION.VERIFY_OTP , params , config),
    registerUser : params=> Axios.post(ENDPOINTS.OTP_VERIFICATION.REGISTER_USER , params , config),
    downloadOtpStudents : params =>Axios.get(ENDPOINTS.OTP_VERIFICATION.DOWNLOAD_OTP_STUDENTS , {params , ...config})
  },
  Kue: {
    search: (params) => Axios.get(ENDPOINTS.KUE.SEARCH, { ...config, params }),
    getJobDetails: (params) => Axios.get(ENDPOINTS.KUE.JOB_DETAILS(params.id), config)
  },
  Feedback: {
    request: (data) => Axios.post(ENDPOINTS.FEEDBACK.REQUEST, data, config),
    fetch: (params) => Axios.get(ENDPOINTS.FEEDBACK.FETCH, {...config, params}),
    accept: (data) => Axios.post(ENDPOINTS.FEEDBACK.ACCEPT, data, config)
  },
  Specialization: {
    fetch: (params) => Axios.get(ENDPOINTS.SPECIALIZATION.FETCH, {...config, params}),
    createSpecialization: params => Axios.post(ENDPOINTS.SPECIALIZATION.CREATE_SPECIALIZATION, params , config),
    deleteSpecialization: params => Axios.post(ENDPOINTS.SPECIALIZATION.DELETE_SPECIALIZATION, params , config),
  },
  CampusProfile : {
    fetch: (params) => Axios.get(ENDPOINTS.CAMPUS_PROFILE.FETCH, {...config, params}),
    createCampusProfile: params => Axios.post(ENDPOINTS.CAMPUS_PROFILE.CREATE_CAMPUS_PROFILE, params , config),
    updateCampusProfile: (data) => Axios.post(ENDPOINTS.CAMPUS_PROFILE.UPDATE_CAMPUS_PROFILE, data, config),
    fetchPastPlacement: (params) => Axios.get(ENDPOINTS.CAMPUS_PROFILE.FETCH_PAST_PLACEMENT, {...config, params}),
    createPastPlacement:  params => Axios.post(ENDPOINTS.CAMPUS_PROFILE.CREATE_PAST_PLACEMENT, params , config),
    updatePastPlacement: (data) => Axios.post(ENDPOINTS.CAMPUS_PROFILE.UPDATE_PAST_PLACEMENT, data, config),
    fetchReportOverview: (params) => Axios.get(ENDPOINTS.CAMPUS_PROFILE.FETCH_REPORT_OVERVIEW, {...config, params}),
  },
  PublicCampusProfile : {
    fetch: (params) => Axios.get(ENDPOINTS.PUBLIC_CAMPUS_PROFILE.FETCH, {...config, params}),
    fetchActiveProcess: (data) => Axios.post(ENDPOINTS.PUBLIC_CAMPUS_PROFILE.FETCH_ACTIVE_PROCESS,data, config),
  },
  recruiterConversation : {
    fetch: (params) => Axios.get(ENDPOINTS.RECRUITER_CONVERSATION.FETCH, {...config, params}),
    send: (params) => Axios.post(ENDPOINTS.RECRUITER_CONVERSATION.SEND, params, config),
    msgCount: (params) => Axios.get(ENDPOINTS.RECRUITER_CONVERSATION.MSG_COUNT, {...config, params}),

  },
}
