import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import { Row, Col,Card, CardBody, Modal, ModalBody, ModalFooter,ModalHeader, Spinner } from 'reactstrap'
import {UncontrolledCollapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';
import { Form, FormGroup, Label, Input, FormText , Popover, PopoverHeader} from 'reactstrap';
import { Loader } from '../../../global/Loader';
import { Select} from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import { uploadFile } from '../../../../actions';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import { SingleDatePicker } from '../../../global/Forms/DatePicker';
import { FileUpload } from '../../../global/FileUpload';
import { CONSTANTS } from '../../../../constants';
import TimePicker from 'rc-time-picker';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/information.svg';
import moment from 'moment';

import { Errorbar } from '../../../global/errorbar';

class OfferForm extends Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
      }

      getInitialState = ()=>{
        return ({
        company_id : this.props.data.campus_company_id || null ,
          offer_date : moment(this.props.data.offer_date).format('YYYY-MM-DD'),
          designationList: [],
          message : '',
          fileName:  this.props.data.fileUrl ||  null  ,
          file : '',
          errorbar: false,
          message: '',
          withdraw_deadline : this.props.data.withdraw_deadline ? moment(this.props.data.withdraw_deadline) : null,
          accept_deadline : this.props.data.accept_deadline ? moment(this.props.data.accept_deadline) : null,
          convertedRate : this.props.data.ctc_value!=null && this.props.data.currency_rate!=null?this.props.data.ctc_value * this.props.data.currency_rate : 0,
          ctc_value : this.props.data.ctc_value,
          conversionRate : this.props.data.currency_rate!=null?this.props.data.currency_rate : 1,
          popoverOpen : false,
          popoverName : '',
          loading : false,
          deleteModal : false
        })
      }

      async componentWillMount(){

        if(this.props.data.campus_company_id){
          let event = {
            target:{
              value: this.props.data.campus_company_id
            }
          }
          await this.change(event)
          if(this.role_id)
          this.role_id.value = this.props.data.role_id
        }
      }

      changeoffer_date = (offer_date) => {
        this.setState({
          offer_date:moment(offer_date).format('YYYY-MM-DD')
        })
      }



      closebar = () => {
        this.setState({ errorbar: false });
      }

      handleFileChange = (e) => {
        if ((e.target.files[0].size) / (1024 * 1024) <= CONSTANTS.FILE_SIZE_LIMIT) {
          this.setState({ fileName : e.target.files[0].name, file : e.target.files[0]  })
        }
        else {
         alert(`File size should be less than ${CONSTANTS.FILE_SIZE_LIMIT} MB`)
        }

      }

      removeSelectedFile = (prop) => {
        this.setState({ fileName: null })
      }

      change = async (event) =>{


        try {
          if(event.target.value.length <= 0){
            await this.setState({
              designationList: []
            });
            return
          }

          let designationList =[]
          let profileResp = await Auth.PtCompanies.getCompanyProfiles(event.target.value);
           designationList = profileResp.data.map(company => <option key={company.company_profile_id} value={company.company_profile_id}>{company.role_name}</option>)

          await this.setState({
            designationList: designationList
          });

        } catch (e) { console.log(e)
        this.setState({
          designationList:[]
        })}

      }


        handleSubmit =  async (event) => {
        if (!this.props.student_id) {
          return
        }


        this.props.loading(true)
        event.persist()
        event.preventDefault();

        let file_id = this.state.fileName? this.props.data.file : 0 ;

        if(this.state.file){
            try {
                let fileUploadResp = await uploadFile(this.state.file,  "offers/" + this.props.student_id)
                if(fileUploadResp.file_id ){
                  file_id = fileUploadResp.file_id
                }
            } catch (error) {
              console.log('Fail to upload', error)
              this.props.loading(false)
              alert(`Fail to save. Please try again `)
              return
            }
        }


        const formdata = {
          process_id:this.props.process_id,
          student_id:this.props.student_id,
          batch_id:this.props.batch_id,
          company_id: event.target.company_id.value,
          campus_company_id:event.target.company_id.value,
          role_id:event.target.role_id.value,
          offer_status: event.target.offer_status.value,
          offer_type: event.target.offer_type.value,
          designation:event.target.designation.value,
          currency:event.target.currency.value,
          ctc_value: event.target.ctc_value.value,
          location:event.target.location.value,
          offer_date:moment(this.state.offer_date).format('YYYY-MM-DD'),
          ctc_fixed_value:event.target.ctc_fixed_value.value? event.target.ctc_fixed_value.value: 0 ,
          varaible:event.target.varaible.value?event.target.varaible.value: 0,
          esop:event.target.esop.value? event.target.esop.value: 0,
          bonus:event.target.bonus.value? event.target.bonus.value: 0,
          file: file_id,
          withdraw_deadline : this.state.withdraw_deadline,
          accept_deadline : this.state.accept_deadline,
          currency_rate : this.state.conversionRate
        }


        if(formdata.length<= 0){
          return
        }

        try {

          if(this.props.data.offer_id){
            formdata['offer_id'] = this.props.data.offer_id
            let editOfferResp = await Auth.Offer.editOffer(formdata);

            if(editOfferResp.status==200){
              await this.props.handleClosed()
              await this.props.loadInit()
            }

          } else{
            let addOfferResp = await Auth.Offer.addOffer(formdata);
            if(addOfferResp.status==200){
              await this.props.handleClosed()
              await this.props.loadInit()
            }
          }

        } catch (e) {
          console.log(e)
        }

        this.props.loading(false)

      }

      deleteOffer = async(offer_id)=>{
        this.setState({loading : true});
        let deleteOfferParams = {
          offer_id : offer_id
        }
        try{
          await Auth.Offer.deleteOffer(deleteOfferParams);
          await this.props.handleClosed()
          await this.props.loadInit()
          this.setState({deleteModal : !this.state.deleteModal})
        }
        catch(e){
          this.setState({errorbar : true});
          if(e.response && e.response.data){
            this.setState({message : e.response.data.error.toString()})
          }
          else{
            this.setState({message : 'Something went wrong! Please try again'})
          }
        }
        this.setState({loading : false});
      }


  render() {
    let {loading ,deleteModal} = this.state;
    return (
        <div className="card p-2 mb-3">
        <Loader show={loading} />
            <Form onSubmit={this.handleSubmit}>
                <Row >
                    <Col xs={4} className="border-right">
                        <p>Offer Details  </p>
                        <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <label className="r-heading-7"><span className="r-text--red-1">*</span>Offer Type</label>
                                <Select defaultValue={this.props.data.offer_type} name="offer_type" required={true}>
                                  <option value="0">On-Campus</option>
                                  <option value="1">PPO</option>
                                  <option value="2">Off-Campus</option>
                                  <option value="3">Self Placed</option>
                                  <option value="4">Competition</option>
                                </Select>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                <label className="r-heading-7"><span className="r-text--red-1">*</span>Offer Status</label>
                                <Select defaultValue={this.props.data.offer_status} name="offer_status"  required={true}>
                                <option value="">Select</option>
                                <option value="1">Confirmed</option>
                                <option value="2">Waiting</option>
                                </Select>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                <label className="r-heading-7"><span className="r-text--red-1">*</span>Offer Date</label>
                                <SingleDatePicker value={this.state.offer_date}
                                onDateChange={this.changeoffer_date}
                                  />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <label className="r-heading-7">Accept Offer Deadline</label>                              
                                <Row>
                                  <Col xs={7}>
                                    <SingleDatePicker
                                      value={this.state.accept_deadline}
                                      onDateChange={(date) => this.setState({ accept_deadline: date })}
                                      />
                                  </Col>
                                  <Col xs={5}>
                                    <TimePicker
                                      onChange={(date) => this.setState({ accept_deadline: date })}
                                      value={this.state.accept_deadline}
                                      format="h:mm a"
                                      use12Hours
                                      showSecond={false}
                                      inputReadOnly />
                                  </Col>
                                </Row>
                              </FormGroup>

                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <label className="r-heading-7">Withdraw Deadline</label>
                                  <span className="withdraw_deadline ml-1" id="Popover-1" onClick={()=>this.setState({popoverOpen : !this.state.popoverOpen , popoverName : "Popover-1"})}><InfoIcon /></span>
                                    <Popover  className="w-100 h-auto  rounded" placement="right" isOpen={this.state.popoverOpen && this.state.popoverName=="Popover-1"} target="Popover-1"  toggle= {()=>this.setState({popoverOpen : !this.state.popoverOpen})}>
                                        <PopoverHeader className=" p-3 r-bg--light  w-100 ">
                                          <Row>
                                            <Col xs="10">

                                              <p className="r-subheading-4">If you allow your students to have multiple offers and pre-defined conditions are applicable on the student's applications after getting an offer, a withdrawal deadline can be given to the student asking him/ her to withdraw from any extra applications so that the pre-defined conditions are met.
                                              </p>
                                              <p className="r-subheading-4">Example - After 1st offer, the student is ONLY allowed 5 more applications, and if he has already applied to 10, the <b>withdrawal deadline</b> is the time given to the candidate to withdraw from excess 5 applicatons to meet the condition.</p>

                                            </Col>
                                            <Col xs="2">
                                              <button className="border-0 r-bg--light" onClick={()=>this.setState({popoverOpen: !this.state.popoverOpen})}>
                                                <i className="fi flaticon-plus-3 r-subheading-3"></i>
                                              </button>
                                            </Col>
                                          </Row>
                                        </PopoverHeader>
                                      </Popover>
                                <Row>
                                  <Col xs={7}>
                                    <SingleDatePicker
                                      value={this.state.withdraw_deadline}
                                      onDateChange={(date) => this.setState({ withdraw_deadline: date })}
                                      />
                                  </Col>
                                  <Col xs={5}>
                                    <TimePicker
                                      onChange={(date) => this.setState({ withdraw_deadline: date })}
                                      value={this.state.withdraw_deadline}
                                      format="h:mm a"
                                      use12Hours
                                      showSecond={false}
                                      inputReadOnly />
                                  </Col>
                                </Row>
                              </FormGroup>

                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                <label className="r-heading-7"> Upload Offer Letter</label>

                                 {
                                    this.state.fileName ?
                                    <div className="text-truncate r-tag r-tag--type-1">
                                     {this.state.fileName }
                                      <Button type="button"
                                        className="r-tag__delete fi flaticon-plus-1"
                                        onClick={() => this.removeSelectedFile('jd')}>
                                      </Button>
                                    </div>
                                   :
                                   <div className="uploadFile">
                                    <label
                                      className="r-btn r-btn--blue text-center d-block"
                                      htmlFor={`1000`}>
                                      <i className="mr-1 r-text--sm fi flaticon-plus-1" />
                                      Upload Offer Letter
                                    </label>
                                    <input
                                      name="file"
                                      onChange={this.handleFileChange}
                                      id={`1000`}
                                      type="file"
                                      className="d-none"
                                    />
                                  </div>
                                 }


                              </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={4} className="border-right">
                        <p>Role Details</p>
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                  <label className="r-heading-7"><span className="r-text--red-1">*</span> Company Name</label>
                                  <Select defaultValue={this.props.data.campus_company_id}  onChange={this.change} name="company_id" required={true}>
                                  <option value="">Select</option>
                                  {this.props.companyList}
                                  </Select>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                  <label className="r-heading-7"><span className="r-text--red-1">*</span>Job Role Offered</label>
                                  <Select
                                    inputref={(ref=>this.role_id=ref)} name="role_id" defaultValue={this.props.data.role_id}  required={true}>
                                    <option value="">Select</option>
                                    {this.state.designationList}
                                  </Select>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                  <label className="r-heading-7"><span className="r-text--red-1">*</span>Designation</label>
                                  <InputField defaultValue={this.props.data.designation} type="text" required={true} className="w-100 mb-2" name="designation"   placeholder="Ex: Web Developer" />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                  <label className="r-heading-7"><span className="r-text--red-1">*</span>Location</label>
                                  <InputField type="text" defaultValue={this.props.data.location} required={true} className="w-100 mb-2" name="location" placeholder="Ex: Delhi"/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                <Col xs={4}>
                    <p>Compensation Details</p>
                    <Row form>
                    <Col md={12}>
                        <FormGroup>
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>CTC Value</label>
                          <InputField type="text" defaultValue={this.props.data.ctc_value} onChange={(e)=>this.setState({ctc_value : e.target.value})} required={true} className="w-100 mb-2" name="ctc_value" placeholder="Ex: 10000"/>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Currency Type</label>
                          <Select   defaultValue={this.props.data.currency} name="currency" required={true}>
                          <option value="">Select</option>
                          <option value="INR">INR</option>
                          <option value="USD">USD</option>
                          <option value="AED">AED</option>
                          <option value="SAR">SAR</option>
                          <option value="BHD">BHD</option>
                          <option value="QAR">QAR</option>
                          <option value="QMR">QMR</option>
                          <option value="KWD">KWD</option>
                          <option value="SGD">SGD</option>
                          <option value="MYR">MYR</option>
                          <option value="HKD">HKD</option>
                          </Select>
                        </FormGroup>
                    </Col>
                      <Col xs={6}>
                          <FormGroup>
                            <label className="r-heading-7">Exchange Rate</label>
                            <InputField placeholder="INR Rate" defaultValue={this.props.data.currency_rate} onChange={(e)=>this.setState({convertedRate : this.state.ctc_value!=null?(parseFloat(this.state.ctc_value * e.target.value).toFixed(2)) : null , conversionRate : e.target.value})} className="w-100" />
                          </FormGroup>
                      </Col>
                      <Col xs={6}>
                          <FormGroup>
                            <Row>
                              <Col>
                                <label className="r-heading-7">Converted INR Value</label>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="m-2">
                                <span className="r-text--green-1">{this.state.conversionRate !=null && this.state.ctc_value? parseFloat(this.state.conversionRate * this.state.ctc_value).toFixed(2) : this.state.convertedRate}</span>
                              </Col>
                            </Row>
                          </FormGroup>
                      </Col>
                    <Col xs={12}>
                         <a href="#" id="toggler">More Fields</a>
                        <UncontrolledCollapse toggler="#toggler"  >
                        <Row >
                            <Col md={12}>
                                <FormGroup>
                                <label className="r-heading-7">CTC Fixed Value</label>
                                <InputField
                                type="text"
                                className="w-100 mb-2"
                                name="ctc_fixed_value"
                                defaultValue={this.props.data.ctc_fixed_value}
                                placeholder="CTC Fixed"
                                />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                <label className="r-heading-7">Variable</label>
                                <InputField
                                  type="tex"
                                  className="w-100 mb-2"
                                  name="varaible"
                                  defaultValue={this.props.data.varaible}
                                  placeholder="CTC Variable"/>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                <label className="r-heading-7">ESOP</label>
                                <InputField
                                  type="text"
                                  className="w-100 mb-2"
                                  name="esop"
                                  defaultValue={this.props.data.esop}
                                  placeholder="ESOP Value"/>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                <label className="r-heading-7">Bonus</label>
                                <InputField
                                  type="text"
                                  defaultValue={this.props.data.bonus}
                                  className="w-100 mb-2" name="bonus"

                                  placeholder="Bonus"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        </UncontrolledCollapse>
                    </Col>
                </Row>
                </Col>
            </Row>

            <hr></hr>
            <Row className="float-right">
              {(this.props.data && this.props.data.offer_id) && <Button type="button" color="dark" className="r-btn--no-radius r-text--sm mr-2" onClick={()=>this.setState({deleteModal : !this.state.deleteModal})} >Delete</Button >}
              <Button type="submit" color="dark" className="r-btn--no-radius r-text--sm" >Save</Button >
              <Button type="button" onClick={this.props.handleClosed}>  Cancel</Button>
            </Row>
            </Form>
            {this.props.data.offer_id &&
              <Modal zIndex={99999} isOpen={deleteModal} toggle={()=>this.setState({deleteModal : !this.state.deleteModal})} >
              <ModalBody toggle={()=>this.setState({deleteModal : !this.state.deleteModal})}>Are you sure you want to delete ?       </ModalBody>
              <ModalFooter>
                <Button type="button" color="dark" className="r-btn--no-radius r-text--sm mr-2" onClick={()=>this.deleteOffer(this.props.data.offer_id)} >Delete</Button>
                <Button type="button" onClick={()=>this.setState({deleteModal : !this.state.deleteModal})}>  Cancel</Button>
              </ModalFooter>
            </Modal>}

            <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
        </div>

    )
  }

}

export default OfferForm
