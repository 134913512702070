import React, {Component} from 'react';
import {Container , Row , Col , Button , Modal , ModalBody , ModalFooter , ModalHeader , Table , Card , CardBody , CardTitle, Collapse} from 'reactstrap';
import { Auth } from '../../../../actions/Auth';
import { connect } from 'react-redux';
import { RTE } from '../../../global/Forms/RTE';
import RButton from '../../../global/Button';
import  {InputField} from '../../../global/Forms';
import EmailCampaign from './email_campaign'
import { Loader } from '../../../global/Loader';
import {Errorbar} from '../../../global/errorbar';
import Aux from '../../../global/AuxComp';
import moment from "moment";
import { uploadFile, getFiles } from '../../../../actions';
import { ReactComponent as AttachmentLogo } from '../../../../assets/icons/attachment.svg';
import { CONSTANTS } from '../../../../constants';

class EmailCampaignOverview extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = ()=>{
    return(
      {
        companyData : [],
        templateDetailsObject : [],
        template : null,
        loading : false,
        startCampaignModal : false,
        templateModal : this.props.location.search ? !!new URLSearchParams(this.props.location.search).get("add") : false,
        viewModal : false,
        deleteModal : false,
        templateList : [],
        mailCountDetails : [],
        templateDetails : [],
        selectedTemplate : null,
        templateName : '',
        senderEmail : '',
        description : '',
        messagBody : '',
        viewEmailDetailCollapse : false,
        errorbar : false,
        message : '',
        attachment : null,
        attachmentFile : null,
        ptList: [],
        processList : [],
        file_id: null,
        deleteAttachment: [],
        isShowTracking: false
      }
    )
  }

  stringToHslColor = (str)=>{
    let hash = 0;
    if(str)
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
    let h = hash % 360;
    return {bg : 'hsl('+h+', '+80+'%, '+80+'%)', text : 'hsl('+(h)+', '+80+'%, '+30+'%)'};
  }

  getEmailTemplateList = async()=>{
    this.setState({loading : true});
    try{
      let templateResp = await Auth.EmailTemplate.getTemplateList();
      this.setState({templateList : templateResp.data});
    }
    catch(e){
      this.setState({ errorbar: true });
      if(e.response && e.response.data){
        this.setState({ message: e.response.data.error.toString()});
      }
      else{
        this.setState({message : 'Something went wrong'});
      }
    }
    this.setState({loading : false});
  }

  createNewTemplate = async(createTemplateParams)=>{
    this.setState({loading : true});
    try{
      await Auth.EmailTemplate.createTemplate(createTemplateParams);
      await this.setState(this.getInitialState());
      this.getEmailTemplateList();
      this.getTemplateMailCount();
    }
    catch(e){
      this.setState({ errorbar: true });
      if(e.response && e.response.data){
        this.setState({ message: e.response.data.error.toString()});
      }
      else{
        this.setState({message : 'Something went wrong'});
      }
    }
    this.setState({loading : false});
  }

  updateTemplate = async(updateTemplateParams)=>{
    this.setState({loading : true});
    try{
      await Auth.EmailTemplate.updateTemplate(updateTemplateParams);
      await this.setState(this.getInitialState());
      this.getEmailTemplateList();
      this.getTemplateMailCount();
    }
    catch(e){
      this.setState({ errorbar: true });
      if(e.response && e.response.data){
        this.setState({ message: e.response.data.error.toString()});
      }
      else{
        this.setState({message : 'Something went wrong! Please try again'});
      }
    }
    this.setState({loading : false});
  }

  deleteEmailTemplate = async(templateId)=>{
    this.setState({loading : true});
    let deleteParams= {
      template_id : templateId,
      status : 0
    }
    try{
      await Auth.EmailTemplate.deleteTemplate(deleteParams);
      await this.setState({selectedTemplate : null, deleteModal : false});
      this.getEmailTemplateList();
      this.getTemplateMailCount();
    }
    catch(e){
      this.setState({ errorbar: true });
      if(e.response && e.response.data){
        this.setState({ message: e.response.data.error.toString()});
      }
      else{
        this.setState({message : 'Something went wrong! Please try again'});
      }
    }
    this.setState({loading : false});
  }

  handleTemplateForm = async ()=>{
    let {templateName , senderEmail , selectedTemplate , description , messagBody, attachment, file_id, deleteAttachment} = this.state;
    if(templateName && description && messagBody){
      let params = {
        template_name: templateName,
        sender_email : senderEmail || "noreply@reculta.com",
        subject : description,
        html  : messagBody,
        template_type : "CAMPAIGN"
      }
      this.setState({loading : true});
      var fileId = file_id || null;
      if(deleteAttachment.length) await Auth.User.deleteFile(deleteAttachment);
      if(attachment && attachment.length){
        for(var i = 0; i < attachment.length; i++){
          var file = await uploadFile(attachment[i], `email_campaign`, fileId);
          fileId = file.file_id;
        }
        params.file_id = fileId
      }
      this.setState({loading : false});
      if(selectedTemplate){
        params.template_id = selectedTemplate
        this.updateTemplate(params);
      }
      else{
        this.createNewTemplate(params);
      }
    }else{
      this.setState({errorbar : true , message : 'Fill all the mandatory values'})
    }
  }
  editTemplate = async (template) => {
    var file = {};
    if(template.file_id){
      file = await getFiles([template.file_id])
    }
    this.setState({
      templateModal : true , 
      selectedTemplate : template.template_id , 
      templateName : template.template_name, senderEmail : 
      template.sender_email , 
      description : template.subject , 
      file_id: template.file_id,
      messagBody : template.html,
      attachmentFile : file[template.file_id] ? file[template.file_id] : null,
      deleteEmailTemplate: []
    })
  }
  closeCampaignModal = ()=>{
    this.setState({startCampaignModal : false});
  }

  handleViewTemplate = async(template)=>{
    this.setState({loading: true});
    let getTemplateDetailsParams = {
      template_id : template.template_id
    }
    try{
      let getTemplateDetailsResp = await Auth.EmailTemplate.getTemplateDetails(getTemplateDetailsParams);
      await this.filterCompanyData(getTemplateDetailsResp.data);
      this.setState({selectedTemplate : template.template_id , template  , viewModal : true});
    }
    catch(e){
      this.setState({ errorbar: true });
      if(e.response && e.response.data){
        this.setState({ message: e.response.data.error.toString()});
      }
      else{
        console.log(e);
        this.setState({message : 'Something went wrong! Please try again'});
      }
    }
    this.setState({loading: false});
  }

  filterCompanyData = async(templateData)=>{
    let templateDetailsObject = {} , emailArray =[] , companyIdArray = [];
    if(templateData.length>0){
      companyIdArray = templateData.map(template=>{return template.campus_company_id});
      templateData.map(template=>{
        templateDetailsObject[template.campus_company_id] = [];
        template.recipient_info.bcc.map(bcc=>{templateDetailsObject[template.campus_company_id].push({[bcc] : {"subject" : template.subject, "messagBody" : template.html}})});
        template.recipient_info.cc.map(cc=>{templateDetailsObject[template.campus_company_id].push({[cc] : {"subject" : [template.subject], "messagBody" : template.html}})});
      })
    }
    try{
      let companyResp = await Auth.PtCompanies.getCompanies(companyIdArray);
      await this.setState({companyData : companyResp.data});
    }
    catch(e){
      console.log(e);
    }
    this.setState({templateDetailsObject: templateData});
  }

  getCompanyList = async()=>{
    this.setState({loading : true});
    try{
      let companyResp = await Auth.PtCompanies.getCompanies();
      this.setState({companyData : companyResp.data});
    }
    catch(e){
      this.setState({ errorbar: true });
      if(e.response && e.response.data){
        this.setState({ message: e.response.data.error.toString()});
      }
      else{
        this.setState({message : 'Something went wrong'});
      }
    }
    this.setState({loading : false});
  }

  getPtList = async () => {
    try {
       let ptListResp = await Auth.Spoc.getUsers({ role_type: CONSTANTS.ROLE_TYPES_ID.PT, campus_id: this.props.role.instituteDetail.campus_id })
        await this.setState({ptList: ptListResp.data})
    } catch (e) {
      if(e.response.data && e.response.data.error) this.setError(true, e.response.data.error.toString())
      else this.setError(true, 'Sorry! something went wrong. Please try again')
    }
  }

  getProcessList = async () => {
    try {
       let ptListResp = await Auth.Processes.getProcesses(this.props.role.instituteDetail.campus_id)
        await this.setState({processList: ptListResp.data})
    } catch (e) {
      if(e.response.data && e.response.data.error) this.setError(true, e.response.data.error.toString())
      else this.setError(true, 'Sorry! something went wrong. Please try again')
    }
  }

  getTemplateMailCount = async()=>{
    let getMailCountResp = await Auth.EmailTemplate.getMailcount();
    this.setState({mailCountDetails : getMailCountResp.data});
  }

  handleTemplateClick = async(template)=>{
    await this.setState({startCampaignModal : !this.state.startCampaignModal , template})
    this.getCompanyList();
  }

  componentDidMount(){
    this.getEmailTemplateList();
    this.getTemplateMailCount();
    this.getPtList();
    this.getProcessList();
  }
  deleteExistingFile = (id) => {
    let {attachmentFile, deleteAttachment} = this.state;
    attachmentFile.splice(attachmentFile.findIndex(o => o.id === id), 1);
    deleteAttachment.push(id);
    this.setState({attachmentFile, deleteAttachment});
  }

  showEmailTracking = async (recipient_id) => {
    try {
      let trackingResp = await Auth.EmailTemplate.getEmailTracking({recipient_id})
       await this.setState({isShowTracking: trackingResp.data})
   } catch (e) {
     if(e.response.data && e.response.data.error) this.setError(true, e.response.data.error.toString())
     else this.setError(true, 'Sorry! something went wrong. Please try again')
   }
  }

  render(){
    let {templateList , startCampaignModal , template , templateModal , selectedTemplate , deleteModal ,loading ,  errorbar , message , viewModal , templateDetails , companyData , templateDetailsObject, viewEmailDetailCollapse  , mailCountDetails, attachmentFile} = this.state;
    return(
      <Container>
      <Loader show={loading} />
        <Row className="mt-0 pb-3 border-bottom align-items-center justify-content-between ">
          <Col xs="auto">
            <div className="heading-dropdown r-heading-1">
                    <div className="r-dropdown d-inline-block align-middle">
                      <span>Email Campaign</span>
                      <button  color="brandB" className="ml-4 r-text--sm ml-2 r-bg--brandB r-text--light  r-btn btn-brandB email_campaign__campaign_allignment" onClick={() => this.setState({
                          templateModal : true ,
                          selectedTemplate : null, 
                          templateName : '',
                          senderEmail : '',
                          description : 'Invite {company-name} to Participate in placement process',
                          messagBody : 'Dear {member-name},<br/>Our placement process is beginning in this month. We are welcoming {company-name} to participate.</br></div>{member-name}, you can contact us in case of any queries.',
                          attachmentFile: [],
                          deleteAttachment: []
                        })
                        }
                        >
                        Design Email Template
                      </button></div>
		              </div>
          </Col>
          <Modal centered={true} zIndex={99999} size="xl" isOpen={templateModal} toggle={() => this.setState({templateModal : !this.state.templateModal})}  >
          <ModalHeader  toggle={() => this.setState({templateModal : !this.state.templateModal})}>Email Template </ModalHeader>
          <ModalBody>
            <Row className="no-gutters">
              <Col>
                <label><span className="r-text--red-1">*</span>Template Name</label>
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col className="mb-3">
                <InputField placeholder="Template Name" value={this.state.templateName} className="w-100" onChange={(e)=>this.setState({templateName : e.target.value})} />
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col>
                <label>Sender email I’d</label>
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col className="mb-3">
                <InputField placeholder="Default : noreply@reculta.com" onChange={(e)=>this.setState({senderEmail : e.target.value})}  value={this.state.senderEmail} className="w-100"/>
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col>
                <label> <span className="r-text--red-1">*</span>Subject</label>
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col className="mb-3">
                <InputField onChange={(e)=>this.setState({description : e.target.value})} className="w-100" placeholder="Type Description here" value={this.state.description} />
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col>
                <label><span className="r-text--red-1">*</span>Message Body</label>
                <span className="float-right text-secondary" style={{fontSize: 10}}>*Use {`{member-name}`} and {`{company-name}`} to indicate where you plan to use member name and company name respectively.</span>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3 calendar__event-description-box">
                <RTE onChange={(value)=>this.setState({messagBody : value})} style={{height:250}}  hideToolbar={true} placeholder="Type Description here" value={this.state.messagBody} />
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col>
                <label>Attachment</label>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3 calendar__event-description-box">
                <input type="file" multiple onChange={(e)=>this.setState({attachment : e.target.files})}  />
                {attachmentFile && 
                attachmentFile.map(file => 
                  <div className="r-tag r-tag--type-1 mt-2" style={{width : 200}}>
                    <AttachmentLogo width={10} /><a href={file.url} target="_blank" rel="noopener noreferrer">{file.file_name}</a> <Button type="button" className="r-tag__delete fi flaticon-plus-1 text-dark" onClick={() => this.deleteExistingFile(file.id)}></Button>
                  </div>
                  )
                  
                }
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col>
                <Button color="dark" className="float-right" onClick={()=>this.handleTemplateForm()}> Save </Button>
              </Col>
            </Row>
          </ModalBody>
          </Modal>
          <Modal centered={true} className="modal-dialog-centered modal-lg" zIndex="99999" isOpen={startCampaignModal} toggle={()=>this.setState({startCampaignModal : !this.state.startCampaignModal})}  style={{width:'80%'}} >
            <ModalHeader  toggle={()=>this.setState({startCampaignModal : !this.state.startCampaignModal})} ><span className="r-heading-3">All Companies</span>  </ModalHeader>
            <ModalBody>
              {(template && companyData) && <EmailCampaign template={template} closeCampaignModal={this.closeCampaignModal} getTemplateMailCount={this.getTemplateMailCount} companyData={companyData} ptList={this.state.ptList} processList={this.state.processList} />}
            </ModalBody>
        </Modal>
        </Row>
        {templateList.map(template=>{
          let color = this.stringToHslColor(template.template_name);
          let sentCount = mailCountDetails.filter(temp=>{if(template.template_id == temp.template_id){return temp.mail_count}})
          return(
            <Row className="align-items-center company-card py-3 my-4 "   >
              <Col xs="auto" className="selectable" >
                <div className="rounded-circle text-center" style={{backgroundColor : color.bg, width:32, height:32, fontSize:18, color:color.text}}>
                  <span className="align-middle">{template.template_name && template.template_name.charAt(0)}</span>
                </div>
              </Col>
              <Col xs={5}>
                <Row>
                  <Col>
                    <Button className="align-middle r-subheading-3 p-0 m-0 r-text--blue-3" color="grey-4" onClick={()=>this.handleTemplateClick(template)}>{template.template_name && template.template_name}</Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="ml-1">
                    <span className="r-text--grey-4 r-subheading-4">{template.subject && template.subject }</span>
                  </Col>
                </Row>
              </Col>
              <Col xs={2}>
                <span className="r-text--grey-4 r-subheading-4">{moment(template.created_on).utcOffset("+05:30").format("ddd, Do YYYY, h:mm:ss a")}</span>
              </Col>
              <Col xs={2} className="text-center">
                {sentCount.length && sentCount[0].mail_count ?
                <RButton onClick={() =>this.handleViewTemplate(template)} >
                  <Row>
                    <Col>
                      <span className="r-text--blue-3 text-center r-subheading-2 mb-0 mt-1" color="blue-3">{sentCount.length>0?sentCount[0].mail_count : 0} </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="r-text--grey-4 text-center r-subheading-3">Sent</span>
                    </Col>
                    <Modal zIndex={99999} size="lg" isOpen={viewModal && (selectedTemplate==template.template_id)} toggle={()=>this.setState({viewModal : !this.state.viewModal})} >
                      <ModalBody>
                        <Table className="post" style={{fontSize: 12}}>
                          <thead>
                            <tr className="tableheader">
                            <th >Email</th>
                            <th >Company</th>
                            <th>Subject</th>
                            <th>Sent Date</th>
                            </tr>
                          </thead>
                          {/* {(companyData.length>0 && Object.keys(templateDetailsObject).length>0) && <tbody>
                            {Object.keys(templateDetailsObject).map(detail=>{
                              let companyDetail = companyData.filter(company=> company.campus_company_id == detail)[0] || '';
                              if(companyDetail){
                                return(
                                  templateDetailsObject[detail].map(item=>{
                                    console.log(item, "emailCampaign")
                                    return(
                                      <tr>
                                        <td>{Object.keys(item)[0]}</td>
                                        <td>{companyDetail.company_details.company_name}</td>
                                        <td>{item[Object.keys(item)[0]].subject}</td>
                                      </tr>
                                    )
                                  })
                                )
                              }

                            })}
                          </tbody>} */}
                          <tbody>
                          {
                            templateDetailsObject.map(sent => {
                              let companyDetail = companyData.filter(company=> company.campus_company_id == sent.campus_company_id)[0] || {};
                              let emails = [...sent.recipient_info.bcc, ...sent.recipient_info.to];
                              return  <tr>
                                        <td>
                                          {
                                            emails.map(obj => 
                                              <div>
                                                <span className="d-block-inline">{obj.email}</span>{'  '}
                                                <span className="d-block-inline text-right">{obj.delivered ? <span className="text-success">&#10004;</span> : null}{'  '}{obj.open_count ? <span className="r-text--blue-3 selectable" id={`Popover_${obj.id}`} onClick={() => this.showEmailTracking(obj.id)}>{obj.open_count}</span> : null}</span>
                                              </div>
                                            )
                                          }
                                        </td>
                                        <td>{companyDetail.company_details && companyDetail.company_details.company_name}</td>
                                        <td>{sent.subject}</td>
                                        <td>{moment(sent.created_on).utcOffset('+05:30').format("Do MMM'YY hh:mmA")}</td>
                                      </tr>
                            })
                          }
                          </tbody>
                          {companyData.length===0 && <tbody><tr><td><span className="r-text--red-1">*Company deleted</span></td><td></td><td></td></tr></tbody>}

                          {sentCount.length===0?
                              <tbody><tr><td><span className="r-text--red-1">*No Details Found</span></td><td></td><td></td></tr></tbody>:null
                          }
                        </Table>
                        <Modal zIndex={999999} centered isOpen={!!this.state.isShowTracking} toggle={() => this.setState({isShowTracking: false})}>
                          <ModalBody className="p-4">
                              <Row className="border-bottom">
                                <Col>Event</Col>
                                <Col>Timestamp</Col>
                              </Row>
                              {
                                this.state.isShowTracking && this.state.isShowTracking.map(obj => 
                                  <Row className="py-2">
                                    <Col style={{textTransform: "capitalize"}}>{obj.event}</Col>
                                    <Col>{moment(obj.created_on).utcOffset('+05:30').format("Do'MMM YYYY h:mA")}</Col>
                                  </Row>
                                )
                              }
                          </ModalBody>
                        </Modal>
                      </ModalBody>
                    </Modal>
                  </Row>
                </RButton>
                : 
                <RButton color={"link-blue"} className="r-subheading-3" onClick={()=>this.handleTemplateClick(template)} >Send</RButton>
                }
              </Col>
              <Col xs={2} className="text-center">
              <RButton onClick={() => this.editTemplate(template)} >
                  <Row>
                    <Col>
                      <span  className="r-text--blue-3 text-center r-subheading-3 mb-0 mt-1" color="blue-3"><i className="fi flaticon-edit" /></span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="r-text--grey-4 text-center r-subheading-3 mb-0 mt-1">Edit</span>
                    </Col>
                  </Row>
                </RButton>
                <RButton  onClick={()=>this.setState({selectedTemplate : template.template_id, deleteModal : !this.state.deleteModal})}>
                  <Row>
                    <Col>
                      <span className="r-text--blue-3 text-center r-subheading-3 mb-0 mt-1" color="blue-3" ><i className="fi flaticon-bin" /></span>
                    </Col>
                    <Modal zIndex={99999} isOpen={deleteModal && (selectedTemplate==template.template_id)} toggle={()=>this.setState({deleteModal : !this.state.deleteModal})} >
                      <ModalHeader >Are you sure you want to delete ?</ModalHeader>
                      <ModalFooter>
                        <Button color="dark" onClick={()=>this.deleteEmailTemplate(template.template_id)}>Delete</Button>
                        <Button className="r-bg--light r-text--dark" onClick={()=>this.setState({deleteModal : !this.state.deleteModal})}>Cancel</Button>
                      </ModalFooter>
                    </Modal>
                  </Row>
                  <Row>
                    <Col>
                      <span className="r-text--grey-4 text-center r-subheading-3 mb-0 mt-1">Delete</span>
                    </Col>
                  </Row>
                </RButton>
              </Col>
            </Row>

          )
        }
      )
    }
      <Errorbar showBar={errorbar} onClose={() => this.setState({errorbar : false})} message={message}/>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(EmailCampaignOverview);
