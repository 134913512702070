import React, { Component } from 'react';
import { Auth } from '../../../actions/Auth';
import { Row, Col, Container, CardHeader, Card, CardBody, CardFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Select , SelectList } from "../../global/Forms/Select";
import { InputField } from "../../global/Forms";
import { Loader } from '../../global/Loader';
import { Errorbar } from '../../global/errorbar';
import ReCAPTCHA from 'react-google-recaptcha';
import Aux from "../../global/AuxComp";
import { CONSTANTS } from '../../../constants';
import { getQueryParams } from '../../../actions';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import moment from 'moment';

class GooglePayRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return ({
      loading: false,
      visibleOtpGenerate: 1,
      isVerifiedGenerate: false,
      sendOtpStatus: null,
      verifyOtpmessage: '',
      userRegisterationMessage: '',
      verifyOTP: null,
      verifyOtpStatus: 1,
      contact: null,
      errorbar: false,
      message: '',
      dob: null,
      mcqAnswer : null,
      otherReason : ''
    })
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  generateOtp = async (retryOtp) => {
    this.setState({ loading: true });
    let { contact } = this.state;
    if (contact) {
      let sendOtpParams = {
        mobile_no: contact
      }
      try {
        if (retryOtp && retryOtp == 1) {
          await Auth.OtpVerification.retryOtp(sendOtpParams);
        }
        else {
          await Auth.OtpVerification.sendOtp(sendOtpParams);
        }
        this.setState({ sendOtpStatus: 1, verifyOtpmessage: ' OTP has been send to your contact number. Kindly check', visibleOtpGenerate: null });
      }
      catch (e) {
        this.setState({ errorbar: true });
        if (e.response && e.response.data) {
          this.setState({ message: e.response.data.error.toString() });
        }
        else {
          this.setState({ message: 'Something went wrong! Please try again' });
        }
      }
    } else {
      this.setState({ errorbar: true, message: 'Enter your contact number to generate OTP' });
    }
    this.setState({ loading: false });
  }

  handleOtpGenerate = async (e) => {
    e.preventDefault()
    let { contact } = e.target;
    await this.setState({ contact: contact.value });
    this.generateOtp(0);

  }

  onchangeGenerate = (value) => {
    if (value) {
      this.setState({ isVerifiedGenerate: true });
    }
    else {
      this.setState({ isVerifiedGenerate: false });
    }
  }

  handleOtpVerification = async (e) => {
    e.preventDefault();
    let { verifyOtp } = e.target;

    this.setState({ verifyOTP: verifyOtp.value })
    this.OtpVerification(verifyOtp.value);
  }

  OtpVerification = async () => {
    this.setState({ loading: true });
    let { contact, verifyOTP } = this.state;
    if (contact && verifyOTP) {
      let verifyOTPParams = {
        otp: verifyOTP,
        mobile_no: contact
      }
      try {
        await Auth.OtpVerification.verifyOTP(verifyOTPParams);
      }
      catch (e) {
        this.setState({ errorbar: true });
        if (e.response && e.response.data) {
          this.setState({ message: e.response.data.error.toString() });
        }
        else {
          this.setState({ message: 'Something went wrong! Please try again' });
        }
      }
    } else {
      this.setState({ message: 'Fill all the mandatory fields', errorbar: true });
    }
    this.setState({ loading: false });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    let { firstName, city, college, email, course ,  gender  } = e.target;
    let { verifyOTP, sendOtpStatus, dob , mcqAnswer , otherReason } = this.state;
    if (sendOtpStatus == 1) {
      let userInfo = {
        // email : email.value,
        gender : gender.value,
        first_name: firstName.value,
        city: city.value,
        college_name: college.value,
        // work_experience: work_experience.value,
        dob: dob ? moment(dob).format("YYYY-MM-DD") : null,
        otp: verifyOTP,
        course : course.value,
        answer : mcqAnswer
      }

      if(mcqAnswer=="Others"){
        userInfo.answer = otherReason;
      }
      this.isValid(userInfo, email.value );
    }
    else {
      this.setState({ errorbar: true, message: 'First enter your contact no. to verify it' });
    }
  }

  isValid = (userInfo, email) => {
    let valid = 1;
    Object.keys(userInfo).forEach(info => {
      if (!userInfo[info]) {
        valid = 0;
      }
    })
    if (valid == 1) {

      if (email) { userInfo.email = email };
      this.userRegistration(userInfo);
    }
    else {
      this.setState({ errorbar: true, message: 'Fill all the mandatory details' });
    }
  }

  userRegistration = async (userInfo) => {
    this.setState({ loading: true });
    let { verifyOTP, contact } = this.state;
    let registerUserParams = { ...userInfo };
    registerUserParams.mobile_no = contact;
    registerUserParams.purpose = "google_pay" ;
    try {
      // await this.OtpVerification();
      await Auth.OtpVerification.registerUser(registerUserParams);
      await this.setState(this.getInitialState());
      await this.setState({ userRegisterationMessage: 'You have successfully registered', visibleOtpGenerate: null });

    }
    catch (e) {
      this.setState({ errorbar: true });
      if (e && e.response && e.response.data) {
        this.setState({ message: e.response.data.error.toString() });
      }
      else {
        this.setState({ message: 'Something went wrong! Please try again' });
      }
    }
    this.setState({ loading: false });
  }




  render() {
    let { errorbar, message, contact, verifyOtpmessage, sendOtpStatus, verifiedOtpMessage, userRegisterationMessage, verifyOtpStatus, visibleOtpGenerate, loading, dob , mcqAnswer } = this.state;
    return (
      <Container>
        <Loader show={loading} />
        <Row>
          <Col align="center" className="m-4">
            {userRegisterationMessage ? <span className="r-text--green-1">{'*'}{userRegisterationMessage}</span> : null}
          </Col>
        </Row>
        <Row >
          <Col md="3" className="d-none" xs={5}>
            <img className="img-fluid logo-size" src="/images/paytm.png" alt="Netambit" />
          </Col>
          <Col xs={10} md={9} className="recruitment-drive">
            <Row>
              <Col>
                <span>Google Pay Live Project</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span></span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            {!userRegisterationMessage ? <Card>
              <CardHeader className="r-text--blue-3 r-heading-6" align="start">Registration Form</CardHeader>
              <CardBody>
                <form onSubmit={this.handleFormSubmit}>
                  <Row>
                    <Col align="start">
                      <label><span className="r-text--red-1">*</span>Full Name</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <InputField placeholder="Full Name" name="firstName" className="w-100" />
                    </Col>
                  </Row>
                  <Row>
                    <Col align="start">
                      <label><span className="r-text--red-1">*</span>Date of Birth</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <SingleDatePicker
                        value={dob}
                        onDateChange={(date) => this.setState({ dob: date })}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col align="start">
                      <label><span className="r-text--red-1">*</span>Gender</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <select  className=" r-subheading-4 calendar__description-box r-text--grey-4 w-100 p-2"  name="gender">
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </Col>
                  </Row>
                  {sendOtpStatus == null ? <Aux>
                    <Row>
                      <Col align="start">
                        <label><span className="r-text--red-1">*</span>Contact No.</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3" xs={8}>
                        <InputField placeholder="Contact No." name="contact" onChange={(e) => this.setState({ contact: e.target.value })} className="w-100" />
                      </Col>
                      <Col className="mb-3" xs="auto">
                        <Button color="dark" type="button" onClick={() => this.generateOtp(0)} className="m-2">Generate OTP</Button>
                      </Col>
                    </Row>
                  </Aux> : null}
                  {<Aux>
                    <Row>
                      <Col align="start">
                        <label><span className="r-text--red-1">*</span>OTP</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3">
                        <InputField placeholder="Enter OTP" name="verifyOTP" onChange={(e) => this.setState({ verifyOTP: e.target.value })} className="w-100" />
                      </Col>
                    </Row>
                  </Aux>}
                  {sendOtpStatus == 1 ?
                    <Row>
                      <Col>
                        {verifyOtpmessage ? <span className="r-text--green-1 r-subheading-4 mb-2">{"*"}{verifyOtpmessage}</span> : null}
                      </Col>
                    </Row> : null}
                  {sendOtpStatus == 1 ? <button type="button" onClick={() => this.generateOtp(1)} className="border-0 r-bg--light r-text--blue-3 r-btn r-btn--link mb-3">Didn't recieve OTP, send again? </button> : null}
                  <Row>
                    <Col align="start">
                      <label><span className="r-text--red-1">*</span>Current City</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                    <select name="city" className=" r-subheading-4 calendar__description-box r-text--grey-4 w-100 p-2">
                      <option value="">Select</option>
                      {CONSTANTS.G_PAY.city.map(c=>
                        <option value={c}>{c}  </option>
                      )}
                    </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col align="start">
                      <label><span className="r-text--red-1">*</span>Name of Institution</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <InputField placeholder="Name of Institution" name="college" className="w-100" />
                    </Col>
                  </Row>
                  <Row>
                    <Col align="start">
                      <label><span className="r-text--red-1">*</span>Course</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                    <select  className=" r-subheading-4 calendar__description-box r-text--grey-4 w-100 p-2"  name="course">
                      <option value="">Select</option>
                      {CONSTANTS.G_PAY.course.map(co=>
                        <option value={co}> {co} </option>
                      )}
                    </select>
                    </Col>
                  </Row>
                  {/*<Row>
                    <Col align="start">
                      <label><span className="r-text--red-1">*</span>Fulltime Work Experience(Months)</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <InputField type="number" placeholder="Work Experience" name="work_experience" className="w-100" />
                    </Col>
                  </Row>*/}
                  <Row>
                    <Col align="start">
                      <label><span className="r-text--red-1">*</span>Email</label>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mb-3">
                      <InputField placeholder="Email" name="email" className="w-100" />
                    </Col>
                  </Row>
                  <Row>
                    <Col align="start">
                      <label><span className="r-text--red-1">*</span>Why do you want to take this internship?</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                    <select  className=" r-subheading-4 calendar__description-box r-text--grey-4 w-100 p-2" onChange={(e)=>this.setState({mcqAnswer : e.target.value})}>
                      <option value="">Select</option>
                      {CONSTANTS.G_PAY.mcq_answers.map(item=>
                        <option value={item}>{item}</option>
                      )}
                      <option value="Others">Others</option>
                    </select>
                    </Col>
                  </Row>
                  {mcqAnswer=="Others" ?
                    <Row>
                      <Col className="mb-3">
                        <textarea name="otherReason" placeholder="Other Reason" cols="5" rows="3" className="w-100" onChange={(e)=>this.setState({otherReason : e.target.value})}></textarea>
                      </Col>
                    </Row>:null
                  }
                  <Button color="dark">Submit</Button>
                  {sendOtpStatus == 1 ? <Button color="light" type="button" className="ml-3 border" onClick={() => this.setState(this.getInitialState())}>Cancel</Button> : null}
                </form>
              </CardBody>
            </Card> : null}
          </Col>
        </Row>
        <Errorbar showBar={errorbar} onClose={this.closebar} message={message} />
      </Container>
    )
  }
}

export default GooglePayRegistration;
