import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container,Collapse} from 'reactstrap';
import Button from '../../global/Button';
import { InputField } from '../../global/Forms';
import { Select } from '../../global/Forms/Select';
import { Switch } from '../../global/Forms/ToggleSwitch';


  export default class AddCourseCollapse extends Component{
    constructor(props){
       super(props);
         this.state = { isOn: false, degreeList:this.props.degreeList || [] };
     }

	  handleToggle(e) {
  		this.setState({isOn: !this.state.isOn});
  	}


    render(){
      let { campusKey, courseKey, state, appendCreateCourseData, removeCourse } = this.props;
      let { degreeList } = this.state;
      return (
        <Collapse isOpen={true}>
          <Row className="align-items-center">
            <Col xs={6} className="offset-2 pt-2" >
              <h1 className="r-subheading-4">Degree Name</h1>
              {/*<InputField list="degree" onChange={(e) => appendCreateCourseData(campusKey,courseKey, 'name', e.target.value)} className="w-100" type="text" />*/}
              <Select  onChange={(e) => appendCreateCourseData(campusKey,courseKey, 'degree_id', e.target.value)}  >
                <option >Select Degree</option>
                {
                  degreeList.map(degree => {
                    return (
                      <option  key={degree.degree_id} value={degree.degree_id}>{degree.degree_name}</option>
                        )
                    })
                }
              </Select>

            </Col>
            <Col xs={1}>
              <h1 className="r-subheading-4">Status</h1>
              <div className="mt-3">
              <Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)} />
              </div>
            </Col>
            <Col xs={2} className="align-middle">
            </Col>
            <Col xs={1}>
              <Button onClick={()=>removeCourse(campusKey, courseKey)} className="r-text--sm ml-3"><i className="d-inline-block fi flaticon-plus-1 rotate--45 mr-2"></i></Button>
            </Col>
          </Row>
        </Collapse>
    )
  }
}
