import React, { Component } from 'react';
import moment from 'moment';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../../actions/Auth';
import { ToggleButton } from '../../../global/Forms/ToggleButton';
import Button from '../../../global/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { InputField } from '../../../global/Forms';
import { uploadFile, getFullName } from '../../../../actions';
import store from '../../../../store';
import { Loader } from '../../../global/Loader';
import { Errorbar } from '../../../global/errorbar';
import PaginationComponent from '../../../global/PaginationComponent.js';
import { Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Link from 'react-router-dom/Link';
import { CONSTANTS } from '../../../../constants';
import { Select, SelectList } from '../../../global/Forms/Select';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Card from 'reactstrap/lib/Card';
import Collapse from 'reactstrap/lib/Collapse';
import Table from 'reactstrap/lib/Table';
import ModalFooter from 'reactstrap/lib/ModalFooter';

class TableST extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      rowsPerPage: 10,
      batchData: props.tableData,
      selected: {},
      toggledrow: {},
      deleteModal: false,
      uploadModal: false,
      uploadedFile: null,
      loader: false,
      cvName: '',
      selectAll: 0,
      errorbar: false,
      message: '',
      selectedStudent: null,
      query: "",
      cvCategories: [],
      studentApplicationRestriction : false,
      processList : {},
      classificationList : []
    };
  }

  componentWillReceiveProps(props) {
    if (JSON.stringify(props.tableData) !== JSON.stringify(this.props.tableData))
      this.setState({ currentPage: 0, batchData: props.tableData });
  }

  componentDidMount() {
    Auth.Student.getCVCategories().then(resp => {
      this.setState({ cvCategories: resp.data })
    });
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  method() {
    this.setState({
      selected: {},
      selectAll:0
    })
  }


  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onSearch = (target) => {
    this.setState({ query: target.value });
    if (target.value !== "") {
      this.setState({ currentPage: 0 });
    }
  }


  selectRow(firstName) {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[firstName] = !this.state.selected[firstName];
    var selectedStudentId = Object.keys(newSelected).filter(id => newSelected[id]);
    this.setState({
      selected: newSelected,
      selectAll: 2
    });
    this.props.onSelectStudent(selectedStudentId)
  }



  toggleRow = async (row) => {
    try {
      await Auth.Student.studentFreeze({ "student_id": row.student_id, "freeze_status": (row.freeze_status === 0 ? 1 : 0) });
    } catch (e) {
      console.log(e);
    }
  }

  toggleSelectAll() {
    let { currentPage, rowsPerPage, selectAll } = this.state;
    let newSelected = {};

    if (selectAll === 0) {
      this.props.tableData.forEach(x => {
        newSelected[x.student_id] = true;
      });
      this.props.handleAllSelect(1);
    }
    else {
      this.props.handleAllSelect(0);
    }

    this.setState({
      selected: newSelected,
      selectAll: selectAll === 0 ? 1 : 0
    });

  }

  toggleDelete = (e) => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal
    }));
  }



  handleSelected = (selectedPage) => {
    this.setState({ currentPage: selectedPage - 1 });
  }

  toggleuploadOpen = (st) => {
    this.setState({ uploadModal: true, selectedStudent: st });
  }

  toggleuploadClose = () => {
    this.setState({ uploadModal: false,uploadedFile:null, selectedStudent: null });
  }

  handleUploadFile = async (event) => {
    if (event.target.files[0].type !== 'application/pdf') {
      this.setState({ errorbar: true });
      this.setState({ message: "Resume should be pdf" });
    }
    else if ((event.target.files[0].size / (1024 * 1024)) <= CONSTANTS.FILE_SIZE_LIMIT) {
      let x = document.getElementById("resumeNameUpload")
      if (x && !x.value) x.value = event.target.files[0].name;
      this.setState({
        uploadedFile: event.target.files[0]
      })
    }
    else {
      this.setState({ errorbar: true });
      this.setState({ message: "File size it too large" });
    }
  }


  addCustomResume = async (e) => {
    e.preventDefault();
    const { selectedStudent, loader, uploadedFile } = this.state,
      { cv_name, cv_category_id } = e.target;
    this.setState({ loader: true });
    try {
      let newFile = await uploadFile(uploadedFile, "cv_upload/" + selectedStudent)
      if (newFile.file_id) await Auth.Student.createResume({ file_id: newFile.file_id, cv_name: cv_name.value || newFile.file_name, student_id: selectedStudent, cv_type: "UPLOADED", cv_category_id: cv_category_id.value })
      this.toggleuploadClose()
      this.setState({ loader: false, uploadedFile: null});
      this.props.handleUploadFile();
    } catch (e) {
      console.log(e)
      this.setState({ loader: false });
    }

  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  onChangeAppRestriction = async (status, blockMessage) => {
    let { studentApplicationRestriction } = this.state;
    if(status !== null) {
      status = parseInt(status);
      studentApplicationRestriction.show_application = status;
    }
    studentApplicationRestriction.block_message = blockMessage;
    this.setState({loader : true})
    try{
      if(status === 2){
        var processResp = await Auth.Processes.getProcesses(store.getState().role.instituteDetail.campus_id);
        var classificationResp = await Auth.Classification.listClassificationDetails();
        var processList = processResp.data.reduce((prev, curr) => {prev[curr.process_id] = curr; return prev;}, {})
        this.fetchBlockedApplicationRules();
        this.setState({processList, classificationList : classificationResp.data})
      }
      console.log(studentApplicationRestriction);
      this.setState({studentApplicationRestriction})
    }
    catch(e){
      this.setState({errorbar : true, message: "Something went wrong!" });
    }
    this.setState({loader : false})
  }

  onSubmitApplicationRestriction = async () => {
    let { studentApplicationRestriction } = this.state;
    if(studentApplicationRestriction.show_application === 2 && !(studentApplicationRestriction.blockedApplication && studentApplicationRestriction.blockedApplication.filter(o => o.pending_application_count > 0).length)){ 
      this.setState({errorbar : true, message: "There is no active restrictions, Please add first" });
      return;
    }
    await this.props.changeApplicationStatus(studentApplicationRestriction)
    this.setState({studentApplicationRestriction: false})
  }

  addApplicationRestriction = async () => {
    let { studentApplicationRestriction } = this.state;
    let { isAddNew, student_id } = studentApplicationRestriction;
    if(!isAddNew.process_id){
      this.setState({errorbar : true, message: "Please select a Placement Drive" });
      return;
    }
    if(!isAddNew.application_count){
      this.setState({errorbar : true, message: "Please enter the count of applications to be block" });
      return;
    }
    this.setState({loader : true});
    try {
       var body = {
         student_id,
         process_id: isAddNew.process_id,
         classification_id: isAddNew.classification_id,
         application_count: isAddNew.application_count,
         show_application: 2
      }
      await Auth.Student.addApplicationBlockRule(body);
      this.fetchBlockedApplicationRules();
      studentApplicationRestriction.isAddNew = false;
      this.setState({studentApplicationRestriction})
    }
    catch(e){
      this.setState({errorbar : true, message: "Something went wrong!" });
    }
    this.setState({loader : false})
  }
  deleteApplicationBlockRule = async (student_blocked_application_count_id) => {
    let { studentApplicationRestriction } = this.state;
    this.setState({loader : true})
    try {
      var body = {
        student_blocked_application_count_id, is_active : "0"
      }
      
      await Auth.Student.deleteApplicationBlockRule(body);
      studentApplicationRestriction.blockedApplication = studentApplicationRestriction.blockedApplication.filter(o => o.student_blocked_application_count_id !== student_blocked_application_count_id)
      this.setState({studentApplicationRestriction});
    }
    catch(e){
      this.setState({errorbar : true, message: "Something went wrong!" });
    }
    this.setState({loader : false})

  }
  fetchBlockedApplicationRules = async () => {
    let { studentApplicationRestriction } = this.state;
    let { student_id } = studentApplicationRestriction;
    try {
      var blockedApplicationResp = await Auth.Student.fetchApplicationBlockRule({student_id});
      studentApplicationRestriction.blockedApplication = blockedApplicationResp.data;
      this.setState({studentApplicationRestriction});
    }
    catch(e){
      this.setState({errorbar : true, message: "Something went wrong!" });
    }
  }

  render() {
    let { columns, tableData } = this.props;
    let { currentPage, rowsPerPage,uploadedFile, query, uploadModal, loader, errorbar, message, selectAll, selected, cvName, cvCategories, studentApplicationRestriction } = this.state;
    let { processList, classificationList } = this.state;
    let campus = store.getState().role.instituteDetail.institute_name
    let length = rowsPerPage * currentPage;
    let data = tableData.filter(row => {
      let name = getFullName(row);
      return name.toLowerCase().includes(query.toLowerCase()) || row.roll_no.toLowerCase().includes(query.toLowerCase())
    });
    let classificationById = {};
    classificationList.forEach(category => 
      category.classification_list.forEach((classification, i) => {
      classificationById[classification.classification_id] = classification;
      })
    )
    return (
      <div>
        <Loader show={loader} />
        <Row className="align-items-center mb-3 scroll-x" style={{ overflowY: 'hidden', border: '2px solid black', borderColor: "#c4dcf3" }}>
          <table>
            <thead id="tableColumn">
              <tr className="tableheader">
                {columns.map((col, i) => {
                  if (col.id === "sno") {
                    return (
                      <th key={i} className='tableheadbox text-center'>
                        <Row className="justify-content-center align-items-center pr-3">
                          <input type="checkbox"
                            checked={selectAll === 1}
                            name="selectAll"
                            ref={input => {
                              if (input) {
                                input.indeterminate = selectAll === 2;
                              }
                            }}
                            onChange={() => this.toggleSelectAll()} />
                          <span className="r-subheading-3 px-3">S. No.</span>
                        </Row>
                      </th>
                    )
                  }
                  else if (col.id === "name") {
                    return (<th key={i} className='tableheadboxsearch'>
                      <Row className="justify-content-start align-items-center ">
                        <Col xs="auto" className="px-2 borderBottomBlack">
                          <InputField className="tableinput r-nav__search" placeholder="Search Name or Roll Number" type="text" style={{ background: "#c4dcf3" }} onChange={({ target }) => this.onSearch(target)} />
                          <span className="fi flaticon-search"></span>
                        </Col>
                        <Button onClick={() => this.props.sortByRoll(col.sortKey)} className="rotate--90">
                          <i className="d-inline-block r-text--sm fi flaticon-arrow-1 " />
                          <i className="d-inline-block r-text--sm fi flaticon-arrow  " />
                        </Button>
                      </Row>
                    </th>)
                  }
                  else if (col.id === "roll_no") {
                    return (
                      <th className='tableheadbox' key={i}>
                        {col.title}
                        <Button onClick={() => this.props.sortByRoll(col.sortKey)} className="rotate--90">
                          <i className="d-inline-block r-text--sm fi flaticon-arrow-1 " />
                          <i className="d-inline-block r-text--sm fi flaticon-arrow  " />
                        </Button>
                        {this.props.isShow ? <Button onClick={(e) => this.props.removeColumn(i)}> <i className="d-inline-block fi flaticon-plus-1 rotate--45" /></Button> : null}
                      </th>)
                  }
                  else {
                    return (
                      <th className='tableheadbox' key={i}>
                        {col.title}
                        {this.props.isShow ? <Button onClick={(e) => this.props.removeColumn(i)}> <i className="d-inline-block fi flaticon-plus-1 rotate--45" /></Button> : null}
                      </th>
                    )
                  }
                }
                )}
              </tr>
            </thead>
            <tbody>
              {
                data.slice(parseInt(currentPage) * parseInt(rowsPerPage), parseInt(currentPage) * parseInt(rowsPerPage) + parseInt(rowsPerPage)).map((row, i) => {
                  length = length + 1;
                  return (

                    <tr
                      className="borderBottomlightblack"
                      key={i}>
                      {
                        columns.map(col => {
                          if (col.id === "name") {
                            return (
                              <th key={col.id} className='tableheadboxsearch px-2' >
                                <Row noGutters className="align-items-center">
                                  <Col xs="9">
                                    <div onClick={(e) => this.props.stProfile(row,'profile')} className="d-inline-block r-text--dark tablestudent__studentstatus" >{getFullName(row)}</div>
                                  </Col>
                                  <Col xs="3" >
                                    <Row className="justify-content-center align-items-center">
                                      <Col xs="auto" className="px-2">
                                        <Button onClick={(e) => this.props.editStudent(row)} className="text-center font-weight-normal px-2 py-0" color="blue"><i className=" r-text--sm fi flaticon-edit" /></Button>
                                      </Col>
                                      <Col xs="auto" className="px-2">
                                        <Button onClick={(e) => this.props.deleteStudent(row)} className="text-center font-weight-normal px-2 py-0" color="blue"><i className=" r-text--sm fi flaticon-bin" /></Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </th>
                            )
                          }
                          else if (col.id === "freeze_status") {
                            return (
                              <th key={col.id} className='tableheadbox align-items-center'>
                                <ToggleButton buttonType="pt" selected={row[col.id]} type onToggle={() => this.props.toggleRow(row)} values={{ value1: " ", value2: !row[col.id] ? "Can Edit" : "Frozen" }} />
                              </th>)
                          }
                          else if (col.id === "sno") {
                            return (
                              <td className='pl-4 tableheadbox text-center' key={col.id}>
                                <Row className="ml-5 align-items-center">
                                  <input name="selectedStudent" type="checkbox" value={row.student_id}
                                    checked={selected[row.student_id] === true}
                                    onChange={() => this.selectRow(row.student_id)} />
                                  <span className="r-subheading-3 px-3">{length}</span>
                                </Row>
                              </td>)
                          }
                          else if (col.id === "year_of_passout") {
                            return (
                              <td className='tableheadbox' key={col.id}>
                                {moment(row[col.id]).format(" YYYY")}
                              </td>)
                          }
                          else if (col.id === "campus_name") {
                            return (
                              <td className='tableheadbox' key={col.id}>
                                {campus}
                              </td>)
                          }
                          else if (col.id === "show_application") {
                            return (
                              <th key={col.id} className='tableheadbox align-items-center'>
                                <ToggleButton buttonType="pt" selected={row[col.id]} type onToggle={() => this.setState({studentApplicationRestriction: {...row}})} values={{ value1: " ", value2: row[col.id] ? (row[col.id] === 2 ? "Blocked *": "Blocked") : "Can View" }} />
                                {/* {row[col.id] === 2 && <Button color="link-blue d-block self-align-center r-text--sm">View Restrictions</Button>} */}
                              </th>)
                          }
                          else if (col.id === "cv_count") {
                            return (
                              <th key={col.id} className='pl-4 tableheadbox text-center'  onClick={(e) => this.props.stProfile(row,'resumes')}>
                                <Row className="ml-2 align-items-center">
                                  <span className="r-subheading-3 px-3 tablestudent__studentstatus">{(row[col.id] > 1) ? (row[col.id] - 1) : 0}</span>
                                </Row>
                              </th>)
                          }
                          else if (col.id === "cv_upload") {
                            return (
                              <th key={col.id} className='tableheadbox align-items-center'>
                                <Row onClick={(e) => this.toggleuploadOpen(row.student_id)} className=" align-items-center font-weight-normal mx-3 py-1  px-3  " color="outline-dark-v">
                                  <i className=" r-text--xl fi flaticon-upload r-text--grey-4" style={{ fontSize: "16px" }} />
                                </Row>
                              </th>)
                          }
                          else {
                            return (
                              <td className='tableheadbox' key={col.id}>
                                {row[col.id]}
                              </td>
                            )
                          }
                        })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <Modal className="modal-lg" onOpened={() => {this.onChangeAppRestriction(studentApplicationRestriction.show_application, studentApplicationRestriction.block_message)}} centered={true} zIndex={99999} isOpen={!!studentApplicationRestriction} toggle={() => this.setState({studentApplicationRestriction: false})}>
            <ModalHeader toggle={() => this.setState({studentApplicationRestriction: false})}>Student Applications Resrictions</ModalHeader>
            <ModalBody>
              <SelectList selected={CONSTANTS.APPLICATION_BLOCK_STATUS[studentApplicationRestriction.show_application]}>
                {Object.keys(CONSTANTS.APPLICATION_BLOCK_STATUS).map(key => 
                  <li value={key} onClick={() => this.onChangeAppRestriction(key)}>{CONSTANTS.APPLICATION_BLOCK_STATUS[key]}</li>
                )}
              </SelectList>

              {studentApplicationRestriction.show_application != 0 &&
                <div>
                  <InputField className="mt-3 w-100" type="text" placeholder="Enter the reason to block student" value={studentApplicationRestriction.block_message} onChange={(e) => {this.onChangeAppRestriction(null, e.target.value)}} />
              </div> }

              {studentApplicationRestriction.show_application === 2 && <>
              <Button color="blue r-text--sm mt-3" onClick={() => {studentApplicationRestriction.isAddNew = {}; this.setState({studentApplicationRestriction})}}>Add Restriction</Button>
              <Collapse isOpen={!!studentApplicationRestriction.isAddNew}>
                {!!studentApplicationRestriction.isAddNew && <Card className="p-3 mt-3">
                  <label className="mt-3">Placement Drive<span className="r-text--red-1"> *</span></label>
                  <SelectList selected={studentApplicationRestriction.isAddNew.process_id ? processList[studentApplicationRestriction.isAddNew.process_id].process_name : "Select Placement Drive"}>
                    <li>Select Placement Drive</li>
                    {Object.keys(processList).map(processId => 
                      <li onClick={() => {studentApplicationRestriction.isAddNew.process_id = processId; this.setState(studentApplicationRestriction);}}>{processList[processId].process_name}</li>
                    )}
                  </SelectList>
                  <label className="mt-3">Classification</label>
                  <SelectList selected={studentApplicationRestriction.isAddNew.classification_id ? classificationById[studentApplicationRestriction.isAddNew.classification_id].name : "Select Classification"}>
                    <li>Select Classification</li>
                    {classificationList.map(category => 
                      category.classification_list.map((classification, i) => 
                        <>
                         {i === 0 && <li style={{fontSize: "bold", color: "#ccc", pointerEvents: "none"}}>{category.name}</li>}
                          <li onClick={() => {studentApplicationRestriction.isAddNew.classification_id = classification.classification_id; this.setState(studentApplicationRestriction);}}>{classification.name}</li>
                        </>
                      )
                    )}

                  </SelectList>
                  <label className="mt-3">Applications to be block<span className="r-text--red-1"> *</span></label>
                  <InputField type="number" placeholder="Enter the number of applications to be restrict" value={studentApplicationRestriction.isAddNew.application_count || ""} onChange={(e) => {studentApplicationRestriction.isAddNew.application_count = e.target.value; this.setState(studentApplicationRestriction);}} />
                  <div className="text-right mt-3">
                    <Button className="r-text--sm" onClick={() => {studentApplicationRestriction.isAddNew = false; this.setState({studentApplicationRestriction})}} color={"dark"}>Cancel</Button>
                    <Button className="r-text--sm ml-2" color={"blue-3"} onClick={this.addApplicationRestriction} >Save</Button>
                  </div>
                </Card>}
              </Collapse>
              <Row>
                <Col xs={6}><span className="d-block r-subheading-3 border-bottom mt-3">{!!studentApplicationRestriction.isShowInactiveCount ? "Inactive":"Active"} Restrictions</span></Col>
                <Col xs={6} className="text-right">
                    <ToggleButton buttonType="pt" selected={!!studentApplicationRestriction.isShowInactiveCount} values={{ value2: studentApplicationRestriction.isShowInactiveCount ? "Show Active" : "Show Inactive", value1: "" }} onToggle={() => {studentApplicationRestriction.isShowInactiveCount = !studentApplicationRestriction.isShowInactiveCount; this.setState({studentApplicationRestriction})}} />
                </Col>
              </Row>
              <Table className="text-center r-text--sm">
                <thead>
                  <tr>
                    <th>Placement Drive</th>
                    <th>Restrictions</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody> 
                  {studentApplicationRestriction.blockedApplication && studentApplicationRestriction.blockedApplication.length ? 
                  studentApplicationRestriction.blockedApplication.filter(o =>!studentApplicationRestriction.isShowInactiveCount ? !!o.pending_application_count : !o.pending_application_count).map((rule) => <tr>
                    <td>{processList[rule.process_id] && processList[rule.process_id].process_name}</td>
                    <td className="text-center">
                      <span className="d-block">{rule.application_count} Applications - {classificationById[rule.classification_id] && classificationById[rule.classification_id].name}</span>
                      <span>{rule.pending_application_count} Applications Pending to block </span>
                    </td>
                    <td>{moment(rule.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mmA")}</td>
                    <td><span className="fi flaticon-bin" style={{cursor: "pointer"}} onClick={() => this.deleteApplicationBlockRule(rule.student_blocked_application_count_id)}></span></td>
                  </tr>)
                  : <tr><td colSpan={4} className="text-center">No Data</td></tr>
                  }
                </tbody>
              </Table>
              </>}
            </ModalBody>
            <ModalFooter>
              <Button className="r-text--sm" onClick={() => this.setState({studentApplicationRestriction: false})} color={"dark"}>Cancel</Button>
              <Button  className="r-text--sm ml-2" color={"blue-3"} onClick={() => this.onSubmitApplicationRestriction()}>Save</Button>
            </ModalFooter>
          </Modal>

          <Modal zIndex={99999} isOpen={uploadModal} toggle={this.toggleuploadClose}>
            <ModalBody>
              <form onSubmit={this.addCustomResume}>
                <Row form className="mt-3">
                  <Col>
                    <label className="r-heading-6">Name of the resume</label>
                    <InputField name="cv_name" placeholder="Name" className="w-100" type="text" id="resumeNameUpload" />
                  </Col>
                </Row>
                <Row form className="mt-3">
                  <Col>
                    <label className="r-heading-6">Select Category</label>
                    {cvCategories.length > 0 && <Select name="cv_category_id" id="cvCategory" defaultValue="1">
                      {cvCategories.map(ct => {
                        return <option key={ct.cv_category_id} value={ct.cv_category_id}>{ct.cv_category}</option>
                      })}
                    </Select>}
                  </Col>
                </Row>
                <Row form className="mt-3 justify-content-end r-text--sm">
                  <Col xs="auto">
                    <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                      <Row className="border align-items-center font-weight-normal mx-3 py-1  px-3  " color="outline-dark-v">
                        {(uploadedFile !== null)?uploadedFile.name:"Upload Resume"}
                        <i className=" r-text--xl fi flaticon-upload r-text--grey-4 ml-3" style={{ fontSize: "16px" }} />
                      </Row>
                      <input id="file-input" accept="application/pdf" onChange={(e) => this.handleUploadFile(e)} type="file" style={{ display: "none" }} />
                      {cvName}
                    </label>
                    <Button onClick={this.toggleuploadClose} className="r-btn--no-radius" type="button">Cancel</Button>
                    <Button className="r-btn--no-radius" color="dark">Save</Button>
                  </Col>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </Row>
        <Row className="justify-content-between align-items-center" >
          <Col xs="auto" className="">
            <Row className="justify-content-between align-items-center">
              <span className="px-1 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>Total students are {this.props.tableData.length}</span>
              <span className="px-3 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>View</span>
              <div className="r-dropdown border-0">
                <select value={rowsPerPage} name="rowsPerPage" className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} onChange={(e) => { this.setState({ [e.target.name]: e.target.value, currentPage: 0 }) }}>
                  <option value="10" >10</option>
                  <option value="15">15</option>
                  <option value="20" >20</option>
                  <option value="25">25</option>
                  <option value="50" >50</option>
                  <option value="100">100</option>
                </select>
                <i className="fi flaticon-arrow" />
              </div>
            </Row>
          </Col>
          <Col xs="auto">
            <PaginationComponent
              totalItems={data.length}
              pageSize={rowsPerPage}
              onSelect={this.handleSelected}
              maxPaginationNumbers={3}
              activePage={currentPage + 1}
            />
          </Col>
        </Row>
        <Errorbar showBar={errorbar} onClose={this.closebar} message={message} />
      </div>
    )
  }
}


export default withRouter(TableST);
