import React, { Component } from 'react';
import { Row, Col, Container, CardHeader, Card, CardBody, CardFooter } from 'reactstrap';
import { Stars } from '../../global/Forms/stars';
import Button from '../../global/Button';
import { getQueryParams, getErrorMessage } from '../../../actions';
import store from '../../../store';
import { setLoading, setError } from '../../../actions/action-creators';
import { Auth } from '../../../actions/Auth';

export class ApplicationForm extends Component {
  state = {
    rating: 0,
    comments: "",
    feedbackDetails: {},
    token: null,
    done: false
  }
  setRating = (rating) => {
    this.setState({ rating })
  }
  async componentDidMount() {
    let params = getQueryParams(this.props.location.search)
    if (!params.token) {
      store.dispatch(setError({ showBar: true, message: "Invalid URL" }))
      return
    }
    this.setState({ token: params.token })
    store.dispatch(setLoading(true))
    try {
      let applicationResp = await Auth.Feedback.fetch(params)
      this.setState({ feedbackDetails: applicationResp.data })
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: "Invalid URL" }))
    }
    store.dispatch(setLoading(false))
  }

  submitFeedback = async () => {
    let { token, rating, comments } = this.state
    if (rating === 0) {
      store.dispatch(setError({ showBar: true, message: "Please select a rating!" }))
      return
    }
    let data = {
      feedback_rating: rating,
      feedback_comment: comments,
      token
    }
    store.dispatch(setLoading(true))
    try {
      await Auth.Feedback.accept(data)
      this.setState({ done: true })
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  render() {
    let { rating, comments, done, feedbackDetails } = this.state
    return (
      <Container>
        <Row className="mt-5">
          <Col xs={12} md={6} lg={5}>
            {feedbackDetails.campusResp && <h3 className="r-heading-3">{feedbackDetails.campusResp.name} - {feedbackDetails.application.role_name}</h3>}
            {done ?
              <h3 className="r-subheading-2">Thank you for your feedback!</h3> : <Card>
                <CardHeader className="r-text--blue-3 r-heading-6">Feedback Form</CardHeader>
                <CardBody>
                  <Row>
                    <Col xs={12}>
                      <p className="r-subheading-3 m-0">How was your experience?</p>
                      <Stars edit={true} proficiency={rating} selectStars={this.setRating} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <p className="r-subheading-3 m-0">Any Comments</p>
                      <textarea value={comments} onChange={(e) => this.setState({ comments: e.target.value })} className="r-inputfield w-100"></textarea>
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col xs="auto">
                      <Button onClick={this.submitFeedback} color="dark">Send</Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>}
          </Col>
        </Row>
      </Container>
    )
  }
}