import React, { Component } from 'react';
import Aux from '../../../../global/AuxComp';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import moment from 'moment';
import Collapse from 'reactstrap/lib/Collapse';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { ProfileActions } from './profile-actions';
import Button from '../../../../global/Button';
import { Auth } from '../../../../../actions/Auth';
import { CONSTANTS } from '../../../../../constants';
import { SingleDatePicker } from '../../../../global/Forms/DatePicker';
import { YearSelect } from '../../../../global/Forms/Select';
import { InputField } from '../../../../global/Forms';
import { filterCVPoints } from '../../../../../actions';
import { CVPoint } from './cv-point';
import { FileUpload } from '../../../../global/FileUpload';
import { Errorbar } from '../../../../global/errorbar';

export class Achievements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      deleteId: null,
      errorbar: false,
      message: ''
    }
  }


  closebar = () => {
    this.setState({ errorbar: false });
  }

  handleAchChange = (e, ach) => {
    ach[e.target.name] = e.target.value;
    this.props.changeAchievement(ach)
  }

  isValid = (ach) => {


    if (ach.achievement_type === 'TEST_SCORE') {
      let { name, organisation, year, score } = ach;
      if (!name || !organisation || !year || !score) {
        this.setState({ message: 'Please fill all mandatory input fields' });
        this.setState({ errorbar: true });
        return false
      }
    }

    if (ach.achievement_type === 'PATENT') {
      let { name, organisation, year, achievements_status } = ach;
      if (!name || !organisation || !year || !achievements_status) {
        this.setState({ message: 'Please fill all mandatory input fields' });
        this.setState({ errorbar: true });
        return false
      }
    }

    if (ach.achievement_type === 'PUBLICATION') {
      let { name, publisher_name, achievements_status, year } = ach;
      if (!name || !publisher_name || !achievements_status || !year) {
        this.setState({ message: 'Please fill all mandatory input fields' });
        this.setState({ errorbar: true });
        return false
      }
    }

    if (ach.achievement_type === 'SCHOLARSHIP') {
      let { name, organisation, achievements_status, year } = ach;
      if (!name || !organisation || !achievements_status || !year) {
        this.setState({ message: 'Please fill all mandatory input fields' });
        this.setState({ errorbar: true });
        return false
      }
    }

    if (ach.achievement_type === 'COMPETITION') {
      let { name, organisation, location, year } = ach;
      if (!name || !organisation || !location || !year) {
        this.setState({ message: 'Please fill all mandatory input fields' });
        this.setState({ errorbar: true });
        return false
      }
    }


    this.setState({ errorbar: false });
    return true

  }

  handleAchSubmit = async (e, ach) => {
    if (e) e.preventDefault();
    if (!this.isValid(ach)) {
      return
    }
    this.props.toggleLoading(true);
    let method = Auth.Student.updateAchievement,
      formData = Object.assign({}, ach);
    formData.year = moment(formData.year).format(CONSTANTS.DATE_FORMAT)
    if (typeof ach.achievement_id === "string") {
      method = Auth.Student.createAchievement;
      formData.student_id = this.props.user_info.student_id;
      delete formData.achievement_id
    }
    try {
      await method(formData)
      this.props.getStudentProfile()
      if (e) this.props.toggleSection("");

    } catch (e) {

      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
    this.props.toggleLoading(false);
  }

  deleteDetail = async () => {
    let { deleteId } = this.state;
    if (!deleteId) return;
    let formData = {
      student_id: this.props.user_info.student_id,
      achievement_id: deleteId,
      status: 0
    }
    this.props.toggleLoading(true)
    try {
      if (typeof deleteId === "number")
        await Auth.Student.updateAchievement(formData)
      this.props.changeAchievement(formData, true)
      this.toggleModal()
      this.props.getStudentProfile()
    } catch (e) {
      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
    this.props.toggleLoading(false)
  }

  toggleModal = (id) => {
    this.setState({
      modalShow: !this.state.modalShow,
      deleteId: id
    })
  }

  changeYear = (e, ach) => {
    let date = ach.year ? moment(ach.year) : moment();
    ach.year = date.year(e.target.value).format(CONSTANTS.DATE_FORMAT)
    this.props.changeAchievement(ach)
  }
  changeDob = (mDate, ach) => {


    ach.year = mDate.format(CONSTANTS.DATE_FORMAT)

    this.props.changeAchievement(ach)
  }
  addAch = (e, type) => {
    this.props.toggleSection("ACH-0")
    this.props.changeAchievement({ achievement_type: type });
    this.el = e.target.closest(".student__profile__container");
    setTimeout(() => {
      if (this.el)
        this.el.scrollIntoView({ behavior: "smooth", block: "start" })
    }, 300)
  }

  render() {
    let { files, editableSection, toggleSection, achievement, onCancel, cv_point, commentThreads } = this.props;
    this.cvPoints = filterCVPoints(cv_point, "ACHIEVEMENTS")
    return (
      <Aux>
        <div className="r-card">
          {
            Object.keys(CONSTANTS.ACHIEVEMENT_TYPES).map(type => {
              return (
                <Row noGutters className="student__profile__container" key={type}>
                  <Col xs={12}>
                    <h2 className="r-heading-4 r-text--blue-3">{CONSTANTS.ACHIEVEMENT_TYPES[type]}</h2>
                    {editableSection === "" && <div className="student__profile__actions">
                      <Button onClick={(e) => this.addAch(e, type)} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
                    </div>}
                    {
                      achievement.map((ach, i) => {
                        if (ach.achievement_type === type) {
                          return (
                            <div className={"student__profile__section pt-4" + (ach.freeze_status == 1 ? " student__profile--freeze" : "")} key={ach.achievement_id}>
                              <Collapse isOpen={editableSection !== `ACH-${i}`}>
                                <h1 className="r-subheading-2 mr-3" style={{ fontWeight: 600 }}>
                                  {ach.name || "Name"}
                                </h1>
                                <h1 className="r-subheading-2 mt-3" style={{ fontWeight: 400 }}>
                                  {type === "PUBLICATION" ? (ach.publisher_name) : (ach.organisation || "Organization")}
                                </h1>
                                <div className="r-text--grey-4 mb-2">{type === "PUBLICATION" || type === "PATENT" ? (ach.year ? moment(ach.year).format("DD MMM YYYY") : "Year") : (ach.year ? moment(ach.year).format("YYYY") : "Year")}</div>

                                {(files[ach.file]) && <Row noGutters>
                                  {files[ach.file].map((doc, i) => <Col className="pr-3" key={i} xs="auto">
                                    <div className="r-tag r-tag--type-1 r-tag--no-delete">
                                      <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.file_name || doc.file_url || "Filename"}</a>
                                    </div>
                                  </Col>)}
                                </Row>}
                              </Collapse>
                              <Collapse isOpen={editableSection === `ACH-${i}`}>
                                <form onSubmit={(e) => this.handleAchSubmit(e, ach)}>
                                  <Row form className="mb-2">
                                    <Col xs={12}>
                                      <label className="r-heading-7"><span className="r-text--red-1">*</span>{type === "PUBLICATION" || type === "PATENT" ? "Title" : "Name"}</label>
                                      <InputField className="w-100" type="text" name="name" value={ach.name || ""} onChange={(e) => this.handleAchChange(e, ach)} />
                                    </Col>
                                  </Row>
                                  <Row form className="mb-2">
                                    <Col xs={12}>
                                      <label className="r-heading-7"><span className="r-text--red-1">*</span>{type === "PUBLICATION" ? "Publisher" : (type === "PATENT" ? "Office" : "Organization")}</label>
                                      <InputField className="w-100" type="text" name={type === "PUBLICATION" ? "publisher_name" : "organisation"} value={(type === "PUBLICATION" ? ach.publisher_name : ach.organisation) || ""} onChange={(e) => this.handleAchChange(e, ach)} />
                                    </Col>
                                  </Row>
                                  <Row form className="mb-2">
                                    <Col xs={6}>
                                      <label className="r-heading-7"><span className="r-text--red-1">*</span>{type !== "PUBLICATION" && type !== "PATENT" ? "Year" : "Date"}</label>
                                      {type !== "PUBLICATION" && type !== "PATENT" ?
                                        <YearSelect onChange={(e) => this.changeYear(e, ach)} value={ach.year ? moment(ach.year).year() : ""} />
                                        :
                                        <SingleDatePicker
                                          value={ach.year ? moment(ach.year) : ''}
                                          onDateChange={(mDate) => this.changeDob(mDate, ach)}
                                        />
                                      }
                                    </Col>
                                    <Col xs={6}>
                                      <Row>
                                        <Col>
                                          <label className="r-heading-7"><span className="r-text--red-1">*</span>{type === "TEST_SCORE" ? "Score" : (type === "COMPETITION" ? "Location" : "Status")}</label>
                                          {type === "TEST_SCORE" ? <InputField className="w-100" type="text" name="score" value={ach.score || ""} onChange={(e) => this.handleAchChange(e, ach)} /> :
                                            (type === "COMPETITION" ? <InputField className="w-100" type="text" name="location" value={ach.location || ""} onChange={(e) => this.handleAchChange(e, ach)} /> :
                                              <InputField className="w-100" type="text" name="achievements_status" value={ach.achievements_status || ""} onChange={(e) => this.handleAchChange(e, ach)} />)}
                                        </Col>
                                        {
                                          type==="TEST_SCORE" && <Col>
                                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Out of</label>
                                          <InputField className="w-100" type="text" name="total_score" value={ach.total_score || ""} onChange={(e) => this.handleAchChange(e, ach)} />
                                          </Col>
                                        }
                                      </Row>

                                    </Col>
                                  </Row>

                                  <div className="d-flex justify-content-end mt-3">
                                    <Button type="button" onClick={() => onCancel()} className="r-text--sm">Cancel</Button>
                                    <Button type="submit" className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
                                  </div>
                                  {typeof ach.achievement_id === "number" && < FileUpload toggleLoading={this.props.toggleLoading} updateData={this.props.changeAchievement} submitData={this.handleAchSubmit} verification={{ user_id: this.props.user_info.user_id, data: ach, files }} />}
                                </form>
                              </Collapse>
                              {typeof ach.achievement_id === "number" && <CVPoint getStudentProfile={this.props.getStudentProfile} settings={this.props.settings} toggleCV={this.props.toggleCV} files={files} toggleLoading={this.props.toggleLoading} user_info={this.props.user_info} profile_id={ach.achievement_id} type="ACHIEVEMENTS" changeCVPoint={this.props.changeCVPoint} cvPoints={this.cvPoints[ach.achievement_id] || []} commentThreads={commentThreads} />}
                              {editableSection !== `ACH-${i}` && <ProfileActions delete={() => this.toggleModal(ach.achievement_id)} verification={{ ...ach }} toggleSection={() => toggleSection(`ACH-${i}`)} isRequiredValidate={true} commentThreads={commentThreads} profileType="ACHIEVEMENTS" />}
                            </div>
                          )
                        }
                      })
                    }
                    {
                      achievement.filter(a => a.achievement_type === type).length === 0 && <div className="student__profile__section student__profile__section--placeholder">
                        <h1 className="r-subheading-2 mr-3" style={{ fontWeight: 600 }}>
                          {type === "PUBLICATION" || type === "PATENT" ? "Title" : "Name"}
                        </h1>
                        <h1 className="r-subheading-2 mt-3" style={{ fontWeight: 400 }}>
                          {type === "PUBLICATION" ? "Publisher" : (type === "PATENT" ? "Office" : "Organisation")}
                        </h1>
                        <div className="r-text--grey-4 mb-2">{type === "PUBLICATION" || type === "PATENT" ? "Date" : "Year"}

                        </div>
                      </div>
                    }
                  </Col>
                </Row>
              )
            })
          }
        </div>
        <Modal className="modal--delete" isOpen={this.state.modalShow} toggle={() => this.toggleModal()}>
          <ModalBody>
            <p>Are you sure?</p>
            <div className="text-right">
              <Button className="r-text--sm" onClick={() => this.toggleModal()}>Cancel</Button>
              <Button color="dark" onClick={this.deleteDetail} className="r-text--sm r-btn--no-radius">Delete</Button>
            </div>
          </ModalBody>
        </Modal>
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
      </Aux>
    )
  }
}
