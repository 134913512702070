import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import Aux from '../../global/AuxComp';
import {Row,Col,Container,Collapse} from 'reactstrap';
import Button from '../../global/Button';
import { Auth } from '../../../actions/Auth';
import {CONSTANTS} from '../../../constants';
import { InputField } from '../../global/Forms';
import { Switch } from '../../global/Forms/ToggleSwitch';
import ShowCampus from './show_campus';
import AppendCampus from './append_campus';
import Classification from './classification';

  export default class ShowCollege extends Component{
    constructor(props){
       super(props);
         this.state = { 
           isOn: false, 
           isEditable:false, 
           degreeList : this.props.degreeList || [],
          };
     }

	  handleToggle(e) {
  		this.setState({isOn: !this.state.isOn});
  	}



    render(){
      let { universityIndex, collegeIndex, data, instituteData, modifyDetails, modifyObjDetails, deleteDetails, addDetailsExistedList, createAppend, removeAppend} = this.props;
      var accessLevel_ids = {college_id : instituteData.college_id, university_id : instituteData.university_id}
      let { isEditable, degreeList } = this.state;
      var campusData = instituteData.campus;
      var role_count = instituteData.role_count && Array.isArray(instituteData.role_count) ? instituteData.role_count : [];
      var societyCount = role_count.filter(cd => cd.role_type === CONSTANTS.ROLE_TYPES_ID.SOCIETY);
      var ptCount = role_count.filter(cd => cd.role_type === CONSTANTS.ROLE_TYPES_ID.PT);
      var studentCount = role_count.filter(cd => cd.role_type === CONSTANTS.ROLE_TYPES_ID.STUDENT);
      var spocCount = role_count.filter(cd => cd.role_type === CONSTANTS.ROLE_TYPES_ID.SPOC);
      return (
        <div className="child-boundry-campus-left">
          <Row className="pt-3 border-top align-items-center">
          <Col xs={5}>
            <Row className="align-items-center">
            <Col>
            { !instituteData.isEditable ?

              <h1 className="r-heading-6">{instituteData.name} </h1>
              :
              <InputField value={instituteData.name} onChange={(e) => modifyObjDetails(universityIndex, collegeIndex,null, null, 'name', e.target.value)}  name="name" className="w-100" type="text" />
            }
            </Col>
            </Row>
          </Col>
          <Col xs={7}>
          <Row className="align-items-center text-center">
            <Col xs={3}>
              {/* <Button onClick={() => this.props.openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.PT, accessLevel_ids)} className="" color="link-blue">{ ptCount[0] ? ptCount[0].id_count : 0 }<span className="r-text--sm"> Members <i className="d-inline-block fi flaticon-eye mr-2"></i></span></Button> */}
            </Col>
            {/* <Col xs={2}><Button onClick={() => this.props.openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.SOCIETY, accessLevel_ids)} color="link-blue">{ societyCount[0] ? societyCount[0].id_count : 0 }<span className="r-text--sm">  Societies <i className="d-inline-block fi flaticon-eye mr-2"></i></span></Button></Col> */}
            <Col xs={2}><Button onClick={() => {return false; this.props.openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.STUDENT, accessLevel_ids)}} color="link">{ studentCount[0] ? studentCount[0].id_count : 0 } <span className="r-text--sm"> Students</span></Button></Col>
            <Col xs={3}><Button onClick={() => this.props.openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.SPOC, accessLevel_ids)} className="" color="link-blue">{ spocCount[0] ? spocCount[0].id_count : 0 } <span className="r-text--sm"> Admin <i className="d-inline-block fi flaticon-eye mr-2"></i></span></Button></Col>
            {/* <Col xs={1} ><Switch  className="mt-3 d-inline-block" isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)} /></Col> */}
            <Col xs={4}>
                { !instituteData.isEditable ?
                  <div className="text-right">
                  <Button onClick={(e) => addDetailsExistedList(universityIndex, collegeIndex,null)} className="r-text--sm mt-1 mr-3" color="link-blue"><i className="d-inline-block fi flaticon-arrow rotate--90 mr-2"></i>Add Campus</Button>

                    <Button onClick={() => modifyObjDetails(universityIndex, collegeIndex,null, null, 'isEditable', true)}  className="p-2"><i className="fi flaticon-edit"></i></Button>
                    <Button onClick={() => deleteDetails(universityIndex, collegeIndex,null, null)}  className="p-2"><i className="fi flaticon-bin"></i></Button>
                  </div>
                :
                <div className="text-right">
                  <Button onClick={() => modifyObjDetails(universityIndex, collegeIndex,null, null, 'isEditable', false)}   className="r-text--sm mt-4 py-2 px-1" color="outline-dark-v" square="true">Cancel</Button>
                  <Button onClick={() => modifyDetails(universityIndex, collegeIndex,null, null )}  className="r-text--sm mt-4 ml-1 px-1 py-2" color="dark-v" square="true">Save</Button>
                </div>
             }
            </Col>
          </Row>
          </Col>
          </Row>
          {
            campusData && Object.keys(campusData).map((child, index) => (!campusData[child].rip ? <ShowCampus key={child} universityIndex={universityIndex} collegeIndex={collegeIndex} campusIndex={child} campusData={campusData[child]} degreeList={degreeList} {...this.props} />  : <AppendCampus key={child} universityIndex={universityIndex} collegeIndex={collegeIndex} campusIndex={child} degreeList={degreeList} campusData={campusData[child]} modifyObjDetails={modifyObjDetails} addDetailsExistedList={addDetailsExistedList} createAppend={createAppend} removeAppend={removeAppend} />) )
          }
        </div>

    )
  }
}
