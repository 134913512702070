import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import { Row, Col,Card, CardBody, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import {UncontrolledCollapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Loader } from '../../../global/Loader';

import { Select} from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import { CONSTANTS } from '../../../../constants';



export default  class FaqCard extends Component {

  constructor(props) {
    super(props);

    this.state = { collapse: false };
  }

  toggle = ()=> {
    this.setState(state => ({ collapse: !state.collapse }));
  }  

  render() {    
    return(
          <Row>
            <Col xs={12}> 
              <div className="card">
                <div className="card-header" onClick={this.toggle}>
                  <Button >
                    <h3 className="r-heading-3">{this.props.heading} &nbsp; <i className="d-inline-block fi flaticon-arrow rotate--90 mr-2"></i></h3>
                  </Button>
                </div>              

              <Collapse isOpen={this.state.collapse}>
                <div className="card-body">
                  {this.props.points}                    
                </div>
              </Collapse>
              </div>
            </Col>
          </Row>  
         )
  }

}
