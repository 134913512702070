import React, { Component } from 'react';
import {Row, Col, Input , Modal, ModalBody, Collapse, Badge} from 'reactstrap';
import { InputField } from '../../../global/Forms';
import { Select } from '../../../global/Forms/Select';
import Button from '../../../global/Button';
import { Auth } from '../../../../actions/Auth';
import { CONSTANTS } from '../../../../constants';
import { getErrorMessage  } from '../../../../actions';
import { setError, setLoading } from '../../../../actions/action-creators';
import store from '../../../../store';



export class AddRecruiter extends Component {
  constructor(props) {
    super(props)
    this.state = { 
        recuriterDetail : {} ,
    };
  }

  addRecruiter = async (e) => {
      e.preventDefault();
      store.dispatch(setLoading(true));
      let {recuriterDetail}=this.state;
      recuriterDetail.company_id = this.props.company_id
      recuriterDetail.user_type = "RECRUITER"
      try {
        await Auth.Recruiter.inviteRecruiter(recuriterDetail)
        this.props.toggleAddRecuriter();
      }
      catch(e) {
            console.log(e)
            store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      }
      this.props.onSuccess && this.props.onSuccess();
      store.dispatch(setLoading(false))
  }

  onChangeDetails = (key, value) =>{
    let { recuriterDetail } = this.state;
    recuriterDetail[key] = value;
    this.setState({ recuriterDetail });
  }

  render() {
   let {recuriterDetail}=this.state ;
    return (
        <div>
            <form onSubmit={this.addRecruiter }>
            
            <h1 className="r-subheading-3 border-bottom pb-3">Create Recruiter Access</h1>
                    <Row className="mt-4 mb-3">
                        <Col>
                            <h4 className="r-subheading-4 pb-3">Creating Recruiter Access will help you recruiter with the following</h4>
                                <ol className="r-subheading-4">
                                    <li>
                                        Post feed to the placement team, which can be shared further with the students
                                    </li>
                                    <li>
                                        Post job opportunities, internships and live projects to the placement team which can be further shared with the students
                                    </li>
                                    <li>
                                        Recruiters can access CVs, excel profile of students via the created login once applicant data is shared by the placement team
                                    </li>
                                </ol>   
                        </Col>
                    </Row>
                
                    <Row className=" align-items-center my-3">
                        <Col xs={12}>
                            <h1 className="r-subheading-3"><span className="r-text--red-1"></span> Name</h1>
                            <InputField className="w-100" value={recuriterDetail.first_name}  onChange={(e)=>{this.onChangeDetails ("first_name",e.target.value )}}/>
                        </Col>
                        <Col xs={12} className="mt-2">
                            <h1 className="r-subheading-3">Email</h1>
                            <InputField className="w-100" value={recuriterDetail.email} onChange={(e)=>{this.onChangeDetails ("email",e.target.value )}}/>
                        </Col>
                        

                    </Row>
                 <Button type="submit" className="float-right mr-3 mt-4" color="dark r-btn--no-radius">Save</Button>
            </form>
        </div>
        )
    }
}
export default AddRecruiter ;
