import React, { Component } from 'react';
import { PieChart, Pie, Sector, Cell, Legend, ResponsiveContainer, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

class PieChartCOMP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1
    };
  }

  renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
      fill, payload, percent, value
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text fontSize="20" fontWeight="600" x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{`${(percent * 100).toFixed(0)}%`}</text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name} (${value})`}</text>
      </g>
    );
  };

  renderLabel = (item) => {
    console.log(item)
    let { cx, cy, midAngle, innerRadius, outerRadius, name } = item
    const RADIAN = Math.PI / 180;
    // eslint-disable-next-line
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    // eslint-disable-next-line
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // eslint-disable-next-line
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="#8884d8"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {name} ({item[this.props.datakey] || item.value})
            </text>
    );
  }

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index
    })
  }

  render() {
    const style = {
      top: 0,
      right: 0,
      lineHeight: '20px',
      fontSize : "10px",
      overflowY : "scroll"
    };
    return (
      <ResponsiveContainer width='100%' height={350}>
        <PieChart>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={this.renderActiveShape}
            data={this.props.data}
            // cx="calc(50% - 150px)"
            // cy="calc(50% - 150px)"
            innerRadius={70}
            outerRadius={100}
            fill="#8884d8"
            dataKey={this.props.datakey || "value"}
            onMouseEnter={this.onPieEnter}
          >
            {
              this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} label />)
            }
          </Pie>
          {/* <Legend iconSize={10} width={250} formatter={(label) => label.substr(0, 15).trim() + (label.length > 10 ? "..." : "")} onMouseEnter={this.onPieEnter} align="right" verticalAlign="middle" layout="vertical" /> */}
          <Legend iconSize={10} width={250} height={350} layout="vertical" align="middle" wrapperStyle={style}  />

        </PieChart>
      </ResponsiveContainer>
    )
  }
}


export default PieChartCOMP;
