import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Row,Col,Container,Collapse, Input} from 'reactstrap';
import Aux from '../../global/AuxComp';
import Button from '../../global/Button';
import { SelectList } from '../../global/Forms/Select';
import { InputField } from '../../global/Forms';
import { Switch } from '../../global/Forms/ToggleSwitch';
import RolePermissionTab from './role_permission_tab';
import RoleAccessLevelTab from './role_accesslevel_tab';

  export default class AddMember extends Component{
    constructor(props){
       super(props);
         this.state = { isOn: false,  isPermissionCollapse : false, activeTab : 1};
     }



	  handleToggle(e) {
  		this.setState({isOn: !this.state.isOn});
  	}
	  togglePermissionsCollapse = () => {
  		this.setState({isPermissionCollapse: !this.state.isPermissionCollapse});
  	}

	  setActiveTab = (value) => {
  		this.setState({activeTab: value});
  	}

    render(){
      let { index, userRoles, cancelAddMember, handleChangeAddMember, addMemberData, createMember, modalInfo } = this.props;
      return (
      <Aux>

        <Row className="align-items-center mt-3">
            <Col xs={7}>
              <Row>
              {/*<Col xs={1}></Col>*/}
              <Col xs={5}>
                <Row>
                  <Col xs={6} className="px-1"><InputField type="text" className="w-100" placeholder="First Name" value={addMemberData.first_name}  onChange={(e) => handleChangeAddMember(index, 'first_name', e.target.value) } /></Col>
                  <Col xs={6} className="px-1"><InputField type="text" className="w-100" placeholder="Last Name" value={addMemberData.last_name}  onChange={(e) => handleChangeAddMember(index, 'last_name', e.target.value) } /></Col>
                </Row>
              </Col>
              <Col xs={6}><InputField onChange={(e) => handleChangeAddMember(index, 'email', e.target.value)} type="text" className="w-100" value={addMemberData.email} /></Col>

              </Row>
            </Col>
            <Col xs={5}>
              <Row className="align-items-center">
              <Col xs={5}>
                <SelectList className="r-text--sm" selected={addMemberData.role_id ? userRoles.filter(data => data.role_id === addMemberData.role_id)[0].role_name : 'Select Role'}>
                <li onClick={(e) => handleChangeAddMember(index, 'role_id', undefined)}>Select Role</li>
                {
                  userRoles.filter(data => data.role_type === modalInfo.role_type).map((child, i) =>  <li key={i} onClick={(e) => handleChangeAddMember(index, 'role_id', child.role_id)} data-role_id={child.role_id} >{child.role_name}</li>)
                }
                </SelectList>
              </Col>
              {/* <Col xs={2}><Switch  isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col> */}

              <Col xs={5}>
                <Button className="m-2" onClick={() => createMember(index)}  color="link-blue" >Save</Button>
                <Button className="m-2"  onClick={() => cancelAddMember(index)} color="link-blue" >Cancel</Button>
              </Col>
              </Row>
            </Col>
        </Row>

      <Collapse className="mt-3" isOpen={this.state.isPermissionCollapse}>
        <Container className="r-bg--grey-1">
          <Row className="pt-4 pb-2 border-bottom  border-secondary">
            <Col>
              <h3 className={"m-2 p-2 d-inline-block r-subheading-3 " +(this.state.activeTab === 1 ? "active-tab active-tab-brandB":"")} onClick={ ()=>this.setActiveTab(1)} >Role Level</h3>
              <h3 className={"m-2 p-2 d-inline-block r-subheading-3 " +(this.state.activeTab === 2 ? "active-tab active-tab-brandB":"")} onClick={ () =>this.setActiveTab(2)}>Permissions</h3>
            </Col>
          </Row>
          {
            // this.state.activeTab === 1 ?
            // <RoleAccessLevelTab />
            // :
            // <RolePermissionTab />
          }
        </Container>
      </Collapse>
      </Aux>

    )
  }
}
