import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from '../../../actions/Auth';
import {SelectList} from '../../global/Forms/Select'
import {Row,Col,Container} from 'reactstrap';
import {HorizontalLine} from '../../global/horizontal_line';
import {VerifySummaryCard} from './verifier_card';
import { Loader } from '../../global/Loader';
import {InputField} from '../../global/Forms'
import PaginationComponent from '../../global/PaginationComponent';

 class VerifierTest extends Component{
  constructor(props){
    super(props);
    this.state = {
      getProcesList:  [],
      getStudentAssigned: [],
      getStudentProfileDetails: [],
      studentId : [],
      selectedProcess: null,
      loading: false,
      unverified : 0,
      processId : null,
      lengths : null,
      studentIdArray : [],
      searchText : '',
      currentPage : 0,
      approved : 0,
      unapproved : 0,
      status : 2
    };
  }

  toggleLoading = (loading)=>{
    this.setState({
      loading
    })
  }

  onClickHandler = async (processId,selectedProcess)=>{
    await this.setState({
      processId,
      selectedProcess,
      studentIdArray:[],
      getStudentAssigned: [],
      getStudentProfileDetails:[],
      status : 2
    })

    await  this.getVerificationDetails(processId);
    this.countPendingApprovedStudents();
  }

  calcUnverifiedStudents = (studentDetails)=>{
    let  all=0, pending=0, approved=0,rejected=0;
       let{academic_detail, achievement, personal_detail, extra_curricular_activity, other_experience, position_of_responsibility, preferences,professional_detail, project, skill , cv_point} =studentDetails;
    let academicPoints = academic_detail!==null?(academic_detail.filter(pt=>{
      if(pt.is_verified===0){
        pending++;
        all++;
      }
      else if(pt.is_verified===1){
        approved++;
        all++;
      }
      else if (pt.is_verified===2) {
        rejected++;
        all++;
      }
    })):null

    let achievementPoints = achievement!==null?(achievement.filter(pt=>{
      if(pt.is_verified===0){
        pending++;
        all++;
      }
      else if(pt.is_verified===1){
        approved++;
        all++;
      }
      else if (pt.is_verified===2) {
        rejected++;
        all++;
      }
    })):null

    let personalDetail = personal_detail.filter(pt=>{
      if(pt.is_verified==0){
        pending++;
        all++;
      }
      else if(pt.is_verified==1){
        approved++;
        all++;
      }
      else if (pt.is_verified==2) {
        rejected++;
        all++;
      }
    })

    let extraCurricularActivityPoints =extra_curricular_activity!==null?( extra_curricular_activity.filter(pt=>{
      if(pt.is_verified===0){
        pending++;
        all++;
      }
      else if(pt.is_verified===1){
        approved++;
        all++;
      }
      else if (pt.is_verified===2) {
        rejected++;
        all++;
      }
    })):null

    let otherExperiencePoints = other_experience!==null?( other_experience.filter(pt=>{
      if(pt.is_verified===0){
        pending++;
        all++;
      }
      else if(pt.is_verified===1){
        approved++;
        all++;
      }
      else if (pt.is_verified===2) {
        rejected++;
        all++;
      }
    })):null

    let positionOfResponsibilityPoints =position_of_responsibility!==null?( position_of_responsibility.filter(pt=>{
      if(pt.is_verified===0){
        pending++;
        all++;
      }
      else if(pt.is_verified===1){
        approved++;
        all++;
      }
      else if (pt.is_verified===2) {
        rejected++;
        all++;
      }
    })):null

    let preferencesPoints =preferences!==null?(preferences.filter(pt=>{
      if(pt.is_verified===0){
        pending++;
        all++;
      }
      else if(pt.is_verified===1){
        approved++;
        all++;
      }
      else if (pt.is_verified===2) {
        rejected++;
        all++;
      }
    })):null

    let professionalDetailPoints =professional_detail!==null?( professional_detail.filter(pt=>{
      if(pt.is_verified===0){
        pending++;
        all++;
      }
      else if(pt.is_verified===1){
        approved++;
        all++;
      }
      else if (pt.is_verified===2) {
        rejected++;
        all++;
      }
    })):null

    let projectPoints =project!==null?( project.filter(pt=>{
      if(pt.is_verified===0){
        pending++;
        all++;
      }
      else if(pt.is_verified===1){
        approved++;
        all++;
      }
      else if (pt.is_verified===2) {
        rejected++;
        all++;
      }
    })):null

    let skillPoints =skill!==null? (skill.filter(pt=>{
      if(pt.is_verified===0){
        pending++;
        all++;
      }
      else if(pt.is_verified===1){
        approved++;
        all++;
      }
      else if (pt.is_verified===2) {
        rejected++;
        all++;
      }
    })):null

    let cvPoint = cv_point!==null?(cv_point.filter(pt=>{
      if(pt.is_verified===0){
        pending++;
        all++;
      }
      else if(pt.is_verified===1){
        approved++;
        all++;
      }
      else if (pt.is_verified===2) {
        rejected++;
        all++;
      }
    })):null
  //  e.preventdefault();
    let unverified = this.state.unverified + 1;
    this.setState({
      unverified
    })
  }

  getProcess = async ()=>{
    try{

      let getProcesList = await Auth.Verfier.getProcesList(this.props.role.assigned_role_id);
      await this.setState({
          getProcesList: getProcesList.data,
          selectedProcess: getProcesList.data[0],
          processId: getProcesList.data[0].process_id

      })
    }
    catch(e){
      console.log(e);
    }
  }
  getVerificationDetails = async(processId)=>{
    this.toggleLoading(true);

    try{
      let params = new URLSearchParams();
      params.append("role_id",this.props.role.assigned_role_id);
      params.append("process_id",this.state.processId);
      let getStudentAssigned = await Auth.Verfier.getStudentAssigned(params);
      let studentIdArray = [];
      if(getStudentAssigned.data){
        getStudentAssigned.data.map(student_id=>{
          studentIdArray.push(student_id.student_id);
        })
        let length = getStudentAssigned.data.length;
        await this.setState({
          lengths:length
        })
          let stParams = new URLSearchParams();
          // studentIdArray.map(student_id=>{
            stParams.append("student_id", studentIdArray.join(","));
          // })
        
              let details = await Auth.Verfier.getStudentDetails(stParams);
                await this.setState({
                  getStudentAssigned: getStudentAssigned.data,
                  getStudentProfileDetails: details.data,
                  studentIdArray

                })
              }
      }




    catch(e){
      console.log(e);
    }
      this.toggleLoading(false);

  }



  handleSelected = (selectedPage) => {
    this.setState({ currentPage: selectedPage - 1 });
  }

  statusHandler = async (status)=>{
    await this.setState({currentPage : 0})
    await this.getVerificationDetails();
        let{getStudentProfileDetails} = this.state;
        let studentDetails = [];
        if(getStudentProfileDetails.length!=0){
          for(let i=0 ; i<getStudentProfileDetails.length; i++){
            if(status==0 && (getStudentProfileDetails[i].profile_metric.unverified_point>0 || getStudentProfileDetails[i].profile_metric.rejected_point>0)){
              studentDetails.push(getStudentProfileDetails[i]);
            }
            else if(status==1  && getStudentProfileDetails[i].profile_metric.unverified_point==0 && getStudentProfileDetails[i].profile_metric.rejected_point==0) {
              studentDetails.push(getStudentProfileDetails[i]);
            }
            else {
              if(status==2) {
                studentDetails.push(getStudentProfileDetails[i]);
              }
            }
          }
        }
         await this.setState({status , getStudentProfileDetails : studentDetails});
  }

  countPendingApprovedStudents= ()=>{
    let {getStudentProfileDetails} = this.state;
    let approved =0, unapproved =0;
    let student = getStudentProfileDetails;
    for(let i=0 ; i< student.length ; i++){

      let {profile_metric} = student[i];
      let all = profile_metric.rejected_point + profile_metric.unverified_point + profile_metric.verified_point;
      let pending = profile_metric.rejected_point + profile_metric.unverified_point;
      if(profile_metric.rejected_point > 0 || profile_metric.unverified_point > 0 ){
        unapproved++;
      }
      else{
        if(all == profile_metric.verified_point){
          approved++;
        }
      }
    }
     this.setState({
      approved,
      unapproved
    })
  }


   async componentDidMount(){
    await this.getProcess();
    await  this.getVerificationDetails();
    await this.countPendingApprovedStudents();
  }

  render(){
    let {getStudentProfileDetails} = this.state;
      return (
   <Container>
   <Loader show={this.state.loading}/>
   <div className="verifier__main">

   <Row className="heading-dropdown r-heading-1 no-gutters">
 <Col xs="auto"><div className="r-dropdown r-heading-1 py-2">Verification/</div>
 </Col>
	<Col xs="auto">
   {this.state.selectedProcess && <SelectList selected={this.state.selectedProcess.process_name}>
   {
     this.state.getProcesList.map(process => {
       return (
         <li key={process.process_id} onClick={()=>this.onClickHandler(process.process_id,process)}> {process.process_name}</li>
       )
     })
   }
   </SelectList>}

   </Col>
   </Row>
   </div>
    {/*}
	 <div className= "verifier__verify-statement__main">
    <Row>
    <Col> <span className="r-subheading-1"> 1250 points needs to be verified </span>
    </Col>
    <Col xs="7">
    <button className="r-btn--dark-v"> <span className ="r-subheading-4"> Start Verifying </span> </button>
    </Col>
    </Row>
    </div> */}

    <div>
    <Row>
    <Col xs="auto">
    <button className="r-btn pb-0" onClick={()=>this.statusHandler(2)} >
      <Row>
        <Col   className="verifier__verify-count-button" xs="2"><span>{this.state.studentIdArray.length!=0?this.state.studentIdArray.length/10>=1?this.state.studentIdArray.length:"0"+this.state.studentIdArray.length:"00"}</span>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3"><button className={ "verifier__verify-text-button "+(this.state.status==2?"active" : "") } ><span className = "r-subheading-3">All Students</span></button></Col>
      </Row>
    </button>
    </Col>
      <Col xs="auto">
      <button className="r-btn pb-0" onClick={()=>this.statusHandler(1)}>
        <Row>
          <Col className="verifier__verify-count-button" xs="2"><span >{this.state.approved!=0?this.state.approved/10>=1?this.state.approved:"0"+this.state.approved:"00"}</span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3"><button className={ "verifier__verify-text-button "+(this.state.status==1?"active" : "")} ><span className = "r-subheading-3">Completed Students</span></button></Col>
        </Row>
      </button>
      </Col>
      <Col xs="auto">
      <button className="r-btn pb-0" onClick={()=>this.statusHandler(0)} >
        <Row>
          <Col   className="verifier__verify-count-button" xs="2"><span>{this.state.unapproved!=0?this.state.unapproved/10>=1?this.state.unapproved:"0"+this.state.unapproved:"00"}</span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3"><button className={ "verifier__verify-text-button "+(this.state.status==0?"active" : "")} ><span className = "r-subheading-3">Pending Students</span></button></Col>
        </Row>
      </button>
      </Col>
    {/*<Col  className="verifier__verify-count-button" xs="2" ><span>{this.state.unverified}</span>
    {/*<Row>
    <Col><button className="verifier__verify-text-button"><span className = "r-subheading-3">Un-Verified Students</span></button></Col>
    </Row>
    </Col>*/}
    </Row>
    </div>
    <HorizontalLine color ="#cfcfcf"/>
    <Row>
    <Col xs="3">
    <div className="mt-2 mb-4">
    <InputField className="w-100 r-radius--3" onChange={(e)=>this.setState({searchText : e.target.value, currentPage: 0})} placeholder="Search for Students" />
    <i className="pt-2 r-text--grey-4 fi flaticon-search verifier__search-bar"  />
    </div>
    </Col>
    </Row>
    {/*<Row className="py-3 justify-content-between mb-5">
    <Col className="text-middle">
    <InputField className="w-100 r-radius--3 pr-5" placeholder="Search for Students" />
    <i className="pt-2 r-text--grey-4 fi flaticon-search" style="position: absolute; margin-left: -30px;" />
    </Col>
    </Row>*/}

    {this.state.getStudentProfileDetails.length!=0?
       <VerifySummaryCard searchText={this.state.searchText} processId={this.state.selectedProcess.process_id} studentDetails={this.state.getStudentProfileDetails} className="verifier__verify-summary-card" calcUnverifiedStudents={this.calcUnverifiedStudents} currentPage={this.state.currentPage} />:null}
    <Row>
    <Col className="verifier__page">
    <PaginationComponent
    totalItems={this.state.getStudentProfileDetails.length!==0?this.state.getStudentProfileDetails.length : 0}
    pageSize={9}
    onSelect={this.handleSelected}
    maxPaginationNumbers={10}
    activePage={this.state.currentPage + 1}  />
    </Col>
    </Row>


  </Container>
  )

}
}

 const mapStateToProps = state => ({
   ...state
 })
export default connect(mapStateToProps, null)(VerifierTest);
;
