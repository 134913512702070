import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from '../../../actions/Auth';
import { Loader } from '../../global/Loader';
import { setUser, setLoading } from '../../../actions/action-creators';
import {Row,Col,Container,Collapse, Modal, ModalBody, Table, Input, ModalHeader } from 'reactstrap';
import Button from '../../global/Button';
import Axios from 'axios';
import AddCollegeCollapse from './add_college_collapse';
import ShowCollege from './show_college';
import ShowUniversity from './show_university';
import MemberListChild from './member_list_child';
import MembersModel from './members_model';
import DepartmentModel from './department_model';
import {Confirmation} from '../../global/confirmation';
import {Errorbar} from '../../global/errorbar';
import store from '../../../store';
import Aux from '../../global/AuxComp';
import { InputField } from '../../global/Forms';
import AddUniversityCollapse from './add_university_collapse';


 class SpocOverview extends Component{
  constructor(props){
    super(props);
    this.state = { loading : false, isOn : false, showError:false, errorMsg : '', collapse: false, isMembersModel : false, createCollegeData : null, instituteList : null, membersModelInfo : {}, isDepartmentModel : false, isConfirmation : false, deleteDetailsConfirmParam : {}, degreeList : [] };
  }

  componentDidMount(){
    this.handleFetchInstitute();
  }
  setError =(isShow, msg) => {
    this.setState({showError : isShow, errorMsg : msg});
  }
  addDetailsExistedList =(university_id, collegeIndex, campusIndex) => {
    var data = this.state.instituteList;
    if(campusIndex === null){
      if(data[university_id].college[collegeIndex].campus.filter((child) => child.rip === true).length === 0)
      data[university_id].college[collegeIndex].campus.unshift({rip:true, campus_degree: []});
    }
    else
    data[university_id].college[collegeIndex].campus[campusIndex].campus_degree.unshift({rip:true});
    this.setState({instituteList : data});
  }

  modifyDetails = async (universityIndex, collegeIndex, campusIndex, courseIndex) => {

    this.toggleLoading(true);
      var data, api;
      var obj = this.state.instituteList;
      if(campusIndex === null && courseIndex=== null){
        data = obj[universityIndex].college[collegeIndex];
        api = Auth.Spoc.editCollege;
      }
      else if(courseIndex === null){
        data = obj[universityIndex].college[collegeIndex].campus[campusIndex];
        api = Auth.Spoc.editCampus;
      }
      try {
        var response = await api(data)
        this.modifyObjDetails(universityIndex, collegeIndex, campusIndex, courseIndex, 'isEditable', false);
      }catch (e){
        if(e.response.data.error.toString() !== null)
          this.setError(true, e.response.data.error.toString())
        else
          this.setError(true, 'Sorry! something went wrong. Please try again')
      }
    this.toggleLoading(false);
  }

  removeAppend = (universityIndex, collegeIndex, campusIndex, courseIndex) => {
    var obj = this.state.instituteList;
    if(courseIndex === null){
      obj[universityIndex].college[collegeIndex].campus.splice(campusIndex, 1);
    }
    else{
      obj[universityIndex].college[collegeIndex].campus[campusIndex].campus_degree.splice(courseIndex, 1);
    }
    this.setState({instituteList : obj});
  }
  createAppend = async (universityIndex, collegeIndex, campusIndex, courseIndex) => {
    this.toggleLoading(true);
    var obj = this.state.instituteList;
    if(courseIndex === null){
      var item = obj[universityIndex].college[collegeIndex].campus[campusIndex];
      if(!item.name){
        this.setError(true, 'Name should not be empty');
        this.toggleLoading(false);
        return false;
      }
      var campus = {
        name : item.name,
        city : item.city,
        state : item.state,
        adress : item.country,
        zip_code : item.pin,
        college_id : obj[universityIndex].college[collegeIndex].college_id,
        university_id : obj[universityIndex].college[collegeIndex].university_id,
      }
      try {
        var campusResTemp = await Auth.Spoc.createCampus(campus)
        var campus_id = campusResTemp.data.insertId;
        if(!campus_id){
          this.setError(true, 'Invalid Campus Name, Duplicate Entry')
          this.toggleLoading(false);
          return false;
        }
        var courseData = item.campus_degree;
        await Promise.all(courseData.map(async(item, i)=>{
          var course = {
            degree_id : item.degree_id,
            campus_id : campus_id,
            college_id : obj[universityIndex].college[collegeIndex].college_id,
            university_id : obj[universityIndex].college[collegeIndex].university_id,
          }
          try {
            var campusResTemp = await Auth.Spoc.createCourse(course)
          }catch (e){
            if(e.response.data.error.toString() !== null)
              this.setError(true, e.response.data.error.toString())
            else
              this.setError(true, 'Sorry! something went wrong. Please try again')
          }
        }));
        this.handleFetchInstitute();
      }catch(e){
        if(e.response.data.error.toString() !== null)
          this.setError(true, e.response.data.error.toString())
        else
          this.setError(true, 'Sorry! something went wrong. Please try again')
      }
    }
    else{
      var item = obj[universityIndex].college[collegeIndex].campus[campusIndex].campus_degree[courseIndex];
      var course = {
        degree_id : item.degree_id,
        campus_id : obj[universityIndex].college[collegeIndex].campus[campusIndex].campus_id,
        college_id : obj[universityIndex].college[collegeIndex].campus[campusIndex].college_id,
        university_id : obj[universityIndex].college[collegeIndex].campus[campusIndex].university_id,
        access_key: 3
      }
      try {
        var campusResTemp = await Auth.Spoc.createCourse(course)
        this.handleFetchInstitute();
      }catch (e){
        if(e.response.data.error.toString() !== null)
          this.setError(true, e.response.data.error.toString())
        else
          this.setError(true, 'Sorry! something went wrong. Please try again')
      }
    }

    this.toggleLoading(false);
  }
  confirmDeleteDetails = (universityIndex, collegeIndex, campusIndex, courseIndex) =>{
    this.setState({deleteDetailsConfirmParam : {universityIndex, collegeIndex, campusIndex, courseIndex}, isConfirmation : true})
  }
  deleteDetails = async () => {
    this.toggleLoading(true);
    let {universityIndex, collegeIndex, campusIndex, courseIndex} = this.state.deleteDetailsConfirmParam;
    var obj = this.state.instituteList;
    try {
      if(collegeIndex === null && campusIndex === null && courseIndex=== null){
        var id = obj[universityIndex].university_id;
        await Auth.Spoc.inactiveUniversity({university_id : id, status : 0});
        obj.splice(universityIndex,1);
      }
      else if(campusIndex === null && courseIndex=== null){
        var id = obj[universityIndex].college[collegeIndex].college_id;
        await Auth.Spoc.inactiveCollege({college_id : id, access_key: 3, status : 0});
        obj[universityIndex].college.splice(collegeIndex,1);
      }
      else if(courseIndex === null){
        var id = obj[universityIndex].college[collegeIndex].campus[campusIndex].campus_id;
        await Auth.Spoc.inactiveCampus({campus_id : id, access_key: 3, status : 0});
        obj[universityIndex].college[collegeIndex].campus.splice(campusIndex,1);
      }
      else{
        var id = obj[universityIndex].college[collegeIndex].campus[campusIndex].campus_degree[courseIndex].campus_degree_id;
        await  Auth.Spoc.inactiveCourse({campus_degree_id : id, access_key: 3, status : 0});
        obj[universityIndex].college[collegeIndex].campus[campusIndex].campus_degree.splice(courseIndex,1);
      }
     this.setState({instituteList : obj});
    }catch (e){
      if(e.response.data.error.toString() !== null)
        this.setError(true, e.response.data.error.toString())
      else
        this.setError(true, 'Sorry! something went wrong. Please try again')
    }
    this.toggleConfirmation()
    this.toggleLoading(false);
  }

  modifyObjDetails = (universityIndex, collegeIndex, campusIndex, courseIndex, key, value) => {
    var obj = this.state.instituteList;

    if(campusIndex === null && courseIndex=== null)
    obj[universityIndex].college[collegeIndex][key] = value;
    else if(courseIndex === null){
      obj[universityIndex].college[collegeIndex].campus[campusIndex] = obj[universityIndex].college[collegeIndex].campus[campusIndex] === null ? {} : obj[universityIndex].college[collegeIndex].campus[campusIndex];
      obj[universityIndex].college[collegeIndex].campus[campusIndex][key] = value;
    }
    else{
      obj[universityIndex].college[collegeIndex].campus[campusIndex].campus_degree[courseIndex][key] = value;
    }
    this.setState({instituteList : obj});
  }
  openAddCollege = (value) => {
    if(value){
      var createCollegeData = {
        name : null,
        campus_obj : [],
        university_id : value
      }
      if(this.state.createCollegeData === null)
      this.setState({createCollegeData : createCollegeData});
    }
    else
      this.setState({createCollegeData : null});
    this.setState({ collapse: value });
   }
  appendCreateCollegeData = (key, value) => {
    const {createCollegeData} = this.state
    this.setState({createCollegeData: {...createCollegeData, [key]:value}})
  }
  addCampus = () => {
    const {createCollegeData} = this.state
    createCollegeData.campus_obj.push({ "course_obj" : []});
    this.setState({createCollegeData: createCollegeData})

  }
  removeCampus = (campusKey) => {
    const {createCollegeData} = this.state
    createCollegeData.campus_obj.splice(campusKey,  1);
    this.setState({createCollegeData: createCollegeData})

  }
  removeCourse = (campusKey, courseKey) => {
    const {createCollegeData} = this.state
    createCollegeData.campus_obj[campusKey].course_obj.splice(courseKey,1);
    this.setState({createCollegeData: createCollegeData})

  }
  addCourse = (campusKey) => {
    const {createCollegeData} = this.state
    createCollegeData.campus_obj[campusKey].course_obj.push({});
    this.setState({createCollegeData: createCollegeData})

  }
  appendCreateCampusData = (index, key, value) => {
    const {createCollegeData} = this.state
    createCollegeData.campus_obj[index] = {...createCollegeData.campus_obj[index], [key]:value};
    this.setState({createCollegeData: createCollegeData})
  }
  appendCreateCourseData = (campusKey, courseKey , key, value) => {
    const {createCollegeData} = this.state
    createCollegeData.campus_obj[campusKey].course_obj[courseKey] = {...createCollegeData.campus_obj[campusKey].course_obj[courseKey], [key]:value};
    this.setState({createCollegeData: createCollegeData})
  }
  handleCreateCollegeSubmit = async (e) => {
    e.preventDefault();
    this.toggleLoading(true);
    var formData = Object.assign({}, this.state.createCollegeData);
    var rawInstituteList = this.state.rawInstituteList;
    if(!(rawInstituteList.length > 0 && rawInstituteList[0].university_id))
    {
      this.setError(true, 'Invalid university ID'); return false;
    }
    var university_id = rawInstituteList[0].university_id;
    var collegeData={
      university_id : formData.university_id,
      name : formData.name,
    }
    var campusData = formData.campus_obj;
    try {
      var collegeRes = await Auth.Spoc.createCollege(collegeData)
      var college_id = collegeRes.data.insertId;
      await Promise.all(campusData.map(async(item, i)=>{
        var courseData = item.course_obj;
        var campus = {
          name : item.name,
          city : item.city,
          state : item.state,
          adress : item.country,
          zip_code : item.pin,
          college_id : college_id,
          university_id : university_id,
        }
        try {
          var campusResTemp = await Auth.Spoc.createCampus(campus)
          var campus_id = campusResTemp.data.insertId;
          if(!campus_id){
            this.setError(true, 'Invalid Campus Name, Duplicate Entry')
            return false;
          }
          await Promise.all(courseData.map(async(item, i)=>{
            var course = {
              degree_id : item.degree_id,
              campus_id : campus_id,
              college_id : college_id,
              university_id : university_id,
            }
            try {
              var campusResTemp = await Auth.Spoc.createCourse(course)
            }catch (e){
              if(e.response.data.error.toString() !== null)
                this.setError(true, e.response.data.error.toString())
              else
                this.setError(true, 'Sorry! something went wrong. Please try again')
            }
          }));

        }catch (e){
          if(e.response.data.error.toString() !== null)
            this.setError(true, e.response.data.error.toString())
          else
            this.setError(true, 'Sorry! something went wrong. Please try again')
        }

      }));
      this.openAddCollege(false);
      this.handleFetchInstitute();
    } catch (e) {
      if(e.response.data.error.toString() !== null)
        this.setError(true, e.response.data.error.toString())
      else
        this.setError(true, 'Sorry! something went wrong. Please try again')
    }

    this.toggleLoading(false);

  }
  
  handleFetchInstitute = async (e) => {
    try {
      this.toggleLoading(true);
      var campusResTemp, degreeList;
      await Axios.all([Auth.Spoc.getInstitutesDetailsByAccess(), Auth.General.getDegrees()])
      .then(Axios.spread((res1, res2) => {
        campusResTemp = res1;
        degreeList = res2;
      }));
      campusResTemp.data = campusResTemp.data.map(univ => {
        univ.college = univ.college.sort((a, b) => a.name.localeCompare(b.name));
        univ.college = univ.college.map(college => {
          college.campus = college.campus.sort((a, b) => a.name.localeCompare(b.name));
          return college;
        })
        return univ;
      });
      this.setState({ instituteList: campusResTemp.data, rawInstituteList : campusResTemp.data, degreeList : degreeList.data });
    }catch (e){
      console.log(e)
      if(e.response)
        this.setError(true, e.response.data.error.toString())
      else
        this.setError(true, 'Sorry! something went wrong. Please try again')
    }
  this.toggleLoading(false);
  }

  openMembersModel = (value, role_type, accessLevel_ids) => {
    if(value && role_type && accessLevel_ids){
      this.setState({
        isMembersModel : true,
        membersModelInfo : {
          role_type,
          ...accessLevel_ids
        }
      })
    }
    else{
      this.setState({ isMembersModel: false, membersModelInfo : {} });
    }

   }


   toggleDepartmentModal = (value) => {
     this.setState({isDepartmentModel : value})
   }


   toggleLoading = loading => {
     this.setState({
       loading
     })
   }
   handleToggle(e) {
     this.setState({isOn: !this.state.isOn});
   }
   toggleConfirmation = () => {
     this.setState({isConfirmation : false, deleteDetailsConfirmParam : {}})
   }
  render(){
    let { collapse,closeBtn, createCollegeData, loading, membersModelInfo, isConfirmation, degreeList } = this.state;
    let collegeCollapseProps = {
      closeAddCollege : () =>this.openAddCollege(false),
      openAddCollege : this.openAddCollege,
      addCampus : this.addCampus,
      removeCampus : this.removeCampus,
      removeCourse : this.removeCourse,
      addCourse : this.addCourse,
      createCollegeData, degreeList,
      isOpen : collapse,
      appendCreateCollegeData : this.appendCreateCollegeData,
      appendCreateCampusData : this.appendCreateCampusData,
      appendCreateCourseData : this.appendCreateCourseData,
      handleCreateCollegeSubmit : this.handleCreateCollegeSubmit
    }
    var currentRoleInfo = store.getState().role;
      return (

      <Container className="pb-5">
      <Loader show={loading} />
        <Row className="mt-5">
          <Col xs={2}>
            <h1 className="r-subheading-1 mt-4">Overview</h1>
            </Col>
            { (currentRoleInfo.role_type === "ADMIN") && <AddUniversityCollapse handleFetchInstitute={this.handleFetchInstitute} toggleLoading={this.toggleLoading}/>
            }
            { (!currentRoleInfo.instituteDetail.college_id && this.state.instituteList && this.state.instituteList.length <= 1) &&
            <Aux><Col>
              <Button onClick={() => this.openAddCollege(this.state.instituteList[0].university_id)} className="r-text--sm mt-4" color="brandB"><i className="fi flaticon-plus mr-2"></i> Add New College</Button>
            </Col>
            <Col xs={12}>
              <AddCollegeCollapse {...collegeCollapseProps} />
            </Col></Aux>
            }
          </Row>


        <Row className="mt-4 py-3 r-bg--blue-2 r-text--sm align-items-center">
          <Col xs={5} className="" >
            <Row className="align-items-center">
              <Col>
                <h1 className="r-subheading-4">Name</h1>
              </Col>
            </Row>
          </Col>
          <Col xs={7}>
            <Row className="align-items-center  text-center">
              <Col style={{whiteSpace: 'nowrap'}} xs={3}><h1 className="r-subheading-4">Placement Team</h1></Col>
              {/* <Col  xs={2} ><h1 className="r-subheading-4">Society</h1></Col> */}
              <Col  xs={2}><h1 className="r-subheading-4">Students</h1></Col>
              <Col  xs={3}><h1 className="r-subheading-4">Admin</h1></Col>
              {/* <Col  xs={1}><h1 className="r-subheading-4">Status</h1></Col> */}
              <Col  xs={4}><h1 className="r-subheading-4">Actions</h1></Col>
            </Row>
          </Col>
        </Row>

        { this.state.instituteList && (
          this.state.instituteList.length <= 1 ?
           this.state.instituteList[0].college.map((child, index) => <ShowCollege key={index} universityIndex={0} collegeIndex={index} instituteData={child} appendCreateCollegeData={this.appendCreateCollegeData} modifyDetails={this.modifyDetails} modifyObjDetails={this.modifyObjDetails} deleteDetails={this.confirmDeleteDetails} addDetailsExistedList={this.addDetailsExistedList} removeAppend={this.removeAppend} createAppend={this.createAppend} openMembersModel={this.openMembersModel} toggleDepartmentModal = {this.toggleDepartmentModal} degreeList={degreeList} />)
           :
           this.state.instituteList.map((child, index) => <ShowUniversity handleFetchInstitute={this.handleFetchInstitute} toggleLoading={this.toggleLoading} key={index} universityIndex={index} instituteData={child} appendCreateCollegeData={this.appendCreateCollegeData} modifyDetails={this.modifyDetails} modifyObjDetails={this.modifyObjDetails} deleteDetails={this.confirmDeleteDetails} addDetailsExistedList={this.addDetailsExistedList} removeAppend={this.removeAppend} createAppend={this.createAppend} openMembersModel={this.openMembersModel} toggleDepartmentModal = {this.toggleDepartmentModal} degreeList={degreeList} {...collegeCollapseProps} />)
         ) }

        {membersModelInfo.role_type && <MembersModel handleFetchInstitute={this.handleFetchInstitute} isMembersModel={this.state.isMembersModel} modalInfo={membersModelInfo} closeMembersModel={ () => this.openMembersModel(false)} />}

        {this.state.isDepartmentModel && <DepartmentModel setError={this.setError} isDepartmentModel={this.state.isDepartmentModel} toggleDepartmentModal={this.toggleDepartmentModal} />}
        <Confirmation isOpen={isConfirmation} toggleConfirmation={this.toggleConfirmation} callback={this.deleteDetails} />
        <Errorbar showBar={this.state.showError} onClose={() => this.setError(false, "")} message={this.state.errorMsg}/>

      </Container>
  )
 }
}

export default SpocOverview;
