import React from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { AddSuggestion } from '../../../global/Forms/AddSuggestion';
import AutosuggestComp from '../../../global/AutosuggestComp';
import withRouter from 'react-router-dom/withRouter';
import {HorizontalLine} from '../../../global/horizontal_line';
import { Auth } from '../../../../actions/Auth';
import { setUser, setLoading } from '../../../../actions/action-creators';
import { Row,Col,Container,  Modal, ModalHeader,Nav, Table, Collapse, NavItem, NavLink,TabContent, TabPane, ModalBody, ModalFooter ,Spinner} from 'reactstrap';
import Button from '../../../global/Button';
import {Errorbar} from '../../../global/errorbar';
import {SelectList} from '../../../global/Forms/Select'
import moment from 'moment';
import { InputField } from '../../../global/Forms';
import classnames from 'classnames';
import EditBatch from './EditBatch.js'
import { Switch } from '../../../global/Forms/ToggleSwitch';

class Batch extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      batchName:'',
      batchdata:[],
      startYear: 2019,
      endYear: 2019,
      errorbar: false,
      message:'',
      years:[],
      batchs:[],
      isOpen: false,
      editBatch: false,
      degreeidList: [],
      degreeValue: "",
      campusID:"",
      campusDegreeName: "Select degree ",
      campusDegreeID: "",
      degreeid: null,
      allowPTEdit: false
    };
  }

  onChange = e => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }

  closebar = () =>{
      this.setState({errorbar: false});
  }

  handleyear (name, value){
    if(name==="startYear"){
        this.setState({startYear: value})
      }
     else{
        this.setState({endYear: value});
     }
  }

  handleCampusID(id, name){
    this.setState({campusID: id})
    this.setState({campusName: name})
  }

  handlecampusDegreeID (data){
    this.setState({campusDegreeName: data.degree_name})
    this.setState({campusDegreeID: data.campus_degree_id})
  }

   toggleOpen = () => {
    this.setState({ isOpen: true });
  }

  componentDidMount () {
    const {years} = this.state;
    this._init();
    var currentYear = new Date().getFullYear();
    var startYear = currentYear-10;
    while ( startYear <= currentYear+10 ) {
        this.state.years.push(startYear++);
    }
    this.setState({years});
  }

  _init = async() =>{
      let {batchdata} = this.state;
    this.setState({setloading: true});
     try {
      let response = await Auth.Batch.getBatchDetail();
      let res = await Auth.General.getDegreesCampus();
      this.setState({batchdata: response.data});
      let resp = await Auth.Spoc.getCourses(2);
      this.setState({degreeidList: res.data})
      await this.getConfig()
      this.setState({setloading: false});
    } catch (e) {
      console.log(e)
        this.setState({setloading: false});
      // this.setState({errorbar: true});
     // this.setState({message: e.response.data.error.toString()});
       }
  }


   toggleClose = () => {
    this.setState({ isOpen: false });
  }

  onSubmit = async(e) =>{
    e.preventDefault();
    this.setState({setloading: true});
    var batchBody = {
          "batch_name":this.state.batchName,
          "start_year":""+this.state.startYear+"-01-05",
          "end_year": ""+this.state.endYear+"-01-05",
          "campus_degree_id":this.state.campusDegreeID,
        }
    try {
          let resp = await Auth.PtStudents.createBatch(batchBody);
          this.setState({
            degreeid: null,
            degreeValue: "",
            campusDegreeName: "Select degree ",
            batchName:'',
            startYear: 2019,
            endYear: 2019,
          })
          this._init();
          this.setState({setloading: false});
          this.toggleClose();
        } catch (e) {
         this.setState({setloading: false});
          this.setState({errorbar: true});
          this.setState({message: e.response.data.error.toString()});
        }
}


  setDegreeValue = (value) => {
    let evtObj = {
      target: {
        value: value.degree_id,
        name: "degree_id",
        degree_name: value.degree_name
      }
    }
    this.setState({
      degreeValue: value.degree_name,
      degreeid:value.degree_id
    })
  }

  getConfig = async ()=>{
    let res =await  Auth.User.getConfiguration();
    if(res.data.STUDENT){
     if(res.data.STUDENT.PROFILE) {
       this.setState({
         allowPTEdit: res.data.STUDENT.PROFILE.allow_pt_edit? true: false
       })
     }
    }
  }
  
  handleProfileEditConfig = async() => {
    let {allowPTEdit} = this.state;
    allowPTEdit = !allowPTEdit
    try {
      await Auth.User.createConfiguration({
        "module": "STUDENT",
        "feature": "PROFILE",
        "config": {
            allow_pt_edit: allowPTEdit?1:0
        }
      })
      this.setState({ allowPTEdit })
    } catch(e) {
      console.log(e)
    }
  }


  render() {
    const {batchName,batchs,degreeid, degreeValue,startYear,endYear,campusDegreeID, editBatch,degreeidList, batchdata, allowPTEdit,} =this.state;
    const autoSuggestProps = {
      onChange: this.onChange,
        className: "w-100 r-inputfield",
    };
    return (
        <Container className="apply" id="pt-students">
              <Row className="justify-content-between align-items-center my-3">
                <Col xs="auto">
                  <div className="heading-dropdown r-heading-3">
                    <Button onClick={this.props.history.goBack} className="font-weight-normal py-0" color="blue3"><img style={{height:"30px"}} src="/images/left-arrow.png" alt=""/></Button>
                    {this.props.location.state && this.props.location.state["some"]=="state"?"Settings/ ":null} Manage Batches
                  </div>
                </Col>
                <Col xs="auto">
                  <Row>
                    {/*<Col xs="auto">
                      <Row className="align-items-center">
                        <Col xs="auto">
                      <span>Placement Team can edit students profile</span>
                      </Col>
                        <Col xs="auto">
                      <Switch handleToggle={this.handleProfileEditConfig} isOn={allowPTEdit}/>
                      </Col>
                      </Row>
                    </Col>*/}
                    <Col xs="auto">
                      <Button onClick={this.toggleOpen} className="add-count" color="brandB">
                        <i className="fi flaticon-user" />
                        <span className="mx-2">Add batch</span>
                        <i className="fi flaticon-plus-1 border-left pl-2" />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            <HorizontalLine  color ="#cfcfcf"/>
           <Row className="justify-content-between align-items-center  r-bg--blue-2 mx-0 py-3 mt-4">
              <Col xs="2"  className="text-center">
                 <span className="r-heading-3">Batch</span>
              </Col>
              <Col xs="3"  className="text-center">
                 <span className="r-heading-3">Degree</span>
              </Col>
              <Col xs="2"  className="text-center">
                    <span className="r-heading-3">Start Year</span>
              </Col>
              <Col xs="2"  className="text-center">
                  <span className="r-heading-3">End Year</span>
              </Col>
              <Col xs="1" className="text-center">
                 <span className="r-heading-3">Actions</span>
              </Col>
              <Col xs="2" className="text-center">
                 <span className="r-heading-3">Archieve</span>
              </Col>
           </Row>
           {this.state.setloading? <div className="center-xy"><Spinner color="dark"/></div>:null}
                     {(!this.state.isOpen)?null
              :<form  onSubmit={this.onSubmit}>
                          <Row  className="justify-content-between align-items-center  mx-0  mt-4">
                              <Col xs="2"  className="text-center">
                                <InputField className="r-nav__search" style={{minWidth: "170px"}}   type="text" name="batchName" value={batchName} onChange={this.onChange} required/>
                              </Col>
                              <Col xs="3"  className="text-center">
                                    <select value={campusDegreeID} name="campusDegreeID" className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} onChange={this.onChange} required>
                                      <option value="" >Select Degree</option>
                                      {degreeidList.map(data => {
                                        return <option key={data.campus_degree_id} value={data.campus_degree_id}>{data.degree_name}</option>
                                      })}
                                    </select>
                              </Col>
                              <Col xs="2"  className="text-center">
                                  <select value={startYear} name="startYear" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={this.onChange} required>
                                         <option value="" >Select start endYear</option>
                                           {this.state.years.map(year =>{
                                             return <option key={year}  value={year}>{year}</option>
                                           })}
                                   </select>
                              </Col>
                              <Col xs="2"  className="text-center">
                                    <select value={endYear} name="endYear" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={this.onChange} required>
                                         <option value="" >Select end year</option>
                                           {this.state.years.map(year =>{
                                             return <option key={year}  value={year}>{year}</option>
                                           })}
                                     </select>
                              </Col>
                              <Col xs="1"  className="text-center">
                                  <Row className="justify-content-center align-items-center">
                                     <Button  type="submit" ><i className="r-text--blue-3 d-inline-block fi flaticon-save" style={{fontSize:"15px"}}/></Button>
                                     <Button type="button" onClick={this.toggleClose}><i className="d-inline-block fi flaticon-plus-1 rotate--45" style={{fontSize:"15px"}}/></Button>
                                  </Row>
                              </Col>
                              <Col xs="2"  className="text-center">
                                  <Row className="justify-content-center align-items-center">
                                     <Button  type="submit" ><i className="r-text--blue-3 d-inline-block fi flaticon-save" style={{fontSize:"15px"}}/></Button>
                                     <Button type="button" onClick={this.toggleClose}><i className="d-inline-block fi flaticon-plus-1 rotate--45" style={{fontSize:"15px"}}/></Button>
                                  </Row>
                              </Col>
                          </Row>
              </form>
            }
           <div className=" my-4">
           {batchdata.map((data, i) =>{
                    return(
                      <div key={i}>
                        <EditBatch data={data} updateBatch={this._init} degreeidList={degreeidList}/>
                      </div>
                      )
                  })
            }
            </div>
                    <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
        </Container>
    );
  }
}


const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoading: (show) => dispatch(setLoading(show)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Batch));
