import React, { Component } from 'react';

import { Button, Popover, PopoverHeader, PopoverBody, Spinner } from 'reactstrap';
import Aux from '../../../../global/AuxComp';
import { InputField } from '../../../../global/Forms';
import { Row, Col } from 'reactstrap';
import { Card, CardHeader, CardFooter, CardBody, CardTitle, CardText } from 'reactstrap';
import { Auth } from '../../../../../actions/Auth';
import moment from 'moment';
import { CONSTANTS } from '../../../../../constants';


class CommentBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: '',
      messages: null,
    };
  }

  async componentDidMount() {
    await this.init();
    var element = document.querySelector('.verifier__comment-body');
    element.scrollTop = element.scrollHeight;
  }

  init = async () => {
    var verifierCommentThreadId = this.props.verifierCommentThreadId;
    if (verifierCommentThreadId) {

      let messages = [];

      try {

        let getComment = await Auth.CommentBox.getComment(verifierCommentThreadId);
        getComment.data.map(comment => {
          messages.push(comment);
        })

        this.setState({
          messages: messages
        })
      }
      catch (e) {
        console.log(e);
      }
    }
  }

  sendComment = async (e) => {
    e.preventDefault();
    this.setState({ isLoadingSpinner: true });
    let formData = {
      "verifier_comment_thread_id": this.props.verifierCommentThreadId,
      "comment_body": this.state.input,
      "commented_by_type": "STUDENT"
    }
    try {
      let comment = await Auth.CommentBox.comment(formData);
      let messages = this.state.messages
      messages.push(formData)
      this.setState({
        messages,
        input: ''
      })
      var element = document.querySelector('.verifier__comment-body');
      if (element) element.scrollTop = element.scrollHeight;
    }
    catch (e) {
      console.log(e);
    }
    this.setState({ isLoadingSpinner: false });
  }
  render() {
    let { popoverName, isOpenCommentBox, commentThreads, profileType, verifierCommentThreadId, toggleCommentBox } = this.props;
    let { isLoadingSpinner } = this.state;
    return (

      <Popover className="w-100 h-auto rounded commentbox" placement="auto" isOpen={isOpenCommentBox} target={popoverName} toggle={() => { console.log('helo') }}>
        <PopoverHeader className="m0 p-3  r-bg--blue-3 w-100 r-text--light "><span className="r-subheading-3" align="center">Verifier Comments</span><Button onClick={toggleCommentBox} className="rotate--45 float-right" color="link r-text--light pt-0"><i className="fi flaticon-plus"></i></Button></PopoverHeader>
        <CardBody className="p-0 m-0 verifier__comment-body"  >
          {
            this.state.messages !== null ? (this.state.messages.map((messages,i) => {

              return (
                <div key={i} className={(messages.commented_by_type === 'STUDENT' ? 'text-right mr-3' : '')}>
                  <span className={"talk-bubble tri-right  ml-4 mt-3 rounded " + (messages.commented_by_type === 'STUDENT' ? "right-top" : "left-top")} align="start">
                    <span className="talktext">
                      <Row className="ml-3">
                        <span className="r-subheading-4 p-1 s">{messages.comment_body}</span>
                      </Row>
                    </span>
                  </span>
                  <Row className="ml-3 mt-1 mb-2 p-0">
                    <Col xs="12" className={(messages.commented_by_type === 'STUDENT' ? 'text-right mr-3' : 'pl-2')}>
                      <span className="">{moment(messages.created_on).format(" h:mm a , Do MMMM YYYY ")}</span>
                    </Col>
                  </Row>
                </div>

              )
            })) : <div className="center-xy"><Spinner color="dark" /></div>
          }

        </CardBody>
        <CardFooter className="p-1 m-0 mt-0 w-100 r-bg--blue-1 overflow-hidden">
          <form onSubmit={this.sendComment}>
          <Row>
            <Col xs="9" >
              <InputField required placeholder="Type here..." onChange={(e) => { this.setState({ input: e.target.value }) }} value={this.state.input} className="w-100  m-1  verifier__comment-box" />
            </Col>
            <Col xs="3" className="p-0 mt-1">
              <button type="submit" className="border-0 verifier__send-button" ><div className="center-xy" style={{ left: '-2%', display: isLoadingSpinner ? 'block' : 'none' }}><Spinner size="sm" /></div><span className="r-subheading-3 p-0">Send</span></button>
            </Col>
          </Row>
          </form>
        </CardFooter>
      </Popover>

    );
  }
}
export default CommentBox;
