import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Collapse } from "reactstrap";
import RButton from '../../global/Button'
import { Auth } from '../../../actions/Auth';
import { InputField } from '../../global/Forms';
import store from '../../../store';
import { setLoading } from '../../../actions/action-creators';
import { Select } from '../../global/Forms/Select';


export class ManageSpecialization extends Component {
    state = {
        specializationModal: false,
        isSpecializationDelete : false,
        specialization : [],
        addSpecializationCollapse : false,       
        specializationType : "",
        isConfirmDelete : false,
        deleteSpecializationId : null
      }
    

    init = async () => {
        store.dispatch(setLoading(true))
        try {
          let resp = await Auth.Specialization.fetch()
          this.setState({ specialization: resp.data })
          this.setState({ specializationModal: true })

        } catch (e) {
          console.log(e)
        }
        store.dispatch(setLoading(false))
      }

    addSpecialization = async(e) => {
        e.preventDefault()
        store.dispatch(setLoading(true))
       var body = {
        specialization_name : e.target.specialization_name.value,
        academic_type : this.state.specializationType
       }
        try {
          await Auth.Specialization.createSpecialization(body)
  
          this.setState({addSpecializationCollapse: false})
        } catch(e) {
          console.log(e)
        }
        store.dispatch(setLoading(true))
        await this.init()
      }
      
      deleteSpecialization = async () => {
        store.dispatch(setLoading(true))
        try {
          var body = {
            specialization_id : this.state.deleteSpecializationId,
            status: 0
          }
          await Auth.Specialization.deleteSpecialization(body)
          this.setState({ isConfirmDelete: false, deleteSpecializationId : null })
        } catch(e) {
          console.log(e)
        }
        store.dispatch(setLoading(true))
        await this.init()
      }
      
   
  render() {
    let { specializationModal, addSpecializationCollapse, isConfirmDelete, specialization, specializationType} = this.state
    return (
      <Row>
        <Col className="ml-2">
          <Button 
            onClick={this.init}
            className="r-subheading-3 r-text--grey-4 position-relative pr-0"
            color="BrandA"
            style={{ fontSize: "14px" }}>
            Specialization
          </Button>

          <Modal zIndex="99999" size="md" isOpen={specializationModal} toggle={() => this.setState({ specializationModal: false })}>
            <ModalBody>
              <Row noGutters>
                <Col>
                  <Row noGutters className="justify-content-between">
                    <Col xs="auto">
                      <p className="r-subheading-3">Specializations</p>
                    </Col>
                    <Col xs="auto">
                      <RButton onClick={()=>{this.setState({addSpecializationCollapse: !addSpecializationCollapse})}} color="blue"><i className="fi flaticon-plus-1 " />Add</RButton>
                    </Col>
                  </Row>

                  <Collapse isOpen={addSpecializationCollapse}>

                  <form onSubmit={this.addSpecialization}>

                      <Row>
                        <Col xs={12}><p className="r-text--sm text-danger">* The specialisations added here will reflect on the student's profile. Students can select one or more specialisations for UG / PG while filling the profile.
The specialisations will also be a part of the eligibility criteria when applications are invited.</p></Col>
                        <Col  xs={12} className="mb-3">
                          <Select required onChange={(e)=>{this.setState({specializationType: e.target.value})}} value={specializationType}>
                            <option value="">Select academic type</option>
                            <option value="UG">Under Graduation</option>
                            <option value="PG">Post Graduation</option>
                          </Select>
                        </Col>
                        <Col  xs={12}>
                            <InputField required className="w-100" type="text" name="specialization_name"  placeholder="Enter Specialization" />
                            <div className="text-right mt-3">
                              <RButton type="reset" onClick={()=>{this.setState({addSpecializationCollapse: false})}}>Cancel</RButton>
                              <RButton color="dark" className="r-btn--no-radius">Save</RButton>
                            </div>
                        </Col>
                      </Row>
                    </form>

                  </Collapse>
                </Col>
              </Row>
              <Modal zIndex="99999" size="sm" isOpen={isConfirmDelete} toggle={() => this.setState({ isConfirmDelete: false })}>
                <ModalBody>
                  <h3>Are you Sure ?</h3>
                  <Row className="mt-3">
                    <Col className="text-right">
                      <Button onClick={() => this.setState({ isConfirmDelete: false })} color="dark-light">Cancel</Button>
                      <Button color="dark" onClick={this.deleteSpecialization} >Delete</Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              { specialization.map((ct, i) =>
                  <Row key={ct.specialization_id} className="py-2 mb-2 border-bottom justify-content-between no-gutters ">                      
                      <Col xs="1"> {i+1} </Col>
                      <Col xs="6">{ct.specialization_name}</Col>
                      <Col xs="4">{ct.academic_type == "UG" ? "Under Graduation" : "Post Graduation"}</Col>
                      <Col xs="1"><RButton onClick={() => this.setState({ isConfirmDelete: true, deleteSpecializationId : ct.specialization_id })}><i className="fi flaticon-bin" /></RButton></Col> 

                      
                  </Row>
                   )
                }
            </ModalBody>
          </Modal>
          
        </Col>
      </Row>
    )
  }
}