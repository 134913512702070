import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Row,Col,Container,Collapse} from 'reactstrap';
import Button from '../../global/Button';
import { InputField } from '../../global/Forms';
import { Switch } from '../../global/Forms/ToggleSwitch';
import AddCampusCollapse from './add_campus_collapse';

  export default class AddCollegeCollapse extends Component{
    constructor(props){
       super(props);
         this.state = { isOn: false};
     }

	  handleToggle(e) {
  		this.setState({isOn: !this.state.isOn});
  	}


    render(){
      let { isOpen, appendCreateCollegeData, createCollegeData, closeAddCollege, addCampus, appendCreateCampusData, appendCreateCourseData, handleCreateCollegeSubmit, addCourse, removeCampus, removeCourse, degreeList } = this.props;
      return (

        <Collapse isOpen={isOpen}>
          <div className="tree">
          <Row className="border__t align-items-center">
            <Col xs={8}>
              <h1 className="r-subheading-4">College Name</h1>
              <InputField onChange={(e) => appendCreateCollegeData('name', e.target.value)} value={createCollegeData && createCollegeData.name ? createCollegeData.name : ""} className="w-100" type="text" />
            </Col>
            <Col xs={1}>
              <h1 className="r-subheading-4">Status</h1>
              <div className="mt-3">
              <Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)} />
              </div>
            </Col>
            <Col xs={2} className="align-middle">
              <Button onClick={addCampus} className="r-text--sm ml-3" color="link-blue"><i className="d-inline-block fi flaticon-arrow rotate--90 mr-2"></i>Add New Campus</Button>
            </Col>
            <Col xs={1}>
              <Button onClick={closeAddCollege} className="r-text--sm ml-3"><i className="d-inline-block fi flaticon-plus-1 rotate--45 mr-2"></i></Button>
            </Col>
          </Row>

          {
            createCollegeData && createCollegeData.campus_obj.map((child, i) => <AddCampusCollapse key={i} campusKey={i} campusData={child} removeCampus={removeCampus} addCourse={addCourse} appendCreateCampusData={appendCreateCampusData} appendCreateCourseData={appendCreateCourseData} removeCourse={removeCourse} degreeList={degreeList} /> )
          }
          <Row className="mt-5">
            <Col xs={12} className="text-right" >
              <Button  onClick={closeAddCollege}  className="r-text--sm mt-4" color="outline-dark-v" square="true">Cancel</Button>
              <Button onClick={handleCreateCollegeSubmit} className="r-text--sm mt-4 ml-2" color="dark-v" square="true">Save</Button>
            </Col>
          </Row>
          </div>
        </Collapse>
    )
  }
}
