import React,{Component} from 'react';
import { Auth } from '../../../../actions/Auth';
import {Row,Col,Container} from 'reactstrap';
import Aux from '../../../global/AuxComp';
import {Button} from 'reactstrap';
import moment from 'moment';
import  {InputField} from '../../../global/Forms';
import { SingleDatePicker } from '../../../global/Forms/DatePicker';
import DatePicker from 'react-dates/esm/components/SingleDatePicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import Link from 'react-router-dom/Link';
import {Errorbar} from '../../../global/errorbar';
import {YearPicker, MonthPicker , DayPicker} from 'react-dropdown-date';
import { withRouter } from "react-router";
import {  Modal, ModalHeader, ModalBody, ModalFooter,Collapse } from 'reactstrap';


class StatusModal extends Component{
  constructor(props){
    super(props);
    this.state={
      modal:false,
      value: 1,
      title: null,
      description : null,
      location: null,
      year: null,
      month:  null,
      day:   null,
      endYear:  null,
      endMonth:  null,
      endDay:  null,
      startTime: null,
      endTime:  null,
      eventType : null,
      startDate: null,
      endDate : null,
      params : null,
      studentCollection : [],
      event_id : null,
      studentMails : [],
      body: null,
      subject : null,
      isOn : null,
      inviteId : null,
      studentlist  : null,
      reminderValue : null,
      remindDate : null,
      remindTime : null,
      ptMemberList : [],
      eventId :  null,
      errorbar: false,
      collapse: false,
      collapse1: false,
      end: moment(new Date()).format('YYYY-MM-DD'),
      endTime:  null,
      message:''
    }
  }

  closebar = () =>{
      this.setState({errorbar: false});
  }



  OnChangeStartTime = (e)=>{
    this.setState({
      startTime : moment(e)
    })
  }

  onChange = e => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }

 toggle = () => {
    this.setState({ 
      collapse: true,
      collapse1: false,
    });
  }
  toggle1 = () => {
    this.setState({ 
      collapse: false,
      collapse1: true,
    });
  }

  submitStatus =()=>{
    const {year, month, day,collapse,end, startTime}=this.state;
    let deadline =  (moment(end).format('YYYY-MM-DD')+" "+moment(startTime).format('HH:mm:ss'));
    this.props.submitStatus(collapse, deadline)
  }

  changeEnd = (end) => {
    this.setState({
      end: moment(end).format('YYYY-MM-DD')
    })
  }


  render(){
    return(
            <Modal  zIndex="99999" className="modal-dialog-centered"  size="md"  isOpen={this.props.isOpenstatusModal} toggle={this.props.toggleStatausModal} >
              
             <Row  className="justify-content-start mx-3 my-2">
                  <span className="r-heading-3">Freeze Profile </span>
             </Row> 
             <Row  className="justify-content-start mx-0 my-3 ">
                <Col xs="6">
                  <Row className="align-items-center mx-0" >
                        <input type="radio" checked={this.state.collapse} onChange={this.toggle}/>
                        <span className="px-2 r-subheading-3">Now</span>
                  </Row>
                </Col>
                <Col xs="6">
                    <Row className="align-items-center mx-0" >
                      <input type="radio" checked={this.state.collapse1} onChange={this.toggle1}/>
                      <span className="px-2 r-subheading-3">Later</span>
                    </Row>
                </Col>
             </Row>
            <Collapse isOpen={this.state.collapse1}>
              <Row className="align-items-center mx-0">
                  <Col xs="4">
                          <SingleDatePicker
                          value={this.state.end}
                          onDateChange={this.changeEnd}
                          id="dob"/>
                  </Col>
                  <Col xs="8">
                          <TimePicker  placeholder="hh:mm:ss" className="r-bg--grey" popupClassName="timePickermodal"  className=" w-25"  onChange={this.OnChangeStartTime}/>
                  </Col>
              </Row>
            </Collapse>
             <Row  className="justify-content-end align-items-center  mx-0  my-2 " style={{textAlign:"center",}}>
                  <Button onClick={this.submitStatus} className="font-weight-normal mx-3  px-5" color="dark"> Submit</Button>
                  <Button onClick={this.props.toggleStatausModal} className="font-weight-normal mx-3  px-5" color="outline-dark-v">Cancel</Button>
             </Row>
               <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
            </Modal>
          )
  }
}

export default StatusModal;
