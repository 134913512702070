import React,{Component} from 'react';

import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import Aux from '../../global/AuxComp';
import {InputField} from '../../global/Forms';
import {Row, Col} from 'reactstrap';
import { Card, CardHeader, CardFooter, CardBody, CardTitle, CardText} from 'reactstrap';
import { Auth } from '../../../actions/Auth';
import moment from 'moment';
import { connect } from 'react-redux';

class PopoverExampleMulti extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: this.props.popoverOpen,
      input: '',
      messages: null,
      verifierThreaId: null,
      studentDetails: null,
      verifierCommentThreadId: null
    };
  }

createCommentThread= async ()=>{
      let formData={
        "verifier_id": this.props.role.assigned_role_id,
        "student_id":this.props.student_id,
        "profile_id":this.props.profile_id,
        "profile_type":this.props.profile_type
    };
    let createCommentThread = await Auth.CommentBox.createCommentThread(formData);
    this.setState({
      verifierCommentThreadId : createCommentThread.data[0].verifier_comment_thread_id
    })
}

getMessageList = async()=>{
  let messages = [];
  if( this.state.verifierCommentThreadId!=null || this.props.allCommentThread.length!=0){
    try{
      let getComment = await Auth.CommentBox.getComment(this.state.verifierCommentThreadId!=null?this.state.verifierCommentThreadId : this.props.allCommentThread[0].verifier_comment_thread_id);
      getComment.data.map(comment=>{
        messages.push(comment);
      })

      this.setState({
        messages: messages
      })
    }
    catch(e){
      console.log(e);
    }
  }
  }

  handleChange = (e)=>{
    // await this.getMessageList();
    this.setState({
      input: e.target.value
    })
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  createComment= async(e)=>{
    // this.handleChange(e);
    let formData = {
      "verifier_comment_thread_id":this.state.verifierCommentThreadId,
      "comment_body": this.state.input,
      "commented_by_type":"VERIFIER"
    }
    try{
      let comment = await Auth.CommentBox.comment(formData);
      let messages = [];
      messages.push(formData)
      await  this.setState({
        messages,
        input:''
      })
    }
    catch(e){
      console.log(e);
    }

  }



  onClickHandler = async()=>{
    if(this.state.verifierCommentThreadId==null){
      await this.createCommentThread();
    }

    await this.createComment();
    await this.getMessageList();
    let element = document.querySelector('#messageBody');
    if (element) element.scrollTop = element.scrollHeight;
  }

  async componentDidMount(){
    await this.getMessageList();
  }

  handleFormSubmit = (e)=>{
    e.preventDefault();
    this.onClickHandler();
  }

  render() {
    let element = document.querySelector('#messageBody');
    if (element) element.scrollTop = element.scrollHeight;
    return (
      <Aux>
        <Button onClick={()=>this.getMessageList()} className="verifier__section-card-button"  id={this.props.popoverName} type="button">
        <span className="r-subheading-4 fi flaticon-chat"> </span>
        </Button>
        <Popover  className="w-100 h-auto  rounded" placement="right" isOpen={this.state.popoverOpen} target={this.props.popoverName}  toggle= {this.toggle}>

          <PopoverHeader className="m0 p-3  r-bg--blue-3 w-100 r-text--light ">
          <Row>
          <Col xs="10">
          <span className="r-subheading-3" align="center">
         {this.state.studentDetails!=null?(this.state.studentDetails.personal_info[0].user_info.first_name):"Verifier Comments"}</span></Col>
          <Col xs="2">
          <button className="border-0 r-bg--blue-3 r-text--light" onClick={()=>this.setState({
            popoverOpen: !this.state.popoverOpen
          })}><i className="fi flaticon-plus-3 r-subheading-3"></i></button></Col></Row></PopoverHeader>

          <CardBody className="p-0 m-0 verifier__comment-body" id="messageBody"  >


          {
            this.state.messages!=null?(this.state.messages.map(messages=>{
              return(

                <div className={(messages.commented_by_type=='VERIFIER' ? 'text-right mr-3' : '')}>
                <span className={"talk-bubble tri-right  ml-4 mt-3 rounded "+(messages.commented_by_type=='VERIFIER'? "right-top":"left-top")} align="start">
                <span className="talktext">
                <Row className="ml-3">
                <span className="r-subheading-4 p-1 s">{messages.comment_body} </span>
                </Row>
                </span>
                </span>
                <Row className="ml-3 mt-1 mb-2 p-0">
                <Col xs="12" className={(messages.commented_by_type=='VERIFIER' ? 'text-right mr-3' : 'pl-2')}>
                <span  className="">{moment(messages.created_on).format(" h:mm a , Do MMMM YYYY ")}</span>
                </Col>
                </Row>
                </div>


              )
            })):null
          }




          </CardBody>
          <CardFooter className="p-1 m-0 mt-0 w-100 r-bg--blue-1 overflow-hidden">
          <form onSubmit={this.handleFormSubmit}>
          <Row>
          <Col xs="9" >
          <InputField placeholder="Type here..." id="message" className="w-100  m-1  verifier__comment-box" value={this.state.input} onChange={this.handleChange}/>
          </Col>
          <Col xs="3" className="p-0 mt-1">
           <button type="button" className="border-0 verifier__send-button" id="sendButton" on onClick={()=>this.onClickHandler()}><span className="r-subheading-3 p-0">Send</span></button>
          </Col>
          </Row>
          </form>
          </CardFooter>
        </Popover>
      </Aux>
    );
  }
}
const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(PopoverExampleMulti);
// export default PopoverExampleMulti;
