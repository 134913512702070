import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody } from 'reactstrap'
import { Loader } from '../../../global/Loader';
import { Auth } from '../../../../actions/Auth';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import PaginationComponent from '../../../global/PaginationComponent.js';
import OfferCard from './offfer-card';
import { getFiles, getFullName } from '../../../../actions';
import { Errorbar } from '../../../global/errorbar';
import HelpSection from '../../helpSection/student_section';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import UploadOffers from './upload_offers';
export default class Offer extends Component {

  state = {
    isOfferOpen: false,
    value: null,
    allStudents: [],
    companyList: [],
    allOffers: [],
    totalPlaced: [],
    loader: false,
    fileUrl: null,
    currentPage: 0,
    rowsPerPage: 10,
    files: {},
    processName: '',
    errorbar: false,
    message: '',
    process_id: this.props.match.params.process_id,
    query: "",
    isOpenUploadOffer : false

  }

  async componentWillMount() {

    await this.init();
  }


  onSearch = (target) => {
    this.setState({ query: target.value });
    if (target.value !== "") {
      this.setState({ currentPage: 0 });
    }
  }


  init = async () => {
    this.setState({ loader: true })
    try {
      let tempstids = [];
      let { params } = this.props.match;
      let processResponse = await Auth.Processes.getProcessById(params.process_id)
      this.setState({ processName: processResponse.data[0].process_name });
      let studentList = await Auth.Processes.getProcessStudents(params.process_id);
      let companiesResp = await Auth.PtCompanies.getCompanies()
      let companyList = companiesResp.data;
      companyList.sort(function( a, b ){
        if ( a.company_details.company_name < b.company_details.company_name ) return -1;
        if ( a.company_details.company_name > b.company_details.company_name ) return 1;
        return 0;
      });
      this.setState({
        companyList: companyList.map(company => <option key={company.campus_company_id} value={company.campus_company_id}>{company.company_details.company_name}</option>)
      })
      tempstids = studentList.data.map(st => st.student_id)
      try {
        if (tempstids.length > 0) {
          let formData = {
            process_id: params.process_id,
            student_id: tempstids
          }
          let offerResp = await Auth.Offer.getOffer(formData);
          let fileIds = []
          offerResp.data.forEach(data => {
            if (data.file > 0) {
              fileIds.push(data.file)
            }
          })

          let files = await getFiles(fileIds)

          offerResp.data.forEach(data => {
            data.fileUrl = files[data.file] ? files[data.file][0].file_name : null
          })

          this.setState({ allOffers: offerResp.data })
        }

        await this.setState({ allStudents: studentList.data });
        let totalPlaced = []
        this.state.allStudents.forEach(student => {
          this.state.allOffers.forEach(offer => {
            if (student.student_id == offer.student_id) {
              totalPlaced.push(student.student_id)
            }
          })
        })

        this.setState({ totalPlaced: [...new Set(totalPlaced)] })
      }
      catch (e) {

        console.log(e);

      }

    } catch (e) {
      console.log(e)
    }
    this.setState({ loader: false });
  }

  toggleLoading = (loader) => {
    this.setState({
      loader
    })
  }

  handleClosed = () => {
    this.setState({
      value: null
    })
  }

  toggle = (value) => {
    this.setState({
      value,
      isOfferOpen: !this.state.isOfferOpen
    })
  }

  handleSelected = (selectedPage) => {
    this.setState({ currentPage: selectedPage - 1 });
  }


  render() {
    const { allStudents, rowsPerPage, query, currentPage } = this.state;
    let data = allStudents.filter(row => {
      let name = row.first_name || "";
      return name.toLowerCase().includes(query.toLowerCase())
    })

    return (

      <div className="container mt-5">
        <Loader show={this.state.loader} />
        <Row className="align-items-end">
          <Col>
            <h1 className="r-heading-1">
              <Button onClick={() => this.props.history.goBack()}>
                <img src="/images/left-arrow.png" style={{ height: 30 }} alt="" />
              </Button>{this.state.processName} / Manage Offer
            </h1>
          </Col>
          <Col xs={"auto"}>
                <Button color="blue-3 r-text--sm" onClick={() => this.setState({isOpenUploadOffer : true})}>Upload Offers</Button>
          </Col>
        </Row>
        <hr></hr>
        <div className="status">
          <Row>
            <Col>
              <div className="card bg-info text-white p-4">
                <h1>{this.state.allStudents.length}</h1>
              </div>
              <p>{this.state.allStudents.length > 1 ? "Total Students" : "Total student"}</p>
            </Col>
            <Col>
              <div className="card text-white  bg-success p-4">
                <h1>{this.state.allOffers.length}</h1>
              </div>
              <p> {this.state.allOffers.length > 1 ? "Total Offers" : "Total Offer"}</p>
            </Col>
            <Col>
              <div className="card text-white  bg-success p-4">
                <h1>{this.state.allOffers.filter(o => o.accept_status === 1).length}</h1>
              </div>
              <p>Accepted Offers</p>
            </Col>
            <Col>
              <div className="card bg-success text-white p-4">
                <h1>{this.state.totalPlaced.length}</h1>
              </div>
              <p>Total Placed</p>
            </Col>

            <Col>
              <div className="card bg-secondary text-white p-4">
                <h1>{(this.state.allStudents.length - this.state.totalPlaced.length)}</h1>
              </div>
              <p>Total Unplaced</p>
            </Col>
          </Row>

        </div>
        <hr></hr>
        <div className="card offer-card shadow-sm">
          <Row className="justify-content-between align-items-center mx-0 px-0" >
            <Col className=" m-3">
              <p className="r-heading-3">&nbsp; Offers Status</p>
            </Col>
            <Col xs="auto" className="px-2 borderBottomBlack">
              <div>
                <InputField className="tableinput r-nav__search" placeholder="Search name" type="text" onChange={({ target }) => this.onSearch(target)} />
                <span className="fi flaticon-search"></span>
              </div>
            </Col>
          </Row>
          <div className="text-left ">
            <Col xs={12} >
              <Row className="bg-light pt-2 tb-2">
                <Col xs={2} >
                  <p >Roll no</p>
                </Col>
                <Col xs={3}>
                  <p>Name</p>
                </Col>
                <Col xs={2}>
                  <p>Department</p>
                </Col>
                <Col xs={3}>
                  <p>Degree</p>
                </Col>
                <Col xs={2}>
                  <p >Offers</p>
                </Col>
              </Row>
            </Col>
          </div>


          {
            data.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map(
              data => {

                let totalStudnetOffer = this.state.allOffers.filter(offer => data.student_id == offer.student_id)
                let acceptedStudnetOffer = this.state.allOffers.filter(offer => data.student_id == offer.student_id && offer.accept_status === 1)
                return (

                  <div className="student border-bottom" key={data.student_id}>
                    <Row className="text-left p-3">
                      <Col xs={2}>
                        <p>&nbsp; {data.roll_no}</p>
                      </Col>
                      <Col xs={3}>
                        <p>{getFullName(data)}</p>
                      </Col>
                      <Col xs={2}>
                        <p>{data.department_name}</p>
                      </Col>
                      <Col xs={3}>
                        <p>{data.campus_degree_name}</p>
                      </Col>
                      <Col xs={2}>
                        <button color="blue" className=" r-text--sm r-btn r-btn--blue" onClick={() => this.toggle(data.student_id)} >
                           {totalStudnetOffer.length > 1 ? totalStudnetOffer.length + ' Offers' : totalStudnetOffer.length + ' Offer'}
                           {acceptedStudnetOffer.length ? (acceptedStudnetOffer.length > 1 ? ", "+acceptedStudnetOffer.length + ' Accepted' : ", "+acceptedStudnetOffer.length + ' Accepted') : ""} 
                          </button>
                      </Col>
                    </Row>
                    <Row>

                    </Row>
                    {
                      (this.state.value == data.student_id) ?

                        <Row>
                          <Col xs={12} >
                            <div className="arrow_box">
                              <OfferCard
                                student_id={data.student_id}
                                batch_id={data.batch_id}
                                handleClosed={this.handleClosed}
                                companylist={this.state.companyList}
                                offer={totalStudnetOffer}
                                loadInit={this.init}
                                loading={this.toggleLoading}
                                process_id={this.state.process_id}

                              />
                            </div>
                          </Col>
                        </Row>

                        : ""
                    }
                  </div>

                )
              }
            )
          }

          <Row className="justify-content-between align-items-center mx-0 px-0" >
            <Col xs="auto" className="">
              <Row className="justify-content-between align-items-center">
                <span className="px-1 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>Total students are {allStudents.length}</span>
                <span className="px-3 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>View</span>
                <div className="r-dropdown border-0">
                  <select value={rowsPerPage} name="rowsPerPage" className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} onChange={(e) => { this.setState({ [e.target.name]: e.target.value, currentPage: 0 }) }}>
                    <option value="10" >10</option>
                    <option value="15">15</option>
                    <option value="20" >20</option>
                    <option value="25">25</option>
                    <option value="50" >50</option>
                    <option value="100">100</option>
                  </select>
                  <i className="fi flaticon-arrow" />
                </div>
              </Row>
            </Col>
            <Col xs="auto">
              <PaginationComponent
                totalItems={data.length}
                pageSize={rowsPerPage}
                onSelect={this.handleSelected}
                maxPaginationNumbers={3}
                activePage={currentPage + 1}
              />
            </Col>
          </Row>

        </div>
        <Modal size="lg" zIndex={9999999}  centered={true} isOpen={this.state.isOpenUploadOffer} toggle={() => this.setState({isOpenUploadOffer: false})}>
          <ModalHeader toggle={() => this.setState({isOpenUploadOffer: false})}>Upload Offers</ModalHeader>
          <ModalBody>
            {this.state.isOpenUploadOffer && 
              <UploadOffers 
                applicants={this.state.allStudents} 
                handleOfferModal={async () => {await this.init(); this.setState({isOpenUploadOffer: false})}} 
                companyList={this.state.companyList}
              />
            }
          </ModalBody>
        </Modal>

        <HelpSection tab={"offersTab"} />
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />

      </div>

    )
  }

}
