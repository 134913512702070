import moment from 'moment';
export const CONSTANTS = {
  DELIMITER: ";;",
  A4_HEIGHT: "1123",
  DATE_FORMAT: "YYYY-MM-DD HH:mm:ss",
  MONTHS: moment.monthsShort(),
  YEARS: () => {
    let years = []
    for (let i = 1970; i < parseInt(moment().year()) + 5; i++) {
      years.push(i)
    }
    return years
  },
  NAV: {
    "STUDENT": [{
      "name": "Feed",
      "link": "/student/feed"
    }, {
      "name": "Profile",
      "link": "/student/recruitments/profile"
    }, {
      "name": "Resumes",
      "link": "/student/recruitments/resumes"
    }, {
      "name": "Apply",
      "link": "/student/recruitments/apply"
    }],
    "VERIFIER": [{
      "name": "Verification",
      "link": "/verifier/verification"
    }],
    "SPOC": [
    {
      "name": "User Roles",
      "link": "/spoc/roles"
    },
    {
      "name": "Overview",
      "link": "/spoc/overview"
    },
    {
      "name": "Reports",
      "link": "/spoc/performance"
    }
    ],
    "PT": [{
      "name": "Students",
      "link": "/pt/students/student",
      "hasPermission": "studentTab"
    }, {
      "name": "Companies",
      "link": "/pt/companies",
      "hasPermission": "companiesTab"
    }, {
      "name": "Placements",
      "link": "/pt/processes",
      "hasPermission": "placementsTab"
    }, {
      "name": "Reports",
      "link": "/pt/performance",
      "hasPermission": "reportPlacementTeamPerformance"
    }],
    "ADMIN" : [{
      "name" : "Overview",
      "link" : "/admin"
    }]
  },
  QUICKNAV: {
    "STUDENT": [{
      "name": "CALENDAR"
    }],
    "PT": [{
      "name": "SETTINGS",
      "hasPermission": "settingsTab"
    },{
      "name": "CALENDAR",
      "hasPermission": "calendarTab"
    }]
  },
  SIDENAV: [{
    id: "personal",
    text: "Personal Details"
  }, {
    id: "academic",
    text: "Academic Details"
  }, {
    id: "professional",
    text: "Professional Experience"
  }, {
    id: "responsibility",
    text: "Positions of Responsibility"
  }, {
    id: "extracurricular",
    text: "Extracurricular Activities"
  }, {
    id: "project",
    text: "Projects"
  }, {
    id: "achievement",
    text: "Achievements"
  }, {
    id: "other",
    text: "Other Experiences"
  }, {
    id: "skill",
    text: "Skills"
  }],
  COLLEGENAV: [{
    id: "students",
    text: "Home"
  },{
    id: "about",
    text: "About"
  },{
    id: "connection",
    text: "Requests"
  },],
  PROFILE_ICONS: {
    github: {
      icon: "cat",
      domain: "github.com/"
    },
    linkedin: {
      icon: "linkedin",
      domain: "linkedin.com/"
    }
  },
  ACADEMIC_TYPES: {
    "X": "Xth",
    "XII": "XIIth",
    "GRADUATION": "Graduation",
    "POST_GRADUATION": "Post Graduation"
  },
  DEGREES: {
    "POST_GRADUATION": ["M.Tech", "MBA", "MCA", "M.Sc"],
    "GRADUATION": ["B.Tech", "BBA", "BCA", "B.Sc"]
  },
  EXPERIENCE_TYPES: {
    FULL_TIME: "Full Time",
    PART_TIME: "Part Time",
    INTERNSHIP: "Internship",
    STARTUP_EXPERIENCE: "Startup Experience",
    SUMMER_INTERNSHIP: "Summer Internship"
  },
  ACHIEVEMENT_TYPES: {
    'TEST_SCORE': "Test Scores",
    'PATENT': 'Patents',
    'PUBLICATION': 'Publications',
    'SCHOLARSHIP': 'Scholarships',
    'COMPETITION': 'Competitions'
  },
  OTHER_EXPERIENCE_TYPES: {
    'COURSE': "Courses / Certifications",
    'WORKSHOP': "Workshops / Professional Training",
    'CONFERENCES': "Conferences / International Exposure",
    'VOLUNTEER': "Volunteer Experience"
  },
  SKILL_TYPES: {
    'LANGUAGE': "Language Skills",
    'TECHNICAL': "Tools & Technologies",
    'INTERPERSONAL': "Interpersonal Skills",
    'OTHER': "Other Skills"
  },
  APPLICATION_STATUS: {
    1: {
      "NAME": "Shortlisted",
      "CLASS": "r-bg--brandD",
      "TEXTCLASS": "r-text--brandD"
    },
    2: {
      "NAME": "Rejected",
      "CLASS": "r-bg--brandF",
      "TEXTCLASS": "r-text--brandF"
    },
    4: {
      "NAME": "Withdrawn",
      "CLASS": "r-bg--brandF",
      "TEXTCLASS": "r-text--brandF"
    },
    5: {
      "NAME": "Applied",
      "CLASS": "r-bg--brandD",
      "TEXTCLASS": "r-text--brandD"
    },
    6: {
      "NAME": "Eligible",
      "CLASS": "r-bg--brandD",
      "TEXTCLASS": "r-text--brandD"
    },
    7: {
      "NAME": "Not Eligible",
      "CLASS": "r-bg--brandF",
      "TEXTCLASS": "r-text--brandF"
    }
  },
  FILE_TYPES: "image/png, image/jpeg, application/pdf",
  FILE_SIZE_LIMIT: 100,
  VERIFICATION: {
    0: "pending",
    1: "approved",
    2: "rejected"
  },
  ELIGIBILITY_OPERATOR_GROUP : {
    1 : [">","<","==","range"],
    2 : ["==", "OR","AND"]
  },
  ELIGIBILITY_OPERATOR: {
    ">": ">=",
    "<": "<=",
    "==": "Equals",
    "range": "Between",
    "OR": "OR",
    "AND": "AND"
  },
  ELIGIBILITY_CRITERIA_TYPES: {
    "X_SCORE": {
      label : "Xth Score",
      operators : [">"],
      placeholder : "Enter percentage value here",
      type : "number"
    },
    "XII_SCORE": {
      label : "XIIth Score",
      operators : [">"],
      placeholder : "Enter percentage value here",
      type : "number"
    },
    "GRAD_SCORE": {
      label : "Graduation Score",
      operators : [">"],
      placeholder : "Enter percentage value here",
      type : "number"
    },
    "PG_SCORE": {
      label : "Post Graduation Score",
      operators : [">"],
      placeholder : "Enter percentage value here",
      type : "number"
    },
    "GENDER": {
      label : "Gender",
      operators : ["OR"],
      listId : "gender_type",
      name: "gender_name",
    },
    "AGE": {
      label : "Age",
      operators : [">","<", "range"],
      placeholder : "Enter age in years",
      type : "number"
    },
    "WORK_EX_MONTHS": {
      label : "Work Experience(Months)",
      operators : [">","<", "range"],
      placeholder : "Enter the number of months here",
      type : "number"
    },
    // "WORK_EX_DOMAIN": {
    //   label : "Work Experience - Domain",
    //   operatorGroup : 2
    // },
    // "WORK_EX_SECTOR": {
    //   label : "Work Experience - Sector",
    //   operators : ["OR"],
    //   listId : "sector_id",
    //   name: "sector_name",
    // },
    "SPECIALIZATION": {
      label : "Academic Specialization",
      operators : ["OR"],
      listId : "specialization_name",
      name: "specialization_name"
    },
    "DEPARTMENT": {
      label : "Student Department",
      operators : ["OR"],
      listId : "batch_department_id",
      name: "batch_department_name"
    },
    "TOTAL_BACKLOG": {
      label : "Total Backlog",
      operators : ["<","=="],
      placeholder : "Enter the number of backlogs here",
      type : "number"
    },
    "CURR_BACKLOG": {
      label : "Current Backlog",
      operators : ["<","=="],
      placeholder : "Enter the number of backlogs here",
      type : "number"
    },
    "CATEGORY": {
      label : "Category",
      operators : ["OR"],
      listId : "type",
      name: "name",
      additional: true
    },
    "PARENTS_ANNUAL_INCOME": {
      label : "Parent Anual Income",
      operators : ["<", ">"],
      placeholder : "Enter the amount",
      type : "number",
      additional: true
    },
    "VISION_ISSUES": {
      label : "Vision Related Issue",
      operators : ["OR"],
      listId : "type",
      name: "name",
      additional: true
    },
  },
  ANSWER_TYPES: {
    "text": "Text",
    "number": "Number",
    "file": "File upload",
    "multiple": "Multiple Choice"
  },
  CONTACT_TYPES: {
    AL: "Alumni",
    BL: "Business Leader",
    HR: "Human Resource",
  },
  COMPANY_STATUS: {
    0: "-",
    1: "Contacted",
    2: "Denied",
    3: "Interested",
    4: "Not Interested",
    5: "Confirmed"
  },
  PROFILE_STATUS: {
    0: "Interested",
    1: "Confirmed",
    2: "Denied"
  },

  PROFILE_TYPES_ID: {
    PERSONAL: "",
    ACADEMIC: "academic_id",
    PROFESSIONAL: "professional_id",
    POR: "id",
    EXTRA_CURRICULAR: "extra_curricular_id",
    PROJECT: "project_id",
    ACHIEVEMENTS: "achievement_id",
    OTHER_EXPERIENCE: "id",
    CV_POINT: "cv_point_id"
  },
  ROLE_TYPES: {
    1: "Admin",
    2: "SPOC",
    3: "Placement Team",
    4: "Verifier",
    5: "Student",
    6: "Society"
  },
  ROLE_LEVELS: {
    1: "University",
    2: "College",
    3: "Campus",
    4: "Degree"
  },
  ROLE_TYPES_ID: {
    ADMIN: 1,
    SPOC: 2,
    PT: 3,
    VERIFIER: 4,
    STUDENT: 5,
    SOCIETY: 6
  },
  PROCESS_APPLICATION_STATUS: {
    0: "Not Open",
    1: "Opened",
  },
  PROCESS_TYPES : {
    "FINALS" : "Final Placements",
    "INTERNSHIP" : "Internships",
    "COMPETITION": "Case Competition",
    "PROJECT": "Live Project"
  },
  ROUND_TYPES : {
    "PI" : "Functional Interview",
    "GD" : "Group Discussion",
    "HR_PI" : "HR Interview",
    "ONLINE_TEST" : "Online Aptitude Test",
    "WRITTEN" : "Online Written Test",
    "OTHER" : "Other",
    "TALK" : "Pre-placement Talk",
    "Psychometric" : "Psychometric",
    "RESUME_SHORTLISTING" : "Resume Shortlisting",
    "TECHNICAL" : "Technical Interview"
  },
  QUESTION_FILE_TYPES : {
    'image/*' : 'Image',
    '.pdf' : 'PDF Document',
    '.ppt, .pptx' : 'PPT File',
    '.doc, .docx' : 'Document File',
    '.csv, .xlsx, .xls' : 'Excel File',
    'video/*' : 'Video File'
  },
  NOTIFICATION: {
    "EVENT": (id) => `/calendar/events/${id}/edit`,
    "PROCESS": (id) => `/student/recruitments/apply?process_id=${id}`
  },
  CONFIGURATION_EMAILS : {
     "APPLICATION_PUBLISH" : "Application Publish",
     "APPLICATION_EDIT" : "Application Edit",
     "BLOCK_STUDENT" : "Block Student",
     "BLOCK_EVENT_MAILS" : "Events",
     "FEEDS" : "Feed",
     "FREEZE_STUDENT" : "Freeze Student",
     "OFFER" : "Offer",
     "SHORTLIST_RELEASE" : "Shortlist Release",
     "APP_FEEDBACK" : "Application Feedback",
     "EMAIL_CAMPAIGN" : "Email Campaign",
     "RECRUITER_INVITE" : "Recruiter Invite",
     "SEND_TO_COMPANY"  : "Send Applicant Data to Company" 
  },

  profileSections : {
    student_info : "Student Info",
    personal_detail : "Personal Detail",
    academic_detail : "Academic Detail",
    student_semester_score : "Semester Score",
    backlog_detail : "Backlog Detail",
    professional_detail_months : "Professional Experience(months)",
    professional_detail : "Professional Detail",
    position_of_responsibility : "Position Of Responsibility",
    extra_curricular : "Extra Curricular Activities",
    project : "Project",
    certifications : "Certifications",
    skills : "Skills"
  },

  RECAPTCHA_KEY : {
    "googleRecaptchaKey" : "6LcsPK0UAAAAAIwWpSVg9oivgM6KrPYVAwf7Mdk-"
  },

  HELP_SECTION : {
    studentTab : [
      {
        "functionality_name" : "Add Students",
        "type" : "icon",
        "src" : "fi flaticon-plus"
      },
      {
        "functionality_name" : "View Students",
        "type" : "icon",
        "src" : "fi flaticon-eye"
      },
      {
        "functionality_name" : "Freeze Unfreeze Students",
        "type" : "icon",
        "src" : "fi flaticon-edit"
      },
      {
        "functionality_name" : "Batch Snapshot",
        "type" : "icon",
        "src" : "fi flaticon-edit"
      }
    ],
    companiesTab : [
      {
        "functionality_name" : "Add Companies",
        "type" : "icon",
        "src" : "fi flaticon-plus"
      },
      {
        "functionality_name" : "Add Contacts",
        "type" : "icon",
        "src" : "fi flaticon-plus"
      },
      {
        "functionality_name" : "Add Roles",
        "type" : "icon",
        "src" : "fi flaticon-plus"
      },
      {
        "functionality_name" : "Map To Placement Drive",
        "type" : "icon",
        "src" : "fi flaticon-plus"
      },
      {
        "functionality_name" : "Email Campaigns",
        "type" : "icon",
        "src" : "fi flaticon-plus"
      }
    ],
    placementsTab : [
      {
        "functionality_name" : "Creating A Process",
        "type" : "icon",
        "src" : "fi flaticon-plus"
      },
      {
        "functionality_name" : "Opening An Application",
        "type" : "icon",
        "src" : "fi flaticon-plus"
      },
      {
        "functionality_name" : "Adding Rounds",
        "type" : "icon",
        "src" : "fi flaticon-plus"
      },
      {
        "functionality_name" : "Mark Attendance",
        "type" : "icon",
        "src" : "fi flaticon-plus"
      }
    ],
    applyTab : [
      {
        "functionality_name" : "Apply Tab",
        "type" : "icon",
        "src" : "fi flaticon-info"
      }
    ],
    offersTab : [
      {
        "functionality_name" : "Offers Tab",
        "type" : "icon",
        "src" : "fi flaticon-info"
      }
    ],
    newsfeedTab : [
      {
        "functionality_name" : "Newsfeed",
        "type" : "icon",
        "src" : "fi flaticon-info"
      }
    ],
    studentNewsFeed  : [
      {
        "functionality_name" : "Student Newsfeed",
        "type" : "icon",
        "src" : "fi flaticon-info"
      }
    ]
  },
  APPLICATION_BLOCK_STATUS: {
    0: "Allow All Applications",
    1: "Block All Applications",
    2: "Block specific Applications" 
  },
  G_PAY : {
    city : ["Faridabad" , "Ghaziabad" , "Greater Noida" , "Jaipur" , "Lucknow" , "Noida"],
    course : ["BBA" , "BCA", "B.Tech" , "MBA", "MCA" , "M.Tech" , "PGDM"] ,
    mcq_answers : ["I want to earn money" , "I want to work for Google Pay brand" , "I want to get the internship certificate" ]
  }
}
