import React, { Component } from 'react';
import { RecruitmentNavigation } from './navigation';
import { connect } from 'react-redux';
import { ManageResume } from './manage-resume';
import { Auth } from '../../../../actions/Auth';
import { setUser, setLoading, setError } from '../../../../actions/action-creators';
import StudentDetailModel from '../../../../constants/StudentDetailModel';
import { BuildProfile } from './build-profile';
import { TEMPLATE_CONFIG } from '../../../../constants/template-1';
import { generateMasterCV, getFiles, generateStudentCVDetails, getErrorMessage } from '../../../../actions';
import Apply from './apply-company/index';
import { CONSTANTS } from '../../../../constants';
import StudentNavPT from '../../ptTeam/Profile/StudentNavPT.js';
import Axios from 'axios';
import { Loader } from '../../../global/Loader';
import store from '../../../../store';

class StudentRecruitment extends Component {
  constructor(props) {
    super(props);
    this.tabs = {
      "profile": 1,
      "resumes": 2,
      "apply": 3
    }
    this.isPT = false;
    this.state = {
      tab: props.match.params.tab ? this.tabs[props.match.params.tab] : 1,
      showNav: true,
      isOn: false,
      loader: false,
      processList: [{ process_name: "Profile", process_type: "profile", process_id: 1 }, { process_name: "Resume", process_type: "resumes", process_id: 2 }, { process_name: "Job Applications", process_type: "apply", process_id: 3 },],
      selectedProcess: { process_name: "Profile", process_type: "profile", process_id: 1 },
      config: TEMPLATE_CONFIG,
      cvTemplates: [],
      studentDetails: JSON.parse(JSON.stringify(StudentDetailModel)),
      studentCVDetails: {
        "user_info": {},
        "personal_info": {},
        "academic_detail": {},
        "professional_detail": {},
        "project": {},
        "achievement": {},
        "other_experience": {},
        "skill": {},
        "cv_point": {},
        "position_of_responsibility": {},
        "extra_curricular_activity": {}
      },
      settings: {}
    }
  }

  updateStudentDetails = studentDetails => {
    this.setState({
      studentDetails
    })
  }
  handleToggle(e) {
    this.setState({ isOn: !this.state.isOn });
  }

  getStudentProfile = async () => {
    let { user, } = this.props,
      { studentDetails } = this.state,
      studentCVDetails = {
        "user_info": {},
        "personal_info": {},
        "academic_detail": {},
        "professional_detail": {},
        "project": {},
        "achievement": {},
        "other_experience": {},
        "skill": {},
        "cv_point": {},
        "position_of_responsibility": {},
        "extra_curricular_activity": {}
      };
    this.setState({ loader: true })
    try {
      Auth.User.getConfiguration().then(resp=>{
        this.setState({settings: resp.data})
      })
    } catch(e) {
      console.log(e)
    }
    try {
      let studentResp, studentInfo;
      if(!this.isPT){
        studentResp = await Auth.Student.getId(user.id);
        studentInfo = studentResp.data[studentResp.data.length - 1] || {};
      }
      let profileResp = await Auth.Student.getProfile(this.isPT ? this.props.match.params.student_id : studentInfo.student_id);
      studentInfo = profileResp.data[0].student_info;
      let cvTemplates = await Auth.Student.getCVTemplates()
      this.allTemplates = cvTemplates.data
      let masterCV = await Auth.Student.getResumes(null, {student_id: studentInfo[0].student_id, cv_type: "MASTER"})
      this.props.setUser(Object.assign({}, user, { studentInfo }))
      studentDetails.user_info = {...profileResp.data[0].student_info[0],...this.props.role};
      studentDetails.user_info.profile_links = studentDetails.user_info.profile_links || JSON.stringify({ "github": "", "linkedin": "" })
      studentDetails.personal_info = Object.assign({}, studentDetails.personal_info, profileResp.data[0].personal_detail[0]);
      studentDetails = Object.assign({}, studentDetails, profileResp.data[0])
      studentDetails.academic_detail.sort(this.sortAcademic);
      studentDetails.files = await this.generateFileUrls(studentDetails);
      studentDetails.personal_info.profile_picture_url = studentDetails.files[studentDetails.personal_info.image_url] && studentDetails.files[studentDetails.personal_info.image_url][0] && studentDetails.files[studentDetails.personal_info.image_url][0].url
      studentCVDetails = generateStudentCVDetails(studentCVDetails, studentDetails);
      if (cvTemplates.data[0]){
        let configByGroup = cvTemplates.data[0]
        if(studentDetails.user_info.group_id) {
          let groupTemplate = cvTemplates.data.filter(tmp=>tmp.group_id===studentDetails.user_info.group_id)[0]
          if(groupTemplate) configByGroup = groupTemplate
        }
        if(masterCV.data[0] && masterCV.data[0].template_id) {
          let masterTemplate = cvTemplates.data.filter(tmp=>tmp.template_id===masterCV.data[0].template_id)[0]
          if(masterTemplate) configByGroup = masterTemplate
        }
          this.setState({ config: configByGroup.template, cvTemplates: [configByGroup] })
      }
      // let template = await Axios.get('/CV_Templates/iimk_2.json')
      // this.setState({config: template.data, cvTemplates: [{template:template.data}]})
      let lists = await Axios.all([Auth.General.getRoles(), Auth.General.getSectors(), Auth.General.getEducationBoards(), Auth.General.getDegrees(), Auth.General.getIndustries()])
      this.roleList = lists[0].data.map(role => <option key={role.role_id} value={role.role_name}>{role.role_name}</option>)
      this.domainList = lists[1].data.map(sector => <option key={sector.sector_id} value={sector.sector_name}>{sector.sector_name}</option>)
      this.boardList = lists[2].data.map(board => <option key={board.board_id} value={board.board_id}>{board.board_name}</option>)
      this.degreeList = lists[3].data.map(degree => <option key={degree.degree_id} value={degree.degree_id}>{degree.degree_name}</option>)
      this.industryList = lists[4].data.map(industry => <option key={industry.industry_id} value={industry.industry_id}>{industry.industry_name}</option>)
      this.sectorList = lists[1].data.map(sector => <option key={sector.sector_id} value={sector.sector_id}>{sector.sector_name}</option>)
    } catch (e) {
      console.log(e)
      store.dispatch(setError({showBar: true, message: getErrorMessage(e)}))
    }

    studentDetails.cv_point = studentDetails.cv_point.map(cv => {
      cv.text = cv.text.replace("<p><br></p>", "");;
      return cv;
    });
    
    this.setState({
      studentDetails,
      studentCVDetails,
      loader: false
    })
  }

  generateFileUrls = async (studentDetails) => {
    let fileIds = [studentDetails.personal_info.image_url, studentDetails.personal_info.file];
    [...studentDetails.academic_detail, ...studentDetails.position_of_responsibility, ...studentDetails.professional_detail, ...studentDetails.project, ...studentDetails.extra_curricular_activity, ...studentDetails.achievement, ...studentDetails.other_experience, ...studentDetails.cv_point].forEach(a => fileIds.push(a.file))
    let files = await getFiles(fileIds);
    return files
  }
  sortAcademic = (a, b) => {
    let types = Object.keys(CONSTANTS.ACADEMIC_TYPES);
    let indexA = types.indexOf(a.academic_type),
      indexB = types.indexOf(b.academic_type);
    if (indexA > indexB)
      return -1;
    if (indexA < indexB)
      return 1;
    return 0;
  }
  componentDidMount() {
    if (this.props.role.role_type === "PT")
      this.isPT = true;
    this.getStudentProfile()

  }

  goBackto = () => {
    window.history.back()
  }

  changeTab = ({ target }) => {
    this.props.history.push(`/student/recruitments/${target.dataset.tab}`)
    this.setState({
      tab: this.tabs[target.dataset.tab] || this.state.tab
    })
    window.scrollTo(0, 0);
  }


  changeTabPT = ({ target }) => {
    this.props.history.push({
      pathname: '/pt/students/recruitments/' + this.props.match.params.student_id + '/' + target.dataset.tab
    })
    this.setState({ selectedProcess: this.state.processList[this.tabs[target.dataset.tab] - 1] });
    this.setState({
      tab: this.tabs[target.dataset.tab] || this.state.tab
    })
    window.scrollTo(0, 0);
  }

  toggleNavbar = (show) => {
    if (this.state.showNav !== show)
      this.setState({
        showNav: show
      })
  }

  renderTab = () => {
    let { params } = this.props.match;
    let hasPermission = this.props.role.hasPermission;
    var AccessDenied = () => <div className="w-100 text-center p-5"><span className="m-5 r-subheading-3">You don't have enough permission to access this section</span></div>;
    let isAccessProfile = hasPermission.viewStudentProfile && hasPermission.viewStudentProfile.permission_access && hasPermission.viewStudentProfile.permission_access.read;
    let isAccessResume = hasPermission.viewStudentResume && hasPermission.viewStudentResume.permission_access && hasPermission.viewStudentResume.permission_access.read;
    let isAccessApply = hasPermission.viewStudentApply && hasPermission.viewStudentApply.permission_access && hasPermission.viewStudentApply.permission_access.read;
    switch (params.tab) {
      case "profile": return this.isPT && !isAccessProfile ? AccessDenied : BuildProfile
      case "resumes": return this.isPT && !isAccessResume ? AccessDenied :ManageResume
      case "apply": return this.isPT && !isAccessApply ? AccessDenied :Apply
      default: return this.isPT && !isAccessProfile ? AccessDenied :BuildProfile
    }
  }

  changeProfile = (e) => {
    let { studentDetails } = this.state,
      key = e.target.id,
      value = e.target.value,
      name = e.target.name;
    studentDetails[name][key] = value;
    this.setState({
      studentDetails
    })
  }

  changePreferences = pref => {
    let { studentDetails } = this.state;
    if (pref.id) {
      studentDetails.preferences.forEach(pr => {
        if (pref.id === pr.id)
          pr = pref
      })
    } else {
      pref.id = "NEW__" + Math.ceil(Math.random() * 10000)
      studentDetails.preferences.push(pref)
    }
    this.setState({
      studentDetails
    })
  }

  changeAcademics = (academic, isDelete, id) => {
    let { studentDetails } = this.state;
    if (isDelete) {
      let index = -1;
      studentDetails.academic_detail.forEach((acad, i) => {
        if (academic.academic_id === acad.academic_id)
          index = i;
      })
      studentDetails.academic_detail.splice(index, 1)
    } else {
      if (academic.academic_id) {
        studentDetails.academic_detail.forEach((acad) => {
          if (academic.academic_id === acad.academic_id)
            acad = academic
        })
      } else {
        academic.academic_id = id
        studentDetails.academic_detail.reverse();
        studentDetails.academic_detail.push(academic);
        studentDetails.academic_detail.reverse();

      }
    }
    this.setState({
      studentDetails
    })
  }
  changePorDetail = (porDetail, isDelete) => {
    let { studentDetails } = this.state;
    if (isDelete) {
      let index = -1;
      studentDetails.position_of_responsibility.forEach((por, i) => {
        if (porDetail.id === por.id)
          index = i;
      })
      studentDetails.position_of_responsibility.splice(index, 1)
    } else {
      if (porDetail.id) {
        studentDetails.position_of_responsibility.forEach((por) => {
          if (porDetail.id === por.id)
            por = porDetail
        })
      } else {
        porDetail.id = "NEWPOR__" + Math.ceil(Math.random() * 10000)
        studentDetails.position_of_responsibility.reverse()
        studentDetails.position_of_responsibility.push(porDetail)
        studentDetails.position_of_responsibility.reverse()
      }
    }
    this.setState({
      studentDetails
    })
  }

  changeProDetail = (profDetail, isDelete) => {
    let { studentDetails } = this.state;
    if (isDelete) {
      let index = -1;
      studentDetails.professional_detail.forEach((pro, i) => {
        if (profDetail.professional_id === pro.professional_id)
          index = i;
      })
      studentDetails.professional_detail.splice(index, 1)
    } else {
      if (profDetail.professional_id) {
        studentDetails.professional_detail.forEach((pro) => {
          if (profDetail.professional_id === pro.professional_id)
            pro = profDetail
        })
      } else {
        profDetail.professional_id = "NEWPRO__" + Math.ceil(Math.random() * 10000)
        studentDetails.professional_detail.reverse()
        studentDetails.professional_detail.push(profDetail)
        studentDetails.professional_detail.reverse()
      }
    }
    this.setState({
      studentDetails
    })
  }

  changeProjectDetail = (projectDetail, isDelete) => {
    let { studentDetails } = this.state;
    if (isDelete) {
      let index = -1;
      studentDetails.project.forEach((proj, i) => {
        if (projectDetail.project_id === proj.project_id)
          index = i;
      })
      studentDetails.project.splice(index, 1)
    } else {
      if (projectDetail.project_id) {
        studentDetails.project.forEach((proj) => {
          if (projectDetail.project_id === proj.project_id)
            proj = projectDetail
        })
      } else {
        projectDetail.project_id = "NEWPRO__" + Math.ceil(Math.random() * 10000)
        studentDetails.project.reverse()
        studentDetails.project.push(projectDetail)
        studentDetails.project.reverse()
      }
    }
    this.setState({
      studentDetails
    })
  }

  changeExtraCurricular = (ec, isDelete) => {
    let { studentDetails } = this.state;
    if (isDelete) {
      let index = -1;
      studentDetails.extra_curricular_activity.forEach((extra, i) => {
        if (ec.extra_curricular_id === extra.extra_curricular_id)
          index = i;
      })
      studentDetails.extra_curricular_activity.splice(index, 1)
    } else {
      if (ec.extra_curricular_id) {
        studentDetails.extra_curricular_activity.forEach((extra) => {
          if (ec.extra_curricular_id === extra.extra_curricular_id)
            extra = ec
        })
      } else {
        ec.extra_curricular_id = "NEWEC__" + Math.ceil(Math.random() * 10000)
        studentDetails.extra_curricular_activity.reverse()
        studentDetails.extra_curricular_activity.push(ec)
        studentDetails.extra_curricular_activity.reverse()
      }
    }
    this.setState({
      studentDetails
    })
  }

  changeAchievement = (achievement, isDelete) => {
    let { studentDetails } = this.state;
    if (isDelete) {
      let index = -1;
      studentDetails.achievement.forEach((ach, i) => {
        if (achievement.achievement_id === ach.achievement_id)
          index = i;
      })
      studentDetails.achievement.splice(index, 1)
    } else {
      if (achievement.achievement_id) {
        studentDetails.achievement.forEach((ach) => {
          if (achievement.achievement_id === ach.achievement_id)
            ach = achievement
        })
      } else {
        achievement.achievement_id = "ACH_" + Math.ceil(Math.random() * 10000)
        studentDetails.achievement.reverse()
        studentDetails.achievement.push(achievement)
        studentDetails.achievement.reverse()
      }
    }
    this.setState({
      studentDetails
    })
  }

  changeOtherExp = (exp, isDelete) => {
    let { studentDetails } = this.state;
    if (isDelete) {
      let index = -1;
      studentDetails.other_experience.forEach((othrExp, i) => {
        if (exp.id === othrExp.id)
          index = i;
      })
      studentDetails.other_experience.splice(index, 1)
    } else {
      if (exp.id) {
        studentDetails.other_experience.forEach((othrExp) => {
          if (exp.id === othrExp.id)
            othrExp = exp
        })
      } else {
        exp.id = "ACH_" + Math.ceil(Math.random() * 10000)
        studentDetails.other_experience.reverse()
        studentDetails.other_experience.push(exp)
        studentDetails.other_experience.reverse()
      }
    }
    this.setState({
      studentDetails
    })
  }

  changeSkill = (skill, isDelete) => {
    let { studentDetails } = this.state;
    if (isDelete) {
      let index = -1;
      studentDetails.skill.forEach((sk, i) => {
        if (skill.student_skill_id === sk.student_skill_id)
          index = i;
      })
      studentDetails.skill.splice(index, 1)
    } else {
      if (skill.student_skill_id) {
        studentDetails.skill.forEach((sk) => {
          if (skill.student_skill_id === sk.student_skill_id)
            sk = skill
        })
      } else {
        skill.student_skill_id = "SK_" + Math.ceil(Math.random() * 10000)
        studentDetails.skill.reverse()
        studentDetails.skill.push(skill)
        studentDetails.skill.reverse()
      }
    }
    this.setState({
      studentDetails
    })
  }

  changeCVPoint = (cv, isDelete, id) => {
    if (isDelete) this.deleteCVPoint(cv);
    let { studentDetails } = this.state;
    if (cv.cv_point_id) {
      studentDetails.cv_point.forEach(pt => {
        if (pt.cv_point_id === cv.cv_point_id)
          pt = cv
      })
    } else {
      cv.cv_point_id = id
      studentDetails.cv_point.push(cv)
    }
    this.setState({
      studentDetails
    })
  }

  deleteCVPoint = cv => {
    let { studentDetails } = this.state;
    let index = -1;
    studentDetails.cv_point.forEach((pt, i) => {
      if (pt.cv_point_id === cv.cv_point_id)
        index = i;
    })
    studentDetails.cv_point.splice(index, 1)

    this.setState({
      studentDetails
    })
  }

  updateCVConfig = (config) => {
    this.setState({
      config
    })
  }

  generateCVConfig = () => {
    let { config, studentCVDetails } = this.state;
    studentCVDetails = generateStudentCVDetails(studentCVDetails, this.state.studentDetails);
    config = generateMasterCV(studentCVDetails, config)
    this.updateCVConfig(config)
  }

  handleMouseOver = (e) => {
    if (e.target.className !== "r-loader")
      this.toggleNavbar(false)
  }

  render() {
    const { tab, loader, showNav, studentDetails, cvTemplates, config, selectedProcess, processList, studentCVDetails, settings } = this.state,
      TabContent = this.renderTab(),
      tabProps = {
        isPT: this.isPT,
        role : this.props.role,
        tab: this.props.match.params.profileTab || "personal",
        history: this.props.history,
        match: this.props.match,
        getStudentProfile: this.getStudentProfile,
        updateStudentDetails: this.updateStudentDetails,
        updateCVConfig: this.updateCVConfig,
        generateCVConfig: this.generateCVConfig,
        changeAcademics: this.changeAcademics,
        changeProfile: this.changeProfile,
        changePorDetail: this.changePorDetail,
        changeProDetail: this.changeProDetail,
        changeProjectDetail: this.changeProjectDetail,
        changeAchievement: this.changeAchievement,
        changeOtherExp: this.changeOtherExp,
        changeSkill: this.changeSkill,
        changePreferences: this.changePreferences,
        changeExtraCurricular: this.changeExtraCurricular,
        changeCVPoint: this.changeCVPoint,
        config,
        cvTemplates,
        studentDetails,
        studentCVDetails,
        roleList: this.roleList,
        domainList: this.domainList,
        boardList: this.boardList,
        degreeList: this.degreeList,
        industryList: this.industryList,
        sectorList: this.sectorList,
        settings,
        allTemplates: this.allTemplates || []
      };
    return (
      <div className="student__recruitment">
        <Loader show={loader} />
        {(this.isPT) ? (
          <StudentNavPT hasPermission={this.props.role.hasPermission} tab={tab} showNav={showNav} toggleNavbar={this.toggleNavbar} goBackto={this.goBackto} selectedProcess={selectedProcess} processList={processList} changeTab={this.changeTabPT} studentDetails={studentDetails} />) : (
            // <RecruitmentNavigation tab={tab} showNav={showNav} toggleNavbar={this.toggleNavbar} changeTab={this.changeTab} />
            null
          )}
        <div className="student__recruitment__tab" onMouseOver={this.handleMouseOver}>
          {(studentCVDetails.user_info.student_id) && <TabContent {...tabProps} />}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user)),
  setLoading: show => dispatch(setLoading(show))
})
export default connect(mapStateToProps, mapDispatchToProps)(StudentRecruitment);
