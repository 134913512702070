import React, { Component } from 'react';
import Aux from '../../../../global/AuxComp';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import moment from 'moment';
import Collapse from 'reactstrap/lib/Collapse';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { ProfileActions } from './profile-actions';
import Button from '../../../../global/Button';
import { Auth } from '../../../../../actions/Auth';
import { CONSTANTS } from '../../../../../constants';
//import { DateRangePicker, SingleDatePicker } from '../../../../global/Forms/DatePicker';
import { InputField } from '../../../../global/Forms';
import { filterCVPoints } from '../../../../../actions';
import { CVPoint } from './cv-point';
import { FileUpload } from '../../../../global/FileUpload';
import { YearSelect } from '../../../../global/Forms/Select';
import { Errorbar } from '../../../../global/errorbar';

export class ExtraCurricular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      deleteId: null,
      errorbar: false,
      message: ''
    };
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  isValid = (ec) => {
    let { activity_name, activity_type, organised_by } = ec;
    if (!activity_name || !activity_type || !organised_by) {
      this.setState({ message: 'Please fill all mandatory input fields' });
      this.setState({ errorbar: true });
      return false
    }
    this.setState({ errorbar: false });
    return true
  }

  handleECDetailSubmit = async (e, ec) => {
    if (e) e.preventDefault();
    if (!this.isValid(ec)) {
      return
    }
    this.props.toggleLoading(true);
    let method = Auth.Student.updateExtraCurricular,
      formData = Object.assign({}, ec);
    formData.activity_date = moment(formData.activity_date).format(CONSTANTS.DATE_FORMAT)
    if (typeof ec.extra_curricular_id === "string") {
      method = Auth.Student.createExtraCurricular;
      formData.student_id = this.props.user_info.student_id;
      delete formData.extra_curricular_id
    }
    try {

      await method(formData)
      this.props.getStudentProfile()
      if (e) this.props.toggleSection("");

    } catch (e) {
      console.log(e)
      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
    this.props.toggleLoading(false);
  }

  deleteDetail = async () => {
    let { deleteId } = this.state;
    if (!deleteId) return;
    let formData = {
      student_id: this.props.user_info.student_id,
      extra_curricular_id: deleteId,
      status: 0
    }
    this.props.toggleLoading(true)
    try {
      if (typeof deleteId === "number")
        await Auth.Student.updateExtraCurricular(formData)
      this.props.changeExtraCurricular(formData, true)
      this.toggleModal()
      this.props.getStudentProfile()
    } catch (e) {
      console.log(e)
      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
    this.props.toggleLoading(false)
  }

  toggleModal = (id) => {
    this.setState({
      modalShow: !this.state.modalShow,
      deleteId: id
    })
  }

  changeYear = (e, ec) => {
    let date = ec.activity_date ? moment(ec.activity_date) : moment();
    ec.activity_date = date.year(e.target.value).format(CONSTANTS.DATE_FORMAT)
    this.props.changeExtraCurricular(ec);
  }

  handleECChange = (e, ec) => {
    ec[e.target.name] = e.target.value;
    this.props.changeExtraCurricular(ec)
  }

  addEC = (e) => {
    e.preventDefault();
    this.props.toggleSection(`EC-0`);
    this.props.changeExtraCurricular({});
    this.el = e.target.closest(".student__profile__container")
    setTimeout(() => {
      if (this.el)
        this.el.scrollIntoView({ behavior: "smooth", block: "start" })
    }, 300)
  }

  render() {
    let { files, editableSection, toggleSection, extra_curricular_activity, onCancel, cv_point, commentThreads } = this.props;
    this.cvPoints = filterCVPoints(cv_point, "EXTRA_CURRICULAR")
    return (
      <Aux>
        <div className="r-card student__profile__container">
          <Row noGutters className="">
            <Col xs={12}>
              <h2 className="r-heading-4 r-text--blue-3">Extracurricular Activities</h2>
              {editableSection === "" && <div className="student__profile__actions">
                <Button onClick={this.addEC} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
              </div>}
            </Col>
          </Row>
          {
            extra_curricular_activity.length > 0 ? extra_curricular_activity.map((ec, i) => {
              return (
                <div className={"student__profile__section" + (ec.freeze_status == 1 ? " student__profile--freeze" : "")} key={ec.extra_curricular_id}>
                  <Collapse isOpen={editableSection !== `EC-${i}`}>
                    <h1 className="r-subheading-2 mr-3" style={{ fontWeight: 600 }}>
                      {ec.activity_name || <em>Activity</em>}
                    </h1>
                    <h1 className="r-subheading-2 mt-3" style={{ fontWeight: 400 }}>
                      {ec.organised_by || <em>Organization</em>}
                    </h1>
                    <div className="r-text--grey-4 mb-2">{ec.activity_date ? moment(ec.activity_date).format("YYYY") : <em>Activity Date</em>}</div>
                    {(files[ec.file]) && <Row noGutters>
                      {files[ec.file].map((doc, i) => <Col className="pr-3" key={i} xs="auto">
                        <div className="r-tag r-tag--type-1 r-tag--no-delete">
                          <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.file_name || doc.file_url || "Filename"}</a>
                        </div>
                      </Col>)}
                    </Row>}
                  </Collapse>
                  <Collapse isOpen={editableSection === `EC-${i}`}>
                    <form onSubmit={(e) => this.handleECDetailSubmit(e, ec)}>
                      <Row form className="mb-2">
                        <Col xs={12}>
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Activity Name</label>
                          <InputField className="w-100" type="text" name="activity_name" value={ec.activity_name || ""} onChange={(e) => this.handleECChange(e, ec)} />
                        </Col>
                      </Row>
                      <Row form className="mb-2">
                        <Col xs={6}>
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Activity Type</label>
                          <InputField className="w-100" type="text" name="activity_type" value={ec.activity_type || ""} onChange={(e) => this.handleECChange(e, ec)} />
                        </Col>
                        <Col xs={6}>
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Activity Level</label>
                          <InputField className="w-100" type="text" name="activity_level" value={ec.activity_level || ""} onChange={(e) => this.handleECChange(e, ec)} />
                        </Col>
                      </Row>

                      <Row form className="mb-2">
                        <Col xs={12}>
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Organization</label>
                          <InputField className="w-100" type="text" name="organised_by" value={ec.organised_by || ""} onChange={(e) => this.handleECChange(e, ec)} />
                        </Col>
                      </Row>
                      <Row form className="mb-2">
                        <Col xs={12}>
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Activity Year</label>
                          <YearSelect onChange={(e) => this.changeYear(e, ec)} value={ec.activity_date ? moment(ec.activity_date).year() : ""} />
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end mt-3">
                        <Button type="button" onClick={() => onCancel()} className="r-text--sm">Cancel</Button>
                        <Button type="submit" className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
                      </div>
                      {typeof ec.extra_curricular_id === "number" && < FileUpload toggleLoading={this.props.toggleLoading} updateData={this.props.changeExtraCurricular} submitData={this.handleECDetailSubmit} verification={{ user_id: this.props.user_info.user_id, data: ec, files }} />}
                    </form>
                  </Collapse>
                  {typeof ec.extra_curricular_id === "number" && <CVPoint getStudentProfile={this.props.getStudentProfile}  settings={this.props.settings} toggleCV={this.props.toggleCV} files={files} toggleLoading={this.props.toggleLoading} user_info={this.props.user_info} profile_id={ec.extra_curricular_id} type="EXTRA_CURRICULAR" changeCVPoint={this.props.changeCVPoint} cvPoints={this.cvPoints[ec.extra_curricular_id] || []} commentThreads={commentThreads} />}
                  {editableSection !== `EC-${i}` && <ProfileActions delete={() => this.toggleModal(ec.extra_curricular_id)} verification={{ ...ec }} toggleSection={() => toggleSection(`EC-${i}`)} isRequiredValidate={true} commentThreads={commentThreads} profileType="EXTRA_CURRICULAR" />}
                </div>
              )
            }) : <div className="student__profile__section student__profile__section--placeholder">
                <h1 className="r-subheading-2 mr-3" style={{ fontWeight: 600 }}>
                  Activity
            </h1>
                <h1 className="r-subheading-2 mt-3" style={{ fontWeight: 400 }}>
                  Organization
            </h1>
                <div className="r-text--grey-4 mb-2">Activity Year</div>

              </div>
          }
          <Modal className="modal--delete" isOpen={this.state.modalShow} toggle={() => this.toggleModal()}>
            <ModalBody>
              <p>Are you sure you?</p>
              <div className="text-right">
                <Button className="r-text--sm" onClick={() => this.toggleModal()}>Cancel</Button>
                <Button color="dark" onClick={this.deleteDetail} className="r-text--sm r-btn--no-radius">Delete</Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
      </Aux>
    )
  }
}
