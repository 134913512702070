import React from 'react';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';
import StudentRecruitment from '../views/student/recruitment';
import StudentFeed from '../views/student/feed';
import Switch from 'react-router-dom/Switch';
import Calendar from '../views/calendar/calendar';
import Event from '../views/calendar/event';
import FAQ from '../views/student/faq/';


export const StudentRoutes = () => {
  return (
    <Switch>
    <Route exact path="/faq" component={FAQ} /> 
    <Route exact path="/calendar" component={Calendar} />
    <Route exact path="/calendar/events" component={Event}/>
    <Route exact path="/calendar/events/:event_id/edit" component={Event}/>
      <Route exact path="/student/feed" component={StudentFeed} />
      <Route exact path="/student/recruitments" render={() => <Redirect to="/student/recruitments/profile" />} />
      <Route exact path="/student/recruitments/:tab/" component={StudentRecruitment} />
      <Route exact path="/student/recruitments/:tab/:profileTab" component={StudentRecruitment} />
      <Route render={() => <Redirect to="/student/feed" />} />
    </Switch>
  )
}
