import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import {Collapse } from 'reactstrap';
import { Loader } from '../../global/Loader';
import Button from '../../global/Button';
import { Auth } from '../../../actions/Auth';
import store from '../../../store';
import { Errorbar } from '../../global/errorbar';
import {Confirmation} from '../../global/confirmation';
import RoleMasterList from './role_master_list';



export default  class Classification extends Component {


    constructor(props){
        super(props);
    }

    state = {
        isOpen : this.props.isModalOpen,
        isLoading: false,
        listClassifications: [],
        isOpenCollapse : false ,
        isAddCategory :  false,
        categoryInpute : '',
        categories: [],
        classification_category_id : null,
        editClassificationCategory: null,
        addClassification: false,
        message:'',
        errorbar: false,
        isConfirmation : false,
        deleteClassCategoryId : false,
        deleteClassification_category_id : null,
        deleteType : null,
        activeTab: '1' ,
        selectedTags : this.props.campusData && this.props.campusData.tag,
    };

    onChangeTag = (value) =>{
        this.setState({ selectedTags : value });
    }

    toggle = () =>{
        this.props.handleToggle()
    }

    toggleLoading = (isLoading) =>{
        this.setState({
            isLoading
        })
    }

    async componentDidMount(){

        this.init()

    }

    init = async ()=>{        

        if(!this.props.accessLevel_ids){           
            this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});
            return
        } 

        try {
            this.toggleLoading(true)
            let apiResp = await Auth.Classification.listClassificationDetails(this.props.accessLevel_ids);
            let addClassification = false;
            if(apiResp.data.length<=0){
                addClassification = true;
            }
            await this.setState(
                {
                    listClassifications:apiResp.data,
                    addClassification: addClassification
                }
            )
            this.toggleLoading(false)
        } catch (e) {
            console.log(e)
            this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});
        }


    }

    saveTags = async () => {
        try {
            this.toggleLoading(true)
            var body = {
                campus_id : this.props.campusData.campus_id, 
                tag : this.state.selectedTags ,
            }
            let apiResp = await Auth.Spoc.editCampus(body);
            this.props.handleFetchInstitute();
            this.toggleLoading(false)
            this.props.handleToggle() ;
        } catch (e) {
            console.log(e)
            this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});
        }

        }

    handleCategoryList = async () =>{

        let {listClassifications, classification_category_id } = this.state

        let listCategories = []

        listCategories =   listClassifications.filter(data=>{
            return(
                data.classification_category_id == classification_category_id
            )
        })

        await this.setState({categories: listCategories[0].classification_list})
    }

    toggleNewClassification = async ()=>{
        await this.setState(
            {
                addClassification : true,
                classification_category_id : null,
                categories:[],
                isAddCategory : null,
                editClassificationCategory: false

            }
        )
    }


    handleCancel = async ()=>{
        await this.setState({addClassification : false})
    }
    
    handleAddClassification = async (e)=>{

        e.persist()
        e.preventDefault();

        let {classification} = e.target

        if(classification.value == ''){
            this.setState({errorbar: true, message:"Please enter classification title"});
            return
        }    

        let formData= {
            name: classification.value
        }

        let classificationData = { ...formData, ...this.props.accessLevel_ids };

        try {
            this.toggleLoading(true)
            let resp = await Auth.Classification.addClassificationCategory(classificationData);
            this.init()
            this.setState({addClassification : false})
            e.target.reset()
        } catch (e) {
            console.log(e)
            this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});
        }
        this.toggleLoading(false)
    }

    handleCategoryInpute = async (e)=>{
        await this.setState({ categoryInpute: e.target.value})
    }


    toggleAddCategory = ()=>{
        this.setState({isAddCategory: !this.state.isAddCategory})


        this.handleCategoryList()
    }

    handleCreateClassification = async ()=>{
        
        let {classification_category_id, categoryInpute} = this.state   

        if(categoryInpute == ''){

            this.setState({errorbar: true, message:"Please enter classification type"});
            return
        }

        let createClassficationData =  {
            classification_category_id : classification_category_id,
            name: categoryInpute
        }

        try {
            this.toggleLoading(true)
            let apiResp = await Auth.Classification.createClassfication(createClassficationData);
            await this.init()
            await this.handleCategoryList()
            this.setState({categoryInpute: ''})

        } catch (e) {
            console.log(e)
            this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});
        }
        this.toggleLoading(false)
    }



    closebar = () =>{
        this.setState({errorbar: false});
    }


    toggleEditClassification = async (id)=>{

        await this.setState({editClassificationCategory:id, addClassification:false})
    }

    toggleAddCategory = ()=>{
        this.setState({isAddCategory: !this.state.isAddCategory})
    }

    updateClassification = async (e)=>{
        e.preventDefault()
        e.persist()

        let {classification, classification_category_id } = e.target

        if(classification.value == ''){
            this.setState({errorbar: true, message:"Please enter classification title"});
            return
        }

        if(classification_category_id.value == ''){            
            this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});
            return
        }

        let formData={
            "classification_category_id" : classification_category_id.value,
            "name" : classification.value
        }
        this.toggleLoading(true)

        try {
            let apiRespUpdate = await Auth.Classification.editClassCategory(formData);
            this.init()
            this.toggleEditClassification(null)
        } catch (e) {
            console.log(e)
        }

        this.toggleLoading(false)
    }


    handleCategoryDisplay = async (classification_category_id)=>{

        this.setState({addClassification : false})
        if(!classification_category_id){
             await this.setState({classification_category_id:null, isAddCategory : false, categories:[]})
             return
         }

         await this.setState({classification_category_id:classification_category_id, isAddCategory : classification_category_id})
         this.handleCategoryList()

    }

    toggleConfirmation = () => {
        this.setState({isConfirmation : false})
    }

    handleDelete = async (id)=>{
        let {deleteClassCategoryId} = this.state

        if(!deleteClassCategoryId){
            this.setState({errorbar: true, message:"Sorry! Please try again"});
        }

        this.toggleConfirmation()
        this.toggleLoading(true)

        let params = {
            classification_id : deleteClassCategoryId
        }
        
        try {
            let apiRespInactive = await Auth.Classification.inactive_category(params);
            await this.init()
            await this.handleCategoryList()        

        } catch (e) {
            console.log(e)
            this.setState({errorbar: true, message:"Sorry! Please try again"});
        }
        this.toggleLoading(false)
    }

    handleDeleteClassCategory = async ()=>{

         let {deleteClassification_category_id } = this.state

         if(!deleteClassification_category_id){

            this.setState({errorbar: true, message:"Sorry! Please try again"});
         }

         this.toggleConfirmation()

        let params = {
            classification_category_id : deleteClassification_category_id
        }

        this.toggleLoading(true)

        try {
            let apiRespClassInactive = await Auth.Classification.inactive_class_category(params);
            this.init()
        } catch (e) {
            console.log(e)
            this.setState({errorbar: true, message:"Sorry! Please try again"});

        }

        this.toggleLoading(false)
    }

    deleteConfirmation = async (id, deleteType)=>{

        if(deleteType == 'deleteSubCategory'){
           await  this.setState({deleteClassCategoryId: id, isConfirmation: true, deleteType})
        }
        else{
          await  this.setState({deleteClassification_category_id: id, isConfirmation: true, deleteType})
        }        

    }

    closebar = () =>{
        this.setState({errorbar: false});
    }

    toggleTabs =  (tab)=> {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }
    

    render() {

        let {
                isModalOpen,
                accessLevel_ids
            } = this.props

        let {
                listClassifications,
                isOpenCollapse,
                isAddCategory,
                categories,
                editClassificationCategory,
                isLoading,
                errorbar,
                message,
                isConfirmation,
                deleteType ,
                selectedTags
            } = this.state



        return (
            <div className="classification">
                <Loader show={isLoading} />
                <Modal zIndex={99999} className="modal-lg modal-dialog-centered" isOpen={isModalOpen} toggle={this.toggle} >
                    <ModalBody>
                    <div>
                        <Nav tabs className="m-3">
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggleTabs('1'); }}
                                >
                                <h3 className="r-heading-5"> Define classifications </h3>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggleTabs('2'); }}
                                >

                                <h3 className="r-heading-5"> Define Roles </h3>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggleTabs('3'); }}
                                >

                                <h3 className="r-heading-5"> Define Tags </h3>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <div className="action-classification">
                                <Col xs={12}>
                                <Row>
                                    <Col xs={10}>
                                    
                                        <p>
                                            Classification of companies helps you with placement reports
                                            <br/>
                                            <i className="r-text--sm">
                                                e.g. - you can view placement report for companies marked as "Dream", "Normal" by creating a classification called "Company Type"
                                            </i>
                                        </p>
                                    
                                    </Col>
                                    <Col xs={2}>
                                        <h3 className="r-heading-4 my-3 text-right" onClick={this.toggleNewClassification}>

                                            <button className="r-text--sm r-btn r-btn--brandB" color="brandB"><i className="fi flaticon-plus mr-2"></i> New </button>

                                        </h3>
                                    </Col>
                                </Row>
                                </Col>

                                <Collapse isOpen={this.state.addClassification}>
                                    <div className="form-add-classification">
                                        <Col xs={12}>
                                                <p>Classification Title</p>
                                        </Col>
                                        <form onSubmit={this.handleAddClassification}>
                                            <Col xs={12} className="border-bottom">
                                                <Row className="my-3">
                                                <Col xs={8}>
                                                    <input type="text" name="classification" className="w-100 r-inputfield"
                                                    placeholder="Type classification here" required={true}/>
                                                </Col>
                                                <Col xs={4}>
                                                    <div className="text-right">
                                                        <button  className="r-btn r-btn--dark-v r-btn--no-radius" type="submit">Save</button>
                                                        <Button type="button" onClick={this.handleCancel}>Cancel</Button>
                                                    </div>
                                                </Col>
                                                </Row>
                                            </Col>
                                        </form>
                                    </div>
                                </Collapse>
                            </div>

                                <Collapse isOpen={true}>

                                <div className="list-classification mb-3 mt-3 ">
                                    <Col xs={12}>
                                    {
                                        listClassifications.map(data=>{
                                            return(

                                                <div className="border-bottom mt-3 py-0" key={data.classification_category_id}>


                                                    <Collapse  isOpen={editClassificationCategory != data.classification_category_id }>
                                                        <Row>
                                                    <Col xs={8}>


                                                        <h1 className="r-subheading-3">
                                                        {data.name} {" "}
                                                        <Button className="r-text--sm ml-3" color="blue" onClick={()=> this.handleCategoryDisplay(data.classification_category_id)}><i className="r-text-sm fi flaticon-plus mr-2"></i>Add</Button>
                                                        </h1>
                                                    </Col>

                                                    <Col xs={4}>
                                                        <div className="text-right">
                                                            <button className="p-2 r-btn"


                                                            onClick={()=> this.toggleEditClassification(data.classification_category_id)}

                                                            >
                                                                <i className="fi flaticon-edit"></i>
                                                            </button>
                                                            <button className="p-2 r-btn"
                                                                onClick={()=> this.deleteConfirmation(data.classification_category_id, 'deleteClassification')}>
                                                                <i className="fi flaticon-bin"></i>
                                                            </button>
                                                        </div>
                                                    </Col>

                                                    <Col xs={12}>
                                                        <Collapse isOpen={isAddCategory == data.classification_category_id}>

                                                        <div className="card shadow-sm mb-2 form-add-classification-category">
                                                            <div className="card-body">

                                                            <Row >
                                                                <Col xs={12}>
                                                                    <div className="no-gutters mt-1 row">
                                                                        {


                                                                        categories.length>0?
                                                                        categories.map((category, i)=>{
                                                                                return(
                                                                                    <div key={i} className="pr-3 col-auto">
                                                                                        <div className="r-tag r-tag--type-1">
                                                                                            {category.name}
                                                                                            <button onClick={() => this.deleteConfirmation(category.classification_id, 'deleteSubCategory')}  type="button" title="Delete Proof" className="r-tag__delete fi flaticon-plus-1">
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>


                                                            <Row className="my-3">
                                                            <Col xs={12}>
                                                            <p className="">Please list different {data.name} types below :</p>
                                                            </Col>
                                                                <Col xs={8}>
                                                                    <input type="text" name="Category" className="w-100 r-inputfield"
                                                                    onChange={this.handleCategoryInpute} value={this.state.categoryInpute} placeholder={"Enter "+data.name+" type here"} />
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <div className="text-right">
                                                                        <button type="button"

                                                                            className="r-btn r-btn--dark-v r-btn--no-radius"
                                                                        onClick={this.handleCreateClassification}>Save</button>
                                                                        <Button type="button" onClick={this.toggleAddCategory}>Cancel</Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            </div>
                                                        </div>

                                                        </Collapse>
                                                    </Col>

                                                    </Row>
                                                    </Collapse>
                                                    <Collapse
                                                        isOpen={editClassificationCategory == data.classification_category_id } >
                                                            <form onSubmit={this.updateClassification}>
                                                            <Row className="my-3">
                                                        <Col xs={8}>


                                                                <input
                                                                    type="text" name="classification"
                                                                    defaultValue={data.name} className="w-100 r-inputfield"
                                                                    placeholder="Type classification here"/>
                                                                    <input type="hidden" name="classification_category_id" value={data.classification_category_id} required={true}/>


                                                        </Col>
                                                        <Col xs={4}>

                                                            <div className="text-right">
                                                                <button type="submit"
                                                                className="r-btn r-btn--dark-v r-btn--no-radius"
                                                                >
                                                                    Save
                                                                </button>
                                                                <Button type="button"  onClick={()=> this.toggleEditClassification(null)}
                                                                    >
                                                                        Cancel
                                                                </Button>
                                                            </div>

                                                        </Col>
                                                        </Row>
                                                        </form>
                                                    </Collapse>

                                                </div>

                                            )
                                        })
                                    }
                                    </Col>
                                </div>

                                </Collapse>
                            </TabPane>
                            <TabPane tabId="2">
                            <Row>
                            <Col xs="12">
                                {
                                    this.state.activeTab === '2'?
                                    <RoleMasterList accessLevel_ids = {accessLevel_ids} loader={this.toggleLoading}/> 
                                    :
                                    null
                                }
                                
                            </Col>             
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                            <Col xs="12" className="mt-5">
                                <p className="ml-3" >Select Tag for campus</p>
                               <Button color={this.state.selectedTags === "B-school" ? "blue-3":"blue"} className="mr-3 ml-3" onClick={(e)=>{this.onChangeTag("B-school")}}>B-school</Button>
                               <Button color={this.state.selectedTags === "Humanities/Sciences/Commerce" ? "blue-3":"blue"} className="  mr-3" onClick={(e)=>{this.onChangeTag("Humanities/Sciences/Commerce" )}}>Humanities/Sciences/Commerce</Button>
                               <Button color={this.state.selectedTags === "Engineering" ? "blue-3":"blue"} onClick={(e)=>{this.onChangeTag("Engineering" )}}>Engineering</Button>
                            </Col>             
                            </Row>
                            <Row className="mt-5">
                                <Col xs={12}>

                                    <div className="text-right">
                                        <button type="submit"
                                        className="r-btn r-btn--dark-v r-btn--no-radius"
                                        onClick={this.saveTags}>
                                            Save
                                        </button>
                                        
                                    </div>

                                </Col>
                            </Row>
                        </TabPane>
                        </TabContent>

                        <Errorbar showBar={errorbar} onClose={this.closebar} message={message}/>
                        {
                            deleteType == 'deleteSubCategory'?
                            <Confirmation isOpen={isConfirmation} toggleConfirmation={this.toggleConfirmation} 
                            callback={this.handleDelete}  
                        />
                            :
                            <Confirmation isOpen={isConfirmation} toggleConfirmation={this.toggleConfirmation} 
                                callback={this.handleDeleteClassCategory}  
                            />
                        }
                    </div> 

                    </ModalBody>

                </Modal>                                   
                
            </div>
        )
    }

}
