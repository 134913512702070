
import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, UncontrolledPopover, PopoverHeader, Table } from 'reactstrap';
import { SingleDatePicker } from '../../../global/Forms/DatePicker';
import { Select } from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import TimePicker from 'rc-time-picker';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/information.svg';
import moment from 'moment';
import UncontrolledCollapse from 'reactstrap/lib/UncontrolledCollapse';
import { getErrorMessage, getFullName, uploadFile } from '../../../../actions';
import { Loader } from "../../../global/Loader";
import PaginationComponent from '../../../global/PaginationComponent';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';
import store from '../../../../store';
import { setError } from '../../../../actions/action-creators';

export default class UploadOffers extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    return ({
      loading : false,
      campus_company_id: null,
      role_id: null,
      designationList: [],
      offer_date: null,
      designation: "",
      location: "",
      process_company_profile_id: "",
      withdraw_deadline: null,
      convertedRate: 0,
      ctc_value: null,
      currency: null,
      conversionRate: 1,
      studentList : [],
      searchText : '',
      currentPage : 0,
      activeTab : '1',
      uploadedFile : null
    })
  }

  onCompanyChange = async (event) =>{
    try {
      var campus_company_id = event.target.value;
      if(event.target.value.length <= 0){
        await this.setState({designationList: []});
        return
      }
      let designationList = [];
      let profileResp = await Auth.PtCompanies.getCompanyProfiles(campus_company_id);
       designationList = profileResp.data.map(company => <option key={company.company_profile_id} value={company.company_profile_id}>{company.role_name}</option>)
      await this.setState({campus_company_id, designationList: designationList});
    } catch (e) { console.log(e)
    this.setState({designationList:[]})}
  }


  handleSelected = (selectedPage) => {
    this.setState({ currentPage: selectedPage - 1 });
  }

  changeoffer_date = (offer_date) => {
    this.setState({
      offer_date: moment(offer_date).format('YYYY-MM-DD')
    })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault();
    let {ctc_fixed_value , varaible , esop , bonus , currency , offer_status, offer_type} = e.target;
    this.handleOfferFormData(ctc_fixed_value, varaible , esop , bonus , offer_status.value, offer_type.value);
  }

  handleOfferFormData = (ctc_fixed_value , varaible , esop , bonus, offer_status, offer_type)=>{
    let {studentList , campus_company_id , offer_date , designation , location , role_id , withdraw_deadline , convertedRate , ctc_value , conversionRate, currency} = this.state;
    let studentIdAray = [];
    if(!this.props.applicants.length) {
      store.dispatch(setError({ showBar: true, message: "No Applicants!"}));
      return;
    }
    if((this.state.activeTab === '1' && studentList.length) || this.state.activeTab === '2'){
      studentList.forEach(student=>{
        studentIdAray.push(student.student_id);
      });
      let formData = {
        process_id: this.props.applicants[0].process_id,
        student_id: studentIdAray,
        campus_company_id: campus_company_id,
        role_id : role_id,
        offer_type: offer_type,
        offer_status: offer_status,
        designation: designation,
        currency: currency,
        ctc_value: ctc_value,
        location: location,
        offer_date: offer_date,
        ctc_fixed_value: ctc_fixed_value.value? ctc_fixed_value.value : 0 ,
        varaible: varaible.value ? varaible.value : 0,
        esop: esop.value? esop.value : 0,
        bonus: bonus.value? bonus.value : 0,
        withdraw_deadline : withdraw_deadline,
        currency_rate : conversionRate
      }
      this.offerCreate(formData);
    }
   }

  offerCreate = async(formData)=>{
    this.setState({loading : true});
    try{
      if(this.state.activeTab === '2'){
        if(!this.state.uploadedFile) throw new Error("Please select a file");
        let file = await uploadFile(this.state.uploadedFile, `process/${formData.process_id}/offer`);
        if (file.file_url) {
          delete formData.student_id;
          formData.file_url = file.file_url
          await Auth.Offer.addOfferExcel(formData)
        } else {
          throw new Error("Error uploading file. Please try again.")
        }
      }
      else { 
        await Auth.Offer.addOffer(formData);
      }
      this.setState(this.getInitialState());
      this.props.handleOfferModal();
    }
    catch(e){
      console.log(e);
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) || e.toString()  }))
    }
    this.setState({loading : false});
  }

  handleOfferSelect = (e)=>{
    let {studentList} = this.state,
    {applicants}=this.props;
    let tempList = [...studentList];
    if(studentList.length>0){
      for(let i=0 ; i<studentList; i++){
      tempList[i].student = {...studentList[i].student};
      tempList[i].shortlisting_data = [...studentList[i].shortlisting_data]
    }
  }
    if(e.target.checked==true){
      let check = this.checkDuplicates(tempList , e.target.name);
      if(check==1){
        tempList.push(applicants.filter(student=>student.student_id == e.target.name)[0]);
      }
    }else{
      for(let i=0; i<tempList.length ; i++){
        if(tempList[i].student_id == e.target.name){
          tempList.splice(i,1);
          i=i-1;
        }
      }
    }
    this.setState({studentList : tempList})
  }

  checkDuplicates = (list , item)=>{
    let flag = 1;
    list.forEach(subItem=>{
      if(item == subItem.student_id){
        flag = 0;
      }
    })
    return flag;
  }

  render() {
    let { application_id, applicants } = this.props,
      { designation, offer_date, withdraw_deadline, location, ctc_value, conversionRate, convertedRate , studentList , loading , searchText , currentPage, currency, uploadedFile } = this.state;
   
    let subApplicants =[]
    if(searchText!==''|| true){
      subApplicants = applicants;
      subApplicants = subApplicants.filter(data=>{
        var studentName = getFullName(data) || '';
        if(studentName.toLowerCase().includes(searchText.toLowerCase()) || data.roll_no.toLowerCase().includes(searchText.toLowerCase())){
          return data;
        }
      })
    }
    return (
      <Form onSubmit={this.handleSubmit}>
      <Loader show={loading} />
        <Row form>
          <Col xs={3} className="border-right">
            <p>Offer Details</p>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <label className="r-heading-7"><span className="r-text--red-1">*</span>Offer Type</label>
                  <Select defaultValue={1} name="offer_type" required={true}>
                    <option value="0">On-Campus</option>
                    <option value="1">PPO</option>
                    <option value="2">Off-Campus</option>
                    <option value="3">Self Placed</option>
                    <option value="4">Competition</option>
                  </Select>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label className="r-heading-7"><span className="r-text--red-1">*</span>Offer Status</label>
                  <Select defaultValue={1} name="offer_status" required={true}>
                    <option value="">Select</option>
                    <option value="1">Confirmed</option>
                    <option value="2">Waiting</option>
                  </Select>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label className="r-heading-7"><span className="r-text--red-1">*</span>Offer Date</label>
                  <SingleDatePicker value={offer_date}
                    onDateChange={this.changeoffer_date}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label className="r-heading-7">Withdraw Deadline</label>
                  <span className="withdraw_deadline ml-1" id={`Popover-${application_id}`}><InfoIcon /></span>
                  <UncontrolledPopover className="w-100 h-auto zIndex--max rounded" placement="right" target={`Popover-${application_id}`} trigger="legacy">
                    <PopoverHeader className=" p-3 r-bg--light  w-100 ">
                      <Row>
                        <Col xs="12">
                          <p className="r-subheading-4">If you allow your students to have multiple offers and pre-defined conditions are applicable on the student's applications after getting an offer, a withdrawal deadline can be given to the student asking him/ her to withdraw from any extra applications so that the pre-defined conditions are met.</p>
                          <p className="r-subheading-4">Example - After 1st offer, the student is ONLY allowed 5 more applications, and if he has already applied to 10, the <b>withdrawal deadline</b> is the time given to the candidate to withdraw from excess 5 applicatons to meet the condition.</p>
                        </Col>
                      </Row>
                    </PopoverHeader>
                  </UncontrolledPopover>
                  <Row>
                    <Col xs={7}>
                      <SingleDatePicker
                        value={withdraw_deadline}
                        onDateChange={(date) => this.setState({ withdraw_deadline: date })}
                      />
                    </Col>
                    <Col xs={5}>
                      <TimePicker
                        onChange={(date) => this.setState({ withdraw_deadline: date })}
                        value={withdraw_deadline}
                        format="h:mm a"
                        use12Hours
                        showSecond={false}
                        inputReadOnly />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col xs={3} className="border-right">
            <p>Role Details</p>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <label className="r-heading-7"><span className="r-text--red-1">*</span> Company Name</label>
                  <Select value={this.state.campus_company_id} onChange={this.onCompanyChange} name="company_id" required={true}>
                    <option value="">Select</option>
                    {this.props.companyList}
                  </Select>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label className="r-heading-7"><span className="r-text--red-1">*</span>Job Role Offered</label>
                  <Select value={this.state.role_id} onChange={(e) => this.setState({role_id: e.target.value})} required={true}>
                    <option value="">Select</option>
                    {this.state.designationList}
                  </Select>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label className="r-heading-7">Designation</label>
                  <InputField value={designation} type="text" onChange={this.handleChange} className="w-100 mb-2" name="designation" placeholder="Ex: Web Developer" />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label className="r-heading-7">Location</label>
                  <InputField type="text" value={location}  onChange={this.handleChange} className="w-100 mb-2" name="location" placeholder="Ex: Delhi" />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col xs={3} className="border-right">
            <p>Compensation Details</p>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <label className="r-heading-7"><span className="r-text--red-1">*</span>CTC Value</label>
                  <InputField value={ctc_value} type="text" onChange={this.handleChange} required={true} className="w-100 mb-2" name="ctc_value" placeholder="Ex: 10000" />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <label className="r-heading-7"><span className="r-text--red-1">*</span>Currency Type</label>
                  <Select name="currency" onChange={this.handleChange} value={currency} required={true}>
                    <option value="">Select</option>
                    <option value="INR">INR</option>
                    <option value="USD">USD</option>
                    <option value="AED">AED</option>
                    <option value="SAR">SAR</option>
                    <option value="BHD">BHD</option>
                    <option value="QAR">QAR</option>
                    <option value="QMR">QMR</option>
                    <option value="KWD">KWD</option>
                    <option value="SGD">SGD</option>
                    <option value="MYR">MYR</option>
                    <option value="HKD">HKD</option>
                  </Select>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <label className="r-heading-7">Exchange Rate</label>
                  <InputField placeholder="INR Rate" onChange={(e) => this.setState({ convertedRate: ctc_value != null ? (parseFloat(ctc_value * e.target.value).toFixed(2)) : null, conversionRate: e.target.value })} className="w-100" />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Row>
                    <Col>
                      <label className="r-heading-7">Converted INR Value</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="m-2">
                      <span className="r-text--green-1">{conversionRate != null && ctc_value ? parseFloat(conversionRate * ctc_value).toFixed(2) : convertedRate}</span>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <a href="#" id={`toggler-${application_id}`}>More Fields</a>
                <UncontrolledCollapse toggler={`toggler-${application_id}`}>
                  <Row >
                    <Col md={12}>
                      <FormGroup>
                        <label className="r-heading-7">CTC Fixed Value</label>
                        <InputField
                          type="text"
                          className="w-100 mb-2"
                          name="ctc_fixed_value"
                          placeholder="CTC Fixed"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <label className="r-heading-7">Variable</label>
                        <InputField
                          type="tex"
                          className="w-100 mb-2"
                          name="varaible"
                          placeholder="CTC Variable" />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <label className="r-heading-7">ESOP</label>
                        <InputField
                          type="text"
                          className="w-100 mb-2"
                          name="esop"
                          placeholder="ESOP Value" />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <label className="r-heading-7">Bonus</label>
                        <InputField
                          type="text"
                          className="w-100 mb-2" name="bonus"
                          placeholder="Bonus" />
                      </FormGroup>
                    </Col>
                  </Row>
                </UncontrolledCollapse>
              </Col>
            </Row>
          </Col>
          <Col xs={3}>
            <Row>
              <Col>
                <p>Selected Students</p>
                {studentList.length?<div className="application_offers">
                  <Table className="processes__table__2">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Roll No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        studentList.map(student => {
                          return (
                            <tr className="row-animate" key={student.student_id}>
                              <td>
                                {getFullName(student)}
                              </td>
                              <td>
                                {student.roll_no}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table></div> : <span className="r-text--red-1">*No Students Selected</span>
                }
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            <Nav tabs className="mb-3">
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === '1' ? "active":""}
                    onClick={() => { this.setState({ activeTab: '1', uploadedFile: null }) }}
                    style={{ cursor: "pointer" }}
                  >
                    <h4>Select Applicants</h4>
                  </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '2' ? "active":""}
                  onClick={() => { this.setState({ activeTab: '2' }) }}
                  style={{ cursor: "pointer" }}
                >
                 <h4>Upload Applicants</h4> 
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col xs="3">
                    <div className="mt-2 mb-4">
                      <InputField className="w-100 r-radius--3" onChange={(e)=>this.setState({searchText : e.target.value})} placeholder="Name/Roll No" />
                      <i className="pt-2 r-text--grey-4 fi flaticon-search verifier__search-bar"  />
                    </div>
                  </Col>
                </Row>
                <Table className="processes__table__2 text-center">
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Name</th>
                      <th>Roll No</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      subApplicants.slice(currentPage * 10, currentPage * 10 + 10).map(student => {
                        // let item = offerDetails.filter(st=>st.student_id == student.student_id)[0];
                        // if(!item){
                          return (
                            <tr className="row-animate" key={student.student_id}>
                              <td>
                                <input type="checkbox" name={student.student_id} checked={studentList.filter(st=>st.student_id==student.student_id).length? true : false} onChange={this.handleOfferSelect} />
                              </td>
                              <td>
                                {getFullName(student)}
                              </td>
                              <td>
                                {student.roll_no}
                              </td>
                              <td>
                                {student.email}
                              </td>
                            </tr>
                          )
                        // }
                      })
                    }
                  </tbody>
                </Table>
              </TabPane>
              <TabPane tabId="2">
                <Row noGutters className="m-4">
                  <Col>
                    <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                      <div className="border text-center font-weight-normal mx-3 py-1  px-3  " color="outline-dark-v">
                        {(uploadedFile !== null) ? uploadedFile.name : "Upload File"}
                        <i className=" r-text--xl fi flaticon-upload r-text--grey-4 ml-3" style={{ fontSize: "16px" }} />
                      </div>
                      <input id="file-input" accept={".xlsx"} onChange={(e) => this.setState({uploadedFile: e.target.files[0]})} type="file" style={{ display: "none" }} />
                    </label>
                    <a className=" my-2" href="/template-shortlist.xlsx" download="template-offer.xlsx">Download excel template</a>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
        <Row noGutters className="m-4">
          <Col>
            {studentList.length || this.state.activeTab === '2'?<Button color="dark" >Submit</Button> : null}
          </Col>
        </Row>
        <Row>
          <Col className="verifier__page">
            <PaginationComponent
            totalItems={applicants.length!==0?applicants.length : 0}
            pageSize={10}
            onSelect={this.handleSelected}
            maxPaginationNumbers={5}
            activePage={currentPage + 1}  />
          </Col>
        </Row>
      </Form>
    )
  }
}
