import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import { Row, Col,Card, CardBody, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import {UncontrolledCollapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Loader } from '../../../global/Loader';

import { Select} from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import { CONSTANTS } from '../../../../constants';
import FaqCard from './faq-card' 



export default  class FAQ extends Component {

  constructor(props) {
    super(props);

    this.state = { collapse: false };
  }

  toggle = ()=> {
    this.setState(state => ({ collapse: !state.collapse }));
  }  

  render() {    
    return(

      <div className="container mt-5">  
        <div className="faq">
          <div className="title">
            <Col xs={12}>
              <h1 className="r-heading-1 text-center mb-5">Placement Team FAQ(s)</h1>
            </Col>
          </div>
          <FaqCard 
            heading={'Creating student logins'} 
            
            points ={
              <div className="point">              
                <ul className="font-weight-normal">
                  <li>	Go to the <b><i>Students</i></b>  tab in your account</li>
                  <li>Click on <b><i>Add Student</i></b></li>
                  <li>Select the batch and department, add relevant student details and click on Save. The student shall receive his/her login </li>
                  <li>credentials on the email id entered.</li>                
                </ul> 
               
                <div className="text-center">
                  <img src={'/images/faq/faq-1.png'} className="img-fluid pb-2 pt-2"/>
                </div>
                <br/>
                <ul>                
                  <li>
                    You can add yourself as a student too, by using the same email id as your placement team login. On doing this, student view will be accessible to you under your account settings.
                  </li>
                </ul>

                <div className="text-center">
                <img src={'/images/faq/faq-2.png'} className="img-fluid pb-2 pt-2"/>
                </div>
                
              </div>
            }
          /> 

        <FaqCard 
            heading={'Freezing and Unfreezing Student Profiles'} 
            
            points ={
              <div className="point">        
                <p>unfreeze 
                Reculta gives you the ability to prevent students from making changes to their profiles. This functionality is useful say when the placement season is about to start and you would not want the students to change their profiles.
                </p>      
                <ul className="font-weight-normal">
                  <li>You can do it either for a specific student by clicking on the <b><i>Freeze</i></b> toggle button against their name.</li>                                
                </ul> 

                <div className="text-center">
                <img src={'/images/faq/faq-3.png'} className="img-fluid pb-2 pt-2"/>
                </div>
                <br/>
                <ul>                
                  <li>
                  	Or you can do it for multiple students as well. Reculta even lets you automate this function for a specific date and time to be decided by you. Simply select some/ all of the students using the check boxes, click on <b><i>Freeze</i></b>. You can then either choose to set a deadline for the task, or you can do it instantly as well. In a similar manner, you can <b><i>unfreeze</i></b>   the profiles of students frozen earlier too.
                  </li>
                </ul>
                <br/>

                <div className="text-center">
                <img src={'/images/faq/faq-4.png'} className="img-fluid pb-2 pt-2"/>
                </div>
              </div>
            }
          /> 

          <FaqCard 
            heading={'Accessing the Batch Profile'} 
            
            points ={
              <div className="point">        
                <p>
                  Once students logins have been created and students have started filling their profile data, you can  access a comprehensive aggregate profile of the different batches at any given time.
                  <br/>
                  <br/>
                  Simply click on the <b><i>Batch Snapshot</i></b> option in the leftmost drop-down on your <b><i>Students</i></b> tab.


                </p>      
                
                <div className="text-center">
                <img src={'/images/faq/faq-5.png'} className="img-fluid pb-2 pt-2"/>
                </div>
                <p>
                You can add more charts to represent different data points as needed.
                </p>
              </div>
            }
          />

          <FaqCard 
            heading={'Student Feed'} 
            
            points ={
              <div className="point">        
                <p>
                  As the placement team at your institute, you get the functionality to post placements related content to the students. This content then appears in their news feed on Reculta. This is a direct and engaging way to keep students abreast of latest recruiter activities and keep their involvement active in the placement process.
                </p>      
                <ul>
                  <li>
                    To access the student feed section, click on the <b><i>Student Feed</i></b> option in the leftmost dropdown in your <b><i>Students</i></b> tab.
                  </li>
                </ul>

                <br/>
                <div className="text-center">
                <img src={'/images/faq/faq-6.png'} className="img-fluid pb-2 pt-2"/>
                </div>
                <br/>
                <ul>
                  <li>
                  	Once you are in the Student Feed section, please use the <b><i>dialogues box</i></b> on top of the page to publish any new content to students. You can add a headline, body text and an image/ video if required.
                  </li>
                </ul>
                <br/>
                
                <div className="text-center">
                <img src={'/images/faq/faq-7.png'} className="img-fluid pb-2 pt-2"/>
                </div>
                <br/>
                <ul>
                  <li>
                  	For all the posts previously published by you, <b><i>student engagement metrics</i></b> are visible against them in this section as well.
                  </li>
                </ul>
              </div>
            }
          />

  

        <FaqCard 
            heading={'Managing Companies'} 
            
            points ={
              <div className="point">        
                <p>
                You can manage all your recruiters on Reculta itself using the ‘Companies’ tab in your account. You can store all information related to your recruiters in one single place here.
                </p>      
                <ul>
                  <li>
                  	To add a recruiter, just click on the “+ Company” button on the top of your screen. The system requests for basic company data which you will need to enter. You can also add multiple companies by using the excel template provided in the dialogue box that opens up while adding a company.
                  </li>
                </ul>
                <div className="text-center">
                <img src={'/images/faq/faq-company-1.png'} className="img-fluid pb-2 pt-2"/>
                </div>
                <ul>
                  <li>
                  Each recruiter added by you on Reculta then starts appearing in the ‘Companies’ tab.
                  </li>
                  <li>
                  	Next step would be to add contact information for each recruiter. You can do that by clicking on the ‘Contact’ column against each recruiter’s name, or you can click on the recruiter name and enter it on a new page. Previously entered contacts are also visible here.
                  </li>
                </ul>
                <div className="text-center">
                <img src={'/images/faq/faq-company-2.png'} className="img-fluid pb-2 pt-2"/>
                </div>
                <ul>
                  <li>
                  Next, you need to add all the roles for which a recruiter is interested in hiring for, from your institute. Follow similar steps as adding the contact details, i.e. by clicking on the ‘Roles’ column against each recruiter’s name. The roles available are restricted basis the list set up by your institute’s admin.
                  </li>
                </ul>
                <div className="text-center">
                  <img src={'/images/faq/faq-company-3.png'} className="img-fluid pb-2 pt-2"/>
                </div>
              </div>
            }
          />

        <FaqCard 
            heading={'Managing a Placement Process'} 
            
            points ={
              <div className="point">        
                <p>
                The <b><i>Process</i></b> tab in your account helps you manage the complete placement process at your institute, once the students have created their profiles/ CV(s) and you have set up the recruiter database.
                </p>
                <ul>
                  <li>
                    Go to the   <b><i>Process</i></b> tab on your login, and click on <b><i>Add Process</i></b>  in the dropdown menu on the top left.
                  </li>
                </ul>
                <div className="text-center">
                  <img src={'/images/faq/process-1.png'} className="img-fluid pb-2 pt-2"/>
                </div>
                <ul>
                  <li>
                    Enter the Process Name and Process Type and click on <b><i>Save</i></b>.
                  </li>
                  <li>
                  After creating the process, its name will start appearing in the same drop down menu on the left. You must now add students to this process by clicking on the <b><i>Students +</i></b> button just next to the drop down menu. Select relevant batches and departments, then click on <b><i>Import</i></b>.
                  </li>
                </ul>
                <div className="text-center">
                  <img src={'/images/faq/process-2.png'} className="img-fluid pb-2 pt-2"/>
                </div>
                <ul>
                  <li>
                  Once this process is created with the students mapped to it, you can start adding recruiters to this process. Simply click the <b><i>Click to add company to the process</i></b> button on the top and select the desired companies. This list of companies is populated basis the data entered by you in the <b><i>Companies</i></b> tab.
                  </li>
                </ul>
                <div className="text-center">
                  <img src={'/images/faq/process-3.png'} className="img-fluid pb-2 pt-2"/>
                </div>
                <ul>
                  <li>
                  For each recruiter you add to the process, you can track the status of their participation (interested, confirmed, denied, etc.) and also track the status of each role. 
                  </li>
                  <li>
                  If you had not added any roles to the company in the <b><i>Companies</i></b> tab previously, you can do that from this page as well.
                  </li>
                  <li>
                   Once you click on <b><i>Click to Start Application Process</i></b> against any company, Reculta gives you the option to either add roles (if none have been added previously), or select which role to open applications for. 
                  </li>
                  <li>
                  While filling the role details, you have the option to upload a JD, company PPT, specify eligibility criteria and even ask additional questions (if any). 
                  </li>
                  <li>
                  You also select the deadline for the application and whether you want to give students the option to withdraw their applications.
                  </li>
                  <li>
                  In the dialogue box that opens, enter all relevant details related to the application and click on <b><i>Save</i></b>.
                  </li>
                </ul>

                <div className="text-center">
                  <img src={'/images/faq/process-4.png'} className="img-fluid pb-2 pt-2"/>
                </div>
                <ul>
                  <li>
                  	Post adding the specific role application to the process, click on the role name, and activate the <b>
                      <i>
                      Publish</i></b> toggle in the menu that opens up. At this point, the notification of the application being published goes to all students and they can start applying.
                  </li>
                  <li>
                    The dashboard for each role for each company can be expanded by clicking on it. This dashboard gives you an overview of the number of eligible students, number of applications, etc. You can even manage the shortlisting process across various rounds here.
                  </li>                  
                </ul>
                <div className="text-center">
                  <img src={'/images/faq/process-5.png'} className="img-fluid pb-2 pt-2"/>
                </div>
              </div>
            }
          />

        </div>
      </div>

    )
  }

}
