import React,{Component} from 'react';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container} from 'reactstrap';
import {Button, ButtonGroup} from 'reactstrap';
import moment from 'moment';
import  {InputField} from '../../global/Forms';
import  {Switch} from '../../global/Forms/ToggleSwitch';
import {HorizontalLine} from '../../global/horizontal_line';
import {Collapse} from 'reactstrap';
import Aux from '../../global/AuxComp';
import { ListGroup, ListGroupItem } from 'reactstrap';
import {Card, CardTitle, CardBody} from 'reactstrap';
import {Errorbar} from '../../global/errorbar';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import DatePicker from 'react-dates/esm/components/SingleDatePicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import {YearPicker, MonthPicker , DayPicker} from 'react-dropdown-date';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Loader } from '../../global/Loader';
import {CONSTANTS} from '../../../constants';


class MeetingPrimarySection extends Component{
  constructor(){
    super();
    this.state={
      value:1,
      subject: null,
      body : null,
      reminderTriggerValue : 1,
      remindDropdownValue : 1,
      remindTime: null,
      remindDate : null,
      year : null,
      month : null,
      day: null,
      reminderValue:null,
      ptMemberList : [],
      ptMember:null,
      isOn:false,
      ptMemberMails: [],
      deletePtList : [],
      ptMemberSelected : [],
      errorbar: false,
      message:''
    }
  }
  closebar = () =>{
      this.setState({errorbar: false});
  }

  onChangeSubject = async(e)=>{
    await this.setState({
      subject: e.target.value
    })
    // this.props.setMailCollection(this.state.studentMails,this.state.body, this.state.subject ,  this.state.studentList)
  }
  onChangeBody = async (e)=>{
    await this.setState({
      body: e.target.value
    })
    // this.props.setMailCollection(this.state.studentMails,this.state.body, this.state.subject,  this.state.studentList)
  }
  handleReminderTrigger = async (e)=>{
    await this.setState({
      reminderTriggerValue : e.target.value
    })
    // this.props.setRepeatReminderValue(this.state.reminderTriggerValue);
  }
  setReminderValue = async (value)=>{
    // let {reminderTriggerValue} = this.state;
      this.setState({
        reminderValue : value
      })
      // if(value==1){
      //   this.props.setRepeatReminderValue("once")
      // }
  }

  OnChangeStartTime = (e)=>{
    if(this.state.day !=null && this.state.month!=null && this.state.year!=null){
      this.formatTime();
      let time = moment(e).format("HH:mm")+":"+"00";
      this.props.setRemindTime(time);
      this.setState({
        remindTime : moment(e).format("HH:mm:ss")
      })
    }
  }


  toggleLoading = (loading) =>{
    this.setState({
      loading
    })
  }

  closebar = () =>{
      this.setState({errorbar: false});
  }

  onChangeSubject = async(e)=>{
    await this.setState({
      subject: e.target.value
    })
    // this.props.setMailCollection(this.state.studentMails,this.state.body, this.state.subject ,  this.state.studentList)
  }
  onChangeBody = async (e)=>{
    await this.setState({
      body: e.target.value
    })
    // this.props.setMailCollection(this.state.studentMails,this.state.body, this.state.subject,  this.state.studentList)
  }
  handleReminderTrigger = async (e)=>{
    await this.setState({
      reminderTriggerValue : e.target.value
    })
    // this.props.setRepeatReminderValue(this.state.reminderTriggerValue);
  }
  setReminderValue = async (value)=>{
    // let {reminderTriggerValue} = this.state;
      this.setState({
        reminderValue : value
      })
      // if(value==1){
      //   this.props.setRepeatReminderValue("once")
      // }
  }

  OnChangeStartTime = (e)=>{
    if(this.state.day !=null && this.state.month!=null && this.state.year!=null){
      this.formatTime();
      let time = moment(e).format("HH:mm")+":"+"00";
      this.props.setRemindTime(time);
      this.setState({
        remindTime : moment(e).format("HH:mm:ss")
      })
    }
  }

  formatTime = ()=>{
    let {day,month,year,remindTime} = this.state;
    let m = month;
    if(m/10<1){
      m=parseInt(m)+1;
      m = "0"+m
    }
    let d= day;
    if(d/10<1){
      d= parseInt(d)+1;
      d="0"+d
    }

    let remindDate = (year+"-"+m+"-"+d);
    // let endDate = (endYear+"-"+em+"-"+ed+" "+endTime);
    // this.setState({
    //   remindDate
    // })
    this.props.setRemindDate(remindDate);
  }


  onClickHandler = (value)=>{
    this.setState({
      value
    })
    // alert("value is "+value);
  }
  handleToggle = (isOn)=>{
    this.setState({
      isOn: !this.state.isOn
    })
  }

  getCampusPtMembers = async ()=>{
    let ptMemberList = [];
    try{
      let params = new URLSearchParams();
       params.append("campus_id",this.props.college_id);
      params.append("role_type",CONSTANTS.ROLE_TYPES_ID["PT"]) ;

      let ptMembers = await Auth.Spoc.getUsers(params);
      if(ptMembers.data.length!=0){
        let finalList = ptMembers.data.filter(member=>member.user_id!=this.props.user_id);
        this.setState({
          ptMember: finalList
        })
      }
    }
    catch(e){
      this.setState({errorbar: true});
      if(e.response && e.response.data){
        this.setState({message: e.response.data.error.toString()});
      }
      else{
        this.setState({message: 'Something went wrong! Please try again'});
      }
    }
  }

  addPtMember = async (ptMember)=>{
    let {ptMemberList, ptMemberSelected, deletePtList} = this.state;
    let flag=0;
    for(let i=0;i<ptMemberList.length;i++){
      if(ptMemberList[i].user_id==ptMember.user_id){
        flag=1
      }
    }
    if(flag==0){
      ptMemberList.push(ptMember);
    }

    if(deletePtList.length!=0){
      for(let i=0;i<deletePtList.length; i++){
        if(deletePtList[i].user_id == ptMember.user_id){
          deletePtList.splice(i,1);
        }
      }
    }
    this.props.setPtMemberList(ptMemberList, ptMemberSelected, deletePtList);
    this.props.setTotalPtMembers(ptMemberList.length);
    await this.setState({
      ptMemberList,
      deletePtList
    })
    // await this.filterPtMemberEmail();
    // this.props.setPtMemberMails(this.state.ptMemberMails);
  }

  deleteptMember = async (ptMember)=>{
    let list = [];
    let {ptMemberList, ptMemberSelected , deletePtList} = this.state;
    for(let i =0;i<ptMemberList.length;i++){
      if(ptMemberList[i].user_id != ptMember.user_id){
        list.push(ptMemberList[i])
      }
    }
    if(ptMemberSelected.length!=0){
      for(let i =0;i<ptMemberSelected.length ; i++){
        if(ptMember.user_id == ptMemberSelected[i].user_id){
          deletePtList.push(ptMember);
        }
      }
    }

    this.props.setPtMemberList(list, ptMemberSelected, deletePtList);
    this.props.setTotalPtMembers(list.length);
    await this.setState({
      ptMemberList : list,
      deletePtList
    })
    // await this.filterPtMemberEmail();
    // this.props.setPtMemberMails(this.state.ptMemberMails);
  }

  getMeetingAttendees = async ()=>{
    if(this.props.meetingId!=null){

      let ptList = [];
      let params = {
        "meeting_id" : this.props.meetingId,
        "attendee_type": "secondary"
      }
      let attendeeList = []
      try{
        let meetingAttendees =await  Auth.Batch.getAttendeesList(params);
        meetingAttendees.data.map(attendee=>{
          attendeeList.push(attendee.user_id);
        })
        if(attendeeList.length!=0){

          try{
            let params = {
              campus_id: this.props.college_id,
              role_type: CONSTANTS.ROLE_TYPES_ID["PT"]
            }

            let ptMembers = await Auth.Spoc.getUsers(params);
            for(let i=0;i<attendeeList.length;i++){
              ptMembers.data.map(member=>{
                if(member.user_id==attendeeList[i]){
                  ptList.push(member);
                }
              })
            }
            this.setState({
              ptMemberList : [...ptList],
              ptMemberSelected : [...ptList]
            })
          }
          catch(e){
            this.setState({errorbar: true});
            if(e.response && e.response.data){
              this.setState({message: e.response.data.error.toString()});
            }
            else{
              this.setState({message: 'Something went wrong! Please try again'});
            }
          }
        }
      }
      catch(e){
        this.setState({errorbar: true});
        if(e.response && e.response.data){
          this.setState({message: e.response.data.error.toString()});
        }
        else{
          this.setState({message: 'Something went wrong! Please try again'});
        }
      }
    }
  }

  handleReminderDropdown = (e)=>{
    this.setState({
      remindDropdownValue : e.target.value
    })
  }

  filterPtMemberEmail = async()=>{
    let {ptMemberList} = this.state;
    let {ptMemberMails} = this.state;
    if(ptMemberList!=undefined){
      ptMemberList.map(email=>{
        ptMemberMails.push(email.userDetail.email);
      })
    }
    await this.setState({
      ptMemberMails
    })
  }

  async componentDidMount(){
    await this.getMeetingAttendees();
    this.getCampusPtMembers();
  }

  render(){
    return(
      <Aux>
      <Loader show={this.state.loading} />
      <Row className="m-1 mt-4 pt-1">
      <Col xs="10" onClick={()=>this.onClickHandler(1)}>
      <span className="r-heading-4">1. Add PT members</span>
      </Col>
      <Col xs="2" align="end">
      {this.state.value==1?<i className="fi flaticon-arrow-q"/> : <i className="fi flaticon-arrow"/>}
      </Col>
      </Row>
      <Collapse  isOpen={this.state.value==1} className="mb-2">
      <Row>
      <Col xs="6" className="mr-0 pr-0">
      <Card className="border-0 r-bg--grey-2 m-1 ml-3 mr-0 pr-0 p-2 mb-4 calendar__primary-card">
      <CardTitle>
      <span className="r-text--dark r-subheading-3">All members</span>
      </CardTitle>
      <CardBody className="p-2 mt-1">
      {this.state.ptMember!=null?this.state.ptMember.map(ptMember=>{
        return(
          <Row className="m-1 mb-2 p-1">
          <Button className="calendar__pt-member-button r-text--grey-4 w-100 r-subheading-4" onClick={()=>this.addPtMember(ptMember)}><Row><Col xs="10" className="m-1" align="start"><span>{ptMember.userDetail.first_name}{" "}{ptMember.userDetail.last_name}{" "} (Placement Team) </span></Col><Button color="link"><Col xs="2" className="p-0 m-0" align="end"><i className="fi flaticon-plus-2"/></Col></Button></Row></Button>
          </Row>
        )
      }):null}

      </CardBody>
      </Card>
      </Col>
      <Col xs="6" className="pl-0 ml-0">
      <Card className="border-0 r-bg--grey-2 m-1 ml-3 p-2 mb-4 calendar__primary-card">
      <CardTitle>

      <Col  className="mb-2"><span className=" r-text--dark r-subheading-3"> Selected </span> <span className="r-text--green-1 r-subheading-4 ml-2">{this.state.ptMemberList.length} Participants selected </span></Col>

      </CardTitle>
      <CardBody className="pt-0">
      {this.state.ptMemberList.length!=0?this.state.ptMemberList.map(ptMember=>{
        return(
          <Row className="m-1 mb-2 p-1">
          <Button className="calendar__pt-member-button r-text--grey-4 w-100 r-subheading-4"><Row><Col xs="10" className="m-1 pr-0" align="start"><span>{ptMember.userDetail.first_name}{" "}{ptMember.userDetail.last_name}{" "} (Placement Team)</span></Col><Col xs="1" className="p-0 m-0 border-0 mt-1"  align="end" onClick={()=>this.deleteptMember(ptMember)}><i className="fi flaticon-plus-3"/></Col></Row></Button>
          </Row>
        )
      }):null
    }
      </CardBody>
      </Card>
      </Col>
      </Row>
      </Collapse>
      {/*
      <HorizontalLine color="#f5f5f5" />
      <Row className="m-1 mt-4 mb-4">
      <Col xs="10">
      <Row>
      <span className="r-heading-4 ml-4">2. </span>
      <Col xs="1"  className="px-4"><b>
      <img className="img-fluid" src="/images/icons/nav_notifications.png" alt="Reminder" /></b>
      </Col>
      <div className="r-dropdown border-0">
        <select onChange={this.handleReminderDropdown}  className="r-dropdown__select r-subheading-4 calendar__description-box r-text--grey-4" ref="remind_type">
        <option value="1">Select</option>
        <option value="2">Set Reminder</option>
        </select>
        <i className="fi flaticon-arrow"/>
        </div>
      </Row>
      </Col>
      <Col xs="2" align="end">
      {this.state.value==2?<i className="fi flaticon-arrow-q"/> : <i className="fi flaticon-arrow"/>}
      </Col>
      </Row>
      <Collapse className="mt-3"  isOpen={this.state.remindDropdownValue==2}>
      <Card className="border-0 m-1 ml-3 mb-4">
      <Row ><Col className="mt-2 ml-3"><span className="mt-2 r-subheading-3"> Set Reminder Start Date And Time</span></Col></Row>
      <div>
      <Row>

      <Col>
      <div className="mt-2">
      <span>
      <YearPicker
                   defaultValue={'Year'}
                   // default is 1900
                   start={2010}
                   // default is current year
                   end={2020}
                   // default is ASCENDING
                   reverse
                   // default is false
                   required={true}
                   // default is false
                   disabled={false}
                   // mandatory
                   value={this.state.year}
                   // mandatory
                   onChange={(year) => {
                       this.setState({ year });
                       // console.log(year);
                   }}
                   id={'year'}
                   name={'year'}
                   classes={'classes border-0 calendar__description-box r-text--grey-4 r-subheading-4 p-2'}
                   optionClasses={'option classes'}
               />
               </span>
                  <span>
                  <MonthPicker
                    defaultValue={'select month'}
                    // to get months as numbers
                    numeric
                    // default is full name
                    short
                    // default is Titlecase
                    caps
                    // mandatory if end={} is given in YearPicker
                    endYearGiven
                    // mandatory
                    year={this.state.year}
                    // default is false
                    required={true}
                    // default is false
                    disabled={false}
                    // mandatory
                    value={this.state.month}
                    // mandatory
                    onChange={(month) => {
                        this.setState({ month });
                        // console.log(month);
                    }}
                    id={'month'}
                    name={'month'}
                    classes={'classes border-0 calendar__description-box r-text--grey-4 r-subheading-4 p-2'}
                    optionClasses={'option classes'}
                />
                </span>
                <span className="r-bg--grey border-0  r-text--grey-4 r-subheading-4">
                <DayPicker
                    defaultValue={'select day'}
                    // mandatory
                    year={this.state.year}
                    // mandatory
                    month={this.state.month}
                    // mandatory if end={} is given in YearPicker
                    endYearGiven
                    // default is false
                    required={true}
                    // default is false
                    disabled={false}
                    // mandatory
                    value={this.state.day}
                    // mandatory
                    onChange={(day) => {
                        this.setState({ day });
                        // console.log(day);
                    }}
                    id={'day'}
                    name={'day'}
                    classes={'classes border-0 calendar__description-box r-text--grey-4 r-subheading-4 p-2'}
                    optionClasses={'option classes'}
                />
                </span>
                <span className="ml-2">
                <TimePicker placeholder="hh:mm:ss" onChange={this.OnChangeStartTime} />
                </span>
                </div>
               </Col>
      </Row>
      <div className="mt-4 ml-1">
      <Row>
      <Col xs="auto">
      <label class="radio-inline"><input type="radio"  name="optradio" onChange={()=>this.setReminderValue(1)}   /><span className="ml-2 r-subheading-4 r-text--grey-4">Only Once</span></label>
      </Col>
      <Col xs="auto">
      <label class="radio-inline"><input type="radio" name="optradio" onChange={()=>this.setReminderValue(2)}   /><span className="ml-2 r-subheading-4 r-text--grey-4">Repeat</span></label>
      </Col>
      </Row>
      </div>
      <Row>
      <Col xs="3">
        <Collapse isOpen={this.state.reminderValue==2}>
        <div className="r-dropdown border-0 mt-4 mb-4">
        <select  className="r-dropdown__select r-subheading-4 calendar__description-box r-text--grey-4" ref="remind_type"  onChange={this.handleReminderTrigger} >
        <option value="">Select</option>
        <option value="monthly">Monthly</option>
        <option value="weekly">Weekly</option>
        <option value="daily">Daily</option>
        </select>
        <i className="fi flaticon-arrow"/>
        </div>
        </Collapse>

      </Col>
      </Row>
      </div>
      </Card>
      </Collapse>
      <HorizontalLine color="#f5f5f5" />  */}
      <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      </Aux>
    )
  }
}

export default MeetingPrimarySection;
