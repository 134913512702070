import React,{Component} from 'react';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container} from 'reactstrap';
import {Button} from 'reactstrap';
import moment from 'moment';
import  {InputField} from '../../global/Forms';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import MeetingEditParticipants from './meeting_edit_participants';
import Aux from '../../global/AuxComp';
import Link from 'react-router-dom/Link';
import {Errorbar} from '../../global/errorbar';
import {Modal,  ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Loader } from '../../global/Loader';
import { connect } from 'react-redux';
import { RTE } from '../../global/Forms/RTE';
import { RTE2 } from '../../global/Forms/RTE-div';

class Meeting extends Component{
  constructor(props){
    super(props);
    this.state={
      loading : false,
      modal:false,
      totalPtMemebers : 0,
      totalCompanyMemebers : 0,
      value: 1,
      title: null,
      description : null,
      location: null,
      meetingId : null,
      year: null,
      month: null,
      day: null,
      endYear:null,
      endMonth: null,
      endDay: null,
      startTime: moment(),
      endTime: moment(),
      start : new Date(),
      end: new Date(),
      meetingType : null,
      startDate: null,
      endDate : null,
      params : null,
      ptMemberMails:[],
      companyCollection : {},
      companyMails : [],
      body: null,
      subject : null,
      userId : this.props.user.id,
      companyList:[],
      selectedCompany : this.props.location.search ? new URLSearchParams(this.props.location.search).get("campus_company_id") : null,
      contactList : [],
      university_id : this.props.role.instituteDetail.university_id,
      college_id : this.props.role.instituteDetail.college_id,
      campus_id : this.props.role.instituteDetail.campus_id,
      deleteMeetingModal : false,
      errorbar: false,
      ptMemberList : [],
      message:'',
      ptMemberSelected : [],
      deletePtList : [],
      contactSelected : [],
      deleteContactList : []
    }
  }

  async componentDidMount(){
    await this.getMeetings();
     this.getCompanyList();
  }

  changeEnd = (end) => {
    this.setState({
      end : moment(end).format('YYYY-MM-DD')
    })
  }

  changeStart = (start) => {
    this.setState({
      start : moment(start).format('YYYY-MM-DD')
    })
  }

  toggleLoading = (loading) =>{
    this.setState({
      loading
    })
  }

  handleToggle=()=>{
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }


  setTotalPtMembers = (totalPtMemebers)=>{
    this.setState({
      totalPtMemebers
    })
  }
  setPtMemberMails = (ptMemberMails)=>{
    this.setState({
      ptMemberMails
    })
  }

  setTotalCompanyMembers = (totalCompanyMemebers)=>{
    this.setState({
      totalCompanyMemebers
    })
  }

  getMeetings = async ()=>{
    if(this.props.match.params.meeting_id){
      // var params = new URLSearchParams();
      // params.append("access_key",this.props.role.access_key);
      // params.append("meeting_id", this.props.match.params.meeting_id)
      var body = {
        access_key : this.props.role.access_key,
        meeting_id : this.props.match.params.meeting_id
      }
      try{

        let meeting = await Auth.Batch.getMeetings(body);
        this.setState({
          meetingId : meeting.data[0].meeting_id,
          title: meeting.data[0].meeting_title,
          description : meeting.data[0].meeting_description,
          location: meeting.data[0].location,
          meetingType : meeting.data[0].meeting_type,
          start : moment(meeting.data[0].start_date).format('YYYY-MM-DD'),
          end: moment(meeting.data[0].end_date).format('YYYY-MM-DD'),
          startTime : moment(meeting.data[0].start_date),
          endTime : moment(meeting.data[0].end_date),
          selectedCompany : meeting.data[0].campus_company_id
        })
        await this.formatTime();
      }
      catch(e){
        this.setState({errorbar: true});
        if(e.response && e.response.data){
          this.setState({message: e.response.data.error.toString()});
        }
        else{
          this.setState({message: 'Something went wrong! Please try again'});
        }
      }
    }
  }

  deleteMeeting = async (meetingId)=>{
    this.toggleLoading(true);
    let params = {
      meeting_id : meetingId,
      status : 0
    }
    try{
      let deleteSingleMeeting = await Auth.Batch.deleteMeeting(params);
      this.props.history.push("/calendar");
    }
    catch(e){
      this.setState({errorbar: true});
      if(e.response && e.response.data){
        this.setState({message: e.response.data.error.toString()});
      }
      else{
        this.setState({message: 'Something went wrong! Please try again'});
      }
    }
    this.toggleLoading(false);
  }

  setPtMemberList = (list, ptMemberSelected, deletePtList)=>{
    this.setState({
      ptMemberList:list,
      ptMemberSelected,
      deletePtList
    })
  }

  setRemindDate = (date)=>{
    this.setState({
      remindDate: date
    })
  }

  setRemindTime = (time)=>{
    this.setState({
      remindTime : time
    })
  }

  setRepeatReminderValue = (reminderValue)=>{
    this.setState({
      reminderValue
    })
  }

  setReminder = ()=>{
    let {ptMemberList} = this.state;
    if(this.state.title!=null && this.state.description!=null){
      let remindTo = [];
      ptMemberList.map(ptMember=>{
        remindTo.push(ptMember.user_id);
      })
      let params = {
        "remind_through":"notification",
        "subject": this.state.title,
        "remind_time":this.state.remindTime ,
        "recurring_type":this.state.reminderValue,
        "notes":this.state.description,
        "remind_to":remindTo,
        "remind_date":this.state.remindDate
      }
      try{
        let create = Auth.Batch.createReminder(params);
      }
      catch(e){
        this.setState({errorbar: true});
        if(e.response && e.response.data){
          this.setState({message: e.response.data.error.toString()});
        }
        else{
          this.setState({message: 'Something went wrong! Please try again'});
        }
      }
    }
  }

  setContactList = (contactList, contactSelected, deleteContactList)=>{
    this.setState({
      contactList, contactSelected, deleteContactList
    })
  }

  getCompanyList =async  ()=>{
    try{
      let params = new URLSearchParams();
      params.append("access_key",this.props.role.access_key);
      params.append("campus_id",this.props.user.current_role_info.campus_id);
      let companyList = await Auth.Batch.getCompanies(params);
      this.setState({
        companyList : companyList.data
      })
    }
    catch(e){
      this.setState({errorbar: true});
      if(e.response && e.response.data){
        this.setState({message: e.response.data.error.toString()});
      }
      else{
        this.setState({message: 'Something went wrong! Please try again'});
      }
    }

  }

  handleCompanychange= (e)=>{
    this.setState({
      selectedCompany: e.target.value
    })
  }

  closebar = () =>{
      this.setState({errorbar: false});
  }

  titleOnChange = (e)=>{
    this.setState({
      title: e.target.value
    })
  }
  descriptionOnChange = (value)=>{
    this.setState({
      description: value
    })
  }
  locationOnChange=(e)=>{
    this.setState({
      location: e.target.value
    })
  }
  meetingTypeOnchange = (e)=>{
    this.setState({
      meetingType: e.target.value
    })
  }
  startTimeOnChange = (e)=>{
    this.setState({
      startTime : moment(e)
    })
  }
  endTimeOnChange = (e)=>{
    this.setState({
      endTime : moment(e)
    })
  }

  formatTime = ()=>{
    let {start,end,startTime,endTime,} = this.state;
    let startDate = (moment(start).format('YYYY-MM-DD')+" "+moment(startTime).format('HH:mm:ss'));
    let endDate = (moment(end).format('YYYY-MM-DD')+" "+moment(endTime).format('HH:mm:ss'));

    this.setState({
      startDate,
      endDate
    })
  }

  createAttendee = async (meetingId)=>{
    let attendeeParams = {
      "meeting_attendees": []
    }

    let {ptMemberList, ptMemberSelected, contactList , contactSelected} = this.state
    if(contactList.length!=0 && contactSelected.length==0){
      contactList.map(contact=>{
        attendeeParams.meeting_attendees.push({"meeting_id":meetingId,"attendee_type":"contact","user_id":contact.contact_id})

      })
    }

    if(ptMemberList.length!=0 && ptMemberSelected.length==0){
      ptMemberList.map(ptMember=>{
        attendeeParams.meeting_attendees.push({"meeting_id":meetingId,"attendee_type":"secondary","user_id":ptMember.user_id})

      })
    }

    if(ptMemberList.length!=0 && ptMemberSelected.length!=0){
      for(let i =0;i<ptMemberList.length ; i++){
        let ptMember = ptMemberSelected.filter(ptMember=>ptMember.user_id == ptMemberList[i].user_id);
        if(ptMember.length==0){
          attendeeParams.meeting_attendees.push({"meeting_id": meetingId, "attendee_type" : "secondary" , "user_id" : ptMemberList[i].user_id})
        }
      }

    }

    if(contactList.length!=0 && contactSelected.length!=0){
      for(let i =0; i<contactList.length ; i++){
        let contact = contactSelected.filter(contacts=>contacts.contact_id == contactList[i].contact_id);
        if(contact.length==0){
          attendeeParams.meeting_attendees.push({"meeting_id": meetingId, "attendee_type" : "contact" , "user_id" : contactList[i].contact_id})
        }
      }
    }

    if(attendeeParams.meeting_attendees.length!=0){
      try{
        let createAttendee  = await Auth.Batch.createMeetingAttendee(attendeeParams);
      }
      catch(e){
        this.setState({errorbar: true});
        if(e.response && e.response.data){
          this.setState({message: e.response.data.error.toString()});
        }
        else{
          this.setState({message: 'Something went wrong! Please try again'});
        }
      }
    }
  }

  createMeeting = async ()=>{
    this.toggleLoading(true);
    this.handleToggle();
    await this.formatTime();
    let {startDate , endDate,description,title,location,meetingType} = this.state;
    if(title!=null && description!=null && meetingType!=null && location!=null && startDate!=null && endDate!=null && startDate <= endDate){
      let params ={
      "access_key": this.props.role.access_key,
      "campus_company_id": this.state.selectedCompany,
      "meeting_type": this.state.meetingType,
      "meeting_title":this.state.title,
      "location": this.state.location,
      "start_date": moment(this.state.startDate).utc(false).format("YYYY-MM-DD HH:mm:ss"),
      "end_date": moment(this.state.endDate).utc(false).format("YYYY-MM-DD HH:mm:ss"),
      "meeting_description": this.state.description,
      "meeting_category" : "Meeting"
      }
      let collection = {};
      try{

        let companyCollection = await Auth.Batch.createMeetings(params)
        collection = companyCollection
        let attendeeParams = {
          "meeting_attendees": [
            {
              "meeting_id": collection.data.meeting_details[0].meeting_id,
              "attendee_type": "primary",
              "user_id": this.state.userId
            }
          ]
        }
        try{
          let createAttendee  = await Auth.Batch.createMeetingAttendee(attendeeParams);
        }
        catch(e){
          this.setState({errorbar: true});
          if(e.response && e.response.data){
            this.setState({message: e.response.data.error.toString()});
          }
          else{
            this.setState({message: 'Something went wrong! Please try again'});
          }
        }

        await this.setState({
          params,
          companyCollection : collection.data.meeting_details[0],
          value: 1,
          title: null,
          description : null,
          location: null,
          year: null,
          month: null,
          day: null,
          endYear:null,
          endMonth: null,
          endDay: null,
          startTime: null,
          endTime:null,
          meetingType : null
        })

        await this.createAttendee(collection.data.meeting_details[0].meeting_id)
        this.props.history.push("/calendar")
      }
      catch(e){
        this.setState({errorbar: true});
        if(e.response && e.response.data){
          this.setState({message: e.response.data.error.toString()});
        }
        else{
          this.setState({message: 'Something went wrong! Please try again'});
        }
      }
    }
    else{
      this.setState({errorbar: true});
      this.setState({message: "Fill all the details to create meeting or Incorrect time alloted"});
    }
    this.toggleLoading(false);
  }

  onClickHandler =(value)=>{
    this.setState({
      value
    })
  }

  changeTab = ()=>{
    this.setState({
      value : 2
    })
  }
  updateMeeting = async (meetingId)=>{
    this.toggleLoading(true);
    this.handleToggle();
    let params ={
      "meeting_id":meetingId,
    "access_key": this.props.role.access_key,
    "campus_company_id": this.state.selectedCompany,
    "meeting_type": this.state.meetingType,
    "meeting_category" : "Meeting",
    "meeting_title":this.state.title,
    "location": this.state.location,
    "meeting_description": this.state.description
    }
    await this.formatTime()
    let {title , selectedCompany , meetingType , location , description , startDate , endDate} = this.state;
    if(title && selectedCompany && meetingType && location && description && startDate  && endDate && (startDate<=endDate)){
    params.start_date =  moment(this.state.startDate).utc(false).format('YYYY-MM-DD HH:mm:ss');
    params.end_date = moment(this.state.endDate).utc(false).format('YYYY-MM-DD HH:mm:ss');
    try{
      let updateMeeting = await Auth.Batch.updateMeetingDetails(params);
      if(this.state.contactList.length!=0 || this.state.ptMemberList.length!=0){
        await this.createAttendee(meetingId);
      }
      await this.inactiveMeetingAttendees();
        this.props.history.push("/calendar");
    }
    catch(e){
      this.setState({errorbar: true});
      if(e.response && e.response.data){
        this.setState({message: e.response.data.error.toString()});
      }
      else{
        this.setState({message: 'Something went wrong! Please try again'});
      }
    }
  }
  else{

    this.setState({errorbar: true});
    this.setState({message: 'Fill in all the details or incorrect date and time entered'});
  }
    this.toggleLoading(false);
  }

inactiveMeetingAttendees =async ()=>{
  if(this.props.match.params.meeting_id){
    let {deleteContactList , deletePtList} = this.state;
    if(deletePtList.length!=0){
      for(let i=0; i<deletePtList.length ; i++){
        let params = {
          meeting_id: this.props.match.params.meeting_id,
          "attendee_type": "secondary",
          user_id : deletePtList[i].user_id,
          status: 0
        }
        try{
          let deleteAttendee = await Auth.Batch.deleteMeetingAttendee(params);
        }
        catch(e){
          this.setState({errorbar: true});
          if(e.response && e.response.data){
            this.setState({message: e.response.data.error.toString()});
          }
          else{
            this.setState({message: 'Something went wrong! Please try again'});
          }
        }

      }
    }

    if(deleteContactList.length!=0){
        for(let i=0; i<deleteContactList.length ; i++){
          let params = {
            meeting_id: this.props.match.params.meeting_id,
            "attendee_type": "contact",
            user_id : deleteContactList[i].contact_id,
            status: 0
          }
          try{
            let deleteAttendee = await Auth.Batch.deleteMeetingAttendee(params);
          }
          catch(e){
            this.setState({errorbar: true});
            if(e.response && e.response.data){
              this.setState({message: e.response.data.error.toString()});
            }
            else{
              this.setState({message: 'Something went wrong! Please try again'});
            }
          }
        }
      }
    }
  }

  onChangeEndTime = (e)=>{
    this.setState({
      endTime : moment(e).format("HH:mm:ss")
    })
  }

  OnChangeStartTime = (e)=>{
    this.setState({
      startTime : moment(e).format("HH:mm:ss")
    })
  }

  render(){
    console.log(this.props)
    return(
      <Container >
      <Loader show={this.state.loading} />
      <Row>
      <Col xs="1.5" className="p-0 m-4 mr-0" align="start"><span className="r-subheading-1 p-0 m-3" >Calendar</span></Col>
      <Col xs="6" className="p-0 m-0 mt-4 ml-4"><InputField className="m-1" placeholder=" *Meeting Title" className="w-100 border-0 rounded m-1" onChange={this.titleOnChange} value={this.state.title} /></Col>
      <Col xs="1" className="m-2 mt-4  ">
      {this.props.match.params.meeting_id && (this.props.location.state &&  this.props.location.state.created_by == this.props.user.id)?
      <Button type="submit" className="calendar__save-button m-2 ml-5" color="dark" onClick={this.handleToggle}><span className="r-subheading-4 pl-3 pr-3">Save</span></Button> : null}
      {!this.props.match.params.meeting_id?
            <Button type="submit" className="calendar__save-button m-2 ml-5" color="dark" onClick={this.handleToggle}><span className="r-subheading-4 pl-3 pr-3">Save</span></Button>:null}
      <Modal isOpen={this.state.modal} toggle={this.handleToggle} className="calendar__invitation-dialog">
        <ModalBody>
        <span className="r-subheading-4">
          Would you like to save this Meeting?
          </span>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" className=" m-2 ml-5"  onClick={this.props.match.params.meeting_id!=null?()=>this.updateMeeting(this.props.match.params.meeting_id):()=>this.createMeeting()}><span className="r-subheading-4 pl-3 pr-3 rounded">Save</span></Button>{' '}
          {/*<Button  onClick={()=>this.saveAndPublish()} className="ml-3 pr-4 pl-4">Send</Button>  */}
          <Button  onClick={this.handleToggle} className="ml-3 pr-4 pl-4">Cancel</Button>
        </ModalFooter>
      </Modal>
      </Col>

      {this.props.match.params.meeting_id!=null && (this.props.location.state && this.props.location.state.created_by == this.props.user.id)?<Col xs="1" className="m-2 mt-4 p-0">
    <Button type="submit" className="calendar__save-button m-2 ml-5" color="dark"><span className="r-subheading-4 pl-3 pr-3" onClick={()=> this.setState({deleteMeetingModal : !this.state.deleteMeetingModal})}>Delete</span></Button>
    </Col>:null}

      <Col xs="1" align="start" className="m-2 mt-4 pr-0">
      <Link to={"/calendar"} >
      <Button type="submit" className="calendar__discard-button m-2 " color="dark"><span className="r-subheading-4 pd-1">{this.props.match.params.event_id!=null &&  (this.props.location.state && this.props.location.state.created_by == this.props.user.id)?"Discard": this.props.match.params.meeting_id!=null?"Close":"Discard"}</span></Button>
      </Link>
      </Col>
      </Row>
      <Modal isOpen={this.state.deleteMeetingModal} zIndex="99999" size="md">
      <ModalHeader toggle={()=>this.setState({deleteMeetingModal : !this.state.deleteMeetingModal})}>Are you sure you want to delete?
      </ModalHeader>
      <ModalFooter>
      <Button  className="pr-4 pl-4  r-text--light border-0 r-subheading-4" color="dark"  onClick={()=>this.deleteMeeting(this.props.match.params.meeting_id)}>Delete</Button>
        <Button  className="pr-4 pl-4 r-bg--grey-2 r-text--grey-4 border-0 r-subheading-4"   onClick={() => this.setState({ deleteMeetingModal : !this.state.deleteMeetingModal})}>Cancel</Button>
      </ModalFooter>
      </Modal>
      <Row>

      <Col xs="2">
      </Col>
        <Aux>
        <Col xs="6" className="p-0 pl-1">

          <div>
        <Row>
        <Col className="m-1 pl-0 pr-3 mr-4 mt-2 calendar__event-description-box">
        <RTE2 onChange={(value)=>this.descriptionOnChange(value)}  hideToolbar={true} placeholder="*Type Description here" value={this.state.description} />
        </Col>
        </Row>
        <Row>
        <Col  className="p-0 pr-5 mt-5">
        {this.state.selectedCompany && <MeetingEditParticipants user_id={this.props.user.id}  role_type={this.props.role.role_type} university_id={this.props.role.instituteDetail.university_id} college_id={this.props.role.instituteDetail.campus_id} campus_id={this.props.role.instituteDetail.campus_id} setPtMemberMails={this.setPtMemberMails} setTotalPtMembers={this.setTotalPtMembers} setTotalCompanyMembers={this.setTotalCompanyMembers} meetingId={this.props.match.params.meeting_id || null} setPtMemberList={this.setPtMemberList}  setContactList={this.setContactList} params={this.state.params} selectedCompany={this.state.selectedCompany} />}
        </Col>
        </Row>
        </div>
        </Col>
        <Col xs="4" className={this.state.value==1?"":"d-none"}>
        <div>
        <Row>
        <Col className="m-1">
        <span className="r-subheading-4">
        <span className="r-text--red-1">*</span>Location
        </span>
        </Col>
        </Row>
        <Row>
        <Col className="m-1">
        <span className="r-subheading-4">
        <InputField  className=" m-1 w-100 border-0 rounded m-1"  placeholder="Type in the location..." onChange={this.locationOnChange} value={this.state.location} />
        </span>
        </Col>
        </Row>
        </div>
        <div>
        <Row className="mt-2">
        <Col className="m-1 ">
        <span className="r-subheading-4">
        <span className="r-text--red-1">*</span>Select Company
        </span>
        </Col>
        </Row>
        <Row>
        <Col className="m-1">
        <div className="r-dropdown border-0">
        <select  className="r-dropdown__select r-subheading-4 calendar__description-box r-text--grey-4" onChange={this.handleCompanychange} value={this.state.selectedCompany}>
        <option value="">Select</option>
        {
          this.state.companyList.length!=0?this.state.companyList.map(company=>{
            return(
              <option value={company.campus_company_id}> {company.company_details.company_name}  </option>
            )}
          ):null
        }

        </select>
        <i className="fi flaticon-arrow"/>
        </div>
        </Col>
        </Row>
        </div>


        <div>
        <Row className="mt-3">
        <Col className="m-1">
        <span className="r-subheading-4">
        <span className="r-text--red-1">*</span>Select Meeting Type
        </span>
        </Col>
        </Row>
        <Row>
        <Col className="m-1">
        <div className="r-dropdown border-0">
        <select  className="r-dropdown__select r-subheading-4 calendar__description-box r-text--grey-4"  onChange={this.meetingTypeOnchange} value={this.state.meetingType}>
        <option>Select</option>
        <option value="Audio Call">Audio Call </option>
        <option value="Video Call">Video Call </option>
        <option value="In-Person"> In Person </option>
        </select>
        <i className="fi flaticon-arrow"/>
        </div>
        </Col>
        </Row>
        </div>
        {this.state.value==1?<div>
        <Row>
        <Col className="m-1 mt-5">
        <span className="r-subheading-4">
        <span className="r-text--red-1">*</span>Start Time
        </span>
        </Col>
        </Row>
        <Row>

        <Col className="pl-4 pr-0">

                 </Col>
        </Row>
        </div>:null}
        <Row>
        <Col xs="5" className="pr-0">
        <SingleDatePicker
        value={this.state.start}
        onDateChange={this.changeStart}
          id="dob" />
        </Col>
        <Col xs="7">
        <TimePicker  placeholder="hh:mm" minuteStep={5} showSecond={false} className="w-50"   onChange={this.startTimeOnChange} value={this.state.startTime}  allowEmpty={false}
        inputReadOnly
        format= "hh:mm a" use12Hours  />
        </Col>
        </Row>

        {this.state.value==1?
        <div>
        <Row>
        <Col className="m-1">
        <span className="r-subheading-4">
        <span className="r-text--red-1">*</span>End Time
        </span>
        </Col>
        </Row>
        <Row>

        <Col className="pl-4 pr-0">
                 </Col>
        </Row>
        </div>:null}
        <Row>
        <Col xs="5" className="pr-0">
        <SingleDatePicker
        value={this.state.end}
        onDateChange={this.changeEnd}

        id="dob"/>
        </Col>
        <Col xs="7">
      <TimePicker placeholder="hh:mm"  className="w-50" onChange={this.endTimeOnChange} value={this.state.endTime} minuteStep={5} showSecond={false}
      allowEmpty={false}
      inputReadOnly
      format= "hh:mm a" use12Hours />
        </Col>
        </Row>
        </Col>
        <Col xs="2">
        </Col>
         </Aux>
      </Row>
      <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(Meeting);
