import React,{Component} from 'react';
import {Col,Row, Container, Button} from 'reactstrap';
import Aux from '../../global/AuxComp';
import moment from 'moment';
import { PieChart, Pie, Sector,Tooltip , Cell,Legend,ResponsiveContainer, Line, BarChart,Bar, XAxis, YAxis,CartesianGrid, LineChart,      LabelList } from 'recharts';
import  CreatePost from './Feed/CreatePost';
var monthlist = ["Jan","Feb","Mar","Apr ","May","Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
var day = new Date().getDate();
var month = new Date().getMonth();
const likes = [
  {
    name: 'Likes',
    data: [
      { category: monthlist[month]+","+day, value: 0 },
      { category: monthlist[month]+","+(day+1), value: 5 },
      { category: monthlist[month]+","+(day+2), value: 7 },
      { category: monthlist[month]+","+(day+3), value: 2 },
      { category: monthlist[month]+","+(day+4), value: 8 },
      { category: monthlist[month]+","+(day+5), value: 4 },
      { category: monthlist[month]+","+(day+6), value: 4 },
    ],
  },
];

const views = [
  {
    name: 'Views',
    data: [
      { category: monthlist[month]+","+day, value: 0 },
      { category: monthlist[month]+","+(day+1), value: 10 },
      { category: monthlist[month]+","+(day+2), value: 5 },
      { category: monthlist[month]+","+(day+3), value: 5 },
      { category: monthlist[month]+","+(day+4), value: 24 },
      { category: monthlist[month]+","+(day+5), value: 13 },
      { category: monthlist[month]+","+(day+6), value: 18 },
    ],
  },
];

const data1 = [
  {
    Students: 'Finance', Interested: 4000, Expected: 2400, Actual: 3400,
  },
  {
    Students: 'Marketing', Interested: 4000, Expected: 2400, Actual: 3400,
  },
  {
    Students: 'Analytics', Interested: 4000, Expected: 2400, Actual: 3400,
  },
  {
    Students: 'Others', Interested: 4000, Expected: 2400, Actual: 3400,
  }
];

class Dashboard extends Component{
  constructor(props) {
    super(props);
    this.state={
      value:1
    }
  }

  render(){
    return(
      <Container>
      <Row>
      <Col className="m-5">
      <span className="r-subheading-1">Job Offers </span><span className="r-subheading-4 r-text--grey-4">(Expected vs Achieved)</span>
      </Col>
      </Row>
      <Row>
      <Col xs="12">
      <ResponsiveContainer width='100%' aspect={10.0/4.0}>
          <BarChart barGap={0} data={data1}  >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="Students" fill="#E4EA2A"  />
        <YAxis  stroke={0}  className="mr-5"/>
        <Tooltip />
        <Legend />
        <Bar barSize={15}  dataKey="Interested" fill="#E4EA2A"  className="m-0"/>
        <Bar barSize={15}  dataKey="Expected" fill="#C7E2DF"  className="m-0"/>
        <Bar barSize={15}  dataKey="Actual" fill="#1FACAC"  className="m-0"/>
        <Legend />
              {/*<Bar barSize={10} dataKey="Sectors" fill="#f4e242" />*/}
          </BarChart>
      </ResponsiveContainer>
      </Col>
      </Row>
      <div>
      <Row>
      <Col>
      <span className="r-subheading-1">Write a Post</span>
      </Col>
      </Row>
      </div>
      <Row>
      <Col xs="12" align="start">
      <CreatePost />
      </Col>
      </Row>
      <div>
      <Row>
      <Col xs="7" className="mt-5 mb-3" text-align="start">
      <span className="r-subheading-1">Post Statistics</span>
      </Col>
      <Col xs="5" className="mt-5 mb-3" text-align="start">
      <span className="r-subheading-1">Quick Links</span>
      </Col>
      </Row>
      <Row>
      <Col xs="7"  style={{backgroundColor:"#0066CC"}}>
          <Row >
          <Col xs="auto" className="m-2 ml-3">
             <Row className="post px-2" style={{backgroundColor:"#799fea"}}>
                <i className="mr-3 r-text--sm r-text--skyblue fi flaticon-eye" style={{fontSize:"40px"}}/>
                <Col xs="auto">
                    <Row><span className="r-subheading-2 r-text--light">12 / 30</span></Row>
                    <Row> <span className="r-subheading-2 r-text--light">Total Views</span></Row>
                 </Col>
             </Row>
             </Col>
             <Col xs="auto" className="m-2 ml-3">
                <Row className="post px-2" style={{backgroundColor:"#799fea"}}>
                   <i className="mr-3 r-text--sm r-text--skyblue fi flaticon-eye" style={{fontSize:"40px"}}/>
                   <Col xs="auto">
                       <Row><span className="r-subheading-2 r-text--light">12 / 30</span></Row>
                       <Row> <span className="r-subheading-2 r-text--light">Total Views</span></Row>
                    </Col>
                </Row>
                </Col>
                <Col xs="auto" className="m-2 ml-3">
                   <Row className="post px-2" style={{backgroundColor:"#799fea"}}>
                      <i className="mr-3 r-text--sm r-text--skyblue fi flaticon-eye" style={{fontSize:"40px"}}/>
                      <Col xs="auto">
                          <Row><span className="r-subheading-2 r-text--light">12 / 30</span></Row>
                          <Row> <span className="r-subheading-2 r-text--light">Total Views</span></Row>
                       </Col>
                   </Row>
                   </Col>
             <Col xs="auto" className="m-2 ml-3">

             <Row className="post px-2" style={{backgroundColor:"#799fea"}}>
                <i className="mr-3 r-text--sm r-text--skyblue fi flaticon-like" style={{fontSize:"40px"}}/>
                <Col xs="auto">
                    <Row><span className="r-subheading-2 r-text--light">1 / 15</span></Row>
                    <Row> <span className="r-subheading-2 r-text--light">Total Likes</span></Row>
                 </Col>
             </Row>
             </Col>
          </Row>
          <Col xs="auto" className=" my-3">
           <select  name="typeList" className="d-inline-block r-text--light my-3" style={{backgroundColor: "rgb(0, 102, 204)"}}  onChange={this.onChange}>
                <option  className="d-inline-block r-text--dark" value="Like">Like</option>
                <option  className="d-inline-block r-text--dark" value="View">View</option>
           </select>

                                <LineChart width={500} height={300}   >
                                    <CartesianGrid  vertical={false} verticalFill={[ '#799fea']} />
                                    <XAxis dataKey="category" stroke="#FFF" />
                                    <YAxis dataKey="value" stroke="#fff" />
                                    {views.map(s => (
                                    <Line stroke="#fff" dataKey="value" data={s.data} name={s.name} key={s.name}  dot={false} />
                                    ))}
                                </LineChart>
          </Col>
      </Col>
      <Col xs="5">
      <div border="1 px solide black">

      </div>
      </Col>
      </Row>
      </div>
      </Container>

    )
  }
}
export default Dashboard;
