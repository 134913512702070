import React,{Component} from 'react';
import { Collapse } from 'reactstrap';
import {  CardBody, CardTitle, CardText} from 'reactstrap';
import {Col,Row} from 'reactstrap';
import { connect } from 'react-redux';
import Aux from '../../global/AuxComp';
import {StatusButton} from './verifier_status_button';
import moment from 'moment';

class PersonalDetailCollapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
      };
    this.section = Math.ceil(Math.random() * 10000)
    this.popoverName = "POP-" + this.section
  }

  toggle = () => {
    this.props.toggleSection(this.section)
    this.props.getfileId(this.props.personalDetail[0].file);
  }


  render() {

    let {personalDetail,student_info} = this.props;
    let allCommentThread= this.props.getAllCommentThread!=null?(this.props.getAllCommentThread.filter(commentThread=>
       commentThread.profile_type=="PERSONAL" && commentThread.profile_id==personalDetail[0].student_id && commentThread.student_id==personalDetail[0].student_id &&commentThread.verifier_id==this.props.role.assigned_role_id)):null;
    return(
      personalDetail[0].student_id!=undefined  &&<Aux>
      <CardBody onClick={this.toggle}  className={"verifier__card-body  "+(this.section==this.props.collapse?"active":"")} >
      <CardTitle >
      <Row>
      <Col xs={10}>
      <span className="r-heading-6">{this.props.student_info.dob ?moment(this.props.student_info.dob).format("DD-MM-YYYY") : ''}</span>{' . '}
      <span className="r-heading-6">{this.props.student_info.gender!=null?this.props.student_info.gender : "" } </span>
      </Col>
      {personalDetail[0].file!=0? <Col xs={2} align="end">
          <span title="Proof Attached"><img src="/images/paper-clip@2x.png" alt="Proof" className="w-25" /></span>
      </Col> : null}
      </Row>
      </CardTitle>
      <CardText>
      <Row>
      <Col>
      <span className="r-heading-6"> {this.props.student_info.city}{this.props.student_info.state?' , ':null}{this.props.student_info.state}{this.props.student_info.country?' , ':null}{this.props.student_info.country}</span>
      </Col>
      </Row>
      </CardText>


      </CardBody>
      <Collapse  className="r-bg--blue-1" isOpen={this.props.collapse==this.section}>
      {personalDetail[0].student_id && <StatusButton allCommentThread={allCommentThread} student_id={personalDetail[0].student_id} id={personalDetail[0].student_id} profile_type="personal_detail" profileType="PERSONAL"   getStudentDetails={this.props.getStudentDetails}  popoverName={this.popoverName} data={personalDetail[0]} />}
      </Collapse>
      </Aux>
    );
  }
}

 const mapStateToProps = state => ({
   ...state
 })
export default connect(mapStateToProps, null)(PersonalDetailCollapse);
