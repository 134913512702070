import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Row,Col,Container,Collapse} from 'reactstrap';
import Button from '../../global/Button';
import {CONSTANTS} from '../../../constants';

import { InputField } from '../../global/Forms';
import { Select } from '../../global/Forms/Select';
import { Switch } from '../../global/Forms/ToggleSwitch';


  export default class ShowCourse extends Component{
    constructor(props){
       super(props);
         this.state = { isOn: false };
     }

	  handleToggle(e) {
  		this.setState({isOn: !this.state.isOn});
  	}

    render(){
      let { universityIndex, collegeIndex, campusIndex, courseIndex,campusData, courseData, modifyDetails, modifyObjDetails, deleteDetails, degreeList, openMembersModel } = this.props;
      var degree_name = degreeList.filter(cd => cd.degree_id === courseData.degree_id)[0];
      var role_count = courseData.role_count && Array.isArray(courseData.role_count) ? courseData.role_count : [];
      var societyCount = role_count.filter(cd => cd.role_type === CONSTANTS.ROLE_TYPES_ID.SOCIETY);
      var ptCount = role_count.filter(cd => cd.role_type === CONSTANTS.ROLE_TYPES_ID.PT);
      var studentCount = role_count.filter(cd => cd.role_type === CONSTANTS.ROLE_TYPES_ID.STUDENT);
      var spocCount = role_count.filter(cd => cd.role_type === CONSTANTS.ROLE_TYPES_ID.SPOC);
      var accessLevel_ids =  {'campus_degree_id' : courseData.campus_degree_id ,'campus_id' : campusData.campus_id, 'college_id' : campusData.college_id,'university_id' : campusData.university_id};

      return (

          <Row className="mt-3 align-items-center">
            <Col xs={5}>
              <Row className="align-items-center">
                <Col className="child-boundry-course  col-4"></Col>
                <Col xs={8}>
                { !courseData.isEditable ?
                  <h1 className="r-heading-6">{degree_name ? degree_name.degree_name : 'degree_name'}</h1>
                  :
                  <Select selected={degree_name ? degree_name.degree_name : 'degree_name'} onChange={(e) => modifyObjDetails(universityIndex, collegeIndex,campusIndex, courseIndex, 'degree_id', e.target.value)}   >
                    <option >Select Degree</option>
                    {
                      degreeList.map(degree => {
                        return (
                          <option  key={degree.degree_id} value={degree.degree_id}>{degree.degree_name}</option>
                            )
                        })
                    }
                  </Select>

                }
                </Col>
              </Row>
            </Col>
            <Col xs={7}>
            <Row className="align-items-center text-center">
            <Col xs={3}>
            {/* <Button onClick={() => openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.PT, accessLevel_ids)} color="link-blue">{ ptCount[0] ? ptCount[0].id_count : 0 } <span className="r-text--sm">Members <i className="d-inline-block fi flaticon-eye mr-2"></i></span></Button> */}
            </Col>
            {/* <Col xs={2}><Button onClick={() => openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.SOCIETY, accessLevel_ids)} color="link-blue">{ societyCount[0] ? societyCount[0].id_count : 0 } <span className="r-text--sm">Societies <i className="d-inline-block fi flaticon-eye mr-2"></i></span></Button></Col> */}
            <Col xs={2}><Button onClick={() => {return false; openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.STUDENT, accessLevel_ids)}} color="link">{ studentCount[0] ? studentCount[0].id_count : 0 }<span className="r-text--sm"> Students</span></Button></Col>
            <Col xs={3}>
            {/* <Button onClick={() => openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.SPOC, accessLevel_ids)} className="" color="link-blue">{ spocCount[0] ? spocCount[0].id_count : 0 } <span className="r-text--sm">Admin <i className="d-inline-block fi flaticon-eye mr-2"></i></span></Button> */}
            </Col>
            {/* <Col xs={1}><Switch  className="mt-3 d-inline-block" isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)} /></Col> */}
            <Col xs={4} className="text-right">
            <Button onClick={() => this.props.toggleDepartmentModal(courseData)} className="r-text--sm mt-1 mr-3" color="blue">Departments</Button>

              {/*  <Button onClick={() => modifyObjDetails(universityIndex, collegeIndex, campusIndex, courseIndex, 'isEditable', true)}  className="p-2"><i className="fi flaticon-edit"></i></Button>*/}
                <Button onClick={() => deleteDetails(universityIndex, collegeIndex, campusIndex, courseIndex)} className="p-2"><i className="fi flaticon-bin"></i></Button>
            </Col>
            </Row>
            </Col>

          </Row>


    )
  }
}
