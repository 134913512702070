import React,{Component} from 'react';
import {Row, Col, Container} from 'reactstrap';
import Link from 'react-router-dom/Link';
import { Auth } from '../../../actions/Auth';
import Modal from 'reactstrap/lib/Modal';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { InputField } from '../../global/Forms';
import { CONSTANTS } from '../../../constants';
import { Loader } from '../../global/Loader';
import { ToggleButton } from '../../global/Forms/ToggleButton';
import {Multifield} from '../../global/Forms/Multifield';
import Button from '../../global/Button';
import { RTE } from '../../global/Forms/RTE';

class EmailConfiguration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading : false,
      sendingEmailArray : [],
      sendingEmailBcc : [],
      sendingEmailCc : [],
      emailArray : {
        "APPLICATION_PUBLISH" : {Bcc : [], Cc : [] , status : 1},
        "APPLICATION_EDIT" : {Bcc : [], Cc : [] , status : 1},
        "SHORTLIST_RELEASE" : {Bcc : [], Cc : [], status : 1},
        "BLOCK_STUDENT" : {Bcc : [], Cc : [], status : 1},
        "OFFER" : {Bcc : [], Cc : [], status : 1},
        "FREEZE_STUDENT" : {Bcc : [], Cc : [], status : 1},
        "BLOCK_EVENT_MAILS" : {Bcc : [], Cc : [], status : 1},
        "FEEDS": {Bcc : [], Cc : [], status : 1},
        "APP_FEEDBACK" : {Bcc : [], Cc : [], status : 1},
        "SEND_TO_COMPANY"  : {Bcc : [], Cc : [], status : 1} ,
        "RECRUITER_INVITE": {Bcc : [], Cc : [], status : 1},
        "EMAIL_CAMPAIGN": {Bcc : [], Cc : [], status : 1},
      },
      confirmDetailModal : false,
      isOpenSignature : false
    };
    this.RTEProps = {
      modules : {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{'list': 'bullet'}],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{'letterspacing': ["-0.5px","0","0.5px"]}],
          [{ 'script': 'sub'}, { 'script': 'super' }],
          [{ 'color': [] }],
          ["link"]
        ],
      },
      formats : [
        'bold', 'italic', 'underline','list', 'bullet', 'header', 'color', 'align','letterspacing','script', "link", "background", "group", "image", "indent", "spacing", "code", "width", "height","style", "code-block"
      ]
    }
  }

  handleInputField = (e)=>{
    let {sendingEmailArray} = this.state;
    sendingEmailArray[e.target.name] = e.target.value;
    this.setState({
      sendingEmailArray
    })
  }

  updateValues = (id , type, emails)=>{
    let  {emailArray} = this.state;
    emailArray[id][type] = emails;
    this.setState({emailArray})
  }

  getConfigList = async()=>{
    this.setState({
      loading : true
    })
    let configDetails = await Auth.User.getConfiguration();
    if(configDetails.data.EMAILS && Object.keys(configDetails.data.EMAILS).length!=0){

      let {sendingEmailArray, emailArray} =  this.state;
      Object.keys(configDetails.data.EMAILS).map(config=>{
        if(emailArray[config]){
          emailArray[config].Bcc = configDetails.data.EMAILS[config].bcc;
          emailArray[config].Cc = configDetails.data.EMAILS[config].cc;
          emailArray[config].status = configDetails.data.EMAILS[config].status;
          emailArray[config].signature = configDetails.data.EMAILS[config].signature;
          emailArray[config].isLoggedInAsSender = configDetails.data.EMAILS[config].isLoggedInAsSender;
        }

        sendingEmailArray[config] = configDetails.data.EMAILS[config].from;

      })
    }

    this.setState({
      loading : false
    })
  }

  createConfiguration = async()=>{
    this.setState({
      loading : true
    })
    let {sendingEmailArray, emailArray} = this.state;
    await Promise.all(Object.keys(emailArray).map(async config=>{
        let data = {
          "module": "EMAILS",
          "feature": config,
          "config": {
           "from": sendingEmailArray[config],
           "cc":  emailArray[config].Cc,
           "bcc": emailArray[config].Bcc,
           "status" : emailArray[config].status,
           "signature" : emailArray[config].signature,
           "isLoggedInAsSender" : emailArray[config].isLoggedInAsSender
          }
        }

          try{
            await Auth.User.createConfiguration(data);
          }
          catch(e){
            console.log(e);
          }
    }))

    await this.getConfigList();
    this.setState({confirmDetailModal: false, isOpenSignature: false});
    this.setState({
      loading : false
    })
  }

  handleToggle = (configurationCategory, value)=>{
    let {emailArray} = this.state;
    emailArray[configurationCategory].status = value==true ? 1 : 0;
    this.setState({
      emailArray
    })
  }

   componentDidMount(){
     this.getConfigList();
  }

  render(){
    return(
      <Container >
      <Loader show={this.state.loading} />
      <div>
      <Row className="m-4"><Col>
      <span className="r-subheading-1">
      <Row>
      <Col xs="1">
      <Link to={"/pt/settings"}>
      <img src="/images/left-arrow.png" alt="back-button" height="30"  />
      </Link>
     </Col>
      <Col xs="auto" align="start">
      <span >Email Configurations </span>      <Button  className="pr-4 pl-4 ml-4  r-text--light border-0 r-subheading-4" style={{background: "#000"}} color="dark"  onClick={()=>this.setState({confirmDetailModal : !this.state.confirmDetailModal})}>Save</Button>

      </Col>
      </Row>
      </span>
      </Col></Row>
      <div>
      <Row className="p-2 border-bottom">
      <Col xs="2">
      <span className="r-heading-6">Actions</span>
      </Col>
      <Col xs="3">
      <span className="r-heading-6">Sender's Name</span>
      </Col>
      <Col xs="3">
      <span className="r-heading-6">Bcc</span>
      </Col>
      <Col xs="3">
      <span className="r-heading-6">Cc</span>
      </Col>
      <Col xs="1">
      <span className="r-heading-6">On/Off</span>
      </Col>
      </Row>
      <div className="mt-3">
      {Object.keys(CONSTANTS.CONFIGURATION_EMAILS).map(configurationCategory=>
      <>
        {"APPLICATION_PUBLISH" === configurationCategory && <h1 className="py-2 r-subheading-3 border-bottom mb-3">1. Student Settings</h1>}
        {"APP_FEEDBACK" === configurationCategory && <h1 className="py-2 r-subheading-3 border-bottom mb-3">2. Recruiter Settings</h1>}
        <Row className=" p-2">
          <Col xs="2">
            <span className="r-subheading-3 r-text--grey-4">{CONSTANTS.CONFIGURATION_EMAILS[configurationCategory]}</span>
          </Col>
          <Col xs="2">
            <InputField disabled={!!this.state.emailArray[configurationCategory].isLoggedInAsSender}  onChange={this.handleInputField} name={configurationCategory} value={this.state.sendingEmailArray[configurationCategory]} className="r-subheading-4 w-100 border-0" placeholder="Type name here" />
            <span className="r-text--grey-4" style={{fontSize: 10}}>
              <input type="checkbox" checked={!!this.state.emailArray[configurationCategory].isLoggedInAsSender} onChange={(e)=>this.updateValues(configurationCategory || [],"isLoggedInAsSender",e.target.checked ? 1 : 0)}/> 
              {" "} Use Logged-in users details as Sender Details
            </span>
          </Col>
          <Col xs={1}>
            <Button className="p-1" color={"blue"} style={{fontSize:10}} onClick={() => this.setState({isOpenSignature : configurationCategory})}>
              {this.state.emailArray[configurationCategory].signature ? 
              <i className="fi flaticon-edit mr-1"></i>
              :
              <i className="fi flaticon-plus-1 mr-1"></i>
              }
              Signature
            </Button>
          </Col>
          <Col xs="3" className="settings__email">
            <Multifield  values={this.state.emailArray[configurationCategory].Bcc?  this.state.emailArray[configurationCategory].Bcc : []} updateValues={(values)=>this.updateValues(configurationCategory || [],"Bcc",values)} name="Bcc" placeholder="Type and press enter" className="" />
          </Col>
          <Col xs="3" className="settings__email">
            <Multifield  values={this.state.emailArray[configurationCategory].Cc ? this.state.emailArray[configurationCategory].Cc : []} updateValues={(values)=>this.updateValues(configurationCategory || [] ,"Cc",values)} placeholder="Type and press enter" name="Cc" className="" />
          </Col>
          <Col xs="1">
            <ToggleButton buttonType="pt" values={{value1: "", value2 : !this.state.emailArray[configurationCategory].status?"Off":"On"}}  selected={!this.state.emailArray[configurationCategory].status} onToggle={()=>this.handleToggle(configurationCategory , !this.state.emailArray[configurationCategory].status)} />
          </Col>
        </Row>
        </>
      )}
      </div>
      </div>
      
      <Modal zIndex="99999" isOpen={this.state.confirmDetailModal} >
        <ModalHeader toggle={()=>this.setState({confirmDetailModal : !this.state.confirmDetailModal})}>Are your sure you want to save? </ModalHeader>
        <ModalFooter>
          <Button color="dark" onClick={()=>this.createConfiguration()}>Save</Button>
          <Button onClick={()=>this.setState({confirmDetailModal : !this.state.confirmDetailModal})} className="r-bg--light r-text--dark"> Cancel </Button>
        </ModalFooter>
      </Modal>
      {!!this.state.isOpenSignature && <Modal zIndex="99999" isOpen={!!this.state.isOpenSignature} >
        <ModalHeader toggle={()=>this.setState({isOpenSignature : false})}>Signature</ModalHeader>
        <ModalBody>
          <RTE onChange={(value) => {this.updateValues(this.state.isOpenSignature ,"signature",value)}} hideToolbar={false} placeholder="Enter the signature here" value={this.state.emailArray[this.state.isOpenSignature].signature || ""} {...this.RTEProps}/>             
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.createConfiguration} className="r-bg--light r-text--dark"> Save </Button>
          <Button onClick={()=>this.setState({isOpenSignature : false})} className="r-bg--light r-text--dark"> Close </Button>
        </ModalFooter>
      </Modal>}
      </div>
      </Container>
    )
  }
}

export default EmailConfiguration;
