import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Row,Col,Container,Collapse} from 'reactstrap';
import Aux from '../../global/AuxComp';
import Button from '../../global/Button';
import { InputField } from '../../global/Forms';
import { Switch } from '../../global/Forms/ToggleSwitch';
import {CONSTANTS} from '../../../constants';
import ShowCourse from './show_course';
import AppendCourse from './append_course';
import Classification from './classification';

  export default class ShowCampus extends Component{
    constructor(props){
       super(props);
         this.state = {
           isOn: false,
           isModalOpen : false
          };
     }

	  handleToggle(e) {
  		this.setState({isOn: !this.state.isOn});
    }

    toggleModal = ()=>{
      this.setState({isModalOpen: !this.state.isModalOpen})
    }


    render(){
      let {universityIndex, collegeIndex, campusIndex, campusData,  modifyDetails, modifyObjDetails, deleteDetails, addDetailsExistedList, degreeList, openMembersModel } = this.props;
      var courseData = campusData.campus_degree || [];
      var role_count = campusData.role_count && Array.isArray(campusData.role_count) ? campusData.role_count : [];
      var societyCount = role_count.filter(cd => cd.role_type === CONSTANTS.ROLE_TYPES_ID.SOCIETY);
      var ptCount = role_count.filter(cd => cd.role_type === CONSTANTS.ROLE_TYPES_ID.PT);
      var studentCount = role_count.filter(cd => cd.role_type === CONSTANTS.ROLE_TYPES_ID.STUDENT);
      var spocCount = role_count.filter(cd => cd.role_type === CONSTANTS.ROLE_TYPES_ID.SPOC);
      var accessLevel_ids =  {'campus_id' : campusData.campus_id, 'college_id' : campusData.college_id,'university_id' : campusData.university_id};
      return (
        <div className="child-boundry-course-left">
          <Row className="mt-3 align-items-center">
            <Col xs={5}>
              <Row className="align-items-center">
              <Col className="child-boundry-campus col-2"></Col>
              <Col xs={10} className="align-self-center" >
                { !campusData.isEditable ?

                <span>
                    <h1 className="r-heading-6">{campusData.name}
                    <Button onClick={this.toggleModal} color="blue" className="r-text--sm ml-3 px-3">Settings</Button>
                  </h1>
                {this.state.isModalOpen &&  <Classification handleFetchInstitute={this.props.handleFetchInstitute} campusData={campusData} isModalOpen = {this.state.isModalOpen} handleToggle={this.toggleModal} accessLevel_ids={accessLevel_ids} />   }
                </span>

                  :
                  <InputField value={campusData.name} onChange={(e) => modifyObjDetails(universityIndex, collegeIndex,campusIndex, null, 'name', e.target.value)}  name="name" className="w-100" type="text" />

                }
              </Col>
              </Row>
            </Col>
            <Col xs={7} className="align-items-center text-center" >
              <Row>
                <Col xs={3}><Button onClick={() => openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.PT, accessLevel_ids)} color="link-blue">{ ptCount[0] ? ptCount[0].id_count : 0 }<span className="r-text--sm"> Members <i className="d-inline-block fi flaticon-eye mr-2"></i></span></Button></Col>
                {/* <Col xs={2}><Button onClick={() => openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.SOCIETY, accessLevel_ids)} color="link-blue">{ societyCount[0] ? societyCount[0].id_count : 0 } <span className="r-text--sm">Societies <i className="d-inline-block fi flaticon-eye mr-2"></i></span></Button></Col> */}
                <Col xs={2}><Button onClick={() => {return false; openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.STUDENT, accessLevel_ids)}} color="link">{ studentCount[0] ? studentCount[0].id_count : 0  }<span className="r-text--sm"> Students</span></Button></Col>
                <Col xs={3}>
                {/* <Button onClick={() => openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.SPOC, accessLevel_ids)} className="" color="link-blue">{ spocCount[0] ? spocCount[0].id_count : 0 } <span className="r-text--sm">Admin <i className="d-inline-block fi flaticon-eye mr-2"></i></span></Button> */}
                </Col>
                {/* <Col xs={1}><Switch  className="mt-3 d-inline-block" isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)} /></Col> */}
                <Col xs={4}>
                { !campusData.isEditable ? <div className="text-right">
                      <Button  onClick={(e) => addDetailsExistedList(universityIndex, collegeIndex,campusIndex)} className="r-text--sm mt-1 mr-3" color="link-blue"><i className="d-inline-block fi flaticon-arrow rotate--90 mr-2"></i>Add Degree</Button>
                      <Button onClick={() => modifyObjDetails(universityIndex, collegeIndex, campusIndex, null, 'isEditable', true)} className="p-2"><i className="fi flaticon-edit"></i></Button>
                      <Button onClick={() => deleteDetails(universityIndex, collegeIndex,campusIndex, null)} className="p-2"><i className="fi flaticon-bin"></i></Button>
                    </div>
                    :
                    <div className="text-right">
                      <Button onClick={() => modifyObjDetails(universityIndex, collegeIndex, campusIndex, null, 'isEditable', false)}   className="r-text--sm mt-4 py-2 px-1" color="outline-dark-v" square="true">Cancel</Button>
                      <Button onClick={() => modifyDetails(universityIndex, collegeIndex,campusIndex, null )}  className="r-text--sm mt-4 ml-1 px-1 py-2" color="dark-v" square="true">Save</Button>
                    </div>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          {
            courseData && Object.keys(courseData).map((child, index) => (!courseData[child].rip ? <ShowCourse key={child} universityIndex={universityIndex} collegeIndex={collegeIndex} campusData={campusData} campusIndex={campusIndex} courseIndex={child} courseData={courseData[child]} {...this.props} /> : <AppendCourse key={child} courseData={courseData[child]} universityIndex={universityIndex} collegeIndex={collegeIndex} campusIndex={campusIndex} courseIndex={child} {...this.props} /> ) )
          }
        </div>

    )
  }
}
