import React, { Component } from 'react';
import withRouter from 'react-router-dom/withRouter';
import { Row,Col,Container,  Modal, ModalHeader,Nav,Table, NavItem,Collapse, NavLink,TabContent, TabPane, ModalBody, ModalFooter,Pagination, PaginationItem, PaginationLink ,Spinner , CardBody, Card } from 'reactstrap';
import Button from '../../global/Button';
import { Loader } from '../../global/Loader';
import {Errorbar} from '../../global/errorbar';
import { Auth } from '../../../actions/Auth';
import { ToggleButton } from '../../global/Forms/ToggleButton';
import { InputField } from '../../global/Forms';
import {CONSTANTS} from '../../../constants';
import Axios from 'axios';
import store from '../../../store';
import Aux from '../../global/AuxComp';


class Permission extends Component{
    constructor(props){
      super(props);
      this.state = {
        getRoleList:  [],
        role:props.role, 
        loader: false,
        permissionList: [],
        rolePermission:[],
        groupList:{},
      };
    }


    componentWillReceiveProps(props){
      this.setState({role :props.role});
  }


  componentDidMount() {
    this.init();
  }


  init = async() =>{
    const {role, groupList}= this.state;
     try {
      this.props.loaderShow()
      let response = await Auth.Spoc.getPermissionListDefault(role.role_type);
      let resp = await Auth.Spoc.getPermissionListRole(role.role_id);
      let merged = [];
      let groups = {}
      for(let i=0; i<response.data.length; i++) {
        merged.push({
         ...response.data[i], 
         ...(resp.data.find((itmInner) => itmInner.permission_id === response.data[i].permission_id))}
        );
      }
      groups = merged.reduce((prev, current) => {
        prev[current.permission_group] =[]
        return prev
      }, {})
      merged.map(obj =>{
        Object.keys(groups).forEach((key) => {
                if(obj.permission_group == key ){
                    groups[key].push(obj);
                }
        });
      });
      this.setState({permissionList: merged,
                      rolePermission: resp.data,
                      groupList:groups  });
      this.props.loaderShow()
    } catch (e) {
      this.props.loaderShow()
      console.log(e)
    }
  }


  render(){
    const {loader, groupList  } = this.state;
    return(
        <Modal  zIndex="99999"  isOpen={this.props.modal} toggle={this.props.toggle} size="md"  style={{marginTop: "50px"}}>
        <Loader show={loader} />
           <Row className="justify-content-between align-items-center mx-0  mt-3">
              <Col xs="auto">
                <Row  className=" align-items-center mx-0 my-4">
                  <span className="r-heading-3">Assign permissions to role</span>
                </Row>
              </Col>
              <Col xs="auto" >
                  <Button onClick={this.props.toggle}><i className="d-inline-block fi flaticon-plus-1 rotate--45 mr-2" style={{fontSize:"20px"}}/></Button>
              </Col>
           </Row>
            {Object.keys(groupList).map((key) =>{
                      return(
                                <GroupPermission key={key} init={this.init} role={this.state.role} group={groupList[key]} handlePermission={this.props.loaderShow} rolePermission={this.state.rolePermission}/>  
                        )
                    })
            }
        </Modal>
    )
  }
}


class GroupPermission extends Component{
    constructor(props){
      super(props);
      this.state = {
        edit:  false,
        errorbar: false,
        collapse: false,
        message:'',
        group:props.group
      };
    }



    componentWillReceiveProps(props){
      this.setState({group :props.group});
  }

  handleCollpase =(e) =>{
    this.setState({collapse: !this.state.collapse})
  }

  render(){
    const{collapse, group} = this.state;
    const{rolePermission} = this.props;
    return(
      <div className="container">
            <Row className="border-bottom mb-3 pb-3">
              <Col xs={1} onClick={this.handleCollpase}>
                  {!collapse?<i className=" d-inline-block fi flaticon-arrow-1  ml-5" style={{fontSize:"15px"}}/>:<i className=" d-inline-block fi flaticon-arrow rotate--90  ml-5 " style={{fontSize:"15px"}}/>}
              </Col>
              <Col xs={4}>
                  <h3 onClick={this.handleCollpase} className="r-subheading-3 mb-0 mt-1">{group[0].permission_group}</h3>
              </Col>
              <Col xs={2}>

              </Col>
              <Col xs={2}>

              </Col>
              <Col xs={2}>
                      <Button  className="permission-btn font-weight-normal mx-3  px-5" color="dark">Update</Button>
              </Col>
            </Row>
            <Collapse isOpen={collapse}>
                {group.map((permission,i) =>{
                  return(
                    <Aux key={i}>
                      <EditPermission key={i} init={this.props.init} permission={permission} role={this.props.role} handlePermission={this.props.handlePermission}/>
                      {
                        permission.permission_name === "reportPlacementTeamPerformance" && permission.read ===1 && 
                        <div className="w-100 pl-5">
                          <EditPermission init={this.props.init} permission={{permission_id : 62, permission_name : "reportPlacement", defaultCheked: 1, permission_title : "Placement Report", ...(rolePermission.filter(per => per.permission_id === 62)[0] || {})}} role={this.props.role} handlePermission={this.props.handlePermission}/>
                          <EditPermission init={this.props.init} permission={{permission_id : 63, permission_name : "reportTeam", defaultCheked: 1, permission_title : "Team Report",  ...(rolePermission.filter(per => per.permission_id === 63)[0] || {})}} role={this.props.role} handlePermission={this.props.handlePermission}/>
                          <EditPermission init={this.props.init} permission={{permission_id : 64, permission_name : "reportStudent", defaultCheked: 1, permission_title : "Student Report", ...(rolePermission.filter(per => per.permission_id === 64)[0] || {})}} role={this.props.role} handlePermission={this.props.handlePermission}/>
                          <EditPermission init={this.props.init} permission={{permission_id : 65, permission_name : "reportMeeting", defaultCheked: 1, permission_title : "Meeting Report", ...(rolePermission.filter(per => per.permission_id === 65)[0] || {})}} role={this.props.role} handlePermission={this.props.handlePermission}/>
                          <EditPermission init={this.props.init} permission={{permission_id : 66, permission_name : "reportCompany", defaultCheked: 1, permission_title : "Company Report", ...(rolePermission.filter(per => per.permission_id === 66)[0] || {})}} role={this.props.role} handlePermission={this.props.handlePermission}/>
                          <EditPermission init={this.props.init} permission={{permission_id : 67, permission_name : "reportAttendance", defaultCheked: 1, permission_title : "Attendance Report", ...(rolePermission.filter(per => per.permission_id === 67)[0] || {})}} role={this.props.role} handlePermission={this.props.handlePermission}/>
                        </div>
                      }
                      {
                        permission.permission_name === "studentTab" && permission.read ===1 && 
                        <div className="w-100 pl-5">
                          <EditPermission init={this.props.init} permission={{permission_id : 55, permission_name : "editStudent", permission_title : "Edit Student Profile", ...(rolePermission.filter(per => per.permission_id === 55)[0] || {})}} role={this.props.role} handlePermission={this.props.handlePermission}/>
                          <EditPermission init={this.props.init} permission={{permission_id : 56, permission_name : "viewStudentProfile", permission_title : "View Student Profile",  ...(rolePermission.filter(per => per.permission_id === 56)[0] || {})}} role={this.props.role} handlePermission={this.props.handlePermission}/>
                          <EditPermission init={this.props.init} permission={{permission_id : 59, permission_name : "editStudentResume", permission_title : "Edit Student Resume", ...(rolePermission.filter(per => per.permission_id === 59)[0] || {})}} role={this.props.role} handlePermission={this.props.handlePermission}/>
                          <EditPermission init={this.props.init} permission={{permission_id : 57, permission_name : "viewStudentResume", permission_title : "View Student Resume", ...(rolePermission.filter(per => per.permission_id === 57)[0] || {})}} role={this.props.role} handlePermission={this.props.handlePermission}/>
                          <EditPermission init={this.props.init} permission={{permission_id : 58, permission_name : "viewStudentApply", permission_title : "View Student Apply", ...(rolePermission.filter(per => per.permission_id === 58)[0] || {})}} role={this.props.role} handlePermission={this.props.handlePermission}/>
                        </div>
                      }
                      {
                        permission.permission_name === "placementsTab" && permission.read ===1 && 
                        <div className="w-100 pl-5">
                          <EditPermission init={this.props.init} permission={{permission_id : 60, permission_name : "showOnlyAssignedCompanies", permission_title : "Access Only Assigned Companies", ...(rolePermission.filter(per => per.permission_id === 60)[0] || {})}} role={this.props.role} handlePermission={this.props.handlePermission}/>
                        </div>
                      }
                      {
                        permission.permission_name === "companiesTab" && permission.read ===1 && 
                        <div className="w-100 pl-5">
                          <EditPermission init={this.props.init} permission={{permission_id : 61, permission_name : "showOnlyAssignedCompany", permission_title : "Access Only Assigned Companies", ...(rolePermission.filter(per => per.permission_id === 61)[0] || {})}} role={this.props.role} handlePermission={this.props.handlePermission}/>
                        </div>
                      }
                    </Aux>
                  )
                })}
            </Collapse>
        </div>
      )
  }


}


class EditPermission extends Component{
    constructor(props){
      super(props);
      this.state = {
        edit:  false,
        errorbar: false,
        message:'',
        permission:props.permission,
        createPer:(props.permission.create)?props.permission.create:0,
        readPer:  props.permission.hasOwnProperty("read") ? props.permission.read : 0,
        editPer:(props.permission.update)?props.permission.update:0,
        deletePer:(props.permission.delete)?props.permission.delete:0,
        rolesList:[1,2,3,4,5,6],
      };
    }

    

    componentWillReceiveProps(props){
      this.setState({permission :props.permission});
  }

    onSubmit = async(e, id) =>{
        const{permission, role} = this.props;
        const {deletePer, createPer, readPer, editPer, } = this.state;
        this.props.handlePermission();
          let body = {
              "role_id": role.role_id,
              "permissionSet":[
              {
              "permission_id": permission.permission_id,
              "create": 1,
              "read": 1,
              "delete": 1,
              "update": 1
              }]
          }
          let bodyupdate = {
              "role_id": role.role_id,
              "id":permission.id,
              "permission_id": permission.permission_id,
              "create":  (permission.read ===1)?0:1,
              "read": (permission.read ===1)?0:1,
              "delete":  (permission.read ===1)?0:1,
              "update":  (permission.read ===1)?0:1
          }
        try {
              let res =  (( permission.hasOwnProperty("read")))? await Auth.Spoc.editPermission(bodyupdate): await Auth.Spoc.addPermission(body);
              this.setState({readPer:(permission.read ===1)?0:1});
              this.props.init();
              this.props.handlePermission();
            } catch (e) {
              console.log(e);
              this.props.handlePermission();
              this.setState({errorbar: true});
              this.setState({message: e.response.data.error.toString()});
            }
        
    }

        
    updateState =()=>{
    const {deletePer, createPer, readPer, editPer } = this.state;
     if ((createPer == 1)||(deletePer == 1)||(editPer == 1)) {
           this.setState({readPer: 1})
         }
     else if((createPer == 0)&& (deletePer == 0)&& (editPer == 0)){
           this.setState({readPer: !readPer})
     }
    }

    handleChangePermission = async(e) => {
    const {deletePer, createPer, readPer, editPer } = this.state;
     await this.setState({[e.target.name]: (e.target.value == 1)?0:1});
     this.updateState();
    }

    
  render(){
    const {deletePer,permission, createPer, readPer } = this.state;
    return(
            <Row className="justify-content-between align-items-center border-bottom mb-3 pb-3 px-5">
                  <Col xs={6}>
                    {permission.permission_title}
                  </Col>
                  <Col xs={4} className="text-right">
                    <ToggleButton buttonType="pt" selected={!readPer} type onToggle={this.onSubmit} values={{ value1: " ", value2:" "  }} />
                  </Col>
            </Row>
      )
  }
}


export default Permission;
