import React, { Component } from 'react';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../../actions/Auth';
import { Row,Col,Container,  Modal, ModalHeader,Nav,Table, NavItem,Collapse, NavLink,TabContent, TabPane, ModalBody, ModalFooter,Pagination, PaginationItem, PaginationLink ,Spinner} from 'reactstrap';
import moment from 'moment';
import Button from '../../../global/Button';
import {Confirmation} from '../../../global/confirmation';
import { InputField } from '../../../global/Forms';
import PaginationComponent from '../../../global/PaginationComponent.js';

 class GroupTable extends Component{

    constructor(props){
    super(props);
    this.state = {
      currentPage: 0,
      rowsPerPage: 10,
      selected: {},
      selectAll: 0,
      studentQuery: "",
      isConfirmation : false, 
      deleteConfirmationId : null,
      allStudents:[],
      columns:[{id: "sno", title: "S. No."},{id: "name", title: "Name"},{id: "roll_no", title: "Roll No."},{id:"email", title:"Email"},{id:"delete", title:"Delete"}],
      batchid: props.batchid,
      departmentid: props.departmentid


    };
  }


    componentWillReceiveProps(props){
      this.setState({
      batchid: props.batchid,
      departmentid: props.departmentid});
  }

    componentDidMount = () => {
      this._init();
  }

  _init  = async () => {
      let {batchdata} = this.state;
     try {
      let batchId = [];
      batchId.push(this.state.batchid);
      let depId = [];
      depId.push(this.state.departmentid.toString());
      let res = await Auth.PtStudents.getBatchSpec(batchId,depId);
      this.setState({allStudents: res.data[0].student_info});
    } catch (e) {
      console.log(e);
    }
  }


  onChange = e => {
    this.setState({
        [e.target.name]: e.target.value,
    })
  }


  handleClick(e, index) {
    e.preventDefault();

    this.setState({
      currentPage: index
    });
  }

  onSubmit = async(e) =>{
      e.preventDefault();
    this.setState({setloading: true});
      let data = [];
      await Object.keys(this.state.selected).forEach(id => {
          if(this.state.selected[id] === true){data.push(parseInt(id));}
      });
      var groupBody = {
                "group_id":this.props.groupid,
                "student_id":data
          }
      try { 
            let resp = await Auth.PtStudents.addStudentoGroup(groupBody);
            this.props.toggle();
            this.props.refresh();
            this.setState({setloading: false});
          } catch (e) {
            console.log(e);
          this.setState({setloading: false});
          }
  }

  selectRow = async(stID) => {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[stID] = !this.state.selected[stID];
    await this.setState({
      selected: newSelected,
      selectAll: 2
    });
  }

  toggleSelectAll = async(e)=> {
    let {currentPage,rowsPerPage, allStudents,studentQuery} = this.state;
    let newSelected = {};

    let students = allStudents.filter(row => {
      let name = row.first_name || "";
      return name.toLowerCase().includes(studentQuery.toLowerCase())
    })

    if (this.state.selectAll === 0) {
      students.forEach(x => {
        newSelected[x.student_id] = true;
      });
    }

    await this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0
    });
  }


  handleSelected = (selectedPage) => {
    this.setState({ currentPage: selectedPage - 1 });
  }


  confirmDeleteUser = (id) => {
    this.setState({deleteConfirmationId : id, isConfirmation : true})
  }

  toggleConfirmation = () => {
    this.setState({isConfirmation : false})
  }


  deleteStudent = async(id) => {
    this.toggleConfirmation();
    this.setState({setloading: true});
    let postBody = {
          "group_id": this.props.groupid,
          "student_id":id
        }
    try {
          await Auth.PtStudents.deleteStudentGroup(postBody);
          this.props.toggle();
          this.props.refresh();
          this.setState({setloading: false});
        } catch (e) {
           console.log(e);
        }
  }


  render(){
    let {columns,allStudents,type,currentPage,rowsPerPage,selectAll, studentQuery, isConfirmation} = this.state;
    let data = [];
    if (this.props.type === "show") {
      data = allStudents.filter(st => {
      if (st.group_id === this.props.groupid) {
        return st;
      }})
    } 
    else{ data = allStudents; }
    let students = data.filter(row => {
      let name = row.first_name || "";
      return name.toLowerCase().includes(studentQuery.toLowerCase())
    })
    let pagesCount =  Math.ceil(data.length /rowsPerPage)
    let length = rowsPerPage*currentPage;
      return (
        <Modal  zIndex="99999"  isOpen={this.props.modal} toggle={this.props.toggle} size="xl"  style={{marginTop: "50px"}}>
              <Row className="justify-content-between align-items-center mx-0  mt-3">
              <Col xs="auto">
                <Row  className=" align-items-center mx-0 my-4">
                  <span className="r-heading-3">{(this.props.type === "show")?"Added students in group":"Add students to group"}</span>
                </Row>
              </Col>
              <Col xs="auto">
                <InputField placeholder="Search Name" name="search" value={studentQuery} onChange={(e) => this.setState({ studentQuery: e.target.value, currentPage: 0 })} />
              </Col>
           </Row>
           {this.state.setloading? <div className="center-xy"><Spinner color="dark"/></div>:null}
           <Row className="align-items-center mx-0 px-0 mb-3" style={{overflowX: 'auto', overflowY: 'hidden', border: '2px solid black', borderColor:"#c4dcf3"}}>
               <Table >
                      <thead>
                        <tr  className="tableheader">
                                {columns.map((col,i) =>
                                {  if(col.id === "sno"){
                                          return(
                                            <th key={i} className='tableheadbox'>
                                               {(this.props.type === "show")? "S.No.":<InputField 
                                                                                                       type="checkbox" value="S.No." 
                                                                                                       checked={this.state.selectAll === 1}
                                                                                                       name="selectAll"
                                                                                                       onChange={() => this.toggleSelectAll()}
                                                                                                       />}
                                            </th>
                                          )
                                        }
                                        else if(col.id ==="name"){
                                         return ( <th key={i} className='tableheadboxsearch'> Name
                                                     </th>)
                                        }
                                        else if(col.id ==="delete"){
                                         return ( 
                                              <th  key={i}>
                                                  {(this.props.type === "show")? col.title:null }
                                                </th>)
                                        }
                                        else{
                                            return(
                                              <th className='tableheadbox' key={i}>
                                                  { col.title }
                                                </th>
                                            )
                                        }}
                                )}
                        </tr>
                      </thead>
                      <tbody>
                            {
                                  students.slice( currentPage * rowsPerPage, currentPage*rowsPerPage +rowsPerPage  ).map((row,i) =>{
                                      length = length+1;
                                    return(
                                      <tr
                                          className="borderBottomlightblack"
                                          key={i}>
                                          {
                                            columns.map(col =>{
                                               if(col.id ==="sno"){
                                                   return ( 
                                                        <td className='tableheadbox' key={col.id}>
                                                         {(this.props.type === "show")? length:<InputField name="selectedStudent"  type="checkbox" value={length}
                                                                                                                           checked={this.state.selected[row.student_id] === true}
                                                                                                                           onChange={() => this.selectRow(row.student_id)}/>}
                                                        </td>)
                                                  }  
                                                else if(col.id === "name"){
                                                    return(
                                                        <th  key={col.id} className='tableheadbox' >
                                                                  {row["first_name"]} {row["last_name"]}
                                                        </th>
                                                    )
                                                  }
                                                  else if(col.id ==="year_of_passout"){
                                                   return ( 
                                                        <td className='tableheadbox' key={col.id}>
                                                         {moment(row[col.id]).format(" YYYY")}
                                                        </td>)
                                                  }
                                                  else if(col.id ==="delete" && this.props.type === "show"){
                                                   return ( 
                                                        <td className='tableheadbox' key={col.id}>
                                                         <Button onClick={(e) => this.confirmDeleteUser(row.student_id)}><i className="r-text--blue-3 d-inline-block fi flaticon-bin " style={{fontSize:"15px"}}/></Button>
                                                        </td>)
                                                  }
                                                  else{
                                                      return(
                                                        <td className='tableheadbox' key={col.id}>
                                                          {row[col.id]}
                                                        </td>
                                                      )
                                                  }
                                            })
                                          }
                                      </tr>
                                    )
                                  })
                            }
                      </tbody>  
                  </Table>
              </Row>
          <Row className="justify-content-between align-items-center  mx-0 px-0">
              <Col xs="auto" className="">
                <Row className="justify-content-between align-items-center">
                <span className="px-1 d-inline-block r-subheading-4 " style={{fontSize:"15px"}}>Total students are {students.length}</span>
                <span className="px-3 d-inline-block r-subheading-4 " style={{fontSize:"15px"}}>View</span>
                <div className="r-dropdown border-0">
                    <select value={rowsPerPage} name="rowsPerPage" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={this.onChange}>
                      <option   value="10" >10</option>
                      <option value="15">15</option>
                       <option   value="20" >20</option>
                      <option value="25">25</option>
                       <option   value="50" >50</option>
                      <option value="100">100</option>
                    </select>
                  <i className="fi flaticon-arrow"/>
                </div> 
                </Row> 
              </Col>
              <Col xs="auto">
                  <PaginationComponent
                    totalItems={students.length}
                    pageSize={rowsPerPage}
                    onSelect={this.handleSelected}
                    maxPaginationNumbers={3}
                    activePage={currentPage + 1}
                  />
              </Col>
          </Row>
              {(this.props.type === "show")? null:<Row  className="justify-content-end align-items-center  mx-0  my-2 " style={{textAlign:"center", padding:"20px 0"}}>
                                  <Button onClick={this.onSubmit} className="font-weight-normal mx-3  px-5" color="dark"> Add</Button>
                                  <Button onClick={this.props.toggle} className="font-weight-normal mx-3  px-5" color="outline-dark-v">Cancel</Button>
                             </Row>}
                  <Confirmation isOpen={isConfirmation} toggleConfirmation={this.toggleConfirmation} callback={() => this.deleteStudent(this.state.deleteConfirmationId)} />
          </Modal>
  )
 }
}

export default GroupTable;
