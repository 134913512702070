import React,{Component} from 'react';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container} from 'reactstrap';
import {Button, ButtonGroup} from 'reactstrap';
import moment from 'moment';
import  {InputField} from '../../global/Forms';
import {HorizontalLine} from '../../global/horizontal_line';
import PrimarySection from './primary_section';
import StudentSection from './student_section';
import Aux from '../../global/AuxComp';

class EditParticipants extends Component {
  constructor() {
    super();
    this.state={
      value : 2,
      eventDetails : [],
      attendeeDetails:[],
      eventList : null
    }

  }
  onClickHandler = (value)=>{
    this.setState({
      value
    })
  }

  getEvents = async (params)=>{
    if(params!=undefined){
      try{
        let events = await Auth.Batch.getEvent(params);
        let eventDetails = events.data[0].event_details;
        await this.setState({
          eventDetails,
        })
        this.filterEvents();
      }
      catch(e){
        console.log(e);
      }
  }

}
  filterEvents = async ()=>{
    let {eventDetails} = this.state;
      let   eventList = eventDetails[0].event_id;

    await this.setState({
      eventList
    })
  }
  async componentDidMount(){
    await this.getEvents(this.props.params);
  }

  render(){
    return(
      <Aux>
      <Row>
      <div className="m-0 ml-4">
      <button type="button" className={" pl-3 pr-3 calendar__edit-partipants__top-buttons r-subheading-4 r-text--grey-4  "+(this.state.value==2?"active":null)} onClick={()=>this.onClickHandler(2)}>Students</button>
      <button type="button" className={"pl-3 pr-3 calendar__edit-partipants__top-buttons r-subheading-4 r-text--grey-4  "+(this.state.value==1?"active":null)} onClick={()=>this.onClickHandler(1)}>Placement Team</button>
      </div>
      </Row>
      <hr  color="black" class="m-0"/>
      {/* <div className={this.state.value==1?"":"d-none"}> */}
      { this.state.value===1 &&<PrimarySection user_id={this.props.user_id} college_id={this.props.college_id} role_type={this.props.role_type} campus_id={this.props.campus_id} university_id={this.props.university_id} eventId={this.props.eventId} setPtMemberList={this.props.setPtMemberList} setTotalPtMembers={this.props.setTotalPtMembers} />}
      {/* </div> */}
      {/* <div className={?"":"d-none"}> */}
      {this.state.value===2 && <StudentSection endDate={this.props.endDate} startDate={this.props.startDate} role_type={this.props.role_type} college_id={this.props.college_id} campus_id={this.props.campus_id} university_id={this.props.university_id} setTotalStudent={this.props.setTotalStudent} eventId={this.props.eventId}  title={this.props.title} description={this.props.description} setStudenList={this.props.setStudenList} setRemindTime={this.props.setRemindTime} setRemindDate={this.props.setRemindDate} params={this.props.params} setRepeatReminderValue={this.props.setRepeatReminderValue}  setStudentCollection={this.props.setStudentCollection} eventList={this.state.eventList} setSwitch={this.props.setSwitch} setMailCollection={this.props.setMailCollection} />}
      {/* </div> */}
      </Aux>
    )
  }
}

export default EditParticipants;
