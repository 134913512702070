import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Spinner from "reactstrap/lib/Spinner";
import FeedPost from "../../global/post";
import { Auth } from "../../../actions/Auth";
import { Loader } from "../../global/Loader";
import { getFiles } from "../../../actions";
import { Select } from "../../global/Forms/Select";
import Aux from "../../global/AuxComp";
import moment from "moment";
// import HelpSection from '../../helpSection/student_section';

class StudentFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      files: {},
      init: true,
      studentId: null,
      isLoading: false,
      events: [],
      processList: [],
      selectedProcess: {},
      applicationsLoader: false,
      isLike: null,
      applicationsStatus: {
        Eligible: [],
        Applied: [],
        Shortlisted: [],
        Selected: []
      },
      attendanceStatus: {}
    };
  }

  componentWillMount() {
    this.init();
    this.fetchAttendanceCount();
  }

  init = async () => {
    this.toggleLoading(true);
    try {
      let posts = await Auth.Feed.postMemberView();
      let fileIds = []
      posts.data.forEach(p => {
        // fileIds.push(p.post_banner_id);
        fileIds.push(p.file_id)
      });
      let files = await getFiles(fileIds)
      this.setState({
        posts: posts.data,
        files
      });
    } catch (e) {
      console.log(e);
    }

    let eventParams = {
      attendee_id: this.props.user.id,
      start_date: moment().startOf("day"),
      end_date: moment().endOf("day")
    };

    try {
      let eventId = await Auth.Batch.getEvent(eventParams);
      await this.setState({
        events: eventId.data[0].event_details
      });
    } catch (e) {
      console.log(e);
    }

    try {
      let studentResp = await Auth.Student.getId(this.props.user.id);
      let studentInfo = studentResp.data[studentResp.data.length - 1] || {};
      let processResp = await Auth.Student.getProcessList(studentInfo.student_id);
      let selectedProcess = processResp.data[0];
      if (selectedProcess) this.fetchApplications(selectedProcess, studentInfo.student_id);
      this.setState({
        processList: processResp.data,
        selectedProcess,
        studentId: studentInfo.student_id
      });
    } catch (e) {
      console.log(e);
    }
    this.setState({
      init: false
    });
    this.toggleLoading(false);
  };

  fetchApplications = async (process, studentId) => {
    this.setState({
      applicationsLoader: true
    });
    try {
      let applicationsStatus = {
        Eligible: [],
        Applied: [],
        Shortlisted: [],
        Selected: []
      };
      let applicationResp = await Auth.Student.getApplications(
        process.process_id, studentId || this.state.studentId
      );
      applicationResp.data.forEach(apl => {
        let shortListed = apl.shortlisting_data.filter(
          sl => sl.shortlist_status === 1
        );
        if (apl.eligibility_status === 1)
          applicationsStatus["Eligible"].push(apl);
        if (apl.student_application_status === 2)
          applicationsStatus["Applied"].push(apl);
        if (apl.shortlisting_status === 1 || shortListed.length)
          applicationsStatus["Shortlisted"].push(apl);
        if (apl.offer_data.length) applicationsStatus["Selected"].push(apl);
      });
      this.setState({
        applicationList: applicationResp.data,
        selectedProcess: process,
        applicationsStatus
      });
    } catch (e) {
      console.log(e);
      this.setState({
        applicationList: [],
        selectedProcess: process
      });
    }
    this.setState({
      applicationsLoader: false
    });
  };

  toggleLoading = isLoading => {
    this.setState({
      isLoading
    });
  };

  handleProcessChange = e => {
    let { processList } = this.state;
    let selectedProcess = processList.filter(
      pr => pr.process_id === parseInt(e.target.value)
    );
    this.fetchApplications(selectedProcess[0]);
  };

  likePost = async data => {
    const { posts } = this.state;
    var stBody = {
      post_id: data.post_id,
      liked: data.is_liked === 1 ? "0" : 1
    };
    try {
      await Auth.Feed.createActivity(stBody);
      let objIndex = posts.findIndex(obj => obj.post_id === data.post_id);
      posts[objIndex].no_of_like =
        data.is_liked === 1 ? data.no_of_like - 1 : data.no_of_like + 1;
      posts[objIndex].is_liked = data.is_liked === 1 ? 0 : 1;
      this.setState({
        posts
      });
    } catch (e) {
      console.log(e.response);
    }
  };

  fetchAttendanceCount = async () => {
    try {
      let resp = await  Auth.Attendance.attendanceView();
      var invited = resp.data[0] ? resp.data[0].attendee_id_count : 0;
      var attended = resp.data[0] ? resp.data[0].attended.attendee_id_count : 0;
      this.setState({attendanceStatus : {invited , attended}})
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    let {
      init,
      isLoading,
      posts,
      files,
      applicationsLoader,
      selectedProcess,
      processList,
      events,
      applicationsStatus,
      attendanceStatus,
    } = this.state;
    return (
      <Container className="feed">
        <Loader show={isLoading} />{" "}
        {!init && (
          <Row>
            <Col xs={12} sm={3}>
              {" "}
              {/* <div className="d-flex justify-content-between">
                          <h1 className="r-heading-5">To-Do List</h1>
                          <i className="fi flaticon-plus mb-2" />
                        </div>
                        <ul className="feed__todo">
                          <li>Fill this by 04:30 pm and check the work culture of this company</li>
                        </ul> */}{" "}
              <div style={{position : "sticky", top : 50}}>

                <h1 className="r-heading-5 mt-3"> Today 's Events</h1>{" "}
                <ul className="feed__event">
                  {" "}
                  {/* <li>
                                  <p className="r-subheading-4">04:30 am, Today</p>
                                  <span className="r-text--blue-3 r-subheading-3">Microsoft Product Manager</span>
                                  <p className="r-text--grey-4 r-subheading-4">Location</p>
                                  <p className="mb-0">Fill this by 04:30 pm and check the work culture of this company</p>
                                </li> */}{" "}
                  {events.length > 0 ? (
                    events.map((evt, i) => {
                      return (
                        <li key={i}>
                          <p className="r-subheading-4">
                            {" "}
                            {moment(evt.start_date).format("hh:mm a")}{" "}
                          </p>{" "}
                          <span className="r-text--blue-3 r-subheading-3">
                            {" "}
                            {evt.title || "-"}{" "}
                          </span>{" "}
                          <p className="r-text--grey-4 r-subheading-4">
                            {" "}
                            {evt.location || "-"}{" "}
                          </p>{" "}
                          {/* <p className="mb-0">{evt.description || "-"}</p> */}{" "}
                        </li>
                      );
                    })
                  ) : (
                      <li> No Events </li>
                    )}{" "}
                </ul>{" "}
              </div>{" "}
            </Col>{" "}
            <Col xs={12} sm={6}>
              {" "}
              {posts.length > 0 ? (
                posts.map(post => (
                  <FeedPost
                    files={files}
                    likePost={this.likePost}
                    key={post.post_id}
                    data={post}
                    role_type={this.props.role.role_type}
                    redirect={this.props.history.push}
                  />
                ))
              ) : (
                  <p className="text-center r-heading-4"> No Posts </p>
                )}{" "}
            </Col>{" "}
              <Col xs={12} sm={3}>
                <div style={{position: "sticky", top : 50}} className="r-subheading-2">
                {selectedProcess && (
                  <Aux>
                  <h1 className="r-heading-5"> Your Applications </h1>{" "}
                  <div className="px-2">
                    <Select
                      value={selectedProcess.process_id}
                      onChange={this.handleProcessChange}
                    >
                      {" "}
                      {processList.map(pr => {
                        return (
                          <option key={pr.process_id} value={pr.process_id}>
                            {" "}
                            {pr.process_name}{" "}
                          </option>
                        );
                      })}{" "}
                    </Select>{" "}
                  </div>{" "}
                  {applicationsLoader ? (
                    <div className="mt-3 text-center">
                      {" "}
                      <Spinner color="dark" />{" "}
                    </div>
                  ) : (
                      <Aux>
                        <Row noGutters>
                          {" "}
                          <Col xs={6}>
                            <div className="feed__application">
                              <span className="r-text--blue-3 d-block">
                                {" "}
                                {applicationsStatus.Eligible.length}{" "}
                              </span>{" "}
                              <span className="r-subheading-3"> Eligible </span>{" "}
                            </div>{" "}
                          </Col>{" "}
                          <Col xs={6}>
                            <div className="feed__application">
                              <span className="r-text--blue-3 d-block">
                                {" "}
                                {applicationsStatus.Applied.length}{" "}
                              </span>{" "}
                              <span className="r-subheading-3"> Applied </span>{" "}
                            </div>{" "}
                          </Col>{" "}
                        </Row>{" "}
                        <Row noGutters>
                          <Col xs={6}>
                            <div className="feed__application">
                              <span className="r-text--blue-3 d-block">
                                {" "}
                                {applicationsStatus.Shortlisted.length}{" "}
                              </span>{" "}
                              <span className="r-subheading-3">
                                {" "}
                                Shortlisted{" "}
                              </span>{" "}
                            </div>{" "}
                          </Col>{" "}
                          <Col xs={6}>
                            <div className="feed__application">
                              <span className="r-text--blue-3 d-block">
                                {" "}
                                {applicationsStatus.Selected.length}{" "}
                              </span>{" "}
                              <span className="r-subheading-3"> Selected </span>{" "}
                            </div>{" "}
                          </Col>{" "}
                        </Row>{" "}
                      </Aux>
                    )}{" "}
                  </Aux>)}
                  <h1 className="r-heading-5 mt-4"> Your Attendance</h1>{" "}
                        <Row noGutters className="r-subheading-2">
                          {" "}
                          <Col xs={6}>
                            <div className="feed__application">
                              <span className="r-text--blue-3 d-block">
                                {" "}
                                {attendanceStatus.invited || 0}{" "}
                              </span>{" "}
                              <span className="r-subheading-3"> Invited </span>{" "}
                            </div>{" "}
                          </Col>{" "}
                          <Col xs={6}>
                            <div className="feed__application">
                              <span className="r-text--blue-3 d-block">
                                {" "}
                                {attendanceStatus.attended || 0}{" "}
                              </span>{" "}
                              <span className="r-subheading-3"> Attended </span>{" "}
                            </div>{" "}
                          </Col>{" "}
                          {/* <Col xs={6}>
                            <div className="feed__application">
                              <span className="r-text--blue-3 d-block">
                                {" "}
                                {applicationsStatus.Applied.length}{" "}
                              </span>{" "}
                              <span className="r-subheading-3"> % </span>{" "}
                            </div>{" "}
                          </Col>{" "} */}
                        </Row>{" "}
                </div>{" "}
              </Col>
            
          </Row>
        )}{" "}
      </Container>
    );
  }
}
const mapStateToProps = state => ({
  ...state
});
export default connect(
  mapStateToProps,
  null
)(StudentFeed);
