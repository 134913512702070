export const STYLE_RANGES = {
  'font-size': {
    'default': '14px',
    'min': '10px',
    'max': '40px',
    'unit': 'px'
  },
  'width': {
    'default': '100%',
    'min': '0%',
    'max': '100%',
    'unit': '%'
  },
  'text-align': {
    'values': ['left','right','center']
  }
}