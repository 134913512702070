import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { connect } from 'react-redux';
import { setUser, setLoading, setRole } from '../actions/action-creators';
import { Auth } from '../actions/Auth';
import { InputField } from './global/Forms';
import Button from './global/Button';
import { Errorbar } from './global/errorbar.js';
import { Loader } from './global/Loader';
import TermsCondition from './TermsCondition';
import { getRoleDetails } from '../actions';
import Aux from './global/AuxComp';
import {getQueryParams} from '../actions';
import {checkPass} from  '../actions';
import withRouter from 'react-router-dom/withRouter';
import { CONFIGURATION } from '../constants/configuration';
import Policies from './policies';

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      username: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      firstTimeLogin : false,
      error: null,
      errorbar: false,
      message: '',
      loader: false,
      showTerms : false,
      modal : false,
      forgotPasswordValue : null,
      forgotPasswordMessage : null,
      showNewPasswordBox : null,
      setNewPassword : null,
      confirmNewPassword : null,
      passwordToken : null,
      wrongPasswordMessage : null,
      isOpenPolicies: false
    }
  }

  componentDidMount() {
    if(window.location.href.includes("?")){
      let getParams = getQueryParams(window.location.href.split("?")[1]);
      if(getParams.token && getParams.page)
      {
        this.setState({
          username : getParams.email,
          showNewPasswordBox : 1,
          passwordToken : getParams.token
        })
      }
    }
    let {history} = this.props;
    if(history.location.state){
      let state = { ...history.location.state };
     state = undefined;
      history.replace({ ...history.location, state });
    }

  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }
  handleResetOldPassword = async (e) => {
      e.preventDefault();
      let { username, password, newPassword, confirmPassword, firstTimeLogin } = this.state;
      if(newPassword !== confirmPassword){
        this.setState({ message: "Confirm Password not matched"});
        this.setState({ errorbar: true });
        return false;
      }
      this.setState({ loader: true })
      try {
        var user = await Auth.User.resetPassword(password, newPassword, confirmPassword);
        let getUser = firstTimeLogin;
        if(getUser.roles && getUser.roles.length > 0)
          await Auth.User.updateProfile({ current_role_id: getUser.roles[0].assigned_role_id })
        this.setState({password : newPassword})
        this.handleLogin()
      } catch (e) {
        this.setState({ loader: false })
        if (e.response) {
          this.setState({ message: e.response.data.error.toString() });
          this.setState({ errorbar: true });
        } else {
          this.setState({ message: 'Sorry! something went wrong. Please try again' });
          this.setState({ errorbar: true });
        }
      }
  }

  handleForgotPassword = async (e)=>{
    if(e)
      e.preventDefault();
    let {username} = this.state;
    this.setState({loader:true,password:""});
    try{
      let params = {
        email : this.state.username
      }
      let forgotPasswordMessage = await Auth.User.forgotPassword(params);
      this.setState({forgotPasswordMessage : forgotPasswordMessage.data.message,username:""})
    }
    catch(e){
      this.setState({ loader: false })
      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
    this.setState({loader:false});
  }

  handleRetievePassword = async(e)=>{
    if(e)
    e.preventDefault();
    let{setNewPassword, confirmNewPassword , passwordToken , username} = this.state;
    if(checkPass(setNewPassword) && checkPass(confirmNewPassword))
    {
      if((setNewPassword == confirmNewPassword) && setNewPassword!= null && confirmNewPassword!=null){
        this.setState({loader: true});
        let params = {
          "email": username,
          "token": passwordToken,
          "new_password": setNewPassword,
          "confirm_password": confirmNewPassword
        }
        try{
          await Auth.User.retrievePassword(params);
          await this.setState(this.getInitialState());
          this.props.history.push({pathname:"/", state:{msg: 'Password changed successfully'}});
        }
        catch(e){
          this.setState({ loader: false })
          if (e.response) {
            this.setState({ message: e.response.data.error.toString() });
            this.setState({ errorbar: true });
          } else {
            this.setState({ message: 'Sorry! something went wrong. Please try again' });
            this.setState({ errorbar: true });
          }
        }
        this.setState({loader : false});
      }else{

        await this.setState({wrongPasswordMessage : "Password dosen't match"});
      }

    }
    else{
      await this.setState({wrongPasswordMessage : "Password is weak ! Should be of atleast 8 characters with one capital letter and one small letter. Must include one number and special characters"})
    }
  }

  handleLogin = async (e) => {
    if(e)
      e.preventDefault();
    let { username, password } = this.state;
    let config = JSON.parse(JSON.stringify(CONFIGURATION))

    this.setState({ loader: true })
    try {
      var user = await Auth.User.login(username, password);
      let response = await Auth.User.getUser();
      if(!user.data.user.terms_and_condition){
        this.setState({ showTerms : true,  loader: false })
        return false;
      }
      if(!user.data.user.current_role_id){
        this.setState({ firstTimeLogin : response.data[0], loader: false })
        return false;
      }

      this.setState({ loader: false })
      let userInfo = response.data[0];
      userInfo.roles = await getRoleDetails(userInfo.roles)
      let role = userInfo.roles.filter(r=>r.assigned_role_id===userInfo.current_role_id)[0];
      if (!role) {
        role = userInfo.roles[0] || { assigned_role_id: 0, role_type: "NONE" }
      }
      await this.props.setRole(role);
      this.props.setUser({...userInfo, config});
    } catch (e) {
      this.setState({ loader: false })
      // if(e.response.data.error.toString() !== null){
      if (e.response) {
        console.log(e.response)
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }

    }
  }
  updateTerms = async (value) => {
    this.setState({ loader: true })
    if(value){
      try{

        let { username, password } = this.state;
        await Auth.User.updateProfile({ terms_and_condition: 1 })
        await this.handleLogin()
        this.setState({showTerms : false, loader: false})
      }
      catch(e){
        if (e.response) {
          this.setState({ message: e.response.data.error.toString() });
          this.setState({ errorbar: true });
        } else {
          this.setState({ message: 'Sorry! something went wrong. Please try again' });
          this.setState({ errorbar: true });
        }
      }
    }else{
      this.setState({showTerms : false, firstTimeLogin : false})
      this.setState({ loader: false })
    }

  }
  render() {
    let { username, password ,setNewPassword, confirmNewPassword,  firstTimeLogin, newPassword, confirmPassword, showTerms} = this.state;
    return (
      <Container fluid={true} className="px-0">
        <Row noGutters className="r-login">
          <Col xs={12} md={6} className="r-login__placeholder">
            <div className="r-login__placeholder__text">
              <div>It's here.</div>
              <div>Better than ever.</div>
              <p className="r-text--md mt-3">To know why we've changed, <a href="https://reculta.com">read here</a>.</p>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <Row className="h-100 align-items-center justify-content-center" noGutters>
              <Col xs={11} sm={10} md={8} lg={6}>
                <div className="mb-5">
                  <img src="/images/reculta_black.png" className="r-login__logo" alt="Reculta" />
                  <h1 className="r-heading-2">Campus Portal</h1>
                </div>
                {showTerms ? <TermsCondition updateTerms={this.updateTerms }/> : "" }
                {
                firstTimeLogin ?
                <form noValidate onSubmit={this.handleResetOldPassword}>
                <h1 className="r-subheading-3">Welcome to Reculta! Let's start by setting your own password.</h1>
                  <div className="mt-4">
                    <label className="r-heading-6 d-block">New Password</label>
                    <InputField className="w-100" value={newPassword} name="newPassword" type="password" onChange={this.handleChange} />
                  </div>
                  <div className="mt-4">
                    <label className="r-heading-6 d-block">Confirm New Password</label>
                    <InputField className="w-100" value={confirmPassword} name="confirmPassword" type="password" onChange={this.handleChange} />
                  </div>
                  <div className="mt-3 r-text--grey">
                    <div className="d-flex mt-5">
                      <Button className="r-btn--no-radius" color="dark" type="submit">Change Password</Button>
                    </div>
                  </div>
                </form>
                :
                <Aux>
                {this.state.forgotPasswordValue!=1?
                  <Aux>
                  {this.state.showNewPasswordBox!=1?
                    <form noValidate onSubmit={this.handleLogin}>
                    <div className="mt-4">
                    {this.props.location.state && <span className="r-text--green-1">{'*'}{this.props.location.state.msg}</span>}
                    </div>
                    <div className="mt-4">
                    <label className="r-heading-6 d-block">Email</label>
                    <InputField name="username" value={username} onChange={this.handleChange} className="w-100" type="text" placeholder="myname@harvard.edu" />
                    </div>
                    <div className="mt-4">
                    <label className="r-heading-6 d-block">Password</label>
                    <InputField className="w-100" value={password} name="password" type="password" onChange={this.handleChange} />
                    </div>
                    <div className="mt-3 r-text--grey">
                    <InputField type="checkbox" value="Keep me logged in" />
                    <div className="d-flex mt-5">
                    <Button className="r-btn--no-radius" color="dark" type="submit">Log In</Button>
                    <Button type="button" className="ml-4 r-btn--no-radius" type="text" color="link" onClick={()=>this.setState({forgotPasswordValue:1})} >Forgot Password?</Button>
                    </div>
                    </div>
                    </form>: <form noValidate onSubmit={this.handleRetievePassword}>
                    <div className="mt-4">
                    <label className="r-heading-6 d-block">New Password</label>
                    <InputField className="w-100" value={setNewPassword} name="setNewPassword" type="password" onChange={this.handleChange} />
                    </div>
                    <div className="mt-4">
                    <label className="r-heading-6 d-block">Confirm New Password</label>
                    <InputField className="w-100" value={confirmNewPassword} name="confirmNewPassword" type="password" onChange={this.handleChange} />
                    </div>
                    <div className="mt-3 r-text--grey">
                    {this.state.wrongPasswordMessage && <span className="r-text--red-1">{this.state.wrongPasswordMessage}</span>}
                    </div>
                    <div className="d-flex mt-5">
                    <Button className="r-btn--no-radius" color="dark" type="submit"> Confirm </Button>
                    <Button type="button" className="ml-4 r-btn--no-radius" type="text" color="link" onClick={()=>this.setState({showNewPasswordBox: null})} >Cancel</Button>
                    </div>
                    </form>
                  }
                </Aux>:  <form noValidate onSubmit={this.handleForgotPassword}>
                    <div className="mt-4">
                    <label className="r-heading-6 d-block">Email</label>
                    <InputField name="username" value={username} onChange={this.handleChange} className="w-100" type="text" placeholder="myname@harvard.edu" />
                    </div>
                    <div className="mt-4">
                    {this.state.forgotPasswordMessage && <span className="r-text--green-1">{this.state.forgotPasswordMessage}</span>}
                    </div>
                    <div className="d-flex mt-5">
                    <Button className="r-btn--no-radius" color="dark" type="submit">Forgot Password</Button>
                    <Button type="button" className="ml-4 r-btn--no-radius" type="text" color="link" onClick={()=>this.setState({forgotPasswordValue: null,forgotPasswordMessage:null})} >Log In</Button>
                    </div>
                    </form>
                }
                </Aux>
              }
    
              </Col>
            </Row>
              <Policies />
          </Col>
        </Row>
        <Loader show={this.state.loader}></Loader>
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
      </Container>

    )
  }
}
const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoading: (show) => dispatch(setLoading(show)),
  setRole: (role) => dispatch(setRole(role))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
