import React, { Component } from 'react';
import Aux from '../../../../global/AuxComp';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import moment from 'moment';
import Collapse from 'reactstrap/lib/Collapse';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { ProfileActions } from './profile-actions';
import Button from '../../../../global/Button';
import AutosuggestComp from '../../../../global/AutosuggestComp';
import { Auth } from '../../../../../actions/Auth';
import { CONSTANTS } from '../../../../../constants';
import { DateRangePicker, SingleDatePicker } from '../../../../global/Forms/DatePicker';
import { Select, YearSelect, MonthSelect } from '../../../../global/Forms/Select';
import { InputField } from '../../../../global/Forms';
import { filterCVPoints } from '../../../../../actions';
import { CVPoint } from './cv-point';
import { FileUpload } from '../../../../global/FileUpload';
import { AddSuggestion } from '../../../../global/Forms/AddSuggestion';
import { Errorbar } from '../../../../global/errorbar';
import { Switch } from '../../../../global/Forms/ToggleSwitch';

export class ProfessionalExperience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      deleteId: null,
      suggestions: {
        getDesignations: [],
        getSectors: [],
        getIndustries: [],
        getCompanies: [],
        getDomains : []
      },
      autoSuggestValues: {
        designation: "",
        sector: "",
        industry: "",
        company: "",
        domain: ""
      },
      errorbar: false,
      message: '',
    };

    this.autoSuggestProps = {
      className: "w-100 r-inputfield",
      onChange: this.handleFieldChange
    }
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  handleFieldChange = (e, pro) => {
    let { autoSuggestValues } = this.state;
    if (e.target.name === "designation") this.setValuefromSuggestion({ designation_id: null, designation_name: "" }, "designation", pro)
    if (e.target.name === "company") this.setValuefromSuggestion({ company_id: null, company_name: "" }, "company", pro)
    if (e.target.name === "sector") this.setValuefromSuggestion({ sector_id: null, sector_name: "" }, "sector", pro)
    if (e.target.name === "industry") this.setValuefromSuggestion({ industry_id: null, industry_name: "" }, "industry", pro)
    if (e.target.name === "domain") this.setValuefromSuggestion({ domain_id: null, domain_name: "" }, "domain", pro)
    autoSuggestValues[e.target.name] = e.target.value;
    this.setState({
      autoSuggestValues
    })
  }

  isValid = (pro) => {
    let { designation, company, sector, industry } = this.state.autoSuggestValues
    let { professional_id, company_id, industry_id, designation_id, sector_id, experience_type } = pro;
    if (!professional_id || !company || !industry || !designation || !sector || !experience_type) {
      this.setState({ message: 'Please fill all mandatory input fields' });
      this.setState({ errorbar: true });
      return false
    }
    return true
  }

  handleProDetailSubmit = async (e, pro) => {
    let { designation, company, sector, industry, domain } = this.state.autoSuggestValues
    if (e) e.preventDefault(e);
    if (!this.isValid(pro)) {
      return
    }
    try {
      this.props.toggleLoading(true);
      designation = designation.replace(/(^|\s)\S/g, l => l.toUpperCase());
      if (!pro.designation_id) {
        let desgResp = await Auth.General.addDesignation({ designation_name: designation })
        if (desgResp.data.insertId) {
          pro.designation_id = desgResp.data.insertId
          pro.designation_name = designation
        }
      }
      if (!pro.sector_id) {
        let sectorResp = await Auth.General.addSector({ sector_name: sector })
        if (sectorResp.data.insertId) {
          pro.sector_id = sectorResp.data.insertId
          pro.sector_name = sector
        }
      }
      if (!pro.industry_id) {
        let industryResp = await Auth.General.addIndustry({ industry_name: industry, sector_id: pro.sector_id })
        if (industryResp.data.insertId) {
          pro.industry_id = industryResp.data.insertId
          pro.industry_name = industry
        }
      }
      if (!pro.domain_id && domain) {
        let domainResp = await Auth.General.addDomain({ domain_name: domain })
        if (domainResp.data.insertId) {
          pro.domain_id = domainResp.data.insertId
          pro.domain_name = domain
        }
      }
      if (!pro.company_id) {
        let companyResp = await Auth.General.addCompany({ company_name: company, sector_id: pro.sector_id, industry_id: pro.industry_id })
        if (companyResp.data.insertId) {
          pro.company_id = companyResp.data.insertId
          pro.company_name = company
        }
      }
      let method = Auth.Student.updateProExpDetail,
        formData = Object.assign({}, pro);
      formData.start_date = moment(formData.start_date).format(CONSTANTS.DATE_FORMAT)
      if(formData.currently_working)
        formData.end_date= moment()
      formData.end_date = moment(formData.end_date).format(CONSTANTS.DATE_FORMAT)
      if (typeof pro.professional_id == "string") {
        method = Auth.Student.createProExpDetail;
        formData.student_id = this.props.user_info.student_id;
        delete formData.professional_id
      }
      await method(formData)
      this.props.getStudentProfile()
      if (e) this.props.toggleSection("");
    } catch (e) {

      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }

    }
    this.props.toggleLoading(false);
  }

  deleteProDetail = async () => {
    let { deleteId } = this.state;
    if (!deleteId) return;
    let formData = {
      student_id: this.props.user_info.student_id,
      professional_id: deleteId,
      status: 0
    }
    this.props.toggleLoading(true)
    try {
      if (typeof deleteId == "number")
        await Auth.Student.updateProExpDetail(formData)
      this.props.changeProDetail(formData, true)
      this.toggleModal()
      this.props.getStudentProfile()
    } catch (e) {
      if (e.response.data.error.toString() !== null) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
    this.props.toggleLoading(false)
  }

  toggleModal = (id) => {
    this.setState({
      modalShow: !this.state.modalShow,
      deleteId: id
    })
  }

  setValuefromSuggestion = (value, name, pro) => {
    let { autoSuggestValues } = this.state,
      proName = name + "_name",
      evtObj = {
        target: {
          name: name + "_id",
          value: value[name + "_id"]
        }
      }
    autoSuggestValues[name] = value[proName]
    pro[proName] = value[proName]
    this.setState({
      autoSuggestValues
    })
    this.handleProChange(evtObj, pro)
  }

  changeYear = (e, field, method, pro) => {
    let date = pro[field] ? moment(pro[field]) : moment();
    pro[field] = date[method](e.target.value).format(CONSTANTS.DATE_FORMAT);
    this.props.changeProDetail(pro);
  }

  handleDateChange = (mDate, field, pro) => {
    pro[field] = mDate
    this.props.changeProDetail(pro);
  }

  handleProChange = (e, pro) => {
    pro[e.target.name] = e.target.value;
    this.props.changeProDetail(pro)
  }

  addProExp = (e) => {
    let { autoSuggestValues } = this.state;
    e.preventDefault();
    this.props.toggleSection(`PRO-0`);
    autoSuggestValues.designation = ""
    autoSuggestValues.sector = ""
    autoSuggestValues.industry = ""
    autoSuggestValues.company = ""
    autoSuggestValues.domain = ""

    this.props.changeProDetail({});
    this.el = e.target.closest(".student__profile__container")
    setTimeout(() => {
      if (this.el)
        this.el.scrollIntoView({ behavior: "smooth", block: "start" })
    }, 300)
  }

  setAutoSuggestionValues = (pro) => {
    let { autoSuggestValues } = this.state;
    autoSuggestValues.designation = pro.designation_name || ""
    autoSuggestValues.sector = pro.sector_name || ""
    autoSuggestValues.industry = pro.industry_name || ""
    autoSuggestValues.company = pro.company_name || ""
    autoSuggestValues.domain = pro.domain_name || ""
    this.setState({
      autoSuggestValues
    })
  }

  workExp = (pro) => {
      let newEndDay = (pro.end_date !== null)?pro.end_date:new Date() ;
      return moment(moment(newEndDay).format('YYYY-MM-DD')).diff(moment(pro.start_date), "month", true);
      // let newEndDay = (pro.end_date !== null)?pro.end_date:new Date() ;
      // let startYear = moment(pro.start_date).format('YYYY');
      // let endyear = moment(newEndDay).format('YYYY');
      // let startMonth = moment(pro.start_date).format('MM');
      // let endMonth = moment(newEndDay).format('MM');
      // if((startYear == endyear)&&(startMonth == endMonth)){
      //     let start_date_Month_day = moment(moment(pro.start_date).format('YYYY-MM-DD '), "YYYY-MM").daysInMonth();
      //     let dayInStartMonth = moment(moment(newEndDay).format('YYYY-MM-DD')).diff(moment(moment(pro.start_date).format('YYYY-MM-DD ')), "days");
      //     return (dayInStartMonth+1)/start_date_Month_day;
      // }
      // else{
      //     let start_date_Month_day = moment(moment(pro.start_date).format('YYYY-MM-DD '), "YYYY-MM").daysInMonth();
      //     let end_date_Month_day = moment(moment(newEndDay).format('YYYY-MM-DD'), "YYYY-MM").daysInMonth();
      //     let endOfStartDateMonth   = moment(moment(pro.start_date).format('YYYY-MM-DD ')).endOf('month').format('YYYY-MM-DD ');
      //     let startOfEndDateMonth   = moment(moment(newEndDay).format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD ');
      //     let dayInStartMonth = moment(endOfStartDateMonth).diff(moment(moment(pro.start_date).format('YYYY-MM-DD ')), "days");
      //     let dayInEndMonth = moment(moment(newEndDay).format('YYYY-MM-DD')).diff(moment(startOfEndDateMonth), "days");
      //     let monthBetween = moment(startOfEndDateMonth).diff(moment(endOfStartDateMonth), "months");
      //     return monthBetween+(dayInStartMonth+1)/start_date_Month_day+(dayInEndMonth+1)/end_date_Month_day;
      // }
    
  }

  render() {
    let { files, editableSection, toggleSection, professional_detail, onCancel, cv_point, commentThreads, sectorList, industryList } = this.props;
    let { autoSuggestValues, suggestions } = this.state;
    let totalExp = 0, totalYear, totalduration;
    professional_detail.forEach(pro => {
    if (pro.experience_type == "FULL_TIME") {
      totalExp += this.workExp(pro);
    }
    })
    if(totalExp >=12){
        totalYear = Math.floor(totalExp/12);
        let totalMonth = totalExp%12;
        totalduration = totalYear+' Year ' + Math.round(totalMonth)  + ' months';
    } 
    else if(totalExp<12){
      totalduration = ((Math.round(totalExp))<2)?(Math.round(totalExp) + ' month'):(Math.round(totalExp) + ' months')
    }
    this.cvPoints = filterCVPoints(cv_point, "PROFESSIONAL")
    return (
      <Aux>
        <div className="r-card student__profile__container">
          <Row noGutters className="">
            <Col xs={12}>
              <h2 className="r-heading-4 r-text--blue-3">Professional Experience | <span className="r-subheading-2 r-text--blue-3">{totalduration} </span></h2>
              {/* {

                this.state.totalExp!=0?<span className="r-text--grey-4">Total Experience {this.state.totalExp}</span>:null
              } */}

              {editableSection === "" && <div className="student__profile__actions">
                <Button onClick={this.addProExp} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
              </div>}
            </Col>
          </Row>
          {
            professional_detail.length > 0 ? professional_detail.map((pro, i) => {
              let duration, currentYear;
              if (pro.experience_type == "FULL_TIME") {
                if(this.workExp(pro) >=12){
                    currentYear = Math.floor(this.workExp(pro)/12);
                    let currentMonth = (this.workExp(pro))%12;
                    duration = currentYear+' Year ' + Math.round(currentMonth)  + ' months';
                } 
                else if(this.workExp(pro)<12){
                  let x = this.workExp(pro);
                  duration = (x<2)?Math.round(x) + ' month':Math.round(x) + ' months'
                }
              }
              return (
                <div className={"student__profile__section" + (pro.freeze_status == 1 ? " student__profile--freeze" : "")} key={pro.professional_id}>
                  <Collapse isOpen={editableSection !== `PRO-${i}`}>
                    <h1 className="r-subheading-2 mr-3" style={{ fontWeight: 600 }}>
                      {pro.designation_name || <em>Designation</em>}
                    </h1>
                    <h1 className="r-subheading-2 mt-3" style={{ fontWeight: 400 }}>{pro.company_name || <em>Company</em>}</h1>
                    <span className="r-text--grey-4">{pro.start_date ? moment(pro.start_date).format("MMM YYYY") : <em>Start Month/Year</em>} - {pro.currently_working?"Currently working" : (pro.end_date ? moment(pro.end_date).format("MMM YYYY") : <em>End Month/Year</em>)}{duration? (" | " + duration): ""}</span>
                    <div className="r-text--grey-4 mb-2">{pro.location || <em>Location</em>}</div>
                    {/* <div>{pro.sector_name || <em>Sector</em>}</div>
                    <p className="r-subheading-3">{pro.industry_name || <em>Industry</em>}</p> */}
                    {(files[pro.file]) && <Row noGutters>
                      {files[pro.file].map((doc, i) => <Col className="pr-3" key={i} xs="auto">
                        <div className="r-tag r-tag--type-1 r-tag--no-delete">
                          <a href={doc.url} target="_blank">{doc.file_name || doc.file_url || "Filename"}</a>
                        </div>
                      </Col>)}
                    </Row>}
                  </Collapse>
                  <Collapse isOpen={editableSection === `PRO-${i}`} onEntering={() => this.setAutoSuggestionValues(pro)}>
                    <form onSubmit={(e) => this.handleProDetailSubmit(e, pro)}>
                      <Row form className="mb-2">
                        <Col xs={12}>
                          <div className="d-flex justify-content-between">
                            <label className="r-heading-7"><span className="r-text--red-1">*</span>Designation</label>
                            {/* <AddSuggestion type="designation" /> */}
                          </div>
                          <AutosuggestComp
                            inputProps={{
                              className: "w-100 r-inputfield",
                              onChange: (e) => this.handleFieldChange(e, pro),
                              placeholder: '',
                              value: autoSuggestValues.designation,
                              name: "designation"
                            }}
                            suggestionID="getDesignations"
                            renderSuggestion={(desg) => <div>{desg.designation_name}</div>}
                            setValue={(value) => this.setValuefromSuggestion(value, "designation", pro)} />
                        </Col>
                      </Row>
                      <Row form className="mb-2">
                        <Col xs={12}>
                          <div className="d-flex justify-content-between">
                            <label className="r-heading-7"><span className="r-text--red-1">*</span>Organization</label>
                            {/* <AddSuggestion type="organization" /> */}
                          </div>
                          <AutosuggestComp
                            inputProps={{
                              className: "w-100 r-inputfield",
                              onChange: (e) => this.handleFieldChange(e, pro),
                              placeholder: '',
                              value: autoSuggestValues.company,
                              name: "company"
                            }}
                            suggestionID="getCompanies"
                            renderSuggestion={(comp) => <div>{comp.company_name}</div>}
                            setValue={(value) => this.setValuefromSuggestion(value, "company", pro)} />
                        </Col>
                      </Row>

                      <Row form className="mb-2">

                        <Col xs={6}>
                          <div className="d-flex justify-content-between">
                            <label className="r-heading-7"><span className="r-text--red-1">*</span>Sector</label>
                            {/* <AddSuggestion type="sector" /> */}
                          </div>
                          {/* <Select name="sector_id" value={pro.sector_id} onChange={(e) => this.handleProChange(e, pro)}>
                            <option value="">Select</option>
                            {sectorList}
                          </Select> */}
                          <AutosuggestComp
                            inputProps={{
                              className: "w-100 r-inputfield",
                              onChange: (e) => this.handleFieldChange(e, pro),
                              placeholder: '',
                              value: autoSuggestValues.sector,
                              name: "sector"
                            }}
                            suggestionID="getSectors"
                            renderSuggestion={(sec) => <div>{sec.sector_name}</div>}
                            setValue={(value) => this.setValuefromSuggestion(value, "sector", pro)} />
                        </Col>
                        <Col xs={6}>
                          <div className="d-flex justify-content-between">
                            <label className="r-heading-7"><span className="r-text--red-1">*</span>Industry</label>
                            {/* <AddSuggestion type="industry" /> */}
                          </div>
                          {/* <Select name="industry_id" value={pro.industry_id} onChange={(e) => this.handleProChange(e, pro)}>
                            <option value="">Select</option>
                            {industryList}
                          </Select> */}
                          <AutosuggestComp
                            inputProps={{
                              className: "w-100 r-inputfield",
                              onChange: (e) => this.handleFieldChange(e, pro),
                              placeholder: '',
                              value: autoSuggestValues.industry,
                              name: "industry"
                            }}
                            suggestionID="getIndustries"
                            renderSuggestion={(ind) => <div>{ind.industry_name}</div>}
                            setValue={(value) => this.setValuefromSuggestion(value, "industry", pro)} />
                        </Col>
                      </Row>

                      <Row form className="mb-2">
                        <Col xs={6}>
                            <div className="d-flex justify-content-between">
                              <label className="r-heading-7">Description / Title</label>
                            </div>
                            <InputField className="w-100" type="text" name="role_description" value={pro.role_description || ""} onChange={(e) => this.handleProChange(e, pro)} />

                             </Col>

                        <Col xs={6}>
                            <div className="d-flex justify-content-between">
                              <label className="r-heading-7">Domain</label>
                            </div>
                            <AutosuggestComp
                              inputProps={{
                                className: "w-100 r-inputfield",
                                onChange: (e) => this.handleFieldChange(e, pro),
                                placeholder: '',
                                value: autoSuggestValues.domain,
                                name: "domain"
                              }}
                              suggestionID="getDomains"
                              renderSuggestion={(dom) => <div>{dom.domain_name}</div>}
                              setValue={(value) => this.setValuefromSuggestion(value, "domain", pro)} />
                      

                        </Col>
                      </Row>

                      <Row form className="mb-2">

                        <Col xs={6}>
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Type</label>
                          <Select name="experience_type" value={pro.experience_type || ""} onChange={(e) => this.handleProChange(e, pro)}>
                            <option value>Select</option>
                            {
                              Object.keys(CONSTANTS.EXPERIENCE_TYPES).map(type => {
                                return (
                                  <option key={type} value={type}>{CONSTANTS.EXPERIENCE_TYPES[type]}</option>
                                )
                              })
                            }
                          </Select>
                        </Col>
                        <Col xs={6}>
                          <label className="r-heading-7">Location</label>
                          <InputField className="w-100" type="text" name="location" value={pro.location || ""} onChange={(e) => this.handleProChange(e, pro)} />
                        </Col>
                      </Row>
                      <Row form className="mb-2">
                        <Col xs={6}>
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Start Date</label>
                          <SingleDatePicker
                            value={pro.start_date}
                            onDateChange={(date) => this.handleDateChange(date, "start_date", pro)}
                            id={`pro_start_${pro.professional_id}`} />
                          {/* <Row noGutters>
                            <Col xs={6}>
                              <MonthSelect onChange={(e) => this.changeYear(e, "start_date", "month", pro)} value={pro.start_date ? moment(pro.start_date).month() : ""} />
                            </Col>
                            <Col xs={6}>
                              <YearSelect onChange={(e) => this.changeYear(e, "start_date", "year", pro)} value={pro.start_date ? moment(pro.start_date).year() : ""} />
                            </Col>
                          </Row> */}
                        </Col>
                        <Col xs={6}>
                          <Row className="justify-content-between align-items-center">
                            <Col xs="auto">
                            <label className="r-heading-7"><span className="r-text--red-1">*</span>End Date</label>
                            </Col>
                            <Col className="d-flex align-items-center" xs="auto">
                              <span className="r-text--sm mr-2">Currently working</span>
                              <Switch isOn={pro.currently_working == 1 ? true : false} handleToggle={(e) => this.handleProChange({ target: { name: "currently_working", value: pro.currently_working ? 0 : 1 } }, pro)} />
                            </Col>
                          </Row>
                          <SingleDatePicker
                            value={pro.end_date}
                            onDateChange={(date) => this.handleDateChange(date, "end_date", pro)}
                            id={`pro_end_${pro.professional_id}`}
                            pickerProps={{disabled:pro.currently_working?true:false}} />
                          {/* <Row noGutters>
                            <Col xs={6}>
                              <MonthSelect onChange={(e) => this.changeYear(e, "end_date", "month", pro)} value={pro.end_date ? moment(pro.end_date).month() : ""} />
                            </Col>
                            <Col xs={6}>
                              <YearSelect onChange={(e) => this.changeYear(e, "end_date", "year", pro)} value={pro.end_date ? moment(pro.end_date).year() : ""} />
                            </Col>
                          </Row> */}
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end mt-3">
                        <Button type="button" onClick={() => onCancel()} className="r-text--sm">Cancel</Button>
                        <Button type="submit" className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
                      </div>
                      {typeof pro.professional_id == "number" && <FileUpload toggleLoading={this.props.toggleLoading} updateData={this.props.changeProDetail} submitData={this.handleProDetailSubmit} verification={{ user_id: this.props.user_info.user_id, data: pro, files }} />}
                    </form>
                  </Collapse>
                  {typeof pro.professional_id == "number" && <CVPoint getStudentProfile={this.props.getStudentProfile}  settings={this.props.settings} toggleCV={this.props.toggleCV} files={files} toggleLoading={this.props.toggleLoading} user_info={this.props.user_info} profile_id={pro.professional_id} type="PROFESSIONAL" changeCVPoint={this.props.changeCVPoint} cvPoints={this.cvPoints[pro.professional_id] || []} commentThreads={commentThreads} />}
                  {editableSection !== `PRO-${i}` && <ProfileActions delete={() => this.toggleModal(pro.professional_id)} verification={{ ...pro }} toggleSection={() => toggleSection(`PRO-${i}`)} isRequiredValidate={true} commentThreads={commentThreads} profileType="PROFESSIONAL" />}
                </div>
              )
            }) : <div className="student__profile__section student__profile__section--placeholder">
                <h1 className="r-subheading-2 mr-3" style={{ fontWeight: 600 }}>
                  Designation
            </h1>
                <h1 className="r-subheading-2 mt-3" style={{ fontWeight: 400 }}>Company</h1>
                <span className="r-text--grey-4">Start Date - End Date | Duration</span>
                <div className="r-text--grey-4 mb-2">Location</div>

              </div>
          }


          <Modal className="modal--delete" isOpen={this.state.modalShow} toggle={() => this.toggleModal()}>
            <ModalBody>
              <p>Are you sure?</p>
              <div className="text-right">
                <Button className="r-text--sm" onClick={() => this.toggleModal()}>Cancel</Button>
                <Button color="dark" onClick={this.deleteProDetail} className="r-text--sm r-btn--no-radius">Delete</Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
      </Aux>
    )
  }
}
