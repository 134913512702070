import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from '../../../../global/Button';
import Aux from '../../../../global/AuxComp';
import { CONSTANTS } from '../../../../../constants';
import CommentBox from './comment_box';

export class ProfileActions extends Component {
  constructor(props) {
    super(props);
    this.popoverName = props.profileType + '_' + (Math.floor(Math.random() * 10000));
    this.state = {
      isOpenCommentBox: false
    }
  }
  toggle = (e) => {
    let isCVPoint = e.target.closest(".cv-point") ? true : false;
    this.el = e.target.closest(".student__profile__section");
    this.props.toggleSection();
    if (!isCVPoint)
      setTimeout(() => {
        if (this.el) {
          let rect = this.el.getBoundingClientRect();
          let isVisible = (
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
          );
          if (!isVisible) {
            this.el.scrollIntoView({ block: "start" })
            window.scrollBy(0, -60)
          }
        }
      }, 300)
  }

  toggleCommentBox = () => {
    this.setState({ isOpenCommentBox: !this.state.isOpenCommentBox })
  }


  componentDidMount() {

  }


  render() {
    let { isRequiredValidate, verification, commentThreads, profileType } = this.props;
    var isCommentThreadExist = false;
    let { isOpenCommentBox } = this.state;
    var verification_class = [`student__profile__verification`];
    let verifierCommentThreadId;
    if (verification) {
      verification_class.push(`student__profile__verification--${CONSTANTS.VERIFICATION[verification.is_verified || 0]}`);
      var profile_id = verification[CONSTANTS.PROFILE_TYPES_ID[profileType]];
      if (commentThreads && commentThreads.filter(data => (data.profile_type === profileType) && ((profile_id && data.profile_id === profile_id) || !profile_id)).length > 0) {
        verifierCommentThreadId = commentThreads.filter(data => (data.profile_type === profileType) && ((profile_id && data.profile_id === profile_id) || !profile_id))[0].verifier_comment_thread_id;
        isCommentThreadExist = true;
        verification_class.push(`student__profile__verification--comment fi flaticon-chat`);
      }
    }

    return (
      <Aux>
        <div className="student__profile__actions">
          {isRequiredValidate ?
            <Aux>

              <span className="student__profile__actions__btn" >


                <Button onClick={this.toggle} className="fi flaticon-edit-2" />
                {this.props.delete && <Button onClick={this.props.delete} className="fi flaticon-bin" />}
              </span>
              {verification &&
                <Aux>
                  <Button className="px-1" id={this.popoverName} onClick={this.toggleCommentBox}>
                    <span className={verification_class.join(" ")}>{CONSTANTS.VERIFICATION[verification.is_verified || 0]} </span>

                  </Button>
                  {isOpenCommentBox && isCommentThreadExist && <CommentBox toggleCommentBox={this.toggleCommentBox} popoverName={this.popoverName} isOpenCommentBox={isOpenCommentBox} commentThreads={commentThreads} profileType={profileType} verifierCommentThreadId={verifierCommentThreadId} />}
                </Aux>
              }
            </Aux> :
            <Button onClick={this.toggle} className="student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>}
        </div>
      </Aux>
    )
  }
}
