import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Row,Col,Container,Collapse} from 'reactstrap';
import Button from '../../global/Button';
import { InputField } from '../../global/Forms';
import { Switch } from '../../global/Forms/ToggleSwitch';
import AppendCourse from './append_course';

  export default class AppendCampus extends Component{
    constructor(props){
       super(props);
         this.state = { isOn: false, addressCollapse : false, appendCollapse : [] };
     }

	  handleToggle(e) {
  		this.setState({isOn: !this.state.isOn});
  	}
	  toggleAddressCollapse = (e) => {
  		this.setState({addressCollapse: !this.state.addressCollapse});
  	}

    render(){
      let { universityIndex, collegeIndex,campusData, modifyObjDetails,  campusIndex, createAppend, removeAppend, addDetailsExistedList} = this.props;
      return (
        <div>
          <Row className="align-items-center">
            <Col xs={8} className="offset-1" >
              <h1 className="r-subheading-4">Campus Name</h1>
              <InputField onChange={(e) => modifyObjDetails(universityIndex, collegeIndex,campusIndex, null, 'name', e.target.value)}  className="w-100" type="text" />
              <Button onClick={this.toggleAddressCollapse} className="r-text--sm ml-3 location_icon" color="link-blue"><i className="d-inline-block fi flaticon-pin"></i></Button>
                <Collapse className="my-3" isOpen={this.state.addressCollapse}>
                <Row className="align-items-center">
                  <Col> <h1 className="r-subheading-4">City</h1> <InputField onChange={(e) => modifyObjDetails(universityIndex, collegeIndex,campusIndex, null, 'city', e.target.value)} className="w-100" type="text" /></Col>
                  <Col> <h1 className="r-subheading-4">State</h1><InputField onChange={(e) => modifyObjDetails(universityIndex, collegeIndex,campusIndex, null, 'state', e.target.value)} className="w-100" type="text" /></Col>
                  <Col> <h1 className="r-subheading-4">Country</h1><InputField onChange={(e) => modifyObjDetails(universityIndex, collegeIndex,campusIndex, null, 'country', e.target.value)} className="w-100" type="text" /></Col>
                  <Col> <h1 className="r-subheading-4">Pin</h1><InputField onChange={(e) => modifyObjDetails(universityIndex, collegeIndex,campusIndex, null, 'pin', e.target.value)} className="w-100" type="text" /></Col>
                </Row>
                </Collapse>
            </Col>
            <Col xs={1}>
              <h1 className="r-subheading-4">Status</h1>
              <div className="">
              <Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)} />
              </div>
            </Col>
            <Col xs={2} className="align-middle">
              <Button  onClick={(e) => addDetailsExistedList(universityIndex, collegeIndex,campusIndex)}  className="r-text--sm ml-3" color="link-blue"><i className="d-inline-block fi flaticon-arrow rotate--90 mr-2"></i>Add New Degree</Button>
            </Col>

          </Row>

          {
            campusData && Array.isArray(campusData.campus_degree) && campusData.campus_degree.map((child, index) => <AppendCourse key={index} universityIndex={universityIndex} collegeIndex={collegeIndex} campusIndex={campusIndex} courseIndex={index} {...this.props} /> )
          }
          <Row>
            <Col xs={12} className="text-right">
              <Button onClick={() => removeAppend(universityIndex, collegeIndex, campusIndex, null)}   className="r-text--sm mt-4 py-2 px-1" color="outline-dark-v" square="true">Cancel</Button>
              <Button onClick={() => createAppend(universityIndex, collegeIndex, campusIndex, null )}  className="r-text--sm mt-4 ml-1 px-1 py-2" color="dark-v" square="true">Save</Button>
            </Col>
          </Row>
          </div>

    )
  }
}
