import React, {Component} from 'react';
import {Container , Row , Col , Button , Modal , ModalBody , ModalFooter , ModalHeader , Spinner} from 'reactstrap';
import { Auth } from '../../../../actions/Auth';
import { connect } from 'react-redux';
import { RTE } from '../../../global/Forms/RTE';
import  {InputField} from '../../../global/Forms';
import { Loader } from '../../../global/Loader';
import {Errorbar} from '../../../global/errorbar';
import { getFullName } from '../../../../actions';
import { CONSTANTS } from '../../../../constants';
import Collapse from 'reactstrap/lib/Collapse';

class EmailCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = ()=>{
    return(
      {
        searchText : '',
        loading : false,
        isLoading : false,
        companyData : [],
        startCampaignModal : false,
        description : null,
        companyObject : {},
        errorbar : false,
        message : '',
        selectedAssignee: "", // this.props.user.id,
        contactType: "",
        selectedProcess: "",
        selectedProcessCompanies: [],
        selectedCompanyStatus: "",
        contactData : {},
        excludeContacts : [],
        isOpenCompanyContact: null,
        contactDetailsData : []
      }
    )
  }
  componentDidMount() {
     this.fetchCompanyContacts();
  }

  fetchCompanyContacts = async () => {
    this.setState({loading : true});
    try{
      let contactResp = await Auth.PtCompanies.getCompanyContacts(this.props.campus_company_id);
      let contactData = contactResp.data.reduce((prev, curr) => {
        prev[curr.campus_company_id] = prev[curr.campus_company_id] || [];
        prev[curr.campus_company_id].push(curr);
        return prev;
      }, {})
      this.setState({ contactData });
    }
    catch(e){      
        this.setState({errorbar: true, message : e.response && e.response.data ? e.response.data.error.toString() : 'Something went wrong'});
    }
    this.setState({loading : false});
  }


  descriptionOnChange = (value)=>{
    this.setState({
      description: value
    })
  }

  stringToHslColor = (str)=>{
    let hash = 0;
    if(str)
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
    let h = hash % 360;
    return {bg : 'hsl('+h+', '+80+'%, '+80+'%)', text : 'hsl('+(h)+', '+80+'%, '+30+'%)'};
  }

  getCompanyList = async()=>{
    this.setState({loading : true});
    try{
      let companyResp = await Auth.PtCompanies.getCompanies();
      this.setState({companyData : companyResp.data});
    }
    catch(e){
      this.setState({ errorbar: true });
      if(e.response && e.response.data){
        this.setState({ message: e.response.data.error.toString()});
      }
      else{
        this.setState({message : 'Something went wrong'});
      }
    }
    this.setState({loading : false});
  }

  handleAllCompanySubmit = (companyData)=>{
    let checkbox = document.getElementById("all");
    let {companyObject} = this.state;
    let obj = {...companyObject};
    if(checkbox.checked==true){
      companyData.map(company=>{
        obj[company.campus_company_id] = true;
      })
    }else{
      companyData.map(company=>{
        obj[company.campus_company_id] = false;
      })
    }
    this.setState({companyObject : obj});
  }

  handleCompanySubmit = (company)=>{
    let checkbox = document.getElementById(company+'');
    let {companyObject} = this.state;
    let obj  = {...companyObject};
    if(checkbox.checked==true){
      obj[company] = true;
    }else{
      obj[company] = false;
    }
    this.setState({companyObject : obj});
  }


  handleCompanyContactsSubmit = ()=>{
    let {companyObject} = this.state;
    let companyArray = [];
    if(Object.keys(companyObject).length>0){
      Object.keys(companyObject).forEach(contact=>{
        if(companyObject[contact]==true){
          companyArray.push(contact);
        }
      })
    }
    this.sendCampaignMails(companyArray );
  }


  selectFilterProcess = async (process_id) => {
    this.setState({isLoading : true});
    try{
      if(process_id){
        let companyResp = await Auth.Processes.getCompaniesInProcess(process_id);
        this.setState({selectedProcess: process_id, selectedProcessCompanies: companyResp.data, selectedCompanyStatus: ""})
      }
      else {
        this.setState({selectedProcess: "", selectedProcessCompanies: [], selectedCompanyStatus: ""})
      }
    }
    catch(e){
      this.setState({ errorbar: true });
      if(e.response && e.response.data){
        this.setState({ message: e.response.data.error.toString()});
      }
      else{
        this.setState({message : 'Something went wrong'});
      }
    }
    this.setState({isLoading : false});
  }


 sendCampaignMails = async(companyArray)=>{
   this.setState({isLoading : true});
   if(companyArray.length>0){
     let {template} = this.props;
     try{
       let sendEmailParams = {
         entity_type : "campus_company_id",
         entity_id : companyArray,
         campus_company_id : companyArray,
         subject  : template.subject,
         sender_email : template.sender_email,
         html : template.html,
         template_id : template.template_id,
         file_id : template.file_id,
         send_now:true,
         contact_type: this.state.contactType || null,
         purpose : "Campaign",
         excluded_contacts: this.state.excludeContacts 
       }
       await Auth.EmailTemplate.sendMails(sendEmailParams);
       await this.setState(this.getInitialState());
       this.props.closeCampaignModal();
       this.props.getTemplateMailCount();

     }
     catch(e){
       this.setState({ errorbar: true });
       if(e.response && e.response.data){
         this.setState({ message: e.response.data.error.toString()});
       }
       else{
         this.setState({message : 'Something went wrong! Please try again'});
       }
     }
   }else{
     this.setState({errorbar : true , message : 'Add Companies to send campaign mails'});
   }
     this.setState({isLoading : false});
   }

   changeExcludeContact = (e, contact_id) => {
      let {excludeContacts} = this.state;
      if(e.target.checked){
        excludeContacts.splice(excludeContacts.indexOf(contact_id), 1);
      }else excludeContacts.push(contact_id)
      this.setState({excludeContacts})
   }

   showContactDetails = async (campus_company_id) => {
    let { isOpenCompanyContact, contactData } = this.state;
    if(isOpenCompanyContact === campus_company_id) isOpenCompanyContact = null;
    else{
      try{
        if(contactData[campus_company_id]){
          let resp = await Auth.PtCompanies.getCompanyContactDetails(contactData[campus_company_id].map(data => data.contact_id))
          this.setState({ contactDetailsData :  resp.data});
        }
      }
      catch(e){      
        this.setState({errorbar: true, message : e.response && e.response.data ? e.response.data.error.toString() : 'Something went wrong'});
      }
      isOpenCompanyContact = campus_company_id;
    }
    this.setState({isOpenCompanyContact})
   }

  render(){
    let { startCampaignModal,companyObject , errorbar , message , loading , searchText , isLoading, selectedProcessCompanies, contactData, excludeContacts, isOpenCompanyContact, contactDetailsData} = this.state;
    let {companyData} = this.props;
    if(this.state.selectedProcess){
      var processCompanies = [...this.state.selectedProcessCompanies];
      if(this.state.selectedCompanyStatus){
        processCompanies = processCompanies.filter(company => company.company_status === parseInt(this.state.selectedCompanyStatus))
      }
      var campusCompanyIds = processCompanies.map(company => company.campus_company_id);
      companyData = companyData.filter(company=>{
        return campusCompanyIds.indexOf(company.campus_company_id) !== -1
      });
    }
    if(this.state.selectedAssignee){
      companyData = companyData.filter(company=>{
        return company.user_id == this.state.selectedAssignee
      })
    }

    companyData = companyData.sort((a, b) => !!companyObject[b.campus_company_id] ? 1 : -1);


    if(searchText!==''|| true){
      companyData = companyData.filter(company=>{
        var companyName = company.company_details &&  company.company_details.company_name || '';
        if(companyName.toLowerCase().indexOf(searchText.toLowerCase())!==-1){
          return company;
        }
      })
    }


    return(
      <Container>
      <Loader show={isLoading} />
        <Row>
          <Col xs="4">
            <div className="m-2">
              <InputField className="w-100 r-radius--3" onChange={(e)=>this.setState({searchText : e.target.value})} placeholder="Search for Companies" />
              <i className="pt-2 r-text--grey-4 fi flaticon-search verifier__search-bar"  />
            </div>
        </Col>
      </Row>
      <Row className="justify-content-end align-items-center">
        <Col className="mt-2">
          <input type="checkbox"  className="m-3" id="all" onChange={()=>this.handleAllCompanySubmit(companyData)} /><span className="email_campaign__select-all">Select All</span>
        </Col>
        <Col xs={2}>
          <div className="r-dropdown border-0 d-inline-block">
            <select className="r-dropdown__select r-subheading-4 r-text--grey-4" value={this.state.selectedProcess || ""} onChange={(e) => {this.selectFilterProcess(e.target.value);}}>
              <option value="">Placement Drive</option>
              {
                this.props.processList.map(process => {
                  return <option key={process.process_id} value={process.process_id}>{process.process_name}</option>
                })
              }
            </select>
            <i className="fi flaticon-arrow"/>
          </div>
        </Col>
        <Col xs={2}>
          <div className="r-dropdown border-0 d-inline-block">
            <select className="r-dropdown__select r-subheading-4 r-text--grey-4" value={this.state.selectedCompanyStatus || ""} onChange={(e) => {this.setState({selectedCompanyStatus: e.target.value});}}>
              <option value="">Company Status</option>
              {
                Object.keys(CONSTANTS.COMPANY_STATUS).map(status => {
                  if (status > 0)
                    return <option key={status} value={status}>{CONSTANTS.COMPANY_STATUS[status]}</option>
                })
              }
            </select>
            <i className="fi flaticon-arrow"/>
          </div>
        </Col>
        <Col xs={2}>
          <div className="r-dropdown border-0 d-inline-block">
            <select className="r-dropdown__select r-subheading-4 r-text--grey-4" value={this.state.selectedAssignee || ""} onChange={(e) => {this.setState({selectedAssignee: e.target.value, companyObject: {}});}}>
              <option value="">Assignees</option>
              {
                this.props.ptList.map(pt => {
                  return <option key={pt.id} value={pt.user_id}>{getFullName(pt.userDetail)}{this.props.user.id === pt.user_id && " (me)"}</option>
                })
              }
            </select>
            <i className="fi flaticon-arrow"/>
          </div>
        </Col>
        <Col xs={2}>
          <div className="r-dropdown  border-0">
            <select className="r-dropdown__select r-subheading-4 r-text--grey-4" value={this.state.contactType || ""} onChange={(e) => this.setState({contactType: e.target.value})}>
              {/* <option value="">Contacts</option> */}
              <option value="">All Contacts</option>
              <option value={"HR"}>HR</option>
              <option value={"BL"}>Business Leader</option>
              <option value={"AL"}>Alumni</option>
            </select>
            <i className="fi flaticon-arrow"/>
          </div>
        </Col>
        <Col xs={2}>
          <Button color="dark" onClick={()=>this.handleCompanyContactsSubmit()}>Send Mails</Button>
        </Col>
      </Row>
      <div className="email_campaign__campgin-modal">
      {loading ? <div className="center-xy"><Spinner color="dark" /></div> : null}
        {companyData.map(company=>{
          let color = this.stringToHslColor(company.company_details.company_name);
          return(
            <>
            <Row className="align-items-center company-card py-3 mt-4 mx-3 "   >
              <Col xs="2" className="selectable email_campaign__modal-checkbox" >
                <input type="checkbox"  className="m-3" id={company.campus_company_id} onChange={()=>this.handleCompanySubmit(company.campus_company_id)} checked={companyObject[company.campus_company_id]?true : false} /><div className="rounded-circle text-center" style={{backgroundColor : color.bg, width:32, height:32, fontSize:18, color:color.text}}>
                  <span className="align-middle">{company.company_details.company_name && company.company_details.company_name.charAt(0)}</span>
                </div>
              </Col>
              <Col>
                <span className="align-middle r-subheading-3 mb-0 mt-1">{company.company_details.company_name && company.company_details.company_name}</span>
              </Col>
              <Col xs={"auto"}><i onClick={() => this.showContactDetails(company.campus_company_id)} className={`fi flaticon-arrow d-block selectable`} style={{transform: `rotate(${isOpenCompanyContact === company.campus_company_id ? 270 : 90}deg)`, transition: 'all 0.5s'}} /></Col>
            </Row>
            <Collapse isOpen={isOpenCompanyContact === company.campus_company_id} className="align-items-center company-card mx-3 ">
              <Row className="p-3">
                <Col>
                  <h5>Contacts</h5>
                  {!!contactData[company.campus_company_id] ? 
                  <>
                    {
                      contactData[company.campus_company_id].map(contact => {
                    let emailDetail = isOpenCompanyContact === company.campus_company_id ? (contactDetailsData.filter(o => contact.contact_id === o.contact_id)[0] || {}) : {};
                      return <Row className="">
                          <Col xs={2}>
                            <input type="checkbox" className="m-2" checked={excludeContacts.indexOf(contact.contact_id) === -1} onChange={(e) => this.changeExcludeContact(e, contact.contact_id)} />
                          </Col>
                          <Col xs={4}>{getFullName(contact)}</Col>
                          <Col xs={6}>{emailDetail.email_detail && emailDetail.email_detail.map(o => o.email_id).join(", ")}</Col>
                        </Row>
                      })
                    }
                  </>
                  : 
                  <span>No Contacts</span>
                  }
                </Col>
              </Row>
            </Collapse>
            </>
          )
        })
      }
      </div>
      <Errorbar showBar={this.state.errorbar} onClose={() => this.setState({errorbar : false})} message={this.state.message}/>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(EmailCampaign);
