import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import Button from '../../global/Button';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import { HorizontalLine } from '../../global/horizontal_line';
import { Row, Col, Container, Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Aux from "../../global/AuxComp";

class SpocNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getProcesList: [],
      getStudentAssigned: [],
      getStudentProfileDetails: [],
      processList: [
        { process_name: "Placement Report", process_type: "process", process_id: 3, permission: "reportPlacement", reportType: "PlacementReport" } , 
        { process_name: "Team Report", process_type: "pt", process_id: 1 , permission: "reportTeam", reportType: "TeamReport" }, 
        { process_name: "Student Report", process_type: "student", process_id: 2 , permission: "reportStudent", reportType: "StudentReport" },
        { process_name: "Meeting Report", process_type: "meeting-report", process_id: 4 , permission: "reportMeeting", reportType: "MeetingReport" },
        { process_name: "Company Report", process_type: "company-report", process_id: 5 , permission: "reportCompany", reportType: "CompanyReport" },
        { process_name: "Attendance Report", process_type: "attendance-report", process_id: 6, permission: "reportAttendance",reportType: "atendanceReport"  }],
      selectedProcess: this.props.process,
      isOn: false,
      modalstudent: false,
      modalshare: false,
      modal: false,
      modaledit: false,
    };
  }

  componentDidMount(){
  }


  onClickHandler = (process)=>{
    this.fetchApplications(process);
  }

  fetchApplications = (process) => {
    this.props.history.push(`/${this.props.role.role_type.toLowerCase()}/performance/` + process.process_type);
  }



  render() {
    let { getStudentProfileDetails, selectedProcess, processList } = this.state;
    let permissions = this.props.role.hasPermission;
   let permissionName = processList.filter(o => o.reportType === this.props.type)[0];
   let isPermitted = this.props.role.role_type !== "PT" || (permissionName && (permissions[permissionName.permission] && permissions[permissionName.permission].permission_access && permissions[permissionName.permission].permission_access.read))
    return (

      <Container className="apply">
        <Row className="justify-content-between align-items-center py-3">
          <Col xs="auto" >
            {
              processList.filter(obj => this.props.role.role_type !== "PT" || (permissions[obj.permission] && permissions[obj.permission].permission_access && permissions[obj.permission].permission_access.read)).map(process => {
                return (
                  <Button key={process.process_id} onClick={() => this.onClickHandler(process)} color={(selectedProcess.process_type === process.process_type ? "blue-3" : "blue") + " mr-3"}>{process.process_name}</Button>
                )
              })
            }
          </Col>
          {!!isPermitted &&
          <Col xs="auto" className="px-2">
          {this.props.type? <Aux>{(this.props.type==="TeamReport")?null:<Button className="font-weight-normal px-2 py-0" color="brandB" onClick={()=>this.props.downloadReport()} >Download Report</Button>} </Aux>: <Aux><Button id="download-report" className="font-weight-normal px-2 py-0" color="brandB">Download Report</Button>
          <UncontrolledTooltip placement="bottom" target="download-report">
            <span className="r-subheading-3">Coming Soon</span>
          </UncontrolledTooltip></Aux>}
          </Col>
          }
        </Row>
        <HorizontalLine color="#cfcfcf" />
      </Container>
    )
  }
}
const mapStateToProps = state => ({
  ...state
})
export default withRouter(connect(mapStateToProps, null)(SpocNav));
