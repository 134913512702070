import React, {Component} from 'react';
import moment from 'moment';
import {Row,Col,Container} from 'reactstrap';
import { Auth } from '../../../actions/Auth';
import Aux from '../../global/AuxComp';
import { Card, Button, CardTitle, CardText} from 'reactstrap';
import {Errorbar} from '../../global/errorbar';

class PendingInvites extends Component{
  constructor(props){
    super(props);
    this.state={
      invite : [],
      inviteDetails : [],
      inviteObject: [],
      params : {
       "recipient_id": this.props.recipient_id,
       "recipient_type": 1,
       "university_id": this.props.university_id,
       "college_id": this.props.college_id
     },
     errorbar : false,
     message : ''
    }
  }

  closebar = () =>{
      this.setState({errorbar: false});
  }

  async componentDidMount(){
    await this.getInvites();
  }

  getInvites = async ()=>{
    let inviteDetails =await Auth.Batch.getInviteDetails(this.state.params);
    await this.setState({
      inviteDetails:inviteDetails.data[0]
    })
    this.filterRecipient();
  }
  sendResponse = async (response,invite_id)=>{
    try{
      let params = this.state.params;
      params.invite_id = invite_id;
      params.response = response;
      await Auth.Batch.sendResponseToInvite(params);
      let {inviteObject} = this.state;
      // let inviteList = this.state.inviteObject.sort((a,b)=>a<b?1:-1);
      for(let i=0;i<inviteObject.length;i++){
        if(invite_id==inviteObject[i].invite_id){
          inviteObject.splice(i,1);
        }
      }
      await this.setState({
        inviteObject
      })
  }
  catch(e){
    this.setState({errorbar: true});
    if(e.response && e.response.data){
      this.setState({message: e.response.data.error.toString()});
    }
    else{
      this.setState({message: 'Something went wrong! Please try again'});
    }
  }
}

  filterRecipient = async ()=>{
    let inviteList = []
    let {inviteDetails} =this.state;
    if(inviteDetails && inviteDetails.recipient_details){
      inviteDetails.recipient_details.map(invite=>{
        if((this.state.params.recipient_id == invite.recipient_id) && this.state.params.recipient_type == invite.recipient_type)
        {
          inviteList.push({[invite.invite_id]:invite.response});
        }
      })}
      await this.setState({
        invite:inviteList
      })
      await this.filterInvites();
    }

    filterInvites = ()=>{
      let {inviteDetails} = this.state;
      let {invite} = this.state;
      if(invite.length!=0 && inviteDetails.length!=0){
        let inviteObject = [];
        for(let i =0; i<inviteDetails.invite_details.length; i++){
          invite.map(details=>{
            if(Object.keys(details) == inviteDetails.invite_details[i].invite_id && details[inviteDetails.invite_details[i].invite_id] == null){
              inviteObject.push({invite_id: inviteDetails.invite_details[i].invite_id,title:inviteDetails.invite_details[i].title, deadline: inviteDetails.invite_details[i].deadline} )
            }
          })
        }
        let list = [];
        if(inviteObject.length!=0){
           list = inviteObject.sort((a,b)=>(a.invite_id<b.invite_id?1:-1));
          for(let i=0;i<list.length-1;i++){
            if(list[i].invite_id==list[i+1].invite_id){
              list.splice(i,1);
            }
          }

        }
        this.setState({
          inviteObject:list
        })
      }
    }


  render(){
    return(
      <div className="w-100 ">
      {this.state.inviteObject.length!=0?this.state.inviteObject.map(invite=>{
        return(
        <Card body className="mt-3 w-100">
        <CardTitle>
        <Row>
        <Col><span className="r-subheading-3">{invite.title}</span></Col>
        </Row>

        {/*  <Row className="mt-0">
        <Col >
        <span className="r-text--grey-4 r-subheading-4" >3:35pm 17:00:00</span>
        <span> - </span>
        <span className="r-text--grey-4 r-subheading-4">3:35pm 19:00:00</span>
        </Col>
        </Row>*/}

        </CardTitle>
        <Row>
        <Col xs="auto" align="start">

        <span className="r-text--green-1 r-subheading-4 calendar__accept-reject" onClick={()=>this.sendResponse(1,invite.invite_id)}>Accept</  span>
        </Col>
        <Col xs="auto" align="start">
        <span className="r-text--red-1 r-subheading-4 calendar__accept-reject" onClick={()=>this.sendResponse(0,invite.invite_id)}>Reject</span>
        </Col>
        </Row>
        </Card>
      )
    })

      :<span className="r-subheading-4 r-text--red-1 ml-3 ">No Pending Invites...</span>
      }
      <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      </div>
    )
  }
}
export default PendingInvites;
