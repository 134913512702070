import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Collapse } from "reactstrap";
import RButton from '../../global/Button'
import { Auth } from '../../../actions/Auth';
import { InputField } from '../../global/Forms';
import store from '../../../store';
import { setLoading } from '../../../actions/action-creators';

export class CVCategory extends Component {
  state = {
    cvCategoryModal: false,
    categories: [],
    addCategory: false
  }

  init = async () => {
    store.dispatch(setLoading(true))
    try {
      let resp = await Auth.Student.getCVCategories()
      this.setState({ categories: resp.data })
    } catch (e) {
      console.log(e)
    }
    this.setState({ cvCategoryModal: true })
    store.dispatch(setLoading(false))
  }
  addCategory = async(e) => {
    e.preventDefault()
    store.dispatch(setLoading(true))
    let {cv_category} = e.target
    try {
      await Auth.Student.createCVCategory({cv_category: cv_category.value})
      this.refs.addCategory.reset()
      this.setState({addCategory: false})
    } catch(e) {
      console.log(e)
    }
    store.dispatch(setLoading(true))
    await this.init()
  }

  deleteCategory = async cv_category_id => {
    store.dispatch(setLoading(true))
    try {
      await Auth.Student.deleteCVCategory({cv_category_id, status: 0})
    } catch(e) {
      console.log(e)
    }
    store.dispatch(setLoading(true))
    await this.init()
  }
  render() {
    let { cvCategoryModal, categories, addCategory } = this.state
    return (
      <Row>
        <Col className="ml-2">
          <Button
            onClick={this.init}
            className="r-subheading-3 r-text--grey-4 position-relative pr-0"
            color="BrandA"
            style={{ fontSize: "14px" }}>
            Manage CV Categories
          </Button>
          <Modal zIndex="99999" size="md" isOpen={cvCategoryModal} toggle={() => this.setState({ cvCategoryModal: false })}>
            <ModalBody>
              <Row noGutters>
                <Col>
                  <Row noGutters className="justify-content-between">
                    <Col xs="auto">
                      <p className="r-subheading-3">CV Categories</p>
                    </Col>
                    <Col xs="auto">
                      <RButton onClick={()=>{this.refs.addCategory.reset();this.setState({addCategory: !addCategory})}} color="blue"><i className="fi flaticon-plus-1" />Add</RButton>
                    </Col>
                  </Row>
                  <Collapse isOpen={addCategory}>
                    <Row>
                      <Col>
                        <form ref="addCategory" onSubmit={this.addCategory}>
                          <InputField required className="w-100" type="text" name="cv_category" placeholder="Enter Category" />
                          <div className="text-right mt-3">
                            <RButton type="reset" onClick={()=>{this.setState({addCategory: false})}}>Cancel</RButton>
                            <RButton color="dark" className="r-btn--no-radius">Save</RButton>
                          </div>
                        </form>
                      </Col>
                    </Row>
                  </Collapse>
                  {
                    categories.map(ct =>
                      <Row key={ct.cv_category_id} noGutters className="py-2 mb-2 border-bottom justify-content-between">
                        <Col>{ct.cv_category}</Col>
                        {ct.campus_id===0 && <Col xs="auto"><span className="r-tag r-tag--no-delete">Default</span></Col>}
                        {/* <Col xs="auto"><RButton onClick={()=>this.deleteCategory(ct.cv_category_id)}><i className="fi flaticon-bin" /></RButton></Col> */}
                      </Row>
                    )
                  }
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    )
  }
}
