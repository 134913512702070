import React from 'react';
import { Row,Col,Container,  Modal, ModalHeader,Nav, NavItem,Collapse, NavLink,TabContent, TabPane, ModalBody, ModalFooter,Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import {Errorbar} from '../../../global/errorbar';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../../actions/Auth';
import { setUser, setLoading } from '../../../../actions/action-creators';
import Button from '../../../global/Button';
import {HorizontalLine} from '../../../global/horizontal_line';
import { InputField } from '../../../global/Forms';
import AutosuggestComp from '../../../global/AutosuggestComp';
import store from '../../../../store';
import classnames from 'classnames';
import EditGroup from './EditGroup.js'

class Groups extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      batchs:  [],
      courses: [],
      departments:  [],
      groups: [],
      batchdata:[],
      batch:'',
      activeTab: '1',
      departmentValue:'',
      course:'',
      dept: '',
      groupName:'',
      errorbar: false,
      setloading:false,
      degreeidList: [],
      departmentLists:[],
      columns:[{id: "sno", title: "S. No."},{id: "name", title: "Name"},{id: "roll_no", title: "Roll No."},{id:"email", title:"Email"}],
      message:'',
      isOpen: false,
      batchid: "",
      departmentid: ""

    };
  }

    componentDidMount = () => {
      this._init();
  }

  _init  = async () => {
      let {batchdata} = this.state;
     try {
      let resp = await Auth.PtStudents.getGroup();
      let batchId = [];
      let depId = [];
      if(resp.data[0]){
      batchId.push(resp.data[0].batch_id)
      depId.push(resp.data[0].degree_department_id)}
      let response = await Auth.Batch.getBatchDetail();
      this.setState({batchdata: response.data});
      this.setState({groups: resp.data});
    } catch (e) {
      console.log(e);
       }
  }

  onChange = async (e) => {
      let {batchdata} = this.state;
    if (e.target.name==="batchid"){
              this.setState({[e.target.name]: e.target.value})
              let obj = batchdata.find(o => o.batch_id === parseInt(e.target.value));
              let res = await Auth.General.getDegreesCampusDepartment(store.getState().role.instituteDetail.campus_id, obj.campus_degree_id);
              this.setState({departmentLists: res.data[0].departments});
    }
    else{
        this.setState({
            [e.target.name]: e.target.value
        })
    }
  }

   toggleOpen = () => {
    this.setState({ isOpen: true });
  }

   toggleClose = () => {
    this.setState({ group: '' });
    this.setState({ isOpen: false });
  }


  closebar = () =>{
      this.setState({errorbar: false});
  }

   toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }


  addgroup = async(event) =>{
    event.preventDefault();
      this.setState({setloading: true});
    let groupBody = {
             "batch_id": this.state.batchid,
             "degree_department_id": this.state.departmentid,
             "group_name":this.state.groupName
        }
    try {
          let resp = await Auth.PtStudents.createGroup(groupBody);
          this.setState({
            batchid:"select",
            departmentid: null,
            groupName: '',
            departmentValue: '',
          })
          this._init();
          this.setState({setloading: false});
          this.toggleClose();
        } catch (e) {
      this.setState({setloading: false});
          this.setState({errorbar: true});
          this.setState({message: e.response.data.error.toString()});
        }
  }

  async deletegroup(data){
      this.setState({setloading: true});
    let groupBody = {
          "group_id":data.group_id
        }
    try {
          let resp = await Auth.PtStudents.deleteGroup(groupBody);
          this.getGropus();
      this.setState({setloading: false});
        } catch (e) {
      this.setState({setloading: false});
          this.setState({errorbar: true});
          this.setState({message: e.response.data.error.toString()});
        }

}



  render() {
    const {batchs, batch, groupName,groups,batchdata,departmentid, dept, departments, batchid, departmentValue,departmentLists} =this.state;
     const autoSuggestProps = {
      onChange: this.onChange,
        className: "w-100 r-inputfield",

    };
    return (
        <Container className="apply">
          <Row className="justify-content-between align-items-center my-3">
            <Col xs="auto">
              <div className="heading-dropdown r-heading-3">
                <Button onClick={this.props.history.goBack} className="font-weight-normal py-0" color="blue3"><img style={{height:"30px"}} src="/images/left-arrow.png" alt=""/></Button>
                  {this.props.location.state && this.props.location.state["some"]=="state"?"Settings/ ":null}Manage Groups
              </div>
            </Col>
              <Col xs="auto">
                <Button onClick={this.toggleOpen} className="add-count" color="brandB">
                  <i className="fi flaticon-user" />
                  <span className="mx-2">Add group</span>
                  <i className="fi flaticon-plus-1 border-left pl-2" />
                </Button>
              </Col>
          </Row>
        <HorizontalLine  color ="#cfcfcf"/>
           <Row className="background-blue2 justify-content-around align-items-center  r-bg--blue-2 mx-0 px-5 py-3 mt-5">
              <Col xs="2" className="text-left px-0">
                  <span className="r-heading-3">Group Name</span>
              </Col>
              <Col xs="2"  className="text-center">
                 <span className="r-heading-3">Batch</span>
              </Col>
              <Col xs="3"  className="text-center">
                   <span className="r-heading-3">Department</span>
              </Col>
              <Col xs="3" className="text-center" >
                  <span className="r-heading-3">Members</span>
              </Col>
              <Col xs="2"  className="text-right px-2" >
                  <span className="r-heading-3">Actions</span>
              </Col>
           </Row>
           {(!this.state.isOpen)?null
              :<form onSubmit={this.addgroup}><Row className="background-blue2 justify-content-around  mx-0 px-5 my-4">
                  <Col xs="3"  className="text-left px-0">
                        <InputField className="r-nav__search" placeholder="Type your Group name..." type="text" name="groupName" value={groupName} onChange={this.onChange} required/>
                  </Col>
                   <Col xs="2" className="text-center">
                    <div className="r-dropdown border-0 ">
                        <select value={batchid} name="batchid" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={this.onChange} required>
                        <option value="" >Select Batch</option>
                          {batchdata.map(data =>{
                            return <option key={data.batch_id}  value={data.batch_id}>{data.batch_name}</option>
                          })}
                        </select>
                      <i className="fi flaticon-arrow"/>
                      </div>
                   </Col>
                       <Col xs="3" className="text-center">
                                   {(this.state.batchid !== null)?<select value={departmentid} name="departmentid" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={this.onChange} required>
                                                                       <option value="" >Select Department</option>
                                                                         {departmentLists.map(data =>{
                                                                           return <option key={data.degree_department_id}  value={data.degree_department_id}>{data.department_name}</option>
                                                                         })}
                                                                       </select>: <div className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}} required></div>}
                       </Col>
                  <Col xs="2" className="text-center">
                  </Col>
                  <Col xs="2" className="text-right px-2">
                       <Button type="submit"><i className="r-text--blue-3 d-inline-block fi flaticon-save " style={{fontSize:"15px"}}/></Button>
                       <Button type="button" onClick={this.toggleClose}><i  className="r-text--blue-3 d-inline-block fi flaticon-plus-1 rotate--45 " style={{fontSize:"15px"}}/> </Button>

                  </Col>
              </Row></form>
            }
           {this.state.setloading? <div className="center-xy"><Spinner color="dark"/></div>:null}
           {
            groups.map((data,i) =>{
              return(
                      <div key={i}>
                        <EditGroup data={data} batchdata={batchdata} updateGroup={this._init}/>
                      </div>
              )
            })
           }
                    <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      </Container>
    );
  }
}


export default Groups;
