import { combineReducers } from 'redux';
import user from './user';
import loader from './loader';
import role from './role';
import error from './error';

export default combineReducers({
  user,
  loader,
  role,
  error
});