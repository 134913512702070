import React, { Component } from 'react';
import { generateInlineStyles, resolveValuesFromObject, setValuesToObject } from '../../../actions';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import QRCode from 'qrcode.react';
import Collapse from 'reactstrap/lib/Collapse';
import Button from '../../global/Button';
import Aux from '../../global/AuxComp';

export class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      hidden: ""
    }
  }

  toggleHidden = (path) => {
    this.setState({
      hidden: path !== this.state.hidden ? path : ""
    })
  }

  hideRow = (path) => {
    this.setState({ hidden: "" })
    let config = setValuesToObject(`section_config.${path}.hide`, JSON.parse(JSON.stringify(this.props.config)), true);
    this.props.updateCVConfig(config)
  }

  showRow = (path) => {
    let config = setValuesToObject(`section_config.${path}.hide`, JSON.parse(JSON.stringify(this.props.config)), false);
    this.props.updateCVConfig(config)
  }

  move = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return this;
  };

  generateRows = (rows, configPath) => {
    const { data } = this.props;
    let hiddenRows = rows.filter(r => r.hide === true)
    return (
      <Aux>
        {hiddenRows.length > 0 && <div className="cv-builder__doc__hidden">
          <span onClick={() => this.toggleHidden(configPath)}></span>
          <Collapse isOpen={this.state.hidden === configPath}>
            {
              rows.map((row, rowId) => {
                if (row.hide === true)
                  return this.generateColumns(row, rowId, data, configPath, true, rows)
              })
            }
          </Collapse>
        </div>}
        {rows.map((row, rowId) => {
          return this.generateColumns(row, rowId, data, configPath, false, rows)
        })}
      </Aux>
    )
  }

  generateColumns = (row, rowId, data, configPath, showHidden, rows) => {
    let rowConfigPath = `${configPath}.rows.${rowId}`;
    let is_verified = (row.id && row.target) ? resolveValuesFromObject(`${row.target}.${row.id}.is_verified`, data) : 1;
    let highlightClass = is_verified === 0 ? 'cv-builder__doc--unverified ' : (is_verified === 2 ? 'cv-builder__doc--rejected ' : '')
    if (row.target && row.key && !resolveValuesFromObject(`${row.target}.${row.key}`, data))
      return null;
    if( row.point && row.id && (data.keys[row.point].indexOf(row.id)<0)){
       return null;
    }
    return (
      <Collapse isOpen={(!row.hide || showHidden)} key={`row-${rowId}`}>
        <Row tabIndex={0} className={"configurable " + ((this.props.preview && !this.props.verification) ? "" : highlightClass) + (row.class ? row.class : "")} data-configpath={`${rowConfigPath}.config`} style={generateInlineStyles(row.config)}>
          {row.key && <div className={"cv-builder__doc__tools" + (row.key === "header" ? " cv-builder__doc__tools--left" : "")}>
            {((row.target === "cv_point")||(row.point)) &&
              <Aux>
                {!row.hide && <div className="cv-builder__doc__tools__reorder">
                  <Button onClick={() => this.move(rows, rowId, rowId - 1)}><i className="fi flaticon-arrow-up r-text--brandB" /></Button>
                  <Button onClick={() => this.move(rows, rowId, rowId + 1)}><i className="fi flaticon-arrow-q r-text--brandB" /></Button>
                </div>}
                {(row.point)?null:<Button onClick={() => this.props.editCVPoint(row.key)}><i className="fi flaticon-edit-2 r-text--brandB" /></Button>}
              </Aux>
            }
            {row.hide ? <Button className="r-heading-4" onClick={() => this.showRow(rowConfigPath)}><i className="fi flaticon-eye r-text--brandD" /></Button> : <Button className="hide-btn" onClick={() => this.hideRow(rowConfigPath)}><i className="fi flaticon-hidden r-text--brandB" /></Button>}
          </div>}
          {
            row.columns.map((col, colId) => {
              let gridSize = col.grid_size ? { xs: col.grid_size } : {},
                colConfigPath = `${rowConfigPath}.columns.${colId}`,
                isHidden = false, isUnverified = false;
              if (col.class === "cvHeader" && row.columns[colId + 1] && row.columns[colId + 1].rows) {
                isHidden = ((row.columns[colId + 1].rows.filter(r => {
                  if (r.hide !== true)
                    return true
                }).length > 0)) ? false : true
                isUnverified = row.columns[colId + 1].rows.filter(r => {
                  let cv_verified = (r.id && r.target) ? resolveValuesFromObject(`${r.target}.${r.id}.is_verified`, data) : 1;
                  if (cv_verified === 1)
                    return true
                })
                if (isUnverified.length === 0)
                  col.class += " cv-builder__doc--unverified"
              }
              return (
                <Col tabIndex={0} className={"configurable " + (col.class ? col.class : "")} data-configpath={`${colConfigPath}.config`} style={generateInlineStyles(col.config)} key={`row-${rowId}-col-${colId}`} {...gridSize}>
                  {
                    col.items.map((item, itemId) => {
                      let content = resolveValuesFromObject(item.data, data, item.type, item.format);
                      if (typeof content === "undefined" || typeof content === "object") {
                        content = item.data
                      }
                      if (content.trim().endsWith(","))
                        content = content.substr(0, content.lastIndexOf(","))
                      content = content.replace(/\(\)/g, "")
                      let styles = generateInlineStyles(item.config) || {}
                      styles = item.type === "LISTITEM" ? Object.assign({}, styles, { display: 'list-item', marginLeft: '20px' }) : styles
                      return (
                        <Collapse isOpen={!isHidden} key={itemId} className="cv-item">
                          {item.data_path ? <div className="cv-builder__doc__tools cv-builder__doc__tools--left">
                            <Button onClick={()=>this.props.editCVData(item.data_path)}><i className="fi flaticon-edit-2 r-text--brandB" /></Button>
                          </div> : null}
                          {item.type === "IMAGE" ?
                            <div tabIndex={0} className={"configurable " + (col.itemsClass ? col.itemsClass : "")} data-configpath={`${colConfigPath}.items.${itemId}.config`} style={styles}>
                              {item.qrtype && <QRCode renderAs="svg" size={100} value={content} />}
                              {item.redirectlink && <a href={content} target="_blank"><img style={{ maxHeight: item.height || "50px" }} src={item.redirectlink} alt="Link" /></a>}
                              {(!item.qrtype && !item.redirectlink)&&<img style={{ maxHeight: item.height || "50px" }} src={content} alt={item.alt || "Qr Code"} />}
                            </div> : <Aux>
                                        {item.hyperlink?<a href={content} target="_blank" tabIndex={0} className={"configurable " + (col.itemsClass ? col.itemsClass : "")} data-configpath={`${colConfigPath}.items.${itemId}.config`} style={styles}>
                                                            {item.hyperlink}
                                                        </a>
                                                        :<div dangerouslySetInnerHTML={{ __html: content }} tabIndex={0} className={"configurable " + (col.itemsClass ? col.itemsClass : "")} data-configpath={`${colConfigPath}.items.${itemId}.config`} style={styles}>
                                                        </div>}
                                    </Aux>}
                        </Collapse>

                      )
                    })
                  }
                  {col.rows && this.generateRows(col.rows, colConfigPath)}
                </Col>
              )
            })
          }
        </Row>
      </Collapse>
    )
  }

  render = () => {
    const { section_layout, root } = this.props,
      { rows } = section_layout.components,
      configPath = `${root}.section_layout.components`;
    return (
      this.generateRows(rows, configPath)
    )
  }
} 
