import React, { Component } from 'react'
import Container from 'reactstrap/lib/Container';
import { connect } from 'react-redux';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import Spinner from 'reactstrap/lib/Spinner';
import Table from 'reactstrap/lib/Table';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Pagination, PaginationItem, PaginationLink, } from 'reactstrap';
import { Loader } from '../../../global/Loader';
import { Auth } from '../../../../actions/Auth';
import { SelectList, Select } from '../../../global/Forms/Select';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import Axios from 'axios';
import FilterComponent from '../../../global/filter-component';
import { getFullName } from '../../../../actions';
import Aux from '../../../global/AuxComp';
import { Confirmation } from '../../../global/confirmation';
import moment from 'moment';
import PaginationComponent from '../../../global/PaginationComponent';

class ManageVerifiersComp extends Component {
  state = {
    init: true,
    currentPage: 0,
    rowsPerPage: 10,
    current: 0,
    rows: 10,
    isLoading: false,
    process: {},
    addVerifierModal: false,
    addStudentModal: false,
    confirmModal: false,
    verifiers: [],
    selectedVerifier: null,
    studentsList: [],
    rolesList: [],
    processStudent: [],
    inlineLoader: false,
    checkedStudents: [],
    query: "",
    blocklist: [],
    studentQuery: "",
    batchList: {},
    depList: {},
    groupList: {},
    studentFilters: {
      batches: [],
      departments: [],
      groups: [],
      query: ""
    }
  }
  async componentWillMount() {
    let { params } = this.props.match;
    this.setState({ isLoading: true })
    try {
      let resp = await Axios.all([Auth.Processes.getProcessById(params.process_id), Auth.Processes.getVerifiers(params.process_id)])
      this.setState({
        process: resp[0].data[0],
        verifiers: resp[1].data
      })
      if (resp[1].data[0]) {
        await this.selectVerifier(resp[1].data[0].verifier_role_id)
      }
    } catch (e) { console.log(e) }
    this.setState({ isLoading: false, init: false })
    Auth.User.getRoleList({ role_type: 4 }).then(resp => {
      this.setState({
        rolesList: resp.data
      })
    })
    Auth.PtStudents.getBatch().then(resp => {
      this.setState({
        batchList: resp.data.reduce((prev, current) => { prev[current.batch_id] = current; return prev; }, {}),
      })
    })
    Auth.Processes.getProcessStudents(this.props.match.params.process_id).then(resp => {
      this.setState({
        processStudent: resp.data
      })
    })
  }

  toggleState = (state) => {
    if (state === "addStudentModal"){
      this.setState({ checkedStudents: [], studentQuery: "" });
      this.selectAllStudents({target: {checked: true}});
    }
    this.setState({ [state]: !this.state[state] })
  }


  handleClick = (selectedPage) => {
    this.setState({
      currentPage: selectedPage - 1
    });
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleAddVerifier = async (e) => {
    e.preventDefault();
    let { rolesList, process } = this.state;
    let formData = {
      first_name: e.target.first_name.value,
      email: e.target.email.value,
      role_id: rolesList[0].role_id,
      access_key: this.props.role.instituteDetail.access_key,
      role_level: 3
    }
    this.setState({ inlineLoader: true })
    try {
      let resp = await Auth.User.addVerifier(formData);
      await Auth.Processes.assignVerifier({ role_id: resp.data.id, process_id: process.process_id })
      let verifiers = await Auth.Processes.getVerifiers(process.process_id)
      await this.selectVerifier(resp.data.id)
      this.setState({ addVerifierModal: false, verifiers: verifiers.data })
    } catch (e) { console.log(e) }
    this.setState({ inlineLoader: false })
  }

  selectVerifier = async (selectedVerifier) => {
    let { process } = this.state;
    this.setState({ isLoading: true })
    try {
      let resp = await Auth.Processes.getStudents(selectedVerifier, process.process_id)
      this.setState({
        selectedVerifier,
        studentsList: resp.data
      })
    } catch (e) { console.log(e) }
    this.setState({ isLoading: false })
  }

  handleAddStudent = async (e) => {
    e.preventDefault();
    let { selectedVerifier, process, checkedStudents } = this.state;
    if (checkedStudents.length === 0)
      return
    let formData = {
      role_id: selectedVerifier,
      process_id: process.process_id,
      student_id: [...checkedStudents]
    }
    try {
      await Auth.Processes.assignStudents(formData)
      await this.selectVerifier(selectedVerifier)
      this.setState(prevState => ({
        studentFilters: {
          ...prevState.studentFilters,
          batches: [],
          departments: [],
          groups:[]
        }
      }))
      this.setState({ addStudentModal: false })
    } catch (e) { console.log(e) }
    this.setState({ inlineLoader: false })
  }

  selectAllStudents = e => {
    let { checkedStudents, studentsList, processStudent, studentFilters } = this.state,
      isChecked = e.target.checked,
      mappedIds = studentsList.map(st => st.student_id),
      totalSt = processStudent.filter(st => {
        if (mappedIds.indexOf(st.student_id) < 0)
          return st;
      })
    if (studentFilters.batches.length > 0)
      totalSt = totalSt.filter(st => studentFilters.batches.indexOf(st.batch_id.toString()) > -1)
    if (studentFilters.departments.length > 0)
      totalSt = totalSt.filter(st => studentFilters.departments.indexOf(st.batch_id + "-" + st.degree_department_id) > -1)
    if (studentFilters.groups.length > 0)
      totalSt = totalSt.filter(st => studentFilters.groups.indexOf(st.batch_id + "-" + st.degree_department_id + "-" + st.group_id) > -1)

    if (isChecked)
      checkedStudents = totalSt.map(st => st.student_id)
    else
      checkedStudents = []
    this.setState({ checkedStudents })
  }

  onStudentChange = (e) => {
    let { checkedStudents } = this.state,
      isChecked = e.target.checked,
      value = parseInt(e.target.value);
    if (isChecked)
      checkedStudents.push(value)
    else
      checkedStudents.splice(checkedStudents.indexOf(value), 1)
    this.setState({ checkedStudents })
  }

  removeStudent = async ({ process_id, role_id, student_id }) => {
    let formData = {
      process_id, role_id, student_id: [student_id]
    }
    this.setState({ isLoading: true })
    try {
      await Auth.Processes.removeStudentfromVerifier({ ...formData })
      await this.selectVerifier(this.state.selectedVerifier)
    } catch (e) { console.log(e) }
    this.setState({ isLoading: false, confirmModal: null })
  }

  removeVerifier = async ({ verifier_role_id, process_id }) => {
    let formData = {
      role_id: verifier_role_id, process_id, status: 0
    }
    this.setState({ isLoading: true })
    try {
      await Auth.Processes.removeVerifier({ ...formData })
      let verifiers = await Auth.Processes.getVerifiers(process_id)
      this.setState({ selectedVerifier: null, verifiers: verifiers.data, studentsList: [] })
    } catch (e) { console.log(e) }
    this.setState({
      isLoading: false, confirmModal: null
    })
  }



  handleSelected = (selectedPage) => {
    this.setState({ current: selectedPage - 1 });
  }


  onSearch = (target) => {
    this.setState({ query: target.value });
    if (target.value !== "") {
      this.setState({ current: 0 });
    }
  }

  filterStudents = (studentFilters, batchList, depList, groupList) => {
    this.setState({ batchList: batchList, depList: depList, groupList: groupList, currentPage:0 });
    this.setState(prevState => ({
      studentFilters: {
        ...prevState.studentFilters,
        batches: studentFilters.batches,
        departments: studentFilters.departments,
        groups: studentFilters.groups,
      }
    }))
    this.selectAllStudents({target: {checked: true}})
  }


  render() {
    let { init, query, batchList, depList, groupList, studentFilters, processStudent, current, rows, currentPage, rowsPerPage, checkedStudents, studentQuery, isLoading, inlineLoader, process, confirmModal, addVerifierModal, addStudentModal, verifiers, selectedVerifier, studentsList, rolesList, } = this.state;
    let mappedIds = studentsList.map(st => st.student_id);
    // let students = processStudent;
    let students = processStudent.filter(st => {
      let name = getFullName(st)
      if (mappedIds.indexOf(st.student_id) < 0 && (name.toLowerCase().includes(studentQuery.toLowerCase()) || st.roll_no.toLowerCase().includes(studentQuery.toLowerCase())))
        return st;
    });
    if (studentFilters.batches.length > 0)
      students = students.filter(st => studentFilters.batches.indexOf(st.batch_id.toString()) > -1)
    if (studentFilters.departments.length > 0)
      students = students.filter(st => studentFilters.departments.indexOf(st.batch_id + "-" + st.degree_department_id) > -1)
    if (studentFilters.groups.length > 0)
      students = students.filter(st => studentFilters.groups.indexOf(st.batch_id + "-" + st.degree_department_id + "-" + st.group_id) > -1)
    let data = studentsList.filter(row => {
      let name = row.first_name || "";
      return name.toLowerCase().includes(query.toLowerCase())
    })
    return (
      <Container>
        <Loader show={isLoading} />
        {!init && <Aux><Row className="mt-3 border-bottom">
          <Col>
            <h1 className="r-heading-1"><Button onClick={() => this.props.history.goBack()}><img src="/images/left-arrow.png" style={{ height: 30 }} alt="" /></Button>{process.process_name} / Manage Verifiers</h1>
          </Col>
        </Row>
          <Row className="mt-3">
            <Col xs={6} className="border-right">
              <Row className="justify-content-between">
                <Col xs="auto"><span className="r-heading-6">All Verifier</span></Col>
                <Col xs="auto"><Button className="r-text--sm" color="blue" onClick={() => this.toggleState("addVerifierModal")}>+ Add Verifier</Button></Col>
                <Modal zIndex="99999" className="modal--centered" isOpen={addVerifierModal} toggle={() => this.toggleState("addVerifierModal")}>
                  <ModalBody>
                    <p className="r-heading-4">Add Verifier</p>
                    {rolesList[0] ? <form onSubmit={this.handleAddVerifier}>
                      <label className="r-heading-7">First name</label>
                      <InputField required={true} className="w-100 mb-2" placeholder="First name" type="text" name="first_name" />
                      <label className="r-heading-7">Email</label>
                      <InputField required={true} className="w-100" placeholder="Email" type="email" name="email" />
                      <div className="text-right mt-3">
                        {inlineLoader && <Spinner color="dark" />}
                        <Button color="dark" className="r-btn--no-radius r-text--sm">Add</Button>
                      </div>
                    </form> : <p>No verifier roles added</p>}
                  </ModalBody>
                </Modal>
              </Row>
              <ul className="processes__verifiers">
                {
                  verifiers.map((v, i) => {
                    return (
                      <li className={v.verifier_role_id === selectedVerifier ? "active" : ""} key={i} onClick={() => this.selectVerifier(v.verifier_role_id)}>
                        <Row noGutters>
                          <Col xs="auto" className="pr-3">
                            {(i + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
                          </Col>
                          <Col>
                            <span className="r-subheading-3">{getFullName(v)}</span>
                            <div className="r-text--sm">{v.email}</div>
                          </Col>
                          <Col xs={1}>
                            <Button onClick={(e) => { e.stopPropagation(); this.setState({ confirmModal: () => this.removeVerifier(v) }) }}><i className="fi flaticon-bin" /></Button>
                          </Col>
                        </Row>
                      </li>
                    )
                  })
                }
              </ul>
            </Col>
            {selectedVerifier && <Col xs={6}>
              <Row className="justify-content-between ">
                <Col xs="auto"><span className="r-heading-6">Students Mapped</span></Col>
                <Modal size="xl" zIndex="99999" isOpen={addStudentModal} toggle={() => this.toggleState("addStudentModal")}>
                  <ModalBody>
                    <Row noGutters className="justify-content-between border-bottom">
                      <Col xs="auto">
                        <p className="r-heading-4">Add Students</p>
                      </Col>
                    </Row>
                    <p className="r-heading-3 mt-3">You are going to map <span className="r-text--green-1">{checkedStudents.length} students </span> to selected verifier</p>
                    <FilterComponent filterStudents={this.filterStudents} />
                    <Row className="justify-content-end">
                      <Col xs="auto mt-2">
                        <InputField placeholder="Search Name/Roll No" name="search" value={studentQuery} onChange={(e) => this.setState({ studentQuery: e.target.value,currentPage:0 })} />
                      </Col>
                    </Row>
                    <form onSubmit={this.handleAddStudent}>
                      <Table className="processes__table__2 mt-4">
                        <thead>
                          <tr>
                            <th>{studentQuery === "" && <input ref={inp => {
                              if (inp) inp.indeterminate = (checkedStudents.length > 0 && checkedStudents.length < students.length)
                            }} checked={students.length === checkedStudents.length} name="selectAll" onChange={this.selectAllStudents} type="checkbox" />}</th>
                            <th>Student</th>
                            <th>Roll</th>
                            <th>Batch</th>
                            <th>Department</th>
                            <th>Group</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            students ? students.slice(parseInt(currentPage) * parseInt(rowsPerPage), (parseInt(currentPage) * parseInt(rowsPerPage)) + parseInt(rowsPerPage)).map((st, i) => {
                              return (
                                <tr key={i}>
                                  <td><input onChange={this.onStudentChange} checked={checkedStudents.indexOf(st.student_id) > -1} name="student_id" value={st.student_id} type="checkbox" /></td>
                                  <td>{getFullName(st)}</td>
                                  <td>{st.roll_no || "-"}</td>
                                  <td>{st.batch_name}</td>
                                  <td>{st.department_name || "-"}</td>
                                  <td>{st.group_name || "-"}</td>
                                </tr>
                              )
                            }) : <tr><td colSpan={5}>No Students in this batch</td></tr>
                          }
                        </tbody>
                      </Table>
                      <Row className="justify-content-between align-items-center  mx-0 px-0">
                        <Col xs="auto" className="">
                          <Row className="justify-content-between align-items-center">
                            <span className="px-3 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>View</span>
                            <div className="r-dropdown border-0">
                              <select value={rowsPerPage} name="rowsPerPage" className="r-dropdown__select " style={{ backgroundColor: "#dee2e6" }} onChange={this.onChange}>
                                <option value="10" >10</option>
                                <option value="15">15</option>
                                <option value="20" >20</option>
                                <option value="25">25</option>
                                <option value="50" >50</option>
                                <option value="100">100</option>
                              </select>
                              <i className="fi flaticon-arrow" />
                            </div>
                          </Row>
                        </Col>
                        <Col xs="auto">
                          <PaginationComponent
                            totalItems={students.length}
                            pageSize={parseInt(rowsPerPage)}
                            onSelect={this.handleClick}
                            activePage={currentPage + 1}
                          />
                        </Col>
                      </Row>
                      <div className="text-right">
                        {inlineLoader && <Spinner color="dark" />}
                        <Button className="r-btn--no-radius" color="dark">Add</Button>
                      </div>
                    </form>
                  </ModalBody>
                </Modal>
              </Row>
              <Row className="justify-content-between mx-0 px-0">
                <Col xs="auto" className="px-2 borderBottomBlack">
                  <InputField className="tableinput r-nav__search r-bg--transparent" placeholder="Search name" type="text" onChange={({ target }) => this.onSearch(target)} />
                  <span className="fi flaticon-search"></span>
                </Col>
                <Col xs="auto"><Button className="r-text--sm" color="blue" onClick={() => this.toggleState("addStudentModal")}>+ Add Students</Button></Col>
              </Row>
              <ul className="processes__students">
                {
                  data.slice(current * rows, current * rows + rows).map((st, i) => {
                    return (
                      <li key={i}>
                        <Row noGutters>
                          {/*<Col xs={1} className="pr-3">
                                                      {(i + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
                                                    </Col>*/}
                          <Col xs={11}>
                            <Row>
                              <Col xs={4}>{getFullName(st)}</Col>
                              <Col xs={2}>{st.year_of_entry ? moment(st.year_of_entry).format("YYYY") : "-"}</Col>
                              <Col xs={5}>{st.department_name || "-"}</Col>
                              <Col xs={1}><Button onClick={() => this.setState({ confirmModal: () => this.removeStudent(st) })}><i className="fi flaticon-bin" /></Button></Col>
                            </Row>
                          </Col>
                        </Row>
                      </li>
                    )
                  })
                }
              </ul>
              <Row className="justify-content-between align-items-center" >
                <Col xs="auto" className="">
                  <Row className="justify-content-between align-items-center">
                    <span className="px-1 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>Total students are {studentsList.length}</span>
                    <span className="px-3 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>View</span>
                    <div className="r-dropdown border-0">
                      <select value={rows} name="rows" className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} onChange={(e) => { this.setState({ [e.target.name]: e.target.value, current: 0 }) }}>
                        <option value="10" >10</option>
                        <option value="15">15</option>
                        <option value="20" >20</option>
                        <option value="25">25</option>
                        <option value="50" >50</option>
                        <option value="100">100</option>
                      </select>
                      <i className="fi flaticon-arrow" />
                    </div>
                  </Row>
                </Col>
                <Col xs="auto">
                  <PaginationComponent
                    totalItems={data.length}
                    pageSize={rows}
                    onSelect={this.handleSelected}
                    maxPaginationNumbers={3}
                    activePage={current + 1}
                  />
                </Col>
              </Row>
            </Col>}
          </Row></Aux>}
        <Confirmation isOpen={confirmModal ? true : false} callback={confirmModal || (() => { })} toggleConfirmation={() => { this.setState({ confirmModal: null }) }} />
      </Container>
    )
  }
}
const mapStateToProps = state => ({
  ...state
})

export const ManageVerifiers = connect(mapStateToProps, null)(ManageVerifiersComp)
