import React,{Component} from 'react';
import {Container, Row, Col, Button, Collapse} from 'reactstrap';
import {InputField} from '../../global/Forms';
import {HorizontalLine} from '../../global/horizontal_line';
import MembersModal from './member_modal';
import { Auth } from '../../../actions/Auth';
import { Loader } from '../../global/Loader';
import {Errorbar} from '../../global/errorbar';
class  AdminOverview extends Component{
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = ()=>{
    return(
      {
        openUniverstyCollapse : false,
        universityName : null,
        spocMail : null,
        universityName : null,
        universityCity: null,
        universityState : null,
        universityAddress : null,
        universityZipcode: null,
        spocMail : null,
        spocName : '',
        isMembersModel : false,
        selectedUniversity : null,
        errorbar: false,
        message : '',
        errorMessage : null
      }
    )
  }

  closebar = () =>{
      this.setState({errorbar: false});
  }

  closeMembersModel = (flag)=>{
    this.setState({
      isMembersModel : flag
    })
  }

  editUniversityDetails = async(university_id)=>{
    this.setState({loading : true});
    if(this.state.universityCity!=null || this.state.universityName || this.state.universityState!=null || this.state.universityZipcode!=null || this.state.universityAddress!=null){
      if(this.state.errorMessage == null){
        let params = {
          "university_id"  : university_id,
          "city" : this.state.universityCity,
          "state" : this.state.universityState,
          "address" : this.state.universityAddress
        }

        if(this.state.universityName!="" && this.state.universityName !=null){
          params.name = this.state.universityName;
        }

        if(this.state.universityZipcode!=null && this.state.universityZipcode.length==6 && this.state.universityZipcode!=""){
          params.zip_code = this.state.universityZipcode;
        }
        try{
          await Auth.University.editUniversity(params);
          this.props.getUniversityList();
        }
        catch(e){
          console.log(e);
        }
      }
      this.setState(this.getInitialState());
    }
    else{
      if(this.state.errorMessage==null){
        this.setState(this.getInitialState());
      }
    }
    this.setState({loading: false});
  }



  render(){
    return(
      <Container>
        <Loader show={this.state.loading} />
        <div className="p-3 mt-4 py-3 r-bg--blue-2 r-text--sm align-items-center">
          <Row className="align-items-center">
            <Col xs="7">
              <span className="r-subheading-4">Name </span>
            </Col>
            <Col xs="3" className="">
              <span className="r-subheading-4">Spoc List</span>
            </Col>
            <Col xs="2" align="center">
              <span className="r-subheading-4">Actions</span>
            </Col>
          </Row>
        </div>
        {
          this.props.universityList && this.props.universityList.map(university=>
            <div>
            <Row className="p-3 align-items-center">
              <Col xs="7">
                <span className="r-heading-6 r-text--grey-4">{university.name}</span>
              </Col>
              <Col xs="3" className="">
                <button className="r-btn r-btn--link-blue" onClick={()=>this.setState({isMembersModel : !this.state.isMembersModel, selectedUniversity : university})}>5 <span className="r-text--sm">Admin <i className="fi flaticon-eye"/></span></button>
              </Col>
              <Col xs="2" align="center">
                <button className="r-btn rbtn--link-blue" onClick={()=>this.setState({openUniverstyCollapse : !this.state.openUniverstyCollapse, selectedUniversity : university})}><i className="fi flaticon-edit"/></button>
              </Col>
            </Row>
            <Collapse className="border p-3 m-3" isOpen={this.state.openUniverstyCollapse && this.state.selectedUniversity && this.state.selectedUniversity.university_id == university.university_id} >
            {this.state.errorMessage  ? <Row><Col><span className="r-text--red-1">{this.state.errorMessage}</span></Col></Row>:null}
            <Row>
              <Col className="m-3">
                <span className="modal-title r-heading-6">Edit University Details</span>
              </Col>
            </Row>
              <form>
              <Row>
                <Col xs="4" className="m-2">
                  <InputField placeholder="University Name" name="universityName" type="text" onChange={(e)=>e.target.value!=""?this.setState({universityName : e.target.value , errorMessage : null}): this.setState({errorMessage : '*University Name Cannot be blank' ,universityName : e.target.value})} defaultValue={this.state.selectedUniversity && this.state.selectedUniversity.name} value={this.state.universityName!=null?this.state.universityName : this.state.selectedUniversity && this.state.selectedUniversity.name}  className="w-100 border-0"  required={true}/>
                </Col>
                <Col xs="4" className="m-2">
                  <InputField placeholder="State" name="universityState" type="text" onChange={(e)=>this.setState({universityState : e.target.value})} defaultValue={this.state.selectedUniversity && this.state.selectedUniversity.state} value={this.state.universityState!=null? this.state.universityState : this.state.selectedUniversity && this.state.selectedUniversity.state}  className="w-100 border-0" />
                </Col>
                <Col xs="4" className="m-2">
                  <InputField placeholder="City" name="universityCity" type="text" onChange={(e)=>this.setState({universityCity : e.target.value})} defaultValue={this.state.selectedUniversity && this.state.selectedUniversity.city} value={this.state.universityCity!=null?this.state.universityCity : this.state.selectedUniversity && this.state.selectedUniversity.city}  className="w-100 border-0" />
                </Col>
                <Col xs="4" className="m-2">
                  <InputField placeholder="Address" name="universityAddress" type="text" onChange={(e)=>this.setState({universityAddress : e.target.value})} defaultValue={this.state.selectedUniversity && this.state.selectedUniversity.address} value={this.state.universityAddress!=null?this.state.universityAddress : this.state.selectedUniversity && this.state.selectedUniversity.address}  className="w-100 border-0" />
                </Col>
                <Col xs="4" className="m-2">
                  <InputField placeholder="ZipCode" name="universityZipcode" type="text" pattern="[0-9]{6}" onChange={(e)=>this.setState({universityZipcode : e.target.value})} defaultValue={this.state.selectedUniversity && this.state.selectedUniversity.zip_code} value={this.state.universityZipcode!=null?this.state.universityZipcode : this.state.selectedUniversity && this.state.selectedUniversity.zip_code}  className="w-100 border-0" />
                </Col>
              {/*<Col xs="3">
              <InputField placeholder="Enter Spoc Name" name="spocName" type="text" className="w-100 border-0" />
              </Col>
              <Col xs="3">
              <InputField placeholder="Enter Spoc email" name="spocMail" type="email"  className="w-100 border-0" />
              </Col>*/}

              </Row>
              <Row>
              <Col align="end">
                <Button color="dark" className="mt-2" onClick={()=>this.editUniversityDetails(university.university_id)}> Save </Button>
                <Button className="r-subheading-4" type="button" className="r-bg--light r-text--dark mt-2 ml-2" onClick={()=>this.setState(this.getInitialState())}>Cancel</Button>
              </Col>
              </Row>
              </form>
            </Collapse>
            {this.state.selectedUniversity && <MembersModal closeMembersModel={this.closeMembersModel} isMembersModel={this.state.isMembersModel} selectedUniversity={this.state.selectedUniversity} university={university}/>}
            </div>
          )
        }
      <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      </Container>
    )
  }
}

export default AdminOverview;
