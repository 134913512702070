import React, { Component } from 'react';
import { Legend, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import { Row, Col, Container, Card , Table, Collapse } from 'reactstrap';
import { Loader } from '../../global/Loader';
import Aux from '../../global/AuxComp';
import SpocNav from './SpocNav';
import Axios from 'axios';
import PieChartCOMP from '../../global/PieChartCOMP';
import { Auth } from '../../../actions/Auth';
import store from '../../../store';
import { MultiSelectList, Select } from '../../global/Forms/Select';
import Button from '../../global/Button';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import moment from 'moment';
import { PermissionDenied } from '../../global/PermissionDenied';

class ProcessPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dept: "all",
      nav: { process_name: "Process Performance", process_type: "process", process_id: 3 },
      offersBySectors: [],
      offersByGender: [],
      offersByRole: [],
      offersByDep: [],
      offersByDegree: [],
      offersByClassification : [],
      collegeList:[],
      campusList:[],
      selectedCollege:'',
      selectedCampus:'',
      selectedProcess:[],
      totalOffers: 0,
      highestCTC: 0,
      lowestCTC: 0,
      isLoading: true,
      processList: [],
      totalMetrics : null,
      categoryMetrics : null,
      offerTypeMetrics: null,
      selectedCategory : null,
      classificationMetrics : null,
      genderMetrics : null,
      departmentMetrics : null,
      campusDegreeMetrics : null,
      batchMetrics: null,
      combineMatrix : [],
      isOpenDateFilter : {},
      columns : ['Category','Registered students','Total Offers' , 'Placed' ,'Unplaced' , 'Highest CTC' , 'Lowest CTC' , 'Average' , 'Median'],
      classificationColumns : ['Category', 'Sub Category' ,'Total Offers' , 'Highest CTC' , 'Lowest CTC' , 'Average' , 'Median']
    };
  }

  formatData = (bucket) => {
    let temp = {}
    temp.doc_count = bucket.doc_count
    temp.name = bucket.key === "NOT_SELECTED" ? "Other" : bucket.key;
    temp.max_ctc_value_inr = bucket.max_ctc_value_inr.value
    temp.min_ctc_value_inr = bucket.min_ctc_value_inr.value
    return temp
  }

  componentDidMount() {
    let permissions = store.getState().role.hasPermission;
    if (store.getState().role.role_type === "PT" && !(permissions.reportPlacement && permissions.reportPlacement.permission_access && permissions.reportPlacement.permission_access.read)) {
      this.props.history.push("/pt/performance/pt")
        return
    }
    this.init()
  }

  getTotalMetricsReport = async params=>{
    this.setState({selectedProcess:params.process_id , isLoading : true});
    try{
      let reportTotalMetrics = await Auth.Reports.placementReportView(params);
      await this.setState({
        totalMetrics : reportTotalMetrics.data
      })
      this.getCategoryMetrics({start_date : params.start_date, end_date: params.end_date});
    }
    catch(e){
      console.log(e);
    }
    this.setState({isLoading : false});
  }

  init = async () => {
    let redux = store.getState(),
      { instituteDetail } = redux.role;
    let params = {}
    if (instituteDetail.campus_id) {
      try {
        let processResp = await Auth.Processes.getProcesses(instituteDetail.campus_id)
        this.setState({
          selectedCampus:instituteDetail.campus_id,
          processList: processResp.data
        })
        if (processResp.data.length > 0)
          params.process_id = [processResp.data[0].process_id]

        // await this.getReports(params)
        await this.getTotalMetricsReport(params);
      } catch (e) { console.log(e) }
    } else {
        this.instiDetail();
    }
    this.setState({ isLoading: false })
  }


  instiDetail = async () =>{
      let campusResTemp, degreeList;
      await Axios.all([Auth.Spoc.getInstitutes()])
      .then(Axios.spread((res1, res2) => {
        campusResTemp = res1;
      }));
      let detailsParams = new URLSearchParams();
      campusResTemp.data.forEach((item, i)=>{
        detailsParams.append("college_id", item.college_id);
        detailsParams.append("campus_id", item.campus_id);
      })
      let details = await Auth.Spoc.getInstituteDetails(detailsParams);
      await this.setState({
        campusList:details.data.campusDetails,
        collegeList:details.data.collegeDetails,
        selectedCollege:details.data.campusDetails[0].college_id,
      })
      this.fetchAllCamusMatrix();
  }

  // getReports = async (params) => {
  //   this.setState({ isLoading: true , selectedProcess:params.process_id });
  //   let body = {
  //                       "must": {
  //                         "process_id": params.process_id
  //                     },
  //                     "filter": {
  //                       "is_active": "true"
  //                     },
  //                     "aggregationParams": [
  //                         {
  //                             "aggregateOn": "sector_name",
  //                             "metrics": "ctc_value_inr"
  //                         },
  //                         {
  //                             "aggregateOn": "gender",
  //                             "metrics": "ctc_value_inr"
  //                         },
  //                         {
  //                             "aggregateOn": "designation_name",
  //                             "metrics": "ctc_value_inr"
  //                         },
  //                         {
  //                             "aggregateOn": "degree_name",
  //                             "metrics": "ctc_value_inr"
  //                         },
  //                         {
  //                             "aggregateOn": "department_name",
  //                             "metrics": "ctc_value_inr"
  //                         }
  //                     ]
  //                 }
  //   let classificationBody = {
  //     "must": {
  //       "process_id": params.process_id
  //     },
  //     "filter": {
  //       "is_active": "true"
  //     },
  //     "aggregationParams": [
  //     {
  //         "aggregateOn": "classification_name",
  //         "metrics": "ctc_value_inr"
  //     }]
  //   }
  //   let sectorReport,
  //     roleReport,
  //     genderReport;
  //   try {
  //     // sectorReport = await Auth.Reports.getCTCbySector(params)
  //     // roleReport = await Auth.Reports.getCTCbyRole(params)
  //     // genderReport = await Auth.Reports.getCTCbyGender(params)
  //     let reportResponse  = await Auth.Reports.getCTC(body)
  //     let classificationResp = await Auth.Reports.getCTCbyClassification(classificationBody);
  //     let offersBySectors = reportResponse.data.aggregations.sector_name_aggregation.buckets.map(this.formatData)
  //     let offersByGender = reportResponse.data.aggregations.gender_aggregation.buckets.map(this.formatData)
  //     let offersByRole = reportResponse.data.aggregations.designation_name_aggregation.buckets.map(this.formatData)
  //     let offersByDep = reportResponse.data.aggregations.department_name_aggregation.buckets.map(this.formatData)
  //     let offersByDegree = reportResponse.data.aggregations.degree_name_aggregation.buckets.map(this.formatData)
  //     let offersByClassification = classificationResp.data.aggregations.classification_name_aggregation.buckets.map(this.formatData)
  //     let totalOffers = 0,
  //       maxCTC = [0],
  //       minCTC = []
  //     offersByGender.forEach(b => {
  //       totalOffers += b.doc_count
  //       maxCTC.push(b.max_ctc_value_inr)
  //       if (b.min_ctc_value_inr)
  //         minCTC.push(b.min_ctc_value_inr)
  //     })
  //     this.setState({
  //       offersBySectors,
  //       offersByGender,
  //       offersByRole,
  //       offersByDep,
  //       offersByDegree,
  //       totalOffers,
  //       offersByClassification,
  //       highestCTC: Math.max(...maxCTC),
  //       lowestCTC: Math.min(...minCTC)
  //     })
  //   } catch (e) {
  //     console.log(e)
  //   }
  //   this.setState({ isLoading: false })
  // }
  tickFormatter = (label) => label.substr(0, 10).trim() + (label.length > 10 ? "..." : "");



  changeCollege = async (target) => {
    await this.setState({
      selectedCollege: target.value,
      selectedCampus:"",
      selectedProcess:'',
      totalOffers: 0,
      highestCTC: 0,
      lowestCTC: 0,
      offersBySectors: [],
      offersByGender: [],
      offersByRole: [],
      offersByDep: [],
      offersByDegree: [],
      offersByClassification : [],
      processList: []});
      this.fetchAllCamusMatrix();
  }

  // getCategoryMetricsReport = async (params)=>{
  //   this.setState({isLoading : true});
  //   if(params.category){
  //     console.log("params" , params);
  //     let newParams = new URLSearchParams();
  //     newParams.append('process_id' , params.process_id);
  //     newParams.append('category' , params.category);
  //     try{
  //       let totalCategoryMetrics = await Auth.Reports.placementReportCategoryView(newParams);
  //       await this.setState({categoryMetrics : totalCategoryMetrics.data , selectedCategory : params.category});
  //     }
  //     catch(e){
  //       console.log(e);
  //     }
  //   }
  //   this.setState({isLoading : false});
  // }

  getCategoryMetrics = async (filter = {})=>{
    this.setState({isLoading : true});
    let {selectedProcess} = this.state;
    try{
      let params={
        process_id : selectedProcess,
        category : "classification",
        ...filter
      };
      let classificationMetrics = await Auth.Reports.placementReportCategoryView(params);
      let genderMetrics = await Auth.Reports.placementReportCategoryView({process_id : selectedProcess , category:"gender", ...filter});
      let departmentMetrics = await Auth.Reports.placementReportCategoryView({process_id : selectedProcess , category:"department_name", ...filter });
      let campusDegreeMetrics = await Auth.Reports.placementReportCategoryView({process_id : selectedProcess , category:"campus_degree_name", ...filter});
      let batchMetrics = await Auth.Reports.placementReportCategoryView({process_id : selectedProcess , category:"batch_name", ...filter});
      let offerTypeMetrics = await Auth.Reports.placementReportCategoryView({process_id : selectedProcess , category:"offer_type", ...filter});
      this.setState({classificationMetrics : classificationMetrics.data, genderMetrics : genderMetrics.data , departmentMetrics : departmentMetrics.data , campusDegreeMetrics : campusDegreeMetrics.data, batchMetrics: batchMetrics.data, offerTypeMetrics: offerTypeMetrics.data});
    }
    catch(e){
      console.log(e);
    }
    this.setState({isLoading : false});
  }

  changeCampus = async (target, process_id) =>{
    this.setState({selectedCampus: target.value, selectedProcess:""});
    if(target.value === ""){
      this.setState({
        totalMetrics: null,
        totalOffers: 0,
        highestCTC: 0,
        lowestCTC: 0,
        offersBySectors: [],
        offersByGender: [],
        offersByRole: [],
        offersByDep: [],
        offersByDegree: [],
        offersByClassification : [],
        processList: []});
        this.fetchAllCamusMatrix();
      return }
    this.setState({isLoading: true});
      try {
        let params = {}
        let processResp = await Auth.Processes.getProcesses(target.value)
        this.setState({
          processList: processResp.data
        })
        if (processResp.data.length > 0)
          params.process_id = process_id ? [process_id] : [processResp.data[0].process_id]
        await this.getTotalMetricsReport(params);
      } catch (e) { console.log(e) }
    this.setState({isLoading: false});
  }

  downloadFile = async() => {
       this.setState({isLoading: true});
      //  let body = new URLSearchParams();
      //  body.append('download' , true);
      //  body.append('process_id' , this.state.selectedProcess )
       let body = {
         download : true,
         process_id : this.state.selectedProcess
            }
      try {
        let res = await Auth.Reports.placementreportDownload(body);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = 'Placement Report'+'.csv';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({isLoading: false});
      } catch (e) {
          this.setState({isLoading: false});
          console.log(e)
      }
  }
  selectProcess =  (e) => {
    let {selectedProcess} = this.state;
    let value = parseInt(e.target.value);
    if(e.target.checked){
      selectedProcess.push(value);
    }
    else{
      if(selectedProcess.length === 1) return;
      selectedProcess.splice(selectedProcess.indexOf(value), 1);
    }
    this.getTotalMetricsReport({process_id : selectedProcess});
  }
  filterByDate = () => {
    console.log("hello")
    let { selectedProcess, isOpenDateFilter } = this.state;
    isOpenDateFilter.is_applied = true;
    isOpenDateFilter.is_open = false;
    this.setState({isOpenDateFilter});
    this.getTotalMetricsReport({process_id : selectedProcess, start_date : moment(isOpenDateFilter.start_date).toDate(), end_date : moment(isOpenDateFilter.end_date).toDate()});
  }
  clearDateFilter = () => {
    let { selectedProcess } = this.state;
    this.setState({isOpenDateFilter : {}});
    this.getTotalMetricsReport({process_id : selectedProcess});
  }

  fetchAllCamusMatrix = async () => {
    let {campusList, selectedCollege} = this.state;
    this.setState({isLoading: true});
    var selectedCampusList = campusList.filter(campus => campus.college_id == selectedCollege);
    try{
      var output = [];
      await Promise.all(selectedCampusList.map(async campus => {
        let processResp = await Auth.Processes.getProcesses(campus.campus_id);
        var processList = processResp.data;
        await Promise.all(processList.map(async process => {
          let reportTotalMetrics = await Auth.Reports.placementReportView({process_id: process.process_id});
          output.push({
            ...reportTotalMetrics.data,
            processDetail: process,
            campusDetails : campus
          });
        }))
      }));
      this.setState({combineMatrix: output})
    }
    catch(e){
      console.error(e);
    }
    this.setState({isLoading: false});
  }
  viewRportByCombineMatrix = async (campus_id, process_id) => {
    await this.changeCampus({value : campus_id}, process_id);
  }
  render() {
    

    let { isLoading,collegeList,combineMatrix, campusList, selectedCampus,selectedCollege,selectedProcess, processList,offersByDep,offersByDegree, offersBySectors, offersByGender, offersByRole, offersByClassification  , totalOffers, highestCTC, lowestCTC , totalMetrics , categoryMetrics , selectedCategory , classificationMetrics , genderMetrics , departmentMetrics , campusDegreeMetrics, batchMetrics , offerTypeMetrics, columns , classificationColumns, isOpenDateFilter } = this.state;
    const redux = store.getState();
   let genderChartData = genderMetrics ? Object.keys(genderMetrics).map(key => {return {name : key, count : genderMetrics[key].total_offer}}) : [];
   let departmentChartData = departmentMetrics ? Object.keys(departmentMetrics).map(key => {return {name : key, count : departmentMetrics[key].total_offer}}) : [];
   let batchChartData = batchMetrics ? Object.keys(batchMetrics).map(key => {return {name : key, count : batchMetrics[key].total_offer}}) : [];
   let offerTypeChartData = offerTypeMetrics ? Object.keys(offerTypeMetrics).map(key => {return {name : key, count : offerTypeMetrics[key].total_offer}}) : [];
   let degreeChartData = campusDegreeMetrics ? Object.keys(campusDegreeMetrics).map(key => {return {name : key, count : campusDegreeMetrics[key].total_offer, min_ctc_value_inr : campusDegreeMetrics[key].lowest, max_ctc_value_inr:  campusDegreeMetrics[key].highest}}) : [];
   let classificationChartData = classificationMetrics? classificationMetrics.reduce((prev,current)=>{
    if(current.data && current.data.length>0){
      let categoryData =current.data;
      prev = prev.concat(categoryData.map(data=>{
        return {
          name : data.name,
          count : data.total_metrics.total_offer,
          min_ctc_value_inr : data.total_metrics.lowest,
          max_ctc_value_inr : data.total_metrics.highest
        };
      }))
    }
    return prev;
  }, []) : [];
   let campusDataList = campusList.filter(campus =>{
      return campus.college_id == selectedCollege;
    });
    return (
      <Container>
        <Loader show={isLoading} />
        <SpocNav process={this.state.nav} downloadReport={this.downloadFile} type="PlacementReport"/>
        {(redux.role.instituteDetail.campus_id > 0 && processList.length === 0) ? <p>No processes added</p> : ""}
        <Row className="mx-0 my-3">
        {collegeList.length > 0 &&
            <Col xs="3">
              <Select onChange={({ target }) => this.changeCollege(target)}>
                {collegeList.map((college, i) =>
                  <option key={i} value={college.college_id}>{college.name}</option>
                )}
              </Select>
            </Col>
        }
        {campusDataList.length > 0 &&
            <Col xs="3">
              <Select value={selectedCampus} onChange={({ target }) => this.changeCampus(target)}>
                  <option value="">Select Campus</option>
                  {campusDataList.map((campus, i) =>
                    <option key={i} value={campus.campus_id}>{campus.name}</option>
                  )}
              </Select>
            </Col>
        }
        {processList.length > 0 &&
            <Col xs="3">
              {/* <MultiSelectList title={"Select Process"} value={selectedProcess} onChange={({ target }) => this.getTotalMetricsReport({ process_id: target.value })}>
                {processList.map((process, i) =>
                  <option key={i} value={process.process_id}>{process.process_name}</option>
                )}
              </MultiSelectList> */}
              <MultiSelectList title={selectedProcess.length ? `${selectedProcess.length} Placement Drive Selected`: "Select Placement Drive"}>
                <ul className="list-unstyled m-0">
                  {processList.map((process, i) => {
                    return (
                      <li key={i}>
                        <input name="value" onChange={(e) => this.selectProcess(e)} checked={selectedProcess.indexOf(process.process_id) >= 0}  type="checkbox" value={process.process_id} />
                        <label>{process.process_name}</label>
                      </li>
                    )
                  })}
                </ul>
              </MultiSelectList>
            </Col>
        }

        <Col>
         <em className="r-text--sm text-muted">Selected : {processList.filter(process => selectedProcess.indexOf(process.process_id) >= 0).map(process => process.process_name).join("  ,  ")}</em> 
        </Col>
        <Col xs={1} className="align-self-end text-right">
          <Button onClick={() => {isOpenDateFilter.is_open = !isOpenDateFilter.is_open; this.setState({isOpenDateFilter})}} className="font-weight-normal px-2 py-0" color="blue3"><img style={{ width: "21px" }} src="/images/filter-512.png" alt="" /></Button>
        </Col>
        </Row>
        { isOpenDateFilter && isOpenDateFilter.is_applied && <p className="text-right "><em className="text-muted r-text--sm">Data showing for {moment(isOpenDateFilter.start_date).format("Do MMM YYYY")} - {moment(isOpenDateFilter.end_date).format("Do MMM YYYY")}</em></p>}
        <Collapse isOpen={this.state.isOpenDateFilter.is_open}>
        <Row className="justify-content-end align-items-end">
          <Col xs={3}>
            <span className="r-subheading-4">From Date</span>
              <SingleDatePicker
                value={isOpenDateFilter.start_date}
                onDateChange={(date) => { isOpenDateFilter.start_date = date; this.setState({ isOpenDateFilter }) }}
              />
          </Col>
          <Col xs={3}>
              <span className="r-subheading-4">To Date</span>
              <SingleDatePicker
                value={isOpenDateFilter.end_date}
                onDateChange={(date) => { isOpenDateFilter.end_date = date; this.setState({ isOpenDateFilter }) }}
              />
          </Col>
          <Col xs={1}>
            {isOpenDateFilter.is_applied ? 
            <Button onClick={this.clearDateFilter} color="dark" square={true}>Clear</Button>
            :
            <Button onClick={this.filterByDate} color="dark" square={true}>Filter</Button>
            }
            </Col>
        </Row>
        </Collapse>
        {
          !!combineMatrix.length && !totalMetrics &&
          <Aux>
              <Table className="post mt-5 text-center" style={{fontSize: 12}}>
                <thead>
                  <tr className="tableheader align-items-center">
                    <th className="align-middle">#</th>
                    <th className="align-middle">Campus Name</th>
                    <th className="align-middle">Placement Drive Name</th>
                    <th className="align-middle"># Students</th>
                    <th className="align-middle"># offers</th>
                    <th className="align-middle"># placed</th>
                    <th className="align-middle"># unplaced</th>
                    <th className="align-middle">% placed</th>
                    <th className="align-middle">Average Salary</th>
                    <th className="align-middle">Highest Salary</th>
                    <th className="align-middle">Lowest Salary</th>
                    <th className="align-middle">Median Salary</th>
                    <th className="align-middle">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    combineMatrix.map((data, i) => 
                      <tr>
                        <td className="text-center align-middle">{i+1}</td>
                        <td className="align-middle">{data.campusDetails.name}</td>
                        <td className="align-middle">{data.processDetail.process_name}</td>
                        <td className="align-middle">{data.placed + data.unplaced}</td>
                        <td className="align-middle">{data.total_offer}</td>
                        <td className="align-middle">{data.placed}</td>
                        <td className="align-middle">{data.unplaced}</td>
                        <td className="align-middle">{(data.placed+data.unplaced) === 0 || data.placed === 0 ? 0 : ((data.placed * 100) / (data.placed+data.unplaced)).toFixed(2)}</td>
                        <td className="align-middle">{parseInt(data.average).toLocaleString('en-IN')}</td>
                        <td className="align-middle">{parseInt(data.highest).toLocaleString('en-IN')}</td>
                        <td className="align-middle">{parseInt(data.lowest).toLocaleString('en-IN')}</td>
                        <td className="align-middle">{parseInt(data.median).toLocaleString('en-IN')}</td>
                        <td className="align-middle"><Button color="blue-3" onClick={()=> this.viewRportByCombineMatrix(data.campusDetails.campus_id, data.processDetail.process_id)} style={{fontSize : 10}}>View Report</Button></td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
          </Aux>
        }
        {(!isLoading && totalMetrics) && <Aux>
          <Row className=" my-4">
          <Col align="center">
            <Card className='bg-dark r-text--light w-75'>
              <span className="r-subheading-1 px-2 py-2">{totalMetrics.total_students}</span>
            </Card>
            <span style={{ fontSize: "12px" }}>Total Students</span>
          </Col>
          <Col align="center">
              <Card className='bg-dark r-text--light w-75'>
                <span className="r-subheading-1 px-2 py-2">{totalMetrics.participated_students}</span>
              </Card>
              <span style={{ fontSize: "12px" }}>Registered students</span>
            </Col>
            <Col align="center">
              <Card className='bg-dark r-text--light w-75'>
                <span className="r-subheading-1 px-2 py-2">{totalMetrics.total_offer}</span>
              </Card>
              <span style={{ fontSize: "12px" }}>Total Offers</span>
            </Col>
            <Col align="center">
              <Card className='bg-dark r-text--light w-75'>
                <span className="r-subheading-1 px-2 py-2">{totalMetrics.placed}</span>
              </Card>
              <span style={{ fontSize: "12px" }}>Placed</span>
            </Col>
            <Col align="center">
              <Card className='bg-dark r-text--light w-75'>
                <span className="r-subheading-1 px-2 py-2">{totalMetrics.unplaced}</span>
              </Card>
              <span style={{ fontSize: "12px" }}>Unplaced</span>
            </Col>
            </Row>
            <Row>
            <Col xs="3">
              <Card className='r-bg--brandA r-text--light'>
                <span className="r-subheading-1 px-2 py-2">{isFinite(totalMetrics.highest) ? (totalMetrics.highest).toLocaleString('en-IN') : 0}</span>
              </Card>
              <span style={{ fontSize: "12px" }}>Highest CTC</span>
            </Col>
            <Col xs="3">
              <Card className='r-bg--brandA r-text--light'>
                <span className="r-subheading-1 px-2 py-2">{isFinite(totalMetrics.lowest) ? parseInt(totalMetrics.lowest).toLocaleString('en-IN') : 0}</span>
              </Card>
              <span style={{ fontSize: "12px" }}>Lowest CTC</span>
            </Col>
            <Col xs="3">
              <Card className='r-bg--brandA r-text--light'>
                <span className="r-subheading-1 px-2 py-2">{isFinite(totalMetrics.average)? parseInt(totalMetrics.average).toLocaleString('en-IN') : 0}</span>
              </Card>
              <span style={{ fontSize: "12px" }}>Avg CTC</span>
            </Col>
            <Col xs="3">
              <Card className='r-bg--brandA r-text--light'>
                <span className="r-subheading-1 px-2 py-2">{isFinite(totalMetrics.median)? (totalMetrics.median).toLocaleString('en-IN') : 0}</span>
              </Card>
              <span style={{ fontSize: "12px" }}>Median</span>
            </Col>
          </Row>
          <Row className="mt-5 mb-2 pb-2 border-bottom">
            <Col>
              <span className="r-heading-3 r-text--grey-5">Gender</span>
            </Col>
          </Row>
          <Row className="border-bottom pb-4 align-items-center my-2">
            <Col xs="12">
              {genderChartData.length > 0 ? <PieChartCOMP data={genderChartData} datakey="count" /> : <p>No records found</p>}
            </Col>
            <Col xs={12}>
              <Table className="mb-3">
                <thead>
                  <tr className="tableheader">
                    {columns.map((col, i) =>
                      <th style={{ textAlign: "center" }} key={i}>
                        {col}
                      </th>

                    )}
                  </tr>
                </thead>
                <tbody>
                      {genderMetrics ? Object.keys(genderMetrics) && Object.keys(genderMetrics).length>0 ?
                        Object.keys(genderMetrics).map((key,i)=>{
                          return (
                            <tr style={{ textAlign: "center" }} key={i}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {genderMetrics[key].participated_students}
                              </td>
                              <td>
                                {genderMetrics[key].total_offer}
                              </td>
                              <td>
                                {genderMetrics[key].placed}
                              </td>
                              <td>
                                {genderMetrics[key].unplaced}
                              </td>
                              <td>
                                {genderMetrics[key].highest.toLocaleString('en-IN')}
                              </td>
                              <td>
                                {genderMetrics[key].lowest.toLocaleString('en-IN')}
                              </td>
                              <td>
                                {parseInt(genderMetrics[key].average).toLocaleString('en-IN')}
                              </td>
                              <td>
                                {genderMetrics[key].median.toLocaleString('en-IN')}
                              </td>
                            </tr>
                          )
                        }) : null : <span>No Data Found</span>}
                </tbody>
              </Table>
            </Col>
          </Row>
          

          <Row className="mt-4 mb-2 pb-3 border-bottom">
            <Col>
              <span className="r-heading-3 r-text--grey-5">Department</span>
            </Col>
          </Row>
          <Row className="border-bottom pb-4 my-2">
            <Col xs="12">
              {departmentChartData.length > 0 ? <PieChartCOMP data={departmentChartData} datakey="count" /> : <p>No records found</p>}
            </Col>
            <Col xs={12}>
              <Table className="mb-3">
                <thead>
                  <tr className="tableheader">
                    {columns.map((col, i) =>
                      <th style={{ textAlign: "center" }} key={i}>
                        {col}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                      {departmentMetrics ? Object.keys(departmentMetrics) && Object.keys(departmentMetrics).length>0 ?
                        Object.keys(departmentMetrics).map((key,i)=>{
                          return (
                            <tr style={{ textAlign: "center" }} key={i}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {departmentMetrics[key].participated_students}
                              </td>
                              <td>
                                {departmentMetrics[key].total_offer}
                              </td>
                              <td>
                                {departmentMetrics[key].placed}
                              </td>
                              <td>
                                {departmentMetrics[key].unplaced}
                              </td>
                              <td>
                                {departmentMetrics[key].highest.toLocaleString('en-IN')}
                              </td>
                              <td>
                                {departmentMetrics[key].lowest.toLocaleString('en-IN')}
                              </td>
                              <td>
                                {parseInt(departmentMetrics[key].average).toLocaleString('en-IN')}
                              </td>
                              <td>
                                {departmentMetrics[key].median.toLocaleString('en-IN')}
                              </td>
                            </tr>
                          )
                        }) : null : <span>No Data Found</span>}
                </tbody>
              </Table>
            </Col>
          </Row>
          

          <Row className="mt-4 mb-2 pb-3 border-bottom">
            <Col>
              <span className="r-heading-3 r-text--grey-5">Degree</span>
            </Col>
          </Row>
          <Row className="border-bottom pb-4 my-2 justify-content-center">
            <Col xs="9">
              {degreeChartData.length > 0 ? <ResponsiveContainer width='100%' aspect={10.0 / 4.0}>
                <BarChart  data={degreeChartData} >
                  <XAxis dataKey="name" tickFormatter={this.tickFormatter} />
                  <YAxis />
                  <Legend />
                  <Tooltip />
                  <Bar barSize={8} stackId="a" dataKey="min_ctc_value_inr" name="Min CTC" fill="#82ca9d" />
                  <Bar barSize={8} stackId="b" dataKey="max_ctc_value_inr" name="Max CTC" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer> : <p>No records found</p>}
            </Col>
            <Col xs={12}>
              <Table className="mb-3">
                <thead>
                  <tr className="tableheader">
                    {columns.map((col, i) =>
                      <th style={{ textAlign: "center" }} key={i}>
                        {col}
                      </th>

                    )}
                  </tr>
                </thead>
                <tbody>
                      {campusDegreeMetrics ? Object.keys(campusDegreeMetrics) && Object.keys(campusDegreeMetrics).length>0 ?
                        Object.keys(campusDegreeMetrics).map((key,i)=>{
                          return (
                            <tr style={{ textAlign: "center" }} key={i}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {campusDegreeMetrics[key].participated_students}
                              </td>
                              <td>
                                {campusDegreeMetrics[key].total_offer}
                              </td>
                              <td>
                                {campusDegreeMetrics[key].placed}
                              </td>
                              <td>
                                {campusDegreeMetrics[key].unplaced}
                              </td>
                              <td>
                                {campusDegreeMetrics[key].highest.toLocaleString('en-IN')}
                              </td>
                              <td>
                                {campusDegreeMetrics[key].lowest.toLocaleString('en-IN')}
                              </td>
                              <td>
                                {parseInt(campusDegreeMetrics[key].average).toLocaleString('en-IN')}
                              </td>
                              <td>
                                {campusDegreeMetrics[key].median.toLocaleString('en-IN')}
                              </td>
                            </tr>
                          )
                        }) : null : <span>No Data Found</span>}
                </tbody>
              </Table>

            </Col>
          </Row>
          
          <Row className="mt-4 mb-2 pb-3 border-bottom">
            <Col>
              <span className="r-heading-3 r-text--grey-5">Batch</span>
            </Col>
          </Row>
          <Row className="border-bottom pb-4 my-2 justify-content-center">
            <Col xs={12}>
              {batchChartData.length > 0 ? <PieChartCOMP data={batchChartData} datakey="count" /> : <p>No records found</p>}
            </Col>
            <Col xs={12}>
              <Table className="mb-3">
                <thead>
                  <tr className="tableheader">
                    {columns.map((col, i) =>
                      <th style={{ textAlign: "center" }} key={i}>
                        {col}
                      </th>

                    )}
                  </tr>
                </thead>
                <tbody>
                      {batchMetrics ? Object.keys(batchMetrics) && Object.keys(batchMetrics).length>0 ?
                        Object.keys(batchMetrics).map((key,i)=>{
                          return (
                            <tr style={{ textAlign: "center" }} key={i}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {batchMetrics[key].participated_students}
                              </td>
                              <td>
                                {batchMetrics[key].total_offer}
                              </td>
                              <td>
                                {batchMetrics[key].placed}
                              </td>
                              <td>
                                {batchMetrics[key].unplaced}
                              </td>
                              <td>
                                {batchMetrics[key].highest.toLocaleString('en-IN')}
                              </td>
                              <td>
                                {batchMetrics[key].lowest.toLocaleString('en-IN')}
                              </td>
                              <td>
                                {parseInt(batchMetrics[key].average).toLocaleString('en-IN')}
                              </td>
                              <td>
                                {batchMetrics[key].median.toLocaleString('en-IN')}
                              </td>
                            </tr>
                          )
                        }) : null : <span>No Data Found</span>}
                </tbody>
              </Table>

            </Col>
          </Row>

          <Row className="mt-4 mb-3 pb-3 border-bottom">
            <Col>
              <span className="r-heading-3 r-text--grey-5">Classification</span>
            </Col>
          </Row>
          <Row className="border-bottom pb-4 my-2 justify-content-center">
            <Col xs="9">
              {/* {classificationChartData.length > 0 ? <PieChartCOMP data={classificationChartData} datakey="count" /> : <p>No records found</p>} */}
              {classificationChartData.length ? <ResponsiveContainer width='100%' aspect={10.0 / 4.0}>
                <BarChart margin={{ left: 10 }} data={classificationChartData} >
                  <XAxis tickSize={4} dataKey="name" tickFormatter={this.tickFormatter} />
                  <YAxis />
                  <Legend />
                  <Tooltip />
                  <Bar barSize={8} stackId="a" dataKey="min_ctc_value_inr" name="Min CTC" fill="#82ca9d" />
                  <Bar barSize={8} stackId="b" dataKey="max_ctc_value_inr" name="Max CTC" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>: <p>No records found</p>}
            </Col>
            <Col xs={12}>
              <Table className="mb-3">
                <thead>
                  <tr className="tableheader">
                    {classificationColumns.map((col, i) =>{
                        return(
                          <th style={{ textAlign: "center" }} key={i}>
                            {col}
                          </th>
                        )
                    })
                  }
                  </tr>
                </thead>
                <tbody>
                  {classificationMetrics? classificationMetrics.map((key,i)=>{
                    if(key.data && key.data.length>0){
                      let categoryData =key.data;
                      return categoryData.map(data=>{
                        return(
                              <tr style={{ textAlign: "center" }} key={i}>
                                <td>
                                  {key.name}
                                </td>
                                <td>
                                  {data.name}
                                </td>
                                <td>
                                  {data.total_metrics.total_offer}
                                </td>
                                <td>
                                  {data.total_metrics.highest.toLocaleString('en-IN')}
                                </td>
                                <td>
                                  {data.total_metrics.lowest.toLocaleString('en-IN')}
                                </td>
                                <td>
                                  {parseInt(data.total_metrics.average).toLocaleString('en-IN')}
                                </td>
                                <td>
                                  {data.total_metrics.median.toLocaleString('en-IN')}
                                </td>
                              </tr>
                            )


                        })
                        }
                      }) : <span>No Data Found</span>}
                    </tbody>
                  </Table>

            </Col>
          </Row>


            
          <Row className="mt-4 mb-2 pb-3 border-bottom">
            <Col>
              <span className="r-heading-3 r-text--grey-5">Offer Type</span>
            </Col>
          </Row>
          <Row className="border-bottom pb-4 my-2 justify-content-center">
            <Col xs={12}>
              {offerTypeChartData.length > 0 ? <PieChartCOMP data={offerTypeChartData} datakey="count" /> : <p>No records found</p>}
            </Col>
            <Col xs={12}>
              <Table className="mb-3">
                <thead>
                  <tr className="tableheader">
                    {columns.map((col, i) =>
                      <th style={{ textAlign: "center" }} key={i}>
                        {col}
                      </th>

                    )}
                  </tr>
                </thead>
                <tbody>
                      {offerTypeMetrics ? Object.keys(offerTypeMetrics) && Object.keys(offerTypeMetrics).length>0 ?
                        Object.keys(offerTypeMetrics).map((key,i)=>{
                          return (
                            <tr style={{ textAlign: "center" }} key={i}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {offerTypeMetrics[key].participated_students}
                              </td>
                              <td>
                                {offerTypeMetrics[key].total_offer}
                              </td>
                              <td>
                                {offerTypeMetrics[key].placed}
                              </td>
                              <td>
                                {offerTypeMetrics[key].unplaced}
                              </td>
                              <td>
                                {offerTypeMetrics[key].highest.toLocaleString('en-IN')}
                              </td>
                              <td>
                                {offerTypeMetrics[key].lowest.toLocaleString('en-IN')}
                              </td>
                              <td>
                                {parseInt(offerTypeMetrics[key].average).toLocaleString('en-IN')}
                              </td>
                              <td>
                                {offerTypeMetrics[key].median.toLocaleString('en-IN')}
                              </td>
                            </tr>
                          )
                        }) : null : <span>No Data Found</span>}
                </tbody>
              </Table>

            </Col>
          </Row>



          {/*<Row className=" align-items-center my-2">
            <Col xs="12">
              <h3 className="r-heading-3">Offers by gender</h3>
              {offersByGender.length > 0 ? <PieChartCOMP data={offersByGender} datakey="doc_count" /> : <p>No records found</p>}
            </Col>
          </Row>*/}
          
          {/*<Row className="my-2">
            <Col xs="12">
              <h3 className="r-heading-3">Offers by sector type</h3>
              {offersBySectors.length > 0 ? <PieChartCOMP data={offersBySectors} datakey="doc_count" /> : <p>No records found</p>}
            </Col>
          </Row>
          <Row className="my-2">
                      <Col xs="12">
                        <h3 className="r-heading-3">Offers by role</h3>
                        {offersByRole.length > 0 ? <PieChartCOMP data={offersByRole} datakey="doc_count" /> : <p>No records found</p>}
                      </Col>
                    </Row>*/}
          {/*<Row className="my-2">
            <Col xs="12">
              <h3 className="r-heading-3">Offers by department</h3>
              {offersByDep.length > 0 ? <PieChartCOMP data={offersByDep} datakey="doc_count" /> : <p>No records found</p>}
            </Col>
          </Row>
          <Row className="my-2">
            <Col xs="12">
              <h3 className="r-heading-3">Offers by degree</h3>
              {offersByDegree.length > 0 ? <PieChartCOMP data={offersByDegree} datakey="doc_count" /> : <p>No records found</p>}
            </Col>
          </Row>

         {/* <Row className="my-2">
                     <Col xs="12" >
                       <h3 className="r-heading-3">Performance by Sector </h3>
                       {offersBySectors.length > 0 ? <ResponsiveContainer width='100%' aspect={10.0 / 4.0}>
                         <BarChart margin={{ left: 50 }} data={offersBySectors} >
                           <XAxis dataKey="name" tickFormatter={this.tickFormatter} />
                           <YAxis />
                           <Legend />
                           <Tooltip />
                           <Bar barSize={50} dataKey="min_ctc_value" name="Min CTC" fill="#82ca9d" />
                           <Bar barSize={50} dataKey="max_ctc_value" name="Max CTC" fill="#8884d8" />
                         </BarChart>
                       </ResponsiveContainer> : <p>No records found</p>}
                     </Col>
                   </Row>*/}
          {/*<Row className="my-2">
            <Col xs="12" >
              <h3 className="r-heading-3">Performance by Gender </h3>
              {offersByGender.length > 0 ? <ResponsiveContainer width='100%' aspect={10.0 / 4.0}>
                <BarChart margin={{ left: 50 }} data={offersByGender} >
                  <XAxis dataKey="name" tickFormatter={this.tickFormatter} />
                  <YAxis />
                  <Legend />
                  <Tooltip />
                  <Bar barSize={50} dataKey="min_ctc_value_inr" name="Min CTC" fill="#82ca9d" />
                  <Bar barSize={50} dataKey="max_ctc_value_inr" name="Max CTC" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer> : <p>No records found</p>}
            </Col>
          </Row>
          {/*<Row className="my-2">
                      <Col xs="12">
                        <h3 className="r-heading-3">Performance by Role</h3>
                        {offersByRole.length > 0 ? <ResponsiveContainer width='100%' aspect={10.0 / 4.0}>
                          <BarChart margin={{ left: 50 }} data={offersByRole} >
                            <XAxis dataKey="name" tickFormatter={this.tickFormatter} />
                            <YAxis />
                            <Legend />
                            <Tooltip />
                            <Bar barSize={50} dataKey="min_ctc_value" name="Min CTC" fill="#82ca9d" />
                            <Bar barSize={50} dataKey="max_ctc_value" name="Max CTC" fill="#8884d8" />
                          </BarChart>
                        </ResponsiveContainer> : <p>No records found</p>}
                      </Col>
                    </Row>*/}
          {/*<Row className="my-2">
            <Col xs="12" >
              <h3 className="r-heading-3">Performance by Department </h3>
              {offersByDep.length > 0 ? <ResponsiveContainer width='100%' aspect={10.0 / 4.0}>
                <BarChart margin={{ left: 50 }} data={offersByDep} >
                  <XAxis dataKey="name" tickFormatter={this.tickFormatter} />
                  <YAxis />
                  <Legend />
                  <Tooltip />
                  <Bar barSize={50} dataKey="min_ctc_value_inr" name="Min CTC" fill="#82ca9d" />
                  <Bar barSize={50} dataKey="max_ctc_value_inr" name="Max CTC" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer> : <p>No records found</p>}
            </Col>
          </Row>
          <Row className="my-2">
            <Col xs="12">
              <h3 className="r-heading-3">Performance by Degree</h3>
              {offersByDegree.length > 0 ? <ResponsiveContainer width='100%' aspect={10.0 / 4.0}>
                <BarChart margin={{ left: 50 }} data={offersByDegree} >
                  <XAxis dataKey="name" tickFormatter={this.tickFormatter} />
                  <YAxis />
                  <Legend />
                  <Tooltip />
                  <Bar barSize={50} dataKey="min_ctc_value_inr" name="Min CTC" fill="#82ca9d" />
                  <Bar barSize={50} dataKey="max_ctc_value_inr" name="Max CTC" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer> : <p>No records found</p>}
            </Col>
          </Row>
          <Row className="my-2">
            <Col xs="12">
              <h3 className="r-heading-3">Performance by Classification</h3>
              {offersByClassification.length > 0 ? <ResponsiveContainer width='100%' aspect={10.0 / 4.0}>
                <BarChart margin={{ left: 50 }} data={offersByClassification} >
                  <XAxis dataKey="name" tickFormatter={this.tickFormatter} />
                  <YAxis />
                  <Legend />
                  <Tooltip />
                  <Bar barSize={50} dataKey="min_ctc_value_inr" name="Min CTC" fill="#82ca9d" />
                  <Bar barSize={50} dataKey="max_ctc_value_inr" name="Max CTC" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer> : <p>No records found</p>}
            </Col>
          </Row>*/}
        </Aux>}
      </Container>
    )
  }
}

export default ProcessPerformance;
