import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import moment from 'moment';
import Button from './Button';
import { RichTextViewer } from './Forms/RichTextViewer';
import like from '../../assets/like.svg';


class FeedPost extends Component {
  render() {
    let { data, files } = this.props;
    let { created_on,file_id, description, title, post_tag, post_banner_id, post_creator_type,no_of_like, is_liked } = data;
    let classNames = ["m-2 r-text--lg fi flaticon-like", (is_liked ===1)?"r-text--brandA":"r-text--brandC"].join(" ");
    var style = {
      background : data.origin_type === "APPLICATION PUBLISH" ? "#868686cc" : (data.origin_type === "EVENT" ? "#3174adb8" : (data.origin_type === "SHORTLIST RELEASE" ? "#35a2a2d4" : "#ffffff")) ,
      color : data.origin_type === "APPLICATION PUBLISH" || data.origin_type === "EVENT"  || data.origin_type === "SHORTLIST RELEASE" ? "#ffffff" : "#000000",
      borderTopLeftRadius : 8,
      borderTopRightRadius : 8
    } 
    return (
      <div className="post" >
        <Row noGutters className="align-items-center border-bottom p-3" style={style}>
          <Col xs={8}>
            <div className="">
              <span className="r-subheading-2" style={{fontWeight : "500"}}>{title}</span>
              {/* <span className="r-text--sm"> {post_tag ? ` | #${post_tag}` : ""} </span> */}

                {/* <span className="r-heading-4 text-capitalize">{post_creator_type==="3"? "Placement Team": getFullName(data)}</span> */}
                {data.origin_type === "RECRUITER" && <p className="mb-0 r-text--sm">{"Posted by"} - {data.company_name}</p>}
            </div>
          </Col>
          <Col xs={4} className="text-right">
            <em style={{color : data.origin_type === "APPLICATION PUBLISH" || data.origin_type === "EVENT"  || data.origin_type === "SHORTLIST RELEASE" ? "#ffffffb3" : "#ccc", fontSize : 12}}>{moment(created_on).format("hh:mma, Do MMMM YYYY")}</em>
          </Col>
        </Row>
        
        <Row>
          <Col xs={12} >
            <div className="post__body">
                <RichTextViewer>
                  {description}
                </RichTextViewer>
              {/* {(files[file_id]) && <Row noGutters>
                {files[file_id].map((doc, i) => <Col className="pr-3" key={i} xs="auto">
                  <div className="r-tag r-tag--type-1 r-tag--no-delete">
                    <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.file_name || doc.file_url || "Filename"}</a>
                  </div>
                </Col>)}
              </Row> } */}
              {/* <Button className="r-btn--no-radius" color="dark">Know more!</Button> */}
              
            </div>
          </Col>
        </Row>
        {(files[file_id] && files[file_id][0]) && <Row className="p-2">
          <Col xs={12} >
            {
              files[file_id][0].url.includes(".pdf") ? 
              <a className='mb-2 ml-2' style={{color: "#000", backgroundColor: "#eee", border: "1px solid #ccc", padding: 2, fontSize: 12}} target="_blank" rel='noopener noreferrer' href={files[file_id][0].url}>
                <i className='fi flaticon-file mr-2' />Download Attachment
              </a>
              :
              <img src={files[file_id][0].url} className="post__banner" alt=""/>
            }
          </Col>
        </Row>}
        <Row noGutters className="align-items-center p-2 border-top">
                <Col>
                   <Button onClick={(e) => this.props.likePost(data)} className="r-btn--no-radius align-items-center" color="light"><i className={classNames} ></i>{no_of_like}</Button>
                </Col>
                <Col className="text-right">
                   { data.origin_type === "RECRUITER" && this.props.role_type==="PT" && data.status === 2 ? <Button onClick={() => this.props.addStModal(data.post_id)} style={{fontSize : 10, background : "#868686cc"}} color="brandB">Publish Recruiter Feed</Button> : null}
                   { data.origin_type === "APPLICATION PUBLISH" ? <Button onClick={() => {this.props.redirect(this.props.role_type==="PT" ? ("/pt/processes/"+data.origin_id+"/detail") :("/student/recruitments/apply?process_id="+data.origin_id))}} style={{fontSize : 10, background : "#868686cc"}} color="brandB">{this.props.role_type==="PT" ? "View Drive" : "Apply Now"}</Button> : null}
                  { data.origin_type === "EVENT" ? <Button onClick={() => {this.props.redirect("/calendar")}} color="brandB" style={{fontSize : 10, background: "#3174adb8"}}>View Event</Button> : null}
                </Col>

              </Row>
      </div>
    )
  }
}
export default FeedPost;
