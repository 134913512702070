import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Collapse from 'reactstrap/lib/Collapse';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { MeetingStatus } from './meeting-status';
import { ProfileApplicationStatus } from './profile-application-status';
import Button from '../../../global/Button';
import { ProfileApplicationDetail } from './profile-application-detail';
import { Auth } from '../../../../actions/Auth';
import { MeetingDetail } from './meeting-detail';
import { getErrorMessage, getFullName } from '../../../../actions';
import Aux from '../../../global/AuxComp';
import { ApplicationModal } from './application-modal';
import { MultiSelectList, Select } from '../../../global/Forms/Select';
import { RolesSection } from '../companies/roles_section';
import { Loader } from '../../../global/Loader';
import Axios from 'axios';
import store from '../../../../store';
import { ModalFooter, ModalHeader } from 'reactstrap';
import { setError } from '../../../../actions/action-creators';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processModal: false,
      applicationModal: false,
      errorbar: false,
      errorMessage: "",
      isLoading: false,
      addRoleModal: false,
      isOpenDuplicateModal: false,
      selectedRole : '',
      duplicateAppSelectedCampus: store.getState().role.instituteDetail.campus_id,
      duplicateAppProcessList: [],
      companyContactList: null,
      contactUpdateSuccess : false,
      isOpenClassification: false
    }
  }

  newApplication = () => {
    return {
      application_id: "NEW_" + (Math.ceil(Math.random() * 10000)),
      process_id: this.props.process_id,
      process_company_id: this.props.process_company_id,
      process_company_profile_id: "",
      location: "",
      application_eligibility: [],
      application_docs: [],
      application_question: [],
      offer_amount_min: "",
      offer_amount_max: "",
      offer_input_type: "strict",
      offer_currency: "INR",
      offer_amount_type: "Y",
      description: ""
    }
  }

  toggleState = (state) => {
    this.setState({
      [state]: !this.state[state]
    })
  }

  showMeeting = async (e, company_id) => {
    if (e.target.closest(".processes__meeting-status__contact"))
      return
    let redux = store.getState(),
      { campus_company_id } = this.props
    if (this.props.selectedCompany != company_id) {
      let meeting = { campus_company_id }
      // let params = new URLSearchParams();
      // params.append("campus_company_id", campus_company_id)
      // params.append("access_key", redux.role.instituteDetail.access_key)
      var body = {
        campus_company_id,
        access_key : redux.role.instituteDetail.access_key
      }
      this.props.toggleLoading(true)
        Axios.all([Auth.PtCompanies.getCompanyContacts(meeting.campus_company_id), Auth.Meetings.getMeetings(body)])
          .then(Axios.spread((contactsResp, meetingsResp) => {
            meeting.contacts = contactsResp.data
            meeting.list = meetingsResp.data;
            this.props.handleDataChange({ company_id , meeting })
            this.props.toggleLoading(false)
          }))
    }
    this.props.toggleCompany(company_id)
  }

  handleAssigneeChange = async (user_id) => {
    let { company_id, process_company_id } = this.props;
    let formData = {
      process_company_id,
      user_id
    }
    this.props.toggleLoading(true)
    try {
      await Auth.Processes.updateCompanyUser(formData)
    } catch (e) {
      if (e.response && e.response.data)
        this.setState({
          errorbar: true,
          errorMessage: e.response.data.error[0]
        })
      else
        this.setState({ errorbar: true, errorMessage: "Something went wrong" })
    }
    this.props.toggleLoading(false)
    this.props.handleDataChange({ company_id, user_id })
  }

  
  onChangeDuplicateApplication = (e) => {
    let { isOpenDuplicateModal } = this.state;
    isOpenDuplicateModal.application_id = isOpenDuplicateModal.application_id || [];
    if(e.target.checked){
      isOpenDuplicateModal.application_id.push(parseInt(e.target.value));
    }
    else{
      isOpenDuplicateModal.application_id.splice(isOpenDuplicateModal.application_id.indexOf(parseInt(e.target.value)), 1);
    }
    this.setState({isOpenDuplicateModal});
  }

  duplicateApplication = async () => {

    this.props.toggleLoading(true);
    let { isOpenDuplicateModal } = this.state;
    let { processList } = this.props;
    try{
      if(!(isOpenDuplicateModal.application_id && isOpenDuplicateModal.application_id.length)) 
        throw new Error("Please select Application first");
      if(!isOpenDuplicateModal.process_id)  throw new Error("Please select Process first");
      await Promise.all(isOpenDuplicateModal.application_id.map(async application_id => {
        var body = {
          application_id,
          process_id : isOpenDuplicateModal.process_id
        }
        return Auth.Processes.duplicateApplication(body);
      }));
      this.setState({isOpenDuplicateModal : false})
      var refreshProcess = processList.filter(p => p.process_id == isOpenDuplicateModal.process_id)[0];
      if(refreshProcess)this.props.fetchProcessData(refreshProcess);

    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: e.response ? getErrorMessage(e) : e.toString() }))
    }
    this.props.toggleLoading(false);
  }
  openDuplicateApplication = async () => {
    this.props.toggleLoading(true);
    let { duplicateAppSelectedCampus, isOpenDuplicateModal } = this.state;
    console.log(isOpenDuplicateModal);
    try{
      if(isOpenDuplicateModal) isOpenDuplicateModal.process_id = "";
      let resp = await Auth.Processes.getProcesses(duplicateAppSelectedCampus);
      this.setState({duplicateAppProcessList: resp.data, isOpenDuplicateModal: isOpenDuplicateModal || {}});
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: e.response ? getErrorMessage(e) : e.toString() }))
    }
    this.props.toggleLoading(false);
  }

  openCompanyContact = async () => {

    let {campus_company_id, company_id} = this.props;
    this.props.toggleLoading(true)
    try {
      var resp = await Auth.PtCompanies.getCompanyContacts(campus_company_id);
      this.setState({ companyContactList: resp.data })
    } catch (e) {
      console.log(e)
    }
    this.props.toggleLoading(false)
  }

  changeContact = async (contact_id) => {
    if(!contact_id){
      store.dispatch(setError({ showBar: true, message: "Invalid Contact" }));
      return;
    }
    this.props.toggleLoading(true)
    let { process_company_id, company_id, handleDataChange } = this.props;
    try{
      var body = {
        process_company_id, contact_id
      }
      await Auth.Processes.updateCompanyContact(body);
      this.setState({contactUpdateSuccess: true})
      handleDataChange({ company_id, contact_id })
      setTimeout(() => {this.setState({contactUpdateSuccess: false})}, 2500);
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: "Something went wrong!" }))
    }
    this.props.toggleLoading(false)

  }
  handleEditClassification= async (e, selectedClassification) => {
    e.preventDefault();
    let {classification_type} = e.target;
    let {company_id, classifications} = this.props;
    if(classification_type.value){
      this.props.toggleLoading(true)
      let params = {
        campus_company_id: this.props.campus_company_id,
        process_id: this.props.process_id,
        classification_id : classification_type.value,
        process_company_classification_id: selectedClassification.process_company_classification_id
      }
      try{
        if(params.process_company_classification_id){
          var resp = await Auth.classification.editProcessCompanyMapping(params);
          classifications = classifications.map(classification => {
            if(classification.process_company_classification_id === selectedClassification.process_company_classification_id){
              return resp.data.length ? resp.data[0] : classification;
            }
            return classification;
          });
        }
        else{
          var resp2 = await Auth.classification.processCompanyMapping(params);
          if(resp2.data.length) classifications.push(resp2.data[0]);
        }
        this.props.handleDataChange({ company_id , classifications });
        this.setState({ toggleEditClassificationCollapse: false });
      }
      catch(e){
        console.log(e);
      }
      this.props.toggleLoading(false)
    }
  }
  render() {
    let { shortlistVerification,selectedRole, process_company_id, company_id, sector_name, meeting, company_name, user_id, ptList, campus_company_id, profile, profileApplications, selectedApplication, selectedCompany, binderSortedProfileId, classifications, companies, contact_id, classificationDetails } = this.props,
      { applicationModal, isLoading, addRoleModal, isOpenDuplicateModal, companyContactList } = this.state,
      allRolesCreated = true;
    let userRoles =  store.getState().user.roles;
    let userRole =  store.getState().role;
    let accesibleCampusList = userRoles.filter(role => role.role_type === "PT" && role.instituteDetail.university_id === userRole.instituteDetail.university_id).map(role => role.instituteDetail)
    let classificationsNames = classifications.map((data) => data.name);
    // var mappedClassificationIds = classifications.map(classification => classification.classification_id);
    profile.forEach(pro => {
      if (!profileApplications[pro.process_company_profile_id])
        allRolesCreated = false;
    })
    var applicationBinderInfo = Object.keys(profileApplications).reduce((prev, current) => {
      if (profileApplications[current].application_binder_id)
        prev[profileApplications[current].application_binder_id] = Object.keys(profileApplications).filter(data => profileApplications[data].application_binder_id == profileApplications[current].application_binder_id)
      else
        prev["applicationId_" + profileApplications[current].application_id] = [current]

      return prev;
    }, {});
    return (
      <>
      <Row tabIndex={-1} className="company-name">
        <Col xs={12}>
          {/* <Loader show={isLoading} /> */}
          <Row noGutters className="processes__company">
            <Col xs={3} style={{ height: Object.keys(profileApplications).length ? 38 : "auto" }}>
              <Row>
                <Col xs={9}>
                  <h3 className="r-heading-4 r-text--brandA">{company_name}</h3>
                  {/*<span className="r-subheading-4">{sector_name || ""}</span>*/}
                  <span className="r-subheading-4">{classificationsNames.join(" | ") || "Classification"} <i onClick={() => this.setState({isOpenClassification : true})} className="selectable fi flaticon-edit" /></span>
                </Col>
                <Col xs={2} className="">
                  <Button type="button" onClick={(e) => this.openCompanyContact()} className="r-text--sm r-btn--no-radius">
                      <i className={(contact_id ? "":"r-text--blue-3")+" d-inline-block fi flaticon-user"} style={{ fontSize: "15px" }} />
                  </Button>
                  <div className="company-delete">
                    <Button type="button" onClick={(e) => this.props.deleteProcessCompany(process_company_id)} className="r-text--sm r-btn--no-radius">
                      <i className="r-text--blue-3 d-inline-block fi flaticon-bin " style={{ fontSize: "15px" }} />
                    </Button>

                    <Button type="button" onClick={() => this.openDuplicateApplication()} className="r-text--sm r-btn--no-radius">
                      <i className="r-text--blue-3 d-inline-block fi flaticon-duplicate " style={{ fontSize: "15px" }} />
                    </Button>
                  </div>

                  {companyContactList && <Modal centered={true} isOpen={true} toggle={() => this.setState({companyContactList : null})}  >
                    <ModalHeader>Select Contact</ModalHeader>
                    <ModalBody className="p-5">
                      <Select value={this.props.contact_id} onChange={(e) => this.changeContact(e.target.value)}>
                        <option value="">Select Contact</option>
                        {
                          companyContactList.map(contact => 
                            <option value={contact.contact_id}>{getFullName(contact)}</option>
                          )
                        }
                      </Select>
                      {this.state.contactUpdateSuccess && <span className="text-success r-text-subheading--3">Contact Updated!</span>}
                    </ModalBody>
                  </Modal>}
                  <Modal centered={true} isOpen={!!isOpenDuplicateModal} toggle={() => this.setState({isOpenDuplicateModal : false})}  >
                    <ModalHeader>Clone Applications</ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col>
                        <span className="r-text--sm r-subheading-3 d-block">Select Role</span>
                        <MultiSelectList title={isOpenDuplicateModal.application_id && isOpenDuplicateModal.application_id.length ? isOpenDuplicateModal.application_id.length + " Role Selected" : "No Role Selected"}>
                          <ul className="list-unstyled m-0">
                            {Object.keys(profileApplications).map((id, i) => {
                              var app = profileApplications[id];
                              return (
                                <li key={i}>
                                  <input onChange={(e) => this.onChangeDuplicateApplication(e)} checked={(isOpenDuplicateModal.application_id || []).indexOf(app.application_id) >= 0}  type="checkbox" value={app.application_id} />
                                  <label>{app.role_name}</label>
                                </li>
                              )
                            })}
                          </ul>
                        </MultiSelectList>
                        </Col>
                        <Col>
                        {accesibleCampusList.length > 1 ?
                        <div className="mb-3">
                          <span className="r-text--sm r-subheading-3 d-block">Select Campus</span>
                          <Select value={this.state.duplicateAppSelectedCampus} onChange={async (e) => {await this.setState({duplicateAppSelectedCampus: e.target.value}); this.openDuplicateApplication()}}>
                            <option value="">Select</option>
                            {
                              accesibleCampusList.map((instituteInfo, i) => {
                                return <option key={i} value={instituteInfo.campus_id}>{instituteInfo.institute_name}</option>
                              })
                            }
                          </Select>
                        </div>: ""
                        }
                        <span className="r-text--sm r-subheading-3 d-block">Select Placement Drive</span>
                        <Select value={isOpenDuplicateModal.process_id} onChange={(e) => {isOpenDuplicateModal.process_id = e.target.value; this.setState({isOpenDuplicateModal})}}>
                          <option value="">Select</option>
                          {
                            this.state.duplicateAppProcessList.map((process, i) => {
                              return <option key={i} value={process.process_id}>{process.process_name}</option>
                            })
                          }
                        </Select>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button square={true} onClick={this.duplicateApplication} size="sm" color="blue">Submit</Button>
                    </ModalFooter>
                  </Modal>
                </Col>
              </Row>
            </Col>
            <Col xs={9}>
              <Row>
                <Col className="px-2" xs={2}>
                  {/* <h5 className="r-heading-7">Assignee</h5> */}
                  <div className="r-dropdown-2">
                    {/* <span>{getFullName(user) || "N/A"}</span> */}
                    <Select disabled={shortlistVerification} value={user_id || ""} onChange={(e) => this.handleAssigneeChange(e.target.value)}>
                      <option value="">Select</option>
                      {
                        ptList.map(pt => {
                          return <option key={pt.id} value={pt.user_id}>{getFullName(pt.userDetail)}</option>
                        })
                      }
                    </Select>
                  </div>
                </Col>
                <Col className={"px-2" + (shortlistVerification ? " company_meeting_status" : "")} xs={3}>
                  <Row>
                    <Col xs={12} onClick={(e) => this.showMeeting(e, company_id)}>
                      <MeetingStatus company={{ ...this.props }} />
                    </Col>
                  </Row>
                </Col>
                <Col className="px-2" xs={7}>
                  {!shortlistVerification && <Aux>
                    {allRolesCreated ? <Aux>
                      <Button onClick={() => this.toggleState("addRoleModal")} className="processes__application__create r-bg--blue-1">
                        Click to Add Roles
                  </Button>
                      <Modal size="xl" className="modal--centered" zIndex={99999} isOpen={addRoleModal} toggle={() => this.toggleState("addRoleModal")}>
                        <ModalBody>
                          <RolesSection refreshParent={this.props.getCompanies} toggleLoading={this.props.toggleLoading} campus_company_id={campus_company_id} campus_id={this.props.campus_id} />
                        </ModalBody>
                      </Modal>
                    </Aux> :
                      <Button onClick={() => { this.toggleState("applicationModal"); this.setState({ addRoleModal: false }) }} className="processes__application__create r-bg--blue-1">
                        Click to Start Application Process
                  </Button>}
                    <ApplicationModal processStudent={this.props.processStudent} profileApplications={profileApplications} handleDataChange={this.props.handleDataChange} profileList={profile} applicationModal={applicationModal} application={this.newApplication()} toggleModal={this.toggleState} role={this.props.role} applicationBinderInfo={applicationBinderInfo} />
                  </Aux>}
                </Col>
              </Row>
            </Col>
          </Row>
         
          <Row> 
            {!shortlistVerification && <Col xs={12}>
              <Collapse isOpen={selectedCompany === company_id}>
                <MeetingDetail getCompanies={this.props.getCompanies} process_company_id={process_company_id} company_id={company_id} handleDataChange={this.props.handleDataChange} profile={profile} meeting={meeting} contact_id={contact_id} />
              </Collapse>
            </Col>}
          </Row>

          {
            binderSortedProfileId && binderSortedProfileId.map((app, index) => {
              var pro = profile.filter(data => data.process_company_profile_id == app)
              if (pro.length) {
                pro = pro[0];
                let application = profileApplications[app] || {}
                let previosApplication = profileApplications[binderSortedProfileId[index - 1]] || {}
                let isMultipleRoleMerge = application.application_binder_id && application.application_binder_id == previosApplication.application_binder_id;
                return (
                  <Row noGutters key={pro.process_company_profile_id}>
                    <Col xs={{ size: 9, offset: 3 }}>
                      <Row className={isMultipleRoleMerge ? "multiple_role" : ""}>
                        <Col className="px-2" xs={{ size: 3, offset: 2 }}>
                          <Row style={{ height: 38, marginBottom: 10 }}><Col className="d-flex justify-content-center align-items-center" xs={12}><span className="processes__company__roles"></span></Col></Row>
                        </Col>
                        <Col onClick={()=>this.props.toggleApplication(application.application_id, company_id)} className={"px-2 " + (isMultipleRoleMerge ? "multipleRoleBar" : "")} xs={7} >
                          <ProfileApplicationStatus processStudent={this.props.processStudent} processId={this.props.processId} role={this.props.role} shouldPublish={this.props.shouldPublish} handleDataChange={this.props.handleDataChange} profile={pro} application={application} />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Collapse isOpen={selectedApplication === application.application_id}>

                        <ProfileApplicationDetail campus_company_id={campus_company_id} companies={companies} company_name={company_name} processStudent={this.props.processStudent} shouldPublish={this.props.shouldPublish} shortlistVerification={shortlistVerification} handleDataChange={this.props.handleDataChange} profile={pro} application={application} role={this.props.role}
                          recruiter={this.props.recruiter} processId={this.props.processId} company_id={company_id}
                        />
                      </Collapse>
                    </Col>
                  </Row>
                )
              }
            })}
        </Col>
      </Row>
      <Modal centered={true} size={"md"} isOpen={this.state.isOpenClassification} toggle={()=>this.setState({isOpenClassification: false})}>
        <ModalBody toggle={()=>this.setState({isOpenClassification: false})}>
        <Row>
            {classificationDetails.map((category)=>{
              var mappedIds = category.classification_list.map(o => o.classification_id);
              var selectedClassification = classifications.filter(classification => mappedIds.indexOf(classification.classification_id) !== -1)[0] || {};
              return (
              <Col xs={4}>
                <div className="card shadow-sm p-4 w-100">
                <Row>
                  <Col xs={10}>
                    <h1 className="r-subheading-3 r-text--blue-3">{selectedClassification.name || "-"}</h1>
                  </Col>
                  <Col xs={2}>
                    <i className="fi flaticon-edit r-text--blue-3 settings__button-cursor" onClick={async()=>await this.setState({toggleEditClassificationCollapse  : this.state.toggleEditClassificationCollapse ? false : category.classification_category_id })} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1 className="r-subheading-4">{category.name}</h1>
                  </Col>
                </Row>

                <Collapse isOpen={this.state.toggleEditClassificationCollapse === category.classification_category_id} >
                <Row>
                <Col>
                  <form onSubmit={(e) => this.handleEditClassification(e, selectedClassification)}>
                  <Select name="classification_type" defaultValue={selectedClassification.classification_id} >
                  <option key="" value="">Select </option>
                  { category.classification_list.map(classification => 
                      <option  value={classification.classification_id}  key={classification.classification_id}>{classification.name} </option>
                    )
                  }
                  
                  </Select>
                  <Button type="submit" color="dark" className="r-text--sm r-btn--no-radius mt-2">Save</Button>
                  <span className="r-subheading-4 ml-3 settings__button-cursor" onClick={()=>this.setState({toggleEditClassificationCollapse: false})}>Cancel</span>
                  </form>
                </Col>
                </Row>
                </Collapse>
                </div>
              </Col>)
            })
            }
          </Row>
        </ModalBody>
      </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(Company);
