import React, { Component } from 'react';
import { Auth } from '../../../actions/Auth';
import { setLoading } from '../../../actions/action-creators';
import {Row,Col,Collapse } from 'reactstrap';
import Button from '../../global/Button';
import {Errorbar} from '../../global/errorbar';
import {Confirmation} from '../../global/confirmation';
import { InputField } from '../../global/Forms';
import Aux from '../../global/AuxComp';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import moment from 'moment';

 class AddUniversityCollapse extends Component{
  constructor(props){
    super(props);
    this.state = { 
      openUniverstyCollapse : false,
      universityName : null,
      spocMail : null,
      spocName : null,
      universityState : null,
      universityCity : null,
      universityZipcode : null,
      universityAddress : null,
      subscription_end_date: null
    };
  }

  toggleCollapse = () => {
    this.setState({openUniverstyCollapse : !this.state.openUniverstyCollapse})
  }

  createUniversitySpoc = async()=>{
    this.setState({loading : true});
    let params = {
      "name" : this.state.universityName,
      "city" : this.state.universityCity,
      "state" : this.state.universityState,
      "address" : this.state.universityAddress,
      "zip_code" : this.state.universityZipcode,
      subscription_end_date: this.state.subscription_end_date

    }
    try{
      let universityResp = await Auth.University.createUniversity(params);
      let accessKeyParams = {
        "university_id" : universityResp.data.insertId
      }
      try{
        let accessKeyResp = await Auth.Spoc.fetchAccessKey(accessKeyParams);
        await this.createRole(accessKeyResp.data[0].access_key);
        await this.props.handleFetchInstitute();
      }
    catch(e){
      console.log(e);
    }
    }
    catch(e){
      console.log(e);
    }
    this.setState({loading : false});
  }

  createRole = async(accessKey)=>{
    let roleParams = {
      "role_name": "Spoc",
      "access_key": accessKey,
      "role_type": 2,
      "description": "Spoc"
    }
    try{
      let roleResp = await Auth.Spoc.addRole(roleParams);
      this.createUser(roleResp.data[0].role_id , accessKey);
    }
    catch(e){
      console.log(e);
    }
  }

  createUser = async(roleId , accessKey)=>{
    let userParams ={
      "first_name" :this.state.spocName,
      "email": this.state.spocMail
    }
    try{
      let userResp = await Auth.User.signUp(userParams);
      this.userRoleMapping(userResp.data[0].id, roleId ,accessKey)
    }
    catch(e){
      console.log(e);
    }
  }

  userRoleMapping  = async(userId , roleId, accessKey)=>{
    this.props.toggleLoading(true)
    let userRoleMappingParams = {
      "user_id": userId,
      "role_id": roleId,
      "role_level": 1,
      "access_key": accessKey,
      "access_level": 1
    }
    try{
      await Auth.Spoc.createUserRoleMapping(userRoleMappingParams);
    }
    catch(e){
      console.log(e);
    }
    this.props.toggleLoading(false)
  }

  submitForm = async(e)=>{
    this.props.toggleLoading(true)

    e.preventDefault();
    e.persist()
    let {spocMail , spocName, universityName , universityCity , universityState , universityZipcode, universityAddress} = e.target
    await this.setState({
      spocName : spocName.value,
      spocMail : spocMail.value,
      universityName : universityName.value,
      universityCity : universityCity.value,
      universityState : universityState.value,
      universityZipcode : universityZipcode.value,
      universityAddress : universityAddress.value,
    })
    await this.createUniversitySpoc();
    e.target.reset();
    this.props.toggleLoading(false)
    this.toggleCollapse();
  }

  render(){
   
      return (
        <Aux>
          <Col>
            <Button onClick={this.toggleCollapse} className="r-text--sm mt-4" color="brandB"><i className="fi flaticon-plus mr-2"></i> Add New University</Button>
          </Col>
          <Col xs={12}>
            <Collapse isOpen={this.state.openUniverstyCollapse} className="border p-3 m-3" >
            <form onSubmit={this.submitForm} id="createUniversityForm" >
              <Row>
                <Col xs="6" className="border-right">
                  <Row>
                    <Col className="m-3">
                      <span className="modal-title r-heading-6">Step 1 - Enter University Details</span>
                    </Col>
                  </Row>
                    <Row>
                      <Col>
                        <InputField placeholder="Name" name="universityName" type="text"  className="w-100 border-0 m-2" required={true} />
                      </Col>
                      <Col xs="6">
                        <InputField placeholder="State" name="universityState" type="text" className="w-100 border-0 m-2"    />
                      </Col>
                      <Col xs="6">
                        <InputField placeholder="City" name="universityCity" type="text"   className="w-100 border-0 m-2"   />
                      </Col>
                      <Col xs="6">
                        <InputField placeholder="Address" name="universityAddress" type="text" className="w-100 border-0 m-2"  />
                      </Col>
                      <Col xs="6">
                        <InputField placeholder="ZipCode" name="universityZipcode" type="text" pattern="[0-9]{6}" className="w-100 border-0 m-2" />
                      </Col>
                      <Col xs="6">
                        <div className='m-2'>
                          <SingleDatePicker
                          value={this.state.subscription_end_date}
                          onDateChange={(date) => this.setState({ subscription_end_date: moment(date).format("YYYY-MM-DD HH:mm:ss") })}
                          id="subscription_end_date" />  
                        </div>                    
                      </Col>
                    </Row>
                </Col>
                <Col xs="6">
                  <Row>
                    <Col className="m-3">
                      <span className="modal-title r-heading-6">Step 2 - Enter Spoc Details</span>
                    </Col>
                  </Row>
                  <Row className="m-3">
                    <Col xs="6">
                      <InputField placeholder="Name" name="spocName" type="text" className="w-100 border-0 m-2"   required />
                    </Col>
                    <Col xs="6">
                      <InputField placeholder="Email" name="spocMail" type="email"   className="w-100 border-0 m-2"  required />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col align="end">
                  <Button color="dark" className="mt-2"> Save </Button>
                  <Button type="reset" className="r-bg--light r-text--dark mt-2 ml-2" onClick={this.toggleCollapse}>Cancel</Button>
                </Col>
              </Row>
            </form>
            </Collapse>
          </Col>
        </Aux>
  )
 }
}

export default AddUniversityCollapse;
