import React,{Component} from 'react';
import { Collapse } from 'reactstrap';
import {Col,Row} from 'reactstrap'
import Aux from '../../global/AuxComp';
import {StatusButton} from './verifier_status_button';
import {CardFooter} from 'reactstrap';
import { connect } from 'react-redux';

class CvPointCollapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
      };

    this.section = Math.ceil(Math.random() * 10000)
    this.popoverName = "POP-" + this.section
  }

  toggle = () => {
    this.props.toggleSection(this.section)
    this.props.getfileId(this.props.cvPoint.file);
  }

  render() {
    let cvPoint = this.props.cvPoint;
    let allCommentThread= this.props.getAllCommentThread!=null?(this.props.getAllCommentThread.filter(commentThread=>
       commentThread.profile_type=="CV_POINT" && commentThread.profile_id==cvPoint.cv_point_id && commentThread.student_id==cvPoint.student_id &&commentThread.verifier_id==this.props.role.assigned_role_id)):null;
    return (
      <Aux>
      <CardFooter onClick={this.toggle} className={"r-bg--light verifier__card-body  "+(this.section==this.props.collapse?"active":"")} >
      <Row noGutters>
      <Col xs="1"  className="px-3"><b>
      <img className="img-fluid" src="/images/achievement.png" alt="CV Point" /></b>
      </Col>
      <Col xs="11">
      <Row>
      <Col xs={10}>
      <span className="r-subheading-4 align-items-center r-richtext" dangerouslySetInnerHTML={{__html: cvPoint.text}}></span>
      </Col>
      {cvPoint.file!=0? <Col xs={2} align="end">
          <span title="Proof Attached"><img src="/images/paper-clip@2x.png" alt="Proof" className="w-25" /></span>
      </Col> : null}
      </Row>
      </Col>
      </Row>
      </CardFooter>
      <Collapse className="r-bg--blue-1" isOpen={this.props.collapse==this.section}>
      <StatusButton allCommentThread={allCommentThread} student_id={cvPoint.student_id} id={cvPoint.cv_point_id} profile_type="cv_point" profileType="CV_POINT" getStudentDetails={this.props.getStudentDetails}  popoverName={this.popoverName} data={cvPoint} />
      </Collapse>
      </Aux>
    );
  }
}
const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(CvPointCollapse)
// export default CvPointCollapse;
