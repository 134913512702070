import React,{Component} from 'react';
import {Container , Row , Col , Button} from 'reactstrap';
import moment from 'moment';
import RoundEvent from './round_event';

class RoundShortlistOverview extends Component {
  constructor(props) {
    super(props);
  }

  render(){
    let {application , roundData} = this.props;
    return(
      <div>
      {
        application.rounds && application.rounds.length?application.rounds.map((singleRound,i)=>{
      let roundDataName = "R-"+singleRound.application_round_id;
      if( singleRound.shortlist_release_status==3 ){
        let announceDate =[];
        if(roundData[roundDataName] && roundData[roundDataName].length){
           if(roundData[roundDataName][0].application_id == application.application_id && roundData[roundDataName][0].shortlisting_data.length){
             roundData[roundDataName][0].shortlisting_data.forEach(round=>{
               if(round.application_round_id == singleRound.application_round_id){
                 announceDate.push(roundData["R-"+round.application_round_id]);
               }
             })
           }
         }
         let lockDate = announceDate.length && announceDate[0].length? (announceDate[0][0].shortlisting_data.length?announceDate[0][0].shortlisting_data.filter(date=>date.application_round_id == singleRound.application_round_id):null):[] ;
        return(
          <Row key={i} noGutters className="processes__application__post m-0 mb-3">
            <Col xs={8} className="p-2">
              <p className="r-subheading-3">{singleRound.round_name && singleRound.round_name}{' Announced'}</p>
              <div className="r-text--sm r-text--grey">
                <span>{lockDate.length>0? (lockDate[0].locked_on? moment(lockDate[0].locked_on).format('Do MMMM YYYY') :null) :null}</span>
                <p>{lockDate.length>0? (lockDate[0].locked_on? moment(lockDate[0].locked_on).format('hh:mm A') :null) :null}</p>
              </div>
            </Col>
            <Col xs={4} >
              <RoundEvent  studentCollection = {announceDate[0]}  applicationId={singleRound.application_id} applicationRoundId={singleRound.application_round_id} />
            </Col>
          </Row>
        )
      }
    })  : null}
    </div>
  )}
}
export default RoundShortlistOverview;
