import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Collapse from 'reactstrap/lib/Collapse';
import Aux from '../../../global/AuxComp';

export class RecruitmentNavigation extends Component {
 

  render() {
    const { tab, showNav, changeTab, toggleNavbar } = this.props;
    return (
      <Aux>
      <div className="recruitment_nav_collapse sticky-top">
        <Collapse isOpen={showNav} >
          <div className="student__nav">
            <Container>

                <Row noGutters className="justify-content-center">
                  <Col xs={12} sm={9} md={6} lg={3}>
                    <Row noGutters className="student__nav__slider">
                      <Col xs={{ size: 4, offset: (4 * (tab - 1)) }} className="r-transition--margin">
                        <div className="student__nav__slider-bar"></div>
                      </Col>
                      <Col data-tab="profile" className="student__nav__slider-placeholder" onClick={changeTab} xs={4}>Profile</Col>
                      <Col data-tab="resumes" xs={{ size: 4, offset: 4 }} className="student__nav__slider-placeholder" onClick={changeTab}>Resume</Col>
                      <Col data-tab="apply" xs={{ size: 4, offset: 8 }} className="student__nav__slider-placeholder" onClick={changeTab}>Apply</Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Collapse>
          <div className={`student__nav-toggle ${showNav ? "" : "student__nav-toggle--collapsed"}`}>
            <button onMouseOver={() => toggleNavbar(true)} className="recruitment_nav_button">
              <i className="fi flaticon-arrow" />
            </button>
          </div>
        </div>
        
      </Aux>
    )
  }
}
