import React, { Component } from 'react';
import Aux from '../../../../global/AuxComp';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import moment from 'moment';
import Collapse from 'reactstrap/lib/Collapse';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { ProfileActions } from './profile-actions';
import Button from '../../../../global/Button';
import AutosuggestComp from '../../../../global/AutosuggestComp';
import { Auth } from '../../../../../actions/Auth';
import { CONSTANTS } from '../../../../../constants';
import { MonthSelect, YearSelect } from '../../../../global/Forms/Select';
import { InputField } from '../../../../global/Forms';
import { filterCVPoints } from '../../../../../actions';
import { CVPoint } from './cv-point';
import { FileUpload } from '../../../../global/FileUpload';
import { AddSuggestion } from '../../../../global/Forms/AddSuggestion';
import { Errorbar } from '../../../../global/errorbar';
import { Switch } from '../../../../global/Forms/ToggleSwitch';

export class POR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      deleteId: null,
      suggestions: {
        getDesignations: [],
        getInstitutes: []
      },
      designationValue: "",
      instituteValue: "",
      errorbar: false,
      message: ''
    };

    this.autoSuggestProps = {
      className: "w-100 r-inputfield",
      onChange: this.handleFieldChange
    }
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  handleFieldChange = (e, por) => {
    if (e.target.name === "designationValue") this.setDesignationValue({ designation_id: null, designation_name: "" }, por)
    if (e.target.name === "instituteValue") this.setInstituteValue({ institution_id: null, college_name: "" }, por)
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  isValid = (obj) => {
    let { society,

      designation_name,
      college_name
    } = obj;

    if (!society || !this.state.designationValue || !this.state.instituteValue) {
      this.setState({ message: 'Please fill all mandatory input fields' });
      this.setState({ errorbar: true });
      return false
    }
    return true
  }

  handlePorDetailSubmit = async (e, por) => {

    if (e) e.preventDefault(e);

    if (!this.isValid(por)) {
      return
    }
    this.props.toggleLoading(true);
    if (!por.designation_id) {
      let desgResp = await Auth.General.addDesignation({ designation_name: this.state.designationValue })
      if (desgResp.data.insertId) {
        por.designation_id = desgResp.data.insertId
        por.designation_name = this.state.designationValue
      }
    }
    if (!por.institution_id) {
      let instituteResp = await Auth.General.addInstitute({ college_name: this.state.instituteValue })
      if (instituteResp.data.insertId) {
        por.institution_id = instituteResp.data.insertId
        por.college_name = this.state.instituteValue
      }
    }
    let method = Auth.Student.updatePOR,
      formData = Object.assign({}, por);
    formData.start_date = moment(formData.start_date).startOf("month").format(CONSTANTS.DATE_FORMAT)
    if(formData.currently_working)
        formData.end_date= moment();
    formData.end_date = moment(formData.end_date).startOf("day").format(CONSTANTS.DATE_FORMAT)
    if (typeof por.id === "string") {
      method = Auth.Student.createPOR;
      formData.student_id = this.props.user_info.student_id;
      delete formData.id
    }
    try {
      await method(formData)
      this.props.getStudentProfile()
      if (e) this.props.toggleSection("");

    } catch (e) {

      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }

    }
    this.props.toggleLoading(false);
  }

  deletePorDetail = async () => {
    let { deleteId } = this.state;
    if (!deleteId) return;
    let formData = {
      student_id: this.props.user_info.student_id,
      id: deleteId,
      status: 0
    }
    this.props.toggleLoading(true)
    try {

      if (typeof deleteId === "number")
        await Auth.Student.updatePOR(formData)
      this.props.changePorDetail(formData, true)
      this.toggleModal()
      this.props.getStudentProfile()

    } catch (e) {
      if (e.response.data.error.toString() !== null) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }

    }
    this.props.toggleLoading(false)
  }

  toggleModal = (id) => {
    this.setState({
      modalShow: !this.state.modalShow,
      deleteId: id
    })
  }

  setDesignationValue = (value, por) => {
    let evtObj = {
      target: {
        value: value.designation_id,
        name: "designation_id",
        designation_name: value.designation_name
      }
    }
    this.setState({
      designationValue: value.designation_name
    })
    this.handlePorChange(evtObj, por)
  }

  setInstituteValue = (value, por) => {
    let evtObj = {
      target: {
        value: value.id,
        name: "institution_id",
        college_name: value.college_name,
        university_name: value.university_name
      }
    }
    this.setState({
      instituteValue: value.college_name
    })
    this.handlePorChange(evtObj, por)
  }

  changeYear = (e, field, method, por) => {
    let date = por[field] ? moment(por[field]) : moment();
    por[field] = date[method](e.target.value).format(CONSTANTS.DATE_FORMAT);
    this.props.changePorDetail(por);
  }

  handlePorChange = (e, por) => {
    por[e.target.name] = e.target.value;
    if (e.target.name === "designation_id") {
      por["designation_name"] = e.target.designation_name
    } else if (e.target.name === "institution_id") {
      por["college_name"] = e.target.college_name
      por["university_name"] = e.target.university_name
    }
    this.props.changePorDetail(por)
  }

  addPor = (e) => {
    e.preventDefault();
    this.props.toggleSection(`POR-0`);
    this.setAutoSuggestionValues({});
    this.props.changePorDetail({});
    this.el = e.target.closest(".student__profile__container")
    setTimeout(() => {
      if (this.el)
        this.el.scrollIntoView({ behavior: "smooth", block: "start" })
    }, 300)
  }

  setAutoSuggestionValues = (por) => {
    this.setState({
      instituteValue: por.college_name || "",
      designationValue: por.designation_name || ""
    })
  }

  render() {
    let { files, editableSection, toggleSection, position_of_responsibility, onCancel, cv_point, commentThreads } = this.props;
    let { instituteValue, designationValue, } = this.state;
    this.cvPoints = filterCVPoints(cv_point, "POR")
    return (
      <Aux>
        <div className="r-card student__profile__container">
          <Row noGutters className="">
            <Col xs={12}>
              <h2 className="r-heading-4 r-text--blue-3">Positions of Responsibility</h2>
              {editableSection === "" && <div className="student__profile__actions">
                <Button onClick={this.addPor} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
              </div>}
            </Col>
          </Row>
          {
            position_of_responsibility.length > 0 ? position_of_responsibility.map((por, i) => {
              return (
                <div className={"student__profile__section" + (por.freeze_status == 1 ? " student__profile--freeze" : "")} key={por.id}>
                  <Collapse isOpen={editableSection !== `POR-${i}`}>
                    <h1 className="r-subheading-2 mr-2" style={{ fontWeight: 600 }}>
                      {por.designation_name || <em>Designation</em>}, {por.society || <em>Team</em>}
                    </h1>
                    <h1 className="r-subheading-2 mt-3" style={{ fontWeight: 400 }}>{por.college_name || <em>Organization Name</em>}</h1>
                    <div className="r-text--grey-4 mb-2">{por.start_date ? moment(por.start_date).format("MMM YYYY") : <em>Start Month/Year</em>} - {por.currently_working? "Currently working" : (por.end_date ? moment(por.end_date).format("MMM YYYY") : <em>End Month/Year</em>)}</div>

                    {(files[por.file]) && <Row noGutters>
                      {files[por.file].map((doc, i) => <Col className="pr-3" key={i} xs="auto">
                        <div className="r-tag r-tag--type-1 r-tag--no-delete">
                          <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.file_name || doc.file_url || "Filename"}</a>
                        </div>
                      </Col>)}
                    </Row>}
                  </Collapse>
                  <Collapse isOpen={editableSection === `POR-${i}`} onEntering={() => this.setAutoSuggestionValues(por)}>
                    <form onSubmit={(e) => this.handlePorDetailSubmit(e, por)}>
                      <Row form className="mb-2">
                        <Col xs={12}>
                          <div className="d-flex justify-content-between">
                            <label className="r-heading-7"><span className="r-text--red-1">*</span>Designation</label>
                            {/* <AddSuggestion type="designation" /> */}
                          </div>
                          <AutosuggestComp
                            inputProps={{
                              className: "w-100 r-inputfield",
                              onChange: (e) => this.handleFieldChange(e, por),
                              placeholder: '',
                              value: designationValue || "",
                              name: "designationValue"
                            }}
                            suggestionID="getDesignations"
                            renderSuggestion={(desg) => <div>{desg.designation_name}</div>}
                            setValue={(value) => this.setDesignationValue(value, por)} />
                        </Col>
                      </Row>
                      <Row form className="mb-2">
                        <Col xs={12}>
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Team</label>
                          <InputField className="w-100" type="text" name="society" value={por.society || ""} onChange={(e) => this.handlePorChange(e, por)} />
                        </Col>
                      </Row>
                      <Row form className="mb-2">
                        <Col xs={12}>
                          <div className="d-flex justify-content-between">
                            <label className="r-heading-7"><span className="r-text--red-1">*</span>Organization</label>
                            {/* <AddSuggestion type="institute" /> */}
                          </div>
                          <AutosuggestComp
                            inputProps={{
                              className: "w-100 r-inputfield",
                              onChange: (e) => this.handleFieldChange(e, por),
                              placeholder: '',
                              value: instituteValue,
                              name: "instituteValue"
                            }}
                            suggestionID="getInstitutes"
                            renderSuggestion={(clg) => <div>{clg.college_name}</div>}
                            setValue={(value) => this.setInstituteValue(value, por)} />
                        </Col>
                      </Row>


                      <Row form className="mb-2">
                        <Col xs={6}>
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Start Month/Year</label>
                          <Row noGutters>
                            <Col xs={6}>
                              <MonthSelect onChange={(e) => this.changeYear(e, "start_date", "month", por)} value={por.start_date ? moment(por.start_date).month() : ""} />
                            </Col>
                            <Col xs={6}>
                              <YearSelect onChange={(e) => this.changeYear(e, "start_date", "year", por)} value={por.start_date ? moment(por.start_date).year() : ""} />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={6}>

                          <Row className="justify-content-between align-items-center">
                            <Col xs="auto">
                              <label className="r-heading-7"><span className="r-text--red-1">*</span>End Month/Year</label>
                            </Col>
                            <Col className="d-flex align-items-center" xs="auto">
                              <span className="r-text--sm mr-2">Currently working</span>
                              <Switch isOn={por.currently_working == 1 ? true : false} handleToggle={(e) => this.handlePorChange({ target: { name: "currently_working", value: por.currently_working ? 0 : 1 } }, por)} />
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col xs={6}>
                              <MonthSelect disabled={por.currently_working} onChange={(e) => this.changeYear(e, "end_date", "month", por)} value={por.end_date ? moment(por.end_date).month() : ""} />
                            </Col>
                            <Col xs={6}>
                              <YearSelect disabled={por.currently_working} onChange={(e) => this.changeYear(e, "end_date", "year", por)} value={por.end_date ? moment(por.end_date).year() : ""} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>


                      <div className="d-flex justify-content-end mt-3">
                        <Button type="button" onClick={() => onCancel()} className="r-text--sm">Cancel</Button>
                        <Button type="submit" className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
                      </div>
                      {typeof por.id === "number" && <FileUpload toggleLoading={this.props.toggleLoading} updateData={this.props.changePorDetail} submitData={this.handlePorDetailSubmit} verification={{ user_id: this.props.user_info.user_id, data: por, files }} />}
                    </form>
                  </Collapse>
                  {typeof por.id === "number" && <CVPoint files={files} toggleLoading={this.props.toggleLoading} settings={this.props.settings} toggleCV={this.props.toggleCV} user_info={this.props.user_info} profile_id={por.id} type="POR" getStudentProfile={this.props.getStudentProfile} changeCVPoint={this.props.changeCVPoint} cvPoints={this.cvPoints[por.id] || []} commentThreads={commentThreads} />}
                  {editableSection !== `POR-${i}` && <ProfileActions delete={() => this.toggleModal(por.id)} verification={{ ...por }} toggleSection={() => toggleSection(`POR-${i}`)} isRequiredValidate={true} commentThreads={commentThreads} profileType="POR" />}
                </div>
              )
            }) : <div className="student__profile__section student__profile__section--placeholder">
                <h1 className="r-subheading-2 mr-2" style={{ fontWeight: 600 }}>
                  Designation, Team
            </h1>
                <h1 className="r-subheading-2 mt-3" style={{ fontWeight: 400 }}>Organization</h1>
                <div className="r-text--grey-4 mb-2">Start Date - End Date</div>

              </div>
          }

          <Modal className="modal--delete" isOpen={this.state.modalShow} toggle={() => this.toggleModal()}>
            <ModalBody>
              <p>Are you sure?</p>
              <div className="text-right">
                <Button className="r-text--sm" onClick={() => this.toggleModal()}>Cancel</Button>
                <Button color="dark" onClick={this.deletePorDetail} className="r-text--sm r-btn--no-radius">Delete</Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
      </Aux>
    )
  }
}
