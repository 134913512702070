import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from '../../../../actions/Auth';
import { setUser, setLoading } from '../../../../actions/action-creators';
import StudentDetailModel from '../../../../constants/StudentDetailModel';
import { TEMPLATE_CONFIG } from '../../../../constants/template-1';
import { generateMasterCV, getFiles, generateStudentCVDetails } from '../../../../actions';
import { CONSTANTS } from '../../../../constants';
import {Row,Col,Container,Table,Pagination, PaginationItem,Popover, PaginationLink} from 'reactstrap';
import {HorizontalLine} from '../../../global/horizontal_line';
import Button from '../../../global/Button';
import {SelectList} from '../../../global/Forms/Select'
import { Switch } from '../../../global/Forms/ToggleSwitch';

class StudentNavPT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOn: false, 
    }
  }

    handleToggle(e) {
      this.setState({isOn: !this.state.isOn});
    }

  render() {
    const { tab, studentDetails,goBackto,selectedProcess,processList,changeTab, hasPermission  } = this.props;
     
    return (
      <Container className="apply">
         <Col xs="auto" className="heeadingNavpt">
            <Row className="justify-content-between align-items-center">
                <Col xs="1">
                        <Button onClick={goBackto} className="font-weight-normal py-0" color="blue3"><img style={{height:"25px"}} src="/images/left-arrow.png" alt=""/></Button>
                                 {/*  <Row className="align-items-center heading-dropdown r-heading-3">
                                      {(studentDetails.student_info===undefined)?null: <div style={{textTransform: 'capitalize'}}>
                                        <Button onClick={goBackto} className="font-weight-normal py-0" color="blue3"><img style={{height:"25px"}} src="/images/left-arrow.png" /></Button>
                                         Student/.../{studentDetails.student_info[0].first_name}/
                                      </div>}
                                      {selectedProcess && <SelectList selected={selectedProcess.process_name}>
                                        {
                                         processList.map(process => {
                                            return (
                                                <li key={process.process_id} onClick={changeTab} data-tab={process.process_type}>{process.process_name}</li>
                                            )
                                          })
                                        }
                                      </SelectList>}
                                    </Row>*/}
                                </Col>
                <Col xs="11">
                <Row noGutters className="justify-content-center" style={{marginRight:"150px"}}>
                  <Col xs={12} sm={9} md={6} lg={3}>
                    <Row noGutters className="student__nav__slider">
                      <Col xs={{ size: 4, offset: (4 * (tab - 1)) }} className="r-transition--margin">
                        <div className="student__nav__slider-bar"></div>
                      </Col>
                      { //hasPermission.viewStudentProfile && hasPermission.viewStudentProfile.permission_access && hasPermission.viewStudentProfile.permission_access.read &&
                      <Col data-tab="profile" className="student__nav__slider-placeholder" onClick={changeTab} xs={4}>Profile</Col>
                      }
                      { //hasPermission.viewStudentResume && hasPermission.viewStudentResume.permission_access && hasPermission.viewStudentResume.permission_access.read &&
                      <Col data-tab="resumes" xs={{ size: 4, offset: 4 }} className="student__nav__slider-placeholder" onClick={changeTab}>Resume</Col>
                      }
                      { //hasPermission.viewStudentApply && hasPermission.viewStudentApply.permission_access && hasPermission.viewStudentApply.permission_access.read &&
                      <Col data-tab="apply" xs={{ size: 4, offset: 8 }} className="student__nav__slider-placeholder" onClick={changeTab}>Apply</Col>
                      }
                    </Row>
                  </Col>
                </Row>
                </Col>
            {/*  <Col xs="2" >
                            <Row className="justify-content-between align-items-center">
                              <Col xs="auto" className="px-2">
                                <Button  className="font-weight-normal px-2 py-0" color="Light"><i className="mr-3 r-text--sm fi flaticon-share" />Share</Button>
                              </Col>
                              <Col xs="auto" className="px-2">
                                    <Row className="justify-content-between align-items-center">
                                        <Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)} />
                                        <span  className="px-3">Un-Freeze</span>
                                    </Row>
                              </Col>
                              <Col xs="auto" className="px-2">
                                <Button className="font-weight-normal px-2 py-0" color="Light"><i className="mr-3 r-text--sm fi flaticon-plus-1" />Add to Process</Button>
                              </Col>
                            </Row>
                          </Col>*/}

            </Row>
        <HorizontalLine color ="#cfcfcf"/>
        </Col>
        </Container>
    )
  }
}
const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user)),
  setLoading: show => dispatch(setLoading(show))
})
export default connect(mapStateToProps, mapDispatchToProps)(StudentNavPT);
