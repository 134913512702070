import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { UncontrolledCollapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Loader } from '../../../global/Loader';
import { getFullName } from '../../../../actions';
import { CONSTANTS } from '../../../../constants';
import Button from '../../../global/Button';
import { Select } from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import { getTimeElasped } from '../../../../actions';
import { SingleDatePicker } from '../../../global/Forms/DatePicker';
import moment from 'moment';
import store from '../../../../store';
import { RTE } from '../../../global/Forms/RTE';
import { Errorbar } from '../../../global/errorbar';
import TimePicker from 'rc-time-picker';

export default class AddMinutes extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    minuteDatatime: moment().format('YYYY-MM-DD'),
    constants: '',
    interactionHistory: null,
    contactList: [],
    meeting_id: '',
    isOpen: null,
    toggleRoleId: 0,
    loader: false,
    modal: false,
    isConfirm: false,
    deleteMeetingId: null,
    description: null,
    filterRole: 'All',
    errorbar: false,
    message: "",
    isOpenAdd : false,
    addReminderDate: null,
  }

  minuteDate = (minuteDatatime) => {
    this.setState({
      minuteDatatime: moment(minuteDatatime).format('YYYY-MM-DD')
    })
  }

  toggle = (history) => {
    this.setState({
      addReminderDate: null,
      isOpen: history ? history.meeting_id : null,
      description: history ? history.meeting_description : null
    })
  }

  toggleRole = async (toggleRoleId) => {
    if (toggleRoleId == this.state.toggleRoleId) {
      await this.setState({
        toggleRoleId: 0
      })
    } else {
      await this.setState({
        toggleRoleId: toggleRoleId
      })

    }
  }

  toggleLoading = (loader) => {
    this.setState({
      loader
    })
  }

  addNotes = async (formData) => {
    let { campus_company_id } = this.props.meetingData
    try {
      var resp = await Auth.Batch.createMeetings(formData)
      if(this.state.addReminderDate){
        var reminderBody = {
          meeting_id: resp.data.meeting_details[0].meeting_id,
          campus_company_id,
          remainder_date: moment(this.state.addReminderDate).format("YYYY-MM-DD 00:00:00")
        }
        await Auth.Batch.createMeetingReminder(reminderBody);
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  toggleModal = async () => {
    await this.setState({ modal: !this.state.modal })
  }

  confirmDelete = async (meetingId) => {
    this.setState({ deleteMeetingId: meetingId })
    this.toggleModal()
  }

  deleteMeeting = async () => {
    let { deleteMeetingId } = this.state
    await this.setState({ modal: !this.state.modal })
    if (deleteMeetingId) {
      let params = {
        meeting_id: deleteMeetingId,
        status: 0
      }
      try {
        this.toggleLoading(true)
        await Auth.Batch.deleteMeeting(params)
        await this.getMeetings()
        this.toggleLoading(false)
      }
      catch (e) {
        console.log(e)
      }
    }
  }

  getMeetings = async () => {
    let { meetingData, company_id } = this.props;
    let access_key = store.getState().role.access_key
    // let params = new URLSearchParams();
    // params.append("campus_company_id", meetingData.campus_company_id)
    // params.append("access_key", access_key)
    var body = {
      access_key,
      campus_company_id : meetingData.campus_company_id
    }
    let resp = await Auth.Meetings.getMeetings(body)
    meetingData.list = resp.data;
    this.props.handleDataChange({ company_id, meeting: meetingData })
  }

  handleSubmit = async (event) => {
    event.persist()
    event.preventDefault();
    let { campus_company_id } = this.props.meetingData
    let { toggleRoleId, minuteDatatime, description } = this.state
    let access_key = store.getState().role.access_key
    let text = document.createElement('div')
    text.innerHTML = description;
    if (text.textContent.trim() === "") {
      this.setState({
        message: "Decription is mandatory",
        errorbar: true
      })
      return
    }
    this.toggleLoading(true)
    let formData = {
      "access_key": access_key,
      "campus_company_id": campus_company_id,
      "company_profile_id": toggleRoleId,
      "meeting_title": "Interaction History",
      "meeting_category": "InteractionHistory",
      "meeting_type": "Video Call",
      "location": "Delhi",
      "start_date": minuteDatatime,
      "end_date": minuteDatatime,
      "meeting_description": description
    }
    if (formData) {
      await this.addNotes(formData);
      this.setState({ description: null })
      this.props.onCloseAdd();
      await this.getMeetings()
    }
    await this.setState({ description: null })
    this.toggleLoading(false)
  }


  updateMeeting = async (event) => {
    event.preventDefault();
    event.persist()
    let { meetingData, company_id } = this.props
    let { description } = this.state
    let { meeting_id, meeting_category, meeting_remainder_id } = event.target
    let access_key = store.getState().role.access_key
    let text = document.createElement('div')
    text.innerHTML = description;
    if (text.textContent.trim() === "") {
      this.setState({
        message: "Decription is mandatory",
        errorbar: true
      })
      return
    }
    this.toggleLoading(true)
    let formData = {
      "meeting_id": meeting_id.value,
      "access_key": access_key,
      'meeting_category': meeting_category.value,
      "meeting_description": description
    }

    try {
      await Auth.Batch.updateMeetingDetails(formData);
      if(this.state.addReminderDate){
        if(meeting_remainder_id){
          await Auth.Batch.updateMeetingReminder({meeting_remainder_id : meeting_remainder_id.value, remainder_date: moment(this.state.addReminderDate).format("YYYY-MM-DD 00:00:00"), is_visited: "0"});
        }
        else{
          await Auth.Batch.createMeetingReminder({meeting_id: meeting_id.value, campus_company_id: meetingData.campus_company_id, remainder_date: moment(this.state.addReminderDate).format("YYYY-MM-DD 00:00:00")});
        }
      }
      this.setState({ description: null })
      this.toggle()
      await this.getMeetings()
    } catch (error) {
      console.log(error)
    }
    this.toggleLoading(false)
  }

  descriptionOnChange = async (value) => {
    this.setState({
      description: value
    })
  }

  handleRoleChanged = (event) => {
    this.setState({ filterRole: event.target.value })
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  render() {
    let { profileData, meetingData } = this.props
    let { isOpen, errorbar, message } = this.state
    return (
      <div className="notes">
        <Loader show={this.state.loader} />
        <Errorbar showBar={errorbar} onClose={this.closebar} message={message} />
        <Modal centered isOpen={this.props.isOpenAdd} toggle={() => this.props.onCloseAdd()}  >
          <ModalBody>
          <div className="card shadow-sm mb-5">
            <form id='myForm' className="add-meeting text-left p-4" onSubmit={this.handleSubmit}>
              <Row>
                <Col xs={12} className="calendar__event-description-box">
                  <RTE onChange={(value) => this.descriptionOnChange(value)} placeholder="Type Description here" />
                </Col>
                <div className="role">
                  {
                    profileData.length ?
                      <Col xs={12} className="my-2 pt-2">
                        <p className="r-heading-7">Roles</p>
                        {
                          profileData.map(profile => {
                            return (
                              <button type="button"
                                className={this.state.toggleRoleId == profile.company_profile_id ? "r-btn role--select mr-2 mb-1" : "r-btn r-btn--blue mr-2 mb-1"}
                                key={profile.company_profile_id}
                                id={profile.company_profile_id}
                                onClick={() => this.toggleRole(profile.company_profile_id)}
                              >
                                {profile.role_name}
                              </button>
                            )
                          }
                          )
                        }
                      </Col>
                      :
                      ""
                  }
                </div>
                {this.state.addReminderDate !== null && <Col xs={12} className="pt-3">
                    <hr />
                    <Row>
                        <Col xs={7}>
                          <SingleDatePicker
                            pickerProps={{placeholder:"Select Reminder Date"}}
                            value={this.state.addReminderDate}
                            onDateChange={(date) => this.setState({ addReminderDate: date })}
                          />
                        </Col>
                      </Row>
                </Col>}
                <Col xs={12}>
                  <hr />
                  <Row className="pr-4">
                    <Col>
                      {this.state.addReminderDate === null ?
                        <Button onClick={() => this.setState({addReminderDate: ""})} type="button" color="dark" className="r-btn--no-radius r-text--sm" ><span className=" fi flaticon-plus"></span> Reminder</Button >
                        :
                        <Button onClick={() => this.setState({addReminderDate: null})} type="button" color="dark" className="r-btn--no-radius r-text--sm" ><span className=" fi flaticon-plus d-inline-block rotate--45"></span> Reminder</Button >
                      }
                    </Col>
                    <Col className="text-right">
                      <Button type="submit" color="dark" className="r-btn--no-radius r-text--sm" >Save</Button >
                      <Button type="button" onClick={() => this.props.onCloseAdd()} > Cancel</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>

          </div>
          </ModalBody>
        </Modal>
        <div className="intraction">
          <Row>
            <Col xs={12}>
              <div className="filter-history">
                {
                  meetingData ?
                    meetingData.list.length > 0 ?
                      profileData.length ?
                        <Row>
                          <Col xs={3} className="my-2 pt-2">
                            <div className="r-dropdown mb-3">
                              <select className="r-dropdown__select" name='roleId' onChange={this.handleRoleChanged}>
                                <option option="All"> All</option>
                                {
                                  profileData.map(profile => {
                                    return (
                                      <option key={profile.company_profile_id} value={profile.company_profile_id}>
                                        {profile.role_name}
                                      </option>
                                    )
                                  }
                                  )
                                }
                              </select>
                              <i className="fi flaticon-arrow"></i>
                            </div>
                          </Col>
                        </Row>
                        :
                        ""
                      :

                      ""
                    :
                    ""
                }
              </div>
              {
                meetingData ?
                  meetingData.list.filter(meet => this.state.filterRole === "All" ? true : this.state.filterRole == meet.company_profile_id).map(history => {
                    return (
                      <div className="intraction showhim" key={history.meeting_id} >
                        {
                            <ul className="timeline" >
                              <li className="timeline-inverted">
                                <div className="timeline-badge"></div>
                                <div className="timeline-panel">
                                  <Row>
                                    <Col xs={12}>
                                      {/* <Collapse isOpen={isOpen !== history.meeting_id} className="text-left"> */}
                                        <div className="student__profile__section text-left" style={{marginTop: '-10px'}}>
                                          <div className="student__profile__actions">
                                            <span className="student__profile__actions__btn">
                                              <button type="button" onClick={() => this.toggle(history)} className="fi flaticon-edit-2 r-btn mr-1"></button>
                                              <button type="button" className="fi flaticon-bin r-btn"
                                                onClick={() => this.confirmDelete(history.meeting_id)}
                                              ></button>
                                            </span>
                                          </div>
                                          <div className='date-section'></div>
                                          <p dangerouslySetInnerHTML={{ __html: history.meeting_description }}></p>
                                          <div className="text-right text-secondary">
                                          <span className="float-left">
                                              {
                                                profileData.map(profile => {
                                                  return (
                                                    profile.company_profile_id == history.company_profile_id ?
                                                      <span key={history.company_profile_id} >
                                                        <span className="r-subheading-3">{profile.role_name}</span>
                                                    </span>
                                                      :
                                                      ""
                                                  )
                                                }
                                                )
                                              }
                                            </span>
                                            {/* <span className="r-subheading-4 mr-1">
                                              {getTimeElasped(history.created_on)}
                                            </span> */}
                                           <span className="r-subheading-4 mr-1 ">
                                              {moment(history.created_on).format('LLL')}
                                              {!!history.meeting_remainder && !!history.meeting_remainder.length &&
                                                <><br /><span className="r-text--brandB">Reminder : {moment(history.meeting_remainder[0].remainder_date).format("Do'MMM YYYY")}</span></>
                                               }
                                            </span>
                                          </div>
                                        </div>
                                      {/* </Collapse> */}
                                      <Modal centered isOpen={isOpen == history.meeting_id}>
                                        <ModalBody>
                                        <form className="add-meeting text-left p-4" onSubmit={this.updateMeeting}>
                                          <Row>
                                            <Col xs={12} className="calendar__event-description-box">
                                              <RTE defaultValue={history.meeting_description} onChange={(value) => this.descriptionOnChange(value)} placeholder="Type Description here" />
                                            </Col>
                                            {this.state.addReminderDate !== null && <Col xs={12} className="pt-3">
                                                <hr />
                                                <Row>
                                                    <Col xs={7}>
                                                      <SingleDatePicker
                                                        pickerProps={{placeholder:"Select Reminder Date"}}
                                                        value={this.state.addReminderDate}
                                                        onDateChange={(date) => this.setState({ addReminderDate: date })}
                                                      />
                                                    </Col>
                                                  </Row>
                                            </Col>}
                                            <Col xs={12}>
                                              <hr />
                                              <Row className="pr-4">
                                                <Col>
                                                  {!!history.meeting_remainder && !!history.meeting_remainder.length && <input type="hidden" name="meeting_remainder_id" defaultValue={history.meeting_remainder[0].meeting_remainder_id} />}
                                                  {this.state.addReminderDate === null ?
                                                  (
                                                    history.meeting_remainder && history.meeting_remainder.length ? 
                                                      <Button type="button" onClick={() => this.setState({addReminderDate : history.meeting_remainder[0].remainder_date})}color="dark" className="r-btn--no-radius r-text--sm float-left" ><span className={`fi flaticon-edit`}></span> Reminder</Button >
                                                    :
                                                    <Button type="button" onClick={() => this.setState({addReminderDate : ""})}color="dark" className="r-btn--no-radius r-text--sm float-left" ><span className={`fi flaticon-plus`}></span> Reminder</Button >
                                                  )
                                                  :
                                                  <Button type="button" onClick={() => this.setState({addReminderDate: null})} color="dark" className="r-btn--no-radius r-text--sm" ><span className="fi flaticon-plus d-inline-block rotate--45"></span> Reminder</Button >
                                                  }
                                                </Col>
                                                <Col className="text-right">
                                                  <input type="hidden" name="meeting_id" defaultValue={history.meeting_id} />
                                                  <input type="hidden" name="meeting_category" defaultValue={history.meeting_category} />
                                                  <Button type="submit" color="dark" className="r-btn--no-radius r-text--sm" >Save</Button >
                                                  <Button type="button" onClick={() => this.toggle(null)}>Cancel</Button>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </form>
                                        </ModalBody>
                                        </Modal>
                                    </Col>
                                  </Row>
                                </div>
                              </li>
                            </ul>

                        }


                      </div>
                    )

                  })
                  :
                  ""
              }
            </Col>
          </Row>

          <Row>

            <Modal className="modal-dialog-centered" isOpen={this.state.modal} >

              <ModalBody>
                <h1 className="r-subheading-3">
                  Are you sure?
                </h1>
              </ModalBody>
              <ModalFooter>
                <button
                  className="mr-3 r-btn r-btn--dark r-btn--no-radius"
                  color="dark r-btn--no-radius"
                  onClick={() => this.deleteMeeting()}
                >
                  Delete
                </button>

                <Button onClick={() => this.toggleModal(false)}>Cancel</Button>
              </ModalFooter>
            </Modal>
          </Row>

        </div>



      </div>


    )
  }
}
