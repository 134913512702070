import React, { Component } from "react";
import withRouter from "react-router-dom/withRouter";
import moment from "moment";
import { Auth } from "../../../../actions/Auth";
import Button from "../../../global/Button";
import { Row, Col, Collapse, Modal, ModalHeader } from "reactstrap";
import { Errorbar } from "../../../global/errorbar";
import { uploadFile } from '../../../../actions';
import EditStudent from "./EditStudent";
import StatusModal from "./StatusModal";
import TableST from "./TableST.js";
import AddtoProcess from './AddtoProcess';

class StudentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getProcesList: [],
      getStudentAssigned: [],
      getStudentProfileDetails: [],
      columnslist: [
        {
          id: "group_name",
          title: "Group"
        },
        {
          id: "campus_name",
          title: "Campus"
        },
        {
          id: "year_of_passout",
          title: "End Year"
        }
      ],
      columns: [
        {
          id: "sno",
          title: "S. No."
        },
        {
          id: "name",
          sortKey:'first_name',
          title: "Name"
        },
        {
          id: "roll_no",
          sortKey:'roll_no',
          title: "Roll No."
        },
        {
          id: "freeze_status",
          title: "Freeze Status"
        },
        {
          id: "department_name",
          title: "Department"
        },
        {
          id: "batch_name",
          title: "Batch"
        },
        {
          id: "campus_degree_name",
          title: "Degree"
        },
        {
          id: "show_application",
          title: "Applications"
        },
        {
          id: "cv_count",
          title: "Total CV"
        }
      ],
      isOn: false,
      modalstudent: false,
      modalshare: false,
      modal: false,
      modaledit: false,
      errorbar: false,
      multiToggle: false,
      deleteModal: false,
      modalDelete: false,
      message: "",
      isOpen: false,
      isFreezeModal: false,
      isUnfreezeModal: false,
      radioButton: 0,
      mystlocalID: null,
      isOpenAddProcess: false,
      stDetails: {},
      process: {
        process_name: "Student Profiles",
        process_type: "student",
        process_id: 1
      },
      pageSize: 2,
      studentsIDs: [],
      currentPage: 1
    };
  }

  componentDidMount() {
    this.getcvConfig();
    let container = document.getElementById("pt-students");
    container.addEventListener("mousedown", this.handleChange);
    container.addEventListener("change", this.handleChange);
  }

  componentWillUnmount() {
    let container = document.getElementById("pt-students");
    container.removeEventListener("mousedown", this.handleChange);
    container.removeEventListener("change", this.handleChange);
  }


  getcvConfig = async () => {
    const {columns} = this.state;
    let res =await  Auth.User.getConfiguration();
    if(res.data.STUDENT && (res.data.STUDENT.STUDENT_CV_SETTING.pt_upload == 1 )){
        columns.push({id: "cv_upload",title: "CV Upload"})
        this.setState({columns})
    }
  }

  getAllStudents() {
    let array = [];
    document.getElementsByName("selectedStudent").forEach(data => {
      if (data.checked) {
        array.push(data.value);
      }
    });
    return array;
  }

  handleAllSelect = (selectAll) =>{
    const {studentsIDs} = this.state;
      if (selectAll === 1)
      { 
        this.props.tableData.forEach(st =>{
             studentsIDs.push(st.student_id)
           })
      }
      else{
        studentsIDs.length = 0
      }
    this.setState({studentsIDs});
  }

  handleChange = e => {
    if (e.target.name === "selectedStudent") {
      // let studentList = this.getTotalStudents();
      // this.setState({
      //   studentsIDs: studentList
      // });
    }  else if (
      this.wrapperRef &&
      !this.wrapperRef.contains(e.target) &&
      this.state.isOpen
    ) {
      this.setState({
        isOpen: false
      });
    } 
  };


  getTotalStudents() {
    let all = document.getElementsByName("selectedStudent");
    let ids = [];
    all.forEach(st => {
      if (st.checked) {
        ids.push(st.value);
      }
    });
    return ids;
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleToggle(e) {
    this.setState({
      isOn: !this.state.isOn
    });
  }

  toggle = e => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  closebar = () => {
    this.setState({
      errorbar: false
    });
  };

  addColumn(data) {
    let { columnslist, columns } = this.state;
    if (this.state.columnslist[data].id === "roll_no") {
      this.state.columns.splice(2, 0, this.state.columnslist[data]);
      this.setState({
        columns
      });
      this.state.columnslist.splice(data, 1);
      this.setState({
        columnslist
      });
    } else {
      this.state.columns.push(this.state.columnslist[data]);
      this.state.columnslist.splice(data, 1);
      this.setState({
        columns
      });
      this.setState({
        columnslist
      });
    }
  }

  toggleProcess = () => {
    this.setState(prevState => ({
      isOpenAddProcess: !prevState.isOpenAddProcess
    }));
  }

  togglefreezeModal = () => {
    this.setState(prevState => ({
      isFreezeModal: !prevState.isFreezeModal
    }));
  };

  toggleUnfreezeModal = () => {
    this.setState(prevState => ({
      isUnfreezeModal: !prevState.isUnfreezeModal
    }));
  };

  toggleditOpen = () => {
    this.setState({
      modaledit: true
    });
  };

  toggleditClose = () => {
    this.setState({
      modaledit: false
    });
    this.setState({
      stDetails: {}
    });
  };

  edit_Student = data => {
    this.setState({
      stDetails: data
    });
    this.toggleditOpen();
  };

  toggleDelete = e => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal
    }));
  };

  setSTId = st_id => {
    this.setState({
      mystlocalID: st_id.student_id
    });
    this.deleteToggle();
  };

  deleteToggle = () => {
    this.setState({
      modalDelete: !this.state.modalDelete
    });
  };

  deleteMultipleStudent = async () => {
    var stBody = {
      student_id: this.state.studentsIDs
    };
    try {
      await Auth.PtStudents.deleteStudent(stBody);
      this.refreshtable();
      this.toggleDelete();
    } catch (e) {
      this.setState({
        errorbar: true
      });
      this.setState({
        message: e.response.data.error
      });
    }
  };

  removeColumn = data => {
    let { columnslist, columns } = this.state;
    this.state.columnslist.push(this.state.columns[data]);
    this.state.columns.splice(data, 1);
    this.setState({
      columns
    });
    this.setState({
      columnslist
    });
  };


  toggleMultipleStudent = async () => {
    this.setState({
      multiToggle: !this.state.multiToggle
    });
    var stBody = {
      student_id: this.state.studentsIDs,
      freeze_status: 1
    };
    try {
      let resp = await Auth.Student.studentFreeze(stBody);
      this.refreshtable();
    } catch (e) {
      console.log(e);
    }
  };

  submitStatus = async (now, deadline) => {
    let deadTime = moment(deadline).utc(false)
    var freezeLater = {
      student_id: this.state.studentsIDs,
      deadline: moment(deadline).utc(false).format('YYYY-MM-DDTHH:mm:ss')
    };
    var freezeNow = {
      student_id: this.state.studentsIDs,
      freeze_status: 1
    };
    try {
      let body = now ? freezeNow : freezeLater;
      await Auth.Student.studentFreeze(body);
      this.togglefreezeModal();
      this.refreshtable();
    } catch (e) {
      console.log(e);
    }
  };

  unFreezeStudent = async deadline => {
    var stBody = {
      student_id: this.state.studentsIDs,
      freeze_status: 0
    };
    try {
      await Auth.Student.studentFreeze(stBody);
      this.refreshtable();
      this.toggleUnfreezeModal();
    } catch (e) {
      console.log(e);
    }
  };

  deleteStudent = async () => {
    var stBody = {
      student_id: this.state.mystlocalID
    };
    try {
      await Auth.PtStudents.deleteStudent(stBody);
      this.refreshtable();
      this.deleteToggle();
    } catch (e) {
      this.setState({
        errorbar: true
      });
      this.setState({
        message: e.response.data.error
      });
    }
  };

  toggleRow = async row => {
    var stBody = {
      student_id: row.student_id,
      freeze_status: row.freeze_status === 0 ? 1 : 0
    };

    try {
      await Auth.Student.studentFreeze(stBody);
      this.refreshtable();
    } catch (e) {
      console.log(e);
    }
  };

  changeApplicationStatus = async row => {
    let applicationParams = {
      student_id: row.student_id,
      show_application: row.show_application,
      block_message: row.block_message
    };
    try {
      await Auth.Processes.changeApplicationStatus(applicationParams);
      this.refreshtable();
    } catch (e) {
      console.log(e);
    }
  };

  setFreezeStaus = e => {
    if (e.target.value === "0") {
      this.togglefreezeModal();
    } else {
      this.toggleUnfreezeModal();
    }
  };

  refreshtable = () =>{
    this.props.refreshtable();
    this.setState({studentsIDs: []})
    this.child.method();
  }


  render() {
    let { modalDelete, isFreezeModal,studentsIDs, isUnfreezeModal } = this.state;
    return (
      <div ref={this.setWrapperRef}>
        {" "}
        {this.props.tableData.length !== 0 ? (
          <div>
            <Row className="justify-content-between align-items-center ">
              <Col xs="auto">
                {" "}
                {this.state.studentsIDs.length !== 0 ? (
                  <Row className="justify-content-between align-items-center ">
                    {" "}
                    {
                      <span className="green-selected">
                        {" "}
                        {this.state.studentsIDs.length}{" "}
                        Selected{" "}
                      </span>
                    }{" "}
                    <Button onClick={() => this.toggleProcess()}>
                      <i className="fi flaticon-plus-1 r-text--sm" />
                      <span className="ml-2">
                        {" "}
                        Add to Placement Drive{" "}
                      </span>{" "}
                    </Button>{" "}
                    |
                    <Col>
                      <Row
                        className="align-items-center px-2"
                        onChange={this.setFreezeStaus}
                      >
                        <input type="radio" name="radioButton" value="0" />
                        <span className="ml-2"> Freeze </span>{" "}
                        <input
                          type="radio"
                          name="radioButton"
                          value="1"
                          className="ml-2"
                        />
                        <span className="ml-2"> Unfreeze </span>{" "}
                      </Row>{" "}
                    </Col>{" "}
                    |
                    <Button onClick={this.toggleDelete}>
                      <i className="fi flaticon-bin" />
                      <span className="ml-2"> Delete </span>{" "}
                    </Button>{" "}
                  </Row>
                ) : null}{" "}
              </Col>{" "}
              <Col xs="auto">
                <Button
                  onClick={this.toggle}
                  className="font-weight-normal customButton px-4 r-text--sm py-1"
                  color="brandI"
                >
                  {" "}
                  Customize Columns{" "}
                </Button>{" "}
              </Col>{" "}
            </Row>{" "}
            <Row>
              <Collapse
                isOpen={this.state.isOpen}
                style={{
                  width: "100%"
                }}
              >
                <div className="r-bg--grey-1" ref={this.setWrapperRef}>
                  {" "}
                  {/*
                                              <Row  className="justify-content-end align-items-center mx-0 px-0"> <Button onClick={this.toggle}> <i className="d-inline-block fi flaticon-plus-1 rotate--45" /></Button></Row>*/}{" "}
                  <Row className=" align-items-center mx-0 px-0">
                    {" "}
                    {this.state.columnslist.map((data, i) => {
                      return (
                        <Button
                          key={data.id}
                          onClick={e => this.addColumn(i)}
                          className="post p-4 my-3 ml-3"
                        >
                          {" "}
                          <i className="mr-3 r-text--sm fi flaticon-plus-1" />{" "}
                          {data.title}{" "}
                        </Button>
                      );
                    })}
                  </Row>{" "}
                </div>{" "}
              </Collapse>{" "}
            </Row>{" "}
            <TableST
              onRef={ref => (this.child = ref)}
              onSelectStudent={studentsIDs => this.setState({studentsIDs})}
              columns={this.state.columns}
              tableData={this.props.tableData}
              editStudent={this.edit_Student}
              deleteStudent={this.setSTId}
              removeColumn={this.removeColumn}
              handleUploadFile={this.refreshtable}
              isShow={this.state.isOpen}
              toggleRow={this.toggleRow}
              handleAllSelect={this.handleAllSelect}
              changeApplicationStatus={this.changeApplicationStatus}
              stProfile={this.props.stProfile}
              sortByRoll={this.props.sortByRoll}
            />{" "}
          </div>
        ) : (
          <div>No students added in batch. please add student to batches</div>
        )}{" "}
        {Object.keys(this.state.stDetails).length > 0 ? (
          <EditStudent
            stDetails={this.state.stDetails}
            toggle={this.toggleditClose}
            modal={this.state.modaledit}
            refresh={this.refreshtable}
          />
        ) : null}
        <StatusModal
          toggleStatausModal={this.togglefreezeModal}
          isOpenstatusModal={isFreezeModal}
          submitStatus={this.submitStatus}
        />{" "}
        <Errorbar
          showBar={this.state.errorbar}
          onClose={this.closebar}
          message={this.state.message}
        />{" "}
        <Modal
          isOpen={this.state.deleteModal}
          toggle={this.toggleDelete}
          centered
        >
          <ModalHeader toggle={this.toggleDelete}>
            {" "}
            <span className="r-subheading-2"> Are you sure to delete ? </span>
          </ModalHeader>
          <Row
            className="justify-content-end align-items-center  mx-0  my-2 "
            style={{
              textAlign: "center"
            }}
          >
            <Button
              onClick={this.deleteMultipleStudent}
              className="font-weight-normal mx-3  px-5"
              color="dark"
            >
              {" "}
              Delete{" "}
            </Button>{" "}
            <Button
              onClick={this.toggleDelete}
              className="font-weight-normal mx-3  px-5"
              color="outline-dark-v"
            >
              {" "}
              Cancel{" "}
            </Button>{" "}
          </Row>{" "}
        </Modal>{" "}
        <Modal isOpen={modalDelete} toggle={this.deleteToggle} centered>
          <ModalHeader toggle={this.deleteToggle}>
            {" "}
            <span className="r-subheading-2"> Are you sure to delete ? </span>
          </ModalHeader>
          <Row
            className="justify-content-end align-items-center  mx-0  my-2 "
            style={{
              textAlign: "center"
            }}
          >
            <Button
              onClick={this.deleteStudent}
              className="font-weight-normal mx-3  px-5"
              color="dark"
            >
              {" "}
              Delete{" "}
            </Button>{" "}
            <Button
              onClick={this.deleteToggle}
              className="font-weight-normal mx-3  px-5"
              color="outline-dark-v"
            >
              {" "}
              Cancel{" "}
            </Button>{" "}
          </Row>{" "}
        </Modal>{" "}
        <Modal
          isOpen={isUnfreezeModal}
          toggle={this.toggleUnfreezeModal}
          centered
        >
          <ModalHeader toggle={this.toggleUnfreezeModal}>
            {" "}
            <span className="r-subheading-2"> Unfreeze Profile </span>
          </ModalHeader>
          <Row
            className="justify-content-end align-items-center  mx-0  my-2 "
            style={{
              textAlign: "center"
            }}
          >
            <Button
              onClick={this.unFreezeStudent}
              className="font-weight-normal mx-3  px-5"
              color="dark"
            >
              {" "}
              Unfreeze{" "}
            </Button>{" "}
            <Button
              onClick={this.toggleUnfreezeModal}
              className="font-weight-normal mx-3  px-5"
              color="outline-dark-v"
            >
              {" "}
              Cancel{" "}
            </Button>{" "}
          </Row>{" "}
        </Modal>{" "}
       {(this.state.isOpenAddProcess)?<AddtoProcess studentsIDs={studentsIDs} isOpenAddProcess={this.state.isOpenAddProcess} toggleAddProcess={this.toggleProcess} />:null}
      </div>
    );
  }
}

export default withRouter(StudentTable);
