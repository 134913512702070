import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { SelectList } from '../../../global/Forms/Select';
import { CONSTANTS } from '../../../../constants';
import { Auth } from '../../../../actions/Auth';
import moment from 'moment';

export class MeetingStatus extends Component {

  changeStatus = async (status) => {
    let { company } = this.props;
    try {
      let company_status = parseInt(status);
      company.toggleLoading(true)
      await Auth.Processes.updateCompanyStatus({ process_company_id: company.process_company_id, company_status })
      company.handleDataChange({ company_id: company.company_id, company_status });
    } catch (e) { console.log(e) }
    company.toggleLoading(false)
  }
  render() {
    let { company } = this.props;
    return (
      <div className="processes__meeting-status">
        {/* <span className="r-subheading-4 r-text--light">Meeting Status</span> */}
        <Row noGutters >
          <Col xs={6}>
            <SelectList className="processes__meeting-status__contact" selected={CONSTANTS.COMPANY_STATUS[company.company_status]}>
              {Object.keys(CONSTANTS.COMPANY_STATUS).map(status => {
                if (status > 0)
                  return <li key={status} onClick={() => this.changeStatus(status)}>{CONSTANTS.COMPANY_STATUS[status]}</li>
              })}
            </SelectList>
          </Col>
          <Col xs={6} className="processes__meeting-status__date">
            {company.date ? moment(company.date).format("Do MMM YYYY") : moment().format("Do MMM YYYY")}
          </Col>
        </Row>
      </div>
    )
  }
}