import React from 'react';
import { Row,Col,Container,  Modal, ModalHeader,Nav, NavItem,Collapse, NavLink,TabContent, TabPane, ModalBody, ModalFooter,Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { HorizontalLine } from '../../../global/horizontal_line';
import { InputField } from '../../../global/Forms';
import Button from '../../../global/Button';
import classnames from 'classnames';
import Aux from '../../../global/AuxComp';
import facebook from '../../../../assets/icons/facebook.svg'
import twitter from '../../../../assets/icons/twitter.svg'
import link from '../../../../assets/icons/link.svg'
import linkedin from '../../../../assets/icons/linkedin.svg'
import { Select } from '../../../global/Forms/Select';
import { MonthSelect, YearSelect } from '../../../global/Forms/Select';
import { RTE } from '../../../global/Forms/RTE';

class Profile extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      sideNavLeft: null,
      sideNavRight: null,
      editableSection:'',
      addSection:true,
      activeTab: '1',
      fileurl: null,
      content:'',
      tab:1

    };
  }


   handleChangefile = (event) =>{
    this.setState({
      fileurl: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    })
  }


  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }



  render() {
    let {editableSection, content} = this.state;
    return (
      <Container>
      <div className="r-card">
          <div className="student__profile__section" >
            <h2 className="r-heading-4 r-text--blue-3">Overview</h2>
            {this.state.addSection?
              <Aux>
                <div className="student__profile__actions">
                  <Button onClick={(e) => this.setState({addSection:false})} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
                </div>
                <div className="student__profile__section student__profile__section--placeholder">
                  <span className="r-subheading-2">
                    <p className="mb-0">College Name</p>
                  </span>
                  <Row className="justify-content-between mb-2">
                    <Col xs={9}>
                      <span className="r-text--grey-4">Founded</span>
                    </Col>
                    <Col xs={3} className="text-right">
                      <Row noGutters>
                        <Col className="px-2">
                          <span>-</span>
                          <p className="r-heading-7 r-text--blue-3">NIRF Rank</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="justify-content-between mb-2">
                    <Col xs={9}>
                      <span className="r-text--grey-4">Location</span>
                    </Col>
                  </Row>
                  <Row className="justify-content-between mb-2">
                    <Col xs={12}>
                    <span className="r-subheading-2">
                      <p className="mb-0">Description</p>
                    </span>
                    </Col>
                    <Col xs={12}>
                      <span className="r-subheading-3">
                          <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently</p>
                        </span>
                    </Col>
                  </Row>
                    <Row noGutters className="text-center pr-4 py-3 justify-content-start">
                      <a className="mr-4" href="https://www.reculta.com/" target="_blank"><img src={facebook}/></a>
                      <a className="mr-4" href="https://www.reculta.com/" target="_blank"><img src={twitter}/></a>
                      <a className="mr-4" href="https://www.reculta.com/" target="_blank"><img src={link}/></a>
                      <a className="mr-4" href="https://www.reculta.com/" target="_blank"><img src={linkedin}/></a>
                    </Row>
                </div>
              </Aux>:<Aux>
                   <Row form className="mb-2">
                    <Col xs={12}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>Name</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                  </Row>
                  <Row form className="mb-2">
                    <Col xs={6}>
                      <label className="r-heading-7"><span className="r-text--red-1">*</span>Founded</label>
                      <Row noGutters>
                        <Col xs={6}>
                          <MonthSelect onChange={(e) => this.changeYear(e, "start_date", "month",)} value={ ""} />
                        </Col>
                        <Col xs={6}>
                          <YearSelect onChange={(e) => this.changeYear(e, "start_date", "year",)} value={""} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                   <Row form className="mb-2">
                    <Col xs={12}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>NIRF Rank</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                  </Row>
                   <Row form className="mb-2">
                    <Col xs={12}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>Location</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                  </Row>
                   <Row form className="mb-2">
                    <Col xs={12}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>Facebook Profile</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                  </Row>
                   <Row form className="mb-2">
                    <Col xs={12}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>Twitter Profile</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                  </Row>
                   <Row form className="mb-2">
                    <Col xs={12}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>Linkdien Profile</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                  </Row>
                   <Row form className="mb-2">
                    <Col xs={12}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>College URL</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                    </Col>
                  </Row>
                   <Row form className="mb-2">
                    <Col xs={12}>
                      <label className="r-heading-7" ><span className="r-text--red-1">*</span>Description</label>
                      <RTE className="r-nav__search titleinput" onChange={(value) => this.setState({content: value})} name="content" value={content} rows="3" placeholder="Description here..." required />
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end mt-3">
                    <Button type="button" onClick={(e) => this.setState({addSection:true})} className="r-text--sm">Cancel</Button>
                    <Button type="submit" className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
                  </div>
              </Aux> }
          </div>
      </div>

       <div className="r-card my-4">
        <h2 className="r-heading-4 r-text--blue-3">Courses</h2>
         <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                  style={{ cursor: "pointer" }}
                >
                  Degree
                        </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                  style={{ cursor: "pointer" }}
                >
                  Department
                        </NavLink>
              </NavItem>
        </Nav>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
          {(!this.state.addDegree)?
          <Aux>
             <Row noGutters className="justify-content-between mb-2 py-4">
              <Col xs={5}>
                  <h2 className="r-heading-4 ">Degree Name</h2>
              </Col>
              <Col xs={3}>
                  <h2 className="r-heading-4">Total Students</h2>
              </Col>
            <Col xs={3}>
                <div className="student__profile__actions">
                  <Button onClick={(e) => this.setState({addDegree:true})} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
                </div>
            </Col>
             </Row>
              <HorizontalLine color="#cfcfcf" />
          </Aux>:<Aux>
                 <Row noGutters className="justify-content-between mb-2 py-4">
                  <Col xs={5}>
                      <label className="r-heading-7" >Name</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                  </Col>
                  <Col xs={3}>
                      <label className="r-heading-7" >Total Students</label>
                      <InputField name="stream" className="w-100" type="text" value={""} />
                  </Col>
                  <Col xs={3}>
                      <div className="d-flex justify-content-end mt-3">
                        <Button type="button" onClick={(e) => this.setState({addDegree:false})} className="r-text--sm">Cancel</Button>
                        <Button type="submit" className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
                      </div>
                  </Col>
                 </Row>
                  <HorizontalLine color="#cfcfcf" />
              </Aux>}
              </TabPane>
              <TabPane tabId="2">
                  {(!this.state.addDep)?
                  <Aux>
                     <Row noGutters className="justify-content-between mb-2 py-4">
                      <Col xs={5}>
                          <h2 className="r-heading-4 ">Department Name</h2>
                      </Col>
                      <Col xs={3}>
                          <h2 className="r-heading-4">Total Students</h2>
                      </Col>
                      <Col xs={3}>
                          <div className="student__profile__actions">
                            <Button onClick={(e) => this.setState({addDep:true})} className="r-text--sm student__profile__actions--add fi flaticon-plus-1" color="brandB"><span>Add</span></Button>
                          </div>
                      </Col>
                     </Row>
                      <HorizontalLine color="#cfcfcf" />
                  </Aux>:<Aux>
                         <Row noGutters className="justify-content-between mb-2 py-4">
                          <Col xs={5}>
                              <label className="r-heading-7" >Name</label>
                              <InputField name="stream" className="w-100" type="text" value={""} />
                          </Col>
                          <Col xs={3}>
                              <label className="r-heading-7" >Total Students</label>
                              <InputField name="stream" className="w-100" type="text" value={""} />
                          </Col>
                          <Col xs={3}>
                          <div className="d-flex justify-content-end mt-3">
                            <Button type="button" onClick={(e) => this.setState({addDep:false})} className="r-text--sm">Cancel</Button>
                            <Button type="submit" className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
                          </div>
                          </Col>
                         </Row>
                          <HorizontalLine color="#cfcfcf" />
                      </Aux>}
              </TabPane>
            </TabContent>
       </div>
       </Container>
    );
  }
}


export default Profile;