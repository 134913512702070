import React from 'react';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';
import SpocOverview from '../views/spoc/overview';
import Roles from '../views/spoc/Roles';
import PTPerformance from '../views/spoc/PTPerformance';
import StudentReport from '../views/spoc/StudentReport';
import ProcessPerformance from '../views/spoc/ProcessPerformance';
import AttendanceReport from '../views/spoc/attendance-report.js';
import MeetingReport from '../views/spoc/meeting-report';
import CompanyReport from '../views/spoc/company-report';
import Switch from 'react-router-dom/Switch';
// import SpocRoutes from '../'

export const SpocRoutes = () => {
  return (
    <Switch>
      <Route exact path="/spoc/overview" component={SpocOverview} />
      <Route exact path="/spoc/roles" component={Roles} />
      <Route exact path="/spoc/performance/pt" component={PTPerformance} />
      <Route exact path="/spoc/performance/student" component={StudentReport} />
      <Route exact path="/spoc/performance/process" component={ProcessPerformance} />
      <Route exact path="/spoc/performance/meeting-report" component={MeetingReport} />
      <Route exact path="/spoc/performance/attendance-report" component={AttendanceReport} />
      <Route exact path="/spoc/performance/company-report" component={CompanyReport} />
      <Route exact path="/spoc/performance" render={() => <Redirect to="/spoc/performance/process" />} />
      <Route render={() => <Redirect to="/spoc/overview" />} />
    </Switch>
  )
}
