import React, {Component} from 'react';
import moment from 'moment';
import {connect, batch} from 'react-redux';
import {Row,Col,Container,Collapse, Input} from 'reactstrap';
import Aux from '../../global/AuxComp';
import Button from '../../global/Button';
import { SelectList } from '../../global/Forms/Select';
import { InputField } from '../../global/Forms';
import { Switch } from '../../global/Forms/ToggleSwitch';
import { Auth } from '../../../actions/Auth';
import { setLoading, setError } from '../../../actions/action-creators';
import store from '../../../store';
import { getErrorMessage } from '../../../actions';


  export default class RolePermissionTab extends Component{
    constructor(props){
       super(props);
         this.state = { 
          campusProcessList : [],
          campusBatchList : [],
          degreeDepartmentList : [],
          restrictedProcessId : [],
          restrictedProcessIdRaw : [],
          restrictedDepartmentId : [],
          restrictedDepartmentIdRaw : [],
          selectedCampus : {}
        };
    }

    componentDidMount(){
      this.init();
    }
    
    init = async () => {
      store.dispatch(setLoading(true))
      let {role_id, user_id, access_key} = this.props.memberData;
      try {
        // let resp = await Auth.Spoc.getInstitutes()
        // var campus_id = resp.data.filter(o => !!o.campus_id).map(o => o.campus_id);
        // let campusResp = await Auth.Spoc.getInstituteDetails({campus_id});
        let processResp = await Auth.Processes.getProcesses(this.props.modalInfo.campus_id);
        let degreeDepartmentResp = await  Auth.Spoc.getDegreeDepartment({campus_id: this.props.modalInfo.campus_id});
        var degreeDepartmentList = degreeDepartmentResp.data;
        let processRestrictedResp = await Auth.Spoc.fetchUserAccess({access_name: "PROCESS", access_type: "RESTRICTION", role_id, user_id})
        // let campusProcessList = campusResp.data.campusDetails.map(campus => {
        //   campus.processList = processResp.data.filter(process => process.campus_id === campus.campus_id);
        //   return campus;
        // })
        let campusProcessList = processResp.data;
        let restrictedProcessId = processRestrictedResp.data.map(o => o.ref_id);
        this.setState({campusProcessList, restrictedProcessId, restrictedProcessIdRaw : [...restrictedProcessId]});
      
        let batchResp = await Auth.Batch.getBatchDetail({parent_access_key : access_key});
        let batchRestrictedResp = await Auth.Spoc.fetchUserAccess({access_name: "BATCH", access_type: "RESTRICTION", role_id, user_id})
        let departmentRestrictedResp = await Auth.Spoc.fetchUserAccess({access_name: "DEPARTMENT", access_type: "RESTRICTION", role_id, user_id})
        let campusBatchList = batchResp.data;
        let restrictedBatchId = batchRestrictedResp.data.map(o => o.ref_id);
        let restrictedDepartmentId = departmentRestrictedResp.data.map(o => o.ref_id);
        this.setState({campusBatchList, degreeDepartmentList, restrictedBatchId, restrictedBatchIdRaw : [...restrictedBatchId], restrictedDepartmentId, restrictedDepartmentIdRaw : [...restrictedDepartmentId]});
      
      }
      catch(e) {
        console.log(e.response.data)
        store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      }   
      store.dispatch(setLoading(false))
    }

    changeProcess = (e, process_id) => {
      let { restrictedProcessId } = this.state;
      if (!e.target.checked) {
        restrictedProcessId.push(process_id)
        }
        else {
          var i = restrictedProcessId.indexOf(process_id)
          restrictedProcessId.splice(i, 1);
        }
      this.setState({restrictedProcessId})    
  }
  changeBatch = (e, batch_id) => {
      let { restrictedBatchId } = this.state;
      if (!e.target.checked) {
        restrictedBatchId.push(batch_id)
        }
        else {
          var i = restrictedBatchId.indexOf(batch_id)
          restrictedBatchId.splice(i, 1);
        }
      this.setState({restrictedBatchId})    
  }
  changeDepartment = (e, department_id) => {
      let { restrictedDepartmentId } = this.state;
      if (!e.target.checked) {
        restrictedDepartmentId.push(department_id)
        }
        else {
          var i = restrictedDepartmentId.indexOf(department_id)
          restrictedDepartmentId.splice(i, 1);
        }
      this.setState({restrictedDepartmentId})    
  }
  
submitAccess = async (e) => {
  e.preventDefault();
  let { restrictedProcessId, restrictedProcessIdRaw, restrictedBatchId, restrictedBatchIdRaw, restrictedDepartmentIdRaw, restrictedDepartmentId } = this.state;
  let {role_id, user_id} = this.props.memberData;
  store.dispatch(setLoading(true))
  try {
      let addProcessId = restrictedProcessId.filter(id => restrictedProcessIdRaw.indexOf(id) === -1);
      let removeProcessId = restrictedProcessIdRaw.filter(id => restrictedProcessId.indexOf(id) === -1);
      addProcessId.length && await Auth.Spoc.grantUserAccess({ role_id, user_id, ref_id : addProcessId, access_type : "RESTRICTION", access_name: "PROCESS" })
      removeProcessId.length && await Auth.Spoc.removeUserAccess({role_id, user_id, ref_id : removeProcessId, access_type : "RESTRICTION", access_name: "PROCESS"})

      let addBatchId = restrictedBatchId.filter(id => restrictedBatchIdRaw.indexOf(id) === -1);
      let removeBatchId = restrictedBatchIdRaw.filter(id => restrictedBatchId.indexOf(id) === -1);
      addBatchId.length && await Auth.Spoc.grantUserAccess({ role_id, user_id, ref_id : addBatchId, access_type : "RESTRICTION", access_name: "BATCH" })
      removeBatchId.length && await Auth.Spoc.removeUserAccess({role_id, user_id, ref_id : removeBatchId, access_type : "RESTRICTION", access_name: "BATCH"})
      
      let addDepartmentId = restrictedDepartmentId.filter(id => restrictedDepartmentIdRaw.indexOf(id) === -1);
      let removeDepartmentId = restrictedDepartmentIdRaw.filter(id => restrictedDepartmentId.indexOf(id) === -1);
      addDepartmentId.length && await Auth.Spoc.grantUserAccess({ role_id, user_id, ref_id : addDepartmentId, access_type : "RESTRICTION", access_name: "DEPARTMENT" })
      removeDepartmentId.length && await Auth.Spoc.removeUserAccess({role_id, user_id, ref_id : removeDepartmentId, access_type : "RESTRICTION", access_name: "DEPARTMENT"})
      this.props.onSuccess();
  } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
  }
  store.dispatch(setLoading(false))
}

    render(){
      let { campusProcessList,campusBatchList, restrictedProcessId, restrictedBatchId, degreeDepartmentList, restrictedDepartmentId } = this.state;
      console.log(degreeDepartmentList)
      return (
        <form onSubmit={this.submitAccess}>
          <Row className="mt-0 r-text--sm r-bg--grey-1 border-top border-bottom mb-2">
              {/* <Col xs={6}>
                <Row className="r-bg--blue-2 align-items-center">
                  <Col className="p-4">
                    <h1 className="r-subheading-4 ml-5">Campus</h1>
                  </Col>
                </Row>
                { campusProcessList.map(campus => 
                <Row className={""+(selectedCampus.campus_id === campus.campus_id ? "r-bg--grey-3" : "")}>
                  <Col className="p-4 pl-5" style={{cursor : "pointer"}} onClick={() => this.setState({selectedCampus : campus})}>
                    <p className="d-inline-block align-middle ml-3">{campus.name}</p><i className="d-inline-block fi flaticon-arrow  float-right"></i>
                  </Col>
                </Row>
                ) }
              </Col> */}
              <Col xs={4} className="border-left">
                <Row className="r-bg--blue-2 align-items-center">
                  <Col className="p-4">
                    <h1 className="r-subheading-4 ml-3">Placement Drives</h1>
                  </Col>
                </Row>
                {campusProcessList.map(process => 
                <Row  className="">
                  <Col className="px-5 py-2">
                    <Input checked={(restrictedProcessId.indexOf(process.process_id) === -1)} onChange={(e) => this.changeProcess(e, process.process_id)} type="checkbox"  />
                    <p className="d-inline-block align-middle m-0 ml-3">{process.process_name}</p>
                  </Col>
                </Row>
                ) }
              </Col>

              <Col xs={4} className="border-right border-left">
                <Row className="r-bg--blue-2 align-items-center">
                  <Col className="p-4">
                    <h1 className="r-subheading-4 ml-3">Batches</h1>
                  </Col>
                </Row>
                {campusBatchList.map(batch => 
                <Row  className="">
                  <Col className="px-5 py-2">
                    <Input checked={(restrictedBatchId.indexOf(batch.batch_id) === -1)} onChange={(e) => this.changeBatch(e, batch.batch_id)} type="checkbox"  />
                    <p className="d-inline-block align-middle m-0 ml-3">{batch.batch_name}</p>
                  </Col>
                </Row>
                ) }
              </Col>


              <Col xs={4} className="border-right">
                <Row className="r-bg--blue-2 align-items-center">
                  <Col className="p-4">
                    <h1 className="r-subheading-4 ml-3">Departments</h1>
                  </Col>
                </Row>
                
                {degreeDepartmentList.map(degree => 
                  degree.departments.map(department => 
                  <Row  className="">
                    <Col className="px-5 py-2">
                      <Input checked={(restrictedDepartmentId.indexOf(department.degree_department_id) === -1)} onChange={(e) => this.changeDepartment(e, department.degree_department_id)} type="checkbox"  />
                      <p className="d-inline-block align-middle m-0 ml-3">{department.department_name}</p>
                    </Col>
                  </Row>
                  ) )}
              </Col>
              
          </Row>
          <Row>
            <Col className="text-right">
                  <Button color="brandB" className="r-text--sm mb-3">Save</Button>
            </Col>
          </Row>
        </form>

    )
  }
}
