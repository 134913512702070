import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Row,Col,Container,Collapse, Input} from 'reactstrap';
import Aux from '../../global/AuxComp';
import Button from '../../global/Button';
import { SelectList } from '../../global/Forms/Select';
import { InputField } from '../../global/Forms';
import { Switch } from '../../global/Forms/ToggleSwitch';


  export default class RolePermissionTab extends Component{
    constructor(props){
       super(props);
         this.state = { isOn: false, isEdit:false, isPermissionCollapse : true };
     }

	  handleToggle(e) {
  		this.setState({isOn: !this.state.isOn});
  	}


    render(){
      let { data } = this.props;
      return (

          <Aux>
              <Row className="mt-3">
                <Col xs={5}>
                  <Row>
                    <Col xs={7} className="r-subheading-4">Permissions</Col>
                    <Col xs={5} className="r-subheading-4">Overall</Col>
                  </Row>
                </Col>
                <Col xs={7}>
                  <Row>
                    <Col xs={3} className="r-subheading-4">Create</Col>
                    <Col xs={3} className="r-subheading-4">Read</Col>
                    <Col xs={3} className="r-subheading-4">Update</Col>
                    <Col xs={3} className="r-subheading-4">Custom Role Access</Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={5}>
                  <Row>
                    <Col xs={7} className="r-subheading-4">PT</Col>
                    <Col xs={5} ><Switch  isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
                <Col xs={7}>
                  <Row>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xs={5}>
                  <Row>
                    <Col xs={7} className="r-subheading-4">Verifier</Col>
                    <Col xs={5} ><Switch  isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
                <Col xs={7}>
                  <Row>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={5}>
                  <Row>
                    <Col xs={7} className="r-subheading-4">Student</Col>
                    <Col xs={5} ><Switch  isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
                <Col xs={7}>
                  <Row>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={5}>
                  <Row>
                    <Col xs={7} className="r-subheading-4">Student Group</Col>
                    <Col xs={5} ><Switch  isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
                <Col xs={7}>
                  <Row>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={5}>
                  <Row>
                    <Col xs={7} className="r-subheading-4">Company</Col>
                    <Col xs={5} ><Switch  isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
                <Col xs={7}>
                  <Row>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={5}>
                  <Row>
                    <Col xs={7} className="r-subheading-4">Company Self Created</Col>
                    <Col xs={5} ><Switch  isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
                <Col xs={7}>
                  <Row>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={5}>
                  <Row>
                    <Col xs={7} className="r-subheading-4">Contacts All</Col>
                    <Col xs={5} ><Switch  isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
                <Col xs={7}>
                  <Row>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                    <Col xs={3} ><Switch isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)}  /></Col>
                  </Row>
                </Col>
              </Row>
            </Aux>

    )
  }
}
