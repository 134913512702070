import React, { Component } from 'react';
import { Auth } from '../../../actions/Auth';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Button, Row , Col , Card , CardBody  , CardHeader
} from 'reactstrap';

const items =[]

class VerifierStudentProof extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0};
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }
  componentDidMount() {
    let el = document.querySelector(".verifier__student-proof")
    if(el) {
      el.style.width = el.parentElement.offsetWidth + "px"
    }
  }
  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex == this.props.signedUrl.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex == 0 ? this.props.signedUrl.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {

    const {activeIndex} = this.state;
    const slides =  this.props.signedUrl.length!=0?(this.props.signedUrl.map(item => {
        return (
          <CarouselItem className="w-100"
          onExiting={this.onExiting}
          onExited={this.onExited} key={item} >
          {item && item.startsWith('blob') ?
            <iframe type="application/pdf" src={item} width="550px" height="550px"></iframe> 
            : 
            (item.split('.').pop().split(/\#|\?/)[0]==='docx' || item.split('.').pop().split(/\#|\?/)[0]==='txt' || item.split('.').pop().split(/\#|\?/)[0]==='doc') 
          ? 
          <div className="text-center">
            <Card>
              <CardHeader className="r-subheading-3"><img  className="img-fluid" alt="No Preview Available" /></CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <a href={item} download ><Button>Download Here</Button></a>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          :
          <div>
            <img src={item} className="img-fluid w-100" alt="The proof will load and appear in the space below ..." />
          </div>}
          </CarouselItem>
        )
    })):[]

    return (
      <div className="verifier__student-proof">
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous} interval={false}>
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
      </Carousel>
      </div>
    );
  }
}


export default VerifierStudentProof;
