import React,{Component} from 'react';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import RecultaAdmin from '../views/recultaAdmin/index';
import SpocOverview from '../views/spoc/overview';
export  const RecultaAdminRoutes = ()=>{
  return(
    <Switch>
      <Route exact path="/admin" component={SpocOverview} />
      {/* <Route exact path="/admin" component={RecultaAdmin} /> */}
      <Route render={() => <Redirect to="/admin" />} />
    </Switch>
  )
}
