import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PieChart, Pie, Sector, Cell, Legend, ResponsiveContainer, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import { Auth } from '../../../../actions/Auth';
import { SelectList,Select } from '../../../global/Forms/Select'
import { Switch } from '../../../global/Forms/ToggleSwitch';
import { setUser, setLoading,setError } from '../../../../actions/action-creators';
import store from '../../../../store';
import { getErrorMessage, sortObjectArray } from '../../../../actions';
import Button from '../../../global/Button';
import PieChartCOMP from '../../../global/PieChartCOMP';
import { Loader } from '../../../global/Loader';
import { InputField } from '../../../global/Forms';
import Aux from '../../../global/AuxComp';
import { Row, Col, Container, Collapse, Table, Pagination, Card, PaginationItem, PaginationLink, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { HorizontalLine } from '../../../global/horizontal_line';
import PTTeamNav from '../PTTeamNav';



class Snapshot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      genderReportList: [],
      domainReportList:[],
      industryReportList:[],
      sectorReportList:[],
      batchList:[],
      isOn: false,
      isLoading:false,
      process: { process_name: "Snapshot", process_type: "batch", process_id: 2 },
    };
  }


  formatData = (bucket) => {
    let temp = {}
    temp.doc_count = bucket.doc_count
    temp.name = bucket.key === "NOT_SELECTED" ? "Other" : bucket.key;
    return temp
  }


  componentDidMount() {
    this.init()
  }

  init = async () =>{
    let {genderReportList,batchList, domainReportList, industryReportList, sectorReportList}= this.state;
    this.setState({isLoading: true})
    let resp = await Auth.PtStudents.getBatchList();
    batchList = resp.data;
    let reportResponse = await this.getGenderReport(resp.data[0].batch_id);
    let reportResSt = await this.getStProReport(resp.data[0].batch_id);
    try {genderReportList = reportResponse.data.aggregations.gender_aggregation.buckets.map(this.formatData);
        domainReportList = reportResSt.data.aggregations.domain_name_aggregation.buckets.map(this.formatData);
        industryReportList = reportResSt.data.aggregations.industry_name_aggregation.buckets.map(this.formatData);
        sectorReportList = reportResSt.data.aggregations.sector_name_aggregation.buckets.map(this.formatData);
      }
    catch (e) {
      console.log(e);
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))

    }
    this.setState({genderReportList, domainReportList, industryReportList, sectorReportList});
    this.setState({isLoading: false,batchList});
  }

  getGenderReport = async (id) =>{
    let body = { 
                      "must": {
                          "batch_id": id
                      },
                      "filter": {},
                      "aggregationParams": [
                              {
                                  "aggregateOn": "gender"
                              }
                      ]
                  }
      try{
          let reportResponse  = await Auth.Reports.genderReport(body);
          return reportResponse;
      }catch (e) {
        console.log(e)
      }
  }

  getStProReport = async (id) =>{
    let body = {
                  "must": {
                      "batch_id": id
                  },
                  "filter": {
                      "experience_type": "FULL_TIME"
                  },
                  "aggregationParams": [
                      {
                          "aggregateOn": "industry_name"
                      },
                      {
                          "aggregateOn": "sector_name"
                      },
                      {
                          "aggregateOn": "domain_name"
                      }
                  ]
              }
      try{
          let reportResponse  = await Auth.Reports.studentProReport(body);
          return reportResponse;
      }catch (e) {
        console.log(e)
      }
  }

  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  getSnapData = async (value) => {
    this.setState({isLoading: true});
    let {genderReportList,batchList, domainReportList, industryReportList, sectorReportList}= this.state;
    let reportResponse = await this.getGenderReport(value);
    let reportResSt = await this.getStProReport(value);
    genderReportList = reportResponse.data.aggregations.gender_aggregation.buckets.map(this.formatData);
    domainReportList = reportResSt.data.aggregations.domain_name_aggregation.buckets.map(this.formatData);
    industryReportList = reportResSt.data.aggregations.industry_name_aggregation.buckets.map(this.formatData);
    sectorReportList = reportResSt.data.aggregations.sector_name_aggregation.buckets.map(this.formatData);
    this.setState({genderReportList, domainReportList, industryReportList, sectorReportList});
    this.setState({isLoading: false});
  }
  handlepostToggle = (e) => {
    this.setState(state => ({ postcolapse: !state.postcolapse }));
  }


  render() {
    let { genderReportList,isLoading,batchList, domainReportList, industryReportList, sectorReportList } = this.state;
    return (
      <Container className="apply">
        <Loader show={isLoading} />
        <Row>
          <Col xs="12">
            <PTTeamNav process={this.state.process} />
          </Col>
        </Row>
        {/*<Row className="justify-content-between align-items-center">
                  <Col xs="auto">
                    <Button onClick={this.handlepostToggle} className="font-weight-normal px-2 py-0" color="brandB"><i className="m-2 r-text--sm fi flaticon-plus-1" />Snapshot</Button>
                  </Col>
                  <Col xs="auto" >
                    <Row className="align-items-center">
                      <Col xs="auto" className="px-2">
                        <Button onClick={this.toggledit} className="font-weight-normal px-2 py-0" color="Light"><i className="mr-3 r-text--sm fi flaticon-edit" />Edit</Button>
                      </Col>
                      <Col xs="auto" className="px-2">
                        <Button className="font-weight-normal px-2 py-0" color="Light"><i className="mr-3 r-text--sm fi flaticon-bin" />Delete</Button>
                      </Col>
                      <Col xs="auto" className="px-2">
                        <Button onClick={this.toggleshare} className="font-weight-normal px-2 py-0" color="Light"><i className="mr-3 r-text--sm fi flaticon-share" />Share</Button>
                      </Col>
                      <Col xs="auto" className="px-2">
                        <Button className="font-weight-normal px-2 py-0" color="Light"><i className="mr-3 r-text--sm fi flaticon-plus-1" />Add to Process</Button>
                      </Col>
                      <Col xs="auto" className="px-2">
                        <Button className="font-weight-normal px-2 py-0" color="Light"><i className="mr-3 r-text--sm fi flaticon-gear" /></Button>
                      </Col>
                    </Row>
                  </Col>
        
                </Row>*/}
        <HorizontalLine color="#cfcfcf" />
        {batchList.length > 0 &&
          <Row className=" my-4">
            <Col xs="3">
              <Select onChange={({ target }) => this.getSnapData(target.value)}>
                {batchList.map((bt, i) =>
                  <option key={i} value={bt.batch_id}>{bt.batch_name}</option>
                )}
              </Select>
            </Col>
          </Row>
        }
     {!isLoading && <Aux>
               <Row className="my-2">
                 <Col xs="12">
                   <h3 className="r-heading-3">Gender</h3>
                   {genderReportList.length > 0 ? <PieChartCOMP data={genderReportList} datakey="doc_count" /> : <p>No records found</p>}
                 </Col>
               </Row>
               <Row className="my-2">
                 <Col xs="12">
                   <h3 className="r-heading-3">Work Experience Domain</h3>
                   {domainReportList.length > 0 ? <PieChartCOMP data={domainReportList} datakey="doc_count" /> : <p>No records found</p>}
                 </Col>
               </Row>
               <Row className="my-2">
                 <Col xs="12">
                   <h3 className="r-heading-3">Work Experience Industry</h3>
                   {industryReportList.length > 0 ? <PieChartCOMP data={industryReportList} datakey="doc_count" /> : <p>No records found</p>}
                 </Col>
               </Row>
               <Row className="my-2">
                 <Col xs="12">
                   <h3 className="r-heading-3">Work Experience by sector</h3>
                   {sectorReportList.length > 0 ? <PieChartCOMP data={sectorReportList} datakey="doc_count" /> : <p>No records found</p>}
                 </Col>
               </Row>
             </Aux>}
        <Row className=" align-items-center my-3 mx-0">
          <Col xs="5" className="post py-5 my-3 mx-0">
            <Row className="justify-content-center align-items-center "><i className="d-inline-block fi flaticon-plus-1" style={{ fontSize: "18px" }} /></Row>
            <Row className="justify-content-center align-items-center "><span className="r-heading-3"> Add Chart</span></Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Snapshot;
