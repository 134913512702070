import React,{Component} from 'react';
import {Container , Row , Col} from 'reactstrap';
import moment from 'moment';
import { InputField } from '../../../global/Forms';
import Button from '../../../global/Button';
import Table from 'reactstrap/lib/Table';
import { Select } from '../../../global/Forms/Select';
import Collapse from 'reactstrap/lib/Collapse';
import { CONSTANTS } from '../../../../constants';
import store from '../../../../store';
import { setError, setLoading } from '../../../../actions/action-creators';
import { getErrorMessage } from '../../../../actions';
import { Auth } from '../../../../actions/Auth';
import { ENDPOINTS } from '../../../../constants/endpoints';

class ShortlistQuestions extends Component {
  constructor(props) {
    super(props);
    this.init_application_question = {
      application_id: this.props.roundData.application_id,
      ref_type: "SHORTLIST",
      ref_id: this.props.roundData.application_round_id,
      question_heading: "",
      question_content: "",
      answer_type: "text",
      is_mandatory: 0,
      is_config: '0',
      answer_type_data: {}
    }
    this.state = {
      addQuestion : false,
      questionList : []
    }
  }
  

  componentDidMount(){
    this.fetchQuestions();
  }

  fetchQuestions = async () => {
    store.dispatch(setLoading(true))
    try{
      var body = {
        ref_type: "SHORTLIST",
        ref_id: this.props.roundData.application_round_id,
        application_id: this.props.roundData.application_id
      }
      let resp = await Auth.Processes.fetchQuestion(body);
      this.setState({questionList: resp.data})
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))

  }
  
  deleteQuestions = async (application_question_id) => {
    store.dispatch(setLoading(true))
    try{
      await Auth.Processes.deleteQuestion({ application_question_id })
      this.fetchQuestions()
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))

  }

  addQuestion = async () => {
    store.dispatch(setLoading(true))
    let {addQuestion} = this.state;
    try{
      addQuestion.answer_type_data = JSON.stringify(addQuestion.answer_type_data)
      await Auth.Processes.addQuestion(addQuestion);
      this.setState({addQuestion: false})
      this.fetchQuestions()
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))

  }

  render(){
    let {addQuestion, questionList} = this.state;
    let {roundData} = this.props;
    return(
    <div>
      <Row>
        <Col>
          <Button type="button" onClick={() => this.setState({addQuestion : addQuestion ? false : {...this.init_application_question}})} className="r-text--sm mb-3" color="brandB">+ Add Question</Button>
        </Col>
        <Col className="text-right">
          {!!questionList.length && <Button type="button" className="r-text--sm mb-3" color="blue-3">
            <a className="text-light" href={ENDPOINTS.PROCESSES.DOWNLOAD_APPLICATION_DATA + "?ref_type=SHORTLIST&ref_id="+roundData.application_round_id+"&application_id=" + roundData.application_id} target="_blank" download>Download Answers</a>
          </Button>}
        </Col>
      </Row>
      <Collapse isOpen={!!addQuestion}>
        <Row>
          <Col xs={12}>
            <span className="r-subheading-4">Enter question<span className="r-text--brandF">*</span></span>
            <InputField type="text" placeholder="Type some question here" className="w-100" value={addQuestion.question_heading} name="question_heading" onChange={(e) => {addQuestion.question_heading = e.target.value; this.setState({addQuestion})}} />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={4}>
            <span className="r-subheading-4">Enter answer hint</span>
            <InputField type="text" placeholder="Type answer hint here" className="w-100" value={addQuestion.question_content} name="question_content" onChange={(e) => {addQuestion.question_content = e.target.value; this.setState({addQuestion})}} />
          </Col>
          <Col xs={3}>
            <span className="r-subheading-4">Select answer type</span>
            <Select type="text" placeholder="Type answer hint here" value={addQuestion.answer_type} name="answer_type"  onChange={(e) => {addQuestion.answer_type = e.target.value; this.setState({addQuestion})}}>
              {
                Object.keys(CONSTANTS.ANSWER_TYPES).map(ans => <option key={ans} value={ans}>{CONSTANTS.ANSWER_TYPES[ans]}</option>)
              }
            </Select>
          </Col>
          <Col xs={3}>
            <span className="r-subheading-4">Mandatory?</span>
            <Select name="is_mandatory"  value={addQuestion.is_mandatory} onChange={(e) => {addQuestion.is_mandatory = e.target.value; this.setState({addQuestion})}}>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </Select>
          </Col>
          {addQuestion.answer_type == 'file' ?
            <Col xs={2}>
              <span className="r-subheading-4">Use Config</span>
              <Select name="is_config"  value={addQuestion.is_config} onChange={(e) => {addQuestion.is_config = e.target.value; this.setState({addQuestion})}}>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Select>
            </Col> : <Col xs={2}></Col>
          }
        </Row>
        <Row className="align-items-center">
          <Col>
            {addQuestion.is_config == '1' ?
              <Row className="mt-2">
                <Col xs={4}>
                  <span className="r-subheading-4">File Type</span>
                  <Select name="answer_type_data" data-state="application_question" value={addQuestion.answer_type_data && addQuestion.answer_type_data.file_type} onChange={(e) => this.setState({ addQuestion: { ...addQuestion, answer_type_data: { ...addQuestion.answer_type_data, file_type: e.target.value } } })}>
                    <option value="">Select</option>
                    {
                      Object.keys(CONSTANTS.QUESTION_FILE_TYPES).map(ft => <option key={ft} value={ft}>{CONSTANTS.QUESTION_FILE_TYPES[ft]}</option>)
                    }
                  </Select>
                </Col>
                <Col xs={4}>
                  <span className="r-subheading-4">File Size(kb)</span>
                  <InputField className="w-100" onChange={(e) => this.setState({ addQuestion: { ...addQuestion, answer_type_data: { ...addQuestion.answer_type_data, file_size: e.target.value } } })} />
                </Col>
              </Row> : null
            }
          </Col>
          <Col xs="auto" className="py-3">
            <Button onClick={this.addQuestion} type="button" className="r-text--blue-3">Add</Button>
          </Col>
        </Row>
      </Collapse>
      <Table className="processes__table__1">
        <tbody>
          {
            questionList.map((q, i) => {
              let answer_type_data = JSON.parse(q.answer_type_data);
              return (
                <tr key={i}>
                  <td className="r-subheading-4">{(i + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}</td>
                  <td>
                    <div className="r-heading-7">{q.question_heading}</div>
                    <span>{q.question_content}</span>
                  </td>
                  <td>{CONSTANTS.ANSWER_TYPES[q.answer_type]}{q.answer_type == 'file' && Object.keys(answer_type_data).length ? "(" + (CONSTANTS.QUESTION_FILE_TYPES[answer_type_data.file_type] || '') + (answer_type_data.file_size ? ", " + answer_type_data.file_size + " kb" : "") + ")" : null}</td>
                  <td>{parseInt(q.is_mandatory) ? "Mandatory" : "Optional"}</td>
                  <td><Button type="button" className="p-0" onClick={() => this.deleteQuestion(q.application_question_id, i)}><i className="fi flaticon-bin" /></Button></td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </div>
  )}
}
export default ShortlistQuestions;
