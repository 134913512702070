import React, { Component } from 'react';
import { Row, Col } from 'reactstrap'
import { Auth } from '../../../../actions/Auth';
import Button from '../../../global/Button';
import { Confirmation } from '../../../global/confirmation';
import Autosuggest from 'react-autosuggest';

export class RolesSection extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      isLoading: false,
      companyProfiles: [],
      campusRoles: [],
      isConfirmation: false,
      suggestions: []
    }
  }

  componentDidMount() {
    this.init();

  }


  init = () => {
    this.fetchCompanyProfiles();
  }

  fetchCompanyProfiles = async () => {
    this.props.toggleLoading(true)
    try {
      let profileResp = await Auth.PtCompanies.getCompanyProfiles(this.props.campus_company_id);
      let campusRolesresp = await Auth.CampusRoles.getRoles(this.props.campus_id);
      this.setState({
        campusRoles: campusRolesresp.data, companyProfiles: profileResp.data,
      })

    } catch (e) { console.log(e) }
    this.props.toggleLoading(false)
  }




  mapDesignation = async (e, index) => {
    e.preventDefault();
    this.props.toggleLoading(true)
    let { companyProfiles } = this.state,
      { campus_id, campus_company_id } = this.props,
      roleData = companyProfiles[index],
      formData = { campus_company_id };
    if (roleData.campus_role_id) {
      formData.campus_role_id = roleData.campus_role_id
    } else if (roleData.role_name.trim() !== "") {
      let resp = await Auth.CampusRoles.createRole({ campus_id, role_name: roleData.role_name })
      formData.campus_role_id = resp.data.insertId
    }
    try {
      await Auth.PtCompanies.addCompanyProfile(formData)
    } catch (e) {
      console.log(e);
    }
    if (this.props.refreshParent)
      this.props.refreshParent()
    this.fetchCompanyProfiles();
    this.props.toggleLoading(false)

  }
  confirmInactiveCompanyProfile = (company_profile_id) => {
    this.setState({ confirmDeleteId: company_profile_id, isConfirmation: true })
  }
  inactiveCompanyProfile = async () => {
    this.props.toggleLoading(true)
    var company_profile_id = this.state.confirmDeleteId;
    var data = {
      company_profile_id: company_profile_id,
      status: 0,
      access_key: 3
    }
    try {
      await Auth.PtCompanies.inactiveCompanyProfile(data)

    } catch (e) {
      console.log(e);
    }
    this.setState({ confirmDeleteId: null, isConfirmation: false })
    this.fetchCompanyProfiles();
    if (this.props.refreshParent)
      await this.props.refreshParent()
    this.props.toggleLoading(false)
  }
  toggleConfirmation = () => {
    this.setState({ isConfirmation: false })
  }

  onChange = (e, index) => {
    let { companyProfiles } = this.state;
    if (e.target.className.indexOf("r-inputfield") > -1) {
      companyProfiles[index] = {
        role_name: e.target.value
      }
      this.setState({
        companyProfiles
      })
    }
  }

  autoSearch = (data) => {
    let { suggestions, campusRoles } = this.state
    suggestions = campusRoles.filter(role => (role.role_name.toLowerCase().indexOf(data.value.toLowerCase()) > -1))
    this.setState({ suggestions })
  }
  setValue = (value, index) => {
    let { companyProfiles } = this.state;
    companyProfiles[index] = value
    this.setState({ companyProfiles })
  }

  render() {
    let { companyProfiles, campusRoles, isConfirmation, suggestions } = this.state;
    let { sectorList } = this.props;
    //var sectorDetail = sectorList.filter(data => data.sector_id === companyInfo.company_details.sector_id);
    return (

      <div className="">
        <Row>
          <Col xs={4}>
            <h1 className="r-subheading-2 d-inline-block">Roles</h1>
            <Button onClick={() => { companyProfiles.push({ role_name: "" }); this.setState({ companyProfiles }) }} className="ml-4 r-text--sm" color="blue">Add Role</Button>
          </Col>
        </Row>
        <Row className="mt-3">
          {companyProfiles.map((child, index) => {
            return (
              child.company_profile_id ?
                <Col xs="auto" className="mt-3" key={index}>
                  <div className="w-100 card shadow-sm">
                    <div className="card-body">
                      <span>{child.role_name || "Role Name"}</span><Button color="link" className="ml-3" onClick={() => { this.confirmInactiveCompanyProfile(child.company_profile_id) }}><i className="fi flaticon-bin mr-2"></i></Button>
                    </div>
                  </div>
                </Col>
                :
                <Col xs={3} className="mt-3" key={index}>
                  <div className="w-100 card shadow-sm">
                  <form onSubmit = {(e) => this.mapDesignation(e, index)} >
                    <div className="card-body py-2 my-1">
                      {/* <select value={child.campus_role_id} onChange={(e) => { companyProfiles[index].campus_role_id = e.target.value; this.setState({ companyProfiles }) }} className="w-100" >
                        <option>Select Role</option>
                        {campusRoles && campusRoles.map((child, index) => <option key={index} value={child.campus_role_id}>{child.role_name}</option>)}
                      </select> */}
                      <Autosuggest
                        inputProps={{
                          className: "w-100 r-inputfield",
                          value: child.role_name,
                          placeholder: "Type to search roles",
                          onChange: (e) => this.onChange(e, index),
                          required : true
                        }}
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.autoSearch}
                        getSuggestionValue={(value) => this.setValue(value, index)}
                        renderSuggestion={(role) => <div>{role.role_name}</div>}
                        onSuggestionsClearRequested={() => { }}
                      />
                      <Button type="button" onClick={() => { companyProfiles.splice(index, 1); this.setState({ companyProfiles }) }} className="r-text--sm mt-2 mr-3" color="dark">Cancel</Button>
                      <Button  className="r-text--sm mt-2" color="dark">Save</Button>
                    </div>
                    </form>
                  </div>
                </Col>
            )
          }
          )}
        </Row>
        <Confirmation isOpen={isConfirmation} toggleConfirmation={this.toggleConfirmation} callback={this.inactiveCompanyProfile} />

      </div>
    )
  }
}
