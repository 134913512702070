import React, { Component } from "react";
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import Table from 'reactstrap/lib/Table';
import UncontrolledCollapse from 'reactstrap/lib/UncontrolledCollapse';
import Collapse from 'reactstrap/lib/Collapse';
import Button from '../../../global/Button';
import { Switch } from '../../../global/Forms/ToggleSwitch';
import { InputField } from '../../../global/Forms';
import { CONSTANTS } from '../../../../constants';
import { Select, MultiSelectList } from '../../../global/Forms/Select';
import { Auth } from "../../../../actions/Auth";
import Aux from "../../../global/AuxComp";
import { SingleDatePicker } from "../../../global/Forms/DatePicker";
import { RTE } from '../../../global/Forms/RTE';
import TimePicker from 'rc-time-picker';
import moment from "moment";
import { getFiles, uploadFile, hasExtension } from "../../../../actions";
import { ReactComponent as AttachmentLogo } from '../../../../assets/icons/attachment.svg';
import { Loader } from "../../../global/Loader";
import { Errorbar } from "../../../global/errorbar";
import { Confirmation } from '../../../global/confirmation';
import { RTE2 } from "../../../global/Forms/RTE-div";
import { UnblockStudents } from "./unblock-student";


export class ApplicationModal extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }
  init_eligibility_criteria = {
    application_id: this.props.application.application_id,
    type: Object.keys(CONSTANTS.ELIGIBILITY_CRITERIA_TYPES)[0],
    operator: "==",
    value: "",
  }
  init_application_question = {
    application_id: this.props.application.application_id,
    question_heading: "",
    question_content: "",
    answer_type: "text",
    is_mandatory: 0,
    is_config: '0',
    answer_type_data: {}
  }
  getInitialState = () => {
    return {
      eligibility_criteria: { ...this.init_eligibility_criteria },
      round_info: { round_type: "GD", round_name: "Group Discussion", is_hotlisting: '0' },
      application_question: { ...this.init_application_question },
      offerInputType: this.props.application.offer_input_type,
      addedCriteria: [],
      addedrounds: [],
      deletedRounds: [],
      deletedCriteria: [],
      addedQuestions: [],
      deletedQuestions: [],
      application_deadline: this.props.application.application_deadline ? moment(this.props.application.application_deadline) : moment(),
      withdraw_deadline: this.props.application.withdraw_deadline ? moment(this.props.application.withdraw_deadline) : null,
      is_withdraw_allow: this.props.application.is_withdraw_allow ? 1 : 0,
      is_multiple_role: this.props.application.is_multiple_role ? 1 : 0,
      is_preference: this.props.application.is_preference ? 1 : 0,
      resume_restriction: this.props.application.resume_restriction ? 1 : 0,
      is_single_application: this.props.application.is_single_application ? 1 : 0,
      binderInfo: this.props.application.application_binder || [{}],
      isLoading: false,
      docJD: [],
      docPPT: [],
      docOther: [],
      files: {
        jd: [],
        ppt: [],
        other: []
      },
      deleteDocs: [],
      error: {
        show: false,
        message: ""
      },
      eligibilityOptionsList: {},
      application_binder_id: 'new',
      savenext: 0,
      application: null,
      applicationInstruction:this.props.application.instruction || '',
      applicationDescription: this.props.application.description || '',
      isSure: false,
      confimationParam: null,
      confirmSaveNext: false,
      isSendEditMail: false,
      emailConfig: {},
      isEditApplicantList : false,
      editApplicantsStudentId : [],
      applicationEligibility: this.props.application.application_eligibility.reduce((prev, current) => {
        prev[current.type] = current;
        return prev
      }, {}),
      isShowAdditionalEligibility : false
    }
  }

  componentWillMount() {
    this.getApplicationDocs()

  }
  getEmailConfig = async () => {
    try {
     let configDetails = await Auth.User.getConfiguration();
      this.setState({emailConfig : configDetails.data})
    }
    catch (e) {
      console.log(e);
    }

  }

  getEligibilitySector = async () => {
    var sector = [],
      { eligibilityOptionsList } = this.state
    try {
      sector = await Auth.General.getSectors();
      sector = sector.data
    }
    catch (e) {
      sector = []
    }
    var sectorOptions = sector.map((data, index) => <option key={index}>{data.sector_name}</option>)
    eligibilityOptionsList["WORK_EX_SECTOR"] = sector
    this.setState({ eligibilityOptionsList })
  }
  getEligibilitySpecialization = async () => {
    var specialization = [],
      { eligibilityOptionsList } = this.state
    try {
      specialization = await Auth.Specialization.fetch();
      specialization = specialization.data
    }
    catch (e) {
      specialization = []
    }
    var specializationOptions = specialization.map((data, index) => <option key={index}>{data.specialization_name}</option>)
    eligibilityOptionsList["SPECIALIZATION"] = specialization
    this.setState({ eligibilityOptionsList })
  }
  getEligibilityDepartment = async () => {
    var batchDepartments = {},
     batches = {},
      { eligibilityOptionsList } = this.state
    try {
      var students = this.props.processStudent;
      students.forEach((student) => {
        batches[student.batch_id] = {
          batch_id : student.batch_id,
          batch_name :student.batch_name
        }
        batchDepartments[student.batch_id+'-'+student.degree_department_id] = {
          batch_department_id : student.batch_id+'-'+student.degree_department_id,
          batch_department_name :student.batch_name+' - '+student.department_name
        }
      });
      batchDepartments = Object.keys(batchDepartments).map(bd=>batchDepartments[bd]);
      batchDepartments.sort((a, b) => {
        if ( a.batch_department_name < b.batch_department_name ) return -1;
        if ( a.batch_department_name > b.batch_department_name ) return 1;
        return 0;
      })
      batches = Object.keys(batches).map(bd=>batches[bd])
      batches.sort((a, b) => {
        if ( a.batch_name < b.batch_name ) return -1;
        if ( a.batch_name > b.batch_name ) return 1;
        return 0;
      })
    }
    catch (e) {
      console.log(e);
    }
    eligibilityOptionsList["DEPARTMENT"] = batchDepartments
    eligibilityOptionsList["BATCH"] = batches;
    this.setState({ eligibilityOptionsList })
  }
  closeError = () => {
    let { error } = this.state;
    error.show = false;
    this.setState({ error })
  }

  handleFileChange = (e) => {
    let { files, error } = this.state,
    file = e.target.files[0],
    exts = {
    }
    if(file) {
      if(!exts[e.target.name] || hasExtension(file.name, exts[e.target.name])) {
        files[e.target.name].push(e.target.files[0]);
        this.setState({ files })
      } else {
        error.show = true;
        error.message = "Please Select a PPT file!"
        this.setState({
          error
        })
      }
    }
  }

  removeSelectedFile = (prop, i) => {
    let { files } = this.state;
    files[prop].splice(i, 1)
    this.setState({ files })
  }


  deleteExistingFile = (id, prop, i) => {
    let { deleteDocs } = this.state;
    var temp = this.state[prop];
    temp.splice(i, 1)
    deleteDocs.push(id);
    this.setState({ deleteDocs, [prop] : temp })
  }

  getApplicationDocs = async () => {
    let { application_docs } = this.props.application,
      { docJD, docPPT, docOther } = this.state,
      fileIds = [], files = [];
    if (application_docs) {
      await application_docs.forEach(doc => {
        if (!isNaN(parseInt(doc.file_path))) fileIds.push(doc.file_path)
        if (doc.doc_type === "JD") docJD.push(doc)
        else if (doc.doc_type === "PPT") docPPT.push(doc)
        else if (doc.doc_type === "Others") docOther.push(doc)
      })
      if (fileIds.length)
        files = await getFiles(fileIds)
      docJD = await docJD.map(function name(doc) {
        return Object.assign({}, doc, { file_detail: files[doc.file_path] ? files[doc.file_path][0] : {} })
      })
      docPPT = await docPPT.map(function name(doc) {
        return Object.assign({}, doc, { file_detail: files[doc.file_path] ? files[doc.file_path][0] : {} })
      })
      docOther = await docOther.map(function name(doc) {
          return Object.assign({}, doc, { file_detail: files[doc.file_path] ? files[doc.file_path][0] : {} })
      })
      this.setState({ docJD, docPPT, docOther })
    }
  }
  

  toggle = async () => {
    await this.setState(this.getInitialState())
    await this.getApplicationDocs()
    this.props.toggleModal("applicationModal")
  }
  getEligibilityRequirements = async () => {
    this.setState({ isLoading: true })
    let { eligibilityOptionsList } = this.state;
    await this.getEligibilityDepartment();
    await this.getEligibilitySector();
    await this.getEligibilitySpecialization();
    eligibilityOptionsList["GENDER"] = [
      {gender_type: "Male", gender_name : "Male"},
      {gender_type: "Female", gender_name : "Female"}
    ];
    eligibilityOptionsList["CATEGORY"] = [
      {type: "SC", name : "SC"},
      {type: "ST", name : "ST"},
      {type: "OBC", name : "OBC"},
      {type: "General", name : "General"}
    ];
    eligibilityOptionsList["VISION_ISSUES"] = [
      {type: "0", name : "No"},
      {type: "1", name : "Yes"}
    ];
    this.setState({eligibilityOptionsList});
    await this.getEmailConfig()
    this.setState({ isLoading: false })
  }

  handleStateChange = (e) => {
    let state = this.state[e.target.dataset.state];
    if (state) {
      state[e.target.name] = e.target.value;
      if (state.type == 'DEPARTMENT' && e.target.name == "value") {
        const { options, selectedIndex } = e.target;
        state.value_type = options[selectedIndex].innerHTML;
      }
      if (e.target.name == "value[]") {
        var options = [...e.target.options]
        var selectedValue = options.reduce((prev, data) => { if (data.selected) return [...prev, data.value]; else return prev }, [])
        if (state.type == 'DEPARTMENT') {
          var selectedText = options.reduce((prev, data) => { if (data.selected) return [...prev, data.innerHTML]; else return prev }, [])
          selectedText = selectedText.join(",")
          state.value_type = selectedText;
        }
        selectedValue = selectedValue.join(",")
        state.value = selectedValue;
      }
      this.setState({
        [state]: state
      })
    }
  }

  toggleEligibility = (data) => {
    let { applicationEligibility } = this.state,
      { application_eligibility } = this.props.application;
    if (applicationEligibility[data.type]) {
      delete applicationEligibility[data.type]
    } else {
      let prev = application_eligibility.filter(ec => ec.type === data.type)
      applicationEligibility[data.type] = Object.assign({}, {
        type: data.type,
        operator: data.operator || data.operators[0],
        value: ""
      }, prev[0])
    }
    this.setState({ applicationEligibility })
  }

  editEligibility = (e, type, op) => {
    let { applicationEligibility, eligibilityOptionsList } = this.state,
      ecData = applicationEligibility[type],
      { name, value } = e.target,
      options = eligibilityOptionsList[type];
    if (ecData) {
      if (op === "OR" || ecData.operator === "OR") {
        let values = ecData[name] ? ecData[name].split(",") : [],
        index = values.indexOf(value);
        if (index > -1){
          values.splice(index, 1)
        }
        else{
          values.push(value)
        }
        if(!op){
          let valueTypes = options.filter(opt=>values.indexOf(opt[CONSTANTS.ELIGIBILITY_CRITERIA_TYPES[type].listId].toString())>-1).map(opt=>opt[CONSTANTS.ELIGIBILITY_CRITERIA_TYPES[type].name])
          ecData["value_type"] = valueTypes.join(",")
        }
        ecData[name] = values.join(",")
      }
      else if(ecData.operator === "range" && name.includes("value")){
        var v = (ecData.value || "").split(",");
        if(name === "value1") v[0] = value;
        if(name === "value2") v[1] = value;
        ecData.value = v.join(",");
      }
      else
        ecData[name] = value
    }
    this.setState({ applicationEligibility })
  }

  handleApplicationSubmit = async (e) => {
    let { process_company_profile_id, location, description, offer_amount_max, offer_amount_min, offer_input_type, offer_currency, offer_amount_type, stipend_value, designation, is_preference, is_single_application, resume_restriction, apply_limit, application_binder_dropdown } = e.target,
      { application_id, application_eligibility, rounds, application_docs, process_id, process_company_id, application_question } = this.props.application,
      { addedCriteria, addedrounds, deletedRounds, is_withdraw_allow, is_multiple_role, withdraw_deadline, deletedCriteria, application_deadline, deleteDocs, files, addedQuestions, deletedQuestions, application_binder_id, savenext, isSure, confirmSaveNext, applicationEligibility,applicationInstruction, applicationDescription } = this.state,
      uploadedDocs = [], applicationResp = {};
    
    if (!isSure && confirmSaveNext) {
      var event = {
        target: { process_company_profile_id, location, description, offer_amount_max, offer_amount_min, offer_input_type, offer_currency, offer_amount_type, stipend_value, designation, is_preference, is_single_application, resume_restriction, apply_limit, application_binder_dropdown }
      }
      this.setState({ isSure: true, confimationParam: event })
      return false;
    }
    else if (isSure && confirmSaveNext) {
      savenext = savenext + 1;
      this.setState({ savenext })
    }
    deletedCriteria = application_eligibility.filter(ec => !applicationEligibility[ec.type]).map(ec => ec.application_eligibility_id)
    let formData = {
      location: location.value,
      offer_amount_min: offer_amount_min.value || '0',
      offer_amount_max: offer_input_type.value === "strict" ? offer_amount_min.value || '0' : offer_amount_max.value || '0',
      offer_input_type: offer_input_type.value,
      offer_currency: offer_currency.value,
      offer_amount_type: offer_amount_type ? offer_amount_type.value : "Y",
      stipend_value: stipend_value.value || 0 ,
      description: applicationDescription,
      instruction: applicationInstruction,
      application_deadline: application_deadline.utc(false).format(CONSTANTS.DATE_FORMAT),
      is_withdraw_allow, is_multiple_role,
      designation: designation.value
    }
    if (withdraw_deadline) {
      formData = {
        ...formData,
        withdraw_deadline: withdraw_deadline.utc(false).format(CONSTANTS.DATE_FORMAT)
      }
    }
    if (process_company_profile_id) {
      formData = {
        ...formData,
        process_company_profile_id: process_company_profile_id.value,
        process_id,
        process_company_id
      }

    }
    this.setState({
      isLoading: true
    })
    try {


      if(!this.state.isEditApplicantList && typeof application_id === "number" && this.props.application.publish_status == 1){
        e.persist();
        let applicantsResp = await Auth.Processes.getApplicants(application_id);
        this.setState({editApplicantsStudentId : applicantsResp.data.applicants.map(applicant => applicant.student_id), isEditApplicantList : e});
        this.setState({ isLoading: false })
        return;
      }

      if (process_company_profile_id) {
        applicationResp = await Auth.Processes.addApplication(formData)
        application_id = applicationResp.data[0].application_id
        if (is_multiple_role) {
          if (application_binder_id == 'new' || application_binder_id.toString().split("_")[0] == "applicationId") {
            var addMultiRoleData = {
              application_id,
              is_preference: is_preference.checked ? '1' : '0',
              is_single_application: is_single_application.checked ? '1' : '0',
              resume_restriction: resume_restriction.checked ? '1' : '0',
            }
            if (application_binder_id.toString().split("_")[0] == "applicationId") {
              var prev_application_id = application_binder_id.toString().split("_")[1]
              addMultiRoleData.application_id = [application_id, prev_application_id]
            }
            if (apply_limit.value)
              addMultiRoleData.apply_limit = apply_limit.value
            var binderRes = await Auth.Processes.addMultipleRole(addMultiRoleData)
            application_binder_id = binderRes.data[0].application_binder_id;
            if (prev_application_id) {
              this.props.handleDataChange({ application_id: prev_application_id, is_multiple_role: '1', application_binder_id }, false)
            }
          }
          else {
            var appendMultiRoleData = {
              application_id, application_binder_id
            }
            await Auth.Processes.appendMultipleRole(appendMultiRoleData)
          }
          applicationResp.data[0].is_multiple_role = 1;
          applicationResp.data[0].application_binder_id = application_binder_id;
          this.setState({ application_binder_id })
        }
      }
      if (typeof application_id === "number") {
        let addedList = await Promise.all(Object.keys(applicationEligibility).map(async (ec) => {
          applicationEligibility[ec].application_id = application_id
          let resp = await Auth.Processes.addEligibilityCriteria(applicationEligibility[ec])
          return resp.data[0];
        }))
        addedList = addedList.reduce((prev, current) => {
          prev[current.type] = current;
          return prev
        }, {})
        for(var i = 0; i < addedrounds.length; i++){
          addedrounds[i].application_id = application_id;
          addedrounds[i].hotlisting_deadline = addedrounds[i].hotlisting_deadline ? moment(addedrounds[i].hotlisting_deadline).utc(false).format("YYYY-MM-DD") : undefined;
          await Auth.Processes.addMilestone(addedrounds[i]);
        }
        if (deletedRounds.length > 0)
          await Auth.Processes.deleteMilestone({ application_round_id: deletedRounds })
        if (deletedCriteria.length > 0)
          await Auth.Processes.deleteEligibilityCriteria({ application_eligibility_id: deletedCriteria, application_id })
        let newQuestions = await Promise.all(addedQuestions.map(async (q) => {
          q.application_id = application_id
          let resp = await Auth.Processes.addQuestion(q)
          return resp.data[0];
        }))
        if (deletedQuestions.length > 0)
          await Auth.Processes.deleteQuestion({ application_question_id: deletedQuestions })
        if (deleteDocs.length > 0)
          await Auth.Processes.deleteDocs(deleteDocs);
        await Promise.all(files.jd.map(async(doc) => {
          let fileJD = await uploadFile(doc, `application/${application_id}/docs`)
          let respJD = await Auth.Processes.addDoc({ application_id, doc_type: "JD", file_path: fileJD.file_id })
          uploadedDocs.push(respJD.data[0])
        }))
        await Promise.all(files.ppt.map(async(doc) => {
          let filePPT = await uploadFile(doc, `application/${application_id}/docs`)
          let respPPT = await Auth.Processes.addDoc({ application_id, doc_type: "PPT", file_path: filePPT.file_id })
          uploadedDocs.push(respPPT.data[0])
        }))
        await Promise.all(files.other.map(async(doc) => {
          let fileOther = await uploadFile(doc, `application/${application_id}/docs`)
          let respOther = await Auth.Processes.addDoc({ application_id, doc_type: "Others", file_path: fileOther.file_id })
          uploadedDocs.push(respOther.data[0])
        }))
        application_docs = application_docs.filter(doc => deleteDocs.indexOf(doc.application_docs_id) < 0)
        application_docs = application_docs.concat(uploadedDocs)
        // application_eligibility = application_eligibility.filter(ec => deletedCriteria.indexOf(ec.application_eligibility_id) < 0);
        // application_eligibility = application_eligibility.concat(addedList);
        application_eligibility = Object.keys(applicationEligibility).map(type => {
          if (addedList[type])
            applicationEligibility[type] = addedList[type];
          return applicationEligibility[type];
        })
        // rounds = rounds.filter(ec => deletedRounds.indexOf(ec.application_round_id) < 0);
        // rounds = rounds.concat(addedRoundsList);
        application_question = application_question.filter(q => deletedQuestions.indexOf(q.application_question_id) < 0);
        application_question = application_question.concat(newQuestions);
        if(!process_company_profile_id) {
          formData.isSendMail = this.state.isSendEditMail
          applicationResp = await Auth.Processes.editApplication(application_id, formData)
        }
        let applicantsResp = await Auth.Processes.getApplicants(application_id)
        let applicantionDetailResp = await Auth.Processes.getApplicationsInProcess(process_id,null,application_id)
        this.props.handleDataChange({ application_id, ...applicantionDetailResp.data[0], application_eligibility, ...applicantsResp.data, application_docs, application_question }, process_company_profile_id)
        if (savenext) {
          this.setState({ isLoading: false, isSure: false, confirmSaveNext: false })
          application_binder_dropdown.value = application_binder_id;
        }
        else {
          this.toggle()
        }
      }
    } catch (e) {
      let error = { show: true, message: "Something went wrong, please try again later" }
      if (e.response) {
        error.message = e.response.data.error.toString()
        this.setState({ error })
      }
      console.log(e)
    }
    this.setState({ isLoading: false })
  }

  onEditApplicants = async(student_id) => {
    let {isEditApplicantList}= this.state;
    var application_id = this.props.application.application_id;
    try{
    let applicantsResp = await Auth.Processes.getApplicants(application_id);
    var currStudentId = applicantsResp.data.applicants.map(applicant => applicant.student_id);
    var removeStudentId = currStudentId.filter(id => student_id.indexOf(id) === -1);
    var addStudentId = student_id.filter(id => currStudentId.indexOf(id) === -1);
    if(addStudentId.length) await Auth.Processes.addApplicantsToApplication({application_id, student_id : addStudentId});
    if(removeStudentId.length) await Auth.Processes.removeApplicantsToApplication({application_id, student_id : removeStudentId});
    await this.handleApplicationSubmit(isEditApplicantList);
    this.setState({isEditApplicantList : false})
    }
    catch(e){
      let error = { show: true, message: "Something went wrong, please try again later" }
      if (e.response) {
        error.message = e.response.data.error.toString()
        this.setState({ error })
      }
      console.log(e)
    }


  }
  addEC = () => {
    let { addedCriteria, eligibility_criteria } = this.state;

    if (eligibility_criteria.value != undefined && eligibility_criteria.value != null && eligibility_criteria.value != '') {
      if (eligibility_criteria.operator == 'range') {
        eligibility_criteria.value1 = eligibility_criteria.value;
        eligibility_criteria.value = eligibility_criteria.value1 + "," + eligibility_criteria.value2;
      }
      addedCriteria.push({ ...eligibility_criteria })
      eligibility_criteria = { ...this.init_eligibility_criteria }
      this.setState({
        addedCriteria, eligibility_criteria
      })
    }
  }
  addRound = () => {
    let { addedrounds, round_info } = this.state;
    addedrounds.push({ ...round_info })
    this.setState({
      addedrounds, round_info: { round_type: "GD", is_hotlisting: '0' }
    })
  }

  deleteRound = (id, i) => {
    let { addedrounds, deletedRounds } = this.state,
      { rounds } = this.props.application;
    if (!id) {
      addedrounds.splice(i - (rounds.length + deletedRounds.length), 1)
      this.setState({ addedrounds })
    }
    else {
      deletedRounds.push(id)
      this.setState({ deletedRounds })
    }

  }
  deleteEC = (id, i) => {
    let { addedCriteria, deletedCriteria } = this.state,
      { application_eligibility } = this.props.application;
    if (!id) {
      addedCriteria.splice(i - (application_eligibility.length + deletedCriteria.length), 1)
      this.setState({ addedCriteria })
    } else {
      deletedCriteria.push(id)
      this.setState({ deletedCriteria })
    }
  }

  addQuestion = () => {
    let { addedQuestions, application_question } = this.state;
    if (application_question.question_heading.trim() !== "") {
      application_question.answer_type_data = application_question.answer_type !== "multiple" ? JSON.stringify(application_question.answer_type_data) : application_question.answer_type_data
      addedQuestions.push({ ...application_question })
      this.setState({
        addedQuestions, application_question: { ...this.init_application_question }
      })
    }
  }

  deleteQuestion = (id, i) => {
    let { addedQuestions, deletedQuestions } = this.state,
      { application_question } = this.props.application;
    if (!id) {
      addedQuestions.splice(i - (application_question.length + deletedQuestions.length), 1)
      this.setState({ addedQuestions })
    } else {
      deletedQuestions.push(id)
      this.setState({ deletedQuestions })
    }
  }

  toggleConfirmation = () => {
    this.setState({ isSure: false })
  }

  render() {
    let { applicationModal, application, profile, profileList, profileApplications, applicationBinderInfo } = this.props,
      { error, files, eligibilityOptionsList, is_withdraw_allow, is_multiple_role, withdraw_deadline, application_question, offerInputType, addedCriteria, docJD, docPPT, docOther, deletedCriteria, isLoading, application_deadline, addedQuestions, deletedQuestions, application_binder_id, savenext, isSure, confimationParam, confirmSaveNext, binderInfo, round_info, addedrounds, deletedRounds, applicationEligibility ,applicationInstruction, applicationDescription} = this.state;
    application.rounds = application.rounds ? application.rounds : []
    let config = {hotlisting : {}}
    return (
      <Aux>
        {(this.state.isEditApplicantList) ? <UnblockStudents onEditApplicants={this.onEditApplicants} selectedStudentId={this.state.editApplicantsStudentId} addStudentModal={true} toggle={() => this.setState({isEditApplicantList : false})} processStudent={this.props.processStudent} /> : null}

        <Loader show={isLoading} />
        <Errorbar showBar={error.show} onClose={this.closeError} message={error.message} />
        <Modal  onOpened={this.getEligibilityRequirements} zIndex="99999" size="md" isOpen={applicationModal} 
        // toggle={this.toggle}
        >
          <ModalBody >
            <form onSubmit={(e) => { e.preventDefault(); this.handleApplicationSubmit(e); }}>
              <Row>
                <Col xs={6}>
                  <Row>
                    <Col xs={12}>
                      <label htmlFor={`application_${application.application_id}_multiple_role`}>Invite as Multi Role Application ?</label> {'  '}
                      <Switch className="d-inline-block align-middle" isOn={is_multiple_role} handleToggle={({ target }) => { if (typeof application.application_id === "number") return true; else this.setState({ is_multiple_role: is_multiple_role ? 0 : 1 }) }} />
                    </Col>
                  </Row>

                  {Object.keys(applicationBinderInfo || {}).length && is_multiple_role && !savenext ?
                    <Row className="pl-4 r-text--sm mt-3">
                      <Col xs={12}>
                        <label>You can merge with existed application.</label>
                      </Col>
                      <Col>
                        <Select name="application_binder_dropdown" value={application_binder_id} onChange={({ target }) => { this.setState({ application_binder_id: target.value }) }}>
                          <option value="new">Create New Multiple Role</option>
                          {
                            Object.keys(applicationBinderInfo).map((binder_id, index) => {
                              var processProfilesBinded = profileList.filter(pro => applicationBinderInfo[binder_id].indexOf(pro.process_company_profile_id.toString()) >= 0).map(data => data.role_name)
                              return <option key={index} value={binder_id}>{processProfilesBinded.toString() + (!isNaN(binder_id) ? "(Multiple Role)" : "")}</option>
                            })
                          }
                        </Select>
                      </Col>
                    </Row> : ""
                  }


                  <Collapse isOpen={(application_binder_id == 'new' || application_binder_id.toString().split("_")[0] == "applicationId") && is_multiple_role ? true : false} >
                    <Row className="ml-4 mr-1 mt-2 r-text--sm py-3 shadow">

                      <Col xs={9}>

                        <label htmlFor={`application_${application.application_id}_is_preference`}>Require preference from student ?</label>
                      </Col>
                      <Col xs={3}>
                        <input checked={binderInfo[0].is_preference} value="is_preference" id={`application_${application.application_id}_is_preference`} type="checkbox" name="is_preference" />
                      </Col>
                      <Col xs={9}>
                        <label htmlFor={`application_${application.application_id}_resume_restriction`}>Restrict student to use same resume</label>
                      </Col>
                      <Col xs={3}>
                        <input checked={binderInfo[0].resume_restriction} value="resume_restriction" id={`application_${application.application_id}_resume_restriction`} type="checkbox" name="resume_restriction" />
                      </Col>
                      <Col xs={9}>
                        <label htmlFor={`application_${application.application_id}_single_application`}>Will you treat it as single application?</label>
                      </Col>
                      <Col xs={3}>
                        <input checked={binderInfo[0].is_single_application} value="is_single_application" id={`application_${application.application_id}_single_application`} type="checkbox" name="is_single_application" />
                      </Col>
                      <Col xs={9}>
                        <label htmlFor={`application_${application.application_id}_single_application`}>Enter apply limit, if required</label>
                      </Col>
                      <Col xs={3}>
                        <InputField value={binderInfo[0].apply_limit} name="apply_limit" className="w-100" />
                      </Col>
                    </Row>
                  </Collapse>
                  {is_multiple_role && applicationBinderInfo ?
                    <Row noGutters className="mt-5 border-bottom">
                      <Col xs={12}>
                        <h1 className="r-heading-3">Role {" "}{((applicationBinderInfo[application_binder_id] && applicationBinderInfo[application_binder_id].length + 1) || 1)}</h1>
                      </Col>
                    </Row> : ""
                  }
                  <Row noGutters className="mb-3 mt-3">
                    <Col xs={12}>
                      <h3 className="r-heading-4"><span className="r-text--red-1">*</span>Application Deadline</h3>
                      <Row>
                        <Col xs={7}>
                          <SingleDatePicker
                            value={application_deadline}
                            onDateChange={(date) => this.setState({ application_deadline: date, withdraw_deadline: date })}
                            id={"deadline_" + application.application_id} />
                        </Col>
                        <Col xs={5}>
                          <TimePicker
                            onChange={(date) => this.setState({ application_deadline: date , withdraw_deadline: date})}
                            value={application_deadline}
                            format="h:mm a"
                            use12Hours
                            showSecond={false}
                            inputReadOnly />
                        </Col>
                      </Row>
                      <h3 className="r-heading-4 mt-2">Application Withdraw</h3>
                      <Row>
                        <Col xs={12}>
                          <input checked={is_withdraw_allow} value="is_withdraw_allow" onChange={({ target }) => this.setState({ is_withdraw_allow: target.checked ? 1 : 0 })} id={`application_${application.application_id}_withdraw`} type="checkbox" name="is_withdraw_allow" />{" "}{" "}
                          <label htmlFor={`application_${application.application_id}_withdraw`}>Allow withdraw?</label>
                        </Col>
                      </Row>
                      {is_withdraw_allow > 0 && <Row>
                        <Col xs={12}>
                          <h3 className="r-heading-7 mt-2">Withdraw deadline</h3>
                        </Col>
                        <Col xs={7}>
                          <SingleDatePicker
                            value={withdraw_deadline}
                            onDateChange={(date) => this.setState({ withdraw_deadline: date })}
                            id={"withdraw_deadline_" + application.application_id} />
                        </Col>
                        <Col xs={5}>
                          <TimePicker
                            onChange={(date) => this.setState({ withdraw_deadline: date })}
                            value={withdraw_deadline}
                            format="h:mm a"
                            use12Hours
                            showSecond={false}
                            inputReadOnly
                            placeholder="Enter Time" />
                        </Col>
                      </Row>}
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <div className="px-4">
                    <h3 className="r-heading-4">Upload Documents</h3>
                    <Row>
                        <Col className="px-1" xs="4" >
                        <label className=" r-btn r-btn--blue text-center d-block" htmlFor={`application_${application.application_id}_jd`}><i className="mr-1 r-text--sm fi flaticon-plus-1" />JD</label>
                        <input name="jd" onChange={this.handleFileChange} id={`application_${application.application_id}_jd`} type="file" className="d-none" />
                        </Col>

                        <Col className="px-1" xs="4" >
                        <label htmlFor={`application_${application.application_id}_ppt`} className=" r-btn r-btn--blue text-center d-block"><i className="mr-1 r-text--sm fi flaticon-plus-1" />PPT</label>
                        <input name="ppt" onChange={this.handleFileChange} id={`application_${application.application_id}_ppt`} type="file" className="d-none" />
                        </Col>

                        <Col className="px-1" xs="4" >
                        <label className=" r-btn r-btn--blue text-center d-block px-0" htmlFor={`application_${application.application_id}_other`}><i className="mr-1 r-text--sm fi flaticon-plus-1" />Other</label>
                        <input name="other" onChange={this.handleFileChange} id={`application_${application.application_id}_other`} type="file" className="d-none" />
                        </Col>
                    </Row>
                    {docJD.map((doc, i) =>  {
                     return <div key={i} className="r-tag r-tag--type-1">
                        <AttachmentLogo width={10} /><a href={doc.file_detail && doc.file_detail.url} target="_blank" rel="noopener noreferrer">Job Description</a> <Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.deleteExistingFile(doc.application_docs_id, "docJD", i)}></Button>
                      </div>
                    })
                    }
                    {files.jd.map((doc, i) => 
                      <div key={i}  className="text-truncate r-tag r-tag--type-1">
                        <AttachmentLogo width={10} />{doc.name}<Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.removeSelectedFile("jd", i)}></Button>
                      </div>
                    )
                    }
                    {docPPT.map((doc, i) =>
                      <div key={i}  className="r-tag r-tag--type-1">
                        <AttachmentLogo width={10} /><a href={doc.file_detail && doc.file_detail.url} target="_blank" rel="noopener noreferrer">PPT file</a><Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.deleteExistingFile(doc.application_docs_id, "docPPT", i)} ></Button>
                      </div> 
                    )
                    }
                    {files.ppt.map((doc, i) =>
                      <div key={i}  className="text-truncate r-tag r-tag--type-1">
                        <AttachmentLogo width={10} />{doc.name}<Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.removeSelectedFile("ppt", i)}></Button>
                      </div>
                    )
                    }
                    {docOther.map((doc, i) =>
                      <div key={i}  className="r-tag r-tag--type-1">
                          <AttachmentLogo width={10} /><a href={doc.file_detail && doc.file_detail.url} target="_blank">Other Document</a> <Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.deleteExistingFile(doc.application_docs_id, "docOther", i)}></Button>
                      </div>
                    )
                    }
                    {files.other.map((doc, i) =>
                      <div key={i}  className="text-truncate r-tag r-tag--type-1">
                        <AttachmentLogo width={10} />{doc.name}<Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.removeSelectedFile("other", i)}></Button>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={6}>
                  <h3 className="r-heading-4 mb-4">Job Description</h3>

                  <Row noGutters>
                    <Col xs={12}>
                      <Row className="mb-3">
                        <Col >
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Role</label>
                          {profile ? <p>{profile.role_name}</p> : <Select name="process_company_profile_id" required>
                            <option value="">Select Role</option>
                            {profileList.map(pro => {
                              if (!profileApplications[pro.process_company_profile_id])
                                return <option key={pro.process_company_profile_id} value={pro.process_company_profile_id}>{pro.role_name}</option>
                            })}
                          </Select>}
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col >
                          <label className="r-heading-7">Designation</label>
                          <InputField className="w-100" name="designation" type="text" defaultValue={application.designation || ""} />

                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col >
                          <label className="r-heading-7">Location</label>
                          <InputField className="w-100" name="location" type="text" defaultValue={application.location} />

                        </Col>
                      </Row>
                      {/* <span>{application.location}</span><Button type="button" id={`location_${application.application_id}`} className="r-text--sm r-text--blue-3">(Edit)</Button>
                <UncontrolledCollapse toggler={`#location_${application.application_id}`}>
                  <InputField name="location" type="text" defaultValue={application.location} />
                </UncontrolledCollapse> */}
                      {/* <span>{application.offer_currency} {application.offer_amount_min ? application.offer_amount_min.toLocaleString("en-IN") : ""}
                  {application.offer_input_type === "range" ? (" - " + (application.offer_amount_max ? application.offer_amount_max.toLocaleString("en-IN") : "")) : ""}
                  {application.offer_amount_type === "M" ? " PM" : " PA"}</span><Button type="button" className="r-text--sm r-text--blue-3">(Edit)</Button> */}
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <h3 className="r-heading-4  mb-4">Offer Details</h3>
                  <Row className="mb-3">
                    <Col xs={6}>
                      <label className="r-heading-7">CTC Input Type</label>
                      <Select name="offer_input_type" onChange={({ target }) => this.setState({ offerInputType: target.value })} defaultValue={application.offer_input_type}>
                        <option value="strict">Strict</option>
                        <option value="range">Range</option>
                      </Select>
                    </Col>
                    <Col xs={6}>
                      <label className="r-heading-7">CTC Currency</label>
                      <Select name="offer_currency" defaultValue={application.offer_currency}>
                        <option value="INR">INR</option>
                        <option value="USD">USD</option>
                        <option value="AED">AED</option>
                        <option value="SAR">SAR</option>
                        <option value="BHD">BHD</option>
                        <option value="QAR">QAR</option>
                        <option value="OMR">OMR</option>
                        <option value="KWD">KWD</option>
                        <option value="SGD">SGD</option>
                        <option value="MYR">MYR</option>
                        <option value="HKD">HKD</option>
                      </Select>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <label className="r-heading-7">CTC Amount Type</label>
                      <Select name="offer_amount_type" defaultValue={application.offer_amount_type}>
                        <option value="M">Monthly</option>
                        <option value="Y">Yearly</option>
                      </Select>
                    </Col>
                    <Col>
                      <label className="r-heading-7">CTC Amount{offerInputType === "range" && " Min"}</label>
                      <InputField className="w-100" name="offer_amount_min" type="number" defaultValue={application.offer_amount_min} />
                    </Col>
                    {offerInputType === "range" && <Col>
                      <label className="r-heading-7">CTC Amount Max</label>
                      <InputField className="w-100" name="offer_amount_max" type="number" defaultValue={application.offer_amount_max} />
                    </Col>}
                    
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <label className="r-heading-7">Stipend Amount</label>
                      <InputField className="w-100" name="stipend_value" type="number" defaultValue={application.stipend_value} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <label className="r-heading-7">Description</label>
                  <RTE2 className="r-rte"  name="applicationDescription"  onChange={(value) => this.setState({applicationDescription: value})} value={applicationDescription} />
                </Col>

                <Col>
                  <label className="r-heading-7">Application Instructions</label>
                  <RTE2 className="r-rte" name="applicationInstruction"  onChange={(value) => this.setState({applicationInstruction: value})} value={applicationInstruction} />
                </Col>
              </Row>


              <div className="border-top my-3"></div>
              <h3 className="r-heading-4">Application Rounds</h3>
              <Table className="processes__table__1">
                <tbody>
                  {
                    application.rounds.concat(addedrounds).filter(e => deletedRounds.indexOf(e.application_round_id) < 0).map((rounds, i) => {
                      return (
                        <tr key={i}>
                          <td className="r-subheading-4">{(i + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}</td>
                          <td>{rounds.round_name}</td>
                          <td>{CONSTANTS.ROUND_TYPES[rounds.round_type]}</td>
                          <td>{ rounds.is_hotlisting == '1' ? moment(rounds.hotlisting_deadline).utc(false).format("YYYY-MM-DD") : ""}</td>
                          <td><Button type="button" className="p-0" onClick={() => this.deleteRound(rounds.application_round_id,i)}><i className="fi flaticon-bin" /></Button></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
              <Button type="button" id={`addRound_${application.application_id}`} className="r-text--sm" color="brandB">+ Add Round</Button>
              <UncontrolledCollapse toggler={`#addRound_${application.application_id}`}>
                <Row className="mt-3 r-text--sm align-items-center">
                  <Col xs={3}>
                    <span className="r-subheading-4">Round Type</span>
                    <Select name="round_type" data-state="round_info" value={round_info.round_type} onChange={(e) => {this.handleStateChange(e); round_info.round_name = CONSTANTS.ROUND_TYPES[e.target.value]; this.setState({round_info})}}>
                      {Object.keys(CONSTANTS.ROUND_TYPES).map(round => {
                        return <option key={round} value={round}>{CONSTANTS.ROUND_TYPES[round]}</option>
                      })}
                    </Select>
                  </Col>
                  <Col xs={3}>
                    <span className="r-subheading-4">Round Name</span>
                    <InputField className="w-100" name="round_name" data-state="round_info" value={round_info.round_name} onChange={this.handleStateChange} />
                  </Col>
                  { config.hotlisting.status ?
                  <Col xs={2}>
                    <span className="r-subheading-4">Hotlisting</span>
                    <Select name="is_hotlisting" data-state="round_info" value={round_info.is_hotlisting} onChange={this.handleStateChange}>
                      <option value="0">No</option>
                      <option value="1">Yes</option>
                    </Select>
                  </Col> : ""
                }
                  {round_info.is_hotlisting == '1'?
                    <Col xs={2}>
                      <span className="r-subheading-4">Hotlisting Deadline</span>
                      <SingleDatePicker
                        value={round_info.hotlisting_deadline}
                        onDateChange={(date) => this.setState({ round_info: { ...round_info, hotlisting_deadline: date } })} />
                    </Col>
                    : null
                  }
                  <Col xs={2}>
                    <Button onClick={this.addRound} type="button" className="r-text--blue-3">Add</Button>
                  </Col>
                </Row>
              </UncontrolledCollapse>


              {/* <span className="r-text--sm">{application.description || ""}</span><Button type="button" className="r-text--sm r-text--blue-3">(Edit)</Button> */}
              <div className="border-top my-3"></div>
              <h3 className="r-heading-4">Eligibility Criteria</h3>
              {/* <Table className="processes__table__1">
                <tbody>
                  {
                    application.application_eligibility.concat(addedCriteria).filter(e => deletedCriteria.indexOf(e.application_eligibility_id) < 0).map((ec, i) => {
                      return (
                        <tr key={i}>
                          <td className="r-subheading-4">{(i + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}</td>
                          <td>{CONSTANTS.ELIGIBILITY_CRITERIA[ec.type] ? CONSTANTS.ELIGIBILITY_CRITERIA[ec.type].value : "unknown"}</td>
                          <td>{CONSTANTS.ELIGIBILITY_OPERATOR[ec.operator]}</td>
                          <td>{ec.operator == 'range' ? ec.value.split(",")[0] + " - " + ec.value.split(",")[1] : (ec.type == "DEPARTMENT" ? ec.value_type : ec.value)}</td>
                          <td><Button type="button" className="p-0" onClick={() => this.deleteEC(ec.application_eligibility_id, i)}><i className="fi flaticon-bin" /></Button></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table> */}
              <Table className="processes__table__1">
                <tbody>
                  {
                    Object.keys(CONSTANTS.ELIGIBILITY_CRITERIA_TYPES).filter(type => this.state.isShowAdditionalEligibility ? true : !CONSTANTS.ELIGIBILITY_CRITERIA_TYPES[type].additional).map(type => {
                      let ecData = { ...CONSTANTS.ELIGIBILITY_CRITERIA_TYPES[type] },
                        criteria = applicationEligibility[type] || null;
                      return (<tr key={type} className={criteria ? "" : "disabled-row"}>
                        <td><Switch isOn={criteria !== null} handleToggle={() => this.toggleEligibility({ ...ecData, type, ...criteria })} /></td>
                        <td>{ecData.label}</td>
                        <td>{ecData.operators.length > 1 ?
                          <Select disabled={criteria ? false : true} name="operator" value={criteria ? criteria.operator : ecData.operators[0]} onChange={(e) => this.editEligibility(e, type)}>
                            {ecData.operators.map(op =>
                              <option key={op} value={op}>{CONSTANTS.ELIGIBILITY_OPERATOR[op]}</option>
                            )}
                          </Select> : <span>{CONSTANTS.ELIGIBILITY_OPERATOR[ecData.operators[0]] === "OR" ? "" : CONSTANTS.ELIGIBILITY_OPERATOR[ecData.operators[0]]}</span>
                        }</td>
                        <td style={{ width: "50%" }}>
                          {ecData.operators[0] === "OR" ? 
                            <MultiSelectList disabled={criteria ? false : true} title={criteria ? (criteria.value_type || criteria.value || "Select") : "Select"}>
                              <ul className="list-unstyled m-0">
                                {eligibilityOptionsList[type] && eligibilityOptionsList[type].map((item, i) => {
                                  let id = item[ecData.listId]
                                  return (
                                    <li key={i}>
                                      <input name="value" onChange={(e) => this.editEligibility(e, type)} checked={criteria ? criteria.value.split(",").indexOf(id.toString()) > -1 : false} id={`${type}-${i}`} type="checkbox" value={id} />
                                      <label htmlFor={`${type}-${i}`}>{item[ecData.name]}</label>
                                    </li>
                                  )
                                })}
                              </ul>
                            </MultiSelectList> 
                            : 
                            <Row>
                              {["GRAD_SCORE", "PG_SCORE"].indexOf(type) !== -1 &&
                              <Col className="">
                                <MultiSelectList disabled={criteria ? false : true} title={criteria ? (criteria.value_type && criteria.value_type.trim() ? (eligibilityOptionsList["BATCH"] && eligibilityOptionsList["BATCH"].filter(batch => criteria.value_type.split(",").indexOf(batch.batch_id.toString()) !== -1).map(b => b.batch_name).join(", ")) : "All Batches") : "All Batches"}>
                                <ul className="list-unstyled m-0">
                                  {eligibilityOptionsList["BATCH"] && eligibilityOptionsList["BATCH"].map((item, i) => {
                                    return (
                                      <li key={i}>
                                        <input name="value_type" onChange={(e) => this.editEligibility(e, type, "OR")} checked={criteria && criteria.value_type && criteria.value_type.trim() ? criteria.value_type.split(",").indexOf(item.batch_id.toString()) > -1 : false} id={`${type}-${i}`} type="checkbox" value={item.batch_id} />
                                        <label htmlFor={`${type}-${i}`}>{item.batch_name}</label>
                                      </li>
                                    )
                                  })}
                                </ul>
                                </MultiSelectList> 
                              </Col>
                              }
                              
                              {criteria && criteria.operator === 'range' ?
                                <>
                                  <Col>
                                    <InputField placeholder={"Min-"+ecData.placeholder} disabled={criteria ? false : true} value={(criteria.value||",").split(",")[0]} name="value1" data-state="eligibility_criteria" onChange={(e) => this.editEligibility(e, type)} type={ecData.type || "text"} className="w-100" />
                                  </Col>
                                  <Col>
                                    <InputField placeholder={"Max-"+ecData.placeholder} disabled={criteria ? false : true} value={(criteria.value||",").split(",")[1]} name="value2" data-state="eligibility_criteria" onChange={(e) => this.editEligibility(e, type)} type={ecData.type || "text"} className="w-100" />
                                  </Col>
                                </>
                                : 
                                <Col>
                                  <InputField type={ecData.type || "text"} placeholder={ecData.placeholder || ""} disabled={criteria ? false : true} name="value" className={"w-100"} value={criteria ? criteria.value : ""} onChange={(e) => this.editEligibility(e, type)} />
                                </Col>
                              }
                            </Row>
                          }
                        </td>
                      </tr>)
                    })
                  }
                </tbody>
              </Table>
              <div className="w-100 text-center"><Button type="button" className="r-text--sm" color="link" onClick={() => this.setState({isShowAdditionalEligibility : !this.state.isShowAdditionalEligibility})}>Show {this.state.isShowAdditionalEligibility ? "Less" : "More"}</Button></div>
              {/* <Button type="button" id={`addEligibility_${application.application_id}`} className="r-text--sm" color="brandB">+ Add Criteria</Button>
              <UncontrolledCollapse toggler={`#addEligibility_${application.application_id}`}>
                <Row className="mt-3 r-text--sm align-items-center">
                  <Col xs={4}>
                    <span className="r-subheading-4">Type</span>
                    <Select name="type" data-state="eligibility_criteria" value={eligibility_criteria.type} onChange={(e) => { this.handleStateChange(e); if (CONSTANTS.ELIGIBILITY_CRITERIA[eligibility_criteria.type].listFunction) this[CONSTANTS.ELIGIBILITY_CRITERIA[eligibility_criteria.type].listFunction](); }}>
                      {Object.keys(CONSTANTS.ELIGIBILITY_CRITERIA).map(op => {
                        return <option key={op} value={op}>{CONSTANTS.ELIGIBILITY_CRITERIA[op].value}</option>
                      })}
                    </Select>
                  </Col>
                  <Col xs={3}>
                    <span className="r-subheading-4">Operator</span>
                    <Select name="operator" data-state="eligibility_criteria" value={eligibility_criteria.operator} onChange={this.handleStateChange}>
                      {
                        CONSTANTS.ELIGIBILITY_OPERATOR_GROUP[CONSTANTS.ELIGIBILITY_CRITERIA[eligibility_criteria.type].operatorGroup].map(op => {
                          return <option key={op} value={op}>{CONSTANTS.ELIGIBILITY_OPERATOR[op]}</option>
                        })
                      }

                    </Select>
                  </Col>
                  <Col xs={3}>
                    <span className="r-subheading-4">Value</span>
                    {!CONSTANTS.ELIGIBILITY_CRITERIA[eligibility_criteria.type].listFunction ?
                      <Row>
                        <Col>
                          <InputField value={eligibility_criteria.value} name="value" data-state="eligibility_criteria" onChange={this.handleStateChange} type="text" className="w-100" />
                        </Col>
                        {eligibility_criteria.operator == 'range' ?
                          <Col className="px-0">
                            <InputField value={eligibility_criteria.value2} name="value2" data-state="eligibility_criteria" onChange={this.handleStateChange} type="text" className="w-100" />
                          </Col>
                          : ""
                        }
                      </Row>
                      :
                      <Select name={"value" + (eligibility_criteria.operator == 'OR' || eligibility_criteria.operator == 'AND' ? "[]" : "")} data-state="eligibility_criteria" onChange={this.handleStateChange} multiple={eligibility_criteria.operator == 'OR' || eligibility_criteria.operator == 'AND' ? true : false}>
                        <option value="">select</option>
                        {this.state.eligibilityOptionsList || ""}
                      </Select>
                    }
                  </Col>
                  <Col xs={2}>
                    <Button onClick={this.addEC} type="button" className="r-text--blue-3">Add</Button>
                  </Col>
                </Row>
              </UncontrolledCollapse> */}
              <div className="border-top my-3"></div>
              <h3 className="r-heading-4">Application Requirements</h3>
              <Table className="processes__table__1">
                <tbody>
                  {
                    application.application_question.filter(q => q.ref_type === "APPLY").concat(addedQuestions).filter(q => deletedQuestions.indexOf(q.application_question_id) < 0).map((q, i) => {
                      let answer_type_data = q.answer_type !== "multiple" ? JSON.parse(q.answer_type_data) : q.answer_type_data;
                      return (
                        <tr key={i}>
                          <td className="r-subheading-4">{(i + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}</td>
                          <td>
                            <div className="r-heading-7">{q.question_heading}</div>
                            <span>{q.question_content}</span>
                          </td>
                          <td>{CONSTANTS.ANSWER_TYPES[q.answer_type]}{q.answer_type == 'file' && Object.keys(answer_type_data).length ? "(" + (CONSTANTS.QUESTION_FILE_TYPES[answer_type_data.file_type] || '') + (answer_type_data.file_size ? ", " + answer_type_data.file_size + " kb" : "") + ")" : null}</td>
                          <td>{parseInt(q.is_mandatory) ? "Mandatory" : "Optional"}</td>
                          <td><Button type="button" className="p-0" onClick={() => this.deleteQuestion(q.application_question_id, i)}><i className="fi flaticon-bin" /></Button></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
              <Button type="button" id={`addQuestion_${application.application_id}`} className="r-text--sm mb-3" color="brandB">+ Add Question</Button>
              <UncontrolledCollapse toggler={`#addQuestion_${application.application_id}`}>
                <Row>
                  <Col xs={12}>
                    <span className="r-subheading-4">Enter question<span className="r-text--brandF">*</span></span>
                    <InputField type="text" placeholder="Type some question here" className="w-100" value={application_question.question_heading} name="question_heading" data-state="application_question" onChange={this.handleStateChange} />
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs={4}>
                    <span className="r-subheading-4">Enter answer hint</span>
                    <InputField type="text" placeholder="Type answer hint here" className="w-100" value={application_question.question_content} name="question_content" data-state="application_question" onChange={this.handleStateChange} />
                  </Col>
                  <Col xs={3}>
                    <span className="r-subheading-4">Select answer type</span>
                    <Select type="text" placeholder="Type answer hint here" value={application_question.answer_type} name="answer_type" data-state="application_question" onChange={this.handleStateChange}>
                      {
                        Object.keys(CONSTANTS.ANSWER_TYPES).map(ans => <option key={ans} value={ans}>{CONSTANTS.ANSWER_TYPES[ans]}</option>)
                      }
                    </Select>
                  </Col>
                  <Col xs={3}>
                    <span className="r-subheading-4">Mandatory?</span>
                    <Select name="is_mandatory" data-state="application_question" value={application_question.is_mandatory} onChange={this.handleStateChange}>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Select>
                  </Col>
                  {application_question.answer_type == 'file' ?
                    <Col xs={2}>
                      <span className="r-subheading-4">Use Config</span>
                      <Select name="is_config" data-state="application_question" value={application_question.is_config} onChange={this.handleStateChange}>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </Select>
                    </Col> : <Col xs={2}></Col>
                  }
                </Row>
                <Row className="align-items-center">
                  <Col xs={10}>
                    {
                      application_question.answer_type === "multiple" && 
                      <>
                      <span className="r-subheading-4">Multiple Choice Options (Comma Separated)</span>
                      <InputField placeholder="Option 1, Option 2, Option 3, Option 4" className="w-100" onChange={(e) => this.setState({ application_question: { ...application_question, answer_type_data: e.target.value } })} />
                      </>
                    }
                    {application_question.is_config == '1' ?
                      <Row className="mt-2">
                        <Col xs={4}>
                          <span className="r-subheading-4">File Type</span>
                          <Select name="answer_type_data" data-state="application_question" value={application_question.answer_type_data && application_question.answer_type_data.file_type} onChange={(e) => this.setState({ application_question: { ...application_question, answer_type_data: { ...application_question.answer_type_data, file_type: e.target.value } } })}>
                            <option value="">Select</option>
                            {
                              Object.keys(CONSTANTS.QUESTION_FILE_TYPES).map(ft => <option key={ft} value={ft}>{CONSTANTS.QUESTION_FILE_TYPES[ft]}</option>)
                            }
                          </Select>
                        </Col>
                        <Col xs={4}>
                          <span className="r-subheading-4">File Size(kb)</span>
                          <InputField className="w-100" onChange={(e) => this.setState({ application_question: { ...application_question, answer_type_data: { ...application_question.answer_type_data, file_size: e.target.value } } })} />
                        </Col>
                      </Row> : null
                    }
                  </Col>
                  <Col xs="auto" className="py-3">
                    <Button onClick={this.addQuestion} type="button" className="r-text--blue-3">Add</Button>
                  </Col>
                </Row>
              </UncontrolledCollapse>
              
              <div className="bg-light shadow-lg text-center p-4" style={{position: "fixed", top: "40%", width: 200, transform: "translateX(650px)"}}>
                {/* {isLoading && <Spinner color="dark" />} */}
                <span className="fi flaticon-multimedia text-light" style={{position: "absolute", left: "-12px", top: "40%", transform: "rotate(180deg)"}}></span>
                <Button type="button" onClick={() => this.toggle()} color="dark" className="r-text--sm r-btn--no-radius">Cancel</Button>

                {is_multiple_role && profileList && (profileList.length - Object.keys(profileApplications).length) > 1 ?
                  <div>
                    <Button type="submit" onClick={() => { this.setState({ savenext: 0 }) }} color="dark" className="r-text--sm r-btn--no-radius mt-3">Save & Exit</Button>
                    <br />
                    <Button type="submit" onClick={() => { this.setState({ confirmSaveNext: true }) }} name="savenext" color="dark" className="mt-3 r-text--sm r-btn--no-radius">Save & Add Next Role</Button>
                  </div>
                  :
                  <div>
                    <Button type="submit" onClick={() => { this.setState({ savenext: 0,isSendEditMail: false}) }} color="dark" className="r-text--sm r-btn--no-radius mt-3">Save</Button>
                  <br />
                  { typeof application.application_id == "number" && this.state.emailConfig.EMAILS && this.state.emailConfig.EMAILS.APPLICATION_EDIT.status == "1" && application.publish_status == "1" ? 
                    <Button type="submit" onClick={() => { this.setState({ savenext: 0,isSendEditMail: true}) }} color="dark" className="r-text--sm r-btn--no-radius mt-3">Save & Notify</Button>
                    : "" 
                    }
                  </div>
                }
              </div>
            </form>
            <Confirmation isOpen={isSure} toggleConfirmation={this.toggleConfirmation} callback={() => this.handleApplicationSubmit(confimationParam)} confirmationText="Application will be created for this role and you will move onto creating the next role. Are you sure?" />

          </ModalBody>
        </Modal>
      </Aux>
    )
  }
}
