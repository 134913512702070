import React, { Component } from "react";
import { CONSTANTS } from "../../../constants";
import { HELP_SECTION_CONFIG } from "../../../constants/help_section_config";
// import Button from '../../../global/Button';
import classnames from "classnames";
import {
  Col,
  Row,
  ModalBody,
  Modal,
  ModalFooter,
  ModalHeader,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";

// var myVideo = document.getElementById("video1");

// const playPause=()=>{
//   if (myVideo.paused)
//     myVideo.play();
//   else
//     myVideo.pause();
// }

class HelpSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      key: null,
      popoverName: "",
      popoverOpen: false,
    };
  }

  render() {
    let { tab } = this.props,
      { modalOpen, key } = this.state;
    let functionalities = tab ? CONSTANTS.HELP_SECTION[tab] : [];
    return functionalities && Object.keys(functionalities).length > 0 ? (
      <div className="help_section">
        <button className="help_section__float border-0" id={tab}>
          <span>Help?</span>
        </button>
        <UncontrolledPopover
          trigger="legacy"
          placement="top"
          className="help_section__ul-style"
          target={tab}
        >
          <ul>
            {Object.keys(functionalities).map((item) => (
              <li
                key={functionalities[item].functionality_name}
                onClick={() =>
                  this.setState({
                    modalOpen: true,
                    key: functionalities[item].functionality_name,
                  })
                }
              >
                <span>{functionalities[item].functionality_name}</span>
              </li>
            ))}
          </ul>
        </UncontrolledPopover>
        <Modal
          isOpen={modalOpen}
          size="lg"
          zIndex="99999"
          toggle={() => this.setState({ modalOpen: !this.state.modalOpen })}
        >
          <ModalHeader toggle={() => this.setState({ modalOpen: false })}>
            <span className="r-subheading-2 r-text--grey-4">{key}</span>
          </ModalHeader>
          <ModalBody>
            {HELP_SECTION_CONFIG[tab][key] &&
              HELP_SECTION_CONFIG[tab][key].length > 0 &&
              HELP_SECTION_CONFIG[tab][key].map((item) => {
                return (
                  Object.keys(item).length > 0 &&
                  Object.keys(item).map((subItem) => {
                    if (subItem == "video" && item[subItem]) {
                      return (
                        <Row key={subItem}>
                          <Col className="m-4 p-3 border">
                            <center>
                              <video id="video1" width="500" controls>
                                <source src={item[subItem]} type="video/mp4" />
                                Your browser does not support HTML5 video.
                              </video>
                            </center>
                            {/* <button onclick="playPause()">Play/Pause</button> */}
                          </Col>
                        </Row>
                      );
                    }
                    if (subItem == "text") {
                      return (
                        <Row key={subItem}>
                          <Col className="m-1">
                            <ul>
                              <li key={subItem}>{item[subItem]}</li>
                            </ul>
                          </Col>
                        </Row>
                      );
                    }
                    if (subItem == "image" && item[subItem]) {
                      return (
                        <Row key={subItem}>
                          <Col className="m-4 p-3 border">
                            <img
                              src={"/images/help_section/" + item[subItem]}
                              className="img-fluid"
                              alt="Help Section"
                            />
                          </Col>
                        </Row>
                      );
                    }
                  })
                );
              })}
          </ModalBody>
        </Modal>
      </div>
    ) : null;
  }
}

export default HelpSection;
