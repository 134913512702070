import React from "react";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import { AddSuggestion } from "../../../global/Forms/AddSuggestion";
import { Confirmation } from "../../../global/confirmation";
import AutosuggestComp from "../../../global/AutosuggestComp";
import withRouter from "react-router-dom/withRouter";
import { Auth } from "../../../../actions/Auth";
import { setUser, setLoading } from "../../../../actions/action-creators";
import {
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  Nav,
  Table,
  Collapse,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Button from "../../../global/Button";
import { Errorbar } from "../../../global/errorbar";
import { SelectList } from "../../../global/Forms/Select";
import moment from "moment";
import { InputField } from "../../../global/Forms";
import { ToggleButton } from "../../../global/Forms/ToggleButton";
import classnames from "classnames";

class EditBatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campusList: [],
      batchName: this.props.data.batch_name,
      startYear: moment(this.props.data.start_year).format("YYYY"),
      endYear: moment(this.props.data.end_year).format("YYYY"),
      degreeid: this.props.data.campus_degree_id,
      campusDegreeID: this.props.data.campus_degree_id,
      campusDegreeName: this.props.data.campus_degree_name,
      degreeValue: "",
      errorbar: false,
      isConfirmation: false,
      deleteConfirmationId: null,
      message: "",
      years: [],
      batchs: [],
      isOpen: false,
      editBatch: false,
      setloading: false,
      // ArchiveStatus: this.props.data.archive_status,
      is_archived: this.props.data.is_archived ? 1 : 0,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      batchName: props.data.batch_name,
      startYear: moment(props.data.start_year).format("YYYY"),
      endYear: moment(props.data.end_year).format("YYYY"),
      campusDegreeID: props.data.campus_degree_id,
      campusDegreeName: props.data.campus_degree_name,
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  closebar = () => {
    this.setState({ errorbar: false });
  };

  handleyear(name, value) {
    if (name === "startYear") {
      this.setState({ startYear: value });
    } else {
      this.setState({ endYear: value });
    }
  }

  handleCampusID(id, name) {
    this.setState({ campusID: id });
    this.setState({ campusName: name });
  }

  toggleOpen = () => {
    this.setState({ isOpen: true });
  };

  componentDidMount() {
    const { years } = this.state;
    //this._init();
    var currentYear = new Date().getFullYear();
    var startYear = currentYear - 10;
    while (startYear <= currentYear + 10) {
      this.state.years.push(startYear++);
    }
    this.setState({ years });
  }

  // _init = async() =>{
  //     let {campusList} = this.state;
  //    this.props.setLoading(true)
  //    try {
  //     let response = await Auth.PtStudents.getCampus(1);
  //     this.setState({campusList: response.data});
  //     this.props.setLoading(false);
  //   } catch (e) {
  //     this.props.setLoading(false);
  //     this.setState({errorbar: true});
  //     this.setState({message: e.response.data.error.toString()});
  //      }
  // }

  // toggleClose = () => {
  //   this.setState({ isOpen: false });
  // };                                                   remove this
  toggleArchive = async () => {
    
    const newArchiveStatus = this.state.is_archived === 0 ? 1 : 0;

    const batchBody = {
      batch_id: this.props.data.batch_id,
      is_archived: newArchiveStatus,
      // is_archived: getvalue(),
    };
    this.setState({ setLoading: true });
    try {
      let resp = await Auth.PtStudents.editBatch(batchBody);
      // this.setState({ is_archived: !this.state.is_archived });         remove this
      this.setState({ is_archived: newArchiveStatus });
      this.setState({ setloading: false });
    } catch (e) {
      // this.setState({ is_archived: this.state.is_archived });
      // this.setState({ is_archived: initial });                         remove this
      this.setState({ setloading: false });
    }
  };

  onSubmitEdit = async (e) => {
    e.preventDefault();
    this.setState({ setloading: true });
    var batchBody = {
      batch_name: this.state.batchName,
      start_year: "" + this.state.startYear + "-01-01",
      end_year: "" + this.state.endYear + "-01-01",
      campus_degree_id: this.state.campusDegreeID,
      batch_id: this.props.data.batch_id,
    };
    try {
      let resp = await Auth.PtStudents.editBatch(batchBody);
      this.props.updateBatch();
      this.setState({ setloading: false });
      this.setState({ editBatch: false });
    } catch (e) {
      this.setState({ setloading: false });
      this.setState({ errorbar: true });
      this.setState({ message: e.response.data.error.toString() });
    }
  };

  handlecampusDegreeID(data) {
    this.setState({ campusDegreeName: data.degree_name });
    this.setState({ campusDegreeID: data.campus_degree_id });
  }

  onDelete = async (id) => {
    this.setState({ setloading: true });
    this.toggleConfirmation();
    var batchBody = {
      batch_id: id,
    };
    try {
      let resp = await Auth.PtStudents.deleteBatch(batchBody);
      this.props.updateBatch();
      this.setState({ setloading: false });
    } catch (e) {
      this.setState({ setloading: false });
      this.setState({ errorbar: true });
      this.setState({ message: e.response.data.error.toString() });
    }
  };

  setDegreeValue = (value) => {
    let evtObj = {
      target: {
        value: value.degree_id,
        name: "degree_id",
        degree_name: value.degree_name,
      },
    };
    this.setState({
      degreeValue: value.degree_name,
      degreeid: value.degree_id,
    });
  };

  confirmDeleteBatch = (id) => {
    this.setState({ deleteConfirmationId: id, isConfirmation: true });
  };

  toggleConfirmation = () => {
    this.setState({ isConfirmation: false });
  };

  render() {
    const {
      batchName,
      batchs,
      campusDegreeID,
      campusDegreeName,
      editBatch,
      startYear,
      endYear,
      isConfirmation,
    } = this.state;
    const { data, degreeidList } = this.props;
    const autoSuggestProps = {
      onChange: this.onChange,
      className: "w-100 r-inputfield",
    };

    return (
      <div>
        {editBatch ? (
          <form onSubmit={this.onSubmitEdit}>
            <Row className="justify-content-between align-items-center mx-0 mb-4 ">
              <Col xs="2" className="text-center">
                <InputField
                  className="r-nav__search"
                  style={{ minWidth: "170px" }}
                  type="text"
                  name="batchName"
                  value={batchName}
                  onChange={this.onChange}
                  required
                />
              </Col>
              <Col xs="3" className="text-center">
                <select
                  value={campusDegreeID}
                  name="campusDegreeID"
                  className="r-dropdown__select "
                  style={{ backgroundColor: "#f5f5f5" }}
                  onChange={this.onChange}
                  required
                >
                  {degreeidList.map((data) => {
                    return (
                      <option
                        key={data.campus_degree_id}
                        value={data.campus_degree_id}
                      >
                        {data.degree_name}
                      </option>
                    );
                  })}
                </select>
              </Col>
              <Col xs="2" className="text-center">
                <select
                  value={startYear}
                  name="startYear"
                  className="r-dropdown__select "
                  style={{ backgroundColor: "#f5f5f5" }}
                  onChange={this.onChange}
                  required
                >
                  {this.state.years.map((year) => {
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </Col>
              <Col xs="2" className="text-center">
                <select
                  value={endYear}
                  name="endYear"
                  className="r-dropdown__select "
                  style={{ backgroundColor: "#f5f5f5" }}
                  onChange={this.onChange}
                  required
                >
                  {this.state.years.map((year) => {
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </Col>
              {this.state.setloading ? (
                <div className="center-xy">
                  <Spinner color="dark" />
                </div>
              ) : null}
              <Col xs="1" className="text-center">
                <Row className="justify-content-center align-items-center">
                  <Button type="submit">
                    <i
                      className="r-text--blue-3 d-inline-block fi flaticon-save"
                      style={{ fontSize: "15px" }}
                    />
                  </Button>
                  <Button
                    type="button"
                    onClick={(e) => this.setState({ editBatch: false })}
                  >
                    <i
                      className="d-inline-block fi flaticon-plus-1 rotate--45"
                      style={{ fontSize: "15px" }}
                    />
                  </Button>
                </Row>
              </Col>
              <Col xs="2" className="text-center">
                <Row className="justify-content-center align-items-center">
                  <Button type="submit">
                    <i
                      className="r-text--blue-3 d-inline-block fi flaticon-save"
                      style={{ fontSize: "15px" }}
                    />
                  </Button>
                  <Button
                    type="button"
                    onClick={(e) => this.setState({ editBatch: false })}
                  >
                    <i
                      className="d-inline-block fi flaticon-plus-1 rotate--45"
                      style={{ fontSize: "15px" }}
                    />
                  </Button>
                </Row>
              </Col>
            </Row>
          </form>
        ) : (
          <Row className="justify-content-between align-items-center  mx-0  mb-4">
            <Col xs="2" className="text-center">
              <span>{data.batch_name}</span>
            </Col>
            {/* <Col xs="3" className="text-center">
                                                                         <span >{data.campus_id}</span>
                                                                   </Col>*/}
            <Col xs="3" className="text-center">
              <span>{data.campus_degree_name || data.campus_degree_id}</span>
            </Col>
            <Col xs="2" className="text-center">
              <span>{moment(data.start_year).format(" YYYY")}</span>
            </Col>
            <Col xs="2" className="text-center">
              <span>{moment(data.end_year).format(" YYYY")}</span>
            </Col>
            {this.state.setloading ? (
              <div className="center-xy">
                <Spinner color="dark" />
              </div>
            ) : null}
            <Col xs="1" className="text-center">
              <Row className="justify-content-center align-items-center">
                <Button onClick={(e) => this.setState({ editBatch: true })}>
                  <i
                    className="r-text--blue-3 d-inline-block fi flaticon-edit"
                    style={{ fontSize: "15px" }}
                  />
                </Button>
                {/*  <Button onClick={(e) => this.confirmDeleteBatch(data.batch_id)}><i className="r-text--blue-3 d-inline-block fi flaticon-bin " style={{fontSize:"15px"}}/></Button>*/}
              </Row>
            </Col>
            <Col xs="2" className="text-center">
              {/* <ToggleButton buttonType="st" selected={"non archive"} onToggle={()=>this.toggleArchive(data.archive_status?false:true,data.batch_id)} values={{ value1: "archive", value2: "non archieve" }} /> */}
              <ToggleButton
                buttonType="pt"
                selected={this.state.is_archived == 1 ? true : false}
                onToggle={() => this.toggleArchive()}
                values={{ value1: " ", value2: " " }}
              />
            </Col>
          </Row>
        )}

        <Errorbar
          showBar={this.state.errorbar}
          onClose={this.closebar}
          message={this.state.message}
        />
        <Confirmation
          isOpen={isConfirmation}
          toggleConfirmation={this.toggleConfirmation}
          callback={() => this.onDelete(this.state.deleteConfirmationId)}
        />
      </div>
    );
  }
}

export default EditBatch;
