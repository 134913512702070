import React, { Component } from 'react';
import withRouter from 'react-router-dom/withRouter';
import {Row,Col,Container,Collapse, Modal, ModalBody, Table, Input, ModalHeader,Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { setUser, setLoading } from '../../../actions/action-creators';
import Button from '../../global/Button';
import {Confirmation} from '../../global/confirmation';
import { Loader } from '../../global/Loader';
import { Auth } from '../../../actions/Auth';
import { InputField } from '../../global/Forms';
import {CONSTANTS} from '../../../constants';
import Permission from './Permission.js'

class Roles extends Component{
	  constructor(props){
	    super(props);
	    this.state = {
	      getRoleList:  [],
	      add: false,
	      roleName:'',
	      roleType:'',
	      isConfirmation : false, 
	      deleteConfirmationId : null,
	      descriptions:'',
	      loader:false,
	      rolesList:[2,3,4,5,6]
	    };
	  }

	  
  onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
  }

	 componentDidMount(){
	 	this.init();
	 }

	 init  = async () =>{
		     try {
		      let res = await Auth.Spoc.getRole();
		      this.setState({getRoleList: res.data});
		    } catch (e) {
		      console.log(e)
		    }
	 }

	 handleSubmit = async(e) =>{
	 	e.preventDefault();
	     this.setState({add: false})
     	this.setState({loader:true})
	     try {
		    let body = {
		            "role_name":this.state.roleName,
					"role_type":this.state.roleType,
					"access_key":this.props.role.access_key,
					"description":this.state.descriptions
		        }
	      	let res = await Auth.Spoc.addRole(body);
	      	this.init();
	      	this.setState({
				      roleName:'',
				      roleType:'',
				      descriptions:'',})
	     	this.setState({loader:false})
	    } catch (e) {
	      console.log(e)
	     	this.setState({loader:false})
	    }
	 }

	 roledit = async(body) =>{
     		this.setState({loader:true})
	     try {
	      	let res = await Auth.Spoc.editRole(body);
	      	this.init();
	     	this.setState({loader:false})
	    } catch (e) {
	      	console.log(e);
	     	this.setState({loader:false})
	    }
	 }

	 deleterole = async(roleid) =>{
     	this.setState({loader:true})
    	this.toggleConfirmation()
	     try {
	     	let roles = [];
	     	roles.push(roleid);
		    let body = {
				    "role_id": roles,
				    "status": 0
		        }
	      	let res = await Auth.Spoc.deleteRole(body);
	      	this.init();
	     	this.setState({loader:false})
	    } catch (e) {
	      	console.log(e);
	     	this.setState({loader:false})
	    }
	 }


  confirmDeleteRole = (id) => {
    this.setState({deleteConfirmationId : id, isConfirmation : true})
  }

  toggleConfirmation = () => {
    this.setState({isConfirmation : false})
  }



	render(){
		const{add,rolesList,roleType,roleName, descriptions,isConfirmation} = this.state;
		return(
			      <Container>
      				<Loader show={this.state.loader} />
			        <Row className="mt-5">
			          <Col xs={2}>
			            <h1 className="r-subheading-1 mt-4">User Roles</h1>
			            </Col>
			            <Col>
			            <Button onClick={(e) => this.setState({add: true})} className="r-text--sm mt-4" color="brandB"><i className="fi flaticon-plus mr-2"></i>Add New Role</Button>
			          </Col>
			        </Row>
			        <Row className="mt-4 mx-0 py-3 r-bg--blue-2 r-text--sm align-items-center">
			          <Col xs={3} className="" >
		                <h1 className="r-subheading-4">Role Name</h1>
			          </Col>
			          <Col xs={3}>
			          	<h1 className="r-subheading-4">Role Type</h1>
			          </Col>
			          <Col xs={3}>
			          	<h1 className="r-subheading-4">Descriptions</h1>
			          </Col>
			          <Col xs={1}>
			          	<h1 className="r-subheading-4">Permissions</h1>
			          </Col>
			          <Col xs={2} className="text-center">
			          	<h1 className="r-subheading-4">Actions</h1>
			          </Col>
			        </Row>
			        {add?
			       		<form  onSubmit={(e)=> this.handleSubmit(e)}>
					        <Row  className="mt-4 mx-0 py-3 r-text--sm align-items-center">
					          <Col xs={3} >
		                        	<InputField className="r-nav__search" placeholder="Type your role name..." type="text" name="roleName" value={roleName} onChange={this.onChange} required/>
					          </Col>
					          <Col xs={3}>
               					 <div className="r-dropdown border-0 ">
					          		<select value={roleType} name="roleType" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={this.onChange} required>
			                           <option value='' >Select Role Type</option>
			                             {rolesList.map(data =>{
			                               return <option key={data}  value={data}>{CONSTANTS.ROLE_TYPES[data]}</option>
			                             })}
			                        </select>
			                      <i className="fi flaticon-arrow"/>
			                      </div>
					          </Col>
					          <Col xs={3} >
		                        	<InputField className="r-nav__search" placeholder="Descriptions..." type="text" name="descriptions" value={descriptions} onChange={this.onChange} required/>
					          </Col>
					          <Col xs={1}  className="text-center">
					          </Col>
					          <Col xs={2} className="text-center">
		                             <Button type="submit" ><i className=" d-inline-block fi flaticon-save " style={{fontSize:"15px"}}/></Button>
		                             <Button type="button" onClick={(e) => this.setState({add: false}) }><i  className=" d-inline-block fi flaticon-plus-1 rotate--45 " style={{fontSize:"15px"}}/> </Button>
					          </Col>
					        </Row>
			       		</form>:null
			    	}
			        {this.state.getRoleList.map((role,i) =>{
			        	return(
			        		<EditRoles key={i} role={role} roledit={this.roledit} deleterole={this.confirmDeleteRole}/>
			        		)
			        })}
                  <Confirmation isOpen={isConfirmation} toggleConfirmation={this.toggleConfirmation} callback={() => this.deleterole(this.state.deleteConfirmationId)} />
			      </Container>
			)
	}
}

class EditRoles extends Component{
	  constructor(props){
	    super(props);
	    this.state = {
	      edit:  false,
	      roleName:props.role.role_name,
	      roleType:props.role.role_type,
	      descriptions:props.role.description,
	      modal:false,
	      loader:false,
	      rolesList:[2,3,4,5,6]
	    };
	  }

	  onChange = (e) => {
	        this.setState({
	            [e.target.name]: e.target.value
	        })
	  }

	 handleSubmit = async(e,role) =>{
	 	e.preventDefault();
	    let body = {
	    		"role_id":role.role_id,
	            "role_name":this.state.roleName,
				"access_key":this.props.role.access_key,
				"role_type":this.state.roleType,
				"description":this.state.descriptions
	        }
	     this.props.roledit(body);
	     this.setState({edit: false})
	 }

	 toggle =()=>{
	 	this.setState({modal: !this.state.modal})
	 }

	 loaderShow = () => {
	 	this.setState({loader: !this.state.loader})
	 }

	render(){
		const{role} = this.props;
		const{edit,rolesList,roleType,modal,roleName,loader,descriptions} = this.state;
		return(
    		<div>        
    		<Loader show={loader} />
		       {!edit?
				       	<Row  className="mt-4 mx-0 py-3 r-text--sm align-items-center border-bottom">
					          <Col xs={3} className="" >
				                <h1 className="r-subheading-4">{role.role_name}</h1>
					          </Col>
					          <Col xs={3}>
					          	<h1 className="r-subheading-4">{CONSTANTS.ROLE_TYPES[role.role_type]}</h1>
					          </Col>
					          <Col xs={3}>
					          	<h1 className="r-subheading-4">{role.description}</h1>
					          </Col>
					          <Col xs={1}  className="text-center">
					          	{(CONSTANTS.ROLE_TYPES[role.role_type] === "Placement Team") && <Button type="button" onClick={this.toggle} color="link-blue"><i className="d-inline-block fi flaticon-eye mr-2"></i></Button>}
					          </Col>
					          <Col xs={2} className="text-center">
		                         <Button type="button" onClick={(e) => this.setState({edit: true})  }><i className=" d-inline-block fi flaticon-edit" style={{fontSize:"15px"}}/></Button>
		                         <Button type="button" onClick={(e) => this.props.deleterole(role.role_id)}><i className=" d-inline-block fi flaticon-bin " style={{fontSize:"15px"}}/></Button>
					          </Col>
					        </Row>:
				       		<form onSubmit={(e)=> this.handleSubmit(e, role)}>
						        <Row  className="mt-4 mx-0 py-3 r-text--sm align-items-center">
						          <Col xs={3} className="" >
			                        	<InputField className="r-nav__search" placeholder="Type your role name..." type="text" name="roleName" value={roleName} onChange={this.onChange} required/>
						          </Col>
						          <Col xs={3}>
               					 	<div className="r-dropdown border-0 ">
						          		<select value={roleType} name="roleType" className="r-dropdown__select " style={{backgroundColor: "#f5f5f5"}}  onChange={this.onChange} required>
				                           <option value="select" >Select Role Type</option>
				                             {rolesList.map(data =>{
				                               return <option key={data}  value={data}>{CONSTANTS.ROLE_TYPES[data]}</option>
				                             })}
				                        </select>
				                      	<i className="fi flaticon-arrow"/>
				                      </div>
						          </Col>
						          <Col xs={3} >
			                        	<InputField className="r-nav__search" placeholder="Descriptions..." type="text" name="descriptions" value={descriptions} onChange={this.onChange} required/>
						          </Col>
						          <Col xs={1}  className="text-center">
						          </Col>
						          <Col xs={2} className="text-center">
			                             <Button type="submit" onClick={this.props.editrole}><i className=" d-inline-block fi flaticon-save " style={{fontSize:"15px"}}/></Button>
			                             <Button type="button" onClick={(e) => this.setState({edit: false}) }><i  className=" d-inline-block fi flaticon-plus-1 rotate--45 " style={{fontSize:"15px"}}/> </Button>
						          </Col>
						        </Row>
				       		</form>
		       			}
					{modal?<Permission modal={modal} role={role} toggle={this.toggle} loaderShow={this.loaderShow}/>:null}
		    </div>
			)
	}
}


const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoading: (show) => dispatch(setLoading(show)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Roles));