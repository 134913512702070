import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {createBrowserHistory} from 'history';
import Aux from '../../global/AuxComp';
import Link from 'react-router-dom/Link';
import {Row,Col,Container} from 'reactstrap';
import { Card, Button, CardTitle, CardText} from 'reactstrap';
import {CardButton} from './verifier_card_buttons';
import {getFullName} from '../../../actions';

export const VerifySummaryCard = (props) => {
  let {studentDetails, searchText} = props;
  if(searchText!==''|| true){
    studentDetails = studentDetails.filter(data=>{
      var studentName = getFullName(data.student_info[0]) || '';
      if(studentName.toLowerCase().indexOf(searchText.toLowerCase())!==-1){
        return data;
      }
    })
  }

  return (
    <Row>
    {studentDetails.slice(props.currentPage * 9, props.currentPage * 9 + 9).map((student,i)=>{
      if(student.student_info[0]){

        let student_id = student.student_info[0].student_id;

      return(
        <Col  xs="4">
        <Link className="verifier__card-link" to={"/verifier/verification/"+student_id+"/"+props.processId+"/studentDetails"}>
        <div>
          <Card body className="  verifier__verify-summary-card">
            <CardTitle><span className=" r-subheading-2">{getFullName(student.student_info[0])}</span></CardTitle>
            <CardText>
            <Row>
            <Col xs="6" className="text-truncate"><span className="r-subheading-3"> {student.student_info[0].department_name!=null?(student.student_info[0].department_name):null}</span>
            </Col>
            <Col xs="6" align="end"><span className="r-subheading-3"> { moment(student.modified_on).format("Do MMMM YYYY")} </span>
            </Col>
            </Row>
            <Row>
            <Col xs="6"><span  className=" r-text--grey-4 r-subheading-4"> Department </span>
            </Col>
            <Col xs="6" align="end"><span className="r-text--grey-4 r-subheading-4"> Last Updated </span>
            </Col>
            </Row>
            </CardText>
            <CardButton  studentDetails={student} calcUnverifiedStudents={props.calcUnverifiedStudents}/>
          </Card>
          </div>
          </Link>
        </Col>
      )}})}

  </Row>

  );
};
