import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import { Row, Col,Card, CardBody, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import {UncontrolledCollapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Loader } from '../../../global/Loader';
import { Select} from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import { SingleDatePicker } from '../../../global/Forms/DatePicker';
import { FileUpload } from '../../../global/FileUpload';
import { CONSTANTS } from '../../../../constants';
import moment from 'moment';
import OfferForm from './offer-form'


class OfferCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'newOfferTab',
      offer_date : moment(),
      designationList: [],
      offerList:[],
      offersPanelContent:[],
      message : '',
    };
  }


  async componentWillMount() {

    await this.init();
  }

  ordinal_suffix_of(i) {
    let j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}


  init = async () => {
     try {
      let offers= this.props.offer.map((data,index ) => {
        return(
          <NavItem key={data.offer_id} >
          <NavLink
            className={classnames({ active: this.state.activeTab === data.offer_id })}
            onClick={() => { this.toggle(data.offer_id); }}
            >
            <p className={data.accept_status ===1 ? "accepted_offer":""}> {this.ordinal_suffix_of(index + 1)}  Offer</p>
          </NavLink>
          </NavItem>
        )
      })

      let offersPanel= this.props.offer.map(data => {
        return(
          <TabPane key={data.offer_id} tabId={data.offer_id}>
          <Row className="p-3">
              <Col xs={12}>
              {this.state.activeTab==data.offer_id && <OfferForm
                student_id = {this.props.student_id}
                batch_id={this.props.batch_id}
                companyList = {this.props.companylist}
                handleClosed = {this.props.handleClosed}
                loadInit={this.props.loadInit}
                loading = {this.props.loading}
                data={data}
                process_id={this.props.process_id}
                />}

              </Col>
          </Row>

        </TabPane>
        )
    })
    await this.setState({offerList:offers})
      this.setState({offersPanelContent:offersPanel})
    } catch (e) {
      console.log(e)
    }
  }


  toggle = async(tab) => {
    if (this.state.activeTab !== tab) {
      await this.setState({
        activeTab: tab
      });
      this.init()
    }
  }






  render() {


    return (
        <Col xs={12}>
        <div className="card p-2 mb-3">
            <Nav tabs>
              {this.state.offerList}
            <NavItem>
                <NavLink
                className={classnames({ active: this.state.activeTab === 'newOfferTab' })}
                onClick={() => { this.toggle('newOfferTab'); }}
                >
                <button color="blue" className=" r-text--sm r-btn r-btn--blue">New offer</button>
                </NavLink>
            </NavItem>
            <div style={{position: "absolute", right: 10, top: 10}} >
              <div className="d-inline-block" style={{width: 10, height: 10, borderRadius: 5, background: "#00b10f"}}></div>Accepted
              <div className="d-inline-block ml-1" style={{width: 10, height: 10, borderRadius: 5, background: "#ffcc33"}}></div>Pending
            </div>

            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              {this.state.offersPanelContent}
              <TabPane tabId="newOfferTab">
                <Row className="p-3">
                  <Col xs={12}>
                    <OfferForm
                    student_id = {this.props.student_id}
                    batch_id={this.props.batch_id}
                    companyList = {this.props.companylist}
                    handleClosed = {this.props.handleClosed}
                    loading = {this.props.loading}
                    loadInit={this.props.loadInit}
                    data={{}}
                    process_id={this.props.process_id}
                     />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
        </div>
        </Col>
    )
  }

}

export default OfferCard
