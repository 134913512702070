import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import { Routes } from './routes';
import Header from './global/Header';
import Aux from './global/AuxComp';
import { setUser, setLoading, setRole } from '../actions/action-creators';
import Login from './Login';
import { Loader } from './global/Loader';
import { Auth } from '../actions/Auth';
import { getRoleDetails } from '../actions';
import { CONFIGURATION } from '../constants/configuration';
import ReactGA from 'react-ga';
import LcmModule from './views/lcm';
import CollegeProfile from './views/ptTeam/college/college-profile';
import Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route';
import { GlobalErrorbar } from './global/errorbar';
import OtpVerification from './views/OtpVerification';
import DownloadOtpStudents from './views/OtpVerification/download_otp_students';
import { ApplicationForm } from './views/feedback/application';
import GooglePayRegistration from './views/googlePay';
/**
 * @author Akshay Arora
 * @description Root component of the application
 * @class App
 * @extends {Component}
 */
class App extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.trackGA()
    }
  }

  initGA = () => {
    var env_url = process.env.REACT_APP_BASE_URL;
    var tracking_id = env_url == "https://institute.reculta.com" ? 'UA-134045003-2' : 'UA-134045003-3';
    ReactGA.initialize(tracking_id, { debug: false });
  }

  trackGA = () => {
    var GA_USER_ID = this.props.user ? this.props.user.id + '-' + (this.props.user.current_role_id || 'NEW_USER') + '-' + this.props.user.user_key : 'NOT_LOGGED_IN_12345';
    ReactGA.set({ userId: GA_USER_ID })
    ReactGA.pageview(this.props.location.pathname);
  }

  getInitialState = () => {
    return {
      sessionCheck: false
    }
  }
  componentWillMount = async () => {
    await this.getSessionUser();
    await this.initGA()
    await this.trackGA()
  }


  getSessionUser = async () => {
    this.setState({ sessionCheck: true })
    let config = JSON.parse(JSON.stringify(CONFIGURATION))
    try {
      let response = await Auth.User.getUser();
      if (!response.data[0].terms_and_condition || !response.data[0].current_role_info) {
        this.setState({ loader: false })
        this.setState({ sessionCheck: false })
        return false;
      }
      let userInfo = response.data[0];
      userInfo.roles = await getRoleDetails(userInfo.roles)
      let role = userInfo.roles.filter(r => r.assigned_role_id === userInfo.current_role_id)[0];
      if (!role) {
        role = userInfo.roles[0] || { assigned_role_id: 0, role_type: "NONE" }
      }
      await this.props.setRole(role);
      this.props.setUser({ ...response.data[0], config });
    } catch (e) {
      console.log(e)
    }
    this.setState({ sessionCheck: false })
  }


  render = () => {
    let { user, loader, role, error, location } = this.props,
      { sessionCheck } = this.state;
    const userDetail = {
      user,
      role
    }
    if(location.pathname === "/application/feedback")
    return (
      <Aux>
      <Loader show={loader} />
      <Route exact path="/application/feedback" component={ApplicationForm} />
      <GlobalErrorbar />
      </Aux>
    )
    return (
      <Aux>
        {user ?
          <Aux>
            <Loader show={loader} />
            <Header setUser={this.props.setUser} setRole={this.props.setRole} userDetail={userDetail} setLoading={this.props.setLoading} />
            {role.archive_status ? 
              <span className="center-xy">Your access is not active</span>
              :
              <Routes role={role} />
            }
          </Aux> :
          ((sessionCheck || loader) ? <Loader show={sessionCheck || loader}></Loader> :
            <Switch>
              <Route exact path="/student/lcm" component={LcmModule} />
              <Route exact path="/profile/:campus_slugname" component={CollegeProfile} />
              <Route exact path="/signup/verification/googlePay" component={GooglePayRegistration} />
              <Route exact path="/signup/verification" component={OtpVerification} />
              <Route exact path="/signup/verification/:purpose" component={OtpVerification} />
              <Route exact path="/netambit/candidates/list" component={DownloadOtpStudents} />
              <Route exact path="/netambit/candidates/list/:purpose" component={DownloadOtpStudents} />
              <Route component={Login} />
            </Switch>
          )
        }
        <GlobalErrorbar />

      </Aux>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoading: (show) => dispatch(setLoading(show)),
  setRole: (role) => dispatch(setRole(role))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
