import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Aux from './AuxComp';
import { CONSTANTS } from '../../constants';
import { uploadFile } from '../../actions';
import { Auth } from '../../actions/Auth';

export class FileUpload extends Component {
  uploadProof = async (e) => {
    e.persist()
    let { verification, updateData, submitData, toggleLoading } = this.props;
    for (let i = 0; i < Object.keys(e.target.files).length; i++) {
      let file = i.toString();
      if ((e.target.files[file].size) / (1024 * 1024) <= CONSTANTS.FILE_SIZE_LIMIT) {
        toggleLoading(true)
        let newFile = await uploadFile(e.target.files[file], verification.user_id + "/proofs", verification.data.file)
        toggleLoading(false);
        if (newFile) {
          verification.data.file = newFile.file_id
          if (Object.keys(verification.data).indexOf("personal_summary") > -1) {
            updateData({
              target: {
                name: 'personal_info',
                id: 'file',
                value: newFile.file_id
              }
            })
            await submitData();
          } else {
            updateData(verification.data);
            await submitData(null, verification.data)
          }
        }
      } else {
        alert(`File size should be less than ${CONSTANTS.FILE_SIZE_LIMIT} MB`)
      }
    }
    e.target.value = ""
  }

  deleteProof = async (id) => {
    this.props.toggleLoading(true)
    try {
      await Auth.User.deleteFile(id);
      this.props.toggleLoading(false)
      await this.props.submitData(null, this.props.verification.data)
    } catch (e) { }
    this.props.toggleLoading(false)
  }
  render() {
    let { verification } = this.props;
    let docs = verification.files[verification.data.file];
    return (
      <Aux>
        <Row form className="mb-2">
          <Col xs={12}>
            <span className="mr-3 r-heading-7">Upload Proofs</span>
            <div className="r-upload">
              <div className="r-upload__newFile">
                <i className="fi flaticon-upload" />
                <input ref="fileUpload" multiple accept={CONSTANTS.FILE_TYPES} onChange={this.uploadProof} type="file" className="r-upload__upload" />
              </div>
              {/* <div className="r-upload__divider"></div>
              <div className="r-upload__browse">
                <i className="fi flaticon-document" />
                <p className="r-text--sm mb-0">File Manager</p>
              </div> */}
            </div>
          </Col>
        </Row>
        {(docs) && <Row noGutters>
          {docs.map((doc, i) => <Col key={i} className="pr-3" xs="auto">
            <div className="r-tag r-tag--type-1">
              <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.file_name || doc.file_url || "Filename"}</a>
              <button type="button" title="Delete Proof" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.deleteProof(doc.id)}></button>
            </div>
          </Col>)}
        </Row>}
      </Aux>
    )
  }
}