import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import { Row, Col, Spinner } from 'reactstrap'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse } from 'reactstrap';
import { Loader } from '../../../global/Loader';
import { SelectList } from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import { CONSTANTS } from '../../../../constants';
import moment from 'moment';


export class CompanyContact extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      contactData : null
    }
  }

  render() {
    let { sectorList, contactData, isDetailsOpen, deleteContact, editContact } = this.props;
    return (

          <Col xs={3} >
            <div className="card contact-card shadow-sm">
              <div className="r-bg--blue-3 py-3 px-3 w-100 contact-card-header selectable" >
                <h1 className="r-subheading-3 r-text--light mb-0">{contactData.first_name} {contactData.middle_name} { contactData.last_name }</h1>
                <span className="r-text--sm r-text--light">{CONSTANTS.CONTACT_TYPES[contactData.contact_type]}</span>
                <div className="student__profile__actions__btn" >
                  <Button onClick={(e) => editContact(contactData,e)} color="blue" ><i className="fi flaticon-edit"></i></Button>
                  <Button onClick={(e) => deleteContact(contactData.contact_id,e)} color="blue" ><i className="fi flaticon-bin"></i></Button>
                </div>
              </div>

              <div className="card-body contact-card-body" >

              <div>
              <div className="w-100 p-3">
                <h1 className="r-subheading-4 mb-0">Phone</h1>
                { contactData.mobile_detail && contactData.mobile_detail.map( (child, index) =>
                  <span key={index} className="r-text--sm d-block mt-2 r-text--grey-4">{child.mobile_number}</span>
                )}
              </div>
              <div className="w-100 p-3">
                <h1 className="r-subheading-4 mb-0">Email</h1>
                { contactData.email_detail && contactData.email_detail.map( (child, index) =>
                  <span key={index} className="r-text--sm d-block mt-2 r-text--grey-4">{child.email_id}</span>
                  )}
              </div>
              <div className="w-100 p-3">
                <h1 className="r-subheading-4 mb-0"><i className="fi flaticon-linkedin"></i></h1>
                {contactData.linkedin && <a href={contactData.linkedin.indexOf("http") == 0 ? contactData.linkedin : "http://"+contactData.linkedin} target="_blank"><Button color="link-blue" className="r-text--sm">{contactData.linkedin}</Button></a>}
              </div>
              {/*
              <Row  className="w-100 p-3 contact-card-footer">
                <Col xs="auto">
                  <h1 className="r-subheading-4 mb-0"><i className="fi flaticon-comment"></i></h1>
                  <Button color="link-blue" className="r-text--sm">Notes</Button>
                </Col>
                <Col xs="auto">
                  <h1 className="r-subheading-4 mb-0"><i className="fi flaticon-user"></i></h1>
                    <Button color="link-blue" className="r-text--sm">Interaction History</Button>
                </Col>
              </Row>
              */}
              </div>

              </div>

            </div>
            <div className="w-100 text-right"><em className="r-text--sm r-text--grey-4">Added on {moment(contactData.created_on).format("Do MMMM YYYY")}</em></div>
          </Col>

    )
  }
}
