import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  LineChart, Line,BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,LabelList,
} from 'recharts';
import DatePicker from 'react-dates/esm/components/SingleDatePicker';
import { Auth } from '../../../../actions/Auth';
import {SelectList} from '../../../global/Forms/Select'
import { Switch } from '../../../global/Forms/ToggleSwitch';
import { setUser, setLoading } from '../../../../actions/action-creators';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import {Row,Col,Container,Collapse,Table,Pagination,Popover, Card, PaginationItem, PaginationLink, InputGroup, InputGroupAddon,  Input} from 'reactstrap';
import PTTeamNav from '../PTTeamNav';


var monthlist = ["Jan","Feb","Mar","Apr ","May","Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
var day = new Date().getDate();
var month = new Date().getMonth();
const likes = [
  {
    name: 'Likes',
    data: [
      { category: monthlist[month]+","+day, value: 0 },
      { category: monthlist[month]+","+(day+1), value: 5 },
      { category: monthlist[month]+","+(day+2), value: 7 },
      { category: monthlist[month]+","+(day+3), value: 2 },
      { category: monthlist[month]+","+(day+4), value: 8 },
      { category: monthlist[month]+","+(day+5), value: 4 },
      { category: monthlist[month]+","+(day+6), value: 4 },
    ],
  },
];

const views = [
  {
    name: 'Views',
    data: [
      { category: monthlist[month]+","+day, value: 0 },
      { category: monthlist[month]+","+(day+1), value: 10 },
      { category: monthlist[month]+","+(day+2), value: 5 },
      { category: monthlist[month]+","+(day+3), value: 5 },
      { category: monthlist[month]+","+(day+4), value: 24 },
      { category: monthlist[month]+","+(day+5), value: 13 },
      { category: monthlist[month]+","+(day+6), value: 18 },
    ],
  },
];


const data1 = [
  { name: '0-12', value: 400 },
  { name: '12-24', value: 300 },
  { name: '24-36', value: 200 },
];



 class PTFeedDetails extends Component{
  constructor(props){
    super(props);
    this.state = {
      getProcesList:  [],
      getStudentAssigned: [],
      getStudentProfileDetails: [],
      isOn: false,
      modalstudent: false,
      typeList: "Like",
      modalshare:false,
      total_students: null,
      modal: false,
      modaledit: false,
      collapse: true,
      postcolapse: false,
      popoverOpen: false,
      popoverOpen2: false,
      process:{process_name: "Student Feed",process_type: "feed",process_id: 3},
    };
  }

  onChange = e => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }

  componentDidMount () {
    this._init();
  }

  _init = async() =>{
      let {batchdata} = this.state;
    this.setState({setloading: true});
     try {
      let count = 0;
      let response = await Auth.PtStudents.getBatch();
      response.data.forEach(data=>{
        count += data.student_info.length
      })
      this.setState({total_students: count});
    } catch (e) {
      console.log(e)
       }
  }



   toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  }

    handleToggle(e) {
      this.setState({isOn: !this.state.isOn});
    }

    handlepostToggle = (e) => {
        this.setState(state => ({ postcolapse: !state.postcolapse }));
    }

    togglePopOver1 = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

    togglePopOver2 = () => {
    this.setState({
      popoverOpen2: !this.state.popoverOpen2
    });
  }


  render(){
    let {data, files} = this.props;
    const{ typeList,total_students } = this.state;
      return (
      	<Col xs="6" className="align-items-center mb-3" style={{backgroundColor:"#0066CC" , borderRadius : "5px"}}>
	          <Row className="justify-content-between align-items-center m-0">
	            <span className="r-subheading-1 r-text--light">Post Statistics</span>
	            <Button onClick={(e) => this.props.deletepost(data.post_id)}> <i  className="d-inline-block fi flaticon-bin r-text--light" style={{fontSize:"20px"}}/> </Button>
	          </Row>
	         {/* <Row className="justify-content-end align-items-center m-0">
	         	                   <SelectList selected="Latest Added" className="d-inline-block r-text--light">
	         	                      <li className="d-inline-block r-text--dark">Latest Added</li>
	         	                      <li className="d-inline-block r-text--dark">Deadline</li>
	         	                       <li className="d-inline-block r-text--dark">Date Announced</li>
	         	                      <li className="d-inline-block r-text--dark">CTC</li>
	         	                   </SelectList>
	         	          </Row>*/}
	          <Row className="justify-content-around align-items-center my-3">
	             <Row className="post px-2" style={{backgroundColor:"#799fea"}}>
	                <i className="mr-3 r-text--sm r-text--skyblue fi flaticon-eye" style={{fontSize:"40px"}}/>
	                <Col xs="auto">
	                    <Row><span className="r-subheading-2 r-text--light">{data.no_of_seen} / {total_students}</span></Row>
	                    <Row> <span className="r-subheading-2 r-text--light">Total Views</span></Row>
	                 </Col>
	             </Row>
	           {/*  <Row className="post px-2" style={{backgroundColor:"#799fea"}}>
                               <i className="mr-3 r-text--sm r-text--skyblue fi flaticon-eye" style={{fontSize:"40px"}}/>
                               <Col xs="auto">
                                   <Row><span className="r-subheading-2 r-text--light">20/{total_students}</span></Row>
                                   <Row> <span className="r-subheading-2 r-text--light">Button clicks</span></Row>
                                </Col>
                            </Row>  */}
	             <Row className="post px-2" style={{backgroundColor:"#799fea"}}>
	                <i className="mr-3 r-text--sm r-text--skyblue fi flaticon-like" style={{fontSize:"40px"}}/>
	                <Col xs="auto">
	                    <Row><span className="r-subheading-2 r-text--light">{data.no_of_like} / {total_students}</span></Row>
	                    <Row> <span className="r-subheading-2 r-text--light">Total Likes</span></Row>
	                 </Col>
	             </Row>
	          </Row>
	          {/* <Col xs="auto" className=" my-3">
				     <select value={typeList} name="typeList" className="d-inline-block r-text--light my-3" style={{backgroundColor: "rgb(0, 102, 204)"}}  onChange={this.onChange}>
				      		<option  className="d-inline-block r-text--dark" value="Like">Like</option>
				      		<option  className="d-inline-block r-text--dark" value="View">View</option>
				     </select>
	               { (typeList === "Like")?<LineChart width={500} height={300}   >
	               	                    <CartesianGrid  vertical={false} verticalFill={[ '#799fea']} />
	               	                    <XAxis dataKey="category" stroke="#FFF" />
	               	                    <YAxis dataKey="value" stroke="#fff" />
	               	                    {likes.map(s => (
	               	                    <Line stroke="#fff" dataKey="value" data={s.data} name={s.name} key={s.name}  dot={false} />
	               	                    ))}
	               	                </LineChart>:
	               	                <LineChart width={500} height={300}   >
	               	                    <CartesianGrid  vertical={false} verticalFill={[ '#799fea']} />
	               	                    <XAxis dataKey="category" stroke="#FFF" />
	               	                    <YAxis dataKey="value" stroke="#fff" />
	               	                    {views.map(s => (
	               	                    <Line stroke="#fff" dataKey="value" data={s.data} name={s.name} key={s.name}  dot={false} />
	               	                    ))}
	               	                </LineChart>}
	          </Col> */}
	         {/* <Col xs="auto" className=" my-3">
	         	                <span className="r-subheading-1 r-text--light">Polls</span>
	         	                <Col xs="auto" className=" my-3" style={{backgroundColor:"#fff"}}>
	         	                    <Row className="justify-content-start align-items-center  borderBottomlightblack ">
	         	                        <span  className="r-subheading-2 r-text--blue-3 ml-5 my-3">Add to group</span>
	         	                        <span  className="r-subheading-2 r-text--blue-3 ml-5 my-3">Create an Event</span>
	         	                    </Row>
	         	                    <BarChart
	         	                          width={500}
	         	                          height={100}
	         	                          data={data1}
	         	                          layout="vertical"
	         	                          margin={{top: 5, right: 30, left: 20, bottom: 5}}
	         	                        >
	         	                          <XAxis type="number" hide/>
	         	                          <YAxis type="category" dataKey="name" hide/>
	         	                          <Bar barSize={10} dataKey="value" fill="#0072BC" />
	         	                    </BarChart>
	         	                </Col>
	         	          </Col>*/}
        </Col>
  	)
 }
}

export default PTFeedDetails;
