import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import moment from 'moment';
import { CONSTANTS } from '../../../../../constants';

export class ApplicationStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deadline: props.application.application_deadline,
      deadLineCounter: moment.duration(moment(props.application.application_deadline).diff(moment(), "milliseconds"))
    }
  }

  componentWillMount() {
    this.interval = setInterval(this.getDeadline, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getDeadline = () => {
    let deadLineCounter = moment.duration(moment(this.state.deadline).diff(moment(), "milliseconds"))
    this.setState({ deadLineCounter })
  }

  render() {
    let { deadLineCounter } = this.state;
    let { application } = this.props;
    let shortlisting_data = application.shortlisting_data.filter(data=>data.locked_status>0)
    let status = shortlisting_data.length > 0 ? shortlisting_data[shortlisting_data.length - 1].locked_status : 0;
    if (!status) {
      if (!application.student_application_status) {
        if (application.eligibility_status === 1)
          status = 6;
        else
          status = 7
      } else {
        status = application.student_application_status + 3
      }
    }
    let isClosed = deadLineCounter.asSeconds() <= 0;
    return (
      <div className="apply__jobs__status" style={{...(isClosed || application.is_blocked ? {backgroundColor: "#bbb"}:{})}}>
        <Row noGutters className="justify-content-between">
          <Col xs="auto">
              {application.is_recruitment_closed ? "Recruitment Completed" : (!isClosed ? "Applications open" : "Applications Closed")}          
          </Col>
          <Col xs="auto">
            {application.isShortListActionRequired && <span className="bg-light text-danger px-3 r-text--sm mr-2" style={{borderRadius: 10}}>*Action required</span>}
            {application.is_blocked ? <span className="r-radius--1 px-2 r-bg--dark">Blocked</span> : 
            CONSTANTS.APPLICATION_STATUS[status] && <span className={`${CONSTANTS.APPLICATION_STATUS[status].CLASS} r-radius--1 px-2`} >{CONSTANTS.APPLICATION_STATUS[status].NAME}</span>}
          </Col>
        </Row>
        <Row noGutters className="text-center">
          <Col className="r-bg--light px-2 rounded-left">
            <span className="r-text--dark">{moment(application.published_on).format("hh:mm a, Do MMM YYYY")}</span>
          </Col>
          <Col className="r-bg--blue-2  px-2 rounded-right">
            <span className="r-subheading-4 r-text--brandF">{`${deadLineCounter.asDays() < 0 ? 0 : Math.floor(deadLineCounter.asDays())}d:${deadLineCounter.hours() < 0 ? 0 : deadLineCounter.hours()}h:${deadLineCounter.minutes() < 0 ? 0 : deadLineCounter.minutes()}m:${deadLineCounter.seconds() < 0 ? 0 : deadLineCounter.seconds()}s`}</span>
          </Col>
        </Row>
      </div>
    )
  }
}
