import React, { Component } from 'react';
import { Layout } from './layout';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Collapse from 'reactstrap/lib/Collapse';
import { generateInlineStyles } from '../../../actions';
import { TEMPLATE_SETTINGS } from '../../../constants/template-1';
import Button from '../../global/Button';

export class CVPreview extends Component {
  constructor(props) {
    super(props);
    this.draggedOverItem = -1
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      docStyle: {},
      editable: "",
      cv_grid:[ { "size":12, "config":{"background":"#ffffff", "width":"100%"} } ],
      hidden: ""
    }
  }

  componentDidMount() {
    let { config } = this.props
    this.scaleCV();
    window.addEventListener('resize', this.scaleCV)
    document.addEventListener('click', this.resetEditableSection)
    if (config && config.fontSrc) {
      this.fontSrc = document.createElement("link")
      this.fontSrc.rel = "stylesheet"
      this.fontSrc.href = config.fontSrc
      document.head.appendChild(this.fontSrc)
    }
  }

  componentWillUnmount() {
    if(this.fontSrc) {
      this.fontSrc.remove()
    }
    window.removeEventListener('resize', this.scaleCV)
    document.removeEventListener('click', this.resetEditableSection)
  }

  componentWillReceiveProps() {
    this.scaleCV()
  }

  resetEditableSection = (e) => {
    let el = e.target.closest(".cv-builder__doc") || e.target.closest('.cv-builder__toolbar') || e.target.closest(".cv-builder__doc__tools");
    if (!el)
      this.editSection("")
  }

  scaleCV = () => {
    let { cvDoc } = this.refs,
      docStyle = {};
    if (cvDoc) {
      let scale = (cvDoc.parentNode.clientWidth - 30) / 800;
      if (scale < 1)
        docStyle["transform"] = `scale(${scale})`;
      if (cvDoc.parentNode.className.indexOf("modal-content") < 0)
        cvDoc.parentNode.style.height = cvDoc.getBoundingClientRect().height + "px";
    }
    this.setState({
      docStyle
    })
  }

  getTemplateSettings = () => {
    let { config } = this.props,
      settings = {};
    if (config) {
      Object.keys(config.settings).forEach(setting => {
        let settingOptions = TEMPLATE_SETTINGS[setting],
          configValue = config.settings[setting] || 0;
        if (settingOptions && settingOptions.propNames) {
          settingOptions.propNames.forEach(propName => {
            if (settingOptions.initial)
              settings[propName] = (settingOptions.initial || 0) + configValue + "px"
            else
              settings[propName] = configValue
          })
        }
      })
    }
    return settings;
  }


  onDragStart = (e, index) => {
    this.draggedItem = this.props.sections[index];
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  }

  onDragOver = index => {
    document.querySelectorAll(".cv-builder__doc__section").forEach(el => el.style.boxShadow = "");
    const draggedOverItem = this.props.sections[index];
    if (this.draggedItem === draggedOverItem) {
      this.draggedOverItem = -1
      return;
    }
    let secEl = document.getElementById(draggedOverItem)
    secEl.style.boxShadow = "0px 0px 5px 3px #bfbfbf";
    this.draggedOverItem = index
  }
  onDragEnd = () => {
    if (this.draggedOverItem > -1) {
      let sections = this.props.sections.filter(item => item !== this.draggedItem);
      sections.splice(this.draggedOverItem, 0, this.draggedItem);
      this.props.reOrderSections(sections);
    }
    document.querySelectorAll(".cv-builder__doc__section").forEach(el => el.style.boxShadow = "");
    this.draggedItem = null;
    this.draggedOverItem = null;
  }

  editSection = (sec) => {
    this.setState({
      editable: sec
    })
  }
  toggleSection = (sec, value) => {
    let { config } = this.props;
    config.section_config[sec].visible = value
    this.props.updateCVConfig(config);
    this.toggleHidden("")
  }
  toggleHidden = (sec) => {
    this.setState({
      hidden: sec !== this.state.hidden ? sec : ""
    })
  }

  render = () => {
    let { sections, data, config, preview, verification, showUnverified, enableDrag,cv_type } = this.props,
      { docStyle, editable, hidden,cv_grid } = this.state,
      settings = this.getTemplateSettings()
    docStyle = Object.assign({}, docStyle, settings);
    cv_grid=config.cv_grid?config.cv_grid:cv_grid;
    if(config.cv_grid){ docStyle.padding = '0px 2px 0px 0px';settings.padding= '0px 2px 0px 0px';}
    return (
      <Row ref="cvDoc" className={`cv-builder__doc mx-0 ${config.doc_class || ""} ${preview ? "preview" : ""} ${showUnverified ? "" : "only-verified"}`} style={preview ? settings : docStyle}>
        {
          cv_grid.map((col, j) =>{
            let customeStyle ={};
            if(true&&(cv_type==="CUSTOM")){
                // customeStyle = {"overflow":"hidden", "maxHeight": "1115px",}
            }
            return(
              <div key={j} style={(true&&(cv_type==="CUSTOM")) ? { ...col.config , ...customeStyle} : {...col.config}}>
              {
                sections.map((sec, i)=>{
                  let direction = (config.section_config[sec].dir !== undefined)?config.section_config[sec].dir:0;
                  if(direction === j){
                      const { section_layout, visible } = config.section_config[sec];
                      let wrapperProps = {},
                        overlayProps = { className: "editable" }
                      if (!preview && (editable !== sec) && enableDrag) {
                        wrapperProps = {
                          onDragOver: () => this.onDragOver(i)
                        }
                        overlayProps = {
                          draggable: enableDrag,
                          onDragEnd: this.onDragEnd,
                          onDragStart: (e) => this.onDragStart(e, i)
                        }
                      }
                      return (
                        <div id={sec} className={`cv-builder__doc__section ${visible === "0" ? "cv-builder__doc__hidden" : ""} ${sec==='footer' ? "footer":""}`} key={sec} {...wrapperProps} onClick={() => this.editSection(sec)}>
                          <span onClick={() => this.toggleHidden(sec)}></span>
                          <div className="cv-builder__doc__tools">
                            {visible === "0" ? <Button className="r-heading-4" onClick={() => this.toggleSection(sec, "1")}><i className="fi flaticon-eye r-text--brandD" /></Button> : <Button className="hide-btn" onClick={() => this.toggleSection(sec, "0")}><i className="fi flaticon-hidden r-text--brandB" /></Button>}
                          </div>
                          <Collapse isOpen={(visible === "1" || hidden === sec)}>
                            <Col xs={12} {...overlayProps} data-configpath={`${sec}.section_layout.config`} style={generateInlineStyles(section_layout.config)}>
                              <div className="d-flex justify-content-center align-items-center cv-builder__doc__overlay">
                                <Row>
                                  <Col xs={12}>
                                    <h3>Click to Edit OR Drag to Reorder</h3>
                                  </Col>
                                </Row>
                              </div>
                              <Layout editCVData={this.props.editCVData} verification={verification} preview={preview} editCVPoint={this.props.editCVPoint} updateCVConfig={this.props.updateCVConfig} section_layout={section_layout} root={sec} config={config} data={data} />
                            </Col>
                          </Collapse>
                        </div>
                      )
                  }
                })
              }
              </div>
              )
          })
        } 
      </Row>
    )
  }
}