import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Collapse from 'reactstrap/lib/Collapse';
import { InputField } from '../../../../global/Forms';
import { ProfileActions } from './profile-actions';
import Button from '../../../../global/Button';
import { Auth } from '../../../../../actions/Auth';
import Aux from '../../../../global/AuxComp';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { CONSTANTS } from '../../../../../constants';
import { uploadFile, groupCVHeaders } from '../../../../../actions';
import { RichTextViewer } from '../../../../global/Forms/RichTextViewer';
import { RTE } from '../../../../global/Forms/RTE';
import { ReactComponent as Badge } from '../../../../../assets/icons/badge.svg';
import { Errorbar } from '../../../../global/errorbar';
import store from '../../../../../store';
export class CVPoint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: "",
      modalShow: false,
      deleteId: null,
      originalData: null,
      sortedIds: [],
      errorbar: false,
      message: ''
    }
    this.RTEProps = {
      modules : {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{'list': 'bullet'}],
          [{ align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [{'letterspacing': ["-0.5px","0","0.5px"]}],
          [{ 'script': 'sub'}, { 'script': 'super' }]
        ],
      },

      formats : [
        'bold', 'italic', 'underline','list', 'bullet', 'align','letterspacing','script'
      ]
    }
  }


  componentDidMount() {
    let { cvPoints } = this.props;
    // this.setState({
    //   sortedIds: cvPoints.sort(this.headerSort).map(cv => cv.cv_point_id)
    // })
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  cancel = () => {
    let { originalData } = this.state;
    if (originalData) {
      if (typeof originalData.cv_point_id === "string") {
        this.props.changeCVPoint(originalData, true)
      } else
        this.props.changeCVPoint(originalData)
      this.setState({
        originalData: null
      })
      this.toggleSection();
    }
  }

  toggleSection = (cv) => {
    let { opened } = this.state;
    let cvPoint = this.props.cvPoints.filter(cv => cv.cv_point_id === opened)[0]
    if (cvPoint && opened && typeof opened === "string") {
      if (!cvPoint.text && !cvPoint.header)
        this.props.changeCVPoint(cvPoint, true)
    }
    this.setState({
      opened: cv ? (opened !== cv.cv_point_id ? cv.cv_point_id : "") : "",
      originalData: cv ? Object.assign({}, cv) : null
    })
  }

  handleCVChange = (e, cv) => {
    cv[e.target.name] = e.target.value;
    this.props.changeCVPoint(cv);
  }

  handleCVPointSubmit = async (e, cv) => {
    if (e) e.preventDefault();
    let text = document.createElement('div')
    text.innerHTML = cv.text
    if (cv.header.trim() === "") {
      this.setState({
        message: "CV Header is mandatory",
        errorbar: true
      })
      return
    }
    if (text.textContent.trim() === "") {
      this.setState({
        message: "CV Text is mandatory",
        errorbar: true
      })
      return
    }
    let formData = Object.assign({}, cv),
      method = Auth.Student.updateCVPoint;
    if (typeof cv.cv_point_id === "string") {
      method = Auth.Student.createCVPoint;
      delete formData.cv_point_id;
    }
    this.props.toggleLoading(true)
    try {
      await method(formData)
      this.props.toggleLoading(false)
      await this.props.getStudentProfile()
      if (e) this.toggleSection()

    } catch (e) {
      this.props.toggleLoading(false)
      if (e.response.data.error.toString() !== null) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
    // this.setState({
    //   sortedIds: this.props.cvPoints.sort(this.headerSort).map(cv => cv.cv_point_id)
    // })
  }

  addCVpoint = () => {
    let { type, user_info, profile_id } = this.props,
      newId = "CV_" + Math.ceil(Math.random() * 10000),
      cv = {
        student_id: user_info.student_id,
        profile_id,
        profile_type: type,
        header: "",
        text: ""
      };
    this.props.changeCVPoint(cv, false, newId)
    this.toggleSection({ ...cv, cv_point_id: newId })
  }

  deleteCVPoint = async () => {
    let { deleteId } = this.state;
    let { user_info } = this.props;
    if (!deleteId) return;
    let formData = {
      student_id: user_info.student_id,
      cv_point_id: deleteId,
      status: 0
    }
    this.props.toggleLoading(true)
    try {
      if (typeof deleteId === "number")
        await Auth.Student.updateCVPoint(formData)
      this.props.changeCVPoint(formData, true)
      this.toggleModal()
      this.props.toggleLoading(false)
      await this.props.getStudentProfile()
    } catch (e) {
      this.props.toggleLoading(false)
      if (e.response.data.error.toString() !== null) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
  }

  toggleModal = (id) => {
    this.setState({
      modalShow: !this.state.modalShow,
      deleteId: id
    })
  }

  uploadProof = async (e, cv) => {
    e.persist();
    let { user_info, toggleLoading } = this.props;
    for (let i = 0; i < Object.keys(e.target.files).length; i++) {
      let file = i.toString();
      if ((e.target.files[file].size) / (1024 * 1024) <= CONSTANTS.FILE_SIZE_LIMIT) {
        toggleLoading(true)
        let newFile = await uploadFile(e.target.files[file], user_info.user_id + "/proofs", cv.file)
        toggleLoading(false);
        cv.file = newFile.file_id
        this.props.changeCVPoint(cv);
        await this.handleCVPointSubmit(null, cv)
      } else {
        alert(`File size should be less than ${CONSTANTS.FILE_SIZE_LIMIT} MB`)
      }
    }
    e.target.value = ""
  }
  deleteProof = async (id, cv) => {
    this.props.toggleLoading(true)
    try {
      await Auth.User.deleteFile(id);
      this.props.toggleLoading(false)
      await this.handleCVPointSubmit(null, cv)
    } catch (e) {
      if (e.response.data.error.toString() !== null) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
    this.props.toggleLoading(false)
  }

  headerSort = (a, b) => {
    if (a.header < b.header)
      return -1
    if (a.header > b.header)
      return 1
    return 0
  }

  renderCVPoints = (cvPoints, sortedIds) => {
    let headers = groupCVHeaders(cvPoints)
    let points = [];
    Object.keys(headers).forEach(header=>{
      points = points.concat(headers[header])
    });
    return points
  }

  render() {
    let { cvPoints, files, commentThreads } = this.props,
      { opened, sortedIds } = this.state,
      points = this.renderCVPoints(cvPoints);
      let studentInfo = store.getState().user.studentInfo;
      studentInfo = studentInfo && studentInfo.length ? studentInfo[0] : {};
    return (
      <Aux>
        <ul className="list-unstyled">
          {
            points.map((cv, i) => {
              let docs = files[cv.file];
              let isSameHeader = false;
              if (i > 0 && cv.header && points[i - 1].header === cv.header)
                isSameHeader = true;
              return (
                <li key={cv.cv_point_id} className={"cv-point" + (cv.freeze_status == 1 ? " student__profile--freeze" : "")}>
                  <Collapse isOpen={opened !== cv.cv_point_id}>
                    <Row noGutters className="align-items-center">
                      <Col xs={1} className="pr-4 align-self-start">
                      </Col>
                      <Col xs={11}>
                        {!isSameHeader && <span className="r-subheading-3">{cv.header || "CV Header Here"}</span>}
                        {cv.year !== null && <div className="text-right"><strong>{cv.year}</strong></div>}
                      </Col>
                    </Row>
                    <Row noGutters>
                      <Col xs={1} className="mt-2 pr-4 align-self-start">
                        {/* <img src="/images/achievement.png" className="img-fluid" /> */}
                        <Badge />
                      </Col>
                      <Col xs={11}>
                        <RichTextViewer>{cv.text || "CV Point Here"}</RichTextViewer>
                      </Col>
                    </Row>
                  </Collapse>
                  <Collapse isOpen={opened === cv.cv_point_id}>
                    <form className="p-1 rounded" onSubmit={(e) => this.handleCVPointSubmit(e, cv)}>
                      <Row form>
                        <Col xs={6}>
                          <InputField name="header" onChange={(e) => this.handleCVChange(e, cv)} placeholder="*Type CV Header" className="rounded-top w-100 cv_header_input" type="text" value={cv.header || ""} />
                          {/* <textarea name="text" onChange={(e) => this.handleCVChange(e, cv)} placeholder="Type CV Point" value={cv.text || ""} className="rounded-bottom border-top-0 w-100 r-inputfield"></textarea> */}
                        </Col>
                        <Col xs={6}>
                          <InputField className="rounded-top w-100 cv_header_input" type="text" onChange={(e) => this.handleCVChange(e, cv)} name="year" value={cv.year} placeholder="Year" />
                        </Col>
                      </Row>
                      <Row form>
                        <Col xs={12}>
                          <RTE onChange={(value) => this.handleCVChange({
                            target: {
                              name: "text",
                              value
                            }
                          }, cv)} hideToolbar={true} placeholder="*Type CV Point" value={cv.text || ""} {...this.RTEProps}/>
                        </Col>
                      </Row>
                      <Row form className="mt-3 justify-content-between">
                        <Col xs={1}>
                          {typeof cv.cv_point_id !== "string" && <Aux><Button type="button" className="cv-point__upload"><i className="fi flaticon-upload" /></Button>
                            <input ref="fileUpload" multiple accept={CONSTANTS.FILE_TYPES} className="r-upload__upload" type="file" onChange={(e) => this.uploadProof(e, cv)} /></Aux>}
                        </Col>
                        <Col xs={6} className="text-right">
                          <Button className="r-text--sm r-btn--no-radius" onClick={this.cancel} type="button">Cancel</Button>
                          {(this.props.settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id] && this.props.settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id].cv_builder===1)&&<Button type="button" onClick={() => this.props.toggleCV()} className="r-text--sm">Preview</Button>}
                          <Button className="r-text--sm r-btn--no-radius" color="dark" type="submit">Save</Button>
                        </Col>
                      </Row>
                    </form>
                  </Collapse>
                  {(docs) && <Row className="mt-2" noGutters>
                    {docs.map((doc, i) => <Col key={i} xs="auto" className="pr-3">
                      <div className={"r-tag r-tag--type-1" + (opened === cv.cv_point_id ? "" : " r-tag--no-delete")}>
                        <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.file_name || doc.file_url || "Filename"}</a>
                        {opened === cv.cv_point_id && <button type="button" className="r-tag__delete fi flaticon-plus-1" title="Delete Proof" onClick={() => this.deleteProof(doc.id, cv)}></button>}
                      </div>
                    </Col>)}
                  </Row>}
                  {opened !== cv.cv_point_id && <ProfileActions delete={() => this.toggleModal(cv.cv_point_id)} verification={{ ...cv }} toggleSection={() => this.toggleSection(cv)} commentThreads={commentThreads} isRequiredValidate profileType="CV_POINT" />}
                </li>
              )
            })
          }
          <li className="cv-point cv-point--add" onClick={this.addCVpoint}>
            <Row noGutters className="align-items-center">
              <Col xs={1} className="pr-4">
                {/* <img src="/images/achievement.png" className="img-fluid" /> */}
                <Badge />
              </Col>
              <Col xs={11}>
                + Add CV Point
            </Col>
            </Row>
          </li>
        </ul>
        <Modal className="modal--delete" isOpen={this.state.modalShow} toggle={() => this.toggleModal()}>
          <ModalBody>
            <p>Are you sure?</p>
            <div className="text-right">
              <Button className="r-text--sm" onClick={() => this.toggleModal()}>Cancel</Button>
              <Button color="dark" onClick={this.deleteCVPoint} className="r-text--sm r-btn--no-radius">Delete</Button>
            </div>
          </ModalBody>
        </Modal>
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
      </Aux>
    )
  }
}
