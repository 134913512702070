import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import {Collapse } from 'reactstrap';
import { Loader } from '../../global/Loader';
import Button from '../../global/Button';
import { Auth } from '../../../actions/Auth';
import store from '../../../store';
import { Errorbar } from '../../global/errorbar';
import {Confirmation} from '../../global/confirmation';



export default class RoleMasterList extends Component {
  constructor(props) {
    super(props);
  }

  state={
      isOpen : false,
      compusRoles : [],
      editRoleid : null,
      deleteRoleId: false,
      isConfirmation: false,
      errorbar :  false,
      message : null
  }

  componentDidMount(){
    this.init();
  }

  init = async ()=>{

    try {

        this.props.loader(true)

        let  { campus_id} = this.props.accessLevel_ids

        if(!campus_id){
            this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});
        }

        let apiRespCampusRoles = await Auth.CampusRoles.getRoles(campus_id)
        let campusRoles = apiRespCampusRoles.data;
        let defaultOpenCollapse = false 
        if(!campusRoles.length){
            defaultOpenCollapse =  true
        }
        await this.setState({campusRoles, isOpen: defaultOpenCollapse})
        this.props.loader(false)

    } catch (e) {
        console.log(e)
    }
    
  }

  addCampusRole = async (e)=>{
            
    e.preventDefault();
    e.persist()

    let {roleName} = e.target
    let {campus_id} = this.props.accessLevel_ids

    if(roleName.value == ''){
        this.setState({errorbar: true, message:"Please enter role title"});
        return
    }

    if(!campus_id){
        this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});
        return
    }    

    let addRoleformData = {
        "campus_id":campus_id,
        "role_name":roleName.value
    }
     

    this.props.loader(true)

    try {
        let apiRespCreateRoles = await Auth.CampusRoles.createRole(addRoleformData)
        this.init()        
        this.setState({ isOpen: false })
        e.target.reset()
    } catch (e) {
        console.log(e)
        this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});
    }   
    this.props.loader(false)
  }

  updateRole = async (e)=>{

        e.preventDefault()
        e.persist()

        let {editRoleid} = this.state
        let {roleName} = e.target

        if(roleName.value == ''){
            this.setState({errorbar: true, message:"Please enter role title"});
            return
        }

        if(!editRoleid){      
            this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});
            return
        }

        let roleUpdateFormData = {
            role_name: roleName.value,
            campus_role_id: editRoleid            
        }

        try {

            this.props.loader(true)
            let apiRespUpdateRole = await Auth.CampusRoles.editRole(roleUpdateFormData) 
            this.init()
            this.setState({editRoleid:null, isOpen:false})
            this.props.loader(false)

        } catch (e) {
            console.log(e)
            this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});
        }

    }

    inactiveRole =  async (id)=>{

        let {deleteRoleId} = this.state
        if(!deleteRoleId){
            this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});
            return
        }

        let activeInactiveformData = {
            campus_role_id : deleteRoleId
        }

        try {
            this.props.loader(true)
            let apiRespActiveInactive = await Auth.CampusRoles.inactiveRole(activeInactiveformData)  
            this.init()          
            this.setState({deleteRoleId:false, isConfirmation:false})
            this.props.loader(false)
        } catch (e) {
            console.log(e)
            this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});
        }

    }  


    toggleCollapse =()=>{
        let {isOpen} =  this.state
        this.setState({isOpen: !isOpen, editRoleid: null})
    }

    toggleEditRole = (id)=>{
        this.setState({editRoleid : id, isOpen : false})
    }

    closebar = () =>{
        this.setState({errorbar: false});
    }

    toggleConfirmation = () => {

        this.setState({isConfirmation : false})
    }

    handleDelete =  (deleteRoleId)=>{
       this.setState({deleteRoleId, isConfirmation:true})      

    }
   


  render() {

    let {accessLevel_ids} = this.props.accessLevel_ids
    let {isOpen, campusRoles, editRoleid, errorbar, message, isConfirmation} = this.state

    return (
      <div className="role p-2" >
        <Row>
            <Col xs={10}>

            </Col>
            <Col xs={2}>
                <h3 className="r-heading-4 text-right" onClick={this.toggleCollapse}>
                <button className="r-text--sm r-btn r-btn--brandB" color="brandB">
                    <i className="fi flaticon-plus mr-2"></i> New 
                </button>
                </h3>
            </Col>
        </Row>
        <div className="campus-roles m-3">
            <Collapse isOpen={isOpen}>
                <p>Add Role</p>
                <form onSubmit={this.addCampusRole} className="border-bottom">
                    <Row className="mb-3">
                        <Col xs={8}>
                            <input type="text" name="roleName" className="w-100 r-inputfield"
                            placeholder="Type role name here" required={true}/>
                        </Col>
                        <Col xs={4}>
                            <div className="text-right">
                                <button  className="r-btn r-btn--dark-v r-btn--no-radius" type="submit">Save</button>
                                <Button type="button" onClick={this.toggleCollapse}>Cancel</Button>
                            </div>
                        </Col>
                    </Row>
                </form>                      
            </Collapse>
        </div>
        <div className="role-list m-3">
            
            {
                campusRoles ?

                campusRoles.map(data=>{                   

                    return(

                        <div className="border-bottom my-3" key={data.campus_role_id}>

                                <Collapse isOpen={ data.campus_role_id == editRoleid? false: true}>  
                                    <Row>
                                        <Col xs={10}>                                               
                                            <h1 className="r-subheading-3">{data.role_name} </h1>
                                        </Col>
                                        <Col xs={2}>
                                            <div className="text-right">
                                                <button className="p-2 r-btn" onClick={()=>this.toggleEditRole(data.campus_role_id)}>
                                                    <i className="fi flaticon-edit"></i>
                                                </button>
                                                <button className="p-2 r-btn" 
                                                    onClick={()=> this.handleDelete(data.campus_role_id)} >
                                                    <i className="fi flaticon-bin"></i>
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Collapse>

                            
                                <Collapse isOpen={ data.campus_role_id == editRoleid}>
                                    <form className="my-3" onSubmit={this.updateRole}>
                                        <Row>
                                            <Col xs={8}>
                                                <input type="text" defaultValue={data.role_name}  name="roleName" className="w-100 r-inputfield" placeholder="Type role name here" />                                          
                                            </Col>
                                            <Col xs={4}>
                                                <div className="text-right">
                                                    <button type="submit" className="r-btn r-btn--dark-v r-btn--no-radius">Save</button>
                                                    <button type="button" className="r-btn" onClick={()=>this.toggleEditRole(false)}>Cancel</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </Collapse> 
                        </div>

                    )

                })

                :
                null
            }
        </div>
        <Errorbar showBar={errorbar} onClose={this.closebar} message={message}/>
        <Confirmation isOpen={isConfirmation} toggleConfirmation={this.toggleConfirmation} 
                            callback={this.inactiveRole}/>
      </div>
    );
  }
}