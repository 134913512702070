import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from '../../../actions/Auth';
import {Row,Col,Container, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import Button from '../../global/Button';
import { SelectList } from '../../global/Forms/Select';
import { InputField } from '../../global/Forms';
import { Switch } from '../../global/Forms/ToggleSwitch';
import AddCollegeCollapse from './add_college_collapse';
import ShowCollege from './show_college';
import AddMember from './add_member';
import MemberListChild from './member_list_child';
import AutosuggestComp from '../../global/AutosuggestComp';

class DepartmentModel extends Component{
  constructor(props){
    super(props);
    this.state = { isSpinnerLoading : false, departmentList : [], addDepartment : false};
  }

  componentDidMount(){
    this.init()
  }

  init = async() => {
    this.setState({isSpinnerLoading : true})
    var campus_id = this.props.isDepartmentModel.campus_id;
    try{
      var resp = await Auth.Spoc.getDegreeDepartment({campus_id});
      var departmentList = resp.data;
      departmentList = departmentList.filter(data => data.campus_degree_id == this.props.isDepartmentModel.campus_degree_id)
      departmentList = departmentList.length > 0 ? departmentList[0].departments : [];
      this.setState({departmentList, isSpinnerLoading : false})
    }
    catch(e){
      this.setState({isSpinnerLoading : false})
      if(e.response.data.error.toString() !== null)
        this.props.setError(true, e.response.data.error.toString())
      else
        this.props.setError(true, 'Sorry! something went wrong. Please try again')
    }
  }

  deleteDeparment = async(degree_department_id) => {
    this.setState({isSpinnerLoading : true})
    try{
      var body = {
        degree_department_id,
        status : 0
      }
      await Auth.Spoc.inactiveDegreeDepartment(body)
      this.init()
    }
    catch(e){
      this.setState({isSpinnerLoading : false})
      if(e.response.data.error.toString() !== null)
        this.props.setError(true, e.response.data.error.toString())
      else
        this.props.setError(true, 'Sorry! something went wrong. Please try again')
    }
  }
  handleChangeAdd =(key, value) => {
    let { addDepartment } = this.state
    addDepartment[key] = value;
    this.setState({addDepartment})
  }

  addDepartmentSubmit = async(e) => {
    e.preventDefault();
    let { addDepartment } = this.state
    if(!this.props.isDepartmentModel.campus_degree_id || !(addDepartment.department_id || addDepartment.department_name) ){
      this.props.setError(true, 'Invalid Department')
      return false
    }
    this.setState({isSpinnerLoading : true})
    try{
      var body = {
        department_id : addDepartment.department_id,
        campus_degree_id : this.props.isDepartmentModel.campus_degree_id
      }
      if(addDepartment.department_name){
        var staticDepartment = await Auth.Spoc.addStaticDepartment({
                              department_name: addDepartment.department_name
                          })
        body.department_id = staticDepartment.data.insertId
      }
      if(!body.department_id){
        this.props.setError(true, 'Invalid Department')
        return false
      }
      await Auth.Spoc.addDegreeDepartment(body)
      this.setState({addDepartment : false})
      this.init()
    }
    catch(e){
      this.setState({isSpinnerLoading : false})
      if(e.response.data.error.toString() !== null)
        this.props.setError(true, e.response.data.error.toString())
      else
        this.props.setError(true, 'Sorry! something went wrong. Please try again')
    }
  }

  render(){
    let {departmentList, isSpinnerLoading, addDepartment } = this.state;
    let {isDepartmentModel, toggleDepartmentModal } = this.props;
    const closeBtn = <button className="close" onClick={() => toggleDepartmentModal(false)}>&times;</button>;
      return (
        <Modal zIndex="99999" isOpen={isDepartmentModel ? true : false} toggle={() => toggleDepartmentModal(false)}  className="spocModel1 modal-lg modal-dialog-centered">
          <Container >
            <ModalHeader close={closeBtn}>
              Manage Departments
              <Button onClick={() => this.setState({addDepartment : {}})} className="r-text--sm mt-3 ml-3" color="brandB"><i className="fi flaticon-plus mr-2"></i> New</Button>

            </ModalHeader>
            <ModalBody >

              {departmentList.map((data, index) => <DepartmentChild key={index} index={index} departmentData={data} deleteDeparment={this.deleteDeparment} />)}
              {addDepartment && <AddDepartment addDepartment={addDepartment} cancelAddDepartment={() => this.setState({addDepartment : false})} handleChangeAdd={this.handleChangeAdd} addDepartmentSubmit={this.addDepartmentSubmit} />}
            </ModalBody>
            {isSpinnerLoading && <div className="center-xy"><Spinner color="dark"/></div>}
          </Container>
        </Modal>
  )
 }
}

export default DepartmentModel;

class DepartmentChild extends Component {
  constructor(props){
    super(props);
    this.state = { deleteConfirmation : false };
  }
  render(){
    return (
        <Row className="align-items-center py-2">
          <Col xs={1}>{this.props.index+1}</Col>
          <Col>{this.props.departmentData.department_name}</Col>
          <Col xs="auto">
            {this.state.deleteConfirmation != this.props.departmentData.degree_department_id && <Button onClick={() => {this.setState({deleteConfirmation : this.props.departmentData.degree_department_id})}} className="p-2"><i className="fi flaticon-bin"></i></Button>}
            {this.state.deleteConfirmation == this.props.departmentData.degree_department_id &&
              <div>
                <Button onClick={() => {this.setState({deleteConfirmation : false})}} color="dark" square="true" className="p-2">Cancel</Button>{' '}
                <Button onClick={() => this.props.deleteDeparment(this.props.departmentData.degree_department_id)} color="dark" square="true" className="p-2">Sure</Button>
              </div>
            }
          </Col>
        </Row>
    )
  }
}

class AddDepartment extends Component {
  constructor(props){
    super(props);
    this.state = {
      isSpinnerLoading : false,
      suggestionValue: {
        department : '',
      },
    }
  }

  handleFieldChange = (e) => {
    let { suggestionValue } = this.state;
    if(e.target.name==="department" && e.target.value !== undefined){
      suggestionValue.department = e.target.value;
      this.setState({suggestionValue })
      this.props.handleChangeAdd('department_name',  e.target.value)
    }
  };

  setValuefromSuggestion = (value) => {
    let { suggestionValue } = this.state;
    suggestionValue.department = value.department_name
    this.props.handleChangeAdd('department_name', undefined)
    this.props.handleChangeAdd('department_id', value.department_id)
    this.setState({suggestionValue})
  }
  render(){
    let { isSpinnerLoading, suggestionValue } = this.state;
    let { handleChangeAdd, addDepartmentSubmit, cancelAddDepartment} = this.props;
    return (
      <form onSubmit={addDepartmentSubmit}>
        <Row className="align-items-center py-2">
          <Col xs={1}></Col>

          <Col>
            <AutosuggestComp
                  inputProps={{
                    className: "w-100 r-inputfield",
                    onChange: this.handleFieldChange,
                    value: suggestionValue.department,
                    name : 'department',
                    required : true
                  }}
                suggestionID="getDepartments"
                renderSuggestion={(comp) => <div>{comp.department_name}</div>}
                setValue={(value) => this.setValuefromSuggestion(value)}
              />
          </Col>
          <Col xs="auto">
            <Button onClick={cancelAddDepartment} type="button" color="dark" square="true" className="p-2">Cancel</Button>{' '}
            <Button color="dark" square="true" className="p-2">Save</Button>
          </Col>
          {isSpinnerLoading && <div className="center-xy"><Spinner color="dark"/></div>}
        </Row>
      </form>

    )
  }
}
