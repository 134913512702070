import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Errorbar} from '../../../global/errorbar';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../../actions/Auth';
import Aux from '../../../global/AuxComp';
import { getErrorMessage, getFiles, getFullName, uploadFile } from '../../../../actions';
import { setUser, setLoading, setError } from '../../../../actions/action-creators';
import {Row,Col,Container } from 'reactstrap';
import {HorizontalLine} from '../../../global/horizontal_line';
import { InputField } from '../../../global/Forms';
import Button from '../../../global/Button';
import Collapse from 'reactstrap/lib/Collapse';
import Autosuggest from 'react-autosuggest';
import store from '../../../../store';
import { ReactComponent as AttachmentLogo } from '../../../../assets/icons/attachment.svg';

 class MultipleStudentApply extends Component{
  constructor(props){
    super(props);
    this.state = {
        isOpenAnswer: false,
        applicantList: [],
        selectedApplicantList: [],
        cvList: [],
        answer_data: {},
        applicantSearch : "",
        applicantSuggestion: []
    };
  }

  componentDidMount() {
    this.fetchApplication()

  }

  fetchApplication = async() =>{
    store.dispatch(setLoading(true))
    try{
        var body = {
            application_id : this.props.applicationData.application_id
        }
        let resp = await Auth.Processes.showAllApplication(body);
        let data = resp.data.applicants ? resp.data.applicants.filter(app =>  app.student_application_status === 0) : [];
        this.setState({applicantList: data})
    }
    catch(e){
        console.log(e);
    }
    store.dispatch(setLoading(false))
}

    selectStudent = async(studentId) =>{
        store.dispatch(setLoading(true))
        let { selectedApplicantList, applicantList } = this.state;
        let data = applicantList.filter(obj => obj.student_id == studentId)[0];
        data = await this.appendCV(data);
        selectedApplicantList.push(data);
        applicantList.splice( applicantList.findIndex(o => o.student_id == studentId) , 1)
        this.setState({selectedApplicantList, applicantList, applicantSearch: ""})
        store.dispatch(setLoading(false))

    }
    removeSelectedStudent = (index) => {
        let { selectedApplicantList, applicantList } = this.state;
        applicantList.push(selectedApplicantList[index]);
        selectedApplicantList.splice( index , 1)
        this.setState({selectedApplicantList, applicantList})
    }
  appendCV = async (application) => {
    try{
        var resp = await Auth.Student.getResumes(application.student_id);
        application.cvList = resp.data.filter(cv => cv.cv_type !== "MASTER");
        return application;
    }
    catch(e){
        application.cvList = [];
        return application;
    }
  }

  selectCv = async(studentId, cv_id) =>{
    let { selectedApplicantList} = this.state;
    selectedApplicantList = selectedApplicantList.map(obj => {
        if(obj.student_id == studentId){
            obj.selectedCvId = cv_id;
        };
        return obj;
    });
    this.setState({selectedApplicantList})
    
}
    apply = async(e) => {
        e.preventDefault();
        let {selectedApplicantList, answer_data} = this.state;
        let {applicationData} = this.props;
        store.dispatch(setLoading(true))
        try{
            await Promise.all(Object.keys(answer_data).map(async studentId => {
               await Promise.all( Object.keys(answer_data[studentId] || {}).map(async questionId => {
                    if (answer_data[studentId][questionId] && typeof answer_data[studentId][questionId] === "object") {
                        try {
                          let file = await uploadFile(answer_data[studentId][questionId], `student/${studentId}/apply`)
                          answer_data[studentId][questionId] = file.file_id.toString()
                        } catch (e) { console.log(e) }
                      }
                      return answer_data
                }))
            }));
           
            var body = 
                {
                    "application_id": this.props.applicationData.application_id,
                    "student_data" :  selectedApplicantList.map(app => {
                        answer_data[app.student_id] = answer_data[app.student_id] || {};
                        return {
                            student_id : app.student_id,
                            resume_id: app.selectedCvId,
                            preference : null,
                            answer_data: this.props.applicationData.application_question.map(question => {
                                return {
                                    application_question_id: question.application_question_id,
                                    answer_content: answer_data[app.student_id][question.application_question_id] || ""
                                }
                            })
                        };
                    })
                }
            let resp = await Auth.Processes.bulkApply(body);
            let applicantsResp = await Auth.Processes.getApplicants(applicationData.application_id)
            this.props.handleDataChange({ application_id: applicationData.application_id, ...applicantsResp.data })
            applicationData.applicant_counts.applied = applicationData.applicant_counts.applied + selectedApplicantList.length;
            this.props.handleDataChange({application_id: applicationData.application_id, applicant_counts: applicationData.applicant_counts});
            this.props.close();
        }
        catch(e){
            console.log(e);
            store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
        }
        store.dispatch(setLoading(false))
    }

    handleAnswerChange = (student_id,question_id, e, file) => {
        let { answer_data } = this.state;
        answer_data[student_id] = answer_data[student_id] || {}
        if (file && file !== true && e.target.files[0].size > (file * 1024)) {
          this.setState({ message: 'File size should be under ' + file + "kb" });
          this.setState({ errorbar: true });
          return false;
        }
        answer_data[student_id][question_id] = file ? e.target.files[0] : e.target.value;
        this.setState({
          answer_data
        })
      }

      removeFile = (student_id ,question_id) => {
        let { answer_data } = this.state;
        answer_data[student_id][question_id] = null;
        this.setState({
          answer_data
        })
      }

    setApplicantSuggestion = (value) => {
        let { applicantList } = this.state;
        let applicantSuggestion = applicantList.filter(applicant => {
           return getFullName(applicant.student).toLowerCase().includes(value.toLowerCase())
        });
        this.setState({applicantSuggestion});
    }

  render(){
    let  { isOpenAnswer ,applicantList, selectedApplicantList, answer_data, applicantSearch, applicantSuggestion} = this.state;

      return (
        <Aux>
            <Row className="pb-3 justify-content-between">
                <Col xs={6}>
                    <Row className="align-items-center borderBottomBlack ml-1 mb-2">
                        { <Autosuggest
                            inputProps={{
                                className: "tableinput r-nav__search mt-2",
                                onChange : (e, {newValue}) => {this.setState({applicantSearch : newValue});},
                                placeholder: 'Search Student to add',
                                value: applicantSearch,
                                name: "applicantSearch"
                            }}
                            suggestions={applicantSuggestion}
                            onSuggestionsFetchRequested={({value}) => this.setApplicantSuggestion(value)}
                            getSuggestionValue={(applicant) => {return getFullName(applicant.student)}}
                            renderSuggestion={(applicant) =>  {return(<div>{getFullName(applicant.student)}</div>)} }
                            onSuggestionsClearRequested={() => console.log("clear")}
                            onSuggestionSelected = {(e, {suggestion}) => this.selectStudent(suggestion.student_id)}
                        /> }
                    </Row>
                </Col>
                <Col xs="auto">
                </Col>
            </Row>
            {selectedApplicantList.length !== 0 &&<Row className="">
                <Col> <h5>Student Name</h5> </Col>
                <Col> <h5>Roll No</h5> </Col>
                <Col> <h5>Batch</h5> </Col>
                <Col> <h5>CV</h5> </Col>
                {!!this.props.applicationData.application_question.length && <Col> <h5>Answer</h5> </Col>}
            </Row>}

            <form onSubmit={this.apply}>
            {selectedApplicantList.map((applicant, i) =>
           
            <Row className="align-items-center card shadow py-3 mx-0 my-4">
                <Col>
                    <Row>
                        <Col> 
                            <i className="fi flaticon-plus d-inline-block rotate--45 mr-2 selectable" onClick={() => this.removeSelectedStudent(i)} ></i> 
                            <span>{getFullName(applicant.student)}</span> 
                        </Col>
                        <Col> <span>{applicant.student.roll_no}</span> </Col>
                        <Col> <span>{applicant.student.batch_name}</span> </Col>
                        <Col> <span>
                                <div className="r-dropdown border-0">
                                    <select required value={applicant.selectedCvId || ""} onChange={(e) => this.selectCv(applicant.student_id, e.target.value)} className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }}>
                                        <option value="">Select Resume</option>
                                        {
                                            applicant.cvList.map(cv => 
                                            <option value={cv.cv_id}>{cv.cv_name}</option>
                                            )
                                        }
                                    </select>
                                    <i className="fi flaticon-arrow" />
                                </div>
                              </span> 
                        </Col>
                        {!!this.props.applicationData.application_question.length &&
                            <Col> 
                            <Button type="button" className="w-100  r-text--light r-bg--blue-3 border-0" onClick={() => {this.setState({isOpenAnswer : !isOpenAnswer})}}>
                                <span className="r-subheading-4">Answer</span>
                            </Button>
                        </Col>}
                    </Row>

                    <Row className="mt-2">
                            <Col>
                                <Collapse isOpen={isOpenAnswer}>
                                    <Row noGutters className="border bg-light">
                                        <Col className="p-2">
                                            {this.props.applicationData.application_question.map((question, i) => {
                                                answer_data[applicant.student_id] = answer_data[applicant.student_id] || {}
                                                let isRequired = {};
                                                if (question.is_mandatory)
                                                isRequired.required = true
                                                return (
                                                <Row form className="mt-4" key={i}>
                                                    <Col xs={12}>
                                                    <p className="r-subheading-4">{question.is_mandatory ? <span className="r-text--red-1">*</span>:""}{question.question_heading}</p>
                                                    {question.answer_type === "text" && <textarea {...isRequired} placeholder={question.question_content} onChange={(e) => this.handleAnswerChange(applicant.student_id,question.application_question_id, e)} className="w-100 r-inputfield" value={answer_data[applicant.student_id][question.application_question_id]}></textarea>}
                                                    {question.answer_type === "number" && <InputField type="number" {...isRequired} placeholder={question.question_content} onChange={(e) => this.handleAnswerChange(applicant.student_id,question.application_question_id, e)} className="w-100" value={answer_data[applicant.student_id][question.application_question_id]} />}
                                                    {question.answer_type === "file" &&
                                                        (answer_data[applicant.student_id][question.application_question_id] ? <span className="text-truncate r-tag r-tag--type-1">
                                                        <AttachmentLogo width={10} />{answer_data[applicant.student_id][question.application_question_id].name}<Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.removeFile(applicant.student_id,question.application_question_id)}></Button>
                                                        </span> : <Aux>
                                                            <label className="r-btn r-btn--blue text-center d-inline-block" htmlFor={`question_${question.application_question_id}`}><i className="mr-1 r-text--sm fi flaticon-upload" />{question.question_content}</label>
                                                            <input id={`question_${question.application_question_id}`} type="file" accept={JSON.parse(question.answer_type_data).file_type || '*'} onChange={(e) => this.handleAnswerChange(applicant.student_id,question.application_question_id, e, JSON.parse(question.answer_type_data).file_size || true)} className="d-none" />
                                                        </Aux>)}
                                                    </Col>
                                                </Row>
                                                )
                                            })
                                            }
                                        </Col>
                                    </Row>
                                </Collapse>
                            </Col>
                    </Row>
                </Col>
            </Row>
            )
        }
        <Row className="mt-3">
            <Col className="text-right">
                <Button type="button" onClick={() => this.props.close()} className="mr-2 r-btn r-btn--outline-dark-v r-text--sm" color="outline-dark-v r-text--sm">Cancel</Button>
               {selectedApplicantList.length !== 0 && <Button type="submit" className="r-btn r-btn--dark r-text--sm" color="dark r-text--sm" >Save</Button>}
            </Col>
        </Row>
    </form>
      </Aux>
  )
 }
}

export default  MultipleStudentApply ;