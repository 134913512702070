import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Row,Col,Container} from 'reactstrap';

export const HorizontalLine = ({ color, className }) => (
    <hr className={className}
        style={{
            color: color,
            backgroundColor: color,
            height: 1,
            margin: 1
        }}
    />
)
