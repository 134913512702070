import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Errorbar} from '../../../global/errorbar';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../../actions/Auth';
import { uploadFile } from '../../../../actions';
import { setUser, setLoading } from '../../../../actions/action-creators';
import Button from '../../../global/Button';
import { RTE } from '../../../global/Forms/RTE';
import { InputField } from '../../../global/Forms';
import {Row,Col} from 'reactstrap';
import FilterWithProcess from '../../../global/filter-with-process';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import Aux from '../../../global/AuxComp';

 class CreatePost extends Component{
  constructor(props){
    super(props);
    this.state = {
      getProcesList:  [],
      getStudentAssigned: [],
      posts: [{key:1, name:"We're Coming", views:0, likes:0, share:0, },{key:2, name:"We're Coming", views:"150/200", likes:50, share:3, }],
      isOn: false,
      modalstudent: false,
      modalshare:false,
      modal: false,
      modaledit: false,
      collapse: false,
      fileId: null,
      bannerId: null,
      videoId: null,
      postcolapse: false,
      popoverOpen: false,
      postsdata: [],
      files: {},
      fileurl: null,
      file: '',
      post_tag: null,
      errorbar: false,
      message:'',
      title:'',
      content:'',
      attachfile:'',
      popoverOpen2: false,
      process:{process_name: "Student Feed",process_type: "feed",process_id: 3},
      addStudentModal: false,
      selectedCount: [],
      init: true,

    };
  }

  toggleLoading = (show) => {
    this.setState({
      isLoading: show
    })
  }

  filterStudents = (studentFilters, processStudent) => {
    let students = this.allStudents;
    if (studentFilters.batches.length > 0)
      students = students.filter(st => studentFilters.batches.indexOf(st.batch_id.toString()) > -1)
    if (studentFilters.departments.length > 0)
      students = students.filter(st => studentFilters.departments.indexOf(st.batch_id + "-" + st.degree_department_id) > -1)
    if (studentFilters.groups.length > 0)
      students = students.filter(st => studentFilters.groups.indexOf(st.batch_id + "-" + st.degree_department_id + "-" + st.group_id) > -1)
    if (studentFilters.selectedProcess && studentFilters.selectedProcess.length && processStudent)
    students = students.filter(st => processStudent.indexOf(st.student_id) > -1)
    this.setState({
       selectedCount: students
    });
  }

   handleChangefile = (event) =>{
    this.setState({
      fileurl: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    })
  }


  closebar = () =>{
      this.setState({errorbar: false});
  }


  postCreate = async(e)=>{
          e.preventDefault();
          this.props.setLoading(true);
          let newFile , attachedfile;
          if(this.state.attachfile){
            attachedfile = await uploadFile(this.state.attachfile,this.props.user.id + "/post" );
            this.setState({fileId: attachedfile.file_id})
          }
          if(this.state.file){
            newFile = await uploadFile(this.state.file,this.props.user.id + "/post" );
            this.setState({bannerId: newFile.file_id})
          }

          var postBody = {
            "title": this.state.title,
            "file_id": this.state.bannerId,
            //"file_id": this.state.fileId,
            "video_id": this.state.videoId,
            "description": this.state.content,
            "campus_id": this.props.user.current_role_info.campus_id,
            origin_type : "GENERAL"
          }
          try {
            let res = await Auth.Feed.sendPost(postBody);
            var userBody = {post_id : res.data.insertId, user_id : this.state.selectedCount.map(s => s.user_id)};
            
            await Auth.Feed.postMemberAdd(userBody)
            
            this.props.refresh();
            await this.setState({
              fileurl: null,
              file:'',
              attachfile:'',
              title:'',
              content:'',
              bannerId:'',
              fileId:'',
              addStudentModal : false
            });
            this.props.setLoading(false);
          } 
          catch (e) {
            this.props.setLoading(false);
            console.log(e);
              // this.setState({errorbar: true});
              // this.setState({message: e.response.data.error.toString()});
          }
  }


  onChange = e => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }

  addStModal = async (e) => {
    e.preventDefault()
    this.setState({
      isLoading: true
    })
    this.setState({ addStudentModal: true })
    this.allStudents = []
    await Auth.PtStudents.getBatch().then(resp => {
      resp.data.forEach(batch => {
        batch.student_info.forEach(student => {
          this.allStudents.push(student)
        })
      })
    })
    this.setState({ isLoading: false, selectedCount: this.allStudents })

  }

  render(){
    let {title, content ,addStudentModal , selectedCount} = this.state;
      return (
      <Aux>
        <Row className={" "+this.props.location.pathname=="/pt/dashboard"? "":"justify-content-center"}>
              <Col xs={{ size: this.props.location.pathname=="/pt/dashboard"?'12':'12' }} className="">
                  <div className="post">
                          <Col xs="auto">
                                    <Row className="align-items-center py-3">
                                        <Col xs={7}>
                                          <div className="post__header align-items-center" >
                                            <span className="r-heading-4">Placement Team</span>
                                          </div>
                                        </Col>
                                      </Row>
                              </Col>
                              <form onSubmit={this.addStModal}>
                                 
                                <Col xs="12" className=" my-3">
                                   <InputField className="r-nav__search titleinput" onChange={this.onChange} placeholder="Title here..." name="title" value={title} type="text" required />
                                  </Col>
                                <Col xs="12" className=" my-3">
                                   <RTE className="r-nav__search " onChange={(value) => this.setState({content: value})} name="content" value={content} rows="3" placeholder="Description here..." required />
                                </Col>
                                {(this.state.fileurl !== null)?
                                    <Row  className="justify-content-center align-items-center ">
                                      
                                      {this.state.file.name.includes(".pdf") ? 
                                        <a download={true} className='mb-2 ml-2' style={{color: "#000", backgroundColor: "#eee", border: "1px solid #ccc", padding: 2, fontSize: 12}} target="_blank" rel='noopener noreferrer' href={this.state.fileurl}>
                                          <i className='fi flaticon-file mr-2' /> Attachment Added
                                        </a> : <img style={{width: "250px"}} src={this.state.fileurl} alt=""/> }
                                    </Row>
                                  :null}
                              <Col xs={{ size: '12', offset: 0 }}>
                                    <Row className="justify-content-end align-items-center my-4">
                                                {/* <label htmlFor="file-input"  >
                                                 <Row className="border align-items-center font-weight-normal mx-3 py-1  px-3  " color="outline-dark-v">
                                                      <span style={{fontSize:"14px"}} className="r-text--grey-4">{this.state.attachfile.name || "Add an attachment"}</span>
                                                      <div className=" mx-2"/>
                                                      <img style={{width:'20px'}}  src={require('../../../../assets/icons/attachment.svg')}/>
                                                </Row>
                                                <input id="file-input"  onChange={(e) =>{this.setState({attachfile:e.target.files[0]})}} type="file" style={{display:"none"}}/>
                                                </label> */}
                                                <label htmlFor="file-input-image"  >
                                                  <Row className="border align-items-center font-weight-normal mx-3 py-1  px-3  " color="outline-dark-v">
                                                      <span style={{fontSize:"14px"}} className="r-text--grey-4">Add an</span>
                                                      <div className=" mx-2"/>
                                                      <i className=" r-text--xl fi flaticon-art r-text--grey-4" style={{fontSize:"16px"}}/>/
                                                      <i className=" r-text--xl fi flaticon-paper r-text--grey-4" style={{fontSize:"16px"}}/>
                                                  </Row>
                                                  <input id="file-input-image" accept='.pdf,image/*'  onChange={this.handleChangefile} type="file" style={{display:"none"}}/>
                                                </label>
                                       { /* <Button id="Popover2" className="font-weight-normal border-left  py-2 px-4 r-btn--no-radius r-btn r-btn--grey" color="grey">+ Button/Link</Button>
                                   <Button id="Popover1" className="font-weight-normal border-left  px-2 py-0" ><img src="/images/barchart.png" style={{height:"20px"}}  alt=""/></Button>

                                                                          <Button className="font-weight-normal border-left  px-2 py-0" ><img src="/images/paper-clip@2x.png" style={{height:"20px"}}  alt=""/></Button>
                                                                          <Col xs="auto">
                                                                              <Row><span className="r-heading-4">Category</span></Row>
                                                                              <Row><select className="selectfield">
                                                                                <option value="volvo">Marketing Society</option>
                                                                                <option value="saab">Likes</option>
                                                                                <option value="opel">Comments</option>
                                                                              </select></Row>
                                                                          </Col>*/}
                                        <Button type="submit" className="font-weight-normal  px-5 py-2 mx-4 r-btn--no-radius r-btn r-btn--dark" color="dark">Post</Button>

                                    </Row>
                              </Col>
            
                          </form>

                          <Modal size="xl" zIndex="99999" isOpen={addStudentModal} toggle={() => this.setState({ addStudentModal: false })}>
                              <ModalBody>
                                <h2 className="r-subheading-1 text-center">Filter Students to add</h2>

                                <p className="r-heading-4"><span className="r-text--green-1">{selectedCount.length} students </span> selected</p>
                                <FilterWithProcess filterStudents={this.filterStudents} />
                                <div className="text-center mt-4">
                                  <Button type="button" color="dark" onClick={this.postCreate} className="r-btn--no-radius">Post</Button>
                                </div>
                              </ModalBody>
                            </Modal>
                      </div>
              </Col>
        </Row>
        <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
      </Aux>
  )
 }
}

const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoading: (show) => dispatch(setLoading(show)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePost));
