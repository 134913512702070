import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import Spinner from 'reactstrap/lib/Spinner';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import {
    Loader
} from '../../../global/Loader';
import {
    SelectList,
    Select
} from '../../../global/Forms/Select';
import {
    Auth
} from '../../../../actions/Auth';
import Button from '../../../global/Button';
import FilterComponent from '../../../global/filter-component';
import {
    InputField
} from '../../../global/Forms';
import Axios from 'axios';
import Link from 'react-router-dom/Link';
import Aux from '../../../global/AuxComp';

class StudentSelect extends Component {
    constructor(props) {
        super(props)
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            batchList: {},
            depList: {},
            groupList: {},
            studentFilters: {
                batches: [],
                departments: [],
                groups: [],
            }
        }
    }

    filterStudents = (studentFilters, batchList, depList, groupList) => {
        this.setState({
            batchList: batchList,
            depList: depList,
            groupList: groupList
        });
        this.setState(prevState => ({
            studentFilters: {
                ...prevState.studentFilters,
                batches: studentFilters.batches,
                departments: studentFilters.departments,
                groups: studentFilters.groups,
            }
        }))
    }
    mapStudenttoProcess = async () => {
        let {
            studentFilters,
            batchList,
            depList,
            groupList
        } = this.state;
        await this.props.filterStudents(studentFilters, batchList, depList, groupList);
    }

    render() {
        let {
            isLoading,
            studentFilters,
            depList,
            batchList,
            groupList
        } = this.state;
        let {
            modal
        } = this.props;
        return ( <
            Modal size = "xl"
            zIndex = "99999"
            isOpen = {
                modal
            }
            toggle = {
                this.props.toggle
            } >
            <
            ModalBody >
            <
            h2 className = "r-subheading-1 text-center" > Select Students < /h2> <
            FilterComponent filterStudents = {
                this.filterStudents
            }
            /> <
            div className = "text-center mt-4" > {
                isLoading && < Spinner color = "dark" / >
            } <
            Button onClick = {
                this.mapStudenttoProcess
            }
            color = "dark"
            className = "r-btn--no-radius" > Show < /Button> <
            /div> <
            /ModalBody> <
            /Modal>

        )
    }
}

export default StudentSelect