import React, { useState } from 'react';
import { Row, Col, Container, Modal, ModalHeader, Nav, NavItem,UncontrolledCollapse,CardBody, Card , Collapse, NavLink, TabContent, TabPane, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import Button from '../../../global/Button';
import RButton from '../../../global/Button';
import classnames from 'classnames';
import { HorizontalLine } from '../../../global/horizontal_line';
import Aux from '../../../global/AuxComp';
import { Auth } from '../../../../actions/Auth';
import store from '../../../../store';
import { setLoading, setError } from '../../../../actions/action-creators';
import Axios from 'axios';
import { Select } from '../../../global/Forms/Select';
import { InputField } from '../../../global/Forms';
import moment from 'moment';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption} from 'reactstrap';
import { YearSelect } from '../../../global/Forms/Select';
import { FileUpload } from '../../../global/FileUpload';
import { uploadFile, getFiles } from '../../../../actions';
import { SingleDatePicker } from '../../../global/Forms/DatePicker';
import { CONSTANTS } from '../../../../constants';
import { Document, Page, pdfjs } from "react-pdf";
import { RTE } from '../../../global/Forms/RTE';
import { RichTextViewer } from '../../../global/Forms/RichTextViewer';
 pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
class About extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isEditDesc : "",
            isEditActiveProcess : false,
            description : null,
            activeIndex : 0,
            animating :false,
            pastProcessList : [],
            isOpenAdd : false,
            addPlacementType : "",
            addPlacementYear : "",
            isLoadingActiveProcess : false,          
            isLoadingPastProcess : false,
            isOpenReport : false,   
            reportOverview: {},        
        }
        
    }
   
    changeProcessDate = (date, key) => {
        let {isEditActiveProcess} = this.state;
        isEditActiveProcess[key] = date.format(CONSTANTS.DATE_FORMAT)
        this.setState({isEditActiveProcess})
    }
    saveProcessDate =async () => {
        let {isEditActiveProcess} = this.state;
        store.dispatch(setLoading(true)) 
        try{
            let processResp = await Auth.Processes.updateProcess(isEditActiveProcess);
            this.setState({isEditActiveProcess : false})
            await this.props.getActivePlacement();

        }
        catch(e){
            console.log(e);
        }
        store.dispatch(setLoading(false)) 

    }
    

    fetchPastPlacement = async() =>{
        this.setState({isLoadingPastProcess : true})
        try{
            var body = {
                campus_id : this.props.campus_id
            }
            let pastProcessResp = await Auth.CampusProfile.fetchPastPlacement(body);
            let data = pastProcessResp.data;
            this.setState({pastProcessList: data})
        }
        catch(e){
            console.log(e);
        }
        this.setState({isLoadingPastProcess : false})
    }

    addPastPlacement = async(e) => {
        e.preventDefault()
        store.dispatch(setLoading(true)) 
        try {
            var body = {
                process_type : e.target.type.value,
                process_year : e.target.year.value,
                highest_ctc :  e.target.h_ctc.value,
                lowest_ctc :  e.target.l_ctc.value,
                average_ctc :  e.target.a_ctc.value,
                median_ctc :  e.target.m_ctc.value,
            } 
          let fileResp = await uploadFile(e.target.report_file.files[0], "campus_profile")
          body.file_id = fileResp.file_id;
          await Auth.CampusProfile.createPastPlacement(body)
          this.togglePastPlacementAdd()
        } catch(e) {
          console.log(e)
        }
        store.dispatch(setLoading(false))
        await this.fetchPastPlacement()
      }

    onDocumentLoadSuccess = (numPages, process_id) => {
        let { pastProcessList } = this.state;
        pastProcessList = pastProcessList.map(data => {
            if(data.process_id== process_id)
                data.numPages = numPages;
            return data;
        })
        this.setState({pastProcessList})
    }

    componentDidMount(){
        this.fetchPastPlacement();
    }
    editDesc = async (e) => {
        e.preventDefault();
        if(this.state.description){
            await this.props.campusProfileChange("description", this.state.description);
            await this.props.handleProfileSubmit();
        }
       this.setState({isEditDesc : false})
    }

   
    togglePastPlacementAdd= () =>{
        this.setState( { isOpenAdd: !this.state.isOpenAdd });
    }

    changePdfPage = (process_id, action) => {
        let { pastProcessList } = this.state;
        pastProcessList = pastProcessList.map(data => {
            if(data.process_id== process_id){
                data.pageNumber = action == "prev" ? (data.pageNumber || 2) - 1 : (data.pageNumber || 1) + 1;
            }
            return data;
        })
        this.setState({pastProcessList})
    }
   
  render() {
   
    
    let { isEditDesc, isOpenAdd, isEditActiveProcess, isLoadingActiveProcess, isLoadingPastProcess, isLoadingReporOverview } = this.state;
    let {isLoggedIn, activeProcessList} = this.props;
    
    return (     
    
       <Container>   
        {isLoggedIn &&
            <Row>
                <Col className="text-left p-0">
                    <span className="r-heading-3 ml-4">Create your public profile</span>
                    <HorizontalLine className="mt-1" color="#cfcfcf" />
                </Col>
            </Row>} 
           <form onSubmit={this.editDesc}>
           <Row className="">
               <Col>
                <div className="form-group ">
                    <h1 className="r-subheading-3 mt-4 pb-3" style={{fontSize: 17}} htmlFor="about">Description{isLoggedIn && <Button type="button"  className="fi r-text--sm flaticon-edit-2" onClick= {() => { this.setState({isEditDesc : true}) }}></Button>}</h1>
                     {
                        isEditDesc && isLoggedIn ? 
                        <RTE onChange={(value) => {this.setState({description : value})}} value={this.state.description || this.props.campusProfile.description} rows="3" placeholder="Description here..."  />

                        :
                        <RichTextViewer>{this.props.campusProfile.description || "No Description to show."}</RichTextViewer>
                        
                     }  
                </div>
               </Col>
           </Row>

           { isEditDesc && isLoggedIn &&
            <Row className="mt-2">
                <Col className=" text-right">
                    <Button  type="button" onClick= {() => { this.setState({isEditDesc : false, description : null}) }} color="light-dark">Cancel</Button>
                    <Button  type="submit" color="dark">Save</Button>
                </Col>
            </Row>
            }
           </form>
        
            <Row>
                <Col className="p-0">
                    <HorizontalLine color="#cfcfcf" />
                </Col>
           </Row>
           <Row className="">
            <Col>
                <h1 className="mt-4 pb-3 r-subheading-3 " style={{fontSize: 17}}>Active Placements</h1>
            </Col>
           </Row>
                        { isLoadingActiveProcess && <Spinner animation="border" variant="dark" />}

                        { activeProcessList.map( (data, i) => {
                            return <Row  key={i}  className={((activeProcessList.length-1) == i ? "" : "border-bottom")}>
                                <Col>
                                <Row>
                                  <Col className="mt-4">
                                      <h1 className="r-subheading-3">{data.process_name} </h1>
                                  </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <p className=" text-muted float-left">{ data.start_date ? moment(data.start_date).format("DD-MMM-YYYY") : "Start Date"} - { data.end_date ? moment(data.end_date).format("DD-MMM-YYYY") : "End Date"}</p>
                                        { 
                                            isEditActiveProcess && isEditActiveProcess.process_id == data.process_id ?
                                            (isLoggedIn && <Row className="align-item-center">
                                                <Col xs={3}><SingleDatePicker value={isEditActiveProcess.start_date || data.start_date} onDateChange={(date) => {this.changeProcessDate(date, "start_date")}} /></Col>
                                                <Col xs={3}><SingleDatePicker value={isEditActiveProcess.end_date || data.end_date} onDateChange={(date) => {this.changeProcessDate(date, "end_date")}} /></Col>
                                                <Col xs={5}> <Button onClick={() => {this.setState({isEditActiveProcess :false})}} color="light-dark">Cancel</Button>
                                                <Button color="dark" onClick={this.saveProcessDate}>Save</Button></Col>
                                            </Row>)
                                            :
                                            (isLoggedIn && <Button onClick={() => {this.setState({isEditActiveProcess : { process_id : data.process_id}})}} className="fi flaticon-edit ml-3 float-center"></Button>)
                                        }
                                    </Col>
                                </Row>
                                <Row> 
                                     <Col>
                                      <p style={{fontSize: 12}}> {data.departments ? data.departments.join(", ") : ""}</p> 
                                     </Col> 
                                </Row>
                                </Col>
                            </Row>
                        })
                        }
            <Row className="my-3">
                <Col className="p-0">
                    <HorizontalLine color="#cfcfcf" />
                </Col>
           </Row>
           <Row className="">
            <Col>
                <h1 className="mt-3 pb-3 r-subheading-3 " style={{fontSize: 17}} >Past Placements</h1>
            </Col>
           </Row>
           { isLoadingPastProcess && <Spinner animation="border" variant="dark" />}

           <Row>
               <Col>
                {isLoggedIn && <Button className="r-text--sm mt-3" color="brandB" onClick={this.togglePastPlacementAdd} style={{ marginBottom: '1rem' }} ><i className="fi flaticon-plus-1 r-text--sm mr-2"></i>Add</Button>}
                {isLoggedIn && <Collapse isOpen={isOpenAdd}>
                    <Card>
                        <CardBody>
                        <form onSubmit={this.addPastPlacement}>
                        <Row  className="mb-3">
                                <Col xs="6">
                                    <label className="r-heading-7">Type</label>
                                    <Select name="type" required >
                                        <option value="">Select</option>
                                        <option value="FULL_TIME">Full-time</option>
                                        <option value="INTERNSHIP">Internships</option>
                                    </Select>
                                </Col>

                                <Col xs="6">
                                <label className="r-heading-7">Placement Year</label>
                                <YearSelect name="year" required />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                <span className="r-heading-7">Highest CTC</span>
                                    <InputField className="w-100" name="h_ctc" type="number"/>
                                </Col>

                                <Col>
                                <span className="r-heading-7">Lowest CTC</span>
                                    <InputField className="w-100" name="l_ctc" type="number"/>
                                </Col>

                                <Col>
                                <span className="r-heading-7">Average CTC</span>
                                    <InputField className="w-100" name="a_ctc" type="number"/>
                                </Col>

                                <Col>
                                <span className="r-heading-7">Median</span>
                                    <InputField className="w-100" name="m_ctc" type="number"/>
                                </Col>
                            </Row>
                            <Row>
                            <Col className="mt-2" xs={12}>
                                <span className="mr-3 r-heading-7">Upload Report</span>
                                    <input name= "report_file"  type="file"  required />
                             </Col>
                        </Row>
                      <Row>
                        <Col  xs={12}>
                        <div className="d-flex justify-content-end mt-3">
                        <Button type="reset" onClick={()=>{this.setState({isOpenAdd: false})}} className="r-text--sm">Cancel</Button>
                        <Button className="r-text--sm r-btn--no-radius" color="dark">Save</Button>
                      </div>
                        </Col>
                     </Row>
                    </form>
                        </CardBody>
                    </Card>
                </Collapse> }
               </Col>
           </Row>
          
            { this.state.pastProcessList.map((item, i) => 
                <Row key={i} className="border my-5 p-2" style={{overflow:"hidden"}}>
                <Col>
                    <Row className="border-bottom">
                        <Col className="my-3">
                            <span className="r-subheading-2">{item.process_type == "INTERNSHIP" ? "Internships" : "Full-time"}{" | "}{item.process_year}</span>
                        </Col>
                    </Row>     
                    <Row>     
                        {item.highest_ctc ?  <Col xs={3}>
                            <div className="r-bg--brandA r-text--light card mt-3">
                                <span className="r-subheading-2 px-2 py-2">{parseInt(item.highest_ctc).toLocaleString('en-IN')}</span>
                            </div>
                            <span className="r-subheading-4 mt-3">Highest CTC </span>
                        </Col>
                            : ""
                        }
                        {item.lowest_ctc ? <Col xs={3}>
                            <div className="r-bg--brandA r-text--light card mt-3">
                                <span className="r-subheading-2 px-2 py-2">{parseInt(item.lowest_ctc).toLocaleString('en-IN')}</span>
                                </div>
                            <span className="r-subheading-4 mt-3">Lowest CTC</span>
                        </Col> : ""
                        }
                        {item.average_ctc ? <Col xs={3}>
                            <div className="r-bg--brandA r-text--light card mt-3">
                                <span className="r-subheading-2 px-2 py-2">{parseInt(item.average_ctc).toLocaleString('en-IN')}</span>
                                </div>
                            <span className="r-subheading-4 mt-3">Average CTC</span>
                        </Col>: ""
                        }
                        {item.median_ctc ? <Col xs={3}>
                            <div className="r-bg--brandA r-text--light card mt-3">
                                <span className="r-subheading-2 px-2 py-2">{parseInt(item.median_ctc).toLocaleString('en-IN')}</span>
                                </div>
                            <span className="r-subheading-4 mt-3">Median CTC</span>
                        </Col> : ""
                        }
                   </Row>
                    
                    <Row>
                    <Col>
                        <div className="w-100 mt-3 text-right">
                            <span className="r-text--sm">Page {item.pageNumber || 1} of {item.numPages || 1}</span>
                            <Button disabled={(item.pageNumber || 1) == 1} onClick={() => {this.changePdfPage(item.process_id, "prev")}} className="fi flaticon-arrow-1 r-text--sm"></Button>
                            <Button disabled={(item.pageNumber || 1) == (item.numPages || 1)} onClick={() => {this.changePdfPage(item.process_id, "next")}} className="fi flaticon-arrow ml-2 r-text--sm"></Button>
                        </div>
                        <Document className="w-100 shadow" file={item.file_data && item.file_data.signed_url[0]} onLoadSuccess={({numPages})=>{this.onDocumentLoadSuccess(numPages, item.process_id)}} >
                            <Page pageNumber={item.pageNumber || 1} />
                        </Document>
                    </Col>
                    </Row>
                </Col>
                </Row>
            )}
              
       </Container>
      
    )
  }




   
}
export default About;


