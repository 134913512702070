import React, { Component } from 'react';

import { Row, Col, Container, Collapse } from 'reactstrap';
import { InputField } from '../../../../global/Forms';
import Button from '../../../../global/Button';
import { ToggleButton } from '../../../../global/Forms/ToggleButton';
import { Auth } from '../../../../../actions/Auth';
import { Loader } from '../../../../global/Loader';
import { Application } from './application';
import Aux from '../../../../global/AuxComp';
import { ApplicationDetail } from './application-detail';
import { Errorbar } from '../../../../global/errorbar';
import PaginationComponent from '../../../../global/PaginationComponent.js';
import { getFiles, formattedMatrixError, getQueryParams, getFilesList, getFullName } from '../../../../../actions';
import moment from 'moment';
import HelpSection from '../../../helpSection/student_section';
import UncontrolledCollapse from 'reactstrap/lib/UncontrolledCollapse';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import {Confirmation} from '../../../../global/confirmation';
import Policies from '../../../../policies';


class Apply extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processList: [],
      currentPage: 0,
      rowsPerPage: 10,
      resumes: [],
      applicationList: [],
      tabs: {
        "Opened": [],
        "Eligible": [],
        "Applied": [],
        "Not Applied": [],
        "Shortlisted": [],
        "Rejected": [],
        "Not Selected": [],
        // "Selected": [],
      },
      isLoading: false,
      applicationId: null,
      selectedTab: "Opened",
      selectedView: "Status View",
      selectedProcess: null,
      applyModal: false,
      isDetailsOpen: false,
      query: "",
      errorbar: false,
      message: '',
      files: {},
      lastOffer : {},
      isRejectInvitation : false,
      reject_message : "",
      withrawCompleteCounter : null,
      studentAppicationStatus : this.props.studentDetails.student_info[0].show_application,
      isSuccessApproval: false,
      offerData : [],
      blockedApplications : [],
      isOpenOffers : false,
    }
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  showApplicationDetails = (id, e) => {
    //let parentEl = e.target.closest(".apply__jobs__company");
    //let el = e.target.closest(".apply__jobs__profile");
    let targetEl = e.target.closest(".apply__jobs__status");
    if (targetEl)
      this.setState({
        applicationId: this.state.applicationId !== id ? id : null,
      })
    if (this.state.isDetailsOpen && (e.target.closest('.apply__jobs__status') || e.target.closest('.close_details')))
      this.setState({
        isDetailsOpen: false,
      })

  }
  componentWillMount() {
    this.init()
  }

  componentWillReceiveProps(nextProps) {
    let params = getQueryParams(nextProps.history.location.search),
    {selectedProcess,processList} = this.state;
    if(params.process_id && params.process_id!=selectedProcess.process_id) {
      selectedProcess = processList.filter(p=>params.process_id==p.process_id)[0] || selectedProcess
      this.fetchApplications(selectedProcess, params.application_id)
    }
  }

  fetchApplications = async (process, selectedApplicationId) => {
    this.setState({
      isLoading: true,
      applicationId: null
    })
    this.fetchOfferData(process);
    await this.getLastOffer(process);
    try {
      let tabs = {
        "Opened": [],
        "Eligible": [],
        "Applied": [],
        "Not Applied": [],
        "Shortlisted": [],
        "Rejected": [],
        // "Selected": [],
        // "Completed": []
      },
        fileIds = [], files = {};
      let applicationResp = await Auth.Student.getApplications(process.process_id, this.props.studentDetails.user_info.student_id);
      let blockedApplicationsResp = await Auth.Student.getBlockedApplications({student_id: this.props.studentDetails.user_info.student_id})
      applicationResp.data = await this.appendShortlistQuestionAnswer(applicationResp.data);
      this.multiroleApplications = applicationResp.data.reduce((prev, cur) => {
        if (cur.application_binder_id) {
          if (!prev[cur.application_binder_id])
            prev[cur.application_binder_id] = []
          prev[cur.application_binder_id].push(cur)
        }
        return prev
      }, {})
      this.binders = Object.keys(this.multiroleApplications).reduce((prev, binder) => {
        let temp = {
          ...prev,
          [binder]: false
        }
        this.multiroleApplications[binder].forEach(({ location }, i) => {
          if (i > 0 && location != this.multiroleApplications[binder][i - 1].location)
            temp[binder] = true
        })
        return temp
      }, {})
      let closed = []
      // applicationResp.data = applicationResp.data.filter(apl=>{
      //   if(apl.is_recruitment_closed){
      //     tabs["Completed"].push(apl);
      //     return false;
      //   }
      //   return true;
      // });
      applicationResp.data = applicationResp.data.filter(apl=>{
        if(moment(apl.application_deadline).diff(moment(),"milliseconds")<=0)
        {
          closed.push(apl)
          return false
        }
        return true
      });
      applicationResp.data = applicationResp.data.sort((a, b) => a.isShortListActionRequired < b.isShortListActionRequired)
      let blockedApplications = blockedApplicationsResp.data.map(o => o.application_id);
      applicationResp.data = applicationResp.data.concat(...closed)
      applicationResp.data.forEach(apl => {
        apl.is_blocked = blockedApplications.indexOf(apl.application_id) !== -1;
        let shortListed = apl.shortlisting_data.filter(sl => sl.locked_status === 1)
        let rejected = apl.shortlisting_data.filter(sl => sl.locked_status === 2)
        tabs["Opened"].push(apl);
        if (apl.eligibility_status === 1 && !apl.is_blocked) tabs["Eligible"].push(apl)
        if (apl.student_application_status === 2) tabs["Applied"].push(apl)
        if (apl.student_application_status === 0 || apl.student_application_status === 1) tabs["Not Applied"].push(apl)
        if (shortListed.length) tabs["Shortlisted"].push(apl)
        if (rejected.length) tabs["Rejected"].push(apl)
        // if (apl.offer_data.length) tabs["Selected"].push(apl)
        apl.application_docs.forEach(doc => {
          if (doc.file_path && !isNaN(doc.file_path))
            fileIds.push(doc.file_path)
        })
      })
      if (fileIds.length)
        files = await getFilesList(fileIds)
      this.setState({
        blockedApplications,
        applicationId: parseInt(selectedApplicationId),
        applicationList: applicationResp.data,
        selectedProcess: process,
        tabs,
        files
      })
    } catch (e) {

      this.setState({
        applicationList: [],
        selectedProcess: process
      })

      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }

    }
    this.setState({
      isLoading: false
    })
  }


  appendShortlistQuestionAnswer = async (applications) => {
      applications = applications.map(app => {
        let allQues = [...app.application_question];
        app.application_question = allQues.filter(q => q.ref_type === "APPLY");
        app.shortlist_question = [];
        app.shortlisting_data.map(round => {
          if(round.shortlist_status === 1 && round.application_round_id !== 0)
            app.shortlist_question = allQues.filter(q => q.ref_type === "SHORTLIST" && q.ref_id === round.application_round_id)
        });
        app.isShortListActionRequired = app.shortlist_question.length ? !app.shortlist_question.filter(ques => ques.answer_data.length).length : false;
        return app;
      });
   
    return applications;
  }




  fetchOfferData = async (process) => {
    try{
      var offerResp = await Auth.Offer.getOffer({process_id : process.process_id, student_id: this.props.studentDetails.user_info.student_id});
      this.setState({offerData: offerResp.data});
    }
    catch(e){
      this.setState({offerData: []})
      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
  }
  init = async () => {
    this.setState({
      isLoading: true
    })
    let params = getQueryParams(this.props.history.location.search),
    processParam = null
    try {
      let processResp = await Auth.Student.getProcessList(this.props.studentDetails.user_info.student_id);
      let resumeResp = await Auth.Student.getResumes(this.props.studentDetails.user_info.student_id)
      processResp.data = processResp.data.filter(process=> process.student_mapping_data.approval_status !== 2);
      if(params.process_id) {
        processParam = processResp.data.filter(p=>p.process_id==params.process_id)[0]
      }
      let selectedProcess = processParam || processResp.data[0];
      if (selectedProcess)
        await this.fetchApplications(selectedProcess)
      this.setState({
        applicationId: parseInt(params.application_id),
        processList: processResp.data,
        selectedProcess,
        resumes: resumeResp.data,
        isLoading: false
      })
    } catch (e) {
      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
  }

  handleProcessChange = (process) => {
    this.setState({
      selectedTab: "Opened",
      selectedView: "Status View",
    })
    this.props.history.push(this.props.history.location.pathname + "?process_id=" + process.process_id)
  }

  getLastOffer = async (process) => {
    if(this.interval)
      clearInterval(this.interval)
    this.setState({withrawCompleteCounter : null})
    if(process.matrix_status.is_restrict){
      try{
        var body = {
          process_id : process.process_id,
          student_id : this.props.studentDetails.user_info.student_id
        }
        let offerResp = await Auth.Offer.getOffer(body)
        var lastOffer = offerResp.data[offerResp.data.length-1] || {};
        this.setState({lastOffer : lastOffer})
        if(lastOffer.withdraw_deadline){
          this.interval = setInterval(this.setWithdrawDeadline, 1000)
        }
      }
      catch(e){
        if (e.response) {
          this.setState({ message: e.response.data.error.toString() });
          this.setState({ errorbar: true });
        } else {
          this.setState({ message: 'Sorry! something went wrong. Please try again' });
          this.setState({ errorbar: true });
        }
      }
    }
  }
  setWithdrawDeadline = () =>{
    let withrawCompleteCounter = moment.duration(moment(this.state.lastOffer.withdraw_deadline).diff(moment(), "milliseconds"))
    if(withrawCompleteCounter._milliseconds <= 0){
        var selectedProcess = this.state.selectedProcess;
        selectedProcess.matrix_status.is_freezed = 1;
        this.setState({selectedProcess })
    }
    this.setState({ withrawCompleteCounter })
  }
  toggleView = (view) => {
    this.setState({
      selectedView: view,
      applicationId: view !== this.state.selectedView ? null : this.state.applicationId
    })
  }

  setParentState = (state) => {
    this.setState(state)
  }
  checkWithdrawComplete = async () => {
    let { selectedProcess, processList } = this.state;
    this.setState({
      isLoading: true
    })
    try{
      var body = {
        process_id : selectedProcess.process_id,
        student_id : this.props.studentDetails.user_info.student_id
      }
      let matrixStatusResp = await Auth.Matrix.checkWithdrawComplete(body)

      selectedProcess.matrix_status.is_restrict = 0;
      processList.map((process, index) => {
        if(process.process_id == selectedProcess.process_id){
          processList[index].matrix_status.is_restrict = 0;
        }
      })
      this.setState({ selectedProcess, processList });


    }
    catch(e){
      var matrixError = formattedMatrixError(e.response.data.error.error_data)
      var formattedError = <ol>
      {matrixError.map((error) => <li>{error}</li>)}
      </ol>
      this.setState({ message: formattedError});
      this.setState({ errorbar: true });
    }
    this.setState({
      isLoading: false
    })
  }


  handleSelected = (selectedPage) => {
    this.setState({ currentPage: selectedPage - 1 });
  }


  handleProcessApprovalPayment = async (status) => {
    let { selectedProcess } = this.state;
    this.setState({isLoading: true})
    try{
      var resp = await Auth.Student.getProcessApprovalOrder({approval_status: status, process_id : selectedProcess.process_id, process_student_mapping_id : selectedProcess.student_mapping_data.process_student_mapping_id, student_id: this.props.studentDetails.user_info.student_id});
      var orderInfo = resp.data;
      var studentInfo = this.props.studentDetails.user_info;
      if(orderInfo.status === "paid"){
        this.setState({isLoading: false})
        this.processApprovalUpdate(status, orderInfo.payment_id);
        return;
      }
      const options = {
        key: process.env.REACT_APP_RAZORPAY_ID,
        name: "Drive Registration",
        description: "Drive - "+selectedProcess.process_name,
        order_id : orderInfo.order_id,
        image: "https://institute.reculta.com/images/reculta_logo.png",
        prefill: {
          "name": getFullName(studentInfo),
          "email": studentInfo.email,
          "contact": studentInfo.mobile
      },
        handler: async (response) => {
          // console.log(response)
          this.processApprovalUpdate(status, response.razorpay_payment_id);
        },
        theme: {
          color: "#686CFD",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
      this.setState({isLoading: false})
      return;
    }
    catch(e){
      console.log(e)
      this.setState({isLoading: false})
      if (e.response) {
        this.setState({ message: e.response.data.error.toString() });
        this.setState({ errorbar: true });
      } else {
        this.setState({ message: 'Sorry! something went wrong. Please try again' });
        this.setState({ errorbar: true });
      }
    }
   

  }


  processApprovalUpdate = async (status, payment_id) => {
    let {selectedProcess} = this.state;
    if(selectedProcess.file_id && !selectedProcess.policyAccepted && status === 1){
      this.setState({isLoading: true})
      var files = await getFiles([selectedProcess.file_id]);
      selectedProcess.file = files[selectedProcess.file_id] ? files[selectedProcess.file_id][0] : null;
      this.setState({selectedProcess, isLoading: false})
      return;
    }
    if(status === 1 && selectedProcess.payment && !payment_id){
      this.handleProcessApprovalPayment(status);
      return;
    }
    if(status === 2 && !selectedProcess.comment){
      this.setState({ errorbar: true, message: 'You must give the Reason', isRejectInvitation: false });
      return;
    }
    this.setState({isLoading: true})
    try{
      var body = {
        process_id : selectedProcess.process_id,
        student_id : this.props.studentDetails.user_info.student_id,
        process_student_mapping_id : selectedProcess.student_mapping_data.process_student_mapping_id,
        approval_status : status,
        payment_id,
        comment : selectedProcess.comment
      }
      await Auth.Student.updateProcessInvitation(body);
      this.setState({isRejectInvitation : false})
      await this.init();  
      if(status === 1) this.setState({isSuccessApproval: true});    
    }
    catch(e){
      this.setState({ errorbar: true , message: e.response ? e.response.data.error.toString() : 'Sorry! something went wrong. Please try again' });
    }
    this.setState({isLoading: false})
  }

  acceptOffer = async (offer) => {
    let {selectedProcess} = this.state;
    this.setState({isLoading: true})
    try{
      var body = {
        offer_id : offer.offer_id
      }
      await Auth.Offer.acceptOffer(body);
      this.setState({isConfirmation : false});
      await this.fetchOfferData(selectedProcess);  
    }
    catch(e){
      this.setState({ errorbar: true , message: e.response ? e.response.data.error.toString() : 'Sorry! something went wrong. Please try again' });
    }
    this.setState({isLoading: false})
  }



  render() {
    let {blockedApplications, currentPage, rowsPerPage,  applicationId, tabs, selectedTab, selectedView, processList, selectedProcess, isLoading, applicationList, files, lastOffer, withrawCompleteCounter, offerData, isConfirmation} = this.state;
    let selectedTabData = tabs[selectedTab].filter(application => {
      let name = application.company.company_name || '';
      let role_name = application.company_profile.role_name || '';
      if (name.toLowerCase().indexOf(this.state.query.toLowerCase()) > -1 || role_name.toLowerCase().indexOf(this.state.query.toLowerCase()) > -1)
        return true;
      else return false;
    })
    return (
      this.state.studentAppicationStatus !== 1?
      <Container className={"apply" + (this.props.isPT ? " apply--no-edit" : "")}>

        <Loader show={isLoading} />
        {(!isLoading || applicationList.length > 0) && <Aux>
          <Row className="justify-content-between align-items-center">
          <Col xs={12}>


          {
            processList.map(process => {
              return (
                <Button className="mr-3 mt-3" color={selectedProcess.process_id == process.process_id ? "blue-3":"blue"} key={process.process_id} onClick={() => this.handleProcessChange(process)}>{process.process_name}</Button>
              )
            })
          }

          </Col>

          {/* <Col xs="auto" >
          <Row className="align-items-center">
          <Col xs="auto" className="px-2">
          <Button className="font-weight-normal px-2 py-0" color="brandA">Sector<i className="ml-3 r-text--sm fi flaticon-arrow" /></Button>
          </Col>
          <Col xs="auto" className="px-2">
          <Button className="font-weight-normal px-2 py-0" color="brandA">Shortlists Status<i className="ml-3 r-text--sm fi flaticon-arrow" /></Button>
          </Col>
          <Col xs="auto" className="px-2">
          <Button className="font-weight-normal px-2 py-0" color="brandA">Status<i className="ml-3 r-text--sm fi flaticon-arrow" /></Button>
          </Col>
          <Col xs="auto" className="">
          <span className="px-3 d-inline-block r-subheading-4 ">Sort by</span>
          <SelectList selected="Latest Added" className="d-inline-block r-text--sm">
          <li>Latest Added</li>
          <li>Deadline</li>
          <li>Date Announced</li>
          <li>CTC</li>
          </SelectList>
          </Col>
          </Row>
          </Col> */}

          </Row>
      {(selectedProcess && selectedProcess.student_mapping_data.approval_status === 1) || !selectedProcess ? 
      <Aux>
        <Row className="mt-5 align-items-center">
          <Col xs={9}>
            <Row noGutters>
              {
                Object.keys(tabs).map(tab => {
                  return (
                    <Col key={tab}>
                      <Button onClick={() => this.setState({ selectedTab: tab, applicationId: null, isOpenOffers: false })} className={`px-2 text-left r-btn--no-radius ${selectedTab === tab && !this.state.isOpenOffers ? "apply__tab--selected" : ""}`}>
                      <span className="d-block r-heading-4 r-text--brandB">{tabs[tab].length}</span>
                      <span className="d-inline-block r-subheading-4">{tab}</span>
                      </Button>
                    </Col>
                  )
                })
              }
              <Col >
                <Button onClick={() => this.setState({ isOpenOffers: true })} className={`px-2 text-left r-btn--no-radius  ${this.state.isOpenOffers ? "apply__tab--selected" : ""}`}>
                  <span className="d-block r-heading-4 r-text--brandB">{offerData.length}</span>
                  <span className="d-inline-block r-subheading-4">Offers</span>
                </Button>
              </Col>
            </Row>
          </Col>
          {selectedProcess && selectedProcess.matrix_status && selectedProcess.matrix_status.is_restrict ?
            <Col className="text-right">
              
            {lastOffer.withdraw_deadline && <span className="r-subheading-4 r-text--brandF">{moment(lastOffer.withdraw_deadline).format("Do MMM YYYY, hh:mm a")}<br /></span>}
            {withrawCompleteCounter && <span className="r-subheading-4 r-text--brandF">{`${withrawCompleteCounter.asDays() < 0 ? 0 : Math.floor(withrawCompleteCounter.asDays())}d:${withrawCompleteCounter.hours() < 0 ? 0 : withrawCompleteCounter.hours()}h:${withrawCompleteCounter.minutes() < 0 ? 0 : withrawCompleteCounter.minutes()}m:${withrawCompleteCounter.seconds() < 0 ? 0 : withrawCompleteCounter.seconds()}s`} left to withdraw complete</span>}
            {selectedProcess.matrix_status.is_freezed ? <span className="r-text--sm"><br />Freezed due to Matrix Restriction.</span> : <Button color="blue" className="r-text--sm my-1" onClick={this.checkWithdrawComplete}>Withdraw Complete</Button>}
            </Col>:''}
            </Row>
            {!this.state.isOpenOffers ? 
            <Row>
            <Col xs={12}>
            <div className="apply__jobs">
              <Row className="apply__jobs__header">
                <Col xs={4} className="py-2">
                  <div className="text-middle">
                  <InputField className="w-100 r-radius--3 pr-5" placeholder="Search for Companies and Positions" onChange={(e) => this.setState({ query: e.target.value, currentPage: 0 })} />
                  <i style={{ position: 'absolute', marginLeft: '-30px' }} className="pt-2 r-text--grey-4 fi flaticon-search"></i>
                  </div>
                </Col>
                <Col xs={8} className=" text-right">
                  <ToggleButton buttonType="st" selected={selectedView} onToggle={this.toggleView} values={{ value1: "Status View", value2: "Performance View" }} />
                </Col>
              </Row>
              <div className="apply__jobs__list">
              {
                selectedTabData.length > 0 ?
                selectedTabData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map((application, i) => {
                  let isMultirole = (i > 0 && application.application_binder_id !== null && application.application_binder_id === selectedTabData[i - 1].application_binder_id) ? " apply__jobs__multirole" : ""
                  let isFirstrole = (i > 0 && application.application_binder_id !== null && application.application_binder_id !== selectedTabData[i - 1].application_binder_id) ? " apply__jobs__firstrole" : ""
                  return (
                    <Row onClick={(e) => this.showApplicationDetails(application.application_id, e)} key={application.application_id} className={"apply__jobs__company py-3 align-items-center" + isMultirole + isFirstrole}>
                    <Application files={files} view={selectedView} application={application} isMultirole={isMultirole ? true : false} isFirstrole={isFirstrole ? true : false} multipleLocation={this.binders[application.application_binder_id]} studentDetails={this.props.studentDetails.student_info[0]} />
                    <Collapse isOpen={applicationId === application.application_id} className="w-100" >
                      <div className="apply__application">
                        <ApplicationDetail studentDetails={this.props.studentDetails.student_info[0]} settings={this.props.settings} process={selectedProcess} resumes={this.state.resumes} fetchApplications={this.fetchApplications} setParentState={this.setParentState} application={application} isDetailsOpen={this.state.isDetailsOpen} multirole={this.multiroleApplications[application.application_binder_id]} />
                      </div>
                    </Collapse>
                    </Row>
                  )
                })
                :
                <Row className="">
                  <Col className="text-center">
                    <img src="/images/noresults.png" alt="" />
                    <h1 className="r-heading-1 r-text--grey-4">Sorry!</h1>
                    <span className="r-text--grey-4">You have no applications here.</span>
                  </Col>
                </Row>
              }
              </div>
            
              <Row className="justify-content-between align-items-center" >
                 <Col xs="auto" className="">
                   <Row className="justify-content-between align-items-center">
                     <span className="px-1 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>Total companies are {tabs[selectedTab].length}</span>
                     <span className="px-3 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>View</span>
                     <div className="r-dropdown border-0">
                       <select value={rowsPerPage} name="rowsPerPage" className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} onChange={(e) => { this.setState({ [e.target.name]: e.target.value, currentPage: 0 }) }}>
                         <option value="10" >10</option>
                         <option value="15">15</option>
                         <option value="20" >20</option>
                         <option value="25">25</option>
                         <option value="50" >50</option>
                         <option value="100">100</option>
                       </select>
                       <i className="fi flaticon-arrow" />
                     </div>
                   </Row>
                 </Col>
                 <Col xs="auto">
                   <PaginationComponent
                     totalItems={tabs[selectedTab].length}
                     pageSize={rowsPerPage}
                     onSelect={this.handleSelected}
                     maxPaginationNumbers={3}
                     activePage={currentPage + 1}
                   />
                 </Col>
               </Row>
            </div>
            </Col>
            </Row>
            : 
            <Row className="apply__jobs__header pt-5">
              <Col>
                {offerData.map(offer => 
                  <Row className="apply__jobs__company py-3 align-items-center">
                    <Col xs={3}>
                      <span className="r-heading-4 r-text--brandA d-block">{offer.company_name}</span>
                      <span className="r-subheading-3">{offer.location}</span>
                    </Col>
                    <Col xs={3}>
                      <span className="d-block r-subheading-3">{offer.role_name || "Product Manager"}</span>
                      <span className="d-block r-subheading-4">{offer.designation || "-"}</span>
                      <span className="d-block r-text--sm">{offer.currency} {offer.ctc_value.toLocaleString("en-IN")}</span>
                    </Col>
                    <Col xs={3}>
                    {offer.accept_deadline && <span className="d-block r-text--sm">Accept Deadline : {moment(offer.accept_deadline).utcOffset("+05:30").format("Do MMM YYYY hh:mm a")}</span>}
                    </Col>
                    <Col xs={2} className="text-right">
                      {offer.accept_status ? 
                      <span className="r-subheading-3">Accepted</span>
                      :
                        <Button color="blue-3" onClick={() => this.setState({isConfirmation : () => this.acceptOffer(offer)})}>Accept</Button>
                      }
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            }
          </Aux>
          :
          <Aux>
            <Row className="mt-5">
              <Col className="text-center mt-5"><span>You are invited to this placement drive. Accept invitation if you want to participate in this drive</span></Col>
            </Row>
            {!!selectedProcess.deadline && 
            <Row className="mt-3">
              <Col className="text-center r-subheading-3 mt-5">Registration Deadline : <span>{moment(selectedProcess.deadline).utcOffset("+05:30").format("Do MMM YYYY, hh:mm a")}</span></Col>
            </Row>}
            {!!selectedProcess.payment && 
            <Row className="mt-3">
              <Col xs={12} className="text-center r-subheading-4 mt-5">Registration Amount : <span>Rs. {selectedProcess.payment}</span></Col>
              {
              // this.props.studentCVDetails.user_info.state ? 
              ((this.props.studentCVDetails.user_info.state || "").toLowerCase().includes("delhi") ? 
                <Aux>
                  <Col xs={12} className="text-center r-subheading-4 mt-1">CGST @9% : <span>+ Rs. {selectedProcess.payment * 9 /100}</span></Col>
                  <Col xs={12} className="text-center r-subheading-4 mt-1">UTGST @9% : <span>+ Rs. {selectedProcess.payment * 9 /100}</span></Col>
                </Aux>
                :
                <Col xs={12} className="text-center r-subheading-4 mt-1">IGST @18% : <span>Rs. {selectedProcess.payment * 18 /100}</span></Col>
              )
              // :""
              }
              {this.props.studentCVDetails.user_info.state && <Col xs={12} className="text-center r-subheading-3 mt-1">Total Amount : <span>Rs. {selectedProcess.payment + (selectedProcess.payment * 18 /100)}</span></Col>}
            </Row>
            }
            {/* {selectedProcess.payment && !this.props.studentCVDetails.user_info.state ?
            <Row>
              <Col xs={12} className="text-center mt-5 text-danger">
                <span>Please fill address in your profile</span>
              </Col>
            </Row>
            : */}
            <Row className="mt-5 justify-content-center">
              <Col xs={12} className="text-center mt-5">
                <Button onClick={() => this.processApprovalUpdate(1)} color="brandA"  className={`px-2 text-left r-btn--no-radius`}>Accept</Button>
                <Button id="reject_process_approval_1" color="brandB"  className={`px-2 text-left r-btn--no-radius ml-5`}>Not Interested</Button>
              </Col>
              <UncontrolledCollapse toggler="#reject_process_approval_1">
                <Col xs={12} className="mt-3">
                  <textarea onChange={(e) => {selectedProcess.comment = e.target.value; this.setState({selectedProcess})}} value={selectedProcess.comment} required className="r-inputfield" style={{width: 400}} name="comment" rows={4} placeholder="*Reason for not participating in this drive"  />
                </Col>
                <Col xs={12} className="text-center mt-3">
                  <Button color="brandB" type="button" onClick={() => this.setState({isRejectInvitation : true})}  className={`px-2 text-left r-btn--no-radius ml-5`}>Save</Button>
                </Col>
                <Modal centered={true} isOpen={this.state.isRejectInvitation}  toggle={() => this.setState({ isRejectInvitation: false })}>
                  <ModalBody className="m-3">
                    <span>You have indicated that you are not interested for {selectedProcess.process_name}. You will not be able to see applications posted by your Placement Team and this Drive will be closed for you.</span>
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={() => this.processApprovalUpdate(2)} color="dark">OK</Button>
                    <Button onClick={() => this.setState({isRejectInvitation : false})} color="link" className="ml-2">Cancel</Button>
                  </ModalFooter>
                </Modal>
              </UncontrolledCollapse>
            </Row>
            {/* } */}
            <Row className="mt-5 pt-5 justify-content-center">
              <Col xs={7} className="pt-5 mt-5">
                <Policies />
              </Col>
            </Row>
          </Aux>
          }
            </Aux>}
            <Modal centered={true} isOpen={this.state.isSuccessApproval}>
              <ModalBody className="m-3">
                <span>Congratulations! <br />Your registration for {selectedProcess && selectedProcess.process_name} is Complete Now! You will soon start seeing applications posted by your Placement Team here.</span>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() =>  this.setState({isSuccessApproval: false})} color="dark">OK</Button>
              </ModalFooter>
            </Modal>
            <Modal centered={true} isOpen={selectedProcess && selectedProcess.file && !selectedProcess.policyAccepted}>
              <ModalBody>
                <iframe title="Placement Policy" src={selectedProcess && selectedProcess.file && selectedProcess.file.url} className="w-100" height="500"></iframe>
              </ModalBody>
              <ModalFooter>
              <Button color="outline-dark-v" square={true} onClick={() => {selectedProcess.file = null; this.setState({selectedProcess})}}>Exit</Button>
                {' '}
              <Button color="dark" square={true} onClick={async () => {selectedProcess.policyAccepted = true; await this.setState({selectedProcess}); this.processApprovalUpdate(1)}}>Agree</Button>
              </ModalFooter>
            </Modal>
            <Confirmation isOpen={!!isConfirmation} toggleConfirmation={() => this.setState({isConfirmation: false})} callback={isConfirmation} />
            <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
            <HelpSection tab={"applyTab"} />
      </Container> : <Container><div align="center" className="mt-4">  <img src="/images/noresults.png" alt="" />
        <h1 className="r-heading-1 r-text--grey-4">Sorry!</h1>
        <span className="r-text--grey-4">{this.props.studentDetails.student_info[0].block_message ||  "Your applications are blocked. Kindly Contact your Placement Team"}</span></div> <HelpSection tab={"applyTab"} /></Container>
    )
  }
}

export default Apply
