import React, { Component } from 'react';
import { MultiSelectList, Select } from '../../global/Forms/Select'
import { Row, Col, Container, Table, Modal, ModalBody, Collapse } from 'reactstrap';
import SpocNav from './SpocNav';
import companyAll from './data/companyALL.json'
import { Auth } from '../../../actions/Auth';
import Axios from 'axios';
import moment from 'moment';
import store from '../../../store';
import { SingleDatePicker } from '../../global/Forms/DatePicker';
import { Loader } from '../../global/Loader';
import { CONSTANTS } from '../../../constants';
import { getFullName } from '../../../actions';
import { InputField } from '../../global/Forms';
import Button from '../../global/Button';
import { Switch } from '../../global/Forms/ToggleSwitch';
import { PermissionDenied } from '../../global/PermissionDenied';


class CompanyReport extends Component {
	  constructor(props) {
	    super(props);
	    this.state = {
  			nav: { process_name: "Company Report", process_type: "company-report", process_id: 5 },
    		processList: [],
		    collegeList:[],
			campusList:[],
			query:"",
		    selectedCollege:'',
		    selectedCampus:'',
		    selectedProcess:[],
			companyList:[],
			isOpenDateFilter : {},
			isOn: false,
			isOpenDownload: false,
			isDownloadByDepartment: false,
			selectedDegree : [],
			selectedDepartment: [],
			degreeDepartmentList: [],
		    companyColumns:[{ id: "sno", title: "  # " }, { id: "company_name", title: "Company Name" }, { id: "eligible", title: "Eligible" }, { id: "applied", title: "Applied" },  { id: "initial_shortlist", title: "Initial Shortlist" }, { id: "round_1_count", title: "Round 1" }, { id: "round_2_count", title: "Round 2" },  { id: "round_3_count", title: "Round 3" }, { id: "round_4_count", title: "Round 4" } , { id: "offer_count", title: "Offer" }],
			companyDataView: []
	    };
	  }


	  componentDidMount () {
		let permissions = store.getState().role.hasPermission;
		if (store.getState().role.role_type === "PT" && !(permissions.reportCompany && permissions.reportCompany.permission_access && permissions.reportCompany.permission_access.read)) {
			this.props.history.push("/pt/performance/attendance-report")
			return
		}
		this.init()
	  }


	  init = async () => {
	    let redux = store.getState(),
	      { instituteDetail } = redux.role;
	    let params = {}
	    if (instituteDetail.campus_id) {
	      try {
	        let processResp = await Auth.Processes.getProcesses(instituteDetail.campus_id)
	        this.setState({
	          selectedCampus:instituteDetail.campus_id,
	          processList: processResp.data
	        })
	        if (processResp.data.length > 0)
	          params.process_id = [processResp.data[0].process_id]

	        await this.getcompanyReports(params)
	      } catch (e) { console.log(e) }
	    }
	    else {
	        this.instiDetail();
	    }
	  }


	  instiDetail = async () =>{
	      let campusResTemp, degreeList;
	      await Axios.all([Auth.Spoc.getInstitutes()])
	      .then(Axios.spread((res1, res2) => {
	        campusResTemp = res1;
	      }));
	      let detailsParams = new URLSearchParams();
	      campusResTemp.data.forEach((item, i)=>{
	        detailsParams.append("college_id", item.college_id);
	        detailsParams.append("campus_id", item.campus_id);
	      })
	      let details = await Auth.Spoc.getInstituteDetails(detailsParams);
	      this.setState({
	        campusList:details.data.campusDetails,
	        collegeList:details.data.collegeDetails,
	        selectedCollege:details.data.campusDetails[0].college_id,
	      })
	  }


  changeCollege = (target) => {
    this.setState({
      selectedCollege: target.value,
      selectedCampus:"",
      selectedProcess:[],})
  }

  changeCampus = async (target) =>{
    this.setState({selectedCampus: target.value, selectedProcess:[]});
    if(target.value === ""){
      this.setState({processList: []})
      return
  	}
    this.setState({isLoading: true});
      try {
        let params = {}
        let processResp = await Auth.Processes.getProcesses(target.value)
        this.setState({
          processList: processResp.data
        })
        if (processResp.data.length > 0)
          params.process_id = [processResp.data[0].process_id]
        await this.getcompanyReports(params)
      } catch (e) { console.log(e) }
    this.setState({isLoading: false});
  }

  getcompanyReports = async (params) => {
		this.setState({ isLoading: true, selectedProcess:params.process_id });
	      
	      try {
	        let res = this.state.isOn ? await Auth.Reports.companyreportDownloadCompanyGrouped(params) :  await Auth.Reports.companyreportDownload(params);
	        this.setState({companyList:res.data})
	        this.setState({isLoading: false});
	      } catch (e) {
	          this.setState({isLoading: false});
	          console.log(e)
	      }
  }

  onSearch = (target) => {
    this.setState({ query: target.value });
    if (target.value !== "") {
      this.setState({ currentPage: 0 });
    }
  }


  downloadFile = async() => {
       this.setState({isLoading: true});
	   let {selectedProcess, selectedDepartment, isDownloadByDepartment} = this.state;
      
      try {
		  let res;
		if(isDownloadByDepartment){
			if(!selectedDepartment.length){
				this.setState({isLoading: false});
				return window.alert('Please select Degree/Department');
			}
			res = await Auth.Reports.companyreportByDepartmentDownload({degree_department_id : selectedDepartment});
		}
        else res = await Auth.Reports.companyreportDownload({download :true,process_id : selectedProcess});
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = 'Company Report'+'.csv';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({isLoading: false});
      } catch (e) {
          this.setState({isLoading: false});
          console.log(e)
      }
  }

  processChangeHandler = async ( process_id, data, type ) =>{
	this.setState({companyDataView : []});
    try{
      var body = {
        "application_id" : data.application_id ,
        "process_id" : process_id,
        "filter" : type,
        type: "company_report"
      }
      let applicationResp = await Auth.Reports.studentReportView(body);
    
     
      this.setState({companyDataView : applicationResp.data});

    }
    catch(e){
      console.log(e);
    }
    this.setState({modalLoading : false});
  }

  handleOfferButton = async (processId, data) =>{
	this.setState({isLoading : true});

    try{
		var body = {
			process_id: processId,
			role_id: data.role_id,
    		campus_company_id: data.campus_company_id,
		}
      let offerResp = await Auth.Offer.getCompanyOffers(body);
      this.setState({companyDataView: offerResp && offerResp.data ? offerResp.data : []});
    }
    catch(e){
      console.log(e);
    }
    this.setState({isLoading : false});
  }
  filterByDate = () => {
    let { selectedProcess, isOpenDateFilter } = this.state;
    isOpenDateFilter.is_applied = true;
    isOpenDateFilter.is_open = false;
    this.setState({isOpenDateFilter});
    this.getcompanyReports({process_id : selectedProcess, start_date : moment(isOpenDateFilter.start_date).toDate(), end_date : moment(isOpenDateFilter.end_date).toDate()});
  }
  clearDateFilter = () => {
    let { selectedProcess } = this.state;
    this.setState({isOpenDateFilter : {}});
    this.getcompanyReports({process_id : selectedProcess});
  }


  handleToggle = async (e) => {
	await this.setState({isOn: !this.state.isOn});
	this.getcompanyReports({process_id: this.state.selectedProcess});
  }

  selectProcess =  (e) => {
    let {selectedProcess} = this.state;
    let value = parseInt(e.target.value);
    if(e.target.checked){
      selectedProcess.push(value);
    }
    else{
      if(selectedProcess.length === 1) return;
      selectedProcess.splice(selectedProcess.indexOf(value), 1);
    }
    this.getcompanyReports({process_id : selectedProcess});
  }


  	fetchDegreeDepartment = async () => {
		this.setState({isLoading: true});
		  try{
			var resp = await Auth.General.getDegreesCampus({campus_id: this.state.selectedCampus});
			this.setState({degreeDepartmentList : resp.data});
		  }
		  catch(e){
			console.log(e)
		  }
		  this.setState({isLoading: false});
	  }


	  selectDegree = (e) => {
		let {selectedDegree, degreeDepartmentList} = this.state;
		let value = parseInt(e.target.value);
		if(e.target.checked) selectedDegree.push(value);
		else{
			if(selectedDegree.length === 1) return;
			selectedDegree.splice(selectedDegree.indexOf(value), 1);
		}
		var selectedDepartment = degreeDepartmentList.filter(degree => selectedDegree.indexOf(degree.campus_degree_id) >= 0).reduce((prev, degree) => prev.concat(degree.departments.map(dep => dep.degree_department_id)), [])
		this.setState({selectedDegree, selectedDepartment})
	  }

	  selectDepartment = (e) => {
		let {selectedDepartment} = this.state;
		let value = parseInt(e.target.value);
		if(e.target.checked) selectedDepartment.push(value);
		else{
			if(selectedDepartment.length === 1) return;
			selectedDepartment.splice(selectedDepartment.indexOf(value), 1);
		}
		this.setState({selectedDepartment})
	  }

	  render(){
		
	  	let { nav, collegeList, campusList,isLoading, selectedCampus,selectedCollege,selectedProcess,query, processList,companyColumns, companyList, isOpenDateFilter, companyDataView} = this.state;
  	    const redux = store.getState();
  	    let campusDataList = campusList.filter(campus =>{
		      return campus.college_id == selectedCollege;
			});
			companyList = companyList.filter(row => {
				let name = row.company_name || "";
				return name.toLowerCase().includes(query.toLowerCase())
			})
	        return (
		        	<Container className="apply">
       							<Loader show={isLoading} />
		        		        <SpocNav process={nav} downloadReport={() => { this.setState({isOpenDownload: true}) }} type="CompanyReport" />
		        		        {(redux.role.instituteDetail.campus_id > 0 && processList.length === 0) ? <p>No processes added</p> : ""}
						        <Row className="mx-0 my-3 justify-content-between">
						        {collegeList.length > 0 &&
						            <Col xs="3">
						              <Select onChange={({ target }) => this.changeCollege(target)}>
						                {collegeList.map((college, i) =>
						                  <option key={i} value={college.college_id}>{college.name}</option>
						                )}
						              </Select>
						            </Col>
						        }
						        {campusDataList.length > 0 &&
						            <Col xs="3">
						              <Select value={selectedCampus} onChange={({ target }) => this.changeCampus(target)}>
						                  <option value="">Select Campus</option>
						                  {campusDataList.map((campus, i) =>
						                    <option key={i} value={campus.campus_id}>{campus.name}</option>
						                  )}
						              </Select>
						            </Col>
						        }
						        {processList.length > 0 &&
						            <Col xs="3">
						              {/* <Select value={selectedProcess} onChange={({ target }) => this.getcompanyReports({ process_id: target.value })}>
						                {processList.map((process, i) =>
						                  <option key={i} value={process.process_id}>{process.process_name}</option>
						                )}
						              </Select> */}
									  	<MultiSelectList title={selectedProcess.length ? `${selectedProcess.length} Placement Drive Selected`: "Select Placement Drive"}>
											<ul className="list-unstyled m-0">
											{processList.map((process, i) => {
												return (
												<li key={i}>
													<input name="value" onChange={(e) => this.selectProcess(e)} checked={selectedProcess.indexOf(process.process_id) >= 0}  type="checkbox" value={process.process_id} />
													<label>{process.process_name}</label>
												</li>
												)
											})}
											</ul>
										</MultiSelectList>
						            </Col>
						        }
								 <Col xs={3} className="pl-2 pull-right">
									<div>
									<InputField onChange={(e) => {this.onSearch(e.target)}} className=" r-radius--3 pr-5 ml-4" style={{width: '190px'}} placeholder="Search for Company" />
									<i style={{ marginLeft: '-30px' }} className="pt-2 r-text--grey-4 fi flaticon-search"></i>
									<Button onClick={() => {isOpenDateFilter.is_open = !isOpenDateFilter.is_open; this.setState({isOpenDateFilter})}} className="font-weight-normal px-2 py-0 ml-4" color="blue3"><img style={{ width: "21px" }} src="/images/filter-512.png" alt="" /></Button>
									</div>
								</Col>
						        </Row>
								{ isOpenDateFilter && isOpenDateFilter.is_applied && <p className="text-right "><em className="text-muted r-text--sm">Data showing for {moment(isOpenDateFilter.start_date).format("Do MMM YYYY")} - {moment(isOpenDateFilter.end_date).format("Do MMM YYYY")}</em></p>}
								<Collapse isOpen={this.state.isOpenDateFilter.is_open}>
								<Row className="justify-content-end align-items-end">
								<Col xs={3}>
									<span className="r-subheading-4">From Date</span>
									<SingleDatePicker
										value={isOpenDateFilter.start_date}
										onDateChange={(date) => { isOpenDateFilter.start_date = date; this.setState({ isOpenDateFilter }) }}
									/>
								</Col>
								<Col xs={3}>
									<span className="r-subheading-4">To Date</span>
									<SingleDatePicker
										value={isOpenDateFilter.end_date}
										onDateChange={(date) => { isOpenDateFilter.end_date = date; this.setState({ isOpenDateFilter }) }}
									/>
								</Col>
								<Col xs={1}>
									{isOpenDateFilter.is_applied ? 
									<Button onClick={this.clearDateFilter} color="dark" square={true}>Clear</Button>
									:
									<Button onClick={this.filterByDate} color="dark" square={true}>Filter</Button>
									}
									</Col>
								</Row>
								</Collapse>
						        {/* <Row className="justify-content-between align-items-center mx-0">
						            <span className="r-heading-3">Company Report</span>
						        </Row> */}

								<Row className="justify-content-between mx-0">
						            <Col><span className="r-heading-3">Company Report</span></Col>
									{/* <Col xs="auto">
										<div className="d-inline-block"><Switch  isOn={this.state.isOn} handleToggle={this.handleToggle}/></div>
										<div className="d-inline-block ml-2">{this.state.isOn ? "Company Wise" : "Application Wise"}</div>
									</Col> */}
						        </Row>

					            <Table className="post">
					              <thead>
					                <tr className="tableheader">
					                  {companyColumns.map((col, i) =>
					                    <th key={i}>
					                      {col.title}
					                    </th>

					                  )}
					                </tr>
					              </thead>
					              <tbody>
					                { (companyList.length>0 )&&
					                  companyList.map((row, i) => {
										  var name = (row.company_name|| "-")+(this.state.isOn ? "":" - "+(row.role_name || ""));
					                    return (
					                      <tr
					                        className="borderBottomlightblack"
					                        key={i}>
					                        <td style={{ textAlign: "center" }}>
					                          {i+1}
					                        </td>
					                        <td>
					                          { (name.slice(0,35)).length==35? name.slice(0,35)+"..." : name.slice(0,35) }
					                        </td>
						                    <td >
						                        <Button onClick={() =>this.processChangeHandler(selectedProcess , row , 'ELIGIBLE' )}>{row.eligible || 0}</Button>
						                    </td>
						                    <td >
						                        <Button onClick={() =>this.processChangeHandler(selectedProcess , row ,'APPLIED')}>{row.applied || 0}</Button>
						                    </td>
											<td >
						                        <Button onClick={() =>this.processChangeHandler(selectedProcess , row ,'ROUND_1')}>{row.initial_shortlist || 0}</Button>
						                    </td>
						                    <td >
						                        <Button onClick={()=> this.processChangeHandler(selectedProcess , row ,'ROUND_2')}>{row.round_1_count || 0}</Button>
						                    </td>
						                    <td >
						                        <Button onClick={()=> this.processChangeHandler(selectedProcess , row ,'ROUND_3')}>{row.round_2_count || 0}</Button>
						                    </td>
						                    <td >
						                        <Button onClick={()=> this.processChangeHandler(selectedProcess , row ,'ROUND_4')}>{row.round_3_count || 0}</Button>
						                    </td>
						                    <td >
						                        <Button onClick={()=> this.processChangeHandler(selectedProcess , row ,'ROUND_5')}>{row.round_4_count || 0}</Button>
						                    </td>
						                    <td >
						                        <Button onClick={() =>this.handleOfferButton(selectedProcess , row)}>{row.offer_count || 0}</Button>
						                    </td>
					                      </tr>
					                    )
					                  })
					                }
					              </tbody>
					            </Table>

								<Modal centered isOpen={this.state.isOpenDownload} toggle={() => this.setState({isOpenDownload: false, selectedDegree: [], selectedDepartment: []})}>
									<ModalBody>
										<Row className="py-4">
											<Col>
												<span><input type="checkbox" onChange={() => {!this.state.isDownloadByDepartment && this.fetchDegreeDepartment(); this.setState({isDownloadByDepartment : !this.state.isDownloadByDepartment})}} /> Download list of companies in which following selection of students are eligible</span>
											</Col>
										</Row>
										<Collapse isOpen={this.state.isDownloadByDepartment}>
											<Row className="py-4">
												<Col>
													<MultiSelectList title={this.state.selectedDegree.length ? `${this.state.selectedDegree.length} Degree Selected`: "Select Degree"}>
														<ul className="list-unstyled m-0">
														{this.state.degreeDepartmentList.map((degree, i) => {
															return (
															<li key={i}>
																<input name="value" onChange={(e) => this.selectDegree(e)} checked={this.state.selectedDegree.indexOf(degree.campus_degree_id) >= 0}  type="checkbox" value={degree.campus_degree_id} />
																<label>{degree.degree_name}</label>
															</li>
															)
														})}
														</ul>
													</MultiSelectList>
												</Col>
												<Col>
													<MultiSelectList title={this.state.selectedDepartment.length ? `${this.state.selectedDepartment.length} Department Selected`: "Select Department"}>
														<ul className="list-unstyled m-0">
														{this.state.degreeDepartmentList.map((degree, i) => {
                                                            if(this.state.selectedDegree.indexOf(degree.campus_degree_id) < 0) return false;
															return degree.departments.map((department, i) => {
																return (
																<li key={i}>
																	<input name="value" onChange={(e) => this.selectDepartment(e)} checked={this.state.selectedDepartment.indexOf(department.degree_department_id) >= 0}  type="checkbox" value={department.degree_department_id} />
																	<label>{degree.degree_name} - {department.department_name}</label>
																</li>
																)
															})

														})
														}
														</ul>
													</MultiSelectList>
												</Col>
											</Row>
										</Collapse>
										<Row className="py-4">
											<Col className="text-center">
												<Button  className="font-weight-normal px-2 py-0" color="brandB" onClick={()=>this.downloadFile()} >Download Report</Button>
											</Col>
										</Row>
									</ModalBody>
								</Modal>



								<Modal zIndex="99999" isOpen={companyDataView.length > 0} toggle={() => this.setState({ companyDataView: [] })}>
									<ModalBody>
									<Table>
										<thead>
										<tr className="tableheader">
											<th>Student Name</th>
											<th>Roll No</th>
										</tr>
										</thead>
										<tbody>
										{companyDataView.map((obj, i ) => {
											return <tr
											className="borderBottomlightblack"
											key={i}>
											<td>{getFullName(obj)}</td>
											<td>{obj.roll_no}</td>
											</tr>
										})}
										</tbody>
									</Table>
									</ModalBody>
								</Modal>
	    			</Container>
	        )
	  }
}
export default CompanyReport;
