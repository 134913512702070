import React from 'react';
import { Row,Col,Container,  Modal, ModalHeader,Nav, Collapse,NavItem, NavLink,TabContent, TabPane, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import classnames from 'classnames';

class ShareProfile extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      batchs:  [],
      courses: [],
      departments:  [],
      groups: [],
      batch:'',
      collapse: false,
      collapse1: false,
      activeTab: '1',
      course:'',
      dept: '',
      group:'',
    };
  }

  onChange = e => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }

 toggle = () => {
    this.setState({ 
      collapse: true,
      collapse1: false,
    });
  }

  toggle1 = () => {
    this.setState({ 
      collapse: false,
      collapse1: true,
    });
  }



  addbatch =(event) =>{
    event.preventDefault()
    this.state.batchs.push(this.state.batch);
    this.setState({batch:''});
  }

  addcourse =(event) =>{
    event.preventDefault()
    this.state.courses.push(this.state.course);
    this.setState({course:''});
  }

  adddept =(event) =>{
    event.preventDefault()
    this.state.departments.push(this.state.dept);
    this.setState({dept:''});
  }

  addgroup =(event) =>{
    event.preventDefault()
    this.state.groups.push(this.state.group);
    this.setState({group:''});
  }

  deleteItem (key){
    const {batchs} = this.state;
    this.state.batchs.splice(key, 1);
    this.setState({batchs});
  }


  render() {
    const {batchs, batch, group,groups, dept, departments, course, courses } =this.state;
    return (
        <Modal isOpen={this.props.modal} toggle={this.props.toggle} size="md"  style={{marginTop: "50px"}}>
          <div className="p-3">
             <Row  className="justify-content-start mx-0 my-2">
                  <span className="r-heading-3">Share Profile Book</span>
             </Row> 
             <Row  className="justify-content-start mx-0 my-3 ">
                <Col xs="6">
                  <Row className="align-items-center mx-0" >
                        <input type="radio" checked={this.state.collapse} onChange={this.toggle}/>
                        <span className="px-2 r-heading-3">Brief Data</span>
                  </Row>
                </Col>
                <Col xs="6">
                    <Row className="align-items-center mx-0" >
                      <input type="radio" checked={this.state.collapse1} onChange={this.toggle1}/>
                      <span className="r-heading-3">All Data</span>
                    </Row>
                </Col>
             </Row>
             <Row  className="justify-content-start mx-0 my-3 ">
                <Col xs="6">
                    <Collapse isOpen={this.state.collapse}>
                        <Row className="align-items-center mx-0" >
                             <Button onClick={this.toggle} className="font-weight-normal py-2" color="BrandA">Personal Summary</Button>
                        </Row>
                        <Row className="align-items-center mx-0" >
                             <Button onClick={this.toggle} className="font-weight-normal py-2" color="BrandA">Work Experiance</Button>
                        </Row>
                        <Row className="align-items-center mx-0" >
                              <Button onClick={this.toggle} className="font-weight-normal py-2" color="BrandA"> Age</Button>
                        </Row>
                        <Row className="align-items-center mx-0" >
                              <Button onClick={this.toggle} className="font-weight-normal py-2" color="BrandA"> Internship</Button>
                        </Row>
                    </Collapse>
                  </Col>
                  <Col xs="6">
                    <Collapse isOpen={this.state.collapse1}>
                      <Row className="align-items-center mx-0" >
                          <Button onClick={this.toggle} className="font-weight-normal py-2" color="BrandA"> All profile data</Button>
                      </Row>
                    </Collapse>
                  </Col>
             </Row>
             <Row  className="align-items-center mx-0 my-3">
                  <span className="r-heading-4 px-2">Link Generated</span>
                  <Col xs="12" className="pl-0 mx-2 ">
                    <form onSubmit={this.addgroup}>
                    <InputField className="r-nav__search" placeholder="Search" type="text" name="group" value={group} onChange={this.onChange}/>
                    </form>
                  </Col>
             </Row> 
             <Row  className="align-items-center mx-0 my-3 ">
                  <span className="r-heading-4 px-2">Profile Book name</span>
                  <Col xs="12" className="pl-0 mx-2 ">
                      <Row className="justify-content-between  mx-0 ">
                        <form onSubmit={this.addgroup} >
                        <InputField className="r-nav__search" placeholder="Search" type="text" name="group" value={group} onChange={this.onChange}/>
                        </form>
                         <Button onClick={this.toggle} className="font-weight-normal px-2 py-0" color="light" style={{color:"blue"}}> Save</Button>
                      </Row>
                  </Col>
             </Row> 
               <Row  className="justify-content-end align-items-center  my-2 mx-0" style={{ padding:"50px 0"}}>
                    <Button onClick={this.toggle} className="font-weight-normal px-3 py-0 mr-3" color="dark"> Share</Button>
               </Row>
          </div>
        </Modal>
    );
  }
}

export default ShareProfile;