import React from 'react';

export const InputField = props => {
  let classes = props.className ? props.className.split(" ") : [],
    type = props.type || "text",
    inputClass = (type === "checkbox") ? "r-check" : "r-inputfield",
    id = "field-" + Math.ceil(Math.random() * 5000),
    inputProps = {...props};
    delete inputProps.inputRef
  classes.push(inputClass);
  let field = <input ref={props.inputRef} {...inputProps} className={classes.join(" ")} id={props.id || id} />
  if (type === "checkbox") {
    return (
      <div>
        {field}
        <label htmlFor={props.id || id}>{props.value || ""}</label>
      </div>
    )
  }
  return field
}