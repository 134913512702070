export const HELP_SECTION_CONFIG = {
  studentTab : {
    "View Students" : [{
      video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/View+Students.mp4",
      text : "The Students tab can be used to view/edit students in a batch across departments and courses. New students can be added and the overall details of the batch can be viewed through this section.",
      image : "view_students_1.jpg"
    },
    {
      text : "Filters can be used only to view select students. ",
      image : "view_students_2.jpg"
    },
    {
      text : "Clicking on the name of any student allows a Placement team member to view the details of a student along with his/her applications. Each section of the student profile is clickable. The Placement team gets a “read only” view of the students’ profile.",
      image : "view_students_3.jpg"
      
      
    }],

    "Add Students" : [{
      video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Add+Students.mp4",
      text : "To Add a new student, click on the Add Student option, fill in the necessary details and save it. An account gets created for the student and an automated email with login credentials is received by the student on successful login creation.",
      image : "add_students_1.jpg",
    },
    {
      text : "For Adding multiple students, a csv file can be uploaded with the same details and multiple student accounts get created simultaneously.",
      image : "add_students_2.jpg",
      
    }],

     "Batch Snapshot" : [{
       video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Batch+snapshot.mp4",
       text : "Once the students fill in their profiles through their respective logins, a batch snapshot gets created for the placement team that graphically represents the student profile.",
       image : ""
     },
     {
       text :  "This can be downloaded and shared to potential recruiters.",
       image : ""
     },
     {
       text : "The batch snapshot can be viewed by clicking on Snapshot and selecting the correct batch from the dropdown.",
       image : "batch_snapshot.jpg",
     }],

    "Freeze Unfreeze Students" : [
      {
        video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Freeze+unfreeze+students.mp4",
        text : "Placement Team members can choose to freeze student profiles and unfreeze them at any given point in time.",
        image : ""
      },
      {
        text : "A timer is also present for determining the same. This feature allows the team members to give students a certain amount of time to edit their profiles, so that the students have a set profile ready ahead of placements.",
        image : ""
      },
      {
        text : "Additionally, any changes the students make can easily be tracked in this manner.",
        image : ""
      },
      {
        text : "To Freeze/ Unfreeze student profiles, select the students, and click on freeze/ Unfreeze and a popup opens up. Select the date and time and click on Submit.",
        image : "freeze_unfreeze_students_1.jpg",
      }
    ],
  },
  companiesTab : {
    "Add Companies" : [
      {
        video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Add+companies.mp4",
        text : "The Companies tab allows the Placement Team to manage the company database.",
        image : ""
      },
      {
        text : "To add a new company in the database, click on “Add Company”, fill in the details and save it.",
        image : "add_companies_1.jpg",
      }
    ],
    "Add Contacts" : [
      {
        video:"",
        text : "To add contacts, click on “Add Contacts”, fill in the details and save it. Multiple contacts can be added. The contact types could be HRs, alumni or business leaders of the company. Different contact points across offices, departments and hierarchy can be easily documented here.",
        image : "add_companies_2.jpg",
      }
    ],
    "Add Roles" : [
      {
        video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Add+roles.mp4",
        text : "To add contacts, click on “Add Contacts”, fill in the details and save it. Multiple contacts can be added. The contact types could be HRs, alumni or business leaders of the company. Different contact points across offices, departments and hierarchy can be easily documented here.",
        image : "add_companies_3.jpg",
      }
    ],
    "Map To Placement Drive" : [
      {
        video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Map+to+placement+drives.mp4",
        text : "A company can be mapped to different placement drives for similar or different roles. Each company can be mapped to various placement drives as follows:",
        image : "add_companies_4.jpg",
      }
    ],
    "Email Campaigns" : [
      {
        video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Email+campaigns.mp4",
        text : "Email campaigns can be sent to companies for better engagement through the Email Campaigns option. Standardized emails can be created for company outreach and sent to potential recruiters by the placement teams. The emails can be sent to talent acquisition heads, campus leads and other business leaders. Additionally, alumni working at these companies can be engaged through email campaigns.",
        image : ""
      },
      {
        text: "To create Email Campaigns, click on Start Campaigns. Fill in the necessary details on the popup that opens up and click on Save.",
        image : "add_companies_5.jpg"
      },
      {
        text : "To Send out this campaign, click on the campaign itself, select the companies that you wish to send it to and click on Send Email. Emails will be sent to all the companies." ,
        image : "",
      }
    ]
  },
  placementsTab : {
    "Creating A Process" : [
      {
        video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Creating+a+process.mp4",
        text : "Click on the Plus Button to create a new process.",
        image : ""
      },
      {
        text : "A popup opens up, fill in the required information and save it.",
        image : "placements_1.jpg",
      },
      {
        text : "Add students to the process by clicking on the plus button next to the add student option and select the relevant students.",
        image : "placements_2.jpg"
      },
      {
        text : "Add companies to the placement drive by searching for the companies in the database.",
        image : "placements_3.jpg",
      }
    ],
    "Opening An Application" : [
      {
        video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Opening+an+application.mp4",
        text : "To open a new application, select “Click to Start Application Process” next to the company added.",
        image : "placements_1_1.jpg"
      },
      {
        text : "A form opens up. Fill in the necessary details, upload the Job description and click on Save.",
        image : "placements_1_2.jpg"
      },
      {
        text : "You can add additional questions, set eligibility criteria and add multiple roles too as per the requirements of the company.",
        image : "placements_1_3.jpg"
      },
      {
        text : "To publish the application to the batch, toggle the “open” button, select the students and save it. A mail goes out to all the students asking them to apply to this company.",
        image : "placements_1_4.jpg",
      }
    ],
    "Adding Rounds" : [
      {
        video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Adding+rounds.mp4",
        text : "To add new rounds in the application process, click on “Add new rounds”, fill in the details, select the relevant students and save it.",
        image : "placements_2_1.jpg"
      },
      {
        text : "You can choose to notify students via mail of their shortlist and subsequent progress by clicking on the Share button.",
        image : "placements_2_2.jpg",
      }
    ],
    "Mark Attendance" : [
      {
        video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Mark+attendance.mp4",
        text : "Attendance can be marked for each of these rounds by creating an event for each round.",
        image : "placements_2_3.jpg"
      },
      {
        text: "Create an event for the round and fill in the required information.",
        image : "placements_3_1.jpg"
      },
      {
        text : "For marking attendance, click on the settings button then select 'Attendance' under Pre-Placement activities.",
        image : "placements_3_2.jpg"
      },
      {
        text : "A list of events open up as follows:",
        image : "placements_3_3.jpg"
      },
      {
        text : "To mark attendance for a specific event, go click against the said event. A QR code gets generated which refreshes every 10 seconds. The students scan the QR code through their Reculta app and their attendance get recorded.",
        image : "placements_3_4.jpg",
      }
    ]
  },
  applyTab : {
    "Apply Tab" : [
      {
        video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Apply+tab.mp4",
        text : "The Apply section on Reculta helps students directly apply to companies via the platform when applications are invited by the placement team.",
        image : ""
      },
      {
        text : "The student is invited to apply through an automated mail that gets generated once the applications are opened.",
        image : ""
      },
      {
        text : "They are redirected through the Reculta portal where they can see the details of the application under the process they are a part of." ,
        image : "apply_1.jpg"
      },
      {
        text : "The timer against each application gives an indication of the time left to apply to that particular company.",
        image : ""
      },
      {
        text : "Additionally, the information button against company name can be used to view any additional instructions given by the team.",
        image : ""
      },
      {
        text : "Clicking on the particular application shows the details of the said application such as CTC, JD, instructions.",
        image : "apply_2.jpg"
      },
      {
        text : "Eligibility criteria (if any) can be viewed by clicking on Eligibility Criteria.",
        image: "apply_3.jpg"
      },
      {
        text : "The history of the company can be viewed by clicking on the blue tab. It shows details regarding the time when applications were opened, the current status of the company, where that particular student stands I the process, etc.",
        image : "apply_4.jpg"
      },
      {
        text : "Additionally, by toggling the performance view button, each student can view his/her performance in each stage of the application process for a particular company. This helps the students get a real-time understanding of his/her performance with respect to each application process.",
        image : "apply_5.jpg"
      },
      {
        text : "The overall performance of the student can also be viewed through the performance dashboard unique to each student at the top of the page. This gives a student first-hand knowledge of where he/she stands in the placement process and also acts an identifier of his/her strengths and weaknesses at each level.",
        image : "apply_6.jpg",
      }
    ]
  },
  offersTab : {
    "Offers Tab" : [
      {
        video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Offers+tab.mp4",
        text : "The Offers section can be used to record final offers for the company a student has been selected for.",
        image : ""
      },
      {
        text : "The placement team gets a real-time understanding of company processes and the number of selected students vis-à-vis each application process.",
        image : ""
      },
      {
        text : "Moreover, multiple offers against a student can be recorded as per the placement policy.",
        image : ""
      },
      {
        text : "To access this section, go to the offers tab under a specific placement process.",
        image : "offers_1.jpg"
      },
      {
        text : "Offer status for each student in the process will be visible in the list.",
        image : "offers_2.jpg"
      },
      {
        text : "To enter a new offer click against a candidate’s name, fill in the necessary details such as CTC, location, location, etc. and click on update.",
        image : ""
      },
      {
        text : "Offer letters received from the company can also be uploaded alongside for easier documentation.",
        image : "offers_3.jpg"
      },
      {
        text : "The Offers Dashboard on the top of the page gives an overview of the total number of offers in a placement process and the offer count on a student basis.",
        image : "offers_4.jpg",
      }
    ]
  },
  newsfeedTab : {
    "Newsfeed"  : [
      {
        video:"",
        text : "The newsfeed feature on Reculta allows the Placement Team to post relevant content ahead of placements for the students to consume. The different clubs and committees of the institute may also have access to this feature. It enables these societies to post preparatory material for the students ahead of placements.",
        image : ""
      },
      {
        text : "To access this feature from the PT login, click on the Students tab and go to 'Feed'.",
        image : "newsfeed_1.jpg"
      },
      {
        text : "Messages in the form of text, images, and attachments can be composed. Click on the post Button to post your message.",
        image : "newsfeed_2.jpg"
      },
      {
        text : "Student engagement in the form of likes and shares per post can be viewed alongside each post.",
        image : ""
      },
      {
        text : "The Societies/ clubs and committees too have a separate login on Reculta.",
        image : ""
      },
      {
        text : "They can post content for placement preparation in a similar manner and that shall be reflected on the student feed.",
        image  : "",
      }
    ]
  },
  studentNewsFeed : {
    "Student Newsfeed" : [
      {
        video:"https://recultacampusproduction.s3.ap-south-1.amazonaws.com/training_videos/Student+newsfeed.mp4",
        text : "The students can view these posts as they login to the Reculta platform.",
        image : ""
      },
      {
        text : "They will be exposed to the central newsfeed that shows them the latest posts made by the placement team.",
        image : "studentNewsFeed",
      }
    ]
  }
}
