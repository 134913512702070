import React, { createRef } from 'react';
import { Row, Col, Container, Modal, ModalHeader, Nav, NavItem, Collapse, NavLink, TabContent, TabPane, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import Button from '../../../global/Button';
import classnames from 'classnames';
import Aux from '../../../global/AuxComp';
import { HorizontalLine } from '../../../global/horizontal_line';
import { Auth } from '../../../../actions/Auth';
import store from '../../../../store';
import { setLoading, setError } from '../../../../actions/action-creators';
import { getErrorMessage, sortObjectArray } from '../../../../actions';
import Axios from 'axios';
import { Select } from '../../../global/Forms/Select';
import { JobModal } from './job_modal';
import { ApplicationStatus } from './application-status';

class Connections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideNavLeft: null,
      sideNavRight: null,
      activeTab: '1',
      tab: 1,
      selectedId: null,
      processes: [],
      selectedProcess: "",
      jobDetails: null,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    let state = store.getState(),
    campus_id = state.role.instituteDetail.campus_id;
    let { applications } = this.props;
    store.dispatch(setLoading(true))
    this.setState({init: true})
    try {
      let resp = await Auth.Processes.getProcesses(campus_id);
      await Promise.all(applications.map(async app => {
        let resp = await Auth.Recruiter.Connection.getApplicationReferences({campus_job_id : app.campus_job_id})
        app.campus_process_id = resp.data.data.map(obj => obj.campus_process_id);
        return app;
      }))
      this.props.onChangeApplication(applications)
      this.setState({ processes: resp.data })
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    this.setState({init: false})
    store.dispatch(setLoading(false))
  }

  updateRequest = async (conn, connection_status) => {
    store.dispatch(setLoading(true))
    try {
      await Auth.Recruiter.Connection.updateRequest({ connection_id: conn.connection_id, connection_status })
      await this.props.fetchConnections()
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }

  updateApplication = async (apl, status) => {
    let { selectedProcess, selectedId } = this.state,
     { connections } = this.props,
      { role, user } = store.getState(),
      companyConn = connections.filter(conn => conn.company_id === apl.company_id && conn.connection_status === 0)
    if (!selectedProcess) {
      store.dispatch(setError({ showBar: true, message: "Select a placement drive to which you want to add this application" }))
    }
    store.dispatch(setLoading(true))
    if (companyConn.length > 0) {
      await this.updateRequest(companyConn[0], 1)
    }
    try {
      await Auth.Recruiter.Connection.updateApplication({ campus_job_id: selectedId, process_id: selectedProcess, user_id: user.id, access_key: role.access_key })
      this.setState({ selectedId: null, selectedProcess: null })
      await this.props.fetchConnections()
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }

  handleApplicationAccept = (id) => {
    this.setState({ selectedId: id })
  }

  handleProcessChange = (e) => {
    this.setState({ selectedProcess: e.target.value })
  }

  getJobDetails = async ({ campus_job_id }) => {
    store.dispatch(setLoading(true))
    try {
      let job = await Auth.Recruiter.Connection.getApplicationDetails({ campus_job_id })
      if (job.data.data[0]) {
        let jobDetails = job.data.data[0];
        jobDetails.job_eligibility = jobDetails.job_eligibility.filter(el => el.campus_id === 0 || el.campus_id == store.getState().role.instituteDetail.campus_id)
        this.setState({ jobDetails })
      }
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }

  render() {
    let { processes, selectedId, selectedProcess, jobDetails } = this.state;
    let { connections, init, applications } = this.props;
    let processListById = processes.reduce((prev, curr) => {prev[curr.process_id] = curr;return prev;}, {})
    return (
      <div className="r-card">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
              style={{ cursor: "pointer" }}
            >
              Connection
                </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
              style={{ cursor: "pointer" }}
            >
              Application
              {!!this.props.pendingApplicationCount && <div style={{float:"right", borderRadius:"50%", background:"red", height:20, width:20, textAlign:"center"}}>{this.props.pendingApplicationCount} </div>}
            </NavLink>
                
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            {connections.map(conn => {
              return (
                <Aux key={conn.connection_id}>
                  <Row noGutters className=" mb-2 py-4 align-items-center">
                    <Col xs={7}>
                     <a target="_blank" href={process.env.REACT_APP_RECRUITER_URL + "/profile/" + conn.recruiter_id}> <h2 className="r-heading-4 r-text--blue-3">{conn.company_name}</h2></a>
                      <Row className=" mb-2">
                        {conn.requested_message ? <Col xs={12}>
                          <span className="r-text--grey-4">{conn.requested_message}</span>
                        </Col> : null}
                      </Row>
                    </Col>

                    <Col xs={2}>
                    <a target="_blank" href={process.env.REACT_APP_RECRUITER_URL + "/profile/" + conn.recruiter_id}> <Button color="blue-3" style={{fontSize: "11px"}}>View Profile</Button></a>
                    </Col>

                    <Col xs="auto">
                      {conn.connection_status === 0 ?
                        <Aux>
                          <Button type="button" onClick={() => this.updateRequest(conn, 2)} className="r-text--sm">Ignore</Button>
                          <Button type="submit" onClick={() => this.updateRequest(conn, 1)}  color="blue-3" style={{fontSize: "11px"}}>Accept</Button>
                        </Aux> :
                        <Button color="blue" style={{fontSize: "11px"}}>&#10004;Connected</Button>
                      }
                    </Col>
                  </Row>
                  <HorizontalLine color="#cfcfcf" />
                </Aux>
              )
            })}
          </TabPane>
          <TabPane tabId="2">
            {
              applications.map((apl, i) => {
                return (
                  <Aux key={i}>
                    <Row noGutters className="justify-content-between mb-2 py-4">
                      <Col>
                        <h2 className="r-heading-4 r-text--blue-3">{apl.company_name}</h2>
                        <Row className="justify-content-between mb-2">
                          <Col xs={12}>
                            <span className="r-text--grey-4">{apl.role}</span>
                          </Col>
                          {
                          apl.campus_process_id && !!apl.campus_process_id.length && 
                          <Col xs={12}>
                            <em className="r-text--grey-4 r-text--sm">Requested for { apl.campus_process_id.map(processId =>  processListById[processId] ? processListById[processId].process_name : " - ").join(", ") }</em>
                          </Col>
                          }
                          {
                          !!apl.other_campus_interest && 
                          <Col xs={12}>
                            <em className="r-text--grey-4 r-text--sm">*Interested to open this opportunity to other campuses too</em>
                          </Col>
                          }
                        </Row>
                      </Col>
                      {selectedId === apl.campus_job_id ?
                        <Col xs={"auto"} className="text-right">
                          <Select value={selectedProcess || ""} onChange={this.handleProcessChange}>
                            <option value="">Select Placement Drive</option>
                            {
                              processes.map(process => {
                                return <option key={process.process_id} value={process.process_id}>{process.process_name}</option>
                              })
                            }
                          </Select>
                          <Button onClick={() => this.setState({ selectedProcess: null, selectedId: null })}>Cancel</Button>
                          <Button className="mt-2 r-btn--no-radius" color="dark" onClick={() => this.updateApplication(apl, 1)}>Add</Button>
                        </Col> :
                        <Col xs={"auto"} className="text-right">
                          {!init && ((apl.job_status === 1) ?
                            <ApplicationStatus job={apl} getJobDetails={() => this.getJobDetails(apl)} handleApplicationAccept={() => this.handleApplicationAccept(apl.campus_job_id)} />
                            :
                            <>
                            <Button color="dark" onClick={() => this.getJobDetails(apl)} className="r-btn--no-radius r-text--sm mr-3">View</Button>

                            <Button color="blue">&#10004;Added to Drive</Button>
                            </>
                          )
                          }

                        </Col>
                      }
                    </Row>
                    <HorizontalLine color="#cfcfcf" />
                  </Aux>
                )
              })
            }
            {jobDetails !== null && <JobModal isOpen={jobDetails !== null} isView={true} job={jobDetails} toggleModal={() => this.setState({ jobDetails: null })} />}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}


export default Connections;