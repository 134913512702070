import React,{Component} from 'react';
import Aux from "../../../global/AuxComp";
import {Row, Col , Button , Modal , ModalBody  , ModalHeader , ModalFooter} from 'reactstrap';
import  {InputField} from '../../../global/Forms';
import { SingleDatePicker } from '../../../global/Forms/DatePicker';
import { RTE } from '../../../global/Forms/RTE';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { connect } from 'react-redux';
import { Auth } from '../../../../actions/Auth';
import {Errorbar} from '../../../global/errorbar';
import { Loader } from '../../../global/Loader';
import { RTE2 } from '../../../global/Forms/RTE-div';
import { ReactComponent as AttachmentLogo } from '../../../../assets/icons/attachment.svg';
import { getFiles, uploadFile } from '../../../../actions';

class RoundEvent extends Component{
  constructor(props) {
    super(props);
    this.state= this.getInitialState();
    }

  getInitialState = ()=>{
    return({
      eventModal : false,
      title : null,
      description : null,
      location : null,
      eventType : null,
      startTime : moment(),
      endTime : moment(),
      start : moment(),
      end : moment(),
      startDate : moment(),
      endDate : moment(),
      errorbar : false,
      message : '',
      loading : false,
      eventDetails : [],
      deleteEventModal : false,
      eventId : null,
      updateEvent : null,
      attachedFile : null,
      attachmentFile : null,
      generatePost : false
    })
  }

  closebar = () =>{
      this.setState({errorbar: false});
    }

  descriptionOnChange = (value)=>{
    this.setState({
      description: value
    })
  }

  OnChangeStartTime = (e)=>{
    this.setState({
      startTime : moment(e)
    })
  }
  onChangeEndTime = (e)=>{
    this.setState({
      endTime : moment(e)
    })
  }

  formatTime = ()=>{
    let {startTime,endTime , start , end} = this.state;
    let startDate = (moment(start).format('YYYY-MM-DD')+" "+moment(startTime).format('HH:mm:ss'));
    let endDate = (moment(end).format('YYYY-MM-DD')+" "+moment(endTime).format('HH:mm:ss'));
    this.setState({
      startDate,
      endDate
    })
  }

  handleEventForm = async(e)=>{
    e.preventDefault();
    let {title , location} = this.state;
    await this.formatTime();
    this.isValid();
  }

  isValid = ()=>{
    let {title , location , startDate , endDate , description } = this.state;
    if(title && location && startDate && endDate && description ){
      this.createRoundEvent();
    }
    else{
      this.setState({errorbar : true});
      this.setState({message : 'Fill all the mandatory fields'});
    }
  }

  createRoundEvent = async()=>{
    this.setState({loading : true});
    let {title , location , startDate , endDate , description, generatePost } = this.state;
    let params= {
      university_id : this.props.role.instituteDetail.university_id,
      college_id : this.props.role.instituteDetail.college_id,
      campus_id : this.props.role.instituteDetail.campus_id,
      title : title,
      description: description,
      location: location,
      generatePost,
      event_type : 7,
      start_date: moment(startDate).utc(false).format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment(endDate).utc(false).format("YYYY-MM-DD HH:mm:ss")
    }
    try{
      if(this.state.attachmentFile){
        var fileResp = await uploadFile(this.state.attachmentFile, `event/attachment`);
        params.file_id = fileResp.file_id;
      }
      let events = await Auth.Batch.createEvent(params);
      let attendeeParams = {
        event_id: events.data.event_details[0].event_id,
        attendee_id: [this.props.user.id],
        attendee_type:1,
        entity_id : events.data.event_details[0].event_id,
        entity_type : "EVENT",
        subject : this.state.title,
        notification_body : this.state.description,
        reciever_id : [this.props.user.id]
      }
      if(this.state.attachmentFile){
        attendeeParams.file_id = fileResp.file_id;
      }
      try{
        let createAttendee = await Auth.Batch.createAttendee(attendeeParams);
        if(events.data.event_details.length){
          await this.createEventAttendee(events.data.event_details[0].event_id);
          await this.createRoundInvites(events.data.event_details[0].event_id, params);
          this.getEventRoundMappingList();
        }
      }
      catch(e){
        this.setState({errorbar: true});
        if(e.response && e.response.data){
          this.setState({message: e.response.data.error.toString()});
        }
        else{
          this.setState({message: 'Something went wrong'});
        }
      }
      this.setState(this.getInitialState());
  }
  catch(e){
    this.setState({errorbar: true});
    if(e.response && e.response.data){
      this.setState({message: e.response.data.error.toString()});
    }
    else{
      this.setState({message: 'Something went wrong'});
    }
  }
}

createRoundInvites = async(eventId, params)=>{
  let {title , location , startDate , endDate , description } = this.state;
  let {studentCollection} = this.props;
  if(title  && description){
    let inviteParams = {
      event_id : eventId,
      university_id : this.props.role.instituteDetail.university_id,
      college_id : this.props.role.instituteDetail.college_id,
      campus_id : this.props.role.instituteDetail.campus_id,
      title : title,
      description : description,
    }
    if(studentCollection && studentCollection.length>0){
    try{
      let createInvite = await Auth.Batch.createInvite(inviteParams);
        let studentEmailArray =[] , attendeeArray =[];
        studentCollection.forEach(student=>{
          studentEmailArray.push(student.student.email);
          attendeeArray.push(student.student.user_id);
        })
          let sendInviteParams = {
            invite_id : createInvite.data[0].invite_details[createInvite.data[0].invite_details.length-1].invite_id,
            recipient_type :1,
            recipient_id : attendeeArray,
            start : moment(startDate).utc(false).format("YYYY-MM-DD HH:mm:ss"),
            end : moment(endDate).utc(false).format("YYYY-MM-DD HH:mm:ss"),
            title : title,
            description : description,
            location : location,
            file_id: params.file_id,
            attendeeEmail : studentEmailArray
          }
          let sendInvites = await Auth.Batch.createInviteAttendee(sendInviteParams);
        }
        catch(e){
          this.setState({errorbar: true});
          if(e.response && e.response.data){
            this.setState({message: e.response.data.error.toString()});
          }
          else{
            this.setState({message: 'Something went wrong! Please try again'});
          }
        }
      }
    }
  }

createApplicationroundMapping = async(applicationId , applicationRoundId , eventId)=>{
  let roundEventMappingParams = {
    event_id : eventId,
	  application_round_id : applicationRoundId,
	  application_id : applicationId
  }
  try{
    await Auth.EventRoundMapping.createEventRoundMapping(roundEventMappingParams);
  }
  catch(e){
    this.setState({errorbar : true});
    if(e.response && e.response.data){
      this.setState({message: e.response.data.error.toString()});
    }
    else{
      this.setState({message: 'Something went wrong'});
    }
  }
}

createEventAttendee = async(event_id)=>{
  let { studentCollection ,applicationId , applicationRoundId } = this.props;
  let {title , description} = this.state;
  if(event_id!=undefined && studentCollection.length!=0){
        try{
          let studentArray = await Promise.all(studentCollection.map(async student=>{
            return student.student.user_id;
          }))
          let attendeeParams = {
            attendee_id : studentArray,
            event_id,
            attendee_type: 1,
            entity_id : event_id,
            entity_type : "EVENT",
            subject : this.state.title,
            notification_body : this.state.description,
            reciever_id : studentArray
          }
       await Auth.Batch.createAttendee(attendeeParams);
      await  this.createApplicationroundMapping(applicationId , applicationRoundId , event_id);
      }
      catch(e){
        this.setState({errorbar : true});
        if(e.response && e.response.data){
          this.setState({message: e.response.data.error.toString()});
        }
        else{
          this.setState({message: 'Something went wrong'});
        }
      }
  }
}

getEventRoundMappingList = async()=>{
  let {applicationId , applicationRoundId } = this.props;

  let getListParams = new URLSearchParams();
    getListParams.append("application_id" , applicationId)
    getListParams.append("application_round_id" , applicationRoundId);
  try{
    let eventRoundMappingResp = await Auth.EventRoundMapping.getEventRoundMapping(getListParams);
    if(eventRoundMappingResp.data.length){
      let detail = eventRoundMappingResp.data[0];
      this.setState({
        title : detail.title,
        description : detail.description,
        location : detail.location,
        eventId : detail.event_id,
        start : moment(detail.start_date).format('YYYY-MM-DD'),
        end: moment(detail.end_date).format('YYYY-MM-DD'),
        startTime :  moment(detail.start_date),
        endTime : moment(detail.end_date)
      })
      if(detail.file_id){
        var files = await getFiles([detail.file_id]);
        if(files[detail.file_id] && files[detail.file_id].length){
          this.setState({attachedFile: files[detail.file_id][0]})
        }
      }
    }
    this.setState({eventDetails : eventRoundMappingResp.data});

  }
  catch(e){
    this.setState({errorbar : true});
    if(e.response && e.response.data){
      this.setState({message: e.response.data.error.toString()});
    }
    else{
      this.setState({message: 'Something went wrong'});
    }
  }
}

deleteEventRound = async(applicationId , applicationRoundId , eventId)=>{
  this.setState({loading:true});
  let deleteEventRoundMappingParams = {
    application_id : applicationId,
    application_round_id : applicationRoundId,
    event_id : eventId
  }
  try{
    await Auth.EventRoundMapping.deleteEventRoundMapping(deleteEventRoundMappingParams);
  }
  catch(e){
    console.log(e);
    this.setState({errorbar : true});
    if(e.response && e.response.data){
      this.setState({message: e.response.data.error.toString()});
    }
    else{
      this.setState({message: 'Something went wrong'});
    }
  }
  this.setState(this.getInitialState());
}

updateEventRound = async (eventId,e)=>{
  e.preventDefault();
  this.setState({loading : true});
  await this.formatTime();
  let {startDate , endDate , location , title , description} = this.state;
  if(startDate <= endDate && location && title && description){
  let params = {
    event_id: eventId,
    title : title,
    description: description,
    location: location
  }
  params.start_date =  moment(startDate).utc(false).format('YYYY-MM-DD HH:mm:ss');
  params.end_date = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm:ss');
  if(!this.state.attachedFile) params.file_id = "0";
  if(this.state.attachmentFile){
    var fileResp = await uploadFile(this.state.attachmentFile, `event/attachment`);
    params.file_id = fileResp.file_id;
  }
  try{
    let updateEvent = await Auth.Batch.updateEventDetails(params);

    this.createRoundInvites(eventId, params);
  }
  catch(e){
    this.setState({errorbar : true});
    if(e.response && e.response.data){
      this.setState({message: e.response.data.error.toString()});
    }
    else{
      this.setState({message: 'Something went wrong'});
    }
  }}
  await this.setState(this.getInitialState());
  this.getEventRoundMappingList();

}

componentDidMount(){
  this.getEventRoundMappingList();
}

  render(){
    let {startDate ,loading ,  endDate , title , description ,eventId , updateEvent, location , eventType , startTime , endTime, start , end , eventDetails} = this.state;
    return (
      <Aux>
      <Loader show={loading} />
        {eventDetails.length>0? <div className="w-100 h-100 border"><div className="w-100 h-100 r-bg--blue-3 r-text--light"><Row >
          <Col xs={7} className="text-truncate">
            <span className="r-subheading-4 ml-2">{eventDetails[0].title}</span>
          </Col>
          <Col xs={5} className="p-0">
            <button type="button"  onClick={()=>this.setState({eventModal : !this.state.eventModal , updateEvent : 1})} className="border-0 r-text--light r-bg--blue-3"><i className="fi flaticon-edit" /></button>
            <button type="button"  onClick={()=>this.setState({deleteEventModal : !this.state.deleteEventModal})} className="border-0 r-text--light r-bg--blue-3"><i className="fi flaticon-bin" /></button>
          </Col>
          <Modal zIndex={99999} isOpen={this.state.deleteEventModal} >
            <ModalHeader>Are you sure you want to delete</ModalHeader>
            <ModalFooter>
              <Button color="dark" onClick={()=>this.deleteEventRound(eventDetails[0].application_id , eventDetails[0].application_round_id ,eventDetails[0].event_id )}>Delete</Button>
              <Button  onClick={()=>this.setState({deleteEventModal : !this.state.deleteEventModal})}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </Row>
        <Row>
          <Col className="m-2">
          <div className="r-text--grey mt-3">
              <p className="mb-1 r-text--light date_font"> {moment(eventDetails[0].start_date).format('DD/MM/YYYY')}{' - '}{moment(eventDetails[0].end_date).format('DD/MM/YYYY')}</p>
              <p className="mb-1 r-text--light date_font"> {moment(eventDetails[0].start_date).format('hh:mm A')}{' - '}{moment(eventDetails[0].end_date).format('hh:mm A')}</p>
        </div></Col></Row></div></div>:
        <Button className="w-100 h-100 r-text--light r-bg--blue-3 border-0" onClick={()=>this.setState({eventModal : !this.state.eventModal})}>
          <Row>
            <Col>
              <span className="r-heading-2"><i className="fi flaticon-plus-1" /></span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="r-subheading-2">Create Event</span>
            </Col>
        </Row>
      </Button>}
      <Modal zIndex={99999} isOpen={this.state.eventModal} >
        <ModalHeader className="r-heading-5 r-text--blue-3" toggle={()=>this.setState({eventModal : !this.state.eventModal})}>Create Event </ModalHeader>
        <ModalBody>
          <form onSubmit={updateEvent==1 && eventId?(e)=>this.updateEventRound(eventId ,e) : this.handleEventForm}>
            <Row>
              <Col>
                <label><span className="r-text--red-1">*</span>Title</label>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <InputField placeholder="Title" className="w-100 border-0" value={title} onChange={(e)=>this.setState({title : e.target.value})} />
              </Col>
            </Row>
            <Row>
              <Col>
                <label><span className="r-text--red-1">*</span>Description</label>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="calendar__event-description-box">
                <RTE2 onChange={(value)=>this.descriptionOnChange(value)}   placeholder="Type Description here" hideToolbar={true} value={description} />
              </Col>
            </Row>
            <Row>
              <Col>
                <label><span className="r-text--red-1">*</span>Location</label>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col >
                <InputField placeholder="Location"  name="location" className="w-100 border-0" value={location} onChange={(e)=>this.setState({location : e.target.value})} />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs="12">
                <span className="r-subheading-4">Attachment</span>
              </Col>
              <Col className=" mt-1" xs="12" >
                {this.state.attachedFile ? 
                  <div className="text-truncate r-tag r-tag--type-1">
                    <AttachmentLogo width={10} />
                    <a href={this.state.attachedFile.url} target="_blank" rel="noopener noreferrer">{this.state.attachedFile.file_name}</a> 
                    <Button type="button" className="r-tag__delete fi flaticon-plus-1 text-dark" onClick={(e) => this.setState({attachedFile : null})}></Button>
                  </div>
                  :
                <input onChange={(e) => this.setState({attachmentFile : e.target.files[0]})} type="file" />
                }
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <label><span className="r-text--red-1">*</span>Start Date</label>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs="5" className="pr-0">
                <SingleDatePicker value={start} onDateChange={(e)=>this.setState({start : moment(e)})} />
              </Col>
              <Col xs="7">
                <TimePicker placeholder="hh:mm"  className="w-50" showSecond={false}  onChange={this.OnChangeStartTime} minuteStep={5} value={startTime} allowEmpty={false}
                inputReadOnly
                format= "hh:mm a" use12Hours  />
              </Col>
            </Row>
            <Row>
              <Col >
                <label><span className="r-text--red-1">*</span>End Date</label>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs="5" className="pr-0">
                <SingleDatePicker
                value={end}
                onDateChange={(e)=>this.setState({end : moment(e)})} />
              </Col>
              <Col xs="7">
                <TimePicker placeholder="hh:mm"   className="w-50" showSecond={false} name="endTime"  onChange={this.onChangeEndTime} minuteStep={5} value={endTime} allowEmpty={false}
                inputReadOnly
                format= "hh:mm a" use12Hours />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
              {!eventId && <span className="mr-3"><input type="checkbox" className="mr-2" checked={this.state.generatePost} onChange={(e) => this.setState({generatePost : !this.state.generatePost})} />Auto Generate Feed</span>}
              </Col>
            </Row>
            <Row>
              <Col align="end">
                <Button color="dark" >Submit </Button>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
      <Errorbar  showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message}/>
    </Aux>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})
export default connect(mapStateToProps, null)(RoundEvent);
